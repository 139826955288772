export const user = {
  namespaced: true,
  state: {
    influencer: {
      data: [],
    },
    data: [],

    info: null,
    token: null,

    track: {},
    cover: null,
  },
  getters: {},
  actions: {
    getInfluencer({ commit }, data) {
      commit("getInfluencer", data);
    },
    getData({ commit }, data) {
      commit("getData", data);
    },
    getInfo({ commit }, data) {
      commit("getInfo", data);
    },
    getTrack({ commit }, data) {
      commit("getTrack", data);
    },
    getToken({ commit }, data) {
      commit("getToken", data);
    },
    getCover({ commit }, data) {
      commit("getCover", data);
    },
    getLocalInfo({ commit }, data) {
      commit("getLocalInfo", data);
    },
    getLocalToken({ commit }, data) {
      commit("getLocalToken", data);
    },
    getLocalTrack({ commit }, data) {
      commit("getLocalTrack", data);
    },
  },
  mutations: {
    getInfluencer(state, data) {
      state.influencer.data = data;
    },
    getData(state, data) {
      state.data = data;
    },
    getInfo(state, data) {
      state.info = data;
    },
    getTrack(state, data) {
      state.track = data;
    },
    getToken(state, data) {
      state.token = data;
    },
    getCover(state, data) {
      state.cover = data;
    },
    getLocalInfo(state, data) {
      localStorage.setItem("user", data);
    },
    getLocalToken(state, data) {
      localStorage.setItem("user_token", data);
    },
    getLocalTrack(state, data) {
      localStorage.setItem("user_track", data);
    },
  },
};
