<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Learning a new language can be challenging, but with the help of this language learning aid, you can make significant progress in your Spanish journey. This subliminal is designed to enhance your vocabulary, grammar, and conversational skills."
    },
    {
      type: 'heading',
      text: "Here's how this language learning aid can benefit you:"
    },
    {
      type: 'list',
      items: [
        "Expanding your Spanish vocabulary by introducing new words and phrases.",
        "Improving your understanding of Spanish grammar and sentence structure.",
        "Enhancing your ability to engage in conversations and express yourself in Spanish.",
        "Boosting your confidence in speaking and understanding the Spanish language.",
        "Accelerating your overall language acquisition process."
      ]
    },
    {
      type: 'paragraph',
      text: "By consistently listening to this subliminal, you can create a subconscious foundation for language learning and reinforce your Spanish skills. Remember to actively practice speaking, reading, and listening to Spanish to maximize your progress. Whether you're a beginner or intermediate learner, this language learning aid can support you on your journey to becoming fluent in Spanish."
    }
  ]
},
            {
  id: 'before-listening',
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a journey to learn a new language can be both exciting and challenging. This subliminal is designed to help you learn Spanish effectively and easily, boosting your vocabulary, grammar, and conversational skills. Before you begin, here are some tips to enhance your learning experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set realistic goals: Establish specific targets for your language learning journey, such as learning a certain number of new words or mastering a particular grammar rule.",
        "Create a language learning routine: Dedicate regular time to practice Spanish, whether it's daily or a few times a week, to maintain consistency and progress.",
        "Immerse yourself in Spanish: Surround yourself with Spanish-speaking resources, such as movies, music, podcasts, and books, to expose yourself to the language and improve your listening skills.",
        "Stay motivated: Remember why you wanted to learn Spanish and visualize the benefits of becoming fluent in the language."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal will work in combination with your language learning efforts, reinforcing positive language patterns and helping you absorb Spanish more effortlessly. By incorporating these tips into your learning routine and listening consistently, you can develop proficiency in Spanish and achieve your language goals. ¡Vamos a aprender español!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing your Spanish Language Learning Aid session! By dedicating time and effort to improving your language skills, you are taking meaningful steps towards broadening your horizons and connecting with a new culture. Take a moment to reflect on your experience and continue your language learning journey."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice speaking: Engage in conversations in Spanish, whether with native speakers or language exchange partners.",
        "Expand vocabulary: Continue learning new words and phrases to enrich your vocabulary.",
        "Immerse yourself: Listen to Spanish music, watch movies or TV shows in Spanish, and read books or articles in the language.",
        "Set realistic goals: Outline specific language learning goals that are achievable and track your progress along the way."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, learning a new language is a journey that requires patience and practice. Embrace every opportunity to use and improve your Spanish skills. With consistent effort and dedication, you will continue to make progress and achieve fluency. ¡Buena suerte! (Good luck!)"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Spanish Language Learning Aid help me learn Spanish?`, 
    answer: `The Spanish Language Learning Aid is designed to assist you in learning Spanish by providing audio content that focuses on vocabulary, grammar, and conversational skills. By listening to the aid regularly, you can reinforce your learning and improve your understanding and fluency in the language. Additionally, the aid may help build your confidence in speaking Spanish with its guided practice exercises and examples.`,
    open: false
  },
  { 
    question: `How long does it take to see progress in learning Spanish with the aid?`, 
    answer: `The progress you make in learning Spanish with the aid can vary depending on factors such as your prior language learning experience, the time you dedicate to practicing, and your individual learning style. Consistent practice and exposure to the aid's content are key to seeing progress, but everyone learns at their own pace. The more you immerse yourself in listening and practicing with the aid, the faster you may see improvements.`,
    open: false 
  },
  { 
    question: `How can I make the most out of the Spanish Language Learning Aid?`, 
    answer: `To make the most out of the Spanish Language Learning Aid, it is recommended to listen to the audio content regularly and actively engage with the exercises and examples. Practice speaking and writing in Spanish using the aid's prompts and exercises, and seek opportunities to apply your learning outside of the aid, such as conversing with native Spanish speakers or using Spanish in everyday situations. Consistency and active participation will help maximize your learning outcomes.`,
    open: false 
  },
  { 
    question: `Can the Spanish Language Learning Aid replace traditional language learning methods?`, 
    answer: `The Spanish Language Learning Aid is designed to complement traditional language learning methods, but it is not intended to replace them entirely. While the aid provides valuable audio content and exercises, combining it with other resources, such as textbooks, online lessons, or language classes, will provide a more comprehensive learning experience. This will allow you to practice different language skills and reinforce your learning in various ways.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with using the Spanish Language Learning Aid?`, 
    answer: `No, there are no known risks or side effects associated with using the Spanish Language Learning Aid. It is a safe and effective tool for language learning. However, if you have any concerns or questions about your language learning progress or process, it is always beneficial to consult with a language instructor or tutor.`,
    open: false 
  },
  { 
    question: `Is the Spanish Language Learning Aid suitable for beginners?`, 
    answer: `Yes, the Spanish Language Learning Aid is suitable for beginners. It is designed to provide guidance and support for individuals who are starting to learn Spanish or want to reinforce their foundational knowledge. The aid covers vocabulary, grammar, and conversational skills at a beginner level, making it accessible and useful for learners at various stages of their Spanish language journey.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>