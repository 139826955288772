<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you've had a night of drinking and are experiencing the unpleasant symptoms of a hangover, this hangover cure subliminal can offer some relief. By targeting your subconscious mind, it aims to alleviate the symptoms and help you recover more quickly."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help you:'
    },
    {
      type: 'list',
      items: [
        "Relieving headache, nausea, and other physical symptoms associated with a hangover.",
        "Reducing fatigue and promoting a sense of revitalization.",
        "Boosting hydration levels to counteract dehydration caused by alcohol consumption.",
        "Calming a queasy stomach and improving digestion.",
        "Promoting relaxation and reducing anxiety or irritability often experienced after drinking."
      ]
    },
    {
      type: 'paragraph',
      text: "Using this hangover cure subliminal can assist in overcoming the discomfort and symptoms of a hangover, allowing you to bounce back more quickly. Remember, it's important to stay hydrated, get rest, and listen to your body as part of your overall recovery process."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "We've all experienced the unpleasant aftermath of a night of drinking. Whether it was a celebration or simply a night out with friends, a hangover can leave you feeling nauseated, fatigued, and with a pounding headache. This subliminal is designed to help alleviate the symptoms of a hangover and restore your well-being. Before you start, here are some suggestions to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Hydrate: Drink plenty of water before and after listening to the subliminal to rehydrate your body.",
        "Eat a balanced meal: Consuming a nourishing meal can help replenish essential nutrients and support your recovery.",
        "Find a quiet and comfortable space: Create a relaxing environment where you can fully immerse yourself in the subliminal messages.",
        "Set your intention: Focus on your desire to feel better and relieve the symptoms of your hangover while listening to the subliminal affirmations."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is intended to provide relief and support your body's natural healing processes. Embrace this moment to prioritize self-care and recovery. Take a deep breath, set your intention, and let the hangover cure guide you towards feeling better and rejuvenated."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Hangover Cure session! By listening to this subliminal, you've taken a proactive step towards relieving the symptoms of a hangover and recovering quickly. Now it's time to reflect on your experience and continue taking care of yourself to ensure a speedy recovery!"
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Hydrate: Drink plenty of water to replenish lost fluids and help flush out toxins.",
        "Eat nutritious foods: Opt for foods rich in vitamins, minerals, and electrolytes to support your body's recovery.",
        "Rest and relax: Allow your body to rest, giving it the time it needs to recover fully.",
        "Avoid alcohol: Give your body a break and avoid consuming alcohol for a while to help it recover."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, the best cure for a hangover is prevention. It's important to drink responsibly and in moderation. However, if you do find yourself experiencing hangover symptoms, following these post-listening tips will help alleviate discomfort and support your recovery. Take care and stay healthy!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Hangover Cure work?`, 
    answer: `The Hangover Cure is designed to help alleviate the symptoms of a hangover by promoting hydration, replenishing essential nutrients, and supporting the body's natural detoxification processes. The subliminal messages are intended to influence your subconscious mind, encouraging a faster recovery and relieving discomfort associated with headaches, nausea, and fatigue.`,
    open: false 
  },
  { 
    question: `When should I listen to the Hangover Cure subliminal?`, 
    answer: `It is recommended to listen to the Hangover Cure subliminal as soon as possible after consuming alcohol to maximize its effectiveness. Ideally, listen to the audio before bedtime or during a relaxation period. However, results may vary depending on the individual and the severity of the hangover symptoms.`,
    open: false 
  },
  { 
    question: `How long will it take for the Hangover Cure to take effect?`, 
    answer: `The time it takes for the Hangover Cure to take effect can vary depending on individual factors, such as the severity of the hangover symptoms and the body's natural ability to recover. Some people may notice relief soon after listening to the subliminal, while others may need more time. It is best to listen to the audio consistently and allow your body to rest and recover naturally.`,
    open: false 
  },
  { 
    question: `Can the Hangover Cure prevent a hangover if I listen to it before drinking alcohol?`, 
    answer: `No, the Hangover Cure is not intended to prevent or eliminate a hangover if consumed with alcohol. It is designed to alleviate symptoms after a night of drinking. To prevent or reduce the likelihood of a hangover, it is important to drink alcohol in moderation, stay hydrated, and consume food before and while drinking.`,
    open: false 
  },
  { 
    question: `Is the Hangover Cure a substitute for drinking responsibly?`, 
    answer: `No, the Hangover Cure is not a substitute for drinking responsibly. It is always important to consume alcohol in moderation, stay hydrated, and be aware of your limits. The Hangover Cure is designed to provide relief from hangover symptoms as a complementary support, but responsible alcohol consumption remains essential for overall health and well-being.`,
    open: false 
  },
  { 
    question: `Are there any side effects of using the Hangover Cure subliminal?`, 
    answer: `No, there are no known side effects associated with using the Hangover Cure subliminal. It is a safe and natural method to support your body's recovery from a hangover. However, if you have any concerns or underlying health conditions, it is always advisable to consult with your healthcare provider.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>