<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Do you find yourself feeling anxious and overwhelmed at the thought of speaking in public? If so, this subliminal can help you overcome your fear of public speaking and develop the confidence to present well in any situation."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can assist you:'
    },
    {
      type: 'list',
      items: [
        "Reducing anxiety and nervousness associated with public speaking.",
        "Boosting self-confidence and belief in your abilities to communicate effectively.",
        "Enhancing your speaking skills and eloquence in front of an audience.",
        "Building a positive and empowering mindset towards public speaking.",
        "Eliminating self-doubt and negative thoughts that hinder your ability to speak confidently in public."
      ]
    },
    {
      type: 'paragraph',
      text: "With consistent listening to this subliminal, you can rewire your subconscious mind to let go of fear and embrace the joy of public speaking. Imagine the possibilities that open up when you no longer feel restricted by anxiety or stage fright. Take the first step towards becoming a confident and compelling speaker today!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Overcoming the fear of public speaking is a common goal for many individuals, and this subliminal is designed to help you achieve just that. Before you begin listening, consider these tips to prepare your mind for success:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your fear: Recognize and accept that you have a fear of public speaking. This is the first step towards conquering it.",
        "Practice deep breathing: Deep breaths can help calm your nerves and reduce anxiety. Incorporate deep breathing exercises into your daily routine.",
        "Visualize success: Imagine yourself speaking confidently and effectively in front of an audience. Visualize a positive outcome.",
        "Set small goals: Start with smaller speaking opportunities or practice sessions to gradually build your confidence.",
        "Positive affirmations: Use positive self-talk and affirmations to reinforce your belief in your ability to speak well in public."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to reprogram your subconscious mind and help you overcome your fear of public speaking. With regular listening and consistent practice, you can develop the confidence and skills necessary to speak in public and present well. Embrace this opportunity for growth, believe in yourself, and let's begin your journey towards fearless public speaking."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Fear of Public Speaking Removal subliminal! By taking this course, you have made a significant step towards overcoming your fear and becoming a confident public speaker. Now that you've finished listening, it's important to reflect on your progress and continue building your public speaking skills."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice speaking in front of a mirror: Gain confidence by rehearsing your speeches and presentations.",
        "Join public speaking groups: Seek opportunities to participate in public speaking clubs or organizations to further develop your skills.",
        "Visualize success: Imagine yourself giving successful presentations and delivering captivating speeches.",
        "Take small steps: Start by speaking in front of smaller audiences to gradually build your confidence."
      ]
    },
    {
      type: 'paragraph',
      text: "Overcoming the fear of public speaking takes time and effort, but with consistent practice and determination, you can become a confident and effective communicator. Embrace opportunities to speak in public, challenge yourself, and celebrate your progress along the way. You are capable of delivering powerful presentations and captivating audiences. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Fear of Public Speaking Removal subliminal work?`, 
    answer: `The Fear of Public Speaking Removal subliminal works by targeting the subconscious mind and reprogramming limiting beliefs and fears associated with speaking in public. The subliminal messages are designed to instill confidence, calmness, and a positive mindset when it comes to public speaking. By consistently listening to the subliminal, you can gradually overcome your fear and develop the skills and confidence to speak effectively in front of any audience.`,
    open: false
  },
  { 
    question: `How long does it take to overcome the fear of public speaking with the subliminal?`, 
    answer: `The time it takes to overcome the fear of public speaking can vary from person to person. Some individuals may notice positive changes and increased confidence after a few listens, while others may need to listen consistently over a longer period of time. We recommend listening to the subliminal daily and consistently for best results.`,
    open: false 
  },
  { 
    question: `How can I enhance the effectiveness of the Fear of Public Speaking Removal subliminal?`, 
    answer: `To enhance the effectiveness of the subliminal, it can be helpful to combine it with other practical strategies and techniques. Practicing public speaking in safe and supportive environments, engaging in relaxation techniques such as deep breathing or meditation before a speaking engagement, and visualizing successful and confident presentations can all complement the subliminal in overcoming the fear of public speaking.`,
    open: false 
  },
  { 
    question: `Can the Fear of Public Speaking Removal subliminal eliminate all nervousness before speaking in public?`, 
    answer: `While the Fear of Public Speaking Removal subliminal is designed to reduce and eliminate fear and anxiety associated with public speaking, it is natural to experience some level of nervousness before speaking in public. However, with consistent listening and practice, the subliminal can empower you to manage and overcome these nerves, allowing you to deliver confident and impactful presentations.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Fear of Public Speaking Removal subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your fear of public speaking or mental well-being, please consult with a mental health professional. It is important to note that the subliminal is intended to supplement personal development efforts, not replace professional assistance.`,
    open: false 
  },
  { 
    question: `Will the Fear of Public Speaking Removal subliminal make me a naturally gifted orator?`, 
    answer: `While the Fear of Public Speaking Removal subliminal can help you overcome your fear and develop confidence in speaking, it does not guarantee or create instant mastery of public speaking skills. Public speaking is a skill that requires practice, experience, and continuous improvement. However, with the right mindset and consistent effort, you can become a confident and effective speaker over time.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>