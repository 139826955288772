<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you find yourself avoiding conflicts or feeling overwhelmed by them, this subliminal can help you overcome your fear of conflict and develop healthier ways to address and resolve disputes in your relationships and everyday life."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Helping you feel more comfortable expressing your thoughts and emotions during conflicts.",
        "Providing you with tools and strategies to handle conflicts in a constructive and productive manner.",
        "Reducing anxiety and fear associated with conflicts, allowing you to approach them with more confidence.",
        "Improving your communication skills, making it easier to express your needs and listen to others.",
        "Teaching you how to find mutually beneficial solutions and reach resolutions that strengthen relationships."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind to perceive conflict as an opportunity for growth and positive change. Over time, you'll develop the skills and mindset to handle conflicts with grace and effectiveness. Remember, building these new habits takes practice and patience, but you have the power to transform your relationship with conflict."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Dealing with conflict can be challenging, especially if you have a fear of confrontation. This subliminal is designed to help you overcome your fear of conflict and develop healthy strategies for addressing and resolving conflicts in your relationships and everyday life. Before you start listening, consider these tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your fear: Recognize and accept that you have a fear of conflict. By acknowledging it, you can take steps to address it.",
        "Educate yourself: Learn about healthy communication techniques, conflict resolution strategies, and assertiveness skills.",
        "Practice self-compassion: Be kind and patient with yourself as you work through your fear. Remember that growth takes time.",
        "Set your intention: Before listening, affirm your commitment to overcoming your fear of conflict and improving your relationships."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal will support you in rewiring your subconscious beliefs and emotions surrounding conflict. With regular practice, you can develop a confident and empowered approach to addressing conflicts. Embrace this opportunity for growth and transformation, and let's begin the journey to overcoming your fear of conflict."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Fear of Conflict Removal subliminal! By dedicating time and effort to address your fear of conflict, you have taken a significant step towards improving your relationships and overall well-being. Now, it's time to reflect on your progress and continue growing in your ability to handle conflicts in a healthy way."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your journey: Take time to journal about your experiences in managing conflicts after listening to the subliminal.",
        "Practice active listening: Develop the skill of truly understanding the perspectives of others in a conversation.",
        "Learn healthy communication techniques: Explore resources or seek professional guidance to develop constructive ways of addressing conflicts.",
        "Embrace assertiveness: Focus on expressing your needs and concerns in a respectful and clear manner."
      ]
    },
    {
      type: 'paragraph',
      text: "The process of overcoming the fear of conflict and embracing healthier ways of resolving conflicts requires patience and practice. Remember that conflicts can be opportunities for growth and understanding. As you continue on this journey, stay committed to personal growth and building better relationships. You're on the right path!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Fear of Conflict Removal subliminal help me overcome my fear of conflict?`, 
    answer: `The Fear of Conflict Removal subliminal is designed to reprogram your subconscious mind, helping you to develop a more positive and confident attitude towards conflict. By listening to the subliminal regularly, you may experience a reduction in fear and anxiety related to conflict, as well as an increase in assertiveness and effective communication skills. Consistency is key, and over time, you may find yourself more comfortable addressing and resolving conflicts in your relationships and everyday life.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Fear of Conflict Removal subliminal?`, 
    answer: `Results can vary from person to person, and it may take time for the subliminal messages to effect subconscious changes. We recommend listening to the Fear of Conflict Removal subliminal consistently for at least a few weeks to allow the positive affirmations to take root. With regular use, you may begin to notice an increased sense of confidence and a reduction in fear and anxiety related to conflict.`,
    open: false 
  },
  { 
    question: `Can the Fear of Conflict Removal subliminal help with all types of conflicts?`, 
    answer: `Yes, the Fear of Conflict Removal subliminal can be beneficial for addressing and resolving conflicts in various areas of your life, including personal relationships, professional settings, and everyday interactions. It aims to help you develop healthier response patterns and more effective communication skills, empowering you to navigate conflicts in a constructive and assertive manner.`,
    open: false 
  },
  { 
    question: `Are there any potential side effects of listening to the Fear of Conflict Removal subliminal?`, 
    answer: `No, there are no known side effects associated with listening to the Fear of Conflict Removal subliminal. However, if you have any concerns about your mental health or specific fears related to conflict, we recommend seeking professional guidance or counseling.`,
    open: false 
  },
  { 
    question: `Can the Fear of Conflict Removal subliminal replace professional therapy or counseling?`, 
    answer: `No, the Fear of Conflict Removal subliminal is not intended to replace professional therapy or counseling. It can be used as a complementary tool to support personal growth and development in overcoming the fear of conflict. If you have deep-seated fears or complex emotional issues related to conflict, we recommend seeking the guidance of a qualified professional.`,
    open: false 
  },
  { 
    question: `Will the Fear of Conflict Removal subliminal make me overly confrontational or aggressive?`, 
    answer: `No, the Fear of Conflict Removal subliminal is designed to help you develop a healthier attitude towards conflict, not to promote aggression or confrontational behavior. It aims to empower you with the confidence and skills needed to address and resolve conflicts in a constructive and assertive manner, fostering healthier relationships and effective communication.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>