<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you desire a slimmer and more defined face, this subliminal can help you achieve your goals. By combining targeted exercises and techniques, it aims to enhance your facial features and boost your self-confidence."
    },
    {
      type: 'heading',
      text: 'Here are some key features of this program:'
    },
    {
      type: 'list',
      items: [
        "Focused facial exercises designed to tone and slim specific areas of the face.",
        "Techniques to improve facial symmetry and proportion.",
        "Strategies to reduce puffiness and bloating in the face.",
        "Visualization exercises to help you develop a positive perception of your facial features.",
        "Affirmations to enhance your self-confidence and embrace your unique beauty."
      ]
    },
    {
      type: 'paragraph',
      text: "Consistency is key when it comes to achieving facial slimming goals. By incorporating this subliminal into your daily routine, you can gradually transform your face and feel more confident in your appearance. Remember, everyone's facial structure is unique, so results may vary, but with dedication and commitment, you can enhance your natural beauty."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Are you looking to achieve a slimmer and more defined face? This subliminal can help you enhance your facial features and boost your self-confidence. Before you start your journey, consider these tips to maximize your results:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Understand your desired outcome: Visualize the facial features you want to enhance and how you would feel with a slimmer face.",
        "Commit to consistency: Set a dedicated time each day for practicing the facial exercises and techniques provided in this subliminal.",
        "Create a peaceful environment: Find a quiet and comfortable space where you can focus on your exercises without distractions.",
        "Believe in the process: Maintain a positive mindset and trust that with regular practice and patience, you will achieve your desired results."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal is designed to guide you through targeted exercises and techniques to help you achieve a slimmer and more defined face. As you listen to the subliminal affirmations, remember to practice the exercises consistently and believe in your ability to transform your facial features. Embrace this opportunity to improve your confidence and take the first step towards your desired outcome. Let's begin!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Facial Slimming session! By focusing on exercises and techniques to achieve a slimmer and more defined face, you've taken a positive step towards enhancing your facial features and boosting your self-confidence. Now, it's time to reflect on your experience and continue your journey towards a more confident you."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Continue practicing facial exercises: Incorporate targeted exercises into your daily routine to strengthen and tone your facial muscles.",
        "Stay hydrated: Drink plenty of water to promote healthy skin and maintain facial elasticity.",
        "Follow a healthy lifestyle: Maintain a balanced diet and engage in regular exercise to support overall well-being, which can also contribute to a more radiant appearance.",
        "Practice self-care: Take time for yourself, engage in activities that bring you joy, and prioritize your mental and emotional well-being."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, the journey to enhancing your facial features and boosting your self-confidence is unique to you. Embrace the progress you make along the way, and be patient with yourself. With consistent effort and self-care, you're on your way to achieving a slimmer and more defined face. Keep shining!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How long does it take to see results from facial slimming exercises?`, 
    answer: `The time it takes to see results from facial slimming exercises can vary from person to person. Factors such as genetics, age, and overall health may play a role in how quickly you see changes. Consistent practice is key to achieving results, so it's important to perform the exercises regularly and follow a healthy lifestyle. It may take several weeks or even months before you notice a significant difference in your facial appearance.`,
    open: false
  },
  { 
    question: `Can facial slimming exercises completely change the shape of my face?`, 
    answer: `Facial slimming exercises can help enhance your facial features and promote a more defined appearance, but they cannot completely change the fundamental structure of your face. These exercises primarily target the muscles in your face, helping to tone and tighten them, resulting in a more contoured look. If you are looking for more significant changes, you may want to consider consulting with a qualified cosmetic professional.`,
    open: false 
  },
  { 
    question: `Can facial slimming exercises get rid of a double chin?`, 
    answer: `Yes, facial slimming exercises can be effective in reducing the appearance of a double chin. Targeted exercises that work the muscles in your chin, jawline, and neck can help tone and tighten the area, resulting in a more defined jawline. It's important to complement these exercises with a healthy lifestyle that includes a balanced diet and regular exercise for optimal results.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of facial slimming exercises?`, 
    answer: `Facial slimming exercises are generally safe and do not have any known serious risks or side effects. However, it's important to perform the exercises correctly to avoid strain or injury. If you experience any discomfort or pain while doing the exercises, it's recommended to stop and consult with a healthcare professional. Additionally, if you have any underlying medical conditions or concerns about your facial health, it's always best to seek guidance from a qualified healthcare provider.`,
    open: false 
  },
  { 
    question: `Can facial slimming exercises be done by anyone?`, 
    answer: `Facial slimming exercises can be done by most people, regardless of age or gender. However, it's important to consider any specific health conditions or concerns you may have. If you have any underlying medical conditions, it's advisable to consult with a healthcare professional before starting any new exercise regimen, including facial exercises. They can provide personalized guidance based on your individual needs and circumstances.`,
    open: false 
  },
  { 
    question: `Can I see results from facial slimming exercises without changing my diet or lifestyle?`, 
    answer: `While facial slimming exercises can help enhance your facial features, the best results are often achieved when combined with a healthy lifestyle. Maintaining a balanced diet and regular exercise routine can contribute to overall weight loss and improved muscle tone, which can further enhance the results of facial exercises. Adopting healthy habits can also promote a more radiant complexion and boost your overall well-being.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>