export const recommendation = {
  namespaced: true,
  state: {
    data: [],
    loading: true,
  },
  getters: {},
  actions: {
    getData({ commit }, data) {
      commit("getData", data);
    },
    getLoading({ commit }, data) {
      commit("getLoading", data);
    },
  },
  mutations: {
    getData(state, data) {
      state.data = data;
    },
    getLoading(state, data) {
      state.loading = data;
    },
  },
};
