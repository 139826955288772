<template>
  <div>
    <UserAppbar />
    <div style="overflow: auto">
      <div
        :class="`card-container ${isPlayerAvailable ? 'with-player' : ''} p-2`"
      >
        <Category />
        <RecentPlayed class="pt-4" :isFilter="false" />
        <Recommendation class="pt-4" :isFilter="false" />
        <Discover class="pt-4" :isFilter="false" />
        <Featured class="pt-4" :isFilter="false" />
      </div>
    </div>
  </div>
</template>

<script>
import UserAppbar from "@/components/User/UserAppbar.vue";
import Category from "@/views/User/Home/Category.vue";
import RecentPlayed from "@/views/User/Home/RecentPlayed.vue";
import Recommendation from "@/views/User/Home/Recommendation.vue";
import Featured from "@/views/User/Home/Featured.vue";
import Discover from "@/views/User/Home/Discover.vue";

import { mapGetters } from "vuex";
import { audio } from "@/mixins/audio";
export default {
  mixins: [audio],
  components: {
    UserAppbar,
    Category,
    Recommendation,
    Discover,
    RecentPlayed,
    Featured,
  },
  computed: {
    audios() {
      return this.$store.state.audio.datas;
    },
    playlistTrack() {
      return this.$store.state.audio.playlist.tracks;
    },
    recentplayed() {
      return this.$store.state.recent.played.data;
    },
    isPlayerAvailable() {
      return this.playlistTracks.length > 0
        ? this.playlistTracks.length > 0
        : this.recentplayed.length > 0;
    },
  },
};
</script>

<style scoped>
.card-container {
  overflow-x: hidden;
  width: calc(100vw - 375px);
  height: calc(100vh - 80px);
}

.card-container.with-player {
  overflow-x: hidden;
  height: calc(100vh - 170px);
}

@media screen and (max-width: 900px) {
  .card-container {
    width: calc(100vw - 48px);
    height: calc(100vh - 160px);
  }

  .card-container.with-player {
    overflow-x: hidden;
    height: calc(100vh - 235px);
  }
}

@media screen and (max-width: 500px) {
  .card-container {
    width: 100vw;
  }

  .card-container.with-player {
    overflow-x: hidden;
    height: calc(100vh - 250px);
  }
}
</style>
