<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Living in fear can hold you back from living your life to the fullest. With this subliminal, you can break free from fear and embrace a life filled with courage and confidence. It is designed to help you overcome your fears and live authentically."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help you:'
    },
    {
      type: 'list',
      items: [
        "Identify and confront your fears, allowing you to take control of your life.",
        "Build resilience and develop the courage to face challenges and obstacles.",
        "Boost self-confidence and self-belief, empowering you to pursue your dreams.",
        "Reduce anxiety and stress related to fear, improving your overall well-being.",
        "Create a positive mindset and shift your perspective to embrace new opportunities."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can rewire your mind to let go of fear and live a more fulfilling life. As you gain confidence and face your fears head-on, you will experience personal growth and a sense of empowerment. Remember, living fearlessly is a journey, and this subliminal can support you every step of the way."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Living a fearless life requires courage, strength, and the ability to conquer your fears. This subliminal is designed to help you overcome your fears and live authentically with confidence. Before you start listening, here are some preparation tips to keep in mind:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Identify your fears: Take a moment to reflect on the fears that are holding you back from living authentically and confidently.",
        "Challenge your fears: Acknowledge that fear is a natural part of life, but it doesn't have to control your actions and decisions.",
        "Set your intention: Visualize yourself living fearlessly and confidently, focusing on the person you want to become.",
        "Embrace discomfort: Know that growth often comes from stepping out of your comfort zone, and be open to taking small steps towards facing your fears."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal and practicing these preparation tips, you can start breaking free from fear and living a life filled with courage and confidence. Remember, the journey to fearless living is a process, so be patient and kind to yourself. Embrace this opportunity to overcome your fears and embrace a life of authenticity and empowerment."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Fearless Living - Stop Living in Fear subliminal session! By taking this step to overcome your fears and live authentically, you have shown immense courage and determination. It's time to reflect on your journey and continue your path to a life filled with courage and confidence."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your progress: Take note of the fears you have already overcome and celebrate your achievements.",
        "Challenge yourself: Continue facing your fears and stepping outside of your comfort zone.",
        "Surround yourself with support: Seek encouragement from friends, family, or support groups who understand your journey.",
        "Practice self-care: Nurture yourself mentally, emotionally, and physically to build resilience and self-confidence."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, breaking free from fear is a process that takes time and effort. Embrace the challenges and continue pushing yourself towards living authentically. With each step you take, you are rewriting your story and creating a life filled with courage and confidence. Keep moving forward with fearless determination!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Fearless Living subliminal help me overcome my fears?`, 
    answer: `The Fearless Living subliminal is designed to reprogram your subconscious mind, helping you break free from fear and live a life filled with courage and confidence. By listening to the subliminal regularly, you can start to shift your perspective, challenge limiting beliefs, and build the mental resilience necessary to face your fears head-on. Over time, you may notice a reduction in fear-based thoughts and behaviors, allowing you to live more authentically.`,
    open: false
  },
  { 
    question: `How long do I need to listen to the Fearless Living subliminal before seeing results?`, 
    answer: `Results may vary from person to person. Some individuals may notice positive changes in their mindset and behavior right away, while others may require more time and consistent listening to fully overcome their fears. It is recommended to listen to the subliminal daily and continuously for best results. Remember that personal growth is a journey, and progress can take time.`,
    open: false 
  },
  { 
    question: `Can the Fearless Living subliminal replace therapy or professional help for overcoming fear?`, 
    answer: `The Fearless Living subliminal can be a helpful tool in complementing therapy or professional help for overcoming fear, but it is not a substitute. It is always recommended to seek appropriate support from qualified professionals when dealing with deep-rooted fears or traumatic experiences. The subliminal can work alongside therapy to enhance your progress and empower you in your journey towards fearless living.`,
    open: false 
  },
  { 
    question: `How can I make the Fearless Living subliminal more effective?`, 
    answer: `To maximize the effectiveness of the Fearless Living subliminal, it is beneficial to create a supportive environment that encourages personal growth. Surround yourself with positive influences, practice self-care, and engage in activities that challenge your comfort zone. Additionally, setting specific goals and reflecting on your progress can help you stay motivated and aligned with your desired outcome.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Fearless Living subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Fearless Living subliminal. However, if you have any concerns about your mental health or specific fears, it is always advisable to consult with a mental health professional for personalized guidance and support.`,
    open: false 
  },
  { 
    question: `Can the Fearless Living subliminal help with all types of fears?`, 
    answer: `The Fearless Living subliminal is designed to help individuals overcome various types of fears. Whether it's fear of failure, fear of rejection, or fear of the unknown, the subliminal messages aim to rewire your subconscious mind and instill a greater sense of courage and confidence. However, for deep-rooted fears or phobias, it may be beneficial to seek additional support from a qualified professional.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>