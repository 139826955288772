<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're struggling with a fear of open spaces or agoraphobia, know that you're not alone. This subliminal is designed to help you overcome those fears and experience freedom and confidence in any environment."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support you:'
    },
    {
      type: 'list',
      items: [
        "Reducing feelings of anxiety and panic when in open spaces or unfamiliar surroundings.",
        "Gradually desensitizing you to open spaces and crowded places, making them less intimidating.",
        "Building a sense of safety and security within yourself, helping you feel more at ease when outside.",
        "Increasing your confidence and belief in your ability to handle any situation or environment.",
        "Providing you with empowering affirmations that reprogram your mind to embrace open spaces and new experiences."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal consistently, you can rewire your subconscious mind, allowing you to face your fears and live a more fulfilling life. Over time, you may notice a reduction in anxiety symptoms, increased comfort in open spaces, and a newfound sense of confidence. Remember, progress may take time, but with patience and dedication, you can overcome your fears and embrace the world around you."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Facing and overcoming the fear of open spaces and agoraphobia can be challenging, but this subliminal is here to support you on your journey towards freedom and confidence. Before you start listening, consider the following strategies to help enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Clarify your goal of overcoming your fear and experiencing freedom in any environment.",
        "Educate yourself: Learn about the nature of your fear and agoraphobia, which can help build understanding and reduce anxiety.",
        "Practice relaxation techniques: Engage in deep breathing, mindfulness, or meditation before listening to help calm your mind and body.",
        "Imagine success: Visualize yourself confidently navigating open spaces and challenging situations, feeling calm and in control.",
        "Hold positive thoughts: Maintain a positive mindset throughout your listening session, focusing on the potential for growth and change."
      ]
    },
    {
      type: 'paragraph',
      text: "As you embark on this transformative journey, remember that this subliminal is designed to rewire your subconscious beliefs and help you overcome your fears. By listening regularly and embracing the process, you may start to experience increased freedom, confidence, and a sense of empowerment in any environment. Embrace this opportunity for personal growth and let's begin the path to a fear-free life."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Fear of Open Spaces and Agoraphobia Removal subliminal session! By taking this step towards overcoming your fear and experiencing freedom and confidence in any environment, you've shown incredible strength and courage."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Celebrate your progress: Take a moment to acknowledge the steps you've taken towards overcoming your fear.",
        "Face your fears gradually: Start by taking small steps outside your comfort zone to build confidence.",
        "Practice relaxation techniques: Incorporate deep breathing exercises or mindfulness to manage anxiety.",
        "Seek support: Consider talking to a mental health professional or joining a support group to help you on your journey."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming fears takes time and effort. Be patient with yourself, believe in your ability to conquer your fear, and take small steps towards progress. You deserve to experience freedom and confidence in any environment. Keep pushing forward!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What is agoraphobia?`, 
    answer: `Agoraphobia is an anxiety disorder characterized by a fear or anxiety about being in situations where escape might be difficult or embarrassing or where help might not be available in case of a panic attack or other distressing symptoms. It often involves a fear of open spaces, crowded places, or being outside of the home.`,
    open: false
  },
  { 
    question: `How can the Fear of Open Spaces and Agoraphobia Removal subliminal help me?`, 
    answer: `The Fear of Open Spaces and Agoraphobia Removal subliminal is designed to target and reprogram the subconscious mind to release fear, reduce anxiety, and promote feelings of safety and calmness in open spaces. By listening to the subliminal regularly, you can help break free from the limitations of agoraphobia and regain confidence in any environment.`,
    open: false 
  },
  { 
    question: `How long does it take to see results from the Fear of Open Spaces and Agoraphobia Removal subliminal?`, 
    answer: `Results may vary from person to person. Some individuals may experience positive changes in their fear and anxiety levels after a few listening sessions, while others may require more consistent and prolonged use. It is important to listen to the subliminal regularly and allow it sufficient time to reprogram your subconscious mind.`,
    open: false 
  },
  { 
    question: `Can the Fear of Open Spaces and Agoraphobia Removal subliminal be used as a standalone treatment for agoraphobia?`, 
    answer: `The Fear of Open Spaces and Agoraphobia Removal subliminal can be a beneficial tool for managing and addressing agoraphobia. However, it is not intended to replace professional therapy or medical treatment. For severe cases of agoraphobia, it is recommended to seek help from a qualified healthcare professional who can provide a comprehensive treatment plan tailored to your specific needs.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Fear of Open Spaces and Agoraphobia Removal subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Fear of Open Spaces and Agoraphobia Removal subliminal. However, if you have any concerns about your mental health or are under the care of a healthcare professional, it is always advisable to consult with them before incorporating any new therapeutic techniques.`,
    open: false 
  },
  { 
    question: `Can the Fear of Open Spaces and Agoraphobia Removal subliminal be used as a preventative measure for developing agoraphobia?`, 
    answer: `The Fear of Open Spaces and Agoraphobia Removal subliminal can help individuals who experience mild anxiety or unease in open spaces, but it is not specifically designed as a preventative measure for developing agoraphobia. If you have concerns about your mental health or are at a high risk of developing agoraphobia, it may be beneficial to seek guidance from a mental health professional.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>