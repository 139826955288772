<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Effective time management and enhanced productivity are vital for achieving your goals efficiently. This subliminal is designed to help you develop the skills and mindset needed to make the most of your time and increase your productivity."
    },
    {
      type: 'heading',
      text: 'Key features and benefits of this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Improving your ability to prioritize tasks and focus on what's important.",
        "Enhancing your organizational skills to streamline your workflow.",
        "Boosting your motivation and discipline to overcome procrastination.",
        "Reducing distractions and improving your ability to stay focused.",
        "Increasing your efficiency in completing tasks and meeting deadlines."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can rewire your subconscious mind to adopt effective time management strategies and optimize your productivity. As a result, you'll be able to accomplish more in less time, achieve your goals with greater ease, and create a better work-life balance. Remember, consistent use is key to maximizing the benefits of this subliminal."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Enhancing your time management and productivity skills can have a significant impact on achieving your goals efficiently. This subliminal is designed to support you on this journey. Before you start listening, consider these tips to maximize your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your goals: Identify the specific tasks and objectives you want to accomplish by improving your time management and productivity.",
        "Plan your day: Create a realistic schedule or to-do list to help you prioritize tasks and allocate time effectively.",
        "Eliminate distractions: Minimize interruptions and distractions during your dedicated work or focused time.",
        "Adopt positive affirmations: Repeat empowering statements that reinforce your ability to manage your time and be productive.",
        "Maintain a growth mindset: Embrace challenges and setbacks as opportunities to learn and improve your time management skills."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind to support your time management and productivity goals. Stay committed, practice the recommended strategies, and keep a positive mindset. Together, let's unlock your full potential and boost your efficiency in achieving your goals."
    }
  ]
},
            {
  id: 'after-listening',
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Time Management and Productivity subliminal session! By investing in improving your time management skills, you have set yourself on a path towards enhancing your productivity and achieving your goals more efficiently. It's time to reflect on your experience and integrate what you've learned into your daily life."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your time usage: Evaluate how you've been spending your time and identify areas for improvement.",
        "Set priorities: Determine your most important tasks and focus on completing them first.",
        "Break tasks into smaller steps: Divide larger tasks into manageable chunks to make them less overwhelming.",
        "Utilize effective tools: Explore productivity apps, planners, or time management techniques that align with your needs and preferences."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, mastering time management is an ongoing process, and it's completely normal to face challenges along the way. Stay committed to implementing new strategies and techniques to enhance your productivity. With consistent effort and a focused mindset, you'll soon reap the rewards of better time management and achieve your goals more efficiently. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Time Management and Productivity subliminal help me?`, 
    answer: `The Time Management and Productivity subliminal is designed to influence your subconscious mind to adopt effective time management strategies and increase your productivity. By listening to the subliminal messages, you can enhance your focus, motivation, and discipline to efficiently manage your time and achieve your goals. Over time, you may notice improvements in your ability to prioritize tasks, avoid procrastination, and work more efficiently.`,
    open: false
  },
  { 
    question: `How long does it take to see improvements in time management and productivity?`, 
    answer: `The time it takes to see improvements in time management and productivity can vary from person to person. Some individuals may notice positive changes within a few days of consistently listening to the subliminal, while others may require several weeks. Consistency is key, and integrating the subliminal with practical time management techniques can accelerate the results.`,
    open: false 
  },
  { 
    question: `Can the Time Management and Productivity subliminal replace time management techniques and strategies?`, 
    answer: `The Time Management and Productivity subliminal is designed to complement and reinforce your time management techniques and strategies. While it can positively influence your mindset and increase your motivation and focus, it is important to also implement practical time management techniques such as creating schedules, setting goals, and utilizing productivity tools. Combining the subliminal with effective strategies can enhance your overall productivity and time management skills.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Time Management and Productivity subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Time Management and Productivity subliminal. However, if you have any concerns about your productivity or mental well-being, please consult with a professional. It is important to note that the subliminal is intended to supplement and support your productivity efforts, not replace the need for actionable steps and strategies.`,
    open: false 
  },
  { 
    question: `Can the Time Management and Productivity subliminal help with procrastination?`, 
    answer: `Yes, the Time Management and Productivity subliminal can help you overcome procrastination by influencing your subconscious mind to adopt a more focused and disciplined mindset. It can increase your motivation, inner drive, and ability to prioritize tasks, making it easier to overcome procrastination and work more efficiently.`,
    open: false 
  },
  { 
    question: `Is the Time Management and Productivity subliminal useful for students or professionals?`, 
    answer: `Yes, the Time Management and Productivity subliminal can be beneficial for students and professionals alike. It can help students enhance their study habits, manage their time effectively, and meet deadlines. For professionals, it can improve productivity, time management, and overall job performance. Regardless of your specific circumstances, the subliminal can be a valuable tool to optimize your time management skills and achieve success.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>