<template>
  <Dialog
    :dialog="dialog"
    :width="1000"
    :title="`Add Track To Subliminal`"
    @closeDialog="closeDialog"
  >
    <Table
      itemkey="track_id"
      :add="false"
      :delete="false"
      :addItem="true"
      :loading="loading"
      :items="items"
      :headers="headers"
      :select="selected"
      :total="total"
      :addVisible="true"
      @action="getAction"
      @updateLimitPage="updateLimitPage"
      @updateSort="updateSort" 
      @updateSearch="updateSearch"
      @updateVisible="updateVisible"
    />
  </Dialog>
</template>

<script>
import {
  updateTrackToSubliminals,
  deleteTrackToSubliminals,
} from "@/api/subliminalinfo";
import { getTrackFilters } from "@/api/track";

import Dialog from "@/components/Dialog.vue";
import Table from "@/components/Admin/Table.vue";
export default {
  props: {
    data: {},
    dialog: {
      type: Boolean,
    },
  },
  components: {
    Table,
    Dialog,
  },
  data: () => {
    return {
      getTrackFilters,
      updateTrackToSubliminals,
      deleteTrackToSubliminals,

      loading: true,

      items: [],
      selected: [],
      old_selected: [],

      headers: [
        { text: "Title", value: "title", sortable: false },
        { text: "Version", value: "selectversion", sortable: false },
        { text: "Audio Type", value: "selectaudiotype", sortable: false },
        { text: "Volumes", value: "volumes", sortable: false },
      ],

      sort: null,
      search: null,
      visible: null,
      limit: 10,
      page: 1,
      total: 0,
    };
  },
  created() {
    this.getData();
  },
  computed: {
    audiotypes() {
      return this.$store.state.audio.types;
    },
    subliminalid() {
      return this.$store.state.audio.subliminal.id;
    },
  },
  watch: {
    sort() {
      this.getData();
    },
    visible() {
      this.getData();
    },
    search() {
      this.getData();
    },
  },
  methods: {
    updateSearch(data) {
      this.search = data
      this.page = 1
      this.getData()
    },
    updateVisible(data) {
      this.visible = data;
      this.page = 1
      this.getData();
    },
    updateSort(data) {
      this.sort = data
      this.getData()
    },
    getData() {
      const data = {
        limit: this.limit,
        page: this.page,
        search: this.search,
        sort: this.sort,
        visible: this.visible,

      };

      this.getTrackFilters(data).then((res) => {
        const { tracks, total } = res

        this.total = total
        this.items = tracks

        this.selected = this.data.tracks
        this.old_selected = this.data.tracks

        for (let index = 0; index < this.items.length; index++) {
          const element = this.items[index];
          const item = this.data.tracks.filter(item => {
            return element.track_id == item.track_id
          })
          if (item.length > 0) {
            element.audio_type_id = item[0].audio_type_id;
            element.version = item[0].version;
          }
        }
        this.loading = false
      })
    },
    getAction(params) {
      this.selected = params.selected;
      
      if (this.old_selected!= undefined && this.old_selected.length > this.selected.length) {
        let data = this.old_selected.filter((object1) => {
          return !this.selected.some((object2) => {
            return object1.track_id === object2.track_id;
          });
        });

        for (let index = 0; index < data.length; index++) {
          let element = data[index];

          element.subliminal_id = this.subliminalid;
          element.track_id = element.track_id;

          this.deleteTrackToSubliminals(element);
        }
      }
      
      for (let index = 0; index < this.selected.length; index++) {
        let element = this.selected[index];

        element.subliminal_id = this.subliminalid;
        element.volume = this.selected[index].volume;

        if (element.hasOwnProperty("tracks")) {
          element.version = element.tracks.filter(
            (el) => el.track_id == element.track_id
          )[0].version;
        } else {
          element.version = element.version;
        }

        this.updateTrackToSubliminals(element).then((res) => {
          this.closeDialog();
        });
      }
    },
    closeDialog() {
      this.$emit("closeDialog", false);
    },
    updateLimitPage(data) {
      //this.loading = true;
      const { page, limit } = data;
      this.page = page;
      this.limit = limit;

      this.getData();
    },
  },
};
</script>
