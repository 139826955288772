import request from "@/utils/request";

export const getSubliminals = (data) => {
  return request.get("/api/subliminal").then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const getSubliminalFilters = (data) => {
  return request.post("/api/subliminal/filter", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const addSubliminals = (data) => {
  return request.post("/api/subliminal", data).then((res) => {
    return res.data;
  });
};

export const updateToggle = (data) => {
  return request.post("/api/subliminal/toggle", data).then((res) => {
    return res.data;
  });
};

export const updateSubliminals = (data) => {
  return request
    .put(`/api/subliminal/${data.subliminal_id}`, data)
    .then((res) => {
      return res.data;
    });
};

export const deleteSubliminals = (data) => {
  return request.delete(`/api/subliminal/${data.subliminal_id}`).then((res) => {
    return res.data;
  });
};
