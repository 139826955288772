<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Antioxidant treatment is a powerful tool for boosting your health and vitality. By combating harmful free radicals in the body, antioxidants promote overall well-being and help protect against various diseases."
    },
    {
      type: 'heading',
      text: 'Here are some key benefits of incorporating antioxidant treatment into your routine:'
    },
    {
      type: 'list',
      items: [
        "Neutralizing free radicals, which can damage cells and contribute to aging and disease.",
        "Supporting a healthy immune system, enabling your body to better fight off infections and illnesses.",
        "Promoting cardiovascular health by protecting against oxidative stress and inflammation.",
        "Enhancing skin health, reducing signs of aging and boosting a youthful appearance.",
        "Improving brain function and supporting cognitive health, potentially reducing the risk of neurodegenerative diseases."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating antioxidant-rich foods, supplements, or treatments into your routine, you can harness the power of these mighty compounds. Prioritizing a diet and lifestyle that includes antioxidants can have profound effects on your health and vitality. Remember, consistency and moderation are key for optimal results."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on an antioxidant treatment journey is a great way to enhance your health and well-being. This subliminal is designed to support your efforts in combating harmful free radicals and promoting overall vitality. Use these tips to prepare yourself before listening:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Educate yourself: Learn about the benefits of antioxidants and how they can improve your overall well-being.",
        "Assess your diet: Take a moment to evaluate your current eating habits and identify areas where you can incorporate more antioxidant-rich foods.",
        "Stay hydrated: Drinking adequate amounts of water can help flush out toxins and support the effectiveness of antioxidants.",
        "Set an intention: Before listening, focus on your desire to boost your health and vitality through antioxidant treatment."
      ]
    },
    {
      type: 'paragraph',
      text: "As you engage with this subliminal regularly, you may start to notice positive changes in your energy levels, immune function, and overall wellness. By embracing this journey, you are taking a proactive step towards optimizing your health. Get ready to tap into the power of antioxidants and enhance your well-being!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Antioxidant Treatment session! By focusing on boosting your health and vitality through antioxidant treatment, you've taken an important step towards enhancing your overall well-being. Now, take a moment to reflect on your experience and continue your journey towards improved health."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Incorporate antioxidant-rich foods into your diet: Include fruits, vegetables, nuts, seeds, and whole grains in your meals.",
        "Stay hydrated: Drink plenty of water throughout the day to support your body's natural detoxification processes.",
        "Protect your skin: Use sunscreen and limit exposure to harmful UV rays to prevent oxidative damage.",
        "Consider supplements: Talk to a healthcare professional about incorporating antioxidant supplements into your routine."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, nurturing your body with antioxidants is an ongoing process. By making these tips a part of your daily routine, you are supporting your overall health and vitality. Keep up the good work, and embrace the benefits that come with promoting your well-being. You deserve it!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What are antioxidants?`, 
    answer: `Antioxidants are compounds that help protect your cells from damage caused by free radicals. Free radicals are unstable molecules that can damage your cells and contribute to various health issues. Antioxidants neutralize free radicals and help reduce oxidative stress, promoting overall health and well-being.`,
    open: false
  },
  { 
    question: `How can antioxidant treatment benefit me?`, 
    answer: `Antioxidant treatment can provide numerous benefits, including reducing the risk of chronic diseases, supporting a healthy immune system, promoting skin health, improving heart health, and enhancing overall vitality. By neutralizing free radicals, antioxidants help protect your cells from damage and support optimal functioning of your body.`,
    open: false 
  },
  { 
    question: `How can I incorporate antioxidants into my daily routine?`, 
    answer: `You can incorporate antioxidants into your daily routine by consuming a diet rich in fruits, vegetables, nuts, and whole grains, which are all natural sources of antioxidants. Additionally, you can consider taking antioxidant supplements after consulting with a healthcare professional. It's important to maintain a balanced diet and practice healthy lifestyle habits alongside antioxidant treatment for optimal results.`,
    open: false 
  },
  { 
    question: `Are there any side effects of antioxidant treatment?`, 
    answer: `In general, antioxidant treatment using natural sources, such as fruits and vegetables, is safe and well-tolerated. However, taking high doses of antioxidant supplements may have potential risks and side effects. It's always best to consult with a healthcare professional before starting any new supplement regimen.`,
    open: false 
  },
  { 
    question: `Can antioxidant treatment reverse the effects of aging?`, 
    answer: `While antioxidant treatment can help protect against cellular damage and promote overall health, it cannot reverse the natural aging process. However, antioxidants can contribute to maintaining healthy-looking skin, enhancing vitality, and supporting overall well-being as you age.`,
    open: false 
  },
  { 
    question: `Can antioxidant treatment prevent all diseases?`, 
    answer: `While antioxidant treatment can provide numerous health benefits and reduce the risk of certain diseases, it cannot guarantee complete prevention of all diseases. Adopting a well-rounded healthy lifestyle, including a balanced diet, regular exercise, and good sleep hygiene, is crucial for overall health and disease prevention.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>