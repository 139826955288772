<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Your mind has a powerful influence on your overall well-being, and this subliminal is designed to help you harness that power to attract optimal health into your life. By using positive affirmations and reprogramming your subconscious mind, you can create a mindset that supports your journey towards a healthier and more vibrant life."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help:"
    },
    {
      type: 'list',
      items: [
        "Increase your belief in your body's ability to heal and maintain good health.",
        "Empower your mind to align with healthy habits and choices effortlessly.",
        "Boost your motivation and commitment to prioritize your well-being.",
        "Enhance your body's natural healing processes and immune system.",
        "Radiate positive energy and attract opportunities for well-being into your life."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can transform your thoughts and beliefs about your health. As your subconscious mind absorbs and integrates these positive affirmations, you may find yourself naturally drawn to behaviors and choices that support your well-being. Remember, true health goes beyond the physical - it encompasses your mental, emotional, and spiritual aspects as well."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Attracting optimal health and well-being starts with a mindset shift, and this subliminal is designed to support you on that journey. Before you start listening, consider the following tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your health goals: Identify what optimal health means to you and set clear intentions for the changes you want to attract into your life.",
        "Believe in the power of affirmations: Trust that by regularly listening to positive affirmations, you can rewire your mind and manifest positive changes in your health.",
        "Adopt a gratitude mindset: Cultivate a grateful attitude for your current state of health while focusing on attracting even greater levels of well-being.",
        "Open yourself to receive: Approach each listening session with an open mind and heart, ready to receive the transformative power of the subliminal messages."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your daily routine, you can tap into the potential of your mind and harness the power of positive thinking to attract optimal health. Embrace this opportunity to align your thoughts with your desires, and let the journey towards greater well-being begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Attract Health subliminal session! By dedicating time to enhance your well-being and attract optimal health, you've taken an empowering step towards creating a positive change in your life. Now, it's important to reflect on your experience and continue your journey towards optimal health."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear health goals: Define specific and realistic goals to work towards.",
        "Practice self-care: Make time for activities that promote relaxation and self-care.",
        "Nurture a positive mindset: Use positive affirmations to reinforce your belief in optimal health.",
        "Stay consistent: Incorporate healthy habits into your daily routine and make them a priority."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, attracting optimal health is a holistic journey that requires dedication and self-care. Embrace the power of your mind and positive affirmations, and focus on nurturing a positive mindset. By staying consistent and committed to your health goals, you have the ability to create positive change and attract optimal health into your life. You've got this!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Attract Health subliminal work?`, 
    answer: `The Attract Health subliminal works by using positive affirmations that are embedded within relaxing music or sounds. These affirmations are designed to target your subconscious mind and help you develop a positive mindset towards health and well-being. By consistently listening to the subliminal, you can reprogram your thoughts and beliefs, attracting optimal health and well-being into your life.`,
    open: false
  },
  { 
    question: `How long should I listen to the Attract Health subliminal to see results?`, 
    answer: `The time it takes to see results from the Attract Health subliminal can vary from person to person. Some people may notice positive changes in their well-being and health relatively quickly, while others may require more time and consistent listening. We recommend listening to the subliminal daily and consistently for at least a few weeks to allow the affirmations to fully integrate into your subconscious mind.`,
    open: false 
  },
  { 
    question: `Can the Attract Health subliminal cure specific health conditions or diseases?`, 
    answer: `No, the Attract Health subliminal is not intended as a replacement for professional medical treatment or as a cure for specific health conditions or diseases. It is designed to support your overall well-being and help you develop a positive mindset towards health. If you have any specific health concerns, it is important to consult with a healthcare professional.`,
    open: false 
  },
  { 
    question: `Can the Attract Health subliminal enhance the effectiveness of other health-related practices?`, 
    answer: `Yes, the Attract Health subliminal can be a powerful tool to complement other health-related practices. By aligning your subconscious mind with positive affirmations about health and well-being, you can enhance the effectiveness of various health practices, such as healthy eating habits, regular exercise, and adequate sleep. It can help motivate and inspire you to make positive choices that contribute to your overall well-being.`,
    open: false 
  },
  { 
    question: `Is it safe to listen to the Attract Health subliminal while doing other activities?`, 
    answer: `Yes, it is safe to listen to the Attract Health subliminal while engaging in other activities, such as reading, working, or relaxing. The subliminal messages work on a subconscious level and do not require your full attention to be effective. However, you may find it more beneficial to create a focused and calm environment where you can fully absorb and internalize the positive affirmations.`,
    open: false 
  },
  { 
    question: `Can the Attract Health subliminal have negative side effects?`, 
    answer: `No, there are no known negative side effects associated with listening to the Attract Health subliminal. However, if you have any concerns about your well-being or health, it is important to consult with a healthcare professional. The subliminal is intended to support your overall well-being and should not replace any necessary medical care.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>