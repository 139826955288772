<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Astral projection, also known as astral travel, is the ability to separate your consciousness from your physical body and explore realms beyond the physical world. This subliminal is designed to help you develop and enhance your astral projection abilities."
    },
    {
      type: 'heading',
      text: 'What you can expect from this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Learning techniques to induce astral projection and travel beyond the physical body.",
        "Gaining a deeper understanding of the astral realm and its inhabitants.",
        "Enhancing your ability to recall and interpret astral experiences.",
        "Exploring different dimensions and realms beyond the physical world.",
        "Developing greater control and navigation skills in the astral plane."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can awaken your innate astral projection abilities and deepen your spiritual journey. As you become more acquainted with the astral realm, you may gain profound insights, spiritual growth, and a greater sense of interconnectedness. Remember, patience and dedication to practice are essential on this path of exploration and self-discovery."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on the journey of astral projection can be an exciting and transformative experience. This subliminal is designed to support you in your exploration of different dimensions and realms beyond the physical world. Here are some tips to consider before you start:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Mental relaxation: Practice deep breathing exercises and meditation to relax your mind and body.",
        "Create a sacred space: Set up a calm and comfortable environment where you can focus without distractions.",
        "Set your intention: Clarify your goals and what you hope to experience during astral projection.",
        "Learn visualization techniques: Enhance your ability to visualize by practicing visualization exercises.",
        "Research and educate yourself: Gain knowledge about astral projection techniques, safety precautions, and experiences to feel more prepared."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, it can help you connect with the astral realm and deepen your understanding of the unseen dimensions. Embrace the wonder and possibility of astral projection, and allow yourself to fully immerse in the experience. Now, take a moment to prepare yourself mentally and physically, and let the journey of astral projection begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Astral Projection and Travel session! You've embarked on a journey to explore the fascinating realm of astral projection and experience dimensions beyond the physical world. Reflecting on your experience and integrating what you've learned is crucial for your continued growth in this practice."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Journal your experiences: Write down your astral travel experiences, insights, and any other observations you had during your session.",
        "Practice mindfulness: Incorporate regular mindfulness exercises to enhance your focus and awareness in and out of your astral experiences.",
        "Engage in visualization exercises: Strengthen your ability to visualize by practicing visualization exercises regularly.",
        "Research and study: Continue to read and educate yourself on astral projection to deepen your understanding and expand your knowledge."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, astral projection is a spiritual practice that requires dedication and discipline. Be patient with yourself as you continue to explore and grow in this realm. Stay open-minded and embrace the limitless possibilities that astral travel offers. Safe journeys!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What is astral projection?`, 
    answer: `Astral projection, also known as astral travel, is the practice of intentionally separating your consciousness or soul from your physical body and traveling to different dimensions or realms beyond the physical world. During astral projection, you may experience lucid dreaming, out-of-body experiences, or explore different planes of existence.`,
    open: false
  },
  { 
    question: `How can I learn astral projection?`, 
    answer: `Learning astral projection requires practice, patience, and a deep understanding of the astral realm. It is essential to develop meditation skills, focus, and relaxation techniques to quiet the mind and achieve a state conducive to astral projection. Various methods, such as visualization, affirmations, and guided meditations, can assist in the process of learning astral projection.`,
    open: false 
  },
  { 
    question: `Is astral projection dangerous?`, 
    answer: `Astral projection is generally considered safe, especially when practiced responsibly and with proper guidance. However, it is essential to approach astral projection with respect and caution. It is advisable to learn from experienced practitioners, follow ethical guidelines, and ensure you are in a safe and protective environment before attempting astral projection.`,
    open: false 
  },
  { 
    question: `What can I expect during astral projection?`, 
    answer: `During astral projection, you may experience a variety of sensations and perceptions. Some people report feelings of weightlessness, floating sensations, or seeing vibrant colors and vivid imagery. Your experiences may vary, but many individuals describe a sense of freedom, expanded consciousness, and a deep connection to the spiritual realm during astral projection.`,
    open: false 
  },
  { 
    question: `Can anyone astral project?`, 
    answer: `Astral projection is considered a natural ability that everyone possesses to some degree. However, not everyone will have the same level of proficiency or frequency of experiences. Like any skill, astral projection requires dedication and practice to enhance and develop. It is a personal journey that varies from individual to individual.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of astral projection?`, 
    answer: `Astral projection is generally considered safe and free of significant risks or side effects. However, it is important to approach the practice responsibly and listen to your intuition. If you feel uncomfortable or encounter negative energies during astral projection, it is advisable to return to your physical body and discontinue the experience. Additionally, some individuals may experience fatigue or disorientation during or after astral projection, which can be alleviated by taking proper care of your physical and energetic well-being.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>