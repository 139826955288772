<template>
  <section>
    <div class="container">
      <div>
        <h1>
          HOW DOES MAGUS HELP YOUR BUSINESS?
        </h1>
        <h5 class="mb-2">
          Employee Benefits + maximized ROI
        </h5>
        <h5>
          Your employees are your greatest assets. Offering your team mental health and self-development benefits makes them feel valued and rewarded.Getting Magus for your team allows your business and your employees to run at peak performance.
        </h5>
      </div>
      <div class="col-10 col-lg-8 mx-auto">
        <div class="row my-4 ">
          <template v-for="item, index in menus">
            <div class="product-box-left" v-if="item.id % 2 == 0">
              <div>
                <h6>{{ item.title }}</h6>
                <p v-html="item.details"></p>
              </div>
              <div>
                <img :src="require(`@/assets/images/main/business/3/${item.icon}.png`)" alt="">
              </div>
            </div>
            <div v-else class="product-box-right">
              <div>
                <img :src="require(`@/assets/images/main/business/3/${item.icon}.png`)" alt="">
              </div>
              <div>
                <h6>{{ item.title }}</h6>
                <p v-html="item.details"></p>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div>
        <button class="button btn btn-primary btn-lg" @click="$router.go(-1)">
        {{ showInterfaceButton }}</button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data: () => {
    return {
      show_interface: true,
      menus: [
        { icon: '1',
          id: '1',
          details: "Revolutionize your productivity and take your job performance to new heights with the Magus App. Experience an array of audio tracks specifically formulated to amplify focus and invigorate your work drive. Tweak your playlists to directly improve key competencies such as time management, creativity, or problem-solving.<br><br>What gives Magus its edge? Our pioneering subliminal messaging technology. As you listen, you're subtly influenced by embedded positive affirmations, fortifying a mindset tuned for efficiency and proactivity.<br><br>The intelligent design of Magus adapts to your listening history, offering you a bespoke selection of tracks perfectly suited to your work objectives. With compatibility across all devices, think of it as a productivity mentor that accompanies you wherever you go, helping you excel in your professional life.",
          title: 'Increased Productivity and Work Performance'
         },
        { icon: '2', 
          id: '2',
          details: "Embark on a journey towards mental harmony and emotional stability with the Magus App. Our eclectic range of audio tracks is meticulously designed to serve your mental and emotional well-being, whether you aim to control stress or uplift your spirits. Create playlists that sync with your mental health objectives, facilitating a more balanced you.<br><br>What separates Magus from the crowd? The integration of embedded positive affirmations into our soothing soundscapes. As you listen, your subconscious mind absorbs these affirmations, gradually molding a more optimistic and resilient emotional state.",
          title: 'Improved Mental and Emotional Health'
        },
        { icon: '3', 
          id: '3',
          details: "Transform your daily life into a sanctuary of peace and balance with the Magus App. Our rich library of audio tracks is your one-stop-shop for stress reduction and holistic well-being. Craft playlists that are not just music to your ears, but balm to your soul, helping you de-stress, focus, and rejuvenate.<br><br>What makes Magus indispensable in your stress management toolkit? Our unique fusion of uplifting affirmations discreetly tucked into calming background audio. Your mind naturally soaks up these affirmations, simplifying the journey toward lower stress levels and greater overall well-being.<br><br>And because life doesn't pause, neither does Magus. Accessible on all devices, it's your pocket-sized stress buster and wellness booster whether you're at home, in-transit, or seizing a momentary break at work.",
          title: 'Better Well-Being and Lower Stress Levels'
        },
        { icon: '4', 
          id: '4',
          details: "In today's fast-paced workplaces, happy employees are key. Magus App tailors subliminal audio just for you and your team. Our app boosts happiness, job satisfaction, and reduces staff turnover. Create playlists that match your company's goals using our tech. By investing in your team's happiness, you invest in your company's future. Less stress and more smiles mean a stronger team. Choose Magus App for a thriving workplace.",
          title: 'Better Employee Morale and Staff Retention'
        },
      ]
    }
  },
  computed: {
    showInterfaceButton() {
      return this.show_interface ? "Back" : "Learn More";
    }
  },
}
</script>

<style scoped>
  h1, p,
  h5 {
    line-height: 1.35em;
    color: var(--ma-black-olive);
  }

  h5:nth-of-type(1) {
    color: var(--ma-tufts-blue);
  }

  h5:nth-of-type(2), p {
    font-family: "Montserrat-Regular";
  }

  h6 {
     color: var(--ma-tufts-blue);
  }

  .product-box-left>div>img, .product-box-right>div>img  {
    height: clamp(2.19rem, calc(1.64rem + 9.72vw), 13.75rem);
    margin-block: 1.5em;
  }
  .product-box-left >div, .product-box-right >div {
      float: left;
  }
  .product-box-left>div:nth-of-type(2) {
    width: 22%;
  }
  .product-box-left>div:nth-of-type(1) {
    width: 70%;
    margin-right: 20px;
    margin-bottom: 30px;
    
  }
  .product-box-left {
    padding: 0em;
    justify-content: center;
    text-align: left;
  }
  .product-box-right>div:nth-of-type(2) {
    width: 70%;
    margin-left: 20px;
    margin-bottom: 30px;
  }
  .product-box-right>div:nth-of-type(1) {
    width: 22%;
  }
  .product-box-right {
    padding: 0em;
    justify-content: center;
    text-align: left;
  }

  @media screen and (min-width: 40rem) {
    section {
      text-align: center;
      overflow-y: scroll;
    }

  }
</style>