<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Learning a new language can be challenging, but with the help of this language learning aid, you can improve your Japanese skills and become more confident in speaking, listening, reading, and writing in Japanese."
    },
    {
      type: 'heading',
      text: 'Here are some ways this language learning aid can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Enhance your pronunciation and fluency in speaking Japanese by listening to native speakers and practicing your speaking skills.",
        "Develop your listening comprehension by engaging with audio materials that expose you to different accents, speech patterns, and vocabulary.",
        "Expand your vocabulary and reading skills through various texts, including articles, short stories, and dialogues.",
        "Improve your writing skills through exercises and prompts that encourage you to practice writing in Japanese.",
        "Gain a deeper understanding of Japanese grammar and sentence structure through lessons and examples."
      ]
    },
    {
      type: 'paragraph',
      text: "Whether you're a beginner or already have some knowledge of Japanese, this language learning aid can help you progress and build a solid foundation in the language. Consistent practice and engagement with the materials will contribute to your overall language proficiency. Enjoy your learning journey!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Learning a new language can be challenging, but this language learning aid is here to support your journey in mastering the Japanese language. Before you begin, here are some tips to enhance your learning experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set goals: Determine what aspects of Japanese language learning you want to focus on, whether it's speaking, listening, reading, or writing.",
        "Create a study plan: Schedule dedicated time for studying and practicing the language, ensuring regularity and consistency.",
        "Immerse yourself: Surround yourself with Japanese language resources, such as books, podcasts, music, or movies, to expose yourself to the language and culture.",
        "Stay motivated: Remind yourself of the reasons why you want to learn Japanese and the benefits it will bring to your life."
      ]
    },
    {
      type: 'paragraph',
      text: "With this language learning aid, you'll have the tools and subliminal affirmations to help you build confidence and proficiency in the Japanese language. Embrace the journey of language learning and immerse yourself in the beauty of Japanese culture. Let's begin!"
    }
  ]
},
            {
  id: 'after-listening',
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Nihongo Japanese Language Learning Aid session! Taking the time to improve your Japanese language skills is an incredible achievement. Now that you've invested in your language learning journey, it's essential to reflect on your progress and continue practicing for even greater fluency."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice speaking: Engage in conversations with native speakers or language exchange partners to enhance your spoken Japanese.",
        "Listen to Japanese media: Immerse yourself in Japanese podcasts, music, movies, or TV shows to improve your listening skills.",
        "Read Japanese texts: Challenge yourself with Japanese books, manga, or online articles to strengthen your reading abilities.",
        "Write in Japanese: Regularly practice writing in Japanese, whether it's through keeping a journal, writing essays, or sending messages to Japanese friends."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, language learning takes time and dedication. Each new word you learn, each conversation you have, and each sentence you write brings you closer to fluency. Keep practicing, stay motivated, and celebrate your progress along the way. Ganbatte kudasai! (Good luck!)"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Is this language learning aid suitable for beginners?`, 
    answer: `Yes, this language learning aid is suitable for beginners. It provides helpful resources and exercises to help you build a strong foundation in Japanese. Whether you are starting from scratch or have some basic knowledge of the language, this aid can assist you in improving your skills.`,
    open: false
  },
  { 
    question: `What are the main features of this language learning aid?`, 
    answer: `This language learning aid offers a variety of features to enhance your Japanese learning experience. It provides vocabulary lists, pronunciation guides, grammar explanations, listening exercises, reading materials, and writing practice. The resources are designed to improve your speaking, listening, reading, and writing skills in Japanese.`,
    open: false 
  },
  { 
    question: `Can I use this language learning aid on my own, or do I need a tutor?`, 
    answer: `You can use this language learning aid on your own, as it is designed to be self-guided. The resources provided offer explanations and exercises that you can work through at your own pace. However, if you prefer the guidance of a tutor or teacher, you can also use this aid as a supplementary resource alongside your lessons.`,
    open: false 
  },
  { 
    question: `How long does it take to become fluent in Japanese using this aid?`, 
    answer: `The time it takes to become fluent in Japanese can vary depending on various factors such as prior language learning experience, study habits, and the amount of time dedicated to learning. This aid provides a comprehensive set of resources to assist your learning journey, but success ultimately depends on your dedication and consistent practice.`,
    open: false 
  },
  { 
    question: `Are there any additional resources or materials recommended to supplement this language learning aid?`, 
    answer: `While this language learning aid provides a wide range of resources, it can be beneficial to supplement your studies with additional materials. This can include textbooks, online courses, language exchange programs, or immersive experiences. By combining different resources, you can further enhance your understanding and practice of the Japanese language.`,
    open: false 
  },
  { 
    question: `Can children use this language learning aid?`, 
    answer: `Yes, children can use this language learning aid with the guidance and support of an adult. It can be a useful tool for children who are interested in learning the Japanese language and want to improve their skills. Parents or guardians can assist children in navigating the resources and provide additional explanations if needed.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>