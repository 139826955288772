<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Dealing with emotional pain can be challenging, but with the right tools, you can effectively manage and cope with difficult emotions. This subliminal is designed to help you do just that, by providing techniques and resources to navigate and heal from emotional pain."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support you:'
    },
    {
      type: 'list',
      items: [
        "Learning healthy coping mechanisms to deal with emotional pain.",
        "Building resilience and emotional strength to navigate challenging emotions.",
        "Finding support and understanding in your healing journey.",
        "Gaining perspective and developing a positive mindset.",
        "Discovering self-care practices that promote emotional well-being."
      ]
    },
    {
      type: 'paragraph',
      text: "Through regular listening, this subliminal can help reframe your mindset and empower you to effectively manage and cope with emotional pain. Remember, healing takes time, and it's important to be patient with yourself as you navigate through your emotions. With dedication and self-care, you can find healing and growth."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Dealing with emotional pain can be challenging, but this subliminal is designed to help you manage and heal from difficult emotions. Before you start listening, here are some tips to maximize the effectiveness of this subliminal:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a safe space: Find a quiet and comfortable place where you can relax and be alone with your thoughts and emotions.",
        "Check-in with yourself: Take a moment to acknowledge and identify the emotions you're currently experiencing.",
        "Set an intention: Clarify what you hope to achieve through listening to this subliminal, such as finding inner peace or gaining emotional resilience.",
        "Open yourself to healing: Be open-minded and receptive to the messages and affirmations embedded within this subliminal."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, it can help you develop coping mechanisms and provide support as you navigate and heal from emotional pain. Remember to be patient and compassionate with yourself throughout this process. Trust that the power of your mind and the healing potential within you will guide you towards emotional well-being."
    }
  ]
},
            {
  id: 'after-listening',
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Emotional Pain Management session! By actively working on managing and coping with your emotional pain, you have taken an important step towards healing and personal growth. Now, it's time to reflect on your experience and continue your journey towards emotional well-being."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your emotions: Take some time to process and understand your feelings. Writing in a journal can be helpful.",
        "Practice self-care: Engage in activities that bring you joy and help you relax, such as taking a walk, listening to calming music, or practicing mindfulness.",
        "Seek support: Reach out to trusted friends, family members, or mental health professionals who can provide guidance and support.",
        "Learn healthy coping strategies: Explore techniques like deep breathing exercises, meditation, or talking to a therapist to help navigate difficult emotions.",
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, managing emotional pain is an ongoing process, and everyone's journey is unique. Be gentle with yourself and allow yourself to heal at your own pace. With time, self-reflection, and the support of others, you can learn to effectively manage and cope with your emotions, paving the way for a happier and healthier life."
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Emotional Pain Management techniques help me cope with difficult emotions?`, 
    answer: `The Emotional Pain Management techniques are designed to provide you with tools and strategies to effectively navigate and heal from emotional pain. These techniques may include mindfulness exercises, journaling, talking to a trusted friend or therapist, engaging in self-care activities, and practicing self-compassion. By learning and implementing these techniques, you can develop healthier coping mechanisms and find relief from emotional pain.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Emotional Pain Management techniques?`, 
    answer: `The time it takes to see results from the Emotional Pain Management techniques can vary depending on the individual and the nature of the emotional pain. It may take time and consistent practice to fully integrate these techniques into your life and experience their full benefits. It's important to be patient with yourself and give yourself permission to heal at your own pace.`,
    open: false 
  },
  { 
    question: `Can the Emotional Pain Management techniques completely eliminate emotional pain?`, 
    answer: `While the Emotional Pain Management techniques can be powerful tools for coping with and healing from emotional pain, they may not completely eliminate it. Emotional pain is a normal part of the human experience, and it's important to allow yourself to feel and process these emotions. The techniques can provide support, guidance, and relief, but healing is a gradual process that may require additional support from a mental health professional if needed.`,
    open: false 
  },
  { 
    question: `Are there any risks associated with practicing the Emotional Pain Management techniques?`, 
    answer: `The Emotional Pain Management techniques are generally considered safe and beneficial. However, it's important to practice self-care and be mindful of your own emotional well-being. If at any point you feel overwhelmed or need additional support, consider reaching out to a mental health professional who can guide you in your healing journey.`,
    open: false 
  },
  { 
    question: `Can I use the Emotional Pain Management techniques alongside therapy or counseling?`, 
    answer: `Yes, the Emotional Pain Management techniques can be used alongside therapy or counseling to enhance your healing process. They can provide you with additional tools and strategies to work through emotional pain and complement the support you receive from mental health professionals. It is important to communicate with your therapist or counselor about any techniques you are practicing.`,
    open: false 
  },
  { 
    question: `Are there any specific techniques or activities I can start with for managing emotional pain?`, 
    answer: `There are several techniques and activities you can start with for managing emotional pain. These may include mindfulness meditation, deep breathing exercises, journaling, engaging in creative outlets or hobbies, seeking social support, and practicing self-compassion. It's important to find what works best for you and to customize your own approach to managing emotional pain.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>