<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Breaking free from alcohol addiction can be incredibly challenging, but with the right support, mindset, and tools, it is possible. This subliminal is designed to help you overcome alcohol addiction and regain control of your life."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can assist you:'
    },
    {
      type: 'list',
      items: [
        "Reducing cravings and the desire to drink alcohol.",
        "Boosting your motivation to stay sober and committed to your recovery journey.",
        "Providing emotional support and inner strength to resist temptations.",
        "Helping you rewire your mindset and beliefs surrounding alcohol.",
        "Encouraging healthier coping mechanisms and stress management techniques.",
        "Supporting your overall well-being and helping you rebuild a fulfilling and sober life."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to break free from the cycle of addiction and embrace a healthier, sober lifestyle. Remember, recovery is a journey, and with dedication and support, you can overcome alcohol addiction and create a brighter future for yourself."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Breaking free from alcohol addiction is a challenging journey, but with the right support, it is possible to regain control of your life. This subliminal is designed to assist you in overcoming your addiction and creating a healthier, sober lifestyle. Before you begin, consider the following strategies to maximize your progress:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Commit to change: Embrace the decision to break free from addiction and reclaim your life.",
        "Seek support: Reach out to a therapist, support group, or loved ones who can provide encouragement and guidance throughout your journey.",
        "Create a supportive environment: Remove triggers and temptations from your surroundings as much as possible.",
        "Focus on self-care: Prioritize self-care activities that promote healing and overall well-being, such as exercise, healthy eating, and stress reduction techniques."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal, allow the positive affirmations to penetrate your subconscious mind and reinforce your commitment to sobriety. Remember, this is just the beginning of your recovery journey, and every small step forward counts. Stay strong, believe in yourself, and let's embark on the path to a healthier, sober life together."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Alcohol Addiction Removal subliminal! This is a significant milestone on your journey towards breaking free from the cycle of addiction and living a healthier, sober life. Take a moment to acknowledge your strength and commitment to your personal growth."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Seek support: Reach out to a therapist, support group, or addiction helpline for additional guidance and assistance.",
        "Create a support network: Surround yourself with people who understand and support your recovery journey.",
        "Practice self-care: Prioritize activities that promote relaxation, mindfulness, and self-reflection.",
        "Avoid triggers: Identify and avoid situations, people, or places that may tempt you to relapse.",
        "Celebrate milestones: Acknowledge and reward yourself for each sober day, week, and month."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, recovery is a process, and setbacks may occur along the way. Be compassionate towards yourself and stay committed to your sobriety. With the right support and determination, you can overcome alcohol addiction and regain control of your life. Stay strong, and believe in your ability to live a healthier, happier future."
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Alcohol Addiction Removal subliminal work?`, 
    answer: `The Alcohol Addiction Removal subliminal uses positive affirmations and messages that target your subconscious mind to help you release the underlying causes and cravings associated with alcohol addiction. By consistently listening to the subliminal, you can reprogram your mindset, reduce cravings, and develop healthier coping mechanisms to overcome addiction.`,
    open: false
  },
  { 
    question: `Is it safe to use the Alcohol Addiction Removal subliminal as the sole treatment for alcohol addiction?`, 
    answer: `No, the Alcohol Addiction Removal subliminal is not intended to be used as the sole treatment for alcohol addiction. It is designed to complement other forms of treatment, such as therapy or support groups. If you are struggling with alcohol addiction, it is important to seek professional help for a comprehensive and personalized treatment plan.`,
    open: false 
  },
  { 
    question: `How long does it take to see results with the Alcohol Addiction Removal subliminal?`, 
    answer: `The time it takes to see results with the Alcohol Addiction Removal subliminal can vary depending on factors such as the individual, the severity of the addiction, and their commitment to recovery. Consistently listening to the subliminal and combining it with other forms of treatment can accelerate the recovery process. It is also important to remember that recovery is a journey, and patience and persistence are key.`,
    open: false 
  },
  { 
    question: `Can the Alcohol Addiction Removal subliminal help with withdrawal symptoms?`, 
    answer: `The Alcohol Addiction Removal subliminal is not specifically designed to address physical withdrawal symptoms. It focuses on psychological and emotional aspects of addiction recovery, helping to reprogram your mindset and develop healthier coping mechanisms. If you are experiencing severe withdrawal symptoms, it is important to consult with a healthcare professional for proper medical assistance.`,
    open: false 
  },
  { 
    question: `Is it possible to relapse after using the Alcohol Addiction Removal subliminal?`, 
    answer: `Relapse is a possible outcome in any addiction recovery process, and the Alcohol Addiction Removal subliminal is not a guarantee against relapse. It is important to view addiction recovery as a continuous journey and to seek additional support systems and professional help to maintain sobriety in the long term.`,
    open: false 
  },
  { 
    question: `Can the Alcohol Addiction Removal subliminal be used in conjunction with other forms of treatment?`, 
    answer: `Yes, the Alcohol Addiction Removal subliminal can be used in conjunction with other forms of treatment for alcohol addiction. It is important to consult with healthcare professionals and addiction specialists to create a comprehensive and personalized recovery plan that includes therapy, support groups, or other evidence-based treatments.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>