<template>
  <div>
    <Dialog
      :dialog="dialog"
      :width="800"
      :title="`Edit Promo Code`"
      @closeDialog="closeDialog"
    >
      <div>
        <p class="mb-1">Promo Code</p>
        <v-text-field
          solo
          dense
          hide-details
          class="mb-3"
          placeholder="Promo Code"
          v-model="code"
        ></v-text-field>

        <p class="mb-1">Promo Name</p>
        <v-text-field
          solo
          dense
          hide-details
          class="mb-3"
          placeholder="Promo Name"
          v-model="promo_name"
        ></v-text-field>

        <p class="mb-1">Description</p>
        <v-textarea
          solo
          dense
          hide-details
          class="mb-3"
          placeholder="Description"
          v-model="description"
        ></v-textarea>

        <p class="mb-1">Discount</p>
        <v-text-field
          solo
          dense
          hide-details
          class="mb-3"
          placeholder="Discount"
          v-model="discount"
          type="number"
        ></v-text-field>

        <p class="mb-1">Expiry Date</p>
        <el-date-picker
          v-model="expirydate"
          type="date"
          placeholder="Expiry Date"
          class="expirydate mb-3"
        />

        <p class="mb-1">Influencer</p>
        <v-autocomplete
          solo
          dense
          hide-details
          clearable
          v-model="influencer"
          :items="influencers"
          item-text="name"
          item-value="user_id"
        />
      </div>
      <template v-slot:action>
        <v-row class="ma-0">
          <v-col class="pl-0 pr-2">
            <v-btn block @click="submitDialog()" :disabled="disabled"
              >Update</v-btn
            >
          </v-col>
          <v-col class="pl-2 pr-0">
            <v-btn block @click="closeDialog()">Cancel</v-btn>
          </v-col>
        </v-row>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "@/components/Dialog.vue";

import { updatePromoCodes } from "@/api/promocode.js";
import { getMessage, parseDate } from "@/utils";
export default {
  props: {
    dialog: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    Dialog,
  },
  data: () => {
    return {
      getMessage,
      parseDate,
      updatePromoCodes,

      id: null,
      code: null,
      promo_name: null,
      description: null,
      discount: 0,
      expirydate: null,
      influencer: null,
    };
  },
  created() {
    this.getData();
  },
  computed: {
    disabled() {
      return (
        this.code == null ||
        this.promo_name == null ||
        this.expirydate == null ||
        this.expirydate == "" ||
        this.code == "" ||
        this.promo_name == ""
      );
    },
    influencers() {
      return this.$store.state.user.influencer.data;
    },
  },
  methods: {
    getData() {
      let expirydate = this.data.expiry_date;
      if (this.data.expiry_date == "0000-00-00 00:00:00") {
        expirydate = new Date().toDateString();
      }

      this.id = this.data.id;
      this.code = this.data.code;
      this.promo_name = this.data.display_name;
      this.description = this.data.display_description;
      this.discount = this.data.adjustment_amount;
      (this.expirydate = parseDate(expirydate)),
        (this.influencer = this.influencers.filter(
          (el) => el.name == this.data.influencer
        )[0].user_id);
    },
    closeDialog() {
      this.$emit("closeDialog", false);
    },
    submitDialog() {
      if (this.expirydate == "0000-00-00 00:00:00") {
        this.expirydate = new Date().toDateString();
      }

      const data = {
        id: this.id,
        code: this.code,
        display_name: this.promo_name,
        display_description: this.description,
        adjustment_amount: parseInt(this.discount),
        expiry_date: parseDate(this.expirydate),
        user_id: this.influencer,
      };

      this.updatePromoCodes(data).then((res) => {
        this.getMessage(this, res);
        this.closeDialog();
      });
    },
  },
};
</script>

<style scoped>
.expirydate {
  width: 100% !important;
  border-radius: 8px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
</style>
