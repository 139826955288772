<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Optimizing your digestive system is crucial for overall health and well-being, and this subliminal is designed to help you achieve just that. By targeting your subconscious mind, it encourages improved digestion and supports a healthier gut."
    },
    {
      type: 'heading',
      text: 'Benefits of using this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Enhancing nutrient absorption, allowing your body to better utilize the nutrients from the foods you eat.",
        "Promoting regular bowel movements and reducing the frequency of digestive discomfort.",
        "Supporting a healthy balance of gut bacteria, which is essential for digestion and immune function.",
        "Reducing bloating, gas, and other digestive symptoms that may cause discomfort.",
        "Promoting a more efficient metabolism, which can aid in weight management and overall health."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your daily routine, you can rewire your subconscious mind to support healthy digestion and optimize your digestive system. As you listen regularly, you may notice improvements in your digestion, energy levels, and overall well-being. Remember, consistency is key to achieving optimal results."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Enhancing digestion and optimizing your digestive system is crucial for maintaining overall health and well-being. This subliminal is designed to support your journey towards achieving a healthy digestive system. Before you start listening, consider these strategies to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Eat mindfully: Practice conscious eating by paying attention to your body's hunger and fullness cues. Chew your food thoroughly and savor each bite.",
        "Stay hydrated: Drink an adequate amount of water throughout the day to support digestion and prevent constipation.",
        "Include fiber-rich foods: Incorporate high-fiber foods such as fruits, vegetables, whole grains, and legumes into your diet to promote healthy digestion.",
        "Manage stress: Stress can affect digestion, so incorporate stress-reducing techniques like meditation, deep breathing, or yoga into your routine.",
        "Maintain a regular eating schedule: Try to eat meals and snacks at consistent times each day to establish a routine for your digestive system.",
        "Avoid trigger foods: Identify any foods that may cause digestive discomfort or irritation, and try to avoid or minimize their consumption."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal can help reprogram your mind to optimize your digestive system and improve your digestion. Remember to listen regularly and combine it with healthy lifestyle choices. Embrace the potential for better digestion and overall well-being, and let's begin this transformative journey together."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Digestion Enhancement subliminal session! By focusing on improving your digestion, you've taken a vital step towards enhancing your overall health and well-being. Take a moment to reflect on the importance of a healthy digestive system in your daily life."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Eat mindfully: Pay attention to your body's hunger and fullness cues while enjoying your meals.",
        "Choose nutritious foods: Incorporate fiber-rich foods, probiotics, and hydrating options into your diet.",
        "Chew thoroughly: Take the time to chew your food properly, aiding in digestion and nutrient absorption.",
        "Manage stress: Practice stress-reducing techniques like deep breathing and mindfulness to support digestion."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, improving digestion is a gradual process that requires patience and consistency. Keep making conscious choices to optimize your digestive health, and you'll notice the positive effects on your overall well-being. Here's to a healthier and happier you!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Digestion Enhancement subliminal improve my digestion?`, 
    answer: `The Digestion Enhancement subliminal works by influencing your subconscious mind to optimize your digestive system for better overall health and well-being. The subliminal messages are designed to promote relaxation, reduce stress, and support healthy digestion. By listening to the subliminal regularly, you can reprogram your mindset to create a more balanced and efficient digestive process.`,
    open: false
  },
  { 
    question: `How long does it take to see improvements in digestion with the Digestion Enhancement subliminal?`, 
    answer: `The time it takes to notice improvements in digestion can vary depending on individual factors. Some people may experience positive changes in digestion and overall well-being within a few weeks of consistent listening to the subliminal. However, it is important to note that results may vary and it is recommended to continue listening to the subliminal for optimal benefits.`,
    open: false 
  },
  { 
    question: `Can the Digestion Enhancement subliminal treat specific digestive conditions?`, 
    answer: `The Digestion Enhancement subliminal is not intended to treat or cure specific digestive conditions. It is designed to support overall digestive health and improve digestion. If you have any specific digestive concerns or conditions, it is important to consult with a healthcare professional for appropriate diagnosis and treatment.`,
    open: false 
  },
  { 
    question: `Is it safe to listen to the Digestion Enhancement subliminal while eating?`, 
    answer: `Yes, it is safe to listen to the Digestion Enhancement subliminal while eating. The subliminal messages are non-intrusive and listening to the audio during meals should not interfere with the digestive process. However, it is recommended to find a quiet and relaxing environment to fully benefit from the subliminal's positive affirmations.`,
    open: false 
  },
  { 
    question: `Can the Digestion Enhancement subliminal replace a healthy diet and lifestyle?`, 
    answer: `No, the Digestion Enhancement subliminal is not intended to replace a healthy diet and lifestyle. It can complement and support your existing efforts towards better digestion and overall well-being. Incorporating the subliminal into a balanced and nutritious diet, regular exercise, and appropriate hydration can enhance its effectiveness.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the Digestion Enhancement subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Digestion Enhancement subliminal. However, if you have any specific concerns about your digestion or health, it is advisable to consult with a healthcare professional for personalized advice and guidance.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>