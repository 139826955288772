import { render, staticRenderFns } from "./24-stop-eating-junk-food.vue?vue&type=template&id=5ef30dc0&scoped=true&"
import script from "./24-stop-eating-junk-food.vue?vue&type=script&lang=js&"
export * from "./24-stop-eating-junk-food.vue?vue&type=script&lang=js&"
import style0 from "./24-stop-eating-junk-food.vue?vue&type=style&index=0&id=5ef30dc0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ef30dc0",
  null
  
)

export default component.exports