<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're looking to improve the health and appearance of your facial skin, this subliminal program can help you achieve that. By targeting your subconscious mind, it can promote skin regeneration and a more youthful appearance."
    },
    {
      type: 'heading',
      text: 'Here are some key benefits of this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Stimulating collagen production for firmer and more elastic skin.",
        "Reducing the appearance of wrinkles, fine lines, and age spots.",
        "Improving skin tone and texture for a smoother and more radiant complexion.",
        "Promoting the regeneration of skin cells for a fresh and youthful look.",
        "Boosting confidence and self-esteem by enhancing your natural beauty."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your skincare routine, you can enhance the effectiveness of your efforts to rejuvenate your facial skin. As you listen regularly, you may notice improvements in the firmness, texture, and overall appearance of your skin. Remember, patience and consistency are key to achieving long-lasting results."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a journey towards facial skin regeneration and a youthful appearance requires patience, commitment, and a positive mindset. This subliminal program is designed to support you in achieving your skincare goals. Before you start listening, consider these helpful tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Identify specific areas of concern or goals you have for your facial skin regeneration",
        "Create a skincare routine: Establish a consistent routine using quality products that nourish and hydrate your skin.",
        "Take care of your overall health: Remember that the health of your skin is connected to your overall well-being, so prioritize sleep, hydration, and a nutritious diet.",
        "Practice self-care: Incorporate stress-reducing activities, such as meditation or deep breathing exercises, to support your skin's regeneration process."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal program works by reinforcing positive beliefs and affirmations within your subconscious mind, allowing you to manifest the changes you desire. By listening regularly and implementing a holistic approach to skincare, you can cultivate healthier and more youthful-looking skin. Embrace this opportunity for self-care and let the journey to facial skin regeneration begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Facial Skin Regeneration program! By dedicating time and effort to this subliminal, you’ve taken a significant step towards revitalizing and renewing your facial skin. Now, it's important to reflect on your experience and continue nurturing your skin for a youthful and radiant appearance."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Develop a skincare routine: Design a personalized skincare routine that includes cleansing, exfoliating, moisturizing, and protecting your skin from the sun.",
        "Hydrate and nourish your skin: Drink enough water and incorporate hydrating foods into your diet to support skin health from within.",
        "Protect your skin from damage: Use sunscreen and avoid excessive sun exposure to prevent premature aging and protect your skin from harmful UV rays.",
        "Get enough sleep: Prioritize quality sleep as it plays a crucial role in skin regeneration and overall health."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, facial skin regeneration is a continuous process that requires consistent care and attention. Embrace the journey and enjoy the healthy glow that comes with taking care of your skin. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Facial Skin Regeneration program work?`, 
    answer: `The Facial Skin Regeneration program utilizes subliminal messages to positively influence your subconscious mind and promote skin regeneration. The subliminal messages aim to encourage healthy skin cell turnover, boost collagen production, and improve overall skin health. By consistently listening to the program, you can help support the natural rejuvenation process of your facial skin.`,
    open: false
  },
  { 
    question: `How long before I start noticing results with the Facial Skin Regeneration program?`, 
    answer: `The time it takes to see noticeable results may vary depending on individual factors and consistency in listening. Some people may experience improvements in their skin's appearance and texture within a few weeks, while others may require more time. It is important to listen to the program consistently and give your skin time to respond to the positive affirmations and suggestions.`,
    open: false 
  },
  { 
    question: `Is the Facial Skin Regeneration program suitable for all skin types?`, 
    answer: `Yes, the Facial Skin Regeneration program is designed to support the natural regeneration process of all skin types. Whether you have dry, oily, combination, or sensitive skin, the program aims to promote overall skin health and help your skin look more rejuvenated and youthful.`,
    open: false 
  },
  { 
    question: `Can the Facial Skin Regeneration program replace skincare products?`, 
    answer: `While the Facial Skin Regeneration program is designed to promote skin regeneration, it is not intended to replace a consistent skincare routine. It works best when used in conjunction with a proper skincare regimen tailored to your specific skin type and concerns. Combining the program with a quality skincare routine can enhance its effectiveness and help you achieve the best possible results.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with the Facial Skin Regeneration program?`, 
    answer: `The Facial Skin Regeneration program is safe to listen to and does not have any known risks or side effects. However, if you have any specific skin concerns or conditions, it is always advisable to consult with a dermatologist before incorporating any new skincare programs into your routine.`,
    open: false 
  },
  { 
    question: `Can the Facial Skin Regeneration program be used in combination with other skincare treatments or procedures?`, 
    answer: `Yes, the Facial Skin Regeneration program can be used in combination with other skincare treatments or procedures. However, it is recommended to consult with a dermatologist or skincare professional before combining different treatments to ensure compatibility and avoid any potential adverse effects.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>