<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "This Mandarin Chinese language learning aid is designed to help you improve your skills and enhance your fluency in Mandarin Chinese. Whether you're a beginner or intermediate learner, this subliminal can assist you in various aspects of language learning."
    },
    {
      type: 'heading',
      text: 'Key features of this learning aid:'
    },
    {
      type: 'list',
      items: [
        "Enhancing your pronunciation and helping you speak Mandarin Chinese with greater clarity.",
        "Expanding your vocabulary by exposing you to commonly used words and phrases.",
        "Improving your understanding of grammar and sentence structure.",
        "Boosting your confidence in conversing and communicating effectively in Mandarin Chinese.",
        "Promoting fluency and helping you develop a more natural flow in your speech.",
        "Assisting you in retaining and recalling information more easily."
      ]
    },
    {
      type: 'paragraph',
      text: "By consistently listening to this subliminal and actively engaging in Mandarin Chinese language practice, you can accelerate your learning journey and make significant progress in your language skills. Remember, language acquisition requires dedication and practice, but with this aid, you're on the right track to becoming more proficient in Mandarin Chinese!" 
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a journey to improve your Mandarin Chinese language skills is an exciting endeavor. Whether you're a beginner or looking to enhance your fluency, this learning aid is designed to support your progress. Before you begin, consider the following tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set achievable goals: Identify what you want to accomplish in your Mandarin Chinese language learning journey, such as improving pronunciation, expanding vocabulary, or developing fluency.",
        "Create a learning plan: Outline a structured approach to your study sessions, including dedicated time for listening, speaking, reading, and writing activities.",
        "Immerse yourself in Mandarin Chinese: Surround yourself with the language through podcasts, movies, music, or conversations with native speakers.",
        "Stay consistent: Regularly engage with this learning aid to reinforce your progress and maintain momentum in your language learning journey."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this learning aid into your Mandarin Chinese language learning routine, you can enhance your pronunciation, vocabulary, and fluency. Embrace each learning session as an opportunity to grow and develop your language skills. Let's begin your exciting journey to improve your Mandarin Chinese!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Mandarin Chinese Language Learning Aid session! By dedicating time and effort to improving your Mandarin Chinese language skills, you are taking significant steps towards becoming more fluent and confident in communicating in this beautiful language. As you reflect on your experience, consider how far you've come and all the progress you've made."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice speaking aloud: Engage in conversations or practice repeating phrases to improve your pronunciation.",
        "Expand your vocabulary: Regularly learn new words and review them using flashcards or language learning apps.",
        "Immerse yourself in the language: Surround yourself with Mandarin Chinese through movies, music, and reading materials.",
        "Seek opportunities for real-world practice: Find language exchange partners or join conversation groups to practice speaking with native speakers."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, language learning is a lifelong journey. Celebrate your progress and stay committed to continuous improvement. With dedication and practice, you will continue to enhance your Mandarin Chinese language skills and open doors to new opportunities and cultural experiences. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Mandarin Chinese Language Learning Aid help me improve my language skills?`, 
    answer: `The Mandarin Chinese Language Learning Aid is designed to enhance your pronunciation, vocabulary, and fluency in Mandarin Chinese. By listening to the audio regularly, you can expose yourself to native Mandarin Chinese speakers and immerse yourself in the language. The subliminal messages aim to assist your subconscious in absorbing the language patterns, improving your understanding and speaking abilities.`,
    open: false
  },
  { 
    question: `How long does it take to see improvements in my Mandarin Chinese language skills?`, 
    answer: `The time it takes to see improvements in your Mandarin Chinese language skills may vary depending on your individual learning style and the amount of time you dedicate to practicing the language. Consistency is key, so we recommend listening to the Mandarin Chinese Language Learning Aid regularly and actively engaging in other language-learning activities, such as reading, writing, and speaking. With consistent effort, you can expect to see progress over time.`,
    open: false 
  },
  { 
    question: `Is it suitable for beginners in Mandarin Chinese?`, 
    answer: `Yes, the Mandarin Chinese Language Learning Aid is suitable for beginners in Mandarin Chinese. It can help you develop an ear for the language, improve pronunciation, and enhance your vocabulary. However, we also recommend combining the subliminal with other language-learning resources, such as textbooks or online courses, to gain a well-rounded understanding of the language's structure and grammar.`,
    open: false 
  },
  { 
    question: `Can the Mandarin Chinese Language Learning Aid replace formal language classes or lessons?`, 
    answer: `No, the Mandarin Chinese Language Learning Aid is designed to complement formal language classes or lessons. While it can be a valuable tool for improving pronunciation, vocabulary, and fluency, it is important to also engage in structured language learning activities and practice speaking Mandarin Chinese with native speakers or language partners.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of using the Mandarin Chinese Language Learning Aid?`, 
    answer: `No, there are no known risks or side effects associated with using the Mandarin Chinese Language Learning Aid. However, it is important to note that learning a language requires effort and consistency. Results may vary depending on individual circumstances and the amount of dedication put into practicing the language.`,
    open: false 
  },
  { 
    question: `Can the Mandarin Chinese Language Learning Aid help with all aspects of Mandarin Chinese language skills?`, 
    answer: `Yes, the Mandarin Chinese Language Learning Aid is designed to help with various aspects of Mandarin Chinese language skills, including pronunciation, vocabulary, and fluency. However, it is important to engage in other language learning activities, such as reading and writing, to develop a well-rounded understanding of the language and improve all aspects of language proficiency.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>