<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Developing a positive mindset and embracing positive thinking can have a profound impact on your overall well-being. This subliminal is designed to help you shift your mindset and cultivate a positive outlook on life."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Increasing your self-confidence and self-belief.",
        "Enhancing your resilience, making it easier to bounce back from setbacks.",
        "Promoting a more optimistic and positive outlook on life.",
        "Reducing negative self-talk and self-doubt.",
        "Improving your overall mental and emotional well-being."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you may notice a gradual shift in your thinking patterns and outlook. Positive thinking can help you navigate life's challenges with a more resilient and optimistic mindset. Remember, building a positive mindset takes time and practice, but with consistent use, you can create lasting changes in your thoughts and attitudes."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embracing positive thinking and developing a positive mindset can greatly impact your overall well-being and outlook on life. This subliminal is designed to help you shift your mindset and cultivate a positive outlook. Before you start listening, consider these preparation tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Clarify your intention to develop a positive mindset and focus on the benefits it can bring to your life.",
        "Monitor self-talk: Be mindful of your inner dialogue and make a conscious effort to replace negative thoughts with positive affirmations.",
        "Practice gratitude: Reflect on the things you are grateful for each day, cultivating a mindset of appreciation and positivity.",
        "Visualize positivity: Imagine yourself embracing positive thinking and experiencing the benefits in different aspects of your life."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind to embrace positivity, resilience, and optimism. As you embark on this journey, stay open to the possibilities and let your mind absorb the positive affirmations. Embrace the power of your mind and get ready to experience a transformation in your thoughts and mindset!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Positive Thinking and Mindset subliminal session! By choosing to shift your mindset towards positive thinking, you have taken a significant step towards creating a more optimistic and fulfilling life. Now, it's time to reflect on your experience and continue nurturing your positive outlook."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice gratitude: Take a few moments each day to acknowledge and appreciate the positive aspects of your life.",
        "Challenge negative thoughts: When negative thoughts arise, consciously reframe them into positive ones.",
        "Surround yourself with positivity: Seek out uplifting books, podcasts, and people that inspire and motivate you.",
        "Engage in positive self-talk: Replace self-criticism with self-compassion and affirmations.",
        "Embrace learning opportunities: View setbacks as opportunities for growth and learning, rather than roadblocks."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, developing a positive mindset is an ongoing process. Be patient with yourself and celebrate the small victories along the way. Your journey towards positive thinking will not only improve your outlook on life but also enhance your overall well-being. Keep shining!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Positive Thinking and Mindset subliminal help me?`, 
    answer: `The Positive Thinking and Mindset subliminal is designed to reprogram your subconscious mind with positive affirmations, helping you develop a more optimistic and resilient mindset. By consistently listening to the subliminal, you may notice a shift in your thought patterns, increased self-belief, and a greater ability to overcome challenges and setbacks. It can help you cultivate a positive outlook on life and attract positive experiences and opportunities.`,
    open: false
  },
  { 
    question: `How long should I listen to the Positive Thinking and Mindset subliminal to see results?`, 
    answer: `Results may vary depending on individual circumstances and receptivity to the subliminal messages. Some people may notice positive changes in their thinking patterns and mindset relatively quickly, while others may require more time and consistent listening. We recommend listening to the subliminal daily for at least a few weeks to allow the affirmations to integrate into your subconscious mind.`,
    open: false 
  },
  { 
    question: `Can the Positive Thinking and Mindset subliminal help with anxiety and negative thoughts?`, 
    answer: `The Positive Thinking and Mindset subliminal can be beneficial for individuals struggling with anxiety and negative thoughts. The positive affirmations are designed to counter negative programming and promote a more positive and empowering mindset. While it can be a helpful tool, it is not a substitute for professional therapy or medical advice. If you are experiencing severe anxiety or negative thoughts, please seek appropriate support.`,
    open: false 
  },
  { 
    question: `Can I listen to the Positive Thinking and Mindset subliminal while doing other activities?`, 
    answer: `It is recommended to listen to the Positive Thinking and Mindset subliminal in a relaxed and focused state to maximize its benefits. Choose a time when you can give your full attention to the subliminal audio to fully absorb the positive affirmations and engage with the transformational process. However, if you find it helpful and non-distracting, you may also listen to it while engaging in activities that do not require your complete focus.`,
    open: false 
  },
  { 
    question: `Are there any side effects of listening to the Positive Thinking and Mindset subliminal?`, 
    answer: `No, there are no known side effects of listening to the Positive Thinking and Mindset subliminal. However, it is important to note that the subliminal is designed to support positive change and personal growth, but individual results may vary. If you have any concerns about your mental health or emotional well-being, please consult with a qualified professional.`,
    open: false 
  },
  { 
    question: `Can the Positive Thinking and Mindset subliminal change my personality?`, 
    answer: `The Positive Thinking and Mindset subliminal is not designed to change your fundamental personality traits. However, by rewiring your subconscious mind with positive affirmations and promoting a more optimistic outlook, it can help you harness your existing strengths and cultivate a mindset that supports growth, resilience, and personal development. Your core personality will remain intact, but you may experience positive shifts in your thoughts, beliefs, and behaviors.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>