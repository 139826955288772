<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "The Akashic Records contain a wealth of information about your past, present, and future experiences. Accessing these records can provide you with deep insights, knowledge, and understanding."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help:"
    },
    {
      type: 'list',
      items: [
        "Unlocking your ability to access the Akashic Records and tap into the infinite wisdom contained within it.",
        "Enhancing your intuition and psychic abilities to receive the information and guidance from the records.",
        "Gaining a clearer understanding of your purpose, life path, and the lessons you're meant to learn.",
        "Exploring your past lives and how they may be influencing your current experiences.",
        "Receiving guidance, solutions, and answers to your questions and challenges by connecting with higher realms of consciousness."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can train your mind to be more receptive and open to accessing the Akashic Records. This can help you gain profound insights and spiritual growth, guiding you towards a more fulfilling and purposeful life. Remember, patience and trust in the process are important as you explore the boundless possibilities of the Akashic Records."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Accessing the Akashic Records can provide you with profound insights about your life and personal growth. This subliminal is designed to support your journey of accessing this vast source of information. Before you begin, it can be helpful to consider the following preparation tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Clarify what you hope to gain from accessing the Akashic Records. Whether it's gaining wisdom, understanding past traumas, or finding guidance for your future, having a clear intention is important.",
        "Create a quiet and calm environment: Find a quiet space where you can relax and focus without distractions.",
        "Practice deep breathing: Take a few moments to calm your mind and center yourself before starting the subliminal.",
        "Open your mind and heart: Approach the experience with an open mind and heart, allowing yourself to receive the information and insights that come through."
      ]
    },
    {
      type: 'paragraph',
      text: "By preparing yourself mentally and creating a peaceful atmosphere, you allow yourself to fully engage with the process of accessing the Akashic Records. As you listen regularly, you may find greater clarity, spiritual growth, and a deeper understanding of your life's journey. Embrace this opportunity for self-discovery, and let the exploration of the Akashic Records begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Akashic Records Accessing session! By accessing the Akashic Records, you've embarked on a profound journey of self-discovery and enlightenment. Now that you've experienced this powerful practice, it's important to reflect on your experience and integrate the knowledge and insights gained into your life."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your insights: Take some time to journal and capture any profound revelations or messages you received.",
        "Integrate the knowledge: Find ways to apply the wisdom and understanding gained from the Akashic Records into your everyday life.",
        "Continue the practice: Consider incorporating regular sessions of Akashic Records Accessing to deepen your connection and access further insights.",
        "Trust your intuition: Trust that the guidance and knowledge you receive from the Akashic Records are valuable and align with your highest good."
      ]
    },
    {
      type: 'paragraph',
      text: "Accessing the Akashic Records is a transformative journey, and your willingness to explore and embrace this realm of knowledge is commendable. Remember to trust yourself and the wisdom you've gained from this experience. Embrace the insights and move forward with confidence and a deeper understanding of your past, present, and future. Keep seeking and expanding your consciousness!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What are the Akashic Records?`, 
    answer: `The Akashic Records are believed to be a cosmic library or database that contains the collective knowledge, experiences, and wisdom of every soul that has ever existed. It is often referred to as the "Book of Life" and can be accessed to gain insights and understanding of past, present, and future experiences.`,
    open: false
  },
  { 
    question: `How can I access the Akashic Records?`, 
    answer: `Accessing the Akashic Records can be done through various methods such as meditation, guided visualization, or working with an experienced practitioner. It requires entering a deep state of relaxation and connecting with the energetic realm where the records are believed to exist. It's important to approach the process with an open mind and pure intentions.`,
    open: false 
  },
  { 
    question: `What can I expect to find in the Akashic Records?`, 
    answer: `In the Akashic Records, you can find information and insights about your past lives, current life lessons, relationships, soul contracts, and potential future paths. It provides an opportunity for self-reflection, spiritual growth, and deeper understanding of your purpose and journey in this lifetime.`,
    open: false 
  },
  { 
    question: `Is it possible to access someone else's Akashic Records?`, 
    answer: `Accessing someone else's Akashic Records without their permission is generally regarded as an invasion of their privacy and energetic boundaries. It is recommended to focus on accessing your own records to gain personal insights and understanding. If you wish to explore a specific aspect of someone else's life, it's best to approach it with their knowledge and consent.`,
    open: false 
  },
  { 
    question: `Are there any risks or negative consequences to accessing the Akashic Records?`, 
    answer: `Accessing the Akashic Records is generally considered safe and beneficial. However, it is important to approach the process with respect, integrity, and pure intentions. Some individuals may experience emotional or energetic shifts during or after accessing the records, so it's advisable to ground yourself and practice self-care afterwards. If you feel overwhelmed or uncertain, it may be helpful to seek guidance from an experienced practitioner.`,
    open: false 
  },
  { 
    question: `Can I change my future by accessing the Akashic Records?`, 
    answer: `While accessing the Akashic Records can provide insights into potential future paths, it is important to remember that the future is not set in stone. Your actions, choices, and decisions in the present moment can influence and shape your future experiences. The Akashic Records can provide guidance and clarity, but ultimately, the power to create your future lies within you.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>