<template>
  <img
    :src="require(`@/assets/images/user/webplayer/${toggleImage}/favorite.png`)"
    @click="toggleFavorite()"
  />
</template>

<script>
import { mapGetters } from "vuex";
import {
  addLikeSubliminal,
  deleteLikeSubliminal,
  addLikePlaylist,
  deleteLikePlaylist,
} from "@/api/favorite";

export default {
  data: () => {
    return {
      isClicked: false,
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    fromPlayer: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  watch: {
    favoriteIDs(data) {
      this.isClicked = data.includes(
        this.data.hasOwnProperty("playlist_id")
          ? this.data.playlist_id
          : this.data.subliminal_id
      );
    },
    title(data) {
      this.isClicked = this.favoriteIDs.includes(
        this.data.hasOwnProperty("playlist_id")
          ? this.data.playlist_id
          : this.data.subliminal_id
      );
    },
  },
  computed: {
    ...mapGetters({
      isDataReady: "newaudio/getDataStatus",
      favoriteIDs: "favorite/getFavoriteIDs",
      favoriteInfoLists: "favorite/getFavoriteInfoLists",
    }),
    user() {
      return this.$store.state.user.info;
    },
    toggleImage() {
      return this.isClicked || this.isClicked2 ? "dark" : "light";
    },
    isFromPlayer() {
      return !this.isDataReady;
    },
    isClicked2() {
      if (this.isDataReady) {
        this.isClicked = this.favoriteIDs.includes(this.data.subliminal_id);
        return this.favoriteIDs.includes(this.data.subliminal_id);
      }
    },
    title() {
      return this.track.title;
    },
    track() {
      return this.$store.state.user.track;
    },
  },
  methods: {
    toggleFavorite() {
      let user = JSON.parse(this.user);
      this.isClicked = !this.isClicked;

      let favoriteIDs = [...this.favoriteIDs];
      let favoriteInfos = [...this.favoriteInfoLists];

      const data = {
        user_id: user.user_id,
        subliminal_id: this.data.subliminal_id,
        playlist_id: this.data.playlist_id,
        subscription_id: user.info.subscription_id,
      };

      if (this.isClicked) {
        if (this.data.hasOwnProperty("playlist_id")) {
          favoriteIDs.push(this.data.playlist_id);
          favoriteInfos.push(this.data);
          addLikePlaylist(data);
        } else {
          favoriteIDs.push(this.data.subliminal_id);
          favoriteInfos.push(this.data);
          addLikeSubliminal(data);
        }

        //console.log("hey 1");
        this.$store.dispatch("favorite/favoriteIDs", favoriteIDs);
        this.$store.dispatch("favorite/favoriteInfoLists", favoriteInfos);
      } else {
        if (this.data.hasOwnProperty("playlist_id")) {
          const index = favoriteIDs.indexOf(this.data.playlist_id);

          if (index !== -1) {
            favoriteIDs.splice(index, 1);
            favoriteInfos.splice(index, 1);
          }

          deleteLikePlaylist(data);
        } else {
          const index = favoriteIDs.indexOf(this.data.subliminal_id);

          if (index !== -1) {
            favoriteIDs.splice(index, 1);
            favoriteInfos.splice(index, 1);
          }

          deleteLikeSubliminal(data);
        }
        //console.log("hey 2");
        this.$store.dispatch("favorite/favoriteIDs", favoriteIDs);
        this.$store.dispatch("favorite/favoriteInfoLists", favoriteInfos);
      }
    },
  },
};
</script>
