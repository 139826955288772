var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.moods_details),function(detail,index){return [(_vm.isMoodExist && index == 0)?_c('div',{key:index,staticClass:"d-flex align-center mb-5"},[_c('img',{staticClass:"mood p-2",style:(`background-color: ${
          _vm.mood_id == detail.id ? detail.description : ''
        };`),attrs:{"src":detail.image},on:{"click":function($event){return _vm.goToRoute('/user/mood')},"mouseenter":function($event){_vm.mood_id = detail.id},"mouseleave":function($event){_vm.mood_id = 0}}}),(detail.status == 'Positive')?_c('div',{staticClass:"ml-10"},[_c('h6',[_vm._v("Glad that you're feeling "+_vm._s(detail.name)+" today.")]),_c('h6',[_vm._v("Here are recommended subliminal for you to boost your mood.")])]):_c('div',{staticClass:"ml-10"},[_c('h6',[_vm._v("We're here to cheer you up!")]),_c('h6',[_vm._v("Here are recommended subliminal for you to boost your mood.")])])]):_vm._e()]}),(_vm.categories.length > 0)?_c('div',[_c('h5',[_vm._v("Category")]),_c('div',{staticClass:"pb-3",staticStyle:{"height":"170px"}},[_c('v-slide-group',{attrs:{"multiple":"","show-arrows":""}},[(_vm.categories.length != 1)?_c('v-slide-item',[_c('div',{staticClass:"card1 mx-2",style:(`border: ${
              _vm.selected_category == '' ? `5px #1c8edf solid` : ''
            };`),on:{"click":function($event){return _vm.getCategory('')}}},[_c('p',{staticClass:"mb-0"},[_vm._v("All")])])]):_vm._e(),_vm._l((_vm.categories),function(category,index){return _c('v-slide-item',{key:index},[_c('div',{staticClass:"card mx-2",style:(`background: url(${category.image}); border: ${
                _vm.selected_category == category.id ? `5px #1c8edf solid` : ''
              };`),on:{"click":function($event){return _vm.getCategory(category.id)}}},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(category.name))])])])})],2)],1)]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }