<template>
  <section>
    <div class="container1">
      <div>
        <img src="@/assets/images/main/footer/about/deer.png" alt="">
      </div>
      <div>
        <h3 class="maintitle">About Us</h3>
        <div class="contents">
        <p class="mt-5">Who We Are: Pioneers in Tech and Content Development<br>
          Magus is a tech and content development company specializing in creating and producing subliminal audios, educational materials, and a listening and streaming platform.<br><br>
          Our Commitment to Quality and Effectiveness<br>
          We offer high-quality, highly effective, and easily accessible subliminal audio worldwide. 100% Safe and Effective that you’ll achieve results so easily because we ensure your safety and satisfaction in all of our products.<br><br>
          Embracing Innovation for a Better Tomorrow<br>
          Since we live in the 21st century, where everything is innovative, Magus is here to bring new ways to improve your life. A change to the world we currently have now. Something so powerful that you’ve never seen before.
        </p>
        </div>
      </div>
      <div class="d-flex our">
        <div>
          <h5>Our Vision</h5>
          <p class="mt-2">Deliver safe, effective and reliable subliminal audio to positively impact the lives of people.</p>
        </div>
        <div>
          <h5>Our Mission</h5>
          <p class="mt-2">To be the standard platform for subliminal audios in 2024.</p>
        </div>
      </div>
      <div>
        <h5>Team Members</h5>
        <div>
          <div class="row my-4">
            <template v-for="item, index in items">          
              <div class="product-card col-6 col-sm-6 col-md-3 col-lg-3 pa-2" :key="index">
                <img :src="require(`@/assets/images/main/footer/about/${item.icon}.png`)" alt="">
                <div>
                  <h6>{{ item.title }}</h6>
                  <h6>{{ item.description }}</h6>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data: () => {
    return {
      items: [
        {
          title: 'Team Members 1', 
          description: 'CEO', 
          icon: '1'
        },
        {
          title: 'Team Members 2', 
          description: 'CTO', 
          icon: '2'
        },
        {
          title: 'Team Members 3', 
          description: 'CFO', 
          icon: '3'
        },
        {
          title: 'Team Members 4', 
          description: 'DATA MANAGER', 
          icon: '4'
        },
        {
          title: 'Team Members 5', 
          description: 'UI/UX', 
          icon: '5'
        },
        {
          title: 'Team Members 6', 
          description: 'WEB DEV', 
          icon: '6'
        },
        {
          title: 'Team Members 7', 
          description: 'ANDROID DEV', 
          icon: '7'
        },
        {
          title: 'Team Members 8', 
          description: 'IOS DEV', 
          icon: '8'
        },
      ] 
    }
  },
  methods: {
    goToRoute(route) {
      if (this.currentRoute != route) {
        this.$router.push(route).catch(err => { })
        window.scrollTo(0,0);
      }
    },
  }
}
</script>

<style scoped>
  section:nth-of-type(1) > .container1 {
    padding-block: 2em;
  }
  h1, h5, h6:nth-of-type(2) {
    color: var(--ma-tufts-blue);
  }
  h3 {
    color: var(--ma-tufts-blue);
    line-height: 1.35em;
  }
  p{
    font-family: "Montserrat-Regular";
    padding: 20px;
  }
  .our> div {
    padding: 0 20px;
    width: 50%;
  }
  .maintitle {
    font-size: 40px !important;
  }
  img{
    width: clamp(1.69rem, calc(1.64rem + 5.72vw), 5.75rem);
    margin-left: auto;
    margin-right: auto; 
  }
  .contents {
    width: 85%;
    margin: auto;
  }

  @media screen and (min-width: 40rem) {
    section {
      text-align: center;
    }

    section:nth-of-type(1) > .container1 {
      @media screen and (min-width: 1400px) {
        padding: 2em;

      }

      @media screen and (max-width: 1400px) {
        padding: 2em;
      }

      @media screen and (max-width: 1000px) {
        text-align: center;
      }
    }
  }
</style>