<template>
  <v-dialog id="dialog" persistent v-model="dialog" :max-width="width" style="overflow-x: hidden !important">
    <v-card id="card-dialog">
      <v-card-title class="d-flex align-self-center px-4">
        <p class="mb-0">{{ title }}</p>
        <v-spacer></v-spacer>
        <v-icon @click="closeDialog" style="color: #B8B8B8;">mdi-close-circle</v-icon>
      </v-card-title>

      <v-divider class="mb-5"></v-divider>

      <v-card-text style="padding: 0 15px">
        <slot></slot>
      </v-card-text>

      <v-divider class="mt-5"></v-divider>

      <v-card-actions>
        <slot name="action"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean
    },
    title: {
      type: String
    },
    width: {
      type: Number
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog', false)
    }
  }
}
</script>
