<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you struggle with fear of being vulnerable, you may find it challenging to form deep connections and experience authentic relationships. But don't worry - this subliminal is here to help you overcome that fear!"
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support you:'
    },
    {
      type: 'list',
      items: [
        "By reprogramming your subconscious mind, it can help you let go of your fear of being vulnerable.",
        "It supports you in developing trust in yourself and others, allowing you to open up more freely.",
        "It encourages self-acceptance and self-love, empowering you to embrace your authentic self.",
        "By reducing anxiety and fear, it helps you feel more comfortable with vulnerability.",
        "It supports you in building deeper connections and cultivating meaningful relationships."
      ]
    },
    {
      type: 'paragraph',
      text: "With regular listening to this subliminal, you can start to release your fear of vulnerability and open yourself up to more fulfilling connections and authentic relationships. Remember, facing your fears takes courage, but the rewards of vulnerability are immeasurable. Embrace the journey and see how it transforms your life!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Conquering the fear of being vulnerable is a courageous step towards developing authentic relationships and deeper connections. This subliminal is designed to support you on this journey of self-discovery. Prior to starting the session, consider these suggestions to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your fear: Recognize and accept your fear of vulnerability, knowing that it is a common human emotion.",
        "Set your intention: Focus on your desire to overcome this fear and embrace vulnerability as a strength.",
        "Practice self-compassion: Remember to be kind and forgiving to yourself throughout the process, as vulnerability can be challenging.",
        "Imagine positive outcomes: Visualize yourself engaging in open and honest connections, feeling empowered and fulfilled."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can rewire your subconscious mind to release fear and embrace vulnerability. Be gentle with yourself and allow this transformative journey to unfold at its own pace. As you cultivate a greater sense of authenticity, meaningful relationships and connections may grow. Take a deep breath, set your intention, and let's begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Fear of Being Vulnerable Removal subliminal! By addressing and working through your fear of vulnerability, you're on your way to forming deeper connections and cultivating more authentic relationships. As you continue your journey, take a moment to reflect on your experience and integrate the learnings into your life."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice self-reflection: Take time to understand and recognize your emotions and vulnerabilities.",
        "Be open to connection: Allow yourself to engage in meaningful conversations and share your authentic self.",
        "Set healthy boundaries: Prioritize your well-being by setting boundaries that align with your values and needs.",
        "Embrace vulnerability as strength: Understand that vulnerability can deepen relationships and foster growth."
      ]
    },
    {
      type: 'paragraph',
      text: "Overcoming the fear of vulnerability is a courageous process that can lead to profound personal and relational growth. As you step out of your comfort zone and embrace vulnerability, trust yourself and the journey ahead. Remember, opening yourself up to deeper connections can bring immense joy and fulfillment to your life."
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Fear of Being Vulnerable Removal subliminal help me overcome my fear?`, 
    answer: `The Fear of Being Vulnerable Removal subliminal is designed to reprogram your subconscious mind, helping you release the fear and resistance surrounding vulnerability. By listening to the subliminal audio regularly, you can gradually build a sense of emotional safety and cultivate healthier perspectives on vulnerability. This can lead to more openness, authenticity, and deeper connections in your relationships.`,
    open: false
  },
  { 
    question: `How long do I need to listen to the subliminal before seeing results?`, 
    answer: `Results may vary depending on the individual, as everyone's journey towards overcoming fear is unique. It is recommended to listen to the Fear of Being Vulnerable Removal subliminal consistently for at least several weeks to allow the positive affirmations to deeply penetrate your subconscious mind. Over time, you may notice a shift in your mindset and a greater willingness to embrace vulnerability.`,
    open: false 
  },
  { 
    question: `Can the Fear of Being Vulnerable Removal subliminal completely eliminate my fear?`, 
    answer: `While the Fear of Being Vulnerable Removal subliminal can be a powerful tool to help you overcome your fear, it is important to approach personal growth and healing with realistic expectations. The subliminal can assist you in releasing and reframing your fear of vulnerability, but individual experiences may vary. It is always beneficial to combine the subliminal with therapy, coaching, or other supportive resources to address deeper emotional wounds and develop new coping strategies.`,
    open: false 
  },
  { 
    question: `Is it normal to feel uncomfortable or resistant when listening to the Fear of Being Vulnerable Removal subliminal at first?`, 
    answer: `Yes, it is normal to feel uncomfortable or resistant when initially exposing yourself to the Fear of Being Vulnerable Removal subliminal. Fear is a deeply ingrained emotion, and it can take time to unravel and release. If you experience discomfort, take it as a sign that the subliminal is working to bring your fear to the surface. With consistent listening and an open mindset, you can gradually overcome that resistance and embrace vulnerability more fully.`,
    open: false 
  },
  { 
    question: `Can the Fear of Being Vulnerable Removal subliminal help me in professional settings as well?`, 
    answer: `Yes, the Fear of Being Vulnerable Removal subliminal can help you in professional settings as well. Overcoming your fear of vulnerability can lead to improved communication skills, increased confidence in expressing ideas, and stronger relationships with colleagues. By embracing vulnerability, you may also feel more comfortable taking calculated risks and showcasing your authentic self in professional environments.`,
    open: false 
  },
  { 
    question: `Are there any known side effects of listening to the Fear of Being Vulnerable Removal subliminal?`, 
    answer: `There are no known side effects associated with listening to the Fear of Being Vulnerable Removal subliminal. However, it is important to remember that everyone responds differently to subliminal audios. If you have any concerns about your emotional well-being, it is recommended to seek support from a qualified mental health professional.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>