<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you want to improve your public speaking skills and become a more effective and confident speaker, this subliminal can help you achieve your goals. By targeting your subconscious mind, it can enhance your abilities, boost your confidence, and provide you with the necessary knowledge and techniques to captivate your audience and deliver impactful presentations."
    },
    {
      type: 'heading',
      text: "Here's what you can expect from using this subliminal:"
    },
    {
      type: 'list',
      items: [
        "Increased confidence in your speaking abilities, allowing you to step on stage with poise and self-assurance.",
        "Improved communication skills, helping you engage and connect with your audience.",
        "Enhanced public speaking techniques, such as voice projection, body language, and storytelling.",
        "Reduced anxiety and nervousness before and during public speaking engagements.",
        "Increased ability to deliver memorable and impactful presentations that leave a lasting impression on your audience."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to support your growth as a speaker. With time and practice, you'll notice significant improvements in your public speaking skills, confidence, and overall performance. Remember, mastering public speaking is a journey, and consistent use of this subliminal is key to achieving long-lasting results."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Improving your public speaking skills can open up new opportunities and boost your confidence. This subliminal is designed to help you enhance your abilities and become a more effective and confident speaker. Before you begin, here are some tips to help you get the most out of this subliminal training:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Focus on what specific aspects of public speaking you want to improve, such as reducing nervousness, improving voice projection, or enhancing body language.",
        "Visualize success: Imagine yourself delivering a successful and impactful presentation, feeling confident and engaging with the audience.",
        "Embrace discomfort: Recognize that growth and improvement often come from stepping out of your comfort zone. Embrace the challenges and learning opportunities that come with improving your public speaking skills.",
        "Practice regularly: Make time to practice speaking in front of others, whether it's during meetings, social gatherings, or by joining public speaking clubs or organizations."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal and incorporating the tips mentioned above, you can enhance your public speaking skills and become a more confident and effective speaker. Embrace this opportunity for personal growth, set your intention, and get ready to captivate your audience with your newfound abilities!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Public Speaking Skills Improvement and Training session! By investing your time in developing your public speaking skills, you've taken a significant step towards becoming a more effective and confident speaker. Now, it's time to reflect on your experience and continue honing your skills."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice, practice, practice: Keep refining your public speaking skills by practicing regularly.",
        "Record yourself: Film your presentations to analyze your body language, voice modulation, and areas of improvement.",
        "Expand your knowledge: Read books, attend workshops, or take courses to acquire new techniques and insights.",
        "Join supportive communities: Seek out groups where you can practice speaking and receive constructive feedback."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, becoming a great speaker takes time and dedication. Embrace every opportunity to speak in public and continue pushing yourself outside of your comfort zone. With consistent practice and a growth mindset, you'll steadily improve your public speaking skills and captivate any audience. Keep up the fantastic work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Public Speaking Skills Improvement and Training subliminal help me become a better speaker?`, 
    answer: `The Public Speaking Skills Improvement and Training subliminal is designed to target your subconscious mind and instill positive beliefs and attitudes towards public speaking. By listening to the subliminal regularly, you can improve your confidence, reduce anxiety, enhance your vocal delivery, and develop effective communication skills. It works by rewiring your mindset and eliminating any limiting beliefs or negative self-talk that may be holding you back.`,
    open: false
  },
  { 
    question: `How long does it take to see improvements in my public speaking skills with the subliminal?`, 
    answer: `The time it takes to see improvements in your public speaking skills can vary depending on the individual. Some people may notice positive changes after a few listening sessions, while others may need more time and consistent practice. We recommend listening to the subliminal daily and actively applying the techniques and strategies learned to accelerate your progress.`,
    open: false 
  },
  { 
    question: `Can the Public Speaking Skills Improvement and Training subliminal help with stage fright?`, 
    answer: `Yes, the Public Speaking Skills Improvement and Training subliminal can help with stage fright and performance anxiety. By reprogramming your subconscious beliefs, it can reduce fear and increase your confidence when speaking in front of an audience. However, it is important to practice and apply the techniques learned in real-life situations to effectively overcome stage fright.`,
    open: false 
  },
  { 
    question: `Does the subliminal provide specific tips and techniques for public speaking?`, 
    answer: `The subliminal works on a subconscious level to improve your mindset and attitudes towards public speaking. While it does not provide explicit tips and techniques, it helps you develop the confidence, charisma, and effective communication skills needed to deliver impactful presentations. If you're looking for specific tips and techniques, we recommend supplementing the subliminal with other resources and training programs.`,
    open: false 
  },
  { 
    question: `Is it effective to listen to the Public Speaking Skills Improvement and Training subliminal while practicing speeches?`, 
    answer: `Yes, listening to the Public Speaking Skills Improvement and Training subliminal while practicing speeches can be effective. It can reinforce the positive beliefs and attitudes instilled by the subliminal messages and help you maintain a confident and focused mindset during your practice sessions. Combining the subliminal with regular practice and constructive feedback can accelerate your progress as a speaker.`,
    open: false 
  },
  { 
    question: `Is it necessary to listen to the subliminal with headphones for it to work?`, 
    answer: `While listening to the subliminal with headphones can enhance the experience, it is not necessary for it to work. Subliminal messages are designed to bypass the conscious mind and directly target the subconscious, so you can still benefit from the subliminal even without using headphones. Choose a listening method that is comfortable and convenient for you.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>