<template>
    <div class="font-sans text-gray-900 dark:text-gray-200  dark:bg-gray-900 min-h-screen">
        <div class="max-w-screen-xl mx-auto p-4 sm:px-6 lg:px-8">

            <div class="mb-4 border-b border-gray-200 dark:border-gray-700">
                <ul class="flex flex-nowrap -mb-px text-sm font-md text-left text-gray-500 dark:text-gray-400" role="tablist">
                    <li class="mr-1" style="margin-left: -2vw;" role="presentation" v-for="(tab, index) in tabs" :key="index">
                        <button
                            :class="['inline-block rounded-t-lg border-b-2 border-transparent py-4 hover:border-gray-300 hover:text-gray-600 dark:hover:text-gray-300', tab.id === selectedTab ? 'active-class' : 'nonactive-class']"
                            type="button"
                            role="tab"
                            @click="selectedTab = tab.id"
                        >
                            {{ tab.name }}
                        </button>
                    </li>
                </ul>
            </div>
  
            <!-- Content for Tabs -->
            <div v-for="tab in tabs" :key="tab.id" v-if="tab.id === selectedTab">
                <div v-for="(section, index) in tab.sections" :key="index" class="mb-4 last:mb-0">
                    
                    <!-- Conditional rendering for different section types -->
                    <div v-if="section.type === 'paragraph'" class="p-4 rounded-lg bg-gray-50 dark:bg-gray-800">
                        <p class="section_text text-gray-500 dark:text-gray-400 mb-4" v-html="section.text"></p>
                    </div>
                    <div class="dark:bg-gray-800">
                        <h2 v-if="section.type === 'heading'" class="mb-2 section_type font-semibold text-gray-900 dark:text-white">{{ section.text }}</h2>
                        <ul v-if="section.type === 'list'" class="space-y-2 bg-gray-50 dark:bg-gray-800 p-4 rounded-lg">
                            <li v-for="(item, i) in section.items" :key="`list-item-${i}`" class="flex items-center section_items">
                                <!-- Inline SVG for bullet point (or any other marker you prefer) -->
                                <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                </svg>
                                {{ item }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
  
            <!-- FAQ Tab with Accordion -->
            <div v-if="selectedTab === 'faq'" class="mt-4">
                <div v-for="(item, index) in faqItems" :key="index" class="font-bold bg-white dark:bg-gray-800 shadow rounded-lg mb-2">
                    <button
                        @click="toggleAccordion(index)"
                        class="flex items-center justify-between w-full p-4 text-left font-medium text-gray-500 dark:text-gray-400 border-b dark:border-gray-700 hover:bg-blue-100 dark:hover:bg-gray-700"
                        :aria-expanded="item.open.toString()"
                        :aria-controls="'accordion-color-body-' + (index + 1)"
                    >
                        <span class="font-bold item_question">{{ item.question }}</span>
                        <svg :class="['w-4 h-4', item.open ? 'transform rotate-180' : '']" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                    </button>
    
                    <div :id="'accordion-color-body-' + (index + 1)" class="p-4 border-t dark:border-gray-700" v-if="item.open">
                        <p class="text-gray-500 dark:text-gray-400 item_answer" v-html="item.answer"></p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

import '@/assets/js/flowbite.min.js'
import '@/assets/js/vue.js'
import '@/assets/css/flowbite.min.css'
import '@/assets/css/tailwind.min.css'

export default {
  props: {
    tabs: {
      type: Array,
      default: () => {
        return [];
      },
    },
    selectedTab: {
      type: String,
      default: () => {
        return "how-it-works";
      },
    },
    sections: {
      type: Array,
      default: () => {
        return [];
      },
    },
    faqItems: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {

    };
  },
  watch: {

  },
  computed: {

  },
  methods: {
    toggleAccordion(selectedIndex){
      this.$emit("toggleAccordion", selectedIndex);
    },
  }
};
</script>

<style scoped>
    .section_text, .section_type {
        line-height: 1.5rem;
        font-family: "Montserrat-Bold";
        font-size: 0.85em;
    }
    .section_items {
        line-height: 1.5rem;
        font-family: "Montserrat-Regular";
        font-size: 0.75em;
    }
    .item_question {
        line-height: 1.5rem;
        font-family: "Montserrat-Regular";
        font-size: 0.85em;
    }
    .item_answer {
        line-height: 1.1rem;
        font-size: 0.75em;
        font-family: "Montserrat-Bold";
    }
    .nonactive-class {
        font-size: 0.9em;
        line-height: 1.5rem;
        padding-left: 2.4vw; 
        padding-right: 2.4vw; 
        font-family: "Montserrat-Regular";
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-size: 0.9em;
        line-height: 1.5rem;
        font-weight: 700;
        padding-left: 2.4vw; 
        padding-right: 2.4vw; 
        font-family: "Montserrat-Bold";

    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>