<template>
  <div style="overflow: auto; border-radius: 5px;">
    <div class="card-container"
    :style="`background: linear-gradient(to bottom, ${color} 0%, #FFFFFF 100%)`">
      <UserTrack />
    </div>
  </div>
</template>

<script>
import UserTrack from "@/views/User/UserTrack.vue";
export default {
  components: {
    UserTrack,
  },
  computed: {
    track() {
      return this.$store.state.user.track;
    },
    color() {
      return this.track.color;
    },
  }
};
</script>

<style scoped>
.card-container {
  width: calc(100vw - 375px);
  height: calc(100vh - 100px);
}

@media screen and (max-width: 990px) {
  .card-container {
    width: calc(100vw - 48px);
  }
}

@media screen and (max-width: 500px) {
  .card-container {
    width: 100vw;
  }
}
</style>
