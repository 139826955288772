<template>
  <Dialog
    :dialog="dialog"
    :width="800"
    :title="`Add Magus Details`"
    @closeDialog="closeDialog"
  >
    <div>
      <p class="mb-1">Title</p>
      <v-text-field
        solo
        dense
        hide-details
        class="mb-3"
        placeholder="Title"
        v-model="title"
      ></v-text-field>

      <p class="mb-1">Description</p>
      <TextEditor :list="list" @listchange="listchange" />

      <p class="mb-1">Remarks</p>
      <v-textarea
        rows="1"
        solo
        dense
        hide-details
        class="mb-3"
        placeholder="Remarks"
        v-model="remarks"
      ></v-textarea>
     
    </div>

    <template v-slot:action>
      <v-row class="ma-0">
        <v-col class="pl-0 pr-2">
          <v-btn block @click="submitDialog()" :disabled="disabled">Add</v-btn>
        </v-col>
        <v-col class="pl-2 pr-0">
          <v-btn block @click="closeDialog()">Cancel</v-btn>
        </v-col>
      </v-row>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "@/components/Dialog.vue";
import Upload from "@/components/Upload.vue";
import TextEditor from "@/components/Admin/TextEditor.vue";

import { getMessage } from "@/utils";
import { addMagusInfos } from "@/api/magusdetails.js";

export default {
  props: {
    dialog: {
      type: Boolean,
    },
  },
  data: () => {
    return {
      getMessage,
      addMagusInfos,

      files: [],
      isVisible: 0,

      image: null,
      sub_title: null,
      title: null,
      description: null,
      text: null,
      remarks: null,
      texteditorvalue: null,
      list: null,
    };
  },
  components: {
    Upload,
    Dialog,
    TextEditor,
  },
  computed: {
    disabled() {
      return;
      this.image == "" ||
        this.sub_title == null ||
        this.title == null ||
        this.sub_title == "" ||
        this.title == "" ||
        this.image == null;
    },
  },
  methods: {
    getText(val) {
      this.texteditorvalue = val;
    },
    listchange(val) {
      this.texteditorvalue = val;
    },
    getImageName(value) {
      this.image = value;
    },
    closeDialog() {
      this.resetDialog();
      this.$emit("closeDialog", false);
    },
    resetDialog() {
      this.title = null;
      this.description = null;
      this.text = null;
      this.remarks = null;
      this.isVisible = 0;
    },
    submitDialog() {
      const data = {
        title: this.title,
        description: this.texteditorvalue,
        remarks: this.remarks,
        is_visible: this.isVisible,
      };

      this.addMagusInfos(data).then((res) => {
        const information = res.data;

        this.getMessage(this, res);
        this.closeDialog();

        this.$store.dispatch("information/getData", information);
      });
    },
  },
};
</script>
<style scoped lang="postcss"></style>
