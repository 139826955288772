<template>
  <div>
    <Dialog :dialog="dialog" :width="350" :title="`Delete ${type == 'expanddelete' ? 'Subliminal from' : ''} Playlist`"
      @closeDialog="closeDialog">
      <p class="mb-0">You are about to delete {{ type == 'expanddelete' ? 'Subliminal from' : '' }} Playlist : {{ name }}
      </p>

      <template v-slot:action>
        <v-row class="ma-0">
          <v-col class="pl-0 pr-2">
            <v-btn block @click="submitDialog()" :disabled="disabledbutton">Delete</v-btn>
          </v-col>
          <v-col class="pl-2 pr-0">
            <v-btn block @click="closeDialog()">Cancel</v-btn>
          </v-col>
        </v-row>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/Dialog.vue'

import { getMessage } from '@/utils'
import { deletePlaylists } from '@/api/playlist.js'
import { deleteSubliminalToPlaylists } from '@/api/playlistinfo.js'

export default {
  props: ['data', 'dialog', 'selected', 'type'],
  data: () => {
    return {
      getMessage,
      deletePlaylists,
      deleteSubliminalToPlaylists,

      name: null,
      select: [],
      disabledbutton: false,
    }
  },
  components: {
    Dialog
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      if (Array.isArray(this.data)) {
        this.name = `${this.data.length} items`
      } else {
        this.name = `${this.data.title}`
      }
    },
    closeDialog() {
      this.$emit('closeDialog', false)
    },
    submitDialog() {
      let data = [this.data]

      if (Array.isArray(this.data)) {
        data = this.data
      }

      this.select = this.selected
      this.disabledbutton = true

      for (let index = 0; index < data.length; index++) {
        const element = data[index]
        this.select = this.select.filter(function (obj) {
          return obj.id !== element.id
        })

        if (this.type == 'expanddelete') {
          this.deleteSubliminalToPlaylists(element).then((res) => {
            this.$emit('selected', this.select)
            this.getMessage(this, res)
            this.closeDialog()

            this.select = []
          })
        } else {
          this.deletePlaylists(element).then((res) => {
            const playlist = res.data

            this.$emit('selected', this.select)
            this.getMessage(this, res)
            this.closeDialog()

            this.$store.dispatch("playlist/getData", playlist)
            this.select = []
          })
        }
      }
    }
  }
}
</script>
