<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you struggle with insecurities and low self-esteem, this subliminal can be a powerful tool to help you overcome those challenges. By targeting your subconscious mind, it aims to eliminate insecurities and boost your self-esteem, so you can live a confident and fulfilling life."
    },
    {
      type: 'heading',
      text: 'Here are some areas where this subliminal can make a difference:'
    },
    {
      type: 'list',
      items: [
        "Addressing specific insecurities, such as body image, social anxiety, or performance anxiety.",
        "Enhancing your self-belief and confidence in various aspects of your life, such as work, relationships, and personal achievements.",
        "Helping you let go of negative self-talk and replace it with positive affirmations and thoughts.",
        "Encouraging self-acceptance and self-love, allowing you to embrace your unique qualities and strengths.",
        "Supporting your journey towards personal growth and self-improvement, empowering you to pursue your goals and dreams."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming insecurities and building self-esteem takes time and effort. By listening to this subliminal consistently, you can rewire your subconscious mind and create a more positive and confident self-image. Embrace the journey and trust in your ability to transform!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Overcoming insecurities and building self-esteem is a powerful journey that can transform your life. This subliminal is designed to empower you in eliminating insecurities and living a more confident and fulfilling life. Before you start listening, consider the following tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Recognize your insecurities: Reflect on the specific insecurities you want to address and understand how they affect your life.",
        "Shift your perspective: Challenge negative thoughts and replace them with positive, empowering beliefs about yourself.",
        "Set clear intentions: Define what you want to achieve through this subliminal and set positive affirmations that align with those goals.",
        "Meditate on self-acceptance: Take a few moments to cultivate self-love and acceptance, letting go of self-judgment and criticism."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal aims to reprogram your subconscious mind, helping you release insecurities and embrace self-confidence. As you listen regularly, you may begin to notice positive shifts in your self-esteem and overall well-being. Allow yourself to fully immerse in this journey, and let the transformation begin!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Insecurity Removal subliminal! By actively working towards eliminating insecurities and boosting your self-esteem, you have taken a significant step towards living a more confident and fulfilling life. Take a moment to reflect on this achievement and embrace the positive changes happening within you."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice self-care: Engage in activities that nourish and uplift you, such as exercise, meditation, or spending time in nature.",
        "Challenge negative thoughts: Replace self-doubt with positive affirmations and focus on your strengths and achievements.",
        "Surround yourself with positivity: Seek the company of supportive friends and loved ones who believe in your potential.",
        "Celebrate progress: Acknowledge and celebrate your growth, big or small, along the journey of building confidence and self-esteem."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, building confidence and eliminating insecurities is a process that takes time and effort. Be patient with yourself and celebrate even the smallest victories. Believe in your worth, and know that you have the power to create a life filled with confidence and fulfillment. You are amazing!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Insecurity Removal subliminal help me boost my self-esteem?`, 
    answer: `The Insecurity Removal subliminal uses positive affirmations to target and reprogram your subconscious mind, helping you eliminate insecurities and boost your self-esteem. By consistently listening to the subliminal, you can cultivate a more positive and confident mindset, allowing you to overcome self-doubt and embrace your true worth.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Insecurity Removal subliminal?`, 
    answer: `Results may vary from person to person, and it may take some time to notice significant changes in your self-esteem. Consistent listening and repetition of the subliminal messages can contribute to reprogramming your subconscious mind, but individual experiences may differ. We recommend dedicating regular time to listen to the subliminal and being patient with the process.`,
    open: false 
  },
  { 
    question: `Can the Insecurity Removal subliminal completely eliminate all my insecurities?`, 
    answer: `While the Insecurity Removal subliminal is designed to help you eliminate insecurities and boost your self-esteem, the process is individual and may vary for each person. It can provide significant support and empowerment, but complete elimination of all insecurities may depend on various factors and personal experiences.`,
    open: false 
  },
  { 
    question: `Can the Insecurity Removal subliminal be used as a substitute for therapy or professional help?`, 
    answer: `The Insecurity Removal subliminal can be a helpful tool to complement therapy or professional help, but it is not a substitute for them. If you have deep-seated insecurities or require additional support, we recommend seeking guidance from a qualified mental health professional who can provide personalized assistance.`,
    open: false 
  },
  { 
    question: `Are there any risks associated with listening to the Insecurity Removal subliminal?`, 
    answer: `There are no known risks associated with listening to the Insecurity Removal subliminal. However, it is important to note that everyone's experiences and reactions can differ. If you have any concerns about your mental health or well-being, we advise consulting with a healthcare professional.`,
    open: false 
  },
  { 
    question: `Can the Insecurity Removal subliminal be used by anyone, regardless of age or gender?`, 
    answer: `Yes, the Insecurity Removal subliminal can be used by anyone who wishes to eliminate insecurities and boost self-esteem, regardless of age or gender. The positive affirmations embedded in the audio are designed to be inclusive and beneficial for a wide range of individuals.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>