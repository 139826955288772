<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you've ever wished you could remember your dreams with more clarity and detail, this subliminal is designed to help you do just that. By targeting your subconscious mind, it enhances your ability to recall and remember your dreams."
    },
    {
      type: 'heading',
      text: 'Benefits of using this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Increased ability to remember dreams upon waking up.",
        "Enhanced clarity and detail in dream recall.",
        "Improved ability to interpret and analyze dream symbolism.",
        "Better understanding of your subconscious mind and its messages.",
        "Stimulated creativity through the exploration of dream imagery."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly before sleep, you can train your mind to better retain and recall your dream experiences. This can lead to a deeper understanding of yourself and facilitate personal growth. Remember, consistency is important for best results."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Improving your dream memory recall is an exciting journey that can provide valuable insights into your subconscious mind. This subliminal is designed to enhance your ability to remember and recall your dreams with greater clarity and detail. Before you start listening, consider these strategies to optimize your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Focus on your desire to remember and recall your dreams, fostering a deep connection with your subconscious mind.",
        "Create a dream journal: Establish a consistent practice of recording your dreams upon waking, using keywords, images, and emotions to capture the essence of your dream experience.",
        "Meditate before sleep: Clear your mind and create a calm mental space before heading to bed, allowing your brain to relax and be more receptive to dream recall.",
        "Hold your intention in mind: While listening to this subliminal, keep your intention to remember and recall your dreams firmly in your thoughts.",
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal and following these preparation tips, you can strengthen the connection with your dream world and unlock insights from your subconscious mind. Embrace this opportunity to explore the depths of your dreams, enhance your dream recall, and uncover the hidden messages they hold."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Dream Memory Recall subliminal session! By focusing on enhancing your dream recall, you've taken a significant step towards unlocking the hidden realm of your dreams. Reflecting on your experience and continuing to nurture your dream recall ability is vital for further growth and exploration."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Keep a dream journal: Write down your dreams upon waking to foster recall and capture the details.",
        "Set dream intention: Before sleep, affirm your desire to remember your dreams and explore their messages.",
        "Create a dream-friendly environment: Ensure your sleeping space is comfortable and conducive to relaxation.",
        "Practice visualization: Picture yourself in a vivid dreamlike state, strengthening your connection to your dreams."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, developing dream recall is a journey that requires patience and consistency. As you continue to work on enhancing your dream memory, you'll gain valuable insights and enjoy the adventures that your dreams offer. Embrace the mysteries of your mind and enjoy the fascinating world of dreams!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Dream Memory Recall subliminal help me remember my dreams?`, 
    answer: `The Dream Memory Recall subliminal is designed to influence your subconscious mind, making it easier for you to remember and recall your dreams with greater clarity and detail. By listening to the subliminal regularly, you can enhance your dream memory and unlock the potential for deeper insights, self-reflection, and personal growth through your dreams.`,
    open: false
  },
  { 
    question: `When can I expect to see improvements in my dream recall?`, 
    answer: `The time it takes to notice improvements in dream recall can vary from person to person. Some individuals may experience enhanced dream memory almost immediately, while others may require more consistent listening to the subliminal before seeing noticeable results. We recommend listening to the Dream Memory Recall subliminal consistently for at least a few weeks to allow the positive affirmations to reinforce your dream memory.`,
    open: false 
  },
  { 
    question: `Can the Dream Memory Recall subliminal help with lucid dreaming?`, 
    answer: `While the Dream Memory Recall subliminal is not specifically designed for lucid dreaming, it can indirectly support the practice. By improving your dream recall and enhancing your dream memory, you may become more aware of the content of your dreams, which can increase your chances of achieving lucidity. If you are specifically interested in lucid dreaming, there are other resources and techniques available that can further assist you in that endeavor.`,
    open: false 
  },
  { 
    question: `Is it necessary to listen to the Dream Memory Recall subliminal before bed?`, 
    answer: `It is not necessary to listen to the Dream Memory Recall subliminal before bed, although it can be a convenient time to incorporate it into your routine. You can also listen to the subliminal during other relaxing moments throughout the day. The important thing is to listen to it consistently and allow the positive affirmations to work on your subconscious mind.`,
    open: false 
  },
  { 
    question: `Can the Dream Memory Recall subliminal improve my overall sleep quality?`, 
    answer: `The primary focus of the Dream Memory Recall subliminal is to enhance dream recall and memory rather than directly improve overall sleep quality. However, a more vivid dream experience can contribute to a sense of fulfillment and satisfaction upon waking up, potentially leading to a positive impact on your overall sleep experience.`,
    open: false 
  },
  { 
    question: `Will the Dream Memory Recall subliminal cause me to have more nightmares?`, 
    answer: `No, the Dream Memory Recall subliminal is not designed to induce nightmares. It aims to improve dream memory and recall, allowing you to better remember and understand your dreams. The content and nature of your dreams will still be influenced by various factors unique to you, and the subliminal does not alter this.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>