<template>
  <PromoCode />
</template>

<script>
import PromoCode from '@/views/Admin/PromoCode/index.vue'
export default {
  components: {
    PromoCode
  },
  data: () => {
    return {}
  }
}
</script>
