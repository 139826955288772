<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "PhotoReading is a powerful technique that can revolutionize the way you read and process information. By leveraging the power of your subconscious mind, this subliminal helps you develop the skills necessary to read faster and comprehend information at an accelerated rate."
    },
    {
      type: 'heading',
      text: 'Here are some of the benefits of using this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Increased reading speed, allowing you to cover more material in less time.",
        "Improved comprehension and retention of information, leading to better understanding and recall.",
        "Enhanced focus and concentration while reading, enabling you to stay engaged and absorb information more effectively.",
        "Greater confidence in your reading abilities, boosting your overall self-esteem and motivation in your studies or professional life.",
        "Efficient learning and studying, saving you time and energy as you tackle large volumes of reading material."
      ]
    },
    {
      type: 'paragraph',
      text: "With regular use of this subliminal, you can train your mind to adopt the PhotoReading technique effortlessly. As you continue to listen, you'll notice your reading speed and comprehension improve, leading to greater success in your academic or professional endeavors. Remember, practice and persistence are key to mastering this technique and reaping its full benefits."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on the journey to improve your reading speed and comprehension can be exciting and challenging. This subliminal is designed to support your efforts in mastering the PhotoReading technique. Before you start listening, take a moment to consider these tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Determine the specific aspects of reading you want to improve, such as increasing speed, enhancing comprehension, or retaining information more effectively.",
        "Create a focused reading environment: Minimize distractions, establish comfortable lighting, and choose a quiet space to optimize your reading experience.",
        "Practice active engagement: Use visualization techniques, ask questions, and make connections while reading to deepen your understanding and retention.",
        "Maintain a positive mindset: Believe in your ability to improve, stay motivated, and be open to new learning experiences as you embark on your PhotoReading journey."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you may start to notice improvements in your reading abilities, including increased speed, enhanced comprehension, and better overall focus. Embrace this opportunity to expand your potential for success in your studies and professional life. So take a deep breath, set your intentions, and get ready to unlock your PhotoReading success!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the PhotoReading Success subliminal session! By investing in your reading skills and utilizing the PhotoReading technique, you have taken a significant step towards achieving greater success in your studies and professional life. It's important to reflect on your progress and continue practicing the skills you have learned."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice the PhotoReading technique: Continue using the technique regularly to reinforce and improve your reading speed and comprehension.",
        "Set reading goals: Determine specific reading goals for different areas of your life and track your progress.",
        "Use visualization techniques: Visualize yourself confidently absorbing information and mastering your studies or professional tasks.",
        "Take regular breaks: Remember to take short breaks during long reading sessions to rest and recharge your mind."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, mastery of any skill takes time and dedication. Be patient with yourself as you continue to develop your reading abilities. Stay consistent in your practice and believe in your own potential for success. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What is PhotoReading and how does it work?`, 
    answer: `PhotoReading is a technique that involves using your whole mind to process information and read at an accelerated rate. It combines relaxation techniques, visualization, and rapid eye movements to quickly scan and absorb material. Through regular practice, you can train your brain to comprehend information at a deeper level and increase your reading speed.`,
    open: false
  },
  { 
    question: `Can anyone learn PhotoReading?`, 
    answer: `Yes, anyone can learn PhotoReading with practice and dedication. The technique is not dependent on a person's current reading speed or level of comprehension. It is a skill that can be developed and improved upon with consistent use.`,
    open: false 
  },
  { 
    question: `How long does it take to become proficient in PhotoReading?`, 
    answer: `The time it takes to become proficient in PhotoReading can vary depending on the individual and their commitment to practicing the technique. Some people may notice improvements in their reading speed and comprehension within a few weeks of consistent practice, while others may require more time. Regular and focused practice is key to becoming proficient in PhotoReading.`,
    open: false 
  },
  { 
    question: `Can PhotoReading be used for studying and professional purposes?`, 
    answer: `Yes, PhotoReading can be an effective tool for studying and professional purposes. By processing information at an accelerated rate and improving comprehension, you can cover more material in less time and retain information more effectively. This can be particularly useful for students and professionals who need to consume large amounts of information quickly.`,
    open: false 
  },
  { 
    question: `Is PhotoReading a replacement for traditional reading methods?`, 
    answer: `PhotoReading is not a replacement for traditional reading methods. It is a supplementary technique that can be used alongside traditional reading methods to enhance reading speed and comprehension. It is important to develop a balance between PhotoReading and traditional reading to optimize your learning and reading experiences.`,
    open: false 
  },
  { 
    question: `Are there any risks or disadvantages associated with PhotoReading?`, 
    answer: `There are no known risks associated with practicing PhotoReading. However, it is important to note that PhotoReading may not be suitable for everyone or effective in every situation. It is a skill that requires time and effort to develop, and individual results may vary.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>