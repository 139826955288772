<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you constantly find yourself putting others' needs before your own, it's time to break free from people-pleasing behaviors and prioritize your own well-being. This subliminal is designed to help you do just that."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support you:'
    },
    {
      type: 'list',
      items: [
        "Recognizing and challenging people-pleasing patterns and behaviors.",
        "Building self-awareness and understanding your own needs and values.",
        "Developing the confidence to set healthy boundaries and say 'no' when necessary.",
        "Cultivating self-worth and practicing self-care without guilt or fear of judgment.",
        "Learning to prioritize your own happiness and well-being."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind to break free from people-pleasing habits. As you develop a stronger sense of self and prioritize your own needs and values, you'll find greater fulfillment and healthier relationships in your life. Remember, it's okay to put yourself first and take care of your own well-being."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Breaking free from people-pleasing habits can be a transformative journey towards greater self-empowerment and personal fulfillment. This subliminal is designed to support you in prioritizing your own needs and values. Before you start listening, consider these strategies to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your values: Clarify what truly matters to you and what you want your life to align with.",
        "Set healthy boundaries: Learn to say 'no' when necessary and communicate your needs and limits to others.",
        "Practice self-care: Prioritize self-care activities that nurture and replenish your physical, emotional, and mental well-being.",
        "Visualize assertiveness: Imagine yourself confidently expressing your opinions, setting boundaries, and asserting your needs."
      ]
    },
    {
      type: 'paragraph',
      text: "Through regular use of this subliminal, you can reprogram your subconscious mind to let go of people-pleasing tendencies and embrace a more self-empowered and authentic way of living. Embrace this opportunity to prioritize yourself and your needs. So take a moment, remind yourself of your values, and let's embark on this journey towards personal growth and self-fulfillment."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Stop Being a People Pleaser subliminal session! You have taken a significant step towards breaking free from people-pleasing behaviors and prioritizing your own needs and values. It's important to reflect on your experience and continue your personal growth and transformation."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your past tendencies: Consider acknowledging and understanding the reasons behind your people-pleasing behaviors.",
        "Set boundaries: Learn to say 'no' when necessary and establish healthy boundaries with others.",
        "Practice self-care: Make time for activities that bring you joy, relaxation, and rejuvenation.",
        "Focus on your values: Identify and prioritize your own values and needs when making decisions or taking action."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, breaking free from people-pleasing is a process that takes time and practice. Embrace each step along the way and be kind to yourself. You deserve to prioritize your own well-being and live an authentic and fulfilling life. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What are some signs of people-pleasing behavior?`, 
    answer: `Some signs of people-pleasing behavior include constantly seeking approval from others, avoiding conflict at all costs, always putting others' needs before your own, and having difficulty saying no. People-pleasers often fear rejection or disapproval and tend to prioritize others' happiness over their own.`,
    open: false
  },
  { 
    question: `How can the Stop Being a People Pleaser subliminal help me?`, 
    answer: `The Stop Being a People Pleaser subliminal is designed to help reprogram your subconscious mind, allowing you to let go of people-pleasing behaviors and prioritize your own needs and values. By listening to the subliminal regularly, you can begin to develop a stronger sense of self-worth, assertiveness, and the ability to set healthy boundaries in your relationships.`,
    open: false 
  },
  { 
    question: `How long will it take for the subliminal to work and help me stop being a people pleaser?`, 
    answer: `The time it takes to see results from the Stop Being a People Pleaser subliminal can vary from person to person. Some individuals may notice positive changes in their behavior and mindset after a few weeks of consistent listening, while others may require more time. It is important to be patient and continue listening to the subliminal regularly for the best results.`,
    open: false 
  },
  { 
    question: `What can I do to reinforce the effects of the subliminal?`, 
    answer: `In addition to listening to the Stop Being a People Pleaser subliminal, you can reinforce its effects by practicing self-reflection, identifying and challenging negative thought patterns, and actively working towards setting healthier boundaries and prioritizing your own needs. Surrounding yourself with supportive and encouraging individuals can also help you stay on track with your personal growth journey.`,
    open: false 
  },
  { 
    question: `Can the subliminal help me maintain healthy relationships after I stop being a people pleaser?`, 
    answer: `Yes, as you break free from people-pleasing behaviors, you can develop healthier and more fulfilling relationships. By prioritizing your own needs and values, you can foster more authentic connections based on mutual respect and understanding. The Stop Being a People Pleaser subliminal aims to empower you with the mindset and tools to cultivate healthier relationships in all areas of your life.`,
    open: false 
  },
  { 
    question: `Will the subliminal make me become selfish or uncaring towards others?`, 
    answer: `No, the goal of the Stop Being a People Pleaser subliminal is not to make you selfish or uncaring towards others. It is about finding a balance between prioritizing your own needs and values while still considering the needs of others. The subliminal aims to help you develop assertiveness and set healthy boundaries, allowing you to navigate relationships in a more empowered and authentic way.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>