import request from "@/utils/request";

export const deleteTrackToSubliminals = (data) => {
  return request.post("/api/subliminal/info/delete", data).then((res) => {
    return res.data;
  });
};

export const addTrackToSubliminals = (data) => {
  return request.post("/api/subliminals/info", data).then((res) => {
    return res.data;
  });
};

export const updateTracksToSubliminals = (data) => {
  return request
    .put(`/api/subliminals/info/${data.id}`, data)
    .then((res) => {
      return res.data;
    });
};

export const updateTrackToSubliminals = (data) => {
  return request.post("/api/subliminal/info/update", data).then((res) => {
    return res.data;
  });
};
