<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            { 
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "This comprehensive wrestling training program is designed to help you develop the skills and techniques necessary to become a skilled wrestler. Whether you're a beginner or an experienced wrestler looking to take your game to the next level, this program can help you achieve your goals."
    },
    {
      type: 'heading',
      text: 'Key Features of this Training Program:'
    },
    {
      type: 'list',
      items: [
        "Build strength, endurance, and agility through a combination of wrestling-specific exercises and drills.",
        "Learn and master essential wrestling techniques, including takedowns, escapes, and pins.",
        "Improve your conditioning and cardiovascular fitness to maximize your performance on the mat.",
        "Develop mental toughness and resilience to overcome challenges and setbacks.",
        "Receive expert guidance and instruction from experienced coaches and wrestlers.",
        "Track your progress and set goals with the help of personalized training plans and assessments."
      ]
    },
    {
      type: 'paragraph',
      text: "By following this program consistently and dedicating yourself to the training, you can make significant progress in your wrestling abilities. Remember, wrestling is a complex and demanding sport, so it's important to approach it with dedication, discipline, and a growth mindset. This program will provide you with the tools and guidance you need to become a master wrestler."
    }
  ]
},
            {
  id: 'before-listening',
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a wrestling training program requires dedication, discipline, and a strong mindset. This subliminal can help you enhance your training experience and achieve wrestling mastery. Before you begin, consider these preparatory tips to optimize your results:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your goals: Clearly define what you want to achieve in your wrestling journey, whether it's improving specific techniques, increasing strength and agility, or becoming a champion.",
        "Create a training plan: Develop a structured training schedule that includes regular practice, strength and conditioning exercises, and recovery periods.",
        "Visualize success: Imagine yourself executing flawless moves, winning matches, and achieving your wrestling goals.",
        "Stay focused: Make sure your mind is fully engaged and centered on your wrestling goals while listening to the subliminal affirmations."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you may find yourself more motivated, focused, and in tune with the mental and physical demands of wrestling. Remember, consistency and practice are key to mastering any skill, so embrace the challenges, push yourself to the limits, and let the journey towards wrestling greatness begin!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Wrestling Training and Mastery subliminal session! You've taken an important step towards becoming a skilled wrestler and mastering the art of this sport. It's time to reflect on your journey so far and prepare for the next steps in your training."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your progress: Take time to analyze your growth as a wrestler and celebrate your achievements.",
        "Continue training: Maintain a consistent training routine to hone your strength, agility, and technique.",
        "Study and learn: Educate yourself on wrestling techniques, strategies, and the history of the sport.",
        "Set goals: Establish short-term and long-term goals to keep yourself motivated and track your progress."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, becoming a skilled wrestler requires dedication and perseverance. Embrace the challenges along the way and stay committed to your training. By putting in the time and effort, you can continue to develop your abilities and reach new heights in the world of wrestling. Keep pushing yourself and never give up!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Is this program suitable for beginners or is it more advanced?`, 
    answer: `This wrestling training program is suitable for both beginners and more advanced wrestlers. The program is designed to cater to individuals at different skill levels and can be customized to meet your specific needs and goals. Whether you are just starting out or looking to take your wrestling skills to the next level, this program can help you develop the necessary strength, agility, and technique.`,
    open: false
  },
  { 
    question: `What are the key components of this wrestling training program?`, 
    answer: `The key components of this wrestling training program include strength training, conditioning, technique drills, and live wrestling sessions. The program focuses on developing full-body strength, agility, speed, flexibility, endurance, and perfecting wrestling techniques. It also emphasizes mental toughness and strategy to help you become a well-rounded wrestler.`,
    open: false 
  },
  { 
    question: `How often should I train to see progress?`, 
    answer: `Consistency is key when it comes to seeing progress in wrestling. We recommend following a structured training schedule that includes regular training sessions. The frequency of training will depend on your individual goals, availability, and recovery abilities. It's important to strike a balance between training and rest to prevent overtraining and allow your body to recover and adapt.`,
    open: false 
  },
  { 
    question: `Are there any specific equipment or facilities required for this program?`, 
    answer: `While having access to a wrestling mat and wrestling-specific equipment can be beneficial, it is not a requirement to get started with this training program. Many of the exercises and drills can be done with minimal equipment or adapted to your available resources. However, as you progress and want to simulate real wrestling scenarios more accurately, having access to a wrestling mat and necessary equipment can enhance your training experience.`,
    open: false 
  },
  { 
    question: `Can this program help me with weight cutting for wrestling competitions?`, 
    answer: `This program is primarily focused on developing wrestling skills, strength, and conditioning. While it can indirectly contribute to weight management through exercise and proper nutrition, weight cutting for wrestling competitions typically requires specialized knowledge and guidance. If you are specifically looking for guidance on weight cutting, it is recommended to consult a wrestling coach or a sports nutritionist who can provide personalized advice.`,
    open: false 
  },
  { 
    question: `I have a previous injury. Can I still participate in this wrestling training program?`, 
    answer: `If you have a previous injury, it is important to consult with a healthcare professional or a qualified trainer before starting any new training program, including wrestling. They can evaluate your specific situation and provide guidance on whether it is safe for you to participate in the program, as well as any modifications or precautions you may need to take.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>