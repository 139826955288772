<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you suffer from a fear of clustered holes, also known as trypophobia, you're not alone. Many people find it challenging to cope with this fear, but there is hope! This subliminal is designed to help you overcome your fear and gain control over your reactions."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can assist you:'
    },
    {
      type: 'list',
      items: [
        "Reducing anxiety and fear levels when exposed to clustered holes.",
        "Training your mind to view clustered holes in a more neutral or positive light.",
        "Developing coping mechanisms to manage and control your reactions.",
        "Increasing your confidence and ability to navigate situations involving clusters of holes.",
        "Promoting a sense of calmness and relaxation when confronted with your fear."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can gradually desensitize yourself to the fear of clustered holes and trypophobia. Over time, you may find that your fear decreases, and you can live without the constant worry and anxiety associated with this phobia. Remember, everyone's journey is unique, so be patient and gentle with yourself as you work towards overcoming your fear."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Facing and overcoming a fear can be challenging, but with this subliminal, you can take steps towards controlling your reaction to clustered holes and trypophobia. Here are some suggestions to help you prepare for your listening experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Focus on your desire to overcome your fear and gain control over your reactions.",
        "Create a safe space: Find a comfortable and calming environment where you can relax and listen to the subliminal without distractions.",
        "Visualize success: Imagine yourself confidently facing situations that trigger your fear without feeling overwhelmed.",
        "Open your mind: Be receptive and willing to confront your fear in a safe and controlled manner while listening to the subliminal affirmations."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember that this subliminal is designed to support and empower you in overcoming your fear of clustered holes and trypophobia. As you listen regularly, you may find yourself feeling more in control, calm, and capable of living without fear. Embrace this opportunity to take a step towards a fearless life, and let's begin your journey towards overcoming your fear."
    }
  ]
},
            {
  id: 'after-listening',
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Fear of Clustered Holes and Trypophobia Removal subliminal! By actively working towards overcoming your fear, you've taken an important step towards living a life free from fear and anxiety. Now that you've completed the session, it's time to reflect on your progress and continue your journey of growth."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Challenge your thoughts: When faced with triggers, practice reframing your thoughts and reminding yourself that you are safe.",
        "Gradual exposure: Take small steps to expose yourself to images or situations that trigger your fear, gradually desensitizing yourself.",
        "Seek support: Consider talking to a therapist or joining support groups to share your experiences and gain encouragement.",
        "Self-care: Engage in activities that promote relaxation and self-care to help manage stress and anxiety."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming fear is a process that takes time and effort. It's important to be patient with yourself as you work towards living without fear. Celebrate each small victory and continue taking steps towards a fear-free life. You have the strength and resilience to overcome this fear, and you are not alone on this journey. Keep going!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Can the Fear of Clustered Holes and Trypophobia Removal subliminal completely eliminate my fear?`, 
    answer: `The Fear of Clustered Holes and Trypophobia Removal subliminal is designed to help you gain control over your reactions and reduce the intensity of your fear. However, individual results may vary, and the extent to which the subliminal can eliminate your fear depends on multiple factors, including your willingness to engage with the process and the severity of your fear. It is recommended to listen to the subliminal consistently and give yourself time to experience gradual improvements.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Fear of Clustered Holes and Trypophobia Removal subliminal?`, 
    answer: `Results may vary from person to person, and it can take time to overcome your fear. Consistent and regular listening to the subliminal can help reprogram your subconscious mind and gradually reduce the intensity of your fear over time. It is important to be patient and allow yourself the necessary time to experience progress.`,
    open: false 
  },
  { 
    question: `Can the Fear of Clustered Holes and Trypophobia Removal subliminal work for everyone?`, 
    answer: `While the Fear of Clustered Holes and Trypophobia Removal subliminal has been designed to help individuals overcome their fear, it may work differently for each person. Factors such as individual mindset, openness to change, and severity of the fear can influence the effectiveness of the subliminal. It is recommended to listen to the subliminal with an open mind and give it a fair chance to work for you.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Fear of Clustered Holes and Trypophobia Removal subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your fear or mental health, please consult with a mental health professional. It is important to note that the subliminal is intended to supplement a holistic approach towards overcoming your fear, and it is not a substitute for professional help if needed.`,
    open: false 
  },
  { 
    question: `Can the Fear of Clustered Holes and Trypophobia Removal subliminal be used alongside therapy or other treatments?`, 
    answer: `Yes, the Fear of Clustered Holes and Trypophobia Removal subliminal can be used alongside therapy or other treatments. It is important to discuss your use of the subliminal with your therapist or healthcare provider to ensure it aligns with your overall treatment plan and goals.`,
    open: false 
  },
  { 
    question: `Will the Fear of Clustered Holes and Trypophobia Removal subliminal make me uncomfortable or trigger my fear?`, 
    answer: `The Fear of Clustered Holes and Trypophobia Removal subliminal is designed to gently and gradually reduce the intensity of your fear. However, everyone's experience may vary. If you find that listening to the subliminal triggers discomfort or increases your fear, it is recommended to stop listening and consult with a mental health professional for further guidance.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>