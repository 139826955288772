<template>
  <div>
    <WhyMagusforBusiness />
  </div>
</template>

<script>
import WhyMagusforBusiness from '@/views/Main/WhyMagusforBusiness'
export default {
  components: {
    WhyMagusforBusiness
  }
}
</script>