<template>
  <div>
    <Dialog :dialog="dialog" :width="800" :title="`Add Featured Contents`" @closeDialog="closeDialog">
      <div>
      <v-row>

        <v-col cols="8">
          <p class="mb-1">Subliminal</p>
          <v-autocomplete solo dense hide-details clearable v-model="subliminal" :items="subliminals" item-text="title"
            item-value="subliminal_id" />
        </v-col>
        <v-col cols="4">
          <p class="mb-1">Visible</p>
          <v-checkbox v-model="is_visible" class="ma-0" style="padding-top: 8px"></v-checkbox>
        </v-col>
      </v-row>
        
      </div>

      <template v-slot:action>
        <v-row class="ma-0">
          <v-col class="pl-0 pr-2">
            <v-btn block @click="submitDialog()" :disabled="disabled">Add</v-btn>
          </v-col>
          <v-col class="pl-2 pr-0">
            <v-btn block @click="closeDialog()">Cancel</v-btn>
          </v-col>
        </v-row>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/Dialog.vue'

import { addFeaturedContents } from '@/api/featured.js'
import { getMessage } from '@/utils'
export default {
  props: {
    dialog: {
      type: Boolean
    }
  },
  components: {
    Dialog
  },
  data: () => {
    return {
      getMessage,
      addFeaturedContents,

      is_visible: 0,
      subliminal: null
    }
  },
  computed: {
    disabled() {
      return (
        this.subliminal == null
      )
    },
    subliminals() {
      return this.$store.state.subliminal.data
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog', false)
      this.resetDialog()
    },
    resetDialog() {
      this.is_visible = 0
      this.subliminal = null
    },
    submitDialog() {
      const data = {
        is_visible: this.is_visible,
        subliminal_id: this.subliminal
      }

      this.addFeaturedContents(data).then((res) => {
        this.getMessage(this, res)
        this.closeDialog()
      })
    }
  }
}
</script>
