<template>
  <v-app>
    <Appbar @openDrawer="openDrawer" />
    <Sidebar :drawer="drawer" @closeDrawer="closeDrawer" />
    <v-main>
      <Subscriptions v-if="!isRouteTrack && !isRouteSettings" />
      <router-view></router-view>
      <Guidebar />
    </v-main>
    <Webplayer />
    <Loading />
    <AudioLoading />
  </v-app>
</template>

<script>
import { getMoods } from "@/api/mood";
import { getSearch } from "@/api/search";
import { getOwnPlaylist } from "@/api/ownplaylist";
import { getSubscriptions } from "@/api/subscription";
import {
  getFeatured,
  getDiscover,
  getRecommendation,
  getAudioHistory,
} from "@/api/home";

import Appbar from "@/components/User/UserAppbar.vue";
import Sidebar from "@/components/Sidebar.vue";
import Guidebar from "@/components/User/Guidebar.vue";
import Webplayer from "@/components/Webplayer.vue";
import Subscriptions from "@/components/User/Subscriptions.vue";
import AudioLoading from "@/components/AudioLoading.vue";
import Loading from "@/components/Loading.vue";

export default {
  components: {
    Appbar,
    Sidebar,
    Guidebar,
    Webplayer,
    Subscriptions,
    AudioLoading,
    Loading,
  },
  data: () => {
    return {
      getMoods,
      getSearch,
      getFeatured,
      getDiscover,
      getOwnPlaylist,
      getAudioHistory,
      getSubscriptions,
      getRecommendation,

      guide: false,
      drawer: false,
    };
  },
  created() {
    this.getSubscriptionData();
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    route() {
      return this.$route.path;
    },
    isRouteTrack() {
      return this.route == "/user/track";
    },
    isRouteSettings() {
      return this.route == "/user/settings";
    },
    subscriptions() {
      return this.$store.state.subscription.data;
    },
    subscriptionid() {
      return this.$store.state.subscription.id;
    },
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
  },
  watch: {
    route() {
      if (!this.isRouteTrack) {
        localStorage.removeItem("pendingTrack");
      }
    },
    subscriptions() {
      this.$store.dispatch("subscription/getId", this.subscriptions[0].id);
      this.getRecentPlayedData();
      this.getMoodData();
      this.getFeaturedData();
      this.getDiscoverData();
      this.getOwnPlaylistData();
      this.getRecentSearchData();
      this.getRecommendationData();
    },
  },
  methods: {
    openDrawer(data) {
      this.drawer = data;
    },
    closeDrawer(data) {
      this.drawer = data;
    },
    openGuide(data) {
      this.guide = data;
    },
    closeGuide(data) {
      this.guide = data;
    },
    getMoodData() {
      this.getMoods().then((res) => {
        this.$store.dispatch("mood/getData", res);
      });
    },
    getFeaturedData() {
      this.getFeatured(this.subscriptionid).then((res) => {
        const playlist = res.playlist;
        const subliminal = res.subliminal;
        let result = playlist.concat(subliminal);

        let results = result.filter((item) => {
          return item.info.length > 0;
        });

        const data = results.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });

        this.$store.dispatch("featured/getData", data);
        this.$store.dispatch("featured/getLoading", false);
      });
    },
    getDiscoverData() {
      this.getDiscover(this.subscriptionid).then((res) => {
        const playlist = res.playlist;
        const subliminal = res.subliminal;
        let result = playlist.concat(subliminal);

        const data = result.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });

        this.$store.dispatch("discover/getData", data);
        this.$store.dispatch("discover/getLoading", false);
      });
    },
    getRecommendationData() {
      this.getRecommendation().then((res) => {
        const playlist = res.playlist;
        const subliminal = res.subliminal;
        let result = playlist.concat(subliminal);

        const data = result.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });

        this.$store.dispatch("recommendation/getData", data);
        this.$store.dispatch("recommendation/getLoading", false);
      });
    },
    getRecentPlayedData() {
      const data = {
        user_id: this.user.user_id,
        subscription_id: this.subscriptionId,
      };

      this.getAudioHistory(data).then((res) => {
        const playlist = res.playlist;
        const subliminal = res.subliminal;
        let result = playlist.concat(subliminal);

        let results = result.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });

        this.$store.dispatch("recent/getPlayed", results);
        this.$store.dispatch("recent/getPlayedLoading", false);
      });
    },
    getRecentSearchData() {
      const data = {
        user_id: this.user.user_id,
      };

      this.getSearch(data).then((res) => {
        this.$store.dispatch("recent/getSearch", res);
        this.$store.dispatch("recent/getSearchLoading", false);
      });
    },
    getOwnPlaylistData() {
      const data = {
        user_id: this.user.user_id,
      };
      this.getOwnPlaylist(data).then((res) => {
        const result = res.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });

        this.$store.dispatch("playlist/getOwnData", result);
        this.$store.dispatch("playlist/getOwnLoading", false);
      });
    },
    getSubscriptionData() {
      this.getSubscriptions().then((res) => {
        this.$store.dispatch("subscription/getData", res);
      });
    },
    handleResize() {
      this.$store.dispatch("screen/getWidth", window.innerWidth);
      this.$store.dispatch("screen/getHeight", window.innerHeight);
    },
  },
};
</script>

<style>
.pages {
  max-width: 1350px;
  margin-block: 20px;
}
</style>
