<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you struggle with staying organized and find yourself frequently overwhelmed by clutter, deadlines, or responsibilities, this subliminal can help you enhance your organization skills and boost your productivity and efficiency."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Developing better time management skills to prioritize tasks effectively.",
        "Increasing focus and concentration, enabling you to complete tasks more efficiently.",
        "Reducing procrastination and cultivating a proactive mindset.",
        "Creating a clutter-free environment that promotes peace of mind and clarity.",
        "Enhancing your ability to plan and set goals for better long-term organization."
      ]
    },
    {
      type: 'paragraph',
      text: "By consistently listening to this subliminal, you can reprogram your subconscious mind to naturally gravitate towards organized habits and behaviors. You may start to notice improvements in your ability to stay on top of tasks, manage your time effectively, and create a harmonious balance between your personal and professional life. Remember, practice makes perfect, so be patient with yourself and embrace the process of becoming more organized!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Enhancing your organization skills can have a profound impact on your productivity, efficiency, and overall success in various aspects of your life. This subliminal is designed to support you in developing and strengthening these skills. Before you begin listening, consider implementing these strategies:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your goals: Identify the areas of your life where you wish to improve your organization skills, such as work, home, or personal projects.",
        "Create a plan: Break down your tasks and responsibilities into manageable chunks, and establish a clear plan and timeline to accomplish them.",
        "Eliminate clutter: Declutter and organize your physical and digital spaces to minimize distractions and promote a clear mind.",
        "Visualize success: Imagine yourself being organized, focused, and in control of your tasks and obligations, feeling productive and accomplished.",
        "Maintain focus: While listening to the subliminal affirmations, keep your desired organizational skills and outcomes in mind, reinforcing your commitment to improvement."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen and immerse yourself in this subliminal, you may notice positive shifts in your mindset, habits, and ability to stay organized and efficient. Embrace the transformational power of organization skills and embark on a journey of increased productivity and success. Let's begin and unleash your full potential!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Organization Skills Enhancement subliminal! By investing time and effort into improving your organization skills, you are setting yourself up for increased productivity and efficiency in all areas of your life. Now that you've finished the session, take a moment to reflect on your progress and consider how you can continue to enhance your organizational abilities."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Declutter your space: Clear out any unnecessary items and create an organized working environment.",
        "Implement a system: Find a method of organizing that works for you, such as creating to-do lists or using digital tools.",
        "Prioritize tasks: Determine what is most important and focus on completing those tasks first.",
        "Set realistic goals: Break down larger tasks into smaller, manageable steps to stay organized and motivated."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, improving organization skills is an ongoing process. Be patient with yourself and embrace the journey of becoming more productive and efficient. By implementing these post-listening tips, you will continue to make progress and experience the benefits of enhanced organization skills. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Organization Skills Enhancement subliminal improve my productivity and efficiency?`, 
    answer: `The Organization Skills Enhancement subliminal works by influencing your subconscious mind to develop and strengthen effective organizational habits. It helps you become more focused, structured, and efficient in managing your time, tasks, and resources. By listening to the subliminal regularly, you can reprogram your mindset to prioritize and streamline your daily activities, leading to increased productivity and overall efficiency in various aspects of your life.`,
    open: false
  },
  { 
    question: `How long does it take to see improvements in my organization skills with this subliminal?`, 
    answer: `The time it takes to see improvements in organization skills varies from person to person. Some individuals may notice positive changes in their productivity and efficiency relatively quickly, while others may need more time and consistent listening for the subliminal messages to take effect. We recommend listening to the subliminal daily and consistently over a sustained period to maximize its potential benefits.`,
    open: false 
  },
  { 
    question: `Can the Organization Skills Enhancement subliminal help with specific areas of organization, such as time management or decluttering?`, 
    answer: `Yes, the Organization Skills Enhancement subliminal can help improve various areas of organization, including time management, decluttering, prioritization, and task management. By influencing your subconscious mind, the subliminal messages work to develop and strengthen overall organizational skills, which can then be applied to specific areas based on your needs and goals.`,
    open: false 
  },
  { 
    question: `Is it necessary to make any changes to my daily routine when using the Organization Skills Enhancement subliminal?`, 
    answer: `While not necessary, making small changes to your daily routine can augment the effectiveness of the Organization Skills Enhancement subliminal. For example, creating a to-do list, setting specific goals, and establishing a structured schedule can help reinforce the positive habits and mindset encouraged by the subliminal. Additionally, practicing mindfulness and self-reflection can help you identify any areas of improvement and prevent overwhelm.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the Organization Skills Enhancement subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Organization Skills Enhancement subliminal. However, if you have any concerns related to your organization skills or mental health, it is always advisable to consult with a professional for personalized guidance and support.`,
    open: false 
  },
  { 
    question: `Can the Organization Skills Enhancement subliminal replace professional guidance or therapy for organization-related issues?`, 
    answer: `No, the Organization Skills Enhancement subliminal is not a substitute for professional guidance, therapy, or counseling. While it can support your efforts to improve organization skills, it may be beneficial to seek guidance from a qualified professional to address any underlying issues or specific challenges you may be facing in organizing your life.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>