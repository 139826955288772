<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Becoming successful in forex and currency trading requires more than just luck. It requires the right skills, strategies, and mindset. This subliminal is designed to help you develop these necessary qualities and increase your chances of success."
    },
    {
      type: 'heading',
      text: 'Key aspects this subliminal focuses on:'
    },
    {
      type: 'list',
      items: [
        "Developing a strong foundation in forex and currency trading knowledge.",
        "Enhancing your analytical and decision-making skills.",
        "Building discipline and patience to follow your trading plan.",
        "Managing emotions and avoiding impulsive trades.",
        "Increasing confidence in your trading abilities.",
        "Staying motivated and resilient in the face of challenges.",
        "Adopting a positive and success-oriented mindset."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to align with the qualities and mindset that are essential for successful forex and currency trading. Remember, trading is a journey that requires continuous learning and improvement, but with the right mindset and skills, you can increase your chances of achieving success."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a journey towards success in forex and currency trading requires not only knowledge and skills but also the right mindset. This subliminal is designed to support and enhance your trading experience. Before you begin, consider these preparation tips to optimize your learning:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Educate yourself: Invest time in learning about forex and currency trading to gain a solid foundation of knowledge.",
        "Establish a trading plan: Define your goals, risk tolerance, and trading strategies to guide your decision-making process.",
        "Cultivate discipline: Develop the ability to stick to your trading plan and manage emotions during trades.",
        "Visualize success: Imagine yourself making successful trades, feeling confident, and achieving your financial goals.",
        "Focus on your intention: Maintain a clear focus on your desire for success in forex and currency trading while listening to the subliminal affirmations."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, it can help reinforce positive beliefs, enhance your skills, and strengthen your determination to achieve success in forex and currency trading. Embrace this opportunity to align your mindset with your trading goals, and may your journey be filled with growth and prosperity."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Forex and Currency Trading Success session! By investing time in developing your skills, strategies, and mindset, you have taken a crucial step towards achieving success in forex and currency trading. As you reflect on your experience, consider the following post-listening tips to further enhance your journey."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Continue learning: Engage in ongoing education through books, courses, and seminars to deepen your knowledge.",
        "Practice disciplined trading: Develop a trading plan and stick to it, while managing your risk effectively.",
        "Analyze your trades: Review your past trades regularly to identify patterns and areas for improvement.",
        "Maintain emotional balance: Cultivate a mindset of resilience and discipline to navigate the ups and downs of the market with calmness."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, success in forex and currency trading is a journey that requires continuous learning, practice, and self-reflection. Embrace the challenges and remain dedicated to developing your skills and strategies. With the right mindset and consistent effort, you can achieve your goals in the exciting world of currency trading. Best of luck on your trading journey!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Can the Forex and Currency Trading Success subliminal guarantee profits in trading?`, 
    answer: `No, the Forex and Currency Trading Success subliminal is not a guaranteed method for making profits in trading. It is designed to help you develop the right skills, strategies, and mindset for successful trading, but the outcome is ultimately dependent on various factors, including market conditions and individual trading decisions.`,
    open: false
  },
  { 
    question: `How can the Forex and Currency Trading Success subliminal help me improve my trading skills?`, 
    answer: `The subliminal messages in Forex and Currency Trading Success are designed to positively influence your subconscious mind, helping you develop a disciplined trading approach, emotional control, and focus. By consistently listening to the subliminal, you may notice improvements in your decision-making skills, risk management, and ability to analyze market trends. However, it is important to complement the subliminal with a comprehensive education in trading and practical experience.`,
    open: false 
  },
  { 
    question: `Do I need any prior trading experience to benefit from the Forex and Currency Trading Success subliminal?`, 
    answer: `No, you do not need prior trading experience to benefit from the subliminal. It is designed to help individuals at various skill levels improve their trading mindset and abilities. However, it is recommended to supplement the subliminal with education, practice, and a solid understanding of trading principles to maximize your trading potential.`,
    open: false 
  },
  { 
    question: `How long does it take to see results from the Forex and Currency Trading Success subliminal?`, 
    answer: `Results may vary depending on your dedication to listening to the subliminal and your commitment to developing your trading skills. Some individuals may notice improvements in their trading mindset and abilities relatively quickly, while others may require more time and practice. Consistent and dedicated listening is key to maximizing the benefits of the subliminal.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Forex and Currency Trading Success subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Forex and Currency Trading Success subliminal. However, it is important to approach trading responsibly and engage in proper risk management to minimize potential losses. The subliminal is intended to supplement your trading journey and should not be the sole basis for making trading decisions.`,
    open: false 
  },
  { 
    question: `Can the Forex and Currency Trading Success subliminal replace education and practice in trading?`, 
    answer: `No, the subliminal is not a substitute for proper education and practice in trading. While it can help develop the right mindset and strategies, it is essential to supplement it with a comprehensive understanding of trading principles and practical experience. Continuous learning and refining your skills are crucial for long-term success in forex and currency trading.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>