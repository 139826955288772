<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you struggle with a fear of criticism, you're not alone. Many people find it challenging to handle feedback or judgment without feeling anxious or defensive. This subliminal is designed to help you overcome that fear and build your self-esteem, allowing you to confidently navigate criticism."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help you:"
    },
    {
      type: 'list',
      items: [
        "By addressing and reframing negative beliefs and thought patterns related to criticism.",
        "By boosting your self-esteem and confidence in your own abilities.",
        "By promoting a mindset of growth and learning from feedback rather than feeling defensive.",
        "By helping you develop effective communication skills to express yourself assertively.",
        "By reducing anxiety and stress associated with the fear of criticism."
      ]
    },
    {
      type: 'paragraph',
      text: "With consistent use, this subliminal can empower you to handle criticism in a healthier and more confident way. You may find that you're able to receive feedback without spiraling into self-doubt or defensiveness, and instead use it as an opportunity for growth. Remember, building self-esteem and overcoming fears takes time, so be patient with yourself as you progress."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Facing criticism can be intimidating and can lead to feelings of low self-esteem and self-doubt. This subliminal is designed to help you overcome your fear of criticism and build your confidence in handling any kind of feedback or judgment. Before you start listening, here are some tips to consider:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Recognize your worth: Remind yourself of your value and inner strength. Know that your self-worth is not determined by the opinions of others.",
        "Focus on self-acceptance: Embrace your imperfections and accept that everyone receives criticism at some point. It's all part of growth and improvement.",
        "Rewrite negative narratives: Challenge negative thoughts and replace them with positive affirmations about yourself and your abilities.",
        "Practice self-compassion: Treat yourself with kindness and understanding, just as you would a close friend facing criticism."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal, allow the positive affirmations to rewire your mindset and empower you to handle criticism with confidence. With practice, you will develop a stronger self-esteem and the ability to take feedback constructively. Embrace this opportunity for personal growth, and let go of the fear of criticism. You have the power to build your self-esteem and overcome any challenges that come your way."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Fear of Criticism Removal subliminal! You've taken a courageous step in overcoming your fear of criticism and building your self-esteem. Now, it's important to reflect on your experience and continue the journey towards greater self-confidence and resilience."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your growth: Take time to journal about the changes you have noticed within yourself.",
        "Challenge negative self-talk: Practice positive affirmations and replace self-critical thoughts with empowering ones.",
        "Seek constructive feedback: Surround yourself with supportive people who can provide valuable input and guidance.",
        "Practice self-compassion: Be kind to yourself, acknowledge your progress, and celebrate small victories."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming the fear of criticism is an ongoing process. As you continue your journey towards self-confidence, be patient with yourself and embrace opportunities for growth. Believe in your abilities and trust that you are capable of handling any feedback or judgment that comes your way. Keep shining!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Fear of Criticism Removal subliminal help me overcome my fear of criticism?`, 
    answer: `The Fear of Criticism Removal subliminal is designed to reprogram your subconscious mind by replacing negative thought patterns and beliefs with positive and empowering ones. By regularly listening to the subliminal, you can gradually build your self-esteem and develop a more confident and resilient mindset when it comes to handling criticism. Over time, you may notice a decrease in fear and anxiety associated with criticism, allowing you to approach feedback and judgment with more self-assurance.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Fear of Criticism Removal subliminal?`, 
    answer: `Results can vary from person to person, and it may take some time to reprogram deep-seated fears and beliefs. Consistent and regular listening to the subliminal can help expedite the process. Some individuals may notice positive changes in their attitude towards criticism within a few weeks, while others may require several months of listening to fully overcome their fear. Patience and perseverance are key in achieving lasting results.`,
    open: false 
  },
  { 
    question: `Can the Fear of Criticism Removal subliminal completely eliminate my fear of criticism?`, 
    answer: `While the Fear of Criticism Removal subliminal can help you develop a more confident and resilient mindset when it comes to criticism, individual results may vary. It is important to remember that eliminating an ingrained fear completely may require consistent effort, self-reflection, and personal growth. The subliminal can be a powerful tool in overcoming your fear, but it is ultimately up to you to actively apply the positive changes in your daily life.`,
    open: false 
  },
  { 
    question: `Will the Fear of Criticism Removal subliminal make me impervious to criticism?`, 
    answer: `The Fear of Criticism Removal subliminal is designed to help you develop a more positive and resilient mindset when faced with criticism. It can empower you to handle feedback and judgment with more confidence, self-assurance, and self-esteem. While it can strengthen your emotional resilience, it does not guarantee that you will be impervious to criticism. It is important to continue practicing self-care, self-compassion, and self-reflection to maintain a healthy perspective on feedback from others.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Fear of Criticism Removal subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Fear of Criticism Removal subliminal. However, if you have any concerns about your mental health or feel overwhelmed during the process, it is recommended to seek support from a mental health professional.`,
    open: false 
  },
  { 
    question: `Can I listen to the Fear of Criticism Removal subliminal while doing other activities?`, 
    answer: `To maximize the effectiveness of the subliminal messages, it is recommended to listen to the Fear of Criticism Removal subliminal in a quiet and relaxed environment where you can focus on the audio. This allows your subconscious mind to fully absorb the positive affirmations without distractions. However, if you prefer to listen while engaging in other activities, you can still benefit from the subliminal, although the impact may be slightly reduced.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>