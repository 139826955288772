import { render, staticRenderFns } from "./214-positive-relationship-with-lover.vue?vue&type=template&id=19acdc0d&scoped=true&"
import script from "./214-positive-relationship-with-lover.vue?vue&type=script&lang=js&"
export * from "./214-positive-relationship-with-lover.vue?vue&type=script&lang=js&"
import style0 from "./214-positive-relationship-with-lover.vue?vue&type=style&index=0&id=19acdc0d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19acdc0d",
  null
  
)

export default component.exports