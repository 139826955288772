<template>
  <Mood />
</template>

<script>
import Mood from '@/views/Admin/Mood/index.vue'
export default {
  components: {
    Mood
  },
  data: () => {
    return {}
  }
}
</script>
