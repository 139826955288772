<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Having a positive relationship with your boss is crucial for your career growth and job satisfaction. This subliminal is designed to help you build a harmonious and positive connection with your boss."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Enhancing communication skills to effectively express your ideas, concerns, and contributions to your boss.",
        "Developing empathy and understanding to build a strong rapport and connection with your boss.",
        "Increasing confidence and assertiveness in professional interactions and decision-making processes.",
        "Managing conflicts and disagreements in a constructive and productive manner.",
        "Improving job satisfaction and overall workplace morale."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind to cultivate a positive mindset and develop the necessary skills to nurture a healthy working relationship with your boss. Remember, building a positive relationship takes time and effort, but it can lead to a more fulfilling and successful professional journey."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Building a positive relationship with your boss can have a significant impact on your career growth and job satisfaction. This subliminal is designed to help you develop a harmonious connection with your boss. Before you start listening, consider these strategies:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Identify your goals: Reflect on what you want to achieve in your relationship with your boss, such as improved communication, trust, or collaboration.",
        "Practice empathy: Put yourself in your boss's shoes and try to understand their perspective and challenges.",
        "Focus on positivity: Approach interactions with your boss with a positive mindset, highlighting their strengths and building rapport.",
        "Clear communication: Clearly express your thoughts, ideas, and concerns to your boss while maintaining respect and professionalism."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal, it's important to remember that building positive relationships takes time and effort. Consistently applying these strategies, combined with the power of subliminal affirmations, can help transform your relationship with your boss. Keep an open mind, set your intentions, and get ready to enhance your career growth and job satisfaction."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the subliminal session on developing a positive relationship with your boss! By investing time and effort into cultivating a harmonious connection with your boss, you're setting yourself up for enhanced career growth and greater job satisfaction. Take a moment to reflect on the valuable insights you've gained."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice open communication: Foster transparency and clarity by regularly discussing your goals, challenges, and expectations with your boss.",
        "Build trust: Show your reliability, competence, and commitment through consistent performance and meeting deadlines.",
        "Seek feedback: Proactively ask for feedback to understand your boss's expectations and how you can improve.",
        "Suggest solutions: Offer constructive suggestions and solutions when presented with challenges or issues at work."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, building a positive relationship with your boss is an ongoing process that requires patience and continued effort. Focus on open communication, trust-building, and proactive engagement to foster a supportive and thriving work environment. With your dedication and positive attitude, you are well on your way to achieving greater success in your career!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can I build a positive relationship with my boss?`, 
    answer: `Building a positive relationship with your boss involves effective communication, demonstrating respect and professionalism, and delivering high-quality work consistently. It's important to understand your boss's expectations and goals, and actively engage in open and honest conversations with them. Building trust and rapport by being reliable, proactive, and receptive to feedback can also contribute to a positive working relationship.`,
    open: false
  },
  { 
    question: `What should I do if I have a disagreement or conflict with my boss?`, 
    answer: `If you have a disagreement or conflict with your boss, it's essential to approach the situation respectfully and professionally. Take the time to understand their perspective and communicate your concerns calmly and constructively. Seek a resolution through open dialogue and compromise, focusing on finding common ground and maintaining a positive working relationship.`,
    open: false 
  },
  { 
    question: `How can I proactively manage my relationship with my boss?`, 
    answer: `To proactively manage your relationship with your boss, keep the lines of communication open, seek regular feedback, and actively seek opportunities for growth and improvement. Show initiative, take responsibility for your work, and align your goals with the goals of your team and organization. Demonstrating a positive attitude, being a team player, and offering support when needed can also contribute to a positive and harmonious relationship with your boss.`,
    open: false 
  },
  { 
    question: `What if my boss is not receptive to building a positive relationship?`, 
    answer: `If your boss is not receptive to building a positive relationship, it's important to focus on what you can control. Continue to communicate professionally, deliver high-quality work, and seek feedback from other sources, such as peers or mentors. If necessary, consider discussing your concerns with someone in HR or seeking guidance from a supervisor or manager who can provide support and help facilitate a constructive resolution.`,
    open: false 
  },
  { 
    question: `Can a positive relationship with my boss improve my career growth?`, 
    answer: `Yes, developing a positive relationship with your boss can improve your career growth. A positive relationship can lead to increased trust, more opportunities for growth and development, and greater job satisfaction. It can also make you more visible and valued within the organization, potentially opening doors for promotions or new responsibilities.`,
    open: false 
  },
  { 
    question: `Is it possible to have a positive relationship with a difficult boss?`, 
    answer: `Yes, it is possible to have a positive relationship with a difficult boss. While challenging, it may require patience, empathy, and adaptability. Practice active listening, seek to understand their perspective, and identify ways to support their goals and priorities. Maintaining a professional approach, setting clear boundaries, and seeking support from colleagues or mentors can also help navigate a relationship with a difficult boss.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>