export const admin = {
  namespaced: true,
  state: {
    info: null,
    token: null,
    cover: null,
  },
  getters: {},
  actions: {
    getInfo({ commit }, data) {
      commit("getInfo", data);
    },
    getToken({ commit }, data) {
      commit("getToken", data);
    },
    getCover({ commit }, data) {
      commit("getCover", data);
    },
    getLocalInfo({ commit }, data) {
      commit("getLocalInfo", data);
    },
    getLocalToken({ commit }, data) {
      commit("getLocalToken", data);
    },
  },
  mutations: {
    getInfo(state, data) {
      state.info = data;
    },
    getToken(state, data) {
      state.token = data;
    },
    getCover(state, data) {
      state.cover = data;
    },
    getLocalInfo(state, data) {
      localStorage.setItem("admin", data);
    },
    getLocalToken(state, data) {
      localStorage.setItem("admin_token", data);
    },
  },
};
