<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "For musicians looking to enhance their talent and skills in playing musical instruments, this subliminal can help you reach new heights of musicality and mastery. By targeting your subconscious mind, it can unlock your full potential and accelerate your musical progress."
    },
    {
      type: 'heading',
      text: 'Key features of this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Increasing focus, concentration, and overall mental clarity, allowing for better practice sessions and performances.",
        "Boosting motivation and discipline to consistently practice and improve your skills.",
        "Enhancing muscle memory and coordination for smoother and more precise playing.",
        "Managing performance anxiety and stage fright, enabling you to perform with confidence.",
        "Stimulating creativity and musical expression, helping you develop your unique style and sound."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your daily routine, you can tap into your musical potential and elevate your playing to new levels. Consistent listening will pave the way for growth, allowing you to express yourself more fully through your chosen instrument. Keep practicing, stay inspired, and enjoy the journey of musical advancement!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Improving your talent and skills in playing musical instruments can be a fulfilling and rewarding journey. This subliminal is designed to support you in reaching new heights of musicality and mastery. Before you start listening, consider these tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Determine what you want to achieve in your musical journey, whether it's mastering a specific technique, improving your improvisation skills, or becoming more proficient in playing a certain genre of music.",
        "Create a practice routine: Develop a regular practice schedule that allows you to focus and dedicate time to improving your musical skills.",
        "Visualize success: Envision yourself excelling in your musical abilities, performing flawlessly, and captivating your audience with your talent.",
        "Maintain a positive mindset: Believe in your potential, embrace the challenges, and stay motivated even when faced with difficulties or setbacks."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal, it can help reprogram your subconscious mind, enhance your musical abilities, and unlock hidden potential. Combine this powerful tool with focused practice, dedication, and a passion for music, and you'll be on your way to reaching new heights of talent and mastery. Embrace the journey, stay committed, and let the music guide you."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Musical Instruments Talent and Skills Enhancement subliminal! You have taken a significant step towards enhancing your musicality and mastery of playing musical instruments. Now, it's time to reflect on your experience and continue nurturing your talent."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your progress: Take some time to think about how your skills have improved since starting the subliminal.",
        "Play regularly: Practice regularly to further develop your talent and skills. Consistency is key!",
        "Set goals: Set specific, achievable goals to work towards. It could be mastering a challenging piece or improving a specific technique.",
        "Seek feedback: Share your progress with others and seek feedback from mentors or fellow musicians. This will help you refine your skills and gain new insights."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, mastering a musical instrument takes time and dedication. Enjoy the journey as you continually strive to reach new heights of musicality. Keep practicing, stay inspired, and embrace the beauty of your musical expression. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Musical Instruments Talent and Skills Enhancement subliminal enhance my musical abilities?`, 
    answer: `The Musical Instruments Talent and Skills Enhancement subliminal is designed to work on a subconscious level, helping you develop a deeper connection with your instrument and improve your technical proficiency. It can boost your confidence, focus, and overall musicality, allowing you to reach new heights in your playing. By consistently listening to the subliminal, you can reinforce positive beliefs and habits that support your musical development.`,
    open: false
  },
  { 
    question: `How often should I listen to the Musical Instruments Talent and Skills Enhancement subliminal?`, 
    answer: `We recommend listening to the Musical Instruments Talent and Skills Enhancement subliminal regularly, ideally daily for at least 30 minutes. Consistency is key in reprogramming your subconscious mind and reinforcing positive beliefs and behaviors. However, you can adjust the listening duration and frequency to fit your schedule and personal preferences.`,
    open: false 
  },
  { 
    question: `Can the subliminal help me overcome performance anxiety or stage fright?`, 
    answer: `Yes, the Musical Instruments Talent and Skills Enhancement subliminal can help you overcome performance anxiety or stage fright by addressing the underlying subconscious beliefs and emotions that contribute to those feelings. It can assist in cultivating a calm and confident mindset, allowing you to perform at your best and enjoy your musical experience.`,
    open: false 
  },
  { 
    question: `Does the subliminal work with all musical instruments?`, 
    answer: `Yes, the Musical Instruments Talent and Skills Enhancement subliminal is designed to enhance talent and skills for all types of musical instruments. Whether you play the piano, guitar, violin, flute, or any other instrument, the subliminal can help you develop a deeper connection with your instrument and improve your overall musical abilities.`,
    open: false 
  },
  { 
    question: `Are there any side effects of listening to the Musical Instruments Talent and Skills Enhancement subliminal?`, 
    answer: `No, there are no known side effects associated with listening to the Musical Instruments Talent and Skills Enhancement subliminal. However, if you have any concerns or underlying medical conditions, it is recommended to consult with your healthcare provider before starting any new self-improvement practice.`,
    open: false 
  },
  { 
    question: `Can the subliminal replace practice and dedicated learning?`, 
    answer: `No, the Musical Instruments Talent and Skills Enhancement subliminal is not intended to replace practice and dedicated learning. It is a tool that can support and enhance your musical journey by promoting a focused and motivated mindset. Continued practice, discipline, and learning are essential for developing and mastering your skills as a musician.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>