<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Are you looking to enhance your flirting skills and boost your confidence with women? Look no further - this subliminal is designed to help you effortlessly attract women with charm and charisma."
    },
    {
      type: 'heading',
      text: 'How this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Increasing self-confidence and self-assuredness in social interactions.",
        "Enhancing your natural charm and charisma to captivate women's attention.",
        "Reducing social anxiety and self-doubt, allowing you to feel more comfortable and relaxed in flirting situations.",
        "Improving body language and non-verbal communication skills to make a strong and positive impression.",
        "Boosting your overall attractiveness and desirability to women."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to develop the confidence and skills needed for successful flirting. As you become more self-assured and charismatic, you'll find it easier to connect with women and leave a lasting positive impression. Remember, practice makes perfect, and with this subliminal, you're on your way to becoming a master at flirting!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Flirting with confidence can be a game-changer when it comes to attracting and connecting with women. This subliminal is designed to help boost your confidence, charm, and charisma in the realm of flirting. Before you start listening, here are a few tips to keep in mind:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Recognize your own worth: Understand and embrace the unique qualities that make you attractive and desirable.",
        "Practice positive self-talk: Replace self-doubt or negative thoughts with affirming statements that reinforce your confidence and charm.",
        "Embody charisma: Visualize yourself exuding confidence and charisma, effortlessly engaging with others and exuding magnetic charm.",
        "Focus on your intention: While listening to the subliminal affirmations, keep your thoughts directed towards improving your flirting skills and feeling more confident in social interactions."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal is designed to work in harmony with your subconscious mind, supporting you in developing the skills and mindset necessary to attract and connect with women effortlessly. As you listen regularly, you may notice a positive shift in your confidence levels and the attention you receive from others. Embrace this opportunity for growth, set your intention, and let your natural charm shine through as you embark on this journey of improving your flirting skills."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Flirting Confidence for Men subliminal! By focusing on enhancing your confidence in flirting, you've taken a bold step towards becoming more charismatic and attractive. Now, it's time to reflect on your experience and continue growing in this area."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice self-reflection: Take some time to think about the progress you've made in boosting your confidence and charisma.",
        "Step out of your comfort zone: Challenge yourself to approach and engage with new people, honing your flirting skills.",
        "Embrace rejection: Remember that rejection is a natural part of the dating process. Regard it as an opportunity for growth and learning.",
        "Stay positive and authentic: Maintain a positive mindset and be yourself while interacting with others. Authenticity is attractive."
      ]
    },
    {
      type: 'paragraph',
      text: "Building confidence in flirting is a continuous journey, and each interaction is an opportunity for growth. Embrace the process and keep refining your flirting skills. Remember, with confidence, charm, and authenticity, you can attract and connect with women effortlessly. Go out there and shine!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Flirting Confidence for Men subliminal help me?`, 
    answer: `The Flirting Confidence for Men subliminal is designed to boost your confidence and enhance your flirting skills, making it easier for you to attract women with charm and charisma. By listening to the subliminal messages, you can reprogram your subconscious mind to exude self-assurance, improve body language, and project an attractive aura. This can ultimately increase your chances of successful interactions and connections with women.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Flirting Confidence for Men subliminal?`, 
    answer: `Results may vary depending on the individual and their unique circumstances. Some people may experience positive changes in their confidence and flirting abilities relatively quickly, while others may require more time and consistent listening to fully internalize the subliminal messages. We recommend listening to the subliminal daily and consistently to maximize its effectiveness.`,
    open: false 
  },
  { 
    question: `Can the Flirting Confidence for Men subliminal work for everyone?`, 
    answer: `The Flirting Confidence for Men subliminal is designed to work for most individuals who wish to enhance their flirting skills and boost their confidence. However, it is important to note that individual results may vary. The effectiveness of the subliminal can depend on factors such as individual receptivity, consistency in listening, and other personal circumstances. It is recommended to approach the subliminal with an open and positive mindset for best results.`,
    open: false 
  },
  { 
    question: `Can the Flirting Confidence for Men subliminal replace genuine connection and communication in flirting?`, 
    answer: `No, the subliminal is intended to enhance your confidence and flirting skills but cannot replace genuine connection and communication. While the subliminal can boost your self-assurance and attractiveness, developing real connections with women still requires authentic communication, empathy, and respect. It is important to cultivate genuine feelings and intentions in your interactions for deeper and more meaningful connections.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Flirting Confidence for Men subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Flirting Confidence for Men subliminal. However, it is important to approach the subliminal with a responsible and ethical mindset, respecting the boundaries and consent of others in your interactions. The subliminal is designed to enhance your confidence and social skills, not to manipulate or deceive others.`,
    open: false 
  },
  { 
    question: `Can the Flirting Confidence for Men subliminal help with long-term relationships or only for initial attraction?`, 
    answer: `While the Flirting Confidence for Men subliminal can help enhance your confidence and flirting skills, it can be beneficial at any stage of a relationship. Whether you are looking to attract someone initially or maintain a long-term relationship, the subliminal can assist in building and maintaining a healthy and confident mindset for successful interactions with women.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>