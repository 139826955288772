<template>
  <div :class="`support-card ${isreverse} ${isfull} d-flex justify-center align-center ma-3 g-3`">
    <div class="image"></div>
    <div class="text pa-3">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    reverse: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    full: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  computed: {
    isreverse() {
      return this.reverse ? 'reverse' : ''
    },
    isfull() {
      return this.full ? 'full' : ''
    }
  }
}
</script>

<style scoped>
.support-card.full {
  flex-direction: column;
}

.image {
  width: 240px;
  height: 240px;

  background: green;
}

.support-card.reverse>.image {
  order: 2;
}

.support-card.reverse>.text {
  order: 1;
}

.text {
  width: 480px;
  height: auto;
}


@media screen and (max-width: 800px) {

  .support-card {
    flex-direction: column;
  }

  .support-card.reverse>.image {
    order: 1;
  }

  .support-card.reverse>.text {
    order: 2;
  }

  .text {
    width: 100%;
  }
}
</style>