<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you suffer from restless leg syndrome (RLS), you know how disruptive it can be to your sleep and daily life. This subliminal is designed to offer relief by targeting the underlying causes of RLS and promoting relaxation and better sleep."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help:"
    },
    {
      type: 'list',
      items: [
        "Reducing the uncomfortable sensations and urges to move your legs commonly associated with RLS.",
        "Promoting relaxation and calmness, making it easier to fall asleep and stay asleep.",
        "Improving the quality of your sleep by reducing RLS symptoms that may disrupt your sleep patterns.",
        "Enhancing your overall well-being by relieving the physical discomfort and restlessness caused by RLS.",
        "Supporting a more restful sleep, which can lead to improved energy levels, mood, and daily functioning."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your nightly routine, you can target the subconscious mind and make positive changes to manage your RLS symptoms. As you listen consistently, you may find relief from the discomfort and experience a significant improvement in the quality of your sleep and daily life. Remember, individual results may vary, so be patient and give it time."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Living with restless leg syndrome can be challenging, but this subliminal is designed to help you find relief and improve the quality of your sleep and daily life. Before you start listening, here are some preparation tips to enhance your experience with this program:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a soothing sleep environment: Ensure your bedroom is comfortable, quiet, and at a cool temperature to promote quality sleep.",
        "Establish a wind-down routine: Engage in relaxing activities, such as reading or taking a warm bath, before bed to prepare your body for sleep.",
        "Avoid stimulants: Limit or avoid caffeine, nicotine, and alcohol, as these can worsen restless leg symptoms.",
        "Stay consistent: Make a commitment to listen to this subliminal regularly, ideally before bed, to maximize its benefits."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your routine and following these preparation tips, you may experience improvements in your restless leg symptoms and overall sleep quality. Embrace this opportunity to prioritize your well-being and embark on a journey towards finding relief and better sleep."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Restless Leg Syndrome session! By actively seeking relief from this condition, you have taken an important step towards improving your sleep and overall quality of life. As you move forward, it's essential to reflect on your experience and continue implementing strategies to manage restless leg syndrome."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Note any changes: Keep a journal to track any changes or improvements in your restless leg syndrome symptoms.",
        "Maintain a sleep routine: Establish a consistent sleep schedule to support better sleep quality.",
        "Engage in relaxation techniques: Practice deep breathing, meditation, or gentle stretching to relax your body and relieve symptoms.",
        "Consider lifestyle adjustments: Explore potential triggers such as caffeine, nicotine, and certain medications, and make necessary changes.",
        "Consult a healthcare professional: If your symptoms persist or worsen, seek medical advice for further evaluation and personalized management strategies."
      ]
    },
    {
      type: 'paragraph',
      text: "Managing restless leg syndrome is an ongoing process. Be patient with yourself as you explore different techniques and strategies to find relief. Remember, there is hope, and with perseverance, you can improve your sleep and enhance your daily life. Wishing you peaceful nights and restful days!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What is Restless Leg Syndrome (RLS)?`, 
    answer: `Restless Leg Syndrome (RLS) is a neurological disorder characterized by an irresistible urge to move the legs, usually accompanied by uncomfortable or painful sensations. These sensations typically occur at night or when the body is at rest, leading to difficulty falling asleep and disrupted sleep patterns. RLS can significantly impact the quality of life and daily functioning of those affected.`,
    open: false
  },
  { 
    question: `Can the Restless Leg Syndrome subliminal provide relief from symptoms?`, 
    answer: `The Restless Leg Syndrome subliminal is designed to promote relaxation, reduce stress, and help reprogram the subconscious mind towards healthier sleep patterns. While it may provide relief for some individuals, it is important to note that it is not a substitute for professional medical advice or treatment. If you are experiencing symptoms of Restless Leg Syndrome, we recommend consulting with a healthcare professional.`,
    open: false 
  },
  { 
    question: `How can I use the Restless Leg Syndrome subliminal to manage my symptoms?`, 
    answer: `The Restless Leg Syndrome subliminal can be used by listening to the audio regularly, preferably before bedtime or during a relaxation period. The subliminal messages are designed to positively influence your subconscious mind, promoting relaxation and reducing the urge to move your legs. Consistency is key, and incorporating the subliminal into a relaxing bedtime routine can enhance its effectiveness.`,
    open: false 
  },
  { 
    question: `Are there any known risks or side effects of using the Restless Leg Syndrome subliminal?`, 
    answer: `There are no known risks or side effects associated with using the Restless Leg Syndrome subliminal. However, if you have any concerns or underlying health conditions, it is always best to consult with your healthcare provider before trying any new therapies or treatments.`,
    open: false 
  },
  { 
    question: `Can the Restless Leg Syndrome subliminal be used alongside other treatments or medications?`, 
    answer: `The Restless Leg Syndrome subliminal can be used as a complementary tool alongside other treatments or medications prescribed by a healthcare professional. It is important to work with your healthcare provider to create a comprehensive treatment plan that addresses your individual needs and symptoms.`,
    open: false 
  },
  { 
    question: `Will the Restless Leg Syndrome subliminal provide instant relief from symptoms?`, 
    answer: `Results may vary from person to person. While some individuals may experience immediate relief from symptoms upon listening to the subliminal, others may need more time and consistent listening for the positive affirmations to take effect. We recommend listening to the subliminal regularly and being patient as your body and mind adjust to the positive changes.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>