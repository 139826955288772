<template>
  <div>
    <Dashboard />
  </div>
</template>

<script>
import Dashboard from '@/views/Admin/Dashboard/index.vue'
export default {
  components: {
    Dashboard
  },
  data: () => {
    return {}
  }
}
</script>
