<template>
  <Dialog :dialog="dialog" :width="350" :title="`Edit User`" @closeDialog="closeDialog">
    <div>
      <div class="upload-container d-flex justify-center ma-5 mb-0">
        <Upload :upload="dialog" :files="files" v-if="dialog" @getImageName="getImageName" />
      </div>

      <p class="mb-1">Display Name</p>
      <v-text-field solo dense hide-details class="mb-3" placeholder="Name" v-model="name"></v-text-field>

      <v-row class="ma-0" style="gap: 15px">
        <v-col class="pa-0">
          <p class="mb-1">First Name</p>
          <v-text-field solo dense hide-details class="mb-3" placeholder="First Name" v-model="firstname"></v-text-field>
        </v-col>
        <v-col class="pa-0">
          <p class="mb-1">Last Name</p>
          <v-text-field solo dense hide-details class="mb-3" placeholder="Last Name" v-model="lastname"></v-text-field>
        </v-col>
      </v-row>
      <p class="mb-1">Influencer</p>
      <v-autocomplete solo dense hide-details clearable v-model="influencer" :items="influencers" item-text="text"
        item-value="value" />
      <p class="mb-1">Email Address</p>
      <v-text-field solo dense hide-details class="mb-3" placeholder="Email Address" disabled
        v-model="email"></v-text-field>

      <p class="mb-1">Subscription</p>
      <v-autocomplete v-model="subscription" :items="subscriptions" solo dense color="blue-grey lighten-2"
        placeholder="Subscriptions" hide-details item-text="name" item-value="id" />
    </div>

    <template v-slot:action>
      <v-row class="ma-0">
        <v-col class="pl-0 pr-2">
          <v-btn block @click="submitDialog()" :disabled="disabled">Update</v-btn>
        </v-col>
        <v-col class="pl-2 pr-0">
          <v-btn block @click="closeDialog()">Cancel</v-btn>
        </v-col>
      </v-row>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialog.vue'
import Upload from '@/components/Upload.vue'
import { influencers } from '@/utils/data'

import { getMessage } from '@/utils'
import { updateUsers } from '@/api/user.js'
import { updateUserInfo } from '@/api/userinfo.js'

export default {
  props: {
    dialog: {
      type: Boolean
    },
    data: {
      type: Object
    }
  },
  components: {
    Upload,
    Dialog
  },
  data: () => {
    return {
      getMessage,
      updateUsers,
      updateUserInfo,
      influencers,

      files: [],

      name: null,
      image: null,
      email: null,
      status: null,
      user_id: null,
      lastname: null,
      firstname: null,
      subscription: null,
      influencer: null
    }
  },
  created() {
    this.getData()
  },
  computed: {
    disabled() {
      return (
        this.name == '' ||
        this.email == '' ||
        this.subscription == '' ||
        this.name == null ||
        this.email == null ||
        this.subscription == null ||
        this.influencer == null
      )
    },
    subscriptions() {
      return this.$store.state.subscription.data
    }
  },
  methods: {
    getImageName(value) {
      this.image = value
    },
    getData() {
      this.files = []

      this.email = this.data.email
      this.status = this.data.status
      this.user_id = this.data.user_id
      this.name = this.data.display_name
      this.lastname = this.data.last_name
      this.firstname = this.data.first_name
      this.subscription = this.data.subscription_id
      this.influencer = this.data.influencer
      if (this.files.length == 0) {
        this.files.push({ name: 'avatar', url: this.data.cover })
      }
    },
    closeDialog() {
      this.$emit('closeDialog', false)
    },
    submitDialog() {
      const data = {
        id: this.id,
        name: this.name,
        email: this.email,
        user_id: this.user_id,
      }
      const data_info = {
        file: this.image,
        status: this.status,
        user_id: this.user_id,
        last_name: this.lastname,
        first_name: this.firstname,
        subscription_id: this.subscription,
        is_influencer: this.influencer,
      }

      this.updateUserInfo(data_info)
      this.updateUsers(data).then((res) => {
        const users = res.data
        this.getMessage(this, res)
        this.closeDialog()
        this.$store.dispatch("user/getData", users)
      })

    }
  }
}
</script>
