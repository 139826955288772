<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Procrastination can be a major roadblock to achieving your goals and living a productive life. But don't worry, you can overcome this habit with the help of this subliminal."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help you:'
    },
    {
      type: 'list',
      items: [
        "By targeting your subconscious mind, it helps you develop a proactive and action-oriented mindset.",
        "It aids in increasing your motivation and focus, allowing you to tackle tasks and projects with greater efficiency.",
        "This subliminal helps you overcome negative thought patterns and self-sabotaging behaviors that contribute to procrastination.",
        "It encourages self-discipline and the ability to prioritize tasks effectively.",
        "By listening regularly, you can rewire your brain to develop productive habits and overcome the temptation to procrastinate."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming procrastination is a process that requires commitment and consistent effort. By incorporating this subliminal into your daily routine, you can gradually overcome your procrastination habits and become more productive and efficient in all aspects of your life."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Breaking the cycle of procrastination and increasing productivity can be challenging, but this subliminal program is designed to help you overcome this habit and achieve your goals. Before you start listening, consider these tips to make the most out of your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set specific goals: Identify what tasks or projects you want to work on and outline clear objectives.",
        "Create a schedule: Plan your day or week in advance, allocating dedicated time for important tasks.",
        "Eliminate distractions: Minimize interruptions by turning off notifications, finding a quiet workspace, or using time-blocking techniques.",
        "Visualize success: Imagine yourself completing tasks efficiently, feeling motivated, and enjoying a sense of accomplishment.",
        "Focus on your intention: While listening to the subliminal affirmations, maintain a clear intention to overcome procrastination and increase productivity."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal program consistently, you can rewire your subconscious mind, develop a proactive mindset, and overcome the habit of procrastination. Embrace this opportunity to enhance your productivity and efficiency, and let's begin this transformative journey together."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Overcome and Stop Procrastination subliminal! By taking this step towards improving your productivity and efficiency, you've set yourself up for success in all areas of your life. As you reflect on your experience, remember that breaking the habit of procrastination takes time and effort, but it's well worth it."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Identify triggers: Take note of the tasks or situations that tend to lead to procrastination.",
        "Break tasks down: Divide your tasks into smaller, more manageable steps to make them less overwhelming.",
        "Set deadlines and create a schedule: Use calendars or task management tools to plan your daily, weekly, and monthly goals.",
        "Practice focus techniques: Experiment with different techniques like the Pomodoro Technique or time-blocking to enhance your concentration and eliminate distractions."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming procrastination is an ongoing process. Stay committed to your goals, embrace the challenge, and implement practical strategies to boost your productivity and efficiency. With dedication and consistency, you'll be amazed at how much you can accomplish. Don't delay your success any longer - start taking action now!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Overcome and Stop Procrastination subliminal help me?`, 
    answer: `The Overcome and Stop Procrastination subliminal is designed to influence your subconscious mind in a way that reduces the tendency to procrastinate and promotes a proactive and productive mindset. By listening to the subliminal regularly, you can reprogram your thought patterns and develop healthier habits that will help you overcome procrastination and become more efficient in all areas of your life.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Overcome and Stop Procrastination subliminal?`, 
    answer: `The time it takes to see results can vary from person to person and depends on factors such as the individual's level of motivation and consistency in listening to the subliminal. Some people may notice positive changes in their productivity and ability to overcome procrastination relatively quickly, while for others, it may take more time and consistent listening. We recommend using the subliminal daily for at least a few weeks to allow the affirmations to take effect.`,
    open: false 
  },
  { 
    question: `Can the Overcome and Stop Procrastination subliminal replace other strategies for overcoming procrastination?`, 
    answer: `The subliminal is designed to complement other strategies and techniques for overcoming procrastination, such as goal setting, time management, and breaking tasks into smaller, more manageable steps. It can enhance your motivation and mindset, making it easier to implement these strategies and achieve your goals. However, it is important to develop a holistic approach that combines various tools and techniques to effectively overcome procrastination.`,
    open: false 
  },
  { 
    question: `What if I still procrastinate even after listening to the subliminal?`, 
    answer: `While the subliminal is designed to help you overcome procrastination, it is not a miracle solution. Overcoming procrastination requires effort, commitment, and a combination of strategies tailored to your specific needs. If you find that you are still struggling with procrastination, it may be helpful to reassess your goals, seek additional support or guidance, and explore different techniques that may resonate better with you.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Overcome and Stop Procrastination subliminal?`, 
    answer: `There are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your mental health or wellbeing, we recommend consulting with a healthcare professional. It is important to note that the subliminal is intended to complement other strategies for overcoming procrastination and should not replace professional help if needed.`,
    open: false 
  },
  { 
    question: `Can the Overcome and Stop Procrastination subliminal make me too focused or obsessive about work?`, 
    answer: `No, the subliminal is designed to help you develop a more proactive and efficient mindset, but it is not intended to make you excessively focused or obsessed with work. It aims to promote a healthy and balanced approach to productivity by reducing procrastination and encouraging you to take action towards your goals.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>