<template>
  <div>
    <div class="pb-3">
      <v-text-field
        class="searching mt-1 d-flex"
        label="Search"
        solo
        dense
        hide-details
        append-icon="mdi-close"
        v-model="search"
        @click:append="empty"
        @keyup.enter="addInputSearch(search)"
      ></v-text-field>
      <div>
        <v-chip
          class="mx-1"
          small
          filter
          outlined
          v-for="(item, index) in recentsearch"
          :key="index"
          @click="getSearch(item)"
        >
          {{ item }}
        </v-chip>
        <v-chip
          v-if="recentsearch.length != 0"
          class="mx-1"
          small
          filter
          outlined
          @click="clearSearch()"
          color="red"
          text-color="black"
        >
          clear all
        </v-chip>
      </div>
    </div>
    <div v-show="isReady" class="pb-10">
      <div
        v-if="searchUser_playlist.length != 0"
        class="align-center banner-cover p-4"
        :style="`background: url(${searchUser_playlist[index].cover})`"
      >
        <div
          @click="goToTrack(searchUser_playlist[index])"
          style="height: 220px"
        >
          <h4
            style="color: white; font-family: 'Montserrat-SemiBold;'"
            class="text-limit-3 mt-2"
          >
            Listen to
          </h4>
          <h2 style="color: white">{{ searchUser_playlist[index].title }}</h2>
        </div>

        <div
          v-if="searchUser_playlist.length != 1"
          class="d-flex"
          style="margin: auto; min-width: 30px; max-width: 80px"
        >
          <div v-for="(user, i) in searchUser_playlist" :key="i">
            <v-icon
              class="mx-1"
              size="medium"
              @click="next(i)"
              v-if="index == i && user.length != 1"
              >mdi-checkbox-blank-circle</v-icon
            >
            <v-icon class="mx-1" size="small" @click="next(i)" v-else
              >mdi-checkbox-blank-circle-outline</v-icon
            >
          </div>
        </div>
      </div>
      <div v-if="searchSubliminals.length != 0 || searchPlaylists.length != 0">
        <div v-if="searchSubliminals.length != 0">
          <h5>Subliminals</h5>
          <div class="pb-3" style="height: 180px">
            <v-slide-group multiple show-arrows>
              <v-slide-item
                v-for="(sub, index) in searchSubliminals"
                :key="index"
              >
                <div
                  class="card mx-2"
                  :style="`background: url(${sub.cover})`"
                  @click="getAudios(searchSubliminals, index)"
                >
                  <p class="mb-0">{{ sub.title }}</p>
                </div>
              </v-slide-item>
            </v-slide-group>
          </div>
        </div>
        <div v-if="searchPlaylists.length != 0">
          <h5>Playlists</h5>
          <div class="pb-3" style="height: 180px">
            <v-slide-group multiple show-arrows>
              <v-slide-item
                v-for="(play, index) in searchPlaylists"
                :key="index"
              >
                <div
                  class="card mx-2"
                  :style="`background: url(${play.cover})`"
                  @click="goToTrack(play)"
                >
                  <p class="mb-0">{{ play.title }}</p>
                </div>
              </v-slide-item>
            </v-slide-group>
          </div>
        </div>
      </div>
      <div v-else class="mx-auto text-center" style="height: 35vh">
        <h4 class="mt-5">No result found!</h4>
        <span
          >We couldn’t find what you searched for.<br />Try using a different
          key word and try again<br
        /></span>
        <img
          class="empty1 mt-2"
          src="@/assets/images/user/playlist/empty.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { addSearch, getSearchResult, getClearSearch } from "@/api/search";
import { getPlaylists } from "@/api/ownplaylist";
import { convertToTime } from "@/utils";

import { audio } from "@/mixins/audio";
import { mapGetters } from "vuex";

export default {
  mixins: [audio],
  data: () => {
    return {
      addSearch,
      getSearchResult,
      getClearSearch,
      getPlaylists,
      convertToTime,

      isReady: false,
      search: null,
      searchSubliminals: [],
      searchPlaylists: [],
      searchData: [],
      searchUser_playlist: [],
      index: 0,
    };
  },
  computed: {
    recentsearch() {
      return this.$store.state.recent.search.data;
    },
    user() {
      return this.$store.state.user.info;
    },
    playlistTrack() {
      return this.$store.state.audio.playlist.tracks;
    },
    playlistType() {
      return this.$store.state.audio.playlist.type;
    },
    ...mapGetters({
      isDataReady: "newaudio/getDataStatus",
      isAudioPlaying: "newaudio/getPlayingStatus",
    }),
  },
  mounted() {
    setTimeout(() => {
      this.isReady = true;
    }, 2500);
  },
  watch: {
    search(val) {
      if (this.search == "") {
        let self = this;
        setTimeout(() => {
          self.getSearch("");
        }, 0);
      }
    },
  },
  created() {
    let self = this;
    setTimeout(() => {
      self.getSearch("");
    }, 0);
  },
  methods: {
    empty() {
      let self = this;
      setTimeout(() => {
        self.search = "";
        self.getSearch("");
      }, 0);
    },
    next(i) {
      this.index = i;
    },
    addInputSearch(search) {
      const user_id = JSON.parse(this.user).user_id;
      const subscription_id = JSON.parse(this.user).info.subscription_id;

      const data = {
        user_id: user_id,
        search: this.search,
        subscription_id: subscription_id,
      };

      this.addSearch(data).then((res) => {
        this.$store.dispatch("recent/getSearch", res);
      });

      this.getSearch(search);
    },
    clearSearch() {
      const user_id = JSON.parse(this.user).user_id;
      const subscription_id = JSON.parse(this.user).info.subscription_id;

      const data = {
        user_id: user_id,
      };

      this.getClearSearch(data).then((res) => {
        this.$store.dispatch("recent/getSearch", res);
        this.$store.dispatch("recent/getSearchLoading", false);
      });
    },
    getSearch(search) {
      const user_id = JSON.parse(this.user).user_id;
      const subscription_id = JSON.parse(this.user).info.subscription_id;

      const data = {
        user_id: user_id,
        search: search,
        subscription_id: subscription_id,
      };

      this.data = [];
      this.getSearchResult(data).then((res) => {
        this.searchPlaylists = res.playlist;
        this.searchSubliminals = res.subliminal;
        this.searchUser_playlist = res.user_playlist;
      });
    },
    getAudios(data, index) {
      if (this.playlistType != "recent") {
        this.$store.dispatch("audio/getPlaylistType", "recentsearch");
      }

      if (JSON.stringify(this.playlistTrack) != JSON.stringify(data)) {
        this.$store.dispatch("audio/getPlaylistTrack", data);
      }

      this.$store.dispatch("newaudio/currentTime", 0);
      this.$store.dispatch("newaudio/subliminalIndex", index);
      this.initializeData(data, index, true);

      this.$store.dispatch("newaudio/playedStatus", true);
    },
    getDuration(params) {
      let data = params.tracks;
      if (data != undefined) {
        return this.convertToTime(
          Math.max(...data.map((o) => o.duration / 1000))
        );
      }
    },
    getBackground(params) {
      if (params.hasOwnProperty("subliminals")) {
        const count = params.subliminals.length;
        if (count > 0) {
          let index = Math.floor(Math.random() * count);
          return params.subliminals[index].cover;
        }
      }

      return params.cover;
    },
    goToTrack(data) {
      localStorage.setItem("user_track", JSON.stringify(data));
      this.$store.dispatch("user/getTrack", data);
      if (!this.isRouteTrack) {
        this.$router.push("/user/track");
      }
    },
  },
};
</script>

<style>
.v-slide-group__next,
.v-slide-group__prev {
  width: 15px !important;
  min-width: 15px !important;
  flex: 0 1 15px !important;
}
</style>

<style scoped>
.searching {
  width: 80%;
  margin-left: 10%;
  margin-top: -10px;
  margin-bottom: 10px;
  height: 40px;
  background: rgba(244, 238, 238, 0.4);
  border: solid 0.5px black;
  border-radius: 10px;
  font-family: "Montserrat-Regular";
}
.inputs:active {
  border: solid 0.5px black;
}
.banner-cover {
  cursor: pointer;
  border-radius: 10px;
  height: 300px;
  margin: 10px;

  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}
.card {
  width: 250px;
  height: 150px;
  padding: 10px;
  color: white;

  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}
.card:hover,
.card1:hover {
  color: black;
  width: 270px;
  height: 170px;
}
.card:nth-child(1) {
  margin-left: 0px !important;
}
p {
  font-size: 16px;
}
.empty1 {
  height: 100px;
}
span {
  font-family: "Montserrat-Regular";
  font-size: 16px;
}
</style>
