import { render, staticRenderFns } from "./99-fear-of-commitment-and-commitment-phobia-removal.vue?vue&type=template&id=0ca11714&scoped=true&"
import script from "./99-fear-of-commitment-and-commitment-phobia-removal.vue?vue&type=script&lang=js&"
export * from "./99-fear-of-commitment-and-commitment-phobia-removal.vue?vue&type=script&lang=js&"
import style0 from "./99-fear-of-commitment-and-commitment-phobia-removal.vue?vue&type=style&index=0&id=0ca11714&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ca11714",
  null
  
)

export default component.exports