<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Struggling with negative body image and low self-confidence can be challenging, but this subliminal is here to help you on your journey to body acceptance and confidence. By targeting your subconscious mind, it can assist in rewiring negative thought patterns and fostering a healthier relationship with your body."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Embracing and loving your body as it is, regardless of societal standards or expectations.",
        "Building confidence and self-esteem in yourself and your appearance.",
        "Letting go of negative thoughts and beliefs about your body and developing a positive body image.",
        "Developing a healthier and more compassionate relationship with your body.",
        "Feeling comfortable and empowered in your own skin and embracing your unique beauty."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can start to internalize these positive affirmations and beliefs about your body. Over time, you may notice a shift in how you perceive yourself, leading to increased confidence, self-acceptance, and a more positive body image. Remember, your body is unique and deserves your love and care."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embracing your body and building confidence in yourself and your appearance is a powerful journey towards self-acceptance. This subliminal is designed to support you in developing a healthy relationship with your body and letting go of negative body image. Before you begin, here are some strategies to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice self-compassion: Treat yourself with kindness and understanding, embracing your body as it is in this present moment.",
        "Challenge negative thoughts: Recognize and challenge negative body-related thoughts that may arise.",
        "Focus on body-positive affirmations: Introduce positive and affirming statements about your body into your daily thoughts.",
        "Surround yourself with positivity: Seek out body-positive images, role models, and communities that celebrate diverse body types."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you may begin to feel a shift in your perception of your body, allowing you to appreciate and love yourself more fully. This journey towards body acceptance and confidence isn't about achieving a specific physical appearance, but rather about fostering a deep sense of self-worth, self-love, and appreciation for your unique beauty. Take a moment now to breathe deeply and remember that you are deserving of love and acceptance exactly as you are."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Body Acceptance and Confidence subliminal! By choosing to embrace and love your body as it is, you've embarked on a journey towards developing a healthy relationship with your body and building confidence in yourself and your appearance. Take a moment to reflect on your experience and continue your personal growth and transformation."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice self-love: Treat yourself with kindness and compassion, reminding yourself of your worthiness.",
        "Challenge negative thoughts: Recognize and replace negative thoughts with positive affirmations about your body and appearance.",
        "Surround yourself with positivity: Surround yourself with supportive and body-positive individuals who uplift and inspire you.",
        "Focus on your strengths: Recognize and appreciate the unique qualities and strengths that make you who you are."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, developing body acceptance and confidence is a lifelong journey. Embrace the process and continue to nurture a positive mindset and self-image. Celebrate your progress and remember that you are beautiful and deserving of love and acceptance, exactly as you are. Keep shining!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Body Acceptance and Confidence subliminal help me?`, 
    answer: `The Body Acceptance and Confidence subliminal is designed to help you embrace and love your body as it is, and build confidence in yourself and your appearance. By listening to the audio regularly, the subliminal messages will work to reprogram your subconscious mind, allowing you to let go of negative body image and develop a healthy relationship with your body. Over time, you may start to see improvements in your self-esteem, body acceptance, and overall confidence.`,
    open: false
  },
  { 
    question: `When will I start to feel more confident and accepting of my body?`, 
    answer: `The time it takes to feel more confident and accepting of your body can vary from person to person. Some may experience changes in their mindset and self-perception relatively quickly, while others may need more time and consistent listening to the subliminal messages. We recommend listening to the Body Acceptance and Confidence subliminal regularly and practicing self-care and self-love to enhance its effectiveness.`,
    open: false 
  },
  { 
    question: `Can the Body Acceptance and Confidence subliminal help with body dysmorphia?`, 
    answer: `While the Body Acceptance and Confidence subliminal can be a supportive tool in developing a healthier mindset towards your body, it is not a substitute for professional help or treatment for body dysmorphia. If you are experiencing body dysmorphia or other mental health concerns, it is important to seek guidance from a mental health professional.`,
    open: false 
  },
  { 
    question: `Is it necessary to make any other lifestyle changes alongside listening to the subliminal?`, 
    answer: `No, the Body Acceptance and Confidence subliminal is designed to work on a subconscious level to promote self-acceptance and confidence in your body. However, incorporating other self-care practices such as mindfulness, self-love, and positive affirmations can further enhance your journey towards body acceptance and confidence.`,
    open: false 
  },
  { 
    question: `Will the Body Acceptance and Confidence subliminal change my physical appearance?`, 
    answer: `No, the subliminal messages in the Body Acceptance and Confidence audio are not intended to change your physical appearance. Their purpose is to help you develop a positive mindset and build confidence in yourself and your appearance. Embracing and loving your body as it is can lead to a healthier relationship with yourself and an improved sense of well-being.`,
    open: false 
  },
  { 
    question: `Is the Body Acceptance and Confidence subliminal safe for everyone to listen to?`, 
    answer: `Yes, the Body Acceptance and Confidence subliminal is safe for everyone to listen to. The subliminal messages are designed to promote body acceptance and confidence in a gentle and positive manner. If you have any concerns about listening to the subliminal, please consult with a healthcare professional.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>