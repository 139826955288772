<template>
  <div class="card mb-2">
    <vue-editor class="editor"
      v-model="listLocal"
      :editor-toolbar="customToolbar"
    />
  </div>
</template>

<script>

import { VueEditor, Quill } from "vue2-editor";

// Generate code-friendly font names
function getFontName(font) {
    return font.toLowerCase().replace(/\s/g, "-");
}

// Specify Quill fonts
const colorList = ['black', 'blue', 'violet', 'red', 'orange', 'green', 'yellow',
                    'white', '#d1e8f8', '#e5d0ff', '#ffbaba', '#ffd7b5', '#ececa3', '#fff9ae',
                    '#D3D3D3', '#8dc6ef', '#dabcff', '#ff7b7b', '#ffb38a', '#b5e550', '#f8ed62',
                    'gray', '#1C8EDF', '#cca3ff', '#ff5252', '#ffa472', '#abc32f', '#e9d700',
                    '#696969', '#4372BD', '#bf8bff', '#ff0000', '#ff6700', '#809c13', '#dab600',
                    '#404040', '#105585', '#51007e', '#a70000', '#ff580f', '#607c3c', '#a98600',
                  ];

const font_size = [
                  "small",
                  "medium",
                  "large",
                  "huge"
                ];

const fontList = [
                "Arial",
                "Courier New",
                "Cursive",
                "Garamond",
                "Georgia",
                "Montserrat",
                "Quicksand",
                "Tahoma",
                "Times New Roman",
                "Verdana",
              ];
const fontNames = fontList.map(font => getFontName(font));
const fonts = Quill.import('formats/font');
fonts.whitelist = fontNames;
Quill.register(fonts, true);

var size = Quill.import('attributors/style/size');
size.whitelist = ['12px', '14px', '16px', '18px', '20px', '25px', '30px'];
Quill.register(size, true);

// Add fonts to CSS style
let fontStyles = "";
fontList.forEach(function(font) {
    let fontName = getFontName(font);
    fontStyles += ".ql-snow .ql-picker.ql-font .ql-picker-label[data-value=" + fontName + "]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=" + fontName + "]::before {" +
        "content: '" + font + "';" +
        "font-family: '" + font + "', sans-serif;" +
        "}" +
        ".ql-font-" + fontName + "{" +
        " font-family: '" + font + "', sans-serif;" +
        "}";
});

const node = document.createElement('style');
node.innerHTML = fontStyles;
document.body.appendChild(node);

export default {
  data: () => {
    return {
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [{ 'font': fonts.whitelist }],
        [{ size: size.whitelist }],
        [{ color: []}, { background: [] }], // dropdown with defaults from theme
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ 'direction': 'rtl' }],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        ['link', 'video', 'image'],
      ],
    };
  },
  components: {
    VueEditor
  },
  props: {
    list: {
      type: String
    },
  },
  model: {
    prop: 'list',
    event: 'listchange'
  },
  watch:{
    listLocal(val){
      console.log(val)

    },
   
  },
  methods: {
    listchange(){
    }
  },
  computed: {
    listLocal: {
            get: function() {
                return this.list
            },
            set: function(value) {
                this.$emit('listchange', value)
            }
        }
    }
};
</script>

<style>
.editor{
  height: 35vh;
  overflow: scroll;
}
.ql-snow{
  .ql-picker{
      &.ql-size{
          .ql-picker-label,
          .ql-picker-item{
              &::before{
                  content: attr(data-value) !important;
              }
          }
      }
  }
}
</style>