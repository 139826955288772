import { render, staticRenderFns } from "./163-depression-healing-and-removal.vue?vue&type=template&id=7f1e2edc&scoped=true&"
import script from "./163-depression-healing-and-removal.vue?vue&type=script&lang=js&"
export * from "./163-depression-healing-and-removal.vue?vue&type=script&lang=js&"
import style0 from "./163-depression-healing-and-removal.vue?vue&type=style&index=0&id=7f1e2edc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f1e2edc",
  null
  
)

export default component.exports