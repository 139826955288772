<template>
  <div>
    <Table title="Magus Details" itemkey="info_id" :loading="loading" :items="items" :headers="headers" :select="selected" :total="total"
      @action="getAction" @updateLimitPage="updateLimitPage" expandtype="magus" :showexpand="true"
      @updateSearch="updateSearch" @updateSort="updateSort"/>

    <addItemDialog v-if="dialogType == 'additem'" :data="data" :dialog="dialog" @closeDialog="closeDialog" />

    <addDialog v-if="dialogType == 'add'" :data="data" :dialog="dialog" @closeDialog="closeDialog" />

    <editDialog v-if="dialogType == 'update'" :data="data" :dialog="dialog" @closeDialog="closeDialog" />
    
    <expandDeleteDialog v-if="dialogType == 'expanddelete'" :data="sub_data" :dialog="dialog" @closeDialog="closeDialog" />
    
    <expandEditDialog v-if="dialogType == 'expandedit'" :data="sub_data" :dialog="dialog" @closeDialog="closeDialog" />

    <deleteDialog v-if="dialogType == 'delete' || dialogType == 'deleteall'" :data="data" :dialog="dialog"
      :selected="selected" @selected="getSelected" @closeDialog="closeDialog" />
  </div>
</template>

<script>
import { getMagusInfos, getMagusInfosFilters, updateToggle } from '@/api/magusdetails'
import Table from '@/components/Admin/Table.vue'
import { sorts } from '@/utils/data'
import { getMessage } from '@/utils'

import addDialog from './components/addDialog.vue'
import editDialog from './components/editDialog.vue'
import deleteDialog from './components/deleteDialog.vue'
import addItemDialog from './components/addItemDialog.vue'
import expandEditDialog from './components/expandEditDialog.vue'
import expandDeleteDialog from './components/expandDeleteDialog.vue'

export default {
  components: {
    Table,
    addDialog,
    editDialog,
    deleteDialog,
    addItemDialog,
    expandDeleteDialog,
    expandEditDialog,
  },
  data: () => {
    return {
      getMagusInfos,
      getMagusInfosFilters,
      getMessage,

      sorts,
      updateToggle,

      data: {},
      sub_data: {},
      dialog: false,
      dialogType: '',

      items: [],
      loading: true,

      selected: [],

      headers: [
        { text: 'Title', value: 'title', sortable: false },
        { text: 'Description', value: 'short_description', sortable: false },
        { text: 'Remarks', value: 'remarks', sortable: false },
        { text: 'Last Modified', value: 'modified_date', sortable: false },
        { text: 'Action', value: 'add_update_delete', sortable: false, width: '200' },
      ],
      search: null,
      sort: null,
      limit: 10,
      page: 1,
      total: 0
    }
  },
  created() {
    this.getData()
  },
  watch: {
    sort() {
      this.getData()
    },
    search() {
      this.getData()
    }
  },
  methods: {
    getAction(params) {
      this.dialog = true
      this.dialogType = params.action
      this.data = params.data
      this.selected = params.selected

      if (params.action == 'toggle') {
        if(this.data.toggle==true){
          this.isVisible=0;
        }else{
          this.isVisible=1;
        }
        const data = {
          is_visible: this.isVisible,
          info_id: this.data.info_id,
        }
        this.updateToggle(data).then((res) => {
          this.getMessage(this, res)
          this.getData()
        })
      }
      if (params.action == 'expanddelete' || params.action == 'expandedit') {
        this.sub_data=(this.data)
      }

    },
    getSelected(data) {
      this.selected = data
    },
    closeDialog() {
      this.dialog = false
      this.dialogType = null
      this.getData()
    },
    getData() {
      this.loading = true
      const data = {
        sort: this.sort,
        search: this.search,
        limit: this.limit,
        page: this.page
      }

      this.getMagusInfosFilters(data).then((res) => {
        const { info, total_pages } = res

        this.total = total_pages
        this.items = info

        this.loading = false
      })
    },
    updateLimitPage(data) {
      const { page, limit } = data
      this.page = page
      this.limit = limit

      this.getData()
    },
    updateSearch(data) {
      this.search = data
      this.getData()
    },
    updateSort(data) {
      this.sort = data
      this.getData()
    }
  }
}
</script>
