<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'heading',
      text: 'The Motivation Improvement subliminal can help you:'
    },
    {
      type: 'list',
      items: [
        "Increase your motivation levels and stay consistently driven towards your goals.",
        "Overcome procrastination and maintain focus on tasks and projects.",
        "Boost your self-belief and confidence in your abilities to achieve success.",
        "Develop a positive mindset and overcome self-doubt and negative thinking.",
        "Tap into your inner determination and persevere through challenges and setbacks."
      ]
    },
    {
      type: 'paragraph',
      text: "Whether you're looking to excel in your career, start a new project, or simply stay motivated in your daily life, this subliminal can be a powerful tool. By targeting your subconscious mind, it helps to rewire deeply ingrained thought patterns and beliefs that may be holding you back from reaching your full potential. Start listening today and unlock your true motivation!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Increasing your motivation and drive to achieve your goals and dreams is an important step towards creating the life you desire. This subliminal is designed to support you on your journey towards motivation improvement. Before you start listening, consider these suggestions to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Get clear on your goals: Take some time to clarify and define your goals and aspirations.",
        "Create a vision board: Visualize your goals by creating a collage of images, words, and quotes that represent what you want to achieve.",
        "Break it down: Divide your goals into smaller, manageable steps to make them more achievable and less overwhelming.",
        "Stay positive: Cultivate a positive mindset by focusing on your strengths and past achievements.",
        "Focus on your intention: While listening to the subliminal affirmations, keep your goals and dreams at the forefront of your mind."
      ]
    },
    {
      type: 'paragraph',
      text: "By integrating this subliminal into your routine, you can strengthen your motivation levels and enhance your drive to succeed. As you listen regularly, you may begin to notice an increase in your motivation, determination, and focus. Embrace this opportunity to connect with your inner drive, and let the journey to achieving your goals and dreams begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Motivation Improvement subliminal! By investing in improving your motivation levels, you've taken a crucial step towards achieving your goals and dreams. Reflecting on your newfound motivation and commitment is key as you move forward."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Write down your goals and break them down into smaller, actionable steps.",
        "Create a vision board: Visualize your dreams and desires by creating a collage of images and words that inspire you.",
        "Stay positive and focused: Surround yourself with positive influences and eliminate distractions that hinder your motivation.",
        "Practice self-care: Take care of your physical and mental well-being to maintain high levels of motivation."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, enhancing motivation is an ongoing process. Celebrate the progress you make towards your goals and stay committed to your journey. With increased motivation and determination, you are well on your way to achieving the success you desire. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Motivation Improvement subliminal help me?`, 
    answer: `The Motivation Improvement subliminal is designed to help you enhance your motivation levels and improve your drive to achieve your goals and dreams. By listening to the subliminal regularly, the positive affirmations embedded in the audio can influence your subconscious mind, promoting a mindset of motivation, determination, and focus. This can help you overcome obstacles, stay committed to your goals, and take consistent action towards your aspirations.`,
    open: false
  },
  { 
    question: `How long does it take to notice the effects of the Motivation Improvement subliminal?`, 
    answer: `The length of time it takes to notice the effects of the Motivation Improvement subliminal may vary from person to person. Some individuals may experience positive changes in their motivation levels relatively quickly, while others may require more time for the subliminal messages to create lasting impact. We recommend listening to the subliminal consistently for at least a few weeks to allow the affirmations to work on your subconscious mind.`,
    open: false 
  },
  { 
    question: `Can the Motivation Improvement subliminal boost my motivation in specific areas of my life?`, 
    answer: `Yes, the Motivation Improvement subliminal can help boost your motivation in specific areas of your life. The affirmations in the audio are designed to influence your subconscious mind, which can positively impact various aspects of your life, including career, fitness, relationships, and personal development. By aligning your thoughts and beliefs with a motivated mindset, you may find yourself more driven and focused in pursuing your goals across different areas.`,
    open: false 
  },
  { 
    question: `How can I maximize the effectiveness of the Motivation Improvement subliminal?`, 
    answer: `To maximize the effectiveness of the Motivation Improvement subliminal, it is recommended to listen to the audio in a quiet and relaxed environment. Regular and consistent listening is important to reinforce the positive affirmations and reprogram your subconscious mind. Additionally, setting specific and achievable goals, creating a supportive environment, and incorporating practical strategies, such as time management and positive visualization, can complement the subliminal in enhancing your motivation levels.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the Motivation Improvement subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Motivation Improvement subliminal. However, it is important to note that the subliminal is intended to supplement and support your motivation levels, not replace necessary lifestyle changes or professional guidance. If you have any concerns about your motivation or mental health, it is recommended to consult with a qualified healthcare professional.`,
    open: false 
  },
  { 
    question: `Is it possible to listen to the Motivation Improvement subliminal while doing other activities or sleeping?`, 
    answer: `Yes, it is possible to listen to the Motivation Improvement subliminal while doing other activities or sleeping. The subliminal messages can influence your subconscious mind regardless of your level of attention, allowing you to listen while engaging in daily tasks or during sleep. However, some individuals may prefer to listen in a focused and relaxed state to maximize the impact of the affirmations.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>