import { render, staticRenderFns } from "./272-korean-language-learning-aid.vue?vue&type=template&id=6801a12f&scoped=true&"
import script from "./272-korean-language-learning-aid.vue?vue&type=script&lang=js&"
export * from "./272-korean-language-learning-aid.vue?vue&type=script&lang=js&"
import style0 from "./272-korean-language-learning-aid.vue?vue&type=style&index=0&id=6801a12f&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6801a12f",
  null
  
)

export default component.exports