<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Having a positive attitude can greatly impact your overall outlook on life and how you navigate through challenges. This subliminal is designed to help train and develop a positive attitude through subconscious reprogramming and practice."
    },
    {
      type: 'heading',
      text: 'Benefits of using this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Increasing your optimism and belief in your abilities.",
        "Enhancing your resilience and ability to bounce back from setbacks.",
        "Improving your self-confidence and self-esteem.",
        "Promoting a mindset focused on gratitude and appreciation.",
        "Encouraging a more positive and optimistic interpretation of events."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can rewire your subconscious mind to adopt a positive attitude. Over time, you may find yourself naturally approaching situations with more optimism and positivity. Remember, developing a positive attitude is a journey and requires consistent practice and reinforcement."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Developing a positive attitude can greatly improve your overall outlook on life and contribute to your personal growth. This subliminal is designed to support your journey towards cultivating positivity and optimism. Before you begin, consider these strategies to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Clarify your desire to develop a positive attitude and embrace a more optimistic mindset.",
        "Practice self-reflection: Take a moment to reflect on any negative thought patterns or limiting beliefs that may be holding you back.",
        "Create affirmations: Generate positive affirmations that counteract negative beliefs and reinforce your commitment to positive thinking.",
        "Visualize your desired outcome: Envision yourself radiating positivity, encountering challenges with resilience, and embracing a positive mindset."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can train your mind to adopt a positive attitude and enhance your overall outlook on life. Throughout your journey, remember to be kind to yourself, practice patience, and celebrate even the smallest victories. Embrace this opportunity for growth and let the transformation begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Positive Attitude Training and Development subliminal! By investing time and effort into developing a positive attitude, you're taking a proactive step towards enhancing your overall outlook on life. Now, it's important to reflect on your journey and continue growing and nurturing your positivity."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice gratitude: Take a few moments each day to appreciate the positive aspects of your life.",
        "Challenge negative thoughts: Identify and reframe negative thoughts into more positive and empowering ones.",
        "Surround yourself with positivity: Spend time with uplifting individuals and engage in activities that bring you joy.",
        "Embrace setbacks as opportunities: View challenges as chances to learn and grow, rather than obstacles."
      ]
    },
    {
      type: 'paragraph',
      text: "Building a positive attitude is a continuous process that requires practice and persistence. Embrace the journey and focus on nurturing your positivity in all aspects of life. Remember, by cultivating a positive attitude, you're creating a foundation for a happier, more fulfilling life. Stay positive!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Positive Attitude Training and Development program help me?`, 
    answer: `The Positive Attitude Training and Development program is designed to help you develop a positive attitude and enhance your overall outlook on life. Through training and practice, you can learn techniques to shift your perspective, manage negative thoughts, and cultivate a more positive mindset. This can lead to increased happiness, improved relationships, and greater success in various areas of your life.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Positive Attitude Training and Development program?`, 
    answer: `Results may vary depending on the individual and their commitment to the program. Some people may experience positive changes and improvements in their attitude relatively quickly, while others may require more time and consistent practice to see noticeable results. We recommend engaging with the program regularly and being patient with yourself as you develop and strengthen your positive mindset.`,
    open: false 
  },
  { 
    question: `What exercises or techniques are included in the Positive Attitude Training and Development program?`, 
    answer: `The program may include various exercises and techniques such as gratitude journaling, positive affirmations, visualization, meditation, cognitive reframing, and goal setting. These practices are designed to help rewire your brain and shift your mindset towards positivity and optimism. The specific exercises and techniques may vary depending on the program or resources you are using.`,
    open: false 
  },
  { 
    question: `Can the Positive Attitude Training and Development program help with depression or other mental health conditions?`, 
    answer: `While developing a positive attitude can be helpful for improving overall well-being, it is important to note that the Positive Attitude Training and Development program is not a substitute for professional mental health treatment. If you are experiencing symptoms of depression or any other mental health condition, we recommend seeking the guidance of a qualified mental health professional who can provide personalized support and interventions.`,
    open: false 
  },
  { 
    question: `Is it possible to maintain a positive attitude even in challenging situations?`, 
    answer: `Yes, it is possible to cultivate and maintain a positive attitude even in challenging situations. Developing a positive attitude involves building resilience, self-awareness, and the ability to reframe negative thoughts. With practice, you can learn to find silver linings, focus on solutions rather than problems, and adopt a more optimistic outlook. It may take time and effort, but it is an attainable goal that can greatly enhance your overall well-being.`,
    open: false 
  },
  { 
    question: `Can the Positive Attitude Training and Development program be used alongside other personal growth initiatives?`, 
    answer: `Yes, the Positive Attitude Training and Development program can be used alongside other personal growth initiatives. In fact, it can complement other programs or practices that focus on self-improvement, mindfulness, stress reduction, or goal attainment. Adopting a positive attitude can enhance the effectiveness of these initiatives by providing a foundation of optimism, resilience, and motivation.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>