<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Having a magnetic personality can bring numerous benefits to your personal and professional life. This subliminal is designed to help you develop the qualities and mindset necessary to attract people, opportunities, and success."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help you:'
    },
    {
      type: 'list',
      items: [
        "Enhancing your confidence and self-esteem, making you more attractive and approachable.",
        "Improving your communication skills, allowing you to connect with others more effectively.",
        "Boosting your charisma and charm, making you enjoyable and compelling to be around.",
        "Increasing your optimism and positive energy, drawing people towards you.",
        "Attracting opportunities and success by radiating a magnetic aura."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you are rewiring your subconscious mind to adopt the traits of a magnetic personality. As these traits become embedded within you, you will notice a positive shift in how people respond to you and the opportunities that come your way. Remember, it takes time and consistency to develop a magnetic personality, so keep listening and watch as your life transforms."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Unleash your inner magnetism and cultivate a magnetic personality that draws people, opportunities, and success towards you. This subliminal is designed to help you develop those traits and create a powerful presence. Before you start listening, consider these strategies to maximize the effectiveness of the program:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your intention: Reflect on the specific qualities you wish to develop to create a magnetic personality.",
        "Embrace positive self-talk: Cultivate a mindset of confidence and self-assurance, using affirmations to reinforce your magnetic traits.",
        "Visualize your magnetic presence: Imagine yourself confidently attracting positive interactions, opportunities, and success.",
        "Maintain focus: Keep your intention in mind while listening to the subliminal affirmations, allowing them to align with your subconscious mind."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your daily routine, you can unlock your magnetic potential and emit a captivating aura that draws people and opportunities towards you. As you listen regularly and embrace these preparation tips, you may start to notice positive changes in your interactions, connections, and overall success. Embrace your journey to becoming a magnetic personality and let the transformation begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Magnetic Personality subliminal! By cultivating a magnetic personality, you've embarked on a journey towards attracting people, opportunities, and success into your life. Now, it's time to reflect on your experience and continue your personal growth and transformation."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Embrace authenticity: Be true to yourself, and let your genuine personality shine through.",
        "Practice good communication: Improve your active listening skills and engage in meaningful conversations with others.",
        "Develop self-confidence: Work on building your self-esteem and believing in your abilities.",
        "Expand your horizons: Seek new experiences, learn new skills, and explore different perspectives."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, developing a magnetic personality is a continuous process. Embrace your uniqueness, cultivate meaningful connections, and seize opportunities that come your way. With a confident and magnetic presence, you are well on your way to a fulfilling and successful life. Keep shining!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Magnetic Personality subliminal help me?`, 
    answer: `The Magnetic Personality subliminal is designed to influence your subconscious mind to develop the qualities and traits associated with a magnetic personality. It can help you improve your charisma, confidence, and interpersonal skills, making you more attractive to others and opening up opportunities for success in various areas of your life.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Magnetic Personality subliminal?`, 
    answer: `Results can vary depending on the individual and their unique circumstances. Some people may experience positive changes in their interactions and relationships right away, while others may require more time and consistent listening to fully develop a magnetic personality. It is recommended to listen to the subliminal daily and consistently for best results.`,
    open: false 
  },
  { 
    question: `Can the Magnetic Personality subliminal make me someone I'm not?`, 
    answer: `No, the Magnetic Personality subliminal is not designed to make you someone you're not. Its purpose is to help you enhance and amplify the positive qualities within you, such as charisma, confidence, and effective communication skills. It aims to unleash your true potential and bring out the best version of yourself.`,
    open: false 
  },
  { 
    question: `How can I enhance the effectiveness of the Magnetic Personality subliminal?`, 
    answer: `You can enhance the effectiveness of the subliminal by actively practicing the qualities and traits associated with a magnetic personality in your daily life. This includes improving your body language, developing active listening skills, and cultivating a positive and open mindset towards others. Consistently applying what you learn from the subliminal in real-life situations can reinforce its impact and help you further develop a magnetic personality.`,
    open: false 
  },
  { 
    question: `Are there any side effects of listening to the Magnetic Personality subliminal?`, 
    answer: `No, there are no known side effects associated with listening to the Magnetic Personality subliminal. However, it is important to note that the subliminal is not intended to replace personal growth and development efforts. It is a tool to help facilitate and enhance your journey towards developing a magnetic personality.`,
    open: false 
  },
  { 
    question: `Can the Magnetic Personality subliminal attract negative attention?`, 
    answer: `The Magnetic Personality subliminal is designed to help you develop positive qualities associated with a magnetic personality, such as charisma, confidence, and effective communication skills. It aims to attract positive attention and create mutually beneficial relationships. However, it is important to use these newfound abilities ethically and in alignment with your values.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>