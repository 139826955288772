<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're struggling with mobile phone addiction and find it hard to disconnect from your device, this subliminal can help you regain control over your life. By targeting your subconscious mind, it aims to reduce dependency and promote a healthier relationship with technology."
    },
    {
      type: 'heading',
      text: 'Here are some benefits you may experience:'
    },
    {
      type: 'list',
      items: [
        "Freedom from constant phone usage and a decreased reliance on your mobile device.",
        "More present and mindful moments in your daily life, leading to a deeper connection with yourself and the world around you.",
        "Improved focus and concentration without the distractions of your phone.",
        "Reduced feelings of anxiety and stress associated with mobile phone addiction.",
        "Increased productivity as you spend less time on your phone and more time on activities that matter to you."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can rewire your subconscious mind and break free from the grip of mobile phone addiction. Embrace the freedom and focus that comes with reducing constant phone usage, and enjoy a more balanced and fulfilling life. Remember, change takes time and effort, but it's worth it!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Overcoming mobile phone addiction can be challenging, but this subliminal is here to support you on your journey towards regaining control over your life. Before you start listening, consider these strategies to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Define what you want to achieve by reducing your mobile phone usage, such as being more present, improving focus, or reclaiming your time.",
        "Create physical boundaries: Designate phone-free zones or specific time periods where you engage in activities without your phone.",
        "Find alternative activities: Discover new hobbies or interests that can replace excessive phone use and provide a sense of fulfillment.",
        "Develop a support system: Seek support from friends or family who can hold you accountable or join you on your journey towards reducing phone addiction."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to rewire your mind and help you break free from mobile phone addiction. With consistent listening and implementation of healthy habits, you can experience more present moments, improved focus, and regain control over your life. Embrace this opportunity for positive change, focus on your goals, and let the transformation begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Mobile Phone Addiction Removal subliminal! By taking this step towards regaining control over your life, you've shown great determination and commitment to breaking free from your mobile phone addiction. Now, it's time to reflect on your progress and continue on your journey towards a healthier relationship with technology."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Monitor your screen time: Utilize apps or features that track and limit the time you spend on your phone.",
        "Create tech-free zones: Designate specific areas or specific times where you keep your phone out of reach.",
        "Engage in offline activities: Rediscover hobbies, spend time with loved ones, or try new experiences unrelated to your phone.",
        "Practice digital detoxes: Take regular breaks from your phone to reset your mind and create healthier habits."
      ]
    },
    {
      type: 'paragraph',
      text: "Breaking free from a mobile phone addiction is a journey, and it's normal to face challenges along the way. Stay determined, and remember the benefits of being more present and focused in your daily life. You have the power to regain control over your actions and create a healthier relationship with technology. Good luck!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Mobile Phone Addiction Removal subliminal help me break free from my addiction?`, 
    answer: `The Mobile Phone Addiction Removal subliminal is designed to reprogram your subconscious mind to reduce cravings and dependency on your mobile phone. By listening to the subliminal messages, you can strengthen your willpower, increase self-control, and cultivate a healthier relationship with technology. Consistent listening and practicing mindful phone usage can help you regain control over your life and experience more present moments.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Mobile Phone Addiction Removal subliminal?`, 
    answer: `The time it takes to see results can vary depending on the individual and their level of addiction. Some people may start noticing positive changes in their behavior and mindset after a few weeks of consistent listening, while others may require more time and effort. It is important to be patient and persistent in your journey towards breaking free from mobile phone addiction.`,
    open: false 
  },
  { 
    question: `Are there any additional steps I can take to overcome my mobile phone addiction?`, 
    answer: `In addition to listening to the Mobile Phone Addiction Removal subliminal, there are several steps you can take to support your journey towards overcoming mobile phone addiction. Creating boundaries by setting specific phone-free times and areas, finding alternative activities to engage in, seeking social support, and practicing mindfulness can all contribute to breaking free from the addiction. It is also important to assess and address any underlying emotional or psychological factors that may contribute to the addiction.`,
    open: false 
  },
  { 
    question: `Can the Mobile Phone Addiction Removal subliminal help with other forms of technology addiction?`, 
    answer: `While the Mobile Phone Addiction Removal subliminal is specifically designed to help with mobile phone addiction, it may also have positive effects on other forms of technology addiction. The subliminal messages aim to reprogram your subconscious mind and strengthen your willpower, self-control, and awareness, which can be beneficial in reducing dependency on other forms of technology as well.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Mobile Phone Addiction Removal subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your addiction or mental health, please consult with a healthcare professional. It is important to note that the subliminal is intended to support your efforts in overcoming mobile phone addiction, but it is not a substitute for professional help if needed.`,
    open: false 
  },
  { 
    question: `Will I have to completely give up using my mobile phone to overcome addiction?`, 
    answer: `Overcoming mobile phone addiction does not necessarily mean completely giving up the use of your mobile phone. It is about developing a healthy and balanced relationship with technology. The goal is to reduce dependency and regain control over your life, allowing you to use your mobile phone in a mindful and intentional way that aligns with your priorities and values.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>