<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Emotional balance is essential for leading a fulfilling and peaceful life. If you find yourself often overwhelmed by your emotions or reacting impulsively, this subliminal can help you regain control."
    },
    {
      type: 'heading',
      text: 'This subliminal is particularly effective for:'
    },
    {
      type: 'list',
      items: [
        "Managing and reducing the intensity of negative emotions such as anger, anxiety, or sadness.",
        "Developing healthier coping mechanisms and responses to challenging situations.",
        "Increasing self-awareness and emotional intelligence, allowing for more balanced decision-making.",
        "Creating a more harmonious and peaceful inner state, leading to better relationships and personal satisfaction.",
        "Gaining a sense of empowerment and control over your emotional reactions."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can rewire your subconscious mind to respond to emotions in a more balanced and constructive way. Over time, you may notice yourself becoming more resilient, adaptable, and calm. Remember, self-growth is a continuous journey, and this subliminal can be a helpful tool along the way."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Taking charge of your emotions and reactions is an empowering step towards a more balanced and peaceful life. By using this subliminal, you can harness the powers of your subconscious mind to cultivate emotional control. Before beginning your listening session, consider these strategies to optimize your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your emotional triggers: Identify situations, people, or events that tend to provoke emotional responses.",
        "Establish a calming practice: Develop techniques to calm your mind and body, such as deep breathing exercises or meditation.",
        "Set your intention: Clarify your desire to gain control over your emotions and responses, and mentally align yourself with that intention.",
        "Focus on positive emotions: Visualize yourself responding calmly and positively to challenging situations, reinforcing the desired behavior."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal is designed to guide your subconscious mind towards healthier emotional patterns and reactions. By making regular use of this audio, you can cultivate emotional stability, resilience, and a greater sense of inner peace. Take a moment to center yourself, reaffirm your intention, and prepare to embark on a transformative journey towards emotional empowerment."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Master your Emotions and Responses subliminal! By focusing on gaining control over your emotions and reactions, you've taken a powerful step towards creating a more balanced and peaceful life. Reflecting on your experience and continuing your personal growth is key as you move forward."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice self-awareness: Pay attention to your thoughts, feelings, and physical sensations in different situations.",
        "Use mindfulness techniques: Incorporate mindfulness exercises to help you stay present and engaged in the moment.",
        "Develop healthy coping mechanisms: Explore different strategies like deep breathing, journaling, or talking to a trusted friend or therapist.",
        "Focus on self-care: Prioritize activities that help you recharge and stay emotionally resilient, such as exercise, hobbies, and adequate rest."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, mastering your emotions and responses is a continuous process. Be patient with yourself as you learn and grow. Embrace the journey, and remember that every step you take towards emotional balance and peace is a step towards a more fulfilling life. You've got this!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Master your Emotions and Responses subliminal help me?`, 
    answer: `The Master your Emotions and Responses subliminal is designed to help you gain control over your emotions and reactions. By listening to the subliminal messages, you can reprogram your subconscious mind to respond to challenging situations with calmness, resilience, and emotional intelligence. Over time, you may notice a greater sense of inner peace, improved relationships, and a more balanced and fulfilling life.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Master your Emotions and Responses subliminal?`, 
    answer: `Results may vary depending on the individual and their unique circumstances. Some people may notice positive changes in their emotional responses and behaviors right away, while others may require more consistent listening for the effects to fully integrate into their daily lives. We recommend listening to the subliminal daily and consistently for best results.`,
    open: false 
  },
  { 
    question: `Can the Master your Emotions and Responses subliminal help me with specific emotions like anger or anxiety?`, 
    answer: `Yes, the Master your Emotions and Responses subliminal can help you develop more balanced and healthier responses to specific emotions like anger, anxiety, or stress. The subliminal messages are designed to reprogram your subconscious mind, empowering you to respond to these emotions in a more calm, rational, and constructive manner.`,
    open: false 
  },
  { 
    question: `Can the subliminal help me in situations where I feel overwhelmed or triggered?`, 
    answer: `Yes, by regularly listening to the Master your Emotions and Responses subliminal, you can train your mind to respond to overwhelming or triggering situations with greater clarity, resilience, and emotional control. The subliminal messages are designed to help you develop a more mindful and empowered approach to challenging circumstances.`,
    open: false 
  },
  { 
    question: `Are there any side effects or risks associated with listening to the Master your Emotions and Responses subliminal?`, 
    answer: `There are no known side effects or risks associated with listening to the Master your Emotions and Responses subliminal. However, it is important to note that the subliminal is intended to supplement personal growth and emotional well-being practices, not replace them. If you have any concerns about your emotional health, please consult with a mental health professional.`,
    open: false 
  },
  { 
    question: `Can the subliminal help me overcome deep-rooted emotional traumas?`, 
    answer: `While the Master your Emotions and Responses subliminal can support emotional well-being and resilience, it is not a substitute for professional therapy or help in overcoming deep-rooted emotional traumas. If you are dealing with trauma or significant emotional distress, we recommend seeking the guidance of a qualified mental health professional.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>