<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Living with a fear of spiders and arachnophobia can be challenging, but it doesn't have to control your life. This subliminal is designed to help you overcome your fear and live without fear or anxiety when faced with spiders."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help you:"
    },
    {
      type: 'list',
      items: [
        "Reprogram your subconscious mind to change your perception of spiders and reduce fear responses.",
        "Build confidence and resilience to face spiders without feeling overwhelmed or scared.",
        "Develop a sense of control and empowerment over your fear, allowing you to live without constant anxiety.",
        "Learn effective techniques and tools to manage your fear and overcome arachnophobia.",
        "Build a positive and calm mindset when encountering spiders, allowing you to coexist peacefully."
      ]
    },
    {
      type: 'paragraph',
      text: "With consistent use of this subliminal, you can gradually reduce your fear of spiders and arachnophobia. It may take time and effort, but you have the power to overcome your fear and live a life free from the constraints of arachnophobia. Remember, facing your fears can lead to personal growth and a newfound sense of freedom."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Living with a fear of spiders, or arachnophobia, can be distressing and limiting. This subliminal is designed to help you overcome your fear and live a life free from fear and anxiety. Before you start listening, consider these strategies to prepare yourself for the journey:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your fear: Recognize that your fear is valid and that you have taken a brave step towards overcoming it.",
        "Educate yourself: Learn about spiders, their behavior, and their positive contributions to the ecosystem. This can help dispel misconceptions and reduce anxiety.",
        "Set clear intentions: Clarify your desire to overcome your fear of spiders and embrace a life without anxiety.",
        "Create a safe space: Ensure you're in a comfortable and calm environment while listening, with no immediate spider-related triggers around."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal will work with your subconscious mind to rewire the fear responses associated with spiders. With consistent listening, you may experience a reduction in anxiety and a greater sense of freedom. Embrace this opportunity to conquer your fear, and let the journey to living without fear of spiders begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Fear of Spiders and Arachnophobia Removal session! By facing your fear head-on, you've taken a courageous step towards overcoming your phobia and living a life without fear. Now, it's important to continue your progress by reflecting on your experience and implementing the techniques you've learned."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Challenge your fear: Gradually expose yourself to spiders in controlled environments, using the techniques you've learned.",
        "Practice relaxation techniques: Use deep breathing or meditation to calm your mind and body when faced with fear or anxiety.",
        "Seek professional help if needed: If your fear continues to significantly impact your daily life, consider reaching out to a therapist or counselor.",
        "Be patient and kind to yourself: Overcoming a fear takes time and effort. Celebrate your progress, no matter how small."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember that fear is a natural response, but it doesn't have to control your life. Embrace the tools and techniques you've learned, and trust in your ability to overcome this fear. With time, practice, and persistence, you can live without the crippling fear of spiders and arachnophobia. Keep up the amazing work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Fear of Spiders and Arachnophobia Removal program help me overcome my fear?`, 
    answer: `The Fear of Spiders and Arachnophobia Removal program utilizes various techniques and tools, including subliminal messaging and guided visualization, to help you overcome your fear of spiders and arachnophobia. By repeatedly exposing you to positive and empowering messages about spiders, the program aims to rewire your subconscious mind's response to spiders and reduce fear and anxiety.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Fear of Spiders and Arachnophobia Removal program?`, 
    answer: `The time it takes to see results can vary from person to person. Some individuals may notice a reduction in fear and anxiety towards spiders after a few weeks of consistently using the program, while others may take longer. Consistency and repetition are key in rewiring subconscious responses, so we recommend using the program daily for the best results.`,
    open: false 
  },
  { 
    question: `Can the Fear of Spiders and Arachnophobia Removal program be used for severe arachnophobia?`, 
    answer: `While the Fear of Spiders and Arachnophobia Removal program can be beneficial for individuals with mild to moderate arachnophobia, it may not be sufficient as the sole treatment for severe arachnophobia. We recommend consulting with a mental health professional who specializes in phobias for more targeted and comprehensive treatment options.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of using the Fear of Spiders and Arachnophobia Removal program?`, 
    answer: `There are no known risks or side effects associated with using the Fear of Spiders and Arachnophobia Removal program. However, if you have any concerns about your fear of spiders or any mental health condition, please consult with a qualified mental health professional.`,
    open: false 
  },
  { 
    question: `Can the Fear of Spiders and Arachnophobia Removal program work for other phobias?`, 
    answer: `While the Fear of Spiders and Arachnophobia Removal program is specifically designed for individuals with a fear of spiders and arachnophobia, some techniques and strategies utilized in the program may be applicable to other phobias. However, for targeted treatment of specific phobias, it is recommended to seek guidance from a mental health professional who can provide tailored therapy approaches.`,
    open: false 
  },
  { 
    question: `Will the Fear of Spiders and Arachnophobia Removal program completely eliminate my fear of spiders?`, 
    answer: `The Fear of Spiders and Arachnophobia Removal program aims to help you reduce fear and anxiety towards spiders and empower you with tools to manage and overcome your phobia. While it can significantly diminish fear responses, individual results may vary. It is important to approach the program with an open mind and commitment to consistent use to achieve the best possible results.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>