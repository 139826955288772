<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you struggle with relationship anxiety and want to create healthier connections with your loved ones, this subliminal can be a valuable tool. By targeting your subconscious mind, it helps you heal and overcome anxiety within relationships."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Reducing feelings of anxiety and insecurity in romantic relationships.",
        "Improving communication and trust with your partner or loved ones.",
        "Letting go of fear, doubt, and negative thought patterns that hinder your relationships.",
        "Building healthier boundaries and increasing self-confidence within relationships.",
        "Creating a more fulfilling and positive emotional connection with your loved ones."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can rewire your subconscious mind towards healthier patterns of thinking and relating to others. This can lead to more fulfilling and harmonious relationships, where you can freely express yourself and cultivate a deeper connection with your loved ones. Remember, healing anxiety within relationships is a journey, and this subliminal can be a powerful tool along the way."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Healing and overcoming anxiety within relationships can be a transformative process that leads to healthier and more fulfilling connections with your loved ones. This subliminal is designed to support you on this journey. Before you start listening, consider the following preparation tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your anxiety: Recognize and accept your feelings of anxiety within relationships, allowing yourself to feel without judgment.",
        "Set your intention: Clarify your desire to heal and create healthier connections.",
        "Practice self-compassion: Be kind to yourself throughout the healing process, embracing self-care and self-love.",
        "Visualize harmonious relationships: Imagine yourself in fulfilling connections, filled with love, trust, and understanding.",
        "Affirm your worthiness: Repeat positive affirmations that promote your self-worth and ability to form healthy relationships."
      ]
    },
    {
      type: 'paragraph',
      text: "By utilizing this subliminal and incorporating these preparation tips, you can begin to heal relationship anxiety and pave the way for more fulfilling connections. Embrace the journey of self-discovery and self-growth, knowing that you have the power to create the relationships you desire and deserve."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Heal Relationship Anxiety subliminal session! You've taken a significant step towards healing and overcoming anxiety within your relationships. It's time to reflect on your experience and continue your journey towards creating healthier and more fulfilling connections with your loved ones."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your emotions: Take time to identify and acknowledge any anxiety triggers or patterns in your relationships.",
        "Communicate openly: Practice effective communication with your loved ones, expressing your needs and fears.",
        "Focus on self-care: Prioritize self-care activities that help you manage stress and anxiety.",
        "Seek professional help: Consider reaching out to a therapist or counselor who can provide guidance and support.",
        "Practice empathy: Seek to understand and empathize with the emotions and perspectives of your loved ones."
      ]
    },
    {
      type: 'paragraph',
      text: "Healing relationship anxiety is a journey that requires patience, understanding, and self-reflection. Embrace the process, remember to be kind to yourself, and keep working towards creating healthier and more fulfilling connections with your loved ones. You have the ability to build strong and nurturing relationships. Good luck!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Heal Relationship Anxiety subliminal help me?`, 
    answer: `The Heal Relationship Anxiety subliminal is designed to support you in healing and overcoming anxiety within your relationships. By listening to the subliminal messages, you can reprogram your subconscious mind to adopt more positive thoughts and beliefs about relationships, develop healthier coping mechanisms, and cultivate a sense of inner calm and security. Over time, this can lead to more fulfilling and harmonious connections with your loved ones.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Heal Relationship Anxiety subliminal?`, 
    answer: `Results may vary depending on the individual and the severity of their relationship anxiety. Some people may notice positive changes relatively quickly, while others may require more time and consistent listening. We recommend listening to the subliminal daily and being patient with the process of healing and transformation.`,
    open: false 
  },
  { 
    question: `Can the Heal Relationship Anxiety subliminal replace therapy or professional help?`, 
    answer: `No, the Heal Relationship Anxiety subliminal is not a substitute for therapy or professional help. It is designed to complement and support your healing journey. If you are experiencing severe anxiety or have underlying mental health conditions, it is important to seek the guidance of a qualified therapist or healthcare professional.`,
    open: false 
  },
  { 
    question: `How can I make the Heal Relationship Anxiety subliminal more effective?`, 
    answer: `To enhance the effectiveness of the subliminal, it can be helpful to combine it with therapy or counseling, practice self-care, and engage in activities that promote relaxation and stress reduction. Journaling, mindfulness, and setting healthy boundaries in your relationships can also support your healing process. Consistent and dedicated listening to the subliminal while actively implementing positive changes in your life can help facilitate transformation.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Heal Relationship Anxiety subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your mental health or relationship issues, it is recommended to seek professional guidance. The subliminal is intended to be a supplementary tool in your healing journey and should not replace professional help when needed.`,
    open: false 
  },
  { 
    question: `Will the Heal Relationship Anxiety subliminal make me stop caring about my relationships?`, 
    answer: `No, the Heal Relationship Anxiety subliminal is not designed to make you stop caring about your relationships. Its goal is to help you heal and overcome anxiety within relationships, allowing you to develop healthier and more fulfilling connections. You will still be able to care deeply for your loved ones while experiencing a greater sense of inner peace and security.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>