<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Building confidence when interacting with the opposite sex can greatly enhance your dating and relationship experiences. This subliminal is designed to help you boost your self-esteem and develop the skills needed to feel more comfortable and confident in these situations."
    },
    {
      type: 'heading',
      text: "Here's what this subliminal can help you achieve:"
    },
    {
      type: 'list',
      items: [
        "Increased self-confidence and self-worth, making you feel more attractive and desirable.",
        "Improved communication skills, allowing you to express yourself more clearly and effectively.",
        "Enhanced social skills, enabling you to connect with others and build meaningful relationships.",
        "Reduced anxiety and self-consciousness when interacting with potential partners.",
        "Developed assertiveness, helping you express your desires and set healthy boundaries in relationships."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can rewire your subconscious mind and overcome any limiting beliefs or insecurities that may be holding you back. As your confidence grows, you'll find it easier to approach, engage, and connect with the opposite sex. Remember, building genuine confidence takes time and practice, but with dedication, the results can be transformative."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Building confidence when interacting with the opposite sex can be challenging, but this subliminal is here to help you improve your dating and relationship skills. Before you press play, consider these tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Identify areas of improvement: Take a moment to reflect on specific areas where you feel less confident or would like to enhance your skills when interacting with the opposite sex.",
        "Visualize success: Imagine yourself confidently engaging in conversations, flirting, or going on dates.",
        "Practice self-care: Prioritize self-care activities that boost your self-esteem and make you feel good about yourself.",
        "Open your mind: Be open to new experiences and opportunities for growth as you listen to the subliminal, allowing the affirmations to support your mindset."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to help you build your confidence and improve your dating and relationship skills. As you listen regularly, you may start to feel more comfortable, self-assured, and successful in your interactions. Take a deep breath, embrace the journey towards self-improvement, and let's begin!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Confidence with the Opposite Sex subliminal session! By taking the initiative to boost your confidence in this area, you've already taken a great stride towards improving your dating and relationship skills. Now, let's reflect on your progress and discuss some post-listening tips to help you along your journey."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice self-acceptance: Focus on embracing your unique qualities and strengths.",
        "Challenge negative thoughts: Replace self-doubt with positive affirmations and constructive thinking.",
        "Engage in social activities: Attend events or join groups where you can interact with different people.",
        "Work on communication skills: Practice active listening and engaging in meaningful conversations."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, building confidence with the opposite sex is an ongoing process. Be patient with yourself and celebrate every step you take towards personal growth. You are capable of developing meaningful connections and enjoying fulfilling relationships. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Confidence with the opposite sex subliminal help me?`, 
    answer: `The Confidence with the opposite sex subliminal is designed to help boost your confidence and improve your dating and relationship skills when interacting with the opposite sex. By listening to the subliminal messages, you can work on reprogramming your subconscious mind to release any negative beliefs or insecurities that may be holding you back. This can lead to greater self-assurance, improved communication skills, and a more positive mindset when it comes to relationships.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Confidence with the opposite sex subliminal?`, 
    answer: `Results may vary from person to person, but with consistent listening, you may begin to notice positive changes in your confidence levels and interactions within a few weeks. The key is to listen to the subliminal daily and make a commitment to personal growth and practice in real-life situations.`,
    open: false 
  },
  { 
    question: `Can the Confidence with the opposite sex subliminal make me instantly confident?`, 
    answer: `While the Confidence with the opposite sex subliminal can help boost your confidence over time, it is not a quick fix solution. Building confidence is a process that requires consistent effort, self-reflection, and practice. The subliminal messages work alongside your own determination to create positive changes.`,
    open: false 
  },
  { 
    question: `How can I maximize the effectiveness of the Confidence with the opposite sex subliminal?`, 
    answer: `To maximize the effectiveness of the Confidence with the opposite sex subliminal, it is important to actively practice and implement what you learn in real-life situations. Focus on building positive self-talk, expanding your social circle, and pushing yourself outside of your comfort zone. Surrounding yourself with supportive and like-minded people can also help boost your confidence and improve your dating and relationship skills.`,
    open: false 
  },
  { 
    question: `Can the Confidence with the opposite sex subliminal work for everyone?`, 
    answer: `The Confidence with the opposite sex subliminal is designed to help individuals boost their confidence and improve their dating and relationship skills. While it can be beneficial for many, individual results may vary. It is important to approach self-improvement with an open mind and be consistent with listening to the subliminal messages.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Confidence with the opposite sex subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your confidence levels or mental health, please consult with a healthcare professional or therapist. It is important to note that the subliminal is designed to supplement personal growth and development, not replace professional advice.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>