<template>
  <Dialog :dialog="dialog" :width="800" :title="`Add Subliminal`" @closeDialog="closeDialog">
    <div>
      <div class="d-flex justify-center ma-5 mb-0">
        <Upload :upload="false" :files="files" v-if="dialog" @getImageName="getImageName" />
      </div>
      <v-row>
        <v-col cols="8">
          <p class="mb-1">Subliminal Name</p>
          <v-text-field solo dense hide-details class="mb-3" placeholder="Subliminal" v-model="title"></v-text-field>
        </v-col>
        <v-col cols="2">
          <p class="mb-1">Featured</p>
          <v-checkbox v-model="isfeatured" class="ma-0" style="padding-top: 8px"></v-checkbox>
        </v-col>
        <v-col cols="2">
          <p class="mb-1">Visible</p>
          <v-checkbox v-model="isVisible" class="ma-0" style="padding-top: 8px"></v-checkbox>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="9">
          <p class="mb-1">Color</p>
          <v-text-field solo dense hide-details disabled class="mb-3" placeholder="Color" v-model="color"></v-text-field>
        </v-col>
        <v-col cols="3">
          <p class="mb-1">Color Card</p>
          <el-color-picker class="colorcard" v-model="color"></el-color-picker>
        </v-col>
      </v-row>

      <p class="mb-1">Description</p>
      <v-textarea solo dense hide-details class="mb-3" placeholder="Description" v-model="description"
        rows="3"></v-textarea>

      <p class="mb-1">Guide Link</p>
      <v-text-field solo dense hide-details class="mb-3" placeholder="Guide Link" v-model="guide_link"></v-text-field>  
      
      <p class="mb-1">Guide HTML</p>
      <TextEditor :list="list" @listchange="listchange" />

      <p class="mb-1">Category Name</p>
      <v-autocomplete v-model="category" :items="categories" solo dense color="blue-grey lighten-2"
        placeholder="Categories" hide-details class="mb-3" item-text="name" item-value="id" />

      <v-row class="ma-0" style="gap: 20px">
        <v-col class="pa-0">
          <p class="mb-1">Subscription Name</p>
          <v-autocomplete v-model="subscription" :items="subscriptions" solo multiple small-chips clearable
            color="blue-grey lighten-2" label="Subscriptions" hide-details class="mb-0" item-text="name"
            item-value="id" />
        </v-col>
        <v-col class="pa-0">
          <p class="mb-1">Mood Name</p>
          <v-autocomplete v-model="mood" :items="moods" solo multiple small-chips clearable color="blue-grey lighten-2"
            label="Moods" hide-details class="mb-0" item-text="name" item-value="id" />
        </v-col>
      </v-row>
    </div>

    <template v-slot:action>
      <v-row class="ma-0">
        <v-col class="pl-0 pr-2">
          <v-btn block @click="submitDialog()" :disabled="disabled">Add</v-btn>
        </v-col>
        <v-col class="pl-2 pr-0">
          <v-btn block @click="closeDialog()">Cancel</v-btn>
        </v-col>
      </v-row>
    </template>
  </Dialog>
</template>

<script>
import Upload from '@/components/Upload.vue'
import Dialog from '@/components/Dialog.vue'
import TextEditor from "@/components/Admin/TextEditor.vue";

import { getMessage } from '@/utils'
import { addSubliminals } from '@/api/subliminal.js'
import { deleteLocalImage } from '@/api/file.js'
export default {
  props: {
    dialog: {
      type: Boolean
    }
  },
  components: {
    Upload,
    TextEditor,
    Dialog
  },
  data: () => {
    return {
      getMessage,
      addSubliminals,
      deleteLocalImage,

      isfeatured: 0,
      isVisible: 0,
      count: 0,

      mood: [],
      files: [],
      subscription: [],

      image: null,
      title: null,
      category: null,
      guide: null,
      color: null,
      description: null,
      texteditorvalue: null,
      text: null,
      list: null,
      guide_link: null,

    }
  },
  watch:{
    subscription(value){
      console.log(value.length)
    }
  },
  computed: {
    moods() {
      return this.$store.state.mood.data;
    },
    categories() {
      return this.$store.state.category.data;
    },
    subscriptions() {
      return this.$store.state.subscription.data;
    },
    disabled() {
      return (
        this.title == "" ||
        this.title == null ||
        this.description == "" ||
        this.description == null 
      )
    }
  },
  methods: {
    getImageName(value) {
      this.image = value
    },
    listchange(val) {
      this.texteditorvalue = val;
    },
    resetDialog() {
      this.isfeatured = 0
      this.isVisible = 0

      this.title = null
      this.category = null
      this.guide = null
      this.color = null
      this.description = null
      this.guide_link = null

      this.mood = []
      this.subscription = []
    },
    closeDialog() {
      this.$emit('closeDialog', false)
      this.deleteLocalImage(this.image)
      this.resetDialog()
    },
    submitDialog() {
      this.count++;
      if(this.count == 1){
        const data = {
          file: this.image,
          title: this.title,
          category_id: this.category,
          is_featured: this.isfeatured,
          is_visible: this.isVisible,
          guide: this.texteditorvalue,
          guide_link: this.guide_link,
          color: this.color,
          description: this.description,
          moods_id: this.mood.join(','),
          subscription_id: this.subscription.join(',')
        }

        this.addSubliminals(data).then((res) => {
          const subliminal = res.data

          this.getMessage(this, res)
          this.closeDialog()

          this.$store.dispatch("subliminal/getData", subliminal)
          this.count=0;
        })
      }
    }
  }
}
</script>
