<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Dealing with erectile dysfunction can be distressing and impact your confidence and relationship. This subliminal is designed to help you overcome erectile dysfunction and regain a fulfilling and satisfying sex life."
    },
    {
      type: 'heading',
      text: 'Benefits of using this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Improving blood flow to the penis, promoting stronger and longer-lasting erections.",
        "Releasing any emotional or psychological blocks that may contribute to erectile dysfunction.",
        "Boosting sexual confidence and self-esteem, allowing you to fully enjoy sexual experiences.",
        "Enhancing overall sexual performance and pleasure for yourself and your partner.",
        "Increasing relaxation and reducing stress, which can have a positive impact on sexual function."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind to support healthy sexual function and overcome the challenges of erectile dysfunction. With time, you may notice improvements in your sexual health, confidence, and overall well-being. Remember, consistency is key to achieving lasting results."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Dealing with erectile dysfunction can be emotionally challenging, but this subliminal is designed to help you regain confidence and improve your sexual health. Before starting your healing journey, here are a few things to keep in mind:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Educate yourself: Understand the various causes and solutions for erectile dysfunction so you can approach your healing journey with knowledge.",
        "Seek professional guidance: Consult with a healthcare professional to rule out any underlying medical conditions and to receive tailored advice.",
        "Address psychological factors: Acknowledge and work through any emotional or psychological factors that may contribute to your sexual health concerns.",
        "Set realistic expectations: While this subliminal can help support your healing process, it's important to understand that individual results may vary.",
        "Stay committed: Consistency is key, so aim to listen to the subliminal regularly and embrace the healing journey.",
        "Believe in your healing: Maintain a positive mindset and trust that you have the power to overcome your challenges and experience a fulfilling sex life once again."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is a tool to support your healing process, but it's important to approach it holistically. By combining the subliminal affirmations with professional guidance and a positive mindset, you can take significant strides towards overcoming erectile dysfunction and reclaiming your sexual confidence. Embrace the journey ahead, believe in yourself, and let the healing begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Erectile Dysfunction Healing session! By dedicating time and effort to address this issue, you've taken a positive step towards improving your sexual health and reclaiming confidence in your personal relationships."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Consult with a healthcare professional: If you haven't done so already, consider seeking medical advice to address any underlying physical or psychological factors.",
        "Communicate with your partner: Open and honest communication about your experiences and feelings can strengthen your relationship and foster understanding.",
        "Educate yourself: Continue learning about erectile dysfunction and the various treatment options available.",
        "Focus on overall well-being: Engage in regular exercise, maintain a healthy diet, and manage stress levels to support your sexual health."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, addressing erectile dysfunction is a journey that requires patience, self-compassion, and professional guidance. Be proud of the steps you've taken so far, and stay hopeful for a fulfilling and satisfying sex life ahead. You deserve it!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Erectile Dysfunction Healing subliminal work?`, 
    answer: `The Erectile Dysfunction Healing subliminal uses powerful affirmations and positive suggestions to reprogram your subconscious mind, helping you overcome any mental or emotional blocks that may be contributing to erectile dysfunction. By aligning your thoughts and beliefs with a healthy and confident mindset towards your sexual health, the subliminal aims to restore your confidence, improve blood flow, and promote overall sexual well-being.`,
    open: false
  },
  { 
    question: `How long will it take to see results from the Erectile Dysfunction Healing subliminal?`, 
    answer: `Results can vary depending on the individual and their unique circumstances. Some people may experience improvements in a relatively short period of time, while others may require more consistent listening to the subliminal to experience lasting benefits. We recommend listening to the subliminal daily and allowing yourself time to heal and adjust to the positive changes.`,
    open: false 
  },
  { 
    question: `Can the Erectile Dysfunction Healing subliminal cure all cases of erectile dysfunction?`, 
    answer: `The Erectile Dysfunction Healing subliminal is designed to address and improve the mental and emotional aspects of erectile dysfunction. While it can be beneficial for many individuals, it is important to understand that the subliminal is not a substitute for medical advice or treatment. If you are experiencing persistent or severe erectile dysfunction, we recommend consulting with a healthcare professional for a comprehensive evaluation and appropriate treatment options.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the Erectile Dysfunction Healing subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your sexual health or underlying medical conditions, it is recommended to consult with your healthcare provider before making any changes to your treatment plan.`,
    open: false 
  },
  { 
    question: `Can I stop using other forms of treatment if I start listening to the Erectile Dysfunction Healing subliminal?`, 
    answer: `No, if you are currently undergoing treatment for erectile dysfunction, it is important to continue following the guidance of your healthcare provider. The Erectile Dysfunction Healing subliminal is not intended to replace medical treatment but can be used as a complementary tool to support your overall sexual health.`,
    open: false 
  },
  { 
    question: `Will the Erectile Dysfunction Healing subliminal guarantee a complete recovery from erectile dysfunction?`, 
    answer: `While the Erectile Dysfunction Healing subliminal can provide support and assistance in overcoming mental and emotional blocks related to erectile dysfunction, individual results may vary. It is important to approach your recovery holistically, including both mental and physical aspects. If you have concerns about your sexual health, it is recommended to consult with a healthcare professional for personalized guidance.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>