<template>
  <div>
    <v-navigation-drawer
      class="sidebar"
      v-if="isMobile"
      app
      v-model="drawer"
      temporary
      fixed
      stateless
      @transitionend="collapse"
      v-click-outside="closeDrawer"
    >
      <SidebarItem :menus="menus" />
    </v-navigation-drawer>

    <v-navigation-drawer
      v-if="!isMobile && !isMain"
      app
      mini-variant
      expand-on-hover
      @transitionend="collapse"
    >
      <SidebarItem :menus="menus" />
    </v-navigation-drawer>
  </div>
</template>

<script>
import { sidebar2 } from "@/utils/data";
import SidebarItem from "@/components/Home/SidebarItem";
export default {
  props: ["drawer"],
  components: {
    SidebarItem,
  },
  data: () => {
    return {
      sidebar2,
    };
  },
  computed: {
    layout() {
      return this.$route.matched[0].meta.layout;
    },
    menus() {
      if (this.layout == "Main") return this.sidebar2.main;
      if (this.layout == "User") return this.sidebar2.user;
      if (this.layout == "Admin") return this.sidebar2.admin;
    },
    isMain() {
      return this.layout === "Main";
    },
    isMobile() {
      return this.$store.state.screen.width < 1264;
    },
  },
  methods: {
    closeDrawer(e) {
      if (e.target.className === "v-overlay__scrim") {
        this.$emit("closeDrawer", false);
      }
    },
    collapse() {
      this.menus.map((item) => (item.active = false));
    },
  },
};
</script>
