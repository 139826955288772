<template>
  <Dialog
    :dialog="dialog"
    :width="800"
    :title="`Edit Mood`"
    @closeDialog="closeDialog"
  >
    <div>
      <div class="d-flex justify-center ma-5 mb-0">
        <Upload :upload="dialog" :files="files" v-if="dialog" @getImageName="getImageName" />
      </div>

      <p class="mb-1">Title</p>
      <v-text-field
        solo
        dense
        hide-details
        class="mb-3"
        placeholder="Title"
        v-model="title"
      ></v-text-field>

      <p class="mb-1">Description</p>
      <TextEditor :list="list" @listchange="listchange" />

    </div>

    <template v-slot:action>
      <v-row class="ma-0">
        <v-col class="pl-0 pr-2">
          <v-btn
            block
            @click="submitDialog()"
            :disabled="disabled || disabledbutton"
            >Update</v-btn
          >
        </v-col>
        <v-col class="pl-2 pr-0">
          <v-btn block @click="closeDialog()">Cancel</v-btn>
        </v-col>
      </v-row>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "@/components/Dialog.vue";
import Upload from "@/components/Upload.vue";
import TextEditor from "@/components/Admin/TextEditor.vue";

import { getMessage } from "@/utils";
import { updateMagusDetailsInfos } from "@/api/magusdetails.js";
import { deleteLocalImage } from "@/api/file.js";

export default {
  props: {
    dialog: {
      type: Boolean,
    },
    data: {
      type: Object,
    },
  },
  components: {
    Dialog,
    TextEditor,
    Upload,
  },
  data: () => {
    return {
      getMessage,
      updateMagusDetailsInfos,
      deleteLocalImage,

      files: [],
      image: null,
      text: null,
      texteditorvalue: null,

      id: null,
      sub_title: null,
      title: null,
      description: null,
      remarks: null,
      list: null,

      disabledbutton: false,
    };
  },
  created() {
    this.getData();
  },
  computed: {
    disabled() {
      return this.title == "" || this.title == null;
    },
  },
  methods: {
    getImageName(value) {
      this.image = value
    },
    getText(val) {
      this.texteditorvalue = val;
    },
    listchange(val) {
      this.texteditorvalue = val;
    },
    getData() {
      this.files = [];

      this.id = this.data.id;
      this.title = this.data.title;
      this.remarks = this.data.remarks;
      this.description = this.data.description;
      this.text = this.data.description;
      this.list = this.data.description;

      if (this.files.length == 0) {
        this.files.push({
          name: 'mood',
          url: this.data.cover
        })
      }
    },
    resetDialog() {
      this.title = null;
      this.remarks = null;
      this.description = null;
      this.image = null;
    },
    closeDialog() {
      this.$emit("closeDialog", false);
      this.deleteLocalImage(this.image);
      this.resetDialog();
    },
    submitDialog() {
      this.disabledbutton = true;
      const data = {
        id: this.id,
        title: this.title,
        remarks: this.remarks,
        description: this.texteditorvalue,
        file: this.image,
        image: this.image,
      };

      this.updateMagusDetailsInfos(data).then((res) => {
        const information = res.data;

        this.getMessage(this, res);
        this.closeDialog();

        this.$store.dispatch("information/getData", information);
      });
    },
  },
};
</script>

<style>
.colorcard .el-color-picker__trigger {
  width: 80px;
}
</style>
