<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Ready to bring more success into your life? This subliminal is designed to help you align your mindset and attract success in both your personal and professional endeavors."
    },
    {
      type: 'heading',
      text: "Here's how it works:"
    },
    {
      type: 'list',
      items: [
        "By harnessing the power of positive affirmations, this subliminal helps rewire your subconscious mind to focus on success and abundance.",
        "It encourages a positive and growth-oriented mindset, allowing you to see opportunities and take confident action.",
        "Through repetitive listening, it strengthens your belief in your ability to achieve your goals.",
        "It helps you overcome limiting beliefs and self-doubt, empowering you to step out of your comfort zone and embrace new opportunities.",
        "It aligns your thoughts, emotions, and actions with success, creating a harmonious flow towards your desired outcomes."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, attracting success is not just about luck - it's about cultivating the right mindset and taking consistent action. As you listen to this subliminal regularly, you may start noticing positive shifts in your confidence, motivation, and overall success mindset. Keep focusing on your goals and trust in your ability to achieve greatness!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Attracting success requires a mindset that is aligned with your goals and ambitions. This subliminal is designed to help you cultivate that mindset and bring success into your life. Before you start listening, here are some tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your goals: Take some time to reflect on what success means to you and set clear, specific goals.",
        "Visualize success: Imagine yourself achieving your goals and experiencing the emotions and benefits that come with it.",
        "Stay positive: Focus on positive affirmations and beliefs about your ability to attract success.",
        "Create a supportive environment: Surround yourself with people, resources, and inspirations that align with your aspirations."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you may notice an increase in your confidence, motivation, and opportunities coming your way. Embrace the power of your mind to attract success and step into your full potential. Now, let's begin this transformative journey together."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Attract Success subliminal session! By listening to this audio, you have taken an important step towards aligning your mindset and attracting success in both your personal and professional life. Now it's time to reflect on your experience and continue to manifest success in all areas of your life."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Visualize your success: Take a moment each day to imagine yourself achieving your goals and feeling successful.",
        "Set clear intentions: Write down your goals and intentions for success, keeping them in focus as you move forward.",
        "Take inspired action: Break down your goals into actionable steps and take consistent action towards achieving them.",
        "Surround yourself with positivity: Surround yourself with individuals who believe in your potential and support your success."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, success is a journey, and it requires dedication, perseverance, and a positive mindset. Embrace the opportunities that come your way and trust in your ability to attract success. Believe in yourself, stay committed to your goals, and watch as your dreams become a reality. You are destined for greatness!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Attract Success subliminal work?`, 
    answer: `The Attract Success subliminal works by sending positive affirmations directly to your subconscious mind. It helps reprogram your beliefs and thoughts to align with success, abundance, and achievement. By rewiring your mindset, you can attract more opportunities, develop a sense of confidence, and take inspired action towards your goals.`,
    open: false
  },
  { 
    question: `How long should I listen to the Attract Success subliminal each day?`, 
    answer: `To experience the best results, we recommend listening to the Attract Success subliminal for at least 30 minutes each day. Consistency is key, so try to incorporate it into your daily routine. You can listen while working, relaxing, or even during activities like exercising or meditating.`,
    open: false 
  },
  { 
    question: `Can the Attract Success subliminal help me in both my personal and professional life?`, 
    answer: `Yes, the Attract Success subliminal is designed to help you align your mindset and attract success in all areas of your life, both personal and professional. By shifting your beliefs and thought patterns, you can open yourself up to new opportunities, enhance your personal relationships, and excel in your career.`,
    open: false 
  },
  { 
    question: `How long does it take to see results from the Attract Success subliminal?`, 
    answer: `Results can vary from person to person, depending on individual circumstances and mindset. Some may notice positive changes relatively quickly, while others may require more time and consistent listening. For best results, we recommend listening to the subliminal regularly and remaining open to new opportunities and possibilities in your life.`,
    open: false 
  },
  { 
    question: `Can the Attract Success subliminal eliminate the need for hard work and effort?`, 
    answer: `While the Attract Success subliminal can help align your mindset and attract opportunities, it is important to note that success still requires effort and action on your part. The subliminal can inspire and motivate you, but it is ultimately up to you to take the necessary steps and put in the hard work to achieve your goals.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Attract Success subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Attract Success subliminal. However, if you have any concerns or underlying mental health conditions, it is always best to consult with a healthcare professional before incorporating any audio programs into your routine.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>