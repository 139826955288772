
<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Managing epilepsy can be a challenging journey, but this subliminal is here to offer support. By harnessing the power of your mind, it aims to promote healing, reduce seizures, and improve your overall well-being."
    },
    {
      type: 'heading',
      text: 'Benefits of using this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Supporting the healing process by redirecting your mind towards positive thoughts and beliefs.",
        "Reducing the frequency and severity of seizures.",
        "Promoting relaxation and stress reduction, which can be beneficial in managing epilepsy.",
        "Enhancing your overall well-being and emotional resilience.",
        "Boosting your confidence in managing and living with epilepsy."
      ]
    },
    {
      type: 'paragraph',
      text: "Through consistent listening, this subliminal can help you rewire your subconscious mind, empowering you to take control of your epilepsy journey. While it may not replace medical treatments or interventions, it can serve as a complementary tool to support your overall well-being. Remember, it's important to consult with your healthcare provider for guidance on managing epilepsy."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Taking control of your epilepsy and improving your overall well-being can be a challenging journey, but this subliminal is here to support you. Before you start listening, consider these preparation tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Educate yourself: Learn about epilepsy and its management options to empower yourself with knowledge.",
        "Consult with your healthcare professional: Discuss your intentions and goals related to using this subliminal as part of your healing journey.",
        "Set realistic goals: Identify specific areas where you wish to experience improvements and make peace with your body.",
        "Embrace relaxation techniques: Practice stress management techniques like deep breathing, meditation, or gentle exercise before listening to enhance your state of mind.",
        "Stay consistent: Commit to regular listening and trust the process, knowing that positive changes may take time."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to harness the power of your mind to heal and manage epilepsy while improving your overall well-being. Embrace this opportunity to take an active role in your health, and let the journey towards healing begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Epilepsy Healing subliminal session! By harnessing the power of your mind, you've taken a significant step towards managing and healing epilepsy while enhancing your overall well-being. It's important to reflect on this experience and continue your journey towards better health."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Stay consistent: Continue listening to the subliminal regularly to reinforce positive changes and promote healing.",
        "Consult professionals: Work with your healthcare team to create a comprehensive plan tailored to your specific needs.",
        "Practice stress management: Explore relaxation techniques like deep breathing, meditation, or yoga to reduce stress levels.",
        "Engage in self-care: Prioritize your mental and physical well-being through activities you enjoy, such as hobbies and spending time with loved ones."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, managing epilepsy and promoting healing is a holistic endeavor that requires a multidimensional approach. Stay committed to taking care of yourself, and trust in your ability to overcome challenges. Your journey towards better health is filled with possibilities and hope."
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Can the Epilepsy Healing subliminal cure my epilepsy?`, 
    answer: `The Epilepsy Healing subliminal is designed to help manage epilepsy and improve overall well-being. It can be used as a complementary tool to your current treatment plan, but it is not intended to cure epilepsy on its own. We recommend consulting with your healthcare provider for a comprehensive approach to managing your condition.`,
    open: false
  },
  { 
    question: `How does the Epilepsy Healing subliminal work?`, 
    answer: `The Epilepsy Healing subliminal works by influencing your subconscious mind with positive affirmations and suggestions related to epilepsy management and overall well-being. It aims to reduce stress, promote relaxation, and encourage a positive mindset towards your condition. Consistent listening, in combination with other recommended therapies or treatments, can enhance its effectiveness.`,
    open: false 
  },
  { 
    question: `How long does it take to see results from the Epilepsy Healing subliminal?`, 
    answer: `The time it takes to see results from the Epilepsy Healing subliminal may vary from person to person. It's important to understand that subliminals work on a subconscious level and changes may occur gradually over time. We recommend listening to the subliminal regularly and consistently for an extended period to maximize its potential benefits.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Epilepsy Healing subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Epilepsy Healing subliminal. However, it is important to note that the subliminal is not a replacement for medical advice or treatment. If you have any concerns about your condition or treatment plan, please consult with your healthcare provider.`,
    open: false 
  },
  { 
    question: `How can I make the Epilepsy Healing subliminal more effective?`, 
    answer: `To enhance the effectiveness of the Epilepsy Healing subliminal, it is recommended to listen to it in a relaxed state, such as before sleep or during meditation. Additionally, incorporating stress-reducing activities into your daily routine, such as deep breathing exercises and mindfulness practices, can further support the positive effects of the subliminal.`,
    open: false 
  },
  { 
    question: `Can the Epilepsy Healing subliminal be used alongside medication?`, 
    answer: `Yes, the Epilepsy Healing subliminal can be used alongside medication as a complementary tool. It is essential to follow the guidance of your healthcare provider regarding your medication and treatment plan. The subliminal is intended to support overall well-being and epilepsy management, but it does not replace medical advice or treatment.`,
    open: false 
  }
] 
        };
    },
};
</script>

