import request from "@/utils/request";

export const getSearch = (data) => {
  return request.post("/api/search/history", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};
export const getClearSearch = (data) => {
  return request.post("/api/search/clear", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const addSearch = (data) => {
  return request.post("/api/search/history/add", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const getSearchResult = (data) => {
  return request.post("/api/search/filter2", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const getSearchPlaylist = (data) => {
  return request.post("/api/own/playlist/search/filter", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const getSearchListenToPlaylist = (data) => {
  return request.post("/api/playlist/magus/user", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const getSearchSubAndPlaylistResult = (data) => {
  return request.post("/api/search/filter/page", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};
