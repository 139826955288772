<template>
  <img
    :class="`${isFromPlayer ? 'disabled' : ''}`"
    :src="require(`@/assets/images/user/webplayer/${text}/favorite.png`)"
    @click="isFromPlayer ? '' : toggleData(data)"
  />
</template>

<script>
import { toggleData } from "@/utils/favorite.js";
import { mapGetters } from "vuex";
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    text: {
      type: String,
      default: () => {
        return null;
      },
    },
    fromPlayer: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  computed: {
    ...mapGetters({
      isDataReady: "newaudio/getDataStatus",
    }),
    isFromPlayer() {
      return !this.isDataReady && this.fromPlayer;
    },
  },
  data: () => {
    return {
      toggleData,
    };
  },
};
</script>
