<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're passionate about archery and want to take your skills to the next level, this subliminal can help you achieve mastery. By targeting your subconscious mind, it provides the mental and emotional support needed for training and skill enhancement."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can assist you:'
    },
    {
      type: 'list',
      items: [
        "Improving focus, concentration, and mental clarity during archery practice.",
        "Enhancing muscle coordination, strength, and accuracy for precise shots.",
        "Boosting confidence and self-belief in your archery abilities.",
        "Increasing motivation and dedication to regular practice and training.",
        "Reducing performance anxiety and pressure, allowing you to shoot with ease and confidence."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal consistently, you can align your subconscious mind with your archery goals and unlock your full potential as an archer. Remember that mastery takes time and practice, but with the right mindset, you can become a skilled and confident archer."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a journey to master archery requires dedication and focus. This subliminal is designed to enhance your skills and training in this ancient art. Before you begin, consider these tips to optimize your learning experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Define what you want to achieve in archery, whether it's improving accuracy, increasing distance, or mastering various shooting techniques.",
        "Establish a consistent practice routine: Dedicate regular time to practicing your archery skills to build muscle memory and improve overall proficiency.",
        "Engage in mental visualization: Visualize yourself hitting targets with precision, embodying the skills and mindset of an expert archer.",
        "Stay motivated and determined: Remind yourself of your passion for archery and your commitment to improving, even during challenging moments."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your archery journey, you can tap into the power of your subconscious mind to enhance your skills and propel yourself towards mastery. Remember to practice regularly, maintain focus on your goals, and trust in your ability to become a master archer. Let's begin this transformative journey!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Archery Skills and Training Mastery session! By dedicating your time and effort to mastering the art of archery, you've embarked on a fulfilling journey of skill development and personal growth."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice consistently: Regularly engage in archery sessions to build muscle memory and improve your accuracy.",
        "Set goals: Establish achievable targets and milestones to track your progress and maintain motivation.",
        "Seek professional guidance: Consider working with an experienced coach or joining a local archery club to receive guidance and feedback.",
        "Strengthen your body: Engage in complementary exercises to enhance your strength, flexibility, and overall physical fitness."
      ]
    },
    {
      type: 'paragraph',
      text: "Mastering the art of archery requires dedication, patience, and resilience. Embrace the challenges and enjoy the journey towards becoming a skilled archer. Remember, practice makes progress, and with each arrow you release, you are one step closer to archery mastery. Keep aiming high!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Is this subliminal suitable for beginners in archery?`, 
    answer: `Yes, the Archery Skills and Training Mastery subliminal can be beneficial for both beginners and experienced archers. It is designed to help enhance your skills and improve your mindset for archery. Whether you are just starting out or looking to take your skills to the next level, this subliminal can support your training and development.`,
    open: false
  },
  { 
    question: `How often should I listen to the Archery Skills and Training Mastery subliminal?`, 
    answer: `Consistency is key when using subliminals. For best results, we recommend listening to the Archery Skills and Training Mastery subliminal daily. Repetition and regular exposure to the positive affirmations and suggestions can help reprogram your subconscious mind and enhance your archery skills over time.`,
    open: false 
  },
  { 
    question: `Can this subliminal help improve my focus and concentration in archery?`, 
    answer: `Yes, the Archery Skills and Training Mastery subliminal includes affirmations and suggestions that can help enhance your focus and concentration during archery practice and competition. By reprogramming your subconscious mind, it can help you stay present, calm, and focused, allowing you to perform at your best.`,
    open: false 
  },
  { 
    question: `Can the Archery Skills and Training Mastery subliminal replace actual archery practice?`, 
    answer: `No, the subliminal is intended to complement and enhance your archery training, not replace it. Regular practice, instruction, and physical training are still essential for skill development in archery. The subliminal works in conjunction with your practice to positively influence your mindset and mindset for archery.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of using the Archery Skills and Training Mastery subliminal?`, 
    answer: `There are no known risks or side effects associated with using the Archery Skills and Training Mastery subliminal. However, if you have any concerns about your training or physical health, it is always a good idea to consult with a qualified archery coach or healthcare professional.`,
    open: false 
  },
  { 
    question: `Can this subliminal help with archery competitions and performance anxiety?`, 
    answer: `Yes, the Archery Skills and Training Mastery subliminal can assist in managing performance anxiety and enhancing your performance during archery competitions. By promoting a positive mindset, confidence, and relaxation, it can help you perform at your best under pressure. It is important to combine the subliminal with appropriate training and mental preparation techniques for optimal results.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>