<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're struggling with self-harming habits and want to break free, this subliminal can be a powerful tool in your journey towards self-healing and self-love. By targeting your subconscious mind, it can help you create a healthier and happier relationship with yourself."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help you:"
    },
    {
      type: 'list',
      items: [
        "Promoting self-compassion and self-acceptance, fostering a positive self-image.",
        "Helping you develop healthier coping mechanisms and alternative ways to deal with emotional pain or distress.",
        "Reducing the intensity and frequency of urges to engage in self-harm.",
        "Boosting your self-esteem and confidence, empowering you to make positive changes in your life.",
        "Encouraging self-care practices and nurturing your mental and emotional well-being."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen regularly to this subliminal, you may begin to notice subtle shifts in your mindset and behaviors. It's important to remember that healing takes time and patience, but with consistent use and a commitment to self-growth, you can create a life free from self-harming habits. You deserve love, support, and a healthy relationship with yourself."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Breaking free from self-harming habits is a courageous step towards a healthier and happier relationship with yourself. This subliminal is designed to support and empower you on your journey. Before you start listening, consider these strategies to maximize your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Seek professional help: If you haven't already, reach out to a mental health professional who can provide guidance, support, and resources.",
        "Create a safe space: Find a comfortable and quiet environment where you can listen to the subliminal without distractions.",
        "Focus on your intention: Clarify your desire to break free from self-harming habits and cultivate self-love and self-compassion.",
        "Practice self-care: Engage in activities that promote self-care, such as journaling, practicing relaxation techniques, or engaging in hobbies that bring you joy.",
        "Be patient and kind to yourself: Understand that healing takes time and setbacks may happen. Practice self-compassion along the way."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is a tool to support your journey, but it's important to seek professional help and resources to address this challenging issue. As you listen, allow the positive affirmations to penetrate deeply into your subconscious mind, reshaping your thoughts and emotions around self-harm. Embrace this opportunity for healing and growth, and take the first step towards creating a healthier and happier relationship with yourself."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Stop Self-Harming subliminal session! You have taken a significant step towards breaking free from self-harming habits and nurturing a healthier and happier relationship with yourself. It's important to reflect on your journey so far and continue building a positive mindset and self-care practices."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Seek support: Reach out to trusted friends, family members, or mental health professionals for guidance and support.",
        "Practice self-compassion: Be kind to yourself and acknowledge the progress you've made on this journey of healing.",
        "Identify triggers: Reflect on situations or emotions that tend to lead to self-harming behaviors and develop coping strategies.",
        "Explore healthier coping mechanisms: Engage in activities that promote relaxation, self-expression, and self-care."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, healing takes time and self-forgiveness. Be patient and compassionate towards yourself as you work towards creating a healthier and happier relationship with yourself. You deserve love, care, and support, and you are capable of breaking free from self-harming habits. Keep going!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Stop Self-Harming subliminal help me?`, 
    answer: `The Stop Self-Harming subliminal is designed to help you break free from self-harming habits and develop a healthier and happier relationship with yourself. By listening to the subliminal messages, you can reprogram your subconscious mind to adopt more positive coping mechanisms, increase self-esteem, and nurture self-compassion. It is important to listen to the subliminal consistently and pair it with additional support, such as therapy, if needed.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Stop Self-Harming subliminal?`, 
    answer: `Results from the Stop Self-Harming subliminal can vary from person to person. Some individuals may notice positive changes and a reduction in self-harming behaviors after a few listens, while others may require more time and consistent practice. We recommend listening to the subliminal daily for best results and seeking professional help if necessary.`,
    open: false 
  },
  { 
    question: `Is listening to the Stop Self-Harming subliminal enough to recover from self-harming habits?`, 
    answer: `While the Stop Self-Harming subliminal can be a helpful tool in the recovery process, it is important to note that self-harm is a complex issue that often requires professional support. The subliminal can assist in creating a positive mindset and reducing self-harming tendencies, but it should not be considered as a standalone solution. It is highly recommended to seek help from a therapist or counselor who specializes in self-harm recovery.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the Stop Self-Harming subliminal?`, 
    answer: `There are no known risks or side effects associated with listening to the Stop Self-Harming subliminal. However, if you have concerns about your mental health or self-harming behaviors, it is crucial to consult with a mental health professional. The subliminal should be used as a complement to a comprehensive treatment plan, not as a substitute for professional help.`,
    open: false 
  },
  { 
    question: `Can the Stop Self-Harming subliminal work for everyone?`, 
    answer: `The effectiveness of the Stop Self-Harming subliminal can vary from person to person. While many individuals find it helpful in reducing self-harming behaviors and developing healthier coping strategies, it may not work the same way for everyone. It is important to approach the subliminal with an open mind, combine it with other forms of support if needed, and consult with a mental health professional for personalized guidance.`,
    open: false 
  },
  { 
    question: `What should I do if I experience urges to self-harm while listening to the subliminal?`, 
    answer: `If you experience urges to self-harm while listening to the subliminal, it is important to reach out for immediate support. Contact a mental health professional, helpline, or trusted individual who can provide assistance. The subliminal is designed to promote positive change, but it may not be sufficient to address acute crises. Your safety and well-being are the top priority, so don't hesitate to seek help.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>