<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Talking too fast can often hinder effective communication and make it difficult for others to understand you. This subliminal is designed to help you overcome the habit of talking too fast and improve your communication skills."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help you:"
    },
    {
      type: 'list',
      items: [
        "By targeting your subconscious mind, it can help you become more aware of your speaking speed.",
        "It encourages you to slow down your speech and communicate at a pace that is comfortable for others to understand.",
        "It helps you develop clarity and enunciation in your words, enhancing the overall effectiveness of your communication.",
        "This subliminal can also assist in reducing anxiety or nervousness that may contribute to talking too fast.",
        "Regular use of this subliminal can lead to improved communication skills and a more confident speaking style."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, change takes time and practice. As you listen to this subliminal regularly, you may start to notice a difference in your speaking speed and overall communication. Embrace the process and be patient with yourself as you work towards improving your communication skills."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "If you find yourself constantly talking too fast and struggling to effectively communicate, this subliminal can help you break that habit and improve your communication skills. Before you start listening, consider the following tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Awareness: Take note of when you tend to speak too quickly and in which situations. This will help you identify triggers and areas to focus on.",
        "Deep breathing: Practice deep breathing exercises to help you relax and slow down your speech patterns.",
        "Conscious pauses: Intentionally pause between sentences or thoughts to give yourself time to gather your thoughts and speak at a more comfortable pace.",
        "Active listening: Pay attention to the pace of others' speech and try to match it, as this can help you align your own speaking speed.",
        "Positive affirmations: Affirm to yourself that you can speak clearly and confidently at a comfortable pace."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to help you overcome the habit of talking too fast and improve your communication skills. As you listen regularly, you may notice yourself speaking with greater clarity, confidence, and a more relaxed pace. Embrace this opportunity for self-improvement and let the journey towards effective communication begin!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Stop Talking too fast subliminal session! By addressing and overcoming the habit of talking too fast, you've taken a significant step towards improving your communication skills and enhancing your overall effectiveness as a speaker. Now, let's reflect on your progress and determine the next steps to continue your growth."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your speaking patterns: Take note of instances where you tend to talk too fast and the situations that trigger it.",
        "Practice mindful speaking: Engage in activities that promote conscious speaking, such as reading aloud or engaging in conversations at a slower pace.",
        "Utilize breathing techniques: Take deep breaths before speaking to help regulate your pace and promote a calmer delivery.",
        "Seek feedback: Ask trusted friends or colleagues to provide input on your speaking speed and offer suggestions for improvement."
      ]
    },
    {
      type: 'paragraph',
      text: "Overcoming the habit of talking too fast requires practice and patience. Be kind to yourself and embrace the process of becoming a more effective communicator. Remember, each effort you make to slow down your speech brings you closer to mastering this skill. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Stop Talking too Fast subliminal help me slow down my speech?`, 
    answer: `The Stop Talking too Fast subliminal is designed to help your subconscious mind overcome the habit of talking too quickly. By listening to the subliminal messages, you can reprogram your mind to adopt a slower and more intentional speaking pace. The subliminal works in conjunction with conscious efforts to practice speaking slowly and mindfully.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Stop Talking too Fast subliminal?`, 
    answer: `The time it takes to see results can vary from person to person. It depends on various factors such as the individual's level of commitment, consistency in listening to the subliminal, and the extent of the habit of talking too fast. However, with regular and consistent listening, combined with conscious effort to speak slowly, you can gradually see improvements in your communication skills.`,
    open: false 
  },
  { 
    question: `Can the Stop Talking too Fast subliminal help with public speaking anxiety?`, 
    answer: `While the Stop Talking too Fast subliminal is primarily designed to help individuals overcome the habit of speaking quickly, it can indirectly assist with public speaking anxiety. By promoting a slower speaking pace, the subliminal can help individuals feel more in control of their speech, reducing anxiety and improving overall communication confidence. However, for severe anxiety, it may be beneficial to seek additional professional support.`,
    open: false 
  },
  { 
    question: `Is it necessary to actively practice speaking slowly while listening to the Stop Talking too Fast subliminal?`, 
    answer: `Yes, actively practicing speaking slowly is crucial for maximum effectiveness. The subliminal messages work in conjunction with conscious effort and practice. By consciously slowing down your speech and being mindful of your pace, you can strengthen the new habit. Consistency and persistence in both listening to the subliminal and practicing slow speech will yield the best results.`,
    open: false 
  },
  { 
    question: `Will the Stop Talking too Fast subliminal affect my ability to speak fluently?`, 
    answer: `No, the subliminal is not intended to affect your overall fluency in speaking. Its purpose is to help you overcome the habit of talking too fast and develop a more deliberate and controlled speaking style. With consistent practice and listening, you can improve your communication skills while maintaining fluency in your speech.`,
    open: false 
  },
  { 
    question: `Can I use the Stop Talking too Fast subliminal while multitasking or doing other activities?`, 
    answer: `It is recommended to listen to the Stop Talking too Fast subliminal in a focused and distraction-free environment. Listening while multitasking or doing other activities may reduce the effectiveness of the subliminal messages. Find a quiet and comfortable space where you can fully concentrate on the audio for optimal results.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>