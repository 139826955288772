import { render, staticRenderFns } from "./20-general-weight-loss.vue?vue&type=template&id=39e3dfc8&scoped=true&"
import script from "./20-general-weight-loss.vue?vue&type=script&lang=js&"
export * from "./20-general-weight-loss.vue?vue&type=script&lang=js&"
import style0 from "./20-general-weight-loss.vue?vue&type=style&index=0&id=39e3dfc8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39e3dfc8",
  null
  
)

export default component.exports