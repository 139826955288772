<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're looking to attract abundant money and improve your financial situation, this subliminal can help you manifest wealth and prosperity. By targeting your subconscious mind, it aims to shift your beliefs and mindset around money."
    },
    {
      type: 'heading',
      text: "Here's how it works:"
    },
    {
      type: 'list',
      items: [
        "Reprograms your subconscious mind to embrace abundance and attract wealth.",
        "Helps you release any limiting beliefs or negative energy related to money.",
        "Increases your confidence and belief in your ability to create financial abundance.",
        "Aligns your thoughts, emotions, and actions with the frequency of unlimited wealth.",
        "Empowers you to take inspired action and make wise financial decisions."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal consistently, you can unlock your money manifestation abilities and open yourself up to opportunities for financial growth. Remember, manifesting abundance is a journey, and it's important to stay patient, positive, and open to receiving all the wealth and prosperity that is available to you."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Unlocking the power of your mind to manifest abundant wealth and financial prosperity is within your reach with this subliminal. Before you start listening, consider these steps to maximize your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear intentions: Clearly define your financial goals and what abundance means to you, whether it's a specific amount of money, financial freedom, or the ability to live a lavish lifestyle.",
        "Create a positive money mindset: Overcome any limiting beliefs or negative thoughts surrounding money by replacing them with positive affirmations and visualizations of unlimited wealth.",
        "Take inspired action: Use the motivation you gain from this subliminal to take proactive steps towards your financial goals, such as creating a budget, exploring investment opportunities, or pursuing new income streams.",
        "Embrace gratitude: Cultivate a sense of gratitude for the money you currently have and the abundance that is coming your way. Express gratitude for every financial blessing, big or small.",
        "Keep your mind receptive: While listening, allow yourself to fully absorb the subliminal affirmations and visualize yourself already living a life of abundance and financial prosperity."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is a powerful tool to rewire your subconscious mind and align your thoughts with the frequency of wealth and abundance. As you listen regularly, you may notice a shift in your mindset, increased motivation, and opportunities flowing into your life. Embrace this transformative journey and open yourself up to the infinite possibilities that await you."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Attract Abundant Money subliminal session! By focusing on manifesting abundance and attracting financial prosperity, you've taken a significant step towards transforming your relationship with money. Now, it's time to reflect on your experience and continue your journey towards unlimited wealth."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Visualize your financial goals: Spend some time envisioning the future you desire with abundant money and financial freedom.",
        "Take inspired action: Identify and act on opportunities that align with your financial goals.",
        "Practice gratitude: Cultivate a mindset of gratitude for the money you have and the abundance that is coming your way.",
        "Educate yourself: Increase your financial literacy by reading books, attending seminars, or engaging with resources on wealth accumulation."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, attracting abundant money is a process that requires consistent effort and belief in your ability to achieve financial prosperity. Stay focused on your goals, take inspired action, and trust in the universe to support your journey towards unlimited wealth. You have the power to manifest abundance in your life!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Attract Abundant Money subliminal work?`, 
    answer: `The Attract Abundant Money subliminal works by delivering positive affirmations and suggestions directly to your subconscious mind. These affirmations are designed to reprogram your mindset and beliefs about money, abundance, and prosperity. By consistently listening to the subliminal, you can align your thoughts, emotions, and actions with the vibration of abundance, attracting more opportunities for financial prosperity into your life.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Attract Abundant Money subliminal?`, 
    answer: `Results can vary from person to person. Some individuals may experience positive shifts in their financial situation and abundance mindset relatively quickly, while others may require more time and consistent listening to see noticeable changes. It is important to listen to the subliminal regularly and consistently for best results.`,
    open: false 
  },
  { 
    question: `Can the Attract Abundant Money subliminal help me become a millionaire?`, 
    answer: `The Attract Abundant Money subliminal is designed to help you align your mindset and thoughts with abundance and financial prosperity. While it can positively influence your beliefs and actions towards wealth creation, individual results may vary. It is important to remember that achieving financial goals requires a combination of mindset, strategy, and action.`,
    open: false 
  },
  { 
    question: `How often should I listen to the Attract Abundant Money subliminal?`, 
    answer: `For best results, it is recommended to listen to the Attract Abundant Money subliminal regularly and consistently. You can incorporate it into your daily routine by listening once or multiple times a day, depending on your preference. Consistency is key to reprogramming your subconscious mind and aligning with the vibration of abundance.`,
    open: false 
  },
  { 
    question: `Can the Attract Abundant Money subliminal replace the need for action and financial planning?`, 
    answer: `No, the Attract Abundant Money subliminal is not intended to replace action and financial planning. While it can help you align your mindset with financial abundance, taking practical steps and implementing effective strategies is crucial for achieving your financial goals. The subliminal can serve as a powerful tool to enhance your motivation, confidence, and focus on wealth creation.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the Attract Abundant Money subliminal?`, 
    answer: `There are no known risks or side effects associated with listening to the Attract Abundant Money subliminal. However, it is essential to maintain a balanced mindset and avoid becoming overly fixated on money. It is also important to note that the subliminal is designed to supplement your personal growth and financial journey, not replace any necessary professional advice or guidance.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>