<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Are you ready to unleash your personal power and unlock your full potential? This subliminal is designed to help you do just that. By targeting your subconscious mind, it can assist in cultivating the mindset and beliefs necessary for achieving success and fulfillment in all areas of your life."
    },
    {
      type: 'heading',
      text: 'Here are some benefits you can expect:'
    },
    {
      type: 'list',
      items: [
        "Increased self-confidence and belief in your abilities.",
        "Enhanced motivation and determination to pursue your goals.",
        "Improved focus and concentration, allowing you to stay on track and overcome obstacles.",
        "Greater resilience and adaptability to handle challenges and setbacks.",
        "Heightened creativity and innovative thinking.",
        "Heightened intuition and a deeper connection with your inner self."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you'll begin to tap into your personal power, unlocking the hidden potential within you. You'll find yourself taking inspired action, making decisions aligned with your highest self, and experiencing a sense of empowerment that propels you towards your dreams. Remember, transformation takes time and dedication, so make sure to incorporate this subliminal into your daily routine to maximize its effectiveness."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Unlocking your personal power is a transformative process that can propel you towards success and fulfillment. This subliminal is designed to support you on your journey to harness your full potential. Before you begin, consider these strategies to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your goals: Take a moment to clearly define what success and fulfillment mean to you in different areas of your life.",
        "Believe in yourself: Cultivate a mindset of self-confidence and self-belief, understanding that you have the ability to achieve great things.",
        "Create a vision board: Visualize your goals by creating a visual representation of the life you want to create for yourself.",
        "Embrace self-care: Prioritize self-care practices to foster resilience, mental clarity, and emotional well-being.",
        "Stay present and open-minded: Be fully present during your listening sessions and approach the subliminal with an open mind and a willingness to embrace change."
      ]
    },
    {
      type: 'paragraph',
      text: "As you embark on this journey of personal growth and empowerment, remember that change takes time and effort. By consistently listening and aligning your subconscious mind with your goals, you can tap into your personal power and create a life filled with success and fulfillment. Trust the process, believe in yourself, and let your transformation begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Tap into your Personal Power subliminal! By dedicating time to unlock your full potential and tap into your personal power, you've taken a significant step towards achieving success and fulfillment in all areas of your life. It's time to reflect on your experience and continue your personal growth journey."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Set goals: Take some time to define your short-term and long-term goals and identify the steps needed to achieve them.",
        "Practice self-reflection: Reflect on your strengths, passions, and values to gain clarity on your personal power.",
        "Embrace positivity: Surround yourself with positive influences and practice gratitude to maintain an optimistic mindset.",
        "Take action: Use your newly tapped personal power to take proactive steps towards your goals and dreams."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, unlocking your personal power is an ongoing process that requires persistence and self-belief. Embrace the power within you and trust that you have what it takes to create success and fulfillment in your life. Keep moving forward and never underestimate the incredible potential you possess!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Tap into your Personal Power subliminal help me?`, 
    answer: `The Tap into your Personal Power subliminal is designed to help you harness your inner strength, confidence, and motivation to achieve success and fulfillment in all areas of your life. By listening to the subliminal messages, you can reprogram your subconscious mind to break through limiting beliefs, overcome obstacles, and unlock your full potential. The subliminal can empower you to take action, make positive changes, and achieve your goals.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Tap into your Personal Power subliminal?`, 
    answer: `Results may vary depending on the individual and their unique circumstances. Some people may notice positive changes right away, while others may require more consistent listening and time to fully reprogram their mindset. We recommend listening to the subliminal daily and consistently to maximize its effectiveness.`,
    open: false 
  },
  { 
    question: `How can I make the Tap into your Personal Power subliminal more effective?`, 
    answer: `To enhance the effectiveness of the subliminal, we recommend setting clear goals, visualizing your success, and taking consistent action towards your desired outcomes. Practice self-reflection and identify any limiting beliefs or self-sabotaging patterns that may be holding you back. Surround yourself with positive influences and a supportive community. Combining the subliminal with personal development strategies, such as affirmations, visualization, and self-care practices, can further amplify its impact.`,
    open: false 
  },
  { 
    question: `Can the Tap into your Personal Power subliminal guarantee success?`, 
    answer: `While the Tap into your Personal Power subliminal can help you tap into your full potential and cultivate a powerful mindset, it cannot guarantee success. Success is a result of various factors, including personal effort, perseverance, and external circumstances. However, the subliminal can provide you with the tools, motivation, and inner strength to maximize your chances of success and fulfillment in life.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Tap into your Personal Power subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Tap into your Personal Power subliminal. However, if you have any concerns about your mental or emotional well-being, please consult with a mental health professional. The subliminal is designed to empower and uplift, but it is essential to prioritize your overall well-being.`,
    open: false 
  },
  { 
    question: `Can I listen to the Tap into your Personal Power subliminal while doing other activities?`, 
    answer: `Yes, you can listen to the Tap into your Personal Power subliminal while doing other activities. However, for the best results, we recommend finding a quiet and comfortable space where you can relax and focus on the subliminal messages. This way, you can fully immerse yourself in the affirmations and allow them to deeply penetrate your subconscious mind.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>