<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Having self-belief is crucial for achieving success and reaching your full potential. This subliminal is designed to help you develop a mindset that allows you to believe in yourself and your abilities."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can be beneficial:'
    },
    {
      type: 'list',
      items: [
        "Boosting your confidence and self-esteem, allowing you to tackle challenges with a positive mindset.",
        "Overcoming self-doubt and negative self-talk that may be holding you back.",
        "Developing a resilient mindset that can bounce back from setbacks and failures.",
        "Cultivating a growth mindset that believes in continuous improvement and learning.",
        "Enhancing your belief in your own capabilities and potential."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can start to reprogram your subconscious mind and replace self-limiting beliefs with empowering ones. As your self-belief grows, you may find yourself taking more risks, stepping outside of your comfort zone, and achieving goals you once thought were impossible. Remember, building self-belief is a process, so be patient and persistent in your journey."
    }
  ]
},
            {
  id: 'before-listening',
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Developing self-belief is crucial for personal growth and success. This subliminal is designed to support you in cultivating a mindset that allows you to believe in yourself and your abilities. Before you start listening, consider these tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set positive intentions: Clarify your goals and aspirations, focusing on the areas where you want to boost your self-belief.",
        "Challenge negative self-talk: Become aware of any self-limiting beliefs or negative thoughts that may hinder your self-belief. Replace them with positive affirmations.",
        "Create a supportive environment: Surround yourself with people who uplift and inspire you. Remove or limit exposure to negativity or individuals who undermine your self-belief.",
        "Visualize success: Take a moment to visualize yourself achieving your goals and embodying the confidence and self-belief you desire."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, it will help reprogram your subconscious mind, instilling a deep belief in yourself and your abilities. Embrace this opportunity for personal transformation, knowing that self-belief is a powerful tool that can unlock your potential and lead you to greater success."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Self-Belief subliminal session! By dedicating time to developing your self-belief and mindset, you're on the path to unlocking your full potential. Now, it's important to reflect on your experience and integrate your newfound self-belief into your daily life."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Affirm your strengths: Write down your positive qualities and affirmations to reinforce your self-belief.",
        "Challenge limiting beliefs: Identify and challenge any negative thoughts or beliefs that hinder your self-belief.",
        "Surround yourself with positivity: Seek out supportive and encouraging individuals who believe in you.",
        "Celebrate small wins: Acknowledge and celebrate each achievement, no matter how small, to boost your self-belief."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, self-belief is a journey that requires ongoing commitment and practice. Embrace your strengths, challenge self-doubt, and surround yourself with positivity. With consistent effort and self-belief, you can overcome obstacles and achieve your goals. Believe in yourself, for you are capable of greatness!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Self-Belief subliminal help me develop a stronger sense of self-belief?`, 
    answer: `The Self-Belief subliminal is designed to tap into your subconscious mind and reprogram it with positive affirmations that promote self-belief. By regularly listening to the audio, you can start shifting your mindset and empowering yourself to believe in your abilities, worth, and potential. Consistency is key, and combining the subliminal with practical actions that align with your goals can enhance its effectiveness.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Self-Belief subliminal?`, 
    answer: `The time it takes to see results from the Self-Belief subliminal varies from person to person. Some people may notice positive changes in their self-belief right away, while others may need more time and consistent listening to reprogram their mindset. We recommend listening to the subliminal daily and consistently for at least a few weeks to allow the affirmations to take root in your subconscious.`,
    open: false 
  },
  { 
    question: `Can the Self-Belief subliminal replace therapy or professional help?`, 
    answer: `No, the Self-Belief subliminal is not intended to replace therapy or professional help. It is designed to complement and support your journey towards developing self-belief. If you are facing deep-seated issues or struggling with mental health concerns, it is important to seek appropriate professional guidance and support.`,
    open: false 
  },
  { 
    question: `How can I make the Self-Belief subliminal more effective?`, 
    answer: `To enhance the effectiveness of the Self-Belief subliminal, you can combine it with daily practices such as positive affirmations, visualization, and journaling. Surrounding yourself with supportive and positive influences, setting achievable goals, and celebrating your accomplishments along the way can also reinforce your self-belief. Remember that developing self-belief is a journey, and it takes time and consistent effort.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Self-Belief subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Self-Belief subliminal. However, if you have any concerns about your mental or emotional well-being, please consult with a mental health professional. The subliminal is intended to be a tool to support your self-belief journey, not a replacement for professional help.`,
    open: false 
  },
  { 
    question: `Can the Self-Belief subliminal help with imposter syndrome?`, 
    answer: `The Self-Belief subliminal is designed to help shift your mindset towards believing in yourself and your abilities. While it can serve as a tool to combat imposter syndrome, it is important to address underlying issues and seek professional guidance if imposter syndrome is significantly impacting your life and well-being. The subliminal can be a part of your overall self-help strategy.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>