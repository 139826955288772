<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're looking to enhance your muscle growth and achieve your desired physique, this powerful muscle growth aid can help you reach your goals. By targeting your subconscious mind, it supports your body's natural muscle-building processes and helps you stay motivated and consistent in your fitness journey."
    },
    {
      type: 'heading',
      text: 'Key features of this muscle growth aid:'
    },
    {
      type: 'list',
      items: [
        "Boosting your motivation and determination to stick to your workout routine.",
        "Enhancing your focus and concentration during workouts for better muscle activation.",
        "Supporting faster recovery times and reducing muscle soreness after intense workouts.",
        "Promoting the synthesis of muscle protein to maximize muscle mass gains.",
        "Increasing your confidence and belief in your ability to achieve your desired physique."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this muscle growth aid, you can harness the power of your subconscious mind to optimize your muscle-building potential. Combined with a balanced diet and proper training, this aid can help you make significant progress towards your fitness goals. Remember, consistency and dedication are key to seeing results."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Building muscle and achieving your desired physique takes dedication and focus. This muscle growth aid subliminal is designed to support your journey and help you reach your goals. Before you start listening, here are some tips to set yourself up for success:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Define your goal: Determine the specific muscle groups you want to focus on and the overall physique you want to achieve.",
        "Create a training plan: Develop a structured workout routine that aligns with your goals and includes progressive overload.",
        "Nutrition matters: Fuel your body with the right nutrients and a balanced diet to support muscle growth and recovery.",
        "Visualize success: Imagine yourself having achieved your desired physique, feeling confident and proud of your hard work and dedication."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this muscle growth aid subliminal regularly and aligning it with your training and nutrition efforts, you can enhance your muscle growth and make noticeable progress. Stay committed, stay focused, and let this subliminal support you on your journey to your dream physique."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Muscle Growth Aid session! By dedicating time and effort to enhance your muscle growth, you are taking significant steps towards achieving your desired physique. Now it's time to reflect on your experience and continue your journey towards your fitness goals."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Evaluate your progress: Take measurements, track strength gains, and review how your body feels.",
        "Modify your workout routine: Consider adjusting exercises, reps, and weights to challenge your muscles.",
        "Fuel your body: Focus on consuming a balanced diet with adequate protein, carbohydrates, and healthy fats.",
        "Allow rest and recovery: Give your muscles time to repair and grow by incorporating rest days into your training plan."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, building muscle takes time and consistent effort. Stay dedicated to your fitness routine, make necessary adjustments, and fuel your body properly to support muscle growth. With perseverance and a positive mindset, you are well on your way to achieving your desired physique. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Muscle Growth Aid work?`, 
    answer: `The Muscle Growth Aid is designed to support and enhance muscle growth by providing your body with the necessary nutrients and promoting an ideal environment for muscle development. The subliminal messages in the audio help to align your mindset with your fitness goals, boost motivation, and promote consistency in your workouts and nutrition.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Muscle Growth Aid?`, 
    answer: `Results from the Muscle Growth Aid can vary depending on factors such as your current fitness level, diet, and exercise routine. It may take several weeks or months of consistent use to see noticeable changes in muscle growth. It is important to combine the use of the aid with a balanced diet, proper rest, and a suitable exercise regimen for optimal results.`,
    open: false 
  },
  { 
    question: `Can the Muscle Growth Aid replace a proper workout and diet plan?`, 
    answer: `No, the Muscle Growth Aid is not intended to replace a proper workout and diet plan. It is designed to enhance muscle growth by aligning your mindset with your fitness goals and promoting consistency. To maximize your results, it is important to have a structured workout routine that includes resistance training, adequate protein intake, and a calorie surplus or deficit depending on your goals.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of using the Muscle Growth Aid?`, 
    answer: `The Muscle Growth Aid is generally safe to use. However, it is important to listen to your body and consult with a healthcare or fitness professional if you have any underlying health conditions or concerns. It is also essential to follow proper form and technique during your workouts to minimize the risk of injury.`,
    open: false 
  },
  { 
    question: `Can women use the Muscle Growth Aid?`, 
    answer: `Yes, women can use the Muscle Growth Aid to support and enhance their muscle growth. While women may have different goals or preferences in terms of muscle development, the aid can still be beneficial in promoting a positive mindset, motivation, and consistency in their fitness journeys.`,
    open: false 
  },
  { 
    question: `What other factors contribute to muscle growth apart from the Muscle Growth Aid?`, 
    answer: `In addition to using the Muscle Growth Aid, factors such as proper nutrition, rest and recovery, progressive overload in your workouts, and sufficient protein intake all play important roles in muscle growth. It is important to have a well-rounded approach that addresses these factors in conjunction with using the Muscle Growth Aid for optimal results.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>