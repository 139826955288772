<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Managing your emotions is an important skill for overall mental and emotional well-being. This subliminal is designed to help you learn to manage your emotions and express them in healthy ways."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Developing the ability to recognize and understand your emotions.",
        "Learning techniques to regulate and control your emotions effectively.",
        "Improving your communication skills to express your emotions in a healthy manner.",
        "Reducing the impact of negative emotions on your mental and emotional state.",
        "Building resilience and coping mechanisms for dealing with difficult emotions."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can rewire your brain to adopt healthier emotional management techniques. Over time, you may find yourself more capable of handling challenging emotions, communicating effectively, and experiencing greater emotional well-being. Remember, developing emotional management skills is a journey, and consistent practice is key to long-term growth."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Managing your emotions can be challenging, but it's an essential skill for maintaining your mental and emotional well-being. This subliminal is designed to help you cultivate healthier ways of expressing and managing your emotions. Before you begin, here are some strategies to consider:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Self-awareness: Take time to understand your emotions and the triggers that may lead to certain reactions.",
        "Breathing techniques: Practice deep breathing exercises to help calm your mind and body when emotions become overwhelming.",
        "Mindfulness: Engage in mindfulness practices such as meditation or grounding techniques to stay present in the moment and manage your emotions effectively.",
        "Journaling: Write down your emotions, thoughts, and experiences to gain clarity and explore healthier ways of expressing yourself.",
        "Positive affirmations: Use positive self-talk and affirmations to reinforce healthy emotional responses and change negative thought patterns."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating these strategies alongside the subliminal affirmations, you can better equip yourself to navigate and manage your emotions. Remember, this process takes time and practice, so be patient and compassionate with yourself. Embrace this opportunity for personal growth and let's begin the journey towards improved emotional well-being."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Emotion Management subliminal! By prioritizing your emotional well-being and learning to manage your emotions, you are taking a positive step towards better mental health. It's important to reflect on your progress and continue integrating healthy emotional habits into your daily life."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice self-awareness: Pay attention to your emotions and identify triggers and patterns.",
        "Use healthy coping strategies: Find healthy outlets such as journaling, exercising, or talking to a trusted friend.",
        "Learn relaxation techniques: Incorporate deep breathing, meditation, or mindfulness practices into your routine.",
        "Seek support: Consider seeking professional help, such as therapy, if you feel overwhelmed or need guidance."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember that managing emotions is an ongoing journey, and it's normal to have ups and downs. Embrace your emotions without judgment and give yourself permission to heal and grow. By actively managing your emotions, you are empowering yourself to cultivate a healthier and more fulfilling life. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Emotion Management subliminal help me?`, 
    answer: `The Emotion Management subliminal is designed to help you develop the skills and mindset to effectively manage your emotions. By listening to the subliminal regularly, you can reprogram your subconscious mind to respond to challenging emotions in healthier and more constructive ways. It can help you gain a greater awareness of your emotions, develop emotional resilience, and improve your overall mental and emotional well-being.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Emotion Management subliminal?`, 
    answer: `The amount of time it takes to see results from the Emotion Management subliminal can vary depending on the individual and their unique circumstances. It may take some time for your subconscious mind to fully internalize the affirmations and make lasting changes. We recommend listening to the subliminal consistently and giving it time to work, as results may be gradual. Continued practice and integration of the techniques discussed can further enhance the effectiveness of the subliminal.`,
    open: false 
  },
  { 
    question: `Can the Emotion Management subliminal replace therapy or professional help?`, 
    answer: `No, the Emotion Management subliminal is not a substitute for therapy or professional help. It is designed to provide support and complement therapeutic interventions by helping you develop healthier emotional coping mechanisms and mindset. If you are experiencing significant emotional distress or mental health issues, it is important to seek the guidance of a qualified mental health professional.`,
    open: false 
  },
  { 
    question: `How can I make the Emotion Management subliminal more effective?`, 
    answer: `To enhance the effectiveness of the Emotion Management subliminal, it can be helpful to actively engage with the content. This can include practicing the techniques discussed in the subliminal, journaling, seeking additional resources on emotional well-being, and seeking support from a therapist or support group. Consistently listening to the subliminal and incorporating the recommended strategies into your daily life can also help reinforce positive changes in your emotional management skills.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Emotion Management subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Emotion Management subliminal. However, if you have any concerns about your emotional well-being, please consult with a mental health professional. It is important to note that the subliminal is intended to supplement healthy emotional management practices, not replace them.`,
    open: false 
  },
  { 
    question: `Can the Emotion Management subliminal help with specific emotional challenges, such as anger or anxiety?`, 
    answer: `The Emotion Management subliminal is designed to help individuals develop healthier emotional coping mechanisms and mindset. While it can be beneficial for managing various emotions, it is not a specific treatment for any particular emotional challenge. If you are experiencing difficulty managing specific emotions, it may be helpful to seek additional support from a therapist or counselor who specializes in that area.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>