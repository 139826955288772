<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Living a fearless life doesn't mean never feeling fear. It means finding the inner strength and courage to face your fears head-on. This subliminal is designed to help you tap into your inner power and embrace fearlessness."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support you:'
    },
    {
      type: 'list',
      items: [
        "Building self-confidence and belief in your abilities.",
        "Reducing anxiety and self-doubt when faced with challenges or new experiences.",
        "Encouraging a positive mindset and empowering thoughts.",
        "Increasing your resilience and ability to bounce back from setbacks.",
        "Helping you step out of your comfort zone and pursue your dreams courageously."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to embrace fearlessness. As you gain confidence and develop a can-do attitude, you'll find yourself taking risks and facing your fears with greater ease. Remember, living fearlessly is a journey, and this subliminal can be your supportive companion along the way."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Overcoming fear and finding the courage to live fearlessly is not always easy, but this subliminal can help you harness your inner strength. Here are some preparation tips to keep in mind before you start listening:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your fears: Take a moment to identify and acknowledge the fears that hold you back from living fearlessly.",
        "Visualize your fearless self: Envision yourself facing your fears with courage and confidence. Imagine the freedom and happiness that comes with living without fear.",
        "Set your intention: Clearly state your intention to overcome your fears and embrace a fearless life.",
        "Open your mind and heart: Be receptive to the subliminal affirmations, allowing them to penetrate your subconscious and shift your mindset."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to empower you and help you live a fearless life. As you listen regularly, you may start to feel a sense of inner strength, confidence, and resilience. Embrace this opportunity to conquer your fears and step into a life filled with courage and fearlessness. Now, take a deep breath, set your intention, and let's begin your journey."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Courageously Fearless subliminal! Taking the steps to confront and overcome your fears is a powerful and transformative journey. Now that you've finished the session, reflect on your experience and continue to nurture your courageous spirit."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your fears: Identify and acknowledge the fears that hold you back from living a fearless life.",
        "Challenge your comfort zone: Push yourself to step outside your comfort zone and face your fears head-on.",
        "Practice self-compassion: Be kind and gentle with yourself as you confront and overcome your fears.",
        "Embrace growth mindset: Believe in your ability to grow and learn from every experience, including the challenges."
      ]
    },
    {
      type: 'paragraph',
      text: "Living fearlessly doesn't mean the absence of fear, but rather, the courage to face it and keep moving forward. Trust in your inner strength and know that you have what it takes to embrace a fearless life. Emerge stronger, bolder, and more empowered with each step you take. You've got this!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Courageously Fearless subliminal help me overcome my fears?`, 
    answer: `The Courageously Fearless subliminal is designed to positively influence your subconscious mind and help you develop the inner strength and courage to face your fears. By consistently listening to the subliminal messages, you can reprogram your mindset and replace fear with confidence. This can empower you to take action, step out of your comfort zone, and live a fearless life.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Courageously Fearless subliminal?`, 
    answer: `The time it takes to see results from the Courageously Fearless subliminal can vary from person to person. Some individuals may notice positive changes in their mindset and behavior almost immediately, while others may require more time for the affirmations to take effect. Consistent listening is key to reprogramming your subconscious and fostering a fearless mindset.`,
    open: false 
  },
  { 
    question: `Can the Courageously Fearless subliminal eliminate all fear from my life?`, 
    answer: `While the Courageously Fearless subliminal can help you develop the inner strength and courage to face your fears, it is important to recognize that fear is a natural and necessary human emotion. The subliminal is designed to empower you to take action despite your fears, allowing you to overcome obstacles and live a more fearless life. It can assist you in reframing fear in a positive way and identifying opportunities for personal growth.`,
    open: false 
  },
  { 
    question: `Can I use the Courageously Fearless subliminal for specific fears?`, 
    answer: `Yes, the Courageously Fearless subliminal can be effective in addressing specific fears. The subliminal messages are designed to help you develop inner strength and courage, allowing you to confront and overcome various fears. Whether you're afraid of public speaking, heights, or other specific fears, consistent listening to the subliminal can help you face these challenges with confidence.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Courageously Fearless subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Courageously Fearless subliminal. However, if you have any concerns about your mental health or specific fears, it is recommended to seek professional advice.`,
    open: false 
  },
  { 
    question: `Can the Courageously Fearless subliminal be used alongside therapy or other fear-related treatments?`, 
    answer: `Yes, the Courageously Fearless subliminal can be used alongside therapy or other fear-related treatments. It can complement and enhance the effectiveness of other therapeutic approaches by empowering you to develop a fearless mindset and take active steps towards overcoming your fears. However, it is important to consult with your healthcare provider or therapist for personalized advice regarding your specific situation.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>