<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Being a tall man comes with its own set of challenges, but this subliminal can help you embrace your height and build confidence and self-assurance. By targeting your subconscious mind, it helps you develop a strong and confident presence that is unique to your height."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Enhancing your self-esteem and acceptance of your height, allowing you to feel more comfortable in your own skin.",
        "Helping you exude confidence in social situations, whether it's dating, networking, or public speaking.",
        "Developing a strong and confident posture that showcases your height in a positive way.",
        "Boosting your overall self-image and self-worth, allowing you to appreciate the advantages of being tall.",
        "Providing a mindset shift that supports you in embracing your unique characteristics and standing tall with pride."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind and build the confidence and self-assurance you deserve. Embrace your height and let your presence command attention and respect. Remember, confidence radiates from within, and this subliminal is here to help you unlock that inner strength."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embracing your unique height and exuding confidence can have a transformative impact on your life. This subliminal is designed to help you build the confidence and self-assurance specifically tailored for tall men. Before you start listening, here are some tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Recognize your strengths: Embrace the advantages that come with your height and acknowledge the unique presence you possess.",
        "Practice positive self-talk: Replace self-doubt with affirmations that reinforce your confidence and self-worth.",
        "Visualize success: Imagine yourself walking tall and confidently, owning your height and radiating a strong and confident presence.",
        "Maintain a positive mindset: Keep a positive attitude and believe in yourself as you listen to the subliminal affirmations."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can rewire your subconscious mind, boost your self-esteem, and feel more empowered in your height. Embrace your uniqueness, focus on your goals, and let the journey to building confidence as a tall man begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Confidence for Tall Man subliminal session! By actively working on building your confidence and self-assurance, tailored specifically for tall men, you are well on your way to embracing your unique height and exuding a strong and confident presence. Now, it's time to reflect on your experience and continue your personal growth."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Visualize success: Imagine yourself confidently navigating different situations and embrace your unique height.",
        "Embrace your physique: Accept and appreciate your height as a part of your identity.",
        "Challenge negative beliefs: Identify and challenge any negative thoughts or beliefs related to your height.",
        "Practice self-care: Focus on self-care activities that boost your confidence and well-being."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, building confidence is a continuous journey. Embrace your unique height and use it as a strength to make a positive impact in various aspects of your life. Keep focusing on your personal growth, and step into the world with a renewed sense of confidence and self-assurance. You've got this!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Confidence for Tall Man subliminal help me?`, 
    answer: `The Confidence for Tall Man subliminal is specifically designed to help tall men build confidence and self-assurance. It aims to empower you to embrace your unique height and exude a strong and confident presence. By listening to the subliminal messages, you can reprogram your subconscious mind to release any negative beliefs or insecurities related to your height and replace them with positive and empowering thoughts.`,
    open: false
  },
  { 
    question: `How long do I need to listen to the Confidence for Tall Man subliminal to see results?`, 
    answer: `Results can vary from person to person, but regular and consistent listening to the subliminal can help expedite the process. We recommend listening daily for at least a few weeks to allow the positive affirmations to work their way into your subconscious mind and influence your thoughts and behaviors.`,
    open: false 
  },
  { 
    question: `Can the Confidence for Tall Man subliminal help with body image concerns related to height?`, 
    answer: `The Confidence for Tall Man subliminal is specifically tailored for tall men to help address any body image concerns related to height. By nurturing a positive and confident mindset, the subliminal aims to support you in embracing your unique height and developing a strong sense of self-assurance.`,
    open: false 
  },
  { 
    question: `Is it normal to still have moments of self-doubt or insecurity after listening to the Confidence for Tall Man subliminal?`, 
    answer: `Yes, it is normal to have moments of self-doubt or insecurity even after listening to the Confidence for Tall Man subliminal. Building confidence is a gradual process, and everyone experiences occasional fluctuations in their self-assurance. Consistently listening to the subliminal and practicing self-care and self-acceptance can help reinforce positive thoughts and reduce moments of self-doubt.`,
    open: false 
  },
  { 
    question: `What other strategies can complement the Confidence for Tall Man subliminal in building confidence?`, 
    answer: `In addition to listening to the Confidence for Tall Man subliminal, there are other strategies you can incorporate to build confidence. These include practicing self-care, engaging in activities that make you feel good about yourself, setting achievable goals, surrounding yourself with supportive and uplifting people, and challenging any negative self-talk or limiting beliefs. Combining these strategies with the subliminal can help you cultivate a strong and confident presence.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the Confidence for Tall Man subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Confidence for Tall Man subliminal. However, if you have any concerns, it is advisable to consult with a mental health professional. The subliminal is intended to empower and support tall men in building confidence and self-assurance.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>