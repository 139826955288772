<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Learning a new language can be challenging, but with this Indian Language Learning Aid, it becomes easier and more effective. Whether you're interested in Hindi, Tamil, Telugu, or any other Indian language, this subliminal can help you expand your language skills and enhance your cultural understanding."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help you:"
    },
    {
      type: 'list',
      items: [
        "It helps you absorb vocabulary, grammar, and pronunciation more effortlessly.",
        "It enhances your language learning capabilities, making the process smoother and quicker.",
        "It boosts your confidence in using the language in real-life situations.",
        "It increases your cultural sensitivity and understanding of Indian traditions and customs.",
        "It provides a supportive and motivating environment for your language learning journey."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this Indian Language Learning Aid, you can reprogram your subconscious mind to grasp and retain language skills more naturally. As you progress, you'll feel more confident and comfortable expressing yourself in the language of your choice. Remember, practice and consistency are crucial for mastering any language."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Learning a new language can be an exciting and enriching experience, and this language learning aid is designed to make your journey easier and more effective. Before you start, here are a few tips to enhance your Indian language learning experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear language learning goals: Determine what specific language skills or areas you want to focus on, such as vocabulary, pronunciation, or grammar.",
        "Immerse yourself in the language: Surround yourself with Indian language materials, like music, movies, or books, to expose yourself to the language and culture.",
        "Practice actively: Engage in regular conversations, either with a language partner or through language learning apps, to actively practice your speaking and listening skills.",
        "Stay consistent: Dedicate regular time to learning and practicing the language, even if it's just a few minutes every day. Consistency is key to progress."
      ]
    },
    {
      type: 'paragraph',
      text: "With this language learning aid, you'll be able to expand your language skills and deepen your cultural understanding. Embrace the learning process, stay motivated, and remember that every small step you take will bring you closer to fluency. So, set your goals, stay consistent, and let's begin your Indian language learning journey!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Indian Language Learning Aid session! By taking the time to improve your language skills and cultural understanding, you have embarked on an enriching journey. Now, it's important to reflect on your experience and think about how you can continue to expand your language abilities."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice conversations: Look for opportunities to engage in conversations with native speakers or language exchange partners.",
        "Immerse yourself in the culture: Watch movies, listen to music, and read books or articles in the language you are learning to deepen your understanding of the culture.",
        "Set achievable goals: Break down your language learning journey into smaller goals to keep yourself motivated and track your progress.",
        "Join language communities: Connect with fellow learners in online forums, language exchange groups, or social media networks to exchange tips and practice regularly."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, language learning is a continuous process that requires dedication and practice. Embrace the challenges and celebrate your successes along the way. With consistent effort and a curious mindset, you will continue to enhance your language skills and cultural understanding. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Indian Language Learning Aid help me in learning Indian languages?`, 
    answer: `The Indian Language Learning Aid is designed to help you learn Indian languages easily and effectively. It provides audio lessons, vocabulary building exercises, and cultural insights to expand your language skills and enhance your cultural understanding. The aid uses proven language learning techniques and is suitable for beginners as well as those who want to improve their existing language skills.`,
    open: false
  },
  { 
    question: `Which Indian languages can I learn with this language learning aid?`, 
    answer: `The Indian Language Learning Aid offers courses for various Indian languages, including but not limited to Hindi, Bengali, Telugu, Tamil, Kannada, Malayalam, Gujarati, Marathi, Punjabi, and Urdu. You can choose the language you want to learn and follow the structured lessons and exercises provided.`,
    open: false 
  },
  { 
    question: `Do I need any prior knowledge of Indian languages to use this language learning aid?`, 
    answer: `No, you do not need any prior knowledge of Indian languages to use this language learning aid. It is designed to cater to beginners and provides step-by-step guidance to help you learn from scratch. Even if you have some familiarity with Indian languages, the aid can still help you further develop your skills.`,
    open: false 
  },
  { 
    question: `Can I use this language learning aid independently, or do I need a teacher?`, 
    answer: `You can use the Indian Language Learning Aid independently as it provides all the necessary materials and resources for self-learning. However, if you prefer having a teacher or want personalized instruction, you can complement your learning with a language tutor or join a language class alongside using the aid.`,
    open: false 
  },
  { 
    question: `Is this language learning aid suitable for children?`, 
    answer: `Yes, the Indian Language Learning Aid can be suitable for children. However, depending on the age and language proficiency of the child, parental guidance or additional support may be required to ensure effective learning and understanding.`,
    open: false 
  },
  { 
    question: `Is there a mobile app available for the Indian Language Learning Aid?`, 
    answer: `Yes, there is a mobile app available for the Indian Language Learning Aid. You can download the app from the respective app store for your device and access the language lessons and exercises on the go.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>