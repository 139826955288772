<template>
  <div class="d-flex align-items-center">
    <v-slider
      v-model="volume"
      hide-details
      max="100"
      min="0"
      class="volume-slider"
    ></v-slider>
  </div>
</template>

<script>
import { getAudioTypeById } from "@/api/audiotype";
export default {
  props: {
    type: {
      type: String,
    },
    selects: {
      type: Array,
    },
    items: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  data: () => {
    return {
      getAudioTypeById,

      volume: 0,
    };
  },
  created() {
    this.getAudioTypesById();
  },
  mounted() {
    this.getVolumes();
  },
  watch: {
    volume() {
      this.getVolume();
    },
  },
  methods: {
    getVolume() {
      const data = {
        type: this.type,
        data: this.volume,
        index: this.index,
      };

      this.$emit("getSelectItem", data);
    },
    getVolumes() {
      let data = this.selects.filter((select) => {
        return select.title == this.items.title;
      });

      if (data.length > 0) {
        this.volume = data[0].volume;
      }
    },
    getAudioTypesById() {
      const audio_type_id = this.items.audio_type_id;
      this.getAudioTypeById(audio_type_id).then((res) => {
        this.volume = res[0].volume;
      });
    },
  },
};
</script>

<style scoped>
.volume-slider {
  margin-bottom: 0px;
  width: 200px;
}
</style>
