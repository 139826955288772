<template>
  <section>
    <div class="containers">
      <div>
        <h1>
          The Science of How <br>
          Magus Subliminal <br>
          Audio Works
        </h1>
        <div class="ipo-card">
          <h3>{{ title }}</h3>
          <p v-html="description"></p>
        </div>
        <div class="text-center">
          <template v-for="bullet in 3">
            <v-icon @click="index = bullet - 1" 
            :class="`bullet ${bullet-1 == index ? 'active' : ''} mx-2 my-4`" size="20">mdi-checkbox-blank-circle</v-icon>
          </template>
        </div>
      </div>
      <div>
        <img class="big-img" :src="cover" alt="">
      </div>
    </div>
  </section>
</template>

<script>
import { getMagus } from '@/api/magusdetails'

export default {
  data: () => {
    return {
      getMagus,

      index: 0,
      sub_title: '',
      cover: '',
      description: '',
      items: [],
      
    }
  },
  created() {
    this.getData()
  },
  watch: {
    index() {
      this.index=this.index
      this.description=this.items[this.index].description
      this.title=this.items[this.index].title
      this.cover=this.items[this.index].cover
    },
  },
  methods: {
    getData() {
      const data = {
        title: 'Science IPO',
      }
      this.getMagus(data).then((res) => {
        this.items=(res.data[0].info)
        this.description=this.items[this.index].description
        this.title=this.items[this.index].title
        this.cover=this.items[this.index].cover
      })
    },
  }
}
</script>

<style scoped>
  h1 {
    color: var(--ma-black-olive);
    zoom: 100%;
    line-height: 1.35em;
  }
  h5 {
    color: var(--ma-tufts-blue);
  }
  .bullet {
    color: var(--ma-light-silver);
    cursor: pointer;
  }
  .bullet.active {
    color: var(--ma-black-olive);
  }
  .big-img {
    width: clamp(17.50rem, calc(15.00rem + 25.75vw), 50.00rem);
    margin-left: auto;
    margin-right: auto;
    height: auto;
  }

  @media screen and (min-width: 80rem) {

    section > .containers {
      @media screen and (min-width: 1400px) {
        height: 90vh;
        display: flex;
          &>div:nth-of-type(2){
            width: 50%;
            .big-img {
              margin-right: 0;
            }
          }

          &>div:nth-of-type(1){
            width: 50%;
            margin-right: auto;
            margin-left: 5em;
            margin-top: 5em;
            text-align: left;
            .ipo-card {
              height: 15em;
            }
          }
      }

      @media screen and (max-width: 1399px) {
        text-align: center;
        height: auto;
      }

    }
  }
</style>