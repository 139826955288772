<template>
  <div>
    <PrivacyPolicy />
  </div>
</template>

<script>
import PrivacyPolicy from '@/views/Main/PrivacyPolicy'
export default {
  components: {
    PrivacyPolicy
  }
}
</script>