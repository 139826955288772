<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Are you interested in learning Muay Thai, one of the most effective martial arts in the world? This subliminal can help you on your journey to mastering this incredible art form."
    },
    {
      type: 'heading',
      text: 'Here is how it works:'
    },
    {
      type: 'list',
      items: [
        "You will receive comprehensive training materials that cover the fundamental techniques, strategies, and principles of Muay Thai.",
        "With regular practice and dedication, you can improve your strength, agility, speed, and overall fitness level.",
        "This subliminal will help you develop the mental focus, discipline, and mindset necessary for mastering Muay Thai.",
        "The subliminal messages will reinforce positive beliefs, confidence, and motivation to keep pushing yourself in your training journey.",
        "You will be guided through various training routines and exercises to enhance your technique, footwork, striking, clinching, and defensive skills.",
        "With time and consistent effort, you can become skilled in Muay Thai and experience the physical, mental, and spiritual benefits it offers."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, mastering Muay Thai requires commitment, discipline, and regular practice. Use this subliminal as a tool to enhance your training, but also engage in practical training with a qualified instructor to fully develop your skills. Your journey to Muay Thai mastery starts here!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on the journey of learning Muay Thai and mastering this martial art form requires dedication, focus, and a strong mindset. This subliminal is designed to enhance your training experience and help you achieve your goals. Before you start your training session, consider the following tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Define what you want to achieve through your Muay Thai training and keep those goals in mind throughout your practice.",
        "Stay disciplined: Commit to regular training sessions and establish a consistent routine to improve your skills and progress.",
        "Warm up properly: Prioritize a thorough warm-up to prevent injuries and optimize your performance.",
        "Focus on your technique: Pay attention to the details of each movement and strive for precision and fluidity.",
        "Mentally prepare: Visualize success and build confidence in your abilities before you begin your training."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to support your journey towards Muay Thai mastery. As you listen regularly, you may notice increased focus, motivation, and a deeper connection with the art. Embrace the mental and physical challenges, stay dedicated, and enjoy the process of becoming a skilled Muay Thai practitioner. Let your training begin!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Muay Thai Training and Mastery session! By choosing to learn and master the art of Muay Thai, you've embarked on an exciting and transformative journey. Now that you've finished, it's time to reflect on your experience and continue honing your skills."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice regularly: Consistent training is key to improving your technique and gaining mastery.",
        "Set realistic goals: Establish short-term and long-term goals to track your progress and stay motivated.",
        "Seek guidance: If possible, work with a qualified instructor who can provide personalized feedback and guidance.",
        "Review and analyze: Record your training sessions to review and analyze your technique for areas of improvement."
      ]
    },
    {
      type: 'paragraph',
      text: "Becoming a skilled Muay Thai practitioner requires dedication, discipline, and perseverance. Embrace the challenges, stay focused on your goals, and continue pushing your limits. With consistent practice and the right mindset, you'll continue to grow and excel in this martial art form. Stay motivated and enjoy your journey as you master the art of Muay Thai!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Do I need any prior experience to start Muay Thai training?`, 
    answer: `No prior experience is necessary to start Muay Thai training. Whether you are a beginner or have some previous martial arts experience, Muay Thai training can be tailored to your skill level. Trainers and instructors will guide you through the basics and gradually progress your training as you become more proficient.`,
    open: false
  },
  { 
    question: `What are the benefits of learning Muay Thai?`, 
    answer: `Muay Thai offers numerous benefits, including improved cardiovascular health, increased strength and endurance, enhanced flexibility, and improved overall fitness. It also helps build self-confidence, discipline, focus, and self-defense skills. Additionally, Muay Thai training can be a great way to relieve stress and improve mental well-being.`,
    open: false 
  },
  { 
    question: `What equipment do I need for Muay Thai training?`, 
    answer: `Basic equipment for Muay Thai training includes hand wraps, gloves, shin guards, a mouthguard, and proper training attire. Some gyms may provide equipment, but it is recommended to have your own for hygiene reasons. As you progress in your training, you may also consider acquiring additional gear such as a heavy bag, focus mitts, and Thai pads.`,
    open: false 
  },
  { 
    question: `How often should I train in Muay Thai to see progress?`, 
    answer: `The frequency of training depends on your goals and availability. To see progress and build proficiency, it is recommended to train at least 2-3 times a week. Consistency is key, and regular training sessions will help develop your skills, conditioning, and overall understanding of Muay Thai techniques.`,
    open: false 
  },
  { 
    question: `Is Muay Thai training suitable for everyone?`, 
    answer: `Muay Thai training can be adapted to suit individuals of various fitness levels and ages. However, it is always advisable to consult with a healthcare professional before starting any new fitness program, especially if you have any pre-existing medical conditions.`,
    open: false 
  },
  { 
    question: `Can I compete in Muay Thai if I train regularly?`, 
    answer: `With regular training and dedication, it is possible to compete in Muay Thai. However, competing is a personal choice and not a requirement. Many people enjoy training Muay Thai solely for fitness, self-defense, or personal development without participating in competitive bouts.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>