import { render, staticRenderFns } from "./128-stop-suicidal-thoughts-and-suicide-prevention.vue?vue&type=template&id=d1b2aee2&scoped=true&"
import script from "./128-stop-suicidal-thoughts-and-suicide-prevention.vue?vue&type=script&lang=js&"
export * from "./128-stop-suicidal-thoughts-and-suicide-prevention.vue?vue&type=script&lang=js&"
import style0 from "./128-stop-suicidal-thoughts-and-suicide-prevention.vue?vue&type=style&index=0&id=d1b2aee2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d1b2aee2",
  null
  
)

export default component.exports