<template>
  <div>
    <UserAppbar />
    <div style="overflow: auto">
      <div class="card-container p-2">
        <UserPlaylist />
      </div>
    </div>
  </div>
</template>

<script>
import UserAppbar from "@/components/User/UserAppbar.vue";
import UserPlaylist from "@/components/User/UserPlaylist.vue";
export default {
  components: {
    UserAppbar,
    UserPlaylist,
  },
  computed: {
    width() {
      return this.$store.state.screen.width;
    },
  },
  watch: {
    width(val) {
      if (val > 990) {
        this.$router.push("/user");
      }
    },
  },
};
</script>

<style scoped>
.card-container {
  width: calc(100vw - 375px);
  height: calc(100vh - 100px);
}

@media screen and (max-width: 990px) {
  .card-container {
    width: calc(100vw - 48px);
  }

  .playlist {
    height: calc(100vh - 160px);
  }

  .playlist.with-player {
    height: calc(100vh - 245px);
  }
}

@media screen and (max-width: 500px) {
  .card-container {
    width: 100vw;
  }
}
</style>
