<template>
  <div>
    <div>
      <div
        :class="`card-container ${isPlayerAvailable ? 'with-player' : ''} p-2`"
      >
        <MoodCalendar />
      </div>
    </div>
  </div>
</template>

<script>
import UserAppbar from "@/components/User/UserAppbar.vue";
import MoodCalendar from "@/views/User/Profile/MoodCalendar.vue";
import { audio } from "@/mixins/audio";
export default {
  mixins: [audio],
  components: {
    UserAppbar,
    MoodCalendar,
  },
  computed: {
    recentplayed() {
      return this.$store.state.recent.played.data;
    },
    isPlayerAvailable() {
      return this.playlistTracks.length > 0
        ? this.playlistTracks.length > 0
        : this.recentplayed.length > 0;
    },
  },
};
</script>

<style scoped>
.card-container {
  overflow-x: hidden;
  width: calc(100vw - 375px);
  height: calc(100vh - 10px);
}

.card-container.with-player {
  overflow-x: hidden;
  height: calc(100vh - 100px);
}

@media screen and (max-width: 900px) {
  .card-container {
    width: calc(100vw - 48px);
    height: calc(100vh - 160px);
  }

  .card-container.with-player {
    overflow-x: hidden;
    height: calc(100vh - 235px);
  }
}

@media screen and (max-width: 500px) {
  .card-container {
    width: 100vw;
  }

  .card-container.with-player {
    overflow-x: hidden;
    height: calc(100vh - 250px);
  }
}
</style>
