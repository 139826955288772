<template>
  <section>
    <div class="container1">
      <div class="ml-6 mr-6">
        <h3 class="maintitle">Frequently Asked Question</h3>
        <input @click="mouseover" class="search-class mt-2 mb-5" v-model="question" placeholder="Let us know what your concern is ..." />
      </div>
      <div v-show="hover==true" class="ml-6 mr-6 mb-6">
        <div v-for="(arr, index) in array" :key="index">
          <h5 @click="select(arr)" class="questions">{{arr.description}}</h5>
        </div>
        <h5 v-show="array.length==0 && items.length==0" @click="dialog = true" class="questions">Please contact support for other concern.</h5>
      </div>
      <div>
        <h5 class="ma-6">{{title}}</h5>
      </div>
      <div class="faqs ml-6 mr-6" v-if="question!=''">
          <v-expansion-panels focusable v-model="expansion">
            <v-expansion-panel v-for="(faq, i) in items" :key="i">
              <v-expansion-panel-header @click="click(i)"
                :style="`background-color: ${ selection == i ? '#1C8EDF' : '' }; color: ${ selection == i ? 'white' : ''};`"
                class="question">{{ faq.title }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <p class="mt-3 mb-0 text-left answer" v-html="faq.description">
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
      </div>

      <div class="faqs ml-6 mr-6" v-else>
          <v-expansion-panels focusable v-model="expansion">
            <v-expansion-panel v-for="(faq, i) in localitems" :key="i">
              <v-expansion-panel-header @click="click(i)" 
                :style="`background-color: ${ selection == i ? '#1C8EDF' : '' }; color: ${ selection == i ? 'white' : ''};`"
                class="question">{{ faq.title }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <p class="mt-3 mb-0 text-left answer" v-html="faq.description">
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
      </div>

      <Dialog :dialog="dialog" :width="800" title="" @closeDialog="closeDialog">
        <ContactUs @closeDialog="closeDialog" />
      </Dialog>
    </div>
  </section>
</template>

<script>
import { getMagus, getMagusSub } from '@/api/magusdetails'
import Dialog from '@/components/Dialog.vue'
import ContactUs from '@/components/Home/ContactUs.vue'

export default {
  components: {
    Dialog,
    ContactUs
  },
  data: () => {
    return {
      getMagus,
      getMagusSub,
      dialog: false,
      
      selected: false,
      hover: false,
      localtitle: '',
      title: '',
      description: '',
      expansion: '',
      question: '',
      localarray: [],
      array: [],
      localitems: [],
      items: [],
      i: 0,
      selection: null,
    }
  },
  watch: {
    question(value){
      if(value!=''){
        this.hover=true;
        this.title=''
        this.getSearch();
      }else{
        this.hover=false;
        this.getData();
      }
    }
  },
  created() {
    this.getData()
  },
  methods: {
    click(i) {
      if(this.selection != i){
        this.selection = i;
      }else{
        this.selection = null;
      }
    },
    closeDialog(data) {
      this.dialog = data
    },
    select(data){
      this.localitems=(data.info)
      this.title=(data.description)
      this.hover = false;
      this.selection = null;
      this.expansion = null;
    },
    mouseover: function(){
      this.hover = true
    },    
    mouseleave: function(){
      this.hover = false
    },
    goToRoute(route) {
      if (this.currentRoute != route) {
        this.$router.push(route).catch(err => { })
        window.scrollTo(0,0);
      }
    },
    getSearch(){
      const data = {
        title: 'Frequently Asked Questions',
        search: this.question,
      }
      this.getMagusSub(data).then((res) => {
        this.array=(res.data.info)
        this.items=(res.data.sub_info)
      })
    },
    getData() {
      const data = {
        title: 'Frequently Asked Questions',
      }
      this.getMagus(data).then((res) => {
        this.array=(res.data)
        this.title=(res.data[0].description)
        this.items=(res.data[0].info)
        this.localarray=(res.data)
        this.localtitle=(res.data[0].description)
        this.localitems=(res.data[0].info)
      })
    },
  }
}
</script>

<style scoped>
  section:nth-of-type(1) > .container1 {
    padding-block: 2em;
  }
  h1, h5, h6:nth-of-type(2) {
    color: var(--ma-tufts-blue);
  }
  h5 {
    text-align: left;
  }
  h3 {
    color: var(--ma-tufts-blue);
    line-height: 1.35em;
  }
  p{
    font-family: "Montserrat-Regular";
    padding: 20px;
  }
  .maintitle {
    font-size: 40px !important;
  }
  .questions{
    color: var(--ma-black-olive);
    line-height: 1.35em;
    font-family: 'Montserrat-Regular' !important;
    border-bottom: 0.5px solid  var(--ma-black-olive);
    padding-bottom: 0.2em;
    margin-bottom: 1em;
    cursor: pointer;
    text-align: left;
  }
  .wrapper {
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding: 2em;
    height: 20em;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  img {
    width: 7em;
    margin-bottom: 0.4em;
  }
  .search-class {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid white;
    border-radius: 5px;
    background-color: white;
    background-image: url("~@/assets/images/logo/search.png");
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    padding: 5px 40px;
    background-repeat: no-repeat;
    background-size: 1.2em;
    background-position: 10px;
  }


  @media screen and (min-width: 40rem) {

    section:nth-of-type(1) > .container1 {
      @media screen and (min-width: 1400px) {
        padding: 8em;
        padding-top: 3em;
      }

      @media screen and (min-width: 1200px) and (max-width: 1400px) {
        padding: 5em;
      }
    }
  }
</style>