<template>
  <v-app-bar app dense height="56">
    <template v-if="userType != 'admin'">
      <!-- app icon when in mobile  -->
      <v-app-bar-nav-icon
        v-if="isMobile"
        @click="openDrawer"
      ></v-app-bar-nav-icon>
      <div v-if="!isMobile && !isMain"></div>
      <!-- main menu -->
      <div class="d-flex align-center" v-if="!isMobile && isMain">
        <img @click="logo()" class="images" src="@/assets/images/logo/1.png" />
        <div
          v-for="(menu, index) in menus"
          :class="`menu mx-3 ${currentRoute == menu.to ? 'active' : ''}`"
          @click="goToRoute(menu.to)"
          :key="index"
        >
          {{ menu.label }}
        </div>
      </div>
      
      <!-- login and register button -->
      <div v-if="!isUserLogin && isMain && !isMobile">
        <v-btn
          class="mx-1"
          width="100"
          style="background: #1c8edf; color: white; font-weight: 700"
          @click="goToRoute('/login')"
          >Login</v-btn
        >
        <v-btn
          class="mx-1"
          width="100"
          style="background: #4372bd; color: white; font-weight: 700"
          @click="goToRoute('/register')"
          >Register</v-btn
        >
      </div>
      <!-- login avatar -->
      <el-dropdown
        v-if="(isUserLogin && !isAdmin)"
        trigger="click"
        :hide-on-click="false"
      >
        <v-avatar class="ml-2" size="40" color="grey lighten-2">
          <img :src="avatar" alt="Profile" />
        </v-avatar>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item class="d-flex py-2" style="width: 180px">
            <div @click="goToWebPlayer()">
              <v-icon class="mr-4">mdi-music</v-icon>
              <span>Web Player</span>
            </div>
          </el-dropdown-item>
          <el-dropdown-item class="d-flex py-2" style="width: 180px">
            <div @click="logout">
              <v-icon class="mr-4">mdi-logout</v-icon>
              <span>Logout</span>
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <el-dropdown
        v-if="(isAdminLogin && isAdmin)"
        trigger="click"
        :hide-on-click="false"
      >
        <v-avatar class="ml-2" size="40" color="grey lighten-2">
          <img :src="my_avatar" alt="Profile" />
        </v-avatar>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item class="d-flex py-2" style="width: 180px">
            <div @click="logout">
              <v-icon class="mr-4">mdi-logout</v-icon>
              <span>Logout</span>
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </template>
  </v-app-bar>
</template>

<script>
import { sidebar2 } from "@/utils/data";
import { loginQueryParams } from "@/utils";

import { addSearch } from "@/api/search";

export default {
  data: () => {
    return {
      sidebar2,
      loginQueryParams,
      addSearch,

      search: null,
      userType: null,
      avatar: null,
    };
  },
  computed: {
    currentRoute() {
      return this.$route.path;
    },
    menus() {
      return this.sidebar2.main;
    },
    layout() {
      return this.$route.matched[0].meta.layout;
    },
    user() {
      return this.$store.state.user.info;
    },
    admins() {
      return this.$store.state.admin.info;
    },
    userToken() {
      return this.$store.state.user.token;
    },
    adminToken() {
      return this.$store.state.admin.token;
    },
    my_avatar(){
      return this.$store.state.admin.cover;
    },
    cover_img() {
      return this.$store.state.user.cover;
    },
    isMain() {
      return this.layout == "Main";
    },
    isAdmin() {
      return this.layout == "Admin";
    },
    isUserLogin() {
      return this.userToken != null;
    },
    isAdminLogin() {
      return this.adminToken != null;
    },
    isMobile() {
      return this.$store.state.screen.width < 1264;
    },
  },
  watch: {
    layout() {
      this.userType = this.loginQueryParams();
    },
  },
  mounted() {
    let self = this;
    this.userType = this.loginQueryParams();

    setTimeout(() => {
      self.getGreeting();
    }, 0);
  },
  methods: {
    getGreeting() {
      if (this.user == null) {
        return false;
      }

      if (this.user.length != undefined) {
        let user = JSON.parse(this.user);
        if (this.cover_img == null) {
          this.avatar = user.info.cover;
        } else {
          this.avatar = this.cover_img;
        }
      }

    },
    goToWebPlayer() {
      const mood = JSON.parse(this.user).info.moods_id;
      if (mood == "" || mood == null) {
        this.goToRoute("/user/mood");
      } else {
        this.goToRoute("/user");
      }
    },
    logo() {
      this.goToRoute("/home");
    },
    openDrawer() {
      this.$emit("openDrawer", true);
    },
    goToRoute(route) {
      if (this.currentRoute != route) {
        this.$router.push(route).catch((err) => {});
      }
      window.scrollTo(0, 0);
    },
    addInputSearch() {
      const user_id = JSON.parse(this.user).user_id;
      const data = {
        user_id: user_id,
        search: this.search,
      };

      this.addSearch(data).then((res) => {
        this.$store.dispatch("recent/getSearch", res);
        this.search = null;
      });
    },
    logout() {
      this.$store.dispatch("screen/getLoading", true);

      setTimeout(() => {
        let type = null;
        if (this.layout == "User" || this.layout == "Main") {
          this.$store.dispatch("user/getInfo", {});
          this.$store.dispatch("user/getToken", null);
          this.$store.dispatch("user/getCover", null);
          localStorage.removeItem("user");
          localStorage.removeItem("user_token");
          localStorage.removeItem("user_track");

          this.$store.dispatch("audio/getPlaylistTrack", []);
          this.$store.dispatch("audio/getPlaylistType", "");

          localStorage.removeItem("playlistType");
          localStorage.removeItem("playlistTrack");
          localStorage.removeItem("playlistLoop");

          this.$store.dispatch("audio/get", false);
          type = "user";
        }

        if (this.layout == "Admin") {
          this.$store.dispatch("admin/getInfo", {});
          this.$store.dispatch("admin/getToken", null);
          localStorage.removeItem("admin");
          localStorage.removeItem("admin_token");
          type = "admin";
        }

        this.$router.push(`/login?type=${type}`);
        this.$store.dispatch("screen/getLoading", false);
      }, 5000);
    },
  },
};
</script>

<style>
header > div {
  display: flex;
  justify-content: space-between;
}

.search {
  max-width: 350px;
}

.menu {
  cursor: pointer;
}
.images {
  width: 0%;
  margin-top: -10px;
  margin-bottom: -20px;
  @media screen {
    @media (min-width: 1000px) {
      width: 120px;
    }
  }
}

.menu.active {
  font-weight: bold;
  color: rgb(67, 156, 212);
}
</style>
