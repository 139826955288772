<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "The fear of death is a common human experience, but it doesn't have to control or limit your life. This subliminal is designed to help you overcome your fear and embrace a peaceful acceptance of the inevitable."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help:'
    },
    {
      type: 'list',
      items: [
        "Reducing anxiety and fear related to death and mortality.",
        "Shifting your perspective to see death as a natural part of life.",
        "Promoting a sense of peace and acceptance in the face of mortality.",
        "Helping you let go of unnecessary worries and live life fearlessly.",
        "Encouraging a deeper appreciation for the present moment and the preciousness of life."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind to release fears and embrace a more peaceful mindset regarding death. As a result, you may experience a greater sense of freedom, joy, and gratitude in your daily life. Remember, changing deep-seated beliefs takes time, so be patient and consistent in your practice."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Confronting and overcoming the fear of death can be a challenging but transformative journey. By using this subliminal, you can learn to embrace a peaceful acceptance of the inevitable and live a life free from the constraints of fear. Here are a few things to consider before you begin:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your fears: Start by accepting that your fear of death is normal and understandable.",
        "Reflect on your views: Consider your beliefs and attitudes towards death and how they shape your fears.",
        "Challenge negative thoughts: Identify and challenge any negative or irrational thoughts related to death.",
        "Cultivate acceptance: Focus on fostering an accepting and peaceful mindset towards the concept of death."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal is designed to help you reconstruct your relationship with death and find peace within it. By consistently listening to the affirmations, you can gradually alleviate your fear and live a life guided by courage and tranquility. Take a moment to prepare yourself mentally, set your intentions, and embark on this transformative journey towards fearlessness."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Fear of Death Removal subliminal! By taking this step towards overcoming your fear and embracing a peaceful acceptance of the inevitable, you've embarked on a journey of living life fearlessly. It's important to reflect on your experience and continue on this path of personal growth and transformation."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your thoughts and emotions: Take time to journal your thoughts and feelings about death and its impact on your life.",
        "Practice mindfulness and meditation: Engage in practices that promote inner peace and presence in the present moment.",
        "Seek support: Consider reaching out to a therapist or support group to help you navigate your thoughts and fears.",
        "Embrace gratitude: Cultivate a sense of appreciation for each day and the opportunities life presents."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming the fear of death is a journey that requires patience and self-compassion. Embrace the process and continue to explore ways to live fearlessly, with a renewed sense of peace and acceptance. You have the strength within you to face your fears and create a life filled with joy and purpose."
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Fear of Death Removal subliminal help me overcome my fear of death?`, 
    answer: `The Fear of Death Removal subliminal is designed to help you reframe your thoughts and emotions surrounding death, allowing you to develop a more peaceful acceptance of the inevitable. By listening to the subliminal messages, you can reprogram your subconscious mind to let go of fear and embrace a fearless approach to life. Consistent listening and an open mind are key to experiencing the positive effects of this subliminal.`,
    open: false
  },
  { 
    question: `Is it possible to completely eliminate the fear of death?`, 
    answer: `While the Fear of Death Removal subliminal can assist in reducing and managing the fear of death, complete elimination of this fear may vary from person to person. It is a deeply personal and individual experience. The subliminal can help shift your mindset and perspective, allowing you to approach the concept of death with more peace and acceptance.`,
    open: false 
  },
  { 
    question: `How long does it take to see results from the Fear of Death Removal subliminal?`, 
    answer: `Results can vary from person to person, and it may take some time for the subliminal messages to effect subconscious changes regarding your fear of death. Consistency is crucial, so we recommend listening to the subliminal daily and consistently for an extended period. Over time, you may notice a shift in your thoughts, emotions, and attitudes towards death, leading to a more peaceful acceptance.`,
    open: false 
  },
  { 
    question: `Can the Fear of Death Removal subliminal be used alongside therapy or counseling?`, 
    answer: `Yes, the Fear of Death Removal subliminal can be used in conjunction with therapy or counseling. However, we recommend discussing your specific situation with a healthcare professional or therapist to determine the best approach for addressing and managing your fear of death.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the Fear of Death Removal subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any underlying mental health conditions or concerns, it is advisable to consult with a healthcare professional or therapist before using this subliminal as part of your fear of death management strategy.`,
    open: false 
  },
  { 
    question: `Will the Fear of Death Removal subliminal make me reckless or unconcerned about my safety?`, 
    answer: `No, the Fear of Death Removal subliminal is not intended to make you reckless or unconcerned about your safety. Its purpose is to help you address and manage your fear of death by promoting a more peaceful and accepting mindset. It is important to continue taking necessary precautions and making responsible choices regarding safety.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>