import { render, staticRenderFns } from "./46-youthful-face.vue?vue&type=template&id=4adbfa15&scoped=true&"
import script from "./46-youthful-face.vue?vue&type=script&lang=js&"
export * from "./46-youthful-face.vue?vue&type=script&lang=js&"
import style0 from "./46-youthful-face.vue?vue&type=style&index=0&id=4adbfa15&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4adbfa15",
  null
  
)

export default component.exports