<template>
  <div>
    <div class="mx-6 mt-5">
      <Card>
        <v-row>
          <v-col cols="12" sm="6" lg="6" v-for="search in searchs">
            <Select :title="search.title" :type="search.type" :text="search.text" :value="search.value"
              @getSelectItem="getSelectItem"></Select>
          </v-col>
        </v-row>
      </Card>
    </div>

    <Table itemkey="email" :loading="loading" :items="items" :headers="headers" :select="selected" :total="total"
      @action="getAction" @updateLimitPage="updateLimitPage" @updateSearch="updateSearch" @updateSort="updateSort" />

    <addDialog v-if="dialogType == 'add'" :data="data" :dialog="dialog" @closeDialog="closeDialog" />

    <editDialog v-if="dialogType == 'update'" :data="data" :dialog="dialog" @closeDialog="closeDialog" />

    <deleteDialog v-if="dialogType == 'delete' || dialogType == 'deleteall'" :data="data" :dialog="dialog"
      :selected="selected" @selected="getSelected" @closeDialog="closeDialog" />
  </div>
</template>

<script>
import { getUserFilters } from '@/api/user'

import Card from '@/components/Card.vue'
import Table from '@/components/Admin/Table.vue'
import Select from '@/components/Admin/Select.vue'

import addDialog from './components/addDialog.vue'
import editDialog from './components/editDialog.vue'
import deleteDialog from './components/deleteDialog.vue'
export default {
  components: {
    Card,
    Table,
    Select,
    addDialog,
    editDialog,
    deleteDialog
  },
  data: () => {
    return {
      getUserFilters,

      data: {},
      dialog: false,
      dialogType: null,

      items: [],
      selected: [],
      loading: true,

      headers: [
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Name', value: 'display_name', sortable: false },
        { text: 'Update', value: 'update', sortable: false },
        { text: 'Delete', value: 'delete', sortable: false }
      ],

      searchs: [
        { title: 'Status', type: 'stateuser', text: 'text', value: 'value' },
        { title: 'Subscription', type: 'subscription', text: 'name', value: 'name' },
      ],

      sort: null,
      search: null,
      status: null,
      subscription: null,

      page: 1,
      total: 0,
      limit: 10,
    }
  },
  created() {
    this.getData()
  },
  watch: {
    sort() {
      this.getData()
    },
    subscription() {
      this.getData()
    },
    status() {
      this.getData()
    },
    search() {
      this.getData()
    },
  },
  methods: {
    closeDialog() {
      this.getData()
    },
    getSelected(data) {
      this.selected = data
    },
    getAction(params) {
      this.dialog = true
      this.data = params.data
      this.dialogType = params.action
      this.selected = params.selected
    },
    getData() {
      this.loading = true
      const data = {
        is_user_admin: 1,
        sort: this.sort,
        page: this.page,
        limit: this.limit,
        status: this.status,
        search: this.search,
        subscription: this.subscription,
      }

      this.getUserFilters(data).then((res) => {
        const { users, total } = res

        this.total = total
        this.items = users

        this.loading = false
        this.dialog = false
        this.dialogType = null
      })
    },
    getSelectItem(item) {
      switch (item.type) {
        case 'stateuser':
          this.status = item.data
          break;
        case 'subscription':
          this.subscription = item.data
          break;
      }
    },
    updateLimitPage(data) {
      const { page, limit } = data
      this.page = page
      this.limit = limit

      this.getData()
    },
    updateSearch(data) {
      this.search = data
      this.getData()
    },
    updateSort(data) {
      this.sort = data
      this.getData()
    }
  }
}
</script>
