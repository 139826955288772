<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're a short woman struggling with confidence and embracing your height, this subliminal is here to support you. It works by targeting your subconscious mind and helping you develop a positive and empowered mindset."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help you:'
    },
    {
      type: 'list',
      items: [
        "Boost your self-esteem and feel confident in your own skin.",
        "Overcome any insecurities or negative beliefs about your height.",
        "Embrace your uniqueness and feel proud of being a short woman.",
        "Develop a positive body image and appreciate your physical features.",
        "Build inner strength and resilience to overcome any challenges related to your height."
      ]
    },
    {
      type: 'paragraph',
      text: "With regular listening, this subliminal can help you shift your perspective and cultivate a confident and empowered mindset as a short woman. It encourages self-acceptance, self-love, and embracing your true worth. Remember, confidence comes from within, and you deserve to feel proud and empowered no matter your height."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Building confidence as a short woman can be a transformative journey. This subliminal is designed to help you embrace your height, feel empowered, and proud of who you are. Before you begin, consider these tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Embrace your uniqueness: Recognize that your height is part of what makes you special and unique.",
        "Focus on positive self-talk: Use affirmations to boost your confidence and create a positive mindset.",
        "Dress for success: Wear clothes that make you feel confident and comfortable in your own skin.",
        "Surround yourself with positive influences: Seek out supportive friends and role models who inspire and uplift you."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal aims to rewire your mindset, replacing self-doubt with self-assurance. As you engage in regular listening and implement these strategies, you may find your confidence growing, allowing you to fully embrace your height and radiate self-assuredness. Remember, you are beautiful, unique, and deserving of confidence. Let's embark on this journey together."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Confidence for Short Women subliminal! By taking this step towards boosting your confidence and embracing your height, you've embarked on a journey of self-empowerment and self-acceptance. Now, it's time to reflect on your experience and continue growing in confidence."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice positive self-talk: Replace negative thoughts with empowering and affirming statements about yourself.",
        "Celebrate your uniqueness: Embrace your height and recognize the value it brings to your life.",
        "Engage in self-care: Prioritize your well-being by engaging in activities that make you feel good.",
        "Surround yourself with positivity: Choose to spend time with people who uplift and support you."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, confidence is built over time and through small daily actions. Embrace your height, be proud of who you are, and let your confidence shine. You are amazing just the way you are, and the world needs your unique presence. Keep standing tall!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Confidence for Short Women subliminal help me?`, 
    answer: `The Confidence for Short Women subliminal is designed to help you embrace your height and boost your confidence as a short woman. By listening to the subliminal regularly, you can reprogram your subconscious mind to adopt a positive mindset and overcome any insecurities about your height. It aims to empower you to feel proud and confident in your own skin.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Confidence for Short Women subliminal?`, 
    answer: `Results may vary from person to person, but with consistent listening, you may start to notice changes in your confidence and self-perception within a few weeks. It is important to continue listening to the subliminal regularly to reinforce positive affirmations and promote lasting change.`,
    open: false 
  },
  { 
    question: `Can the Confidence for Short Women subliminal make me taller?`, 
    answer: `No, the Confidence for Short Women subliminal cannot physically change your height. Its purpose is to help you embrace and feel confident in your existing height. It aims to shift your mindset and focus on the positive aspects of being a short woman, empowering you to feel good about yourself.`,
    open: false 
  },
  { 
    question: `Is it safe to listen to the Confidence for Short Women subliminal?`, 
    answer: `Yes, it is safe to listen to the Confidence for Short Women subliminal. The subliminal messages are designed to promote positive self-perception and boost your confidence. However, if you have any concerns or underlying psychological conditions, it is always advisable to consult with a mental health professional.`,
    open: false 
  },
  { 
    question: `Can the Confidence for Short Women subliminal replace therapy or counseling?`, 
    answer: `The Confidence for Short Women subliminal is not a replacement for therapy or counseling. It is designed to complement self-work and personal development. If you are experiencing significant emotional distress or struggling with self-esteem issues, it is recommended to seek professional help from a qualified therapist or counselor.`,
    open: false 
  },
  { 
    question: `Can men listen to the Confidence for Short Women subliminal?`, 
    answer: `While the Confidence for Short Women subliminal is specifically designed for short women, men can also benefit from the positive affirmations and mindset shifts it offers. The subliminal's messages of confidence and self-acceptance can be applicable to individuals of any gender.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>