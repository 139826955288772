<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Having strong social skills and the ability to effectively socialize are essential for building meaningful relationships and experiencing a fulfilling social life. This subliminal is designed to help you enhance your socialization skills and improve your interactions with others."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Enhancing your ability to communicate clearly and confidently.",
        "Boosting your self-confidence and reducing social anxiety.",
        "Developing active listening skills and showing genuine interest in others.",
        "Improving your non-verbal communication, such as body language and facial expressions.",
        "Building empathy and understanding, allowing for more meaningful connections.",
        "Developing assertiveness and setting healthy boundaries in social situations."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to adopt these positive social habits and skills. Over time, you may notice improved confidence, more enjoyable social interactions, and stronger relationships. Remember, practice and patience are key to deepening your social connections."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Enhancing your socialization and improving your social skills can have a significant impact on your relationships and overall well-being. This subliminal is designed to support your journey towards more satisfying interactions with others. Before you begin, consider these strategies to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Clarify what specific social skills you want to improve, such as listening, assertiveness, or empathy.",
        "Practice self-reflection: Reflect on your current social strengths and areas for growth, and visualize how you want to interact with others.",
        "Create positive affirmations: Write down affirmations that reinforce your desired social skills and repeat them to yourself regularly.",
        "Visualize successful interactions: Imagine yourself engaging confidently and positively in different social situations.",
        "Hold your intention in mind: Keep your desired social skills in focus while listening to the subliminal affirmations."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to support and enhance your journey towards improved socialization and enhanced social skills. Through regular listening and intentional practice, you can become more comfortable, confident, and authentic in your interactions with others. Embrace this opportunity for growth, set your intention, and let's begin the transformation together!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Socialization and Social Skills Enhancement subliminal! By taking the time to improve your social skills, you've opened the doors to more fulfilling and meaningful relationships. Now, it's important to reflect on your experience and continue nurturing your social growth."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your progress: Take some time to think about how you have grown and improved in your social interactions.",
        "Practice active listening: Focus on fully engaging with others and showing genuine interest in what they have to say.",
        "Step out of your comfort zone: Push yourself to try new social experiences and challenge any fears or anxieties you may have.",
        "Seek feedback: Ask trusted friends or family members for constructive feedback on your social skills to further improve."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, enhancing your socialization and social skills is an ongoing process. Embrace the opportunity for personal growth, and keep practicing and refining your skills. By doing so, you'll continue to strengthen your connections with others and create more meaningful relationships. Enjoy the journey!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Socialization and Social Skills Enhancement subliminal help improve my social skills?`, 
    answer: `The Socialization and Social Skills Enhancement subliminal is designed to reprogram your subconscious mind with positive affirmations that can enhance your social skills. By listening to the subliminal regularly, you can strengthen your confidence, improve communication abilities, and develop a more positive mindset towards social interactions. The subliminal can be used as a tool to complement other self-improvement efforts and practice real-life social situations.`,
    open: false
  },
  { 
    question: `How long does it take to see improvements in my social skills with the subliminal?`, 
    answer: `The time it takes to see improvements in social skills can vary from person to person. Some individuals may notice positive changes in their confidence and communication abilities shortly after starting to listen to the subliminal, while others may require more time and consistent listening. We recommend listening to the subliminal daily and incorporating the skills learned into real-life social situations for optimal results.`,
    open: false 
  },
  { 
    question: `Can the Socialization and Social Skills Enhancement subliminal help with social anxiety?`, 
    answer: `The Socialization and Social Skills Enhancement subliminal can be a helpful tool for individuals with social anxiety. By rewiring your subconscious mind with positive affirmations and empowering beliefs, it can reduce self-doubt and promote a more confident and relaxed state in social situations. However, we recommend seeking professional help, such as therapy or counseling, for severe social anxiety or related mental health issues.`,
    open: false 
  },
  { 
    question: `Will the subliminal make me overly extroverted or change my personality?`, 
    answer: `No, the subliminal is not designed to change your fundamental personality or make you overly extroverted. Its goal is to enhance your social skills, improve confidence, and help you feel more at ease in social situations. You will still maintain your unique personality traits while gaining the tools to navigate social interactions more effectively.`,
    open: false 
  },
  { 
    question: `Can I listen to the Socialization and Social Skills Enhancement subliminal while engaging in other activities?`, 
    answer: `While it is possible to listen to the subliminal while engaging in other activities, it is recommended to listen in a relaxed and focused state. This allows the subliminal messages to penetrate your subconscious mind more effectively. Listening before bed or during a quiet time can be beneficial as it minimizes distractions and helps you fully absorb the positive affirmations.`,
    open: false 
  },
  { 
    question: `Are there any side effects or risks associated with listening to the Socialization and Social Skills Enhancement subliminal?`, 
    answer: `No, there are no known side effects or risks associated with listening to the subliminal. However, everyone is unique, and if you have any concerns or underlying psychological conditions, it is advisable to consult with a mental health professional before using any self-help tools or techniques.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>