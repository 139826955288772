<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you struggle with a fear of blood or hemophobia, you know how debilitating it can be, especially in medical situations. But there's good news - you can overcome this fear and live a life free from anxiety and fear."
    },
    {
      type: 'heading',
      text: 'How this subliminal can help:'
    },
    {
      type: 'list',
      items: [
        "It works on your subconscious mind to reframe your thoughts, beliefs, and reactions to blood-related situations.",
        "It helps you understand and address the root causes of your fear, allowing you to process and overcome it.",
        "It guides you in developing healthier coping mechanisms and strategies to manage anxiety and fear.",
        "It empowers you to feel more confident and in control in medical situations involving blood."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal consistently, you can gradually rewire your mind and overcome your fear of blood. You'll gain the ability to remain calm and composed, allowing you to face medical situations with confidence and peace of mind. Remember, overcoming fear takes time and practice, but with dedication, you can achieve lasting freedom from hemophobia."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "If you struggle with a fear of blood or hemophobia, this subliminal can help you overcome your anxiety and live a life free from fear in medical situations. Before you start listening, here are some tips to prepare yourself:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your fear: Recognize that your fear is valid and that you are taking steps to overcome it.",
        "Educate yourself: Learn more about blood, medical procedures, and the different coping techniques that can help manage your fear.",
        "Relaxation techniques: Practice deep breathing exercises or other relaxation techniques before listening to help calm your mind and body.",
        "Set your intention: Focus on your goal of overcoming your fear and remind yourself that this subliminal is designed to assist you on that journey."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can train your mind to overcome the fear of blood and hemophobia. Remember to be patient with yourself as progress takes time, and everyone's journey is unique. Take a moment to center yourself, set your intention, and let's begin the process of living a life free from anxiety and fear in medical situations."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Fear of Blood and Hemophobia Removal subliminal! By facing your fear and taking steps to overcome it, you've made significant progress towards living a life free from anxiety and fear in medical situations. It's important to reflect on your journey and continue your growth and transformation."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice exposure therapy: Gradually expose yourself to blood-related situations, starting with less intense exposures and gradually working your way up.",
        "Seek support: Consider reaching out to a therapist or support group who can provide guidance and encouragement throughout your journey.",
        "Use relaxation techniques: Learn and practice relaxation techniques such as deep breathing or mindfulness to manage anxiety in medical situations.",
        "Challenge negative thoughts: Replace negative thoughts with positive affirmations and rational thinking to reframe your perception of blood-related situations."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming fear requires persistence and courage, but you have already demonstrated these qualities by listening to this subliminal. Keep challenging yourself, seeking support when needed, and practicing relaxation techniques to continue your progress. Embrace your newfound freedom from anxiety and fear, and live your life to the fullest!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Fear of Blood and Hemophobia Removal subliminal help me overcome my fear?`, 
    answer: `The Fear of Blood and Hemophobia Removal subliminal uses powerful affirmations to reprogram your subconscious mind and help you overcome your fear of blood and hemophobia. By listening to the subliminal regularly, you can gradually desensitize yourself to the triggers associated with your fear, reduce anxiety, and develop a more positive and calm mindset in medical situations. Consistency is key, and we recommend listening to the subliminal daily for best results.`,
    open: false
  },
  { 
    question: `How long will it take to see results from the Fear of Blood and Hemophobia Removal subliminal?`, 
    answer: `Results can vary from person to person, as everyone responds differently to subliminal messaging. Some individuals may notice positive changes in their fear response relatively quickly, while others may require more time and consistent listening to fully overcome their fear. We recommend listening to the subliminal regularly and with patience, as significant change may take time.`,
    open: false 
  },
  { 
    question: `Can the Fear of Blood and Hemophobia Removal subliminal be used alongside therapy or other treatments?`, 
    answer: `Yes, the Fear of Blood and Hemophobia Removal subliminal can be used as a complementary tool alongside therapy or other treatments for overcoming fear and anxiety. It is important to consult with a healthcare professional or therapist to determine the most appropriate approach for your specific situation.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the Fear of Blood and Hemophobia Removal subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your fear or phobia, please consult with a healthcare professional or therapist. It is important to note that the subliminal is intended to supplement other forms of therapy, not replace them.`,
    open: false 
  },
  { 
    question: `Will the Fear of Blood and Hemophobia Removal subliminal completely eliminate my fear?`, 
    answer: `While the Fear of Blood and Hemophobia Removal subliminal is designed to help you overcome your fear, the extent to which your fear is eliminated can vary. Results depend on various factors, including the intensity of your fear and your individual response to the subliminal. The consistent listening and integration of the subliminal messages into your daily life can greatly reduce your fear, enabling you to live a life free from anxiety and fear in medical situations.`,
    open: false 
  },
  { 
    question: `Can I listen to the Fear of Blood and Hemophobia Removal subliminal while sleeping?`, 
    answer: `Yes, you can listen to the Fear of Blood and Hemophobia Removal subliminal while sleeping. Your subconscious mind is highly receptive during sleep, and listening to the subliminal during this time can reinforce positive affirmations and help reprogram your mindset. However, it is important to ensure your listening experience is comfortable and does not disrupt your sleep.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>