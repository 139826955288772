<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you struggle with staying motivated to exercise or incorporate physical activity into your daily routine, this subliminal can provide the support you need. By targeting your subconscious mind, it can help you build the motivation, consistency, and discipline necessary to make exercise a regular part of your life."
    },
    {
      type: 'heading',
      text: 'Here are some ways in which this subliminal can be beneficial:'
    },
    {
      type: 'list',
      items: [
        "Overcoming mental barriers or resistance to exercise.",
        "Increasing your desire and enthusiasm to engage in physical activity.",
        "Building the discipline and consistency to stick to an exercise routine.",
        "Boosting your energy levels and reducing feelings of fatigue during workouts.",
        "Enhancing your overall physical and mental well-being through regular exercise."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can rewire your mindset and develop a positive attitude towards exercise. It can help you overcome procrastination, stay focused on your fitness goals, and find enjoyment in physical activity. Remember, progress takes time, so be patient and stay committed to your journey towards better health and well-being."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Finding the motivation to exercise regularly can be challenging, but this subliminal is designed to help you overcome obstacles and build a consistent exercise routine. Before you start listening, here are some tips to set yourself up for success:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Determine what you want to achieve through exercise, whether it's improving fitness, losing weight, or boosting energy levels.",
        "Create a schedule: Plan your exercise sessions in advance to ensure regularity and make it a priority in your daily routine.",
        "Find an enjoyable activity: Choose exercises that you genuinely enjoy doing, whether it's dancing, swimming, or playing a sport.",
        "Visualize success: Imagine yourself engaging in exercise and feeling strong, energized, and proud of your accomplishments.",
        "Keep a positive mindset: Stay optimistic and remind yourself of the benefits of exercise, both for your physical and mental well-being."
      ]
    },
    {
      type: 'paragraph',
      text: "With the help of this subliminal, you can transform your mindset and develop a strong motivation to engage in regular exercise. Remember to stay consistent, be kind to yourself, and embrace the journey towards improved health and well-being. Now, let's get ready to press play and begin your exercise journey!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Exercise Motivation subliminal! By dedicating yourself to consistent exercise and physical activity, you're taking important steps towards improving your health and overall well-being. Take a moment to reflect on your experience and keep up the great work!"
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Set SMART goals: Define specific, measurable, attainable, relevant, and time-bound exercise goals.",
        "Create a workout schedule: Plan your exercise routine in advance to help you stay consistent.",
        "Mix up your workouts: Try different types of exercises and activities to keep your routine interesting and prevent boredom.",
        "Celebrate milestones: Acknowledge and reward yourself for reaching exercise milestones and achievements."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, building exercise motivation is a journey that requires consistency and dedication. Stay motivated, track your progress, and celebrate your achievements along the way. Your commitment to regular exercise will lead to improved physical and mental well-being. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Exercise Motivation subliminal help me stay motivated to exercise?`, 
    answer: `The Exercise Motivation subliminal is designed to stimulate your subconscious mind and cultivate a positive and motivated mindset towards exercise. By listening to the subliminal regularly, you may experience increased motivation, higher energy levels, and a greater desire to engage in physical activity. It can help you overcome mental barriers, build consistency, and develop a long-term exercise routine that supports your health and well-being.`,
    open: false
  },
  { 
    question: `When will I start noticing improvements in my exercise motivation?`, 
    answer: `Results may vary from person to person, but some individuals may start noticing improvements in their exercise motivation after consistent listening to the Exercise Motivation subliminal. It is recommended to listen to the subliminal regularly for at least a few weeks to allow the positive affirmations to integrate into your subconscious mind and produce lasting change.`,
    open: false 
  },
  { 
    question: `Can the Exercise Motivation subliminal replace physical exercise?`, 
    answer: `No, the Exercise Motivation subliminal is not intended to replace physical exercise. It is designed to enhance your motivation and mindset towards exercise, but it is still important to engage in regular physical activity to achieve optimal health and fitness. The subliminal can provide support and help you stay consistent with your exercise routine.`,
    open: false 
  },
  { 
    question: `How can I make the Exercise Motivation subliminal more effective?`, 
    answer: `To make the Exercise Motivation subliminal more effective, you can combine it with practical strategies such as setting realistic exercise goals, finding activities you enjoy, and creating a supportive environment. Surrounding yourself with like-minded individuals and keeping track of your progress can also help boost your motivation. Remember to listen to the subliminal consistently and incorporate it into your daily routine.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Exercise Motivation subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Exercise Motivation subliminal. However, if you have any concerns about your health or exercise routine, it is recommended to consult with a healthcare professional.`,
    open: false 
  },
  { 
    question: `Will listening to the Exercise Motivation subliminal guarantee results?`, 
    answer: `While the Exercise Motivation subliminal can help you cultivate a positive mindset towards exercise, individual results may vary. It is important to combine the subliminal with consistent effort, dedication, and healthy lifestyle choices to achieve your fitness goals. The subliminal can serve as a powerful tool to support and enhance your motivation, but it is ultimately up to you to take action and stay committed to your exercise routine.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>