<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Establishing healthy eating habits is crucial for supporting your overall health and well-being. This subliminal is designed to help you develop and maintain these habits, making it easier for you to nourish your body with nutritious food."
    },
    {
      type: 'heading',
      text: 'Here are some key benefits of using this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Increased motivation and discipline to make healthier food choices.",
        "Improved portion control and mindful eating habits.",
        "Reduced cravings for unhealthy foods and snacks.",
        "Enhanced enjoyment of nutritious foods and flavors.",
        "Increased energy levels and overall well-being through proper nutrition."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind to align with your desire for healthy eating. With continued use, you may find it easier to make conscious choices that support your body's nutritional needs. Remember, building healthy eating habits takes time and patience, but the benefits are well worth it."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Developing healthy eating habits is a key component of maintaining overall health and well-being. This subliminal is designed to support you on your journey towards nourishing your body with nutritious foods. Before you start listening, consider these tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Define what healthy eating means to you and the specific habits you want to develop.",
        "Educate yourself: Learn about the benefits of different food groups and the impact of nutritious choices on your body.",
        "Create a healthy meal plan: Plan your meals in advance to ensure you have nutritious options readily available.",
        "Practice mindful eating: Focus on enjoying and savoring your meals, paying attention to hunger and fullness cues.",
        "Maintain a positive mindset: Cultivate a positive attitude towards food and nourishing your body."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal, allow the affirmations to penetrate your subconscious mind, reinforcing and solidifying your desire for healthy eating habits. Stay committed to your goals, and remember that small, consistent changes can lead to long-term success. Embrace this opportunity to prioritize your health and well-being, and let's begin this transformative journey towards healthier eating habits."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Healthy Eating Habits subliminal session! By prioritizing your nutrition and making positive changes to your diet, you've taken an important step towards improving your overall health and well-being. Now, it's time to reflect on your experience and continue fostering healthy habits."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your experience: Take some time to journal your thoughts and feelings about your journey to healthier eating habits.",
        "Educate yourself: Research different foods, recipes, and nutrition tips to expand your knowledge and make informed choices.",
        "Listen to your body: Pay attention to how different foods make you feel and adjust your diet accordingly.",
        "Make gradual changes: Implement small, sustainable changes to your diet rather than striving for perfection overnight."
      ]
    },
    {
      type: 'paragraph',
      text: "Building healthy eating habits is a lifelong process that requires dedication and patience. Remember to celebrate your progress and be kind to yourself during this journey. By nourishing your body with nutritious food, you are investing in your long-term health and well-being. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Healthy Eating Habits subliminal help me develop better eating habits?`, 
    answer: `The Healthy Eating Habits subliminal is designed to positively influence your subconscious mind, helping you develop better eating habits. By listening to the subliminal regularly, you can reprogram your mindset and enhance your motivation towards making healthier food choices. It can also help you overcome cravings, adopt a balanced and nutritious diet, and cultivate a positive relationship with food.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Healthy Eating Habits subliminal?`, 
    answer: `Results can vary from person to person. Some individuals may notice positive changes in their eating habits within a few weeks of consistent listening, while others may require more time. We recommend listening to the subliminal daily and combining it with practical steps, such as meal planning and mindful eating, for best results.`,
    open: false 
  },
  { 
    question: `Can the Healthy Eating Habits subliminal help me lose weight?`, 
    answer: `The Healthy Eating Habits subliminal is not specifically designed for weight loss. However, by helping you develop healthier eating habits and a positive mindset towards food, it can support your overall weight management goals. It is essential to combine the subliminal with other healthy lifestyle choices, such as regular physical activity and a balanced diet, for optimal weight management.`,
    open: false 
  },
  { 
    question: `Is it necessary to follow a specific diet while listening to the Healthy Eating Habits subliminal?`, 
    answer: `While it is not necessary to follow a specific diet while listening to the Healthy Eating Habits subliminal, the subliminal can enhance any dietary approach you choose. It can support you in making healthier food choices, portion control, and maintaining a balanced and nutritious diet.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Healthy Eating Habits subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any specific dietary concerns or health conditions, it is always advisable to consult with a healthcare professional before making any significant changes to your diet.`,
    open: false 
  },
  { 
    question: `Can the Healthy Eating Habits subliminal help me overcome food cravings?`, 
    answer: `Yes, the Healthy Eating Habits subliminal can help you overcome food cravings by influencing your subconscious mind towards healthier eating choices. As you listen to the subliminal regularly, you may find it easier to resist unhealthy temptations and make choices that align with your goals of nourishing your body and improving your overall health.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>