<template>
  <Dialog :dialog="dialog" :width="350" :title="`Add Admin`" @closeDialog="closeDialog">
    <div>
      <div class="upload-container d-flex justify-center ma-5 mb-0">
        <Upload :upload="false" :files="files" v-if="dialog" @getImageName="getImageName" />
      </div>

      <p class="mb-1">Display Name</p>
      <v-text-field solo dense hide-details class="mb-3" placeholder="Name" v-model="name"></v-text-field>

      <v-row class="ma-0" style="gap: 15px">
        <v-col class="pa-0">
          <p class="mb-1">First Name</p>
          <v-text-field solo dense hide-details class="mb-3" placeholder="First Name" v-model="firstname"></v-text-field>
        </v-col>
        <v-col class="pa-0">
          <p class="mb-1">Last Name</p>
          <v-text-field solo dense hide-details class="mb-3" placeholder="Last Name" v-model="lastname"></v-text-field>
        </v-col>
      </v-row>

      <p class="mb-1">Email Address</p>
      <v-text-field solo dense hide-details class="mb-3" placeholder="Email Address" v-model="email"></v-text-field>
    </div>

    <template v-slot:action>
      <v-row class="ma-0">
        <v-col class="pl-0 pr-2">
          <v-btn block @click="submitDialog()" :disabled="disabled">Add</v-btn>
        </v-col>
        <v-col class="pl-2 pr-0">
          <v-btn block @click="closeDialog()">Cancel</v-btn>
        </v-col>
      </v-row>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialog.vue'
import Upload from '@/components/Upload.vue'

import { getMessage } from '@/utils'
import { addUsers } from '@/api/user.js'

export default {
  props: {
    dialog: {
      type: Boolean
    }
  },
  components: {
    Upload,
    Dialog
  },
  data: () => {
    return {
      addUsers,
      getMessage,

      files: [],

      name: null,
      image: null,
      email: null,
      lastname: null,
      firstname: null,
    }
  },
  computed: {
    disabled() {
      return (
        this.name == '' ||
        this.image == '' ||
        this.email == '' ||
        this.lastname == '' ||
        this.firstname == '' ||
        this.name == null ||
        this.image == null ||
        this.email == null ||
        this.lastname == null ||
        this.firstname == null
      )
    },
  },
  methods: {
    getImageName(value) {
      this.image = value
    },
    closeDialog() {
      this.resetDialog()
      this.$emit('closeDialog', false)
    },
    resetDialog() {
      this.name = null
      this.image = null
      this.email = null
      this.lastname = null
      this.firstname = null
    },
    submitDialog() {
      const data = {
        name: this.name,
        is_user_admin: 1,
        file: this.image,
        email: this.email,
        lastname: this.lastname,
        firstname: this.firstname,
      }

      this.addUsers(data).then((data) => {
        this.getMessage(this, data)
        this.closeDialog()
      })
    }
  }
}
</script>
