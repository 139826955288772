import request from "@/utils/request";

export const updateUserInfo = (data) => {
  return request.put(`/api/user_info/${data.user_id}`, data).then((res) => {
    return res.data;
  });
};

export const updateUserInfoImage = (data) => {
  return request.post(`/api/user_info/update`, data).then((res) => {
    return res.data;
  });
};

export const deleteUserInfo = (data) => {
  return request.delete(`/api/user/${data.user_id}`).then((res) => {
    return res.data;
  });
};
