<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're looking to improve your speech and communication skills, proper accent speech training can be a game-changer. This subliminal focuses on helping you enhance your pronunciation and accent, enabling you to speak more clearly and effectively."
    },
    {
      type: 'heading',
      text: 'Benefits of using this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Improving your overall speech clarity and precision.",
        "Enhancing your pronunciation of specific sounds or words.",
        "Developing a more neutral or desired accent for better communication.",
        "Boosting your confidence in speaking and expressing yourself.",
        "Increasing your ability to be understood by others in different settings."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can retrain your brain to adopt proper accent techniques, resulting in noticeable advancements in your speech and communication skills. Remember, practice and consistency are key to achieving long-term improvement."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Developing proper accent speech can greatly enhance your communication skills and boost your confidence. This subliminal is designed to help you improve your pronunciation and accent, allowing you to speak more clearly and effectively. Before you start listening, consider implementing these strategies:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Identify specific areas of improvement: Assess your speech patterns and identify the specific sounds or words that you would like to work on.",
        "Practice active listening: Pay close attention to native speakers and mimic their pronunciation and intonation patterns.",
        "Record and analyze your voice: Record yourself speaking and listen back to identify areas for improvement.",
        "Focus on correct mouth positioning: Pay attention to how your lips, tongue, and jaw move to produce different sounds, and aim for optimal positioning.",
        "Visualize success: Imagine yourself speaking with confidence and clarity, mastering the accent you desire."
      ]
    },
    {
      type: 'paragraph',
      text: "By combining this subliminal with dedicated practice and focused effort, you can significantly enhance your speech and communication skills. Embrace this opportunity for growth and embrace the journey towards more effective and impactful communication."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Proper Accent Speech Training session! By dedicating time to improve your speech and communication skills, you've taken an important step towards becoming a more effective and confident speaker. Reflecting on your experience and implementing what you've learned will help you continue your growth in this area."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice regularly: Keep up with consistent practice to reinforce the techniques you've learned.",
        "Record and listen to yourself: Use a voice recorder to assess your progress and identify areas for improvement.",
        "Engage in conversation: Seek opportunities to engage in conversations to apply your newly acquired accent training techniques.",
        "Receive feedback: Ask for feedback from trusted friends or mentors to help further refine your pronunciation and accent."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, improving your accent and speech skills is a journey that requires ongoing effort and practice. Embrace the challenges and continue to develop your abilities in a patient and consistent manner. Your enhanced communication skills will open up new opportunities and help you express yourself with clarity and confidence. Keep up the good work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can proper accent speech training benefit me?`, 
    answer: `Proper accent speech training can benefit you by improving your communication skills and enhancing your pronunciation and accent. It can help you speak more clearly and effectively, increasing your confidence in social, academic, and professional settings. By learning proper accent training techniques, you can reduce misunderstandings and enhance your overall communication abilities.`,
    open: false
  },
  { 
    question: `How long does it take to see results from proper accent speech training?`, 
    answer: `Results from proper accent speech training can vary depending on various factors, such as the individual's commitment, practice, and initial proficiency. Some individuals may notice improvements in their accent and speech clarity relatively quickly, while others may require more time and consistent practice. It is important to approach accent training with patience, dedication, and regular practice to see long-term results.`,
    open: false 
  },
  { 
    question: `Can I learn proper accent speech training on my own?`, 
    answer: `While it is possible to learn some accent training techniques on your own, working with a qualified speech and accent trainer can provide personalized guidance, constructive feedback, and targeted exercises to address specific areas of improvement. A professional trainer can also help you identify your unique challenges and develop a training plan that suits your individual needs, maximizing the effectiveness of your accent speech training.`,
    open: false 
  },
  { 
    question: `Is it too late for me to improve my accent and speech clarity?`, 
    answer: `It is never too late to improve your accent and speech clarity. With dedication, practice, and proper accent speech training techniques, individuals of all ages can make significant progress in enhancing their pronunciation and communication skills. Remember that consistency and a positive mindset are key factors in achieving improvement.`,
    open: false 
  },
  { 
    question: `Will proper accent speech training completely eliminate my accent?`, 
    answer: `Proper accent speech training aims to enhance your pronunciation and speech clarity, rather than completely eliminating your accent. It recognizes the uniqueness of different accents and focuses on improving clarity, understanding, and effective communication. The goal is to develop a more intelligible and confident speaking style while preserving your cultural identity.`,
    open: false 
  },
  { 
    question: `Can I use accent speech training to learn a new accent or dialect?`, 
    answer: `Yes, accent speech training can be used to learn a new accent or dialect. By working with a qualified speech and accent trainer, you can learn the specific nuances, pronunciation patterns, and speech features of the accent or dialect you are interested in. This can be particularly helpful if you are an actor, language learner, or someone interested in expanding your linguistic repertoire.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>