<template>
  <div>
    <NewSubliminalForYou />
  </div>
</template>

<script>
import NewSubliminalForYou from '@/views/User/NewSubliminalForYou.vue';
export default {
  components: {
    NewSubliminalForYou
  }
}
</script>