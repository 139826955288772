<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're looking to master the art of Jujitsu and become skilled in self-defense techniques, this subliminal can provide you with the mindset and motivation to achieve your goals."
    },
    {
      type: 'heading',
      text: 'Key features and benefits of this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Enhancing your focus, concentration, and mental clarity for effective training sessions.",
        "Boosting your motivation and discipline to consistently show up for training and practice.",
        "Improving your physical strength, agility, and flexibility, enhancing your overall performance.",
        "Developing quick reflexes and instinctual responses for fast and effective self-defense maneuvers.",
        "Building confidence and self-belief in your abilities as a Jujitsu practitioner.",
        "Enhancing your understanding and application of Jujitsu techniques and strategies."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can program your subconscious mind to align with your Jujitsu goals and maximize your potential as a martial artist. As you train and practice, you may notice significant improvements in your skills, self-confidence, and overall performance. Remember, consistent dedication and practice are key to mastering the art of Jujitsu."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on the journey to master the art of Jujitsu requires dedication, discipline, and mental strength. This subliminal is designed to support your training and help you become skilled in self-defense techniques. Before you begin, consider the following tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your goals: Clearly define what you hope to achieve through your Jujitsu practice, such as improved technique, self-confidence, or physical fitness.",
        "Create a training schedule: Establish a regular practice routine that aligns with your goals, ensuring consistency and progress.",
        "Visualize success: Imagine yourself executing techniques with precision, feeling confident and in control.",
        "Clear your mind: Prioritize mental clarity and focus, allowing the subliminal affirmations to penetrate your subconscious mind."
      ]
    },
    {
      type: 'paragraph',
      text: "With consistent listening to this subliminal, you may experience increased motivation, improved technique, and a deeper connection to the art of Jujitsu. As you immerse yourself in this powerful training tool, remember to be patient with yourself and trust in your ability to master the techniques. Get ready to embark on a transformative journey of self-discovery and skill development in Jujitsu."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Jujitsu Training and Mastery subliminal! You've taken a major step towards mastering the art of Jujitsu and becoming proficient in self-defense techniques. As you reflect on your training session, remember that this is just the beginning of your journey towards gaining expertise in Jujitsu."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice regularly: Dedicate time to practice Jujitsu techniques and drills to refine your skills.",
        "Set goals: Establish specific goals and milestones to keep yourself motivated and track your progress.",
        "Seek guidance: Consider working with a Jujitsu instructor or joining a local dojo for additional training and support.",
        "Stay committed: Remember that mastery takes time and consistent effort, so stay dedicated and persevere through challenges."
      ]
    },
    {
      type: 'paragraph',
      text: "As you continue your journey to become skilled in Jujitsu, embrace the learning process and the growth it brings. By practicing regularly and setting goals, you will steadily progress towards mastery. Stay committed, persevere through challenges, and become the master of your own self-defense skills. Good luck on your path to Jujitsu mastery!" 
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How long does it take to become proficient in Jujitsu?`, 
    answer: `The time it takes to become proficient in Jujitsu can vary depending on several factors, including your dedication, training frequency, and natural ability. Consistent practice and training under the guidance of a skilled instructor are essential. Progress can be measured in terms of belts or ranks, and it typically takes several years of committed training to reach higher levels of proficiency.`,
    open: false
  },
  { 
    question: `What are the benefits of learning Jujitsu?`, 
    answer: `Learning Jujitsu offers various benefits, including improved physical fitness, increased self-confidence, enhanced self-defense skills, and better mental discipline. Jujitsu training helps develop balance, flexibility, coordination, and strength. Additionally, it can instill a sense of discipline, focus, and respect for oneself and others.`,
    open: false 
  },
  { 
    question: `Do I need any prior experience or specific fitness level to start learning Jujitsu?`, 
    answer: `No prior experience is required to start learning Jujitsu. Jujitsu is a martial art that can be practiced by individuals of various fitness levels and ages. Whether you are a beginner or have previous martial arts experience, you can start learning Jujitsu and gradually improve your fitness and skills over time.`,
    open: false 
  },
  { 
    question: `Is Jujitsu suitable for self-defense?`, 
    answer: `Yes, Jujitsu is an effective martial art for self-defense. It teaches techniques that focus on using an opponent's strength against them, allowing individuals to defend themselves regardless of their size or strength. Learning Jujitsu can provide practical self-defense skills for real-life situations.`,
    open: false 
  },
  { 
    question: `What equipment do I need for Jujitsu training?`, 
    answer: `Jujitsu training typically requires a gi (uniform), a belt, and a comfortable training mat. Some schools or training facilities may also require additional protective gear, such as mouthguards or shin guards. It is best to consult with your instructor or the training facility for specific equipment requirements.`,
    open: false 
  },
  { 
    question: `Is Jujitsu safe to practice?`, 
    answer: `Jujitsu is generally considered a safe martial art to practice when taught and practiced correctly under the guidance of a qualified instructor. However, as with any physical activity, injuries can occur. It is essential to train with caution, follow proper techniques, and communicate any concerns or injuries to your instructor.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>