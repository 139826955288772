<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on your journey to become a skilled martial artist in Taekwondo requires dedication, discipline, and training. This subliminal is designed to support you on this path by helping you master the techniques and principles of this ancient Korean martial art."
    },
    {
      type: 'heading',
      text: 'Here are some key benefits of using this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Enhancing your focus and concentration during training sessions.",
        "Improving your speed, agility, and flexibility for executing Taekwondo techniques.",
        "Building strength and endurance to endure rigorous training and competitions.",
        "Boosting your confidence and self-esteem as you progress in your journey.",
        "Cultivating discipline, patience, and respect in your practice.",
        "Developing mental toughness and resilience to overcome obstacles."
      ]
    },
    {
      type: 'paragraph',
      text: "Through consistent listening to this subliminal, you can tap into your full potential as a Taekwondo practitioner. It will help you internalize the values and techniques required for mastery, enabling you to excel in your training and achieve your goals. Remember, mastery takes time and practice, but with dedication and this subliminal, you can become the martial artist you aspire to be."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on your journey to become a skilled martial artist through Taekwondo training takes dedication and perseverance. This subliminal is designed to support you in mastering the techniques and principles of this ancient Korean martial art. Before you begin your training, consider these tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Define what you want to achieve in your Taekwondo training, whether it's perfecting specific techniques, improving your flexibility, or advancing in belt ranks.",
        "Establish a training schedule: Consistency is key. Create a regular training routine that aligns with your goals and commitments.",
        "Create a positive training environment: Set up a space where you can practice without distractions, ensuring you have enough room to move and practice your Taekwondo techniques.",
        "Visualize success: Imagine yourself performing the techniques with skill and precision, feeling confident and powerful.",
        "Focus your mind: Maintain a clear and focused mindset while listening, absorbing the subliminal affirmations that support your progress and mastery in Taekwondo."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you may notice improvements in your skills, discipline, and overall performance in Taekwondo. Embrace the journey, stay committed to your training, and remember that mastery comes through consistent effort and dedication. Now, let's embark on this exciting adventure of Taekwondo training and mastery!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Taekwondo Training and Mastery subliminal! You have taken an important step towards becoming a skilled martial artist and mastering the techniques and principles of this ancient Korean art. Reflecting on your progress and continuing your training journey is essential for further growth and improvement."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice regularly: Dedicate consistent time to practice the various Taekwondo techniques.",
        "Set goals: Establish clear goals for your training and strive towards achieving them.",
        "Seek guidance: Consider finding a qualified instructor or joining a Taekwondo class to receive proper guidance.",
        "Challenge yourself: Push beyond your comfort zone and continuously strive for improvement."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, mastery in Taekwondo requires dedication, perseverance, and continuous learning. Embrace the challenges and be patient with your progress. With consistent practice and the right mindset, you will continue to grow as a martial artist. Keep up the fantastic work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Do I need any prior experience to start Taekwondo training?`, 
    answer: `No, Taekwondo is suitable for individuals of all ages and fitness levels, regardless of prior experience. Whether you are a beginner or have previous martial arts experience, Taekwondo offers a progressive training program that allows students to learn and advance at their own pace. Instructors will provide guidance and support to help you develop the necessary skills and techniques.`,
    open: false
  },
  { 
    question: `What are the benefits of practicing Taekwondo?`, 
    answer: `Practicing Taekwondo offers numerous benefits, including improved physical fitness, increased strength and flexibility, enhanced self-confidence and self-discipline, better focus and concentration, stress relief, and the development of practical self-defense skills. Additionally, Taekwondo promotes mental, emotional, and spiritual growth, fostering a strong sense of discipline and respect.`,
    open: false 
  },
  { 
    question: `What can I expect from a typical Taekwondo training session?`, 
    answer: `A typical Taekwondo training session consists of a warm-up, stretching exercises, practicing basic techniques such as punches, kicks, and blocks, patterns or forms (a series of predetermined movements), partner drills, sparring (controlled, supervised combat), and cool-down exercises. The specific content may vary depending on the instructor, level of training, and focus of the session.`,
    open: false 
  },
  { 
    question: `How long does it take to master Taekwondo?`, 
    answer: `The time it takes to master Taekwondo varies depending on various factors, including the individual's dedication, practice frequency, natural aptitude, and the level of mastery desired. Taekwondo is a lifelong journey, and mastery is a continuous process that involves consistent practice, ongoing learning, and personal growth. With regular training and commitment, one can progress through the different color belt levels towards black belt mastery.`,
    open: false 
  },
  { 
    question: `Is Taekwondo suitable for children?`, 
    answer: `Yes, Taekwondo is a popular martial art for children as it promotes self-discipline, respect, and physical fitness. Taekwondo training can help children develop coordination, balance, and focus, as well as teach them valuable life skills such as perseverance and goal-setting. Many Taekwondo schools offer specialized programs and classes tailored specifically for children.`,
    open: false 
  },
  { 
    question: `Is Taekwondo safe?`, 
    answer: `Taekwondo is generally safe when practiced under the guidance of qualified instructors in a proper training environment. Instructors prioritize safety and ensure students learn and practice techniques correctly to prevent injury. Protective gear, such as helmets, gloves, and shin guards, may be used during sparring or other contact drills. It is important to follow the instructor's guidelines and practice with respect for yourself and others.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>