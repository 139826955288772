import { render, staticRenderFns } from "./Recent.vue?vue&type=template&id=77b7c342&scoped=true&"
import script from "./Recent.vue?vue&type=script&lang=js&"
export * from "./Recent.vue?vue&type=script&lang=js&"
import style0 from "./Recent.vue?vue&type=style&index=0&id=77b7c342&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77b7c342",
  null
  
)

export default component.exports