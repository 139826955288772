<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Becoming a successful call center agent requires a combination of skills, mindset, and strategies. This subliminal is designed to help you develop these essential qualities and excel in customer service."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can assist you:'
    },
    {
      type: 'list',
      items: [
        "Enhancing your communication skills, including active listening and effective verbal and written communication.",
        "Building resilience and patience to handle difficult customers or challenging situations.",
        "Developing a positive mindset and maintaining a professional attitude.",
        "Improving your problem-solving abilities to provide efficient solutions to customers' inquiries or concerns.",
        "Boosting your confidence and self-esteem in your role as a call center agent.",
        "Strengthening your ability to multitask and manage your time effectively.",
        "Enhancing your knowledge of products, services, and systems to provide accurate information and assistance to customers.",
        "Learning effective techniques for building rapport and maintaining customer relationships.",
        "Adapting to changes and continuously improving your skills and performance."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to align with the qualities needed to thrive as a successful call center agent. With consistent practice and dedication, you can develop the skills and mindset necessary to provide exceptional customer service and achieve success in your role. Remember, every interaction is an opportunity to make a positive impact."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Becoming a successful call center agent requires a combination of skills, mindset, and strategies. This subliminal is designed to help you develop those qualities and excel in customer service. Before you start listening, here are some tips to prepare yourself:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Clarify your goal to become a successful call center agent and provide exceptional customer service.",
        "Develop positive affirmations: Create statements that reinforce your abilities and strengths as a call center agent.",
        "Visualize success: Imagine yourself handling calls confidently, resolving customer issues efficiently, and building positive relationships.",
        "Maintain focus: Keep your attention on the subliminal messages and align them with your goals and aspirations in the call center industry."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal consistently, you may notice changes in your mindset, communication skills, and customer interaction. Embrace the opportunity to grow and develop as a call center agent, and remember that success in this field is achievable with dedication, practice, and a positive mindset. Now, let's begin your journey to becoming a successful call center agent!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Become a Successful Call Center Agent program! By investing in your growth and honing your skills, you've taken a significant step towards becoming a thriving call center agent. Now it's time to reflect, reinforce your learnings, and continue your journey towards success in customer service."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your progress: Take some time to journal about the skills and strategies you've learned and how you can apply them in your work.",
        "Practice active listening: In your everyday conversations, focus on listening attentively to understand the needs and concerns of customers.",
        "Seek feedback: Ask for feedback from your supervisors or colleagues to identify areas of improvement and enhance your performance.",
        "Embrace continuous learning: Stay updated with industry trends, customer service best practices, and new technologies to stay ahead of the game."
      ]
    },
    {
      type: 'paragraph',
      text: "Becoming a successful call center agent is a journey that requires ongoing dedication and a growth mindset. Stay committed to sharpening your skills, providing exceptional service, and delivering positive outcomes for your customers. With perseverance and a passion for customer satisfaction, you have the potential to excel in your career. Good luck!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What skills do I need to become a successful call center agent?`, 
    answer: `To become a successful call center agent, it is important to have strong communication and interpersonal skills, excellent problem-solving abilities, good time management, and the ability to multitask. Additionally, being empathetic, patient, and having a positive attitude towards customer interactions can greatly contribute to your success as a call center agent.`,
    open: false
  },
  { 
    question: `How can the Become a Successful Call Center Agent subliminal help me?`, 
    answer: `The Become a Successful Call Center Agent subliminal is designed to help you develop the mindset, skills, and strategies needed to thrive in a call center environment. It uses subliminal messages to influence your subconscious mind, promoting confidence, adaptability, active listening skills, and effective problem-solving abilities. By listening to the subliminal regularly, you can enhance your performance and improve customer interactions.`,
    open: false 
  },
  { 
    question: `Will the Become a Successful Call Center Agent subliminal replace training and experience?`, 
    answer: `No, the subliminal is intended to complement your training and experience as a call center agent, not replace them. It is a tool to help you enhance your mindset and performance, but it does not substitute the knowledge and skills acquired through training and hands-on experience. Continual learning and on-the-job training remain essential for success in the call center industry.`,
    open: false 
  },
  { 
    question: `Can the Become a Successful Call Center Agent subliminal help me handle difficult customers?`, 
    answer: `Yes, the Become a Successful Call Center Agent subliminal can help you handle difficult customers more effectively. By reprogramming your subconscious mind, the subliminal messages can enhance your ability to remain calm, empathetic, and solution-oriented during challenging interactions. However, it is important to remember that each customer interaction is unique, and ongoing training and experience are also valuable in providing excellent customer service in difficult situations.`,
    open: false 
  },
  { 
    question: `How long does it take to see results from the Become a Successful Call Center Agent subliminal?`, 
    answer: `The time it takes to see results from the Become a Successful Call Center Agent subliminal can vary depending on the individual and their level of receptiveness to the affirmations. Consistent listening is key to allow the positive messages to influence your subconscious mind and bring about the desired changes in mindset and performance. Patience and persistence are important for long-term success.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Become a Successful Call Center Agent subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Become a Successful Call Center Agent subliminal. However, if you have any concerns about your mental health or performance, please consult with a healthcare professional or your supervisor. The subliminal is designed to be a helpful tool to enhance your mindset and skills as a call center agent.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>