<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're looking to boost your personal magnetism and attract positive energy and opportunities into your life, this subliminal can help you achieve that. By targeting your subconscious mind, it helps you tap into your innate charisma and presence."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can enhance your personal magnetism:'
    },
    {
      type: 'list',
      items: [
        "Increasing self-confidence and self-esteem, allowing you to radiate positive energy.",
        "Enhancing your natural charm and charisma, making you more appealing to others.",
        "Attracting opportunities and abundance into your life, both personally and professionally.",
        "Improving your communication and interpersonal skills, aiding in building strong connections.",
        "Boosting your overall presence and magnetism, making you a memorable individual."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind to align with the qualities of personal magnetism. As a result, you may find yourself attracting positive people, circumstances, and opportunities. Remember, embracing your personal power and embodying confidence are key aspects of enhancing your magnetism."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Cultivating personal magnetism can have a powerful impact on your life, helping you attract positive energy, opportunities, and connections. This subliminal is designed to support you in enhancing your personal magnetism. Before you start listening, here are a few tips to prepare yourself:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your intentions: Reflect on the specific areas of your life where you want to enhance your personal magnetism.",
        "Visualize your desired outcome: Imagine yourself radiating charisma, confidence, and positive energy.",
        "Focus on self-care: Prioritize taking care of yourself physically, emotionally, and mentally.",
        "Maintain a positive mindset: Cultivate optimism and gratitude, as they contribute to a magnetic aura.",
        "Open your mind: Be receptive to the subliminal affirmations, allowing them to integrate and align with your subconscious mind."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can tap into your innate charm and attract positive experiences and people into your life. Embrace this opportunity to embrace your personal magnetism, and let the journey towards a more fulfilling and captivating life begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Improve Personal Magnetism subliminal! By dedicating time and energy to enhancing your personal magnetism, you have opened yourself up to a world of positive energy and opportunities. Now, it's important to reflect on your experience and maintain the momentum of your personal growth."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice self-awareness: Observe how your energy and presence affect your interactions with others.",
        "Embrace positive affirmations: Use affirmations to reinforce your belief in your own magnetism and attractiveness.",
        "Cultivate gratitude: Appreciate the positive aspects of your life to attract even more positivity.",
        "Engage in personal development: Continuously work on improving yourself and expanding your knowledge and skills."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, enhancing your personal magnetism is an ongoing journey. Embrace your uniqueness and let your positive energy shine through. As you attract more positivity into your life, seize every opportunity and watch as your life transforms. Keep radiating that magnetic energy!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  {
    question: `What is personal magnetism?`,
    answer: `Personal magnetism refers to the quality or aura that attracts and influences others. It is the ability to exude confidence, charisma, and positivity, which can attract opportunities, relationships, and success in various areas of life.`,
    open: false
  },
  {
    question: `How can the Improve Personal Magnetism subliminal help me?`,
    answer: `The Improve Personal Magnetism subliminal is designed to work with your subconscious mind to enhance your confidence, charisma, and positive energy. By listening to the subliminal, you can align your thoughts and beliefs with the qualities that attract positive experiences and opportunities into your life.`,
    open: false
  },
  {
    question: `How long does it take to see results from the Improve Personal Magnetism subliminal?`,
    answer: `Results can vary from person to person. Some individuals may notice positive changes in their confidence and magnetism immediately, while others may require more time for the subliminal messages to influence their subconscious mind. Consistent listening over a period of time can enhance the effectiveness of the subliminal.`,
    open: false
  },
  {
    question: `Can the Improve Personal Magnetism subliminal make me attractive to everyone?`,
    answer: `While the Improve Personal Magnetism subliminal can enhance your confidence and charisma, it cannot guarantee that you will be attractive to everyone. Personal magnetism is subjective, and attraction is influenced by multiple factors. However, by cultivating a positive mindset and radiating confidence and positivity, you can increase your chances of attracting positive relationships and opportunities.`,
    open: false
  },
  {
    question: `Are there any risks or side effects of listening to the Improve Personal Magnetism subliminal?`,
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, it is important to remember that personal magnetism is just one aspect of overall personal growth and success. It is recommended to complement the subliminal with practical actions and positive habits to maximize its benefits.`,
    open: false
  },
  {
    question: `Can the Improve Personal Magnetism subliminal help with social anxiety?`,
    answer: `While the Improve Personal Magnetism subliminal can boost confidence and charisma, it is not a substitute for professional help for social anxiety. If you are experiencing social anxiety or any mental health concerns, it is important to seek appropriate guidance and support from a qualified professional.`,
    open: false
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>