<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you find yourself making excessive gestures while communicating and want to be more effective and confident in your interactions, this subliminal can help you achieve that goal."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help you:"
    },
    {
      type: 'list',
      items: [
        "Learn to control and reduce excessive gestures, allowing your words to take center stage.",
        "Enhance your non-verbal communication skills, making your message clearer and more impactful.",
        "Increase your confidence in social and professional settings by presenting yourself in a more composed manner.",
        "Develop a greater awareness of your body language, allowing you to make intentional and purposeful gestures.",
        "Improve your overall communication abilities, leading to more effective and successful interactions."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to make positive changes in your body language and communication style. Over time, you may notice a reduction in excessive gestures and an increase in your ability to express yourself with confidence and effectiveness. Remember, practice and consistency are key to achieving lasting results."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Being mindful of excessive gestures can help improve your communication skills and project confidence. This subliminal is designed to assist you in reducing and controlling gestures that may distract from your message. Before starting, consider these tips to enhance your journey:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Be aware of your gestures: Pay attention to your body language and become mindful of any excessive or distracting gestures.",
        "Practice in front of a mirror: Observe how your gestures align with your words and evaluate if they enhance or hinder your message.",
        "Experiment with alternative gestures: Find simple, purposeful movements that support your spoken words without being excessive.",
        "Visualize confident communication: Imagine yourself speaking with clarity and confidence, using controlled gestures that enhance your message."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating these preparation tips with regular listening to this subliminal, you can develop a more effective and confident communication style. Remember to stay mindful of your gestures and aim for controlled and purposeful movements. Embrace this opportunity to enhance your communication skills and let the journey to more impactful interactions begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Stop Over Gesturing subliminal! By taking the time to work on reducing excessive gestures in your communication, you are on your way to becoming a more effective and confident communicator. Now, it's important to reflect on your experience and continue your growth."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Self-awareness: Pay attention to your gestures during conversations and presentations. Take note of any excessive or unnecessary gestures.",
        "Practice in front of a mirror: Stand in front of a mirror and practice your communication skills while observing your body language.",
        "Seek feedback: Ask a trusted friend or colleague to provide feedback on your gestures and suggest areas for improvement.",
        "Challenging situations: Practice controlling your gestures in stressful or challenging situations to better manage your nonverbal communication."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming excessive gestures is a process that requires practice and self-awareness. By implementing these post-listening tips, you can continue to refine your communication skills and become a more effective and confident communicator. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Why should I stop over gesturing?`, 
    answer: `Over gesturing can be distracting and can take away from your message. By reducing excessive gestures, you can appear more composed, confident, and focused during your conversations. It allows your audience to better concentrate on your words and helps you convey your message more effectively.`,
    open: false
  },
  { 
    question: `How can I reduce over gesturing?`, 
    answer: `To reduce over gesturing, start by becoming aware of your body language during conversations. Pay attention to the frequency and intensity of your gestures. Practice speaking in front of a mirror or recording yourself to help identify any excessive gestures. Focus on keeping your body relaxed, maintaining good posture, and using intentional and purposeful gestures that enhance your message.`,
    open: false 
  },
  { 
    question: `Is it okay to use some gestures while communicating?`, 
    answer: `Yes, using gestures can be a helpful way to emphasize points and enhance your communication. The key is to use them purposefully and in moderation. Avoid excessive or repetitive gestures that may distract your audience or take away from your message. You want your gestures to support and complement your verbal communication, not overpower it.`,
    open: false 
  },
  { 
    question: `How long does it take to reduce over gesturing?`, 
    answer: `The time it takes to reduce over gesturing can vary from person to person. It may depend on how ingrained the habit is and how mindful you are about changes in your body language. Consistent practice and self-awareness are key. With time and effort, you can train yourself to be more intentional and controlled with your gestures, leading to more effective and confident communication.`,
    open: false 
  },
  { 
    question: `Are there any exercises or techniques I can use to help reduce over gesturing?`, 
    answer: `Yes, there are exercises and techniques that can help you reduce over gesturing. One effective method is to practice delivering a speech or presentation while holding an object, such as a pen or a small prop. This can help keep your hands occupied and minimize excessive movements. Additionally, working with a speech coach or taking a public speaking course can provide you with valuable feedback and guidance on how to improve your body language and gestures.`,
    open: false 
  },
  { 
    question: `Can reducing over gesturing improve my communication skills?`, 
    answer: `Yes, reducing over gesturing can improve your communication skills. By being more aware and intentional with your gestures, you can appear more confident, composed, and in control. It allows your audience to focus on your message and enhances your overall impact as a speaker or communicator. Developing a more conscious and controlled approach to gestures can help you convey your ideas more effectively and build stronger connections with your audience.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>