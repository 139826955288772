import request from "@/utils/request";

export const getSubscriptionPrices = (data) => {
  return request.get("/api/price").then((res) => {
    const { data, code } = res.data;
    return data;
  });
};

export const getSubscriptions = (data) => {
  return request.get("/api/subscription").then((res) => {
    const { data, code } = res.data;
    return data;
  });
};

export const getSubscriptionFilters = (data) => {
  return request.post("/api/subscription/filter", data).then((res) => {
    const { data, code } = res.data;
    return data;
  });
};

export const addSubscriptions = (data) => {
  return request.post("/api/subscription", data).then((res) => {
    return res.data;
  });
};

export const updateSubscriptions = (data) => {
  return request.put(`/api/subscription/${data.id}`, data).then((res) => {
    return res.data;
  });
};

export const updateToggle = (data) => {
  return request.post("/api/subscription/toggle", data).then((res) => {
    return res.data;
  });
};

export const deleteSubscriptions = (data) => {
  return request.delete(`/api/subscription/${data.id}`).then((res) => {
    return res.data;
  });
};

export const getSubscriptionById = (data) => {
  return request.get(`/api/subscription/${data}`).then((res) => {
    const { data, code } = res.data;
    return data;
  });
};

export const getUserSubscriptionCount = (data) => {
  return request.post("/api/subscription/count", data).then((res) => {
    const { data, code } = res.data;
    return data;
  });
};

export const getSubscriptionByName = (data) => {
  return request.get(`/api/subscription/name/${data}`).then((res) => {
    const { data, code } = res.data;
    return data;
  });
};
