import { render, staticRenderFns } from "./189-resolve-and-heal-guilt.vue?vue&type=template&id=4f2b74fa&scoped=true&"
import script from "./189-resolve-and-heal-guilt.vue?vue&type=script&lang=js&"
export * from "./189-resolve-and-heal-guilt.vue?vue&type=script&lang=js&"
import style0 from "./189-resolve-and-heal-guilt.vue?vue&type=style&index=0&id=4f2b74fa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f2b74fa",
  null
  
)

export default component.exports