import axios from "axios";
import store from "@/store";

// const url = process.env.VUE_APP_BASE_URL || "http://localhost:8000";
const url = process.env.VUE_APP_BASE_URL || "https://prod.v2.magusaudio.com";
//const url = process.env.VUE_APP_BASE_URL || "https://dev.v2.magusaudio.com";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const type = urlParams.get("type");

const service = axios.create({
  baseURL: url,
  timeout: 60000,
});

service.interceptors.request.use(
  (config) => {
    const page = store.state.screen.layout.toLowerCase();
    let token = store.state.user.token;

    if (page == "admin" || type == "admin") {
      token = store.state.admin.token;
    }

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      localStorage.removeItem("page");
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    // const { code } = response.data;

    // const path = window.location.pathname;
    // const layout = localStorage.getItem("layout") || "User";
    // const userType = layout.toLowerCase();

    // if (response.config.url == "/api/auth/check") {
    //   if (code === 401) {
    //     if (layout != "Main") {
    //       if (path != "/login") {
    //         window.location.href = `/login?type=${userType}`;
    //         localStorage.removeItem("layout");
    //         return;
    //       }
    //     }
    //   } else {
    //     if (path == "/login" || path == "/register") {
    //       let user = "user";
    //       if (type == "admin") {
    //         user = type;
    //       }

    //       window.location.href = `/${user}`;
    //       localStorage.removeItem("layout");
    //       return;
    //     }
    //   }
    // }

    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
