<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're struggling with cannabis addiction and looking to break free, this subliminal can provide you with the support and guidance you need. By targeting your subconscious mind, it aims to help you overcome cravings, develop healthier coping mechanisms, and live a substance-free life."
    },
    {
      type: 'heading',
      text: 'How this subliminal can help:'
    },
    {
      type: 'list',
      items: [
        "Reduce cravings for cannabis and decrease dependence on the substance.",
        "Encourage the development of healthier coping mechanisms and stress management techniques.",
        "Boost self-confidence and self-esteem to support your journey towards a substance-free life.",
        "Promote a positive mindset and motivation to stay committed to your recovery.",
        "Support overall well-being and improve the quality of your life without cannabis."
      ]
    },
    {
      type: 'paragraph',
      text: "Breaking free from cannabis addiction is a challenging process, but with the help of this subliminal, you can build inner strength and resilience to overcome the obstacles. Remember, everyone's journey is unique, and progress takes time. Stay committed and believe in your ability to live a healthier, substance-free life."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Overcoming cannabis addiction can be challenging, but with the right mindset and support, you can break free and live a healthier, substance-free life. This subliminal is designed to help you in your journey towards recovery. Before you begin, here are some tips to prepare yourself for listening:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Self-reflection: Take a moment to reflect on why you want to overcome your cannabis addiction and the positive changes you hope to experience in your life.",
        "Set clear intentions: Clarify your goals and commit to them, whether it's reducing cravings, developing healthier coping strategies, or breaking free from the addiction entirely.",
        "Create a support system: Reach out to friends, family, or support groups who can offer encouragement, understanding, and guidance during your journey.",
        "Remind yourself of your strength: You have the power to overcome this addiction and create a healthier, substance-free life for yourself. Believe in your ability to heal and grow."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal, trust that it is working at a deep, subconscious level to help rewire your mindset and break the chains of addiction. Embrace this opportunity for positive change, hold your intentions in your heart, and let the healing begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Cannabis Addiction Removal subliminal! By taking this courageous step towards breaking free from cannabis addiction, you've shown incredible strength and determination. It's important to reflect on your journey and continue to prioritize your health and well-being moving forward."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Seek professional help: Consider reaching out to addiction counselors or support groups for additional guidance and assistance.",
        "Build a support system: Surround yourself with positive influences who understand your journey and can provide ongoing support.",
        "Utilize healthy coping mechanisms: Find alternative activities and hobbies to replace cannabis use, such as exercise, meditation, or creative outlets.",
        "Practice self-care: Prioritize self-care activities that promote relaxation, stress reduction, and overall well-being."
      ]
    },
    {
      type: 'paragraph',
      text: "Breaking free from addiction is a challenging process, but remember that you are not alone. Stay committed to your recovery journey and embrace the positive changes that come with living a healthier, substance-free life. Your future is bright and full of possibilities. Keep moving forward!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Cannabis Addiction Removal subliminal help me break free from cannabis addiction?`, 
    answer: `The Cannabis Addiction Removal subliminal works by delivering positive affirmations to your subconscious mind, helping to rewire your thoughts and beliefs about cannabis. Over time, listening to these subliminal messages can help diminish cravings, strengthen your willpower, and cultivate a mindset that supports a substance-free life. It is designed as a tool to complement and support your journey towards breaking free from cannabis addiction.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Cannabis Addiction Removal subliminal?`, 
    answer: `Results may vary from person to person. Some individuals may start to experience positive changes in their thoughts and behaviors towards cannabis addiction within a few weeks, while others may require longer and more consistent listening. It is important to persevere and use the subliminal as a part of a comprehensive approach to overcoming addiction, including therapy, support groups, and other resources.`,
    open: false 
  },
  { 
    question: `Can the Cannabis Addiction Removal subliminal be used as a standalone solution for addiction recovery?`, 
    answer: `No, the Cannabis Addiction Removal subliminal is not intended to be a standalone solution for addiction recovery. It is designed to be used as a tool to complement and support your journey towards breaking free from cannabis addiction. It is important to seek professional help, such as counseling or therapy, and rely on a comprehensive approach that may include support groups, lifestyle changes, and other resources to effectively recover from addiction.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Cannabis Addiction Removal subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Cannabis Addiction Removal subliminal audio. However, it is important to note that the subliminal is intended to support your recovery journey, not replace professional medical or therapeutic interventions. If you have any concerns about your addiction or health, please consult with a healthcare provider or addiction specialist.`,
    open: false 
  },
  { 
    question: `Can the Cannabis Addiction Removal subliminal be used alongside other addiction recovery programs?`, 
    answer: `Yes, the Cannabis Addiction Removal subliminal can be used alongside other addiction recovery programs. It is designed to complement and support your recovery journey by helping to rewire your thoughts and beliefs about cannabis addiction. If you are currently engaged in an addiction recovery program, we recommend speaking with your healthcare provider or therapist to determine how the subliminal can be incorporated into your existing treatment plan.`,
    open: false 
  },
  { 
    question: `Is the Cannabis Addiction Removal subliminal confidential and private?`, 
    answer: `Yes, using the Cannabis Addiction Removal subliminal is a confidential and private experience. The audio can be listened to at your convenience, ensuring your privacy during the recovery process. It allows you to work on rewiring your mindset and overcoming addiction in the comfort of your own space.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>