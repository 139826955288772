<template>
  <section>
    <div class="container1">
      <div style>
        <h1 class="mt-5">Welcome to Magus</h1>
        <h5 class="mb-7">Sign in to start listening.</h5>
        <div class="wraps">
          <div class="inputs">
            <h5 v-show="email != ''" class="h51">
              <span>Email</span>
              <span class="input-invalid">{{ email_valid }}</span>
            </h5>
            <v-text-field
              flat
              solo
              placeholder="E-mail Address"
              v-model="email"
              class="shrink"
            ></v-text-field>
          </div>
          <div class="inputs">
            <v-divider class="divider"></v-divider>
            <h5 v-show="password != ''" class="h51">
              <span>Password</span>
              <span class="input-invalid">{{ password_valid }}</span>
            </h5>
            <v-text-field
              flat
              solo
              class="shrink"
              :type="showPassword ? 'text' : 'password'"
              placeholder="Password"
              v-model="password"
              @click:append="showPassword = !showPassword"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            ></v-text-field>
          </div>
        </div>
        <div class="wraps2 d-flex">
          <div class="d-flex align-center">
            <input class="check" type="checkbox" v-model="rememberMe" />
            <p class="mt-4">Remember Me</p>
          </div>
          <v-spacer></v-spacer>
          <p @click="dialog = true" class="mt-4 forgot">Forgot Password?</p>
        </div>
        <div
          style="width: max-content; margin: auto"
          class="justify-center align-center mt-0"
        >
          <p v-show="message != ''" medium class="message">{{ message }}</p>
        </div>
        <button
          class="button btn btn-primary btn-lg"
          :disabled="!isLoginReady"
          @click="login()"
        >
          SIGN IN
        </button>
        <div class="separator">
          <div class="line"></div>
          <p class="or">Or login with</p>
          <div class="line"></div>
        </div>
        <div class="d-flex justify-center align-center">
          <div class="ma-2">
            <v-btn
              block
              :href="`${url}/api/login/google`"
              v-if="isUser"
              medium
              style="background: white; border-radius: 10px"
              class="d-flex button justify-center align-center"
            >
              <img
                class="google mr-2"
                src="../../../assets/images/main/login/google.png"
              />
              <p style="color: #427ab3; margin: auto; width: 100px">Google</p>
            </v-btn>
          </div>
          <div class="ma-2">
            <v-btn
              block
              :href="`${url}/api/login/facebook`"
              v-if="isUser"
              medium
              style="background: white; border-radius: 10px"
              class="d-flex button justify-center align-center"
            >
              <img
                class="fb mr-2"
                src="../../../assets/images/main/login/fb.png"
              />
              <p style="color: #427ab3; margin: auto; width: 100px">Facebook</p>
            </v-btn>
          </div>
        </div>
        <h6>
          <span class="mt-5">Don’t have an account? </span>
          <span @click="goToRoute('/register')" class="sign-up mt-5"
            >Sign up</span
          >
        </h6>
      </div>
    </div>
    <ForgotPassword :dialog="dialog" @closeDialog="closeDialog" />
  </section>
</template>

<script>
import Card from "@/components/Card.vue";
import ForgotPassword from "./ForgotPassword.vue";

import { url, loginQueryParams, getMessage } from "@/utils";
import { userSignIn } from "@/api/user";

export default {
  components: {
    Card,
    ForgotPassword,
  },
  data: () => {
    return {
      url,
      getMessage,
      userSignIn,
      loginQueryParams,

      email: "",
      password: "",
      message: "",
      email_valid: "",
      password_valid: "",

      dialog: false,

      rememberMe: false,
      isEmailExist: false,
      showPassword: false,
    };
  },
  watch: {
    email(value) {
      if (value != "") {
        this.message = "";
        this.validateEmail(value);
      }
    },
    password(value) {
      if (value != "") {
        this.message = "";
        this.validatePassword(value);
      }
    },
  },
  computed: {
    isLoginReady() {
      return this.checkForm();
    },
    isUser() {
      return this.userType == "user";
    },
    userType() {
      return this.loginQueryParams();
    },
  },
  methods: {
    checkForm() {
      return this.email != "" && this.password != "";
    },
    validateEmail(email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        this.email_valid = "";
      } else {
        this.email_valid = "Email is not valid";
      }
    },
    validatePassword(password) {
      if (password.length < 8) {
        this.password_valid = "Min 8 characters";
      } else {
        if (/[0-9]/.test(password)) {
          if (/[a-z]/.test(password)) {
            if (/[A-Z]/.test(password)) {
              this.password_valid = "";
            } else {
              this.password_valid = "At least one uppercase letter";
            }
          } else {
            this.password_valid = "At least one lowercase letter";
          }
        } else {
          this.password_valid = "At least one number";
        }
      }
    },
    goToRoute(route) {
      if (this.currentRoute != route) {
        this.$router.push(route).catch((err) => {});
      }
    },
    closeDialog() {
      this.dialog = false;
    },
    login() {
      this.$store.dispatch("screen/getLoading", true);

      const params = {
        email: this.email,
        password: this.password,
        admin: !this.isUser,
      };

      this.userSignIn(params).then((res) => {
        const { success, data } = res;
        if (success) {
          let type = null;
          let path = null;

          const token = data.token;
          const info = JSON.stringify(data.user);
          const cover = data.user.info.cover;
          if (this.userType == "user") {
            type = "user";
            if (
              data.user.info.moods_id == null ||
              data.user.info.moods_id == ""
            ) {
              path = "/user/mood";
            } else {
              path = "/user/overview";
            }
          }

          if (this.userType == "admin") {
            type = "admin";
            path = "/admin";
            this.$store.dispatch(`${type}/getCover`, cover);
          }

          this.$store.dispatch(`${type}/getInfo`, info);
          this.$store.dispatch(`${type}/getToken`, token);
          this.$store.dispatch(`${type}/getLocalInfo`, info);
          this.$store.dispatch(`${type}/getLocalToken`, token);

          this.$router.push(path);
        } else {
          this.email = "";
          this.password = "";
        }
        this.message = res.message;
        this.$store.dispatch("screen/getLoading", false);
      });
    },
  },
};
</script>

<style scoped>
.google {
  height: 30px;
}

.fb {
  height: 20px;
}

h1 {
  color: var(--ma-tufts-blue);
}
p,
.h51 {
  text-align: left;
  margin-left: 10px;
}
.check {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 50px 0 black;
}
.divider {
  background: black;
  margin-left: 10px;
  margin-top: -10px;
  margin-right: 10px;
}
div.wraps {
  border: solid 0.5px #cecece;
  border-radius: 10px;
  flex-direction: column;
  width: 92%;
  margin-left: 4%;
  padding-top: 1em;
}
div.wraps2 {
  width: 92%;
  margin-left: 4%;
}
.forgot {
  cursor: pointer;
  color: var(--ma-tufts-blue);
  text-decoration: underline;
}
.inputs {
  margin-bottom: -10px;
}
.input-invalid {
  color: red;
  margin-left: 15px;
  font-size: 15px;
  font-weight: 600;
}
.message {
  background: #ffacac !important;
  color: black;
  padding: 10px;
  font-family: "Montserrat-Regular";
  text-align: center;
  border-radius: 10px;
  margin-left: -2px;
}
.separator {
  display: flex;
  text-align: center;
  align-items: center;
}
.separator .line {
  height: 0.5px;
  flex: 1;
  background-color: #272727;
}
.or {
  margin: 10px;
  text-align: center;
  font-family: "Montserrat-Regular";
}
h6 {
  font-family: "Montserrat-Regular";
  margin: 20px;
}
.sign-up {
  text-decoration: underline;
  color: #427ab3;
  cursor: pointer;
  font-family: "Montserrat-Bold";
}

@media screen and (min-width: 80rem) {
  section:nth-of-type(1) > .container1 {
    @media screen and (min-width: 1400px) {
      background-image: url("~@/assets/images/main/login/1.png");
      background-repeat: no-repeat;
      background-size: 50%;
      background-position: left;
    }

    @media screen and (min-width: 1400px) {
      padding: 2em;
      background-position: left;
      & > div {
        width: 50%;
        margin-right: 0;
        margin-left: auto;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }
  }
}
</style>
