<template>
  <div>
    <v-navigation-drawer v-if="isMobile" app right width="300" temporary stateless v-model="guideStatus">
      <div class="guide-container">
        <div class="guide-title d-flex justify-space-between">
          <h3 class="my-3 mx-5">Guide</h3>
          <v-icon @click="closeGuide">mdi-close</v-icon>
        </div>
        <div class="ma-5 guide">
          <p>{{ guideText }}</p>
        </div>
      </div>
    </v-navigation-drawer>

    <v-navigation-drawer v-else app right width="300" fixed stateless v-model="guideStatus">
      <div class="guide-container">
        <div class="guide-title">
          <h3 class="my-3 mx-5">Guide</h3>
        </div>
        <div class="ma-5 guide">
          <p>{{ guideText }}</p>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  computed: {
    isMobile() {
      return this.$store.state.screen.width < 800
    },
    guideStatus: {
      get() {
        return this.$store.state.audio.guide.status
      },
      set(val) {
        this.$store.dispatch('audio/getGuideStatus', val)
      }
    },
    guideText() {
      return this.$store.state.audio.guide.data
    }
  },
  watch: {
    isMobile() {
      this.closeGuide()
    }
  },
  methods: {
    closeGuide() {
      this.$store.dispatch('audio/getGuideStatus', false)
    }
  }
}
</script>

<style scoped>
.guide-container {
  overflow-y: hidden;
}

.guide-title {
  width: 95%;
  position: fixed;
  background: #ffffff;
}

.guide {
  overflow-y: auto;
  text-align: justify;
  margin-top: 50px !important
}
</style>