import { render, staticRenderFns } from "./227-wrestling-training-and-mastery.vue?vue&type=template&id=0dfe2b46&scoped=true&"
import script from "./227-wrestling-training-and-mastery.vue?vue&type=script&lang=js&"
export * from "./227-wrestling-training-and-mastery.vue?vue&type=script&lang=js&"
import style0 from "./227-wrestling-training-and-mastery.vue?vue&type=style&index=0&id=0dfe2b46&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0dfe2b46",
  null
  
)

export default component.exports