<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Stepping outside of your comfort zone can be intimidating, but it is often the key to personal growth and success. With this subliminal, you can train your mind to embrace new challenges and expand your comfort zone."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help:'
    },
    {
      type: 'list',
      items: [
        "Increasing your confidence and belief in your abilities to take on new challenges.",
        "Reducing fear, anxiety, and self-doubt that may hold you back from stepping outside of your comfort zone.",
        "Encouraging a growth mindset that embraces learning, adaptability, and resilience.",
        "Providing motivation and inspiration to push beyond your limits and explore new opportunities.",
        "Supporting you in overcoming obstacles and setbacks, fostering perseverance and determination."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can rewire your subconscious mind to embrace new challenges and see them as opportunities for growth. As you expand your comfort zone, you may discover hidden talents, achieve personal breakthroughs, and unlock new levels of success. Remember, personal growth is a lifelong journey, and this subliminal can be a powerful tool to support you along the way."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Stepping out of your comfort zone and embracing new challenges is a powerful way to grow, learn, and achieve personal success. This subliminal is designed to support and motivate you on this journey of expansion and self-discovery. Before you begin listening, consider these tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your goals: Take the time to clearly define the challenges you want to overcome and the areas you want to expand in your life.",
        "Ready your mindset: Adopt a positive and open mindset, knowing that growth comes from pushing beyond your comfort zone.",
        "Visualize success: Imagine yourself confidently taking on new challenges and succeeding in your pursuits.",
        "Stay focused: While listening to the affirmations, maintain your intentions and keep your goals in mind."
      ]
    },
    {
      type: 'paragraph',
      text: "Keep in mind that this subliminal is a tool to support and empower you on your journey. With consistent listening, you may find yourself more willing to take risks, embrace new opportunities, and achieve personal growth. So take a deep breath, remind yourself of your goals, and get ready to expand your comfort zone!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Accepting New Challenges and Expanding Comfort Zone subliminal! By embracing new opportunities and pushing beyond your comfort zone, you have taken a significant step towards personal growth and success. Now is the time to reflect on your experience and continue your journey towards achieving your goals."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Celebrate your achievements: Take note of all the challenges you have overcome and the progress you have made.",
        "Set new goals: Push yourself to reach new heights and establish new milestones.",
        "Step out of your comfort zone: Continue to embrace new experiences and opportunities that come your way.",
        "Practice perseverance: Develop the mindset of consistently challenging yourself and not giving up."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, personal growth requires stepping outside of your comfort zone and embracing new challenges. Embrace the unknown and believe in your ability to overcome obstacles. With each new challenge you conquer, you are expanding your comfort zone and unlocking your full potential. Keep pushing forward and never stop growing!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the subliminal for Accepting New Challenges and Expanding Comfort Zone help me?`, 
    answer: `The subliminal for Accepting New Challenges and Expanding Comfort Zone is designed to reprogram your subconscious mind and help you overcome fear and resistance towards new opportunities. It can improve your self-confidence, motivation, and resilience, enabling you to step out of your comfort zone and embrace personal growth and success. As you continue to listen to the subliminal, you may notice a shift in your mindset and a greater willingness to take on new challenges.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Accepting New Challenges and Expanding Comfort Zone subliminal?`, 
    answer: `Results can vary from person to person, but consistently listening to the subliminal can help accelerate personal growth and the ability to embrace new challenges. It may take time to reprogram your subconscious mind and overcome ingrained patterns of resistance, so we recommend listening to the subliminal daily for at least a few weeks to notice significant changes.`,
    open: false 
  },
  { 
    question: `Are there any techniques I can use to enhance the effectiveness of the Accepting New Challenges and Expanding Comfort Zone subliminal?`, 
    answer: `To enhance the effectiveness of the subliminal, you can pair it with personal development practices such as journaling, goal-setting, and visualization. It is also beneficial to create a supportive environment by surrounding yourself with like-minded individuals who encourage and motivate you to step out of your comfort zone. Consistency is key, so make sure to listen to the subliminal regularly and integrate new challenges into your daily life.`,
    open: false 
  },
  { 
    question: `Can the Accepting New Challenges and Expanding Comfort Zone subliminal be used in combination with other personal growth methods?`, 
    answer: `Yes, the Accepting New Challenges and Expanding Comfort Zone subliminal can be used in combination with other personal growth methods such as affirmations, meditation, and coaching. These complementary practices can further reinforce positive mindset shifts and help you embrace new challenges more readily. Find the combination of techniques that work best for you and align with your personal growth goals.`,
    open: false 
  },
  { 
    question: `Is there any risk of pushing too far out of my comfort zone with the subliminal?`, 
    answer: `While it is important to challenge yourself and step out of your comfort zone, it is also essential to listen to your intuition and acknowledge your limits. The subliminal aims to support healthy personal growth and gradual expansion of your comfort zone. If you ever feel overwhelmed or overly anxious, it is important to take a step back and assess your boundaries. Always prioritize your well-being and growth at a pace that feels comfortable for you.`,
    open: false 
  },
  { 
    question: `Can the Accepting New Challenges and Expanding Comfort Zone subliminal help with fear of failure?`, 
    answer: `Yes, the Accepting New Challenges and Expanding Comfort Zone subliminal can help address and overcome the fear of failure. By reprogramming your subconscious mind and boosting your confidence, it can empower you to take calculated risks and view failure as an opportunity for growth and learning. Over time, you may find yourself more willing to embrace challenges and less hindered by the fear of failure.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>