<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Teeth grinding, also known as bruxism, can have negative effects on both your dental health and overall well-being. This subliminal program is designed to help you stop grinding your teeth and protect your dental health."
    },
    {
      type: 'heading',
      text: 'Benefits of using this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Reducing or eliminating teeth grinding during sleep.",
        "Preventing dental damage such as tooth wear, fracture, and jaw pain.",
        "Promoting relaxation and stress relief to reduce the triggers for teeth grinding.",
        "Improving your sleep quality by reducing disruptions caused by teeth grinding.",
        "Developing new habits and techniques to prevent teeth grinding and protect your dental health."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind to break the teeth grinding habit and promote dental health. As you continue to use the program, you may notice a decrease in jaw clenching and grinding, leading to improved sleep and overall well-being. Remember, consistency is key for long-term results."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Grinding your teeth can lead to various dental issues and discomfort. This subliminal program is designed to help you break the habit and protect your dental health. Before you start listening, consider implementing the following strategies for a more effective experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Increase awareness: Pay attention to your teeth grinding patterns and identify triggers or situations that contribute to the behavior.",
        "Relaxation techniques: Practice stress management techniques like deep breathing, meditation, or engaging in calming activities to reduce anxiety or tension that may lead to teeth grinding.",
        "Oral self-care routine: Establish a consistent oral hygiene routine, including brushing and flossing, to maintain good dental health.",
        "Maintain focus: While listening to the subliminal messages, keep your intention of breaking the teeth grinding habit at the forefront of your mind."
      ]
    },
    {
      type: 'paragraph',
      text: "As you embark on this journey to protect your dental health, remember that consistency and patience are key. This subliminal program aims to rewire your subconscious mind and help you overcome teeth grinding. So, find a quiet and comfortable space, set your intention, and get ready to embrace healthier dental habits."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Stop Teeth Grinding program! By taking this step towards protecting your dental health and stopping teeth grinding, you're prioritizing your well-being. Now, it's time to reflect on your experience and continue on your journey to a healthier smile."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Be mindful of your jaw: Pay attention to any tension or clenching in your jaw throughout the day.",
        "Practice stress management: Explore relaxation techniques like deep breathing, meditation, or yoga.",
        "Take care of your teeth: Maintain regular dental hygiene practices, such as brushing and flossing.",
        "Consider a mouthguard: Talk to your dentist about getting a custom mouthguard to protect your teeth during sleep."
      ]
    },
    {
      type: 'paragraph',
      text: "Stopping teeth grinding is a process that requires patience and self-awareness. By implementing these post-listening tips, you'll continue to support your dental health and well-being. Remember, every effort you make towards stopping teeth grinding brings you one step closer to a healthier smile."
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  {
    question: `How can the Stop Teeth Grinding program help me?`,
    answer: `The Stop Teeth Grinding program is designed to help individuals reduce and eventually stop teeth grinding, also known as bruxism. The program utilizes subliminal messages to reprogram the subconscious mind, helping to relax the jaw muscles, reduce stress and anxiety, and promote healthier sleep patterns. By listening to the program regularly, you can gradually change your habits and protect your dental health.`,
    open: false
  },
  {
    question: `How long does it take to see results from the Stop Teeth Grinding program?`,
    answer: `The time it takes to see results may vary from person to person. Some individuals may notice improvements in their teeth grinding habits within a few weeks of consistent listening, while others may require more time. It's important to remember that individual results can depend on various factors, including the severity of the teeth grinding and the consistency of listening to the program.`,
    open: false
  },
  {
    question: `Can I listen to the Stop Teeth Grinding program during sleep?`,
    answer: `Yes, you can listen to the Stop Teeth Grinding program during sleep if it is comfortable for you. The subliminal messages are designed to influence the subconscious mind, which remains receptive during sleep. By listening to the program while sleeping, you may further reinforce the positive affirmations and support the development of healthier sleep habits.`,
    open: false
  },
  {
    question: `Is the Stop Teeth Grinding program suitable for children?`,
    answer: `The Stop Teeth Grinding program is primarily designed for adults. It is recommended that parents or guardians consult with a healthcare professional before introducing the program to children. They can provide guidance on potential causes and appropriate treatments for teeth grinding in children.`,
    open: false
  },
  {
    question: `Can the Stop Teeth Grinding program replace the use of dental devices?`,
    answer: `The Stop Teeth Grinding program aims to provide a natural and non-invasive approach to reduce teeth grinding. While it may help some individuals manage their teeth grinding habits, it is not intended to replace the use of dental devices prescribed by a dental professional. It's important to consult with your dentist for a comprehensive evaluation and advice on the best course of treatment for your specific case.`,
    open: false
  },
  {
    question: `Are there any known side effects of listening to the Stop Teeth Grinding program?`,
    answer: `No, there are no known side effects associated with listening to the Stop Teeth Grinding program. However, if you experience any discomfort or concerns while using the program, it is recommended to discontinue use and consult with a healthcare professional or dentist.`,
    open: false
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>