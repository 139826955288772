import { render, staticRenderFns } from "./162-general-trauma-healing.vue?vue&type=template&id=3390ce12&scoped=true&"
import script from "./162-general-trauma-healing.vue?vue&type=script&lang=js&"
export * from "./162-general-trauma-healing.vue?vue&type=script&lang=js&"
import style0 from "./162-general-trauma-healing.vue?vue&type=style&index=0&id=3390ce12&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3390ce12",
  null
  
)

export default component.exports