<template>
  <section>
    <div class="container1">
      <div>
        <h3 class="ma-2">
          Automatic Mental Health and Self Improvement
        </h3>
        <h1>AT YOUR <br>
        WORKPLACE!</h1>
      </div>
      <div>
        <button @click="dialog = true" class="button btn btn-primary btn-lg">Contact Us</button>
      </div>
      <Dialog :dialog="dialog" :width="800" title="" @closeDialog="closeDialog">
        <ContactUs @closeDialog="closeDialog" />
      </Dialog>
    </div>
  </section>
</template>

<script>
import Dialog from '@/components/Dialog.vue'
import ContactUs from '@/components/Home/ContactUs.vue'

export default {
  components: {
    Dialog,
    ContactUs
  },
  data: () => {
    return {
      dialog: false,
      selected: 1,
      message: '',
      name: '',
    }
  },
  methods: {
    goToRoute(route) {
      if (this.currentRoute != route) {
        this.$router.push(route).catch(err => { })
      }
    },
    closeDialog(data) {
      this.dialog = data
    },
  }
}
</script>

<style scoped>
  section:nth-of-type(1) > .container1 {
    padding-block: 2em;
  }
  h1 {
    font-size: clamp(2.50rem, calc(1.41rem + 3.43vw), 5.63rem);
    color: var(--ma-tufts-blue);
    font-family: "Montserrat-Black";
  }
  h3 {
    margin-top: 2em;
    color: var(--ma-black-olive);
    line-height: 1.35em;
  }


  @media screen and (min-width: 40rem) {
    section {
      text-align: center;
    }

    section:nth-of-type(1) > .container1 {
      padding-block: 4em;

      @media screen and (min-width: 1000px) {
        background-image: url("~@/assets/images/main/business/1/1.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
      }

      @media screen and (min-width: 1400px) {
        height: 95vh;
        padding: 8em;
      }

      @media screen and (min-width: 1200px) and (max-width: 1400px) {
        padding: 5em 12em 10em;
      }

      @media screen and (min-width: 1000px) and (max-width: 1200px) {
        padding: 5em 10em 10em;
      }

      @media screen and (max-width: 1000px) {
        text-align: center;
      }
    }
  }
</style>