<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Having a positive relationship with your coworkers is essential for a productive and enjoyable work environment. This subliminal is designed to help you foster those positive and harmonious relationships, leading to a more fulfilling professional life."
    },
    {
      type: 'heading',
      text: 'Here are some key aspects this subliminal can help with:'
    },
    {
      type: 'list',
      items: [
        "Building strong connections and friendships with your coworkers.",
        "Improving communication skills and resolving conflicts peacefully.",
        "Increasing empathy and understanding towards your colleagues.",
        "Creating a supportive and collaborative work culture.",
        "Promoting teamwork and cooperation among coworkers."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to embrace positive attitudes and behaviors towards your coworkers. This will not only enhance your professional relationships but also contribute to a more productive and enjoyable work environment. Remember, building positive relationships takes effort, but the results are worth it!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Building positive relationships with your coworkers is essential for creating a supportive and enjoyable work environment. This subliminal is designed to help you improve your connections with colleagues and foster a harmonious workplace. Before starting, consider these strategies to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your goals: Consider the type of relationships you want to cultivate with your coworkers, such as trust, cooperation, and mutual respect.",
        "Approach with empathy: Put yourself in your coworkers' shoes and strive to understand their perspectives and challenges.",
        "Practice active listening: Give your full attention during conversations and respond with empathy and interest.",
        "Promote positivity: Encourage a positive work culture by offering praise, support, and kindness to your coworkers."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal, allow the affirmations to penetrate your subconscious mind and help you develop a positive and harmonious relationship with your coworkers. With consistent practice, you may find that communication, teamwork, and overall workplace satisfaction improve. Embrace this opportunity to create a productive and enjoyable work environment for yourself and your colleagues."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Positive Relationship with Coworkers subliminal! By investing in fostering positive and harmonious relationships with your coworkers, you're taking important steps towards creating a productive and enjoyable work environment for yourself and those around you. Now, let's reflect on your experience and set intentions for moving forward."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice active listening: Show genuine interest in others' perspectives and actively engage in conversations.",
        "Offer support and appreciation: Recognize and acknowledge your colleagues' efforts and contributions.",
        "Communicate effectively: Use clear and respectful communication to avoid misunderstandings and conflicts.",
        "Build rapport: Find common interests or activities to bond with your coworkers outside of work."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, cultivating positive relationships at work takes time and effort. Be patient with yourself and others as you navigate different personalities and dynamics. By prioritizing open communication, empathy, and collaboration, you'll contribute to a more fulfilling and supportive work environment. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Positive Relationship with Coworkers subliminal benefit me?`, 
    answer: `The Positive Relationship with Coworkers subliminal is designed to help you develop a positive and harmonious relationship with your coworkers. By listening to the subliminal messages, you can cultivate empathy, effective communication skills, and a sense of cooperation, leading to a more productive and enjoyable work environment. It can also help you navigate conflicts or challenging situations with colleagues in a positive and constructive manner.`,
    open: false
  },
  { 
    question: `How long does it take to see improvements in my relationship with coworkers?`, 
    answer: `The time it takes to see improvements in your relationship with coworkers can vary depending on individual circumstances and the frequency of listening to the subliminal. It is recommended to listen regularly for several weeks to allow the positive affirmations to influence your subconscious mind and for the changes to manifest in your behavior and interactions.`,
    open: false 
  },
  { 
    question: `Can the Positive Relationship with Coworkers subliminal help with difficult coworkers?`, 
    answer: `Yes, the Positive Relationship with Coworkers subliminal can help you develop strategies to navigate challenging interactions with difficult coworkers. By promoting empathy, understanding, and effective communication, the subliminal messages can assist in fostering a more positive and harmonious relationship, even in challenging circumstances. However, it is important to remember that the subliminal is not a substitute for professional mediation or addressing underlying workplace issues.`,
    open: false 
  },
  { 
    question: `Should I listen to the subliminal during work hours?`, 
    answer: `It is recommended to listen to the Positive Relationship with Coworkers subliminal in a relaxed environment outside of work hours. Find a quiet and comfortable space where you can focus on the subliminal messages without distractions. Listening during breaks or before or after work can be beneficial in aligning your mindset for positive coworker interactions during work hours.`,
    open: false 
  },
  { 
    question: `Can the Positive Relationship with Coworkers subliminal help with workplace conflicts?`, 
    answer: `The Positive Relationship with Coworkers subliminal is designed to promote empathy, effective communication, and cooperation, which can be helpful in resolving workplace conflicts or preventing them from escalating. However, it is important to address conflicts directly and professionally, seeking appropriate channels such as HR or management for resolution when necessary. The subliminal can support you in maintaining a positive and constructive attitude while addressing conflicts.`,
    open: false 
  },
  { 
    question: `Is there a certain duration or frequency that I should listen to the subliminal for optimal results?`, 
    answer: `For optimal results, it is recommended to listen to the Positive Relationship with Coworkers subliminal consistently for at least 20-30 minutes per session. It can be beneficial to listen daily or several times a week depending on your preference and availability. Consistency is key in allowing the positive affirmations to impact your subconscious mind and promote positive changes in your relationship with coworkers.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>