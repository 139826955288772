<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
                          //:class="['inline-block rounded-t-lg border-b-2 border-transparent hover:border-gray-300 hover:text-gray-600 dark:hover:text-gray-300', tab.id === selectedTab ? 'active-class' : 'nonactive-class']"
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're struggling with insomnia and finding it difficult to fall asleep or stay asleep at night, this subliminal is here to help. By targeting your subconscious mind, it works to relax your body and train your mind for a restful, uninterrupted sleep."
    },
    {
      type: 'heading',
      text: 'How this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Fall asleep faster and ease into a deep, rejuvenating sleep.",
        "Increase the duration of your sleep and reduce nighttime awakenings.",
        "Wake up feeling refreshed and energized, ready to take on the day.",
        "Train your mind and body to relax and sleep whenever you desire.",
        "Reduce anxiety and stress levels that can contribute to insomnia."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to let go of insomnia and embrace healthy sleep patterns. With improved sleep, you may experience increased focus, productivity, and a better overall quality of life. Remember, consistency is key in achieving lasting results."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Struggling with insomnia can be incredibly frustrating, but this subliminal is here to help you find relief and reclaim restful sleep. Before you start listening, here are a few suggestions to maximize the effectiveness of this subliminal:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a relaxing bedtime routine: Establish a soothing pre-sleep ritual to signal to your body that it's time to unwind and prepare for sleep.",
        "Eliminate distractions: Remove any devices or stimuli that may hinder your ability to fall asleep, such as bright screens or noise.",
        "Set a sleep schedule: Establish consistent sleep and wake times to regulate your body's natural sleep-wake cycles.",
        "Visualize peaceful sleep: Imagine yourself in a tranquil and comfortable environment, feeling calm and relaxed as you drift off to sleep.",
        "Keep an open mind: Be receptive to the subliminal messages and allow them to work with your subconscious mind to support deep, restorative sleep."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal is designed to help you overcome insomnia and train your mind and body to experience restful sleep. With regular listening and a commitment to implementing healthy sleep habits, you can free yourself from the grip of insomnia and enjoy the rejuvenating benefits of a good night's sleep. So take a moment to prepare yourself, relax, and let the journey to improved sleep begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Insomnia Cure subliminal session! By taking this step towards overcoming insomnia and improving your sleep, you have paved the way for a more restful and rejuvenating life. Now, it's time to reflect on your experience and continue on your journey to achieving better sleep."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Evaluate your progress: Take note of any improvements in your sleep patterns and quality.",
        "Create a sleep-friendly routine: Establish a bedtime routine that promotes relaxation and signals your body it's time to sleep.",
        "Practice relaxation techniques: Incorporate calming activities like deep breathing, meditation, or gentle stretches before bedtime.",
        "Create a sleep-conducive environment: Optimize your bedroom by adjusting factors such as light, temperature, and noise."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, conquering insomnia takes time and dedication, but with consistent effort, you can achieve restful and rejuvenating sleep. Embrace the power of your mind and body to relax and find peace in slumber. Keep persevering, and soon, you'll experience the joy of effortless and restful sleep. Sweet dreams!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Insomnia Cure subliminal work?`, 
    answer: `The Insomnia Cure subliminal works by targeting your subconscious mind and reprogramming it with positive affirmations and suggestions related to relaxation, sleep, and overcoming insomnia. As you listen to the subliminal audio, the embedded messages bypass your conscious awareness and directly influence your subconscious, helping to train your mind and body to sleep more easily and peacefully.`,
    open: false
  },
  { 
    question: `How long should I listen to the Insomnia Cure subliminal?`, 
    answer: `The duration for listening to the Insomnia Cure subliminal can vary depending on the individual and the severity of their insomnia. For best results, we recommend listening to the subliminal consistently every night before bedtime for at least 21-30 days. This allows the positive affirmations to deeply penetrate your subconscious mind and facilitate lasting changes in your sleep patterns.`,
    open: false 
  },
  { 
    question: `Can the Insomnia Cure subliminal help with all types of insomnia?`, 
    answer: `The Insomnia Cure subliminal is designed to help individuals overcome common forms of insomnia and develop healthier sleep patterns. It can be effective for mild to moderate cases of insomnia caused by factors such as stress, anxiety, or racing thoughts. However, it may not replace professional medical advice or treatment for severe or chronic insomnia. If you have persistent and severe sleep issues, it is recommended to consult with a healthcare professional.`,
    open: false 
  },
  { 
    question: `Is it safe to use the Insomnia Cure subliminal with other sleep aids?`, 
    answer: `The Insomnia Cure subliminal is generally safe to use with other sleep aids, such as herbal supplements or relaxation techniques. However, if you are taking prescription sleep medication, it is advisable to consult with your healthcare provider before combining it with the subliminal. They will be able to provide personalized guidance based on your specific situation and any potential interactions.`,
    open: false 
  },
  { 
    question: `Will the Insomnia Cure subliminal make me feel drowsy during the day?`, 
    answer: `No, the goal of the Insomnia Cure subliminal is to help you sleep better and achieve more restful nights, not to induce daytime drowsiness. The subliminal is designed to positively influence your subconscious mind and sleep patterns. If you have concerns about daytime drowsiness, it is recommended to ensure you have an appropriate sleep duration and to consult with a healthcare professional if the issue persists.`,
    open: false 
  },
  { 
    question: `Can't I just use the Insomnia Cure subliminal instead of seeking professional help?`, 
    answer: `No, the Insomnia Cure subliminal is not a substitute for seeking professional help if you have chronic or severe insomnia. While the subliminal can be helpful in improving sleep habits and patterns, it is important to consult with a healthcare professional who can provide a thorough evaluation and determine the underlying causes of your insomnia. They can then recommend appropriate treatments and therapies to address your specific needs.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>
