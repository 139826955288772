<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you suffer from a fear of cockroaches or have a cockroach phobia, you understand how debilitating and distressing it can be. This subliminal is specifically designed to help you overcome your fear and eliminate your phobia for good."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help you:"
    },
    {
      type: 'list',
      items: [
        "Reducing the intensity of your fear response towards cockroaches.",
        "Helping you develop a sense of control and calmness when facing cockroaches.",
        "Gradually desensitizing you to the presence of cockroaches.",
        "Rewiring your subconscious mind to associate cockroaches with neutral or positive emotions.",
        "Building confidence in your ability to cope with and overcome your fear."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind to let go of your fear and anxiety surrounding cockroaches. Over time, you may find yourself feeling more at ease and less overwhelmed in the presence of these insects. Remember, everyone's journey is unique, so be patient and kind to yourself as you work towards overcoming your fear."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Dealing with a fear of cockroaches or having a phobia can be challenging and distressing. This subliminal is specifically designed to help you overcome your fear and eliminate your cockroach phobia. Before you start listening, consider these suggestions to prepare yourself:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your fear: Recognize that it is normal to have fears, and it is possible to overcome them.",
        "Education and information: Learn more about cockroaches, their behavior, and their harmlessness. This knowledge can empower you and help demystify your fear.",
        "Progressive exposure: Gradually expose yourself to cockroaches in a controlled way. Start with pictures or videos, then move on to live encounters, if you feel comfortable.",
        "Visualization: Practice visualizing yourself being calm and relaxed in the presence of cockroaches. Imagine yourself feeling safe and unaffected by their presence.",
        "Affirmations: Repeat positive affirmations to reinforce your ability to overcome your fear. Examples include 'I am in control of my fears' and 'I am calm and relaxed around cockroaches.'"
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to help reprogram your mind and overcome your fear of cockroaches. Regular listening combined with your self-preparation can contribute to positive changes in your response to these insects. Take a moment to focus on your desire to overcome your fear, trust in the process, and let's begin the journey to a fear-free life."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Fear of Cockroaches and Cockroach Phobia Removal subliminal! By facing your fear head-on, you've taken an important step towards overcoming your fear and regaining control over your emotions. Now, let's focus on maintaining your progress and continuing to cultivate a fear-free environment."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice exposure therapy: Gradually expose yourself to cockroaches in controlled situations to desensitize your fear.",
        "Challenge negative thoughts: Replace irrational thoughts with positive and realistic ones about cockroaches.",
        "Educate yourself: Learn about cockroaches to dispel any misconceptions that may be fueling your fear.",
        "Seek professional help: If your fear and phobia persist, consider reaching out to a therapist or counselor who specializes in anxiety disorders."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming a fear takes time and effort, but with persistence, you can conquer your fear of cockroaches and eliminate your phobia. Stay focused on your goal, practice exposure therapy, challenge negative thoughts, and seek support when needed. You're on your way to living a fear-free life!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Fear of Cockroaches and Cockroach Phobia Removal subliminal help me?`, 
    answer: `The Fear of Cockroaches and Cockroach Phobia Removal subliminal is designed to help you overcome your fear and phobia of cockroaches. By listening to the subliminal messages regularly, it can positively influence your subconscious mind, allowing you to reprogram your thoughts and emotions towards cockroaches. Over time, you may experience reduced anxiety, fear, and distress when encountering cockroaches.`,
    open: false
  },
  { 
    question: `How long does it take to see results with the Fear of Cockroaches and Cockroach Phobia Removal subliminal?`, 
    answer: `Results can vary depending on the individual and the severity of the fear or phobia. Some people may notice improvements after a few weeks of consistent listening, while others may need more time to rewire their thought patterns. Consistency and persistence are key to achieving long-lasting results.`,
    open: false 
  },
  { 
    question: `Can the Fear of Cockroaches and Cockroach Phobia Removal subliminal completely eliminate my fear?`, 
    answer: `The Fear of Cockroaches and Cockroach Phobia Removal subliminal is designed to help you reduce and manage your fear of cockroaches. While it can be highly effective for many individuals, the extent of fear reduction may vary from person to person. It is important to approach your progress with patience and understanding, as overcoming fears and phobias may involve gradual steps.`,
    open: false 
  },
  { 
    question: `Is it safe to listen to the Fear of Cockroaches and Cockroach Phobia Removal subliminal?`, 
    answer: `Yes, it is safe to listen to the Fear of Cockroaches and Cockroach Phobia Removal subliminal. The audio contains positive affirmations and messages that aim to reprogram your subconscious mind. However, if you have any concerns or underlying mental health conditions, it is always recommended to consult with a mental health professional.`,
    open: false 
  },
  { 
    question: `Can the Fear of Cockroaches and Cockroach Phobia Removal subliminal be used alongside exposure therapy?`, 
    answer: `Yes, the Fear of Cockroaches and Cockroach Phobia Removal subliminal can be used alongside exposure therapy, if recommended and supervised by a licensed therapist. Combining the subliminal with exposure therapy can potentially enhance the treatment process and help you overcome your fear more effectively. Always consult with a therapist to ensure the best approach for your specific needs.`,
    open: false 
  },
  { 
    question: `Will the Fear of Cockroaches and Cockroach Phobia Removal subliminal work for other phobias too?`, 
    answer: `While the focus of the Fear of Cockroaches and Cockroach Phobia Removal subliminal is on overcoming fear and phobia specifically related to cockroaches, it may have a positive impact in reducing fear and anxiety in other areas of your life as well. However, for specific phobias unrelated to cockroaches, there may be more targeted subliminals available that address those specific fears.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>