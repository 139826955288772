<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on the journey to learn and master the ancient Filipino martial art of Kali Arnis can be both challenging and rewarding. This subliminal is designed to support you along the way, helping you enhance your strength, coordination, and self-defense skills."
    },
    {
      type: 'heading',
      text: 'Here are some key aspects of this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Increasing your focus and mental clarity during training sessions.",
        "Boosting your motivation and determination to excel in Kali Arnis.",
        "Enhancing your physical flexibility, agility, and overall body coordination.",
        "Improving your reaction time and instinctual responses in combat situations.",
        "Supporting your ability to learn and memorize different techniques and forms of Kali Arnis."
      ]
    },
    {
      type: 'paragraph',
      text: "With regular listening, this subliminal can help you tap into the power of your subconscious mind to accelerate your progress in Kali Arnis. It can provide you with the mental and physical edge you need to become proficient in this ancient martial art. Remember, consistent practice and dedication are essential on your path to mastery."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Welcome to your journey of learning and mastering the ancient Filipino martial art of Kali Arnis. This subliminal is designed to enhance your strength, coordination, and self-defense skills. Before you begin, here are some tips to optimize your training experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a dedicated training space: Set up an area where you can safely practice and have enough room to move.",
        "Warm up: Prioritize a proper warm-up routine that includes stretching and light exercises to reduce the risk of injury.",
        "Focus on your intent: Visualize yourself becoming skilled in Kali Arnis and achieving mastery over the art.",
        "Maintain a growth mindset: Embrace challenges and setbacks as opportunities for improvement and progress.",
        "Stay consistent: Develop a regular practice schedule to ensure steady progress and skill development."
      ]
    },
    {
      type: 'paragraph',
      text: "As you embark on this journey, remember that mastery of any martial art takes time and dedication. This subliminal is here to support your growth and development, but it is up to you to put in the effort and practice. With consistent training and a positive mindset, you can unlock your full potential in Kali Arnis. Now, let's begin this exciting journey of self-discovery and empowerment!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Kali Arnis Training and Mastery subliminal! By dedicating your time and effort to learning this ancient Filipino martial art, you've embarked on a journey towards enhancing your strength, coordination, and self-defense skills. Now, take a moment to reflect on your experience and consider how you can continue your growth and practice."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Engage in consistent practice: Continue training regularly to further improve your Kali Arnis skills.",
        "Seek further education: Attend workshops or classes to deepen your understanding and refine your techniques.",
        "Set goals and track progress: Establish milestones to work towards and monitor your growth as you advance in this martial art.",
        "Stay committed and motivated: Surround yourself with supportive individuals and find inspiration to stay dedicated to your training."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, mastering Kali Arnis takes time and dedication. Embrace the journey, stay committed to your progress, and celebrate every step forward. With continued practice and determination, you will enhance not only your physical abilities but also your discipline, focus, and self-confidence. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What is Kali Arnis?`, 
    answer: `Kali Arnis is an ancient Filipino martial art that focuses on weapon-based fighting and empty-hand techniques. It emphasizes fluid movements, coordination, and practical self-defense skills. Kali Arnis is known for its use of various weapons, such as sticks, knives, and bladed weapons.`,
    open: false
  },
  { 
    question: `Is Kali Arnis suitable for beginners?`, 
    answer: `Yes, Kali Arnis is suitable for beginners. It can be learned by individuals of all ages and fitness levels. The training can be tailored to accommodate different skill levels, allowing beginners to gradually build their strength, coordination, and self-defense abilities.`,
    open: false 
  },
  { 
    question: `Do I need any prior martial arts experience to learn Kali Arnis?`, 
    answer: `No, you do not need any prior martial arts experience to learn Kali Arnis. The training is designed to be accessible to individuals with varying levels of experience. Whether you are a beginner or have previous martial arts experience, Kali Arnis offers a unique and effective way to develop your skills and mindset.`,
    open: false 
  },
  { 
    question: `What are the benefits of learning Kali Arnis?`, 
    answer: `Learning Kali Arnis offers numerous benefits, such as improved physical fitness, coordination, self-defense skills, and mental focus. It also provides an opportunity to learn about Filipino culture and history. Additionally, practicing Kali Arnis can enhance your confidence, discipline, and overall well-being.`,
    open: false 
  },
  { 
    question: `Is Kali Arnis only for self-defense purposes?`, 
    answer: `While Kali Arnis does include self-defense techniques, it offers much more than just self-defense. The training provides a holistic approach to martial arts, focusing on physical fitness, coordination, mental focus, and personal development. It can be practiced as a form of exercise, a competitive sport, or a means of self-expression.`,
    open: false 
  },
  { 
    question: `Are there any risks involved in practicing Kali Arnis?`, 
    answer: `Like any physical activity, there is a risk of injury when practicing Kali Arnis. However, with proper instruction, use of safety equipment, and adherence to training protocols, the risk can be minimized. It is essential to train under the guidance of a qualified instructor and follow safety guidelines to ensure a safe and effective learning experience.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>