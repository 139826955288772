<template>
  <section>
    <div class="container align-center justify-content-center">
      <div>
        <div>
          <h1>Subliminal Audios</h1>
          <h3>
            Transforming your Subconscious <br />
            Mind to change your life
          </h3>
          <p>
            As the most innovative mental health and <br />
            self-development company, Magus can help <br />
            you address the mental health and wellness <br />
            needs of your business. <br />
            <br />
            We offer flexibility and convenience to your <br />
            company with innovative and relevant <br />
            solutions.
          </p>
        </div>
        <div class="wrapperss">
          <Miniplayer />
        </div>
      </div>
    </div>
  </section>  
</template>

<script>
import Miniplayer from "@/components/Home/Miniplayer.vue";
export default {
  components: {
    Miniplayer,
  },
};
</script>

<style scoped>
  h1 {
    color: var(--ma-black-olive);
    line-height: 1.35em;
  }
  h3 {
    color: var(--ma-tufts-blue);
  }
  p{
    font-family: "Montserrat-Regular";
  }
  .wrapperss{
    @media screen and (min-width: 1400px) {
      width: 30%;
      float: right; 
      position: relative;
    }
  }

  .big-img {
    width: clamp(17.50rem, calc(15.00rem + 30.75vw), 32.00rem);
  }

  @media screen and (min-width: 80rem) {
    section>.container {
      @media screen and (min-width: 1400px) {
        text-align: right;
        height: 100vh;
        background-image: url("@/assets/images/main/science/2/1.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center left;
      }

      @media screen and (max-width: 1200px) {
        height: auto;
        text-align: center;
      }
    }
  }
</style>