<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're feeling stuck or struggling to achieve your goals, this subliminal can help you develop the mindset and strategies necessary for success. By targeting your subconscious mind, it can unlock your full potential and empower you to overcome obstacles."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help:"
    },
    {
      type: 'list',
      items: [
        "Providing positive affirmations and visualizations that align with your goals and aspirations.",
        "Boosting your confidence and self-belief, helping you overcome self-doubt and fear of failure.",
        "Enhancing motivation and perseverance, enabling you to stay focused and committed to your goals.",
        "Helping you identify and overcome limiting beliefs and negative thought patterns that may be holding you back.",
        "Providing strategies and techniques for effective goal setting and action planning."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your daily routine, you can reprogram your subconscious mind to support your goals and dreams. It can help you overcome obstacles, stay motivated, and take consistent action towards achieving what you desire. Remember, success is a journey, and with the right mindset and strategies, you can turn your dreams into reality."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a journey to achieve your goals requires dedication, resilience, and a mindset focused on success. This subliminal aims to support you in overcoming obstacles and reaching your aspirations. Before you begin, consider these strategies to optimize your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your goals: Clearly define what you want to achieve and why it matters to you.",
        "Break it down: Break your goal into manageable steps, allowing you to focus on one task at a time.",
        "Create a plan: Develop a roadmap of actionable and realistic strategies to help you move forward.",
        "Stay motivated: Find sources of inspiration and reminders to keep your commitment alive.",
        "Visualize success: Imagine yourself accomplishing your goals and experiencing the joy and fulfillment that comes with it."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal and immerse yourself in its affirmations, remember that your mindset is a powerful tool in achieving your goals. Stay determined, believe in yourself, and be open to embrace the transformation that can come from within. Let's get started on this exciting journey towards success!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Achieve Goals subliminal! You've taken a significant step towards overcoming obstacles and achieving your goals. Now, it's time to reflect on your experience and continue your journey towards personal growth and success."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Set specific goals: Define your targets and break them down into manageable steps.",
        "Stay positive: Cultivate a mindset of positivity and self-belief.",
        "Create a roadmap: Develop a detailed plan with deadlines and milestones to guide your progress.",
        "Take consistent action: Implement the strategies and actions discussed in the subliminal on a daily basis."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, achieving goals requires dedication, perseverance, and consistent effort. Embrace any setbacks as opportunities for growth and learning, and stay focused on the vision of your desired outcomes. With the right mindset and strategies, you have what it takes to achieve your goals. Keep going!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Achieve Goals subliminal help me overcome obstacles?`, 
    answer: `The Achieve Goals subliminal uses positive affirmations and messages to reprogram your subconscious mind, helping you develop a resilient mindset and strategies for overcoming obstacles. By listening to the subliminal regularly, you can increase your motivation, focus, and determination to overcome challenges and achieve your goals successfully.`,
    open: false
  },
  { 
    question: `Does the Achieve Goals subliminal work for everyone?`, 
    answer: `The effectiveness of the Achieve Goals subliminal can vary from person to person. Results are influenced by factors such as individual mindset, beliefs, and willingness to take action. However, many people have found success in using the subliminal to support their goal achievement efforts.`,
    open: false 
  },
  { 
    question: `How long should I listen to the Achieve Goals subliminal each day?`, 
    answer: `We recommend listening to the Achieve Goals subliminal for at least 30 minutes each day. Consistency is important to allow the positive affirmations to manifest in your subconscious mind and influence your thoughts and actions.`,
    open: false 
  },
  { 
    question: `Can the Achieve Goals subliminal help me with specific goals?`, 
    answer: `Yes, the Achieve Goals subliminal can be tailored to specific goals or areas of focus. By visualizing and affirming your specific goals while listening to the subliminal, you can enhance its effectiveness in helping you achieve those goals.`,
    open: false 
  },
  { 
    question: `Is it necessary to take action towards my goals while listening to the subliminal?`, 
    answer: `While the Achieve Goals subliminal can provide the mindset and motivation to overcome obstacles, it is essential to take action towards your goals. The subliminal complements your efforts by guiding your subconscious mind and providing positive reinforcement, but ultimately, achieving your goals requires consistent action and persistence.`,
    open: false 
  },
  { 
    question: `What other strategies can I combine with the Achieve Goals subliminal to maximize its effectiveness?`, 
    answer: `To maximize the effectiveness of the Achieve Goals subliminal, you can incorporate other strategies such as setting specific and measurable goals, creating a plan of action, practicing visualization, and seeking support from accountability partners or mentors. Additionally, maintaining a positive mindset, practicing self-care, and developing good time management skills can also contribute to your success in achieving goals.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>