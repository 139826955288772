<template>
  <div
    id="deleteaccount"
    :class="`content ${isfull} px-5`"
    :style="`height: ${contentheight}px`"
  >
    <p :class="`title ${isfull}`">How to delete your account?</p>
    <p>Account deletions are permanent and cannot be reversed.</p>
    <p>
      Upon account deletion, your saved data will be deleted including but not
      limited to:
    </p>

    <ul class="mb-5">
      <li>Account credentials</li>
      <li>Session history and saved sessions</li>
      <li>In-app downloads and purchases</li>
      <li>Trackers and journals</li>
    </ul>

    <p id="deletelimit">If you want to manually delete your account</p>
    <p>Simply follow these steps to delete your account:</p>

    <p class="subtitle">Delete account on the website</p>

    <ul class="my-5">
      <li>
        Log in on Magus website and click on your profile picture icon located
        at the upper right corner of the screen.
      </li>
      <li>Click “Profile”.</li>
      <li>Click “Delete Account”</li>
      <li>Type in your password and click “Confirm” deletion.</li>
      <li>You will be asked once again if you wish to proceed.</li>
      <li>Click “Proceed account deletion” to confirm.</li>
    </ul>

    <p class="subtitle">Delete account on the mobile app</p>

    <ul class="my-5">
      <li>Open the Magus app and tap “Me” at the bottom of the screen.</li>
      <li>Tap “Delete Account” under “My Account” section.</li>
      <li>Type in your password and click “Confirm” deletion.</li>
      <li>You will be asked once again if you wish to proceed.</li>
      <li>Tap “Proceed account deletion” to confirm.</li>
    </ul>

    <p class="subtitle">If you want to request account deletion</p>
    <p>
      If you want to request account deletion but are unable to login your
      account or do not have access to the mobile app, you can contact our
      customer support to request for account deletion.
    </p>
    <p>
      Send a request to info@magusaudio.com with the subject line “Account
      Deletion Request”. Include the email address of your account and reason
      for deletion together with other details you would like to include.
    </p>

    <div class="d-flex justify-space-between">
      <p class="link" @click="getAction('reset')">Reset Password</p>
      <p class="link" @click="getAction('crash')">App Crashing</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    full: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data: () => {
    return {
      contentheight: 0,
    };
  },
  created() {
    window.addEventListener("resize", this.changeContentHeight);
  },
  mounted() {
    this.changeContentHeight();
  },
  computed: {
    isfull() {
      return this.full ? "full" : "";
    },
  },
  methods: {
    changeContentHeight() {
      if (!this.full) {
        const contentheight = document.getElementById("deletelimit").offsetTop;
        const deleteaccount =
          document.getElementById("deleteaccount").offsetTop;

        this.contentheight = contentheight - deleteaccount;
      }
    },
    getAction(data) {
      let route = "";
      switch (data) {
        case "reset":
          route = "/support/reset/password";
          break;
        case "crash":
          route = "/support/app/crash";
          break;
      }

      this.$router.push(route);
    },
  },
};
</script>

<style scoped>
.content {
  overflow: hidden;
}

.content.full {
  height: 100% !important;
  overflow: initial;
}

.title.full {
  text-align: center;
}

p {
  font-size: 12px;
  text-align: justify;
}

li {
  font-size: 12px;
  padding: 5px 0px;
  text-align: justify;
}

.link {
  font-size: 12px;
  cursor: pointer;
}

.link:hover {
  color: blue;
  text-decoration: underline;
}
</style>
