<template>
  <div>
    <Appbar @openDrawer="openDrawer" />
    <Sidebar :drawer="drawer" @closeDrawer="closeDrawer" v-if="isMobile" />
    <v-main class="pa-0">
      <router-view></router-view>
    </v-main>
    <Footer />
    <Loading />
  </div>
</template>

<script>
import { getSubscriptionPrices } from "@/api/subscription";

import Appbar from "@/components/User/UserAppbar.vue";
import Sidebar from "@/components/Sidebar.vue";
import Footer from "@/components/Home/Footer.vue";
import Loading from "@/components/Loading.vue";
export default {
  components: {
    Appbar,
    Footer,
    Loading,
    Sidebar,
  },
  data: () => {
    return {
      getSubscriptionPrices,

      drawer: false,
    };
  },
  created() {
    this.getSubscriptionData();
  },
  mounted() {
    document.body.id = "main";
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  computed: {
    showNavbar() {
      return this.$route.name !== "admin/login";
    },
    isMobile() {
      return this.$store.state.screen.width < 1264;
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    openDrawer(data) {
      this.drawer = data;
    },
    closeDrawer(data) {
      this.drawer = data;
    },
    handleResize() {
      this.$store.dispatch("screen/getWidth", window.innerWidth);
      this.$store.dispatch("screen/getHeight", window.innerHeight);
    },
    getSubscriptionData() {
      this.getSubscriptionPrices().then((res) => {
        this.$store.dispatch("subscription/getData", res);
      });
    },
  },
};
</script>

<style>
html,
body {
  overflow: auto;
}
</style>

<style scoped>
body#main {
  margin-top: 60px;
  height: calc(100% - 60px);
}
</style>
