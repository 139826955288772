import { render, staticRenderFns } from "./117-fear-of-criticism-removal.vue?vue&type=template&id=a79cbdae&scoped=true&"
import script from "./117-fear-of-criticism-removal.vue?vue&type=script&lang=js&"
export * from "./117-fear-of-criticism-removal.vue?vue&type=script&lang=js&"
import style0 from "./117-fear-of-criticism-removal.vue?vue&type=style&index=0&id=a79cbdae&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a79cbdae",
  null
  
)

export default component.exports