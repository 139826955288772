<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you aspire to become a successful entrepreneur and achieve your business goals, this subliminal can be a valuable tool in your journey. By programming your mind with the right mindset and skills, it can help you overcome challenges, make effective decisions, and take calculated risks."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can assist you:'
    },
    {
      type: 'list',
      items: [
        "Developing a growth mindset that sees failure as a learning opportunity and drives perseverance.",
        "Building strong self-confidence and belief in your abilities to handle challenges and achieve success.",
        "Enhancing your problem-solving and decision-making skills to navigate the complexities of entrepreneurship.",
        "Unlocking creativity and innovation to generate unique ideas and solutions.",
        "Fostering effective communication and networking skills to build connections and partnerships.",
        "Cultivating discipline, focus, and resilience to overcome setbacks and stay committed to your vision.",
        "Managing stress and maintaining work-life balance to sustain long-term success and well-being."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can rewire your subconscious mind and align it with the mindset and behaviors of successful entrepreneurs. Over time, you may find yourself more confident, driven, and equipped to tackle the challenges of entrepreneurship. Remember, success as an entrepreneur is a journey, and this subliminal can be a powerful companion along the way."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Becoming a successful entrepreneur requires a unique mindset and the ability to overcome challenges. This subliminal is designed to help program your mind with the right beliefs and skills needed to achieve your business goals. Before you start listening, consider these preparation tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your vision: Clearly define your long-term goals and what success means to you as an entrepreneur.",
        "Embrace a growth mindset: Cultivate a mindset that embraces learning, resilience, and the ability to adapt to change.",
        "Visualize your success: Imagine yourself achieving your business goals and experiencing the satisfaction and fulfillment that comes with it.",
        "Stay focused: While listening, keep your mind focused on your business goals and the mindset and skills you want to develop."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to reprogram your subconscious mind and instill the beliefs and skills required for entrepreneurial success. Through regular listening and aligning your actions with your goals, you can overcome obstacles and achieve the success you desire. Embrace this opportunity to program your mind for success, and let's begin the journey to becoming a successful entrepreneur."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Successful Entrepreneur Programming session! By investing in your personal and professional development, you've taken a significant step towards achieving your business goals and becoming a successful entrepreneur. Reflecting on your journey and maintaining a positive mindset will propel you forward as you continue on your path to success."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Set actionable goals: Define clear and achievable short-term and long-term goals for your business.",
        "Create a strategic plan: Map out the steps needed to accomplish your goals and take consistent action.",
        "Continuously learn and adapt: Stay updated with industry trends, seek mentorship, and be open to new ideas and strategies.",
        "Embrace challenges: View obstacles as opportunities for growth and learning to overcome them.",
        "Network and collaborate: Build relationships with like-minded individuals, attend events, and seek business partnerships.",
        "Celebrate progress: Acknowledge and celebrate your achievements along the way to stay motivated and inspired."
      ]
    },
    {
      type: 'paragraph',
      text: "Becoming a successful entrepreneur is a journey that requires dedication, resilience, and continuous learning. Trust in yourself and your abilities, and keep pushing forward even when faced with obstacles. Remember, each step you take brings you closer to your goals. Go forth and create the future you envision!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Successful Entrepreneur Programming subliminal help me become a successful entrepreneur?`, 
    answer: `The Successful Entrepreneur Programming subliminal is designed to reprogram your subconscious mind with positive beliefs, attitudes, and behaviors necessary for entrepreneurial success. By listening to the subliminal regularly, you can develop traits such as resilience, confidence, creativity, and a growth mindset. It can also help you overcome self-limiting beliefs and develop effective problem-solving skills, communication skills, and business strategies.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Successful Entrepreneur Programming subliminal?`, 
    answer: `Results can vary from person to person, as everyone's learning and growth process is unique. Some individuals may experience noticeable changes in mindset and behavior sooner, while others may require more time and consistent listening to fully internalize the messages. We recommend listening to the subliminal daily for at least a few weeks to allow for subconscious reprogramming and potential transformation.`,
    open: false 
  },
  { 
    question: `Can the Successful Entrepreneur Programming subliminal replace formal education or business training?`, 
    answer: `No, the Successful Entrepreneur Programming subliminal is not intended to replace formal education or professional business training. It is meant to complement your existing knowledge and skills by enhancing your mindset and subconscious programming. Formal education and specialized training can provide valuable theoretical and practical foundations for entrepreneurship, which can be further reinforced and amplified through the subliminal.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Successful Entrepreneur Programming subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, it is important to note that the subliminal is designed to enhance personal development and mindset. Success as an entrepreneur also involves other factors such as market dynamics, business strategies, and industry-specific knowledge. It is crucial to combine the subliminal with practical skills, education, and experience for optimal results.`,
    open: false 
  },
  { 
    question: `Can the Successful Entrepreneur Programming subliminal guarantee success as an entrepreneur?`, 
    answer: `The Successful Entrepreneur Programming subliminal is a tool that can support and enhance your entrepreneurial journey by programming your mindset for success. However, the outcome also depends on various factors such as market conditions, business strategies, dedication, and hard work. The subliminal can help you develop the necessary mindset, skills, and beliefs, but ultimately, success as an entrepreneur is a multifaceted process that requires continuous learning, adaptability, and perseverance.`,
    open: false 
  },
  { 
    question: `How can I maximize the effectiveness of the Successful Entrepreneur Programming subliminal?`, 
    answer: `To maximize the effectiveness of the Successful Entrepreneur Programming subliminal, we recommend listening to it in a relaxed and focused state, such as before bed or during meditation. Create a consistent listening routine and supplement it with intentional goal-setting, self-reflection, and action-oriented steps towards your business objectives. Surrounding yourself with positive and supportive influences, such as successful entrepreneurs, mentors, and networks, can also contribute to your entrepreneurial growth and success.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>