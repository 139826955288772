<template>
  <section>
    <div class="container1">
      <div v-if="selected==0">
        <h2>Tell us a bit about yourself</h2>
        <div class="text-left">
          <p>Name</p>
          <input v-model="name" required/>
        </div>
        <div class="text-left">
          <p>Contact Number</p>
          <input v-model="number" required/>
        </div>
        <div class="text-left">
          <p>
            <span>Email Address</span>
            <span v-if="email_valid != ''">{{email_valid}}</span>
          </p>
          
          <input email v-model="email" required/>
        </div>

        <p class="error_contact" v-if="error_message1 != ''">{{error_message1}}</p>

        <div style="wrapper">
          <button @click="closeDialog" class="button btn white btn-lg backs ma-2 mt-5">BACK</button>
          <button @click="next(selected)"  :disabled="disableBtn" class="button btn btn-primary btn-lg ma-2 mt-5">PROCEED</button>
        </div>
      </div>
      <div v-else-if="selected==1">
        <h2>What are you working on?</h2>
        <div class="text-left">
          <p>Company Name</p>
          <input v-model="name2"/>
        </div>
        <div class="text-left">
          <p>Company Size</p>
          <input v-model="company"/>
        </div>
        <div class="text-left">
          <p>Country</p>
          <input v-model="country"/>
        </div>
        <div class="text-left">
          <p>Industry</p>
          <input v-model="industry"/>
        </div>

        <p class="error_contact" v-if="error_message2 != ''">{{error_message2}}</p>

        <div style="wrapper">
          <button @click="back(selected)" class="button btn white btn-lg backs ma-2 mt-5">BACK</button>
          <button @click="next(selected)" class="button btn btn-primary btn-lg ma-2 mt-5">PROCEED</button>
        </div>
      </div>
      <div v-else-if="selected==2">
        <h2>How can we help you?</h2>
        <div class="text-left">
          <p>Message</p>
          <textarea v-model="message"/>
        </div>

        <p class="error_contact" v-if="error_message3 != ''">{{error_message3}}</p>

        <div style="wrapper">
          <button @click="back(selected)" class="button btn white btn-lg backs ma-2 mt-5">BACK</button>
          <button @click="next(selected)" class="button btn btn-primary btn-lg ma-2 mt-5">SUBMIT</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { getMessage } from '@/utils'
import { addConcerns } from '@/api/support.js'

import Dialog from '@/components/Dialog.vue'
export default {
  components: {
    Dialog
  },
  data: () => {
    return {
      addConcerns, 
      getMessage, 

      dialog: true,
      selected: 0,
      disableBtn: true,
      
      name: '',
      email: '',
      number: '',
      company: '',
      industry: '',
      name2: '',
      message: '',
      country: '',

      email_valid: '',
      error_message1: '',
      error_message2: '',
      error_message3: '',
      messageAll: 'All fields are required.'
      
    }
  },
  watch: {
    email(value) {
      if (value != "") {
        this.validateEmail(value);
      }else{
        this.email_valid = 'Email is required.';
        this.disableBtn = true;
      }
    },
  },
  methods: {
    goToRoute(route) {
      if (this.currentRoute != route) {
        this.$router.push(route).catch(err => { })
      }
    },
    next(selected) {
      if(selected == 0) {
        if(this.checkForm1()){
           this.selected=selected+1;
        }else{
          this.error_message1 = this.messageAll;
          setTimeout(() => {
            this.error_message1 = '';
          }, 3000);
        }
      }else if (selected == 1) {
        if(this.checkForm2()){
          this.selected=selected+1;
        }else{
          this.error_message2 = this.messageAll;
          setTimeout(() => {
            this.error_message2 = '';
          }, 3000);
        }
      }else{
        if(this.message != ''){
          const data = {
            name: this.name,
            email: this.email,
            number: this.number,
            message: this.message,
            company: this.name2,
            size: this.company,
            country: this.country,
            industry: this.industry,
          }

          this.addConcerns(data).then((res) => {
            this.getMessage(this, res)
            this.closeDialog()

          })
        }else{
          this.error_message3 = 'Please include your inquiry/concern.';
          setTimeout(() => {
            this.error_message3 = '';
          }, 3000);
        }
      }
    },  
    back(selected) {
        this.selected=selected-1;
    },
    checkForm1() {
      return this.name != "" && this.number != "" && this.email != "";
    },
    checkForm2() {
      return this.name2 != "" && this.company != "" && this.country != ""  && this.industry != "";
    },
    validateEmail(email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        this.email_valid = "";
        this.disableBtn = false;
      } else {
        this.email_valid = "Email is not valid";
        this.disableBtn = true;
      }
    },
    closeDialog() {
      this.resetForm()
      this.selected=0;
      this.$emit('closeDialog', false)
    },
    
    resetForm(){
      this.name = ''
      this.email = ''
      this.number = ''
      this.message = ''
      this.name2 = ''
      this.company = ''
      this.country = ''
      this.industry = ''
    }
  }
}
</script>

<style scoped>
  .error_contact {
    color: red;
    margin-bottom: -10px;
  }
  .container1 {
    padding: 0 5%;
    @media screen and (min-width: 1200px) {
      padding: 0 20%;
    }
  }
  p {
    margin-top: 10px;
  }
  span:nth-of-type(1) {
    font-family: "Montserrat-Bold";
  }
  span:nth-of-type(2) {
    font-family: "Montserrat-SemiBold";
    color: red;
    margin-left: 10px;
    font-size: 12px;
  }
  input, textarea{
    padding-left: 10px;
    width: 100%; 
    margin: auto;
    margin-top: -10px;
    border-radius: 10px;
    border: solid 1px black; 
  }
  textarea{
    height: 140px;
  }
  input{
    height: 40px;
  }
  h2 {
    color: var(--ma-tufts-blue);
  }
.backs{
  border: #1c8edf 1px solid !important;
  color: black;
}
  @media screen and (min-width: 40rem) {

    section:nth-of-type(1) > .container1 {
      padding-block: 4em;

     
    }
  }
</style>