<template>
  <div class="mb-5 mt-10">
    <SupportCard :full="true">
      <AppCrashing :full="true" />
    </SupportCard>
  </div>
</template>

<script>
import AppCrashing from '@/views/Main/SupportPage/AppCrashing.vue'
import SupportCard from '@/components/SupportCard.vue'
export default {
  components: {
    AppCrashing,
    SupportCard
  }
}
</script>
