<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're a shorter man and struggle with confidence issues related to your height, this subliminal can help you overcome those insecurities and embrace your uniqueness. By targeting your subconscious mind, it works to build your confidence and empower you in any situation."
    },
    {
      type: 'heading',
      text: 'Benefits of using this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Boosting your self-esteem and helping you feel more comfortable and confident in your own skin.",
        "Shifting your mindset to focus on your strengths rather than your height.",
        "Encouraging positive body image and self-acceptance.",
        "Developing assertiveness and the ability to project confidence regardless of your height.",
        "Enhancing your overall self-confidence in social, personal, and professional settings."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind and change the way you perceive yourself and your height. Embracing your uniqueness and feeling empowered will allow you to navigate any situation with confidence. Remember, building confidence is a journey, but you have the power to embrace your height and thrive."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Developing confidence and overcoming insecurities related to height can be a transformative journey. This subliminal is designed to support you in embracing your uniqueness and feeling empowered in any situation. Before you start listening, consider these strategies to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your unique qualities: Recognize and appreciate the strengths and attributes that make you who you are.",
        "Challenge negative beliefs: Identify and question any negative thoughts or beliefs about your height. Replace them with positive affirmations.",
        "Focus on self-acceptance: Embrace your uniqueness and value beyond physical appearance. Cultivate self-love and confidence from within.",
        "Visualize confidence: Imagine yourself confidently navigating various situations, unaffected by any insecurities related to your height."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal, allow the powerful affirmations to penetrate deep into your subconscious mind, reinforcing positive beliefs about yourself and heightening your self-confidence. Embrace your journey of personal growth and empowerment, knowing that you have the strength to overcome any insecurities and thrive in every aspect of life."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Confidence for Short Man subliminal session! By investing in your confidence and overcoming insecurities related to your height, you are on a powerful journey of self-acceptance and empowerment. Now, it's time to reflect on your experience and continue your growth."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Embrace your uniqueness: Celebrate what makes you special and focus on the qualities that define your true worth beyond physical appearance.",
        "Challenge negative thoughts: Replace self-doubt with positive affirmations and remind yourself of your strengths and accomplishments.",
        "Practice good posture: Stand tall with confidence, as posture can greatly influence how others perceive you.",
        "Step out of your comfort zone: Engage in activities and social settings that push you beyond your limits, helping you grow and build confidence."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, confidence comes from within, and you have the power to rewrite the narrative surrounding your height. Embrace your uniqueness, focus on your personal growth, and step into every situation with empowerment. You are worthy and capable of achieving anything you set your mind to!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Can the Confidence for Short Man subliminal increase my height?`, 
    answer: `No, the Confidence for Short Man subliminal is not designed to physically increase your height. It is intended to help you build confidence and overcome insecurities related to height. It focuses on empowering you to embrace your uniqueness and feel empowered in any situation, regardless of your height.`,
    open: false
  },
  { 
    question: `How can the Confidence for Short Man subliminal help me?`, 
    answer: `The Confidence for Short Man subliminal can help you build self-esteem, overcome insecurities, and develop a positive mindset regarding your height. It utilizes subliminal messages to reprogram your subconscious mind, allowing you to let go of negative beliefs and embrace your unique qualities. The subliminal can boost your confidence, self-acceptance, and your ability to handle social situations with ease.`,
    open: false 
  },
  { 
    question: `How long does it take to see results from the Confidence for Short Man subliminal?`, 
    answer: `Results can vary depending on the individual and their unique circumstances. Some people may experience positive changes in their confidence and mindset right away, while others may require more time and consistent listening to fully reprogram their subconscious. We recommend listening to the Confidence for Short Man subliminal daily and consistently for best results.`,
    open: false 
  },
  { 
    question: `Can the Confidence for Short Man subliminal replace therapy or counseling?`, 
    answer: `No, the Confidence for Short Man subliminal is not a substitute for therapy or counseling. It is intended to complement personal development efforts and provide additional support in building confidence and empowering mindset. If you are facing emotional challenges or need professional guidance, it is recommended to seek the assistance of a qualified therapist or counselor.`,
    open: false 
  },
  { 
    question: `Will the Confidence for Short Man subliminal make me taller in the eyes of others?`, 
    answer: `No, the Confidence for Short Man subliminal does not physically change your height. It is designed to help you develop confidence, self-acceptance, and a positive mindset regarding your unique qualities, including your height. Its purpose is to empower you to feel confident and empowered in any situation, regardless of how others perceive your height.`,
    open: false 
  },
  { 
    question: `Is there a risk of becoming overconfident or arrogant with the Confidence for Short Man subliminal?`, 
    answer: `No, the Confidence for Short Man subliminal is not intended to promote overconfidence or arrogance. It aims to help you develop healthy self-esteem, authentic confidence, and self-acceptance. The subliminal messages are designed to foster a positive mindset and empower you to embrace your uniqueness with humility and grace.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>