<template>
  <div>
    <UserAppbar />
    <div style="overflow: auto">
      <div class="card-container p-2">
        <Recent />
      </div>
    </div>
  </div>
</template>

<script>
import UserAppbar from "@/components/User/UserAppbar.vue";
import RecentSearch from "@/views/User/Search/RecentSearch.vue";
import Recent from "@/views/User/Search/Recent.vue";
import ListenTo from "@/views/User/Search/ListenTo.vue";
import Subliminal from "@/views/User/Search/Subliminal.vue";
import Playlist from "@/views/User/Search/Playlist.vue";
export default {
  components: {
    UserAppbar,
    RecentSearch,
    Recent,
    ListenTo,
    Subliminal,
    Playlist
  },
};
</script>

<style scoped>
.card-container {
  width: calc(100vw - 375px);
  height: calc(100vh - 100px);
}

@media screen and (max-width: 990px) {
  .card-container {
    width: calc(100vw - 48px);
  }
}

@media screen and (max-width: 500px) {
  .card-container {
    width: 100vw;
  }
}
</style>
