<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you struggle with falling asleep quickly and peacefully, this subliminal can help. By combining soothing sounds and guided relaxation techniques, it aims to help you achieve a state of relaxation that promotes easy and effortless sleep."
    },
    {
      type: 'heading',
      text: 'Key features and benefits of this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Using calming sounds and music to create a peaceful and serene atmosphere for sleep.",
        "Incorporating guided relaxation techniques to help relax your body and clear your mind before sleep.",
        "Promoting a sense of tranquility and peace, allowing you to let go of the day's stress and worries.",
        "Creating a positive association between bedtime and relaxation, making it easier to fall asleep quickly.",
        "Helping you achieve a deeper and more restful sleep, leading to increased energy and productivity during the day."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you may find it easier to transition into a state of deep relaxation and fall asleep swiftly. Remember, consistency is important in retraining your mind and body for better sleep. Make this subliminal a part of your nightly routine to enjoy the benefits of peaceful and restorative sleep."}
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Having trouble falling asleep can be frustrating and disrupt your overall well-being. This subliminal is designed to help you relax and ease into a peaceful sleep faster. Before you begin, here are a few suggestions to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a bedtime routine: Establish a consistent routine that signals to your body it's time to sleep, such as taking a warm bath or reading a book.",
        "Eliminate distractions: Ensure your sleep environment is quiet, dark, and free from any devices that might disrupt your sleep.",
        "Find a comfortable position: Make sure you're lying down in a comfortable position, with pillows and blankets that provide optimal support.",
        "Focus on relaxation: Clear your mind and let go of any stress or tension. Allow yourself to fully relax and be open to the soothing sounds and guided relaxation techniques in this subliminal."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your evening routine, you can train your mind to enter a state of relaxation and fall asleep faster. Embrace this moment to prioritize your sleep and well-being, and prepare to experience a night of deep, restful sleep. Let's begin the journey to falling asleep fast and peacefully."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Fall Asleep Fast subliminal session! You've taken a significant step towards improving the quality of your sleep and achieving a peaceful, restful night. Now, it's time to reflect on your experience and integrate the techniques you've learned into your nightly routine."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a bedtime ritual: Develop a routine that relaxes your mind and signals your body it's time to sleep.",
        "Use soothing sounds: Experiment with calming music or nature sounds to create a peaceful sleep environment.",
        "Practice relaxation techniques: Explore deep breathing exercises and progressive muscle relaxation for a deeper sense of relaxation.",
        "Create a sleep-friendly environment: Optimize your bedroom for quality sleep, ensuring it's dark, quiet, and at a comfortable temperature."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, falling asleep quickly and peacefully is a process that may require some experimentation to find what works best for you. Embrace the journey to better sleep, and be patient with yourself as you develop and refine your sleep habits. Here's to many refreshing and rejuvenating nights!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Fall Asleep Fast program work?`, 
    answer: `The Fall Asleep Fast program incorporates soothing sounds and guided relaxation techniques to help you easily and peacefully fall asleep. The program creates a calming environment that promotes relaxation and helps quiet the mind. By following the guided instructions, you can let go of stress and tension, allowing your body and mind to enter a state of deep relaxation, making it easier to fall asleep quickly.`,
    open: false
  },
  { 
    question: `Do I need any special equipment or tools to use the Fall Asleep Fast program?`, 
    answer: `No special equipment or tools are required to use the Fall Asleep Fast program. It can be accessed through various platforms, such as mobile apps or online streaming services. You may choose to listen to the soothing sounds and guided relaxation techniques using earphones or speakers, depending on your preference.`,
    open: false 
  },
  { 
    question: `How long does it take to fall asleep using the Fall Asleep Fast program?`, 
    answer: `The time it takes to fall asleep using the Fall Asleep Fast program can vary from person to person. Some individuals may find themselves drifting off to sleep within minutes, while others may need more time to relax and unwind before falling asleep. Consistently practicing the techniques and incorporating the program into a bedtime routine can help improve its effectiveness over time.`,
    open: false 
  },
  { 
    question: `Can the Fall Asleep Fast program be used by individuals with insomnia?`, 
    answer: `Yes, the Fall Asleep Fast program can be beneficial for individuals with insomnia. The soothing sounds and guided relaxation techniques help promote a sense of calmness and relaxation, which can aid in falling asleep. However, if you have severe or persistent insomnia, it is recommended to seek professional medical advice for proper evaluation and treatment.`,
    open: false 
  },
  { 
    question: `Are there any side effects of using the Fall Asleep Fast program?`, 
    answer: `No known side effects are associated with using the Fall Asleep Fast program. It utilizes gentle relaxation techniques and soothing sounds to create a peaceful environment conducive to falling asleep. However, if you experience any discomfort or have concerns, it is recommended to discontinue use and consult a healthcare professional.`,
    open: false 
  },
  { 
    question: `Can I use the Fall Asleep Fast program every night?`, 
    answer: `Yes, you can use the Fall Asleep Fast program every night if it helps you fall asleep quickly and peacefully. However, it is important to listen to your body and adjust your routine as needed. If you find that you are consistently falling asleep easily without the program, you may choose to use it as needed or incorporate it into your bedtime routine as a relaxation tool.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>