<template>
  <div>
    <Section1 />
  </div>
</template>

<script>
import Section1 from '@/views/Main/Price/Section1.vue'
export default {
  components: {
    Section1
  }
}
</script>