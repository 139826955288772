<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you struggle with fear and anxiety related to sickness and diseases, this subliminal can help you overcome those fears and embrace a mindset of health and well-being. By targeting your subconscious mind, it can help you shift your perspective and feel more empowered in taking care of your physical and mental health."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can benefit you:"
    },
    {
      type: 'list',
      items: [
        "Reducing excessive worry and anxiety about sickness and diseases.",
        "Promoting a sense of calm and tranquility when faced with health-related concerns.",
        "Building resilience and confidence in your ability to maintain good health.",
        "Encouraging proactive health habits and self-care practices.",
        "Promoting a positive and optimistic outlook on life and your well-being."
      ]
    },
    {
      type: 'paragraph',
      text: "With regular use of this subliminal, you may notice a gradual decrease in your fear and anxiety levels related to sickness and diseases. You can develop a healthier mindset and embrace practices that contribute to your overall well-being. Remember, everyone's journey is unique, so be patient with yourself and continue to listen to the subliminal for lasting results."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "It's common to experience fear and anxiety surrounding sickness and diseases, but this subliminal is designed to help you overcome those fears and embrace a mindset of health and well-being. Before you start listening, take a moment to consider the following strategies:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your fears: Recognize and accept any specific fears or anxieties you have about sickness and diseases.",
        "Educate yourself: Seek reliable information about the topics that cause fear, as knowledge can help alleviate anxiety.",
        "Practice mindfulness: Engage in activities like meditation or deep breathing to help calm your mind and reduce stress.",
        "Focus on positive affirmations: Set the intention to replace fearful thoughts with positive, empowering affirmations related to health and well-being."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, it can help rewire your subconscious mind and shift your perspective on sickness and diseases. You may begin to feel more confident, resilient, and in control of your emotions and thoughts. Take this opportunity to cultivate a mindset of health, well-being, and strength. Let's embark on this journey together, leaving fear and anxiety behind."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Fear of Sickness and Diseases Removal subliminal! By confronting and overcoming your fears and anxieties, you've taken a significant step towards embracing a healthier mindset and overall well-being. It's important to reflect on your experience and continue to grow and transform."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice mindfulness: Engage in activities that promote relaxation and reduce stress, such as meditation or deep breathing exercises.",
        "Educate yourself: Learn about health and wellness to empower yourself with accurate information and dispel fears rooted in misconceptions.",
        "Focus on self-care: Prioritize your physical and mental well-being through proper nutrition, exercise, and self-care practices.",
        "Seek support: Reach out to loved ones or consider professional help if needed to address any underlying fears or anxieties."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming fear is a process that takes time and effort. Embrace a mindset of health and well-being, and celebrate each step you take towards conquering your fears. You have the strength and resilience to overcome any obstacle. Stay strong and be healthy!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Fear of Sickness and Diseases Removal subliminal work?`, 
    answer: `The Fear of Sickness and Diseases Removal subliminal works by targeting your subconscious mind with positive affirmations and suggestions to help you overcome fears and anxieties related to sickness and diseases. By consistently listening to the subliminal, you can reprogram your mindset and develop a more positive and empowered outlook towards health and well-being.`,
    open: false
  },
  { 
    question: `How long do I need to listen to the subliminal to see results?`, 
    answer: `The time it takes to see results can vary from person to person. Some may notice a shift in their mindset and reduction in fear and anxiety after a few listens, while others may require more consistent listening over an extended period of time. We recommend listening to the subliminal daily and consistently for optimal results.`,
    open: false 
  },
  { 
    question: `Can the Fear of Sickness and Diseases Removal subliminal cure health conditions?`, 
    answer: `No, the Fear of Sickness and Diseases Removal subliminal is not intended to cure any health conditions. Its purpose is to help you overcome fears and anxieties related to sickness and diseases and develop a more positive mindset towards health and well-being. If you have any health concerns, please consult with your healthcare provider.`,
    open: false 
  },
  { 
    question: `Can the subliminal help with specific phobias, like fear of needles or blood?`, 
    answer: `While the Fear of Sickness and Diseases Removal subliminal is designed to address fears and anxieties related to sickness and diseases in general, it may indirectly help individuals with specific phobias related to medical procedures or blood. The positive affirmations and suggestions can help promote a more positive mindset and reduce overall anxiety.`,
    open: false 
  },
  { 
    question: `Are there any side effects of listening to the Fear of Sickness and Diseases Removal subliminal?`, 
    answer: `No, there are no known side effects associated with listening to this subliminal audio. However, if you have any concerns about your mental health or specific phobias, it is recommended to consult with a mental health professional.`,
    open: false 
  },
  { 
    question: `Can the Fear of Sickness and Diseases Removal subliminal replace therapy?`, 
    answer: `The Fear of Sickness and Diseases Removal subliminal can be a helpful complement to therapy, but it is not a substitute for professional medical or mental health treatment. It is designed to support individuals in overcoming fears and anxieties related to sickness and diseases, but for deeper or specific issues, it is important to seek guidance from a qualified professional.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>