<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Social media addiction can have a negative impact on various aspects of your life, from productivity to mental well-being. This subliminal is designed to help you break free from the addictive patterns and regain control over your use of social media."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help:'
    },
    {
      type: 'list',
      items: [
        "Reducing the urge to constantly check social media platforms.",
        "Increasing your ability to focus on important tasks without distractions.",
        "Improving your overall productivity and time management skills.",
        "Promoting a healthier balance between online and offline activities.",
        "Boosting your self-discipline and strengthening your willpower."
      ]
    },
    {
      type: 'paragraph',
      text: "Using this subliminal regularly can reprogram your subconscious mind, making it easier to resist the addictive pull of social media. You may find yourself naturally gravitating towards more meaningful activities and experiences, enhancing your overall well-being and relationships. Remember, breaking free from social media addiction takes time and effort, but with consistent use of this subliminal, you can regain control of your life and productivity."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Overcoming social media addiction can be challenging, but with the help of this subliminal, you can regain control of your life and productivity. Before you start listening, here are some tips to prepare yourself:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your addiction: Acknowledge the negative impact social media has had on your life, and identify the areas you want to improve.",
        "Set boundaries: Decide on specific time limits for social media usage and establish guidelines to prevent excessive scrolling.",
        "Create alternative activities: Prepare healthy alternatives to engage in when the urge to use social media arises, such as reading, exercising, or spending quality time with loved ones.",
        "Maintain focus: While listening to the subliminal, remind yourself of your desire to break free from social media addiction and regain control over your life."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal is designed to help you rewire your subconscious mind and develop healthier habits around social media usage. As you listen to the subliminal regularly, you may find yourself becoming more aware of the detrimental effects of social media addiction and feeling empowered to make positive changes. Embrace this opportunity for personal growth and take the first step towards regaining control of your life and productivity."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Social Media Addiction Removal subliminal! By taking this step towards breaking free from the grip of social media addiction, you are on your way to regaining control of your life and productivity. Take a moment to reflect on your journey and embrace the positive changes ahead."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Create alternative activities: Find new hobbies or engage in activities that bring you joy and fulfillment.",
        "Set boundaries: Establish limits on your social media usage and create a healthy balance in your life.",
        "Connect with loved ones: Spend quality time with friends and family to strengthen relationships outside of social media.",
        "Practice self-care: Prioritize self-care activities like exercise, meditation, and practicing gratitude."
      ]
    },
    {
      type: 'paragraph',
      text: "Breaking free from social media addiction is a process that requires perseverance and determination. Remember that you have the power to regain control of your time and attention. Stay committed to self-improvement, and embrace a life filled with meaningful connections and personal growth. Wishing you all the best on your journey!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Social Media Addiction Removal subliminal help me?`, 
    answer: `The Social Media Addiction Removal subliminal is designed to help you break free from the detrimental effects of social media addiction and regain control of your life and productivity. By listening to the subliminal regularly, it can align your subconscious mind with healthier habits and behaviors, reduce cravings for excessive social media use, and promote a more balanced and fulfilling lifestyle.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Social Media Addiction Removal subliminal?`, 
    answer: `Results can vary depending on the individual and their level of addiction. Some people may notice positive changes in their behaviors and mindset towards social media within a few weeks, while others may require more time and consistent listening to fully overcome their addiction. We recommend listening to the subliminal daily and consistently for best results.`,
    open: false 
  },
  { 
    question: `Can the Social Media Addiction Removal subliminal replace professional help?`, 
    answer: `The subliminal is intended to be used as a tool to supplement your efforts in overcoming social media addiction, not as a substitute for professional help. If you believe your addiction is severe or if it is causing significant distress in your life, we recommend seeking professional assistance from a therapist or counselor who specializes in addiction.`,
    open: false 
  },
  { 
    question: `How can I make the Social Media Addiction Removal subliminal more effective?`, 
    answer: `In addition to listening to the subliminal, it can be helpful to set limits on your social media usage, create a supportive network of friends or family, find alternative activities to engage in, and practice self-care and mindfulness techniques. Journaling and identifying triggers or patterns associated with your addiction can also assist in breaking the cycle and reprogramming your mindset.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Social Media Addiction Removal subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your addiction or mental health, please consult with a healthcare professional or addiction specialist.`,
    open: false 
  },
  { 
    question: `Will the Social Media Addiction Removal subliminal completely eliminate my desire to use social media?`, 
    answer: `The goal of the Social Media Addiction Removal subliminal is to help you regain control over your social media usage and develop healthier habits. While it can reduce cravings and promote a more balanced lifestyle, individual results may vary. It is important to combine the subliminal with practical steps and strategies to create lasting change.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>