export const subscription = {
  namespaced: true,
  state: {
    id: 0,
    data: [],
  },
  getters: {},
  actions: {
    getId({ commit }, data) {
      commit("getId", data);
    },
    getData({ commit }, data) {
      commit("getData", data);
    },
  },
  mutations: {
    getId(state, data) {
      state.id = data;
    },
    getData(state, data) {
      state.data = data;
    },
  },
};
