<template>
  <Dialog :dialog="dialog" :width="800" :title="`Edit Subliminal`" @closeDialog="closeDialog">
    <div>
      <v-row>
        <v-col cols="8">
          <p class="mb-1">Title</p>
          <v-text-field solo dense hide-details class="mb-3" placeholder="Subliminal" v-model="title"></v-text-field>
        </v-col>
       
        <v-col cols="2">
          <p class="mb-1">Visible</p>
          <v-checkbox v-model="isVisible" class="ma-0" style="padding-top: 8px"></v-checkbox>
        </v-col>
      </v-row>

      <p class="mb-1">Description</p>
      <v-textarea solo dense hide-details class="mb-3" placeholder="Description" v-model="description"
        rows="3"></v-textarea>
      
      <p class="mb-0">Volume</p>
      <v-slider v-model="volume" :max="100" hide-details>
        <template v-slot:prepend>
          <v-text-field solo dense v-model="volume" type="number" style="width: 70px" hide-details
            disabled></v-text-field>
        </template>
      </v-slider>
     
      <v-row class="ma-0 mt-2" style="gap: 20px">
        <v-col class="pa-0">
          <p class="mb-1">Subscription Name</p>
          <v-autocomplete v-model="subscription" :items="subscriptions" solo multiple small-chips dense
            color="blue-grey lighten-2" label="Subscriptions" hide-details class="mb-0" item-text="name"
            item-value="id" />
        </v-col>
        <v-col class="pa-0">
          <p class="mb-1">Audio Type</p>
          <v-autocomplete v-model="audiotype" :items="audiotypes" solo dense color="blue-grey lighten-2"
          label="Audio Type" hide-details item-text="name" item-value="id" />
        </v-col>
      </v-row>
    </div>

    <template v-slot:action>
      <v-row class="ma-0">
        <v-col class="pl-0 pr-2">
          <v-btn block :disabled="disabled" @click="submitDialog()">Update</v-btn>
        </v-col>
        <v-col class="pl-2 pr-0">
          <v-btn block @click="closeDialog()">Cancel</v-btn>
        </v-col>
      </v-row>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialog.vue'

import { getMessage } from '@/utils'
import { updateTracksToSubliminals } from '@/api/subliminalinfo.js'
import { updateTracks } from '@/api/track.js'
import { getMoods } from '@/api/mood.js'
import { getSubscriptions } from '@/api/subscription.js'
import { deleteLocalImage } from '@/api/file.js'

export default {
  props: {
    dialog: {
      type: Boolean
    },
    data: {
      type: Object
    }
  },
  components: {
    Dialog,
  },
  data: () => {
    return {
      getMessage,
      getSubscriptions,
      updateTracksToSubliminals,
      updateTracks,

      isfeatured: true,
      isVisible: true,

      subscription: [],

      image: null,
      title: null,
      version: null,
      volume: null,
      description: null,
      track_id: null,
      id: null,
      subliminal_id: null,
      audiotype: null,

    }
  },
  created() {
    this.getData()
  },
  computed: {
    subscriptions() {
      return this.$store.state.subscription.data;
    },
    audiotypes() {
      return this.$store.state.audio.types
    },
    disabled() {
      return (
        this.title == "" ||
        this.title == null 
      )
    }
  },
  methods: {
    getData() {
      this.subscription = []

      this.title = this.data.title
      this.isVisible = this.data.is_visible
      this.description = this.data.description
      this.track_id = this.data.track_id
      this.version = this.data.version
      this.volume = this.data.volume
      this.audiotype = this.data.audio_type_id
      this.subliminal_id = this.data.subliminal_id
      this.id = this.data.id

      const subscription = this.data.subscription_id.split(',')

      subscription.forEach((item) => {
        this.subscription.push(parseInt(item))
      })

    },

    resetDialog() {
      this.title = null
      this.isVisible = null
      this.version = null
      this.volume = null
      this.track_id = null
      this.id = null
    },
    closeDialog() {
      this.$emit('closeDialog', false)
      this.resetDialog()
    },
    submitDialog() {
      const data = {
        volume: this.volume,
        version: this.version,
        audio_type_id: this.audiotype,
        subliminal_id: this.subliminal_id,
        track_id: this.track_id,
        id: this.id,
      }
  
      const data2 = {
        id: this.id,
        track_id: this.track_id,
        description: this.description,
        is_visible: this.isVisible,
        title: this.title,
        subscription_id: this.subscription.join(','),
        audio_type_id: this.audiotype,
      }

      this.updateTracks(data2).then((res) => {
      })
      this.updateTracksToSubliminals(data).then((res) => {
        const subliminal = res.data
        this.$store.dispatch("subliminal/getData", subliminal)
        this.getMessage(this, res)
        this.closeDialog()
      })
    }
  }
}
</script>
