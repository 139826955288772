<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you have a fear or phobia of doctors and medical visits, you're not alone. Many people experience anxiety and discomfort when it comes to receiving medical care. This subliminal is designed to help you overcome your fear and build a more positive and relaxed attitude towards doctors and medical appointments."
    },
    {
      type: 'heading',
      text: 'Benefits of using this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Feeling more calm and relaxed before and during medical appointments.",
        "Reducing anxiety and fear associated with doctors and medical procedures.",
        "Building a more positive and trusting relationship with healthcare providers.",
        "Improving your overall health and well-being by seeking necessary medical care without fear.",
        "Developing coping strategies and tools to manage any discomfort or anxiety that may arise."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind to let go of fear and embrace a more positive and empowering mindset towards medical visits. Over time, you may find that your anxiety decreases, and you are able to approach medical care with greater ease and confidence. Remember, everyone's journey is unique, so be patient and kind to yourself as you work towards overcoming your fear."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "If you struggle with a fear or phobia of doctors or medical visits, this subliminal can provide support and help you on your journey towards overcoming it. Here are some suggestions to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your fear: Recognize that it's normal to feel anxious or fearful, and remember that this subliminal is meant to assist you in addressing and overcoming those emotions.",
        "Identify the root cause: Reflect on any specific experiences or triggers that contribute to your fear, as understanding the source can be beneficial for your healing process.",
        "Visualize positive outcomes: Imagine yourself experiencing a calm and positive visit to the doctor's office, allowing yourself to feel safe and relaxed.",
        "Stay focused: Prior to and during the listening session, keep your intention of overcoming your fear at the forefront of your mind."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen regularly to this subliminal, you may notice a gradual reduction in anxiety and an increase in feelings of safety and comfort when it comes to doctor visits. Embrace this opportunity to address your fears and prioritize your health and well-being. Take a deep breath, set your intention, and let's embark on this journey together."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Fear of Doctors and Doctor Phobia Removal subliminal session! By confronting your fear and phobia of doctors, you have taken a significant step towards prioritizing your health and well-being. Now that you have listened to the session, it is important to reflect on your experience and continue on your journey of overcoming this fear."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your experience: Consider writing down any thoughts or emotions that arose during the session in a journal.",
        "Take small steps: Gradually expose yourself to medical-related situations, starting with less intimidating scenarios.",
        "Seek support: Reach out to a trusted friend or family member for encouragement and assistance in overcoming your fear.",
        "Consider therapy: If your fear of doctors persists, consider seeking professional help from a therapist or counselor specializing in phobias."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming fear takes time and persistence. Be patient with yourself and celebrate each small victory as you gradually build your confidence in visiting doctors. Your health and well-being are worth it, and with time, you can overcome this fear and ensure you receive the medical care you need. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What is doctor phobia and fear of doctors?`, 
    answer: `Doctor phobia, also known as iatrophobia or fear of doctors, is an intense and irrational fear or anxiety related to medical professionals or medical visits. It can cause significant distress and may lead to avoidance of necessary medical care. This fear is often rooted in past negative experiences, fear of medical procedures, or a general fear of the unknown in a medical setting.`,
    open: false
  },
  { 
    question: `How can the Fear of Doctors and Doctor Phobia Removal subliminal help me?`, 
    answer: `The Fear of Doctors and Doctor Phobia Removal subliminal is designed to target and reprogram the subconscious mind, helping you overcome your fear or phobia of doctors and medical visits. By listening to the subliminal, you can gradually change your perception and emotions associated with doctors, creating a more positive and calm mindset towards medical care.`,
    open: false 
  },
  { 
    question: `How long does it take to see results from the Fear of Doctors and Doctor Phobia Removal subliminal?`, 
    answer: `Results can vary from person to person. Some individuals may start noticing positive changes in their mindset and emotions towards doctors and medical visits after listening to the subliminal for several weeks or even sooner. Consistency is key, and regular listening can help reinforce the desired changes in your subconscious mind.`,
    open: false 
  },
  { 
    question: `Can the Fear of Doctors and Doctor Phobia Removal subliminal cure my fear or phobia instantly?`, 
    answer: `While the Fear of Doctors and Doctor Phobia Removal subliminal can help you change your mindset and emotions towards doctors over time, it may not instantly cure your fear or phobia. Overcoming a fear or phobia often requires a comprehensive approach, including therapy, exposure techniques, and self-reflection. The subliminal can be used as a helpful tool to support your overall effort to conquer your fear.`,
    open: false 
  },
  { 
    question: `Will I still have caution and anxiety around doctors after using the Fear of Doctors and Doctor Phobia Removal subliminal?`, 
    answer: `The Fear of Doctors and Doctor Phobia Removal subliminal aims to help you develop a more positive and calm mindset towards doctors and medical visits. While caution and anxiety are natural emotions, the subliminal can help reduce the intensity and irrationality of your fear or phobia, allowing you to approach medical care with greater ease and peace of mind.`,
    open: false 
  },
  { 
    question: `Can I stop going to therapy if I listen to the Fear of Doctors and Doctor Phobia Removal subliminal?`, 
    answer: `The Fear of Doctors and Doctor Phobia Removal subliminal can be used as a supplemental tool to support your journey towards overcoming your fear or phobia. However, it is important to consult with a mental health professional to determine if therapy is still necessary for your specific situation. They can provide personalized guidance and support to address your individual needs.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>