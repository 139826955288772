<template>
  <div class="ribbon">
    <span class="ribbon2">
      <v-icon size="24" :class="`mt-2 mb-1`" color="grey-darken-2"
        @click.stop="liked == 1 ? removeLiked() : addLiked()">{{ icon }}</v-icon>
    </span>
  </div>
</template>

<script>
import { addLikePlaylist, addLikeSubliminal, deleteLikePlaylist, deleteLikeSubliminal } from '@/api/favorite'
export default {
  props: {
    data: {
      type: Object
    }
  },
  data: () => {
    return {
      addLikePlaylist,
      addLikeSubliminal,
      deleteLikePlaylist,
      deleteLikeSubliminal,

      liked: false
    }
  },
  computed: {
    icon() {
      return this.liked ? 'mdi-star' : 'mdi-star-outline'
    },
    user() {
      return JSON.parse(this.$store.state.user.info)
    }
  },
  mounted() {
    let data = JSON.parse(localStorage.getItem('user_track'))
    if (Object.keys(this.data).length > 0) {
      this.liked = this.data.is_liked
    } else {
      this.liked = data.is_liked
    }
  },
  methods: {
    addLiked() {
      let data = this.data
      data.user_id = this.user.user_id
      if (data.hasOwnProperty('subliminal_id')) {
        this.addLikeSubliminal(data).then(res => {
          this.liked = true
          this.$emit('updateLiked', true)
        })
      }

      if (data.hasOwnProperty('playlist_id')) {
        this.addLikePlaylist(data).then(res => {
          this.liked = true
          this.$emit('updateLiked', true)
        })
      }
    },
    removeLiked() {
      const data = this.data
      data.user_id = this.user.user_id
      if (data.hasOwnProperty('subliminal_id')) {
        this.deleteLikeSubliminal(data).then(res => {
          this.liked = false
          this.$emit('updateLiked', false)
        })
      }

      if (data.hasOwnProperty('playlist_id')) {
        this.deleteLikePlaylist(data).then(res => {
          this.liked = false
          this.$emit('updateLiked', false)
        })
      }
    }
  }
}
</script>

<style scoped>
.ribbon {
  width: 70px;
  position: relative;
  background-size: cover;
  text-transform: uppercase;
  color: white;

  position: absolute;
  top: 0px;
  right: 0px;
}

.ribbon2 {
  width: 29.5px;
  padding: 2px 0;
  position: absolute;
  top: -6px;
  left: 25px;
  text-align: center;
  border-top-left-radius: 3px;
  background: #fbc02d;
}

.ribbon2:before {
  height: 0;
  width: 0;
  right: -5.5px;
  top: 0.1px;
  border-bottom: 6px solid #8D5A20;
  border-right: 6px solid transparent;
}

.ribbon2:before,
.ribbon2:after {
  content: "";
  position: absolute;
}

.ribbon2:after {
  height: 0;
  width: 0;
  bottom: -14px;
  left: 0;
  border-left: 15.25px solid #fbc02d;
  border-right: 15.25px solid #fbc02d;
  border-bottom: 16px solid transparent;
}
</style>