<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Embracing minimalist living can have a profound impact on your life, allowing you to create a more intentional and simplified existence. This subliminal is designed to support you in simplifying your surroundings, decluttering your space, and focusing on what truly matters."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help:'
    },
    {
      type: 'list',
      items: [
        "Encouraging you to let go of material possessions that no longer serve a purpose.",
        "Inspiring you to declutter your living space and create a clean and organized environment.",
        "Helping you develop a mindset of contentment and gratitude for what you already have.",
        "Encouraging you to prioritize experiences and relationships over material possessions.",
        "Supporting you in finding joy and fulfillment in simplicity and minimalism."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you may find yourself naturally drawn to a more minimalist lifestyle. You may begin to appreciate the freedom and peace of mind that comes with letting go of unnecessary clutter. Remember, the journey to minimalist living is unique for everyone, so embrace the changes at your own pace and enjoy the transformative process."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embracing a minimalist lifestyle can help bring clarity, ease, and focus to your everyday life. This subliminal is designed to support your journey towards a more intentional and simplified existence. Here are some preparations you can make before you begin:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Reflect on why you want to adopt a minimalist lifestyle and what you hope to achieve.",
        "Declutter your space: Clear out any unnecessary items and create a clean and organized environment.",
        "Define your values: Identify what truly matters to you and what you want to prioritize in your life.",
        "Visualize your ideal minimalist lifestyle: Imagine how it would feel to have a clutter-free, intentional life."
      ]
    },
    {
      type: 'paragraph',
      text: "As you embark on your minimalist journey with this subliminal, remember that it is a process and not an overnight transformation. Be patient, embrace simplicity, and focus on what truly brings you joy and fulfillment. Let go of the unnecessary, and create space for what matters most. Now, take a deep breath, set your intention, and let's begin this transformative experience."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Minimalist Living subliminal! By exploring minimalist living, you've taken a step towards creating a more intentional and simplified life. Now that you've learned strategies to simplify your surroundings and declutter your space, it's time to reflect on your journey and implement positive changes."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Assess your belongings: Evaluate your possessions and consider what truly adds value and joy to your life.",
        "Declutter your space: Start small by tackling one area at a time, letting go of items that no longer serve a purpose or bring you joy.",
        "Minimize distractions: Create an environment that fosters focus and clarity by limiting unnecessary stimuli.",
        "Focus on experiences: Shift your focus from acquiring material possessions to investing in meaningful experiences and relationships."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, minimalist living is a continuous journey of intentional choices. Embrace the process of simplifying your life and focus on what truly matters to you. By eliminating the excess and prioritizing what brings you joy, you'll cultivate a more fulfilling and intentional life. Enjoy the freedom that comes with a minimalist approach!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What is minimalist living?`, 
    answer: `Minimalist living is a lifestyle approach that involves intentionally simplifying your life by focusing on the things that truly bring you joy and value. It entails decluttering your living space, reducing your possessions to essentials, and prioritizing experiences and relationships over material possessions. It is a way to free yourself from the burden of excess and create more space for what truly matters to you.`,
    open: false
  },
  { 
    question: `How can minimalist living benefit me?`, 
    answer: `Minimalist living can bring numerous benefits to your life. By decluttering your space and simplifying your surroundings, you create a more peaceful and organized environment. This can help reduce stress, increase productivity, and improve your overall well-being. Minimalist living also encourages mindful consumption, helping you save money, reduce waste, and live a more sustainable lifestyle. It can also free up time and energy to focus on your passions and relationships.`,
    open: false 
  },
  { 
    question: `How do I start practicing minimalist living?`, 
    answer: `Starting minimalist living involves assessing your current possessions and lifestyle to determine what truly adds value and what can be let go. Begin by decluttering one area at a time, such as a closet or a room, and ask yourself if each item brings you joy or serves a purpose. Gradually adopt a more mindful approach to consumption, only bringing new items into your life that align with your priorities and values. Remember, minimalist living is a personal journey, so take it at your own pace and embrace the freedom of letting go.`,
    open: false 
  },
  { 
    question: `Is minimalist living for everyone?`, 
    answer: `Minimalist living is a personal lifestyle choice, and it may not be suitable for everyone. It is important to recognize that minimalism looks different for each individual, and there is no one-size-fits-all approach. However, anyone can benefit from adopting certain minimalist principles, such as decluttering, mindful consumption, and prioritizing what truly matters.`,
    open: false 
  },
  { 
    question: `What are some tips for maintaining a minimalist lifestyle?`, 
    answer: `To maintain a minimalist lifestyle, it is essential to regularly reassess your possessions and let go of anything that no longer serves a purpose or brings you joy. Avoid impulsive shopping and be mindful of what you bring into your space. Create organizing systems and designate specific homes for your belongings to avoid clutter. Embrace the practice of gratitude and focus on experiences rather than material possessions. Remember that minimalist living is an ongoing process, and it is okay to make adjustments along the way to suit your evolving needs and values.`,
    open: false 
  },
  { 
    question: `Can minimalist living make me happier?`, 
    answer: `Minimalist living can contribute to increased happiness and well-being by reducing clutter, simplifying your surroundings, and allowing you to focus on what truly brings you joy and fulfillment. By eliminating distractions and excess, you can create space and clarity in your life, leading to a greater sense of contentment and satisfaction.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>