<template>
  <section>
    <div class="container1">
      <div style>
        <h1 class="mt-5">Unleash your minds potential</h1>
        <h5 class="mb-7">Sign up to start listening.</h5>
        <div class="wraps">
          <div class="inputs d-flex gap-2">
            <div style="width: 50%">
              <h5 v-show="firstName != ''" class="h51">
                <span>First Name</span>
                <span class="input-invalid">{{ firstName_valid }}</span>
              </h5>
              <v-text-field
                flat
                solo
                placeholder="First Name"
                v-model="firstName"
                class="shrink"
              ></v-text-field>
            </div>
            <div style="width: 50%">
              <h5 v-show="lastName != ''" class="h51">
                <span>Last Name</span>
                <span class="input-invalid">{{ lastName_valid }}</span>
              </h5>
              <v-text-field
                flat
                solo
                placeholder="Last Name"
                v-model="lastName"
                class="shrink"
              ></v-text-field>
            </div>
          </div>
          <div class="inputs">
            <v-divider class="divider"></v-divider>
            <h5 v-show="email != ''" class="h51">
              <span>Email</span>
              <span class="input-invalid">{{ email_valid }}</span>
            </h5>
            <v-text-field
              flat
              solo
              placeholder="E-mail Address"
              v-model="email"
              class="shrink"
            ></v-text-field>
          </div>
          <div class="inputs">
            <v-divider class="divider"></v-divider>
            <h5 v-show="password != ''" class="h51">
              <span>Password</span>
              <span class="input-invalid">{{ password_valid }}</span>
            </h5>
            <v-text-field
              flat
              solo
              class="shrink"
              :type="showPassword ? 'text' : 'password'"
              placeholder="Password"
              v-model="password"
              @click:append="showPassword = !showPassword"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            ></v-text-field>
          </div>
          <div class="inputs">
            <v-divider class="divider"></v-divider>
            <h5 v-show="passwordConfirm != ''" class="h51">
              <span>Confirm Password</span>
              <span class="input-invalid">{{ passwordConfirm_valid }}</span>
            </h5>
            <v-text-field
              flat
              solo
              class="shrink"
              :type="showPasswordConfirm ? 'text' : 'password'"
              placeholder="Confirm Password"
              v-model="passwordConfirm"
              @click:append="showPasswordConfirm = !showPasswordConfirm"
              :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
            ></v-text-field>
          </div>
        </div>
        <div class="wraps2 d-flex">
          <div class="d-flex align-center">
            <input class="check" type="checkbox" v-model="termsAndConditions" />
            <p class="mt-4">
              <span>I have read and accept the </span>
              <span @click="dialog = true" class="forgot"
                >Terms and Conditions</span
              >
            </p>
          </div>
        </div>
        <div
          style="width: max-content; margin: auto"
          class="justify-center align-center mt-0"
        >
          <p v-show="message != ''" medium class="message">{{ message }}</p>
        </div>
        <button
          class="button btn btn-primary btn-lg"
          :disabled="!isRegisterReady"
          @click="register()"
        >
          SIGN UP
        </button>

        <div class="separator">
          <div class="line"></div>
          <p class="or">Or register with</p>
          <div class="line"></div>
        </div>
        <div class="d-flex justify-center align-center">
          <div class="ma-2">
            <v-btn
              block
              :href="`${url}/api/login/google`"
              medium
              style="background: white; border-radius: 10px"
              class="d-flex button justify-center align-center"
            >
              <img
                class="google mr-2"
                src="../../../assets/images/main/login/google.png"
              />
              <p style="color: #427ab3; margin: auto; width: 100px">Google</p>
            </v-btn>
          </div>
          <div class="ma-2">
            <v-btn
              block
              :href="`${url}/api/login/facebook`"
              medium
              style="background: white; border-radius: 10px"
              class="d-flex button justify-center align-center"
            >
              <img
                class="fb mr-2"
                src="../../../assets/images/main/login/fb.png"
              />
              <p style="color: #427ab3; margin: auto; width: 100px">Facebook</p>
            </v-btn>
          </div>
        </div>
        <h6>
          <span class="mt-5">Already have an account? </span>
          <span @click="goToRoute('/login')" class="sign-up mt-5">Sign in</span>
        </h6>
      </div>
    </div>
    <Dialog
      :dialog="dialog"
      :width="1000"
      :title="title"
      @closeDialog="closeDialog"
    >
      <div style="overflow-x: hidden; height: 100vh">
        <div class="content">
          <h2 class="maintitle">Terms & Conditions</h2>
          <div class="faqs">
            <v-expansion-panels focusable v-model="expansion">
              <v-expansion-panel v-for="(faq, i) in items" :key="i">
                <v-expansion-panel-header class="subtitle">{{
                  faq.title.toUpperCase()
                }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p
                    class="mt-3 mb-0 text-justify answer"
                    v-html="faq.description"
                  ></p>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </div>
      </div>
      <template v-slot:action>
        <v-row class="text-center" style="margin-top: -20px">
          <v-col>
            <button
              class="button btn btn-primary btn-lg"
              @click="acceptTermsAndConditions"
            >
              Accept
            </button>
          </v-col>
        </v-row>
      </template>
    </Dialog>
  </section>
</template>

<script>
import { url, getMessage, loginQueryParams, generateToken } from "@/utils";
import { checkUrl } from "../../../utils";
import { userSignUp, userCheckEmail } from "@/api/user.js";
import { createPaymentLink, createPayment } from "@/api/payment";
import { addPromoCodeManual } from "@/api/promocode";
import { getSubscriptionByName } from "@/api/subscription";
import { userSignIn } from "@/api/user";
import Dialog from "@/components/Dialog";
import Card from "@/components/Card.vue";
import { getMagus } from "@/api/magusdetails";

export default {
  components: {
    Card,
    Dialog,
  },
  data: () => {
    return {
      getMessage,
      userSignUp,
      userCheckEmail,
      createPayment,
      createPaymentLink,
      generateToken,
      getMagus,
      loginQueryParams,
      addPromoCodeManual,
      url,
      getSubscriptionByName,

      title: null,
      dialog: false,
      items: [],

      expansion: "",
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      passwordConfirm: "",
      message: "",
      email_valid: "",
      firstName_valid: "",
      lastName_valid: "",
      password_valid: "",
      passwordConfirm_valid: "",

      termsAndConditions: false,

      showPassword: false,
      showPasswordConfirm: false,
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    this.$store.dispatch("screen/getLoading", false);
  },
  watch: {
    email(value) {
      if (value != "") {
        this.message = "";
        this.validateEmail(value);
      }
    },
    password(value) {
      if (value != "") {
        this.message = "";
        this.validatePassword(value);
        if (this.passwordConfirm != "") {
          this.passwordMatch();
        }
      }
    },
    passwordConfirm(value) {
      this.passwordMatch();
    },
  },
  computed: {
    isUser() {
      return this.userType == "user";
    },
    userType() {
      return this.loginQueryParams();
    },
    isRegisterReady() {
      return this.checkForm();
    },
  },
  methods: {
    getData() {
      const data = {
        title: "Terms and Condition",
      };
      this.getMagus(data).then((res) => {
        this.items = res.data[0].info;
      });
    },
    acceptTermsAndConditions() {
      this.dialog = false;
      this.termsAndConditions = true;
    },
    validateEmail(email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        this.email_valid = "";
      } else {
        this.email_valid = "Email is not valid";
      }
    },
    validatePassword(password) {
      if (password.length < 8) {
        this.password_valid = "Min 8 characters";
      } else {
        if (/[0-9]/.test(password)) {
          if (/[a-z]/.test(password)) {
            if (/[A-Z]/.test(password)) {
              this.password_valid = "";
            } else {
              this.password_valid = "At least one uppercase letter";
            }
          } else {
            this.password_valid = "At least one lowercase letter";
          }
        } else {
          this.password_valid = "At least one number";
        }
      }
    },
    goToRoute(route) {
      if (this.currentRoute != route) {
        this.$router.push(route).catch((err) => {});
      }
    },
    closeDialog() {
      this.dialog = false;
    },
    resetForm() {
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.password = "";
      this.passwordConfirm = "";
      this.termsAndConditions = false;
      this.$store.dispatch("screen/getLoading", false);
    },
    checkForm() {
      return (
        this.firstName != "" &&
        this.lastName != "" &&
        this.email != "" &&
        this.password != "" &&
        this.passwordConfirm != "" &&
        this.termsAndConditions != false
      );
    },
    passwordMatch() {
      if (this.password != this.passwordConfirm) {
        this.passwordConfirm_valid = "Password does not match";
      } else {
        this.passwordConfirm_valid = "";
      }
    },
    register() {
      this.$store.dispatch("screen/getLoading", true);
      const data = { email: this.email };
      this.userCheckEmail(data).then((res) => {
        const { success } = res;
        if (success) {
          res.success = false;
          res.message = "E-mail is already used";

          this.email = "";
          this.getMessage(this, res);
          this.$store.dispatch("screen/getLoading", false);
        } else {
          const params = {
            route: this.$route,
            router: this.$router,
            tempname: "subscription_temp",
          };
          checkUrl(params);
          if (localStorage.getItem("subscription_temp") != null) {
            const params = localStorage.getItem("subscription_temp");
            const decrypted = atob(params);
            const data = JSON.parse(decrypted);
            data.first_name = this.firstName;
            data.last_name = this.lastName;
            data.name = this.firstName + " " + this.lastName;
            data.email = this.email;
            data.password = this.password;
            data.password_confirmation = this.passwordConfirm;
            data.time_created = new Date();

            let paymentData = { ...data };
            this.userSignUp(data).then((res) => {
              const { success, data } = res;
              if (success) {
                localStorage.setItem("user_token", data.token);
                localStorage.setItem("user", JSON.stringify(data.user));
                localStorage.removeItem("subscription_temp");
              }
              //this.getMessage(this, res);
              if (paymentData.amount != 0) {
                paymentData.user_id = data.user.user_id;

                this.createPaymentLink(paymentData).then((res) => {
                  const checkout_url = res.attributes.checkout_url;
                  paymentData.payment_id = res.attributes.reference_number;
                  paymentData.transaction_code =
                    res.attributes.reference_number;

                  this.getSubscriptionByName(paymentData.subscription).then(
                    (res) => {
                      paymentData.subscription_id = res[0].id;
                      this.addPromoCodeManual(paymentData);
                      this.createPayment(paymentData);
                    }
                  );

                  window.location.href = checkout_url;
                });
                return;
              }

              // this.$router.push('/user')
              this.$router.push("/user/mood");
            });
          } else {
            const params = JSON.stringify({
              first_name: this.firstName,
              last_name: this.lastName,
              name: this.firstName + " " + this.lastName,
              email: this.email,
              password: this.password,
              password_confirmation: this.passwordConfirm,
              time_created: new Date(),
            });
            this.$router.push("price");
            localStorage.setItem("register_temp", btoa(params));
            this.resetForm();
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.google {
  height: 30px;
}
.fb {
  height: 20px;
}
h1 {
  color: var(--ma-tufts-blue);
}
p,
.h51 {
  text-align: left;
  margin-left: 10px;
}
.divider {
  background: black;
  margin-left: 10px;
  margin-top: -10px;
  margin-right: 10px;
}
div.wraps {
  border: solid 0.5px #cecece;
  border-radius: 10px;
  flex-direction: column;
  width: 92%;
  margin-left: 4%;
  padding-top: 1em;
}
div.wraps2 {
  width: 92%;
  margin-left: 4%;
}
.forgot {
  cursor: pointer;
  color: var(--ma-tufts-blue);
  text-decoration: underline;
}
.inputs {
  margin-bottom: -10px;
}
.input-invalid {
  color: red;
  margin-left: 15px;
  font-size: 15px;
  font-weight: 600;
}
.message {
  background: #ffacac !important;
  color: black;
  padding: 10px;
  font-family: "Montserrat-Regular";
  text-align: center;
  border-radius: 10px;
  margin-left: -2px;
}
.separator {
  display: flex;
  text-align: center;
  align-items: center;
}
.separator .line {
  height: 0.5px;
  flex: 1;
  background-color: #272727;
}
.or {
  margin: 10px;
  text-align: center;
  font-family: "Montserrat-Regular";
}
h6 {
  font-family: "Montserrat-Regular";
  margin: 20px;
}
.sign-up {
  text-decoration: underline;
  color: #427ab3;
  font-family: "Montserrat-Bold";
  cursor: pointer;
}
.content {
  padding-inline: 20px;
  margin-bottom: 10px;
  @media screen {
    @media (min-width: 1000px) {
      margin-bottom: 10px;
      padding-inline: 50px;
    }
  }
}
.maintitle {
  font-family: "Montserrat-Bold" !important;
  color: var(--ma-tufts-blue);
  margin-top: 10px !important;
}

@media screen and (min-width: 80rem) {
  section:nth-of-type(1) > .container1 {
    @media screen and (min-width: 1400px) {
      background-image: url("~@/assets/images/main/login/1.png");
      background-repeat: no-repeat;
      background-size: 50%;
      background-position: left;
    }

    @media screen and (min-width: 1400px) {
      padding: 2em;
      background-position: left;
      & > div {
        width: 50%;
        margin-right: 0;
        margin-left: auto;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }
  }
}
</style>
