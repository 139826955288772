<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Breaking free from smoking addiction is a challenging but highly rewarding journey. This subliminal is designed to support and empower you as you work towards living a smoke-free and healthier life."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help you:"
    },
    {
      type: 'list',
      items: [
        "Reducing cravings and the desire to smoke, making it easier to resist the urge.",
        "Replacing smoking habits with healthier coping mechanisms and stress-relief strategies.",
        "Boosting your willpower, motivation, and determination to quit smoking for good.",
        "Building a stronger, more positive mindset that supports a smoke-free lifestyle.",
        "Increasing your confidence and belief in your ability to quit and stay smoke-free."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal consistently, you can reprogram your subconscious mind to break free from the grip of smoking addiction. It may take time and effort, but with each listen, you're paving the way to a healthier, smoke-free future. Remember, you have the power to quit and live a life free from smoking."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Quitting smoking can be one of the most challenging but rewarding steps towards a healthier life. This subliminal is designed to support you in overcoming your smoking addiction and embracing a smoke-free lifestyle. Before you start listening, consider these tips to help you along the way:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Find your motivation: Identify your reasons for wanting to quit smoking, such as improving your health, saving money, or setting a positive example for loved ones.",
        "Create a support system: Seek encouragement from friends, family, or support groups who can provide understanding and accountability.",
        "Remove triggers: Get rid of cigarettes, lighters, and any other smoking-related items to minimize temptation.",
        "Visualize a smoke-free future: Picture yourself living a healthier, smoke-free life and enjoying the benefits that come with it."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal, let the positive affirmations work on your subconscious mind, empowering you to break free from smoking addiction. Remember, quitting smoking is a journey, and every step you take brings you closer to a smoke-free and healthier life. Stay committed, stay strong, and let's begin this transformative journey together."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Smoking Addiction Removal subliminal session! You have made a significant decision to overcome your smoking addiction and embark on a journey towards a smoke-free and healthier life. Now, it's time to reflect on your experience and take steps to support your ongoing success."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your progress: Celebrate each milestone and remind yourself how far you've come.",
        "Create a support system: Surround yourself with understanding and encouraging individuals who can help you stay on track.",
        "Identify triggers: Be aware of situations, emotions, or habits that may tempt you to smoke and develop strategies to manage them.",
        "Practice self-care: Engage in activities that promote relaxation, reduce stress, and help you stay focused on your smoke-free journey."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember that quitting smoking is a process that may have ups and downs. Stay committed to your decision, and don't be too hard on yourself if you slip up. With determination, support, and self-care, you can overcome your addiction and embrace a healthier, smoke-free life. You're on the right path!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Smoking Addiction Removal subliminal work?`, 
    answer: `The Smoking Addiction Removal subliminal works by targeting your subconscious mind to reprogram your beliefs and behaviors associated with smoking. It uses positive affirmations and suggestions to help you overcome cravings, reduce withdrawal symptoms, and develop a smoke-free mindset. As you listen regularly to the subliminal, it can gradually change your perception of smoking and empower you to live a healthier and smoke-free life.`,
    open: false
  },
  { 
    question: `How long does it take to see results with the Smoking Addiction Removal subliminal?`, 
    answer: `Results may vary depending on the individual and the level of their smoking addiction. Some people may experience positive changes and reduced cravings after just a few listens, while others may require more time and consistent listening to fully overcome their addiction. We recommend listening to the subliminal daily for best results and allowing it to work on a subconscious level over time.`,
    open: false 
  },
  { 
    question: `Is the Smoking Addiction Removal subliminal safe to use?`, 
    answer: `Yes, the Smoking Addiction Removal subliminal is safe to use. It contains positive affirmations and suggestions designed to support and retrain your subconscious mind. However, if you have any underlying health conditions or concerns, it is always advisable to consult with your healthcare provider before making any changes to your smoking cessation plan.`,
    open: false 
  },
  { 
    question: `Can the Smoking Addiction Removal subliminal be used in conjunction with other smoking cessation methods?`, 
    answer: `Yes, the Smoking Addiction Removal subliminal can be used alongside other smoking cessation methods such as nicotine replacement therapy or counseling. It can enhance your overall efforts to quit smoking by addressing the psychological aspects of addiction and helping you develop a positive mindset towards a smoke-free life. However, it is important to follow the guidance and advice of your healthcare provider or professional smoking cessation counselor alongside using the subliminal.`,
    open: false 
  },
  { 
    question: `How can I make the Smoking Addiction Removal subliminal more effective?`, 
    answer: `To make the Smoking Addiction Removal subliminal more effective, it is recommended to listen to it in a relaxed state, preferably with headphones, and in a quiet environment. Creating a support system or joining smoking cessation programs can also provide additional motivation and resources. It is important to have a strong desire and commitment to quit smoking while using the subliminal to maximize its effectiveness.`,
    open: false 
  },
  { 
    question: `Can the Smoking Addiction Removal subliminal help with long-term smoking cessation?`, 
    answer: `Yes, the Smoking Addiction Removal subliminal is designed to help you develop a smoke-free mindset and overcome your addiction to cigarettes in the long-term. By reprogramming your subconscious beliefs and attitudes towards smoking, the subliminal aims to support you in maintaining your commitment to a smoke-free and healthier life.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>