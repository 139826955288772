import { render, staticRenderFns } from "./118-fear-of-being-vulnerable-removal.vue?vue&type=template&id=3f48c05a&scoped=true&"
import script from "./118-fear-of-being-vulnerable-removal.vue?vue&type=script&lang=js&"
export * from "./118-fear-of-being-vulnerable-removal.vue?vue&type=script&lang=js&"
import style0 from "./118-fear-of-being-vulnerable-removal.vue?vue&type=style&index=0&id=3f48c05a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f48c05a",
  null
  
)

export default component.exports