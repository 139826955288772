<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Mindfulness training is a powerful tool for enhancing focus and awareness in your daily life. This subliminal is designed to help you cultivate mindfulness and be present in the moment, leading to a sense of tranquility and clarity."
    },
    {
      type: 'heading',
      text: 'Benefits of mindfulness training include:'
    },
    {
      type: 'list',
      items: [
        "Improved ability to focus and concentrate on tasks at hand.",
        "Reduced stress and anxiety levels, promoting overall well-being.",
        "Increased self-awareness and emotional intelligence.",
        "Enhanced ability to handle difficult emotions and situations with equanimity.",
        "Improved relationships and communication through active listening and empathy."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can train your subconscious mind to embrace mindfulness, making it an integral part of your daily life. As you become more present, you may notice improvements in your mental clarity, stress levels, and overall sense of well-being. Remember, like any skill, mindfulness takes practice, so be patient and persistent."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a mindfulness training journey can be transformative, helping you develop a heightened sense of focus, presence, and tranquility. This subliminal is designed to support your practice and deepen your experience. Before you start, consider these suggestions for preparation:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Find a quiet space: Choose a location where you can practice without distractions or interruptions.",
        "Set a dedicated time: Establish a consistent daily routine for your mindfulness practice.",
        "Set your intention: Clarify your goal for practicing mindfulness, whether it's reducing stress, increasing self-awareness, or cultivating inner peace.",
        "Create a comfortable posture: Find a position that allows you to be relaxed yet alert, whether sitting or lying down.",
        "Focus your attention: During the subliminal session, direct your attention to your breath or another focal point to enhance your mindfulness practice."
      ]
    },
    {
      type: 'paragraph',
      text: "As you engage with this subliminal on a regular basis, you may notice a greater ease in your ability to be present and a more profound sense of calm in your daily life. Use this opportunity to embrace the potential for growth and transformation that comes with mindfulness. Now, without further ado, let's begin this journey together!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Great job on completing the Mindfulness Training subliminal! By dedicating time to enhance your focus and awareness, you're taking important steps towards cultivating tranquility and clarity in your daily life. Now, it's time to reflect on your experience and continue your journey of mindfulness."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice daily mindfulness: Set aside a few minutes each day for meditation or mindfulness exercises.",
        "Stay present in the moment: Focus on being fully engaged in whatever you're doing, whether it's work, spending time with loved ones, or enjoying a hobby.",
        "Observe your thoughts and emotions: Take time to notice your thoughts and feelings without judgment or attachment.",
        "Find moments of stillness: Take short breaks throughout the day to breathe deeply and bring yourself into a state of calm and clarity."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, mindfulness is a practice that requires patience and consistency. Embrace the moments of tranquility and clarity that come with being fully present in the now. As you continue on your mindfulness journey, may you find a deeper sense of peace and fulfillment in your daily life."
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What is mindfulness training?`, 
    answer: `Mindfulness training is a practice that involves bringing attention to the present moment, paying attention to thoughts and feelings without judgment. It involves focusing on the sensations of the body, the breath, and the surrounding environment. Through regular practice, mindfulness training can increase self-awareness, reduce stress, and improve overall well-being.`,
    open: false
  },
  { 
    question: `How can mindfulness training benefit me?`, 
    answer: `Mindfulness training can benefit you in several ways. It can help reduce stress and anxiety, improve focus and concentration, enhance emotional regulation, promote better sleep, and cultivate a greater sense of well-being and happiness. By practicing mindfulness, you can develop a deeper understanding and acceptance of yourself and others, leading to improved relationships and a more fulfilling life.`,
    open: false 
  },
  { 
    question: `How do I start practicing mindfulness?`, 
    answer: `You can start practicing mindfulness by setting aside a few minutes each day for focused attention. Find a quiet and comfortable space where you can sit or lie down. Begin by bringing your attention to your breath and observe the sensations of each inhale and exhale. As thoughts arise, gently acknowledge them and return your focus to the breath. Over time, you can expand your practice by incorporating mindful awareness into other activities, such as eating, walking, or engaging in everyday tasks.`,
    open: false 
  },
  { 
    question: `Do I need any special equipment or resources for mindfulness training?`, 
    answer: `No, mindfulness training does not require any special equipment or resources. All you need is a quiet space and a willingness to be present in the moment. However, you may find it helpful to use a meditation cushion, yoga mat, or meditation app to support your practice and provide additional guidance.`,
    open: false 
  },
  { 
    question: `How long does it take to see the benefits of mindfulness training?`, 
    answer: `The benefits of mindfulness training can be experienced in different ways and at different times for each individual. Some people may notice immediate effects, such as increased relaxation or improved focus, while it may take others a longer period of regular practice to fully experience the benefits. Consistency and patience are key to reaping the rewards of mindfulness training.`,
    open: false 
  },
  { 
    question: `Can mindfulness training help with mental health issues?`, 
    answer: `Mindfulness training has been shown to be beneficial for individuals dealing with various mental health issues, such as anxiety, depression, and stress. It can help individuals develop healthier coping mechanisms, manage difficult emotions, and improve overall well-being. However, it is important to consult with a mental health professional for personalized guidance and treatment if you are experiencing severe or persistent mental health symptoms.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>