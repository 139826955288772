<template>
  <div>
    <AudioType />
  </div>
</template>

<script>
import AudioType from '@/views/Admin/AudioType/index.vue'
export default {
  components: {
    AudioType
  },
  data: () => {
    return {}
  }
}
</script>
