<template>
  <div v-if="discovers.length > 0">
    <h5>Discover</h5>
    <div
      :class="`playlist vstack gap-2 ${isPlayerAvailable ? 'with-player' : ''}`"
    >
      <div class="card p-2" v-for="(item, index) in discovers" :key="index">
        <div class="d-flex justify-content-between">
          <div
            class="information d-flex align-items-center gap-2"
            @click="getAudios(discover, index)"
          >
            <div class="cover" :style="`background: url(${item.cover});`"></div>
            <div class="details mx-2">
              <h6 class="mb-0 text-limit-1">{{ item.title }}</h6>
              <span class="mb-0 text-limit-3">
                {{ item.description }}
              </span>
            </div>
          </div>
          <div
            class="other-button d-flex align-items-center justify-content-end gap-3 p-2"
          >
            <!-- <Favorite :data="discover" :text="getText(item.subliminal_id)" /> -->
            <TestFavorite :data="item" />
            <UserEllipsis :data="item" />
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-end px-1"
        v-if="discoverLength > 3 && isRouteUserOverview"
      >
        <p class="mb-0 view-all" @click="goToRoute('discover')">View All</p>
      </div>
    </div>
  </div>
</template>

<script>
import Favorite from "@/components/User/Favorite.vue";
import TestFavorite from "@/components/User/UserFavorite.vue";
import UserEllipsis from "@/components/User/UserEllipsis.vue";
import { getText, toggleData } from "@/utils/favorite.js";

import { audio } from "@/mixins/audio";
import { mapGetters } from "vuex";

export default {
  mixins: [audio],
  props: {
    isFilter: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },
  data: () => {
    return {
      getText,
      toggleData,
    };
  },
  mounted() {},
  components: {
    Favorite,
    TestFavorite,
    UserEllipsis,
  },
  computed: {
    recentplayed() {
      return this.$store.state.recent.played.data;
    },
    discover() {
      return this.$store.state.discover.data;
    },
    discoverLength() {
      return this.discover.length;
    },
    discovers() {
      return this.isFilter ? this.discover : this.discover.slice(0, 3);
    },
    playlistTrack() {
      return this.$store.state.audio.playlist.tracks;
    },
    playlistType() {
      return this.$store.state.audio.playlist.type;
    },
    isRouteUserOverview() {
      return this.$route.path == "/user/overview";
    },
    isPlayerAvailable() {
      return this.playlistTrack.length > 0;
    },
    ...mapGetters({
      isDataReady: "newaudio/getDataStatus",
      isAudioPlaying: "newaudio/getPlayingStatus",
    }),
    isFavoriteUpdated() {
      return this.$store.state.audio.favorite.update;
    },
  },
  watch: {
    isFavoriteUpdated(val) {
      let self = this;
      setTimeout(() => {
        if (val) {
          self.getDiscoverData();
        }
      }, 0);
    },
  },
  methods: {
    goToRoute(route) {
      this.$router.push(route);
    },
    getAudios(data, index) {
      if (this.playlistType != "recent") {
        this.$store.dispatch("audio/getPlaylistType", "discover");
      }

      if (JSON.stringify(this.playlistTrack) != JSON.stringify(data)) {
        this.$store.dispatch("audio/getPlaylistTrack", data);
      }

      this.$store.dispatch("newaudio/currentTime", 0);
      this.$store.dispatch("newaudio/subliminalIndex", index);
      this.initializeData(data, index, true);

      this.$store.dispatch("newaudio/playedStatus", true);
    },
    getDiscoverData() {
      let data = this.discover.map((item) => {
        if (item.subliminal_id == this.favoriteId) {
          return { ...item, is_liked: item.is_liked == 1 ? 0 : 1 };
        }

        if (item.playlist_id == this.favoriteId) {
          return { ...item, is_liked: item.is_liked == 1 ? 0 : 1 };
        }

        return item;
      });

      this.$store.dispatch("discover/getData", data);
    },
  },
};
</script>

<style scoped>
.other-button > img {
  height: 16px;
}

.card:hover {
  > div > .information > .cover {
    min-width: 80px;
    height: 54px;
    margin: 5px;
    margin-right: 15px;
  }
  > div > .information > .details {
    color: #1c8edf;
  }
}

.cover {
  min-width: 90px;
  height: 64px;

  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.card,
.details {
  cursor: pointer;
}

.playlist {
  max-height: 100vh;
}

.playlist.with-player {
  max-height: calc(100vh - 230px);
}

.details > span:nth-of-type(1) {
  font-size: 10px;
  font-family: "Montserrat-Regular";
}

.text-limit-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.text-limit-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.view-all {
  cursor: pointer;
}

@media screen and (max-width: 990px) {
  .playlist {
    max-height: calc(100vh - 190px);
  }

  .playlist.with-player {
    max-height: calc(100vh - 280px);
  }
}
</style>
