<template>
  <div style="overflow-x: hidden; height: 100vh;" >
    <div class="content">
      <p class="maintitle">Privacy and Policy</p>
      <p class="description" v-html="description"></p>
    </div>
  </div>
</template>

<script>
import { getMagus } from '@/api/magusdetails'
import Card from '@/components/Card.vue'

export default {
  components: {
    Card
  },
  data: () => {
    return {
      getMagus,
      title: '',
      description: '',
      expansion: '',
      terms: '',
      items: [],
    }
  },
  created() {
    this.getData()
  },
  methods: {
    stringToArray(description) {
      return description.split("~")
    },
    stringToArray2(description) {
      return description.split("**")
    },
    stringToArrays(description) {
      return description.split("##")
    },
    getData() {
      const data = {
        title: 'Privacy and Policy',
      }
      this.getMagus(data).then((res) => {
        this.items=(res.data[0].info)
        this.description=this.items[0].description
        consol
      })
    },
  }
}
</script>
<style scoped>
.content {
  margin-top: 50px;
  margin-bottom: 50px;
  padding-inline: 30px;
  @media screen {
    @media (min-width: 1000px) {
      margin-bottom: 100px;
      padding-inline: 100px;
    }
  }
}

p.maintitle {
  text-align: left !important;
  color: #4797DE !important;
  font-family: 'Montserrat-Bold' !important;
  
  font-size: 40px !important;
  margin-top: 30px !important;
}
p.description {
  padding-bottom: 50px;
}
</style>
