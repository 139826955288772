<template>
  <div>
    <Dialog :dialog="dialog" :width="800" :title="`Edit Track`" @closeDialog="closeDialog">
      <div>
        <v-text-field solo dense hide-details class="mb-3" placeholder="Title" v-model="title"></v-text-field>

        <v-textarea solo dense hide-details class="mb-3" placeholder="Description" v-model="description"></v-textarea>

        
        <v-row class="ma-0" style="gap: 20px">
        <v-col class="pa-0">
          <p class="mb-1">Subscription Name</p>
          <v-autocomplete v-model="subscription" :items="subscriptions" solo multiple small-chips clearable
            color="blue-grey lighten-2" label="Subscriptions" hide-details class="mb-0" item-text="name"
            item-value="id" />
        </v-col>
        <v-col class="pa-0">
          <p class="mb-1">Audio Type</p>
          <v-autocomplete v-model="audiotype" :items="audiotypes" solo color="blue-grey lighten-2"
          placeholder="Audio Type" hide-details item-text="name" item-value="id" />
        </v-col>
      </v-row>
      </div>

      <template v-slot:action>
        <v-row class="ma-0">
          <v-col class="pl-0 pr-2">
            <v-btn block @click="submitDialog()">Update</v-btn>
          </v-col>
          <v-col class="pl-2 pr-0">
            <v-btn block @click="closeDialog()">Cancel</v-btn>
          </v-col>
        </v-row>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/Dialog.vue'

import { getMessage } from '@/utils'
import { updateTracks } from '@/api/track.js'
import { getSubscriptions } from '@/api/subscription.js'

export default {
  props: {
    dialog: {
      type: Boolean
    },
    data: {
      type: Object
    }
  },
  components: {
    Dialog
  },
  data: () => {
    return {
      getMessage,
      updateTracks,
      getSubscriptions,

      title: null,
      track_id: null,
      audiotype: null,
      description: null,

      subscription: [],

    }
  },
  created() {
    this.getData()
  },
  computed: {
    audiotypes() {
      return this.$store.state.audio.types
    },
    subscriptions() {
      return this.$store.state.subscription.data;
    }
  },
  methods: {
    getData() {
      this.title = this.data.title
      this.track_id = this.data.track_id
      this.audiotype = this.data.audio_type_id
      this.description = this.data.description
      this.subscription = []

      const subscription = this.data.subscription_id.split(',')

      subscription.forEach((item) => {
        this.subscription.push(parseInt(item))
      })

    },
    closeDialog() {
      this.$emit('closeDialog', false)
      this.resetDialog()
    },
    resetDialog() {
      this.title = null
      this.track_id = null
      this.audiotype = null
      this.description = null
    },
    submitDialog() {
      const data = {
        title: this.title,
        track_id: this.track_id,
        audio_type_id: this.audiotype,
        description: this.description,
        subscription_id: this.subscription.join(',')

      }

      this.updateTracks(data).then((res) => {
        const tracks = res.data

        this.getMessage(this, res)
        this.closeDialog()

        this.$store.dispatch("track/getData", tracks)
      })
    }
  }
}
</script>
