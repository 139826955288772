import { render, staticRenderFns } from "./372-sense-of-smell-and-taste-healing-and-recovery.vue?vue&type=template&id=46ab4ffc&scoped=true&"
import script from "./372-sense-of-smell-and-taste-healing-and-recovery.vue?vue&type=script&lang=js&"
export * from "./372-sense-of-smell-and-taste-healing-and-recovery.vue?vue&type=script&lang=js&"
import style0 from "./372-sense-of-smell-and-taste-healing-and-recovery.vue?vue&type=style&index=0&id=46ab4ffc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46ab4ffc",
  null
  
)

export default component.exports