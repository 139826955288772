<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're looking to develop a healthier relationship with alcohol and learn to moderate your consumption, this subliminal can provide the support you need. By targeting your subconscious mind, it can help you make responsible choices and develop better habits around drinking."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Helping you become more aware of your drinking patterns and habits.",
        "Encouraging you to make conscious choices about when and how much you drink.",
        "Reducing excessive cravings for alcohol and promoting moderation.",
        "Supporting you in developing healthier coping mechanisms and stress management techniques.",
        "Empowering you to prioritize your overall well-being and make responsible decisions."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can rewire your subconscious mind to align with your goals of moderation and responsible alcohol consumption. As a result, you may begin to develop healthier habits around drinking and enjoy a more balanced and fulfilling lifestyle. Remember, change takes time and effort, but you have the ability to make positive changes in your relationship with alcohol."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Striving for a healthy relationship with alcohol is a positive step towards better overall well-being. This subliminal is designed to support you in moderating your alcohol consumption and making responsible choices. Before you begin, consider implementing these strategies to enhance your journey:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your goals: Determine why you want to moderate your alcohol consumption and establish clear intentions.",
        "Educate yourself: Learn about the effects of alcohol on your body and mind, as well as the benefits of moderation.",
        "Create a support system: Seek out friends, family, or support groups who can provide encouragement and accountability.",
        "Practice mindfulness: Be aware of your thoughts, feelings, and triggers related to alcohol, and commit to making responsible choices.",
      ]
    },
    {
      type: 'paragraph',
      text: "As you embark on this journey of moderation, this subliminal can act as a powerful tool to reinforce positive changes in your relationship with alcohol. Regular listening, combined with your commitment to responsible choices, can lead to a healthier and more balanced approach. Take a moment to center yourself, focus on your intentions, and let's begin this transformative process together."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Alcohol Moderation subliminal session! By taking the initiative to work on your relationship with alcohol, you are on your way to making positive changes and practicing responsible choices. Now, it's time to reflect on your experience and continue your journey towards alcohol moderation."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Self-reflection: Take time to understand your motivations, triggers, and relationship with alcohol.",
        "Set realistic goals: Define what moderation means to you and establish limits that you feel comfortable with.",
        "Develop alternatives: Find enjoyable activities and hobbies that can help replace or reduce your reliance on alcohol.",
        "Seek support: Consider joining support groups or confiding in trusted friends and family members who can offer guidance and encouragement."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, alcohol moderation is not about deprivation but about making informed and responsible choices. Stay committed to your goals, be kind to yourself, and celebrate your progress along the way. You have the power to create a healthier and more balanced relationship with alcohol. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Alcohol Moderation subliminal help me?`, 
    answer: `The Alcohol Moderation subliminal is designed to help you develop a healthier relationship with alcohol by influencing your subconscious mind towards moderation and responsible choices. By listening to the subliminal, you can reprogram your mindset, reduce cravings, and increase your awareness of the negative effects of excessive alcohol consumption. It aims to support you in making conscious decisions about alcohol and finding a balanced and responsible approach to drinking.`,
    open: false
  },
  { 
    question: `Is the Alcohol Moderation subliminal effective for everyone?`, 
    answer: `The effectiveness of the Alcohol Moderation subliminal may vary from person to person. It depends on various factors, including individual susceptibility to suggestion and the level of commitment to change. Consistency and regular listening are key to achieving the desired results. Additionally, combining the subliminal with other healthy strategies, such as seeking support from loved ones or professional help if needed, can further enhance its effectiveness.`,
    open: false 
  },
  { 
    question: `Will the Alcohol Moderation subliminal completely eliminate my desire to drink?`, 
    answer: `The Alcohol Moderation subliminal is designed to promote moderation and responsible drinking. It aims to help you develop a healthier relationship with alcohol by reducing cravings and influencing your mindset. However, it does not guarantee the complete elimination of your desire to drink. It is essential to still exercise self-control and make conscious choices that align with your goals for moderation.`,
    open: false 
  },
  { 
    question: `Can the Alcohol Moderation subliminal help me quit drinking?`, 
    answer: `The Alcohol Moderation subliminal is not specifically designed to help individuals quit drinking altogether. Its goal is to support you in developing a healthier relationship with alcohol and making responsible choices. If you are seeking to quit drinking entirely, it is recommended to seek professional help and resources specifically tailored to alcohol addiction and recovery.`,
    open: false 
  },
  { 
    question: `Are there any side effects of listening to the Alcohol Moderation subliminal?`, 
    answer: `There are no known side effects associated with listening to the Alcohol Moderation subliminal. However, if you have concerns about your alcohol consumption or any underlying health conditions, it is important to consult with a healthcare professional. The subliminal is intended to complement healthy practices and responsible decision-making regarding alcohol, not replace them.`,
    open: false 
  },
  { 
    question: `How long should I listen to the Alcohol Moderation subliminal to see results?`, 
    answer: `The time it takes to see results from the Alcohol Moderation subliminal may vary from person to person. It depends on various factors such as individual susceptibility to suggestion and consistency in listening. We recommend listening to the subliminal daily and consistently for at least a few weeks to allow the positive affirmations to take effect and for your mindset to align with moderation and responsible choices.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>