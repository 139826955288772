<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Nail biting can be a difficult habit to break, but with the help of this subliminal, you can overcome it and achieve healthier nails and hands. By targeting your subconscious mind, it aims to change your behavior and eliminate the urge to bite your nails."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can assist you:'
    },
    {
      type: 'list',
      items: [
        "Reducing the need to bite your nails by rewiring your subconscious thought patterns.",
        "Increasing your self-awareness and helping you recognize triggers that lead to nail biting.",
        "Providing relaxation and stress relief techniques that can replace the habit of nail biting.",
        "Building your confidence and self-esteem to prevent engaging in nail biting behaviors.",
        "Helping you develop healthier alternatives and habits to keep your nails and hands occupied."
      ]
    },
    {
      type: 'paragraph',
      text: "With consistent listening, this subliminal can help you break the cycle of nail biting and cultivate healthier habits. Over time, you may notice improved nail growth, enhanced appearance of your hands, and increased self-control. Remember, it takes time and effort to break a habit, so be patient and persistent in your journey to healthier nails."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Breaking the habit of nail biting can be challenging, but this subliminal is designed to help you overcome this behavior and achieve healthier nails and hands. Before you start listening, here are some tips to prepare yourself:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Identify triggers: Pay attention to the situations or emotions that tend to lead to nail biting, so you can develop strategies to avoid or manage them.",
        "Replace the habit: Find alternative behaviors or activities to distract your mind and hands when the urge to bite your nails arises.",
        "Keep your nails well-maintained: Regularly trim and file your nails to reduce the temptation to bite.",
        "Set a positive affirmation: Repeat a positive affirmation, such as 'I have strong and healthy nails', to reinforce your commitment to breaking the habit."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind and build the motivation and willpower to stop nail biting. Remember, breaking a habit takes time and effort, but with dedication and patience, you can achieve healthier nails and hands. So take a deep breath, set your intention, and let's begin this transformative journey together."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Stop Nail Biting subliminal session! By working towards breaking the nail biting habit, you have taken an important step towards achieving healthier nails and hands. Now, it's time to reflect on your progress and continue your journey of overcoming this habit."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Be aware of triggers: Identify situations or emotions that lead to nail biting and find healthier coping mechanisms.",
        "Keep your nails well-groomed: Trim and shape your nails regularly to reduce the temptation to bite.",
        "Find alternatives: Keep fidget toys or stress balls handy to redirect your urge to bite your nails.",
        "Reward yourself: Celebrate small victories along the way to keep yourself motivated and encouraged."
      ]
    },
    {
      type: 'paragraph',
      text: "Breaking the habit of nail biting takes time and dedication, but remember that you are capable of making positive changes. Stay committed to your goal, be patient with yourself, and believe in your ability to achieve healthier nails and hands. You've got this!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Stop Nail Biting subliminal help me break the habit?`, 
    answer: `The Stop Nail Biting subliminal is designed to target the subconscious mind and reprogram your thoughts and behaviors surrounding nail biting. By listening to the subliminal consistently, you can increase your motivation to stop nail biting, reduce anxiety or stress that may trigger the habit, and develop healthier coping mechanisms. The positive affirmations in the subliminal can help you replace the nail biting habit with more positive and healthy habits.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Stop Nail Biting subliminal?`, 
    answer: `The time it takes to see results from the Stop Nail Biting subliminal may vary from person to person. Some individuals may experience positive changes quickly, while others may require more time and consistent listening to fully break the habit. It is recommended to use the subliminal daily and consistently for a few weeks to allow the affirmations to take effect and reprogram your subconscious mind.`,
    open: false 
  },
  { 
    question: `Can I use the Stop Nail Biting subliminal along with other methods to quit nail biting?`, 
    answer: `Yes, you can use the Stop Nail Biting subliminal along with other methods to quit nail biting. Combining the subliminal with practical strategies such as keeping your nails trimmed, using bitter-tasting nail polish, or finding alternative stress-relief techniques can enhance the effectiveness of breaking the habit. The subliminal can provide added support and motivation as you work towards stopping nail biting.`,
    open: false 
  },
  { 
    question: `Is the Stop Nail Biting subliminal safe to use?`, 
    answer: `Yes, the Stop Nail Biting subliminal is safe to use. The subliminal messages are designed to help you break the habit of nail biting and promote healthier alternatives. However, if you have any concerns or underlying medical conditions related to your nail biting habit, it is recommended to consult with a healthcare professional.`,
    open: false 
  },
  { 
    question: `Will the Stop Nail Biting subliminal work for severe cases of nail biting?`, 
    answer: `The Stop Nail Biting subliminal is designed to address the habit of nail biting and can be beneficial for individuals with milder cases. However, for severe cases or underlying psychiatric conditions that contribute to nail biting, it is important to seek professional help from a healthcare provider or mental health professional.`,
    open: false 
  },
  { 
    question: `Can children use the Stop Nail Biting subliminal?`, 
    answer: `While the Stop Nail Biting subliminal may be suitable for older children and teenagers, it is always advisable to consult with a parent or guardian before introducing any new audio programs. Parental guidance and support are crucial for children who want to quit the habit of nail biting.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>