<template>
  <section>
    <div class="container1 justify-content-center align-center">
      <div>
        <h1 class="ma-2">Upgrade Plan to Experience More.</h1>
      </div>
      <div>
        <div class="products justify-content-center d-flex">
          <div v-if="prevButton == true" class="mt-5">
            <v-icon size="50" @click="prev()">mdi-chevron-left</v-icon>
          </div>
          <div
            class="col-4 col-sm-4 col-md-4 col-lg-4 wrap"
            v-for="(menu, index) in items.slice(start, end)"
          >
            <img class="big-img" :src="menu.cover" alt="" />
            <h6 class="mt-3" v-html="menu.description"></h6>
          </div>
          <div v-if="nextButton == true" class="mt-5">
            <v-icon size="50" @click="next()">mdi-chevron-right</v-icon>
          </div>
        </div>
      </div>
      <div style="margin: 20px">
        <h5>Choose your Plan</h5>
      </div>
      <div class="justify-content-center align-center text-center">
        <div>
          <div v-if="period == 'Monthly'">
            <div class="d-flex wrapperss" @click="getPeriod('Monthly')">
              <v-icon size="30" style="color: var(--ma-tufts-blue)"
                >mdi-check-circle-outline</v-icon
              >
              <span class="ml-4">
                <h5>Monthly</h5>
                <h5>{{ data[0].amount }} PHP</h5>
              </span>
            </div>
            <div class="d-flex wrappers2 mt-5" @click="getPeriod('Yearly')">
              <v-icon size="30">mdi-checkbox-blank-circle-outline</v-icon>
              <span class="ml-4">
                <h5>Annually</h5>
                <h5>{{ data[0].amount_year }} PHP</h5>
              </span>
            </div>
          </div>
          <div v-else>
            <div class="d-flex wrappers2" @click="getPeriod('Monthly')">
              <v-icon size="30">mdi-checkbox-blank-circle-outline</v-icon>
              <span class="ml-4">
                <h5>Monthly</h5>
                <h5>{{ data[0].amount }} PHP</h5>
              </span>
            </div>
            <div class="d-flex wrappers3 mt-5" @click="getPeriod('Yearly')">
              <v-icon size="30" style="color: var(--ma-tufts-blue)"
                >mdi-check-circle-outline</v-icon
              >
              <span class="ml-4">
                <h5>Annually</h5>
                <h5>{{ data[0].amount_year }} PHP</h5>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <button @click="openDialog()" class="button btn btn-primary btn-lg">
          Continue
        </button>
      </div>
      <div class="mt-3">
        <button @click="skipPayment()" class="skip">
          Continue with free account
        </button>
      </div>
    </div>
    <DialogPrice
      :dialog="dialog"
      :width="350"
      title=""
      @closeDialog="closeDialog"
    >
      <Payment
        name="Premium"
        :code="codeParams"
        :amount="subscription_amount.toString()"
        :period="period"
        :isLogin="isLogin"
        @closeDialog="closeDialog"
      />
    </DialogPrice>
  </section>
</template>

<script>
import { checkUrl } from "@/utils";
import { getMagus } from "@/api/magusdetails";
import SliderSingleForPricing from "@/components/Slider/SliderSingleForPricing.vue";
import { userSignUp, checkMobileLogin } from "@/api/user.js";

import { codeQueryParams, loginQueryParams, periodQueryParams } from "@/utils";
import DialogPrice from "@/components/DialogPrice.vue";
import Payment from "@/components/Home/Payment.vue";
export default {
  components: {
    DialogPrice,
    Payment,
    SliderSingleForPricing,
  },
  data: () => {
    return {
      getMagus,
      userSignUp,
      checkMobileLogin,
      codeQueryParams,
      periodQueryParams,

      dialog: false,

      subscription_name: "",
      subscription_amount: 0,
      subscription_period: "",

      period: "Monthly",
      isLogin: false,
      items: [],
      start: 0,
      end: 3,
      nextButton: true,
      prevButton: true,
    };
  },
  created() {
    const params = {
      route: this.$route,
      router: this.$router,
      tempname: "register_temp",
    };
    checkUrl(params);
    this.getData();
    this.checkMobileLogins()
  },
  computed: {
    periodParams() {
      return this.periodQueryParams();
    },
    codeParams() {
      return this.codeQueryParams();
    },
    isMobile() {
      return this.$store.state.screen.width < 1200;
    },
    isPeriod() {
      if (this.period == "Monthly") return "month";
      if (this.period == "Yearly") return "year";
    },
    isPeriodMonthly() {
      if (this.period == "Monthly") return "active";
    },
    isPeriodYearly() {
      if (this.period == "Yearly") return "active";
    },
    subscription() {
      return this.$store.state.subscription.data;
    },
    data() {
      return this.subscription.filter((items) => items.name == "Premium");
    },
    user() {
      return this.$store.state.user.info;
    },
    users() {
      return JSON.parse(this.user);
    },
    userToken() {
      return this.$store.state.user.token;
    },
  },
  watch: {
    period(val) {
      this.subscription_period = val;
    },
  },
  methods: {
    checkMobileLogins() {
      if(this.codeParams != null){
        const data = {
          code: this.codeParams,
        }
        this.checkMobileLogin(data).then((res) => {
          if(res.success == true) {
            const token = res.data.token;
            const info = JSON.stringify(res.data.user);
            const cover = res.data.user.info.cover;

            this.$store.dispatch(`user/getCover`, cover);
            this.$store.dispatch(`user/getInfo`, info);
            this.$store.dispatch(`user/getToken`, token);
            this.$store.dispatch(`user/getLocalInfo`, info);
            this.$store.dispatch(`user/getLocalToken`, token);
            if(this.periodParams != null) {
              if(this.periodParams == 1){
                this.getPeriod('Monthly')
                this.openDialog()
              }else if (this.periodParams == 2){
                this.getPeriod('Yearly')
                this.openDialog()
              }
            }
          }
        })
      }
    },
    skipPayment() {
      if (this.userToken == null) {
        this.$store.dispatch("screen/getLoading", true);
        setTimeout(() => {
          if (localStorage.getItem("register_temp") == null) {
            this.$router.push("register");

            const params = JSON.stringify({
              period: this.period,
              amount: 0,
              subscription: "Free",
              time_created: new Date(),
            });

            localStorage.setItem("subscription_temp", btoa(params));
          } else {
            const params = localStorage.getItem("register_temp");
            const decrypted = atob(params);
            const data = JSON.parse(decrypted);

            this.userSignUp(data).then((res) => {
              const { success, data } = res;

              if (success) {
                localStorage.setItem("user_token", data.token);
                localStorage.setItem("user", JSON.stringify(data.user));
                localStorage.removeItem("register_temp");
                localStorage.removeItem("subscription_temp");
                this.$router.push("/user/mood");
                this.$store.dispatch("screen/getLoading", false);
              }

              // this.$router.push('/user')
            });
          }
        }, 3000);
      } else {
        this.$router.push("user");
      }
    },
    getData() {
      const data = {
        title: "Premium",
      };
      this.getMagus(data).then((res) => {
        this.items = res.data[0].info;
      });
    },
    openDialog() {
      this.dialog = true;
      if (this.period == "Yearly")
        this.subscription_amount = this.data[0].amount_year;
      else this.subscription_amount = this.data[0].amount;
    },
    getPeriod(data) {
      this.period = data;
    },
    getSubscriptionName(subscription) {
      this.subscription_name = subscription.name;
      return subscription.name.toUpperCase();
    },
    getAmount(subscription, period) {
      let price = subscription.amount;
      if (period == "Yearly") price = subscription.amount_year;

      this.subscription_amount = price;
      return price.toLocaleString("en-US");
    },
    closeDialog(data) {
      this.dialog = false;
    },
    next() {
      if (this.end != this.items.length && this.end < this.items.length) {
        this.start = this.end;
        this.end = this.start + 3;
      } else {
        this.start = 0;
        this.end = this.start + 3;
      }
    },
    prev() {
      if (this.start != 0) {
        this.end = this.start;
        this.start = this.end - 3;
      } else {
        this.end = this.items.length;
        this.start = this.end - 3;
      }
    },
  },
};
</script>

<style scoped>
section:nth-of-type(1) > .container1 {
  padding-block: 2em;
}
h1 {
  color: var(--ma-tufts-blue);
  font-family: "Montserrat-Black";
}
h3 {
  margin-top: 2em;
  color: var(--ma-black-olive);
  line-height: 1.35em;
}
h5:nth-of-type(2) {
  font-family: "Montserrat-Regular";
}
.products {
  width: 80%;
  margin: auto;
}
.skip {
  font-family: "Montserrat-Bold";
  color: #427ab3;
  margin-top: 2em;
}
img {
  margin-right: auto;
  margin-left: auto;
  height: clamp(4.19rem, calc(1.64rem + 5.72vw), 2.75rem);
}
.wrapperss {
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: #c6d7e8;
  background-image: url("~@/assets/images/main/price/1.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: right;
}
.wrappers3 {
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: #c6d7e8;
  background-image: url("~@/assets/images/main/price/2.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: right;
}
.wrappers2,
.wrappers3,
.wrapperss {
  cursor: pointer;
  margin: auto;
  width: 80%;
  padding: 10px;
  @media screen and (min-width: 1000px) {
    width: 35%;
  }
}

@media screen and (min-width: 40rem) {
  section {
    text-align: center;
  }

  section:nth-of-type(1) > .container1 {
    padding-block: 4em;

    @media screen and (min-width: 1000px) {
      background-image: url("~@/assets/images/main/price/4.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: top;
    }
  }
}
</style>
