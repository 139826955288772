<template>
  <div class="mb-5 mt-10">
    <SupportCard :full="true">
      <ResetPassword :full="true" />
    </SupportCard>
  </div>
</template>

<script>
import ResetPassword from '@/views/Main/SupportPage/ResetPassword.vue'
import SupportCard from '@/components/SupportCard.vue'
export default {
  components: {
    ResetPassword,
    SupportCard
  }
}
</script>
