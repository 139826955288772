import request from "@/utils/request";

export const getCategories = (data) => {
  return request.get("/api/category").then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const getCategoryFilters = (data) => {
  return request.post("/api/category/filter", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const addCategories = (data) => {
  return request.post("/api/category", data).then((res) => {
    return res.data;
  });
};

export const updateCategories = (data) => {
  return request.put(`/api/category/${data.id}`, data).then((res) => {
    return res.data;
  });
};

export const updateToggle = (data) => {
  return request.post("/api/category/toggle", data).then((res) => {
    return res.data;
  });
};

export const deleteCategories = (data) => {
  return request.delete(`/api/category/${data.id}`).then((res) => {
    return res.data;
  });
};

export const getUserCategories = (data) => {
  return request.post(`/api/user/category/filter`, data).then((res) => {
    return res.data;
  });
};
