<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you've been feeling stuck or held back from achieving your goals, this subliminal can help you remove the obstacles and blockages standing in your way. By targeting your subconscious mind, it empowers you to embrace new opportunities and create positive changes in your life."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can support you:"
    },
    {
      type: 'list',
      items: [
        "Identifying and releasing limiting beliefs that may be holding you back.",
        "Removing self-doubt and feelings of unworthiness that hinder your progress.",
        "Clearing away subconscious fears and insecurities that sabotage your success.",
        "Boosting your confidence and self-belief, allowing you to take bold actions.",
        "Opening your mind to new possibilities and opportunities.",
        "Enhancing your intuition and decision-making abilities.",
        "Fostering a growth mindset, allowing you to embrace challenges and learn from failure."
      ]
    },
    {
      type: 'paragraph',
      text: "By consistently listening to this subliminal, you can reprogram your subconscious mind to let go of obstacles and embrace opportunities. This can lead to a transformed mindset and a greater sense of empowerment and success in all areas of your life. Remember, change takes time and effort, but you have the power to create the life you desire."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Unlocking your potential and seizing opportunities often requires removing the obstacles and blockages that stand in your way. This subliminal is designed to help you do just that. Before you start listening, consider these strategies to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Identify your obstacles: Reflect on the specific challenges that are preventing you from seizing opportunities and achieving your goals.",
        "Visualize success: Imagine yourself overcoming these obstacles and reaching your goals successfully.",
        "Shift your mindset: Embrace a positive and growth-oriented mindset, knowing that obstacles can be overcome with perseverance and determination.",
        "Hold your intention in mind: Keep focused on your desire for removing obstacles and blockages while listening to the subliminal affirmations."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you may start to notice a shift in your mindset, increased resilience, and a greater ability to recognize and seize opportunities. Embrace the process, stay committed to your goals, and let's start removing those obstacles that are holding you back from achieving the life you desire."
    }
  ]
},
            {
  id: 'after-listening',
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Opportunity Obstacles and Blockages Removal subliminal session! By dedicating time to removing the obstacles and blockages in your path, you've taken a powerful step towards seizing opportunities and achieving your goals. Now, it's time to reflect on your experience and continue on your journey to success."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Identify your obstacles: Take some time to think about the specific obstacles that have been holding you back from seizing opportunities.",
        "Make a plan: Create a clear and actionable plan that addresses each obstacle, breaking them down into manageable steps.",
        "Practice positive affirmations: Repeat positive affirmations that empower and motivate you to overcome any obstacles that come your way.",
        "Stay persistent: Remember that obstacles are a natural part of the journey towards success. Stay persistent and don't let setbacks discourage you."
      ]
    },
    {
      type: 'paragraph',
      text: "Removing obstacles and blockages is an ongoing process. Embrace it as an opportunity for growth and transformation. With the commitment to overcome any challenges that come your way, you'll be well on your path to achieving your goals and seizing every opportunity that comes your way. Keep going!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Opportunity Obstacles and Blockages Removal subliminal work?`, 
    answer: `The Opportunity Obstacles and Blockages Removal subliminal works by targeting and reprogramming your subconscious mind to eliminate any mental, emotional, or energetic blockages that may be hindering your ability to seize opportunities and achieve your goals. The subliminal messages are designed to replace limiting beliefs and negative thought patterns with positive affirmations and empowering beliefs, allowing you to unlock your full potential and create opportunities for success.`,
    open: false
  },
  { 
    question: `How long does it take to see results with the Opportunity Obstacles and Blockages Removal subliminal?`, 
    answer: `Results can vary depending on the individual and the specific blockages or obstacles they are facing. Some people may experience noticeable changes and breakthroughs quickly, while others may require more time and consistent listening to fully overcome deep-seated blockages. We recommend listening to the subliminal daily and consistently for best results, and allowing yourself the patience and self-compassion to undergo a transformative journey at your own pace.`,
    open: false 
  },
  { 
    question: `Can the Opportunity Obstacles and Blockages Removal subliminal help me overcome specific obstacles or challenges in my life?`, 
    answer: `Yes, the Opportunity Obstacles and Blockages Removal subliminal is designed to help you identify and overcome any obstacles or challenges that may be holding you back from seizing opportunities and achieving your goals. By reprogramming your subconscious mind, it can provide you with the mental clarity, confidence, and resilience to overcome adversity and create pathways for success.`,
    open: false 
  },
  { 
    question: `Is it necessary to believe in the subliminal messages for them to work?`, 
    answer: `No, it is not necessary to believe in the subliminal messages for them to work. The subliminal works by bypassing the conscious mind and directly influencing the subconscious. However, having an open mind and being receptive to the affirmations can enhance the effectiveness of the subliminal and align you more fully with the desired outcomes.`,
    open: false 
  },
  { 
    question: `Can the Opportunity Obstacles and Blockages Removal subliminal replace taking action towards my goals?`, 
    answer: `No, the subliminal is a tool that works on the subconscious level to remove mental and emotional blockages. It is important to combine the subliminal with practical action steps towards your goals. The subliminal can help shift your mindset and remove obstacles, but taking action is still essential for achieving tangible results.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Opportunity Obstacles and Blockages Removal subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your mental or emotional well-being, please consult with a professional. It is important to note that the subliminal is intended to complement personal growth efforts and should not replace professional advice or therapy if needed.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>