<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Whether you're a beginner or an experienced painter, this subliminal can help unlock and enhance your painting talent and skills. By targeting your subconscious mind, it can ignite your creativity and inspire you to create beautiful artwork."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can assist you:"
    },
    {
      type: 'list',
      items: [
        "Unlock hidden painting talent and abilities that you may not even know you possess.",
        "Enhance your current painting skills by building confidence, focus, and precision.",
        "Explore different painting techniques and styles to expand your artistic repertoire.",
        "Boost your creative thinking and problem-solving skills to overcome artistic challenges.",
        "Tap into a limitless well of inspiration and ideas for your artwork."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly while practicing your art, you can tap into the full potential of your painting talent. As you become more confident and skilled, you may find yourself creating artwork that surpasses your own expectations. Remember, practice and dedication are essential, but with this subliminal, you can truly unlock your artistic potential." 
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Developing your painting talent and skills can be a rewarding journey of self-expression and creativity. This subliminal is designed to help unlock and enhance your artistic abilities. Before you start listening, consider the following tips to make the most out of this experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Gather your painting supplies: Ensure you have the necessary materials and a comfortable space to practice and explore different techniques.",
        "Set a positive mindset: Believe in your artistic potential and approach each painting session with an open and curious attitude.",
        "Experiment with different styles: Don't be afraid to try new techniques, experiment with colors, and explore various artistic styles to find what resonates with you.",
        "Imagine your artistic goals: Visualize the kind of artist you aspire to be and the kind of artwork you want to create. Use this vision to stay motivated and inspired throughout your painting journey."
      ]
    },
    {
      type: 'paragraph',
      text: "With regular listening and dedicated practice, this subliminal can help you tap into your painting talent and enhance your skills. Embrace the joy of creating art, explore your creativity, and let your imagination guide you to create beautiful masterpieces. Get ready to unlock your artistic potential and start your painting journey now!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Painting Talent and Skills Enhancement session! By investing time and effort into unlocking and enhancing your painting talent and skills, you've taken a significant step towards expressing your creativity and creating beautiful artwork. As you reflect on your experience, consider the possibilities that lie ahead."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Experiment with different techniques: Try new painting techniques and explore various styles to broaden your artistic expression.",
        "Create an art schedule: Set aside dedicated time for painting each week to nurture your talent and continue practicing.",
        "Observe and appreciate art: Visit galleries, museums, and exhibitions to draw inspiration from other artists.",
        "Document your progress: Take photos or keep a visual diary of your artwork to see your growth and improvement over time."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, enhancing your painting talent and skills is an ongoing journey. Embrace the experimentation, practice, and exploration required to become the artist you aspire to be. By harnessing your creativity, you are capable of bringing beauty and inspiration into the world. Keep painting and let your imagination soar!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Can the Painting Talent and Skills Enhancement subliminal help me become a better painter?`, 
    answer: `Yes, the Painting Talent and Skills Enhancement subliminal is designed to help unlock and enhance your painting talent and skills. By positively influencing your subconscious mind, it can help you tap into your creativity, improve your technique, and explore different styles and approaches to painting. Consistent listening and practicing are key to achieving desired results.`,
    open: false
  },
  { 
    question: `How often should I listen to the Painting Talent and Skills Enhancement subliminal?`, 
    answer: `To maximize the benefits, it is recommended to listen to the Painting Talent and Skills Enhancement subliminal regularly. Ideally, find a time when you can relax and focus solely on the subliminal audio. Consistency is key, so aim for at least once a day or as frequently as possible to reinforce positive messages and mental imagery associated with painting.`,
    open: false 
  },
  { 
    question: `Will the subliminal help me find my unique painting style?`, 
    answer: `The Painting Talent and Skills Enhancement subliminal can help you explore and develop your painting skills, which may naturally lead to discovering your unique style. By enhancing your creative abilities and opening your mind to new techniques, the subliminal can assist you in finding and refining your own artistic voice.`,
    open: false 
  },
  { 
    question: `Can the subliminal help me overcome creative blocks in painting?`, 
    answer: `Yes, the Painting Talent and Skills Enhancement subliminal can help you overcome creative blocks in painting by promoting a positive and inspired mindset. It can help boost confidence, ignite creativity, and provide new perspectives to overcome challenges. Combining the subliminal with regular practice and experimentation can help you move past creative blocks and further develop as an artist.`,
    open: false 
  },
  { 
    question: `Is there any age limit to benefit from the Painting Talent and Skills Enhancement subliminal?`, 
    answer: `No, there is no age limit to benefit from the Painting Talent and Skills Enhancement subliminal. Anyone passionate about painting can use the subliminal, regardless of age or skill level. It is never too late to explore and enhance your artistic abilities.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Painting Talent and Skills Enhancement subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Painting Talent and Skills Enhancement subliminal. However, it is important to remember that the subliminal is a supplementary tool and should not replace practicing and actively engaging in painting techniques and learning.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>