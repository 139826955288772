<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're looking to enhance your behavior and personal development, this subliminal can be a powerful tool to help you achieve your goals. By targeting your subconscious mind, it can assist you in learning new skills, improving communication, and fostering positive relationships."
    },
    {
      type: 'heading',
      text: 'Some key areas where this subliminal can be beneficial include:'
    },
    {
      type: 'list',
      items: [
        "Developing effective communication skills to express yourself clearly and confidently.",
        "Fostering positive relationships and improving your interactions with others.",
        "Building self-confidence and belief in your abilities.",
        "Enhancing your problem-solving skills and decision-making abilities.",
        "Motivating and inspiring yourself to take action towards personal growth."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you may start to notice positive changes in your behavior, mindset, and relationships. Remember, personal development is an ongoing journey, and consistent use of this subliminal can support you in making lasting improvements. Start your journey today and unlock your full potential!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a path of personal development and enhancing your behavior can have a profound impact on your life and relationships. This subliminal is designed to support your journey towards positive growth. Before you begin, consider these strategies to maximize your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Define your personal goals: Identify areas of your behavior or skills you wish to improve upon, fostering clarity and focus.",
        "Highlight effective communication: Prioritize enhancing your communication skills to cultivate positive relationships and better connect with others.",
        "Develop a growth mindset: Embrace a belief in your capacity for growth and improvement, allowing yourself to take on new challenges and learn from them.",
        "Maintain a receptive mindset: As you listen to the subliminal affirmations, stay open to the messages and let them align with your subconscious mind."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can tap into your inherent potential for personal growth and behavioral enhancement. With a commitment to your goals and an open mindset, you can make significant progress in developing positive behaviors, acquiring new skills, and nurturing healthy relationships. Take a moment to set your intentions, embrace this opportunity, and let your journey towards personal development begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Behavioral Development subliminal! By investing your time and energy in this session, you've taken a significant step towards enhancing your behavior and personal growth. Reflecting on your experience and continuing to nurture positive habits and relationships will propel you forward in your journey."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Self-reflection: Take time to reflect on the insights gained from the subliminal and how they can be applied to your behavior and personal development.",
        "Learn new skills: Seek opportunities to acquire new skills that are relevant to your personal growth and goals.",
        "Improve communication: Focus on developing effective communication skills to foster positive relationships.",
        "Practice gratitude: Cultivate an attitude of gratitude by expressing appreciation for the people and experiences in your life."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember that behavior and personal development are ongoing processes. Embrace the journey and be patient with yourself as you work towards positive changes. Your commitment and dedication to self-improvement will lead to a fulfilling and thriving life. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Behavioral Development subliminal help me improve my skills?`, 
    answer: `The Behavioral Development subliminal is designed to stimulate positive changes in your subconscious mind, helping you develop new skills or improve existing ones. By influencing your thoughts and beliefs, the subliminal messages can enhance your motivation, focus, and self-confidence, making it easier for you to learn and master new skills.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Behavioral Development subliminal?`, 
    answer: `Results may vary depending on individual factors and the specific skills you are seeking to develop. However, with consistent listening and practice, you may start noticing improvements in your behavior, communication, and personal development within a few weeks. The subliminal messages aim to align your subconscious mind with your goals, helping you make positive changes in your life.`,
    open: false 
  },
  { 
    question: `Can the Behavioral Development subliminal help me improve my relationships?`, 
    answer: `Yes, the Behavioral Development subliminal can help improve your relationships by influencing your thoughts and beliefs, promoting understanding, empathy, and effective communication. By cultivating a positive mindset and focusing on personal growth, you can develop healthier relationship dynamics with others.`,
    open: false 
  },
  { 
    question: `Is it necessary to practice the skills I want to develop while listening to the Behavioral Development subliminal?`, 
    answer: `While consistent listening to the Behavioral Development subliminal can help create a positive mindset and enhance your motivation, it is also important to actively practice the skills you want to develop. Combining the subliminal with real-world practice and application can lead to more significant and lasting improvements.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Behavioral Development subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Behavioral Development subliminal. However, if you have any concerns about your personal development or mental well-being, it is always recommended to seek professional advice.`,
    open: false 
  },
  { 
    question: `Can the Behavioral Development subliminal help with specific behavioral issues or disorders?`, 
    answer: `The Behavioral Development subliminal is designed to promote positive changes in behavior and personal development. While it may complement other therapeutic interventions, it is not a substitute for professional help. If you are dealing with specific behavioral issues or disorders, it is important to seek the guidance of a qualified healthcare professional or therapist.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>