<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you often find yourself talking too much and struggling to control your urge to dominate conversations, this subliminal can help you become a better listener and communicator."
    },
    {
      type: 'heading',
      text: 'Here are some benefits of using this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Developing active listening skills and showing genuine interest in others' perspectives.",
        "Becoming more aware of your talkativeness and learning to pause and allow others to speak.",
        "Improving your communication skills by learning how to convey thoughts concisely and effectively.",
        "Reducing interruptions and allowing conversations to flow more smoothly.",
        "Increasing your self-awareness and mindfulness in social interactions."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can rewire your subconscious mind to control your urge to talk excessively and become a more attentive and considerate communicator. Remember, change takes time and practice, but with commitment and consistency, you can become a better listener and cultivate meaningful connections with others."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "If you struggle with talking too much and want to become a better listener and communicator, this subliminal can help you in your journey. Before you start listening, here are some tips to consider:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your communication habits: Take some time to self-reflect and identify areas where you tend to talk excessively.",
        "Set clear intentions: Determine what specific communication goals you want to achieve, such as becoming a better listener or speaking more intentionally.",
        "Practice active listening: Use this opportunity to actively listen to others, focusing on understanding their perspective rather than waiting for your turn to speak.",
        "Be patient with yourself: Changing communication habits takes time and effort. Allow yourself to make mistakes and learn from them."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to help reprogram your subconscious mind, enabling you to develop healthier communication patterns. As you listen regularly, you may find yourself becoming more aware of your speech habits and gradually gaining control over your urge to talk excessively. Embrace this moment of self-improvement, set your intentions, and let's begin the journey towards becoming a better listener and communicator."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Stop Talking Too Much subliminal! By working on your communication habits and becoming a better listener, you've taken an important step towards improving your relationships and interactions with others. Now, let's reflect on your experience and continue to strengthen your communication skills."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your communication style: Consider how your excessive talking may have affected your relationships or conversations.",
        "Practice active listening: Focus on genuinely listening to others and giving them your full attention without interrupting.",
        "Ask open-ended questions: Encourage others to share their thoughts and opinions by asking questions that promote deeper conversations.",
        "Take breaks: Remember that silence can be powerful in communication. Allow moments of silence to give others space to speak."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, becoming a better communicator and listener is a continuous process. By being aware of your communication habits and actively practicing new skills, you can create stronger connections and more meaningful conversations. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Stop Talking Too Much subliminal help me?`, 
    answer: `The Stop Talking Too Much subliminal is designed to help you develop self-awareness and control over your urge to talk excessively. By listening to the subliminal messages, you can reprogram your subconscious mind to become a better listener and communicator. Over time, you may notice a reduction in the frequency and duration of your excessive talking, leading to improved relationships and better communication skills.`,
    open: false
  },
  { 
    question: `How long do I need to listen to the Stop Talking Too Much subliminal to see results?`, 
    answer: `Results can vary depending on the individual and their unique circumstances. Some people may experience positive changes right away, while others may require more time and consistent listening to fully reprogram their subconscious mind. We recommend listening to the subliminal daily and consistently for best results.`,
    open: false 
  },
  { 
    question: `Can the Stop Talking Too Much subliminal completely eliminate my urge to talk excessively?`, 
    answer: `While the Stop Talking Too Much subliminal can help you control and reduce your urge to talk excessively, complete elimination of the desire may not be realistic or necessary. The goal is to develop a healthier balance and become a better listener and communicator. By reprogramming your subconscious mind, you can learn to control your urge and engage in more meaningful and effective conversations.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Stop Talking Too Much subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your communication habits or mental health, please consult with a professional. It is important to note that the subliminal is intended to supplement personal growth and self-improvement efforts, not replace professional advice or treatment.`,
    open: false 
  },
  { 
    question: `Can the Stop Talking Too Much subliminal be used alongside other self-improvement practices?`, 
    answer: `Yes, the Stop Talking Too Much subliminal can be used alongside other self-improvement practices that align with your goals. You may find it beneficial to combine the subliminal with techniques such as mindfulness, active listening, and assertiveness training to enhance your communication skills and habits.`,
    open: false 
  },
  { 
    question: `Will listening to the Stop Talking Too Much subliminal make me extremely introverted or quiet?`, 
    answer: `No, the goal of the Stop Talking Too Much subliminal is not to make you extremely introverted or quiet. It aims to help you develop self-awareness and control over your urge to talk excessively, allowing you to engage in more balanced and meaningful conversations. You can still express yourself and interact with others while practicing better communication habits and active listening.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>