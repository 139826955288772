<template>
  <div>
    <WideProductSelections />
  </div>
</template>

<script>
import WideProductSelections from '@/views/Main/WideProductSelections'
export default {
  components: {
    WideProductSelections
  }
}
</script>