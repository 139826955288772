<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you struggle with the fear of aging or gerascophobia, this program is here to help. It is designed to support you in embracing the natural process of aging and living a fulfilling life at any age."
    },
    {
      type: 'heading',
      text: 'Here are some ways this program can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Letting go of negative beliefs and fears associated with aging.",
        "Developing a positive mindset towards getting older and seeing it as an opportunity for growth and wisdom.",
        "Reducing anxiety and stress related to the aging process.",
        "Boosting self-confidence and self-acceptance in regards to your changing appearance.",
        "Embracing a healthier lifestyle to enhance your overall well-being as you age."
      ]
    },
    {
      type: 'paragraph',
      text: "As you engage with this program, you'll have the opportunity to reframe your thoughts and emotions about aging. Over time, you may find yourself feeling more content, empowered, and excited about the different stages of life. Remember, aging is a natural part of the journey, and this program is here to provide you with the tools to embrace it fully."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Conquering the fear of aging and gerascophobia is a courageous step towards embracing life at any age. This program is designed to support you on this empowering journey. Before you start listening to the subliminal, consider these preparatory strategies:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your fear: Take a moment to recognize and accept the fear of aging that you carry within you.",
        "Understand the process of aging: Educate yourself on the natural physical and emotional changes that occur as we grow older.",
        "Shift your perspective: Embrace the wisdom and life experience that comes with aging, focusing on the positive aspects of getting older.",
        "Set your intention: Clarify your desire to let go of your fear of aging and embrace the opportunity to live a fulfilling life at any age."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal program, you can rewire your subconscious mind to release the fear of aging. Embrace this transformative journey and welcome a new perspective on your own aging process. Believe in your ability to live a fulfilling life at any age, and let the subliminal affirmations guide you towards a sense of peace and acceptance."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Fear of Aging and Gerascophobia Removal program! By taking this step to overcome your fear of aging and embrace the aging process, you've embarked on a journey towards living a fulfilling and vibrant life at any age. Now, take a moment to reflect on your experience and continue your personal growth."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Embrace self-reflection: Write down your thoughts, emotions, and any changes you notice as you work through your fear of aging.",
        "Practice self-compassion: Be patient and kind to yourself as you navigate your journey towards embracing the aging process.",
        "Challenge negative beliefs: Identify negative thoughts or beliefs about aging and replace them with positive and empowering ones.",
        "Engage in self-care: Prioritize activities that make you feel good and promote overall well-being."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming the fear of aging is a process that requires self-compassion and continuous effort. Approach each day with a positive mindset, and embrace the wisdom and experiences that come with age. You have the power to shape and create a fulfilling life at any stage. Embrace the journey and live life to the fullest!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What is gerascophobia?`, 
    answer: `Gerascophobia is the fear of aging or growing old. It is characterized by an intense fear or dread of the process of aging and the physical, emotional, and social changes associated with it. This fear can be disruptive to daily life and may lead to avoidance of certain activities or social situations. The Fear of Aging and Gerascophobia Removal program aims to help individuals overcome this fear and embrace the aging process with a positive mindset.`,
    open: false
  },
  { 
    question: `How can the Fear of Aging and Gerascophobia Removal program help me?`, 
    answer: `The Fear of Aging and Gerascophobia Removal program uses subliminal messages to target and reprogram the subconscious mind. By listening to the program regularly, you can gradually shift your mindset towards aging from one of fear and negativity to one of acceptance, positivity, and a desire to live a fulfilling life at any age. It can help you overcome the limitations and anxieties associated with gerascophobia, allowing you to embrace the natural process of aging.`,
    open: false 
  },
  { 
    question: `How long will it take to overcome my fear of aging and gerascophobia?`, 
    answer: `The time it takes to overcome the fear of aging and gerascophobia varies from individual to individual. Factors such as the severity of the fear, personal circumstances, and consistency in listening to the program can affect the timeline for seeing results. We recommend listening to the program consistently and giving it time to work. Over time, you may notice a gradual shift in your mindset and perception of aging.`,
    open: false 
  },
  { 
    question: `Can the Fear of Aging and Gerascophobia Removal program cure my fear completely?`, 
    answer: `While the Fear of Aging and Gerascophobia Removal program is designed to help individuals overcome their fear of aging, the extent to which the fear is alleviated may vary from person to person. Results can depend on various factors, including personal circumstances and dedication to the program. It is important to approach the program with an open mind and realistic expectations.`,
    open: false 
  },
  { 
    question: `Can the Fear of Aging and Gerascophobia Removal program be used in conjunction with therapy?`, 
    answer: `Yes, the Fear of Aging and Gerascophobia Removal program can be used as a complementary tool to therapy or counseling. It can help reinforce positive beliefs and attitudes towards aging, providing additional support in overcoming gerascophobia. If you have concerns about your fear of aging, it is always advisable to consult with a qualified mental health professional.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the Fear of Aging and Gerascophobia Removal program?`, 
    answer: `There are no known risks or side effects associated with listening to the Fear of Aging and Gerascophobia Removal program. However, if you have any concerns about your mental health or specific fears, it is recommended to consult with a qualified mental health professional for personalized guidance and support.`,
    open: false 
  }
]
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>