<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Muscle pain can be uncomfortable and limit your ability to fully enjoy your daily activities. This subliminal is designed to help you relieve muscle pain and discomfort through healing and relaxation techniques."
    },
    {
      type: 'heading',
      text: 'How this subliminal can help:'
    },
    {
      type: 'list',
      items: [
        "Promote physical relaxation and help reduce muscle tension.",
        "Accelerate the healing process by supporting the body's natural repair mechanisms.",
        "Reduce inflammation and swelling, which can alleviate pain and discomfort.",
        "Enhance your body's ability to recover from physical activities and injuries.",
        "Encourage a positive mindset, which can aid in pain management and coping with discomfort."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can tap into the power of your subconscious mind to promote healing and relaxation within your muscles. This can lead to a reduction in pain, improved mobility, and an overall enhanced sense of well-being. Remember, consistency is key, so make sure to incorporate this subliminal into your routine for optimal results."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Finding relief from muscle pain and discomfort is essential for your overall well-being. This subliminal is designed to support your body's natural healing processes and promote relaxation. Before you start listening, here are some tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a peaceful environment: Find a quiet and comfortable space where you can relax and focus.",
        "Warm up your muscles: Prior to listening, consider gentle stretching or applying a warm compress to soothe the affected area.",
        "Set your intention: Clearly define your desire for healing and relief from muscle pain and discomfort.",
        "Visualize healing: Imagine your muscles becoming relaxed, rejuvenated, and free from pain as you listen to the subliminal affirmations."
      ]
    },
    {
      type: 'paragraph',
      text: "As you engage with this subliminal, allow the positive affirmations to sink deep into your subconscious, promoting healing and relaxation. By incorporating these preparation tips into your practice and listening regularly, you can support your body's healing process and experience relief from muscle pain. Embrace this opportunity to prioritize your well-being and begin your journey towards a pain-free life."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Muscle Pain Healing session! By dedicating time to relax and heal your muscles, you have taken an important step towards relieving pain and discomfort. Reflect on your experience and continue on your journey towards improved physical well-being."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice self-care: Take time to listen to your body's needs and provide it with the care it deserves.",
        "Incorporate stretches: Engage in gentle stretching exercises to release tension and improve flexibility.",
        "Apply heat or cold therapy: Use heating pads, warm baths, or ice packs to soothe sore muscles.",
        "Consider alternative therapies: Explore options like massage therapy, acupuncture, or foam rolling to complement your healing process."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, healing muscle pain is a gradual process, and each step you take towards self-care counts. Be patient with yourself and find the methods that work best for your body. With continued dedication, you'll find relief and embrace a more pain-free, active lifestyle. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Muscle Pain Healing subliminal help relieve muscle pain?`, 
    answer: `The Muscle Pain Healing subliminal is designed to promote relaxation, reduce muscle tension, and enhance the body's natural healing process. By listening to the subliminal regularly, you can align your subconscious mind with healing and relaxation, allowing your body to recover from muscle pain more effectively. It is important to note that the subliminal is intended to complement, not replace, any necessary medical treatments or therapies.`,
    open: false
  },
  { 
    question: `How long does it take to experience relief from muscle pain using the Muscle Pain Healing subliminal?`, 
    answer: `The time it takes to experience relief from muscle pain can vary depending on the individual and the severity of the pain. Some people may notice improvements in their symptoms within a few days of listening to the subliminal, while others may require longer. Consistency is key, so we recommend listening to the subliminal daily and giving your body time to heal naturally.`,
    open: false 
  },
  { 
    question: `Can the Muscle Pain Healing subliminal be used for chronic muscle pain?`, 
    answer: `The Muscle Pain Healing subliminal can be used as a complementary tool for managing chronic muscle pain. While it is not intended to replace medical treatments or therapies, the subliminal messages can help promote relaxation, reduce tension, and enhance the body's natural healing response. It is recommended to consult with a healthcare professional to develop a comprehensive treatment plan for chronic muscle pain.`,
    open: false 
  },
  { 
    question: `Are there any side effects of listening to the Muscle Pain Healing subliminal?`, 
    answer: `No, there are no known side effects associated with listening to the Muscle Pain Healing subliminal. However, if you have any concerns or underlying health conditions, it is always advisable to consult with a healthcare professional before incorporating new relaxation techniques into your routine.`,
    open: false 
  },
  { 
    question: `Can the Muscle Pain Healing subliminal be used alongside other treatments for muscle pain?`, 
    answer: `Yes, the Muscle Pain Healing subliminal can be used alongside other treatments for muscle pain. It is designed to complement existing therapies or treatments by promoting relaxation, reducing tension, and supporting the body's natural healing process. It is advisable to consult with a healthcare professional to determine the best combination of treatments for your specific needs.`,
    open: false 
  },
  { 
    question: `How often should I listen to the Muscle Pain Healing subliminal?`, 
    answer: `For best results, we recommend listening to the Muscle Pain Healing subliminal daily. Consistency is key in allowing the subliminal messages to influence your subconscious mind and promote relaxation and healing. You can listen to the subliminal at a time and in a setting that is most convenient and conducive to relaxation.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>