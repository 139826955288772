<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Developing self-love and acceptance is a journey that requires compassion and effort. This subliminal is designed to support you on that journey by helping you cultivate a deeper sense of self-worth and self-care."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help transform your relationship with yourself:'
    },
    {
      type: 'list',
      items: [
        "Encouraging positive self-talk and self-affirmations to boost your self-esteem.",
        "Assisting in letting go of self-doubt and embracing your unique qualities and strengths.",
        "Promoting forgiveness and healing from past mistakes or perceived flaws.",
        "Supporting the development of healthy boundaries and self-care practices.",
        "Empowering you to prioritize your needs and happiness without guilt or self-sacrifice."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to embrace self-love, acceptance, and care. As you cultivate a strong sense of self-worth, you may notice improved mental well-being, increased resilience, and healthier relationships with others. Remember, self-love is a lifelong practice, so be patient and kind to yourself throughout the journey."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Developing self-love is a powerful journey towards accepting yourself and nurturing a positive relationship with your inner self. This subliminal is designed to support you on this path of self-discovery and growth. Before you begin, consider the following strategies to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Reflect on what self-love means to you. Visualize the qualities you want to embody as you progress on this journey.",
        "Practice self-care: Dedicate time each day to engage in activities that bring you joy and prioritize your well-being.",
        "Challenge negative self-talk: Replace self-critical thoughts with affirmations of self-love and acceptance.",
        "Surround yourself with positivity: Create a supportive environment by connecting with loved ones or engaging in uplifting content.",
        "Create a gratitude practice: Focus on acknowledging and appreciating your strengths, accomplishments, and the things you love about yourself."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal, allow the affirmations to penetrate your subconscious mind, helping you cultivate a deep sense of self-love and acceptance. Embrace this opportunity to embark on a transformative journey towards embracing your true self and living a life filled with compassion, self-care, and empowerment. Let's begin!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Self-Love subliminal session! You've taken an important step towards developing a deeper sense of self-acceptance, self-worth, and self-care. It's time to reflect on your experience and continue nurturing a positive relationship with yourself."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice self-reflection: Explore your thoughts, feelings, and experiences through journaling or meditation.",
        "Celebrate your strengths: Recognize and appreciate your unique qualities and abilities.",
        "Engage in self-care activities: Prioritize activities that make you feel good and rejuvenated.",
        "Set boundaries: Learn to say no when necessary and protect your mental and emotional well-being."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, cultivating self-love is an ongoing process that requires patience and compassion. Embrace yourself fully, flaws and all, and continue to prioritize your emotional well-being. You are valuable, deserving of love, and capable of incredible growth. Keep shining!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Self-Love subliminal help me?`, 
    answer: `The Self-Love subliminal is designed to empower you with positive affirmations and messages that promote self-acceptance, self-worth, and self-care. By listening to the subliminal regularly, you can train your subconscious mind to cultivate a stronger sense of self-love and develop a healthier relationship with yourself. Over time, you may notice increased self-confidence, improved self-esteem, and a more positive outlook on life.`,
    open: false
  },
  { 
    question: `How long does it take to build self-love with the Self-Love subliminal?`, 
    answer: `The time it takes to build self-love using the Self-Love subliminal can vary from person to person. Some individuals may notice positive changes right away, while others may require more time and consistent listening to rewire their mindset. It is recommended to listen to the subliminal daily and consistently for best results, allowing the affirmations to penetrate your subconscious mind deeply.`,
    open: false 
  },
  { 
    question: `Can the Self-Love subliminal help me overcome negative self-talk and self-criticism?`, 
    answer: `Yes, the Self-Love subliminal can help you overcome negative self-talk and self-criticism by promoting positive and empowering thoughts about yourself. It aims to rewire your subconscious mind to replace self-critical thoughts with self-compassionate and self-affirming ones. By consistently listening to the subliminal, you can cultivate a kinder and more loving inner dialogue.`,
    open: false 
  },
  { 
    question: `Is self-love the same as being selfish?`, 
    answer: `No, self-love is not the same as being selfish. Self-love involves recognizing your own worth, taking care of yourself, and prioritizing your mental, emotional, and physical well-being. It is about setting boundaries and making choices that are in alignment with your values and needs. Self-love also enables you to show up as the best version of yourself for others.`,
    open: false 
  },
  { 
    question: `Can the Self-Love subliminal replace therapy or professional help?`, 
    answer: `No, the Self-Love subliminal is not a substitute for therapy or professional help. While it can be a beneficial tool to complement self-improvement journeys, it is important to seek professional assistance if you are dealing with deep-rooted issues or mental health concerns. The subliminal is intended to support and enhance your personal growth, but it should not replace necessary professional support.`,
    open: false 
  },
  { 
    question: `Will listening to the Self-Love subliminal make me arrogant or overly self-focused?`, 
    answer: `No, the goal of the Self-Love subliminal is to help you cultivate a healthy and balanced sense of self-worth and self-care, not to promote arrogance or excessive self-focused behavior. It aims to foster a genuine and compassionate love for oneself, which can positively impact your relationships, personal growth, and overall well-being.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>