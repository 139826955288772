<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you've been cracking your fingers or knuckles and want to break the habit, this subliminal can help. It targets your subconscious mind to help you overcome the urge to crack your fingers and knuckles."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help you:"
    },
    {
      type: 'list',
      items: [
        "Reduce the desire to crack your fingers and knuckles, leading to improved hand health.",
        "Break the habit of finger and knuckle cracking by reprogramming your subconscious mind.",
        "Prevent potential joint damage caused by excessive cracking.",
        "Improve your hand mobility and prevent stiffness or discomfort.",
        "Enhance your overall hand hygiene and appearance."
      ]
    },
    {
      type: 'paragraph',
      text: "With consistent use of this subliminal, you can gradually overcome the urge to crack your fingers and knuckles. Remember, breaking a habit takes time and patience, but with commitment, you can achieve healthier hand habits and improve your hand health."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Cracking your fingers and knuckles can become an unconscious habit that is hard to break. This subliminal is designed to help you overcome this habit and improve the health of your hands. Before you start listening, consider these tips to enhance your progress:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Increase awareness: Pay attention to when and why you feel the urge to crack your fingers or knuckles. Understanding your triggers can help in finding alternative coping mechanisms.",
        "Find substitutes: Keep stress balls, fidget spinners, or other objects nearby to provide a healthy outlet for your fingers.",
        "Practice relaxation techniques: Deep breathing, mindfulness, or other relaxation exercises can help reduce the need to crack your fingers or knuckles as a stress response.",
        "Set realistic goals: Gradually decrease the frequency of cracking and celebrate small victories along the way.",
        "Remind yourself of the benefits: Visualize your hands in a healthier state and focus on the positive outcomes of breaking this habit."
      ]
    },
    {
      type: 'paragraph',
      text: "Breaking the habit of cracking your fingers and knuckles is an achievable goal, and this subliminal can support you in your journey towards healthier hand habits. With time and consistency, you can improve the health and well-being of your hands. Take a moment to set your intention, stay committed, and let's begin!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Great job completing the Stop Cracking Fingers and Knuckles subliminal session! By deciding to break this habit, you've taken an important step towards improving your hand health and overall well-being. Now, it's time to reflect on your experience and continue your journey towards healthier habits."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Be mindful of triggers: Identify situations or moments that usually lead to cracking your fingers and knuckles.",
        "Find alternative ways to relieve stress or boredom: Discover new activities or stress-relief techniques that don't involve cracking your fingers.",
        "Practice finger and hand stretches: Incorporate exercises that improve flexibility and reduce tension in your hands.",
        "Stay consistent with your resolve: Remember why you chose to break this habit and continue to make conscious efforts to resist the urge."
      ]
    },
    {
      type: 'paragraph',
      text: "Breaking a habit takes time and patience, but by staying focused and determined, you can overcome it. Believe in yourself and your ability to make positive changes. Remember, every moment you resist the urge to crack your fingers is a step towards healthier hands and a healthier you. Keep up the good work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  {
    question: `Why is cracking my fingers and knuckles harmful?`,
    answer: `Cracking your fingers and knuckles can potentially lead to joint damage, ligament injuries, and reduced grip strength over time. The habit may also be associated with inflammation and swelling in the affected joints. It is best to break the habit to maintain optimal hand health.`,
    open: false
  },
  {
    question: `Is cracking my fingers and knuckles addictive?`,
    answer: `While cracking your fingers and knuckles may provide temporary relief or satisfaction, it is not considered addictive in the traditional sense. It is often a habit that can be broken with awareness and determination. Establishing alternative ways to cope with stress or discomfort can help you overcome the habit.`,
    open: false
  },
  {
    question: `How can I stop cracking my fingers and knuckles?`,
    answer: `Breaking the habit of cracking your fingers and knuckles starts with awareness and self-discipline. You can try replacing the habit with healthier alternatives, such as stretching your fingers or engaging in stress-relieving exercises. Working on improving your hand health through regular hand exercises and maintaining proper posture can also help reduce the desire to crack your fingers and knuckles.`,
    open: false
  },
  {
    question: `Will the Stop Cracking Fingers and Knuckles subliminal instantly eliminate the habit?`,
    answer: `Results can vary from person to person, but the Stop Cracking Fingers and Knuckles subliminal can help you reprogram your subconscious mind to reduce the habit over time. Consistent listening to the subliminal can support your commitment to breaking the habit, but it may require additional effort and determination on your part as well.`,
    open: false
  },
  {
    question: `Are there any side effects from using the Stop Cracking Fingers and Knuckles subliminal?`,
    answer: `The Stop Cracking Fingers and Knuckles subliminal is designed to be safe without any known side effects. However, if you have any concerns about your hand health or any existing conditions, it is advisable to consult with a healthcare professional before practicing any new methods to break the habit.`,
    open: false
  },
  { 
    question: `Can the subliminal be used for other habits or addictions?`, 
    answer: `The Stop Cracking Fingers and Knuckles subliminal is specifically designed to address the habit of cracking fingers and knuckles. While it may involve similar principles as other habit-breaking subliminals, it is recommended to find specific subliminals or resources tailored to address other habits or addictions you may be seeking to overcome.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>