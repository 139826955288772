<template>
  <DialogBack
    :dialog="dialog"
    :width="500"
    :button="true"
    @save="save"
    @closeDialog="closeDialog"
  >
    <div v-if="thisdialog == 1" class="mx-10 mb-7 text-center">
      <div class="d-flex align-center justify-center mb-5">
        <div v-if="cover != ''">
          <img :src="cover" class="big-imgs" />
          <v-icon @click="handleRemove(files)" class="imgdelete"
            >mdi-delete</v-icon
          >
        </div>
        <div v-else>
          <el-upload
            ref="upload"
            :class="`${uploads ? ' upload' : ''}`"
            :action="`${url}/api/local/image`"
            list-type="picture-card"
            :limit="1"
            :multiple="false"
            :on-change="handleChange"
            :on-remove="handleRemove"
          >
            <img class="el-upload-list__item-thumbnail" :src="files.url" />
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>
        </div>
      </div>

      <div class="d-flex">
        <img src="@/assets/images/user/profile/edit_white.png" class="img3" />
        <h5>USER DETAILS</h5>
      </div>
      <h1 class="label">Display Name</h1>
      <v-text-field
        class="searching1 d-flex"
        label="Display Name"
        solo
        dense
        v-model="display_name"
      ></v-text-field>
      <h1 class="label">First Name</h1>
      <v-text-field
        class="searching1 d-flex"
        label="First Name"
        solo
        dense
        v-model="first_name"
      ></v-text-field>
      <h1 class="label">Last Name</h1>
      <v-text-field
        class="searching1 d-flex"
        label="Last Name"
        solo
        dense
        v-model="last_name"
      ></v-text-field>
      <h1 class="label">Email</h1>
      <v-text-field
        class="searching1 d-flex"
        label="Email"
        solo
        dense
        disabled
        v-model="email"
      ></v-text-field>

      <h5 class="h52">ACCOUNT PRIVACY</h5>
      <div
        v-if="provider == null"
        @click="changepass"
        class="d-flex"
        style="cursor: pointer"
      >
        <img src="@/assets/images/user/profile/change.png" class="img3" />
        <h6 class="h6-label">Change Password</h6>
      </div>
      <div @click="thisdialog = 3" class="d-flex" style="cursor: pointer">
        <img src="@/assets/images/user/profile/delete.png" class="img3" />
        <h6 class="h6-label">Delete Account</h6>
      </div>
    </div>
    <div v-else-if="thisdialog == 2" class="mx-10 mb-7 text-center">
      <div class="d-flex">
        <img src="@/assets/images/user/profile/edit_white.png" class="img3" />
        <h6>ACCOUNT PRIVACY > Change Password</h6>
      </div>
      <h1 class="label">Current Password</h1>
      <v-text-field
        class="searching1 d-flex"
        solo
        dense
        placeholder="Current Password"
        :type="showCurrentPassword ? 'text' : 'password'"
        @click:append="showCurrentPassword = !showCurrentPassword"
        :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
        v-model="current_password"
      ></v-text-field>

      <h1 class="label">New Password</h1>
      <v-text-field
        class="searching1 d-flex"
        solo
        dense
        placeholder="New Password"
        :type="showNewPassword ? 'text' : 'password'"
        @click:append="showNewPassword = !showNewPassword"
        :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
        v-model="new_password"
      ></v-text-field>

      <h1 class="label">Confirm New Password</h1>
      <v-text-field
        class="searching1 d-flex"
        solo
        dense
        placeholder="Confirm New Password"
        :type="showConfirmPassword ? 'text' : 'password'"
        @click:append="showConfirmPassword = !showConfirmPassword"
        :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
        v-model="confirm_password"
      ></v-text-field>
      <p class="p-style" style="color: red; text-align: right">{{ message2 }}</p>

      <div class="mt-10">
        <p class="p-style" style="text-align: left">Password must:</p>
        <div class="d-flex">
          <v-icon v-if="chars == true">mdi-check</v-icon>
          <v-icon v-else style="color: red">mdi-window-close</v-icon>
          <span>Contain at least 8 characters</span>
        </div>
        <div class="d-flex">
          <v-icon v-if="number == true">mdi-check</v-icon>
          <v-icon v-else style="color: red">mdi-window-close</v-icon>
          <span>Include at least one number (0-9)</span>
        </div>
        <div class="d-flex">
          <v-icon v-if="upper == true">mdi-check</v-icon>
          <v-icon v-else style="color: red">mdi-window-close</v-icon>
          <span>Include at least one uppercase letter (A-Z)</span>
        </div>
      </div>
    </div>
    <div v-else class="mx-10 mb-7 text-center">
      <div class="d-flex">
        <img src="@/assets/images/user/profile/edit_white.png" class="img3" />
        <h6>ACCOUNT PRIVACY > Delete Account</h6>
      </div>
      <div class="mx-auto text-center">
        <img
          class="delete-1 mt-2"
          src="@/assets/images/user/profile/delete_can.png"
          alt=""
        />
        <h6 class="del-label mt-5">
          Are you sure you want to delete your account?
        </h6>
        <button
          v-if="proceed == false"
          @click="proceed = true"
          class="button btn btn-lg"
          style="color: white; background: #1c8edf"
        >
          Proceed
        </button>
        <div v-else-if="proceed == true && provider == null" class="mt-5">
          <h1 class="label">Current Password</h1>
          <v-text-field
            class="searching1 d-flex"
            solo
            dense
            placeholder="Current Password"
            :type="showPass_delete ? 'text' : 'password'"
            @click:append="showPass_delete = !showPass_delete"
            :append-icon="showPass_delete ? 'mdi-eye' : 'mdi-eye-off'"
            v-model="pass_delete"
          ></v-text-field>
          <p class="p-style" style="color: red; text-align: right">{{ message_del }}</p>
          <button
            @click="deleteUser()"
            class="button btn btn-lg mt-5"
            style="background: #1c8edf; color: white"
          >
            Confirm
          </button>
        </div>
        <div v-else class="mt-5">
          <button
            @click="deleteUser2()"
            class="button btn btn-lg mt-5"
            style="background: #1c8edf; color: white"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </DialogBack>
</template>

<script>
import { updateUserInfo, updateUserInfoImage } from "@/api/userinfo.js";
import { getMessage, url } from "@/utils";
import { deleteLocalImage } from "@/api/file.js";

import {
  userUpdatePassword,
  deleteUserEmail,
  deleteUserEmailProvider,
} from "@/api/user";

import DialogBack from "@/components/DialogBack.vue";

export default {
  props: {
    dialog: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data: () => {
    return {
      getMessage,
      url,
      updateUserInfo,
      updateUserInfoImage,
      deleteUserEmail,
      deleteUserEmailProvider,
      userUpdatePassword,
      deleteLocalImage,

      display_name: "",
      first_name: "",
      last_name: "",
      email: "",
      thisdialog: 1,

      current_password: "",
      new_password: "",
      confirm_password: "",
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      message1: "",
      message_del: "",
      message2: "",
      chars: false,
      upper: false,
      lower: false,
      number: false,
      same: false,
      message: false,
      proceed: false,
      pass_delete: "",
      showPass_delete: false,
      image: null,
      cover: "",
      uploads: false,

      files: [],
    };
  },
  mounted() {},
  components: {
    DialogBack,
  },
  computed: {
    user() {
      return JSON.parse(this.$store.state.user.info);
    },
    covers() {
      return this.user != null
        ? this.user.info.cover
        : `${url}/api/image/default.png`;
    },
    cover_img() {
      return this.$store.state.user.cover;
    },
    provider() {
      return this.user != null ? this.user.provider : null;
    },
  },
  mounted() {
    var self = this;
    setTimeout(() => {
      self.first_name = self.user.info.first_name;
      self.email = self.user.email;
      self.last_name = self.user.info.last_name;
      self.display_name = self.user.name;
      if (self.cover_img == null) {
        self.cover = self.user.info.cover;
      } else {
        self.cover = self.cover_img;
      }
      self.getPicture();
    }, 0);
  },
  watch: {
    current_password(value) {
      if (value != "") {
        this.message = "";
        this.validatePassword(value);
      }
    },
    new_password(value) {
      if (value != "") {
        this.message1 = "";
        this.validatePassword(value);
        if (this.confirm_password != "") {
          this.validateSamePassword();
        }
      }
    },
    confirm_password(value) {
      if (value != "") {
        this.message2 = "";
        this.validatePassword(value);
        if (this.new_password != "") {
          this.validateSamePassword();
        }
      }
    },
    pass_delete(value) {
      if (value != "") {
        this.message2 = "";
        this.validatePassword2(value);
      }
    },
  },
  methods: {
    handleChange(file) {
      this.uploads = true;
      this.files = file;
      this.image = file.name;
    },
    handleRemove(file) {
      this.deleteLocalImage(file.name);
      this.uploads = false;

      this.files = [];
      this.cover = "";
      this.getImageName("");
    },
    getPicture() {
      if (this.files.length == 0) {
        this.files.push({
          name: "mood",
          url: this.user.info.cover,
        });
      }
    },
    getImageName(value) {
      if (this.cover == "") {
        this.image = "default.png";
      } else {
        this.image = value;
      }
    },
    validateSamePassword() {
      if (this.new_password == this.confirm_password) {
        this.same = true;
      } else {
        this.same = false;
      }
    },
    closeDialog() {
      if (this.thisdialog == 1) {
        if (this.cover_img == null) {
          this.cover = this.user.info.cover;
        } else {
          this.cover = this.cover_img;
        }
        this.$emit("closeDialog", false);
      } else {
        this.thisdialog = 1;
        this.proceed = false;
      }
    },
    changepass() {
      this.thisdialog = 2;
    },
    save() {
      if (this.thisdialog == 1) {
        this.updateUserInfos();
      } else if (this.thisdialog == 2) {
        if (
          this.chars == false ||
          this.number == false ||
          this.upper == false ||
          this.lower == false
        ) {
        } else {
          this.updatePassword();
        }
      } else {
      }
    },
    updatePassword() {
      if (this.new_password != this.confirm_password) {
        this.message2 = "Confirm Password does not match";
      } else {
        this.message2 = "";
        const data = {
          user_id: this.user.user_id,
          new_password: this.new_password,
          current_password: this.current_password,
          new_password_confirmation: this.confirm_password,
        };

        this.userUpdatePassword(data).then((res) => {
          this.getMessage(this, res);
          this.confirm_password = null;
          this.new_password = null;
          this.current_password = null;
          this.lower = false;
          this.chars = false;
          this.number = false;
          this.upper = false;
        });
      }
    },
    updateUserInfos() {
      const info = {
        file: this.image,
        last_name: this.last_name,
        user_id: this.user.user_id,
        name: this.display_name,
        first_name: this.first_name,
      };

      this.updateUserInfoImage(info).then((res) => {
        const { success } = res;

        if (success) {
          this.$store.dispatch("screen/getLoading", true);
          setTimeout(() => {
            this.user.info.last_name = this.last_name;
            this.user.info.first_name = this.first_name;
            this.user.name = this.display_name;
            this.$store.dispatch("user/getCover", res.data[0].cover);
            localStorage.setItem("cover", res.data[0].cover);
            localStorage.setItem("user", JSON.stringify(this.user));
            this.$store.dispatch("user/getInfo", JSON.stringify(this.user));
            this.$store.dispatch(
              "user/getLocalInfo",
              JSON.stringify(this.user)
            );

            this.$emit("closeDialog", false);
            this.getMessage(this, res);
            this.$store.dispatch("screen/getLoading", false);
          }, 5000);
        }
      });
    },
    deleteUser() {
      const info = {
        name: this.user.name,
        email: this.user.email,
        user_id: this.user.user_id,
        password: this.pass_delete,
      };
      this.deleteUserEmail(info).then((res) => {
        this.getMessage(this, res);
        if (res.success == true) {
          this.$store.dispatch("screen/getLoading", true);
          setTimeout(() => {
            this.$store.dispatch("user/getInfo", {});
            this.$store.dispatch("user/getToken", null);
            localStorage.removeItem("user");
            localStorage.removeItem("user_token");
            localStorage.removeItem("user_track");

            this.$router.push(`/login?type=user`);
            this.$store.dispatch("screen/getLoading", false);
            this.$store.dispatch("audio/getData", []);
            this.$store.dispatch("audio/getDatas", []);
          }, 3000);
        } else {
          this.pass_delete = "";
        }
      });
    },
    deleteUser2() {
      const info = {
        name: this.user.name,
        email: this.user.email,
        user_id: this.user.user_id,
      };
      this.deleteUserEmailProvider(info).then((res) => {
        this.getMessage(this, res);
        if (res.success == true) {
          this.$store.dispatch("screen/getLoading", true);
          setTimeout(() => {
            this.$store.dispatch("user/getInfo", {});
            this.$store.dispatch("user/getToken", null);
            localStorage.removeItem("user");
            localStorage.removeItem("user_token");
            localStorage.removeItem("user_track");

            this.$router.push(`/login?type=user`);
            this.$store.dispatch("screen/getLoading", false);
            this.$store.dispatch("audio/getData", []);
            this.$store.dispatch("audio/getDatas", []);
          }, 3000);
        } else {
          this.pass_delete = "";
        }
      });
    },
    validatePassword(password) {
      if (/[0-9]/.test(password)) {
        this.number = true;
      } else {
        this.number = false;
      }

      if (/[a-z]/.test(password)) {
        this.lower = true;
      } else {
        this.lower = false;
      }

      if (password.length < 8) {
        this.chars = false;
      } else {
        this.chars = true;
      }

      if (/[A-Z]/.test(password)) {
        this.upper = true;
      } else {
        this.upper = false;
      }
    },
    validatePassword1(password) {
      if (password.length < 8) {
        this.message1 = "Min 8 characters";
      } else {
        if (/[0-9]/.test(password)) {
          if (/[a-z]/.test(password)) {
            if (/[A-Z]/.test(password)) {
              this.message1 = "";
            } else {
              this.message1 = "At least one uppercase letter";
            }
          } else {
            this.message1 = "At least one lowercase letter";
          }
        } else {
          this.message1 = "At least one number";
        }
      }
    },
    validatePassword2(password) {
      if (password.length < 8) {
        this.message_del = "Min 8 characters";
      } else {
        if (/[0-9]/.test(password)) {
          if (/[a-z]/.test(password)) {
            if (/[A-Z]/.test(password)) {
              this.message_del = "";
            } else {
              this.message_del = "At least one uppercase letter";
            }
          } else {
            this.message_del = "At least one lowercase letter";
          }
        } else {
          this.message_del = "At least one number";
        }
      }
    },
  },
};
</script>

<style>
.big-imgs {
  height: 145px;
  width: 145px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
}
.h52 {
  margin-top: 40px;
  text-align: left;
}
.h6-label {
  margin-top: 5px;
  font-family: "Montserrat-SemiBold";
}
.delete-1 {
  height: 150px;
}
.del-label {
  font-family: "Montserrat-Bold";
}
.label {
  font-family: "Montserrat-Regular";
  text-align: left;
  margin-top: 10px;
  font-size: 12px;
  margin-bottom: -1px;
}
.imgdelete {
  width: 30px;
  height: 30px;
  margin-top: 90px;
  margin-left: -20px;
  background: white;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
}
.img3 {
  height: 15px;
  margin-right: 10px;
  margin-top: 5px;
}
.searching1 {
  width: 100%;
  margin-left: 10%;
  height: 40px;
  background: rgba(244, 238, 238, 0.4);
  border-radius: 5px;
  font-family: "Montserrat-Regular";
}
.content {
  height: 40px;
  margin: auto;
  margin-top: -15px;
}
.message {
  background: #eafbf6;
}
.message,
.errors {
  border-radius: 5px;
  margin: auto;
  height: 40px;
  padding: 10px 15px;
  font-family: "Montserrat-Regular";
  text-align: center;
}
.errors {
  background: #fdedee;
}
.wrapper {
  margin-top: 10px;
}
.icon {
  height: 20px;
}
.check {
  height: 17px;
}
.cover {
  height: 70px;
  width: 80px;
  border-radius: 5px;
  margin-right: 10px;
}
.search {
  padding-left: 10px;
  width: 100%;
  margin: auto;
  margin-top: -10px;
  margin-bottom: 10px;
  border-radius: 10px;
  border: solid 0.5px black;
  height: 40px;
  background: #f4eeee;
  font-family: "Montserrat-Regular";
}
.p-style {
  font-size: 10px;
  padding-right: 20px;
  font-family: "Montserrat-Regular";
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.upload > div {
  margin: 0px;
  padding: 0px;
}

.upload .el-upload {
  display: none;
}
</style>
