export const playlist = {
  namespaced: true,
  state: {
    id: "",
    title: "",

    own: {
      data: [],
      loading: true,
      updated: false,
    },
    playlist: {
      data: [],
      loading: true,
    },

    playlistIDs: [],
    playlistInfoLists: [],
  },
  getters: {
    getPlaylistIDs(state) {
      return state.playlistIDs;
    },
    getPlaylistInfoLists(state) {
      return state.playlistInfoLists;
    },
  },
  actions: {
    getId({ commit }, data) {
      commit("getId", data);
    },
    getTitle({ commit }, data) {
      commit("getTitle", data);
    },
    getData({ commit }, data) {
      commit("getData", data);
    },
    getOwnData({ commit }, data) {
      commit("getOwnData", data);
    },
    getLoading({ commit }, data) {
      commit("getLoading", data);
    },
    getOwnLoading({ commit }, data) {
      commit("getOwnLoading", data);
    },
    getOwnUpdate({ commit }, data) {
      commit("getOwnUpdate", data);
    },
    playlistIDs({ commit }, data) {
      commit("setPlaylistIDs", data);
    },
    playlistInfoLists({ commit }, data) {
      commit("setPlaylistInfoLists", data);
    },
  },
  mutations: {
    getId(state, data) {
      state.id = data;
    },
    getTitle(state, data) {
      state.title = data;
    },
    getData(state, data) {
      state.playlist.data = data;
    },
    getOwnData(state, data) {
      state.own.data = data;
    },
    getLoading(state, data) {
      state.playlist.loading = data;
    },
    getOwnLoading(state, data) {
      state.own.loading = data;
    },
    getOwnUpdate(state, data) {
      state.own.updated = data;
    },
    setPlaylistIDs(state, data) {
      state.playlistIDs = data;
    },
    setPlaylistInfoLists(state, data) {
      state.playlistInfoLists = data;
    },
  },
};
