<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Bed wetting can be an embarrassing and inconvenient problem, but with this subliminal, you can train your body to stay dry throughout the night. By targeting your subconscious mind, it helps reprogram your body's response to nighttime bladder control."
    },
    {
      type: 'heading',
      text: 'Here are some key benefits of using this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Reducing the frequency or completely stopping bed wetting episodes.",
        "Training your body to recognize feelings of a full bladder during sleep and waking up to use the restroom.",
        "Building confidence and reducing embarrassment associated with bed wetting.",
        "Promoting a sense of independence and self-control.",
        "Improving quality of sleep by ensuring you stay dry through the night."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your routine, you can work towards overcoming bed wetting and enjoying dry nights. Remember, results may vary, and it may take time to see significant improvements. Consistency is key, so make sure to listen regularly to maximize the benefits."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "If you're struggling with bed wetting and looking for ways to overcome it, this subliminal can help you on your journey to staying dry throughout the night. Before you start listening, consider these tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Stay positive: Believe that you have the power to overcome bed wetting and stay dry throughout the night.",
        "Talk to a healthcare professional: Consider speaking with a doctor or therapist who can provide guidance and support.",
        "Establish a bedtime routine: Create a consistent routine that helps your body relax and prepare for sleep.",
        "Practice bladder control exercises: Strengthen your pelvic floor muscles by practicing exercises recommended by healthcare professionals.",
        "Set realistic goals: Understand that progress takes time and be patient with yourself as you work towards staying dry throughout the night."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to help reprogram your subconscious mind and support you in overcoming bed wetting. With regular listening and a positive mindset, you can make progress and experience the freedom and confidence of staying dry throughout the night. Embrace this opportunity to take control of your situation and start your journey towards a bed-wetting-free life."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Stop Bed Wetting subliminal session! You've taken a courageous step towards overcoming the embarrassment and inconvenience of bed wetting. Reflect on your journey and the positive changes you can make to train your body to stay dry throughout the night."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Consult a healthcare professional: Reach out to a doctor or specialist who can provide guidance and support.",
        "Establish a bedtime routine: Create a consistent routine that includes bathroom breaks before sleep.",
        "Limit fluid intake before bed: Avoid drinking excessive fluids close to bedtime to reduce the risk of bed wetting.",
        "Use absorbent materials: Consider using absorbent products or protective bedding to manage accidents.",
        "Practice relaxation techniques: Engage in relaxation exercises like deep breathing or meditation before bed."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming bed wetting may take time, but you're on the right path. Stay committed to your efforts and be patient with yourself. With the right support and determination, you can achieve dry nights and regain your confidence. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Stop Bed Wetting subliminal work?`, 
    answer: `The Stop Bed Wetting subliminal uses positive affirmations and calming background sounds to influence your subconscious mind. By listening to the audio regularly, the subliminal messages help reprogram your thoughts and behaviors related to staying dry throughout the night. It aims to create new patterns and habits in your mind, promoting dry nights and overcoming bed wetting.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Stop Bed Wetting subliminal?`, 
    answer: `Results can vary depending on the individual and their unique circumstances. Some people may start seeing improvements in bed wetting within a few weeks of consistent listening, while others may require more time. It is important to remember that consistency is key. We recommend listening to the subliminal daily and consistently for best results.`,
    open: false 
  },
  { 
    question: `Can the Stop Bed Wetting subliminal be used for both children and adults?`, 
    answer: `Yes, the Stop Bed Wetting subliminal can be used by both children and adults. The positive affirmations and soothing sounds are designed to reprogram the subconscious mind, helping individuals of all ages overcome bed wetting and promoting dry nights.`,
    open: false 
  },
  { 
    question: `Is it necessary to listen to the Stop Bed Wetting subliminal every night?`, 
    answer: `Consistency is important when listening to the Stop Bed Wetting subliminal. To reinforce positive changes in your subconscious mind, it is recommended to listen to the audio every night before going to bed. This regular practice can help establish new patterns and habits and increase the effectiveness of the subliminal messages.`,
    open: false 
  },
  { 
    question: `Can the Stop Bed Wetting subliminal replace other treatment options?`, 
    answer: `The Stop Bed Wetting subliminal is designed as a supplement to other treatment options and strategies for overcoming bed wetting. It can be used in conjunction with medical advice, therapy, or lifestyle changes recommended by healthcare professionals. It is important to consult with a healthcare provider for a comprehensive approach to addressing bed wetting.`,
    open: false 
  },
  { 
    question: `Are there any side effects of listening to the Stop Bed Wetting subliminal?`, 
    answer: `No known side effects have been associated with listening to the Stop Bed Wetting subliminal. However, if you have any concerns or underlying medical conditions, it is advisable to consult with a healthcare professional before incorporating the subliminal into your routine.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>