<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're looking to enhance your creativity, problem-solving skills, and innovative thinking, this subliminal can help you think outside the box."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Expand your creativity and unlock new ideas and perspectives.",
        "Improve your problem-solving skills and approach challenges with innovative thinking.",
        "Enhance your ability to find unique and creative solutions to problems.",
        "Boost your confidence in your creative abilities and trust your intuition.",
        "Develop a flexible mindset and embrace new possibilities and opportunities."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can rewire your mind to think in unconventional ways and break free from limitations. Allow your creativity to flow and approach challenges with a fresh perspective. Remember, embracing innovative thinking is an ongoing process, so make listening to this subliminal a part of your routine to experience lasting changes in your mindset and approach to problem-solving."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Unlocking your creative potential and developing innovative thinking skills can transform the way you approach challenges and find solutions. This subliminal is designed to help you think outside the box and tap into your creativity. Before you begin, here are some suggestions to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a positive mindset: Believe in your ability to think creatively and solve problems in unique ways.",
        "Find inspiration: Surround yourself with inspiring materials, such as books, art, or music, that spark creativity.",
        "Embrace curiosity: Stay open-minded and explore new ideas and perspectives.",
        "Practice mindfulness: Be present and aware of your thoughts, allowing space for creative insights to emerge."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal, allow the affirmations to work on your subconscious mind, helping to rewire your thinking patterns and unleash your creative potential. Embrace the journey of expanding your imagination, problem-solving skills, and seeing possibilities where others may not. Get ready to think outside the box and let your creativity soar!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Thinking Outside the Box subliminal! By investing in your creative thinking and problem-solving skills, you've taken a powerful step towards personal growth and innovation. Now, it's time to reflect on your experience and continue cultivating your creative mindset."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Embrace curiosity: Stay open-minded and welcome new ideas and perspectives.",
        "Practice brainstorming: Engage in regular brainstorming sessions to generate innovative solutions.",
        "Step out of your comfort zone: Seek new experiences and challenges to stimulate your creativity.",
        "Embrace failure: Don't be afraid to make mistakes and learn from them. Failure can often lead to breakthroughs." 
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, creative thinking is a skill that can be developed over time. Embrace the process of exploring new ideas, thinking outside the box, and approaching challenges with fresh perspectives. With dedication and practice, you'll find yourself thinking more creatively and finding innovative solutions in no time. Keep pushing the boundaries!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Thinking Outside the Box subliminal help me become more creative?`, 
    answer: `The Thinking Outside the Box subliminal is designed to stimulate your subconscious mind and encourage creative thinking. By listening to the subliminal messages, you can enhance your ability to generate new ideas, think divergently, and approach challenges from different perspectives. Over time, consistent use of the subliminal can help expand your creativity and innovative problem-solving skills.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Thinking Outside the Box subliminal?`, 
    answer: `The results from the Thinking Outside the Box subliminal may vary between individuals. Some people may start noticing a positive shift in their creativity and problem-solving abilities soon after starting to listen, while others may require more time and consistent use to see significant changes. We recommend listening to the subliminal daily and being open to new ideas and perspectives to maximize its effect.`,
    open: false 
  },
  { 
    question: `Can the Thinking Outside the Box subliminal improve critical thinking skills?`, 
    answer: `Yes, the Thinking Outside the Box subliminal can help improve critical thinking skills by encouraging you to think more creatively and explore different solutions to problems. It aims to expand your perspective and encourage innovative thinking, which are key components of critical thinking. Consistent use of the subliminal can help you develop a more open and flexible mindset when approaching complex situations.`,
    open: false 
  },
  { 
    question: `Is it necessary to have prior creative skills or experience to benefit from the Thinking Outside the Box subliminal?`, 
    answer: `No, it is not necessary to have prior creative skills or experience to benefit from the Thinking Outside the Box subliminal. The subliminal is designed to stimulate your creativity and problem-solving abilities, regardless of your past experience. It can help you tap into your innate creativity and develop new ways of thinking and approaching challenges.`,
    open: false 
  },
  { 
    question: `Are there any side effects of listening to the Thinking Outside the Box subliminal?`, 
    answer: `There are no known negative side effects of listening to the Thinking Outside the Box subliminal. However, it is important to note that the subliminal is designed to enhance your creativity and problem-solving skills as a supplement to your existing mindset and practices. It is not intended to replace professional advice or treatment for any specific condition or issue.`,
    open: false 
  },
  { 
    question: `Can the Thinking Outside the Box subliminal be used by children or teenagers?`, 
    answer: `The Thinking Outside the Box subliminal is suitable for individuals of various ages, including children and teenagers. However, it is recommended that parents or guardians supervise and assess whether the content is appropriate for their child's age and maturity level. It is always best to consult with a healthcare professional or therapist if there are any concerns regarding the use of subliminals for children or teenagers.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>