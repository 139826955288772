import { render, staticRenderFns } from "./104-fear-of-sickness-and-diseases-removal.vue?vue&type=template&id=25227ff3&scoped=true&"
import script from "./104-fear-of-sickness-and-diseases-removal.vue?vue&type=script&lang=js&"
export * from "./104-fear-of-sickness-and-diseases-removal.vue?vue&type=script&lang=js&"
import style0 from "./104-fear-of-sickness-and-diseases-removal.vue?vue&type=style&index=0&id=25227ff3&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25227ff3",
  null
  
)

export default component.exports