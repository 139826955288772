<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you've always wanted to solve the Rubik's Cube or improve your skills, this subliminal can help you achieve that. By working on your subconscious mind, it enhances your problem-solving abilities and helps you become a master of this iconic puzzle."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can benefit you:"
    },
    {
      type: 'list',
      items: [
        "Developing a deeper understanding of the Rubik's Cube and its solving techniques.",
        "Improving your spatial awareness and logical reasoning skills.",
        "Enhancing your ability to recognize patterns and algorithms.",
        "Increasing your speed and efficiency in solving the Rubik's Cube.",
        "Building confidence and motivation to tackle more challenging cube variations."
      ]
    },
    {
      type: 'paragraph',
      text: "With regular use, this subliminal can help you sharpen your Rubik's Cube skills and achieve impressive solving times. As you continue to practice and train, you'll notice significant improvements in your problem-solving abilities and overall mastery of the puzzle. Remember, consistent dedication and practice are key to becoming a Rubik's Cube master."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "If you're ready to become a master at solving the Rubik's Cube, this subliminal is here to support your journey. Before you dive in and press play, consider these tips to optimize your training and development:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Start with the basics: Familiarize yourself with the cube, its structure, and the moves necessary for solving it.",
        "Set specific goals: Define what level of proficiency you want to achieve and establish milestones along the way.",
        "Practice regularly: Consistent daily or weekly practice is crucial for improving your skills and building muscle memory.",
        "Track your progress: Keep a log of your solving times and techniques used to monitor your improvement.",
        "Visualize success: Imagine yourself effortlessly solving the Rubik's Cube, experiencing the joy and satisfaction of mastering this iconic puzzle."
      ]
    },
    {
      type: 'paragraph',
      text: "By using this subliminal regularly and incorporating these tips into your training routine, you'll enhance your problem-solving abilities and become a Rubik's Cube master. Embrace the challenge, stay persistent, and let the journey to solving this iconic puzzle begin!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing your Rubik's Cube Skills Training and Development session! By dedicating time and effort to improving your skills in solving this iconic puzzle, you've taken a significant step towards becoming a master. Now, it's time to reflect on your progress and consider how you can continue to develop your problem-solving abilities."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice regularly: Set aside time each day to work on solving the Rubik's Cube.",
        "Analyze algorithms: Study different algorithms and techniques to improve your solving speed and efficiency.",
        "Participate in competitions: Join Rubik's Cube competitions to challenge yourself and learn from other enthusiasts.",
        "Teach others: Sharing your knowledge and teaching others can deepen your understanding and mastery of the cube."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, mastering the Rubik's Cube is a journey that requires patience and perseverance. Embrace the challenges, have fun, and celebrate every improvement you make along the way. With dedication and practice, you'll continue to enhance your problem-solving abilities and become a true master of the Rubik's Cube. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Rubik's Cube Skills Training and Development program help me improve my cube-solving skills?`, 
    answer: `The Rubik's Cube Skills Training and Development program provides structured training and practice exercises to help you improve your cube-solving skills. It includes techniques, algorithms, and strategies for solving the cube, as well as tips for improving your speed and efficiency. By following the program consistently and practicing regularly, you can develop a deeper understanding of the cube and become more proficient in solving it.`,
    open: false
  },
  { 
    question: `Do I need any prior experience to participate in the Rubik's Cube Skills Training and Development program?`, 
    answer: `No, the Rubik's Cube Skills Training and Development program is suitable for both beginners and those with some prior experience. The program starts with foundational concepts and gradually progresses to more advanced techniques. Whether you are a complete beginner or have some knowledge of cube-solving, the program can help you develop your skills and take them to the next level.`,
    open: false 
  },
  { 
    question: `How long does it take to become proficient in solving the Rubik's Cube?`, 
    answer: `The time it takes to become proficient in solving the Rubik's Cube varies from person to person. It depends on factors such as your dedication, practice frequency, and previous experience. With regular practice and consistent implementation of the techniques and strategies taught in the program, you can develop your skills over time and achieve faster solve times.`,
    open: false 
  },
  { 
    question: `Can the Rubik's Cube Skills Training and Development program help me solve different variations of the Rubik's Cube?`, 
    answer: `Yes, the Rubik's Cube Skills Training and Development program focuses on the foundational concepts and techniques that apply to solving different variations of the Rubik's Cube. While specific algorithms and strategies may differ slightly for different cube sizes or shapes, the program provides a solid foundation that can be applied to various cube variations.`,
    open: false 
  },
  { 
    question: `Can I still participate in the Rubik's Cube Skills Training and Development program if I only want to solve the cube for fun and not competitions?`, 
    answer: `Absolutely! The Rubik's Cube Skills Training and Development program is designed to cater to both those interested in competitive solving and those who simply want to enjoy solving the cube as a recreational activity. The skills and techniques taught in the program can be applied to solve the cube at your own pace and for personal enjoyment.`,
    open: false 
  },
  { 
    question: `Is there any support available during the Rubik's Cube Skills Training and Development program?`, 
    answer: `Yes, there is support available during the Rubik's Cube Skills Training and Development program. You can access a community of fellow cube enthusiasts and experts to ask questions, share your progress, and seek guidance. Additionally, the program may offer additional resources such as video tutorials, practice sheets, and online forums to further support your learning and development.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>