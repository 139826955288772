<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Self-discipline is a vital skill that can help you achieve your goals and improve various aspects of your life. This subliminal is designed to assist you in developing self-discipline by targeting your subconscious mind and promoting positive changes in your behavior and actions."
    },
    {
      type: 'heading',
      text: 'Benefits of using this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Increasing your ability to resist distractions and stay focused on your tasks.",
        "Enhancing your willpower and self-control to overcome temptations and bad habits.",
        "Improving your time management skills to maximize productivity and meet deadlines.",
        "Encouraging consistent and disciplined daily routines to achieve long-term success.",
        "Strengthening your determination and perseverance to overcome obstacles and challenges."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can rewire your subconscious mind to support your efforts in developing self-discipline. As you continue to reinforce positive behaviors, you will notice significant improvements in your ability to control your actions, stay focused, and achieve your desired outcomes. Remember, practice and consistency are key to mastering self-discipline."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Developing self-discipline can be a challenging but important step towards personal growth and achieving your goals. This subliminal is designed to assist you in cultivating self-discipline and empowering you to take control of your actions and behavior. Before you start listening, consider the following preparation tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Identify what areas of your life you want to improve your self-discipline in, whether it's work, health, or relationships.",
        "Create a routine: Establish a structured daily routine that includes specific times for tasks and activities, helping you develop consistency and discipline.",
        "Eliminate distractions: Minimize potential distractions, both physical and digital, that can hinder your focus and self-discipline.",
        "Practice mindfulness: Develop a mindful awareness of your thoughts, emotions, and impulses, allowing you to discern when to resist temptations or distractions.",
        "Use positive affirmations: Affirmations can reinforce your desire to develop self-discipline, so create and repeat positive statements that align with your goals."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is a tool to support you on your journey towards developing self-discipline, but true change comes from your own commitment and effort. As you listen regularly, you may start to notice improvements in your ability to stay focused, stay motivated, and stay disciplined. Embrace this opportunity for personal growth, set your intentions, and let the transformation begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Self-Discipline subliminal session! By focusing on developing self-discipline, you've taken a powerful step towards increasing your ability to control your actions and behavior. Reflecting on the impact of self-discipline in your life is important as you continue to grow and transform."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Define what you want to achieve and break them down into actionable steps.",
        "Create routines: Establish daily habits that align with your goals and practice them consistently.",
        "Practice delayed gratification: Learn to prioritize long-term rewards over short-term pleasures.",
        "Build resilience: Embrace setbacks and challenges as opportunities for growth and resilience building."
      ]
    },
    {
      type: 'paragraph',
      text: "Developing self-discipline is a process that requires dedication and perseverance. Embrace the journey, stay committed to your goals, and remember that every small step you take towards self-discipline brings you closer to living a more empowered and purposeful life. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  {
    question: `How can the Self-Discipline subliminal help me?`,
    answer: `The Self-Discipline subliminal is designed to help you develop self-discipline and increase your ability to control your actions and behavior. By listening to the subliminal regularly, the positive affirmations and messages embedded in the audio can influence your subconscious mind, making it easier for you to stay focused, motivated, and disciplined in achieving your goals.`,
    open: false
  },
  {
    question: `How long does it take to see results from the Self-Discipline subliminal?`,
    answer: `Results can vary from person to person, and it may take some time to see noticeable changes in your self-discipline. Consistent listening to the subliminal over a period of time can help reinforce the positive affirmations and messages in your subconscious mind. We recommend listening to the subliminal daily and consistently for best results.`,
    open: false
  },
  {
    question: `Can the Self-Discipline subliminal replace willpower and motivation?`,
    answer: `The Self-Discipline subliminal is designed to support and enhance your willpower and motivation, but it cannot replace them completely. It is essential to cultivate and maintain your determination and drive towards your goals. The subliminal can act as a tool to reinforce positive mindset and behaviors that align with self-discipline, but using it in conjunction with other strategies, such as goal-setting and creating a supportive environment, can lead to the best results.`,
    open: false
  },
  {
    question: `Are there any risks or side effects of listening to the Self-Discipline subliminal?`,
    answer: `No, there are no known risks or side effects associated with listening to the Self-Discipline subliminal. However, it is important to note that results may vary, and individual experiences may differ. If you have any concerns or underlying conditions, it is recommended to consult with a healthcare professional before using subliminal audios.`,
    open: false
  },
  {
    question: `Can the Self-Discipline subliminal help with procrastination?`,
    answer: `Yes, the Self-Discipline subliminal can help with overcoming procrastination. The subliminal messages are designed to influence your subconscious mind, encouraging you to stay focused, take action, and overcome the tendency to procrastinate. However, it is important to combine the subliminal with practical strategies such as creating a schedule, breaking tasks into smaller steps, and prioritizing your goals to address procrastination effectively.`,
    open: false
  },
  {
    question: `Can I listen to the Self-Discipline subliminal while doing other activities?`,
    answer: `While it is possible to listen to the Self-Discipline subliminal while doing other activities, it is generally recommended to listen to it in a focused and relaxed state. This allows your subconscious mind to absorb the positive affirmations and messages more effectively. Find a quiet and comfortable space where you can fully engage with the audio to maximize its benefits.`,
    open: false
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>