<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you have a fear of injections or medical procedures, don't worry - you're not alone. This subliminal is designed to help you overcome your fear and transform the experience into a stress-free one."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help:'
    },
    {
      type: 'list',
      items: [
        "Reducing anxiety and fear associated with injections and medical procedures.",
        "Helping you stay calm and relaxed during medical appointments and treatments.",
        "Providing a sense of reassurance and confidence in your ability to handle medical procedures.",
        "Building a positive mindset so that medical procedures become a less intimidating experience.",
        "Promoting a sense of control and empowerment over your fear of injections and medical procedures."
      ]
    },
    {
      type: 'paragraph',
      text: "With regular use of this subliminal, you can reprogram your subconscious mind to approach injections and medical procedures with ease and confidence. Over time, you may find that your fear diminishes, and these experiences become much more manageable. Remember, it's a process, and every small step counts towards overcoming your fear."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Dealing with a fear of injections and medical procedures can be challenging, but this subliminal is designed to help you overcome that fear and experience a stress-free process. Before you start listening, consider the following tips to prepare yourself:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your fear: Recognize that your fear is valid, and it's okay to feel anxious before medical procedures.",
        "Educate yourself: Gather information about the procedure so you can understand the process and alleviate any irrational fears or misconceptions.",
        "Relaxation techniques: Practice deep breathing exercises or other relaxation techniques to help calm your mind and body before the procedure.",
        "Positive visualization: Imagine yourself going through the procedure calmly and confidently, focusing on the positive outcome you want to achieve.",
        "Keep your intention in mind: While listening to the subliminal, remind yourself of your intention to overcome your fear and have a stress-free experience."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your routine, you are taking a proactive step towards overcoming your fear of injections and medical procedures. As you listen regularly, you may begin to feel more at ease and in control of your emotions. Embrace this opportunity to confront your fear and envision a future where medical procedures no longer evoke anxiety. Let's begin this transformative journey together."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Fear of Injection Removal subliminal! You've taken a significant step towards overcoming your fear and transforming your experience with medical procedures. As you reflect on your progress, remember that you have the power to change your perception and create a more positive, stress-free experience."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice relaxation techniques: Deep breathing, visualization, and mindfulness can help you stay calm during medical procedures.",
        "Talk to your healthcare provider: Discuss your fears and concerns beforehand to establish trust and explore strategies for coping.",
        "Seek support: Consider joining a support group or seeking therapy to work through your fear in a safe and understanding environment.",
        "Use distraction techniques: Engage in activities that divert your attention during procedures, such as listening to music or watching a movie."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming your fear of injections and medical procedures is a journey. Be patient and kind to yourself as you work towards a stress-free experience. With the right mindset and coping strategies, you can conquer your fear and emerge stronger. You've got this!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Fear of Injection Removal subliminal help me overcome my fear?`, 
    answer: `The Fear of Injection Removal subliminal works by targeting your subconscious mind and reprogramming it to associate injections and medical procedures with calmness and relaxation. By repeatedly listening to the subliminal, you can gradually reduce your fear and anxiety, allowing these experiences to become more manageable and stress-free. It is important to note that individual results may vary, and consistent listening is recommended for best results.`,
    open: false
  },
  { 
    question: `How long does it take to see the effects of the Fear of Injection Removal subliminal?`, 
    answer: `The time it takes to see the effects of the Fear of Injection Removal subliminal can vary depending on the individual and the severity of their fear. Some people may start noticing positive changes after a few listens, while others may require more time and consistent listening. We recommend regularly listening to the subliminal and being patient with the process.`,
    open: false 
  },
  { 
    question: `Can the Fear of Injection Removal subliminal completely eliminate my fear?`, 
    answer: `While the Fear of Injection Removal subliminal can help you reduce and manage your fear of injections and medical procedures, the extent to which it completely eliminates your fear may vary from person to person. The subliminal is designed to reprogram your subconscious mind and create a more positive association with these experiences, but it may not completely eliminate fear in all cases. It is important to have realistic expectations and be patient with the process.`,
    open: false 
  },
  { 
    question: `Can listening to the Fear of Injection Removal subliminal during a medical procedure help me stay calm?`, 
    answer: `While listening to the Fear of Injection Removal subliminal before a medical procedure can help you cultivate a calmer mindset, we recommend focusing on the instructions and guidance of the medical professionals during the procedure itself. It is important to communicate any concerns or fears you may have with the medical staff, as they can provide additional support and help create a more comfortable experience for you.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Fear of Injection Removal subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Fear of Injection Removal subliminal audio. However, if you have any concerns about your fear of injections or medical procedures, please consult with a qualified healthcare professional. The subliminal is intended to complement, not replace, any necessary medical advice or treatments.`,
    open: false 
  },
  { 
    question: `Can the Fear of Injection Removal subliminal be used for other fears or phobias?`, 
    answer: `The Fear of Injection Removal subliminal is specifically designed to address the fear of injections and medical procedures. While some techniques and affirmations may overlap with other fears or phobias, it is recommended to use subliminals that are tailored to your specific fear or phobia for optimal results.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>