<template>
  <div>
    <v-list-item class="px-2">
      <v-list-item-avatar tile style="height: 3em">
        <v-img src="@/assets/logo.png"></v-img>
      </v-list-item-avatar>

      <v-list-item-title>Magus Audio</v-list-item-title>
    </v-list-item>
    <v-divider />
    <v-list>
      <template v-for="menu in menus">
        <v-list-item
          color="primary"
          v-if="menu.submenu.length == 0"
          :to="menu.to"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ menu.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ menu.label }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group
          v-else
          :prepend-icon="`${menu.icon}`"
          v-model="menu.active"
          append-icon="mdi-chevron-down"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ menu.label }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-for="submenu in menu.submenu" :to="submenu.to" link>
            <v-list-item-icon>
              <v-icon>{{ submenu.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ submenu.label }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </template>
    </v-list>

    <div v-if="!isLogin && isMobile" class="login-register-container">
      <div class="mx-4">
        <v-btn
          class="login-register-button mb-4"
          style="background: #1c8edf; color: white; font-weight: 700"
          @click="getRoute('/login?type=user')"
          >Login</v-btn
        >
        <v-btn class="login-register-button mb-4" 
          style="background: #4372bd; color: white; font-weight: 700"
          @click="getRoute('/register')"
          >Register</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menus: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data: () => {
    return {};
  },
  computed: {
    layout() {
      return this.$route.matched[0].meta.layout;
    },
    userToken() {
      return this.$store.state.user.token;
    },
    adminToken() {
      return this.$store.state.admin.token;
    },
    isMobile() {
      return this.$store.state.screen.width < 1264;
    },
    isLogin() {
      if (this.layout == "User" || this.layout == "Main")
        return this.userToken != null;
      return true;
    },
  },
  methods: {
    getRoute(route) {
      const path = this.$route.path;
      if (path != route && route != "") {
        this.$router.push(route);
      }
    },
  },
};
</script>

<style>
.login-register-container {
  margin-top: 50px;
  width: 100%;
}

.login-register-button {
  width: 100%;
}
</style>
