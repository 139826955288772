import { render, staticRenderFns } from "./370-puzzle-games-skills-training-and-development.vue?vue&type=template&id=c3a9b2b2&scoped=true&"
import script from "./370-puzzle-games-skills-training-and-development.vue?vue&type=script&lang=js&"
export * from "./370-puzzle-games-skills-training-and-development.vue?vue&type=script&lang=js&"
import style0 from "./370-puzzle-games-skills-training-and-development.vue?vue&type=style&index=0&id=c3a9b2b2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3a9b2b2",
  null
  
)

export default component.exports