import store from "@/store";
import {
  addLikeSubliminal,
  deleteLikeSubliminal,
  addLikePlaylist,
  deleteLikePlaylist,
} from "@/api/favorite";

export const toggleData = (data, index = 0) => {
  let favorite = store.state.favorite.id;
  let favorites = [...favorite];

  if (data.hasOwnProperty("subliminals")) {
    favorites.includes(data.playlist_id)
      ? toggleUnlike(data, true)
      : toggleLike(data, true);
  } else {
    if (favorites.includes(data.subliminal_id)) {
      data.is_liked = 1;
    }
    data.is_liked === 1 ? toggleUnlike(data) : toggleLike(data);
  }

  setTimeout(() => {
    store.dispatch("audio/getFavoriteUpdate", true);
  }, 500);
};

export const toggleLike = (data, isPlaylist = false) => {
  let favorite = store.state.favorite.id;
  let favorites = [...favorite];

  if (isPlaylist) {
    if (!favorites.includes(data.playlist_id)) {
      favorites.push(data.playlist_id);
    }

    addLikePlaylists(data, favorites);
    /*for (let i = 0; i < data.subliminals.length; i++) {
      const element = data.subliminals[i];

      if (!favorites.includes(element.subliminal_id)) {
        favorites.push(element.subliminal_id);
      }

      addLikeSubliminals(element, favorites);
    }*/
  } else {
    if (!favorites.includes(data.subliminal_id)) {
      favorites.push(data.subliminal_id);
    }

    addLikeSubliminals(data, favorites);
  }
};

export const toggleUnlike = (data, isPlaylist = false) => {
  let favorite = store.state.favorite.id;
  let favorites = [...favorite];

  if (isPlaylist) {
    const index = favorites.indexOf(data.playlist_id);
    if (index > -1) {
      favorites.splice(index, 1);
    }

    deleteLikePlaylists(data, favorites);
    /*for (let i = 0; i < data.subliminals.length; i++) {
      const element = data.subliminals[i];

      const index = favorites.indexOf(element.subliminal_id);
      if (index > -1) {
        favorites.splice(index, 1);
      }

      deleteLikeSubliminals(element, favorites);
    }*/
  } else {
    const index = favorites.indexOf(data.subliminal_id);
    if (index > -1) {
      favorites.splice(index, 1);
    }

    deleteLikeSubliminals(data, favorites);
  }
};

export const getText = (id) => {
  let favorite = store.state.favorite.id;
  return favorite.includes(id) ? "dark" : "light";
};

export const addLikeSubliminals = (data, favorites) => {
  let user = store.state.user.info;
  let users = JSON.parse(user);

  data.user_id = users.user_id;

  addLikeSubliminal(data).then((res) => {
    store.dispatch("favorite/getFavorite", favorites);
  });
};

export const deleteLikeSubliminals = (data, favorites) => {
  let user = store.state.user.info;
  let users = JSON.parse(user);

  data.user_id = users.user_id;

  deleteLikeSubliminal(data).then((res) => {
    store.dispatch("favorite/getFavorite", favorites);
  });
};

export const addLikePlaylists = (data, favorites) => {
  let user = store.state.user.info;
  let users = JSON.parse(user);

  data.user_id = users.user_id;

  addLikePlaylist(data).then((res) => {
    store.dispatch("favorite/getFavorite", favorites);
    data.is_liked = data.is_liked == 1 ? 0 : 1;
  });
};

export const deleteLikePlaylists = (data, favorites) => {
  let user = store.state.user.info;
  let users = JSON.parse(user);

  data.user_id = users.user_id;

  deleteLikePlaylist(data).then((res) => {
    store.dispatch("favorite/getFavorite", favorites);
    data.is_liked = data.is_liked == 1 ? 0 : 1;
  });
};
