<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Job interviews can be nerve-wracking, but with the right mindset and preparation, you can increase your chances of success. This subliminal is designed to help you feel confident, calm, and prepared for your job interviews."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support you:'
    },
    {
      type: 'list',
      items: [
        "Boosting your self-confidence and belief in your abilities.",
        "Reducing anxiety and nerves before and during job interviews.",
        "Improving your communication skills and ability to articulate your strengths.",
        "Enhancing your preparation and interview performance.",
        "Instilling a positive mindset and belief in your potential for success."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind to align with your goals and aspirations. It can help you project confidence, showcase your skills, and make a positive impression on your potential employers. Remember, preparation and mindset are crucial in job interviews, and this subliminal can be a valuable tool in your job search journey."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Preparing for a job interview can be nerve-wracking, but this subliminal can help boost your confidence and increase your chances of success. Before you start listening, here are some tips to help you get ready:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Research the company: Familiarize yourself with the organization's values, mission, and achievements as it can help you tailor your answers and demonstrate your interest.",
        "Practice common interview questions: Prepare and rehearse your responses to commonly asked interview questions to improve your confidence and articulate your qualifications.",
        "Dress appropriately: Ensure you have appropriate attire for the interview, adhering to the company's dress code.",
        "Visualize success: Imagine yourself confidently answering questions and leaving a lasting impression on the interviewer.",
        "Maintain a positive mindset: Believe in your abilities and stay positive throughout the interview process, regardless of any challenges you may face."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to help you tap into your inner confidence, enhance your communication skills, and bring out your best during the job interview. As you listen regularly, you may notice increased self-assurance, improved articulation, and a greater ability to showcase your qualifications. So take a moment to center yourself, visualize success, and let's begin the journey to job interview success and landing your dream job."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Job Interview Success subliminal! You've taken an important step towards preparing yourself for success in job interviews and achieving your career goals. Now that you've listened to the session, it's time to reflect on your experience and take practical steps towards confidently landing your dream job."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Review your interview preparation: Consider the key points and strategies mentioned in the subliminal session.",
        "Practice mock interviews: Ask a trusted friend or family member to conduct mock interviews to practice your skills.",
        "Research the company: Gather information about the company you're interviewing with to show your interest and preparedness.",
        "Focus on your strengths: Identify your unique skills and experiences that make you a great fit for the job."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, success in job interviews is not guaranteed overnight. Stay persistent, confident, and focused on your abilities. With preparation and practice, you can enhance your interview skills and increase your chances of landing your dream job. Best of luck in your job search!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Job Interview Success subliminal help me?`, 
    answer: `The Job Interview Success subliminal is designed to help you prepare for job interviews and increase your confidence and composure during the process. By listening to the subliminal, you can reprogram your mindset to overcome interview anxiety, project confidence, and enhance your ability to articulate your skills and experiences effectively. It aims to create a positive and motivated mindset that will help you stand out and perform well in job interviews.`,
    open: false
  },
  { 
    question: `How often should I listen to the Job Interview Success subliminal?`, 
    answer: `We recommend listening to the Job Interview Success subliminal daily in the days leading up to your job interview. Consistency is key in allowing the positive affirmations to take root in your subconscious mind. You can also listen to the subliminal during your pre-interview preparations to boost your confidence and mental readiness.`,
    open: false 
  },
  { 
    question: `Can the Job Interview Success subliminal guarantee that I will get the job?`, 
    answer: `While the Job Interview Success subliminal can help you feel more confident, composed, and prepared for job interviews, it cannot guarantee that you will get the job. It is meant to be used as a tool to enhance your interview skills and mindset, increasing your chances of success. Ultimately, the outcome of the job interview depends on various factors, including your qualifications, the interviewer's judgment, and the competition for the position.`,
    open: false 
  },
  { 
    question: `Will the Job Interview Success subliminal work for me if I have no prior interview experience?`, 
    answer: `Yes, the Job Interview Success subliminal can benefit individuals with no prior interview experience as well. It can help instill confidence, reduce anxiety, and improve communication skills, all of which are essential for a successful job interview. Listening to the subliminal regularly can help you prepare mentally and be more self-assured when facing the interview process.`,
    open: false 
  },
  { 
    question: `Can the Job Interview Success subliminal help me overcome nervousness and anxiety during interviews?`, 
    answer: `Yes, the Job Interview Success subliminal is specifically designed to help you overcome interview nervousness and anxiety. By listening to the subliminal, you can reprogram your subconscious mind to stay calm, focused, and confident during job interviews. It can help you manage your nerves, think clearly, and present yourself in the best possible way.`,
    open: false 
  },
  { 
    question: `Is it necessary to practice my interview skills alongside listening to the Job Interview Success subliminal?`, 
    answer: `While the Job Interview Success subliminal can boost your confidence and mindset, it is also essential to practice your interview skills alongside it. Practicing common interview questions, researching the company, and having mock interviews can help you become more comfortable and prepared for the actual job interview. Combining the subliminal with practical interview preparation can increase your chances of success.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>