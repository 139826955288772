<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you've experienced cyberbullying, you know how damaging it can be to your mental and emotional well-being. This subliminal is here to help you overcome the negative effects of cyberbullying and build resilience and confidence to face online bullying with strength."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help you:"
    },
    {
      type: 'list',
      items: [
        "By boosting your self-esteem and confidence, helping you believe in your worth and abilities.",
        "By reducing the impact of negative comments and cyberbullying, allowing you to maintain a positive outlook.",
        "By promoting emotional healing and helping you process and release any pain or trauma caused by cyberbullying.",
        "By building resilience and inner strength, enabling you to face online bullying with courage and determination.",
        "By providing affirmations and suggestions for a healthy mindset, empowering you to rise above cyberbullying and thrive."
      ]
    },
    {
      type: 'paragraph',
      text: "Through consistent listening, this subliminal can rewire your subconscious mind, helping you regain your mental and emotional well-being. Remember, you are strong, worthy, and deserving of a safe and respectful online environment. Don't let cyberbullying define you – rise above it with the help of this subliminal."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Dealing with cyberbullying can have a profound impact on your mental and emotional well-being. This subliminal is designed to help you overcome the negative effects of online bullying and build resilience and confidence. Before you start listening, here are some strategies to consider:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your emotions: Recognize and accept the feelings that arise from cyberbullying, allowing yourself to process and validate them.",
        "Seek support: Reach out to trusted friends, family members, or professionals who can provide guidance and understanding.",
        "Practice self-care: Engage in activities that promote self-compassion, relaxation, and well-being.",
        "Set boundaries: Establish clear boundaries in your online interactions, such as limiting exposure to negative content or toxic individuals."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your routine, you can empower yourself to overcome the negative effects of cyberbullying. Regular listening combined with self-care and seeking support can help you regain your mental and emotional well-being. Remember, you are strong and capable of facing online bullying with resilience. Let's embark on this journey together and embrace your inner strength."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Overcome Cyber Bullying subliminal! By investing in your mental and emotional well-being, you have taken a significant step towards regaining control and building resilience against cyberbullying. It's important to reflect on your experience so far and continue your journey towards healing and empowerment."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your feelings: Take the time to acknowledge and process your emotions regarding cyberbullying.",
        "Seek support: Reach out to friends, family, or professional counselors who can provide guidance and reassurance.",
        "Practice self-care: Engage in activities that bring you joy and nurture your mental and emotional well-being.",
        "Confront negative self-talk: Replace self-doubt and criticism with positive affirmations and self-compassion."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming cyberbullying is a journey, and it may take time to fully heal. Be patient with yourself, practice self-care, and surround yourself with positive influences. Embrace your inner strength and resilience as you continue to navigate the online world with confidence. You are stronger than you know!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What is cyberbullying?`, 
    answer: `Cyberbullying refers to the use of technology, such as social media platforms or messaging apps, to harass, intimidate, or harm others. It can involve sending hurtful messages, spreading rumors online, sharing embarrassing photos or videos, or any other form of online harassment. Cyberbullying can have severe negative effects on a person's mental and emotional well-being.`,
    open: false
  },
  { 
    question: `How can the Overcome Cyber Bullying subliminal help me?`, 
    answer: `The Overcome Cyber Bullying subliminal is designed to support individuals in overcoming the negative effects of cyberbullying and regaining their mental and emotional well-being. It works by empowering listeners to build resilience, confidence, and inner strength to face online bullying. The subliminal messages are aimed at reprogramming the subconscious mind and promoting a positive mindset, helping individuals rise above the negative experiences and develop healthier ways of coping.`,
    open: false 
  },
  { 
    question: `Can the Overcome Cyber Bullying subliminal erase the memories of cyberbullying?`, 
    answer: `No, the Overcome Cyber Bullying subliminal is not intended to erase memories of cyberbullying. Instead, it aims to empower individuals with the mental and emotional tools to heal, recover, and build resilience. It helps individuals develop healthier coping mechanisms and a more positive outlook, allowing them to move forward and regain their well-being.`,
    open: false 
  },
  { 
    question: `How long before I see improvements in my mental and emotional well-being with the Overcome Cyber Bullying subliminal?`, 
    answer: `The time it takes to see improvements varies from person to person depending on their unique circumstances. It is recommended to listen to the Overcome Cyber Bullying subliminal regularly and consistently for optimal results. Some individuals may start noticing positive changes in their mental and emotional well-being sooner, while others may require more time. The subliminal messages work on a subconscious level to promote long-lasting healing and empowerment.`,
    open: false 
  },
  { 
    question: `Can the Overcome Cyber Bullying subliminal protect me from future cyberbullying?`, 
    answer: `The Overcome Cyber Bullying subliminal is not designed to provide physical or digital protection against cyberbullying. It aims to empower individuals with the mental and emotional resilience to face and overcome the negative effects of cyberbullying. It is important to take appropriate measures, such as blocking or reporting abusive individuals, practicing online safety, and seeking support from trusted individuals or organizations to prevent or address cyberbullying incidents.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Overcome Cyber Bullying subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Overcome Cyber Bullying subliminal. However, if you have any concerns about your mental or emotional well-being, or if you are experiencing severe distress as a result of cyberbullying, it is important to seek professional help from a qualified mental health practitioner or counselor.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>