<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "This subliminal is designed to help you embrace patriotism and reject colonial mentality, fostering a sense of national pride and identity. By targeting your subconscious mind, it encourages a shift in mindset and promotes a deeper connection to your country and its culture."
    },
    {
      type: 'heading',
      text: 'Here are some key benefits of using this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Developing a stronger love and appreciation for your country and its heritage.",
        "Becoming more aware of the influence of colonial mentality and working to overcome it.",
        "Fostering a sense of unity and belonging with fellow citizens.",
        "Promoting positive attitudes towards local culture, traditions, and values.",
        "Inspiring you to play an active role in the development and progress of your nation."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can rewire your subconscious mind to align with patriotic values and reject the negative aspects of colonial mentality. Over time, you may find yourself actively seeking ways to contribute to your country's growth and embracing your unique national identity. Remember, this is a journey of self-discovery and an opportunity to foster a deep sense of pride in your roots."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embracing patriotism and rejecting colonial mentality are essential for fostering national pride and identity. This subliminal is designed to help you develop a deeper connection to your culture and build a strong sense of pride in your country. Before you start listening, consider these suggestions to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Educate yourself: Learn about the history, traditions, and cultural heritage of your country. Understand the impact of colonialism and recognize the importance of embracing local values.",
        "Challenge colonial narratives: Question and challenge any beliefs or biases that may be a result of colonial influence. Seek to understand your own identity and value systems.",
        "Surround yourself with positive influences: Engage with individuals, books, movies, or music that celebrate your country's culture and contribute to a sense of national pride.",
        "Reflect on your values: Consider the values and principles that define your country and how they align with your personal beliefs. Cultivate a sense of appreciation for these values.",
        "Embrace diversity: Recognize and celebrate the diversity within your country. Embrace inclusivity and unity, fostering a shared national identity."
      ]
    },
    {
      type: 'paragraph',
      text: "By actively engaging with these tips and listening to this subliminal regularly, you can begin to reject the remnants of colonial mentality and nurture a stronger sense of patriotism and national identity. Remember, this journey towards embracing your heritage and celebrating your country's uniqueness requires patience, self-reflection, and a commitment to personal growth. Let's embark on this transformative journey together!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Patriotism and End Colonial Mentality session! By exploring and embracing patriotism, you've taken an important step towards fostering national pride and identity. Reflect on your experience and continue to contribute to the growth and development of your nation."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Educate yourself: Learn more about the history, culture, and achievements of your nation.",
        "Engage in community activities: Participate in events that celebrate and promote national pride.",
        "Challenge colonial narratives: Question and challenge any negative beliefs or stereotypes influenced by colonial mentality.",
        "Promote cultural heritage: Support and appreciate local traditions, artworks, and indigenous knowledge."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, embracing patriotism and rejecting colonial mentality is an ongoing journey. Stay informed, engage with your community, and actively contribute to a more inclusive and proud nation. Together, we can create a stronger and more united society."
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What is colonial mentality?`, 
    answer: `Colonial mentality refers to the mindset or behavior patterns that are influenced by the ideals, values, and culture of the colonizers or dominant external forces. It is characterized by a preference for foreign products, cultural practices, or ideologies over one's own, and a lack of pride in one's national identity or heritage.`,
    open: false
  },
  { 
    question: `Why is it important to embrace patriotism and reject colonial mentality?`, 
    answer: `Embracing patriotism and rejecting colonial mentality is important for fostering a sense of national pride, identity, and unity. It allows individuals and communities to reconnect with their culture, traditions, and values, and promotes a sense of belonging and solidarity. By valuing and celebrating their own history and heritage, people can contribute to the development and progress of their nation.`,
    open: false 
  },
  { 
    question: `How can I embrace patriotism and reject colonial mentality?`, 
    answer: `Embracing patriotism and rejecting colonial mentality requires a conscious effort to recognize and challenge the influence of external forces on your mindset and behavior. This can be done by learning about your country's history, culture, and achievements, participating in and supporting local initiatives and industries, promoting inclusivity and respect for diverse perspectives, and actively contributing to the betterment of your community and nation.`,
    open: false 
  },
  { 
    question: `Is it unpatriotic to appreciate aspects of other cultures?`, 
    answer: `Appreciating aspects of other cultures does not have to be seen as unpatriotic. It is possible to appreciate and learn from different cultures while still valuing and preserving your own. The key is to strike a balance between openness to new ideas and experiences and maintaining a strong connection to your national identity, heritage, and values.`,
    open: false 
  },
  { 
    question: `Can subliminals help in fostering patriotism and rejecting colonial mentality?`, 
    answer: `Subliminals can be a tool to support mindset and behavior change, including fostering patriotism and rejecting colonial mentality. By using subliminal messaging, individuals can reinforce positive affirmations and beliefs that align with national pride, identity, and values. However, it is important to remember that subliminals alone cannot create sweeping changes and must be supplemented with conscious efforts and actions.`,
    open: false 
  },
  { 
    question: `Is embracing patriotism and rejecting colonial mentality relevant in today's globalized world?`, 
    answer: `Yes, embracing patriotism and rejecting colonial mentality remain relevant in today's globalized world. While globalization brings opportunities for cultural exchange and collaboration, it is essential to navigate this interconnectedness while maintaining a strong sense of national identity, pride, and cultural integrity. Doing so allows for the celebration of diversity and the preservation of local traditions and values.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>