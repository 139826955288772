<template>
  <section>
    <div class="container">
      <div>
        <h1>
          Wide Product Selections
        </h1>
        <h5 class="mb-2">
          Broad Content Library. Hundreds of Topics.
        </h5>
        <h5>
          As the most innovative health and self-development company. Magus can help you <br>
          address the mental health and wellness needs of your business. We offer flexibility <br>
          and convenience to your company with innovative and relevant solutions.
        </h5>
      </div>
      <div class="col-10 col-lg-8 mx-auto">
        <div class="row my-4 ">
          <template v-for="item, index in items">
            <div class="product-box-left" v-if="item.icon % 2 == 0">
              <div>
                <h6>{{ item.title }}</h6>
                <p v-html="item.details"></p>
              </div>
              <div>
                <img :src="require(`@/assets/images/main/home/3/${item.icon}.png`)" alt="">
              </div>
            </div>
            <div v-else class="product-box-right">
              <div>
                <img :src="require(`@/assets/images/main/home/3/${item.icon}.png`)" alt="">
              </div>
              <div>
                <h6>{{ item.title }}</h6>
                <p v-html="item.details"></p>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div>
        <button class="button btn btn-primary btn-lg" @click="$router.go(-1)">
        {{ showInterfaceButton }}</button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data: () => {
    return {
      show_interface: true,
      items: [
        {
          title: 'Magus for Health', 
          description: 'A companion for your <br> journey to success.', 
          details: "In a world that's constantly in flux, prioritizing mental well-being is no longer optional; it's essential. Magus offers you an ingenious and easily accessible solution to sustain a balanced mind amid life's complexities. Created for everyone—from academics to teenagers, from hardworking individuals to imaginative souls—our app removes the obstacles to mental health support.<br><br>Utilizing advanced subliminal audio technology, Magus acts as your dependable mental wellness companion, guiding you toward a mindset that is resilient and at peace. Embracing the uniqueness of each individual, we welcome users from all life experiences and cultural backgrounds. Choose Magus for Health as your ally in forging a brighter, more tranquil future.<br><br>Discover untapped reservoirs of inner strength and say farewell to the shackles of stress, anxiety, and self-doubt. Welcome a life marked by clarity, confidence, and inner peace.",
          icon: '1'
        },
        {
          title: 'Magus for Beauty', 
          description: 'A companion for your <br> journey to success.', 
          details: "Unlock the secret to holistic beauty with the Magus app, a rich repository of audio tracks dedicated to enhancing your well-being and appearance. Craft your own beauty-centric playlists and zero in on what matters most to you.<br><br>Our unique feature? We embed discreet positive affirmations within our audio that your subconscious mind naturally processes, fostering incremental yet impactful life changes. The app's intelligent system also tailors track suggestions based on your preferences, fine-tuning your beauty journey.<br><br>Whether you're using a smartphone, tablet, or computer, Magus offers a seamless way to invest in your most beautiful self, wherever you are and whenever you wish.",
          icon: '2'
        },
        {
          title: 'Magus for Success', 
          description: 'A companion for your <br> journey to success.', 
          details: "Elevate your journey towards personal and professional excellence with the Magus app. Far more than an ordinary audio streaming platform, think of Magus as your success mentor always available right in your pocket. With an extensive library covering key success factors like focus, willpower, and aspiration, we help smooth your pathway to achieving your ambitions.<br><br>Tailor your listening experience with hand-picked playlists that motivate and inspire. Beyond mere music, our tracks include hidden positive affirmations that your subconscious effortlessly absorbs, setting the stage for your success. Our smart algorithm learns your listening habits, suggesting tracks that harmonize with your individual goals.<br><br>Available across all your digital devices, Magus ensures that no matter where life takes you, your sights are set firmly on your dreams.",
          icon: '3'
        },
        {
          title: 'Magus for Wealth', 
          description: 'A companion for your <br> journey to success.', 
          details: "Unlock financial abundance with Magus for Wealth, our easy-to-use Subliminal Audio App. Whether you dream of starting a business or simply want more money in your life, we've got you covered. Explore our library of tracks on making money, setting goals, and thinking positively about wealth. Our smart technology personalizes the content just for you, and you can use it on your phone or computer. Get ready to transform your financial life with Magus.",
          icon: '4'
        },
        {
          title: 'Magus for Calmness and Peace', 
          description: 'A companion for your <br> journey to success.', 
          details: "Discover peace with Magus for Calmness and Peace, our calming audio app for everyone. We've got tracks to help you relax, be resilient, and find balance in your life. Choose from stress relief, anxiety tips, mindfulness, and meditation. You can even make your own playlists and soak in positive messages effortlessly. Magus is easy to use on all your devices, and it's here to bring peace to your life. Start your journey to inner peace today.",
          icon: '5'
        },
        {
          title: 'Magus for Romance', 
          description: 'A companion for your <br> journey to success.', 
          details: 'A companion for your <br> journey to success.', 
          icon: '6'
        },
        {
          title: 'Magus for Self-Development', 
          description: 'A companion for your <br> journey to success.', 
          details: "Empower yourself with Magus for Self-Development, our simple audio app. Dive into a library of tracks for boosting your confidence, finding motivation, and more. You get to make your playlists, and we make sure you hear positive messages seamlessly. Our smart tech even learns what you like. Use Magus on any device, and it'll guide you toward success. Let's get started now.",
          icon: '7'
        },
        {
          title: 'Magus for Learning', 
          description: 'A companion for your <br> journey to success.', 
          details: "Elevate your learning with Magus for Learning, your ultimate study buddy. We have tracks to help with memory, focus, and faster learning. You can create playlists that work for you, and our special messages make learning super easy. Magus is ready to help you on your learning journey, and it's available on all your devices. Start your quest for knowledge now.",
          icon: '8'
        },
      ] 
    }
  },
  computed: {
    showInterfaceButton() {
      return this.show_interface ? "Back" : "Learn More";
    }
  },
}
</script>

<style scoped>
  h1, p,
  h5 {
    line-height: 1.35em;
    color: var(--ma-black-olive);
  }

  h5:nth-of-type(1) {
    color: var(--ma-tufts-blue);
  }

  h5:nth-of-type(2), p {
    font-family: "Montserrat-Regular";
  }

  h6 {
     color: var(--ma-tufts-blue);
  }

  .product-box-left>div>img, .product-box-right>div>img  {
    width: clamp(4.19rem, calc(1.64rem + 8.72vw), 10.75rem);
    margin-block: 1.5em;
  }
  .product-box-left >div, .product-box-right >div {
      float: left;
  }
  .product-box-left>div:nth-of-type(2) {
    width: 22%;
  }
  .product-box-left>div:nth-of-type(1) {
    width: 70%;
    margin-right: 20px;
    margin-bottom: 30px;
    
  }
  .product-box-left {
    padding: 0em;
    justify-content: center;
    text-align: left;
  }
  .product-box-right>div:nth-of-type(2) {
    width: 70%;
    margin-left: 20px;
    margin-bottom: 30px;
  }
  .product-box-right>div:nth-of-type(1) {
    width: 22%;
  }
  .product-box-right {
    padding: 0em;
    justify-content: center;
    text-align: left;
  }

  @media screen and (min-width: 40rem) {
    section {
      text-align: center;
      overflow-y: scroll;
    }

  }
</style>