<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Learning a new language can be challenging, but with the help of this subliminal, you can enhance your English language skills and become fluent in speaking, reading, and writing English."
    },
    {
      type: 'heading',
      text: 'What this subliminal can assist you with:'
    },
    {
      type: 'list',
      items: [
        "Improving your pronunciation and accent to sound more natural and confident in English.",
        "Enhancing your vocabulary and expanding your knowledge of English words and phrases.",
        "Developing better grammar and sentence structure skills to communicate effectively.",
        "Increasing your confidence in speaking and expressing yourself in English conversations.",
        "Becoming more fluent in reading and understanding English texts.",
        "Improving your writing skills, including grammar, spelling, and overall clarity."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can rewire your subconscious mind to absorb and retain English language knowledge more effortlessly. You may notice improvements in your language skills, increased confidence, and a greater ability to communicate effectively in English. Remember, practice and consistency are vital for progress."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on the journey of improving your English language skills is a commendable goal. This subliminal is designed to support your learning process and help you become more fluent in speaking, reading, and writing English. Before you start listening, consider these tips to enhance your learning experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear language learning goals: Identify what areas of English you want to improve and define specific goals for each area.",
        "Create a structured study plan: Establish a schedule for regular practice and allocate time for different language skills, such as listening, speaking, reading, and writing.",
        "Immerse yourself in English: Surround yourself with English-language media, such as movies, music, podcasts, and books, to enhance your exposure to the language.",
        "Stay motivated and persistent: Learning a new language can be challenging, but maintaining a positive mindset and being consistent with your practice will lead to progress."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your language learning journey, you can tap into the power of your subconscious mind and accelerate your progress. Embrace the excitement of acquiring new language skills, stay dedicated to your goals, and let's begin your English language learning adventure."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the English Language Learning Aid session! By dedicating time and effort towards improving your English language skills, you're on your way to becoming fluent in speaking, reading, and writing English. Take a moment to reflect on your progress and consider how you can continue your language learning journey."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice speaking: Engage in conversations with native English speakers or language exchange partners to practice your speaking skills.",
        "Read extensively: Explore a wide range of English literature, articles, and other written materials to enhance your reading comprehension.",
        "Write regularly: Keep a journal or write essays to strengthen your writing skills and expand your vocabulary.",
        "Listen to English audio: Watch TV shows, movies, or listen to podcasts in English to improve your listening comprehension."
      ]
    },
    {
      type: 'paragraph',
      text: "Becoming fluent in English requires consistent practice and exposure to the language. Embrace opportunities to use English in various contexts and continue to immerse yourself in English-speaking environments. With your dedication and effort, you'll soon achieve your language learning goals. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the English Language Learning Aid help me improve my English skills?`, 
    answer: `The English Language Learning Aid is designed to support your language learning journey by providing subliminal messages that promote a positive mindset and accelerated learning. By listening to the audio regularly, the subliminal messages can help boost your confidence, improve your pronunciation, enhance your vocabulary, and strengthen your comprehension skills. It is recommended to use the English Language Learning Aid in conjunction with other language learning resources and practice regularly to see the best results.`,
    open: false
  },
  { 
    question: `How long before I start seeing improvements in my English language skills?`, 
    answer: `The time it takes to see improvements in your English language skills can vary depending on various factors, including your current skill level, the amount of time you dedicate to learning, and your individual learning style. Consistently listening to the English Language Learning Aid and actively engaging in other language learning activities, such as reading, speaking, and writing in English, can help accelerate your progress. It is important to be patient and persistent in your language learning journey.`,
    open: false 
  },
  { 
    question: `Can the English Language Learning Aid help me become fluent in English?`, 
    answer: `The English Language Learning Aid can be a helpful tool in improving your English language skills and building a foundation for fluency. However, achieving fluency requires consistent practice, exposure to the language, and immersion in English-speaking environments. The subliminal messages in the English Language Learning Aid can support your learning efforts, but it is essential to practice speaking, reading, and listening to English regularly to develop fluency.`,
    open: false 
  },
  { 
    question: `Is it necessary to have prior knowledge of the English language to use the English Language Learning Aid?`, 
    answer: `While prior knowledge of the English language is not necessary to use the English Language Learning Aid, some basic understanding of English can be beneficial. The subliminal messages are designed to reinforce and accelerate language learning, but it is recommended to have a foundation in English vocabulary and basic grammar before utilizing the aid.`,
    open: false 
  },
  { 
    question: `Can the English Language Learning Aid help with specific aspects of English, such as pronunciation or writing skills?`, 
    answer: `Yes, the English Language Learning Aid can help with various aspects of English, including pronunciation, writing skills, vocabulary expansion, and comprehension. The subliminal messages are designed to target and strengthen these specific areas, providing support and reinforcement as you practice and improve. It is recommended to actively engage in activities that focus on the specific aspect you want to improve, such as speaking exercises for pronunciation or writing exercises for writing skills, in conjunction with listening to the aid.`,
    open: false 
  },
  { 
    question: `Is there a recommended listening schedule for the English Language Learning Aid?`, 
    answer: `There is no strict listening schedule for the English Language Learning Aid. However, it is recommended to listen to the audio regularly and consistently to maximize its benefits. Find a time and place where you can relax and focus on the subliminal messages without distractions. You can listen to it daily or as often as you prefer, depending on your availability and learning goals.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>