<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you struggle with the fear of being alone, know that you are not alone in this feeling. This subliminal is designed to help you overcome that fear and find fulfillment within yourself."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help:'
    },
    {
      type: 'list',
      items: [
        "Let go of the anxiety and discomfort that comes with being alone.",
        "Develop a sense of peace, contentment, and fulfillment in your own company.",
        "Cultivate self-love and build a healthier relationship with yourself.",
        "Find joy in solitude and embrace the opportunities it presents for personal growth.",
        "Release the dependency on others for happiness and a sense of worth."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to let go of the fear of being alone and embrace solitude. You can learn to enjoy your own company, build a stronger sense of self, and find fulfillment within yourself. Remember, overcoming fears takes time and practice, but with dedication, you can achieve a healthier mindset."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Conquering the fear of being alone and finding fulfillment within yourself is an empowering journey, and this subliminal is here to support you every step of the way. Before you begin listening, consider these strategies to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your emotions: Recognize and accept any fear or discomfort you may feel about being alone.",
        "Start small: Begin by setting aside short periods of time to be alone and gradually work your way up to longer periods.",
        "Create a safe space: Prepare a comfortable and inviting environment where you can relax and reflect on your thoughts and emotions.",
        "Practice self-care: Engage in activities that bring you joy and nourish your soul. Use this time alone to focus on self-discovery and personal growth."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal, allow the positive affirmations to penetrate your subconscious mind, replacing fear with confidence, and transforming your relationship with solitude. Embrace this opportunity for self-reflection and growth, and embrace the beauty of enjoying your own company. Let the journey to overcoming fear and finding fulfillment within yourself begin!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Fear of Being Alone Removal subliminal! You have taken a brave step towards overcoming your fear and embracing the beauty of solitude. Now it's time to reflect on your experience and continue on your journey towards finding fulfillment within yourself."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Embrace alone time: Set aside regular moments to spend alone and discover activities that bring you joy and peace.",
        "Challenge negative thoughts: When feelings of fear or discomfort arise, remind yourself of the benefits of solitude and the opportunity for personal growth.",
        "Practice self-care: Prioritize self-care activities that nourish your mind, body, and soul.",
        "Seek support if needed: Reach out to loved ones, a therapist, or join support groups to talk about your fears and connect with others who have similar experiences."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming the fear of being alone is a process that may take time. Be patient and kind to yourself as you navigate the journey. With each step forward, you will find greater fulfillment within yourself and the ability to truly enjoy your own company. You are never alone in this process - you've got this!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Fear of Being Alone Removal subliminal help me?`, 
    answer: `The Fear of Being Alone Removal subliminal is designed to help individuals overcome their fear of being alone and find fulfillment within themselves. By listening to the subliminal regularly, the affirmations and positive messages will work to reprogram your subconscious mind, allowing you to embrace solitude and enjoy your own company. With consistent listening, you may notice a shift in your mindset and a decrease in anxiety or discomfort when faced with being alone.`,
    open: false
  },
  { 
    question: `How long do I need to listen to the Fear of Being Alone Removal subliminal to see results?`, 
    answer: `The time it takes to see results can vary from person to person. Some individuals may start noticing positive changes in their perception of being alone after a few listening sessions, while others might require more time to fully reprogram their subconscious mind. We recommend listening to the subliminal daily and consistently for at least a few weeks to allow the affirmations to take effect.`,
    open: false 
  },
  { 
    question: `Can the Fear of Being Alone Removal subliminal cure loneliness?`, 
    answer: `The Fear of Being Alone Removal subliminal is designed to help individuals overcome their fear of being alone and find fulfillment within themselves. While it can contribute to a more positive mindset and healthier perception of solitude, it is not a substitute for addressing deeper emotional issues related to loneliness. If you are experiencing chronic loneliness, it may be beneficial to seek support from a mental health professional.`,
    open: false 
  },
  { 
    question: `Is it possible to become too comfortable being alone after listening to the Fear of Being Alone Removal subliminal?`, 
    answer: `The goal of the Fear of Being Alone Removal subliminal is to help individuals overcome their fear of being alone and find a healthy balance between enjoying their own company and engaging with others. It aims to promote a positive mindset towards solitude, not to create excessive dependency on being alone. The subliminal is designed to empower individuals to embrace solitude while maintaining fulfilling relationships with others.`,
    open: false 
  },
  { 
    question: `Can the Fear of Being Alone Removal subliminal replace professional therapy or counseling?`, 
    answer: `No, the Fear of Being Alone Removal subliminal is not a substitute for professional therapy or counseling. While it can be a helpful tool in overcoming the fear of being alone, it is important to seek guidance from a qualified mental health professional if you are dealing with deep-rooted fears or emotional issues. They can provide individualized support and help you navigate your specific concerns.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the Fear of Being Alone Removal subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Fear of Being Alone Removal subliminal. However, if you have any concerns about your mental health or emotional well-being, it is always a good idea to consult with a healthcare professional or qualified therapist.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>