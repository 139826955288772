import { render, staticRenderFns } from "./124-fear-of-intimacy-removal.vue?vue&type=template&id=647fc3bc&scoped=true&"
import script from "./124-fear-of-intimacy-removal.vue?vue&type=script&lang=js&"
export * from "./124-fear-of-intimacy-removal.vue?vue&type=script&lang=js&"
import style0 from "./124-fear-of-intimacy-removal.vue?vue&type=style&index=0&id=647fc3bc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "647fc3bc",
  null
  
)

export default component.exports