<template>
  <Dialog :dialog="dialog" :width="800" :title="`Add Subscription`" @closeDialog="closeDialog">
    <div>

      <v-row class="ma-0 justify-space-between">
        <v-col cols="9" class="pa-0">
          <p class="mb-1">Subscription Name</p>
          <v-text-field solo dense hide-details class="mb-3" placeholder="Subscription" v-model="name"></v-text-field>
        </v-col>
        <v-col cols="2" class="pa-0">
          <p class="mb-1">Color Card</p>
          <el-color-picker class="colorcard" v-model="color"></el-color-picker>
        </v-col>
      </v-row>

      <p class="mb-1">Description</p>
      <v-textarea solo dense hide-details class="mb-3" placeholder="Description" v-model="description"></v-textarea>
      <p class="mb-1">Amount (Month)</p>
      <v-text-field type="number" solo dense hide-details class="mb-3" placeholder="Amount (Month)"
        v-model="amount_month"></v-text-field>
      <p class="mb-1">Amount (Year)</p>
      <v-text-field type="number" solo dense hide-details class="mb-3" placeholder="Amount (Year)"
        v-model="amount_year"></v-text-field>
    </div>

    <template v-slot:action>
      <v-row class="ma-0">
        <v-col class="pl-0 pr-2">
          <v-btn block @click="submitDialog()" :disabled="disabled">Add</v-btn>
        </v-col>
        <v-col class="pl-2 pr-0">
          <v-btn block @click="closeDialog()">Cancel</v-btn>
        </v-col>
      </v-row>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialog.vue'

import { getMessage } from '@/utils'
import { addSubscriptions } from '@/api/subscription.js'
import { deleteLocalImage } from '@/api/file.js'

export default {
  props: {
    dialog: {
      type: Boolean
    }
  },
  data: () => {
    return {
      getMessage,
      addSubscriptions,
      deleteLocalImage,

      name: null,
      description: null,
      amount_month: 0,
      amount_year: 0,
      color: null,
    }
  },
  components: {
    Dialog
  },
  computed: {
    disabled() {
      return (
        this.name == null ||
        this.name == ''
        
      )
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog', false)
      this.deleteLocalImage(this.image)
      this.resetDialog()
    },
    resetDialog() {
      this.name = null
      this.description = null
      this.amount_month = null
      this.amount_year = null
      this.color = null
    },
    submitDialog() {
      const data = {
        name: this.name,
        description: this.description,
        amount: this.amount_month,
        amount_year: this.amount_year,
        color: this.color,
        is_visible: 1
      }

      this.addSubscriptions(data).then((res) => {
        const subscription = res.data

        this.getMessage(this, res)
        this.closeDialog()

        this.$store.dispatch("subscription/getData", subscription)

      })
    }
  }
}
</script>
