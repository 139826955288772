<template>
  <Dialog :dialog="dialog" :width="350" :title="`Add Mood`" @closeDialog="closeDialog">
    <div>
      <div class="d-flex justify-center ma-5">
        <Upload v-if="dialog" :upload="false" :files="files" @getImageName="getImageName" />
      </div>

      <v-row class="ma-0 justify-space-between">
        <v-col cols="auto" class="pa-0">
          <p class="mb-1">Mood Name</p>
          <v-text-field solo dense hide-details class="mb-3" placeholder="Mood" v-model="name"></v-text-field>
        </v-col>
        <v-col cols="3" class="pa-0">
          <p class="mb-1">Color Card</p>
          <el-color-picker class="colorcard" v-model="description"></el-color-picker>
        </v-col>
      </v-row>

      <p class="mb-1">Description</p>
      <v-textarea solo dense hide-details class="mb-3" placeholder="Description" disabled
        v-model="description"></v-textarea>
      <p class="mb-1">Status</p>
      <v-autocomplete solo dense hide-details clearable v-model="status" :items="statusMood" item-text="text"
        item-value="value" />
    </div>

    <template v-slot:action>
      <v-row class="ma-0">
        <v-col class="pl-0 pr-2">
          <v-btn block @click="submitDialog()" :disabled="disabled || disabledbutton">Add</v-btn>
        </v-col>
        <v-col class="pl-2 pr-0">
          <v-btn block @click="closeDialog()">Cancel</v-btn>
        </v-col>
      </v-row>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialog.vue'
import Upload from '@/components/Upload.vue'
import { statusMood } from '@/utils/data'

import { getMessage } from '@/utils'
import { addMoods } from '@/api/mood.js'
import { deleteLocalImage } from '@/api/file.js'

export default {
  props: {
    dialog: {
      type: Boolean
    }
  },
  data: () => {
    return {
      getMessage,
      addMoods,
      deleteLocalImage,
      statusMood,

      files: [],
      disabledbutton: false,

      image: null,
      name: null,
      status: null,
      description: null,
    }
  },
  components: {
    Upload,
    Dialog
  },
  computed: {
    disabled() {
      return (
        this.image == '' ||
        this.name == '' ||
        this.status == '' ||
        this.description == '' ||
        this.image == null ||
        this.name == null ||
        this.status == null ||
        this.description == null
      )
    }
  },
  methods: {
    getImageName(value) {
      this.image = value
    },
    resetDialog() {
      this.image = null
      this.name = null
      this.status = null
      this.description = null
    },
    closeDialog() {
      this.$emit('closeDialog', false)
      this.deleteLocalImage(this.image)
      this.resetDialog()
    },
    submitDialog() {
      this.disabledbutton = true
      const data = {
        name: this.name,
        file: this.image,
        status: this.status,
        description: this.description
      }

      this.addMoods(data).then((res) => {
        const moods = res.data

        this.getMessage(this, res)
        this.closeDialog()

        this.$store.dispatch("mood/getData", moods)
      })
    }
  }
}
</script>

<!-- <style>
.upload-container > div {
  margin-right: 25px;
}
</style> -->
