<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you've always felt more energized and productive during the night, it's time to embrace your natural inclination! This subliminal is designed to help you optimize your creativity and productivity during those hours, so you can make the most of your night owl tendencies."
    },
    {
      type: 'heading',
      text: 'Benefits of embracing your night owl nature:'
    },
    {
      type: 'list',
      items: [
        "Increased productivity during nighttime hours.",
        "Enhanced creativity and problem-solving abilities during late hours.",
        "Improved focus and concentration during the night.",
        "Feeling more energized and alert during late hours.",
        "Enjoying a sense of flow and inspiration during your most active hours."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal on a regular basis, you can tap into your natural night owl tendencies and optimize your productivity and creativity during those hours. Embracing your preferred sleep schedule can lead to improved work performance and overall satisfaction with your daily routine. Remember, consistency is key to unlocking your full night owl potential!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embracing your natural inclination to be awake during the night can lead to increased productivity and creativity. Before you start this subliminal session, consider the following tips to optimize your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a conducive environment: Set up a workspace or creative area that is comfortable and inspiring.",
        "Designate your productive hours: Determine when you are most alert and focused during the night and plan your activities accordingly.",
        "Establish a routine: Create a consistent schedule for bedtime, waking up, and breaks to support your circadian rhythm.",
        "Set clear intentions: Clarify your goals for your productive nighttime hours and visualize yourself achieving them.",
        "Remove distractions: Minimize interruptions and eliminate distractions that may hinder your productivity.",
        "Embrace self-care: Take care of your physical and mental well-being to ensure optimal energy and focus during your night hours."
      ]
    },
    {
      type: 'paragraph',
      text: "With this subliminal, you can tap into your natural night owl tendencies and unlock your full potential during those hours. Embrace the uniqueness of your rhythm and allow yourself to thrive during the night. Now, it's time to begin your journey to heightened productivity and creativity!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Well done on completing the Become a Night Owl subliminal! By embracing your natural inclination to be awake during the night, you are unlocking the potential for increased productivity and creativity during those hours. Now, it's time to reflect on your experience and make the most of your new night owl lifestyle."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Design a schedule: Create a routine that aligns with your natural sleep pattern, maximizing your productivity during the night.",
        "Create a productive environment: Organize your workspace and eliminate distractions to stay focused during your nocturnal hours.",
        "Find your creative outlet: Explore activities that inspire your creativity and make the most of your creative energy during the night.",
        "Take care of your health: Prioritize a healthy lifestyle, including exercise, balanced meals, and adequate sleep during the day."
      ]
    },
    {
      type: 'paragraph',
      text: "As a night owl, it's important to find a balance between your natural inclination and the demands of everyday life. Listen to your body's needs and honor your creative and productive potential during the night. Your night owl journey has just begun, and there are endless possibilities ahead. Enjoy the magic of the night!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Become a Night Owl subliminal help me optimize my productivity and creativity during the night?`, 
    answer: `The Become a Night Owl subliminal is designed to align your subconscious mind with your natural inclination to be awake during the night. By listening to the subliminal regularly, it can help you cultivate a positive and focused mindset during those hours. This can lead to increased productivity, enhanced creativity, and improved overall performance during the night.`,
    open: false
  },
  { 
    question: `How long does it take to see the effects of the Become a Night Owl subliminal?`, 
    answer: `Results may vary from person to person. Some individuals may experience positive changes in their ability to be productive and creative during the night almost immediately, while others may require more time and consistent listening to fully reprogram their mindset. It is recommended to listen to the subliminal regularly and allow the affirmations to work on a subconscious level.`,
    open: false 
  },
  { 
    question: `Can the Become a Night Owl subliminal change my natural sleep-wake cycle?`, 
    answer: `The Become a Night Owl subliminal is designed to embrace your natural inclination to be awake during the night, rather than change your natural sleep-wake cycle. It aims to optimize your productivity and creativity during the hours that are most suitable for you. If you are looking to shift your sleep-wake cycle, it is recommended to consult with a sleep specialist or healthcare professional.`,
    open: false 
  },
  { 
    question: `Is it possible to feel well-rested and maintain a healthy lifestyle as a night owl?`, 
    answer: `Yes, it is possible to feel well-rested and maintain a healthy lifestyle as a night owl. By implementing good sleep hygiene practices and creating a consistent routine that prioritizes restful sleep during the day, you can ensure that you get the rest your body needs. It is important to maintain a balanced and healthy lifestyle, including proper nutrition, regular exercise, and managing stress levels to support overall well-being.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the Become a Night Owl subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Become a Night Owl subliminal. However, if you have any concerns about your sleep patterns or health, it is recommended to consult with a healthcare professional.`,
    open: false 
  },
  { 
    question: `Can the Become a Night Owl subliminal replace the need for sleep?`, 
    answer: `No, the Become a Night Owl subliminal is not intended to replace the need for sleep. Sleep is essential for overall health and well-being. The subliminal aims to optimize your productivity and creativity during the night, but it is important to prioritize restful sleep and ensure that you are getting enough restorative rest.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>