import { render, staticRenderFns } from "./75-mobile-phone-addiction-removal.vue?vue&type=template&id=58e5a9b3&scoped=true&"
import script from "./75-mobile-phone-addiction-removal.vue?vue&type=script&lang=js&"
export * from "./75-mobile-phone-addiction-removal.vue?vue&type=script&lang=js&"
import style0 from "./75-mobile-phone-addiction-removal.vue?vue&type=style&index=0&id=58e5a9b3&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58e5a9b3",
  null
  
)

export default component.exports