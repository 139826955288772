<template>
  <v-app>
    <template v-if="isEmptyLayout">
      <router-view />
    </template>
    <template v-else>
      <Appbar @openDrawer="openDrawer" v-if="!isUser" />
      <Sidebar :drawer="drawer" @closeDrawer="closeDrawer" v-if="!isUser" />

      <v-main>
        <div class="d-flex justify-content-center" v-if="isUser">
          <UserSidebar v-if="isUser" />
          <router-view style="overflow: hidden" />
        </div>
        <template v-else>
          <router-view />
        </template>
        <Guidebar />

        <Footer v-if="isMain" />
      </v-main>

      <NewUserFooter v-if="isUser" />
      <!-- <UserFooter v-if="isUser" /> -->

      <AudioLoading />
    </template>
    <!-- <Loading v-if="!isUser" /> -->
    <Loading />
  </v-app>
</template>

<script>
import { loginQueryParams } from "@/utils";

import { getMoods } from "@/api/mood";
import { getTracks } from "@/api/track";
import { getSearch } from "@/api/search";
import { getPlaylists } from "@/api/playlist";
import { getCategories, getUserCategories } from "@/api/category";
import { getAudioTypes } from "@/api/audiotype";
import { getSubliminals } from "@/api/subliminal";
import { getOwnPlaylist } from "@/api/ownplaylist";
import { getInfluencers, getUsers, getUserByUserId } from "@/api/user";
import { getSubscriptions, getSubscriptionPrices } from "@/api/subscription";
import {
  getFeatured,
  getDiscover,
  getRecommendation,
  getAudioHistory,
} from "@/api/home";

import Appbar from "@/components/Home/Appbar";
import UserAppbar from "@/components/User/UserAppbar";
import Footer from "@/components/Home/Footer";
import Sidebar from "@/components/Sidebar";
import Loading from "@/components/Loading";

import UserSidebar from "@/components/User/UserSidebar";
import NewUserFooter from "@/components/User/NewUserFooter";
import UserFooter from "@/components/UserFooter";
import Webplayer from "@/components/Webplayer";
import Guidebar from "@/components/User/Guidebar.vue";
import AudioLoading from "@/components/AudioLoading";

import { playlistMixins } from "@/mixins/playlist.js";

export default {
  mixins: [playlistMixins],
  components: {
    Appbar,
    Footer,
    Sidebar,
    Loading,
    Guidebar,
    Webplayer,
    AudioLoading,
    UserFooter,
    UserSidebar,
    UserAppbar,
    NewUserFooter,
  },
  data: () => {
    return {
      loginQueryParams,

      getUsers,
      getMoods,
      getTracks,
      getSearch,
      getFeatured,
      getDiscover,
      getPlaylists,
      getCategories,
      getUserCategories,
      getAudioTypes,
      getInfluencers,
      getSubliminals,
      getOwnPlaylist,
      getUserByUserId,
      getAudioHistory,
      getSubscriptions,
      getRecommendation,
      getSubscriptionPrices,

      drawer: false,
    };
  },
  computed: {
    currentRoute() {
      return this.$route.name;
    },
    layout() {
      // Main, User, Admin
      return this.$route.matched[0].meta.layout;
    },
    userType() {
      return this.loginQueryParams();
    },
    user() {
      return this.$store.state.user.info;
    },
    admin() {
      return this.$store.state.admin.info;
    },
    users() {
      return JSON.parse(this.user);
    },
    admins() {
      return JSON.parse(this.admin);
    },
    subscriptions() {
      return this.$store.state.subscription.data;
    },
    subscriptionId() {
      return this.$store.state.subscription.id;
    },
    userToken() {
      return this.$store.state.user.token;
    },
    adminToken() {
      return this.$store.state.admin.token;
    },
    isUser() {
      return this.layout == "User";
    },
    isMain() {
      return this.layout == "Main";
    },
    isUserLogin() {
      return this.userToken != null;
    },
    isAdminLogin() {
      return this.adminToken != null;
    },
    isMobile() {
      return this.$store.state.screen.width < 1264;
    },
    /*isEmptyLayout() {
      return ["reset/password"].includes(this.currentRoute);
    },
    isEmptyLayout() {
      return ["html"].includes(this.currentRoute);
    },*/
    //Individual declarations for Guide Pages EmptyLayout
    isEmptyLayout() {
      return [
      "html",
      "user/mood",
      "reset/password",
      "price/mobile",
      "insomnia-cure",
      "hangover-cure",
      "epilepsy-healing",
      "healthy-sleep-recovery-and-healing",
      "bacterial-infection-healing",
      "general-pain-healing",
      "body-pain-relief-and-healing",
      "bone-pain-relief-and-healing",
      "muscle-pain-healing",
      "nerve-pain-healing",
      "mentally-induced-pain-healing",
      "anti-aging",
      "antioxidant-treatment",
      "erectile-dysfunction-healing",
      "stop-premature-ejaculation",
      "male-genital-warts-removal-and-healing",
      "menstrual-cycle-healing",
      "polycystic-ovary-syndrome-(pcos)-healing",
      "female-genital-warts-healing",
      "general-weight-loss",
      "healthy-eating-habits",
      "diet-motivation",
      "stop-eating-sweets",
      "stop-eating-junk-food",
      "eyesight-improvement",
      "metabolism-enhancement",
      "irritable-bowel-syndrome-(ibs)-treatment",
      "irritable-bowel-syndrome-(ibs)-treatment",
      "irritable-bowel-syndrome-(ibs)-treatment",
      "migraine-pain-relief-and-healing",
      "avoid-burnout",
      "immune-system-boost",
      "immune-system-boost",
      "general-stress-relief-and-healing",
      "exercise-motivation",
      "muscle-recovery-aid",
      "muscle-growth-aid",
      "relaxation-aid",
      "fall-asleep-fast",
      "fall-asleep-fast",
      "relieve-constipation",
      "gray-hair-reversal-and-natural-color-restoration",
      "rock-hard-abs",
      "look-younger",
      "facial-toning-and-development",
      "youthful-face",
      "facial-acne-removal-and-treatment",
      "golden-ratio-facial-alignment",
      "double-chin-removal",
      "facial-skin-regeneration",
      "stretch-mark-healing-and-removal",
      "digestion-enhancement",
      "posture-alignment-and-training",
      "body-acceptance-and-confidence",
      "baldness-acceptance-and-confidence",
      "disabilities-acceptance-and-confidence",
      "age-acceptance-and-confidence",
      "height-acceptance-and-confidence",
      "facial-slimming",
      "golden-ratio-and-symmetrical-face-development",
      "healthy-hair-growth",
      "smoking-addiction-removal",
      "stop-smoking-completely",
      "stop-recreational-drug-abuse",
      "stop-using-illegal-drugs-completely",
      "alcohol-addiction-removal",
      "cannabis-addiction-removal",
      "freedom-from-drug-addiction-and-abuse",
      "gambling-addiction-removal",
      "pornography-addiction-removal",
      "masturbation-addiction-removal",
      "alcohol-moderation",
      "smoking-moderation",
      "social-media-addiction-removal",
      "mobile-phone-addiction-removal",
      "soft-drinks-addiction-removal",
      "binge-eating-habit-removal",
      "binge-drinking-habit-removal",
      "binge-watching-habit-removal",
      "chocolate-addiction-removal",
      "sweets-addiction-removal",
      "selfie-addiction-removal",
      "stop-nail-biting",
      "stop-swearing",
      "stop-mumbling",
      "stop-stuttering",
      "stop-talking-too-fast",
      "stop-noisy-breathing",
      "stop-teeth-grinding",
      "stop-sweating-excessively",
      "stop-cracking-fingers-and-knuckles",
      "stop-foot-stomping",
      "stop-over-gesturing",
      "fear-of-closed-spaces-and-claustrophobia-removal",
      "fear-of-open-spaces-and-agoraphobia-removal",
      "fear-of-flying-and-aerophobia-removal",
      "fear-of-clustered-holes-and-trypophobia-removal",
      "fear-of-schools-and-school-phobia-removal",
      "fear-of-commitment-and-commitment-phobia-removal",
      "fear-of-death-removal",
      "fear-of-aging-and-gerascophobia-removal",
      "fear-of-abandonment-removal",
      "fear-of-being-alone-removal",
      "fear-of-sickness-and-diseases-removal",
      "fear-of-cockroaches-and-cockroach-phobia-removal",
      "fear-of-snakes-and-snake-phobia-removal",
      "fear-of-spiders-and-arachnophobia-removal",
      "fear-of-doctors-and-doctor-phobia-removal",
      "fear-of-dogs-and-cynophobia-removal",
      "fear-of-blood-and-hemophobia-removal",
      "fear-of-injection-removal",
      "fear-of-cancer-removal",
      "fear-of-childbirth-removal-for-women",
      "fear-of-clowns-and-clown-phobia-removal",
      "fear-of-conflict-removal",
      "fear-of-confrontation-removal",
      "fear-of-criticism-removal",
      "fear-of-being-vulnerable-removal",
      "fear-of-heights-and-acrophobia-removal",
      "fear-of-ghosts-removal",
      "fear-of-inadequacy-removal",
      "fear-of-public-speaking-removal",
      "fear-of-missing-out-fomo-removal",
      "fear-of-intimacy-removal",
      "fearless-living---stop-living-in-fear",
      "overcome-analysis-paralysis",
      "remove-and-heal-fear-based-anxiety",
      "stop-suicidal-thoughts-and-suicide-prevention",
      "stop-feelings-of-worthlessness",
      "attract-money",
      "attract-luck",
      "become-debt-free",
      "acquire-leadership-qualities",
      "sales-success",
      "stock-market-trading-success",
      "forex-and-currency-trading-success",
      "cryptocurrency-trading-success",
      "successful-entrepreneur-programming",
      "time-management-and-productivity",
      "manifest-relationship-with-a-wealthy-lover",
      "manifest-a-sugar-mommy",
      "manifest-a-sugar-daddy",
      "manifest-your-dream-job-and-career",
      "manifest-your-dream-business",
      "disconnect-from-people-who-drags-you-down",
      "disconnect-from-toxic-coworkers",
      "curse-cancellation-and-protection-shield",
      "cancel-bankruptcy",
      "anger-management-issues-removal",
      "emotional-release",
      "anger-release",
      "anxiety-healing",
      "general-emotional-healing",
      "get-over-and-move-on-from-your-ex-and-past-relationships",
      "break-up-emotional-healing-and-recovery",
      "get-over-and-move-on-from-divorce",
      "divorce-emotional-pain-healing",
      "broken-family-emotional-healing",
      "emotional-pain-management",
      "emotional-pain-management",
      "release-forgiveness",
      "general-trauma-healing",
      "depression-healing-and-removal",
      "general-abuse-healing",
      "master-your-emotions-and-responses",
      "overcome-cyber-bullying",
      "heal-post-traumatic-stress-disorder-(ptsd)",
      "safe-haven---feel-safe-and-secure-always",
      "calm-and-peaceful-feelings",
      "toxic-shame-healing",
      "childbirth-trauma-healing",
      "stop-self-harming",
      "self-forgiveness",
      "forgive-others-and-move-on",
      "assertiveness-training",
      "develop-positive-humor",
      "happier-life-now",
      "become-an-early-riser",
      "become-a-night-owl",
      "socialization-and-social-skills-enhancement",
      "let-go-of-the-past-and-move-on",
      "positive-thinking-and-mindset",
      "positive-attitude-training-and-development",
      "toxic-and-negative-attitude-removal",
      "behavioral-development",
      "public-speaking-skills-improvement-and-training",
      "resolve-inner-conflict",
      "overcome-and-heal-shame",
      "resolve-and-heal-guilt",
      "self-care",
      "self--responsibility",
      "self-belief",
      "self-discipline",
      "meditation-aid-and-improvement",
      "charisma-development",
      "self-love",
      "self-acceptance",
      "self-consideration",
      "insecurity-removal",
      "stop-being-a-people-pleaser",
      "learn-to-say-no",
      "accepting-new-challenges-and-expanding-comfort-zone",
      "stop-talking-too-much",
      "failure-mental-and-emotional-stability-and-recovery",
      "become-more-resilient",
      "courageously-fearless",
      "overcoming-shyness",
      "stop-hair-loss",
      "develop-emotional-maturity",
      "positive-relationship-with-parents",
      "positive-relationship-with-coworkers",
      "positive-relationship-with-boss",
      "positive-relationship-with-siblings",
      "positive-relationship-with-lover",
      "get-over-jealousy",
      "heal-relationship-anxiety",
      "maximum-athletic-performance-enhancement",
      "basketball-skills-training-and-mastery",
      "baseball-skills-training-and-mastery",
      "american-football-skills-and-training-mastery",
      "rugby-skills-and-training-mastery",
      "archery-skills-and-training-mastery",
      "darts-skills-and-training-mastery",
      "boxing-training-and-mastery",
      "fencing-training-and-mastery",
      "kendo-training-and-mastery",
      "wrestling-training-and-mastery",
      "muay-thai-training-and-mastery",
      "kali-arnis-training-and-mastery",
      "yaw-yan-fervilleon-martial-arts-training-and-mastery",
      "swordsmanship-training-and-mastery",
      "judo-training-and-mastery",
      "aikido-training-and-mastery",
      "jeet-kune-do-training-and-mastery",
      "wing-chun-kung-fu-training-and-mastery",
      "jujitsu-training-and-mastery",
      "karate-training-and-mastery",
      "taekwondo-training-and-mastery",
      "tai-chi-training-and-mastery",
      "bajiquan-training-and-mastery",
      "singing-talent-and-skills-enhancement",
      "dancing-talent-and-skills-enhancement",
      "musical-instruments-talent-and-skills-enhancement",
      "drawing-talent-and-skills-enhancement",
      "painting-talent-and-skills-enhancement",
      "become-a-successful-teacher",
      "become-a-successful-insurance-broker",
      "become-a-successful-vlogger",
      "become-a-successful-youtuber",
      "become-a-successful-call-center-agent",
      "become-a-successful-programmer",
      "become-a-successful-social-media-marketer",
      "become-a-successful-architect",
      "become-a-successful-mechanical-engineer",
      "become-a-successful-civil-engineer",
      "become-a-successful-project-manager",
      "become-a-successful-lawyer",
      "brain-healing-and-regeneration",
      "strategic-thinking-skills-training-and-development",
      "memory-enhancement-and-booster",
      "chess-training-and-mastery",
      "board-games-training-and-mastery",
      "creative-thinking",
      "brain-performance-enhancement",
      "numerical-intelligence-improvement",
      "speed-reading-success",
      "photoreading-success",
      "stop-the-habit-of-translating-in-the-mind-before-you-speak",
      "general-language-learning-aid",
      "proper-accent-speech-training",
      "english-language-learning-aid",
      "korean-language-learning-aid",
      "nihongo-japanese-language-learning-aid",
      "mandarin-chinese-language-learning-aid",
      "russian-language-learning-aid",
      "spanish-language-learning-aid",
      "indian-language-learning-aid",
      "confidence-for-single-dad",
      "confidence-for-single-mom",
      "parenting-confidence",
      "confidence-with-the-opposite-sex",
      "flirting-confidence-for-men",
      "flirting-confidence-for-women",
      "lucid-dreaming-assistance",
      "astral-projection-and-travel",
      "dream-memory-recall",
      "make-people-respect-you",
      "end-nightmares",
      "stop-drunk-driving",
      "stop-second-hand-smoking",
      "magnetic-personality",
      "improve-personal-magnetism",
      "social-gathering-confidence",
      "self-confidence-booster",
      "healthy-body-development",
      "personal-power-development",
      "success-secrets",
      "general-life-success",
      "housework-productivity-and-enjoyment",
      "exam-success",
      "focus-and-concentration-training-and-development",
      "job-interview-success",
      "investment-presentation-success",
      "law-of-attraction-maximizer",
      "quantum-jumping-success",
      "motivation-improvement",
      "overcome-and-stop-procrastination",
      "achieve-goals",
      "anorexia-healing",
      "attract-health",
      "have-a-healthy-lifestyle",
      "become-more-sociable-and-outgoing",
      "become-more-persistent",
      "become-more-productive",
      "stop-bed-wetting",
      "charismatic-personality-enhancement",
      "sex-addiction-healing-and-removal",
      "freedom-from-worry",
      "stop-anxiety-based-panic-attacks",
      "develop-telepathy",
      "think-like-a-wealthy-person",
      "getting-things-done-habit-development",
      "have-an-extroverted-personality",
      "thinking-outside-the-box",
      "confidence-for-tall-man",
      "confidence-for-short-man",
      "confidence-for-short-women",
      "confidence-for-person-with-disabilities",
      "reinventing-yourself",
      "restless-leg-syndrome",
      "organization-skills-enhancement",
      "manifestation-willpower-enhancement",
      "tap-into-your-personal-power",
      "think-big-and-make-it-big",
      "push-plans-and-goals-to-the-limit",
      "attitude-of-gratitude",
      "attract-abundant-money",
      "attract-success",
      "attract-prosperity",
      "law-of-attraction-success",
      "financially-organized",
      "plan-the-movement-of-money-carefully",
      "sustainable-financials-and-financial-plan",
      "have-enough-money-to-pay-off-financial-obligations",
      "millionaire-money-mindset",
      "solid-decision-belief-and-conviction",
      "emotion-management",
      "purpose-driven-life-know-your-purpose-in-life",
      "love-languages-mastery-and-expression",
      "mindfulness-training",
      "enhanced-vitality",
      "motion-sickness-cure",
      "positive-self-talk",
      "clarity-of-mind",
      "getting-unstuck",
      "optimistic-mindset",
      "housework-motivation",
      "real-estate-selling-success",
      "weatherproof-mindset",
      "opportunity-obstacles-and-blockages-removal",
      "minimalist-living",
      "self-judgement-healing",
      "intentional-living---making-every-moment-matters",
      "inner-belief-and-conviction-manifestation",
      "self-life-coach---become-your-own-life-coach",
      "akashic-records-accessing",
      "toothache-pain-relief",
      "deep-sleep-training",
      "healthy-hormonal-balancing",
      "puzzle-games-skills-training-and-development",
      "rubiks-cube-skills-training-and-development",
      "sense-of-smell-and-taste-healing-and-recovery",
      "become-online-and-virtual-extrovert",
      "exercise-and-workout-results-enhancer",
      "patriotism-and-end-colonial-mentality",
      "idea-creation-mastery",
      "stop-snoring",
      "intention-discernment",

      ].includes(this.currentRoute);
    },

  },
  mounted() {
    this.$store.dispatch("screen/getLoading", true);
    this.getData();

    // save user & admin info and token
    this.setUserData();
    window.addEventListener("beforeunload", this.setLocalUserData());

    // check if user or admin is login
    this.checkLogin("user");
    this.checkLogin("admin");

    // set width and height of browser
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    if (this.layout == "Admin") {
      this.$store.dispatch("audio/getTrackId", null);
      this.$store.dispatch("audio/getPlaylistId", null);
      this.$store.dispatch("audio/getSubliminalId", null);
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    layout() {
      this.setUserData();
      window.addEventListener("beforeunload", this.setLocalUserData());

      this.getData();
    },
    isMobile() {
      this.drawer = false;
    },
    subscriptions(val) {
      if (this.layout == "User" && val != undefined) {
        this.$store.dispatch("subscription/getId", this.subscriptions[0].id);
        this.getRecentPlayedData();
        this.getMoodData();
        this.getFeaturedData();
        this.getDiscoverData();
        this.getOwnPlaylistData();
        this.getRecentSearchData();
        this.getRecommendationData();
        this.getUserCategoryData();
      }
    },
  },
  methods: {
    openDrawer(data) {
      this.drawer = data;
    },
    closeDrawer(data) {
      this.drawer = data;
    },
    handleResize() {
      // set height and width of browser
      this.$store.dispatch("screen/getWidth", window.innerWidth);
      this.$store.dispatch("screen/getHeight", window.innerHeight);
    },
    checkLogin(type) {
      const isLogin = type == "user" ? this.isUserLogin : this.isAdminLogin;

      // check if user was login or not
      if (isLogin) {
        // refrain user from going to login and register page
        if (this.$route.name == "login" && this.userType == type) {
          this.$router.push(`/${type}`);
        }

        if (this.$route.name == "register" && this.isUserLogin) {
          this.$router.push(`/${type}`);
        }
      } else {
        // redirect to login
        if (this.layout.toLowerCase() == type) {
          this.$router.push(`/login?type=${type}`);
        }
      }
    },
    setLocalUserData() {
      // set token and info in localstorage
      if (this.userToken != null) {
        this.$store.dispatch("user/getLocalInfo", this.user);
        this.$store.dispatch("user/getLocalToken", this.userToken);
      }
      if (this.adminToken != null) {
        this.$store.dispatch("admin/getLocalInfo", this.admin);
        this.$store.dispatch("admin/getLocalToken", this.adminToken);
      }
    },
    setUserData() {
      // set token and info in vuex
      if (localStorage.getItem("user_token") != null) {
        this.$store.dispatch("user/getInfo", localStorage.getItem("user"));
        this.$store.dispatch(
          "user/getToken",
          localStorage.getItem("user_token")
        );
      }

      if (localStorage.getItem("admin_token") != null) {
        this.$store.dispatch("admin/getInfo", localStorage.getItem("admin"));
        this.$store.dispatch(
          "admin/getToken",
          localStorage.getItem("admin_token")
        );
      }
    },

    getUserData() {
      this.getUsers().then((res) => {
        this.$store.dispatch("user/getData", res);
      });
    },
    getUserByUserIdData() {
      const user = localStorage.getItem("user");

      if (user != undefined || user != null) {
        const data = JSON.parse(user);
        this.getUserByUserId(data).then((res) => {
          if (res != undefined) {
            this.$store.dispatch("user/getInfo", JSON.stringify(res[0]));
            this.$store.dispatch("user/getLocalInfo", JSON.stringify(res[0]));
          }
        });
      }
    },
    getMoodData() {
      this.getMoods().then((res) => {
        this.$store.dispatch("mood/getData", res);
      });
    },
    getTrackData() {
      this.getTracks().then((res) => {
        this.$store.dispatch("track/getData", res);
      });
    },
    getAudioTypeData() {
      this.getAudioTypes().then((res) => {
        this.$store.dispatch("audio/getTypes", res);
      });
    },
    getCategoryData() {
      this.getCategories().then((res) => {
        this.$store.dispatch("category/getData", res);
      });
    },
    getPlaylistData() {
      this.getPlaylists().then((res) => {
        this.$store.dispatch("playlist/getData", res);
      });
    },
    getInfluencerData() {
      this.getInfluencers().then((res) => {
        this.$store.dispatch("user/getInfluencer", res);
      });
    },
    getSubliminalData() {
      this.getSubliminals().then((res) => {
        this.$store.dispatch("subliminal/getData", res);
      });
    },
    getSubscriptionData() {
      this.getSubscriptions().then((res) => {
        this.$store.dispatch("subscription/getData", res);
      });
    },
    getSubscriptionPriceData() {
      this.getSubscriptionPrices().then((res) => {
        this.$store.dispatch("subscription/getData", res);
      });
    },
    getFeaturedData() {
      if (this.user != null) {
        const data = {
          user_id: this.users.user_id,
          subscription_id: this.users.info.subscription_id,
        };

        this.getFeatured(data).then((res) => {
          const playlist = res.playlist;
          const subliminal = res.subliminal;
          let result = playlist.concat(subliminal);

          const results = result.sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at);
          });
          this.$store.dispatch("featured/getData", results);
          this.$store.dispatch("featured/getLoading", false);
        });
      }
    },
    getDiscoverData() {
      if (this.user != null) {
        const data = {
          user_id: this.users.user_id,
          subscription_id: this.users.info.subscription_id,
        };

        this.getDiscover(data).then((res) => {
          const playlist = res.playlist;
          const subliminal = res.subliminal;
          let result = playlist.concat(subliminal);

          const results = result.sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at);
          });
          this.$store.dispatch("discover/getData", results);
          this.$store.dispatch("discover/getLoading", false);
        });
      }
    },
    getRecommendationData() {
      if (this.user != null) {
        const data = {
          user_id: this.users.user_id,
          subscription_id: this.users.info.subscription_id,
          mood_id: this.users.info.moods_id,
        };

        this.getRecommendation(data).then((res) => {
          const playlist = res.playlist;
          const subliminal = res.subliminal;
          let result = playlist.concat(subliminal);

          const results = result.sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at);
          });
          this.$store.dispatch("recommendation/getData", subliminal);
          this.$store.dispatch("recommendation/getLoading", false);
        });
      }
    },
    getRecentSearchData() {
      if (this.user != null) {
        const data = {
          subscription_id: this.users.info.subscription_id,
          user_id: this.users.user_id,
        };

        this.getSearch(data).then((res) => {
          this.$store.dispatch("recent/getSearch", res);
          this.$store.dispatch("recent/getSearchLoading", false);
        });
      }
    },
    getOwnPlaylistData() {
      if (this.user != null) {
        const data = {
          subscription_id: this.users.info.subscription_id,
          user_id: this.users.user_id,
        };
        this.getOwnPlaylist(data).then((res) => {
          const result = res.sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at);
          });

          this.$store.dispatch("playlist/getOwnData", result);
          this.$store.dispatch("playlist/getOwnLoading", false);
        });
      }
    },
    getRecentPlayedData() {
      if (this.user != null) {
        const data = {
          user_id: this.users.user_id,
          subscription_id: this.users.info.subscription_id,
        };

        this.getAudioHistory(data).then((res) => {
          const playlist = res.playlist;
          const subliminal = res.subliminal;
          let result = subliminal;

          /*let results = result.sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at);
          });*/

          this.$store.dispatch("recent/getPlayed", subliminal);
          this.$store.dispatch("recent/getPlayedLoading", false);
        });
      }
    },
    getUserCategoryData() {
      const data = {
        user_id: this.users.user_id,
        subscription_id: this.users.info.subscription_id,
        mood_id: this.users.info.moods_id,
      };

      this.getUserCategories(data).then((res) => {
        this.$store.dispatch("category/getUserCategory", res.data);
      });
    },
    getData() {
      this.$store.dispatch("screen/getLoading", true);
      this.$store.dispatch("screen/getLayout", this.layout);

      switch (this.layout) {
        case "Main":
          this.getUserByUserIdData();
          this.getSubscriptionPriceData();
          this.$store.dispatch("screen/getLoading", false);
          break;
        case "User":
          this.getUserByUserIdData();
          this.getSubscriptionData();
          this.getCategoryData();

          if (this.$route.path != "/user/mood") {
            this.$store.dispatch("screen/getLoading", false);
          }
          break;
        case "Admin":
          this.getUserData();
          this.getMoodData();
          this.getTrackData();
          this.getPlaylistData();
          this.getCategoryData();
          this.getAudioTypeData();
          this.getInfluencerData();
          this.getSubliminalData();
          this.getSubscriptionData();
          this.$store.dispatch("screen/getLoading", false);
          break;
      }
    },
  },
};
</script>

<style scoped>
@media screen and (min-width: 990px) {
  .v-main__wrap > div {
    gap: 24px;
  }
}
</style>
