<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're struggling with recreational drug abuse and want to break free from the destructive cycle, you're in the right place. This subliminal is designed to help you overcome addiction and take control of your life."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support you:'
    },
    {
      type: 'list',
      items: [
        "Assisting in reducing cravings and urges to use recreational drugs.",
        "Encouraging a positive mindset and motivation to stay substance-free.",
        "Providing support during withdrawal symptoms and the recovery process.",
        "Helping to address underlying issues that contribute to drug abuse.",
        "Promoting the development of healthier coping mechanisms and habits."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to resist the temptation of recreational drugs and embrace a healthier, substance-free future. Recovery is a journey, and this subliminal can be an important tool in the process. Remember, seeking additional support from professionals is also crucial in overcoming addiction."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Breaking free from recreational drug abuse is a difficult journey, but this subliminal is here to support you every step of the way. Before you start listening, consider these strategies to help you on your path to recovery:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Clearly define your desire to break free from the destructive cycle of recreational drug abuse.",
        "Create a support system: Surround yourself with positive influences who understand your journey and can provide support along the way.",
        "Identify triggers: Recognize the situations, people, or emotions that often lead to drug abuse and develop strategies to cope with them.",
        "Visualize a substance-free future: Envision yourself living a healthy and fulfilling life without the need for recreational drugs.",
        "Commit to self-care: Prioritize your physical, mental, and emotional well-being during your recovery process."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal is designed to help you rewire your subconscious mind and build a foundation for a healthier, substance-free future. As you listen regularly, you may start to experience increased self-control, reduced cravings, and a greater sense of empowerment. Embrace this moment of change, trust in your ability to overcome addiction, and let's begin your journey towards a brighter future."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Stop Recreational Drug Abuse subliminal! By taking this step towards overcoming addiction and breaking free from the destructive cycle of recreational drug abuse, you have shown incredible strength and determination. Now, it's important to reflect on your experience and continue on your path to a healthier, substance-free future."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Seek support: Reach out to friends, family, or support groups who can provide guidance and encouragement.",
        "Create a healthy routine: Establish a structured daily routine that promotes physical and mental well-being.",
        "Avoid triggers: Identify and avoid situations, people, or places that may tempt you to use recreational drugs.",
        "Focus on self-care and stress management: Prioritize activities and practices that help you relax and cope with stress in healthy ways."
      ]
    },
    {
      type: 'paragraph',
      text: "Recovering from recreational drug abuse is a courageous journey, and you are making progress each day. Remember to be patient with yourself and celebrate each small victory along the way. With perseverance and a strong support system, you can create a healthier, substance-free future. Keep going, you're doing amazing!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Stop Recreational Drug Abuse subliminal help me?`, 
    answer: `The Stop Recreational Drug Abuse subliminal is designed to help you break free from the destructive cycle of recreational drug abuse. By influencing your subconscious mind, the subliminal messages aim to strengthen your motivation, willpower, and determination to overcome addiction. It can help you develop a healthier mindset and provide support as you work towards a substance-free future.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Stop Recreational Drug Abuse subliminal?`, 
    answer: `Results may vary depending on individual circumstances and the severity of the addiction. Breaking free from recreational drug abuse is a personal journey that requires commitment and perseverance. Consistent listening to the subliminal messages, combined with seeking support from professionals and building a strong support network, can help you make progress. It's important to approach recovery as a long-term process and be patient with yourself.`,
    open: false 
  },
  { 
    question: `Is the Stop Recreational Drug Abuse subliminal a replacement for professional help or treatment?`, 
    answer: `No, the Stop Recreational Drug Abuse subliminal is not a substitute for professional help or treatment. It is intended to be used as a complement to other therapeutic interventions, such as counseling, support groups, or rehabilitation programs. If you are struggling with recreational drug abuse, it is important to seek professional guidance to develop a comprehensive treatment plan tailored to your needs.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Stop Recreational Drug Abuse subliminal?`, 
    answer: `There are no known risks or side effects associated with listening to the Stop Recreational Drug Abuse subliminal. However, it is important to note that while the subliminal can be a supportive tool on your journey to recovery, it should not replace professional help or treatment. If you have concerns about your addiction or health, please consult with a healthcare professional or addiction specialist.`,
    open: false 
  },
  { 
    question: `Can the Stop Recreational Drug Abuse subliminal be used alongside other addiction recovery methods?`, 
    answer: `Yes, the Stop Recreational Drug Abuse subliminal can be used alongside other addiction recovery methods. It is often beneficial to combine multiple strategies to increase your chances of success. The subliminal can provide additional support and reinforcement as you engage in therapy, attend support groups, and make positive lifestyle changes to overcome addiction.`,
    open: false 
  },
  { 
    question: `Will the Stop Recreational Drug Abuse subliminal completely eliminate my cravings for drugs?`, 
    answer: `The Stop Recreational Drug Abuse subliminal aims to strengthen your motivation, willpower, and determination to overcome addiction. While it can help you develop a healthier mindset and reduce cravings over time, it is important to understand that addiction is a complex issue, and overcoming it requires a comprehensive approach. Combining the subliminal with professional help, therapy, and healthy coping strategies can enhance your chances of success.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>