<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're ready to let go of the past and move on, this subliminal can help guide you towards a fresh start. By targeting your subconscious mind, it can assist you in releasing any negative emotions or attachments to past experiences."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support you:'
    },
    {
      type: 'list',
      items: [
        "Letting go of grudges, resentment, or anger towards others or yourself.",
        "Learning from past mistakes and using them as stepping stones for personal growth.",
        "Building resilience and embracing change as an opportunity for growth.",
        "Creating a positive mindset and focusing on the present moment.",
        "Forgiving yourself and others, allowing for inner peace and healing.",
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can rewire your subconscious mind and cultivate a mindset that is more aligned with your desire to let go of the past. Remember, it's a process, and progress may take time. Trust in your ability to heal and create a brighter future for yourself."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Moving on from the past can be a challenging process, but this subliminal is designed to help you let go and embrace the present. Before you start listening, here are some tips to help you prepare:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your emotions: Take a moment to recognize and accept the emotions associated with your past experiences.",
        "Practice self-compassion: Be kind to yourself as you navigate through the process of letting go.",
        "Reflect and learn: Consider the lessons you've learned from the past and how they can shape your future in a positive way.",
        "Visualize a fresh start: Imagine yourself moving forward, free from the weight of the past, and focus on the positive changes you want to make."
      ]
    },
    {
      type: 'paragraph',
      text: "Listening to this subliminal can help reprogram your subconscious mind, allowing you to release the past and move forward with a renewed sense of peace and clarity. Embrace this opportunity for growth and transformation, and take the first step towards a brighter future."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the 'Let go of the Past and Move on' subliminal session! By choosing to release the past and embrace personal growth, you've taken a courageous step towards a brighter future. Reflecting on your experience is essential as you continue on your journey of letting go and moving forward."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Self-reflection: Take time to introspect and identify any emotions or patterns that may still be holding you back.",
        "Acceptance: Practice accepting the past as a part of your life's journey and acknowledge the lessons it taught you.",
        "Forgiveness: Forgive yourself and others involved in past experiences to release any residual negativity.",
        "Gratitude: Cultivate a sense of gratitude for the present moment and the opportunities it holds for personal growth.",
        "Setting new goals: Take this opportunity to establish new goals and aspirations for your future."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, the process of letting go and moving on is unique to each individual. Be patient with yourself and celebrate the progress you make along the way. Embrace the present moment and keep your focus on building a brighter future filled with joy, fulfillment, and personal transformation. You've got this!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the "Let go of the Past and Move on" subliminal help me?`, 
    answer: `The "Let go of the Past and Move on" subliminal is designed to help you release emotional attachments to the past and cultivate a mindset of acceptance, forgiveness, and growth. By listening to the subliminal messages, you can reprogram your subconscious mind to let go of negative experiences, heal emotional wounds, and embrace a brighter future. It can assist you in moving on from the past while learning valuable lessons from your experiences.`,
    open: false
  },
  { 
    question: `How long will it take to let go of the past using this subliminal?`, 
    answer: `The time it takes to let go of the past using the subliminal can vary from person to person. Some individuals may notice positive changes in their mindset and emotional well-being after a few listens, while others may require more time and consistent repetition. It is recommended to listen to the subliminal daily and have patience with the process.`,
    open: false 
  },
  { 
    question: `Can the subliminal help me heal from past trauma?`, 
    answer: `The "Let go of the Past and Move on" subliminal can help facilitate emotional healing and support your journey towards recovery from past trauma. However, it is important to note that this subliminal is not a substitute for professional therapy or medical treatment. If you are dealing with severe trauma or mental health issues, it is advisable to seek the guidance of a qualified mental health professional.`,
    open: false 
  },
  { 
    question: `What can I do to enhance the effectiveness of the "Let go of the Past and Move on" subliminal?`, 
    answer: `To enhance the effectiveness of the subliminal, it is recommended to create a supportive environment by surrounding yourself with positive influences, engaging in self-care practices, and seeking support from loved ones or a therapist. Additionally, practicing mindfulness, journaling, and engaging in activities that bring you joy can also aid in the process of letting go and moving on.`,
    open: false 
  },
  { 
    question: `Will the subliminal erase all memories of the past?`, 
    answer: `No, the "Let go of the Past and Move on" subliminal does not aim to erase memories of the past entirely. Instead, it focuses on helping you develop a healthier perspective and emotional detachment from negative experiences. It encourages you to learn from the past, grow from it, and create a more positive future.`,
    open: false 
  },
  { 
    question: `What if I have difficulty letting go of specific events or people from my past?`, 
    answer: `If you find it challenging to let go of specific events or people from your past, consider seeking support from a therapist or counselor. They can provide guidance and techniques tailored to your specific circumstances and help you navigate the healing process. The "Let go of the Past and Move on" subliminal can complement therapy or self-help practices but should not replace professional support when needed.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>