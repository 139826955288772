<template>
  <div
    id="resetpassword"
    :class="`content ${isfull} px-5`"
    :style="`height: ${contentheight}px`"
  >
    <p :class="`title ${isfull}`">How to Reset your Password</p>
    <p>
      If you're having trouble logging in to your Magus account, you can reset
      or update your password.
    </p>

    <p>
      If you originally created your account using other 3rd party sign-in
      options and you would like to use your email address and password to log
      in to Magus, please see this article for more information.
    </p>

    <p id="resetlimit">
      If you don't remember your current password, follow the steps below:
    </p>
    <p>Reset Password on the mobile app</p>
    <ol class="my-5">
      <li>Open the Magus app and “Sign in with Email”.</li>
      <li>Tap “Forgot Password”.</li>
      <li>
        Type in your email address associated with your Magus account and press
        “Submit”.
      </li>
      <li>
        You will receive an email with a link to reset your password. Please
        check your spam folder if you do not see the email in your inbox.
      </li>
      <li>The email should look like this:</li>
      <li>
        Click the link and you will be redirected to the “Reset Password” page.
      </li>
      <li>Type in your new password.</li>
      <li>Confirm your new password.</li>
      <li>Click “Reset Password”.</li>
    </ol>
    <p>
      If you have not received the email after 10 minutes, please add
      info@magusaudio.com to your email contact list and try resetting your
      password again.
    </p>

    <p>
      For security reasons, your password reset link must be accessed within 90
      minutes. After 90 minutes, the link will expire, but you can always
      request a new link by following these steps again.
    </p>

    <div class="d-flex justify-space-between">
      <p class="link" @click="getAction('support')">Support Page</p>
      <p class="link" @click="getAction('delete')">Delete Account</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    full: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data: () => {
    return {
      contentheight: 0,
    };
  },
  created() {
    window.addEventListener("resize", this.changeContentHeight);
  },
  mounted() {
    this.changeContentHeight();
  },
  computed: {
    isfull() {
      return this.full ? "full" : "";
    },
  },
  methods: {
    changeContentHeight() {
      if (!this.full) {
        const contentheight = document.getElementById("resetlimit").offsetTop;
        const resetpassword =
          document.getElementById("resetpassword").offsetTop;

        this.contentheight = contentheight - resetpassword;
      }
    },
    getAction(data) {
      let route = "";
      switch (data) {
        case "support":
          route = "/support";
          break;
        case "delete":
          route = "/support/delete/account";
          break;
      }

      this.$router.push(route);
    },
  },
};
</script>

<style scoped>
.content {
  overflow: hidden;
}

.content.full {
  height: 100% !important;
  overflow: initial;
}

.title.full {
  text-align: center;
}

p {
  font-size: 12px;
  text-align: justify;
}

li {
  font-size: 12px;
  padding: 5px 0px;
  text-align: justify;
}

.link {
  font-size: 12px;
  cursor: pointer;
}

.link:hover {
  color: blue;
  text-decoration: underline;
}
</style>
