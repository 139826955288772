<template>
  <div>
    <div class="mx-8 mt-3 mb-0">
      <h3>Audios</h3>
    </div>
    <Tabs :tabs="tabs" @getTabItem="getTabItem" />

    <Track v-if="tabItem == 0" />
    <Subliminal v-if="tabItem == 1" />
    <Playlist v-if="tabItem == 2" />
  </div>
</template>

<script>
import Tabs from '@/components/Tabs.vue'

import Track from '@/views/Admin/Track/index.vue'
import Subliminal from '@/views/Admin/Subliminal/index.vue'
import Playlist from '@/views/Admin/Playlist/index.vue'
export default {
  components: {
    Tabs,
    Track,
    Subliminal,
    Playlist
  },
  data: () => {
    return {
      tabs: ['Tracks', 'Subliminals', 'Playlists'],
      tabItem: 0
    }
  },
  methods: {
    getTabItem(data) {
      this.tabItem = data
    }
  }
}
</script>
