<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Whether you're a beginner looking to learn the basics of boxing or an experienced fighter aiming to improve your skills, this subliminal can help you on your journey to boxing mastery. By targeting your subconscious mind, it enhances your boxing training and helps you become a skilled boxer."
    },
    {
      type: 'heading',
      text: 'Here are a few ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Enhancing your focus, concentration, and mental toughness in training and fights.",
        "Improving your agility, speed, and footwork for better movement in the ring.",
        "Boosting your endurance and stamina to last longer during intense training sessions and fights.",
        "Increasing your confidence and self-belief in your boxing abilities.",
        "Helping you develop effective techniques, combinations, and defensive skills.",
        "Supporting your motivation and discipline to consistently practice and improve."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can align your subconscious mind with your boxing goals, allowing you to train more effectively and master the art of boxing. Remember, mastery takes time and dedication, but with the right mindset and consistent practice, you can achieve great results in your boxing journey."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on the journey of learning and mastering boxing requires dedication and discipline. This subliminal is designed to enhance your boxing skills and help you become a skilled boxer. Before you start listening, consider these tips to optimize your training experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Define what you want to achieve in your boxing training, whether it's improving your technique, building strength, or increasing agility.",
        "Establish a training routine: Create a consistent schedule that allows for regular practice and progression.",
        "Focus on proper form: Pay attention to the correct execution of punches, footwork, and defensive maneuvers to maximize effectiveness and minimize injury.",
        "Stay motivated: Use visualizations and affirmations to keep yourself motivated and focused on your boxing goals as you listen to the subliminal messages."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your boxing training regime, you can enhance your mental focus, physical abilities, and overall performance. Embrace the challenges, stay committed to your goals, and let the journey towards boxing mastery begin!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Boxing Training and Mastery session! By dedicating yourself to training and practice, you've taken a significant step towards improving your boxing skills and becoming a skilled boxer. Now, it's important to reflect on your experience and continue your journey towards mastery."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your progress: Take time to evaluate your growth and improvements in boxing skills.",
        "Continue practicing: Dedicate regular time to practice and refine your techniques and moves.",
        "Set challenging goals: Set specific training goals that push you to improve your boxing skills.",
        "Seek guidance: Consider working with a boxing coach or joining a boxing club to further develop your skills."
      ]
    },
    {
      type: 'paragraph',
      text: "Becoming a skilled boxer requires dedication, consistency, and discipline. Embrace the journey and continue to challenge yourself as you strive for mastery. Remember, with continued practice and commitment, you have the opportunity to become the best boxer you can be. Keep up the hard work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Do I need any prior experience or boxing skills to use the Boxing Training and Mastery program?`, 
    answer: `No prior experience or boxing skills are necessary to use the Boxing Training and Mastery program. The program is designed to accommodate individuals of all skill levels, from beginners to advanced boxers. Whether you are just starting or looking to improve your technique, the program provides step-by-step guidance and training to help you develop your boxing skills.`,
    open: false
  },
  { 
    question: `How often should I train using the Boxing Training and Mastery program?`, 
    answer: `The frequency of your training sessions using the Boxing Training and Mastery program can vary depending on your schedule and individual goals. However, for optimal results, it is recommended to train regularly, aiming for at least three to four sessions per week. Consistency and dedication to practice are key to improving your boxing skills.`,
    open: false 
  },
  { 
    question: `Can I use the Boxing Training and Mastery program for self-defense purposes?`, 
    answer: `While the Boxing Training and Mastery program can enhance your physical fitness and boxing skills, it is important to note that it is not specifically designed for self-defense purposes. If your goal is to learn self-defense techniques, it is recommended to seek proper training and guidance from a qualified self-defense instructor or martial arts practitioner.`,
    open: false 
  },
  { 
    question: `Will I need any special equipment to follow the Boxing Training and Mastery program?`, 
    answer: `The Boxing Training and Mastery program can be followed with minimal equipment. Basic equipment such as boxing gloves, hand wraps, a punching bag, and a jump rope are commonly used for training. However, if you don't have access to these items, modifications and alternative exercises can be suggested within the program to accommodate your circumstances.`,
    open: false 
  },
  { 
    question: `Is the Boxing Training and Mastery program safe for beginners?`, 
    answer: `Yes, the Boxing Training and Mastery program is safe for beginners. The program is designed to gradually introduce you to boxing techniques, allowing you to progress at your own pace. However, it is recommended to consult with a healthcare professional before starting any new physical activity, especially if you have any pre-existing health conditions.`,
    open: false 
  },
  { 
    question: `Can the Boxing Training and Mastery program help me compete in professional boxing matches?`, 
    answer: `The Boxing Training and Mastery program is a comprehensive training resource that can help you improve your boxing skills. While it can provide valuable guidance and training, competing in professional boxing matches requires additional training, experience, and guidance from a professional boxing coach. If your goal is to compete professionally, it is recommended to seek specialized training and support.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>