import request from "@/utils/request";

export const getPlaylists = (data) => {
  return request.post("/api/search/all/playlist").then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const getOwnPlaylist = (data) => {
  return request.post("/api/own/playlist", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const getMagusPlaylist = (data) => {
  return request.post("/api/magus/playlist", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const addOwnPlaylist = (data) => {
  return request.post("/api/own/playlist/add", data).then((res) => {
    const { data } = res;
    return data;
  });
};

export const editOwnPlaylist = (data) => {
  return request.put("/api/own/playlist", data).then((res) => {
    const { data } = res;
    return data;
  });
};

export const deleteOwnPlaylist = (data) => {
  return request.post("/api/own/playlist/delete", data).then((res) => {
    const { data } = res;
    return data;
  });
};

export const searchOwnPlaylist = (data) => {
  return request.post("/api/own/playlist/filter/add/subliminal", data).then((res) => {
    const { data } = res;
    return data;
  });
};
