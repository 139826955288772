<template>
  <el-dropdown trigger="click" @command="handleCommand">
    <img
      class="ellipsis"
      src="@/assets/images/user/webplayer/light/ellipsis2.png"
    />
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        command="rename"
        class="d-flex py-2"
        style="width: 230px"
      >
        <img src="@/assets/images/user/playlist/edit.png" />
        <span>Rename Playlist</span>
      </el-dropdown-item>
      <el-dropdown-item
        command="remove"
        class="d-flex py-2"
        style="width: 230px"
      >
        <img src="@/assets/images/user/playlist/delete.png" />
        <span>Delete Playlist</span>
      </el-dropdown-item>
      <!-- <v-list-item class="list mx-auto" @click="renamePlaylist()">
        <img src="@/assets/images/user/playlist/edit.png" />
        <v-list-item-title>Rename Playlist</v-list-item-title>
      </v-list-item>
      <v-list-item class="list mx-auto" @click="deletePlaylist()">
        <img src="@/assets/images/user/playlist/delete.png" />
        <v-list-item-title>Delete</v-list-item-title>
      </v-list-item> -->
    </el-dropdown-menu>
    <Dialog :dialog="dialog" :width="600" title="" @closeDialog="closeDialog">
      <div v-if="button == 'Update'" class="text-center">
        <div class="deer-container">
          <img class="deer" :src="data.cover" />
        </div>
        <input class="p-title" v-model="title" />
      </div>
      <div v-else class="text-center">
        <div class="deer-container">
          <img
            class="deleted"
            src="@/assets/images/user/playlist/deleted.png"
          />
        </div>
        <h6>Are you sure you want to delete this playlist?</h6>
      </div>
      <template v-slot:action>
        <div class="add-btn">
          <button v-if="button == 'Update'" @click="editPlaylists()">
            {{ button }}
          </button>
          <button v-else @click="deleteOwnPlaylists()">{{ button }}</button>
        </div>
      </template>
    </Dialog>
  </el-dropdown>
</template>

<script>
import Dialog from "@/components/Dialog.vue";
import { getMessage } from "@/utils";
import { getAllUserPlaylist } from "@/api/playlist";

import { toggleData } from "@/utils/favorite.js";
import { editOwnPlaylist, deleteOwnPlaylist } from "@/api/ownplaylist.js";
import { getAllFavorites } from "@/api/favorite.js";
export default {
  props: {
    height: {
      type: Number,
      default: () => {
        return 16;
      },
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: () => {
        return 0;
      },
    },
  },
  components: {
    Dialog,
  },
  data: () => {
    return {
      dialog: false,
      owndialog: false,
      getMessage,
      getAllFavorites,
      getAllUserPlaylist,

      toggleData,
      editOwnPlaylist,
      deleteOwnPlaylist,
      button: "",
      title: "",
    };
  },
  mounted() {},
  computed: {
    user() {
      return JSON.parse(this.$store.state.user.info);
    },
  },
  methods: {
    handleCommand(command) {
      if (command == "rename") {
        this.renamePlaylist();
      }

      if (command == "remove") {
        this.deletePlaylist();
      }
    },
    renamePlaylist() {
      this.title = this.data.title;
      this.button = "Update";
      this.dialog = true;
      // this.$refs.menu.isActive = false;
    },
    deletePlaylist() {
      this.button = "Confirm";
      this.title = this.data.title;
      this.dialog = true;
      // this.$refs.menu.isActive = false;
    },
    closeDialog(data) {
      this.dialog = data;
    },
    editPlaylists() {
      const data = {
        playlist_id: this.data.playlist_id,
        title: this.title,
        user_id: this.user.user_id,
        subscription_id: this.user.info.subscription_id,
      };
      this.editOwnPlaylist(data).then((res) => {
        this.getMessage(this, res);
        if (res.success == true) {
          this.closeDialog(false);
          localStorage.setItem("user_track", JSON.stringify(res.data[0]));
          this.$store.dispatch("user/getTrack", res.data[0]);

          this.$store.dispatch("playlist/getOwnUpdate", res.data);
          this.$store.dispatch("playlist/getOwnLoading", false);
        }
        this.title = null;
      });
    },
    deleteOwnPlaylists() {
      const data = {
        playlist_id: this.data.playlist_id,
        user_id: this.user.user_id,
        subscription_id: this.user.info.subscription_id,
      };
      this.deleteOwnPlaylist(data).then((res) => {
        this.getMessage(this, res);
        this.closeDialog(false);

        if (res.success == true) {
          if (res.data.length != 0) {
            localStorage.setItem("user_track", JSON.stringify(res.data[0]));
            this.$store.dispatch("user/getTrack", res.data[0]);
          } else {
            this.$router.push("/user/overview");
          }
          this.getAllUserPlaylist(data).then((res) => {
            let playlistId = [];
            let playlists = [];
            res.forEach((items) => {
              playlistId.push(items.playlist_id);
              playlists.push(items);
            });
            this.$store.dispatch("playlist/playlistIDs", playlistId);
            this.$store.dispatch("playlist/playlistInfoLists", playlists);
          })
        }

        this.getAllFavorites(data).then((res) => {
          const subliminal = res.subliminal;
          const playlist = res.playlist;

          let favoriteId = [];
          let favorites = [];

          subliminal.forEach((items) => {
            favoriteId.push(items.subliminal_id);
            favorites.push(items);
          });

          playlist.forEach((items) => {
            favoriteId.push(items.playlist_id);
            favorites.push(items);
          });

          this.$store.dispatch("favorite/favoriteIDs", favoriteId);
          this.$store.dispatch("favorite/favoriteInfoLists", favorites);
        });
      });
    },
  },
};
</script>

<style scoped>
.list {
  width: 180px;
}
.menu-img {
  width: 20px;
  margin-right: 10px;
  margin-top: 10px;
}
.deer-container {
  background-color: white;
}
.deer {
  height: 120px;
  width: 130px;
  border-radius: 10px;
}
.deleted {
  height: 130px;
}
.ellipsis {
  height: 7px;
  width: 25px;
}
img {
  height: 20px;
  margin-right: 10px;
}
</style>
