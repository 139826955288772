<template>
  <Dialog
    :dialog="dialog"
    :width="800"
    :title="`Edit Magus Details`"
    @closeDialog="closeDialog"
  >
    <div>
        <v-row>
          <v-col cols="8">
            <p class="mb-1">Subliminal</p>
            <v-autocomplete solo dense hide-details clearable v-model="subliminal" :items="subliminals" item-text="title"
              item-value="subliminal_id" />
          </v-col>
          <v-col cols="4">
            <p class="mb-1">Visible</p>
            <v-checkbox v-model="is_visible" class="ma-0" style="padding-top: 8px"></v-checkbox>
          </v-col>
        </v-row>
    </div>

    <template v-slot:action>
      <v-row class="ma-0">
        <v-col class="pl-0 pr-2">
          <v-btn block @click="submitDialog()" :disabled="disabled"
            >Update</v-btn
          >
        </v-col>
        <v-col class="pl-2 pr-0">
          <v-btn block @click="closeDialog()">Cancel</v-btn>
        </v-col>
      </v-row>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialog.vue'

import { getMessage } from '@/utils'
import { updateFeaturedContents } from '@/api/featured.js'

export default {
  props: {
    dialog: {
      type: Boolean
    },
    data: {
      type: Object
    }
  },
  data: () => {
    return {
      getMessage,
      updateFeaturedContents,

      is_visible: 0,
      id: null,
      subliminal: null

    }
  },
  components: {
    Dialog
  },
  computed: {
    disabled() {
      return this.subliminal == null || this.subliminal == ''
    },
    subliminals() {
      return this.$store.state.subliminal.data
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.id = this.data.id
      this.is_visible = this.data.is_visible
      this.subliminal = this.data.subliminal_id
    },
    closeDialog() {
      this.$emit('closeDialog', false)
    },
    submitDialog() {
      const data = {
        id: this.id,
        subliminal_id: this.subliminal,
        is_visible: this.is_visible
      }

      this.updateFeaturedContents(data).then((data) => {
        this.getMessage(this, data)
        this.closeDialog()
      })
    }
  }
}
</script>
