<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're experiencing body pain and looking for natural ways to relieve and heal, this subliminal is here to support you. By combining guided mind-body techniques, it aims to help you experience a pain-free life."
    },
    {
      type: 'heading',
      text: 'Key features and benefits include:'
    },
    {
      type: 'list',
      items: [
        "Reducing physical discomfort and pain levels through relaxation and targeted healing.",
        "Stimulating the body's natural healing processes and promoting faster recovery.",
        "Relieving tension, stiffness, and muscle soreness, improving mobility and flexibility.",
        "Calming the mind and reducing stress, which can intensify pain perception.",
        "Enhancing overall well-being and promoting a positive mindset towards healing."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can tap into the power of your subconscious mind to promote pain relief and healing. Remember that this is a complementary approach and not a substitute for medical advice or treatment. It's important to consult with a healthcare professional for a comprehensive assessment and guidance on managing your pain."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Living with chronic pain can be debilitating and affect your overall quality of life. This subliminal is designed to help you find relief and heal your body pain through guided mind-body techniques. Before you listen, consider the following tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a comfortable space: Find a quiet and peaceful environment where you can relax and focus on your healing journey.",
        "Center yourself: Take a few moments to practice deep breathing and grounding exercises to calm your mind and body.",
        "Set your intention: Clearly state your desire to release and heal your body pain, allowing the subliminal affirmations to work with your subconscious mind.",
        "Believe in your healing: Cultivate a positive mindset and truly believe in your ability to find relief and heal your body pain."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to support your healing process. As you listen regularly, you may start to notice improvements in your pain levels and overall well-being. Embrace this opportunity to take a proactive approach to pain relief and healing, and trust in your ability to lead a pain-free life. Now, find a comfortable position, take a deep breath, and let the journey to healing begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Body Pain Relief and Healing session! By engaging in these guided mind-body techniques, you have taken a significant step towards relieving and healing your body pain. Now, it's important to reflect on your experience and continue your journey to living a pain-free life."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Listen to your body: Pay attention to any changes in your pain levels and how your body reacts.",
        "Practice self-care: Engage in activities that promote relaxation and reduce stress, such as meditation, massage, or deep breathing exercises.",
        "Seek professional help if needed: If your pain persists or worsens, consult a healthcare professional for further evaluation and guidance.",
        "Stay consistent: Incorporate the techniques and practices you learned into your daily routine to continue experiencing the benefits of pain relief."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, healing takes time and patience. Embrace the process and be kind to yourself as you navigate your pain relief journey. By staying consistent and attentive to your body's needs, you are on the path to living a pain-free and fulfilling life. Keep going!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Body Pain Relief and Healing subliminal help with my body pain?`, 
    answer: `The Body Pain Relief and Healing subliminal is designed to promote relaxation, reduce stress, and support the body's natural healing processes. By listening to the subliminal regularly, you can align your subconscious mind with positive affirmations that encourage pain relief, healing, and overall well-being. It is important to note that the subliminal is not a substitute for professional medical advice or treatment, but it can be used as a complementary tool alongside other pain management strategies.`,
    open: false
  },
  { 
    question: `How long before I start experiencing relief from my body pain with the subliminal?`, 
    answer: `The time it takes to experience relief from body pain can vary from person to person. While some individuals may notice improvements in their pain levels and overall well-being relatively quickly, others may require more time and consistent listening to the subliminal to fully benefit from its effects. We recommend listening to the audio regularly and being patient with the process.`,
    open: false 
  },
  { 
    question: `Can the Body Pain Relief and Healing subliminal replace medical treatment?`, 
    answer: `No, the Body Pain Relief and Healing subliminal is designed to complement and enhance other pain management strategies, not replace them. It is important to follow any medical advice or treatment plans prescribed by healthcare professionals. The subliminal can be used as a supportive tool to promote relaxation, reduce stress, and align your mindset with positive affirmations for pain relief and healing.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Body Pain Relief and Healing subliminal?`, 
    answer: `There are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your pain or health, please consult with your healthcare provider. The subliminal is intended to supplement other pain management strategies and should not replace professional medical advice or treatment.`,
    open: false 
  },
  { 
    question: `Can the subliminal help with chronic pain?`, 
    answer: `The Body Pain Relief and Healing subliminal can be used as a supportive tool for individuals experiencing chronic pain. While it may not provide immediate or complete relief, regular listening can help promote relaxation, reduce stress, and support the body's natural healing processes, which may contribute to improved pain management over time. It is important to consult with your healthcare provider for a comprehensive approach to managing chronic pain.`,
    open: false 
  },
  { 
    question: `Can the subliminal be used alongside other pain management techniques?`, 
    answer: `Yes, the Body Pain Relief and Healing subliminal can be used alongside other pain management techniques and therapies. It is designed to complement and enhance your existing pain management strategies by promoting relaxation, reducing stress, and aligning your mindset with positive affirmations for pain relief and healing. It is important to collaborate with your healthcare provider to develop a comprehensive pain management plan tailored to your specific needs.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>