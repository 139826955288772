<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Developing a millionaire money mindset is all about shifting your beliefs, thoughts, and attitudes towards wealth and abundance. This subliminal is designed to help you do just that, by reprogramming your subconscious mind with positive affirmations and empowering messages."
    },
    {
      type: 'heading',
      text: 'Here are some key areas this subliminal can help with:'
    },
    {
      type: 'list',
      items: [
        "Changing any limiting beliefs or negative thoughts about money and success.",
        "Increasing your attraction to abundance and financial opportunities.",
        "Enhancing your confidence and belief in your ability to create wealth.",
        "Encouraging a mindset of abundance and gratitude rather than scarcity.",
        "Motivating you to take action towards your financial goals and dreams."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can gradually rewire your subconscious mind to align with the mindset of millionaires and attract greater wealth into your life. It's important to remember that developing a millionaire money mindset is a process that requires consistent practice and dedication. With time and persistence, you can start to see positive changes in your financial mindset and attract greater abundance."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Developing a millionaire money mindset is a powerful step towards attracting abundance and wealth into your life. This subliminal is designed to help you shift your mindset and unleash your full potential when it comes to money. Before you begin, here are some preparation tips to make the most out of this transformative experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your financial goals: Clearly define what you want to achieve financially and visualize your desired success.",
        "Identify limiting beliefs: Become aware of any negative thoughts or beliefs you have around money and replace them with empowering affirmations.",
        "Create affirmations: Write down positive affirmations that align with your financial goals and repeat them regularly.",
        "Embrace a growth mindset: Cultivate a mindset of abundance, prosperity, and gratitude towards money.",
        "Maintain focus and consistency: While listening, stay present and fully engaged, allowing the subliminal affirmations to penetrate your subconscious mind effectively."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly and embracing the concepts of abundance and wealth, you can begin to reshape your relationship with money. Open your mind to new possibilities, believe in your ability to create financial abundance, and get ready to welcome prosperity into your life. Let's embark on this journey together and unlock your millionaire money mindset!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Millionaire Money Mindset subliminal! By investing in your mindset, you've taken a significant step towards attracting abundance and wealth into your life. It's now time to reflect on your experience and continue your journey towards financial success."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Challenge limiting beliefs: Identify and challenge any negative thoughts or beliefs about money and success that may be holding you back.",
        "Set clear financial goals: Define your financial aspirations and create a plan to achieve them.",
        "Educate yourself: Continue learning about personal finance, investing, and wealth creation strategies to expand your knowledge and skills.",
        "Take inspired action: Take practical steps towards improving your financial situation, such as budgeting, saving, and exploring investment opportunities."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, developing a millionaire money mindset is a journey that requires consistency and commitment. Trust in your abilities and stay focused on your goals. With the right mindset and action, you have the power to attract abundance and create the financial future you desire. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Millionaire Money Mindset subliminal help me attract abundance and wealth?`, 
    answer: `The Millionaire Money Mindset subliminal is designed to reprogram your subconscious mind with positive affirmations and beliefs about money and abundance. By listening to the subliminal regularly, you can align your thoughts, beliefs, and actions with those of a millionaire, which may attract opportunities for wealth and abundance into your life. It can also help remove any limiting beliefs or negative thought patterns that may be blocking your financial success.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Millionaire Money Mindset subliminal?`, 
    answer: `Results can vary depending on the individual and their current mindset and circumstances. Some people may start noticing positive shifts in their thoughts, behavior, and financial opportunities soon after starting to listen to the subliminal, while others may require more time and consistent listening. We recommend listening to the subliminal daily and consistently for best results.`,
    open: false 
  },
  { 
    question: `Can the Millionaire Money Mindset subliminal replace hard work and financial planning?`, 
    answer: `No, the subliminal is not a substitute for hard work, financial planning, or taking action. While it can help reprogram your mindset towards abundance and wealth, practical steps such as goal-setting, budgeting, and investing are still essential for financial success. The subliminal can complement your efforts by aligning your thoughts and beliefs with those of a millionaire, potentially enhancing your motivation, focus, and resilience.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Millionaire Money Mindset subliminal?`, 
    answer: `There are no known risks or side effects associated with listening to the Millionaire Money Mindset subliminal. However, it is important to note that the subliminal is intended to supplement your financial efforts, not replace them. If you have any concerns about your financial situation, it is recommended to consult with a financial advisor.`,
    open: false 
  },
  { 
    question: `Can the Millionaire Money Mindset subliminal work for everyone?`, 
    answer: `The Millionaire Money Mindset subliminal is designed to help individuals develop a mindset conducive to attracting abundance and wealth. However, its effectiveness may vary from person to person depending on factors such as individual beliefs, mindset, and actions. It is important to approach it with an open mind and be willing to actively work towards your financial goals.`,
    open: false 
  },
  { 
    question: `Can I listen to the Millionaire Money Mindset subliminal while doing other activities?`, 
    answer: `While it is generally recommended to listen to the subliminal in a relaxed and focused state, you can still benefit from listening to it while performing other activities. However, to maximize the subliminal's effectiveness, it is best to create a dedicated listening environment where you can relax and allow the positive affirmations to sink into your subconscious mind without distractions.`,
    open: false 
  }
]
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>