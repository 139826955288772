<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Becoming a successful programmer requires more than just technical knowledge. This subliminal is designed to help you develop the skills, mindset, and habits needed to excel in the field of programming and create efficient and high-quality code."
    },
    {
      type: 'heading',
      text: 'Here are some areas where this subliminal can be especially beneficial:'
    },
    {
      type: 'list',
      items: [
        "Building a strong foundational understanding of programming concepts and principles.",
        "Developing problem-solving and critical thinking skills to tackle complex coding challenges.",
        "Improving your ability to write clean, efficient, and maintainable code.",
        "Boosting your focus, concentration, and productivity while programming.",
        "Enhancing your creativity and innovation to develop unique and innovative solutions.",
        "Fostering a growth mindset and embracing continuous learning and improvement.",
        "Cultivating effective communication and collaboration skills for teamwork and project management."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind to align with the mindset and behaviors of successful programmers. As a result, you may find yourself becoming more confident, skilled, and successful in the field of programming. Remember, success in programming is a journey, and with dedication and persistence, you can achieve your goals."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a journey to become a successful programmer requires a combination of knowledge, skills, and the right mindset. This subliminal is designed to support your growth and help you excel in the field of programming. Before you start listening, consider these tips to maximize your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Identify specific skills or programming languages you want to focus on and outline your desired achievements.",
        "Establish a learning routine: Consistency is crucial in programming. Schedule regular study sessions and allocate dedicated time to practice coding.",
        "Create a conducive learning environment: Set up a comfortable and organized workspace with access to necessary resources and tools.",
        "Stay motivated and focused: Remind yourself of why you want to become a successful programmer and maintain a positive mindset throughout your learning journey."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your learning routine, you can tap into your subconscious mind's potential and accelerate your progress as a programmer. As you listen regularly, you may notice increased focus, improved problem-solving abilities, and a deeper understanding of programming concepts. Embrace this opportunity to enhance your skills, embrace challenges, and become the successful programmer you aspire to be."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Become a Successful Programmer subliminal session! You've taken a significant step towards achieving your goal of excelling in the field of programming. Now, it's essential to reflect on your experience and continue nurturing your skills and mindset for success."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice, practice, practice: Continue to hone your programming skills by consistently coding and solving real-world problems.",
        "Stay updated: Keep yourself informed about the latest trends, tools, and techniques in the programming world.",
        "Collaborate and network: Engage with other programmers, join coding communities, and participate in coding challenges to expand your knowledge and connections.",
        "Seek continuous learning: Embrace a growth mindset, be open to learning new languages and technologies, and stay curious about different aspects of programming."
      ]
    },
    {
      type: 'paragraph',
      text: "Becoming a successful programmer is a journey that requires a combination of dedication, curiosity, and continuous improvement. Stay committed to honing your skills, staying updated, and fostering a supportive network. With time and effort, you will thrive in the programming field. Best of luck on your coding adventures!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Become a Successful Programmer subliminal help me?`, 
    answer: `The Become a Successful Programmer subliminal is designed to help you develop the mindset, skills, and habits required to excel in the field of programming. By regularly listening to the subliminal messages, you can enhance your focus, creativity, problem-solving abilities, and motivation to learn and improve as a programmer. It can also help you develop efficient and high-quality coding practices.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Become a Successful Programmer subliminal?`, 
    answer: `The time it takes to see results from the Become a Successful Programmer subliminal can vary depending on factors such as individual learning styles and commitment to practicing programming skills. Some people may notice positive changes in their mindset and coding abilities relatively quickly, while others may require more time and consistent listening to fully integrate the subliminal messages. It is recommended to listen to the subliminal regularly and practice coding regularly to maximize its effectiveness.`,
    open: false 
  },
  { 
    question: `Can the Become a Successful Programmer subliminal replace learning and practicing programming?`, 
    answer: `No, the Become a Successful Programmer subliminal is not intended to replace the process of learning and practicing programming skills. It is designed to support and enhance your learning journey by instilling a positive mindset, motivation, and focus. To become a successful programmer, it is important to combine the subliminal with active learning, practical application of programming concepts, and continuous practice.`,
    open: false 
  },
  { 
    question: `How can I maximize the effectiveness of the Become a Successful Programmer subliminal?`, 
    answer: `To maximize the effectiveness of the Become a Successful Programmer subliminal, it is recommended to listen to it regularly, preferably daily, in a quiet and relaxed environment. Creating a dedicated study or coding routine can help reinforce the positive programming mindset. Additionally, actively engaging in coding projects, seeking out learning resources, and collaborating with fellow programmers can provide practical reinforcement and enhance the subliminal's impact.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Become a Successful Programmer subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Become a Successful Programmer subliminal. However, it is important to note that the subliminal is not a substitute for consistent learning and practice. It is recommended to consult programming resources, engage in coding exercises, and actively apply the concepts learned.`,
    open: false 
  },
  { 
    question: `Can the Become a Successful Programmer subliminal be used by beginners?`, 
    answer: `Yes, the Become a Successful Programmer subliminal can be used by beginners who are new to programming. It can help instill a positive and motivated mindset from the start of the learning journey. However, it is important to supplement the subliminal with dedicated learning resources and hands-on practice to develop the necessary programming skills and knowledge.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>