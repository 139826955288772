import { render, staticRenderFns } from "./91-stop-cracking-fingers-and-knuckles.vue?vue&type=template&id=1168f88a&scoped=true&"
import script from "./91-stop-cracking-fingers-and-knuckles.vue?vue&type=script&lang=js&"
export * from "./91-stop-cracking-fingers-and-knuckles.vue?vue&type=script&lang=js&"
import style0 from "./91-stop-cracking-fingers-and-knuckles.vue?vue&type=style&index=0&id=1168f88a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1168f88a",
  null
  
)

export default component.exports