<template>
  <Card class="mx-6 mt-4 mb-0 pa-0">
    <v-tabs v-model="tab">
      <v-tab style="text-transform: none" v-for="tab, index in tabs" :key="index">{{ tab }}</v-tab>
    </v-tabs>
  </Card>
</template>

<script>
import Card from '@/components/Card.vue'
import { getAudio, playAudio, pauseAudio } from "@/utils/audio";

export default {
  props: {
    tabs: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  components: {
    Card
  },
  computed: {
    audio() {
      return this.$store.state.audio.data;
    },
    isAudioPlaying() {
      return this.$store.state.audio.status;
    },
    durationTimes() {
      return this.$store.state.audio.duration.times;
    },
  },
  data: () => {
    return {
      tab: null,
      pauseAudio,
    }
  },
  watch: {
    tab(value) {
      if (this.isAudioPlaying) {
        this.pauseAudio(false);
      }
      this.$emit('getTabItem', value)
    }
  }
}
</script>

<style>
.el-tabs__item {
  padding-inline: 10px !important;
}
</style>
