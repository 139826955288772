<template>
  <Dialog :dialog="dialog" :width="500" @closeDialog="closeDialog">
    <div v-if="dialog_count == 0">
      <div v-if="isReady == true" class="mx-auto text-center">
        <input v-if="items.length != 0 || searching == true" class="search" v-model="search" placeholder="Search" />
        <div v-if="items.length != 0 && searching == false || items.length != 0 && searching == true">
            <template v-for="(item, index) in items">
              <div class="wrapper d-flex align-center" :key="index">

                <img v-if="item.subliminals!=[]" class="cover" :src="getBackground(item)" />
                <img v-else class="cover" :src="item.cover"/>

                <div class="titles">
                  <h6>{{ item.title }}</h6>
                </div>
                <img
                  v-if="item.is_added != 1"
                  @click="addSubliminal(item.playlist_id)"
                  class="icon"
                  src="@/assets/images/user/playlist/add.png"
                />
                <v-icon @click="addSubliminal(item.playlist_id)" v-else
                  >mdi-check</v-icon
                >
              </div>
            </template>
        </div>
        <div v-else-if="items.length == 0 && searching == true" class="mx-auto text-center">
          <h6 class="mt-5">No result found!</h6>
          <span
            >We couldn’t find what you searched for.<br />Try using a different
            key word and try again<br
          /></span>
          <img
            class="empty mt-2"
            src="@/assets/images/user/playlist/empty.png"
            alt=""
          />
        </div>
        <div v-else class="mx-auto text-center">
          <img
            class="empty mb-2"
            src="@/assets/images/user/playlist/empty_playlist.png"
            alt=""
          />
          <p>Let’s start building your playlist</p>
        </div>
      </div>
      <div v-else class="text-center">
        <v-progress-circular indeterminate></v-progress-circular>
      </div>
    </div>
    <div v-else class="text-center">
      <div class="deer-container">
        <img class="deer" src="@/assets/images/user/playlist/deer.png" />
      </div>
      <input
        class="p-title"
        v-model="title"
        placeholder="Type playlist title"
      />
    </div>
    <template v-slot:action v-if="dialog_count == 0">
      <div class="text-center" style="margin: auto; margin-top: -15px">
        <button
          @click="opendAddtoNewPlaylist()"
          class="button btn btn-primary btn-lg"
        >
          Create new playlist
        </button>
      </div>
    </template>
    <template v-slot:action v-else>
      <div class="text-center" style="margin: auto; margin-top: -15px">
        <div class="add-btn">
          <button @click="addPlaylist()">Add</button>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>

import Dialog from "@/components/Dialog.vue";
import { searchOwnPlaylist, getOwnPlaylist, addOwnPlaylist } from "@/api/ownplaylist";
import { addSubliminalToOwnPlaylists } from "@/api/playlistinfo";
import { getMessage } from "@/utils";
import { getAllUserPlaylist } from "@/api/playlist";

import { mapGetters } from "vuex";

export default {
  props: {
    dialog: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data: () => {
    return {
      getAllUserPlaylist,
      getMessage,
      searchOwnPlaylist,
      addOwnPlaylist,
      addSubliminalToOwnPlaylists,

      title: '',
      search: '',
      searching: false,
      isReady: false,
      items: [],
      dialog_count: 0,
      playlistId: [],
      playlists: [],
    };
  },
  components: {
    Dialog,
  },
  computed: {
    ...mapGetters({
      isDataReady: "newaudio/getDataStatus",
      favoriteIDs: "favorite/getFavoriteIDs",
      favoriteInfoLists: "favorite/getFavoriteInfoLists",
      playlistIDs: "playlist/getPlaylistIDs",
      playlistInfoLists: "playlist/getPlaylistInfoLists",
    }),
    user() {
      return JSON.parse(this.$store.state.user.info);
    },
    subscriptionId() {
      return this.$store.state.subscription.id;
    },
  },
  mounted() {
  },
  watch: {
    dialog(val) {
      if(val == true){
        this.getData();
      }
    },
    search(val) {
      this.searching = true;
      this.getData();
    },
  },
  methods: {
    addPlaylist() {
      const data = {
        title: this.title,
        user_id: this.user.user_id,
        subscription_id: this.user.info.subscription_id,
      };
      this.addOwnPlaylist(data).then((res) => {
        if (res.success == true) {
          const data2 = {
            user_id: this.user.user_id,
            subscription_id: this.user.info.subscription_id,
            playlist_id: res.data[0].playlist_id,
            subliminal_id: this.data.subliminal_id,
          };
          this.addSubliminalToOwnPlaylists(data2).then((res) => {
            this.closeDialog();
            this.getMessage(this, res);
            this.getPlaylists();
          });
        } else {
          this.title = null;
          this.getMessage(this, res);
        }
      });
    },
    opendAddtoNewPlaylist() {
      this.dialog_count = 1;
    },
    addSubliminal(id) {
      const data = {
        user_id: this.user.user_id,
        subscription_id: this.user.info.subscription_id,
        playlist_id: id,
        subliminal_id: this.data.subliminal_id,
      };
      this.addSubliminalToOwnPlaylists(data).then((res) => {
        this.getMessage(this, res);
        this.getData();
          this.getAllUserPlaylist(data).then((res) => {
            let playlistId = [];
            let playlists = [];
            res.forEach((items) => {
              playlistId.push(items.playlist_id);
              playlists.push(items);
            });
            this.$store.dispatch("playlist/playlistIDs", playlistId);
            this.$store.dispatch("playlist/playlistInfoLists", playlists);
          })
      });
    },
    getBackground(params) {
      if (params.hasOwnProperty("subliminals")) {
        const count = params.subliminals.length;
        if (count > 0) {
          let index = Math.floor(Math.random() * count);
          return params.subliminals[index].cover;
        }
      }
      return params.cover;
    },
    getData() {
      const data = {
        user_id: this.user.user_id,
        subscription_id: this.user.info.subscription_id,
        search: this.search,
        subliminal_id: this.data.subliminal_id,
      };
      this.searchOwnPlaylist(data).then((res) => {
        this.items = res.data;
        this.isReady = true;
      });
    },
    getPlaylists() {
      let playlistId = [];
      let playlists = [];

      const data = {
        user_id: this.user.user_id,
        subscription_id: this.user.info.subscription_id,
      };

      this.getAllUserPlaylist(data).then((res) => {
        res.forEach((items) => {
          playlistId.push(items.playlist_id);
          playlists.push(items);
        });
      });

      this.$store.dispatch("playlist/playlistIDs", playlistId);
      this.$store.dispatch("playlist/playlistInfoLists", playlists);
    },
    closeDialog() {
      let self = this;
      self.title = '';
      self.search = '';
      self.dialog_count = 0;
      self.items = [];
      self.isReady = false;
      self.getData()
      this.$emit("closeDialog", false);
    },
  },
};
</script>

<style>
.empty {
  height: 80px;
}
p {
  font-family: "Montserrat-Regular";
  font-size: 12px;
  margin-top: 10px;
}
.search {
  padding-left: 10px;
  width: 100%;
  margin: auto;
  margin-top: -10px;
  margin-bottom: 10px;
  border-radius: 10px;
  border: solid 0.5px black;
  height: 40px;
  background: #f4eeee;
  font-family: "Montserrat-Regular";
}
span {
  font-family: "Montserrat-Regular";
}
.wrapper {
  margin-top: 10px;
}
.icon {
  height: 20px;
}
.check {
  height: 17px;
}
.cover {
  height: 70px;
  width: 80px;
  border-radius: 5px;
  margin-right: 10px;
}
.titles {
  width: 80%;
  text-align: left;
}
</style>
