<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Attracting a sugar mommy into your life is a process that starts from within. This subliminal is designed to help you align your thoughts, beliefs, and actions with the law of attraction, increasing your chances of manifesting a sugar mommy."
    },
    {
      type: 'heading',
      text: 'Here\'s how this subliminal can support your manifestation journey:'
    },
    {
      type: 'list',
      items: [
        "It helps you cultivate a positive mindset and believe in your ability to attract a sugar mommy.",
        "It enhances your self-confidence and self-worth, making you more attractive to potential sugar mommies.",
        "It aligns your subconscious mind with your desire to manifest a sugar mommy, increasing your vibrational frequency.",
        "It encourages you to take inspired action, putting yourself in situations where you are more likely to meet a sugar mommy.",
        "It supports you in maintaining a sense of gratitude and appreciation, attracting positive energy and opportunities into your life."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to eliminate any limiting beliefs or doubts that may be blocking your manifestation. Remember, the law of attraction works best when you combine it with inspired action and an open mind. Stay positive, stay aligned, and trust in the process!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "If you're looking to manifest a sugar mommy into your life, this subliminal can help you align your thoughts and beliefs with the law of attraction. Before you start listening, consider these tips to enhance your manifestation journey:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your desires: Get clear on what you want in a sugar mommy, including personality traits, financial capabilities, and the type of relationship you desire.",
        "Believe in abundance: Cultivate a mindset of abundance and believe that the universe will provide you with the opportunities to attract a sugar mommy.",
        "Visualize your ideal relationship: Close your eyes and imagine yourself in a fulfilling relationship with your sugar mommy, feeling the emotions and joy that comes with it.",
        "Focus on positive affirmations: While listening to the subliminal, repeat positive affirmations in your mind that align with manifesting a sugar mommy."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, the power of manifestation lies within your beliefs and alignment with your desires. By listening to this subliminal regularly and maintaining a positive mindset, you can attract a sugar mommy into your life. Embrace the journey, stay open to opportunities, and trust in the process of manifestation."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Manifest a Sugar Mommy subliminal session! By harnessing the power of the law of attraction, you have taken a step towards manifesting your desires and attracting a sugar mommy into your life. Now, it's time to reflect on your experience and continue on your manifestation journey."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Align your thoughts and beliefs: Continuously remind yourself that your desires are possible and that the universe is working in your favor.",
        "Take inspired action: Act on any opportunities that come your way, whether it's attending social events, joining online communities, or engaging in activities where you can meet potential sugar mommies.",
        "Visualize your ideal partnership: Use visualization techniques to imagine yourself in a fulfilling and harmonious relationship with a sugar mommy.",
        "Practice gratitude: Express gratitude for the abundance that already exists in your life and for the manifestations that are on their way."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, manifesting your desires requires a combination of belief, action, and gratitude. Stay open to the possibilities and trust that the universe is conspiring to bring your desires into reality. Keep your focus on attracting the ideal sugar mommy for a mutually beneficial and fulfilling partnership. Best of luck on your manifestation journey!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Can manifesting a sugar mommy guarantee financial stability?`, 
    answer: `While manifesting a sugar mommy can potentially lead to financial support, it is important to approach manifesting with realistic expectations. The law of attraction can help you attract opportunities and abundance into your life, but it does not guarantee specific outcomes. It is important to focus on personal growth, self-improvement, and developing a positive mindset, as these can contribute to your overall success and fulfillment.`,
    open: false
  },
  { 
    question: `How can I use the law of attraction to manifest a sugar mommy?`, 
    answer: `To manifest a sugar mommy, it is important to align your thoughts, feelings, and actions with the belief that you are deserving of a loving and supportive partner. Visualize yourself in a fulfilling relationship with a sugar mommy, practice gratitude for the abundance in your life, and take inspired actions that are in alignment with your desires. Remember to focus on self-improvement and personal growth, as these qualities can attract the right partner into your life.`,
    open: false 
  },
  { 
    question: `Is it ethical to manifest a sugar mommy?`, 
    answer: `The concept of manifesting a sugar mommy involves attracting a partner who is willing to provide financial support. Whether it is ethical or not depends on the specific dynamics and agreements within the relationship. It is important to have open and honest communication with your partner and ensure that both parties are comfortable with the arrangement. Additionally, it is crucial to approach any relationship, including those based on financial support, with respect, consent, and mutual understanding.`,
    open: false 
  },
  { 
    question: `Does manifesting a sugar mommy mean I won't need to work or be self-sufficient anymore?`, 
    answer: `Manifesting a sugar mommy does not exempt you from working or being self-sufficient. While a sugar mommy may provide financial support, it is still important to cultivate your own independence and work towards your personal goals. Self-sufficiency and having a sense of purpose can contribute to your overall happiness and fulfillment, regardless of any financial assistance you may receive in your relationship.`,
    open: false 
  },
  { 
    question: `Can I manifest a sugar mommy without putting in any effort?`, 
    answer: `Manifestation requires a combination of focused intention, belief, and inspired action. While the law of attraction can help you attract opportunities and abundance, it is important to take proactive steps towards meeting potential partners and creating meaningful connections. Putting in effort, such as socializing, networking, and actively pursuing personal growth, can increase your chances of manifesting a sugar mommy and cultivating a fulfilling relationship.`,
    open: false 
  },
  { 
    question: `Is manifesting a sugar mommy the only way to find financial support?`, 
    answer: `No, manifesting a sugar mommy is just one potential avenue for finding financial support. There are many other ways to seek financial stability and support, such as building a successful career, starting a business, or finding investment opportunities. It is important to explore different avenues and consider what aligns with your values, goals, and personal growth journey.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>