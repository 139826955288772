<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Preparing for exams can be a stressful and challenging time, but with the help of this subliminal, you can boost your chances of success. By targeting your subconscious mind, it works to enhance your focus, motivation, and study skills."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support your exam success:'
    },
    {
      type: 'list',
      items: [
        "Improving your ability to concentrate and maintain focus during study sessions.",
        "Increasing your motivation and drive to put in the necessary effort and preparation.",
        "Enhancing your memory and retention of important information.",
        "Boosting your confidence and reducing test anxiety.",
        "Developing effective study habits and time management skills."
      ]
    },
    {
      type: 'paragraph',
      text: "By consistently listening to this subliminal while studying and in the lead-up to your exams, you can harness the power of your subconscious mind to achieve the grades you desire. Remember, success in exams is a combination of hard work, preparation, and a positive mindset. You've got this!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Preparing for exams can be challenging, but this subliminal is designed to enhance your focus, motivation, and study skills to help you achieve success. Before you start listening, here are some tips to help you get in the right mindset:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Define what you want to achieve in your exams and write them down, making them specific and achievable.",
        "Create a study schedule: Plan out dedicated study sessions that align with your goals and include breaks for rest and relaxation.",
        "Visualize success: Imagine yourself confidently answering questions, retaining information, and achieving the grades you desire.",
        "Eliminate distractions: Find a quiet and comfortable study space, free from distractions like electronic devices or noisy environments."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you will tap into your subconscious mind to boost your focus, motivation, and study skills. With determination, dedication, and the support of this subliminal, you can excel in your academics and achieve the grades you desire. So take a deep breath, visualize your success, and let's get ready to conquer those exams!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Exam Success subliminal session! You've taken an important step towards achieving success in your exams and fulfilling your academic goals. Now, let's reflect on your experience and explore how you can continue to excel in your studies."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Review your study routine: Evaluate the effectiveness of your current study methods and make any necessary adjustments.",
        "Set realistic goals: Break down your study goals into smaller, manageable tasks to stay motivated and focused.",
        "Utilize effective study techniques: Experiment with different techniques like active recall, spaced repetition, and summarization.",
        "Manage your time effectively: Create a study schedule that balances your academic responsibilities and personal commitments."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, success in exams is not achieved overnight. It requires consistent effort, dedication, and perseverance. Believe in yourself, stay motivated, and trust in your abilities. With the right mindset and effective study habits, you can achieve the grades you desire. Keep up the great work and best of luck on your journey to academic excellence!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Exam Success subliminal help me improve my exam performance?`, 
    answer: `The Exam Success subliminal is designed to boost your focus, motivation, and study skills, helping you achieve success in your exams. By listening to the subliminal regularly, it can help reprogram your subconscious mind with positive affirmations and align your mindset with success. It may enhance your concentration, information retention, and ability to perform under exam-related pressures.`,
    open: false
  },
  { 
    question: `How long should I listen to the Exam Success subliminal before seeing results?`, 
    answer: `The time it takes to see results from the Exam Success subliminal can vary from person to person. Consistency is key, so it is recommended to listen to the subliminal daily for at least a few weeks to allow its messages to deeply influence your subconscious mind. Additionally, combining the subliminal with effective study strategies and consistent effort can maximize your chances of success.`,
    open: false 
  },
  { 
    question: `Can the Exam Success subliminal replace studying and preparation?`, 
    answer: `No, the Exam Success subliminal is not a substitute for studying and preparation. It is designed to enhance your mindset and mental state, boosting your focus, motivation, and study skills. It is important to combine the subliminal with effective study techniques, time management, and thorough preparation to achieve the best results in your exams.`,
    open: false 
  },
  { 
    question: `Are there any side effects of listening to the Exam Success subliminal?`, 
    answer: `No, there are no known side effects associated with listening to the Exam Success subliminal. However, if you have any concerns about your exam preparation or health, it is always advisable to seek guidance from your teachers or healthcare provider.`,
    open: false 
  },
  { 
    question: `Is it recommended to listen to the Exam Success subliminal during studying or before exams?`, 
    answer: `Yes, it is recommended to listen to the Exam Success subliminal during studying or before exams. The subliminal messages can help create a focused and motivated mindset, enhancing your study sessions and preparing you mentally for the exam. However, personal preferences may vary, so you can experiment with different listening times to find what works best for you.`,
    open: false 
  },
  { 
    question: `Can the Exam Success subliminal guarantee me a specific grade or outcome in exams?`, 
    answer: `No, the Exam Success subliminal cannot guarantee a specific grade or outcome in exams. It is designed to improve your mindset, focus, motivation, and study skills, which can increase your chances of success. Ultimately, your exam results depend on various factors, including your effort, preparation, and external circumstances. The subliminal aims to support and empower you in achieving your academic goals.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>