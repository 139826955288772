<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're struggling to break free from your junk food addiction and develop healthier eating habits, this subliminal is designed to support you on your journey. By targeting your subconscious mind, it can help you make conscious choices about the food you consume and improve your overall health and well-being."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help:'
    },
    {
      type: 'list',
      items: [
        "Reducing cravings for junk food and unhealthy snacks, making it easier to resist temptations.",
        "Boosting your willpower and self-control, helping you stick to your healthy eating goals.",
        "Encouraging a preference for nutritious foods and an aversion to unhealthy options.",
        "Promoting a positive relationship with food and helping you make conscious choices.",
        "Supporting you in creating new, healthy eating habits that are sustainable for the long term."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind to align with your desire for healthier eating habits. Over time, you may notice a decrease in cravings for junk food, an increase in your willpower, and a greater enjoyment of nourishing foods. Remember, change takes time and effort, but with commitment, you can develop a healthier relationship with food."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Overcoming your addiction to junk food and developing healthier eating habits is a challenging but worthwhile journey. This subliminal is designed to support you in making conscious choices about the food you consume and improving your overall well-being. Before you start listening, here are some tips to help you get ready:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Educate yourself: Learn about the negative effects of junk food on your health and the benefits of eating nutritious, whole foods.",
        "Clear your environment: Remove any tempting junk food from your pantry and replace it with healthier options.",
        "Set realistic goals: Define what healthy eating means to you and establish attainable goals that align with your lifestyle.",
        "Reframe your mindset: Shift your perspective on food by focusing on nourishing your body and embracing a more mindful approach to eating.",
        "Stay committed: Remind yourself of the reasons why you want to break free from junk food and stay motivated throughout your journey."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind and develop new, healthier eating habits. Remember that change takes time and effort, but with determination and consistency, you can overcome your addiction to junk food and improve your overall health and well-being. Get ready to embark on this transformative journey and let's say goodbye to junk food!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Stop Eating Junk Food subliminal! By taking this step towards breaking free from your junk food addiction and developing healthier eating habits, you are prioritizing your overall health and well-being. Reflecting on your journey and continuing to make conscious choices about the food you consume is vital as you move forward."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Eliminate triggers: Remove tempting junk food from your home and workspace.",
        "Stock up on healthy options: Fill your pantry and refrigerator with nutritious foods.",
        "Practice mindful eating: Slow down, savor each bite, and pay attention to your body's hunger and fullness cues.",
        "Seek support: Connect with like-minded individuals or join a support group to stay motivated and share experiences."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, breaking free from junk food addiction is a process that requires dedication and perseverance. Stay committed to your goals, surround yourself with a supportive environment, and celebrate each small victory along the way. You have the power to transform your relationship with food and embrace a healthier, happier lifestyle. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Stop Eating Junk Food subliminal help me break free from my addiction?`, 
    answer: `The Stop Eating Junk Food subliminal is designed to influence your subconscious mind, helping you develop healthier eating habits and break free from your addiction to junk food. By regularly listening to the subliminal, you can reprogram your thoughts and cravings, making it easier to resist unhealthy foods and make conscious choices that support your overall health and well-being.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Stop Eating Junk Food subliminal?`, 
    answer: `Results can vary depending on the individual and their level of commitment. Some people may experience positive changes in their cravings and food choices almost immediately, while others may require more time and consistent listening to fully overcome their addiction. We recommend listening to the subliminal daily and consistently for the best results.`,
    open: false 
  },
  { 
    question: `What can I do to support the effectiveness of the Stop Eating Junk Food subliminal?`, 
    answer: `To enhance the effectiveness of the Stop Eating Junk Food subliminal, it can be helpful to remove unhealthy food temptations from your immediate surroundings and replace them with healthier alternatives. Creating a supportive environment, developing a meal plan, and seeking the help of a nutritionist or support group can also aid in your journey towards healthier eating habits.`,
    open: false 
  },
  { 
    question: `Can the Stop Eating Junk Food subliminal completely eliminate cravings for unhealthy foods?`, 
    answer: `While the Stop Eating Junk Food subliminal is designed to help you develop healthier eating habits and reduce cravings for junk food, individual results may vary. The subliminal aims to shift your mindset and behaviors towards making conscious choices for your overall health and well-being.`,
    open: false 
  },
  { 
    question: `Is it safe to listen to the Stop Eating Junk Food subliminal while on a diet?`, 
    answer: `Yes, it is safe to listen to the Stop Eating Junk Food subliminal while on a diet. The subliminal audio is designed to support your goals of breaking free from junk food addiction and making healthier choices. It can complement your diet plan and provide subconscious reinforcement for your efforts.`,
    open: false 
  },
  { 
    question: `Will the Stop Eating Junk Food subliminal make me lose interest in all types of food?`, 
    answer: `No, the Stop Eating Junk Food subliminal is not intended to make you lose interest in all types of food. Its purpose is to help you break free from your addiction to junk food and develop healthier eating habits. You should still be able to enjoy a wide variety of nutritious and delicious foods.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>