<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Breaking free from a sweets addiction and regaining control of your eating habits can be challenging, but you don't have to do it alone. This subliminal is designed to help you overcome your addiction to sweets and live a healthier lifestyle."
    },
    {
      type: 'heading',
      text: 'How this subliminal can help:'
    },
    {
      type: 'list',
      items: [
        "Reduce cravings for sweets and unhealthy snacks.",
        "Increase your willpower and self-discipline when it comes to resisting temptations.",
        "Reprogram your subconscious mind to find satisfaction in healthier food choices.",
        "Build a positive relationship with food and develop a healthier approach to eating.",
        "Boost your confidence and motivation to stick to your goals and live a healthier lifestyle."
      ]
    },
    {
      type: 'paragraph',
      text: "As you regularly listen to this subliminal, you may notice a shift in your cravings and a stronger sense of control over your eating habits. Remember, overcoming addiction takes time and effort, but with the right mindset and support, it is possible to break free and create a healthier, balanced lifestyle."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Breaking free from a sweets addiction can be challenging, but this subliminal is here to support you on your journey towards a healthier lifestyle. Before you start listening, consider these helpful tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your addiction: Recognize and accept that you have a problem with sweets and that you want to make a change.",
        "Identify triggers: Pay attention to the situations, emotions, or thoughts that lead you to crave sweets. This self-awareness will help you better manage those triggers.",
        "Find healthier alternatives: Discover alternative snacks or treats that can satisfy your sweet tooth without derailing your progress.",
        "Create a support network: Seek the support of friends, family, or even online communities who can help keep you accountable and motivated.",
        "Stay committed: Remember that breaking an addiction takes time and effort. Stay focused on your goal of living a healthier lifestyle."
      ]
    },
    {
      type: 'paragraph',
      text: "By using this subliminal consistently, you can reprogram your subconscious mind and regain control over your eating habits. With determination and a strong support system, you can overcome your addiction to sweets and embrace a healthier way of living. Take a moment now to set your intention, prepare yourself mentally, and let's begin this transformative journey."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Sweets Addiction Removal subliminal! By committing to overcoming your addiction to sweets, you've taken a significant step towards living a healthier lifestyle. Reflecting on your progress and staying committed to breaking free from cravings is vital as you continue on this journey."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Identify triggers: Pay attention to the situations or emotions that lead to your sweet cravings.",
        "Find healthy alternatives: Discover and incorporate nutritious sweets that satisfy your taste buds.",
        "Practice mindful eating: Slow down and truly enjoy each bite, savoring the flavors and textures.",
        "Seek support: Reach out to friends, family, or a support group to share your journey and receive encouragement."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming a sweets addiction takes time and commitment. Stay focused on your goal of regaining control over your eating habits and living a healthier life. You have the power to break free from cravings and create a more balanced and fulfilling relationship with food. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Sweets Addiction Removal subliminal help me overcome my addiction to sweets?`, 
    answer: `The Sweets Addiction Removal subliminal works by targeting the subconscious mind, helping you develop a healthier and more balanced relationship with sweets. By listening to the subliminal regularly, you can rewire your thought patterns and reduce cravings, enabling you to make healthier food choices. Consistently listening to the subliminal and combining it with lifestyle changes, such as keeping healthier alternatives on hand and practicing self-control, can help you break free from your addiction.`,
    open: false
  },
  { 
    question: `How long will it take to overcome my addiction to sweets with the Sweets Addiction Removal subliminal?`, 
    answer: `The time it takes to overcome an addiction to sweets can vary depending on the individual and their commitment to the process. Some people may notice changes in their cravings and eating habits after a few weeks of consistent listening, while others may require more time. It's important to be patient and persistent in your efforts. Remember that everyone's journey is unique, and progress is progress no matter how small.`,
    open: false 
  },
  { 
    question: `Can the Sweets Addiction Removal subliminal be used alongside other methods to overcome addiction?`, 
    answer: `Yes, the Sweets Addiction Removal subliminal can be used alongside other methods to overcome addiction. It can complement other strategies such as therapy, counseling, or support groups. It is important to find a treatment approach that works best for you and to reach out for professional help if needed.`,
    open: false 
  },
  { 
    question: `Will listening to the Sweets Addiction Removal subliminal make me lose interest in all types of sweets?`, 
    answer: `The Sweets Addiction Removal subliminal is designed to help you develop a healthier relationship with sweets and reduce cravings, but it does not aim to eliminate all enjoyment of sweets entirely. The goal is to empower you to make healthier choices and regain control over your eating habits. You should still be able to enjoy occasional treats in moderation and without feeling deprived.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Sweets Addiction Removal subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Sweets Addiction Removal subliminal. However, if you have any concerns about your diet or health, please consult with your healthcare provider. The subliminal is intended to supplement healthy lifestyle changes, not replace them.`,
    open: false 
  },
  { 
    question: `Can the Sweets Addiction Removal subliminal help with other food addictions?`, 
    answer: `While the Sweets Addiction Removal subliminal specifically targets cravings and addiction to sweets, it may indirectly help with other food addictions by promoting a healthier mindset and overall improvement in eating habits. However, for specific food addictions, it is recommended to seek professional guidance tailored to your specific needs.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>