<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Being a parent comes with its own set of challenges, and it's normal to feel unsure or overwhelmed at times. This subliminal is designed to help you boost your parenting skills and confidence, so you can become a more effective and confident parent."
    },
    {
      type: 'heading',
      text: 'Here are some areas where this subliminal can be especially helpful:'
    },
    {
      type: 'list',
      items: [
        "If you often doubt your parenting abilities and struggle with self-confidence in your parenting decisions.",
        "If you want to develop better communication and problem-solving skills with your children.",
        "If you find it challenging to set boundaries and establish consistent discipline.",
        "If you want to enhance your ability to understand and connect with your child's emotions.",
        "If you want to cultivate a positive and nurturing environment for your family."
      ]
    },
    {
      type: 'paragraph',
      text: "No parent is perfect, but by listening to this subliminal regularly, you can start to build the confidence and skills needed to navigate the ups and downs of parenting. As you continue to use this subliminal, you may find yourself feeling more capable, compassionate, and empowered in your role as a parent. Remember, parenting is a journey, and with dedication and self-improvement, you can become the parent you aspire to be."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Parenting is a journey that often comes with its challenges, but this subliminal can help you enhance your parenting skills and boost your confidence as a parent. Before you start listening, take a moment to consider these tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your strengths: Acknowledge your positive qualities as a parent and remind yourself of the skills and experiences that make you competent.",
        "Identify areas for growth: Consider the aspects of parenting that you would like to improve or learn more about.",
        "Set realistic goals: Define specific goals that you want to achieve in your parenting journey, focusing on small, attainable steps.",
        "Embrace self-compassion: Parenting can be challenging, and it's important to be kind to yourself. Remember that mistakes are part of the learning process."
      ]
    },
    {
      type: 'paragraph',
      text: "As you begin your parenting confidence subliminal journey, keep in mind that it is designed to support and empower you. By listening regularly, you may notice a boost in your parenting skills, confidence, and overall effectiveness. Trust in your abilities, be patient with yourself, and embrace the joys and challenges of being a parent. Let's start this transformative experience together!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Parenting Confidence subliminal session! By focusing on improving your parenting skills and confidence, you are taking important steps towards becoming a more effective and confident parent. Now that you've finished listening, it's important to reflect on your experience and continue your growth as a parent."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your experience: Take a moment to write down any insights or thoughts you've gained during this session.",
        "Practice self-compassion: Remember, no parent is perfect. Be kind and patient with yourself as you navigate parenthood.",
        "Set realistic expectations: Recognize that parenting is a journey with ups and downs. It's okay to make mistakes and learn from them.",
        "Seek support: Connect with other parents or join parenting groups to share experiences and gain valuable insights.",
        "Celebrate victories: Acknowledge and celebrate the positive moments and accomplishments in your parenting journey."
      ]
    },
    {
      type: 'paragraph',
      text: "Parenting is a continuous learning process, and building confidence takes time. Embrace your growth as a parent, and remember that the love and effort you put into raising your child will make a meaningful impact. You're doing great!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Parenting Confidence subliminal help me become a more confident parent?`, 
    answer: `The Parenting Confidence subliminal is designed to target your subconscious mind and help reprogram your thoughts and beliefs about parenting. By listening to the subliminal messages, you can begin to boost your confidence, trust in your parenting abilities, and feel more competent in handling various parenting challenges. Consistent listening to the subliminal can help reinforce positive beliefs and behaviors, ultimately leading to increased parenting confidence.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Parenting Confidence subliminal?`, 
    answer: `Results from the Parenting Confidence subliminal can vary from person to person. Some individuals may start noticing improvements in their confidence levels shortly after beginning to listen, while others may require more time. It is important to listen to the subliminal consistently and give it time to work on your subconscious mind. Over time, you may see positive changes in your thoughts, emotions, and behavior as your confidence grows.`,
    open: false 
  },
  { 
    question: `Can the Parenting Confidence subliminal replace parenting skills and knowledge?`, 
    answer: `No, the Parenting Confidence subliminal is not intended to replace parenting skills and knowledge. It is designed to enhance and boost your confidence as a parent, helping you feel more capable and better equipped to handle parenting challenges. It is important to continue learning and developing your parenting skills through various resources and seeking support from experts when needed.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of using the Parenting Confidence subliminal?`, 
    answer: `No, there are no known risks or side effects associated with using the Parenting Confidence subliminal. However, if you have any concerns about your parenting or mental health, it is recommended to consult with a healthcare professional. The subliminal is intended to be used as a supplementary tool for boosting confidence and should not replace professional advice or support.`,
    open: false 
  },
  { 
    question: `How can I make the Parenting Confidence subliminal more effective?`, 
    answer: `To enhance the effectiveness of the Parenting Confidence subliminal, it can be helpful to listen to it in a quiet and comfortable environment where you can focus on the subliminal messages. Additionally, practicing self-reflection, positive affirmations, and seeking support from fellow parents or professionals can also contribute to your overall growth and confidence as a parent.`,
    open: false 
  },
  { 
    question: `Can the Parenting Confidence subliminal be used by new parents and experienced parents alike?`, 
    answer: `Yes, the Parenting Confidence subliminal can be used by both new parents and experienced parents. It is designed to help individuals of all levels of parenting experience boost their confidence and feel more capable in their parenting roles. The subliminal can be particularly beneficial during moments of self-doubt or when facing new challenges in parenting.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>