<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Motion sickness can put a damper on your travel experiences, causing nausea, dizziness, and discomfort. This subliminal is designed to help you overcome motion sickness and enjoy travel without these symptoms."
    },
    {
      type: 'heading',
      text: 'Key benefits of using this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Training your body to adapt to motion and reducing the likelihood of experiencing motion sickness.",
        "Finding relief from symptoms such as nausea, dizziness, and discomfort while traveling.",
        "Increasing your comfort and enjoyment during flights, car rides, boat trips, and other forms of transportation.",
        "Enhancing your ability to focus and engage in activities during travel without feeling overwhelmed by motion-related discomfort.",
        "Developing a more positive mindset towards travel and reducing any anxiety or fear associated with motion sickness."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can retrain your mind and body to respond in a more adaptable and comfortable way to motion. Over time, you may find that your symptoms diminish or even disappear completely, allowing you to travel with confidence and ease. Remember to use this subliminal consistently for optimal results."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Motion sickness can put a damper on your travel plans, but with the help of this subliminal, you can overcome the discomfort and enjoy your journeys. Before you start listening, here are some preparation tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Stay hydrated: Dehydration can worsen symptoms of motion sickness, so be sure to drink plenty of water before and during your travels.",
        "Choose your seat wisely: Opt for a seat with a good view of the horizon and less motion, such as over the wing in an airplane or in the front seat of a car.",
        "Take breaks: If possible, take frequent breaks during long journeys to allow your body to adjust and rest.",
        "Relax and breathe: Practice deep breathing and relaxation techniques to calm your mind and body while in motion."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal, the affirmations will work on reprogramming your subconscious mind to reduce the symptoms of motion sickness. Through regular listening, you can train your body to adapt and find relief from the discomfort caused by motion. So prepare yourself, take a deep breath, and get ready to enjoy your travels without the burden of motion sickness!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Motion Sickness Cure subliminal session! By taking this step towards overcoming motion sickness, you've opened up a world of possibilities for enjoyable and hassle-free travel. Now, it's important to reflect on your experience and continue your journey towards finding relief from motion-related discomfort."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice motion acclimation exercises: Gradually expose yourself to motion by watching videos or taking short trips to build resilience.",
        "Focus on your breathing: Deep breathing exercises can help reduce anxiety and alleviate symptoms of motion sickness.",
        "Choose your seat wisely: Opt for a seat with less motion, such as near a window or in the front of a vehicle.",
        "Stay hydrated and avoid heavy meals: Avoid dehydration and eating large, heavy meals before travel to minimize discomfort."
      ]
    },
    {
      type: 'paragraph',
      text: "Overcoming motion sickness is a process that requires patience and trial-and-error. Be kind to yourself as you experiment with different strategies to find relief. With consistent effort and a positive mindset, you'll be able to enjoy travel without the discomfort of motion sickness. Bon voyage!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Motion Sickness Cure subliminal help?`, 
    answer: `The Motion Sickness Cure subliminal is designed to help you overcome motion sickness and find relief from motion-related discomfort. It works by influencing your subconscious mind to adapt to motion, reducing feelings of nausea and dizziness. By regularly listening to the subliminal, you can train your body to handle different types of motion and enjoy travel without discomfort.`,
    open: false
  },
  { 
    question: `How long does it take to see improvements in motion sickness symptoms?`, 
    answer: `The time it takes to see improvements in motion sickness symptoms may vary from person to person. Some individuals may notice a reduction in symptoms after a few listens, while others may require more time and consistent listening. It is recommended to listen to the Motion Sickness Cure subliminal daily and consistently to maximize its effectiveness.`,
    open: false 
  },
  { 
    question: `Can the Motion Sickness Cure subliminal be used for all types of motion sickness?`, 
    answer: `Yes, the Motion Sickness Cure subliminal is designed to help with various types of motion sickness, including car sickness, seasickness, and airsickness. It aims to reduce the discomfort associated with motion and improve your ability to adapt to different environments.`,
    open: false 
  },
  { 
    question: `Are there any side effects of listening to the Motion Sickness Cure subliminal?`, 
    answer: `No, there are no known side effects associated with listening to the Motion Sickness Cure subliminal. However, if you have any concerns about your health or experience any unusual symptoms, it is recommended to consult with a healthcare professional.`,
    open: false 
  },
  { 
    question: `Can the Motion Sickness Cure subliminal be used alongside other motion sickness remedies?`, 
    answer: `Yes, the Motion Sickness Cure subliminal can be used alongside other motion sickness remedies and strategies. It can complement other treatments, such as medications, acupressure bands, and avoiding triggers, by helping you develop a more positive mindset towards motion and reducing discomfort in a holistic way.`,
    open: false 
  },
  { 
    question: `Is the Motion Sickness Cure subliminal effective for everyone?`, 
    answer: `While the Motion Sickness Cure subliminal can be effective for many individuals, results may vary from person to person. Factors such as the severity of motion sickness and individual responses to subliminal messages can influence its effectiveness. It is recommended to listen to the subliminal consistently and combine it with other techniques for managing motion sickness.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>