<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Becoming debt-free and achieving financial freedom requires discipline, planning, and the right mindset. This subliminal is designed to help you with all of these aspects, providing the support and motivation you need on your journey towards financial stability."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help you:"
    },
    {
      type: 'list',
      items: [
        "Learning effective strategies to manage your finances and create a realistic budget.",
        "Developing strong money management skills to help you make wise financial decisions and avoid unnecessary debt.",
        "Building discipline and self-control to resist impulse spending and unnecessary purchases.",
        "Visualizing and manifesting a debt-free future, helping you stay focused and motivated on your goal.",
        "Reducing stress and anxiety related to financial matters, creating a more positive and empowered mindset.",
        "Developing a long-term plan to pay off debts and establish a solid foundation for financial freedom."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to support your financial goals and cultivate healthy financial habits. It's important to remember that becoming debt-free is a journey that requires dedication and consistency. Stick to your plan, stay motivated, and you can achieve the financial freedom you desire!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Becoming debt-free and achieving financial freedom is an empowering goal, and this subliminal can assist you on your journey by reprogramming your mindset around money and debt. Before starting, consider these helpful tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Assess your financial situation: Take stock of your debts, income, and expenses to gain a clear understanding of where you currently stand.",
        "Create a realistic budget: Develop a budget that aligns with your financial goals, allowing you to allocate funds towards paying off debt.",
        "Identify triggers and patterns: Reflect on the behaviors and situations that contribute to your spending habits and debt accumulation.",
        "Visualize a debt-free future: Imagine the freedom and peace of mind that comes with being debt-free, allowing this vision to motivate you on your journey."
      ]
    },
    {
      type: 'paragraph',
      text: "By consistently listening to this subliminal, you can develop a positive and supportive mindset that encourages responsible financial habits. As you embark on this path towards becoming debt-free, remember to be patient and persistent. With each step you take, you are moving closer to financial freedom and a brighter future."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Become Debt-Free subliminal session! By dedicating yourself to improving your financial well-being, you've taken a significant step towards achieving financial freedom. As you move forward, it's important to reflect on your experience and continue building momentum towards your goal."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Assess your current financial situation: Take stock of your income, expenses, and debts to get a clear picture.",
        "Create a budget: Develop a realistic budget that aligns with your financial goals and helps you manage your finances.",
        "Set priorities: Determine the most effective strategies to pay off debt, such as the snowball or avalanche method.",
        "Seek professional advice: Consult with a financial advisor or credit counselor for personalized guidance and support."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, becoming debt-free takes time and commitment, but it is absolutely achievable. Stay focused on your goals, make informed financial decisions, and take proactive steps towards managing your debts effectively. You have the power to take control of your financial future and create lasting financial stability. Best of luck on your journey to financial freedom!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Become Debt-Free subliminal help me?`, 
    answer: `The Become Debt-Free subliminal is designed to help you develop the mindset and habits necessary to manage your finances, create a budget, and work towards becoming debt-free. By listening to the subliminal regularly, you can reprogram your subconscious mind to align with your financial goals and make the necessary changes to achieve financial freedom.`,
    open: false
  },
  { 
    question: `How long does it take to become debt-free using the subliminal?`, 
    answer: `The time it takes to become debt-free can vary depending on your individual financial situation, the amount of debt you have, and your commitment to the process. The Become Debt-Free subliminal is not a quick fix, but rather a tool to aid in your journey towards financial freedom. It is essential to combine the subliminal with practical financial management strategies and consistently make progress towards paying off your debts.`,
    open: false 
  },
  { 
    question: `What other steps can I take alongside listening to the Become Debt-Free subliminal?`, 
    answer: `Listening to the Become Debt-Free subliminal is just one aspect of your journey towards becoming debt-free. It is essential to adopt good financial habits, such as creating a budget, reducing unnecessary expenses, and increasing your income if possible. Educating yourself on personal finance, seeking professional advice if needed, and staying committed to your financial goals can also contribute to your success.`,
    open: false 
  },
  { 
    question: `Are there any risks involved in listening to the Become Debt-Free subliminal?`, 
    answer: `No, there are no known risks associated with listening to the Become Debt-Free subliminal. However, it is important to note that the subliminal is designed to complement your financial journey and should not replace professional financial advice. It is always advisable to consult with a financial advisor or expert when dealing with significant financial matters.`,
    open: false 
  },
  { 
    question: `Can the Become Debt-Free subliminal work for any amount of debt?`, 
    answer: `Yes, the Become Debt-Free subliminal can be beneficial for individuals with any amount of debt. The messages in the subliminal are designed to help you develop a positive and motivated mindset towards managing your finances and becoming debt-free, regardless of the specific amount you owe.`,
    open: false 
  },
  { 
    question: `Will the Become Debt-Free subliminal magically eliminate my debt?`, 
    answer: `No, the Become Debt-Free subliminal is not intended to magically eliminate your debt. It is a tool to help you develop the mindset and habits necessary to manage your finances, create a budget, and work towards becoming debt-free. It is important to combine the subliminal with practical financial management strategies and take consistent action towards paying off your debts.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>