<template>
  <div>
    <div class="mx-6 mt-5">
      <Card>
        <v-row>
          <v-col cols="12" sm="4" lg="4" v-for="search in searchs">
            <Select :title="search.title" :type="search.type" :text="search.text" :value="search.value"
              @getSelectItem="getSelectItem"></Select>
          </v-col>
        </v-row>
      </Card>
    </div>

    <Table itemkey="email" :loading="loading" :items="items" :headers="headers" :select="selected" :total="total"
      @action="getAction" @updateLimitPage="updateLimitPage" :add="false" @updateSearch="updateSearch"
      @updateSort="updateSort" />

    <addDialog v-if="dialogType == 'add'" :data="data" :dialog="dialog" @closeDialog="closeDialog" />

    <editDialog v-if="dialogType == 'update'" :data="data" :dialog="dialog" @closeDialog="closeDialog" />

    <passwordDialog v-if="dialogType == 'change_password'" :data="data" :dialog="dialog" @closeDialog="closeDialog" />

    <deleteDialog v-if="dialogType == 'delete' || dialogType == 'deleteall'" :data="data" :dialog="dialog"
      :selected="selected" @selected="getSelected" @closeDialog="closeDialog" />
  </div>
</template>

<script>
import { getUserFilters, updateToggle } from '@/api/user'
import { getMessage } from '@/utils'

import Card from '@/components/Card.vue'
import Table from '@/components/Admin/Table.vue'
import Select from '@/components/Admin/Select.vue'

import addDialog from './components/addDialog.vue'
import editDialog from './components/editDialog.vue'
import deleteDialog from './components/deleteDialog.vue'
import passwordDialog from './components/passwordDialog.vue'

export default {
  components: {
    Card,
    Table,
    Select,
    addDialog,
    editDialog,
    passwordDialog,
    deleteDialog
  },
  data: () => {
    return {
      getUserFilters,
      getMessage,
      updateToggle,

      data: {},
      dialog: false,
      dialogType: null,

      items: [],
      selected: [],
      loading: true,

      headers: [
        { text: 'Image', value: 'cover', sortable: false },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Name', value: 'display_name', sortable: false },
        { text: 'Influencer', value: 'influencer', sortable: false },
        { text: 'Subscription', value: 'subscription_name', sortable: false },
        { text: 'Date of Subscription', value: 'subscription_start', sortable: false },
        { text: 'Expiry', value: 'subscription_end', sortable: false },
        { text: 'Subscription Status', value: 'subscription_status', sortable: false },
        { text: 'Action', value: 'change_update_delete', sortable: false, width: '200' },
      ],

      searchs: [
        { title: 'Name', type: 'user', text: 'name', value: 'name' },
        { title: 'Status', type: 'stateuser', text: 'text', value: 'value' },
        { title: 'Subscription', type: 'subscription', text: 'name', value: 'name' },
      ],

      sort: null,
      status: null,
      search: null,
      display_name: null,
      subscription: null,

      page: 1,
      total: 0,
      limit: 10,
    }
  },
  created() {
    this.getData()
  },
  watch: {
    sort() {
      this.getData()
    },
    search() {
      this.getData()
    },
    subscription() {
      this.getData()
    },
    display_name() {
      this.getData()
    },
    status() {
      this.getData()
    },
  },
  methods: {
    closeDialog() {
      this.getData()
    },
    getSelected(data) {
      this.selected = data
    },
    getAction(params) {
      this.dialog = true
      this.data = params.data
      this.dialogType = params.action
      this.selected = params.selected

      if (params.action == 'toggle') {
        if(this.data.toggle==true){
          this.isVisible=0;
        }else{
          this.isVisible=1;
        }
        const data = {
          is_visible: this.isVisible,
          user_id: this.data.user_id,
        }
        this.updateToggle(data).then((res) => {
          this.getMessage(this, res)
          this.getData()
        })
      }
    },
    getData() {
      this.loading = true
      const data = {
        sort: this.sort,
        page: this.page,
        is_user_admin: 0,
        limit: this.limit,
        search: this.search,
        status: this.status,
        influencer: this.influencer,
        subscription: this.subscription,
        display_name: this.display_name,
      }

      this.getUserFilters(data).then((res) => {
        const { users, total } = res

        this.total = total
        this.items = users

        this.loading = false
        this.dialog = false
        this.dialogType = null
      })
    },
    getSelectItem(item) {
      switch (item.type) {
        case 'stateuser':
          this.status = item.data
          break;
        case 'user':
          this.display_name = item.data
          break;
        case 'subscription':
          this.subscription = item.data
          break;
      }
    },
    updateLimitPage(data) {
      const { page, limit } = data
      this.page = page
      this.limit = limit

      this.getData()
    },
    updateSearch(data) {
      this.search = data
      this.getData()
    },
    updateSort(data) {
      this.sort = data
      this.getData()
    }
  }
}
</script>
