<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Taking care of yourself is crucial for overall well-being and happiness. This subliminal is designed to support you in prioritizing self-care and developing healthy habits for your physical, mental, and emotional well-being."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help:'
    },
    {
      type: 'list',
      items: [
        "Encouraging self-reflection and awareness of your needs and boundaries.",
        "Promoting relaxation and stress reduction techniques for mental and emotional well-being.",
        "Supporting healthy lifestyle choices, such as exercise, nutrition, and sleep.",
        "Boosting self-esteem and self-love to cultivate a positive self-image.",
        "Building resilience and coping skills to manage challenges and setbacks."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can rewire your subconscious mind to prioritize self-care and make it a natural part of your daily routine. As you develop healthier habits and take time for yourself, you may experience increased happiness, reduced stress levels, and improved overall well-being. Remember, self-care is not selfish - it's necessary for you to thrive." 
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Taking care of yourself is essential for overall health and well-being, but it's easy to neglect self-care in our busy lives. This subliminal is designed to help you prioritize self-care and cultivate a deeper connection with yourself. Before you start listening, consider these tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Identify your needs: Take some time to reflect on what areas of your life could benefit from more self-care, whether it's physical, mental, or emotional.",
        "Create a self-care plan: Make a list of activities or practices that make you feel good and nourish your well-being.",
        "Set boundaries: Learn to say no to things that drain you and make time for yourself without guilt.",
        "Stay present: As you listen to the subliminal, focus on the present moment and the positive changes you seek in your self-care routine."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, self-care is not selfish. It is a necessary part of maintaining balance and taking care of yourself, so you can better show up for others. As you listen to the subliminal, allow yourself to embrace self-compassion and prioritize your well-being. Let's begin this journey of self-care and create a life that is more aligned with your needs and desires."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Self-Care subliminal! By prioritizing your well-being and learning to take care of yourself, you've taken a significant step towards a more fulfilling and balanced life. As you continue on your self-care journey, it's essential to reflect on your experience and commit to ongoing growth and self-improvement."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a self-care routine: Incorporate activities that nourish your physical, mental, and emotional well-being.",
        "Practice mindfulness: Engage in mindfulness exercises to stay present and cultivate a sense of inner peace.",
        "Set boundaries: Learn to say no and prioritize your needs to avoid burnout and overwhelm.",
        "Seek support: Reach out to loved ones or consider professional help when needed to enhance your self-care journey."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, self-care is a continuous and evolving process. Be patient with yourself and celebrate each step you take towards nurturing your well-being. You deserve to invest in yourself and create a life filled with self-love, joy, and fulfillment. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What is self-care and why is it important?`, 
    answer: `Self-care refers to the intentional actions we take to prioritize our physical, mental, and emotional well-being. It involves activities that promote relaxation, stress reduction, and overall self-improvement. Self-care is important because it allows us to recharge, restore balance, and prevent burnout. It helps improve our mood, boost our productivity, and enhance our overall quality of life.`,
    open: false 
  },
  {
    question: `What are some examples of self-care activities?`, 
    answer: `Self-care activities can vary depending on individual preferences, but some examples include: 
- Taking a warm bath
- Engaging in regular exercise
- Practicing mindfulness or meditation
- Spending time in nature
- Journaling or engaging in creative activities
- Spending quality time with loved ones
- Getting enough sleep
- Eating a balanced diet
- Prioritizing hobbies or interests
- Seeking professional help or therapy when needed`,
    open: false 
  },
  {
    question: `How can I make self-care a regular part of my routine?`, 
    answer: `Incorporating self-care into your routine can be as simple as setting aside dedicated time for yourself each day. It can be helpful to schedule specific activities or create a self-care plan to ensure you prioritize these activities regularly. Start small and gradually increase the time and effort you devote to self-care. Remember, self-care is a personal journey, so find activities that resonate with you and make you feel nourished and rejuvenated.`,
    open: false 
  },
  {
    question: `Is it selfish to prioritize self-care?`, 
    answer: `No, prioritizing self-care is not selfish. Taking care of your own physical, mental, and emotional well-being is essential for maintaining a healthy balance in your life. It allows you to show up as your best self for others and gives you the capacity to provide support and care to those around you. Remember that self-care is not only about indulging in pleasurable activities, but also about making choices that support your overall well-being.`,
    open: false 
  },
  {
    question: `What should I do if I struggle to prioritize self-care?`, 
    answer: `If you are struggling to prioritize self-care, it may be helpful to reassess your priorities and mindset. Recognize that taking care of yourself is not selfish but necessary for your overall well-being. Start by incorporating small self-care activities into your routine and gradually increase their frequency and importance. Seek support from loved ones or consider working with a professional, such as a therapist, who can provide guidance and help you develop a self-care plan that works for you.`,
    open: false 
  },
  {
    question: `Is self-care only for individuals who have a lot of free time and resources?`, 
    answer: `No, self-care is for everyone, regardless of their available time and resources. While some self-care activities may require more time or financial investment, there are many simple and cost-effective ways to prioritize self-care. Remember that self-care is about making intentional choices to prioritize your well-being, so find activities that align with your circumstances and make you feel nourished and cared for.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>