<template>
  <div class="my-5">
    <ChangePassword />
  </div>
</template>

<script>
import ChangePassword from '@/views/Main/ChangePassword'
export default {
  components: {
    ChangePassword
  }
}
</script>