<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Are you interested in learning Russian but find it challenging to grasp the language? This language learning aid is here to assist you in becoming fluent in Russian by enhancing your vocabulary, pronunciation, and grammar skills."
    },
    {
      type: 'heading',
      text: 'Here are some features of this language learning aid:'
    },
    {
      type: 'list',
      items: [
        "Improve your Russian vocabulary by learning new words and phrases.",
        "Enhance your pronunciation skills through audio exercises and practice.",
        "Develop a strong understanding of Russian grammar rules and concepts.",
        "Engage in interactive activities and exercises to reinforce your learning.",
        "Utilize helpful resources, such as flashcards and language exercises, for effective learning."
      ]
    },
    {
      type: 'paragraph',
      text: "By utilizing this language learning aid regularly, you can gradually and effectively improve your Russian language skills. Whether you're a beginner or already have some knowledge of the language, this aid will support your language learning journey and help you become fluent in Russian. Remember, consistent practice and dedication are key to achieving your language goals."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a journey to learn a new language can be both exciting and challenging. This language learning aid is designed to support your progress in mastering the Russian language. Before you begin, consider these pre-listening tips to enhance your learning experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Identify what aspects of the Russian language you want to improve, such as vocabulary, pronunciation, or grammar skills.",
        "Create a study plan: Establish a consistent learning schedule that allows for regular practice and review.",
        "Immerse yourself: Surround yourself with Russian language resources, such as movies, books, or music, to heighten your exposure to the language.",
        "Stay motivated: Keep your enthusiasm for learning Russian alive by celebrating your progress and using positive affirmations to stay focused on your goals."
      ]
    },
    {
      type: 'paragraph',
      text: "With regular use of this language learning aid, you can develop a deeper understanding and fluency in the Russian language. Utilize these pre-listening tips to enhance your learning experience and maximize your language acquisition potential. Remember, language learning is a journey, and each step you take brings you closer to your goal of becoming fluent in Russian. Enjoy the process and let's begin!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Russian Language Learning Aid session! By dedicating your time and effort to mastering the Russian language, you're opening up a world of possibilities for communication and cultural understanding. Take a moment to reflect on your progress and commit to continuing your language journey."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice regularly: Consistency is key to language learning. Set aside time each day to practice Russian.",
        "Expand your vocabulary: Continuously build your vocabulary by learning new words and phrases.",
        "Improve pronunciation: Focus on mastering the pronunciation of Russian sounds and practice speaking aloud.",
        "Review grammar rules: Enhance your understanding of Russian grammar by reviewing key concepts and practicing exercises."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, learning a new language is a lifelong journey. Embrace the challenges and celebrate your progress along the way. Keep practicing, stay motivated, and soon you'll be speaking Russian with confidence. Удачи (Good luck)!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Can the Russian Language Learning Aid help me if I have no prior knowledge of the Russian language?`, 
    answer: `Yes, the Russian Language Learning Aid is designed to be beginner-friendly and can help you learn Russian from scratch. It provides vocabulary, pronunciation, and grammar support to help you build a solid foundation in the language. With consistent practice and dedication, you can make significant progress in your Russian language learning journey.`,
    open: false
  },
  { 
    question: `How can I use the Russian Language Learning Aid to improve my vocabulary?`, 
    answer: `The Russian Language Learning Aid incorporates various vocabulary-building techniques, such as flashcards, word lists, and audio exercises. By actively engaging with these resources and regularly practicing new words and phrases, you can expand your Russian vocabulary. It is also helpful to immerse yourself in Russian language media like movies, music, and books to reinforce your learning.`,
    open: false 
  },
  { 
    question: `Will the Russian Language Learning Aid help improve my pronunciation?`, 
    answer: `Yes, the Russian Language Learning Aid includes pronunciation guidance and exercises to help you improve your Russian pronunciation. It provides audio recordings of native speakers, practice drills, and tips to help you master the sounds of the Russian language. Consistent practice and repetition are key to enhance your pronunciation skills.`,
    open: false 
  },
  { 
    question: `Can the Russian Language Learning Aid help me with Russian grammar?`, 
    answer: `Absolutely! The Russian Language Learning Aid offers grammar explanations, examples, and exercises to help you understand and apply Russian grammar rules. It covers various concepts such as verb conjugation, noun cases, and sentence structure. By studying and practicing grammar with the aid, you can improve your understanding and proficiency in Russian grammar.`,
    open: false 
  },
  { 
    question: `Is it necessary to have a teacher or attend classes while using the Russian Language Learning Aid?`, 
    answer: `While the Russian Language Learning Aid is designed to provide comprehensive support, having a teacher or attending classes can further enhance your learning experience. A teacher can provide personalized feedback, clarify any doubts, and guide you through your language learning journey. However, you can still make significant progress by using the aid independently.`,
    open: false 
  },
  { 
    question: `How long will it take to become fluent in Russian using the Russian Language Learning Aid?`, 
    answer: `The time it takes to become fluent in Russian varies from person to person and depends on various factors, such as your prior language learning experience, dedication, and consistent practice. The Russian Language Learning Aid is a valuable resource that can support your learning, but becoming fluent generally requires a long-term commitment and immersion in the language. With regular practice and patience, you can achieve fluency in Russian.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>