<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Practicing self-forgiveness is a powerful way to let go of the burden of guilt and regret. This subliminal is specifically designed to help you embrace self-acceptance and forgive yourself for past mistakes and shortcomings."
    },
    {
      type: 'heading',
      text: 'Here are some key aspects of this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Promotes self-compassion and understanding, allowing you to nurture a more positive relationship with yourself.",
        "Encourages letting go of negative self-talk and self-judgment, replacing it with self-love and acceptance.",
        "Guides you to reframe past experiences and see them as opportunities for growth and learning rather than sources of guilt and regret.",
        "Supports the healing process by releasing emotional baggage and allowing you to move forward with forgiveness for yourself.",
        "Helps you cultivate a mindset of self-forgiveness that extends to all aspects of your life, fostering personal growth and inner peace."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can gradually shift your perspective and free yourself from the weight of self-blame and regret. As you practice self-forgiveness and embrace self-acceptance, you can experience greater emotional well-being, improved self-esteem, and a more fulfilling life." 
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Adopting a practice of self-forgiveness can be transformative in setting yourself free from the weight of guilt and regret. This subliminal is designed to support you on this journey towards self-acceptance. Before you begin, consider these suggestions to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your emotions: Recognize and allow yourself to feel the emotions associated with guilt and regret, without judgment or resistance.",
        "Practice self-compassion: Treat yourself with kindness and understanding, as you would a dear friend who is experiencing similar feelings.",
        "Reflect on lessons learned: Consider the lessons and growth opportunities that have emerged from your past experiences.",
        "Set your intention: Clarify your desire and commitment to forgive yourself and embrace self-acceptance while listening to the subliminal messages."
      ]
    },
    {
      type: 'paragraph',
      text: "As you embark on this journey of self-forgiveness, the subliminal will help rewire your subconscious mind, allowing you to let go of the past and embrace a brighter future. By regularly engaging with this subliminal, you may find yourself experiencing increasing feelings of self-compassion, self-acceptance, and inner peace. Take a moment, breathe deeply, and let the process of self-forgiveness unfold."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Self-Forgiveness subliminal session! By focusing on practicing self-forgiveness and embracing self-acceptance, you've taken an important step towards releasing the burden of guilt and regret. Reflecting on this experience and continuing to nurture self-forgiveness is crucial for your personal growth and well-being."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your journey: Take the time to journal your thoughts, emotions, and insights following this session.",
        "Practice self-compassion: Be kind and understanding towards yourself, acknowledging that everyone makes mistakes.",
        "Let go of self-judgment: Release the need for perfection and embrace yourself fully, flaws and all.",
        "Seek support if needed: Consider reaching out to trusted friends, family, or professionals for guidance and understanding."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, self-forgiveness is a continuous process. Be patient with yourself and celebrate every step towards self-acceptance and healing. By letting go of guilt and regret, you free yourself to live a more fulfilling and joyful life. Embrace the journey of self-forgiveness, and may it bring you peace and liberation."
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What is self-forgiveness?`, 
    answer: `Self-forgiveness is the act of letting go of self-blame, guilt, and resentment towards oneself for past actions or mistakes. It involves acknowledging and accepting one's imperfections and making a conscious effort to release negative emotions and thoughts associated with self-judgment.`,
    open: false
  },
  { 
    question: `Why is self-forgiveness important?`, 
    answer: `Self-forgiveness is important for emotional healing and personal growth. Holding onto guilt and resentment towards oneself can negatively impact self-esteem, relationships, and overall well-being. By practicing self-forgiveness, individuals can let go of the past, promote self-acceptance, and cultivate a healthier and more compassionate relationship with themselves.`,
    open: false 
  },
  { 
    question: `How can I practice self-forgiveness?`, 
    answer: `Practicing self-forgiveness involves acknowledging and accepting one's mistakes or past actions, expressing remorse, and making a commitment to learn from those experiences. It also requires letting go of negative self-talk and replacing it with self-compassion and understanding. Engaging in self-reflection, seeking support from loved ones or a therapist, and practicing self-care activities can also aid in the process of self-forgiveness.`,
    open: false 
  },
  { 
    question: `Is self-forgiveness a one-time event?`, 
    answer: `Self-forgiveness is a process that takes time and practice. It is not a one-time event but rather a continuous effort to let go of self-blame and embrace self-acceptance. It may involve revisiting past actions or mistakes and reaffirming the commitment to forgive oneself as new challenges arise.`,
    open: false 
  },
  { 
    question: `Can self-forgiveness improve my overall well-being?`, 
    answer: `Yes, practicing self-forgiveness can have a positive impact on overall well-being. By releasing guilt and self-blame, individuals can experience improved mental and emotional health, reduced stress levels, and enhanced self-esteem. It can also lead to healthier relationships, increased self-compassion, and a greater sense of inner peace and happiness.`,
    open: false 
  },
  { 
    question: `What if I struggle with forgiving myself?`, 
    answer: `Struggling with self-forgiveness is common and understandable. It is important to remember that forgiveness is a personal journey and a process that may take time. Seeking guidance from a therapist or support group, practicing self-compassion, and engaging in self-care activities can help individuals navigate and work through their struggles with self-forgiveness.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>