<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Whether you're a beginner looking to learn the fundamentals or an experienced player wanting to improve your skills, this subliminal can help you master the game of American football. By targeting your subconscious mind, it enhances your training sessions and helps you become a skilled player on the field."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can benefit you:"
    },
    {
      type: 'list',
      items: [
        "Improving your technique in key football skills, such as passing, catching, blocking, and tackling.",
        "Increasing your speed, agility, and overall physical performance on the field.",
        "Boosting your confidence and mental focus during games and practice sessions.",
        "Enhancing your football IQ and understanding of strategies, plays, and game situations.",
        "Developing better teamwork and communication skills to excel as part of a football team."
      ]
    },
    {
      type: 'paragraph',
      text: "By consistently listening to this subliminal while practicing and training, you can accelerate your progress as a football player. It can help you internalize the skills, techniques, and mindset necessary for success on the field. Remember, dedication and practice are essential for maximizing your football mastery."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Becoming a skilled American football player requires dedication and consistent training. This subliminal is designed to enhance your skills and techniques, helping you improve your performance on the field. Before you start listening, consider these tips to maximize your training sessions:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set specific goals: Determine what skills or techniques you want to improve and set clear, achievable goals.",
        "Create a structured practice routine: Plan regular training sessions and focus on specific areas of improvement.",
        "Visualize your success: Imagine yourself executing the skills and techniques flawlessly, fostering a positive mindset.",
        "Stay focused during training: Concentrate on your goals, pay attention to details, and maintain a high level of intensity and effort."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly and incorporating these preparation tips into your training routine, you can amplify your progress on the field. As you become more skilled and confident, enjoy the journey of mastering American football and let your passion for the game drive your motivation. Now, let's get ready to take your skills to the next level!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the American Football Skills and Training Mastery program! By dedicating your time and effort to mastering the skills and techniques of football, you've taken a significant step towards becoming a skilled player. Now, it's time to reflect on your progress and continue honing your abilities."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Analyze your performance: Review your practices and games to identify areas for improvement.",
        "Set specific goals: Define what skills you want to improve and create a plan to achieve them.",
        "Diversify your training: Incorporate different drills, exercises, and workouts to develop a well-rounded skillset.",
        "Seek feedback: Regularly consult with coaches, trainers, and teammates to gain valuable insights."
      ]
    },
    {
      type: 'paragraph',
      text: "Becoming a skilled football player requires dedication, discipline, and continuous improvement. Embrace the challenges and setbacks along the way, and never stop striving for excellence. With consistent effort and a growth mindset, you can achieve great things on the field. Keep pushing yourself and enjoy the journey to football mastery!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Who can benefit from American Football Skills and Training Mastery?`, 
    answer: `American Football Skills and Training Mastery is designed for anyone who wants to improve their skills and performance in American football. Whether you are a beginner looking to learn the basics or an experienced player wanting to take your game to the next level, this program can help you enhance your skills, technique, and overall understanding of the game.`,
    open: false
  },
  { 
    question: `What areas of American football does this program cover?`, 
    answer: `This program covers a wide range of areas in American football, including fundamental skills such as passing, catching, blocking, tackling, and kicking. It also focuses on position-specific training, strategy and game analysis, physical conditioning, speed and agility, and mental preparation. With comprehensive training sessions, you will have the opportunity to develop various aspects of your game.`,
    open: false 
  },
  { 
    question: `Do I need any prior experience in American football to benefit from this program?`, 
    answer: `No prior experience is necessary to benefit from American Football Skills and Training Mastery. This program is suitable for beginners who want to learn the game from scratch as well as experienced players who want to refine their skills and knowledge. The training is designed to cater to different skill levels and progress accordingly.`,
    open: false 
  },
  { 
    question: `Can I use this program for individual training or is it better suited for team practice?`, 
    answer: `American Football Skills and Training Mastery can be used for both individual training and team practice. The program includes drills and exercises that can be done individually to focus on specific skills, as well as strategies and game analysis that can be implemented in team practices. Whether you are training on your own or with a team, this program can be customized to suit your needs.`,
    open: false 
  },
  { 
    question: `How long does it take to see improvements in my playing skills?`, 
    answer: `The time it takes to see improvements in your playing skills can vary depending on several factors, including your dedication to training, your current skill level, and your natural ability. With consistent training and practice, you can start noticing improvements in your skills within a few weeks. Continued commitment and effort will lead to even greater progress over time.`,
    open: false 
  },
  { 
    question: `Can this program help me prepare for tryouts or competitive games?`, 
    answer: `Yes, American Football Skills and Training Mastery can help you prepare for tryouts and competitive games. The program is designed to improve your overall performance, including technical skills, physical conditioning, mental preparation, and strategic understanding of the game. By following the training sessions and incorporating the principles taught, you can enhance your chances of success in tryouts and perform at your best during competitive games.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>