<template>
  <Slider_new :name="name" >
    <template v-for="item in data">
      <CardForBusiness :item="item"/>
    </template>
    
  </Slider_new>
  
</template>

<script>
import Slider_new from '@/components/Slider_new.vue'
import CardForBusiness from '@/components/Card/CardForBusiness.vue'
export default {
  components: {
    Slider_new,
    CardForBusiness
  },
  props: {
    name: {
      type: String,
      default: () => {
        return null
      }
    },
    data: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>

<style scoped>
.item {
  min-width: calc((100%/4) - 16px);
}

@media screen and (max-width: 1200px) {
  .item {
    min-width: calc((100%/3) - 16px);
  
  }
}

@media screen and (max-width: 960px) {
  .item {
    min-width: calc((100%/2));
  }
}
</style>