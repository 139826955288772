<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Our brain is a complex and vital organ that requires care and attention to maintain its optimal function. This subliminal is designed to support brain healing and regeneration, helping you improve your brain health and enhance overall cognitive abilities."
    },
    {
      type: 'heading',
      text: "Here's how this program works to promote brain healing and regeneration:"
    },
    {
      type: 'list',
      items: [
        "Guided sessions stimulate cognitive function and activate neural connections.",
        "Promotes the production of new brain cells and enhances neuroplasticity.",
        "Supports the healing of brain injuries or trauma by optimizing brain function.",
        "Enhances memory, focus, and concentration abilities.",
        "Improves mental clarity, creativity, and problem-solving skills.",
        "Boosts overall brain performance and cognitive abilities.",
        "Provides relaxation and stress-reducing benefits, which contribute to brain health."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly engaging in these guided sessions, you can actively promote brain healing, improve cognitive function, and enhance your mental abilities. Remember, consistency and commitment to the program are crucial in experiencing the full benefits and achieving lasting positive changes in your brain health."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a journey to improve brain health and promote brain regeneration can be an exciting and transformative experience. This subliminal program is designed to support you in enhancing your mental abilities and overall brain performance. Before you begin, take a moment to consider the following preparation tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a peaceful environment: Find a quiet space where you can relax and focus on the guided sessions without distractions.",
        "Set clear intentions: Define your specific goals and objectives for improving brain health and boosting cognitive function.",
        "Engage in regular practice: Establish a consistent routine of listening to the guided sessions to maximize the benefits and stimulate neural connections.",
        "Embrace an open mindset: Approach the sessions with curiosity and a willingness to explore new ways of thinking and processing information."
      ]
    },
    {
      type: 'paragraph',
      text: "Throughout this subliminal journey, you may experience improvements in memory, concentration, creativity, and overall cognitive abilities. Take this opportunity to prioritize your brain health and embrace the potential for growth and regeneration. Get ready to stimulate your mental abilities and enhance your brain's performance."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Brain Healing and Regeneration guided sessions! By dedicating time to improve your brain health, you have taken a significant step towards enhancing your cognitive function and overall brain performance. It's time to reflect on your experience and continue nurturing your mind."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Journal your progress: Take note of any improvements or changes you experience in your mental abilities.",
        "Engage in brain-boosting activities: Solve puzzles, read, learn new skills, or engage in activities that challenge your mind.",
        "Prioritize self-care: Get sufficient sleep, eat a balanced diet, exercise regularly, and manage stress to support brain health.",
        "Stay mentally stimulated: Continue listening to brain-boosting content, explore new topics, and engage in meaningful conversations."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember that brain healing and regeneration is an ongoing process. Embrace the journey and incorporate the post-listening tips to maintain and further enhance your cognitive abilities. By investing in your brain health, you're investing in a brighter, more vibrant future. Keep up the excellent work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  {
    question: `How does the Brain Healing and Regeneration program work?`, 
    answer: `The Brain Healing and Regeneration program utilizes guided sessions that stimulate cognitive function and neural connections. It incorporates techniques such as brain entrainment, visualization, and positive affirmations to promote brain healing and regeneration. By regularly engaging in these guided sessions, you can enhance your mental abilities, improve brain health, and optimize overall brain performance.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Brain Healing and Regeneration program?`, 
    answer: `Results may vary depending on individual circumstances and commitment to the program. Some people may experience improvements in cognitive function, memory, and brain health relatively quickly, while others may require more time and consistent practice. Consistency is key, so we recommend engaging in the program regularly over an extended period for optimal results.`,
    open: false 
  },
  { 
    question: `Can the Brain Healing and Regeneration program help with specific brain-related conditions or injuries?`, 
    answer: `While the Brain Healing and Regeneration program is designed to support brain health and cognitive function, it is not a substitute for professional medical advice or treatment. It can be used as a complementary practice alongside any prescribed treatments or therapies for specific brain-related conditions or injuries. If you have any specific concerns, we recommend consulting with a healthcare professional.`,
    open: false 
  },
  { 
    question: `Is the Brain Healing and Regeneration program safe to use?`, 
    answer: `Yes, the Brain Healing and Regeneration program is safe to use. It uses proven techniques and practices that are generally well-tolerated by most individuals. However, if you have any pre-existing medical conditions or concerns, it is recommended to consult with a healthcare professional before starting the program.`,
    open: false 
  },
  { 
    question: `Can the Brain Healing and Regeneration program be used by people of all ages?`, 
    answer: `The Brain Healing and Regeneration program can be beneficial for people of various age groups. However, some sessions or exercises may be more suitable for certain age ranges. It is important to select the program or sessions that align with your specific needs and capabilities. If you have any concerns or questions, feel free to reach out to our support team for guidance on the most appropriate program for your age group.`,
    open: false 
  },
  { 
    question: `Is there any scientific evidence supporting the effectiveness of the Brain Healing and Regeneration program?`, 
    answer: `While individual experiences may vary, there is scientific research and evidence supporting the effectiveness of various techniques and practices employed in the Brain Healing and Regeneration program. These techniques include brain entrainment, visualization, and positive affirmations, which have demonstrated potential benefits in promoting brain health and cognitive function. For a comprehensive understanding, we recommend exploring relevant scientific literature and studies on these techniques.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>