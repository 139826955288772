import request from "@/utils/request";

export const getAllFavorites = (data) => {
  return request.post("api/favorites/user", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const getLikePlaylist = (data) => {
  return request.post("api/like/playlist", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const getLikeSubliminal = (data) => {
  return request.post("api/like/subliminal", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const addLikePlaylist = (data) => {
  return request.post("api/like/playlist/add", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const addLikeSubliminal = (data) => {
  return request.post("api/like/subliminal/add", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const deleteLikePlaylist = (data) => {
  return request.post("api/like/playlist/delete", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const deleteLikeSubliminal = (data) => {
  return request.post("api/like/subliminal/delete", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};
