<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Healing from Post Traumatic Stress Disorder (PTSD) can be a challenging journey, but this subliminal is designed to support you every step of the way. By targeting your subconscious mind, it helps you overcome the effects of trauma and reclaim a healthier and happier life."
    },
    {
      type: 'heading',
      text: 'Using this subliminal can help you:'
    },
    {
      type: 'list',
      items: [
        "Reduce the intensity and frequency of traumatic memories and flashbacks.",
        "Manage and alleviate symptoms of anxiety, depression, or hypervigilance.",
        "Promote a sense of safety, calm, and relaxation in your daily life.",
        "Increase self-confidence and self-esteem, restoring your sense of self-worth.",
        "Reconnect with positive emotions and improve your overall emotional well-being."
      ]
    },
    {
      type: 'paragraph',
      text: "Recovery from PTSD takes time, but with regular use of this subliminal, you can start to make significant progress. By rewiring your subconscious mind, you can begin to release the lingering effects of trauma and embrace a healthier and happier life. Remember to be patient and kind to yourself throughout the healing process."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Healing from Post Traumatic Stress Disorder (PTSD) is a courageous journey towards reclaiming your well-being. This subliminal is designed to support you in overcoming the effects of trauma and finding healing. Before you begin listening, consider the following tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a safe space: Find a comfortable and quiet environment free from distractions where you can listen to the subliminal without interruptions.",
        "Set your intention: Focus your mind on your desire for healing, resilience, and a healthier, happier life.",
        "Practice self-care: Engage in activities that nurture your physical, mental, and emotional well-being to create a foundation for healing.",
        "Be patient and compassionate: Healing takes time, so be gentle with yourself and acknowledge the progress you make along the way."
      ]
    },
    {
      type: 'paragraph',
      text: "As you embark on this healing journey, remember that you have the strength within you to overcome the effects of trauma and find a place of inner peace. As you listen to the subliminal affirmations, trust in the process and be open to the healing that is possible. Take this moment to prioritize your well-being and make the first step towards a healthier and happier life."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Heal Post Traumatic Stress Disorder (PTSD) session! By making the choice to address and heal from the effects of trauma, you have taken a significant step towards a healthier and happier life. It's important to take some time to reflect on your experience and continue your journey towards healing."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Self-reflection: Take the time to process your emotions and thoughts. Consider journaling or talking to someone you trust.",
        "Seek professional help: Reach out to a mental health professional who specializes in trauma if you feel the need for additional support.",
        "Practice self-care: Prioritize self-care activities that help you relax and recharge. This can include activities like meditation, exercise, art, or spending time in nature.",
        "Connect with others: Surround yourself with a supportive community or join a support group where you can share your experiences and find solace.",
        "Be patient with yourself: Healing from trauma takes time. Be kind to yourself and acknowledge that progress may not always be linear."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember that healing from PTSD is a journey, and everyone's path may be different. Embrace self-compassion and continue to prioritize your mental and emotional well-being. With time, support, and a willingness to heal, you can find renewed strength and inner peace. Keep moving forward!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Heal Post Traumatic Stress Disorder (PTSD) subliminal help me?`, 
    answer: `The Heal Post Traumatic Stress Disorder (PTSD) subliminal is designed to support your healing journey by addressing the underlying thoughts, emotions, and beliefs associated with trauma. It aims to promote a sense of safety, resilience, and inner strength while reducing anxiety, nightmares, and other symptoms commonly associated with PTSD. Listening to the subliminal regularly can help reprogram your subconscious mind and facilitate emotional healing.`,
    open: false
  },
  { 
    question: `How long will it take for the subliminal to work?`, 
    answer: `The healing process is unique to each individual and can vary in duration. Some people may experience positive changes within a few weeks, while others may require more time. It is important to be patient and consistent in listening to the subliminal for optimal results. Over time, you may notice a gradual reduction in PTSD symptoms and an increased sense of well-being.`,
    open: false 
  },
  { 
    question: `Is the Heal Post Traumatic Stress Disorder (PTSD) subliminal a replacement for therapy?`, 
    answer: `No, the Heal Post Traumatic Stress Disorder (PTSD) subliminal is not intended to replace therapy or professional medical help. It is a supplementary tool that can support your healing process. If you are experiencing severe PTSD symptoms or are in need of professional help, it is important to consult with a mental health professional who specializes in trauma.`,
    open: false 
  },
  { 
    question: `Can the subliminal help with all types of trauma?`, 
    answer: `The Heal Post Traumatic Stress Disorder (PTSD) subliminal is designed to address and support healing from various types of trauma. It aims to help rewire the subconscious mind to promote healing, resilience, and inner strength. While the subliminal can be beneficial for many individuals, the healing process may vary depending on the nature and severity of the trauma.`,
    open: false 
  },
  { 
    question: `Are there any known side effects of listening to the Heal Post Traumatic Stress Disorder (PTSD) subliminal?`, 
    answer: `No, there are no known side effects associated with listening to the Heal Post Traumatic Stress Disorder (PTSD) subliminal. However, if you have any concerns or questions, it is recommended to consult with a mental health professional. It is important to approach your healing journey with self-care, compassion, and support.`,
    open: false 
  },
  { 
    question: `Can I listen to the subliminal while doing other activities?`, 
    answer: `While you can listen to the Heal Post Traumatic Stress Disorder (PTSD) subliminal while doing other activities, it is recommended to create a dedicated and focused listening environment to maximize its effectiveness. Find a quiet and comfortable space where you can fully immerse yourself in the audio's healing messages and engage in self-reflection.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>