<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you've been struggling to manifest your goals and desires, this subliminal can help enhance your manifestation abilities and increase your willpower. By accessing the power of your subconscious mind, it can support you in aligning your thoughts, feelings, and actions with your desired manifestations."
    },
    {
      type: 'heading',
      text: 'Here are some key ways this subliminal can assist you:'
    },
    {
      type: 'list',
      items: [
        "Enhancing your belief in your ability to manifest your desires.",
        "Increasing your focus and concentration to visualize and attract what you want.",
        "Boosting your motivation and determination to take consistent action towards your goals.",
        "Reprogramming negative or limiting beliefs that may be blocking your manifestations.",
        "Amplifying your positive energy and vibrational frequency to align with your desires."
      ]
    },
    {
      type: 'paragraph',
      text: "With regular use of this subliminal, you can train your mind to strengthen your manifestation abilities and develop unwavering willpower. Remember, manifestation is a process that requires both inner alignment and inspired action, and this subliminal can support you on your journey to success and fulfillment."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Increasing your willpower and strengthening your manifestation abilities can help you achieve your goals and desires. Before you start listening to this subliminal, consider these strategies to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear intentions: Clearly define your goals and desires, and be specific about what you want to manifest.",
        "Visualize success: Imagine yourself already achieving your goals and experiencing the desired outcomes.",
        "Eliminate distractions: Find a quiet and comfortable space where you can focus solely on listening to the subliminal messages.",
        "Relax and open your mind: Take deep breaths, clear your mind, and be open to receiving the positive affirmations embedded in the subliminal audio."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating these preparation tips into your routine, you can optimize the effectiveness of this subliminal. As you listen regularly, you may notice an increase in your willpower and an enhanced ability to manifest your desires. Remember to stay focused, believe in your abilities, and embrace the power of your mind as you embark on this journey of manifestation and willpower enhancement."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Manifestation Willpower Enhancement subliminal! You've taken an important step towards boosting your manifestation abilities and strengthening your willpower to achieve your goals and desires. Now, it's time to reflect on your experience and continue harnessing your manifestation skills."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Visualize your goals: Spend a few minutes each day visualizing yourself achieving your desired outcomes.",
        "Take inspired action: Break down your goals into actionable steps and start taking consistent action towards them.",
        "Practice gratitude: Express gratitude for what you have and what you are manifesting, creating a positive mindset.",
        "Stay focused and positive: Surround yourself with positivity and affirmations to maintain the belief in your manifestation abilities."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, manifesting your desires is a process that requires patience, belief, and consistent effort. Stay aligned with your goals, take inspired action, and trust in the power of the universe to support you. You have the willpower to achieve anything you set your mind to. Keep manifesting and watch your desires come to fruition!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Manifestation Willpower Enhancement subliminal help me?`, 
    answer: `The Manifestation Willpower Enhancement subliminal is designed to reprogram your subconscious mind to align with your goals and desires, increasing your willpower and motivation to manifest them into reality. It works by replacing limiting beliefs and negative thought patterns with empowering affirmations, helping you overcome obstacles and stay focused on your path to success.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Manifestation Willpower Enhancement subliminal?`, 
    answer: `Results may vary depending on the individual and their specific goals. Some people may start experiencing changes in their willpower and motivation shortly after starting to listen to the subliminal, while others may need more time for the affirmations to make a profound impact on their subconscious mind. Consistency and regular listening are important for optimal results.`,
    open: false 
  },
  { 
    question: `Can the Manifestation Willpower Enhancement subliminal help me achieve any goal?`, 
    answer: `Yes, the Manifestation Willpower Enhancement subliminal can be used to enhance willpower and motivation for any goal or desire you may have. It is designed to strengthen your belief in your abilities and align your mindset with success. Whether your goal is related to career, relationships, health, or any other aspect of life, the subliminal can support you in manifesting your desires.`,
    open: false 
  },
  { 
    question: `How can I make the Manifestation Willpower Enhancement subliminal more effective?`, 
    answer: `To enhance the effectiveness of the subliminal, it is beneficial to create a clear vision of your goals and actively visualize and affirm them while listening to the audio. Additionally, maintaining a positive mindset, practicing gratitude, and taking inspired action towards your goals can strengthen the impact of the affirmations on your subconscious mind. Consistent and dedicated listening is also key to maximizing the benefits of the subliminal.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Manifestation Willpower Enhancement subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, it is important to note that the subliminal is a tool to support your manifestation journey and enhance your willpower. It should be used alongside practical actions and strategies to achieve your goals.`,
    open: false 
  },
  { 
    question: `Can the Manifestation Willpower Enhancement subliminal guarantee results?`, 
    answer: `While the Manifestation Willpower Enhancement subliminal is designed to assist and support you in your manifestation journey, the results ultimately depend on various factors, including your mindset, actions, and alignment with your goals. The subliminal can be a powerful tool to enhance your willpower, but success also requires personal effort, dedication, and consistency in taking inspired action towards your desires.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>