<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "This subliminal is specifically designed to empower individuals with disabilities to build confidence and self-esteem. It aims to support them in overcoming challenges, embracing their unique abilities, and living a fulfilling life."
    },
    {
      type: 'heading',
      text: 'Key benefits of using this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Boosting self-confidence to embrace one's disabilities and see them as strengths.",
        "Reducing self-doubt and negative self-talk, fostering a positive mindset.",
        "Encouraging self-acceptance and self-love, promoting a sense of worthiness and belonging.",
        "Building resilience and the ability to overcome obstacles with determination and perseverance.",
        "Inspiring individuals to pursue their passions, dreams, and goals despite any limitations."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, individuals with disabilities can reprogram their subconscious minds to believe in themselves and their abilities. It can help them overcome self-limiting beliefs and social stigmas, empowering them to lead a confident and fulfilling life. Remember, building confidence is a journey, and consistent use of this subliminal is key to achieving lasting results."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Building confidence and self-esteem is essential for individuals with disabilities to overcome challenges and live a fulfilling life. This subliminal is designed to support and empower you on your journey. Before you start listening, here are some strategies to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Focus on the specific areas of your life where you want to build confidence and self-esteem.",
        "Affirm yourself: Repeat positive affirmations that reinforce your worthiness, abilities, and potential.",
        "Celebrate your accomplishments: Take time to acknowledge and appreciate the progress you've already made.",
        "Embrace self-care: Prioritize activities that nourish your mind, body, and soul, promoting overall well-being.",
        "Stay present: Focus on the present moment, letting go of any worries or doubts, and allowing the subliminal messages to penetrate your subconscious mind."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal and practicing these preparation tips, you can develop a stronger sense of confidence and self-esteem. Believe in your abilities, celebrate your uniqueness, and know that you are capable of achieving great things. Embrace this journey of empowerment, and let your confidence shine."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Confidence for Person with Disabilities subliminal session! By focusing on building confidence and self-esteem, you've taken an important step towards overcoming challenges and living a fulfilling life. Now, it's time to reflect on your experience and continue your personal growth and transformation."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your achievements: Take note of your successes, big or small, and celebrate your progress.",
        "Practice self-care: Engage in activities that nurture your mind, body, and soul.",
        "Surround yourself with positivity: Spend time with people who uplift and support you.",
        "Set realistic goals: Break down your aspirations into achievable steps and work towards them.",
        "Embrace self-compassion: Be kind and patient with yourself as you navigate challenges."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, building confidence is a lifelong journey, and you have already taken a powerful step forward. Keep believing in yourself, embracing your uniqueness, and pursuing your dreams. With confidence, perseverance, and self-acceptance, you can overcome any obstacle and live a fulfilling life. You are capable and deserving of success. Keep shining!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Confidence for Person with Disabilities subliminal help me?`, 
    answer: `The Confidence for Person with Disabilities subliminal is designed to empower individuals with disabilities by promoting self-confidence and self-esteem. It uses positive affirmations to reprogram your subconscious mind, helping you overcome challenges, embrace your strengths, and live a fulfilling life. By listening to the subliminal regularly, you can cultivate a positive mindset and outlook that supports your personal growth and success.`,
    open: false
  },
  { 
    question: `Will the Confidence for Person with Disabilities subliminal make my disability disappear?`, 
    answer: `No, the subliminal is not meant to cure or eliminate disabilities. Its purpose is to help individuals with disabilities build confidence, self-esteem, and a positive mindset, allowing them to navigate life with greater resilience, determination, and self-acceptance. The subliminal can empower you to embrace your abilities, overcome challenges, and make the most of your unique experiences and talents.`,
    open: false 
  },
  { 
    question: `How long will it take to see results from the Confidence for Person with Disabilities subliminal?`, 
    answer: `Results may vary from person to person, and it may take time and consistent listening to experience significant changes in confidence and self-esteem. The subliminal messages work on a subconscious level, gradually shaping your thoughts and beliefs to support your personal growth. Consistently listening to the subliminal over a period of time can help you strengthen your confidence and self-esteem.`,
    open: false 
  },
  { 
    question: `Can the Confidence for Person with Disabilities subliminal be used alongside therapy or other support?`, 
    answer: `Yes, the Confidence for Person with Disabilities subliminal can be used alongside therapy or other support systems. It is important to consult with your healthcare provider or therapist to determine the best approach for your individual needs. The subliminal can complement and enhance your existing therapy or support plan by reinforcing positive beliefs and attitudes.`,
    open: false 
  },
  { 
    question: `Is there any risk or harm in listening to the Confidence for Person with Disabilities subliminal?`, 
    answer: `No, there are no known risks or harm associated with listening to the Confidence for Person with Disabilities subliminal. However, if you have any concerns or are experiencing distress, it is important to seek support from a qualified professional. The subliminal is designed to promote positive thinking, confidence, and self-esteem, but personal circumstances and individual responses may vary.`,
    open: false 
  },
  { 
    question: `Can the Confidence for Person with Disabilities subliminal be customized for specific disabilities?`, 
    answer: `The Confidence for Person with Disabilities subliminal is not specific to any particular disability. However, its positive affirmations and empowering messages can be beneficial for individuals with different disabilities. The subliminal aims to cultivate a positive mindset, build confidence, and embrace one's uniqueness, which can be applied to various disabilities and personal situations.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>