<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Jealousy can be a destructive emotion that negatively impacts relationships and your own well-being. This subliminal is designed to help you overcome jealousy and develop healthy, trusting relationships."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Learning to let go of jealousy and embrace peace and happiness in your relationships.",
        "Developing trust in yourself and others, allowing for stronger and healthier connections.",
        "Gaining perspective and understanding to recognize and address the underlying causes of jealousy.",
        "Improving self-esteem and self-confidence, reducing the need for comparison and jealousy.",
        "Cultivating open communication and effective conflict resolution skills within relationships."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind and replace jealousy with trust, peace, and happiness. It takes time and effort to overcome jealousy, but with consistency and this subliminal, you can create healthier relationships and a more fulfilled life."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Jealousy can be a difficult emotion to navigate, but overcoming it is possible with the right mindset. This subliminal is designed to help you let go of jealousy, learn to trust, and cultivate healthy relationships. Before you start listening, here are some suggestions to prepare yourself:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your jealousy: Recognize and accept that jealousy is a natural emotion, but it doesn't have to define your relationships.",
        "Identify triggers: Reflect on the situations or thoughts that typically trigger your jealousy and be mindful of them.",
        "Practice self-compassion: Be kind to yourself and remind yourself that your worth is not determined by others.",
        "Focus on building trust: Visualize yourself having healthy, trusting relationships and remind yourself that trust can be rebuilt.",
        "Hold positive intentions: Keep an open mind and a positive attitude while listening to the subliminal affirmations, allowing them to influence your subconscious mind."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you may start to feel a shift in your mindset and develop a greater sense of peace and happiness. Remember, change takes time, so be patient and kind to yourself throughout this journey. Embrace the opportunity to let go of jealousy and create healthy, fulfilling relationships."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Get over Jealousy subliminal! By dedicating yourself to overcome jealousy and cultivate trust in your relationships, you've embarked on a journey towards inner peace and happiness. Now, take a moment to reflect and continue your personal growth."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your emotions: Consider journaling about your thoughts and feelings related to jealousy and trust.",
        "Practice self-compassion: Be gentle with yourself as you navigate this journey of healing and personal development.",
        "Challenge negative thoughts: Counteract jealous thoughts with positive affirmations and rational thinking.",
        "Communicate with your partner or loved ones: Openly express your concerns and work together to build trust."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming jealousy is a process that requires patience and self-reflection. Embrace the changes and growth as you let go of jealousy and welcome peace and happiness into your life. Trust yourself and your relationships, and believe that you can create healthy, loving connections. You've got this!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Get over Jealousy subliminal help me?`, 
    answer: `The Get over Jealousy subliminal is designed to help you overcome jealousy and develop trust in yourself and your relationships. By listening to the subliminal messages, you can reprogram your subconscious mind to let go of jealousy and embrace peace and happiness. Over time, you may notice a decrease in jealous thoughts and behaviors, and an increase in feelings of security, trust, and emotional well-being.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Get over Jealousy subliminal?`, 
    answer: `The time it takes to see results from the Get over Jealousy subliminal can vary from person to person. Some individuals may notice positive changes in their mindset and behavior immediately, while others may require more time and consistency in listening to the subliminal. We recommend listening to the subliminal daily and being patient with the process as it works to rewire your subconscious patterns.`,
    open: false 
  },
  { 
    question: `Can the Get over Jealousy subliminal completely eliminate jealousy?`, 
    answer: `The Get over Jealousy subliminal is designed to help you overcome jealousy and develop healthier patterns of thinking and behavior. However, it is important to note that jealousy is a complex emotion influenced by various factors. The subliminal can provide support in letting go of jealousy, but it may be necessary to address deeper underlying issues, such as insecurity or past traumas, through therapy or professional support.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Get over Jealousy subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Get over Jealousy subliminal. However, if you have any concerns about your emotional well-being or mental health, it is advisable to seek guidance from a qualified professional.`,
    open: false 
  },
  { 
    question: `How can I make the Get over Jealousy subliminal more effective?`, 
    answer: `To enhance the effectiveness of the Get over Jealousy subliminal, you can combine it with other self-help practices such as self-reflection, journaling, and therapy. Developing a greater understanding of the underlying causes of jealousy and working on improving your self-esteem and communication skills can also contribute to your progress in overcoming jealousy.`,
    open: false 
  },
  { 
    question: `Can the Get over Jealousy subliminal help with trust issues in relationships?`, 
    answer: `Yes, the Get over Jealousy subliminal can help you address and overcome trust issues in relationships. By reprogramming your subconscious mind to let go of jealousy and develop trust, you may find that your relationships become healthier and more fulfilling. However, it is important to remember that building trust in relationships is a multifaceted process and may require additional effort and open communication with your partner.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>