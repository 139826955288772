<template>
  <div class="pa-5" style="background: #BBE3FF; border-radius: 5px;">
    <h3>Influencer Dashboard</h3>
    <v-row class="mx-3 my-0">
      <v-col cols="12" md="8" lg="8">
        <div style="background: #BBE3FF;">
          <div class="d-flex align-center justify-space-between">
            <v-text-field solo dense class="searchs" placeholder="Search for Promo Code" v-model="search"></v-text-field>
            <el-date-picker
              v-model="value2"
              type="month"
              class="date_pickers"
              placeholder="Pick a month"
            />
          </div>
          <div class="contents">
            <div class="headers">
              <v-row class="">
                <v-col cols="4" md="4" lg="4">
                  <h6>User</h6>
                </v-col>
                <v-col cols="4" md="4" lg="4">
                  <h6>Discount Value</h6>
                </v-col>
                <v-col cols="4" md="4" lg="4">
                  <h6>Code</h6>
                </v-col>
              </v-row>
            </div>
            <div class="px-1 items" v-if="list_of_users.length != 0">
              <v-row v-for="items, index in list_of_users" :key="index"
                class="d-flex justify-center text-center">
                  <v-col cols="4" class="pl-5">{{ items.name }}</v-col>
                  <v-col cols="4">{{ items.discount }}</v-col>
                  <v-col cols="4">{{ items.promo_code }}</v-col>
              </v-row>
            </div>
            <div v-else class="mx-auto text-center">
              <h5 class="mt-5">No data found</h5>
              <img
                class="empty1 mt-2"
                src="@/assets/images/user/dashboard/4.png"
                alt=""
              />
            </div>

            <v-divider style="background: gray;"></v-divider>
            <h5 style="text-align: center; margin-bottom: 10px;">Total {{table_commission}}</h5>

          </div>
        </div>
      </v-col>
      <v-col cols="12" md="4" lg="4">
        <div>
          <div class="cashout">
            <div class="d-flex pa-4">
              <div>
                <img class="cashout_img" src="@/assets/images/user/dashboard/3.png" alt="">
              </div>
              <div style="padding-left: 20px; padding-right: 5px;">
                <h2>{{total_commission}}.00</h2>
                <p>Current Accumulated Commission</p>
                <p>Cashout Minimum of 5000.00</p>
                <button class="button btn btn-primary btn-lg">Cashout</button>
              </div>
            </div>
          </div>
          <div class="coupons">
            <div class="d-flex pa-4">
              <div>
                <img class="coupons_img" src="@/assets/images/user/dashboard/1.png" alt="">
              </div>
              <div>
                <h3 style="text-align: center; color: white; margin-top: 17px;">{{total_coupons}} users availed your Coupons!</h3>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getMessage } from "@/utils";
import { getDashboard } from "@/api/user";
import Card from "@/components/Card.vue";
import Upload from "@/components/Upload.vue";
import { formatMonth } from '@/utils'

export default {
  components: {
    Card,
    Upload,
  },
  data: () => {
    return {
      formatMonth,
      getMessage,
      getDashboard,
      data: [],
      list_of_promo: [],
      list_of_users: [],
      total_coupons: 0,
      table_commission: 0,
      total_commission: 0,
      value2: '',
      search: '',
      date: ''
    };
  },
  computed: {
    user() {
      return JSON.parse(this.$store.state.user.info);
    },
  },
  watch: {
    value2(val) {
      if(val != null){
        this.date = (this.formatMonth(val));
      }else{
        this.date = ''
      }
      this.getData();
    },
    search(val) {
      this.search = val;
      this.getData();
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      const data = {
        user_id: this.user.user_id,
        name: this.search,
        date: this.date,
      }
      this.getDashboard(data).then((res) => {
        this.data = res.data.data
        this.total_coupons = (res.data.total_coupons)
        this.list_of_promo = (res.data.list_of_promo)
        this.total_commission = (res.data.total_commission)
        this.table_commission = (res.data.table_commission)
        this.list_of_users = (res.data.list_of_users)
      })
    },
  }
};
</script>

<style scoped>
  h3 {
    padding: 0 12px;
  }
  h2 {
    padding: 5px 0;
  }
  h1 {
    text-align: center;
  }
  .searchs {
    margin-right: 100px;
  }
  .date_pickers {
    margin-top: -25px;
  }
  .contents {
    border-radius: 5px;
    background: white;
    max-height: 50vh;
    overflow-y: hidden;
  }
  .headers {
    background: #439CD4;
    border-radius: 5px;
  }
  .items {
    max-height: 32vh;
    overflow: scroll;
  }
  .empty1 {
    height: 100px;
    margin-left: auto;
    margin-right: auto;
  }
  h6{
    font-family: "Montserrat-Bold";
    text-align: center;
    color: white;
    margin-top: 10px;
  }
  p{
    font-family: "Montserrat-Regular";
    margin: 0px;
  }
  p:nth-of-type(1){
    font-size: 10px;
  }
  p:nth-of-type(2){
    font-size: 8px;
    margin-bottom: 10px;
  }
  .cashout {
    background: white;
    margin: 10px;
    border-radius: 5px;
    margin-top: 70px;
    min-height: 170px;
    max-height: 200px;
    overflow-y: scroll;
  }
  .coupons {
    background: #F32B7F;
    margin: 10px;
    max-height: 200px;
    min-height: 170px;
    overflow-y: scroll;
    border-radius: 5px;
  }
  .div1 {
    background: url("~@/assets/images/user/dashboard/1.png"), white;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
    border-radius: 10px;
    margin: 10px;
  }
  .div2 {
    background: url("~@/assets/images/user/dashboard/2.png"), white;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 5% 20%;
    border-radius: 10px;
    margin: 10px;
  }
  .div3 {
    background: white;
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
  }
  .div4 {
    background: url("~@/assets/images/user/dashboard/3.png"), white;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
    border-radius: 10px;
    margin: 10px;
  }
  .div5 {
    background: url("~@/assets/images/user/dashboard/3.png"), white;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
    height: 230px; 
    overflow-y: hidden;
    width: 40%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .wrappers {
    width: 50%;
    margin-right: 0px;
    margin-left: auto;
  }
  .wrappers1 {
    width: 60%;
    margin-right: 0px;
    margin-left: auto;
  }
  .wrappers2 {
    width: 50%;
    margin-right: 0px;
    margin-left: auto;
    padding: 90px;
    overflow-y: hidden;
  }
  .cashout_img {
    width: clamp(4.01rem, calc(4.25rem + 2.63vw), 2.9rem);
  }
  .coupons_img {
    width: clamp(8.01rem, calc(4.95rem + 2.63vw), 2.9rem);
  }

</style>