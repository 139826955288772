<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Having strong decision-making skills, beliefs, and convictions is crucial for living a confident and fulfilling life. This subliminal is designed to help you develop and strengthen these essential qualities."
    },
    {
      type: 'heading',
      text: 'Benefits of using this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Enhancing your ability to make solid and informed choices.",
        "Building confidence in your decision-making skills.",
        "Strengthening your beliefs and values.",
        "Increasing conviction in your choices and actions.",
        "Improving your self-trust and reducing self-doubt.",
        "Empowering you to take ownership of your decisions and live in alignment with your values."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to support and reinforce solid decision-making, strong beliefs, and unwavering convictions. As your mindset shifts, you may find yourself making choices with confidence and clarity, ultimately leading to a more fulfilling and empowered life. Remember, the more consistently you use it, the greater the potential for lasting change."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Making solid decisions and staying true to your beliefs and convictions can bring immense value and satisfaction to your life. This subliminal is designed to support and enhance your decision-making skills. Before pressing play, consider the following tips to maximize your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your values: Take a moment to identify and clarify your core values, as they will guide you in making decisions that align with your principles.",
        "Boost self-confidence: Practice self-affirmations and visualize yourself making assertive decisions with belief and conviction.",
        "Create a decision-making framework: Establish a systematic approach to weighing options, considering pros and cons, and evaluating potential outcomes before making a final decision.",
        "Maintain focus: While listening to the subliminal affirmations, keep your desired outcomes in mind and embrace the transformative power of your subconscious mind."
      ]
    },
    {
      type: 'paragraph',
      text: "Embrace the opportunity to strengthen your decision-making abilities and nourish your belief system. Regular listening to this subliminal can empower you to make solid choices and live a more confident and fulfilling life. Take a moment to center yourself, reaffirm your values, and let's embark on this transformative journey together."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Solid Decision Belief and Conviction subliminal! By working on strengthening your decision-making skills, beliefs, and convictions, you're taking significant steps towards living a more confident and fulfilling life. Now, it's time to reflect on your experience and continue your growth and transformation."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your decision-making process: Take some time to think about how your decision-making has evolved and improved since listening to the subliminal.",
        "Cultivate self-belief: Practice positive affirmations and surround yourself with supportive people who encourage your beliefs and convictions.",
        "Gain clarity on your values: Reflect on your core values and ensure they align with the decisions you make.",
        "Embrace opportunities for growth: Be open to new experiences and challenges that can further strengthen your decision-making skills and beliefs."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, solid decision-making skills are developed over time with practice and self-reflection. Embrace the process, stay true to your convictions, and trust in your ability to make confident choices. Your future is shaped by the decisions you make, so continue to grow, evolve, and live a fulfilling life!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Solid Decision Belief and Conviction subliminal help improve my decision-making skills?`, 
    answer: `The Solid Decision Belief and Conviction subliminal uses affirmations and positive suggestions to influence your subconscious mind and strengthen your decision-making skills. By regularly listening to the subliminal audio, you can reprogram your mindset and develop a stronger sense of belief and conviction in your decisions. Over time, this can lead to more confident and fulfilling choices in your life.`,
    open: false
  },
  { 
    question: `How long does it take to see improvements in decision-making skills with the Solid Decision Belief and Conviction subliminal?`, 
    answer: `The time it takes to see improvements in decision-making skills can vary from person to person. It depends on factors such as your individual mindset, willingness to change, and consistency in listening to the subliminal. We recommend regularly listening to the audio for at least a few weeks to allow the positive affirmations to take effect and create long-lasting changes in your decision-making processes.`,
    open: false 
  },
  { 
    question: `Can the Solid Decision Belief and Conviction subliminal help me overcome indecisiveness?`, 
    answer: `Yes, the Solid Decision Belief and Conviction subliminal is designed to help individuals overcome indecisiveness by strengthening their belief and conviction in their choices. Through the use of positive affirmations, the subliminal works to reprogram your subconscious mind and instill a sense of confidence in decision-making. However, it is important to note that individual results may vary, and it is always beneficial to seek professional assistance if indecisiveness significantly affects your daily life.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the Solid Decision Belief and Conviction subliminal?`, 
    answer: `There are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your decision-making abilities or mental well-being, it is advisable to consult with a qualified professional. The subliminal is designed to be a tool to support and enhance your decision-making skills, not replace important professional guidance when needed.`,
    open: false 
  },
  { 
    question: `Can the Solid Decision Belief and Conviction subliminal guarantee that I will always make the right decisions?`, 
    answer: `No, the Solid Decision Belief and Conviction subliminal cannot guarantee that you will always make the right decisions. It is designed to improve your decision-making skills by strengthening your belief and conviction in your choices. Ultimately, the ability to make the right decisions depends on various factors such as information, circumstances, and individual judgment. However, the subliminal can provide you with the mental tools and confidence to make more solid choices in your life.`,
    open: false 
  },
  { 
    question: `How can I maximize the effectiveness of the Solid Decision Belief and Conviction subliminal?`, 
    answer: `To maximize the effectiveness of the Solid Decision Belief and Conviction subliminal, we recommend listening to the audio consistently and in a relaxed state of mind. Find a quiet and comfortable space where you can focus on the affirmations without distractions. Additionally, reflecting on your decision-making processes, seeking self-improvement resources, and applying the lessons learned from the audio in your daily life can further enhance its effectiveness.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>