<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you struggle to find the motivation and energy to tackle your housework, this subliminal can help you feel more inspired and efficient in getting your chores done."
    },
    {
      type: 'heading',
      text: 'Here are some benefits of using this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Increasing your motivation to start and complete housework tasks.",
        "Boosting your energy levels to tackle larger and more challenging chores.",
        "Improving your focus and productivity while cleaning and organizing your home.",
        "Helping you develop a positive and proactive attitude towards housework.",
        "Reducing feelings of overwhelm or boredom associated with cleaning tasks."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can train your subconscious mind to associate housework with motivation, energy, and efficiency. As a result, you may find that completing your chores becomes easier, more enjoyable, and less of a burden. Remember, consistency is key to achieving lasting results."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Finding the motivation and energy to tackle housework can be challenging, but this subliminal can help you get in the right mindset. Before you start listening, try these tips to enhance your housework productivity:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set a clear intention: Focus on your goals and the benefits of completing your housework, such as having a clean and organized space.",
        "Break it down: Divide your tasks into smaller, more manageable chunks to make them feel less overwhelming.",
        "Create a motivating environment: Play uplifting music or a podcast to keep you engaged and energized while doing your chores.",
        "Use visual cues: Hang pictures or quotes that inspire and remind you of the satisfaction and sense of accomplishment that comes with completing your housework."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal, it will help reprogram your subconscious mind and boost your motivation towards housework. Embrace the positive affirmations and allow them to influence your thoughts and actions. With increased motivation and focus, you can tackle your housework with ease and efficiency. Now, let's get ready to create a clean and inviting space for yourself!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Housework Motivation subliminal! By listening to this session, you've taken a positive step towards becoming more motivated and efficient in your housework tasks. Now, it's time to reflect on your experience and continue your progress."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Set achievable goals: Break down your housework tasks into smaller, manageable goals to keep yourself motivated.",
        "Create a cleaning schedule: Plan out specific cleaning tasks for each day or week to maintain a clean and organized home.",
        "Reward yourself: Give yourself small rewards or treats after completing housework tasks to boost motivation.",
        "Make it enjoyable: Listen to music or podcasts while doing housework to make it a more enjoyable and engaging experience."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, motivation and efficiency in housework require consistency and a positive mindset. Celebrate your progress and stay committed to creating a cleaner and more organized living space. Enjoy the benefits of a clean home and the sense of accomplishment that comes with completing your housework tasks. You're doing great!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Housework Motivation subliminal help me?`, 
    answer: `The Housework Motivation subliminal is designed to program your subconscious mind with positive affirmations that boost motivation and energy levels when it comes to housework. By listening to the subliminal regularly, you may notice an increase in your desire to tackle household tasks, as well as improved focus and efficiency. It can help shift your mindset towards viewing housework as a rewarding and fulfilling activity.`,
    open: false
  },
  { 
    question: `How long do the effects of the Housework Motivation subliminal last?`, 
    answer: `The duration of the effects may vary from person to person. For some, the effects may be felt immediately and last throughout the day, providing a sustained level of motivation. For others, consistent listening may be needed to reinforce the positive affirmations and maintain long-term motivation. We recommend incorporating the subliminal into your daily routine to maximize its impact.`,
    open: false 
  },
  { 
    question: `Can the Housework Motivation subliminal help with procrastination?`, 
    answer: `Yes, the Housework Motivation subliminal can be helpful for individuals who struggle with procrastination when it comes to housework. The subliminal messages work to reprogram your subconscious mind, promoting focus, productivity, and a positive attitude towards completing household tasks. Combined with practical strategies such as breaking tasks into smaller, manageable steps, it can be an effective tool for overcoming procrastination.`,
    open: false 
  },
  { 
    question: `Is it necessary to listen to the Housework Motivation subliminal every day?`, 
    answer: `Consistency is key when it comes to subliminal messaging. Listening to the Housework Motivation subliminal daily can help reinforce the positive affirmations and maximize its effectiveness. However, you can adapt the frequency to your personal needs and schedule. Find a routine that works for you and stick to it to experience the best results.`,
    open: false 
  },
  { 
    question: `Will the Housework Motivation subliminal make me enjoy housework?`, 
    answer: `The Housework Motivation subliminal aims to enhance your motivation, energy, and efficiency when it comes to housework. While it can help shift your mindset towards finding fulfillment in completing household tasks, it cannot guarantee that you will suddenly love doing housework. However, by boosting your motivation, you may find it easier and more enjoyable to tackle your chores.`,
    open: false 
  },
  { 
    question: `How can I make the Housework Motivation subliminal more effective?`, 
    answer: `To enhance the effectiveness of the Housework Motivation subliminal, try setting specific goals for your housework, breaking tasks into manageable steps, and creating a pleasant environment to work in. Additionally, practicing gratitude and focusing on the positive aspects of maintaining a clean and organized space can help reinforce motivation and make the subliminal more effective.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>