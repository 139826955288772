<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're searching for ways to bring more happiness and fulfillment into your life, look no further. This subliminal is designed to help you unlock the secrets to a happier and more fulfilling life, starting now!"
    },
    {
      type: 'heading',
      text: 'Here are some areas where this subliminal can make a difference:'
    },
    {
      type: 'list',
      items: [
        "Enhancing your ability to find joy and gratitude in everyday moments.",
        "Building healthier relationships and improving your communication skills.",
        "Reducing stress, anxiety, and negative thought patterns that can hinder your happiness.",
        "Increasing self-confidence and self-esteem, allowing you to embrace life's challenges with a positive mindset.",
        "Cultivating a positive and resilient mindset, enabling you to bounce back from setbacks and maintain a hopeful outlook.",
        "Discovering and pursuing your passions and purpose, leading to a more fulfilling life."
      ]
    },
    {
      type: 'paragraph',
      text: "With regular use, this subliminal can help you reprogram your subconscious mind to focus on positivity, gratitude, and personal growth. As you embrace the teachings of this subliminal and take inspired actions, you may experience a profound shift towards a happier and more fulfilling life. Remember, happiness is a journey, and you have the power to create it for yourself!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a journey to a happier and more fulfilling life is an exciting endeavor. This subliminal is designed to help you unlock the secrets to a happier life, starting now. Before you start listening, consider implementing these strategies to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Clarify what a happier life means to you and what specific changes or improvements you want to make.",
        "Practice gratitude: Start cultivating a gratitude practice by acknowledging and appreciating the positive aspects of your life.",
        "Visualize your desired life: Take a moment to imagine yourself living the life you desire, feeling all the positive emotions it brings.",
        "Focus on the present moment: Stay present during the subliminal listening session, allowing the affirmations to work on your subconscious mind."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is a powerful tool that can help shift your mindset, release negative beliefs, and attract positivity into your life. Embrace this opportunity to create lasting change and experience the happiness and fulfillment you deserve. Get ready to embark on a journey towards a happier life, starting now!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congrats on completing the Happier Life Now subliminal session! You've taken a big step towards unlocking the secrets to a happier and more fulfilling life. Now, it's time to reflect on your experience and embrace the positive changes you can make going forward."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Gratitude practice: Take a moment each day to write down things you're grateful for.",
        "Embrace positivity: Surround yourself with uplifting and positive influences.",
        "Self-care routine: Prioritize activities that promote self-care and enhance your well-being.",
        "Set meaningful goals: Identify what brings you joy and set goals to help you achieve it."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, happiness is an ongoing journey, and you have the power to make positive changes each day. Embrace the happiness within you and let it shine brightly. Your happier and more fulfilling life starts right now!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  {
    question: `How can the Happier Life Now subliminal help me?`,
    answer: `The Happier Life Now subliminal is designed to help you unlock the secrets to a happier and more fulfilling life. By listening to the subliminal messages regularly, you can train your subconscious mind to embrace positive thinking, gratitude, self-love, and other empowering beliefs and attitudes. Over time, you may notice improvements in your overall mood, relationships, and general outlook on life.`,
    open: false
  },
  {
    question: `How long does it take to see results from the Happier Life Now subliminal?`,
    answer: `Results may vary from person to person, but consistent and regular listening can yield positive effects over time. Some individuals may start noticing subtle shifts in their mindset and daily experiences within a few weeks, while others may require more time. It is recommended to incorporate the subliminal into your daily routine for optimal results.`,
    open: false
  },
  {
    question: `Can the Happier Life Now subliminal replace therapy or professional help?`,
    answer: `No, the Happier Life Now subliminal is not a substitute for therapy or professional help. It is designed to complement personal development and self-improvement efforts. If you are experiencing mental health concerns or require professional support, it is important to consult with a qualified healthcare provider or therapist.`,
    open: false
  },
  {
    question: `How can I maximize the effectiveness of the Happier Life Now subliminal?`,
    answer: `To maximize the effectiveness of the Happier Life Now subliminal, it is recommended to create a routine that includes daily listening to the audio. You can also enhance the subliminal's impact by practicing gratitude, mindfulness, and self-reflection in your daily life. Surrounding yourself with positive influences, engaging in activities you enjoy, and seeking personal growth opportunities can also contribute to a happier life overall.`,
    open: false
  },
  {
    question: `Are there any risks or side effects of listening to the Happier Life Now subliminal?`,
    answer: `No, there are no known risks or side effects associated with listening to the Happier Life Now subliminal audio. However, if you have any concerns about your mental health or well-being, it is always advisable to seek professional advice from a healthcare provider or therapist.`,
    open: false
  },
  {
    question: `Will I need to listen to the Happier Life Now subliminal forever to maintain a happier life?`,
    answer: `While regular and consistent listening can help reinforce positive beliefs and attitudes, the ultimate goal of the Happier Life Now subliminal is to help you develop a long-lasting positive mindset and habits. With time, the positive changes may become more ingrained, reducing the need for continued daily listening. However, it can still be beneficial to revisit the subliminal periodically as a reminder and reinforcement of the principles for a happier life.`,
    open: false
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>