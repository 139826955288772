<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Do you want to achieve success in all areas of your life? This subliminal is designed to help you develop the mindset and skills needed to thrive and reach your full potential."
    },
    {
      type: 'heading',
      text: 'By using this subliminal, you can:'
    },
    {
      type: 'list',
      items: [
        "Enhance your self-confidence and belief in your abilities.",
        "Increase your motivation and drive to pursue your goals.",
        "Improve your focus and productivity to get things done efficiently.",
        "Develop effective communication and relationship-building skills.",
        "Overcome limiting beliefs and self-doubt that may hold you back.",
        "Cultivate a positive mindset and resilience to face challenges with confidence.",
        "Take consistent action towards your personal growth and success."
      ]
    },
    {
      type: 'paragraph',
      text: "Whether you're aiming for career success, fulfilling relationships, personal growth, or overall happiness, this subliminal can support you on your journey. As you listen regularly, you may notice a shift in your mindset, behaviors, and the opportunities that come your way. Remember, success is a journey, and with the right mindset, you can achieve greatness."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a journey towards overall life success can be exciting yet challenging. This subliminal is designed to support and enhance your personal growth, career, relationships, and more. Before pressing play, consider the following tips to make the most out of this experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your goals: Take a moment to reflect on what success means to you in different areas of your life. Write down specific and measurable goals.",
        "Embrace a growth mindset: Believe in your ability to learn, improve, and adapt to achieve your goals. Embrace challenges as opportunities for growth.",
        "Create an action plan: Break down your goals into actionable steps. Identify the skills you need to develop and the resources you can utilize.",
        "Visualize your success: Imagine yourself already achieving your goals, feeling confident, and living a fulfilling life.",
        "Stay committed: Make a commitment to listen to the subliminal regularly and incorporate the messages into your daily life."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is a powerful tool to align your subconscious mind with your aspirations for success. By actively engaging with the affirmations and taking intentional steps towards your goals, you can manifest positive changes in your life. Now, take a moment to set your intention, embrace the possibilities, and let's begin this transformative journey towards overall life success."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the General Life Success subliminal! By investing in your personal growth and development, you've taken a significant step towards achieving success in all areas of your life. Now, it's important to reflect on your experience and continue your journey towards reaching your full potential."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Identify what success means to you in different areas of your life.",
        "Create an action plan: Break down your goals into smaller, achievable steps.",
        "Develop positive habits: Cultivate routines that align with your goals and support your growth.",
        "Continue learning and self-improvement: Seek opportunities to expand your knowledge and skills.",
        "Stay focused and motivated: Consistently remind yourself of your why and stay committed to your goals."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, success is a journey, not a destination. Embrace the challenges and setbacks along the way, as they are opportunities for growth. With the right mindset, determination, and consistent effort, you have everything you need to achieve success in all areas of your life. Keep pushing forward!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the General Life Success subliminal help me achieve success in all areas of my life?`, 
    answer: `The General Life Success subliminal is designed to reprogram your subconscious mind with positive beliefs, attitudes, and habits that are conducive to success. By regularly listening to the subliminal, you can develop a growth mindset, increase self-confidence, enhance motivation, and cultivate a positive outlook on life. These changes can positively impact various areas of your life, including your career, relationships, personal growth, and more.`,
    open: false
  },
  { 
    question: `When will I start seeing results from the General Life Success subliminal?`, 
    answer: `The time it takes to see results from the General Life Success subliminal can vary from person to person. Some individuals may notice positive changes in their mindset, behaviors, and opportunities relatively quickly, while others may require more time and consistent listening to fully integrate the messages into their subconscious. We recommend listening to the subliminal daily and consistently for best results.`,
    open: false 
  },
  { 
    question: `Are there any specific actions I should take while listening to the General Life Success subliminal?`, 
    answer: `While listening to the General Life Success subliminal, it can be helpful to set specific goals, create action plans, and take consistent steps towards achieving them. Practicing self-reflection, visualization, and positive affirmations can also enhance the effectiveness of the subliminal messages. It's important to remember that the subliminal is just one tool in your journey towards success, and taking intentional action aligned with your goals is crucial.`,
    open: false 
  },
  { 
    question: `Can the General Life Success subliminal guarantee success in all areas of my life?`, 
    answer: `The General Life Success subliminal is designed to support and empower you in your pursuit of success, but it cannot guarantee specific outcomes in all areas of your life. Achieving success involves a combination of factors, including individual effort, external circumstances, and personal growth. The subliminal is intended to stimulate positive change in your mindset and behavior, increasing the likelihood of success, but it is ultimately up to you to take action and make the most of opportunities that arise.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the General Life Success subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the General Life Success subliminal. However, if you have any concerns about your mental health or well-being, please consult with a healthcare professional. Remember that the subliminal is a tool for personal development and should not replace professional advice or treatment.`,
    open: false 
  },
  { 
    question: `Can the General Life Success subliminal be used alongside other personal development practices?`, 
    answer: `Yes, the General Life Success subliminal can be used alongside other personal development practices, such as meditation, journaling, visualization, and goal setting. It can complement and enhance these practices by further reprogramming your subconscious mind with positive beliefs and attitudes. Building a holistic approach to personal growth can enable you to make significant strides towards success in all areas of your life.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>