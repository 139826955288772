<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "This language learning aid is designed to make your journey of learning Korean language easier and more efficient. Whether you're a beginner or want to enhance your existing language skills, this aid can support your learning process."
    },
    {
      type: 'heading',
      text: 'Key features of this language learning aid include:'
    },
    {
      type: 'list',
      items: [
        "Interactive lessons that cover essential Korean vocabulary, grammar, and phrases.",
        "Audio recordings of native Korean speakers to help you improve your pronunciation.",
        "Progress tracking and personalized learning plans to adapt to your individual needs.",
        "Practice exercises and quizzes to reinforce your understanding and retention of the language.",
        "Cultural insights and tips to enhance your understanding and appreciation of the Korean language and culture."
      ]
    },
    {
      type: 'paragraph',
      text: "By utilizing this language learning aid, you can learn Korean language more easily and efficiently. Whether you're planning to travel to Korea, communicate with Korean friends or colleagues, or simply have a passion for learning languages, this aid can help you achieve your goals. Start your Korean language learning journey today!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Learning a new language can be both exciting and challenging. This language learning aid is here to support your journey towards learning the Korean language easily and efficiently. Before you begin, here are some tips to help you get started on the right track:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set realistic goals: Determine what you want to achieve with your Korean language learning and break it down into smaller, achievable milestones.",
        "Create a study schedule: Dedicate regular time to practice and study Korean, ensuring consistency in your learning process.",
        "Immerse yourself in Korean language and culture: Watch Korean movies or dramas, listen to Korean music, and try to engage with native speakers if possible.",
        "Stay motivated: Keep in mind your reasons for learning Korean and find ways to stay motivated throughout your language learning journey."
      ]
    },
    {
      type: 'paragraph',
      text: "This language learning aid is designed to enhance your language learning experience and accelerate your progress. By incorporating these tips into your learning routine and regularly using this aid, you can improve your Korean language skills effectively. Embrace the challenge, stay dedicated, and enjoy the process of learning the Korean language!"
    }
  ]
},
            {
  id: 'after-listening',
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Korean Language Learning Aid audio session! You've taken a significant step towards mastering the Korean language and expanding your communication skills. Now that you've finished, it's important to reflect on your experience and continue your language learning journey."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice regularly: Consistency is key to language learning. Set aside dedicated time each day to practice Korean.",
        "Review and reinforce: Go over the lessons and materials you've learned. Reinforce your understanding and knowledge through review sessions.",
        "Immerse yourself: Surround yourself with Korean language media, such as music, movies, and TV shows, to immerse yourself in the language and culture.",
        "Find a language partner: Seek out language exchange opportunities or connect with native Korean speakers to practice speaking and improve your fluency."
      ]
    },
    {
      type: 'paragraph',
      text: "Learning a new language requires dedication and perseverance, but with practice and determination, you can achieve your goals. Keep pushing forward, and embrace the journey of discovering the beauty of the Korean language. 화이팅! (Fighting!)"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Korean Language Learning Aid help me in learning Korean?`, 
    answer: `The Korean Language Learning Aid is designed to support your language learning journey by providing essential vocabulary, grammar, and pronunciation guidance. It offers audio recordings, visual aids, and interactive exercises to help you practice and reinforce your skills. Whether you are a beginner or an intermediate learner, this learning aid can assist you in developing your Korean language proficiency.`,
    open: false
  },
  { 
    question: `How long does it take to become fluent in Korean using the Language Learning Aid?`, 
    answer: `The time it takes to become fluent in Korean can vary depending on various factors, such as your learning style, dedication, and the amount of time you dedicate to practicing. The Language Learning Aid is designed to provide you with the necessary tools and resources to learn Korean efficiently, but consistent practice and immersion in the language are essential for achieving fluency. With regular study and practice, you can make significant progress in your language skills over time.`,
    open: false 
  },
  { 
    question: `Can I use the Korean Language Learning Aid as a beginner?`, 
    answer: `Absolutely! The Korean Language Learning Aid is suitable for beginners and individuals with little to no prior knowledge of the Korean language. It provides a structured curriculum and step-by-step lessons to help you learn the basics of Korean grammar, vocabulary, and pronunciation. The aid also includes exercises and interactive activities to reinforce your learning.`,
    open: false 
  },
  { 
    question: `Is the Korean Language Learning Aid suitable for advanced learners?`, 
    answer: `While the Korean Language Learning Aid may primarily cater to beginners and intermediate learners, advanced learners can still benefit from certain aspects of the aid. It serves as a comprehensive resource for expanding vocabulary, refining grammar, and improving pronunciation skills. However, advanced learners may find the aid more useful as a supplementary tool to further enhance their Korean language proficiency.`,
    open: false 
  },
  { 
    question: `Can the Korean Language Learning Aid teach me to read and write in Korean?`, 
    answer: `Yes, the Korean Language Learning Aid includes lessons on reading and writing Hangul, the Korean writing system. It provides explanations, examples, and practice exercises to help you understand and master the basics of Hangul. By progressing through the lessons and practicing regularly, you can develop the ability to read and write in Korean.`,
    open: false 
  },
  { 
    question: `Is there any additional support available if I have questions or need clarification while using the Korean Language Learning Aid?`, 
    answer: `Yes, we provide customer support to assist you with any questions or concerns you may have while using the Korean Language Learning Aid. Simply reach out to our support team, and we will be more than happy to help you clarify any doubts or provide further guidance.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>