<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you've ever been curious about lucid dreaming or want to enhance your dream experiences, this subliminal can help you achieve those goals. By targeting your subconscious mind, it can assist in developing the skills and awareness necessary for lucid dreaming."
    },
    {
      type: 'heading',
      text: 'Here are some benefits you may experience:'
    },
    {
      type: 'list',
      items: [
        "Increased dream recall, allowing you to remember and analyze your dreams more easily.",
        "Improved ability to recognize when you're dreaming and become fully aware within the dream.",
        "Enhanced control over dream content and the ability to manipulate the dream environment.",
        "Exploration of the limitless possibilities and creativity within the dream world.",
        "Heightened intuition and insights gained through lucid dreaming experiences."
      ]
    },
    {
      type: 'paragraph',
      text: "To make the most of this subliminal, listen to it regularly before sleep and engage in practices that promote lucid dreaming, such as reality checks and journaling your dreams. Over time, you may find yourself experiencing more vivid and lucid dreams, unlocking the incredible potential of the dream world."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on the journey of lucid dreaming can be exciting and transformative, but it's important to prepare yourself for the experience. This subliminal is designed to assist you in achieving lucid dreams, enhancing dream recall, and exploring the boundless possibilities of the dream world. Before you begin, consider these tips to optimize your lucid dreaming practice:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Clarify your objective for lucid dreaming, whether it's to have creative adventures, gain self-awareness, or enhance problem-solving abilities.",
        "Keep a dream journal: Establish a habit of recording your dreams upon waking, as this can improve dream recall and deepen your connection to the dream world.",
        "Practice reality checks: Throughout the day, question your surroundings and perform reality checks to increase your awareness and encourage lucidity in dreams.",
        "Immerse in lucid dreaming literature: Educate yourself by reading books or articles on lucid dreaming to gain insights and techniques for success."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your routine and implementing these preparation tips, you can create the optimal conditions for lucid dreaming. Remember, each experience is unique, and with patience and persistence, you can unlock the wonders and potentials of your dream world. So get ready, set your intention, and let your lucid dreaming journey begin!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Lucid Dreaming Assistance subliminal! By investing time into enhancing your dream experiences, you've unlocked a world of limitless possibilities. Now, it's important to reflect on your journey and continue to explore the wonders of the dream world."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Keep a dream journal: Write down your dreams upon waking to improve your dream recall.",
        "Practice reality checks: Throughout the day, question whether you are dreaming or not by performing simple reality checks.",
        "Experiment with dream techniques: Explore different methods to induce lucid dreams, such as reality testing or mnemonic induction.",
        "Maintain a consistent sleep schedule: Optimize your sleep routine to increase the likelihood of lucid dreaming."
      ]
    },
    {
      type: 'paragraph',
      text: "The world of lucid dreaming is vast and exciting, and with commitment and practice, you can continue to unlock incredible experiences. Embrace the journey of exploration and allow your dreams to take you to places beyond imagination. Sweet dreams and happy lucid dreaming!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What is lucid dreaming?`, 
    answer: `Lucid dreaming is when you become aware that you are dreaming while you are still in the dream. This awareness allows you to have control over the dream, explore and manipulate the dream environment, and engage in activities that you may not be able to experience in waking life. It is a state of heightened consciousness within the dream state.`,
    open: false
  },
  { 
    question: `How can the Lucid Dreaming Assistance subliminal help me have lucid dreams?`, 
    answer: `The Lucid Dreaming Assistance subliminal is designed to influence your subconscious mind, promoting lucidity and dream awareness during your sleep. By listening to the subliminal regularly, you can increase your chances of having lucid dreams and enhance your ability to recall and control your dreams. The subliminal messages work to align your thoughts and beliefs with the idea of lucid dreaming, making it more likely to occur naturally.`,
    open: false 
  },
  { 
    question: `How long does it take to experience lucid dreams with the Lucid Dreaming Assistance subliminal?`, 
    answer: `The time it takes to experience lucid dreams can vary from person to person. Some individuals may notice an increase in dream awareness and the frequency of lucid dreams soon after starting to listen to the subliminal, while others may need more time and consistent practice. It is important to listen to the subliminal regularly and combine it with other lucid dreaming techniques, such as reality checks and dream journaling, for best results.`,
    open: false 
  },
  { 
    question: `Can the Lucid Dreaming Assistance subliminal help with dream recall?`, 
    answer: `Yes, the Lucid Dreaming Assistance subliminal can help improve your dream recall. By influencing your subconscious mind, the subliminal messages work to enhance your ability to remember and recall your dreams. Keeping a dream journal and practicing techniques to enhance dream recall, in combination with listening to the subliminal, can further improve your dream recall abilities.`,
    open: false 
  },
  { 
    question: `Is it possible to have too many lucid dreams?`, 
    answer: `Having frequent lucid dreams is generally considered a positive experience, but it is important to maintain a healthy sleep schedule and prioritize restful sleep. If lucid dreaming begins to interfere with your sleep or daily functioning, you may want to decrease the frequency or intensity of your lucid dreaming practices. Everyone is different, so it is important to listen to your body and make adjustments as necessary.`,
    open: false 
  },
  { 
    question: `Can the Lucid Dreaming Assistance subliminal be used along with other lucid dreaming techniques?`, 
    answer: `Yes, the Lucid Dreaming Assistance subliminal can be used in conjunction with other lucid dreaming techniques. It can complement practices such as reality checks, meditation, visualization, and maintaining a dream journal. The subliminal messages work to strengthen your overall intention to have lucid dreams and enhance your subconscious receptiveness to other techniques.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>