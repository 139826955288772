<template>
  <div>
    <Category />
  </div>
</template>

<script>
import Category from '@/views/Admin/Category/index.vue'
export default {
  components: {
    Category
  },
  data: () => {
    return {}
  }
}
</script>
