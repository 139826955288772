<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Quantum Jumping is a powerful technique that taps into the potential of parallel universes to manifest success in your life. By using this subliminal, you can harness the power of your subconscious mind to access different versions of yourself and unlock your full potential."
    },
    {
      type: 'heading',
      text: 'Key benefits of Quantum Jumping success include:'
    },
    {
      type: 'list',
      items: [
        "Gaining a new perspective and fresh insights to overcome challenges and obstacles.",
        "Enhancing your intuition and decision-making abilities for more accurate and effective choices.",
        "Expanding your creativity and thinking outside the box to generate innovative ideas.",
        "Manifesting abundance and success in all areas of your life, such as career, relationships, and personal growth.",
        "Breaking through limiting beliefs and self-imposed barriers to reach new heights of achievement."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can align your subconscious mind with your goals and intentions, opening yourself up to new possibilities and opportunities. Quantum Jumping can be a transformative tool on your journey to success, allowing you to tap into the infinite potential of the universe."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Quantum jumping is an incredible tool that can unlock your potential and help you manifest success across all aspects of your life. This subliminal is designed to support your quantum jumping practice and assist you in achieving your goals. Before you begin, consider the following tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your goals: Take some time to identify your specific desires and intentions. Visualize what success looks like for you in different areas of your life.",
        "Create a quiet and focused environment: Find a serene space where you can relax and concentrate, free from distractions.",
        "Adopt a positive mindset: Approach your quantum jumping practice with an open mind, believing in the power of your intentions and the possibility of success.",
        "Maintain a journal: Consider recording your experiences, insights, and manifestations. This can help reinforce your belief in the process and track your progress.",
      ]
    },
    {
      type: 'paragraph',
      text: "By dedicating yourself to your quantum jumping practice and using this subliminal regularly, you can tap into the limitless potential within you and manifest the success you desire. Embrace the power of your mind, trust in the process, and get ready to take quantum leaps towards your goals."
    }
  ]
},
            {
  id: 'after-listening',
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Quantum Jumping Success subliminal session! By embracing the power of quantum jumping, you've embarked on a journey of manifesting success in every aspect of your life. As you continue on this path of growth and transformation, take a moment to reflect on your experience and consider the following tips."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Visualize success: Spend time each day visualizing yourself achieving your goals and living the life you desire.",
        "Set clear intentions: Write down your goals and intentions, focusing on what you want to manifest in your life.",
        "Take inspired action: Trust your intuition and take steps towards your goals, even if they seem unconventional.",
        "Stay positive and open-minded: Embrace the infinite possibilities that quantum jumping offers, and maintain a positive mindset throughout your journey."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, success is a personal and unique journey. By harnessing the power of quantum jumping, you have the ability to create the life of your dreams. Stay committed to your goals, remain open to opportunities, and believe in your own potential. The universe is ready to support you on your path to success. Keep quantum jumping and watch your dreams come true!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What is quantum jumping?`, 
    answer: `Quantum jumping is a visualization and meditation technique that involves mentally shifting or "jumping" into different versions of yourself in alternate realities. This practice is based on the concept that there are infinite possibilities and versions of ourselves in the universe. By accessing and aligning with a desired version of ourselves, we can tap into their knowledge, skills, and experiences to manifest success in our own lives.`,
    open: false
  },
  { 
    question: `How does the Quantum Jumping Success subliminal work?`, 
    answer: `The Quantum Jumping Success subliminal uses positive affirmations and subliminal messages to influence your subconscious mind and enhance your ability to practice quantum jumping effectively. By listening to the subliminal regularly, you can reprogram your mindset, beliefs, and thought patterns to align with success across various aspects of life. The subliminal works in conjunction with your own efforts and actions to help you achieve your goals and aspirations.`,
    open: false 
  },
  { 
    question: `Can everyone benefit from the Quantum Jumping Success subliminal?`, 
    answer: `Yes, the Quantum Jumping Success subliminal can benefit anyone who is open to exploring and harnessing the power of their mind to manifest success. Although individual results may vary, the subliminal is designed to help individuals overcome limiting beliefs, tap into their full potential, and create positive changes in their personal and professional lives.`,
    open: false 
  },
  { 
    question: `How long does it take to see results with the Quantum Jumping Success subliminal?`, 
    answer: `The time it takes to see results with the Quantum Jumping Success subliminal may vary for each individual. Some people may notice positive changes in their mindset and circumstances within a few weeks, while others may require more time and consistent listening to see significant results. It is important to continue listening regularly and taking inspired action towards your goals.`,
    open: false 
  },
  { 
    question: `Can the Quantum Jumping Success subliminal make me instantly successful?`, 
    answer: `The Quantum Jumping Success subliminal is a tool to enhance your mindset, motivation, and belief systems towards success. While it can help you tap into your potential and create positive change, it is important to note that success is a process that requires effort, dedication, and consistent action. The subliminal can empower and support you on your journey, but it cannot guarantee instant success without your active participation.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of using the Quantum Jumping Success subliminal?`, 
    answer: `No, there are no known risks or side effects associated with using the Quantum Jumping Success subliminal. However, if you have any concerns about your mental health or well-being, it is always recommended to consult with a healthcare professional. The subliminal is intended to complement your personal growth and self-development journey.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>