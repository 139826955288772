<template>
  <DialogBack
    :dialog="dialog"
    :width="500"
    :button="false"
    @closeDialog="closeDialog"
  >
    <div class="mx-10 mb-7 text-center">
      <div class="d-flex">
        <img src="@/assets/images/user/profile/edit_white.png" class="img3" />
        <h5>SUBSCRIPTION DETAILS</h5>
      </div>
      <h1 class="label">Subscription Start</h1>
      <v-text-field
        class="searching1 d-flex"
        solo
        dense
        disabled
        v-model="subscriptionStart"
      ></v-text-field>
      <h1 class="label">Subscription End</h1>
      <v-text-field
        class="searching1 d-flex"
        solo
        dense
        disabled
        v-model="subscriptionExpiration"
      ></v-text-field>
    </div>
  </DialogBack>
</template>

<script>
import { updateUserInfo, updateUserInfoImage } from "@/api/userinfo.js";
import { getMessage, url } from "@/utils";
import {
  userUpdatePassword,
  deleteUserEmail,
  deleteUserEmailProvider,
} from "@/api/user";
import Upload from "@/components/Upload.vue";

import DialogBack from "@/components/DialogBack.vue";

export default {
  props: {
    dialog: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data: () => {
    return {
      getMessage,
      updateUserInfo,
      updateUserInfoImage,
      deleteUserEmail,
      deleteUserEmailProvider,
      userUpdatePassword,

      display_name: "",
      first_name: "",
      last_name: "",
      email: "",
      thisdialog: 1,

      current_password: "",
      new_password: "",
      confirm_password: "",
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      message1: "",
      message_del: "",
      message2: "",
      chars: false,
      upper: false,
      lower: false,
      number: false,
      same: false,
      message: false,
      proceed: false,
      pass_delete: "",
      showPass_delete: false,
      image: null,

      files: [],
    };
  },
  mounted() {},
  components: {
    DialogBack,
    Upload,
  },
  mounted() {
    this.subscription = this.user.info.subscription_id;
  },
  computed: {
    user() {
      return JSON.parse(this.$store.state.user.info);
    },
    subscriptions() {
      return this.$store.state.subscription.data;
    },
    data() {
      return this.subscriptions.filter((items) => items.name == "Premium");
    },
    subscriptionStart() {
      return this.user.info.subscription_start;
    },
    subscriptionExpiration() {
      return this.user.info.subscription_end;
    },
    expiryMonth() {
      return this.subscriptionExpiration != null
        ? this.subscriptionExpiration.split("-")[1]
        : null;
    },
    expiryDate() {
      return this.subscriptionExpiration != null
        ? this.subscriptionExpiration.split("-")[2]
        : null;
    },
    expiryYear() {
      return this.subscriptionExpiration != null
        ? this.subscriptionExpiration.split("-")[0].slice(-2)
        : null;
    },
    startMonth() {
      return this.subscriptionStart != null
        ? this.subscriptionStart.split("-")[1]
        : null;
    },
    startDate() {
      return this.subscriptionStart != null
        ? this.subscriptionStart.split("-")[2]
        : null;
    },
    startYear() {
      return this.subscriptionStart != null
        ? this.subscriptionStart.split("-")[0].slice(-2)
        : null;
    },
  },
  mounted() {
    var self = this;
    setTimeout(() => {
      self.first_name = self.user.info.first_name;
      self.email = self.user.email;
      self.last_name = self.user.info.last_name;
      self.display_name = self.user.name;
      self.getPicture();
    }, 0);
  },
  watch: {
    current_password(value) {
      if (value != "") {
        this.message = "";
        this.validatePassword(value);
      }
    },
    new_password(value) {
      if (value != "") {
        this.message1 = "";
        this.validatePassword(value);
        if (this.confirm_password != "") {
          this.validateSamePassword();
        }
      }
    },
    confirm_password(value) {
      if (value != "") {
        this.message2 = "";
        this.validatePassword(value);
        if (this.new_password != "") {
          this.validateSamePassword();
        }
      }
    },
    pass_delete(value) {
      if (value != "") {
        this.message2 = "";
        this.validatePassword2(value);
      }
    },
  },
  methods: {
    getPicture() {
      if (this.files.length == 0) {
        this.files.push({
          name: "mood",
          url: this.user.info.cover,
        });
      }
    },
    getImageName(value) {
      this.image = value;
    },
    validateSamePassword() {
      if (this.new_password == this.confirm_password) {
        this.same = true;
      } else {
        this.same = false;
      }
    },
    closeDialog() {
      if (this.thisdialog == 1) {
        this.$emit("closeDialog", false);
      } else {
        this.thisdialog = 1;
        this.proceed = false;
      }
    },
    changepass() {
      this.thisdialog = 2;
    },
    save() {
      if (this.thisdialog == 1) {
        this.updateUserInfos();
      } else if (this.thisdialog == 2) {
        if (
          this.chars == false ||
          this.number == false ||
          this.upper == false ||
          this.lower == false
        ) {
        } else {
          this.updatePassword();
        }
      } else {
      }
    },
    updatePassword() {
      if (this.new_password != this.confirm_password) {
        this.message2 = "Confirm Password does not match";
      } else {
        this.message2 = "";
        const data = {
          user_id: this.user.user_id,
          new_password: this.new_password,
          current_password: this.current_password,
          new_password_confirmation: this.confirm_password,
        };

        this.userUpdatePassword(data).then((res) => {
          this.getMessage(this, res);
          this.confirm_password = null;
          this.new_password = null;
          this.current_password = null;
          this.lower = false;
          this.chars = false;
          this.number = false;
          this.upper = false;
        });
      }
    },
    updateUserInfos() {
      const info = {
        file: this.image,
        last_name: this.last_name,
        user_id: this.user.user_id,
        name: this.display_name,
        first_name: this.first_name,
      };

      this.updateUserInfoImage(info).then((res) => {
        const { success } = res;
        if (success) {
          this.user.info.last_name = this.last_name;
          this.user.info.first_name = this.first_name;
          this.user.name = this.display_name;
          this.$store.dispatch("user/getCover", res.data[0].cover);
          localStorage.setItem("cover", res.data[0].cover);
          localStorage.setItem("user", JSON.stringify(this.user));
          this.$store.dispatch("user/getInfo", JSON.stringify(this.user));
          this.$store.dispatch("user/getLocalInfo", JSON.stringify(this.user));

          this.$emit("closeDialog", false);
          this.getMessage(this, res);
        }
      });
    },
    deleteUser() {
      const info = {
        name: this.user.name,
        email: this.user.email,
        user_id: this.user.user_id,
        password: this.pass_delete,
      };
      this.deleteUserEmail(info).then((res) => {
        this.getMessage(this, res);
        if (res.success == true) {
          this.$store.dispatch("screen/getLoading", true);
          setTimeout(() => {
            this.$store.dispatch("user/getInfo", {});
            this.$store.dispatch("user/getToken", null);
            localStorage.removeItem("user");
            localStorage.removeItem("user_token");
            localStorage.removeItem("user_track");

            this.$router.push(`/login?type=user`);
            this.$store.dispatch("screen/getLoading", false);
            this.$store.dispatch("audio/getData", []);
            this.$store.dispatch("audio/getDatas", []);
          }, 3000);
        } else {
          this.pass_delete = "";
        }
      });
    },
    deleteUser2() {
      const info = {
        name: this.user.name,
        email: this.user.email,
        user_id: this.user.user_id,
      };
      this.deleteUserEmailProvider(info).then((res) => {
        this.getMessage(this, res);
        if (res.success == true) {
          this.$store.dispatch("screen/getLoading", true);
          setTimeout(() => {
            this.$store.dispatch("user/getInfo", {});
            this.$store.dispatch("user/getToken", null);
            localStorage.removeItem("user");
            localStorage.removeItem("user_token");
            localStorage.removeItem("user_track");

            this.$router.push(`/login?type=user`);
            this.$store.dispatch("screen/getLoading", false);
            this.$store.dispatch("audio/getData", []);
            this.$store.dispatch("audio/getDatas", []);
          }, 3000);
        } else {
          this.pass_delete = "";
        }
      });
    },
    validatePassword(password) {
      if (/[0-9]/.test(password)) {
        this.number = true;
      } else {
        this.number = false;
      }

      if (/[a-z]/.test(password)) {
        this.lower = true;
      } else {
        this.lower = false;
      }

      if (password.length < 8) {
        this.chars = false;
      } else {
        this.chars = true;
      }

      if (/[A-Z]/.test(password)) {
        this.upper = true;
      } else {
        this.upper = false;
      }
    },
    validatePassword1(password) {
      if (password.length < 8) {
        this.message1 = "Min 8 characters";
      } else {
        if (/[0-9]/.test(password)) {
          if (/[a-z]/.test(password)) {
            if (/[A-Z]/.test(password)) {
              this.message1 = "";
            } else {
              this.message1 = "At least one uppercase letter";
            }
          } else {
            this.message1 = "At least one lowercase letter";
          }
        } else {
          this.message1 = "At least one number";
        }
      }
    },
    validatePassword2(password) {
      if (password.length < 8) {
        this.message_del = "Min 8 characters";
      } else {
        if (/[0-9]/.test(password)) {
          if (/[a-z]/.test(password)) {
            if (/[A-Z]/.test(password)) {
              this.message_del = "";
            } else {
              this.message_del = "At least one uppercase letter";
            }
          } else {
            this.message_del = "At least one lowercase letter";
          }
        } else {
          this.message_del = "At least one number";
        }
      }
    },
  },
};
</script>

<style>
.h52 {
  margin-top: 40px;
  text-align: left;
}
.h6-label {
  margin-top: 5px;
  font-family: "Montserrat-SemiBold";
}
.delete-1 {
  height: 150px;
}
.del-label {
  font-family: "Montserrat-Bold";
}
.label {
  font-family: "Montserrat-Regular";
  text-align: left;
  margin-top: 10px;
  font-size: 12px;
  margin-bottom: -1px;
}
.imgcam {
  width: 30px;
  height: 30px;
  margin-top: 50px;
  margin-left: -20px;
  background: white;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
}
.img3 {
  height: 15px;
  margin-right: 10px;
  margin-top: 5px;
}
.searching1 {
  width: 100%;
  margin-left: 10%;
  height: 40px;
  background: rgba(244, 238, 238, 0.4);
  border-radius: 5px;
  font-family: "Montserrat-Regular";
}
.content {
  height: 40px;
  margin: auto;
  margin-top: -15px;
}
.message {
  background: #eafbf6;
}
.message,
.errors {
  border-radius: 5px;
  margin: auto;
  height: 40px;
  padding: 10px 15px;
  font-family: "Montserrat-Regular";
  text-align: center;
}
.errors {
  background: #fdedee;
}
.wrapper {
  margin-top: 10px;
}
.icon {
  height: 20px;
}
.check {
  height: 17px;
}
.cover {
  height: 70px;
  width: 80px;
  border-radius: 5px;
  margin-right: 10px;
}
.search {
  padding-left: 10px;
  width: 100%;
  margin: auto;
  margin-top: -10px;
  margin-bottom: 10px;
  border-radius: 10px;
  border: solid 0.5px black;
  height: 40px;
  background: #f4eeee;
  font-family: "Montserrat-Regular";
}
.p-style {
  font-size: 10px;
  padding-right: 20px;
  font-family: "Montserrat-Regular";
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
