<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Becoming a master of chess requires dedication, practice, and effective training techniques. This subliminal is designed to support your chess journey and help you improve your skills on and off the board."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help you:"
    },
    {
      type: 'list',
      items: [
        "Enhancing your strategic thinking and decision-making abilities in the game.",
        "Boosting your focus and concentration to maintain a sharp mind throughout the match.",
        "Improving your pattern recognition skills to anticipate and counter your opponent's moves.",
        "Increasing your confidence and belief in your chess abilities.",
        "Promoting a growth mindset that embraces learning, challenges, and continuous improvement.",
        "Increasing your passion for the game and desire to excel in chess."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can train your subconscious mind to align with the qualities and skills necessary for chess mastery. As you incorporate these positive affirmations into your mindset, you may find yourself playing with more confidence, making better decisions, and enjoying the game on a deeper level."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Becoming a master of chess requires dedication, strategic thinking, and continuous training. This subliminal is designed to enhance your chess skills and help you reach your full potential. Before you begin listening, here are some suggestions to maximize your training:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set specific goals: Determine what aspects of your chess game you want to improve, such as opening strategies, middle game tactics, endgame techniques, or overall game analysis.",
        "Create a training plan: Establish a structured routine that includes studying chess books, analyzing game positions, solving puzzles, and playing practice games.",
        "Stay mentally focused: Maintain concentration and engage in mindfulness exercises to enhance your ability to think strategically and make effective decisions during gameplay.",
        "Embrace a growth mindset: View each game, win, or loss as an opportunity to learn and improve, rather than as a reflection of your abilities."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is a powerful tool that can help reinforce your dedication to chess training and mastery. By consistently listening and implementing these preparation tips, you can accelerate your progress and increase your proficiency in the game. Embrace the challenge, enjoy the process, and let your journey towards chess mastery begin!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Chess Training and Mastery subliminal session! By dedicating yourself to improving your chess skills, you've embarked on a journey towards mastery and personal growth. Now that you've finished, it's important to reflect on your experience and continue your progress in becoming a chess master."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Analyze your games: Review your previous games to identify strengths, weaknesses, and areas for improvement.",
        "Study chess theories and strategies: Continue your learning journey by reading books, watching tutorials, or studying famous games.",
        "Play regularly: Regularly challenge yourself by playing against opponents of varying skill levels, both online and offline.",
        "Record your games: Keep a record of your chess games to track your progress and analyze your decision-making."
      ]
    },
    {
      type: 'paragraph',
      text: "Becoming a master chess player requires dedication, practice, and continuous learning. Embrace the challenges and setbacks along the way, as they provide valuable opportunities for growth. Stay motivated and persistent, and remember that every game is a chance to improve. Keep striving for chess mastery and enjoy the journey!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Chess Training and Mastery program help me improve my chess skills?`, 
    answer: `The Chess Training and Mastery program is designed to provide you with comprehensive training and resources to improve your chess skills. It includes lessons on various chess strategies, tactics, and techniques, as well as practice exercises and games. By following the program and dedicating time to practice, you can develop a deeper understanding of the game and improve your overall performance.`,
    open: false
  },
  { 
    question: `Is the Chess Training and Mastery program suitable for beginners?`, 
    answer: `Yes, the Chess Training and Mastery program is suitable for both beginners and more experienced players. It includes lessons that cater to different skill levels, starting from the basics and gradually progressing to more advanced concepts. Whether you are just starting out or looking to elevate your game, the program can provide valuable guidance and training.`,
    open: false 
  },
  { 
    question: `How long does it take to see improvements in my chess skills with the Chess Training and Mastery program?`, 
    answer: `The time it takes to see improvements in your chess skills can vary depending on factors such as your dedication, practice consistency, and natural aptitude for the game. With regular practice and implementation of the lessons from the program, you can expect gradual improvements in your understanding and performance. It is important to approach chess training with patience and a long-term mindset.`,
    open: false 
  },
  { 
    question: `Can I use the Chess Training and Mastery program to compete in chess tournaments?`, 
    answer: `Yes, the Chess Training and Mastery program can equip you with the necessary skills and knowledge to compete in chess tournaments. However, success in tournaments also requires experience, strategic thinking, and the ability to adapt to different opponents. The program can serve as a valuable foundation for tournament play, but additional practice and exposure to competitive games are recommended.`,
    open: false 
  },
  { 
    question: `Does the Chess Training and Mastery program include personalized coaching?`, 
    answer: `The Chess Training and Mastery program does not include personalized coaching. However, it provides a structured curriculum and resources to guide your learning and improvement. If you are looking for personalized coaching, it may be beneficial to seek out a chess coach or join a local chess club for additional guidance and support.`,
    open: false 
  },
  { 
    question: `Can I access the Chess Training and Mastery program on different devices?`, 
    answer: `Yes, the Chess Training and Mastery program is designed to be accessible on various devices, including computers, tablets, and smartphones. You can log in to the program from any compatible device with internet access, allowing you to engage in training and practice activities at your convenience.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>