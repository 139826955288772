<template>
  <div class="d-grid">
    <div class="d-flex align-center gap-4 mb-4">
      <div>
        <div class="d-flex align-center justify-center cover-wrapper">
          <img :src="cover" class="img1" />
          <div @click="dialog = true" class="edit-wrapper">
            <img src="@/assets/images/user/profile/edit.png" class="img2" />
          </div>
        </div>
      </div>
      <div>
        <div class="greet-wrapper">
          <p>Good to see you back,</p>
          <h2>{{ name }}</h2>
          <div
            @click="goToPrice('/price')"
            class="go-premium"
            v-if="subscriptionId == 1"
          >
            <p>GO PREMIUM</p>
          </div>
          <div class="premium" v-else @click="thisdialog = true">
            <p>PREMIUM</p>
          </div>
        </div>
      </div>
    </div>
    <div class="calendar-container">
      <div class="monthly">
        <div class="calendar-wrapper">
          <div class="header">
            <h3>{{ monthYear }}</h3>
            <div class="icon-wrapper">
              <v-icon class="icon" size="20" @click="nextMonth">
                mdi-chevron-up
              </v-icon>
              <v-icon class="icon" size="20" @click="prevMonth">
                mdi-chevron-down
              </v-icon>
            </div>
          </div>
          <div class="calendar">
            <div class="week" v-for="week in weeks" :key="week">
              {{ week }}
            </div>
            <div
              class="date"
              v-for="(day, index) in lastDayOfTheMonth"
              :key="index"
              :style="`${day == 1 ? `grid-column-start: ${firstDayWeek}` : ''}`"
            >
              <div>
                {{ day }}
                <div
                  class="mood"
                  :id="`${yearMonth}-${day < 10 ? '0' + day : day}`"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="weekly">
        <div>
          <div v-for="(weekDate, index) in weekly" :key="index" class="d-flex">
            <div class="box-card-text">
              <p class="date-text">
                {{ weekDate.day }}
              </p>
              <p class="week-text">{{ weekDate.week }}</p>
            </div>
            <el-card class="box-card">
              <div
                v-if="weekDate.id == null && weekDate.subs == 0"
                class="d-flex gap-2"
              >
                <img class="mood" src="@/assets/logo.png" />
                <p class="listens mt-4">No data available.</p>
              </div>

              <div
                v-else-if="weekDate.id != null && weekDate.subs == 0"
                class="d-flex gap-2"
              >
                <img class="mood" :src="weekDate.mood.image" />
                <div class="align-center justify-center">
                  <p class="days mt-3">{{ weekDate.mood.name }}</p>
                </div>
              </div>

              <div
                v-else-if="weekDate.id == null && weekDate.subs != 0"
                class="d-flex gap-2"
              >
                <img class="mood" src="@/assets/logo.png" />
                <div class="align-center justify-center">
                  <p class="days">Undefined</p>
                  <p class="listens">
                    <span>You mostly listened to:</span>
                    <span> <br />{{ weekDate.subliminal.title }}</span>
                  </p>
                </div>
              </div>

              <div v-else class="d-flex gap-2">
                <img class="mood" :src="weekDate.mood.image" />
                <div class="align-center justify-center">
                  <p class="days">{{ weekDate.mood.name }}</p>
                  <p class="listens">
                    <span>You mostly listened to:</span>
                    <span> <br />{{ weekDate.subliminal.title }}</span>
                  </p>
                </div>
              </div>
            </el-card>
          </div>
        </div>
      </div>
    </div>

    <SubscriptionDetailsDialog
      :dialog="thisdialog"
      @closeDialog="closeDialog"
    />
    <UserDetailsDialog :dialog="dialog" @closeDialog="closeDialog" />
  </div>
</template>

<script>
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import UserDetailsDialog from "@/components/User/UserDetailsDialog.vue";
import SubscriptionDetailsDialog from "@/components/User/SubscriptionDetailsDialog.vue";

import {
  getMoodById,
  getUserCalendarMoods,
  getUserMoodAudioCalendar,
} from "@/api/mood";

dayjs.extend(weekday);
dayjs.extend(weekOfYear);

export default {
  components: {
    UserDetailsDialog,
    SubscriptionDetailsDialog,
  },
  data: () => {
    return {
      getMoodById,
      getUserCalendarMoods,
      getUserMoodAudioCalendar,

      thisdialog: false,
      dialog: false,
      cover: null,

      month: 0,
      year: 0,

      weekly: [],
      monthly: [],

      weeks: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    };
  },
  mounted() {
    let self = this;
    setTimeout(() => {
      self.month = Number(self.currentMonth);
      self.year = Number(self.currentYear);
      if (self.cover_img == null) {
        self.cover = self.user.info.cover;
      } else {
        self.cover = self.cover_img;
      }
      self.getMoodData();
    }, 0);
  },
  computed: {
    user() {
      return JSON.parse(this.$store.state.user.info);
    },
    cover_img() {
      return this.$store.state.user.cover;
    },
    name() {
      return this.user != null ? this.user.name : "";
    },
    subscriptionId() {
      return this.user != null ? this.user.info.subscription_id : 1;
    },
    currentDate() {
      return Number(dayjs().format("DD"));
    },
    currentMonth() {
      let month = Number(dayjs().format("MM")) - 1;
      return month == -1 ? 11 : month;
    },
    currentYear() {
      return Number(dayjs().format("YYYY"));
    },
    lastDayOfCurrentMonth() {
      return Number(dayjs().daysInMonth());
    },
    firstDayWeek() {
      return (
        Number(
          dayjs()
            .set("month", this.month)
            .set("year", this.year)
            .startOf("month")
            .day()
        ) + 1
      );
    },
    lastDayOfTheMonth() {
      return dayjs()
        .set("month", this.month)
        .set("year", this.year)
        .daysInMonth();
    },
    weekDateFromToday() {
      let weekDate = [];
      let month = Number(dayjs().format("MM") - 1);
      let last = dayjs()
        .set("month", month - 1)
        .set("year", this.year)
        .daysInMonth();

      for (let index = 0; index < 7; index++) {
        let currentDate = this.currentDate - index;
        let lastDate = this.lastDayOfCurrentMonth;

        if (currentDate <= 0) {
          weekDate.push(last + currentDate);
        } else {
          weekDate.push(currentDate);
        }
      }
      return weekDate;
    },
    weekNameFromToday() {
      let weekName = [];
      for (let index = 0; index < 7; index++) {
        let currentDate = this.currentDate - index;
        let lastDate = this.lastDayOfCurrentMonth;
        if (currentDate <= lastDate) {
          const name = this.getWeekName(currentDate);
          weekName.push(name);
        }
      }

      return weekName;
    },
    monthYear() {
      return `${this.getMonthName(this.month)} ${this.year}`;
    },
    yearMonth() {
      return `${this.year}-${
        this.month + 1 < 10 ? "0" + this.month + 1 : this.month + 1
      }`;
    },
  },
  methods: {
    goToPrice(route) {
      this.$router.push(route);
    },
    getWeekName(date) {
      return dayjs().set("date", date).format("ddd");
    },
    getMonthName(month) {
      return dayjs().set("month", month).format("MMMM");
    },
    updateMonthYear() {
      if (this.month < 11) {
        this.month += 1;
        return;
      }

      this.month = 0;
      this.year += 1;
    },
    nextMonth() {
      this.clearMonthlyBackground();
      if (this.month < 11) {
        this.month += 1;
        this.getMoodData();
        return;
      }

      this.month = 0;
      this.year += 1;
      this.getMoodData();
    },
    prevMonth() {
      this.clearMonthlyBackground();
      if (this.month > 0) {
        this.month -= 1;
        this.getMoodData();
        return;
      }

      this.month = 11;
      this.year -= 1;
      this.getMoodData();
    },
    closeDialog(data) {
      this.thisdialog = data;
      this.dialog = data;

      if (this.cover_img == null) {
        this.cover = this.user.info.cover;
      } else {
        this.cover = this.cover_img;
      }
    },
    getMoodData() {
      const data = {
        subscription_id: this.subscriptionId,
        user_id: this.user.user_id,
        month: `${this.yearMonth}`,
        startDate: `${this.yearMonth}-01`,
        endDate: `${this.yearMonth}-${this.lastDayOfTheMonth}`,
      };

      this.getUserMoodAudioCalendar(data).then((res) => {
        this.monthly = res.data.monthly;
        this.weekly = res.data.weekly;

        this.getMonthlyBackground();
      });
    },
    clearMonthlyBackground() {
      for (let index = 1; index < this.lastDayOfTheMonth + 1; index++) {
        let month = `${this.yearMonth}-${index < 10 ? "0" + index : index}`;
        let elem = document.getElementById(`${month}`);
        if (typeof elem != "undefined" && elem != null) {
          elem.style.backgroundImage = "";
        }
      }
    },
    getMonthlyBackground() {
      for (let index = 0; index < this.monthly.length; index++) {
        const element = this.monthly[index];
        const date = element.date;
        const image = element.mood.image;

        let elem = document.getElementById(date);
        if (typeof elem != "undefined" && elem != null) {
          elem.style.backgroundImage = `url(${image})`;
        }
      }
    },
    getWeeklyBackground() {
      for (let index = 0; index < this.weekly.length; index++) {
        const element = this.weekly[index];
        const date = element.date;
        const image = element.mood[0].image;
        const title = element.mood[0].name;
        const description = element.mood[0].description;

        let elemImage = document.getElementById(`week-${date}`);
        let elemTitle = document.getElementById(`week-title-${date}`);
        let elemDescription = document.getElementById(
          `week-description-${date}`
        );
        if (typeof elemImage != "undefined" && elemImage != null) {
          elemImage.style.backgroundImage = `url(${image})`;
          elemTitle.innerHTML = title;
          elemDescription.innerHTML = description;
        }
      }
    },
  },
};
</script>

<style scoped>
.header-wrapper {
  height: 200px;
  width: 100%;
}

.cover-wrapper {
  background: rgba(146, 213, 255, 0.58);
  width: 150px;
  height: 150px;
  border-radius: 150px;
}

.greet-wrapper {
  margin-top: 50px;
}
.greet-wrapper > p {
  font-family: "Montserrat-Regular";
  margin-bottom: 0px;
}
.greet-wrapper > h2 {
  font-family: "Montserrat-Bold";
}
.go-premium {
  background: #1c8edf;
  cursor: pointer;
}
.premium {
  background: #faba6f;
}
.premium,
.go-premium {
  width: 120px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
  margin: 0px;
}
.premium > p,
.go-premium > p {
  text-align: center;
  padding: 5px;
  margin: 0px;
  font-family: "Montserrat-Bold";
  color: white;
}

.img2 {
  width: 15px;
  height: 15px;
  margin-top: 2px;
}

.img1 {
  border-radius: 150px;
  width: 120px;
  height: 120px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
}

.edit-wrapper {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  width: 20px;
  height: 20px;
  background: white;
  margin-top: 70%;
  margin-left: -20px;
}

.calendar-container {
  display: flex;
}

.calendar-wrapper {
  background: #1c8edf;

  text-align: center;
  padding-top: 20px;

  font-family: "Montserrat-Bold";
  color: #ffffff;
}

.calendar {
  display: grid;
  grid-template-columns: repeat(7, 70px);
  grid-gap: 5px;

  justify-content: center;
  align-content: center;
  font-family: "Montserrat-Bold";

  padding: 0px 40px 20px;
}

.calendar div {
  aspect-ratio: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Montserrat-Bold";

  padding-bottom: 2px;
}
.days {
  font-family: "Montserrat-Bold";
  margin-bottom: 0;
}

.listens {
  font-family: "Montserrat-Medium";
  font-size: 13px;
}

.date {
  font-size: 16px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 10px;
}

.icon-wrapper {
  display: grid;
  margin-bottom: 10px;
}

.icon {
  color: #ffffff;
}

.mood {
  width: 2.5rem;
  height: 2.5rem;

  border-radius: 50%;

  background: #fff;
  background-size: contain;
}

.mood-week {
  width: 20px;
  height: 20px;
}

.box-card {
  width: 300px;
  height: 80px;
  background: rgba(71, 151, 237, 0.2);
}

.box-card-text {
  width: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.date > div {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat-Bold";
}

.week-text {
  font-size: 16px;
  font-family: "Montserrat-Bold";
  margin-bottom: 0px;
}

.date-text {
  font-size: 24px;
  margin-bottom: 0px;
  font-family: "Montserrat-Bold";
}

.weekly {
  width: 100%;
  display: grid;
  gap: 10px;

  padding-block: 10px;
}

.weekly > div {
  width: 100%;
  display: grid;
  gap: 5px;
  overflow-x: auto;
  padding-block: 10px;
}

@media screen and (max-width: 1400px) {
  .calendar-container {
    flex-direction: column;
  }

  .weekly {
    order: 1;
  }

  .monthly {
    order: 2;
  }

  .weekly > div {
    display: flex;
  }
}

@media screen and (max-width: 980px) {
  .calendar {
    grid-template-columns: repeat(7, 60px);
  }
}

@media screen and (max-width: 600px) {
  .calendar {
    grid-template-columns: repeat(7, 50px);
  }

  .mood {
    width: 2rem;
    height: 2rem;
  }
}

@media screen and (max-width: 450px) {
  .calendar {
    grid-template-columns: repeat(7, 40px);
  }

  .mood {
    width: 1.5rem;
    height: 1.5rem;
  }
}

@media screen and (max-width: 400px) {
  .calendar {
    grid-template-columns: repeat(7, 35px);
  }

  .mood {
    width: 1.2rem;
    height: 1.2rem;
  }

  .date {
    font-size: 14px;
  }
}
</style>
