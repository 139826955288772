<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Interested in cryptocurrency trading but not sure where to start? Look no further! This subliminal is designed to help you learn the strategies and techniques for successful crypto trading, enabling you to maximize your profits."
    },
    {
      type: 'heading',
      text: 'What you can expect from this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Understanding the fundamentals of cryptocurrency trading and the various market analysis techniques.",
        "Developing sound risk management strategies to protect your investments and minimize losses.",
        "Gaining confidence in your trading decisions and executing trades with precision.",
        "Strengthening your mindset and emotional resilience to navigate the volatile nature of the crypto market.",
        "Staying updated with market trends and news to make informed trading choices."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you'll absorb the knowledge and expertise required for successful cryptocurrency trading. It will help you develop a disciplined approach, make informed decisions, and ultimately maximize your profits. Remember, mastering crypto trading takes practice and continuous learning, and this subliminal can be a valuable tool in your journey towards trading success."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on your journey to successful cryptocurrency trading can be an exciting but challenging endeavor. This subliminal is designed to support your learning and implementation of effective trading strategies. Before you start listening, consider these tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Educate yourself: Make sure you have a good understanding of cryptocurrency basics, market trends, and trading strategies.",
        "Establish a trading plan: Set clear goals, define your risk tolerance, and determine your entry and exit strategies for trades.",
        "Practice patience: Cryptocurrency markets can be volatile, so it's crucial to stay calm, patient, and disciplined during market fluctuations.",
        "Stay informed: Continuously monitor market news, analysis, and updates to make informed trading decisions.",
        "Maintain a positive mindset: Believe in your ability to learn, adapt, and succeed in cryptocurrency trading."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your trading routine, you can strengthen your mindset, improve your focus, and increase your confidence as a cryptocurrency trader. With dedication, continuous learning, and disciplined execution of your trading plan, you can maximize your profits and achieve success in the world of cryptocurrency trading. Now, let's dive into the empowering world of cryptocurrency trading!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Cryptocurrency Trading Success session! By investing your time in learning the strategies and techniques for successful cryptocurrency trading, you're on your way to maximizing your profits and achieving your financial goals. Now, it's important to reflect on your experience and continue your journey towards trading success."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Review your goals: Assess your trading objectives and make sure they align with your long-term financial aspirations.",
        "Stay informed: Continuously educate yourself about the latest trends, news, and changes in the cryptocurrency market.",
        "Practice risk management: Implement strategies such as setting stop-loss orders and diversifying your cryptocurrency portfolio.",
        "Analyze your performance: Regularly review your trades, identify successful strategies, and learn from any mistakes or losses."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, successful cryptocurrency trading requires constant learning, adaptability, and careful decision-making. Keep refining your skills, stay disciplined, and be patient. As you continue to improve your understanding of the market and apply effective trading strategies, you'll increase your chances of achieving long-term success. Happy trading!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `I am new to cryptocurrency trading. Can the Cryptocurrency Trading Success subliminal help me?`, 
    answer: `Yes, the Cryptocurrency Trading Success subliminal is designed to help both beginners and experienced traders. It can help you develop the mindset and skills necessary for successful cryptocurrency trading, such as risk management, decision-making, and emotional control. By consistently listening to the subliminal and actively applying the principles you learn, you can enhance your trading abilities and increase your chances of success.`,
    open: false
  },
  { 
    question: `How long does it take to see results with the Cryptocurrency Trading Success subliminal?`, 
    answer: `Results may vary depending on your previous knowledge and experience with cryptocurrency trading and your dedication to actively applying the principles you learn. It is recommended to listen to the subliminal consistently and give yourself time to practice the strategies and techniques it promotes. With patience and persistence, you can gradually see improvements in your trading skills and potentially maximize your profits.`,
    open: false 
  },
  { 
    question: `Can the Cryptocurrency Trading Success subliminal guarantee profits in trading?`, 
    answer: `No, the Cryptocurrency Trading Success subliminal does not guarantee profits in trading. It is designed to help you develop the mindset, knowledge, and skills necessary for successful trading, but the actual outcomes depend on various factors, including market conditions and individual decisions. It is important to approach cryptocurrency trading with a realistic understanding of the risks involved and to continuously educate yourself on market trends and strategies.`,
    open: false 
  },
  { 
    question: `Is the Cryptocurrency Trading Success subliminal suitable for day trading or long-term investing?`, 
    answer: `The Cryptocurrency Trading Success subliminal is designed to enhance your overall trading mindset, knowledge, and skills, regardless of your preferred trading style. Whether you engage in day trading or long-term investing, the subliminal can help you develop discipline, emotional control, and effective decision-making strategies to maximize your profits and minimize risks.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the Cryptocurrency Trading Success subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, it is essential to practice responsible trading and to always do thorough research and exercise caution when making investment decisions. The subliminal is meant to supplement your knowledge and skills for trading success, but it does not replace the need for careful analysis and risk management.`,
    open: false 
  },
  { 
    question: `Can the Cryptocurrency Trading Success subliminal predict market trends or provide insider information?`, 
    answer: `No, the Cryptocurrency Trading Success subliminal does not possess any predictive abilities or provide insider information. It is designed to enhance your trading mindset, knowledge, and skills to make informed decisions based on your analysis of market trends and available information. It is important to rely on reputable sources and maintain a deep understanding of the cryptocurrency market when making trading decisions.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>