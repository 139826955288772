<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're looking to improve your eyesight and enhance your vision naturally, this subliminal can be a valuable tool on your journey. By tapping into the power of your subconscious mind, it aims to reprogram your beliefs and support the natural improvement of your eyesight."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help:'
    },
    {
      type: 'list',
      items: [
        "Promoting relaxation and reducing eye strain, which can contribute to improved vision.",
        "Enhancing the health of your eyes and supporting their natural functioning.",
        "Boosting your belief in your ability to improve your eyesight naturally.",
        "Encouraging positive thoughts and affirmations about your vision.",
        "Motivating you to take proactive steps towards better eye care, such as maintaining a healthy lifestyle and seeking regular eye check-ups."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you may experience gradual improvements in your vision. It's important to note that individual results may vary, as everyone's eyesight is unique. However, with consistent use and a positive mindset, you can work towards enhancing your vision naturally. Remember to consult with an eye care professional for any specific concerns or conditions."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Improving your eyesight and enhancing your vision naturally is possible with this subliminal. Before you start listening, consider these tips to optimize your experience and maximize your results:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a calm environment: Find a quiet space where you can relax and focus without distractions.",
        "Adjust your posture: Sit or lie in a comfortable position that allows your body to be fully relaxed.",
        "Set your intention: Visualize yourself with improved eyesight and believe in the power of your mind to make it a reality.",
        "Be consistent: Listen to the subliminal regularly to reinforce the positive affirmations and encourage long-term improvements in your vision."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to tap into the potential of your mind to naturally improve your eyesight. As you listen and align yourself with the positive affirmations, you may experience enhanced vision and decreased reliance on glasses or contacts. Embrace the power of your mind and the possibilities that lie ahead for your eyesight!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Eyesight Improvement subliminal session! By dedicating time to improve your eyesight naturally, you've taken a significant step towards enhancing your visual acuity and reducing reliance on glasses or contacts. Now, let's reflect on your experience and continue your journey towards better vision."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Implement eye exercises: Research and practice eye exercises to strengthen your eye muscles.",
        "Reduce screen time: Minimize digital eye strain by taking regular breaks and adjusting screen brightness.",
        "Protect your eyes: Wear sunglasses to shield your eyes from harmful UV rays and avoid eye strain.",
        "Maintain a healthy diet: Consume foods rich in antioxidants, omega-3 fatty acids, and vitamins A, C, and E."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, improving your eyesight naturally may take time and consistency. Stay committed to implementing these post-listening tips in your daily routine. By taking care of your eyes and incorporating healthy habits, you're on your way to achieving clearer vision and reducing the need for glasses or contacts. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Eyesight Improvement subliminal work?`, 
    answer: `The Eyesight Improvement subliminal works by sending positive affirmations directly to your subconscious mind, helping you develop a more positive and focused mindset towards improving your eyesight. It aims to activate your body's natural ability to heal and strengthen your eyes, promoting visual clarity and healthier vision. Consistent listening can reinforce the desired changes in your subconscious and support your journey towards better eyesight.`,
    open: false
  },
  { 
    question: `How long does it take to see improvements in eyesight using the subliminal?`, 
    answer: `The time it takes to see improvements in eyesight can vary from person to person. Some individuals may notice positive changes relatively quickly, while others may require more time and consistent listening. It is important to remember that everyone's eyesight improvement journey is unique. We recommend listening to the subliminal daily and consistently for at least a few weeks to allow the positive affirmations to take effect.`,
    open: false 
  },
  { 
    question: `Can the Eyesight Improvement subliminal replace corrective lenses?`, 
    answer: `The Eyesight Improvement subliminal is not intended to replace corrective lenses prescribed by an optometrist. It is designed to work in conjunction with healthy lifestyle habits and provide a mindset that supports better vision. If you require corrective lenses, please consult with your optometrist for appropriate guidance and recommendations.`,
    open: false 
  },
  { 
    question: `Is it safe to listen to the Eyesight Improvement subliminal while wearing contact lenses?`, 
    answer: `Yes, it is safe to listen to the Eyesight Improvement subliminal while wearing contact lenses. The subliminal audio does not pose any harm or interference with contact lens usage. However, please make sure to follow proper hygiene practices and the recommended wearing schedule for your contact lenses.`,
    open: false 
  },
  { 
    question: `Can the Eyesight Improvement subliminal work for everyone?`, 
    answer: `The Eyesight Improvement subliminal is designed to work for most individuals by stimulating positive subconscious changes related to eyesight improvement. However, individual results may vary depending on factors such as overall eye health, pre-existing eye conditions, and adherence to healthy lifestyle habits. It is always recommended to consult with an eye care professional for personalized advice and guidance.`,
    open: false 
  },
  { 
    question: `Is there a certain time of day I should listen to the Eyesight Improvement subliminal?`, 
    answer: `There is no specific time of day that is required for listening to the Eyesight Improvement subliminal. You can choose a time that is convenient and comfortable for you, whether it's in the morning, afternoon, or evening. Consistent listening is key, so aim to incorporate it into your daily routine.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>