<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Being a single mom can be challenging, but with the right mindset, you can not only survive, but thrive. This subliminal is specifically designed to boost your confidence and help you embrace your strength, independence, and ability to provide for your family."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support you:'
    },
    {
      type: 'list',
      items: [
        "Building a positive self-image and recognizing your worth as a single mom.",
        "Overcoming self-doubt and negative beliefs that may be holding you back.",
        "Strengthening your belief in your abilities to handle the challenges and responsibilities of single parenthood.",
        "Becoming more resilient and confident in navigating various aspects of life as a single mom.",
        "Developing a positive mindset and embracing your independence and strength."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, it can help reprogram your subconscious mind, empowering you to believe in yourself and your capabilities. Remember, being a single mom is a journey, and building confidence takes time. But with consistent use and a positive mindset, you can thrive and create a fulfilling life for yourself and your family."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Being a single mom is an incredible responsibility, and it's vital to recognize and nurture your own confidence and self-worth. This subliminal is designed to support you in embracing your strength, independence, and ability to provide for your family. Before starting this empowering audio, consider these suggestions:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Affirm your worth: Remind yourself that you are capable, resilient, and deserving of happiness and success.",
        "Surround yourself with support: Build a network of friends, family, or support groups who understand and uplift you on your journey as a single mom.",
        "Practice self-care: Prioritize your well-being by engaging in activities that nourish your body, mind, and soul.",
        "Set positive intentions: Focus on your goals as a single mom, be it providing a loving home, nurturing your children, or achieving personal growth.",
        "Embrace forgiveness: Allow yourself to let go of past mistakes or insecurities and cultivate a compassionate and forgiving attitude towards yourself."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal aims to help you tap into your inner strength, ignite your confidence, and thrive as a single mom. As you listen regularly, you may notice a transformation in your mindset, increased self-assurance, and renewed energy to face any challenges that come your way. Embrace your role as a resilient and empowered individual, and let this empowering subliminal be your companion on this journey."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Confidence for Single Mom subliminal! You have taken an important step towards building confidence and embracing your strength as a single mom. Reflecting on your journey and recognizing your own abilities is crucial as you continue to grow and thrive in this role."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Celebrate your achievements: Take a moment to acknowledge all that you have accomplished as a single mom.",
        "Practice self-care: Prioritize your well-being by engaging in activities that recharge and rejuvenate you.",
        "Connect with support systems: Seek out support groups, friends, or family members who understand your unique experiences.",
        "Set goals and take action: Identify areas where you would like to grow and take steps towards achieving those goals."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, being a single mom can be challenging, but you have the strength and resilience to overcome any obstacles that come your way. Trust yourself, believe in your abilities, and remember that you are an incredible provider and role model for your family. Keep flourishing!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Confidence for Single Mom subliminal help me?`, 
    answer: `The Confidence for Single Mom subliminal is designed to help you build confidence and thrive as a single mom. It consists of positive affirmations that target your subconscious mind, promoting self-belief, resilience, and a positive mindset. By listening to the subliminal regularly, you can strengthen your confidence, embrace your strength and independence, and develop a strong sense of self-worth.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Confidence for Single Mom subliminal?`, 
    answer: `Results may vary depending on the individual and their unique circumstances. Some people may experience an improvement in their confidence and mindset right away, while others may require more time and consistent listening to fully benefit from the subliminal messages. We recommend listening to the subliminal daily and consistently for best results.`,
    open: false 
  },
  { 
    question: `Can the Confidence for Single Mom subliminal help with the challenges of being a single mom?`, 
    answer: `While the Confidence for Single Mom subliminal can help you build confidence and resilience, it is important to note that it is not a substitute for practical support systems or professional advice. It can complement your existing resources and promote a positive mindset, which can be beneficial in facing the challenges of being a single mom.`,
    open: false 
  },
  { 
    question: `How can I make the Confidence for Single Mom subliminal more effective?`, 
    answer: `To enhance the effectiveness of the subliminal, we recommend listening to it in a quiet and relaxed environment without distractions. You can also combine it with other confidence-building practices, such as positive self-talk, setting and achieving small goals, and seeking support from friends, family, or support groups. Consistent listening and an open mindset can help you make the most of the subliminal's affirmations.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Confidence for Single Mom subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your confidence or well-being, please consult with a mental health professional. The subliminal is intended to supplement your personal growth journey and empower you with a positive mindset as a single mom.`,
    open: false 
  },
  { 
    question: `Can the Confidence for Single Mom subliminal be effective for any parent, not just single moms?`, 
    answer: `While the Confidence for Single Mom subliminal is specifically designed for single moms, the positive affirmations and mindset it promotes can be beneficial for any parent looking to build confidence and resilience. The subliminal's messages of strength, independence, and self-worth can resonate with individuals in various parenting situations.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>