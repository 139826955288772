export const audio = {
  namespaced: true,
  state: {
    data: [], // audio file
    datas: [], // audio data (track, playlist, subliminal)
    types: [],
    titles: [],
    volumes: [],
    audio_types: [],
    upload: {
      data: [],
    },

    cover: null,
    title: null,

    index: 0,
    volume: 0,
    count: 0,
    version: null,

    current: {
      time: 0,
    },
    duration: {
      time: 0,
      times: [],
    },
    track: {
      id: null,
      data: [],
    },
    playlist: {
      id: null,
      data: [],
      type: null,
      tracks: [],
      track: {
        id: null,
      },
    },
    subliminal: {
      id: null,
      data: [],
    },

    guide: {
      data: null,
      status: false,
    },

    loop: false,
    repeat: false,
    status: false, // audio is playing - true, paused - false
    loading: false,
    initial_status: true,

    favorite: {
      id: null,
      update: false,
      webplayer: false,
    },
  },
  getters: {},
  actions: {
    getIndex({ commit }, data) {
      commit("getIndex", data);
    },
    getCount({ commit }, data) {
      commit("getCount", data);
    },
    getLoop({ commit }, data) {
      commit("getLoop", data);
    },
    getCover({ commit }, data) {
      commit("getCover", data);
    },
    getTitle({ commit }, data) {
      commit("getTitle", data);
    },
    getTypes({ commit }, data) {
      commit("getTypes", data);
    },
    getRepeat({ commit }, data) {
      commit("getRepeat", data);
    },
    getFavoriteUpdate({ commit }, data) {
      commit("getFavoriteUpdate", data);
    },
    getWebplayerFavoriteUpdate({ commit }, data) {
      commit("getWebplayerFavoriteUpdate", data);
    },
    getFavoriteId({ commit }, data) {
      commit("getFavoriteId", data);
    },
    getStatus({ commit }, data) {
      commit("getStatus", data);
    },
    getTitle({ commit }, data) {
      commit("getTitle", data);
    },
    getTitles({ commit }, data) {
      commit("getTitles", data);
    },
    getVolume({ commit }, data) {
      commit("getVolume", data);
    },
    getVersion({ commit }, data) {
      commit("getVersion", data);
    },

    getGuideData({ commit }, data) {
      commit("getGuideData", data);
    },
    getGuideStatus({ commit }, data) {
      commit("getGuideStatus", data);
    },
    getLoading({ commit }, data) {
      commit("getLoading", data);
    },
    getUploadList({ commit }, data) {
      commit("getUploadList", data);
    },
    getTrackId({ commit }, data) {
      commit("getTrackId", data);
    },
    getPlaylistId({ commit }, data) {
      commit("getPlaylistId", data);
    },
    getSubliminalId({ commit }, data) {
      commit("getSubliminalId", data);
    },
    getDurationTime({ commit }, data) {
      commit("getDurationTime", data);
    },
    getDurationTimes({ commit }, data) {
      commit("getDurationTimes", data);
    },
    getCurrentTime({ commit, state }, data) {
      for (let index = 0; index < state.data.length; index++) {
        const element = state.data[index];
        element.currentTime = data;
      }

      commit("getCurrentTime", data);
    },
    getVolumes({ commit, state }, data) {
      for (let index = 0; index < state.data.length; index++) {
        const element = state.data[index];
        element.volume = data[index] / 100;
      }

      commit("getVolumes", data);
    },
    getData({ commit }, data) {
      let audios = [];
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        let audio = new Audio();

        audio.src = element;
        audios.push(audio);
      }
      commit("getData", audios);
    },
    getDatas({ commit }, data) {
      commit("getDatas", data);
    },
    getPlay({ commit, state }) {
      for (let index = 0; index < state.data.length; index++) {
        let element = state.data[index];
        element.volume = state.volumes[index] / 100;
        element.play();

        if (state.initial_status) {
          element.ontimeupdate = (event) => {
            commit("getCurrentTime", parseInt(element.currentTime));
          };
          commit("getInitialStatus", false);
        }
      }

      commit("getStatus", true);
      commit("getLoading", false);
    },
    getPause({ commit, state }) {
      for (let index = 0; index < state.data.length; index++) {
        const element = state.data[index];
        // element.currentTime = state.current.time;
        element.volume = state.volumes[index] / 100;
        element.pause();
      }

      commit("getStatus", false);
    },
    getAudioTypes({ commit, state }, data) {
      commit("getAudioTypes", data);
    },
    getPlaylistType({ commit }, data) {
      commit("getPlaylistType", data);
    },
    getPlaylistTrack({ commit }, data) {
      commit("getPlaylistTrack", data);
    },
    getPlaylistTrackId({ commit }, data) {
      commit("getPlaylistTrackId", data);
    },
  },
  mutations: {
    getLoop(state, data) {
      state.loop = data;
    },
    getData(state, data) {
      state.data = data;
    },
    getCount(state, data) {
      state.count = data;
    },
    getDatas(state, data) {
      state.datas = data;
    },
    getIndex(state, data) {
      state.index = data;
    },
    getCover(state, data) {
      state.cover = data;
    },
    getTitle(state, data) {
      state.title = data;
    },
    getTypes(state, data) {
      state.types = data;
    },
    getRepeat(state, data) {
      state.repeat = data;
    },
    getFavoriteUpdate(state, data) {
      state.favorite.update = data;
    },
    getWebplayerFavoriteUpdate(state, data) {
      state.favorite.webplayer = data;
    },
    getFavoriteId(state, data) {
      state.favorite.id = data;
    },
    getStatus(state, data) {
      state.status = data;
    },
    getInitialStatus(state, data) {
      state.initial_status = data;
    },
    getTitles(state, data) {
      state.titles = data;
    },
    getVolume(state, data) {
      state.volume = data;
    },
    getVolumes(state, data) {
      state.volumes = data;
    },
    getAudioTypes(state, data) {
      state.audio_types = data;
    },
    getVersion(state, data) {
      state.version = data;
    },
    getLoading(state, data) {
      state.loading = data;
    },
    getUploadList(state, data) {
      state.upload.data = data;
    },
    getTrackId(state, data) {
      state.track.id = data;
    },
    getPlaylistId(state, data) {
      state.playlist.id = data;
    },
    getCurrentTime(state, data) {
      state.current.time = data;
    },
    getGuideData(state, data) {
      state.guide.data = data;
    },
    getGuideStatus(state, data) {
      state.guide.status = data;
    },
    getCurrentTime(state, data) {
      state.current.time = data;
    },
    getSubliminalId(state, data) {
      state.subliminal.id = data;
    },
    getDurationTime(state, data) {
      state.duration.time = data;
    },
    getDurationTimes(state, data) {
      if (data != null) {
        state.duration.times = data;
      } else {
        state.duration.times = [];
      }
    },
    getPlaylistType(state, data) {
      state.playlist.type = data;
    },
    getPlaylistTrack(state, data) {
      state.playlist.tracks = data;
    },
    getPlaylistTrackId(state, data) {
      state.playlist.track.id = data;
    },
  },
};
