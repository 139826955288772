<template>
  <div class="body">
    <div><p> ‎ </p></div>
    <div id="app" class="px-4 sm:px-6 lg:px-8"> 
        <div class="mb-4 border-b border-gray-200 dark:border-gray-700"> 
            <ul class="flex flex-nowrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400" role="tablist">
                <li class="mr-2" role="presentation" v-for="(tab, index) in tabs" :key="index">
                    <button
                        :class="['inline-block rounded-t-lg border-b-2 border-transparent px-8 py-4 text-lg hover:border-gray-300 hover:text-gray-600 dark:hover:text-gray-300', tab.id === selectedTab ? 'active-class' : '']"
                        type="button"
                        role="tab"
                        @click="selectedTab = tab.id"
                    >
                        {{ tab.name }}
                    </button>
                </li>
            </ul>
        </div>
    
        <!-- Content for Non-FAQ Tabs -->
        <div v-for="tab in tabs" :key="tab.id">
            <div v-if="tab.id !== 'faq' && tab.id === selectedTab" class="p-4" v-html="tab.content"></div>
        </div>
    
        <!-- FAQ Tab with Accordion -->
        <div v-if="selectedTab === 'faq'">
            <div v-for="(item, index) in faqItems" :key="index" :id="'accordion-color-heading-' + (index + 1)">
                <button 
                    @click="toggleAccordion(index)"
                    class="flex items-center justify-between w-full p-4 font-medium text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800 dark:border-gray-700 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800 gap-3"
                    :aria-expanded="item.open.toString()" 
                    :aria-controls="'accordion-color-body-' + (index + 1)">
                    {{ item.question }}
                    <svg :class="['w-3 h-3 shrink-0', item.open ? '' : 'transform rotate-180']" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
                    </svg>
                </button>

                <div :id="'accordion-color-body-' + (index + 1)" class="p-4 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900" v-if="item.open" :aria-labelledby="'accordion-color-heading-' + (index + 1)">
                    <div v-html="item.answer" class="text-gray-500 dark:text-gray-400"></div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import '@/assets/js/flowbite.min.js'
import '@/assets/js/vue.js'
import '@/assets/css/flowbite.min.css'
import '@/assets/css/tailwind.min.css'

export default {
  data: () => {
    return {
      items: [],
      selectedTab: 'how-it-works',
                tabs: [
                    { 
                        id: 'how-it-works', 
                        name: 'How it Works', 
                        content: `
                            <div class="p-4 rounded-lg bg-gray-50 dark:bg-gray-800">
                                <p class="text-lg text-gray-500 dark:text-gray-400 mb-4">
                                    If you're having trouble staying motivated with your diet or healthy eating habits, don't worry - you're not alone. That's where this subliminal comes in!
                                </p>
                            </div>
                            <div><p> ‎ </p></div>
                            <div class="p-4 rounded-lg bg-gray-50 dark:bg-gray-800">    
                                <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">Here are some situations where it can be especially helpful:</h2>
                                <ul class="space-y-2 text-gray-500 list-inside dark:text-gray-400">
                                    <li class="flex items-center">
                                        <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                        </svg>
                                        If you find it hard to resist unhealthy food cravings or emotional eating.
                                    </li>
                                    <li class="flex items-center">
                                        <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                        </svg>
                                        If you struggle to stay motivated to stick to your diet plan or healthy eating habits.
                                    </li>
                                    <li class="flex items-center">
                                        <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                        </svg>
                                        If you want to cultivate a healthier relationship with food and your body.
                                    </li>
                                    <li class="flex items-center">
                                        <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                        </svg>
                                        If you're starting a new diet plan or healthy lifestyle and need support to stay motivated.
                                    </li>
                                    <li class="flex items-center">
                                        <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                        </svg>
                                        If you want to feel more confident and empowered in your ability to make healthy choices.
                                    </li>
                                </ul>
                            </div>
                            <div><p> ‎ </p></div>
                            <div class="p-4 rounded-lg bg-gray-50 dark:bg-gray-800">   
                                <p class="text-lg text-gray-500 dark:text-gray-400 mt-4">
                                    No matter what your situation is, this subliminal can help you shift your mindset and overcome your struggles with diet and healthy eating habits. As you listen regularly, you may start to feel more motivated, inspired, and in control of your relationship with food. Remember, building healthy eating habits takes time and effort, but you can do it!
                                </p>
                            </div>
                        `
                    },
                    { 
                        id: 'before-listening', 
                        name: 'Before Listening', 
                        content: `
                            <div class="p-4 rounded-lg bg-gray-50 dark:bg-gray-800">
                                <p class="text-lg text-gray-500 dark:text-gray-400 mb-4">
                                    Getting motivated to stick to a diet plan or make healthier food choices can be tough, but this subliminal can help you overcome those barriers and achieve your goals. Here are some tips to try before pressing play:
                                </p>
                            </div>
                            <div><p> ‎ </p></div>
                            <div class="p-4 rounded-lg bg-gray-50 dark:bg-gray-800">  
                                <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">Preparation Tips:</h2>
                                <ul class="space-y-2 text-gray-500 list-inside dark:text-gray-400">
                                    <li class="flex items-center">
                                        <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                        </svg>
                                        Set your intention: Focus on your goals, whether it's to resist cravings, make healthier choices, or stay committed to your diet plan.
                                    </li>
                                    <li class="flex items-center">
                                        <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                        </svg>
                                        Write it down: Document your intention in a journal using specific, positive language.
                                    </li>
                                    <li class="flex items-center">
                                        <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                        </svg>
                                        Visualize success: Imagine achieving your goal and feeling confident in your ability to make healthy choices.
                                    </li>
                                    <li class="flex items-center">
                                        <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                        </svg>
                                        Hold your intention in mind: Keep focused on your goals while listening to the subliminal affirmations.
                                    </li>
                                </ul>
                            </div>
                            <div><p> ‎ </p></div>
                            <div class="p-4 rounded-lg bg-gray-50 dark:bg-gray-800">   
                                <p class="text-lg text-gray-500 dark:text-gray-400 mt-4">
                                    Remember, this subliminal is designed to help you shift your mindset and overcome your struggles with diet and healthy eating habits. As you listen regularly, you may start to feel more motivated, inspired, and in control of your relationship with food. So take a deep breath, set your intention, and let's get started!
                                </p>
                            </div>
                        `
                    },
                    { 
                        id: 'after-listening', 
                        name: 'After Listening', 
                        content: `
                            <div class="p-4 rounded-lg bg-gray-50 dark:bg-gray-800">
                                <p class="text-lg text-gray-500 dark:text-gray-400 mb-4">
                                    Congratulations on finishing the Diet Motivation subliminal! Now that you've taken this step towards improving your diet and healthy eating habits, it's important to reflect on your experience and continue your personal growth and transformation.
                                </p>
                            </div>
                            <div><p> ‎ </p></div>
                            <div class="p-4 rounded-lg bg-gray-50 dark:bg-gray-800">
                                <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">Post-Listening Tips:</h2>
                                <ul class="space-y-2 text-gray-500 list-inside dark:text-gray-400">
                                    <li class="flex items-center">
                                        <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                        </svg>
                                        Reflect on your experience: Consider writing down your thoughts and experiences in a journal.
                                    </li>
                                    <li class="flex items-center">
                                        <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                        </svg>
                                        Track your progress: Use mood trackers to observe trends related to your diet and habits.
                                    </li>
                                    <li class="flex items-center">
                                        <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                        </svg>
                                        Plan healthy meals: Create a meal plan with nutritious foods to fuel your body and goals.
                                    </li>
                                    <li class="flex items-center">
                                        <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                        </svg>
                                        Take practical steps: Focus on actions like portion control, adequate sleep, and staying hydrated.
                                    </li>
                                </ul>
                            </div>
                            <div><p> ‎ </p></div>
                            <div class="p-4 rounded-lg bg-gray-50 dark:bg-gray-800">
                                <p class="text-lg text-gray-500 dark:text-gray-400 mt-4">
                                    Remember, building healthy eating habits takes time and effort, but you can do it! Stay focused on your goals, and take practical steps to improve your diet and overall well-being. You got this!
                                </p>
                            </div>
                        `
                    },

                    { 
                        id: 'faq', 
                        name: 'FAQ', 
                        content: '' 
                    }
                ],

                faqItems: [
                    { 
                        question: 'Q: How can I tell if the Diet Motivation subliminal is working for me?', 
                        answer: `A: It might take some time to see the effects of the subliminal on your motivation towards dieting, but you may notice changes in your behavior and attitude towards food. As you keep listening, pay attention to any positive changes or opportunities for growth that may arise in your life. You may also experience a greater sense of inner motivation, focus, and determination as your mindset becomes more aligned with healthy eating habits.`,
                        open: false
                    },
                    { 
                        question: 'Q: How long does it take to see results from the Diet Motivation subliminal?', 
                        answer: `A: Results can vary depending on the individual and their unique circumstances. Some people may experience positive changes right away, while others may require more time and consistent listening to fully reprogram their mindset towards healthy eating. We recommend listening to the subliminal daily and consistently for best results.`,
                        open: false 
                    },
                    { 
                        question: 'Q: How can I make the Diet Motivation subliminal more effective?', 
                        answer: `A: You can enhance the effectiveness of the subliminal by setting specific and achievable goals, creating a support system, and surrounding yourself with healthy food options. Practicing mindfulness and self-reflection can also help you identify triggers or patterns that may be hindering your motivation towards healthy eating. Combining the subliminal with practical and sustainable lifestyle changes, such as a balanced and nutritious diet and regular exercise, can also help you achieve your goals.`,
                        open: false 
                    },
                    { 
                        question: 'Q: Can the Diet Motivation subliminal replace a healthy diet and exercise?', 
                        answer: `A: No, the subliminal is intended to help you cultivate a positive and motivated mindset towards healthy eating habits. It is important to combine the subliminal with practical and sustainable lifestyle changes, such as a balanced and nutritious diet and regular exercise, to achieve long-term results.`,
                        open: false 
                    },
                    { 
                        question: 'Q: Are there any risks or side effects of listening to the Diet Motivation subliminal?', 
                        answer: `A: No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your diet or health, please consult with your healthcare provider. It is important to note that the subliminal is intended to supplement healthy lifestyle changes, not replace them.`,
                        open: false 
                    },
                    { 
                        question: 'Q: Will listening to the Diet Motivation subliminal make me lose my appetite for food or my sense of enjoyment in eating?', 
                        answer: `A: No, the Diet Motivation subliminal is not intended to decrease your appetite or make you lose your sense of enjoyment in eating. It is designed to help you cultivate a positive and motivated mindset towards healthy eating habits. You should still be able to enjoy your food while making healthy choices that align with your goals.`,
                        open: false 
                    }
                ]

          
    };
  },
  components: {
   
  },
  computed: {
   
  },
  methods: {
    toggleAccordion(selectedIndex) {
                    this.faqItems.forEach((item, index) => {
                        item.open = index === selectedIndex ? !item.open : false;
                    });
                }
  },
};
</script>

<style scoped>
        .body {
            font-family: 'Montserrat', sans-serif;
            font-size: 18px;
        }
        .active-class {
            border-bottom-color: #4a5568;
        }
        button:focus {
            outline: none;
        }
    </style>