<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're feeling stuck or unsatisfied with your current life situation, it's time to consider reinventing yourself. This subliminal is designed to help you tap into your true potential and create the life you've always dreamed of."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can assist you on your journey:'
    },
    {
      type: 'list',
      items: [
        "Unleashing your creativity and finding new passions that bring you joy and fulfillment.",
        "Building confidence and self-belief to overcome fears and take bold steps towards your goals.",
        "Letting go of negative thought patterns, self-doubt, and limiting beliefs that hold you back.",
        "Creating a clear vision for your future and setting achievable goals to work towards.",
        "Developing resilience and adaptability to navigate challenges and setbacks with confidence.",
        "Cultivating a positive mindset and embracing a growth mindset to unlock your full potential."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, reinventing yourself is a journey, and it takes time. By regularly listening to this subliminal and taking inspired action, you can gradually transform your life and step into the person you've always wanted to be. Embrace this opportunity for personal growth and let the journey begin!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a journey of self-discovery and reinvention can be both exciting and challenging. This subliminal is designed to assist you in unlocking your true potential and becoming the person you've always aspired to be. Before you begin, here are some suggestions to help you make the most of this transformative experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your aspirations: Take some time to explore your desires, passions, and goals. Clarify what you truly want to achieve or become.",
        "Set clear intentions: Write down your intentions and affirmations in a journal, using positive language and visualizing your future self.",
        "Create a supportive environment: Surround yourself with people, places, and things that inspire and encourage personal growth.",
        "Maintain a growth mindset: Embrace the process of transformation and remain open to new opportunities and perspectives."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is a tool to guide and empower you on your journey of reinvention. It can help rewire your subconscious mind, boost your self-confidence, and bring clarity of purpose. As you listen regularly, you may find yourself embracing new possibilities, stepping out of your comfort zone, and creating a fulfilling life aligned with your true self. Embrace this moment of transformation, believe in your potential, and let the journey begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Reinventing Yourself subliminal session! By embarking on this journey of self-discovery and personal growth, you have taken a powerful step towards transforming your life. It's time to reflect on your experience and embrace the opportunity to become the person you've always wanted to be."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your goals: Take some time to clarify your vision and set clear, achievable goals for yourself.",
        "Embrace change: Be open to new experiences, perspectives, and opportunities for growth.",
        "Cultivate positive habits: Identify habits that align with your desired identity and work on incorporating them into your daily life.",
        "Surround yourself with support: Seek out a supportive network of friends, mentors, or like-minded individuals who can inspire and motivate you on your journey."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, reinventing yourself is an ongoing process that requires dedication and self-belief. Trust in your abilities and stay committed to your personal growth. Embrace this new chapter of your life with confidence and purpose, and watch yourself blossom into the person you've always aspired to be. You've got this!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Reinventing Yourself subliminal help me?`, 
    answer: `The Reinventing Yourself subliminal is designed to help you tap into your true potential and transform into the person you've always wanted to be. By listening to the subliminal regularly, you can reprogram your subconscious mind, replacing limiting beliefs and self-doubt with positive affirmations and empowering thoughts. This can help boost your confidence, motivation, and sense of purpose as you embark on a new chapter of your life.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Reinventing Yourself subliminal?`, 
    answer: `Results can vary from person to person and depend on various factors, including your mindset and level of commitment. Some people may notice positive changes and shifts in perception and behavior relatively quickly, while others may require more time and consistent listening to see significant results. We recommend listening to the subliminal daily and remaining patient and open to the transformative process.`,
    open: false 
  },
  { 
    question: `Can the Reinventing Yourself subliminal help me overcome self-doubt and limiting beliefs?`, 
    answer: `Yes, the Reinventing Yourself subliminal is specifically designed to help you overcome self-doubt and limiting beliefs. The subliminal messages target your subconscious mind, replacing negative thoughts and beliefs with positive affirmations and empowering thoughts. By consistently listening to the subliminal, you can rewire your mindset and develop a newfound sense of confidence and self-belief.`,
    open: false 
  },
  { 
    question: `Is it necessary to make changes in my life while listening to the Reinventing Yourself subliminal?`, 
    answer: `The Reinventing Yourself subliminal can be a powerful tool to initiate positive change in your life, but it is ultimately up to you to take actions and make the necessary changes. While the subliminal can help shift your mindset and perspective, it is important to actively pursue personal growth and take steps towards your goals. The subliminal can provide the motivation and clarity you need to make those changes, but it is not a substitute for action.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the Reinventing Yourself subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Reinventing Yourself subliminal. However, if you have any concerns regarding your mental health or well-being, we recommend consulting with a mental health professional. The subliminal is intended to be a positive and empowering tool to support personal growth.`,
    open: false 
  },
  { 
    question: `Can the Reinventing Yourself subliminal help me find my life purpose?`, 
    answer: `The Reinventing Yourself subliminal can help you gain clarity and discover your true potential, which can in turn lead you to align with your life purpose. By listening to the subliminal and consistently focusing on personal growth, you can uncover your passions, values, and aspirations, guiding you towards a life that is meaningful and purposeful.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>