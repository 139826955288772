<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Dealing with toxic coworkers can be incredibly draining and demotivating. This subliminal is designed to help you disconnect from toxic coworkers and create a healthier work environment for yourself."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help you:"
    },
    {
      type: 'list',
      items: [
        "It can help you develop boundaries and assertiveness skills, empowering you to stand up against toxic behavior.",
        "It can boost your self-confidence and self-esteem, making it easier to handle toxic individuals without internalizing their negativity.",
        "It can help you maintain a positive mindset, focusing on your own goals and growth rather than getting caught up in office drama.",
        "It can support you in finding healthy ways to cope with stress and maintaining your emotional well-being in the workplace.",
        "It can encourage you to connect with supportive coworkers and cultivate a positive professional network."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind to let go of toxic interactions and create a healthier work environment for yourself. Remember, change won't happen overnight, but with consistent use, you can establish new empowering habits and attitudes."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Dealing with toxic coworkers can have a negative impact on your mental well-being and overall job satisfaction. This subliminal is designed to help you disconnect from toxic coworkers and create a healthier work environment. Before you start listening, consider these strategies to empower yourself:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Recognize toxic behaviors: Identify the behaviors and actions of coworkers that contribute to a toxic work environment.",
        "Set boundaries: Establish clear boundaries with toxic coworkers and know what behavior is acceptable and what is not.",
        "Focus on personal growth: Prioritize your personal and professional growth, seeking opportunities that can help you advance and thrive.",
        "Seek support: Reach out to trusted colleagues, friends, or a mentor who can provide guidance and perspective during challenging times."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal can help you develop a stronger sense of self, confidence, and resilience in dealing with toxic coworkers. As you listen regularly, you may find it easier to detach emotionally, set boundaries, and create a healthier work environment for yourself. Take a moment to acknowledge your worth, envision a positive work environment, and let this subliminal assist you in disconnecting from toxic coworkers."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Disconnect From Toxic Coworkers subliminal session! You've taken a powerful step towards creating a healthier work environment for yourself. It's important to reflect on your experience and consider how you can continue to foster a positive atmosphere in your professional life."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Set boundaries: Clearly define what you will and will not tolerate from toxic coworkers.",
        "Seek support: Connect with trusted colleagues or mentors who can provide guidance and advice.",
        "Focus on self-care: Prioritize activities that promote your mental and emotional well-being.",
        "Build a support network: Surround yourself with positive influences both inside and outside of work."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, creating a healthy work environment takes time and effort, but taking steps to distance yourself from toxic coworkers is a significant move towards your own growth and happiness. Stay strong, set boundaries, and surround yourself with positivity. You have the power to create the work environment you deserve!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Disconnect From Toxic Coworkers subliminal help me?`, 
    answer: `The Disconnect From Toxic Coworkers subliminal is designed to help you develop a mindset that allows you to distance yourself from toxic coworkers and create a healthier work environment. By listening to the audio regularly, it can help you build inner strength, confidence, and assertiveness to set boundaries and navigate challenging situations with toxic coworkers more effectively.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Disconnect From Toxic Coworkers subliminal?`, 
    answer: `Results can vary depending on the individual and the specific circumstances. Some people may notice a shift in their mindset and behavior shortly after starting to listen to the subliminal, while others may require more time and consistent listening to fully reprogram their thoughts and responses towards toxic coworkers. We recommend listening to the subliminal daily and consistently for best results.`,
    open: false 
  },
  { 
    question: `Can the Disconnect From Toxic Coworkers subliminal help me improve relationships with my coworkers in general?`, 
    answer: `Yes, the Disconnect From Toxic Coworkers subliminal can indirectly help improve your relationships with coworkers in general by promoting a healthier work environment. As you distance yourself from toxic individuals, you may find more opportunities to develop positive connections with colleagues who align with your values and promote a supportive work culture.`,
    open: false 
  },
  { 
    question: `What should I do if I can't completely disconnect from toxic coworkers?`, 
    answer: `In situations where complete disconnection from toxic coworkers is not possible, it is important to set firm boundaries, prioritize self-care, and focus on developing resilience to their negative influence. The Disconnect From Toxic Coworkers subliminal can still help you build mental strength and strategies to withstand their toxic behavior and maintain your well-being.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Disconnect From Toxic Coworkers subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you are experiencing significant workplace challenges or mental health concerns, it may be beneficial to seek guidance from a mental health professional to address your specific situation.`,
    open: false 
  },
  { 
    question: `Can the Disconnect From Toxic Coworkers subliminal guarantee that toxic coworkers will leave my workplace?`, 
    answer: `While the Disconnect From Toxic Coworkers subliminal can help you develop a healthier mindset and cope better with toxic coworkers, it cannot guarantee their departure from your workplace. The subliminal is focused on empowering you to create positive changes within yourself and navigate challenging situations more effectively.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>