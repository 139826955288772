<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you often find yourself stuck in a cycle of overthinking and indecision, this subliminal can help you overcome analysis paralysis. By targeting your subconscious mind, it aims to develop clarity and empower you to make decisions confidently and efficiently."
    },
    {
      type: 'heading',
      text: 'By using this subliminal, you can:'
    },
    {
      type: 'list',
      items: [
        "Break free from the cycle of overthinking and indecision.",
        "Develop a deeper sense of self-trust and confidence in your decision-making abilities.",
        "Cultivate a calm and focused mindset, enabling you to make clear and informed choices.",
        "Overcome fear and hesitation, allowing you to take action and move forward.",
        "Embrace a proactive approach to decision-making, leading to increased productivity and progress."
      ]
    },
    {
      type: 'paragraph',
      text: "With regular use of this subliminal, you can reprogram your subconscious mind to let go of analysis paralysis and embrace a more decisive mindset. You'll be able to make choices more confidently and efficiently, freeing yourself from the stress and indecision that can hold you back. Remember, consistent practice is important for lasting change."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "If you often find yourself stuck in a loop of overthinking and indecision, this subliminal can help you break free from analysis paralysis and embrace a more confident and decisive mindset. Before you begin listening, consider these preparation tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Identify your decision-making goals: Determine the areas where you tend to get stuck in overthinking and establish a clear intention to overcome analysis paralysis.",
        "Practice mindfulness: Engage in activities that promote present-moment awareness, such as meditation or deep breathing, to calm the mind and reduce overthinking.",
        "Visualize yourself taking action: Imagine yourself making decisions confidently and efficiently, embracing a decisive mindset.",
        "Hold your intention in mind: Keep your goal of overcoming analysis paralysis at the forefront while listening to the subliminal affirmations."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you may notice a shift in your mindset, feeling more empowered to make decisions with clarity and take action without hesitation. Embrace this opportunity to let go of overthinking and step into a more confident and decisive version of yourself. It's time to break free from analysis paralysis and start making progress towards your goals."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Overcome Analysis Paralysis subliminal! By exploring this topic, you've taken an important step towards gaining clarity and making decisions confidently. It's now time to reflect on your experience and continue to grow in your ability to take action without hesitation."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your decision-making process: Consider writing down your thoughts and insights in a journal.",
        "Break down tasks: Divide complex decisions into manageable steps to make the process less overwhelming.",
        "Practice mindfulness: Engage in activities like meditation or deep breathing to calm your mind and enhance focus.",
        "Embrace imperfection: Understand that not all decisions will be perfect, and it's okay to learn from mistakes."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming analysis paralysis is a journey that requires practice and self-compassion. Embrace the process of decision-making, celebrate the progress you make, and continue to take action with confidence. You have the power to make choices that align with your goals and dreams. Keep moving forward!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What is analysis paralysis?`, 
    answer: `Analysis paralysis refers to a state where a person is unable to make decisions or take action due to overthinking, having too many options, or feeling overwhelmed by information. It can hinder progress and productivity, leading to missed opportunities and increased stress.`,
    open: false
  },
  { 
    question: `How can the Overcome Analysis Paralysis subliminal help me?`, 
    answer: `The Overcome Analysis Paralysis subliminal is designed to reprogram your subconscious mind and help you develop the mental clarity and confidence needed to make decisions confidently and efficiently. By listening to the subliminal regularly, you can overcome the fear of making the wrong choice, let go of perfectionism, and trust in your ability to take decisive action.`,
    open: false 
  },
  { 
    question: `How long does it take to overcome analysis paralysis using the subliminal?`, 
    answer: `The time it takes to overcome analysis paralysis can vary from person to person. Some may experience positive changes and greater decisiveness almost immediately, while others may require more time and consistent listening to fully reprogram their mindset. We recommend listening to the subliminal daily and consistently for best results.`,
    open: false 
  },
  { 
    question: `What can I do to support the effectiveness of the Overcome Analysis Paralysis subliminal?`, 
    answer: `To enhance the effectiveness of the subliminal, it can be helpful to practice mindfulness, focus on setting clear goals, and prioritize self-reflection. Simplifying decisions, using visualization techniques, and breaking tasks into smaller manageable steps can also support the process of overcoming analysis paralysis. It is important to be patient with yourself and celebrate even small steps of progress.`,
    open: false 
  },
  { 
    question: `Is there a risk of becoming impulsive or making rash decisions with the subliminal?`, 
    answer: `No, the Overcome Analysis Paralysis subliminal is not designed to make you impulsive or encourage rash decision-making. Its purpose is to help you develop the clarity and confidence needed to make decisions confidently and efficiently. It aims to strike a balance between thoughtful consideration and taking action without hesitation.`,
    open: false 
  },
  { 
    question: `Can the subliminal help me overcome analysis paralysis in professional and personal areas of my life?`, 
    answer: `Yes, the Overcome Analysis Paralysis subliminal can be beneficial in both professional and personal areas of your life. It can help you overcome decision-making challenges in your career, relationships, and other aspects by reprogramming your mindset and building the confidence to take action.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>