<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're struggling with suicidal thoughts, it's important to remember that you're not alone and there is help available. This subliminal is designed to provide the support and tools you need to overcome these thoughts and find hope for a brighter future."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can assist you:'
    },
    {
      type: 'list',
      items: [
        "Providing a safe and comforting space to explore and address your emotions.",
        "Helping to reframe negative thoughts and replace them with positive and hopeful ones.",
        "Promoting self-care and self-compassion as essential components of your journey towards healing.",
        "Offering guidance and encouragement to reach out for professional help and support from loved ones.",
        "Empowering you to develop coping strategies and resilience, allowing you to better navigate difficult times."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming suicidal thoughts and preventing suicide requires a holistic approach, and this subliminal can be an important part of your journey. Use it regularly, and don't hesitate to seek professional help and support from trained mental health professionals. You deserve a future filled with hope and happiness, and there are people who want to assist you in finding it."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Taking steps to stop suicidal thoughts and prevent suicide is a brave and important decision. This subliminal is designed to provide you with the support and tools you need to find hope and create a brighter future. Before you begin, consider the following suggestions to help maximize the effectiveness of this subliminal:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Reach out for support: Talk to a trusted friend, family member, or mental health professional about what you're experiencing.",
        "Create a safety plan: Develop a list of coping strategies, distractions, and emergency contacts that you can rely on when you're feeling overwhelmed.",
        "Engage in self-care: Prioritize activities that bring you joy, calmness, and relaxation to help manage stress and promote emotional well-being.",
        "Remind yourself of your worth: Practice self-compassion and challenge negative thoughts by focusing on your strengths and achievements."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is a tool to support your healing journey, but it is important to seek professional help if you are in immediate crisis. Reach out to a mental health helpline or emergency services if you are at risk. You are not alone, and there is support available. Take a moment to care for yourself, reach out for help, and let this subliminal guide you towards a future filled with hope and well-being."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Stop Suicidal Thoughts and Suicide Prevention session! By seeking support and working towards stopping these thoughts, you have taken a brave and important step towards finding hope and creating a brighter future. It's essential to reflect on your experience and continue to prioritize your mental health and well-being."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reach out for support: Connect with a trusted friend, family member, or mental health professional to talk about your feelings and experiences.",
        "Create a safety plan: Develop a plan that includes coping strategies and emergency contacts to have in case of crisis.",
        "Engage in self-care: Prioritize activities that promote your mental and emotional well-being, such as practicing mindfulness, exercising, or engaging in hobbies you enjoy.",
        "Educate yourself: Learn about suicide prevention resources, warning signs, and strategies for maintaining mental wellness."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, you're not alone in this journey. Reach out for support when you need it, and continue to take active steps towards your own well-being. With time, care, and support, you can find hope and create a future filled with possibilities. Stay strong!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Stop Suicidal Thoughts and Suicide Prevention program help me?`, 
    answer: `The Stop Suicidal Thoughts and Suicide Prevention program is designed to provide support and tools for individuals struggling with suicidal thoughts. It offers guidance, resources, and encouragement to help you find hope and a brighter future. Through various therapeutic techniques and expert advice, the program aims to empower you with the skills to overcome negative thoughts and develop coping mechanisms.`,
    open: false
  },
  { 
    question: `Is it possible to overcome suicidal thoughts with the help of this program?`, 
    answer: `Yes, it is possible to overcome suicidal thoughts with the help of the Stop Suicidal Thoughts and Suicide Prevention program. It provides an empathetic and understanding approach to help you navigate through difficult times. While everyone's journey is unique, this program aims to provide you with the support and resources needed to find hope, build resilience, and work towards a healthier and happier life.`,
    open: false 
  },
  { 
    question: `What support resources are available through the program?`, 
    answer: `The Stop Suicidal Thoughts and Suicide Prevention program offers a variety of support resources, including online communities, helpline numbers, therapist recommendations, and self-help tools. These resources can provide you with a safe space to connect with others who may be experiencing similar struggles and offer valuable guidance and support.`,
    open: false 
  },
  { 
    question: `Is the program a substitute for professional mental health treatment?`, 
    answer: `The Stop Suicidal Thoughts and Suicide Prevention program is not a substitute for professional mental health treatment. While it provides supportive resources and tools, it is important to seek help from qualified mental health professionals for a comprehensive evaluation and personalized treatment plan. If you or someone you know is in immediate danger, please contact emergency services or a mental health hotline.`,
    open: false 
  },
  { 
    question: `Can the program guarantee that I won't have suicidal thoughts again?`, 
    answer: `The Stop Suicidal Thoughts and Suicide Prevention program aims to provide you with the support, tools, and coping mechanisms to overcome suicidal thoughts and develop a healthier mindset. However, individual experiences and progress may vary. It is important to continue seeking professional help and utilizing the resources provided in the program to build resilience and maintain mental well-being.`,
    open: false 
  },
  { 
    question: `Is there a time limit for the program's effectiveness?`, 
    answer: `The effectiveness of the Stop Suicidal Thoughts and Suicide Prevention program may vary for each individual. Mental health journeys are unique, and the program provides ongoing support and resources to help you on your path towards recovery. It is important to continue utilizing the program's tools and seeking professional help for long-term support and maintenance of your mental well-being.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>