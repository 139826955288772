<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Aging is a natural process, but that doesn't mean we can't take steps to maintain a youthful appearance and minimize the signs of aging. This subliminal is designed to help you do just that by targeting your mindset and practices."
    },
    {
      type: 'heading',
      text: 'By using this subliminal, you can:'
    },
    {
      type: 'list',
      items: [
        "Boost your confidence and self-esteem by embracing your age and feeling comfortable in your skin.",
        "Reduce the appearance of fine lines, wrinkles, and age spots, promoting a more youthful complexion.",
        "Support the health and elasticity of your skin, helping to maintain its firmness and radiance.",
        "Nurture a positive attitude and mindset towards aging, embracing wisdom and experience.",
        "Incorporate healthy habits and lifestyle choices that promote overall well-being and vitality."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, reversing the signs of aging requires a holistic approach that combines mindset, skincare, and healthy habits. By consistently listening to this subliminal and incorporating targeted practices, you can enhance your natural beauty and age gracefully. Embrace the journey and celebrate your timeless beauty!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embracing the journey towards reversing the signs of aging and maintaining a youthful appearance requires dedication and a positive mindset. This subliminal is designed to support you in this endeavor. Before you begin, take a moment to consider the following tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Clearly define your desired outcomes and visualize yourself already embodying a youthful appearance.",
        "Nourish your body: Focus on nourishing foods, hydration, and a healthy lifestyle to support your anti-aging goals from within.",
        "Create a self-care routine: Incorporate practices that enhance your physical and emotional well-being, such as skincare, exercise, and stress reduction techniques.",
        "Adopt a positive mindset: Cultivate beliefs that support your ability to age gracefully and maintain a youthful glow.",
        "Maintain consistency: Commit to regular listening sessions to reinforce positive neural pathways and maximize the benefits of the subliminal affirmations."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal is a powerful tool that can help you align your subconscious mind with your intentions to reverse the signs of aging. With dedication, self-care, and a positive mindset, you can enhance your appearance and radiate youthful vitality. Embrace the transformative journey ahead and let's embark on this rejuvenating experience together."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Anti Aging subliminal! By investing in yourself and committing to reversing the signs of aging, you've taken a significant step towards maintaining a youthful appearance and mindset. Now, it's time to reflect on your experience and continue your journey towards a more vibrant version of yourself."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Evaluate your skincare routine: Assess your current skincare practices and make adjustments to include age-defying products and practices.",
        "Nurture your body: Focus on nourishing your body with a balanced diet, regular exercise, and staying hydrated.",
        "Practice stress management: Incorporate stress-reducing techniques like meditation, deep breathing, or engaging in relaxing activities.",
        "Adopt a positive mindset: Embrace the power of positive thinking and affirmations to cultivate a youthful outlook on life."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, the journey to reversing the signs of aging is ongoing, and consistency is key. Stay committed to your skincare routine, nourish your body, manage stress, and maintain a positive mindset. With dedication and the right mindset, you can embrace the aging process gracefully and radiate youthfulness inside and out. Keep shining!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Anti Aging subliminal help me reverse the signs of aging?`, 
    answer: `The Anti Aging subliminal is designed to reprogram your subconscious mind with positive affirmations related to aging, rejuvenation, and maintaining a youthful appearance. By listening to the subliminal regularly, you can reinforce these beliefs and align your mindset with practices that support anti-aging, such as healthy eating, exercise, skincare, and stress reduction techniques.`,
    open: false
  },
  { 
    question: `Can the Anti Aging subliminal get rid of wrinkles and fine lines?`, 
    answer: `While the Anti Aging subliminal can help you adopt a mindset and lifestyle that supports maintaining a youthful appearance, it is important to note that it cannot magically get rid of wrinkles and fine lines. However, by incorporating the subliminal into your overall anti-aging routine, you can potentially minimize the appearance of wrinkles and fine lines and support the health and vitality of your skin.`,
    open: false 
  },
  { 
    question: `How long does it take to see results from the Anti Aging subliminal?`, 
    answer: `Results may vary depending on individual factors, such as age, genetics, and lifestyle. It is recommended to listen to the Anti Aging subliminal consistently and give it time to influence your mindset and habits. Over time, with consistent use and adherence to a holistic approach to anti-aging, you may notice positive changes in your appearance and overall well-being.`,
    open: false 
  },
  { 
    question: `Is there an age limit for using the Anti Aging subliminal?`, 
    answer: `No, there is no specific age limit for using the Anti Aging subliminal. It can be beneficial to individuals of various ages who want to maintain a youthful appearance and adopt a positive mindset towards aging. It is never too early or too late to start incorporating practices that support healthy aging.`,
    open: false 
  },
  { 
    question: `Can the Anti Aging subliminal replace skincare products and treatments?`, 
    answer: `The Anti Aging subliminal is not intended to replace the use of skincare products and treatments. It is designed to complement and support your existing anti-aging routine. While the subliminal can help you adopt a positive mindset towards aging and support healthy habits, it's still important to use effective skincare products and consult with skincare professionals for optimal results.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with using the Anti Aging subliminal?`, 
    answer: `There are no known risks or side effects associated with using the Anti Aging subliminal. However, if you have any concerns about your skincare routine or general health, it is recommended to consult with a dermatologist or healthcare provider. The subliminal is intended to supplement a holistic approach to anti-aging, not replace necessary medical advice or treatments.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>