<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Dealing with male genital warts can be uncomfortable and stressful, but this subliminal is here to help. It is designed to safely and effectively remove genital warts, and promote healing to ensure a comfortable and healthy experience."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can assist you:"
    },
    {
      type: 'list',
      items: [
        "Targets the subconscious mind to aid in the removal of genital warts.",
        "Promotes the healing process for faster recovery and reduced discomfort.",
        "Provides support and reassurance during the healing journey.",
        "Helps reduce feelings of embarrassment, shame, or anxiety.",
        "Encourages a positive mindset for overall well-being and a healthy experience."
      ]
    },
    {
      type: 'paragraph',
      text: "It's important to remember that this subliminal is a complementary approach and should be used in conjunction with professional medical advice. By incorporating this subliminal into your routine, you can enhance the healing process and promote a positive mindset, making your experience more comfortable and empowering. Remember, healing takes time, but you're not alone on this journey."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Dealing with genital warts can be a challenging and uncomfortable experience, but this subliminal is designed to assist you in safely and effectively removing the warts and promoting healing. Before you start listening, consider these strategies to enhance your journey:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Educate yourself: Research and understand the condition, available treatments, and self-care measures for genital warts.",
        "Consult a healthcare professional: Seek advice from a healthcare provider to ensure you have accurate information and guidance specific to your situation.",
        "Practice self-care: Maintain good hygiene, keep the area clean and dry, and avoid any irritants or activities that may exacerbate the condition.",
        "Focus on healing: While listening, visualize the warts shrinking and your body healing, promoting a comfortable and healthy experience."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal aims to provide support and aid in your journey towards removing genital warts and promoting healing. By listening regularly and following proper self-care measures, you can increase the likelihood of positive outcomes. Remember to be patient and kind to yourself throughout this process. Let's embark on the road to comfort and health together!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Male Genital Warts Removal and Healing subliminal session! By investing in your health and well-being, you've taken a significant step towards addressing and managing male genital warts. Now that you've finished listening, it's important to focus on your post-listening routine to ensure a comfortable and healthy healing process."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Follow professional advice: Consult a healthcare provider for specific guidance on managing male genital warts.",
        "Practice good hygiene: Keep the affected area clean and dry, following proper cleaning techniques.",
        "Avoid sexual activity: Refrain from sexual intercourse until you have healed completely to prevent further complications or transmission.",
        "Be patient and gentle: Allow your body time to heal naturally, avoiding any aggressive methods that may cause harm or irritation."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, healing takes time and varies for each individual. It's important to prioritize your physical and emotional well-being during this process. Focus on self-care, follow professional guidance, and be patient with yourself. You are on the path to experiencing a comfortable and healthy recovery. Take care!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Male Genital Warts Removal and Healing subliminal work?`, 
    answer: `The Male Genital Warts Removal and Healing subliminal works by targeting your subconscious mind to promote the natural healing process and boost your body's immune response against genital warts. The subliminal messages are designed to help you maintain a positive mindset while supporting the eradication of warts and promoting overall genital health. It is important to note that this subliminal is not a substitute for medical advice or treatment.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Male Genital Warts Removal and Healing subliminal?`, 
    answer: `Results can vary depending on the individual and the severity of the condition. Some people may experience positive changes in a short span of time, while others may require more time and consistent listening to see noticeable improvements. It is important to continue listening to the subliminal consistently and follow any recommended medical treatments for best results.`,
    open: false 
  },
  { 
    question: `Can the Male Genital Warts Removal and Healing subliminal replace medical treatment?`, 
    answer: `No, the Male Genital Warts Removal and Healing subliminal is not intended to replace medical advice or treatment. It is designed to support the healing process and promote a positive mindset towards male genital health. It is important to consult with a healthcare professional for proper diagnosis and treatment of genital warts.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Male Genital Warts Removal and Healing subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your genital health or are currently undergoing medical treatment, it is important to consult with your healthcare provider. The subliminal is intended to supplement medical treatment, not replace it.`,
    open: false 
  },
  { 
    question: `Can the Male Genital Warts Removal and Healing subliminal be used as a preventive measure?`, 
    answer: `The Male Genital Warts Removal and Healing subliminal is designed to support the healing process and promote a positive mindset towards male genital health. It is not intended to substitute preventive measures such as safe sex practices and vaccination against human papillomavirus (HPV). It is important to consult with a healthcare professional for appropriate preventive measures.`,
    open: false 
  },
  { 
    question: `Can the Male Genital Warts Removal and Healing subliminal be used alongside medical treatments?`, 
    answer: `Yes, the Male Genital Warts Removal and Healing subliminal can be used alongside medical treatments. It is important to follow the advice and treatment plan provided by your healthcare professional and use the subliminal as a complementary tool to support the healing process and promote a positive mindset.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>