<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Taking self-responsibility is an important step towards personal growth and happiness. This subliminal is designed to help you develop the mindset and habits necessary to take ownership of your actions, choices, and decisions."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support your journey:'
    },
    {
      type: 'list',
      items: [
        "Encouraging self-reflection and introspection to gain a deeper understanding of your own behavior and patterns.",
        "Promoting self-accountability by recognizing that you have the power to shape your own life.",
        "Instilling a sense of empowerment and confidence in your ability to make positive changes.",
        "Supporting you in setting realistic goals and taking the necessary steps towards achieving them.",
        "Enhancing self-discipline and the ability to follow through on commitments and promises."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can rewire your subconscious mind to embrace self-responsibility as a core value in your life. As you take ownership, you may experience increased satisfaction, fulfillment, and a greater sense of control over your own happiness. Remember, change takes time and effort, but with dedication, you can create a life you are proud of."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Taking responsibility for our actions, choices, and decisions is an essential part of personal growth and happiness. This subliminal is designed to help you develop a sense of self-responsibility and accountability. Before you begin listening, consider these tips for maximizing your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your goals: Clarify what areas of your life you want to take more responsibility for and set clear intentions.",
        "Embrace self-reflection: Explore your current patterns of behavior and identify areas where you can improve your level of responsibility.",
        "Set realistic expectations: Understand that change takes time and effort. Be patient and kind to yourself throughout this process.",
        "Maintain focus: While listening to the subliminal, keep your intentions and goals at the top of your mind."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly engaging with this subliminal, you can empower yourself to take charge of your life, make conscious choices, and create the happiness you deserve. Remember, change starts from within, and by embracing self-responsibility, you are taking the first step towards personal growth and fulfillment. Let's embark on this journey together and unlock your true potential."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Self-Responsibility subliminal! By prioritizing ownership and accountability, you're on your way to transforming your life and finding true happiness. It's important to reflect on your progress and continue cultivating self-responsibility in your daily life."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your actions: Take time to assess how your choices and decisions impact your life and those around you.",
        "Practice self-awareness: Pay attention to your emotions, thoughts, and behaviors, and take responsibility for managing them.",
        "Set and honor boundaries: Establish clear boundaries to protect your time, energy, and well-being, and hold yourself accountable to them.",
        "Embrace growth mindset: See challenges as opportunities for growth and take responsibility for learning and evolving.",
      ]
    },
    {
      type: 'paragraph',
      text: "Remember that self-responsibility is a lifelong practice. It requires embracing personal growth and continuously making choices that align with your values and goals. Keep taking small steps each day, and you'll see the positive impact it brings to your life. You have the power to shape your own future, so embrace it and thrive!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What does it mean to take self-responsibility?`, 
    answer: `Taking self-responsibility means acknowledging that you are in control of your own choices, actions, and outcomes. It is about understanding that you have the power to create the life you want and being accountable for your own happiness and success.`,
    open: false
  },
  { 
    question: `Why is self-responsibility important?`, 
    answer: `Self-responsibility is important because it empowers you to shape your own life and take control of your happiness. By taking ownership of your choices and actions, you can set clear goals, make positive changes, and overcome obstacles. It helps you develop resilience, self-discipline, and a sense of personal growth.`,
    open: false 
  },
  { 
    question: `How can I develop self-responsibility?`, 
    answer: `Developing self-responsibility starts with self-awareness and recognizing that you have the power to make choices that align with your values and goals. It involves taking ownership of your mistakes and learning from them, setting realistic and achievable goals, and being proactive in taking necessary actions. Practicing self-reflection, self-discipline, and cultivating a growth mindset can also help you develop self-responsibility.`,
    open: false 
  },
  { 
    question: `Can the Self-Responsibility subliminal make me instantly become more responsible?`, 
    answer: `The Self-Responsibility subliminal is designed to help you develop a mindset that supports self-responsibility. While it can positively influence your subconscious mind, the process of becoming more responsible is an ongoing journey that requires consistent effort and practice. The subliminal can be a valuable tool in reinforcing positive habits and beliefs, but personal growth takes time and commitment.`,
    open: false 
  },
  { 
    question: `Can self-responsibility help me overcome challenges and obstacles in life?`, 
    answer: `Yes, developing self-responsibility can help you overcome challenges and obstacles in life. By acknowledging that you have control over your choices and actions, you can approach difficult situations with a solution-oriented mindset. Taking responsibility allows you to learn from setbacks, adapt, and make necessary changes to achieve your goals.`,
    open: false 
  },
  { 
    question: `Is self-responsibility the same as blaming myself for everything?`, 
    answer: `No, self-responsibility is not about blaming yourself for everything that happens in your life. It is about taking ownership of your choices and actions without being overly self-critical or judgmental. It is about accepting that you have control over your own life and using that power to make positive changes for yourself.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>