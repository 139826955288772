<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Speed reading is a valuable skill that can save you time, enhance your learning, and boost your success in various areas of life. This subliminal is designed to help you acquire the techniques and strategies needed to read faster and improve your reading comprehension."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help you:"
    },
    {
      type: 'list',
      items: [
        "Improving your reading speed, allowing you to cover more material in less time.",
        "Enhancing your reading comprehension, ensuring that you retain and understand the information you read.",
        "Boosting your focus and concentration while reading, helping you stay engaged and absorb information efficiently.",
        "Increasing your confidence as a reader, making reading more enjoyable and rewarding.",
        "Accelerating your learning process, as you can consume and process information at a faster pace."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can train your subconscious mind to adopt new reading habits and techniques that promote speed and comprehension. As you practice these skills, you'll notice improvements in your reading speed and comprehension, leading to greater success in academics, professional endeavors, and personal growth."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Speed reading can be a valuable skill to have, allowing you to consume information faster and enhance your overall reading comprehension. This subliminal is designed to assist you in achieving success in your speed reading endeavors. Before you start listening, consider following these tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Clarify your goals and why you want to improve your speed reading skills.",
        "Create a conducive reading environment: Find a quiet and comfortable space where you can focus without distraction.",
        "Practice concentration techniques: Develop your ability to maintain focus and avoid distractions while reading.",
        "Visualize success: Imagine yourself reading effortlessly, absorbing information quickly, and feeling a sense of accomplishment."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you may notice improvements in your reading speed, comprehension, and overall reading abilities. Embrace the information, techniques, and strategies that come your way, and let go of any self-doubt or limitations. Remember, learning to speed read is a journey, so take a deep breath, set your intention, and get ready to embark on the path to speed reading success."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Speed Reading Success subliminal! By focusing on improving your reading skills, you've taken a significant step towards enhancing your success in various areas of life. As you reflect on your experience and move forward, consider the many benefits that come with the ability to read faster and comprehend better."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice speed reading techniques: Continue to use the strategies you learned to read faster and improve your reading comprehension.",
        "Set reading goals: Challenge yourself by setting specific reading goals, such as finishing a certain number of books each month or exploring new genres.",
        "Expand your vocabulary: Read a wide range of materials to expose yourself to new words and phrases, and actively use them in your daily conversations.",
        "Take breaks and rest: Remember to give your mind a break by taking short breaks during reading sessions and ensuring you prioritize sufficient rest."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, speed reading and improved reading comprehension are skills that require practice and consistency. Embrace the journey and enjoy the benefits that come with being able to absorb information faster and more effectively. Keep up the great work, and continue to broaden your horizons through the power of reading!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How long does it take to see results from the Speed Reading Success subliminal?`, 
    answer: `The time it takes to see results may vary from person to person. Some people may notice improvements in their reading speed and comprehension after just a few sessions, while others may require more time and consistent practice. We recommend listening to the subliminal regularly and incorporating speed reading techniques into your reading routine to maximize its effectiveness.`,
    open: false
  },
  { 
    question: `How can I make the Speed Reading Success subliminal more effective?`, 
    answer: `To enhance the effectiveness of the Speed Reading Success subliminal, we recommend practicing speed reading techniques regularly. This can include using a pacer, focusing on eliminating subvocalization, and increasing reading speed gradually. Additionally, creating a distraction-free reading environment and setting specific reading goals can also help improve your speed and comprehension. Combining the subliminal with practical application of speed reading strategies is key for optimal results.`,
    open: false 
  },
  { 
    question: `Will the Speed Reading Success subliminal make me lose comprehension?`, 
    answer: `No, the Speed Reading Success subliminal is designed to improve both reading speed and comprehension. The subliminal messages aim to enhance your cognitive abilities, focus, and information processing skills, allowing you to read faster while maintaining comprehension. With practice and consistent listening, you can achieve a balance between speed and understanding in your reading.`,
    open: false 
  },
  { 
    question: `Can the Speed Reading Success subliminal help with dyslexia or other learning difficulties?`, 
    answer: `While the Speed Reading Success subliminal may provide some benefits for individuals with learning difficulties, such as dyslexia, it is not a substitute for professional assessment or intervention. If you have specific learning challenges, it is advisable to seek guidance from a qualified specialist who can provide tailored strategies and support.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Speed Reading Success subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, it is important to practice safe reading techniques and avoid pushing yourself too hard. If you experience any discomfort or eye strain while practicing speed reading, it is advisable to take breaks and consult with a healthcare professional if needed.`,
    open: false 
  },
  { 
    question: `Is it possible to become a speed reader overnight with the Speed Reading Success subliminal?`, 
    answer: `No, becoming a speed reader requires practice, dedication, and consistent effort. While the Speed Reading Success subliminal can help improve reading speed and comprehension, it is important to set realistic expectations and be patient with yourself. Gradual progress over time is more sustainable and provides lasting results.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>