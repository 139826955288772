<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Becoming a successful project manager requires a combination of skills, knowledge, and mindset. This subliminal is designed to assist you in developing these qualities, enabling you to effectively manage teams, resources, and timelines to achieve project success."
    },
    {
      type: 'heading',
      text: 'Here are some key areas where this subliminal can be beneficial:'
    },
    {
      type: 'list',
      items: [
        "Improving your leadership skills and developing a strong managerial mindset.",
        "Enhancing your communication abilities to effectively collaborate with team members, stakeholders, and clients.",
        "Learning effective project planning and organization techniques to ensure projects are delivered on time and within budget.",
        "Building problem-solving and decision-making skills to handle challenges and obstacles that arise during project execution.",
        "Developing the ability to adapt to change and manage risks effectively.",
        "Fostering a positive and motivating work environment to keep team members engaged and productive.",
        "Gaining confidence in your abilities to lead and deliver successful projects."
      ]
    },
    {
      type: 'paragraph',
      text: "Through regular listening, this subliminal can help reprogram your subconscious mind, enabling you to embody the qualities and skills needed to excel as a project manager. As you develop these attributes, you'll find yourself better equipped to overcome challenges, lead teams, and achieve project success. Remember, consistent practice and application of learning are key to becoming a successful project manager."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Becoming a successful project manager requires a combination of skills, mindset, and dedication to your craft. This subliminal is designed to support your journey towards achieving success in project management. Before you begin listening, consider these tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your goals: Clearly define what success means to you as a project manager, whether it's delivering projects on time, within budget, or exceeding client expectations.",
        "Embrace a growth mindset: Believe in your ability to continuously learn and develop the necessary skills and knowledge to excel in project management.",
        "Focus on effective communication: Recognize the importance of clear and concise communication, both in written and verbal form, to collaborate with teams and stakeholders.",
        "Manage your time wisely: Prioritize tasks, set deadlines, and organize your schedule to ensure efficient project management.",
        "Visualize success: Imagine yourself successfully managing projects, leading teams, and achieving project goals."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal will help reprogram your subconscious mind, allowing you to tap into your full potential as a project manager. As you listen regularly, you may find yourself becoming more confident, focused, and skilled in managing projects. Get ready to embark on an empowering journey towards success as a project manager!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Become a Successful Project Manager session! By dedicating time and effort to developing your project management skills and mindset, you are taking the necessary steps to excel in this role. Reflect on your experience and the potential impact it can have on your professional journey."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Determine your short-term and long-term objectives as a project manager.",
        "Continuously learn: Stay updated with industry trends and best practices to enhance your knowledge and expertise.",
        "Build effective communication skills: Practice active listening, clear articulation, and concise written communication.",
        "Embrace problem-solving: Develop a proactive approach to identify and resolve project-related challenges."
      ]
    },
    {
      type: 'paragraph',
      text: "Becoming a successful project manager is a continuous journey of growth and learning. Embrace each opportunity to lead and manage projects, and consistently work towards improving your skills. Remember, with the right mindset and dedication, you have the potential to achieve remarkable success as a project manager. Keep striving for excellence!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What are the key skills required to become a successful project manager?`, 
    answer: `Becoming a successful project manager requires a combination of technical and soft skills. Some key skills include communication, leadership, problem-solving, time management, and organizational skills. It is also important to have a strong understanding of project management methodologies and tools to effectively plan, execute, and monitor projects.`,
    open: false
  },
  { 
    question: `How can I improve my project management skills?`, 
    answer: `To improve your project management skills, you can consider pursuing certifications such as PMP (Project Management Professional) or CAPM (Certified Associate in Project Management). These certifications provide a comprehensive understanding of project management principles, methodologies, and best practices. Additionally, gaining practical experience through managing projects or taking on project management roles can help you develop and refine your skills.`,
    open: false 
  },
  { 
    question: `What are some common challenges faced by project managers?`, 
    answer: `Project managers often face challenges such as scope creep, resource constraints, communication breakdowns, and managing conflicting stakeholder expectations. It is important for project managers to proactively identify and address these challenges through effective planning, communication, and problem-solving strategies. Adapting to change and being flexible in managing unexpected obstacles is also crucial.`,
    open: false 
  },
  { 
    question: `How can I effectively manage project timelines and deadlines?`, 
    answer: `To effectively manage project timelines and deadlines, it is important to create a realistic project schedule with clear milestones and deadlines. Breaking down the project into smaller tasks and assigning them to team members with realistic timeframes can help ensure timely completion. Regularly monitoring progress, conducting status meetings, and addressing any issues or delays promptly can also help keep the project on track.`,
    open: false 
  },
  { 
    question: `What are some strategies for managing project teams?`, 
    answer: `Managing project teams involves effective leadership, communication, and collaboration. It is important to establish clear roles and responsibilities, foster a positive team culture and motivation, provide feedback and support to team members, and promote effective communication channels. Building strong relationships and trust within the team can also contribute to better teamwork and project success.`,
    open: false 
  },
  { 
    question: `How can I handle project risks and mitigate them effectively?`, 
    answer: `Handling project risks involves identifying potential risks, assessing their impact and likelihood, and developing strategies to mitigate or minimize their impact. Creating a risk register, conducting regular risk assessments, and implementing risk response plans can help proactively manage risks. It is also important to continuously monitor and reassess risks throughout the project lifecycle.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>