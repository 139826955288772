<template>
  <div>
    <Support />
  </div>
</template>

<script>
import Support from '@/views/Main/Support'
export default {
  components: {
    Support
  }
}
</script>