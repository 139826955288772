<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you have a passion for singing and want to improve your talent and skills, this subliminal is here to support you on your journey. By targeting your subconscious mind, it helps enhance your singing abilities and boost your confidence as a singer."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help:'
    },
    {
      type: 'list',
      items: [
        "Improving vocal range, control, and technique to hit high notes and sing with precision.",
        "Enhancing breath control, stamina, and projection for powerful and engaging performances.",
        "Boosting self-confidence and overcoming stage fright or performance anxiety.",
        "Developing a strong sense of pitch and the ability to stay in tune consistently.",
        "Promoting a positive and disciplined mindset towards practice, growth, and learning.",
        "Fostering a deep connection with music and the emotions it conveys in your singing.",
        "Refining your vocal tone, timbre, and diction for a more captivating and professional sound."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, becoming a better singer takes time, practice, and dedication. By listening to this subliminal regularly, you can rewire your subconscious mind and unlock your full singing potential. Embrace the journey and enjoy the progress you make along the way!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Unleashing your singing talent and enhancing your skills can be an exciting and transformative journey. This subliminal is designed to support you in becoming a better and more confident singer. Before you start, consider these tips to maximize your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Find a quiet and comfortable space: Create an environment where you can fully immerse yourself in the subliminal affirmations without distractions.",
        "Set clear singing goals: Determine what you want to achieve with your voice – range expansion, vocal control, or performance confidence.",
        "Visualize success: Imagine yourself singing flawlessly, with passion and ease, captivating your audience with your voice.",
        "Practice mindfulness: Stay present and engaged, fully absorbing the positive subliminal messages as you listen."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to unlock your singing potential and help you grow as an artist. As you listen regularly, you may find your voice becoming stronger, more versatile, and filled with emotion. Embrace the opportunity to connect with your true voice, and let your singing talent shine. Now, take a deep breath, set your intentions, and let's start this transformative journey towards becoming a better singer."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Singing Talent and Skills Enhancement subliminal session! By dedicating time to developing your singing talent and skills, you have taken a significant step towards becoming a more confident and skilled singer. Now it's time to reflect on your experience and continue your growth and progress in this journey."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice consistently: Set aside regular practice sessions to strengthen your voice and improve your singing technique.",
        "Record yourself: Use a recording device to capture your singing and analyze areas for improvement.",
        "Seek feedback: Share your recordings with trusted individuals who can provide constructive criticism and guidance.",
        "Explore different genres: Challenge yourself by trying out different musical genres to expand your vocal range and versatility."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, becoming a better singer requires dedication and perseverance. Embrace the journey and enjoy the process of honing your singing talent and skills. With consistent practice and a passion for growth, you have the ability to become the confident and proficient singer you aspire to be. Keep singing and let your voice shine!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Can the Singing Talent and Skills Enhancement subliminal help me become a better singer?`, 
    answer: `Yes, the Singing Talent and Skills Enhancement subliminal is designed to help you develop and enhance your singing talent and skills. By listening to the subliminal regularly, you can improve your vocal range, pitch accuracy, breath control, and overall singing technique. The subliminal messages aim to reprogram your subconscious mind, allowing you to tap into your full singing potential and gain confidence in your abilities.`,
    open: false
  },
  { 
    question: `How often should I listen to the Singing Talent and Skills Enhancement subliminal?`, 
    answer: `For optimal results, we recommend listening to the Singing Talent and Skills Enhancement subliminal daily. Consistency is key in reprogramming your subconscious mind, so regular and repeated exposure to the subliminal messages can help maximize your progress and growth as a singer.`,
    open: false 
  },
  { 
    question: `Will the Singing Talent and Skills Enhancement subliminal teach me how to sing?`, 
    answer: `The Singing Talent and Skills Enhancement subliminal is not a singing lesson or tutorial. It is designed to work in conjunction with your singing practice and learning efforts. The subliminal messages aim to enhance your natural singing abilities, improve your vocal technique, and increase your confidence as a singer. If you want to learn how to sing, we recommend seeking guidance from a vocal coach or enrolling in singing lessons.`,
    open: false 
  },
  { 
    question: `Can the Singing Talent and Skills Enhancement subliminal help with stage fright?`, 
    answer: `Yes, the Singing Talent and Skills Enhancement subliminal can help with stage fright by boosting your confidence and reducing performance anxiety. The subliminal messages target your subconscious mind, helping you develop a positive and confident mindset when performing in front of an audience. With consistent listening, you may experience reduced stage fright and increased comfort on stage.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Singing Talent and Skills Enhancement subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Singing Talent and Skills Enhancement subliminal. However, if you have any concerns about your vocal health or any pre-existing conditions, it's advisable to consult with a vocal coach or healthcare professional before making any changes to your singing routine.`,
    open: false 
  },
  { 
    question: `Will the Singing Talent and Skills Enhancement subliminal assist me in becoming a professional singer?`, 
    answer: `The Singing Talent and Skills Enhancement subliminal is designed to help you develop and enhance your singing talent and skills, including vocal technique, range, and confidence. While it can be a valuable tool in your journey towards becoming a professional singer, it is important to note that professional success may also depend on other factors such as dedication, training, opportunities, and industry connections.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>