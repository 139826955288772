<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Having a positive and strong relationship with your siblings is important for your overall happiness and well-being. This subliminal is designed to help you build that bond through effective communication and understanding."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help:'
    },
    {
      type: 'list',
      items: [
        "Improving your communication skills with your siblings, leading to better understanding and fewer misunderstandings.",
        "Enhancing empathy and fostering a deeper understanding of each other's perspectives.",
        "Strengthening the bond and creating lifelong connections by fostering love, respect, and appreciation.",
        "Promoting forgiveness and letting go of past conflicts or grievances.",
        "Encouraging open and honest conversations that promote healthy conflict resolution.",
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind to embrace positive behaviors and attitudes towards your siblings. Over time, this can lead to a stronger, healthier, and more fulfilling relationship with your brothers and sisters. Remember, building a positive relationship takes effort and patience, but it's worth it for the lifelong connection and love you can create."
    }
  ]
},
            {
  id: 'before-listening',
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Developing a positive and strong relationship with your siblings can bring joy and support to your life. This subliminal is designed to help you enhance your bond with your siblings through effective communication and understanding. Before you start listening, consider these suggestions to get the most out of this experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your relationship: Take a moment to think about how you currently interact with your siblings. Identify areas of improvement and envision a more positive connection.",
        "Practice empathy: Put yourself in your siblings' shoes and try to understand their perspectives and feelings. This will enhance communication and foster mutual understanding.",
        "Open communication: Prioritize open and honest conversations. Create an atmosphere of trust and approachability with your siblings, allowing for better communication and fewer misunderstandings.",
        "Focus on the positive: Shift your attention towards the positive aspects of your relationship with your siblings. Recognize and appreciate their unique qualities and strengths.",
        "Visualize a strong bond: Imagine a future where you have a strong and supportive relationship with your siblings. Picture moments of joy, understanding, and lifelong connections."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to help you build a positive and lasting relationship with your siblings. As you listen regularly, you may find yourself more open-minded, patient, and understanding in your interactions. Embrace this opportunity to strengthen your bond with your siblings and create lifelong connections. Let's begin this journey together!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Positive Relationship with Siblings subliminal! By focusing on improving your relationship with your siblings, you have taken a step towards building a strong and lasting bond filled with love, understanding, and connection. Reflecting on this experience can help you continue nurturing your relationships as you move forward."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice effective communication: Be open, honest, and kind when expressing your thoughts and feelings to your siblings.",
        "Show empathy and understanding: Make an effort to put yourself in your siblings' shoes to better understand their perspective.",
        "Spend quality time together: Plan activities or outings that allow you to bond and create positive memories.",
        "Resolve conflicts peacefully: When disagreements arise, seek resolution through calm and respectful conversations."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, building a positive relationship with your siblings is an ongoing process that requires effort from both sides. Embrace the opportunity to grow together, celebrate your differences, and cherish the special bond you share. With effective communication and understanding, you can create lifelong connections and a sense of belonging within your family. Keep nurturing your relationships and enjoy the journey!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Positive Relationship with Siblings subliminal help improve my relationship with my siblings?`, 
    answer: `The Positive Relationship with Siblings subliminal is designed to reprogram your subconscious mind, promoting positive thoughts, emotions, and behaviors towards your siblings. It can help you develop better communication skills, empathy, and understanding, leading to healthier relationships. By consistently listening to the subliminal, you may notice positive changes in your interactions with your siblings and a stronger bond between you.`,
    open: false
  },
  { 
    question: `How long does it take to see improvements in my relationship with my siblings using the subliminal?`, 
    answer: `The time it takes to see improvements in your relationship with your siblings may vary depending on various factors, including your current dynamics and the effort you put into applying the positive changes inspired by the subliminal. Consistent listening to the subliminal and actively practicing better communication and understanding can accelerate the process. We recommend listening daily and being patient as gradual changes occur over time.`,
    open: false 
  },
  { 
    question: `Can the Positive Relationship with Siblings subliminal resolve deep-rooted conflicts between me and my siblings?`, 
    answer: `While the Positive Relationship with Siblings subliminal can help improve your relationship with your siblings, it is not a substitute for addressing deep-rooted conflicts. In cases of significant conflicts, seeking professional help, such as family therapy, may be beneficial. The subliminal can complement such therapy approaches by promoting a more positive mindset and facilitating open and effective communication.`,
    open: false 
  },
  { 
    question: `Is it necessary for my siblings to also listen to the subliminal for it to be effective?`, 
    answer: `While it is not necessary for your siblings to listen to the subliminal for it to be effective, their active participation can enhance the overall results. Improving relationships is a two-way process, and open communication with your siblings about your personal growth goals and the subliminal's benefits may encourage their involvement. However, positive changes in your behavior and mindset can still positively impact your relationship, even if your siblings do not listen to the subliminal directly.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the Positive Relationship with Siblings subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, it is essential to apply the positive changes inspired by the subliminal consciously and mindfully. It is also important to remember that personal growth and relationship improvement require effort and willingness from all parties involved.`,
    open: false 
  },
  { 
    question: `Can the Positive Relationship with Siblings subliminal help with sibling rivalry?`, 
    answer: `Yes, the Positive Relationship with Siblings subliminal can help address sibling rivalry by promoting understanding, empathy, and healthier communication. It can assist in shifting the focus from competition to cooperation and fostering a supportive and loving bond between siblings. Consistently listening to the subliminal and implementing its positive suggestions can contribute to reducing rivalry and building a more positive and harmonious relationship.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>