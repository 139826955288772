<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're feeling stressed, anxious, or overwhelmed, this subliminal can provide you with the relief you need. By targeting your subconscious mind, it helps promote relaxation, reduce anxiety, and bring peace of mind to your daily life."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Finding relief from stress and tension, allowing you to feel more at ease.",
        "Reducing anxiety and overwhelming thoughts, promoting a sense of calmness.",
        "Enhancing your ability to cope with daily challenges and setbacks.",
        "Improving your overall well-being and quality of life.",
        "Encouraging a positive mindset and fostering emotional healing."
      ]
    },
    {
      type: 'paragraph',
      text: "Through regular listening, this subliminal can help rewire your subconscious mind, enabling you to better manage stress, anxiety, and overwhelm. As you incorporate these positive affirmations into your daily routine, you may start to experience a greater sense of relaxation, peace, and emotional well-being. Remember, practice and consistency are key on your journey to finding inner tranquility."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Finding relief from stress and anxiety is essential for your overall well-being, and this subliminal is designed to help you achieve that. Before you start listening, consider these suggestions to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a calm environment: Find a quiet space where you can relax and focus on the subliminal affirmations.",
        "Set your intention: Clarify your desire for stress relief, relaxation, and peace of mind.",
        "Take slow, deep breaths: Before pressing play, take a few moments to breathe deeply, inhaling calmness and exhaling tension.",
        "Open yourself to healing: Allow the subliminal messages to penetrate your subconscious mind and support your journey towards stress relief and healing."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you may notice a shift in your mindset and an increased sense of calm and relaxation. Embrace this opportunity to prioritize your mental and emotional well-being. Take a moment now to set your intention, relax your mind and body, and let the healing begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the General Stress Relief and Healing subliminal! By investing in your well-being and taking steps to find relief from stress and anxiety, you're prioritizing your mental and emotional health. As you wrap up this session, take a moment to reflect on your experience and continue your journey towards relaxation and peace of mind in your daily life."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice mindfulness: Incorporate mindfulness exercises into your daily routine to cultivate a sense of calm and awareness.",
        "Engage in self-care: Treat yourself with activities that promote self-love and relaxation, such as taking baths or practicing gratitude.",
        "Set boundaries: Learn to say no to excessive commitments and prioritize self-care.",
        "Connect with nature: Spend time outdoors and immerse yourself in the healing power of nature.",
        "Reach out for support: If needed, don't hesitate to seek professional help or talk to a trusted friend or family member for support."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, managing stress is an ongoing process, and it's okay to ask for help when needed. By incorporating these tips into your life, you're taking important steps towards finding relief, healing, and overall well-being. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the General Stress Relief and Healing subliminal help me?`, 
    answer: `The General Stress Relief and Healing subliminal is designed to help you find relief from stress, anxiety, and overwhelm. By listening to the subliminal regularly, you can experience a sense of relaxation, peace of mind, and a reduction in stress levels. The subliminal messages work to positively influence your subconscious mind, promoting a more calm and balanced mental state.`,
    open: false
  },
  { 
    question: `How long do I need to listen to the General Stress Relief and Healing subliminal to see results?`, 
    answer: `The time it takes to see results from the General Stress Relief and Healing subliminal can vary from person to person. Some individuals may notice positive changes right away, while others may require more time and consistent listening to fully benefit from the subliminal messages. We recommend listening daily and consistently for best results.`,
    open: false 
  },
  { 
    question: `Can the General Stress Relief and Healing subliminal replace therapy or professional help?`, 
    answer: `No, the General Stress Relief and Healing subliminal is not intended to replace therapy or professional help for individuals dealing with serious mental health conditions. It is designed to complement your existing self-care practices and promote a positive mindset towards stress relief and healing. If you are experiencing severe stress or mental health issues, it is important to seek professional assistance.`,
    open: false 
  },
  { 
    question: `Will the General Stress Relief and Healing subliminal help with physical pain or ailments?`, 
    answer: `While the General Stress Relief and Healing subliminal can help reduce stress levels and promote relaxation, it is not specifically designed to address physical pain or ailments. However, by reducing stress and promoting a positive mindset, it may indirectly contribute to overall well-being and potentially alleviate some physical symptoms associated with stress.`,
    open: false 
  },
  { 
    question: `Is it safe to listen to the General Stress Relief and Healing subliminal while doing other tasks?`, 
    answer: `Yes, it is safe to listen to the General Stress Relief and Healing subliminal while doing other tasks. The subliminal messages are designed to work on a subconscious level and do not require your full attention. You can listen to it while working, studying, doing household chores, or engaging in any other activities.`,
    open: false 
  },
  { 
    question: `Can I listen to the General Stress Relief and Healing subliminal at any time of the day?`, 
    answer: `Yes, you can listen to the General Stress Relief and Healing subliminal at any time of the day that is convenient for you. It can be beneficial to incorporate it into your daily routine, whether it's in the morning, during breaks, or before bed, to help maintain a calmer and more relaxed state of mind throughout the day.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>