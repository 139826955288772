<template>
  <div>
    <Table title="Promo Codes" itemkey="code" :loading="loading" :items="items" :headers="headers" :select="selected"
      :total="total" @action="getAction" @updateLimitPage="updateLimitPage" @updateSearch="updateSearch"
      @updateSort="updateSort" />

    <addDialog v-if="dialogType == 'add'" :data="data" :dialog="dialog" @closeDialog="closeDialog" />

    <editDialog v-if="dialogType == 'update'" :data="data" :dialog="dialog" @closeDialog="closeDialog" />

    <deleteDialog v-if="dialogType == 'delete' || dialogType == 'deleteall'" :data="data" :dialog="dialog"
      :selected="selected" @selected="getSelected" @closeDialog="closeDialog" />
  </div>
</template>

<script>
import { getPromoCodes, getPromoCodeFilters, updateToggle } from '@/api/promocode'
import Table from '@/components/Admin/Table.vue'
import Card from '@/components/Card.vue'
import { sorts } from '@/utils/data'
import { getMessage } from '@/utils'

import addDialog from './components/addDialog.vue'
import editDialog from './components/editDialog.vue'
import deleteDialog from './components/deleteDialog.vue'
export default {
  components: {
    Card,
    Table,
    addDialog,
    editDialog,
    deleteDialog
  },
  data: () => {
    return {
      getPromoCodes,
      getPromoCodeFilters,
      getMessage,
      sorts,
      updateToggle,

      data: {},
      dialog: false,
      dialogType: '',

      items: [],
      loading: true,

      selected: [],

      headers: [
        { text: 'Code', value: 'code', sortable: false },
        { text: 'Promo Name', value: 'display_name', sortable: false },
        { text: 'Description', value: 'display_description', sortable: false },
        { text: 'Discount', value: 'adjustment_amount', sortable: false },
        { text: 'Influencer', value: 'influencer', sortable: false },
        { text: 'Expiry Date', value: 'expiry_date', sortable: false },
        { text: 'Action', value: 'update_delete', sortable: false, width: '180' },
      ],

      search: null,
      sort: null,
      limit: 10,
      page: 1,
      total: 0
    }
  },
  created() {
    this.getData()
  },
  watch: {
    sort() {
      this.getData()
    },
    search() {
      this.getData()
    }
  },
  methods: {
    getAction(params) {
      this.dialog = true
      this.data = params.data
      this.dialogType = params.action
      this.selected = params.selected

      if (params.action == 'toggle') {
        if(this.data.toggle==true){
          this.isVisible=0;
        }else{
          this.isVisible=1;
        }
        const data = {
          is_visible: this.isVisible,
          code: this.data.code,
        }
        this.updateToggle(data).then((res) => {
          this.getMessage(this, res)
          this.getData()
        })
      }
    },
    getSelected(data) {
      this.selected = data
    },
    closeDialog() {
      this.dialog = false
      this.dialogType = null
      this.getData()
    },
    getData() {
      this.loading = true
      const data = {
        sort: this.sort,
        page: this.page,
        limit: this.limit,
        search: this.search,
      }

      this.getPromoCodeFilters(data).then((res) => {
        const { promo_code, total_pages } = res

        this.total = total_pages
        this.items = promo_code

        this.loading = false
      })
    },
    updateLimitPage(data) {
      const { page, limit } = data
      this.page = page
      this.limit = limit

      this.getData()
    },
    updateSearch(data) {
      this.search = data
      this.getData()
    },
    updateSort(data) {
      this.sort = data
      this.getData()
    }
  }
}
</script>
