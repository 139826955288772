<template>
  <section>
    <div class="container">
      <div>
        <h1>Wide Product Selections</h1>
        <h5>Broad Content Library. Hundreds of Topics.</h5>
        <h5 class="black-text">
          As the most innovative health and self-development company. Magus can
          help you address the mental health and wellness needs of your
          business. We offer flexibility and convenience to your company with
          innovative and relevant solutions.
        </h5>
      </div>
      <div class="col-10 col-lg-8 mx-auto">
        <div class="row">
          <template v-for="(item, index) in items">
            <div class="wide-card col-6 col-sm-6 col-md-3 col-lg-3 align-center justify-center" :key="index">
              <img class="wide-image" :src="require(`@/assets/images/main/home/3/${item.icon}.png`)" alt=""/>
              <div>
                <h6>{{ item.title }}</h6>
                <p v-html="item.description"></p>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div>
        <button class="button btn btn-primary btn-lg" @click="goToRoute('/wideproductselection')">
          {{ showInterfaceButton }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data: () => {
    return {
      show_interface: false,
      items: [
        {
          title: "Magus for Health",
          description:
            "Boost your emotional well-being with Magus, an app that helps you feel better inside. It's like a friend for your mind and spirit, making you happier.",
          details:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in.",
          icon: "1",
        },
        {
          title: "Magus for Beauty",
          description:
            "Feel more confident and better about yourself with Magus. It's like a self-esteem booster in your pocket, helping you shine.",
          details:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in.",
          icon: "2",
        },
        {
          title: "Magus for Success",
          description:
            "Reach your dreams with Magus. It's like a coach for life that makes your goals come true.",
          details:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in.",
          icon: "3",
        },
        {
          title: "Magus for Wealth",
          description:
            "Bring more money and success into your life with Magus. It's like a lucky charm for your wallet.",
          details:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in.",
          icon: "4",
        },
        {
          title: "Magus for Calmness and Peace",
          description:
            "Relax and find peace with Magus. It's like a soothing friend in your ear, helping you find your peace.",
          details:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in.",
          icon: "5",
        },
        {
          title: "Magus for Romance",
          description:
            "Spice up your love life with Magus. It's like a love potion for your heart, bringing passion to your relationships.",
          details:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in.",
          icon: "6",
        },
        {
          title: "Magus for Self-Development",
          description:
            "Become a better you with Magus. It's like a personal growth buddy, guiding you to be the best you can be.",
          details:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in.",
          icon: "7",
        },
        {
          title: "Magus for Learning",
          description:
            "Learn more and get smarter with Magus. It's like a book that talks to you, helping you grow your knowledge.",
          details:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in.",
          icon: "8",
        },
      ],
    };
  },
  computed: {
    showInterfaceButton() {
      return this.show_interface ? "Back" : "Learn More";
    },
  },
  methods: {
    goToRoute(route) {
      if (this.currentRoute != route) {
        this.$router.push(route).catch((err) => {});
        window.scrollTo(0, 0);
      }
    },
  },
};
</script>

<style scoped>
  h1, p, .black-text {
    line-height: 1.35em;
    color: var(--ma-black-olive);
  }
  h5 {
    line-height: 1.35em;
    color: var(--ma-tufts-blue);
  }
  .black-text {
    padding: 0 2em;
    font-family: "Montserrat-Regular";
  }

.wide-card > .wide-image {
  width: clamp(2.19rem, calc(1.64rem + 3.72vw), 5.75rem);
  height: auto;
  margin-block: 1.5em;
  margin-left: auto;
  margin-right: auto;
}

.wide-card {
  justify-content: center;
  text-align: center;
  & p {
    font-family: "Montserrat-Regular";
    display: none;
  }
}

.wide-card:hover,
.wide-card:focus {
  margin: auto;
  & > div {
    & h6 {
      color: var(--ma-tufts-blue);
    }
    & p {
      display: block;
      color: var(--ma-black-olive);
    }
  }
}

.wide-card:nth-of-type(3),
.wide-card:nth-of-type(4) {
  & img {
    margin-block: 1.75em;
  }
}
</style>
