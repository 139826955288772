<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're experiencing mental pain or emotional wounds, this subliminal can provide the support and healing you need. By targeting your subconscious mind, it aims to alleviate the pain and promote a healthier mindset and overall well-being."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help:'
    },
    {
      type: 'list',
      items: [
        "Providing comfort and relief from mental pain and emotional suffering.",
        "Allowing you to process and heal emotional wounds at your own pace.",
        "Encouraging self-compassion and acceptance, promoting a positive self-image.",
        "Facilitating emotional growth and personal development.",
        "Improving your resilience and ability to cope with difficult emotions or situations."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can gradually release and heal emotional pain, leading to a healthier mindset and improved well-being. Remember, everyone's healing journey is unique, so be patient and kind to yourself as you navigate this process."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Finding relief from mental pain and emotional wounds can be a challenging journey, but this subliminal is designed to support your healing process and promote a healthier mindset. Before you start, consider these suggestions to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a safe space: Find a quiet and comfortable place where you feel secure and free from distractions.",
        "Set your intention: Reflect on your desire to heal and release mental pain, allowing yourself to be open to the transformative power of this subliminal.",
        "Practice self-care: Engage in activities that bring you joy, relaxation, and self-love, ensuring you prioritize your well-being throughout your healing process.",
        "Maintain an open mind: Embrace a mindset of receptiveness and receptivity to the healing messages embedded in this subliminal."
      ]
    },
    {
      type: 'paragraph',
      text: "As you embark on this journey of mental and emotional healing, remember to be patient and kind to yourself. Allow this subliminal to guide you towards a healthier mindset and well-being. With regular listening and a commitment to self-care, you can alleviate mental pain and cultivate a more positive and empowered state of being. Embrace this opportunity for healing and growth, and let the transformative journey begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Mentally Induced Pain Healing session. By embarking on this journey to heal your emotional wounds and cultivate a healthier mindset, you have taken a courageous step towards personal growth and well-being. It's important to reflect on your experience and continue your healing process."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice self-reflection: Take the time to journal and explore your emotions and thoughts.",
        "Seek professional support: Consider reaching out to a therapist or counselor who can guide you through your healing journey.",
        "Practice self-care: Prioritize activities that bring you joy and promote emotional well-being.",
        "Establish healthy boundaries: Identify and set boundaries to protect your emotional well-being.",
        "Practice forgiveness: Work towards forgiving yourself and others to lighten the emotional burden."
      ]
    },
    {
      type: 'paragraph',
      text: "Healing from emotional pain is a process that takes time and effort, but it is worth it. Remember to be kind and patient with yourself throughout the healing journey. Allow yourself to heal, grow, and embrace a healthier mindset and well-being. You are deserving of peace and happiness."
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does Mentally Induced Pain Healing work?`, 
    answer: `Mentally Induced Pain Healing works by utilizing subliminal messages that are designed to access and influence the subconscious mind. These messages help to alleviate mental pain and heal emotional wounds by promoting positive thinking patterns, self-compassion, and resilience. The subliminal aims to rewire negative thought patterns and emotions, leading to a healthier mindset and overall well-being.`,
    open: false
  },
  { 
    question: `How long does it take to experience relief from mental pain?`, 
    answer: `The time it takes to experience relief from mental pain can vary depending on the individual and the specific circumstances. Some people may notice improvements relatively quickly, while others may need more time and consistent listening to the subliminal messages. It is important to be patient with yourself and trust the process as you work towards healing.`,
    open: false 
  },
  { 
    question: `Can Mentally Induced Pain Healing replace therapy or professional help?`, 
    answer: `No, Mentally Induced Pain Healing is not a replacement for therapy or professional help. While the subliminal can be a helpful tool in supporting mental healing, it is essential to seek appropriate professional assistance for more severe or complex mental health issues. Consider using the subliminal as a complementary resource alongside therapy or counseling.`,
    open: false 
  },
  { 
    question: `Can listening to the subliminal worsen my pain or emotions?`, 
    answer: `The subliminal is designed to promote healing and alleviate mental pain. However, everyone's experience may be different. It is possible to feel temporary discomfort or an intensification of emotions as part of the healing process. If you find the subliminal to be overwhelming or distressing, it is recommended to discontinue use and seek professional support.`,
    open: false 
  },
  { 
    question: `How often should I listen to the Mentally Induced Pain Healing subliminal?`, 
    answer: `To maximize the benefits, it is recommended to listen to the subliminal regularly. You may choose to listen once or multiple times a day, depending on your preference and availability. Consistent and dedicated listening can help reinforce positive changes in your thinking patterns and emotional well-being.`,
    open: false 
  },
  { 
    question: `Are there any side effects of using the Mentally Induced Pain Healing subliminal?`, 
    answer: `There are no known side effects associated with using the Mentally Induced Pain Healing subliminal. However, as with any form of self-help or therapy, it is important to listen to your own needs and boundaries. If you experience any adverse effects or discomfort, it is advisable to discontinue use and consult with a healthcare provider or mental health professional.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>