<template>
  <div class="vstack">
    <div
      class="d-flex align-items-end banner-cover p-4"
      :style="`background: url(${cover})`"
    ></div>
    <div class="overlay">
      <div>
        <h1>{{ title }}</h1>
        <p class="text-limit-3">{{ description }}</p>
        <div class="d-flex align-items-center gap-3">
          <div class="playpause">
            <!-- <img
              v-if="isAudioPlaying && isTrackSameWithPlayer"
              src="@/assets/images/user/webplayer/light/pause.png"
              @click="pauseAudios()"
            />
            <img
              v-else
              src="@/assets/images/user/webplayer/light/play.png"
              @click="playAudios()"
            /> -->
            <img
              :class="`${!isDataReady ? 'disabled' : ''}`"
              :src="
                require(`@/assets/images/user/webplayer/light/${toggleText}.png`)
              "
              @click="toggleAudios()"
            />
          </div>
          <div class="favorites">
            <!-- <Favorite :data="track" :text="getText(track.playlist_id)" /> -->
            <TestFavorite :data="track" />
          </div>
          <div class="add" v-if="isTrackAvailable && is_own_playlist != 0">
            <img
              @click="dialog = true"
              style="cursor: pointer"
              src="@/assets/images/user/playlist/add2.png"
            />
          </div>
          <div v-if="is_own_playlist != 0">
            <OwnPlaylistEllipsis :data="track" />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isTrackAvailable"
      class="track pl-5 pr-5"
      :style="`height: ${trackHeight}px`"
    >
      <div class="card p-2 my-2" v-for="(item, index) in tracks" :key="index">
        <div class="d-flex justify-content-between">
          <div
            class="information d-flex align-items-center gap-2"
            @click="getAudios(tracks, index)"
          >
            <div
              class="cover"
              :style="`border-radius: 5px; background: url(${item.cover});`"
            ></div>
            <div class="details mx-2">
              <h6 class="mb-0 text-limit-1">{{ item.title }}</h6>
              <span class="mb-0 text-limit-3">
                {{ item.description }}
              </span>
            </div>
          </div>
          <div
            class="other-button d-flex align-items-center justify-content-end gap-3 p-2"
          >
            <!-- <Favorite :data="item" :index="index" /> -->
            <!-- <Favorite :data="item" :text="getText(item.subliminal_id)" /> -->
            <TestFavorite :data="item" />
            <template>
              <UserEllipsis
                :data="item"
                :is_own="track.is_own_playlist"
                :index="index"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center">
      <img
        class="empty"
        src="@/assets/images/user/playlist/empty_playlist.png"
        alt=""
      />
      <p>Let’s start building your playlist</p>
      <button class="button-add" @click="dialog = true">Add Subliminal</button>
    </div>

    <SubliminalListDialog
      :dialog="dialog"
      :data="track"
      @closeDialog="closeDialog"
    />
  </div>
</template>

<script>
// import { getAudio, playAudio, pauseAudio } from "@/utils/audio";

import Favorite from "@/components/User/Favorite.vue";
import TestFavorite from "@/components/User/UserFavorite.vue";
import UserEllipsis from "@/components/User/UserEllipsis.vue";
import OwnPlaylistEllipsis from "@/components/User/OwnPlaylistEllipsis.vue";

import { getText, toggleData } from "@/utils/favorite.js";
import Dialog from "@/components/Dialog.vue";
import SubliminalListDialog from "@/components/User/SubliminalListDialog.vue";

import { audio } from "@/mixins/audio";
import { mapGetters } from "vuex";

export default {
  mixins: [audio],
  props: {
    isFilter: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },
  data: () => {
    return {
      getText,
      dialog: false,

      toggleText: "play",
    };
  },
  mounted() {
    if (Object.keys(this.track).length == 0) {
      let track = JSON.parse(localStorage.getItem("user_track"));
      this.$store.dispatch("user/getTrack", track);
    }
  },
  components: {
    Dialog,
    SubliminalListDialog,
    Favorite,
    TestFavorite,
    UserEllipsis,
    OwnPlaylistEllipsis,
  },
  computed: {
    favorite() {
      return this.$store.state.favorite.id;
    },
    width() {
      return this.$store.state.screen.width;
    },
    height() {
      return this.$store.state.screen.height;
    },
    audios() {
      return this.$store.state.audio.datas;
    },
    recentplayed() {
      return this.$store.state.recent.played.data;
    },
    title() {
      return this.track.title;
    },
    color() {
      return this.track.color;
    },
    cover() {
      if (this.track.is_own_playlist == 0) {
        return this.track.cover;
      } else {
        return this.getBackground(this.track);
      }
    },
    is_own_playlist() {
      return this.track.is_own_playlist;
    },
    description() {
      return this.track.description;
    },
    playlist_id() {
      return this.track.playlist_id;
    },
    track() {
      return this.$store.state.user.track;
    },
    tracks() {
      return this.track.subliminals;
    },
    isTrackAvailable() {
      return this.tracks != null || this.tracks != undefined
        ? this.tracks.length > 0 || Object.keys(this.tracks).length > 0
        : false;
    },
    isTrackSameWithPlayer() {
      return (
        this.$store.state.audio.playlist.track.id == this.playlist_id &&
        this.track.subliminals.findIndex(
          (item) => item.subliminal_id == this.recentplayed[0].subliminal_id
        ) > -1
      );
    },
    subscriptionId() {
      return this.$store.state.subscription.id;
    },
    user() {
      return JSON.parse(this.user);
    },
    isFavoriteUpdated() {
      return this.$store.state.audio.favorite.update;
    },
    favoriteId() {
      return this.$store.state.audio.favorite.id;
    },
    trackHeight() {
      return this.width < 990
        ? this.isPlayerAvailable // mobile
          ? this.height - 510 // player show
          : this.height - 455 // player hide
        : this.isPlayerAvailable // web
        ? this.height - 455 // player show
        : this.height - 510; // player hide
    },
    playlistTrack() {
      return this.$store.state.audio.playlist.tracks;
    },
    playlistType() {
      return this.$store.state.audio.playlist.type;
    },
    isRouteUserOverview() {
      return this.$route.path == "/user/overview";
    },
    isPlayerAvailable() {
      return this.playlistTrack.length > 0;
    },
    ...mapGetters({
      isDataReady: "newaudio/getDataStatus",
      isAudioPlaying: "newaudio/getPlayingStatus",
    }),
  },
  watch: {
    isFavoriteUpdated(val) {
      let self = this;
      setTimeout(() => {
        if (val) {
          self.getTrackData();
        }
      }, 0);
    },
    isAudioPlaying(val) {
      if (
        val &&
        JSON.stringify(this.tracks) === JSON.stringify(this.playlistTrack)
      ) {
        this.toggleText = "pause";
      } else {
        this.toggleText = "play";
      }
    },
  },
  methods: {
    goToRoute(route) {
      this.$router.push(route);
    },
    closeDialog(data) {
      this.dialog = data;
    },
    getBackground(params) {
      if (params.hasOwnProperty("subliminals")) {
        const count = params.subliminals.length;
        if (count > 0) {
          let index = Math.floor(Math.random() * count);
          return params.subliminals[index].cover;
        }
      }
      return params.cover;
    },
    playAudios() {
      let index = -1;

      if (this.recentplayed.length > 0) {
        index = this.track.subliminals.findIndex(
          (item) => item.subliminal_id == this.recentplayed[0].subliminal_id
        );
      }

      if (index == -1) {
        index = 0;
        this.$store.dispatch("audio/getPlaylistTrackId", this.playlist_id);
        this.getAudios(this.track, index);
      } else {
        this.playAudio();
      }
    },
    pauseAudios() {
      this.pauseAudio();
    },
    toggleAudios() {
      if (JSON.stringify(this.tracks) === JSON.stringify(this.playlistTrack)) {
        this.toggleAudio();
      } else {
        this.getAudios(this.tracks, 0);
      }
    },
    getAudios(data, index) {
      if (this.playlistType != "recent") {
        this.$store.dispatch("audio/getPlaylistType", "track");
      }

      if (JSON.stringify(this.playlistTrack) != JSON.stringify(data)) {
        this.$store.dispatch("audio/getPlaylistTrack", data);
      }

      this.$store.dispatch("newaudio/currentTime", 0);
      this.$store.dispatch("newaudio/subliminalIndex", index);
      this.initializeData(data, index, true);

      this.$store.dispatch("newaudio/playedStatus", true);
    },
    getTrackData() {
      let tracks;
      let track = { ...this.track };
      if (track.playlist_id == this.favoriteId) {
        track.is_liked = track.is_liked == 1 ? 0 : 1;

        tracks = this.tracks.map((item) => {
          return { ...item, is_liked: item.is_liked == 1 ? 0 : 1 };
        });
      } else {
        tracks = this.tracks.map((item) => {
          if (item.subliminal_id == this.favoriteId) {
            return { ...item, is_liked: item.is_liked == 1 ? 0 : 1 };
          }

          if (item.playlist_id == this.favoriteId) {
            return { ...item, is_liked: item.is_liked == 1 ? 0 : 1 };
          }
        });
      }

      track.subliminals = tracks;
      localStorage.setItem("user_track", JSON.stringify(track));
      this.$store.dispatch("user/getTrack", track);
    },
  },
};
</script>

<style scoped>
.empty {
  height: 80px;
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;
}

.card:hover {
  > div > .information > .cover {
    min-width: 80px;
    height: 54px;
    margin: 5px;
    margin-right: 15px;
  }
  > div > .information > .details {
    color: #1c8edf;
  }
}

p {
  font-family: "Montserrat-Regular";
  font-size: 12px;
  margin-top: 10px;
}
.button-add {
  border-width: 0px;
  border-radius: 2px;
  font-family: "Montserrat-Bold";
  font-size: 11px;
  background-color: white;
  color: #427ab3;
  padding: 12px;
  width: 200px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
}
.other-button > img {
  height: 16px;
}

.banner-cover {
  height: 350px;

  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );
}

.overlay {
  margin-left: 2em;
  margin-right: 2em;
  margin-top: -100px;
  margin-bottom: 1em;

  z-index: 10;
}

.track {
  overflow-y: auto;
}

.cover {
  min-width: 90px;
  height: 64px;

  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.card {
  height: 80px;
  background-color: transparent;
}

.card,
.details {
  cursor: pointer;
}

.details > span:nth-of-type(1) {
  font-size: 10px;
  font-family: "Montserrat-Regular";
}

.text-limit-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
  font-family: "Montserrat-SemiBold";
}

.text-limit-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  font-family: "Montserrat-Regular";
}

.playpause > img {
  height: 48px;
  width: 48px;
  cursor: pointer;
}

.ellipsis > img {
  height: 5px;
  width: 26px;
}
.favorites > img {
  width: 26px;
  height: 24px;
  cursor: pointer;
}
.add > img {
  width: 26px;
  height: 24px;
}
</style>