<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're experiencing fear or anxiety about childbirth, you're not alone. Many women feel this way, but it's important to know that there are ways to overcome these fears and approach childbirth with confidence and peace of mind. This subliminal is designed to help you do just that."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help you:"
    },
    {
      type: 'list',
      items: [
        "Reduce fear and anxiety surrounding childbirth.",
        "Increase feelings of confidence and empowerment.",
        "Prepare your mind and body for a positive birthing experience.",
        "Promote relaxation and a sense of peace during the labor process.",
        "Shift your mindset to focus on the potential joy and beauty of childbirth."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind to let go of fear and embrace a positive mindset when it comes to childbirth. This can lead to a more positive and peaceful birthing experience for you and your baby. It's important to remember that each birth is unique, but with the right mindset and tools, you can approach it with confidence and peace of mind."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Preparing your mind and body for childbirth is essential to approach the experience with confidence and peace of mind. This subliminal is designed to help you overcome your fear of childbirth and cultivate a positive mindset. Here are some suggestions to help you prepare before listening:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Educate yourself: Gain knowledge about the birthing process and techniques that can empower you and reduce fear.",
        "Practice relaxation techniques: Learn and practice deep breathing, meditation, and visualization exercises to promote relaxation during labor.",
        "Create a birth plan: Outline your preferences and desires for your birthing experience, discussing them with your healthcare provider.",
        "Seek support: Surround yourself with positive and supportive individuals who can offer encouragement and help alleviate fears.",
        "Connect with your baby: Bonding with your baby during pregnancy can create a sense of connection and strengthen your confidence in your ability to birth.",
        "Stay open-minded: Embrace the unpredictable nature of childbirth, knowing that you have the inner strength to handle any situation that may arise."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to help you release fear and approach childbirth with confidence and peace of mind. With regular listening, you may find yourself feeling more prepared, empowered, and capable of having a positive birthing experience. Take a moment to relax, trust your body, and get ready to welcome your baby into the world."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Fear of Childbirth Removal for Women subliminal! By confronting your fear and preparing your mind and body for a positive birthing experience, you've taken an important step towards building confidence and peace of mind. It's essential to take time to reflect on your journey and continue your preparation."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Educate yourself: Learn about the birthing process, different techniques, and strategies to manage pain and anxiety.",
        "Practice relaxation techniques: Explore breathing exercises, meditation, and visualization to help calm your mind and body.",
        "Seek support: Connect with professionals, such as doulas, therapists, or support groups, who specialize in childbirth anxiety.",
        "Create a birthing plan: Plan and communicate your preferences with your healthcare provider to create a sense of control and empowerment."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, childbirth is a unique and transformative experience. You have the strength within you to face it with confidence and peace of mind. Trust in yourself and the preparation you've done. With the right support and resources, you are ready to embrace this miraculous journey. You've got this!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Fear of Childbirth Removal subliminal help me overcome my fear?`, 
    answer: `The Fear of Childbirth Removal subliminal is designed to help reprogram your subconscious mind and release any negative emotions or beliefs surrounding childbirth. By listening to the subliminal regularly, you can train your mind to approach childbirth with confidence, peace, and positivity. Repetition of positive affirmations and suggestions can gradually alleviate fear and promote a more empowered mindset towards the birthing experience.`,
    open: false
  },
  { 
    question: `How long do I need to listen to the Fear of Childbirth Removal subliminal to see results?`, 
    answer: `The length of time it takes to see results from the Fear of Childbirth Removal subliminal can vary from person to person. Some individuals may experience positive changes in their mindset and emotional well-being after a few sessions, while others may require more time and consistent listening. We recommend listening to the subliminal daily and consistently for at least several weeks to allow the positive affirmations to take effect.`,
    open: false 
  },
  { 
    question: `Can the Fear of Childbirth Removal subliminal replace childbirth education classes or therapy?`, 
    answer: `No, the Fear of Childbirth Removal subliminal is not intended to replace childbirth education classes or therapy. It is designed to complement existing support systems and resources by providing positive affirmations and suggestions to help alleviate fear and promote a more confident mindset towards childbirth. If you have specific concerns or require professional guidance, it is recommended to consult with a healthcare provider or mental health professional.`,
    open: false 
  },
  { 
    question: `Is it safe to listen to the Fear of Childbirth Removal subliminal during pregnancy?`, 
    answer: `Yes, it is generally safe to listen to the Fear of Childbirth Removal subliminal during pregnancy. The subliminal messages are designed to promote a positive mindset towards childbirth and alleviate fear. However, if you have any concerns or underlying health conditions, it is recommended to consult with your healthcare provider before incorporating any new practices into your pregnancy routine.`,
    open: false 
  },
  { 
    question: `Can the Fear of Childbirth Removal subliminal completely eliminate my fear of childbirth?`, 
    answer: `While the Fear of Childbirth Removal subliminal can help you develop a more positive mindset and decrease fear surrounding childbirth, individual results may vary. The subliminal aims to reprogram your subconscious mind and release negative emotions, but it cannot guarantee the complete elimination of fear. It is important to approach the process with realistic expectations and to seek additional support if needed.`,
    open: false 
  },
  { 
    question: `Will the Fear of Childbirth Removal subliminal affect my birthing experience?`, 
    answer: `The Fear of Childbirth Removal subliminal is designed to promote a positive mindset and alleviate fear surrounding childbirth, which can contribute to a more empowering birthing experience. However, the subliminal alone cannot guarantee specific outcomes as each individual's birthing experience is unique. It is important to combine the subliminal with appropriate prenatal care, education, and support for the best possible birthing experience.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>