export const category = {
  namespaced: true,
  state: {
    data: [],
    selected: [],
    user: {
      data: [],
    },
  },
  getters: {},
  actions: {
    getData({ commit }, data) {
      commit("getData", data);
    },
    getSelected({ commit }, data) {
      commit("getSelected", data);
    },
    getUserCategory({ commit }, data) {
      commit("getUserCategory", data);
    },
  },
  mutations: {
    getData(state, data) {
      state.data = data;
    },
    getSelected(state, data) {
      state.selected = data;
    },
    getUserCategory(state, data) {
      state.user.data = data;
    },
  },
};
