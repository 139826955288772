<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you have a fear of dogs or cynophobia, this subliminal can help you overcome your fear and develop a sense of calmness and confidence when facing dogs."
    },
    {
      type: 'heading',
      text: 'Here are a few ways this subliminal can assist you:'
    },
    {
      type: 'list',
      items: [
        "Reducing anxiety and fear responses when encountering dogs.",
        "Promoting a sense of calmness and relaxation in the presence of dogs.",
        "Instilling a positive and confident mindset when interacting with dogs.",
        "Helping you build a newfound appreciation and understanding of dogs.",
        "Empowering you to gradually face your fear and overcome cynophobia."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to let go of your fear of dogs and replace it with a sense of calmness and confidence. Over time, this can help you enjoy a more fulfilling and fear-free life, allowing you to appreciate the companionship and joy that dogs can bring. Remember, progress may take time, but with consistent use, you can overcome your fear of dogs."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Facing a fear of dogs or cynophobia can be challenging, but this subliminal is designed to help you overcome your fear and develop a sense of calmness and confidence in the presence of dogs. Before you start listening, here are some preparation tips to consider:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your fear: Recognize your fear of dogs and accept that it is a valid feeling.",
        "Educate yourself: Learn more about dogs, their behavior, and their body language to gain a better understanding.",
        "Visualize positivity: Imagine yourself in various situations involving dogs, staying calm and confident throughout.",
        "Set your intention: Focus on your desire to overcome your fear and embrace a calm and confident mindset in the presence of dogs."
      ]
    },
    {
      type: 'paragraph',
      text: "By following these preparation tips and listening to this subliminal regularly, you can gradually overcome your fear of dogs and develop a positive and confident mindset. Embrace the opportunity to transform your relationship with dogs and cultivate a sense of calmness and confidence. Now, let's begin your journey towards fear-free interactions with dogs!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Fear of Dogs and Cynophobia Removal subliminal session! By taking this step, you've shown great courage in facing your fear and working towards overcoming it. Now that you're done, it's time to reflect on your experience and continue your journey towards conquering cynophobia."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Expose yourself gradually: Start by looking at pictures of dogs and gradually progress to being around them.",
        "Practice relaxation techniques: Deep breathing exercises and meditation can help you calm down when facing dogs.",
        "Seek professional help if needed: Consider consulting with a therapist or counselor who specializes in phobias.",
        "Educate yourself about dogs: Learn about their behavior, body language, and how to interact safely."
      ]
    },
    {
      type: 'paragraph',
      text: "Overcoming a fear takes time and effort, but remember that you have the ability to become calm and confident around dogs. Take each step at your own pace and celebrate your progress along the way. With determination and dedication, you can conquer cynophobia and embrace a life free from fear. Good luck on your journey!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Will the Fear of Dogs and Cynophobia Removal subliminal help me completely eliminate my fear?`, 
    answer: `The Fear of Dogs and Cynophobia Removal subliminal is designed to help you overcome your fear of dogs and develop a sense of calmness and confidence when facing them. While it can be highly effective, the results may vary from person to person. With consistent listening, you can make significant progress in reducing and managing your fear.`,
    open: false
  },
  { 
    question: `How often should I listen to the Fear of Dogs and Cynophobia Removal subliminal?`, 
    answer: `For best results, we recommend listening to the subliminal at least once a day. Consistency is key when it comes to reprogramming your subconscious mind. You can listen to it during a dedicated relaxation period or as background audio while doing other activities. The more you listen, the more effective it can be in helping you overcome your fear of dogs.`,
    open: false 
  },
  { 
    question: `Can the Fear of Dogs and Cynophobia Removal subliminal be used alongside therapy or other treatments?`, 
    answer: `Yes, the Fear of Dogs and Cynophobia Removal subliminal can be used alongside therapy or other treatments for cynophobia. It can complement and enhance your progress in overcoming your fear of dogs. If you are currently receiving professional help, we recommend discussing the use of the subliminal with your therapist or healthcare provider.`,
    open: false 
  },
  { 
    question: `Is it possible to overcome cynophobia without therapy with the help of the Fear of Dogs and Cynophobia Removal subliminal?`, 
    answer: `Yes, it is possible to make progress in overcoming cynophobia without therapy. The Fear of Dogs and Cynophobia Removal subliminal is designed to help you reprogram your subconscious mind and develop a more positive and calm mindset towards dogs. However, if your fear is severe or deeply rooted, seeking professional help and combining it with the subliminal can offer a more comprehensive approach to overcoming cynophobia.`,
    open: false 
  },
  { 
    question: `Are there any side effects or risks associated with using the Fear of Dogs and Cynophobia Removal subliminal?`, 
    answer: `No, there are no known side effects or risks associated with using the Fear of Dogs and Cynophobia Removal subliminal. However, if you have any concerns or pre-existing conditions, it is always wise to consult with a healthcare professional before using subliminals or making any significant changes to your mental health practices.`,
    open: false 
  },
  { 
    question: `Can the Fear of Dogs and Cynophobia Removal subliminal work if I have had a traumatic experience with dogs in the past?`, 
    answer: `While the Fear of Dogs and Cynophobia Removal subliminal aims to help you overcome your fear and develop a sense of calmness and confidence when facing dogs, it may not address specific traumatic experiences. If you have had a traumatic experience with dogs in the past, it is recommended to seek professional help, such as therapy or counseling, to address and process those experiences.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>