<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you've been searching for the secrets to success in your personal and professional life, look no further. This subliminal is designed to help you uncover those secrets and unlock your full potential."
    },
    {
      type: 'heading',
      text: 'Here are a few ways this subliminal can assist you:'
    },
    {
      type: 'list',
      items: [
        "Developing a growth mindset and embracing a positive attitude towards success.",
        "Enhancing self-confidence and belief in your abilities to achieve your goals.",
        "Setting clear and actionable goals to create a roadmap for success.",
        "Overcoming self-doubt, fear, and limiting beliefs that hold you back.",
        "Building resilience and the ability to bounce back from setbacks and challenges.",
        "Improving time management and productivity skills for effective goal achievement.",
        "Cultivating healthy habits and a balanced lifestyle to support your success."
      ]
    },
    {
      type: 'paragraph',
      text: "With consistent use, this subliminal can rewire your subconscious mind, aligning your thoughts, beliefs, and actions with the principles of success. As a result, you may experience increased motivation, focus, and a greater sense of purpose. Remember, success is a journey, and this subliminal can be your guiding companion along the way."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a journey towards success requires motivation, determination, and a growth mindset. This subliminal is designed to support your quest for success and help you unlock your full potential. Before you start listening, here are some recommendations to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your goals: Set clear and specific goals for success in various areas of your life.",
        "Identify your strengths: Recognize your unique talents and capabilities that can contribute to your success.",
        "Believe in yourself: Cultivate self-confidence and a positive mindset, knowing that you are capable of achieving greatness.",
        "Visualize your success: Imagine yourself already achieving your goals and living the life you desire.",
        "Stay committed: Keep your goals and aspirations in mind as you listen to the subliminal affirmations, reinforcing your dedication to success."
      ]
    },
    {
      type: 'paragraph',
      text: "By consistently listening to this subliminal, you can reprogram your subconscious mind, align your thoughts and actions with success, and realize your full potential. Remember, success is a journey, and every step you take towards it is significant. Embrace this opportunity for personal growth, and let the secrets to success unfold before you."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Success Secrets subliminal session! By exploring the secrets to achieving success, you have taken a significant step towards unlocking your full potential and transforming your life in all areas. Now, it's time to reflect on your experience and continue on your journey towards success."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Define what success means to you and outline the specific steps you need to take to achieve it.",
        "Embrace a growth mindset: Believe in your ability to learn and grow, and see challenges as opportunities for growth.",
        "Stay motivated: Surround yourself with positive influences, create a vision board, and regularly revisit your goals.",
        "Take consistent action: Break down your goals into smaller, manageable tasks and take deliberate actions towards them."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, success is a journey that requires dedication, perseverance, and self-belief. Keep pushing forward, stay focused on your goals, and continue to take consistent action. You have the power to achieve greatness in all areas of your life. Believe in yourself and keep striving for success!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  {
    question: `What can I expect from the Success Secrets subliminal?`,
    answer: `The Success Secrets subliminal is designed to help you tap into your full potential and achieve success in all areas of your life. By listening to the subliminal messages, you may experience increased motivation, confidence, and clarity of thought. It can also help you break through limiting beliefs and develop a success-oriented mindset.`,
    open: false
  },
  {
    question: `How long do I need to listen to the Success Secrets subliminal to see results?`,
    answer: `Results may vary from individual to individual, and the time it takes to see results can depend on various factors. Consistent listening is key, so we recommend incorporating the subliminal into your daily routine for at least a few weeks. As you continue to listen, pay attention to any positive changes or opportunities that arise in your life.`,
    open: false
  },
  {
    question: `Can the Success Secrets subliminal help me in my specific field or industry?`,
    answer: `Yes, the Success Secrets subliminal is designed to help you achieve success in all areas of your life, including your specific field or industry. It can help you develop the mindset, skills, and strategies necessary to thrive and reach your goals in your chosen profession or area of expertise.`,
    open: false
  },
  {
    question: `How can I maximize the effectiveness of the Success Secrets subliminal?`,
    answer: `To maximize the effectiveness of the Success Secrets subliminal, it's important to listen to it in a calm and relaxed state, preferably with headphones. Create a positive environment, free from distractions, and incorporate the subliminal into your daily routine. Additionally, combining the subliminal with practical actions, such as setting goals, developing a plan, and staying committed, can further enhance your journey towards success.`,
    open: false
  },
  {
    question: `Is there any risk of becoming overambitious or losing sight of balance with the Success Secrets subliminal?`,
    answer: `No, the Success Secrets subliminal is designed to help you achieve success while maintaining a balanced and healthy approach to life. It aims to empower you with the mindset and skills necessary for success in all areas, emphasizing the importance of overall well-being and fulfillment.`,
    open: false
  },
  {
    question: `Can the Success Secrets subliminal replace hard work and effort?`,
    answer: `No, the Success Secrets subliminal is not intended to replace hard work and effort. It is designed to enhance your mindset, motivation, and abilities, but success still requires dedication, persistence, and action. The subliminal can serve as a powerful tool to support and amplify your efforts towards achieving success.`,
    open: false
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>