<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you or someone you know is struggling with drug addiction or abuse, this subliminal can be a powerful tool for recovery. By targeting the subconscious mind, it helps to break through the mental and emotional barriers that often accompany addiction."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support your journey to freedom:'
    },
    {
      type: 'list',
      items: [
        "Reducing cravings and the desire to use drugs.",
        "Increasing motivation and determination to overcome addiction.",
        "Boosting self-esteem and self-worth to support a positive recovery mindset.",
        "Releasing negative emotions and trauma that may contribute to addiction.",
        "Providing a sense of inner calm and peace to navigate through withdrawal and recovery.",
        "Developing healthier coping mechanisms and strategies to deal with triggers and cravings.",
        "Supporting the development of a new identity and purpose beyond addiction.",
        "Promoting a sense of empowerment and control over one's life and choices."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming addiction is a challenging journey, but with the right support and tools, it is possible to break free. This subliminal can be a valuable addition to your recovery process, providing the subconscious reinforcement needed to achieve lasting change. Commitment, resilience, and seeking professional guidance are key components in your path towards a life of freedom and recovery."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Recovering from drug addiction and abuse is a challenging journey, but it is possible. This subliminal is designed to support you in breaking free from the chains of addiction and living a life of freedom and recovery. Before you begin, here are some suggestions to prepare yourself:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Commit to change: Recognize that you have the power to overcome your addiction and make a commitment to change your life.",
        "Create a support system: Reach out to trusted friends, family, or support groups who can be there for you during your recovery journey.",
        "Set boundaries: Identify triggers and environments that may lead to relapse and establish healthy boundaries to protect your sobriety.",
        "Stay positive: Focus on the positive aspects of your recovery and imagine a future free from addiction.",
        "Believe in yourself: Have faith in your ability to overcome your addiction and embrace the possibilities of a new life."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, it can help reprogram your subconscious mind to support your recovery and empower you to stay on the path of sobriety. Remember, your journey to freedom from addiction is a personal one, and by taking this step, you are demonstrating your strength and determination. Embrace the opportunity for transformation, believe in yourself, and begin your journey towards a life of freedom and recovery."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Freedom from Drug Addiction and Abuse subliminal session! This is a significant step towards breaking free from the chains of addiction and starting a journey of recovery and freedom. It's important to reflect on your experience and continue building a life of health and well-being."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Seek support: Reach out to professionals, support groups, or loved ones who can provide guidance and encouragement.",
        "Develop coping skills: Learn healthy strategies to manage stress, cravings, and triggers.",
        "Create a healthy routine: Establish a structured schedule that includes healthy activities, exercise, and self-care.",
        "Build a support network: Surround yourself with people who support your recovery journey and understand your goals."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, recovery is a lifelong process, and every step counts. Stay committed to your journey and take advantage of the resources available to you. You have the power to break free, heal, and create a fulfilling life of freedom and recovery."
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Freedom from Drug Addiction and Abuse subliminal help me?`, 
    answer: `The Freedom from Drug Addiction and Abuse subliminal is designed to assist individuals in breaking free from the cycle of drug addiction and abuse. By listening to the subliminal messages, you can reprogram your subconscious mind and develop a stronger mindset for recovery. It can support your journey by increasing your motivation, willpower, and self-discipline while reducing cravings and negative thought patterns associated with addiction.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Freedom from Drug Addiction and Abuse subliminal?`, 
    answer: `Results can vary from person to person depending on individual circumstances and the level of commitment to recovery. Some individuals may notice positive changes in their attitude and behavior towards addiction after just a few listens, while others may require more time and consistent listening to fully reprogram their mindset. It is important to remember that recovery is a journey, and the subliminal can be a valuable tool along the way.`,
    open: false 
  },
  { 
    question: `Can the Freedom from Drug Addiction and Abuse subliminal replace professional treatment?`, 
    answer: `No, the Freedom from Drug Addiction and Abuse subliminal is not a substitute for professional treatment for drug addiction and abuse. It is designed to complement and support your recovery efforts by influencing your subconscious mind. If you are struggling with addiction, it is important to seek professional help and create a comprehensive treatment plan that includes therapy, counseling, support groups, and other evidence-based interventions.`,
    open: false 
  },
  { 
    question: `How can I enhance the effectiveness of the Freedom from Drug Addiction and Abuse subliminal?`, 
    answer: `To enhance the effectiveness of the subliminal, it is important to create a supportive environment for your recovery. Surround yourself with positive influences, such as supportive friends and family members who understand and respect your journey. Engage in holistic self-care practices, such as exercise, mindfulness, and healthy coping mechanisms to reduce stress. Engaging in therapy or counseling alongside listening to the subliminal can also provide additional support and guidance.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Freedom from Drug Addiction and Abuse subliminal?`, 
    answer: `There are no known risks or side effects associated with listening to the Freedom from Drug Addiction and Abuse subliminal. However, if you are currently undergoing addiction treatment or taking medications, it is recommended to consult with your healthcare provider or addiction specialist before incorporating the subliminal into your recovery plan. It is important to note that the subliminal is intended to be used as part of a comprehensive recovery program and not as a standalone solution.`,
    open: false 
  },
  { 
    question: `Can the Freedom from Drug Addiction and Abuse subliminal help with withdrawal symptoms?`, 
    answer: `While the subliminal can support your recovery by strengthening your mindset and motivation, it is not specifically designed to alleviate withdrawal symptoms. If you are experiencing withdrawal symptoms, it is important to seek medical assistance and receive appropriate care under the guidance of healthcare professionals who specialize in addiction treatment.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>