<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Swearing can often be a reflex or a habit that we don't even realize we have. If you're looking to break the habit of swearing and communicate more effectively without offensive language, this subliminal can help."
    },
    {
      type: 'heading',
      text: 'Here are some ways in which using this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Developing awareness and recognizing when you're about to swear.",
        "Increasing self-control and learning to pause before using offensive language.",
        "Finding alternative ways to express your emotions and frustrations.",
        "Enhancing your communication skills and finding more effective ways to express yourself.",
        "Building a positive and respectful language habit that aligns with your values."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can rewire your subconscious mind to break the habit of swearing and communicate more effectively. As you become more aware of your language choices, you'll be able to express yourself in a way that is more respectful and considerate to others. Remember, change takes time and effort, but with consistent use of this subliminal, you can develop healthier communication habits."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Breaking the habit of swearing and learning to communicate effectively without offensive language is a positive step in personal growth. This subliminal is designed to help you achieve this goal. Before you start listening, here are some suggestions to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge the habit: Recognize and accept that swearing has become a habit that you want to change.",
        "Identify triggers: Reflect on situations or emotions that often lead to swearing and be mindful of them in your daily life.",
        "Discover alternative expressions: Explore new words or phrases that can replace offensive language in your communication.",
        "Remain conscious of your speech: During the subliminal session and throughout the day, stay aware of the words you use and focus on expressing yourself without swearing."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal, it will help rewire your subconscious mind, empowering you to choose words that align with your intention of effective communication. Over time, you may find yourself naturally avoiding swearing and communicating more thoughtfully. Take this opportunity to commit to personal growth and cultivate positive communication habits. Let's begin!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Stop Swearing subliminal session! By taking the initiative to break the habit of swearing, you have shown a commitment to improving your communication skills and fostering a more positive and respectful environment."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Be aware of your language: Pay close attention to the words you use and the context in which you speak.",
        "Practice self-control: When the urge to swear arises, take a deep breath and consciously choose alternative, more positive words.",
        "Expand your vocabulary: Learn new words and expressions to effectively convey your thoughts and emotions without resorting to offensive language.",
        "Seek support and accountability: Share your goals with a trusted friend or family member who can help keep you on track and provide encouragement when needed."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, breaking any habit takes time and effort. Be patient with yourself and celebrate your progress along the way. By choosing to communicate respectfully and effectively, you are fostering healthier relationships and creating a more positive impact in your personal and professional life. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How long will it take to stop swearing using the subliminal?`, 
    answer: `The time it takes to break the habit of swearing varies from person to person. Some may notice positive changes right away, while others may require more time and consistent listening to fully reprogram their language habits. We recommend listening to the subliminal daily and consistently for best results. It is important to remember that breaking any habit takes effort and commitment.`,
    open: false
  },
  { 
    question: `Can the subliminal help me control my swearing in different situations?`, 
    answer: `Yes, the subliminal is designed to help you develop greater awareness and control over your language, regardless of the situation. It works by influencing your subconscious mind to adopt more positive and respectful communication patterns. However, it is important to also actively practice and reinforce these behaviors in your daily life to achieve the desired results.`,
    open: false 
  },
  { 
    question: `What can I do to make the subliminal more effective in stopping swearing?`, 
    answer: `You can enhance the effectiveness of the subliminal by consciously monitoring your language and replacing swear words with more appropriate alternatives. Practice self-awareness and redirect your thoughts and actions towards more positive and respectful communication. Surrounding yourself with supportive and like-minded individuals who also value non-offensive language can also help reinforce your efforts.`,
    open: false 
  },
  { 
    question: `Is it normal to experience setbacks or slip-ups while trying to stop swearing?`, 
    answer: `Yes, it is normal to experience setbacks or slip-ups while trying to break a habit such as swearing. Breaking any habit takes time, effort, and persistence. It is important to be patient and kind to yourself during this process. If you do slip, acknowledge the mistake, learn from it, and continue working towards your goal of communicating without offensive language.`,
    open: false 
  },
  { 
    question: `Are there any side effects of listening to the subliminal to stop swearing?`, 
    answer: `No, there are no known side effects associated with listening to this subliminal audio. However, it is important to note that the subliminal is intended to supplement your conscious efforts to break the habit of swearing. It is not a substitute for personal commitment and active practice in adopting more positive communication patterns.`,
    open: false 
  },
  { 
    question: `Will the subliminal completely eliminate swearing from my vocabulary?`, 
    answer: `The subliminal is designed to help you break the habit of swearing and develop a more positive approach to communication. While it can significantly reduce swearing, the extent to which it eliminates it entirely may vary from person to person. It is important to actively practice and reinforce the desired behaviors in your daily life to fully adopt non-offensive language habits.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>