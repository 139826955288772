<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Do you feel nervous or anxious when attending social gatherings or events? If so, this subliminal can help you build confidence and feel more comfortable in social situations."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help you:"
    },
    {
      type: 'list',
      items: [
        "Reducing social anxiety and nervousness, allowing you to relax and enjoy social events.",
        "Boosting self-esteem and promoting a positive self-image, so you feel confident in yourself.",
        "Helping you overcome shyness and become more outgoing and sociable.",
        "Enhancing your communication skills, allowing you to engage in conversations with ease.",
        "Increasing your overall comfort level in social situations, so you can fully enjoy gatherings and events."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your mind to feel more at ease and confident in social settings. You may notice a gradual improvement in your ability to interact with others, make new connections, and embrace social opportunities. Remember, building confidence takes time and practice, but you can achieve it!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Feeling confident in social situations can greatly enhance your overall well-being and enjoyment of gatherings and events. This subliminal is designed to help you build confidence and feel comfortable and at ease when attending social gatherings. Before you start listening, consider these strategies to maximize your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Visualize yourself feeling confident, relaxed, and at ease in social situations.",
        "Practice deep breathing: Before, during, and after listening, focus on your breath to calm your mind and body.",
        "Release negative thoughts: Let go of any self-doubt or negative beliefs about your social abilities.",
        "Visualize success: Imagine yourself engaging effortlessly in conversations, making connections, and enjoying the company of others."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to tap into the power of your subconscious mind to boost your confidence and ease in social gatherings. As you listen regularly, you may find yourself embracing social situations with more enthusiasm and discovering a newfound sense of self-assurance. So take a moment to set your intention, breathe deeply, and let this journey towards social confidence begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Social Gathering Confidence subliminal! By taking steps to build your confidence in social situations, you're on your way to feeling more comfortable and at ease when attending gatherings and events. Now it's time to reflect on your experience and continue your personal growth."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your progress: Take a moment to acknowledge and celebrate the improvements you've noticed in your social confidence.",
        "Practice social skills: Continue engaging in activities that help you develop and refine your social skills, such as joining clubs or volunteering.",
        "Visualize success: Use the power of visualization to imagine yourself confidently navigating social gatherings.",
        "Challenge negative thoughts: When negative thoughts arise, challenge and replace them with positive, empowering statements."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, building confidence in social situations is a process. Be patient with yourself and embrace each opportunity to grow and improve. You have the ability to feel comfortable and at ease when attending gatherings and events. Keep moving forward, and enjoy the positive impacts it brings to your social life!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Social Gathering Confidence subliminal help me in social situations?`, 
    answer: `The Social Gathering Confidence subliminal is designed to help boost your self-confidence and comfort levels in social situations. By listening to the subliminal messages, you can reprogram your subconscious mind to release any negative self-beliefs or anxieties that may be holding you back. Over time, this can lead to increased self-assurance, improved social skills, and a greater ability to feel at ease when attending gatherings and events.`,
    open: false
  },
  { 
    question: `Will I see immediate results after listening to the Social Gathering Confidence subliminal?`, 
    answer: `Results may vary from person to person, but it's common for the effects of the Social Gathering Confidence subliminal to build gradually over time. Some individuals may notice immediate improvements in their confidence and comfort levels, while others may require consistent listening over a period of time before significant changes occur. It's important to be patient and continue listening regularly for best results.`,
    open: false 
  },
  { 
    question: `Can I use the Social Gathering Confidence subliminal for specific social situations, such as presentations or job interviews?`, 
    answer: `Yes, the affirmations in the Social Gathering Confidence subliminal can be beneficial for specific social situations, such as presentations, job interviews, or any scenario in which you want to feel more confident and at ease. By consistently listening to the subliminal, you can train your mind to respond more positively to these situations, reducing anxiety and boosting your overall confidence.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Social Gathering Confidence subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your mental health or social anxiety, it's always a good idea to consult with a mental health professional. The subliminal is intended to complement any existing therapeutic interventions or strategies you may be using to build confidence in social situations.`,
    open: false 
  },
  { 
    question: `Can the Social Gathering Confidence subliminal completely eliminate social anxiety?`, 
    answer: `The Social Gathering Confidence subliminal is designed to help improve your confidence and comfort levels in social situations, but it may not completely eliminate social anxiety. It aims to reprogram your subconscious mind with positive affirmations and beliefs, empowering you to respond more positively in social gatherings. However, if you have severe social anxiety, it's important to seek professional help for a comprehensive treatment plan.`,
    open: false 
  },
  { 
    question: `How long should I listen to the Social Gathering Confidence subliminal to see results?`, 
    answer: `The time it takes to see noticeable results from the Social Gathering Confidence subliminal varies from person to person. Consistent listening is key, so we recommend incorporating the subliminal into your daily routine for at least a few weeks to allow the positive affirmations to take effect. The more you listen, the more your subconscious mind can absorb and integrate the empowering messages.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>