<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Dealing with the emotional wounds caused by a broken family can be challenging, but this subliminal is here to support your healing process. By targeting your subconscious mind, it aims to help you find peace, healing, and emotional well-being."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can assist you:'
    },
    {
      type: 'list',
      items: [
        "By releasing and letting go of past pain and resentment from your broken family.",
        "By cultivating forgiveness and compassion towards yourself and others involved.",
        "By building resilience and strength to overcome the emotional impact of a broken family.",
        "By promoting self-love and self-acceptance, allowing you to heal and move forward.",
        "By creating a sense of inner peace and stability, even in the midst of family challenges."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember that healing from a broken family is a process that takes time and effort. As you listen to this subliminal regularly, you may start to notice positive changes in how you feel and how you navigate your emotions. Be patient and kind to yourself as you embark on this journey of healing and self-discovery."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Healing from the emotional wounds caused by a broken family is a courageous step towards finding peace and healing within yourself. This subliminal is designed to support you on your journey of emotional healing. Before you begin listening, consider these recommendations to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a safe space: Find a quiet and comfortable place where you can listen to the subliminal without interruptions.",
        "Set your intention: Focus on your desire to heal from the emotional wounds caused by your broken family and find inner peace.",
        "Practice self-care: Engage in activities that nurture your emotional well-being, such as journaling, meditation, or spending time in nature.",
        "Be patient and compassionate with yourself: Emotional healing takes time, so be gentle with yourself and allow yourself to feel and process your emotions.",
        "Keep an open mind: Trust that the subliminal affirmations are working on a subconscious level, even if you're not consciously aware of them."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you may start to notice a greater sense of emotional healing, forgiveness, and self-compassion. Embrace this opportunity to cultivate a healthier relationship with yourself and find the inner peace you deserve. Take a deep breath, set your intention, and let the journey of healing begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Broken Family Emotional Healing subliminal! By taking this step towards healing and finding peace within yourself, you are on a path to emotional well-being. It's important to take time to reflect on your experience and continue your journey towards healing."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your emotions: Allow yourself to feel and express your emotions in a healthy way.",
        "Seek support: Consider therapy or counseling to address the emotional wounds caused by your broken family.",
        "Practice self-care: Engage in activities that bring you joy and help you nurture your emotional well-being.",
        "Forgive and let go: Work on forgiving yourself and others involved, allowing yourself to move forward with healing."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, healing from a broken family takes time and self-compassion. By taking care of your emotional well-being, you are creating a foundation for a brighter future. Be kind to yourself and know that you are deserving of love, peace, and healing."
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Can the Broken Family Emotional Healing subliminal heal all the emotional wounds caused by a broken family?`, 
    answer: `The Broken Family Emotional Healing subliminal is designed to support you in finding peace and healing within yourself. While it can help you on your journey of emotional healing, it is important to keep in mind that healing is a personal and ongoing process. The subliminal aims to empower you with positive affirmations and support your emotional well-being, but it is not a substitute for professional therapy or counseling if you feel you need additional support.`,
    open: false
  },
  { 
    question: `How can the Broken Family Emotional Healing subliminal benefit me?`, 
    answer: `The Broken Family Emotional Healing subliminal can benefit you by helping you release negative emotions, develop a sense of self-acceptance and self-love, and foster inner peace. It aims to reprogram your subconscious mind with positive affirmations and beliefs that support your emotional healing journey. Listening to the subliminal regularly can help you build resilience, develop healthier coping mechanisms, and find greater emotional well-being.`,
    open: false 
  },
  { 
    question: `How long does it take to experience healing with the Broken Family Emotional Healing subliminal?`, 
    answer: `The time it takes to experience healing with the Broken Family Emotional Healing subliminal can vary depending on the individual and the depth of emotional wounds. Healing is a personal and ongoing process, and the subliminal serves as a tool to support you in your journey. It is recommended to listen to the subliminal consistently over an extended period of time to allow the positive affirmations to work on a subconscious level.`,
    open: false 
  },
  { 
    question: `Can the Broken Family Emotional Healing subliminal replace therapy or counseling?`, 
    answer: `No, the Broken Family Emotional Healing subliminal is not a substitute for professional therapy or counseling if you feel you need additional support. It is designed to complement your healing journey and provide positive affirmations and support for your emotional well-being. If you are struggling with the effects of a broken family, it is important to reach out to a qualified therapist or counselor for personalized guidance and support.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Broken Family Emotional Healing subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Broken Family Emotional Healing subliminal. However, if you experience any discomfort or emotional distress while listening, it is recommended to stop and seek support from a mental health professional.`,
    open: false 
  },
  { 
    question: `Can the Broken Family Emotional Healing subliminal help me forgive my family members?`, 
    answer: `The Broken Family Emotional Healing subliminal aims to support you in finding peace and healing within yourself. While it can assist in releasing negative emotions and developing a sense of self-acceptance, forgiveness is a personal journey that may require additional healing techniques and support. It is important to be patient and kind to yourself as you navigate the process of forgiveness.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>