<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Guilt can weigh heavily on our hearts and minds, preventing us from fully enjoying life and finding peace within ourselves. This subliminal is designed to help you resolve and heal feelings of guilt, allowing you to move forward and find inner peace."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support you:'
    },
    {
      type: 'list',
      items: [
        "Helping you identify and understand the root causes of your guilt.",
        "Assisting in releasing and letting go of guilt, allowing forgiveness and self-compassion to emerge.",
        "Encouraging self-reflection and personal growth, empowering you to make positive changes.",
        "Promoting inner healing and nurturing a sense of self-acceptance and forgiveness.",
        "Supporting you in creating healthy boundaries and letting go of unnecessary guilt and self-blame."
      ]
    },
    {
      type: 'paragraph',
      text: "Using this subliminal regularly can help rewire your subconscious mind, enabling you to free yourself from the burden of guilt. As you embrace self-compassion and forgiveness, you can pave the way for personal growth, inner peace, and a brighter future. Remember, healing takes time, so be patient and kind to yourself throughout this journey."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Healing and resolving feelings of guilt can be a transformative journey towards finding inner peace and self-acceptance. This subliminal is designed to support you in releasing guilt and embracing forgiveness. Before starting this session, consider the following suggestions to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your feelings: Recognize and give yourself permission to feel the guilt that is burdening you.",
        "Reflect on the root cause: Identify the source of your guilt to better understand its impact on your life and healing process.",
        "Set an intention to heal: Affirm your desire to let go of guilt and embrace forgiveness and self-compassion.",
        "Create a safe space: Find a comfortable and quiet environment where you can fully engage with the subliminal affirmations, allowing your subconscious mind to absorb the healing messages."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to guide you on a path of healing, self-forgiveness, and self-compassion. As you listen to the affirmations, allow yourself to release the weight of guilt and open your heart to healing. Embrace this transformative process and let it lead you towards finding inner peace and freedom from the burdens of guilt."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Resolve and Heal Guilt subliminal! By acknowledging and addressing your feelings of guilt, you've taken a significant step towards finding inner peace and moving forward in your life. It's important to reflect on your experience and continue your journey of healing and self-discovery."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Journal your thoughts and emotions: Writing down your feelings can help you gain clarity and understanding.",
        "Practice self-compassion: Be kind and understanding towards yourself as you navigate through the healing process.",
        "Seek support if needed: Reach out to trusted friends, family, or professionals who can support you in your journey.",
        "Forgive yourself: Let go of self-blame and embrace self-forgiveness as a powerful tool for healing and growth."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, healing from guilt takes time and self-compassion. Be patient with yourself and celebrate every step you take towards finding inner peace. Know that you have the strength and resilience within you to heal and create a brighter future. Keep moving forward!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Resolve and Heal Guilt subliminal help me?`, 
    answer: `The Resolve and Heal Guilt subliminal is designed to address and heal feelings of guilt that may be burdening you. By listening to the subliminal messages, you can reprogram your subconscious mind to release guilt, forgive yourself, and find inner peace. The subliminal works on a deeper level, targeting the root causes of guilt and helping you resolve these emotions.`,
    open: false
  },
  { 
    question: `How long does it take to resolve feelings of guilt with the subliminal?`, 
    answer: `The time it takes to resolve feelings of guilt can vary from person to person. It depends on factors such as the intensity of the guilt, the underlying causes, and individual differences. Consistent listening to the Resolve and Heal Guilt subliminal, along with practicing self-reflection and self-compassion, can help expedite the healing process.`,
    open: false 
  },
  { 
    question: `Can the Resolve and Heal Guilt subliminal help with specific instances of guilt?`, 
    answer: `Yes, the Resolve and Heal Guilt subliminal can help address specific instances of guilt. It works to heal and resolve guilt by reprogramming your subconscious mind and providing a foundation for forgiveness and self-acceptance. It can be effective for guilt related to past actions, mistakes, or regrets.`,
    open: false 
  },
  { 
    question: `Is the Resolve and Heal Guilt subliminal a substitute for therapy?`, 
    answer: `No, the Resolve and Heal Guilt subliminal is not a substitute for therapy or professional help. While it can be a helpful tool in the healing process, it is important to consult with a qualified therapist or counselor for more intensive support, especially for deep-rooted or complex feelings of guilt.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Resolve and Heal Guilt subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Resolve and Heal Guilt subliminal. However, everyone is unique, and it is important to listen to your own intuition and seek professional help if needed. This subliminal is designed to work in harmony with your self-healing process.`,
    open: false 
  },
  { 
    question: `Can the Resolve and Heal Guilt subliminal make me forget about the past or my actions?`, 
    answer: `No, the Resolve and Heal Guilt subliminal is not intended to make you forget about the past or your actions. Its purpose is to help you heal and resolve feelings of guilt, promote forgiveness and self-acceptance, and find inner peace. It aims to provide you with a healthier mindset to navigate your past experiences and grow from them.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>