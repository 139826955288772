<template>
  <div
    id="soundtrouble"
    :class="`content  ${isfull}  px-5`"
    :style="`height: ${contentheight}px`"
  >
    <p :class="`title ${isfull}`">The sounds won’t stop when I quit the app.</p>
    <p>
      The Magus sounds are automatically set to play anytime if you have an
      active playing of a subliminal and while the app is running in the
      background. So even if you pressed the home button, the sounds are still
      playing in the background.
    </p>

    <p id="soundlimit">
      The sounds will only stop once you open the app, and you pause or stop the
      playing of the sounds. The sound also stops once it has reached the end of
      a track or playlist if the “Loop” is not active.
    </p>

    <p>
      There are also other instances on most mobile devices that the Magus
      sounds will be stopped or interrupted if you played another music or video
      using a different app.
    </p>

    <p>How to calibrate the volume?</p>
    <p>
      The best advice is to listen on your most comfortable level as there are
      static or buzzing sounds that are hidden on another layer which are called
      Ultrasonic and Pure subliminal audio.
    </p>

    <p>
      The overall and general volume can be adjusted through your device’s
      volume bar and volume control buttons. Individual audio track volumes can
      be configured separately using advanced volume controls.
    </p>

    <p>
      Take note that the ratio of the advanced volume controls is proportional
      to your device’s volume level. For example, your device’s general volume
      is set to 50% and Pure and Ultrasonic is set to 50%, the actual volume of
      the Pure and Ultrasonic is 25% of your overall volume.
    </p>

    <p>
      So, the best practice is to set your device’s overall volume to 100% to
      easily calibrate the volume each track
    </p>

    <p>Unable to other contents.</p>
    <p>
      Some contents are exclusive to certain user groups or subscription levels.
      They are usually marked differently than the general and publicly
      available contents. Please check your subscription status under the “Me”
      tab. If you have the right subscription type, you can send us an email to
      info@magusaudio.com including your query and screenshots.
    </p>

    <div class="d-flex justify-space-between">
      <p class="link" @click="getAction('crash')">App Crashing</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    full: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data: () => {
    return {
      contentheight: 0,
    };
  },
  created() {
    window.addEventListener("resize", this.changeContentHeight);
  },
  mounted() {
    this.changeContentHeight();
  },
  computed: {
    isfull() {
      return this.full ? "full" : "";
    },
  },
  methods: {
    changeContentHeight() {
      if (!this.full) {
        const contentheight = document.getElementById("soundlimit").offsetTop;
        const soundtrouble = document.getElementById("soundtrouble").offsetTop;

        this.contentheight = contentheight - soundtrouble;
      }
    },
    getAction(data) {
      let route = "";
      switch (data) {
        case "crash":
          route = "/support/app/crash";
          break;
      }

      this.$router.push(route);
    },
  },
};
</script>

<style scoped>
.content {
  overflow: hidden;
}

.content.full {
  height: 100% !important;
  overflow: initial;
}

.title.full {
  text-align: center;
}

p {
  font-size: 12px;
  text-align: justify;
}

li {
  font-size: 12px;
  padding: 5px 0px;
  text-align: justify;
}

.link {
  font-size: 12px;
  cursor: pointer;
}

.link:hover {
  color: blue;
  text-decoration: underline;
}
</style>
