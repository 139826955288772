<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're looking to enhance your stock market trading skills and achieve success in your investments, this subliminal can provide the support you need. By targeting your subconscious mind, it can help you develop the mindset and habits necessary for profitable trading."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Improving your confidence and belief in your trading abilities.",
        "Enhancing your focus and concentration while analyzing market trends and data.",
        "Helping you make rational and informed trading decisions based on research and analysis.",
        "Boosting your patience and discipline to stick to your trading plan and strategies.",
        "Reducing stress and anxiety related to stock market volatility and uncertainties."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind to align with the mindset and behaviors of successful traders. Over time, you may notice increased clarity, confidence, and profitability in your stock market investments. Remember, success in trading requires continuous learning and practice, but with the right mindset, you can achieve your goals."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Achieving success in the stock market requires knowledge, strategy, and the right mindset. This subliminal is designed to help you develop the mindset necessary for trading success. Before you start listening, consider these tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Educate yourself: Continuously learn and stay updated on stock market trends, strategies, and analysis.",
        "Set specific goals: Determine what you want to achieve with your stock market investments and create a plan to reach those goals.",
        "Cultivate discipline: Develop the ability to stick to your trading strategy, manage risks effectively, and control emotions.",
        "Visualize success: Imagine yourself making profitable trades, being confident, and achieving your financial goals.",
        "Maintain focus: Direct your attention towards your trading goals while listening to the subliminal affirmations."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal is a tool to help you enhance your trading mindset and improve your chances of success in the stock market. By incorporating these tips into your preparation, combined with regular listening, you can develop the skills, knowledge, and mindset needed to excel in your trading journey. Get ready to unlock your potential and achieve your goals in the stock market!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Stock Market Trading Success subliminal! By investing time and energy into improving your trading skills, you're setting yourself up for success in the stock market. Now is the time to reflect on your progress and continue taking steps towards achieving your financial goals."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Monitor your trades: Keep a record of your trades and analyze your decision-making process.",
        "Stay informed: Continuously educate yourself about market trends, news, and investment strategies.",
        "Network with fellow traders: Join trading communities, attend webinars or workshops to exchange knowledge and experiences.",
        "Stick to a trading plan: Develop a clear strategy and follow it consistently to minimize risk and maximize returns."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, achieving success in stock market trading requires continuous learning, adaptability, and discipline. Embrace the ups and downs of the market, learn from your experiences, and keep refining your skills. With dedication and perseverance, you can reach new heights in your trading journey. Best of luck!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Can the Stock Market Trading Success subliminal guarantee profits in the stock market?`, 
    answer: `The Stock Market Trading Success subliminal is designed to enhance your mindset, focus, and confidence in stock market trading. While it can provide valuable support and positive affirmations to help you make informed investment decisions, it does not guarantee profits. Successful stock market trading involves a combination of knowledge, skills, timing, and market factors that are beyond the control of the subliminal.`,
    open: false
  },
  { 
    question: `How often should I listen to the Stock Market Trading Success subliminal for optimal results?`, 
    answer: `Consistency is key when using the Stock Market Trading Success subliminal. We recommend listening to the audio daily or as frequently as possible to reinforce positive beliefs, boost confidence, and align your mindset for successful trading. Additionally, combining the subliminal with ongoing education and practice in stock market trading can help you achieve optimal results.`,
    open: false 
  },
  { 
    question: `Can the Stock Market Trading Success subliminal replace proper education and research in stock market trading?`, 
    answer: `No, the Stock Market Trading Success subliminal is not intended to replace proper education, research, and due diligence in stock market trading. It is designed to enhance your mindset and confidence, but successful trading requires a solid foundation of knowledge, understanding market trends, and staying informed about economic factors. The subliminal can complement your learning and provide positive reinforcement, but it is not a substitute for comprehensive market analysis.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Stock Market Trading Success subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Stock Market Trading Success subliminal. However, if you have any concerns about your investments or trading strategies, it is recommended to consult with a financial advisor or professional. It is important to note that the subliminal is intended to supplement your stock market trading efforts and mindset, not replace them.`,
    open: false 
  },
  { 
    question: `Can the Stock Market Trading Success subliminal predict market trends or provide stock recommendations?`, 
    answer: `No, the Stock Market Trading Success subliminal does not possess the ability to predict market trends or provide specific stock recommendations. Its purpose is to help you develop a positive and confident mindset for successful trading by reinforcing beliefs and attitudes conducive to effective decision-making. It is important to rely on your own analysis, research, and market insights when making investment decisions.`,
    open: false 
  },
  { 
    question: `How soon can I expect to see results from the Stock Market Trading Success subliminal?`, 
    answer: `The time it takes to see results from the Stock Market Trading Success subliminal can vary from person to person. Some individuals may experience positive changes in their mindset and trading skills quite quickly, while others may require more time and consistent listening. We recommend listening to the subliminal regularly while actively working on improving your stock market knowledge and skills for optimal results.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>