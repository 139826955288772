<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Achieving correct posture is crucial for maintaining a healthy body and preventing discomfort or pain. This subliminal is designed to help you correct your posture and train your body to naturally adopt proper alignment."
    },
    {
      type: 'heading',
      text: 'Here is how this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Correcting imbalances and misalignments in your posture.",
        "Strengthening the muscles that support good posture.",
        "Increasing body awareness to recognize and correct poor alignment.",
        "Promoting flexibility and mobility for a more fluid and natural posture.",
        "Reducing tension, pain, and discomfort associated with poor posture."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your mind to prioritize and maintain proper posture effortlessly. With time and practice, your body will become accustomed to the correct alignment, alleviating discomfort and promoting overall well-being. Remember, consistency is key in improving your posture and reaping the long-term benefits."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Improving your posture is essential for overall well-being, as it can alleviate discomfort and prevent long-term health issues. This subliminal is designed to support your journey towards correct posture and alignment. Before you begin, consider these tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Prioritize your desire to correct and improve your posture, and envision the positive impact it will have on your daily life.",
        "Educate yourself: Learn about proper posture alignment and training techniques, so you can apply them consciously in your daily activities.",
        "Practice mindfulness: Develop body awareness and pay attention to your posture throughout the day, making adjustments as needed.",
        "Combine with physical exercises: Incorporating exercises that strengthen your core and back muscles can complement the subliminal affirmations."
      ]
    },
    {
      type: 'paragraph',
      text: "By consistently listening to this subliminal and applying the tips provided, you can retrain your mind and body to adopt better posture habits. Over time, you may experience reduced discomfort, increased energy, and greater overall well-being. Embrace this opportunity to prioritize your posture and start your journey towards a healthier you."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Great job completing the Posture Alignment and Training session! By focusing on correcting and improving your posture, you're on your way to alleviating discomfort and promoting overall well-being. Take a moment to reflect on your experience and continue your journey towards better posture."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice awareness: Pay attention to your posture throughout the day and make adjustments as needed.",
        "Engage in posture exercises: Incorporate specific exercises that target your problem areas and promote alignment.",
        "Take regular breaks: Avoid prolonged sitting or standing by incorporating breaks and stretches into your daily routine.",
        "Invest in ergonomic support: Consider using ergonomic chairs, pillows, or supportive devices to maintain correct posture."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, correcting posture is a continuous process that requires consistent effort. Embrace the adjustments and exercises necessary to improve your posture. With dedication and practice, you can cultivate a strong and healthy posture to support your overall well-being. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How long does it take to see improvements in posture using posture alignment and training techniques?`, 
    answer: `The time it takes to see improvements in posture can vary depending on individual factors such as the severity of the postural issues and the consistency of practice. With regular alignment exercises and training techniques, you may start to notice positive changes in your posture within a few weeks or months. However, it is important to maintain consistency and practice proper technique to achieve long-term results.`,
    open: false
  },
  { 
    question: `Are there any risks or side effects associated with posture alignment and training techniques?`, 
    answer: `When done correctly and under professional guidance, posture alignment and training techniques are generally safe and free from side effects. However, if you have any pre-existing medical conditions or injuries, it is recommended to consult with a healthcare professional before starting any new exercise or training program to ensure it is suitable for your specific needs.`,
    open: false 
  },
  { 
    question: `Can posture alignment and training techniques help with chronic pain and discomfort?`, 
    answer: `Correcting poor posture through alignment and training techniques can help reduce chronic pain and discomfort associated with musculoskeletal imbalances. By improving alignment, strengthening weak muscles, and increasing flexibility, posture training can alleviate strain on the body and promote better overall well-being. However, it is important to approach posture training as part of a holistic approach to pain management and consult with a healthcare professional for personalized guidance and advice.`,
    open: false 
  },
  { 
    question: `How often do I need to practice posture alignment and training exercises?`, 
    answer: `Consistency is key when it comes to posture alignment and training. It is recommended to practice alignment exercises and training techniques daily or as advised by a healthcare professional or posture specialist. Regular practice helps reinforce correct alignment and train your body to adopt healthier postural habits.`,
    open: false 
  },
  { 
    question: `Can posture alignment and training techniques be done by anyone?`, 
    answer: `Posture alignment and training techniques can generally be done by anyone, but it is important to approach them with proper technique and guidance. People with pre-existing medical conditions or injuries should consult with a healthcare professional or posture specialist to determine the appropriate exercises and modifications for their specific needs. Additionally, it is important to start with gentle exercises and gradually increase intensity to avoid strain or injury.`,
    open: false 
  },
  { 
    question: `Can posture alignment and training techniques improve my overall well-being?`, 
    answer: `Yes, correcting and improving your posture through alignment and training techniques can have a positive impact on your overall well-being. Better posture can lead to reduced pain and discomfort, improved breathing, increased energy, enhanced body awareness, and improved confidence. It can also help prevent future postural issues and musculoskeletal imbalances, promoting long-term health and well-being.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>