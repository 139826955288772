<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Accepting and embracing your disabilities is an important step towards living a fulfilling life with confidence. This subliminal is designed to help you build self-esteem and overcome any limitations you may feel due to your disabilities."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can assist you:'
    },
    {
      type: 'list',
      items: [
        "Boosting your self-esteem and sense of self-worth, regardless of your disabilities.",
        "Helping you develop a positive and accepting mindset towards your limitations.",
        "Providing motivation and inspiration to pursue your goals and dreams.",
        "Encouraging self-acceptance and embracing the unique qualities and strengths your disabilities may bring.",
        "Supporting you in building resilience and overcoming any negative societal or personal perceptions about disabilities."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reframe your mindset, boost your confidence, and live a fulfilling life regardless of your limitations. Remember, you are capable of achieving amazing things!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Accepting and embracing your disabilities is a powerful step towards self-empowerment and a fulfilling life. This subliminal is designed to boost your confidence and help you overcome any limitations you may face. Before you start listening, consider these strategies to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your strengths: Focus on what you can do and the unique qualities that make you who you are.",
        "Practice self-compassion: Be kind to yourself and recognize that everyone faces challenges in life.",
        "Set positive affirmations: Write down empowering statements that reaffirm your self-worth and capabilities.",
        "Visualize your success: Imagine yourself thriving and living a fulfilling life regardless of any limitations."
      ]
    },
    {
      type: 'paragraph',
      text: "Through regular listening to this subliminal, you can build a strong sense of acceptance, confidence, and self-esteem. Embrace your journey, celebrate your achievements, and remember that your disabilities do not define you. You are capable of living a fulfilling and meaningful life. Now, let's begin this transformative process together."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Disabilities Acceptance and Confidence subliminal! By choosing to embrace and accept your disabilities with confidence, you are taking a significant step towards living a fulfilling life regardless of any limitations you may have. It's important to reflect on your journey and continue fostering self-esteem and acceptance."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice self-compassion: Be kind and forgiving to yourself. Celebrate your strengths and achievements.",
        "Surround yourself with support: Build a network of friends, family, or support groups who understand and uplift you.",
        "Set achievable goals: Focus on what you can do and set realistic goals that align with your capabilities.",
        "Challenge negative self-talk: Replace self-limiting beliefs with affirmations that empower and inspire you."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, accepting and embracing your disabilities is a continuous process, and it's okay to have ups and downs. Be patient with yourself and cultivate a positive mindset. Your unique experiences and strengths make you who you are, and with confidence, you can lead a fulfilling and joyful life. Believe in yourself!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Disabilities Acceptance and Confidence subliminal help me?`, 
    answer: `The Disabilities Acceptance and Confidence subliminal is designed to help you embrace and accept your disabilities with confidence. By listening to the subliminal messages, you can boost your self-esteem and develop a positive mindset towards your limitations. It can help you overcome societal prejudices, embrace your uniqueness, and live a fulfilling life.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Disabilities Acceptance and Confidence subliminal?`, 
    answer: `Results may vary from person to person. Some individuals may experience positive changes in their confidence and self-acceptance relatively quickly, while for others, it may take more time. We recommend listening to the subliminal consistently and for an extended period to allow the affirmations to align with your subconscious mind and foster lasting changes.`,
    open: false 
  },
  { 
    question: `Can the Disabilities Acceptance and Confidence subliminal cure my disabilities?`, 
    answer: `No, the Disabilities Acceptance and Confidence subliminal is not a cure for disabilities. It is designed to empower you and boost your confidence by promoting acceptance and embracing your limitations. It aims to help you develop a positive mindset and live a fulfilling life regardless of your disabilities.`,
    open: false 
  },
  { 
    question: `How can I enhance the effectiveness of the Disabilities Acceptance and Confidence subliminal?`, 
    answer: `In addition to listening to the subliminal messages, practicing self-love, self-compassion, and surrounding yourself with a supportive community can enhance the effectiveness of the subliminal. Engaging in activities that build your self-confidence and focusing on your strengths and abilities can also contribute to a more positive and accepting mindset.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Disabilities Acceptance and Confidence subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your mental health or well-being, it is always advisable to consult with a healthcare professional or mental health expert.`,
    open: false 
  },
  { 
    question: `Can the Disabilities Acceptance and Confidence subliminal help with overcoming societal prejudices?`, 
    answer: `Yes, the Disabilities Acceptance and Confidence subliminal is designed to help you overcome societal prejudices by fostering self-acceptance, self-confidence, and a positive mindset towards your disabilities. It can empower you to challenge stereotypes and embrace your uniqueness, allowing you to navigate societal obstacles with resilience and grace.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>