import { render, staticRenderFns } from "./59-facial-slimming.vue?vue&type=template&id=39c78489&scoped=true&"
import script from "./59-facial-slimming.vue?vue&type=script&lang=js&"
export * from "./59-facial-slimming.vue?vue&type=script&lang=js&"
import style0 from "./59-facial-slimming.vue?vue&type=style&index=0&id=39c78489&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39c78489",
  null
  
)

export default component.exports