<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're looking to increase your productivity and achieve more in your personal and professional life, this subliminal can be a valuable tool. By targeting your subconscious mind, it helps to reprogram your thoughts and beliefs about productivity."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help:'
    },
    {
      type: 'list',
      items: [
        "Boosting your focus and concentration, allowing you to stay on task and complete your work more efficiently.",
        "Enhancing your motivation and drive, making it easier to overcome procrastination and stay committed to your goals.",
        "Improving your time management skills, enabling you to prioritize tasks and make the most out of your day.",
        "Increasing your creativity and problem-solving abilities, allowing you to find innovative solutions to challenges.",
        "Reducing distractions and improving your ability to maintain a productive mindset.",
        "Helping you develop healthy habits and rituals that support your productivity."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your daily routine, you can train your subconscious mind to align with your productivity goals. As you listen regularly, you may notice an increase in your focus, motivation, and ability to accomplish tasks. Remember, consistent use is key to experiencing lasting changes and becoming more productive."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Increasing productivity is a common goal for many individuals, and this subliminal is designed to help you achieve that. Before you listen, here are some preparation tips to consider:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set specific goals: Clearly define what you want to accomplish and break them down into actionable steps.",
        "Plan and prioritize: Create a schedule or to-do list to organize your tasks and focus on the most important ones.",
        "Eliminate distractions: Minimize interruptions, turn off notifications, and create a quiet workspace to help maintain focus.",
        "Create a positive mindset: Visualize yourself being productive and feeling motivated, reinforcing a positive attitude towards your goals."
      ]
    },
    {
      type: 'paragraph',
      text: "By using this subliminal regularly, you can reprogram your subconscious mind to align with your productivity goals. As you listen, envision yourself completing tasks efficiently and maintaining a high level of focus. Embrace this opportunity to boost your productivity and achieve more in your personal and professional life."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Become More Productive subliminal session! By focusing on boosting your productivity, you've taken a significant step towards achieving greater success in your personal and professional life. Now, take a moment to reflect on your experience and consider how you can continue to enhance your productivity moving forward."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Define your objectives and break them down into smaller, manageable tasks.",
        "Eliminate distractions: Create a conducive environment for focused work by removing potential distractions.",
        "Prioritize tasks: Identify and tackle the most important tasks first to maximize your productivity.",
        "Take regular breaks: Allow yourself short breaks to recharge and maintain focus.",
        "Practice time management: Use techniques such as Pomodoro or time blocking to optimize your schedule."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, productivity is a skill that can be developed and improved over time. Embrace the habits and strategies that work best for you and continue to challenge yourself. With dedication and persistence, you'll be amazed at what you can achieve. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Become More Productive subliminal help me increase my productivity?`, 
    answer: `The Become More Productive subliminal is designed to reprogram your subconscious mind with positive affirmations related to productivity. By listening to the audio regularly, you can enhance your focus, time management skills, motivation, and discipline. The subliminal messages work on a subconscious level to align your thoughts and beliefs with productivity, helping you achieve more in your personal and professional life.`,
    open: false
  },
  { 
    question: `When will I start noticing improvements in my productivity?`, 
    answer: `The time it takes to see improvements in productivity can vary from person to person. Some may experience changes in their mindset and behavior relatively quickly, while others may need more time for the subconscious reprogramming to take effect. Consistently listening to the Become More Productive subliminal and actively applying the principles of productivity in your daily life can help speed up the process.`,
    open: false 
  },
  { 
    question: `What are some ways to maximize the effectiveness of the Become More Productive subliminal?`, 
    answer: `To enhance the effectiveness of the Become More Productive subliminal, you can create a structured routine, set clear goals and priorities, eliminate distractions, and break tasks into manageable chunks. Practicing mindfulness, utilizing time management techniques, and surrounding yourself with a productive environment can also boost the impact of the subliminal messages. Remember, the subliminal is a tool to support your journey towards increased productivity, but it is up to you to take action and implement productive habits in your life.`,
    open: false 
  },
  { 
    question: `Can the Become More Productive subliminal replace hard work and effort?`, 
    answer: `No, the Become More Productive subliminal is not a substitute for hard work and effort. It is designed to help align your thoughts, beliefs, and mindset with productivity, but it is up to you to actively apply the principles and take action. The subliminal messages can support your journey towards increased productivity, but ultimately, it is your dedication and effort that will produce results.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the Become More Productive subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Become More Productive subliminal. However, it is important to note that results may vary and individual experiences may differ. It is always advisable to consult with a qualified professional if you have any concerns about your productivity or mental health.`,
    open: false 
  },
  { 
    question: `Can I listen to the Become More Productive subliminal while multitasking?`, 
    answer: `While you can listen to the Become More Productive subliminal while engaging in light activities, it is recommended to give your full attention to the audio for maximum effectiveness. Find a quiet and comfortable space where you can fully immerse yourself in the subliminal messages without distractions. This will allow your subconscious mind to absorb the affirmations and work towards enhancing your productivity.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>