<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're looking to turn back the clock and achieve a more youthful appearance, this subliminal program can help you do just that. By targeting your subconscious mind, it helps to reverse the signs of aging and support a more youthful look."
    },
    {
      type: 'heading',
      text: 'Here are some key benefits of using this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Reducing the appearance of fine lines and wrinkles.",
        "Improving the elasticity and firmness of your skin.",
        "Enhancing your natural radiance and glow.",
        "Promoting a more even skin tone and texture.",
        "Boosting your confidence and self-esteem in your appearance."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can shift your subconscious beliefs and perceptions about aging, helping you to manifest a more youthful appearance. Remember, consistency and patience are key to achieving long-lasting results. Embrace your journey towards a more vibrant and youthful you!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Looking younger and maintaining a youthful appearance is something many people desire. This subliminal program is designed to help you reverse the signs of aging and achieve a more youthful look. Before you start listening, consider these tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Visualize your desired outcome of achieving a younger appearance and feeling more confident in your skin.",
        "Create a positive mindset: Cultivate positive thoughts about your ability to reverse aging and embrace your inner beauty.",
        "Nourish your body: Prioritize a healthy diet, proper hydration, and regular exercise to support your overall well-being and youthful glow.",
        "Focus on the present moment: Keep your attention in the present while listening to the subliminal affirmations, allowing them to penetrate your subconscious mind."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal program is here to support you in your journey towards a more youthful appearance, but it is not a substitute for a healthy lifestyle. By combining regular listening with a positive mindset, self-care practices, and healthy habits, you can maximize the benefits of this program. Embrace your beauty, set your intention, and let the journey to a more youthful you begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Look Younger subliminal program! By actively working towards reversing the signs of aging, you are taking charge of your appearance and boosting your self-confidence. Reflect on the changes you can make to embrace a more youthful and vibrant outlook."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice good skincare: Develop a skincare routine to keep your skin healthy and nourished.",
        "Stay hydrated: Drink plenty of water throughout the day to maintain a youthful complexion.",
        "Eat a balanced diet: Include foods rich in antioxidants, vitamins, and minerals to support your skin health.",
        "Take care of your mental health: Engage in activities that reduce stress and promote overall well-being.",
        "Embrace positive body language: Stand tall, smile, and exude confidence to enhance your youthful appearance."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, looking younger is not just about external treatments; it’s also about taking care of your body and mind. Embrace a holistic approach to vibrancy and radiate youthfulness from within. You have the power to age gracefully and live life to the fullest. Keep shining!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Look Younger program work?`, 
    answer: `The Look Younger program utilizes subliminal messages to target your subconscious mind and help you shift your beliefs and attitudes about aging. By consistently listening to the subliminal audio, you can reprogram your mindset and enhance your inner and outer beauty, leading to a more youthful appearance. The subliminal messages work in conjunction with your own efforts to take care of your skin, exercise, and maintain a healthy lifestyle.`,
    open: false
  },
  { 
    question: `When will I start seeing results from the Look Younger program?`, 
    answer: `Results from the Look Younger program may vary from person to person. Some individuals may start noticing positive changes in their appearance early on, while others may take more time to see visible results. Consistent listening and combining the program with a healthy skincare routine and lifestyle habits can enhance the effectiveness of the program.`,
    open: false 
  },
  { 
    question: `Can the Look Younger program eliminate wrinkles and fine lines?`, 
    answer: `The Look Younger program is designed to help you achieve a more youthful appearance and reverse the signs of aging. While it can positively influence your mindset and promote healthy skin, it is important to note that individual results may vary. Incorporating the program into a comprehensive skincare routine that includes other anti-aging practices can maximize its potential benefits.`,
    open: false 
  },
  { 
    question: `Is the Look Younger program safe?`, 
    answer: `Yes, the Look Younger program is safe to use. The subliminal messages are intended to create positive shifts in your mindset and enhance your natural beauty. However, if you have any concerns about your skin or health, it is always advisable to consult with a dermatologist or healthcare professional.`,
    open: false 
  },
  { 
    question: `Can I solely rely on the Look Younger program to reverse the signs of aging?`, 
    answer: `The Look Younger program is designed to complement your efforts in maintaining a healthy lifestyle and skincare routine. While it can be a powerful tool in achieving a more youthful appearance, it is important to combine it with other skincare practices, such as protecting your skin from sun damage, eating a balanced diet, staying hydrated, and getting enough sleep. Remember, achieving and maintaining a youthful appearance is a holistic process.`,
    open: false 
  },
  { 
    question: `Will the Look Younger program make me look significantly younger overnight?`, 
    answer: `The Look Younger program is not a magic solution that will make you instantly look significantly younger. It is a tool designed to help you shift your mindset and enhance your beauty over time. The program works best when used consistently and in conjunction with healthy lifestyle choices and skincare practices. Patience and persistence are key when it comes to achieving long-lasting results.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>