<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Building and maintaining a positive and fulfilling relationship with your lover requires effort and intention. This subliminal is designed to help you cultivate and strengthen the important aspects of your relationship such as trust, communication, and love."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support you:'
    },
    {
      type: 'list',
      items: [
        "Enhancing trust and deepening the bond between you and your lover.",
        "Improving communication skills to express your needs, feelings, and desires effectively.",
        "Nurturing a loving and supportive environment for both partners.",
        "Developing empathy and understanding to foster compassion and closeness.",
        "Resolving conflicts and disagreements in a healthy and productive manner.",
        "Strengthening emotional and physical intimacy in your relationship."
      ]
    },
    {
      type: 'paragraph',
      text: "By consistently listening to this subliminal, you can align your subconscious mind with the positive changes you desire in your relationship. As you and your lover both cultivate these qualities, you can create a deeper connection and a more loving partnership. Remember, building a positive relationship takes time and effort from both sides, but with dedication, you can create a fulfilling and lasting bond with your lover."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Building and maintaining a positive relationship with your lover requires effort, understanding, and open communication. This subliminal aims to support you in cultivating a loving and fulfilling bond with your partner. Before starting, consider these suggestions to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your desires: Clarify what you want to achieve in your relationship, such as increased trust, improved communication, or a deeper emotional connection.",
        "Release negative emotions: Let go of any past hurts or resentments to create space for positive growth.",
        "Practice effective communication: Ensure that you express your needs, listen actively, and cultivate understanding.",
        "Visualize a harmonious relationship: Picture the love, happiness, and shared experiences you desire with your lover while listening to the subliminal affirmations."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to help align your subconscious mind with your desires for a positive relationship. As you listen regularly, you may find it easier to communicate, feel more connected, and experience greater harmony with your partner. Take a moment to set your intention, focus on the love you share, and let this journey towards a fulfilling relationship begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Positive Relationship with Lover subliminal! By working to cultivate a positive and fulfilling relationship with your lover, you are taking important steps towards building a strong bond based on trust, communication, and love. Now, it's time to reflect on your experience and continue nurturing your relationship."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your relationship: Take some time to think about the strengths and areas for improvement in your relationship.",
        "Practice open communication: Foster healthy communication by actively listening and expressing your thoughts and feelings.",
        "Cultivate trust: Prioritize trust-building activities, such as keeping promises and being reliable.",
        "Create quality time: Plan activities and dates that allow you to connect and enjoy each other's company."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, building a positive relationship takes effort from both partners. Focus on open communication, trust, and quality time together. Embrace the opportunity to strengthen your bond and create a loving connection with your partner. Wishing you a fulfilling and joyful journey together!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  {
    question: `How can the Positive Relationship with Lover subliminal help improve my relationship?`,
    answer: `The Positive Relationship with Lover subliminal works by targeting your subconscious mind to promote positive behaviors and attitudes in your relationship. By listening to the subliminal regularly, you can increase your capacity for love, strengthen trust, enhance communication, and foster a deeper connection with your lover. It can help you both create a more fulfilling and harmonious relationship.`,
    open: false
  },
  {
    question: `How long does it take to see improvements in my relationship when using the Positive Relationship with Lover subliminal?`,
    answer: `The time it takes to see improvements in your relationship can vary depending on your unique circumstances. Some people may notice positive changes right away, while others may require more time for the subliminal messages to take effect. Consistent listening is important for the subliminal to make a lasting impact on your subconscious mind and manifest positive changes in your relationship.`,
    open: false
  },
  {
    question: `Can the Positive Relationship with Lover subliminal fix all my relationship problems?`,
    answer: `The Positive Relationship with Lover subliminal is a tool designed to help improve your relationship by fostering positive behaviors, attitudes, and communication. While it can contribute to creating a healthier and more loving bond, it is important to recognize that relationships are complex and multifaceted. It is also important to actively engage in open and honest communication, compromise, and seek professional guidance if needed, to address any deeper issues that may be present in your relationship.`,
    open: false
  },
  {
    question: `Is it necessary for both partners to listen to the Positive Relationship with Lover subliminal for it to be effective?`,
    answer: `While it can be beneficial for both partners to listen to the subliminal to align their mindset towards a positive relationship, it is not a requirement for its effectiveness. The subliminal can influence your own thoughts, attitudes, and behaviors, which can contribute to positive changes in the relationship dynamics. However, open communication and mutual effort from both partners are important for fostering a healthy and satisfying relationship.`,
    open: false
  },
  {
    question: `Are there any risks or side effects associated with listening to the Positive Relationship with Lover subliminal?`,
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, it is important to note that the subliminal is designed to complement your personal growth and self-improvement efforts in the context of a healthy and consensual relationship. It is not intended to substitute professional advice or therapy for any relationship or psychological issues.`,
    open: false
  },
  {
    question: `Can the Positive Relationship with Lover subliminal be used to attract a specific person into my life?`,
    answer: `The Positive Relationship with Lover subliminal is not intended to attract or manipulate a specific person into your life against their free will. It is designed to help you develop a positive mindset, improve your relationship dynamics, and foster a loving bond with your current lover. It is important to respect the autonomy and consent of others and to build relationships based on mutual respect and genuine connection.`,
    open: false
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>