<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you desire to become more sociable and outgoing, this subliminal can be a valuable tool in your journey. By targeting your subconscious mind, it helps you develop social skills and boosts your confidence in social situations."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help you:'
    },
    {
      type: 'list',
      items: [
        "Enhancing your communication skills, including active listening and effective conversation techniques.",
        "Boosting your self-confidence and self-esteem in social interactions.",
        "Reducing social anxiety and fear of judgment in social situations.",
        "Improving your ability to connect with others and build meaningful relationships.",
        "Developing a more positive and friendly mindset towards socializing."
      ]
    },
    {
      type: 'paragraph',
      text: "With consistent use of this subliminal, you can rewire your subconscious mind to embrace sociability and outgoingness. Over time, you may notice yourself becoming more comfortable, confident, and at ease in social settings. Remember, practice and patience are vital in developing social skills, and this subliminal can provide the support you need along the way."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Building social skills and becoming more outgoing can greatly enhance your interactions and connections with others. This subliminal is designed to support you on your journey towards social growth and increased confidence. Before you begin, here are some tips to help you maximize your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intentions: Clearly define what areas you want to improve, whether it's initiating conversations, overcoming social anxiety, or feeling more comfortable in group settings.",
        "Practice positive self-talk: Replace self-doubt and negative thoughts with affirmations and encouraging statements that boost your confidence.",
        "Visualize success: Imagine yourself confidently engaging in social situations, forming connections, and enjoying meaningful conversations.",
        "Maintain an open mind: Approach this subliminal with a willingness to learn, grow, and embrace new experiences."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is a tool to help you develop your social skills and boost your confidence. As you listen regularly, you may find that you feel more at ease in social settings, find it easier to start conversations, and develop deeper connections with others. So take a moment to set your intentions, embrace the process, and let your transformation into a more sociable and outgoing person begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Become More Sociable and Outgoing session! By investing in your social skills and confidence, you are on the path to transforming your social interactions and enhancing your overall well-being. Now, it's time to reflect on your experience and continue your personal growth."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice active listening: Pay attention to others, show genuine interest, and engage in conversations.",
        "Step out of your comfort zone: Challenge yourself to initiate conversations and meet new people.",
        "Join social groups or clubs: Find communities with similar interests and engage in activities together.",
        "Work on your body language: Maintain good posture, make eye contact, and project confidence."
      ]
    },
    {
      type: 'paragraph',
      text: "Becoming more sociable and outgoing is a gradual process that requires practice and patience. Celebrate your progress and keep pushing yourself to grow. Remember, you have the power to positively influence your social interactions and create meaningful connections. Keep shining and enjoy the journey!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Become More Sociable and Outgoing subliminal help me?`, 
    answer: `The Become More Sociable and Outgoing subliminal is designed to help you develop social skills, boost your confidence, and overcome any social anxiety or shyness. By regularly listening to the subliminal messages, your subconscious mind is influenced to adopt a more sociable and outgoing mindset, allowing you to feel more comfortable and confident in social situations.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Become More Sociable and Outgoing subliminal?`, 
    answer: `Results may vary from person to person, and the time it takes to see results can depend on factors such as individual circumstances and consistency in listening to the subliminal. Some people may start noticing positive changes right away, while others may require more time and consistent listening to fully develop social skills and boost their confidence.`,
    open: false 
  },
  { 
    question: `Can the Become More Sociable and Outgoing subliminal replace social skills training or therapy?`, 
    answer: `No, the Become More Sociable and Outgoing subliminal is not intended to replace social skills training or therapy. It is designed to supplement your efforts in developing social skills and boosting confidence. If you are experiencing significant social anxiety or have difficulty with social interactions, it may be beneficial to seek professional guidance from a therapist or coach.`,
    open: false 
  },
  { 
    question: `How can I maximize the effectiveness of the Become More Sociable and Outgoing subliminal?`, 
    answer: `To maximize the effectiveness of the Become More Sociable and Outgoing subliminal, it is recommended to listen to the audio regularly and consistently. Additionally, practice exposure to social situations, push yourself out of your comfort zone, and actively work on developing social skills. Combine the subliminal with practical exercises and techniques for best results.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Become More Sociable and Outgoing subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Become More Sociable and Outgoing subliminal. However, if you have any concerns about social anxiety or your mental health, it is always advisable to seek professional guidance.`,
    open: false 
  },
  { 
    question: `Can the Become More Sociable and Outgoing subliminal help me overcome social anxiety?`, 
    answer: `While the Become More Sociable and Outgoing subliminal can help boost your confidence and develop social skills, it may not be a complete solution for overcoming social anxiety. If you are struggling with social anxiety, it is recommended to seek professional help from a therapist who specializes in anxiety disorders.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>