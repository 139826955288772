<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're feeling stuck or dissatisfied in your current job or career, this subliminal can help you manifest your dream job and career. By aligning your thoughts, beliefs, and actions with your desired outcome, you can create the life and professional success you've always envisioned."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support you:'
    },
    {
      type: 'list',
      items: [
        "By reprogramming your subconscious mind, it can help you overcome limiting beliefs and self-doubt that may be holding you back from pursuing your dream job or career.",
        "By boosting your confidence and self-worth, it can help you present yourself more effectively in job interviews and professional settings.",
        "By enhancing your focus and motivation, it can help you take the necessary steps to achieve your career goals and make progress towards your desired outcome.",
        "By attracting opportunities, synchronicities, and connections that align with your dream job or career, it can help pave the way for new and exciting possibilities.",
        "By instilling a positive and optimistic mindset, it can help you overcome setbacks and obstacles with resilience and determination."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, manifesting your dream job and career takes time and effort. By consistently listening to this subliminal and taking inspired actions towards your goals, you can create the life and professional success you've always envisioned. Trust in the process and believe in your abilities!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Are you ready to manifest your dream job and career? This subliminal is designed to help you align your thoughts, beliefs, and actions with your desired outcome. Before starting your manifestation journey, here are some tips to prepare:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your vision: Get clear on what your dream job and career looks like. Visualize yourself in that role and feel the emotions associated with it.",
        "Set positive intentions: Focus on what you want to attract, using clear, affirming language.",
        "Release limiting beliefs: Identify and let go of any negative beliefs or self-doubt that may be hindering your progress.",
        "Take inspired action: Take small steps towards your dream job and career, such as updating your resume, networking, or researching opportunities.",
        "Trust the process: Believe in yourself and have faith that the universe is working in your favor to manifest your desires."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is a powerful tool to support you on your manifestation journey. By regularly listening and staying aligned with your intentions, you can create the life and professional success you've always envisioned. Trust in your ability to manifest, stay positive, and let's begin manifesting your dream job and career!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Manifest your Dream Job and Career subliminal session! You have taken an important step towards aligning your thoughts, beliefs, and actions with your desired outcome. Reflecting on this journey and continuing your efforts will help you create the life and professional success you've always envisioned."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your goals and aspirations: Take time to clearly define what you want in your dream job and career.",
        "Visualize success: Imagine yourself in your ideal professional role and imagine the positive outcomes.",
        "Take inspired action: Actively pursue opportunities that align with your aspirations and make steps towards your goals.",
        "Stay positive and persistent: Believe in yourself, stay motivated, and overcome any obstacles or setbacks that may come your way."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, manifesting your dream job and career requires patience, determination, and consistency. Stay focused on your goals, take inspired action, and maintain a positive mindset. With dedication and unwavering belief in yourself, you can create the professional success and fulfillment you've always dreamed of. Best of luck!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Manifest your Dream Job and Career subliminal help me?`, 
    answer: `The Manifest your Dream Job and Career subliminal is designed to help you align your thoughts, beliefs, and actions with your desired job and career path. By listening to the subliminal regularly, you can reprogram your subconscious mind to attract opportunities, build confidence, and take inspired actions towards your professional goals. It aims to create a positive and empowering mindset that supports your journey towards manifesting your dream job and career.`,
    open: false
  },
  { 
    question: `When will I start seeing results from the Manifest your Dream Job and Career subliminal?`, 
    answer: `Results can vary from person to person and depend on various factors, such as your mindset, beliefs, and the actions you take towards your desired job and career. Some individuals may start noticing shifts in their thoughts, behaviors, and opportunities soon after listening to the subliminal, while others may need more time for the subconscious reprogramming to take effect. Consistent listening and taking inspired actions in alignment with your goals can enhance the manifestation process.`,
    open: false 
  },
  { 
    question: `Can the Manifest your Dream Job and Career subliminal guarantee that I will get my dream job?`, 
    answer: `The Manifest your Dream Job and Career subliminal is a tool to support and amplify your efforts in manifesting your dream job and career. While it can help you align your thoughts, beliefs, and actions towards your desired outcome, it cannot guarantee specific results. Ultimately, the manifestation process depends on a combination of factors, including your mindset, skills, qualifications, and external opportunities. The subliminal is intended to assist you in creating a positive mindset and taking inspired actions, but tangible results may vary.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Manifest your Dream Job and Career subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Manifest your Dream Job and Career subliminal. However, it is important to note that the subliminal is intended to work in conjunction with your own efforts, such as building relevant skills, qualifications, and actively pursuing opportunities that align with your goals. It is not a substitute for taking tangible actions towards your dream job and career.`,
    open: false 
  },
  { 
    question: `Can I listen to the Manifest your Dream Job and Career subliminal while I sleep?`, 
    answer: `Yes, you can listen to the Manifest your Dream Job and Career subliminal while you sleep. The subconscious mind is highly receptive during sleep, and incorporating the subliminal into your bedtime routine can enhance its effectiveness. However, you can also listen to it during your waking hours while engaging in relaxing activities like meditation or visualization.`,
    open: false 
  },
  { 
    question: `Is it necessary to have a clear vision of my dream job before listening to the subliminal?`, 
    answer: `While having a clear vision of your dream job can be helpful, it is not necessary before listening to the Manifest your Dream Job and Career subliminal. The subliminal can assist you in clarifying your desires and uncovering your professional passions as you progress through the manifestation process. As you listen to the subliminal regularly, pay attention to any insights, inspirations, or intuitive nudges that may arise, as they can guide you towards greater clarity and alignment with your dream job and career.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>