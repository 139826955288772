<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Emotional healing is a journey that allows us to let go of negative emotions and cultivate a positive mindset. This subliminal is designed to support your emotional well-being and guide you towards inner peace."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help you:'
    },
    {
      type: 'list',
      items: [
        "Release negative emotions and patterns that no longer serve you.",
        "Discover healing practices and techniques to promote emotional well-being.",
        "Nurture self-love and self-compassion for a healthier relationship with yourself.",
        "Cultivate a positive mindset and develop resilience in the face of challenges.",
        "Find inner peace and balance in your emotions."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can engage with the power of your subconscious mind to facilitate emotional healing and growth. Embrace the journey and give yourself the opportunity to let go of the past, find healing, and create a brighter future. Remember, healing takes time and patience, but you have the strength within you to embark on this transformative path."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a journey of emotional healing can be transformative and empowering. This subliminal is designed to support your path towards emotional well-being and inner peace. Before starting, consider implementing these strategies for a more enriching experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a safe and quiet space: Find a comfortable environment where you can fully immerse yourself without distractions.",
        "Set your intention: Clarify your desire for emotional healing and growth, visualizing the positive changes you want to manifest.",
        "Practice self-compassion: Be gentle with yourself and embrace any emotions that arise during the listening process.",
        "Engage in grounding techniques: Prioritize activities such as deep breathing, meditation, or journaling to create a sense of inner stability.",
        "Maintain an open mind and heart: Release any expectations and approach this practice with a willingness to receive the healing messages embedded in the subliminal affirmations."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal aims to assist you in releasing negative emotions and cultivating a positive mindset. As you engage in regular listening, allow yourself to become more attuned to your emotions and experiences, finding solace and growth along the way. Trust the process, be patient, and let the journey towards emotional healing and inner peace unfold before you."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the General Emotional Healing subliminal! By dedicating time to focus on your emotional well-being, you are taking an important step towards cultivating inner peace and restoring balance in your life. As you reflect on your experience, remember that healing is a continuous process."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice self-care: Prioritize activities that promote relaxation and emotional well-being.",
        "Express yourself: Engage in creative outlets such as journaling, art, or music to process and release emotions.",
        "Seek support: Reach out to trusted friends, family, or professionals for additional support or guidance.",
        "Nurture positivity: Surround yourself with positive influences, practice gratitude, and challenge negative thoughts."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, healing takes time, and it's important to be patient and gentle with yourself. Embrace the journey ahead and continue to prioritize your emotional well-being. You have the power to create positive changes and find inner peace. Keep shining!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the General Emotional Healing subliminal help me?`, 
    answer: `The General Emotional Healing subliminal is designed to support and facilitate emotional healing by reprogramming your subconscious mind with positive affirmations and messages. It can help you release negative emotions, heal past trauma, and cultivate a more positive and resilient mindset. By consistently listening to the subliminal, you may experience increased emotional well-being, inner peace, and personal growth.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the General Emotional Healing subliminal?`, 
    answer: `Results vary from person to person, and it may take time for the effects of the General Emotional Healing subliminal to manifest. Some individuals may notice positive changes in their emotional well-being and mindset right away, while others may require more time and consistent listening. We recommend listening to the subliminal daily and consistently for at least a few weeks to allow the affirmations to deeply penetrate your subconscious mind.`,
    open: false 
  },
  { 
    question: `Can the General Emotional Healing subliminal replace therapy or professional help?`, 
    answer: `The General Emotional Healing subliminal is not a substitute for therapy or professional help. It is designed to complement and enhance the healing process by promoting a positive mindset and facilitating emotional well-being. If you are experiencing severe emotional distress or mental health issues, we recommend seeking the guidance of a qualified therapist or healthcare professional.`,
    open: false 
  },
  { 
    question: `How can I make the General Emotional Healing subliminal more effective?`, 
    answer: `To enhance the effectiveness of the General Emotional Healing subliminal, it can be helpful to create a supportive environment for your healing journey. This may include engaging in other healing practices such as journaling, meditation, or therapy. Taking care of your physical health through proper nutrition, regular exercise, and adequate rest can also contribute to your overall emotional well-being. Additionally, being patient and compassionate with yourself as you navigate the healing process can help create a nurturing space for transformation.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the General Emotional Healing subliminal?`, 
    answer: `There are no known risks or side effects associated with listening to the General Emotional Healing subliminal. However, it is important to note that everyone's experience may be different, and some individuals may experience temporary discomfort or emotional release during the healing process. If you have any concerns or questions, please consult with a healthcare professional.`,
    open: false 
  },
  { 
    question: `Can the General Emotional Healing subliminal be used in combination with other healing modalities?`, 
    answer: `Yes, the General Emotional Healing subliminal can be used in conjunction with other healing modalities such as therapy, meditation, or energy healing practices. It is important to find what works best for you and create a comprehensive approach to your emotional healing journey. Integrating various modalities can provide a holistic and synergistic approach to your well-being.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>