<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're looking to enhance your basketball skills and take your game to the next level, this subliminal can help you achieve that. With targeted suggestions, it is designed to improve your dribbling, shooting, passing, and overall game performance."
    },
    {
      type: 'heading',
      text: "Here's what you can expect from this subliminal:"
    },
    {
      type: 'list',
      items: [
        "Increased focus and concentration during training sessions and games.",
        "Improved hand-eye coordination and ball handling skills.",
        "Enhanced shooting accuracy and consistency.",
        "Better passing and decision-making abilities on the court.",
        "Boosted confidence and mental resilience to overcome challenges.",
        "Faster reaction times and agility for quicker movements on the court."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, along with consistent training and practice, you can expedite your progress and become a master of basketball skills. It is important to note that while subliminals can enhance your abilities, putting in the work and dedication is essential in reaching your full potential as a basketball player."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Enhancing your basketball skills requires dedication, practice, and a mindset focused on improvement. This subliminal is designed to help you develop mastery in dribbling, shooting, passing, and overall game performance. Before you start your training session, consider these tips to optimize your learning:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set specific goals: Identify the areas of your game you want to target and improve, such as shooting accuracy, ball handling, or court vision.",
        "Visualize success: Imagine yourself performing basketball skills with precision and confidence, visualizing the desired outcomes.",
        "Create a focused practice routine: Structure your training sessions with drills that target specific skills and challenge you to improve.",
        "Maintain a growth mindset: Embrace challenges, setbacks, and constructive criticism as opportunities for learning and growth."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is a tool to enhance your mindset and accelerate skill development. As you listen regularly, you may notice increased motivation, focus, and confidence in your abilities. Embrace each training session as an opportunity for growth, and the journey to mastery will become more rewarding. Now lace up your sneakers, grab your basketball, and let the training begin!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Basketball Skills Training and Mastery subliminal! By dedicating yourself to improving your basketball skills, you've taken a significant step towards becoming a master of the game. Now, it's time to reflect on your experience and continue your journey towards basketball excellence."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice consistently: Dedicate regular time to daily training sessions and practice your skills with focus and determination.",
        "Set measurable goals: Define specific targets for each skill area, such as increasing shooting accuracy or improving dribbling speed.",
        "Visualize success: Use visualization techniques to see yourself performing at your best and achieving your basketball goals.",
        "Analyze and learn: Watch game footage, study successful players, and analyze your own performances to identify areas for improvement."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, mastering basketball skills requires perseverance, dedication, and a growth mindset. Embrace the challenges, keep pushing yourself, and never stop honing your abilities. With consistent training and practice, you have the potential to become the basketball player you aspire to be. Keep shooting for greatness!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Basketball Skills Training and Mastery subliminal help improve my basketball skills?`, 
    answer: `The Basketball Skills Training and Mastery subliminal is designed to influence your subconscious mind, helping you develop a more focused and disciplined approach to training and practice. By listening to the subliminal messages regularly, you may experience improvements in your dribbling, shooting, passing, and overall game performance. The subliminal can help reinforce positive mindset and visualization techniques, enhancing your ability to learn and master basketball skills.`,
    open: false
  },
  { 
    question: `How often should I listen to the Basketball Skills Training and Mastery subliminal?`, 
    answer: `We recommend listening to the Basketball Skills Training and Mastery subliminal consistently for best results. You can incorporate it into your daily routine, such as listening before or after basketball practice, during workouts, or at a time when you can relax and focus on the messages. Consistency is key, so listen as frequently and for as long as you find beneficial for your personal development.`,
    open: false 
  },
  { 
    question: `Can the Basketball Skills Training and Mastery subliminal replace actual basketball training and practice?`, 
    answer: `No, the subliminal is designed to complement your existing training and practice routine. It can help enhance your mindset and focus, boost confidence, and reinforce positive habits. However, to truly improve your basketball skills, it is crucial to engage in regular physical practice, seek guidance from coaches, and participate in team drills and gameplay.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Basketball Skills Training and Mastery subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, it is important to note that the subliminal is intended to supplement your basketball training and practice, not replace them. It is always advisable to listen to your body, avoid overtraining, and consult with a healthcare professional if you have any concerns about your physical performance or health.`,
    open: false 
  },
  { 
    question: `How long will it take to see improvements in my basketball skills?`, 
    answer: `The time it takes to see improvements in your basketball skills can vary depending on various factors, including your current skill level, commitment to training, and consistency in listening to the subliminal. While some individuals may notice positive changes relatively quickly, others may require more time and practice to fully benefit from the subliminal messages. It is important to approach the learning process with patience, perseverance, and dedication.`,
    open: false 
  },
  { 
    question: `Can the Basketball Skills Training and Mastery subliminal be used by beginners?`, 
    answer: `Yes, the Basketball Skills Training and Mastery subliminal can be used by beginners looking to improve their basketball skills. The subliminal messages can help instill a growth mindset, increase motivation, and enhance focus, which are all essential for skill development. Whether you are a beginner or an experienced player, the subliminal can support you in reaching your full potential on and off the court.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>