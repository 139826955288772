<template>
  <div>
    <div class="mx-8 mt-3 mb-0">
      <h3>{{ title }}</h3>
    </div>
    <v-row class="mx-3 my-0">
      <v-col cols="12">
        <Card>
          <template>
            <div class="d-flex align-center mb-3">
              <template v-if="isCollapse">
                <v-btn
                  class="mr-3 btn-icon"
                  style="color: var(--ma-tufts-blue)"
                  @click="getAction(selected, 'additem')"
                  v-if="showAddItem"
                >
                  <v-icon>mdi-plus-outline</v-icon>
                </v-btn>
                <v-btn
                  class="mr-3 btn-icon"
                  style="color: var(--ma-tufts-blue)"
                  @click="getAction([], 'add')"
                  v-if="add"
                >
                  <v-icon>mdi-plus-outline</v-icon>
                </v-btn>
                <v-btn
                  v-if="showDeleteAll"
                  style="color: red"
                  class="mr-3 btn-icon"
                  @click="getAction(selected, 'deleteall')"
                >
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </template>
              <template v-else>
                <v-btn
                  class="mr-3 add-btn_table"
                  @click="getAction(selected, 'additem')"
                  v-if="showAddItem"
                  >Add Item</v-btn
                >
                <v-btn
                  class="mr-3 add-btn_table"
                  @click="getAction([], 'add')"
                  v-if="add"
                  >Add</v-btn
                >
                <v-btn
                  v-if="showDeleteAll"
                  class="mr-3 delete-all-btn"
                  @click="getAction(selected, 'deleteall')"
                  >Delete All</v-btn
                >
              </template>

              <v-spacer></v-spacer>
              <div class="d-flex">
                <v-text-field
                  class="search_table mx-1"
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  solo
                  dense
                  hide-details
                ></v-text-field>
                <v-autocomplete 
                  v-model="sort" 
                  :items="sorts" 
                  clearable 
                  solo dense 
                  color="blue-grey lighten-2"
                  placeholder="Sort" 
                  hide-details 
                  item-text="text" 
                  item-value="value"
                />
                <v-autocomplete 
                  v-if="addVisible == true"
                  v-model="visible" 
                  :items="visibles" 
                  clearable 
                  solo dense 
                  color="blue-grey lighten-2"
                  placeholder="Visible" 
                  hide-details 
                  item-text="text" 
                  item-value="value"
                  class="ml-1"
                />
              </div>
            </div>
            <Card>
              <v-data-table
                show-select
                v-model="selected"
                :item-key="itemkey"
                :headers="headers"
                :items="items"
                :search="search"
                :loading="loading"
                :items-per-page="limit"
                mobile-breakpoint="0"
                :show-expand="showexpand"
                :single-expand="true"
                hide-default-footer
              >
                <template v-slot:expanded-item="{ headers, item }">
                  <template v-if="expandtype == 'track' && item.tracks != null">
                    <template>
                      <td></td>
                      <td></td>
                      <td colspan="2">
                        <p class="pt-5 pb-0" v-for="info in item.tracks">
                          Version {{ info.version }}
                        </p>
                      </td>
                      <td colspan="2">
                        <p class="pt-5 pb-0" v-for="info in item.tracks">
                          Duration {{ convertToTime(info.duration) }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="pt-5 pb-0"
                          v-for="(info, index) in item.tracks"
                        >
                          <v-icon
                            size="30"
                            style="color: red"
                            @click="getAction(item, 'expandpause', index)"
                            v-if="isCurrentTrackPlaying(item, index)"
                          >
                            mdi-pause
                          </v-icon>
                          <v-icon
                            size="30"
                            style="color: var(--ma-tufts-blue)"
                            @click="getAction(item, 'expandplay', index)"
                            v-else
                          >
                            mdi-play
                          </v-icon>
                          <v-icon size="30" style="color: var(--ma-tufts-blue)">
                            mdi-format-list-bulleted
                          </v-icon>
                          <v-icon
                            size="30"
                            style="color: red"
                            @click="getAction(info, 'expanddelete', index)"
                          >
                            mdi-trash-can-outline
                          </v-icon>
                        </p>
                      </td>
                    </template>
                  </template>

                  <template v-if="expandtype == 'subliminal'">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2">
                      <p class="pt-5 pb-0" v-for="info in item.tracks">
                        {{ info.title }}
                      </p>
                    </td>
                    <td>
                      <p class="pt-5 pb-0" v-for="info in item.tracks">
                        {{ info.description }}
                      </p>
                    </td>
                    <td>
                      <p class="pt-5 pb-0" v-for="info in item.tracks">
                        Version {{ info.version }}
                      </p>
                    </td>
                    <td>
                      <p class="pt-5 pb-0" v-for="info in item.tracks">
                        {{ info.volume }}
                      </p>
                    </td>
                    <td colspan="2">
                      <p class="pt-5 pb-0" v-for="(info, index) in item.tracks">
                        <v-icon
                          size="30"
                          style="color: red"
                          @click="getAction(item, 'expandpause', index)"
                          v-if="isCurrentSubliminalTrackPlaying(item, index)"
                        >
                          mdi-pause
                        </v-icon>
                        <v-icon
                          size="30"
                          style="color: var(--ma-tufts-blue)"
                          @click="getAction(item, 'expandplay', index)"
                          v-else
                        >
                          mdi-play
                        </v-icon>
                        <v-icon
                          size="30"
                          @click="getAction(info, 'expandedit', index)"
                          style="color: var(--ma-tufts-blue)"
                        >
                          mdi-square-edit-outline
                        </v-icon>
                        <v-icon
                          size="30"
                          style="color: red"
                          @click="getAction(info, 'expanddelete', index)"
                        >
                          mdi-trash-can-outline
                        </v-icon>
                      </p>
                    </td>
                  </template>

                  <template v-if="expandtype == 'playlist'">
                    <td></td>
                    <td></td>
                    <td>
                      <div class="pt-5 pb-0" v-for="info in item.subliminals">
                        <img
                          class="ml-3"
                          :src="`${info.cover}`"
                          width="40"
                          height="40"
                        />
                      </div>
                    </td>
                    <td>
                      <p class="pt-5 pb-0" v-for="info in item.subliminals">
                        {{ info.title }}
                      </p>
                    </td>
                    <td>
                      <p class="pt-5 pb-0" v-for="info in item.subliminals">
                        {{ info.description }}
                      </p>
                    </td>
                    <td>
                      <p class="pt-5 pb-0" v-for="info in item.subliminals">
                        {{ parseDataName(info, "category_id") }}
                      </p>
                    </td>
                    <td>
                      <p
                        class="pt-5 pb-0"
                        v-for="(info, index) in item.subliminals"
                      >
                        {{ parseDataName(info, "moods_id") }}
                      </p>
                    </td>
                    <td>
                      <p class="pt-5 pb-0" v-for="info in item.subliminals">
                        {{ parseDataName(info, "subscription_id") }}
                      </p>
                    </td>
                    <td>
                      <p
                        class="pt-5 pb-0"
                        v-for="(info, index) in item.subliminals"
                      >
                        <v-icon
                          size="30"
                          style="color: red"
                          @click="getAction(item, 'pause', index)"
                          v-if="isCurrentPlaylistPlaying(item, index)"
                        >
                          mdi-pause
                        </v-icon>
                        <v-icon
                          size="30"
                          style="color: var(--ma-tufts-blue)"
                          @click="getAction(item, 'play', index)"
                          v-else
                        >
                          mdi-play
                        </v-icon>
                        <v-icon
                          size="30"
                          style="color: red"
                          @click="getAction(info, 'expanddelete', index)"
                        >
                          mdi-trash-can-outline
                        </v-icon>
                      </p>
                    </td>
                  </template>

                  <template v-if="expandtype == 'magus'">
                    <td></td>
                    <td></td>
                    <td>
                      <div class="pt-5 pb-0" v-for="info in item.info">
                        <img
                          class="ma-2"
                          v-if="info.cover != `${url}/api/image/`"
                          :src="info.cover"
                          width="40"
                          height="40"
                        />
                        <p class="pt-5 pb-0" v-else>No Image</p>
                      </div>
                    </td>
                    <td>
                      <p
                        class="pt-5 pb-0 align-center justify-center"
                        v-for="info in item.info"
                      >
                        {{ info.title }}
                      </p>
                    </td>
                    <td colspan="2">
                      <p
                        class="pt-5 pb-0 align-center justify-center"
                        v-for="info in item.info"
                      >
                        {{ info.short_description }}
                      </p>
                    </td>
                    <td>
                      <p class="pt-5 pb-0" v-for="(info, index) in item.info">
                        <v-icon
                          size="30"
                          style="color: var(--ma-tufts-blue)"
                          @click="getAction(info, 'expandedit', index)"
                        >
                          mdi-square-edit-outline
                        </v-icon>
                        <v-icon
                          size="30"
                          style="color: red"
                          @click="getAction(info, 'expanddelete', index)"
                        >
                          mdi-trash-can-outline
                        </v-icon>
                      </p>
                    </td>
                  </template>
                </template>

                <template v-slot:item.image="{ item }">
                  <v-img
                    class="ma-2"
                    :src="`${item.image}`"
                    width="50"
                    height="50"
                  />
                </template>

                <template v-slot:item.cover="{ item }">
                  <v-img
                    class="ma-2"
                    :src="`${item.cover}`"
                    width="50"
                    height="50"
                  />
                </template>

                <template v-slot:item.is_influencer="{ item }">
                  <span>{{ parseUtilsData(item, "is_influencer") }}</span>
                </template>

                <template v-slot:item.moods_id="{ item }">
                  <span>{{ parseDataName(item, "moods_id") }}</span>
                </template>
                
                <template v-slot:item.description="{ item }">
                  <span class="text-limit-3">{{ item.description }}</span>
                </template>

                <template v-slot:item.category_id="{ item }">
                  <span>{{ parseDataName(item, "category_id") }}</span>
                </template>

                <template v-slot:item.subscription_id="{ item }">
                  <span>{{ parseDataName(item, "subscription_id") }}</span>
                </template>

                <template v-slot:item.audio_type_id="{ item }">
                  <span>{{ parseDataName(item, "audio_type_id") }}</span>
                </template>

                <!-- action column in subliminal table -->
                <template v-slot:item.action_subliminal="{ item }">
                  <v-tooltip top v-if="isCurrentSubliminalPlaying(item)">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="mr-1"
                        size="30"
                        style="color: red"
                        @click="getAction(item, 'pause')"
                      >
                        mdi-pause-box-outline
                      </v-icon>
                    </template>
                    <span>Pause</span>
                  </v-tooltip>

                  <v-tooltip top v-else>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="mr-1"
                        size="30"
                        style="color: var(--ma-tufts-blue)"
                        @click="getAction(item, 'play')"
                      >
                        mdi-play-box-outline
                      </v-icon>
                    </template>
                    <span>Play</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="mr-1"
                        size="30"
                        style="color: var(--ma-tufts-blue)"
                        @click="getAction(item, 'additem')"
                      >
                        mdi-plus-box-outline
                      </v-icon>
                    </template>
                    <span>Add Item</span>
                  </v-tooltip>

                  <v-tooltip top v-if="item.toggle == true">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="mr-1"
                        size="30"
                        style="color: #4caf50"
                        @click="getAction(item, 'toggle')"
                      >
                        mdi-toggle-switch
                      </v-icon>
                    </template>
                    <span>Set as Invisible</span>
                  </v-tooltip>

                  <v-tooltip top v-else>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="mr-1"
                        size="30"
                        style="color: #4caf50"
                        @click="getAction(item, 'toggle')"
                      >
                        mdi-toggle-switch-off-outline
                      </v-icon>
                    </template>
                    <span>Set as Visible</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="mr-1"
                        size="30"
                        style="color: var(--ma-tufts-blue)"
                        @click="getAction(item, 'update')"
                      >
                        mdi-square-edit-outline
                      </v-icon>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="mr-1"
                        size="30"
                        style="color: red"
                        @click="getAction(item, 'delete')"
                      >
                        mdi-trash-can-outline
                      </v-icon>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </template>

                <!-- action column add update delete toggle -->
                <template v-slot:item.add_update_delete="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="mr-1"
                        size="30"
                        style="color: var(--ma-tufts-blue)"
                        @click="getAction(item, 'additem')"
                      >
                        mdi-plus-box-outline
                      </v-icon>
                    </template>
                    <span>Add Item</span>
                  </v-tooltip>

                  <v-tooltip top v-if="item.toggle == true">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="mr-1"
                        size="30"
                        style="color: #4caf50"
                        @click="getAction(item, 'toggle')"
                      >
                        mdi-toggle-switch
                      </v-icon>
                    </template>
                    <span>Set as Invisible</span>
                  </v-tooltip>

                  <v-tooltip top v-else>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="mr-1"
                        size="30"
                        style="color: #4caf50"
                        @click="getAction(item, 'toggle')"
                      >
                        mdi-toggle-switch-off-outline
                      </v-icon>
                    </template>
                    <span>Set as Visible</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="mr-1"
                        size="30"
                        style="color: var(--ma-tufts-blue)"
                        @click="getAction(item, 'update')"
                      >
                        mdi-square-edit-outline
                      </v-icon>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="mr-1"
                        size="30"
                        style="color: red"
                        @click="getAction(item, 'delete')"
                      >
                        mdi-trash-can-outline
                      </v-icon>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </template>

                <!-- action column add update delete toggle -->
                <template v-slot:item.change_update_delete="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="mr-1"
                        size="30"
                        style="color: var(--ma-tufts-blue)"
                        @click="getAction(item, 'change_password')"
                      >
                        mdi-lock
                      </v-icon>
                    </template>
                    <span>Change Password</span>
                  </v-tooltip>

                  <v-tooltip top v-if="item.toggle == true">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="mr-1"
                        size="30"
                        style="color: #4caf50"
                        @click="getAction(item, 'toggle')"
                      >
                        mdi-toggle-switch
                      </v-icon>
                    </template>
                    <span>Set as Invisible</span>
                  </v-tooltip>

                  <v-tooltip top v-else>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="mr-1"
                        size="30"
                        style="color: #4caf50"
                        @click="getAction(item, 'toggle')"
                      >
                        mdi-toggle-switch-off-outline
                      </v-icon>
                    </template>
                    <span>Set as Visible</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="mr-1"
                        size="30"
                        style="color: var(--ma-tufts-blue)"
                        @click="getAction(item, 'update')"
                      >
                        mdi-square-edit-outline
                      </v-icon>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="mr-1"
                        size="30"
                        style="color: red"
                        @click="getAction(item, 'delete')"
                      >
                        mdi-trash-can-outline
                      </v-icon>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </template>

                <!-- action column update delete toggle -->
                <template v-slot:item.update_delete="{ item }">
                  <v-tooltip top v-if="item.toggle == true">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="mr-1"
                        size="30"
                        style="color: #4caf50"
                        @click="getAction(item, 'toggle')"
                      >
                        mdi-toggle-switch
                      </v-icon>
                    </template>
                    <span>Set as Invisible</span>
                  </v-tooltip>

                  <v-tooltip top v-else>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="mr-1"
                        size="30"
                        style="color: #4caf50"
                        @click="getAction(item, 'toggle')"
                      >
                        mdi-toggle-switch-off-outline
                      </v-icon>
                    </template>
                    <span>Set as Visible</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="mr-1"
                        size="30"
                        style="color: var(--ma-tufts-blue)"
                        @click="getAction(item, 'update')"
                      >
                        mdi-square-edit-outline
                      </v-icon>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="mr-1"
                        size="30"
                        style="color: red"
                        @click="getAction(item, 'delete')"
                      >
                        mdi-trash-can-outline
                      </v-icon>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </template>

                <!-- play/pause action in main table -->
                <template v-slot:item.additem="{ item }">
                  <v-icon
                    size="30"
                    style="color: var(--ma-tufts-blue)"
                    @click="getAction(item, 'additem')"
                  >
                    mdi-plus-box-outline
                  </v-icon>
                </template>

                <!-- play/pause action in main table -->
                <template v-slot:item.play="{ item }">
                  <v-icon
                    size="30"
                    style="color: red"
                    @click="getAction(item, 'pause')"
                    v-if="isCurrentSubliminalPlaying(item)"
                  >
                    mdi-pause-box-outline
                  </v-icon>
                  <v-icon
                    size="30"
                    style="color: var(--ma-tufts-blue)"
                    @click="getAction(item, 'play')"
                    v-else
                  >
                    mdi-play-box-outline
                  </v-icon>
                </template>

                <!-- list action in main table -->
                <template v-slot:item.list="{ item }">
                  <v-icon
                    size="30"
                    style="color: var(--ma-tufts-blue)"
                    @click="getAction(item, 'list')"
                  >
                    mdi-format-list-bulleted
                  </v-icon>
                </template>

                <!-- visible action in main table -->
                <template v-slot:item.visible="{ item }">
                  <v-switch
                    label=""
                    @click="getAction(item, 'toggle')"
                    v-model="item.toggle"
                  ></v-switch>
                </template>

                <!-- edit action in main table -->
                <template v-slot:item.update="{ item }">
                  <v-icon
                    size="30"
                    style="color: var(--ma-tufts-blue)"
                    @click="getAction(item, 'update')"
                  >
                    mdi-square-edit-outline
                  </v-icon>
                </template>

                <!-- change password action in main table -->
                <template v-slot:item.change_password="{ item }">
                  <v-icon
                    size="30"
                    style="color: var(--ma-tufts-blue)"
                    @click="getAction(item, 'change_password')"
                  >
                    mdi-lock
                  </v-icon>
                </template>

                <!-- delete action in main table -->
                <template v-slot:item.delete="{ item }">
                  <v-icon
                    size="30"
                    style="color: red"
                    @click="getAction(item, 'delete')"
                  >
                    mdi-trash-can-outline
                  </v-icon>
                </template>

                <!-- select version in main table -->
                <template v-slot:item.selectversion="{ item, index }">
                  <Select
                    v-if="getTrackFromSubliminalForSelect(item).length > 0"
                    type="version"
                    text="version"
                    value="version"
                    :data="getTrackFromSubliminalForSelect(item)[0].version"
                    :custom="item.tracks"
                    @getSelectItem="getSelectItem"
                  ></Select>

                  <Select
                    v-else
                    type="version"
                    text="version"
                    value="version"
                    :custom="item.tracks"
                    :data="item.tracks[0].version"
                    @getSelectItem="getSelectItem"
                  ></Select>
                </template>

                <!-- select category in main table -->
                <template v-slot:item.selectcategory="{ item, index }">
                  <Select
                    v-if="getSubliminalFromPlaylistForSelect(item).length > 0"
                    type="category"
                    text="name"
                    :index="index"
                    value="id"
                    :data="
                      parseInt(
                        getSubliminalFromPlaylistForSelect(item)[0].category_id
                      )
                    "
                    @getSelectItem="getSelectItem"
                  ></Select>

                  <Select
                    v-else
                    type="category"
                    text="name"
                    value="id"
                    :data="item.category_id"
                    @getSelectItem="getSelectItem"
                  ></Select>
                </template>

                <!-- select audiotype action in main table -->
                <template v-slot:item.selectaudiotype="{ item, index }">
                  <Select
                    v-if="getTrackFromSubliminalForSelect(item).length > 0"
                    type="audiotype"
                    text="name"
                    value="id"
                    :data="
                      getTrackFromSubliminalForSelect(item)[0].audio_type_id
                    "
                    @getSelectItem="getSelectItem"
                  ></Select>

                  <Select
                    v-else
                    type="audiotype"
                    text="name"
                    value="id"
                    :data="item.audio_type_id"
                    :index="index"
                    @getSelectItem="getSelectItem"
                  ></Select>
                </template>

                <!-- select subscription in main table -->
                <template v-slot:item.selectsubscription="{ item, index }">
                  <Select
                    v-if="getSubliminalFromPlaylistForSelect(item).length > 0"
                    type="subscription"
                    text="name"
                    value="id"
                    :index="index"
                    :data="
                      Array.isArray(
                        getSubliminalFromPlaylistForSelect(item)[0]
                          .subscription_id
                      )
                        ? getSubliminalFromPlaylistForSelect(item)[0]
                            .subscription_id
                        : getSubliminalFromPlaylistForSelect(item)[0]
                            .subscription_id.split(',')
                            .map((element) => parseInt(element))
                    "
                    @getSelectItem="getSelectItem"
                  ></Select>

                  <Select
                    v-else
                    type="subscription"
                    text="name"
                    value="id"
                    :index="index"
                    :data="
                      Array.isArray(item.subscription_id)
                        ? item.subscription_id
                        : item.subscription_id
                            .split(',')
                            .map((element) => parseInt(element))
                    "
                    @getSelectItem="getSelectItem"
                  ></Select>
                </template>

                <!-- select mood in main table -->
                <template v-slot:item.selectmood="{ item, index }">
                  <Select
                    type="mood"
                    text="name"
                    value="id"
                    :data="mood"
                    :index="index"
                    @getSelectItem="getSelectItem"
                  ></Select>
                </template>

                <!-- slider in add track to subliminal -->
                <template v-slot:item.volumes="{ item, index }">
                  <VolumeSlider
                    type="volume"
                    :index="index"
                    :selects="select"
                    :items="item"
                    @getSelectItem="getSelectItem"
                  ></VolumeSlider>
                </template>
              </v-data-table>

              <v-divider></v-divider>

              <div class="d-flex justify-end align-center mt-5">
                <v-select
                  solo
                  dense
                  hide-details
                  v-model="limit"
                  :items="pageLimits"
                  item-text="text"
                  item-value="value"
                  style="max-width: 85px; text-align: center"
                  :disabled="total == 0"
                />
                <v-pagination
                  v-model="page"
                  :length="total"
                  :total-visible="8"
                ></v-pagination>
              </div>
            </Card>
          </template>
        </Card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { sorts } from "@/utils/data";
import { visibles } from "@/utils/data";
import { getTrackById } from "@/api/track";
import { influencers, pageLimits } from "@/utils/data";
import { convertToTime, convertToShortTime, url } from "@/utils";

import Card from "@/components/Card.vue";
import Select from "@/components/Admin/Select.vue";
import VolumeSlider from "@/components/VolumeSlider.vue";

export default {
  components: {
    Card,
    Select,
    VolumeSlider,
  },
  props: {
    changePage: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    headers: {
      type: Array,
      default: () => {
        return [];
      },
    },
    select: {
      type: Array,
      default: () => {
        return [];
      },
    },
    itemkey: {
      type: String,
      default: () => {
        return "";
      },
    },
    title: {
      type: String,
      default: () => {
        return "";
      },
    },
    loading: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    showexpand: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    expandtype: {
      type: String,
      default: () => {
        return "";
      },
    },
    add: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    delete: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    total: {
      type: Number,
    },
    addItem: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    addVisible: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      visibles,
      sorts,
      visibles,
      pageLimits,
      influencers,
      getTrackById,
      convertToTime,
      convertToShortTime,

      search: "",

      selected: [],

      track: null,

      page: 1,
      limit: 10,

      data: {},

      visible: null,
      sort: null,
      mood: null,
      category: null,
      audiotype: [],
      subscription: [],
      url,
    };
  },
  watch: {
    select(value) {
      this.page = 1
      this.selected = value;
    },
    limit() {
      this.page = 1
      this.updateLimiPage();
    },
    changePage(val) {
      this.page = 1
    },
    page() {
      this.updateLimiPage();
    },
    search() {
      this.page = 1
      this.updateSearch();
    },
    sort() {
      this.page = 1
      this.updateSort();
    },
    visible() {
      this.page = 1
      this.updateVisible();
    },
  },
  computed: {
    moods() {
      return this.$store.state.mood.data;
    },
    categories() {
      return this.$store.state.category.data;
    },
    audiotypes() {
      return this.$store.state.audio.type.data;
    },
    subscriptions() {
      return this.$store.state.subscription.data;
    },
    isCollapse() {
      return (
        this.$vuetify.breakpoint.name == "sm" ||
        this.$vuetify.breakpoint.name == "xs"
      );
    },
    showDeleteAll() {
      return this.selected.length > 0 && this.delete;
    },
    showAddItem() {
      return this.selected.length > 0 && this.addItem;
    },
    isAudioPlaying() {
      return this.$store.state.audio.status;
    },
    trackId() {
      return this.$store.state.audio.track.id;
    },
    playlistId() {
      return this.$store.state.audio.playlist.id;
    },
    subliminalId() {
      return this.$store.state.audio.subliminal.id;
    },
    version() {
      return this.$store.state.audio.version;
    },
  },
  methods: {
    isCurrentPlaylistPlaying(item, index = 0) {
      return (
        item.playlist_id == this.playlistId &&
        item.subliminals[index].subliminal_id == this.subliminalId &&
        this.isAudioPlaying
      );
    },
    isCurrentSubliminalTrackPlaying(item, index) {
      return (
        //item.subliminal_id == this.subliminalId &&
        item.tracks[index].track_id == this.trackId && this.isAudioPlaying
      );
    },
    isCurrentSubliminalPlaying(item) {
      return item.subliminal_id == this.subliminalId && this.isAudioPlaying;
    },
    isCurrentTrackPlaying(item, index) {
      return (
        item.track_id == this.trackId &&
        item.tracks[index].version == this.version &&
        this.isAudioPlaying
      );
    },
    getAction(data, action, index) {
      const params = {
        data: data,
        action: action,
        selected: this.selected,
        index: index,
      };

      this.data = data;
      this.$emit("action", params);
    },
    parseUtilsData(data, item) {
      let list = [];
      switch (item) {
        case "is_influencer":
          list = this.influencers;
          break;
      }

      const result = list.filter(
        (element) => parseInt(element.value) == parseInt(data[item])
      );
      return result.length > 0 ? result[0].text : "";
    },
    parseDataName(data, item) {
      let id = [];
      let array = [];

      switch (item) {
        case "subscription_id":
          id = this.subscriptions;
          break;
        case "audio_type_id":
          id = this.audiotypes;
          break;
        case "category_id":
          id = this.categories;
          break;
        case "moods_id":
          id = this.moods;
          break;
      }

      if (data.info != null) {
        if (data.info.hasOwnProperty(item)) {
          array = this.formatParseData(id, data.info[item]);
        }
      }

      if (data.hasOwnProperty(item)) {
        array = this.formatParseData(id, data[item]);
      }
      return array.join(",");
    },
    formatParseData(array, data) {
      let id = [];
      let arr = [];

      if (data != null) {
        id = data.toString().split(",");
      }

      array.forEach((element) => {
        if (id.includes(element.id.toString())) {
          arr.push(element.name);
        }
      });

      return arr;
    },
    updateLimiPage() {
      const data = { limit: this.limit, page: this.page };
      this.$emit("updateLimitPage", data);
    },
    updateSearch() {
      this.$emit("updateSearch", this.search);
    },
    updateSort() {
      this.$emit("updateSort", this.sort);
    },
    updateVisible() {
      this.$emit("updateVisible", this.visible);
    },
    getSelectItem(item) {
      switch (item.type) {
        case "subscription":
          const data = item.data.map((el) => {
            return parseInt(el);
          });
          this.items[item.index].subscription_id = data;
          this.selected.map((element) => {
            if (this.items[item.index].subliminal_id == element.subliminal_id) {
              element.subscription_id = data;
            }
          });
          break;
        case "statusMood":
          this.state.mood = item.data;
          break;
        case "audiotype":
          this.items[item.index].audio_type_id = item.data;

          this.selected.map((element) => {
            if (this.items[item.index].track_id == element.track_id) {
              element.audio_type_id = item.data;
            }
          });
          break;
        case "category":
          this.items[item.index].category_id = item.data;

          this.selected.map((element) => {
            if (this.items[item.index].subliminal_id == element.subliminal_id) {
              element.category_id = item.data;
            }
          });
          break;
        case "version":
          this.selected.map((element) => {
            if (this.items[item.index].track_id == element.track_id) {
              element.version = item.data;
            }
          });
          break;
        case "volume":
          this.selected.map((element) => {
            if (this.items[item.index].title == element.title) {
              element.volume = item.data;
            }
          });
          break;
      }
    },
    getSubliminalFromPlaylistForSelect(item) {
      return this.selected.filter(
        (element) =>
          element.playlist_id == this.playlistId &&
          element.subliminal_id == item.subliminal_id
      );
    },
    getTrackFromSubliminalForSelect(item) {
      return this.selected.filter(
        (element) => element.track_id == item.track_id
      );
    },
  },
};
</script>

<style scoped>
.add-btn_table {
  background: var(--ma-tufts-blue) !important;
  color: white !important;
}
.delete-all-btn {
  background: red !important;
  color: white !important;
}
.search_table {
  max-width: 250px;
}
.text-limit-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  font-family: "Montserrat-Regular";
}

.btn-icon {
  min-width: 36px !important;
  max-width: 36px !important;
  padding: 0px 10px !important;
}
</style>
