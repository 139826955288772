<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Are you looking to become a master at generating creative and innovative ideas? This subliminal is designed to unlock your creativity and help you excel at idea generation for any endeavor you pursue."
    },
    {
      type: 'heading',
      text: 'Here are some ways in which this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Enhancing your ability to think outside the box and come up with unique ideas.",
        "Increasing your mental flexibility and adaptability when faced with creative challenges.",
        "Boosting your confidence in your creative abilities and overcoming self-doubt.",
        "Developing your problem-solving skills and finding innovative solutions to obstacles.",
        "Inspiring a mindset of curiosity, exploration, and open-mindedness.",
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can tap into your inner creative genius and unleash your full creative potential. You'll find yourself consistently generating fresh and original ideas, no matter the task or project at hand. Remember, creativity is a skill that can be cultivated with practice and persistence."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Unlocking your creative potential and mastering the art of idea generation is essential for success in any endeavor. This subliminal is designed to enhance your creativity and help you generate innovative ideas. Before you begin your listening session, consider the following preparation tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a conducive environment: Find a quiet and comfortable space where you can fully focus and let your mind wander.",
        "Clear distractions: Put away any electronic devices or other distractions that might hinder your ability to concentrate and think creatively.",
        "Set your intention: Clarify your goal for the session, whether it's coming up with ideas for a specific project or simply improving your overall idea generation skills.",
        "Relax your mind and body: Take a few moments to do some deep breathing exercises or engage in a calming activity to put yourself in a relaxed state.",
        "Embrace curiosity: Let go of preconceived notions and open your mind to new possibilities. Be open to exploring different perspectives and thinking outside the box."
      ]
    },
    {
      type: 'paragraph',
      text: "By following these preparation tips, you can optimize your listening experience and maximize your potential for creative idea generation. As you listen to the subliminal messages, allow yourself to let go and embrace the flow of your thoughts. Prepare to unleash your creativity and master the art of generating innovative ideas!"
    }
  ]
},
            {
  id: 'after-listening',
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Idea Creation Mastery subliminal! By dedicating time to unlock your creativity and enhance your idea generation skills, you have taken an important step towards becoming more innovative in any endeavor you pursue. Now, it's time to reflect on your experience and continue cultivating your creative mindset."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice brainstorming: Set aside regular time for brainstorming sessions to exercise your idea generation muscles.",
        "Seek inspiration: Surround yourself with creative stimuli like books, art, nature, or engaging in new experiences.",
        "Embrace ambiguity: Allow yourself to explore unconventional and out-of-the-box ideas without judgment or limitations.",
        "Collaborate and share: Engage in discussions, group activities, or share your ideas with others to gain different perspectives."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, creativity is a skill that can be cultivated and developed over time. Stay curious, be open to new possibilities, and embrace the process of idea creation. As you continue your journey towards mastering the art of generating innovative ideas, trust in your abilities and let your imagination soar. Best wishes on your creative endeavors!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Idea Creation Mastery subliminal help improve my creativity?`, 
    answer: `The Idea Creation Mastery subliminal is designed to tap into your subconscious mind and remove any mental blocks or limiting beliefs that may be hindering your creativity. It can help you unlock your full creative potential by rewiring your thought patterns, enhancing your ability to think outside the box, and boosting your confidence in generating innovative ideas for any endeavor.`,
    open: false
  },
  { 
    question: `How long does it take to see improvements in my idea generation skills with the Idea Creation Mastery subliminal?`, 
    answer: `The time it takes to see improvements in your idea generation skills may vary from person to person. Some individuals may notice positive changes almost immediately, while others may require more time and consistent listening to the subliminal. We recommend listening to the audio regularly and incorporating practices such as journaling and brainstorming to facilitate the creative process.`,
    open: false 
  },
 { 
      question: `Can the Idea Creation Mastery subliminal be used for specific areas of creativity, such as writing or problem-solving?`, 
      answer: `Yes, the Idea Creation Mastery subliminal can be used to enhance creativity in various areas, including writing, problem-solving, art, and more. The subliminal messages are designed to improve your overall creative thinking skills, which can be applied to different domains of creativity. By cultivating a creative mindset, you may find yourself generating innovative ideas in various aspects of your life.`,
      open: false 
    },
 { 
      question: `Is it necessary to have prior creative experience to benefit from the Idea Creation Mastery subliminal?`, 
      answer: `No, prior creative experience is not necessary to benefit from the Idea Creation Mastery subliminal. The subliminal is designed to unlock and enhance your inherent creative abilities, regardless of your previous experience. It can help you tap into your unique perspective and unleash your creativity in any endeavor.`,
      open: false 
    },
 { 
      question: `Can I listen to the Idea Creation Mastery subliminal while doing other activities?`, 
      answer: `While it is possible to listen to the Idea Creation Mastery subliminal while doing other activities, we recommend dedicating specific time for focused listening to maximize its effectiveness. Creating a quiet and calm environment where you can fully absorb the subliminal messages can enhance your overall experience and allow for deeper subconscious rewiring.`,
      open: false 
    },
 { 
      question: `Is the Idea Creation Mastery subliminal effective for everyone?`, 
      answer: `The Idea Creation Mastery subliminal is designed to be effective for most individuals who are seeking to enhance their creative thinking skills. Results may vary depending on factors such as individual receptivity, consistency of listening, and personal commitment to nurturing creativity. It is important to approach the subliminal with an open mind and a willingness to explore and experiment with new ideas.`,
      open: false 
    }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>