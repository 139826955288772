<template>
  <section>
    <div class="container">
      <div>
        <h1>
          Automatic Mental Health <br />
          and Self Development <br />
          even while you're sleep
        </h1>
        <h5>Change Your Life Automatically</h5>
      </div>
      <div>
        <button class="button btn btn-primary btn-lg" @click="skipPayment()">Try for Free!</button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.state.user.info;
    },
    users() {
      return JSON.parse(this.user);
    },
    userToken() {
      return this.$store.state.user.token;
    },
  },
  methods: {
    skipPayment() {
      if(this.userToken == null){
        this.$router.push('register')

        const params = JSON.stringify({
          period: this.period,
          amount: 0,
          subscription: 'Free',
          time_created: new Date()
        })

        localStorage.setItem('subscription_temp', btoa(params))
      }else{
        this.$router.push('user')
      }
    },
  }
}
</script>
<style scoped>
  h1 {
    color: var(--ma-black-olive);
    zoom: 100%;
    line-height: 1.35em;
  }
  h5 {
    color: var(--ma-tufts-blue);
  }

  @media screen and (min-width: 80rem) {

    section:nth-of-type(1) > .container {

      @media screen and (min-width: 1400px) {
        background-image: url("~@/assets/images/main/home/1/1.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center right;
      }

      @media screen and (min-width: 1400px) {
        padding: 5em 2em 18em;
        background-position: center;
        text-align: left;
      }

      @media screen and (max-width: 1399px) {
        text-align: center;
        padding: 5em 2em 2em;
      }

    }
  }
</style>