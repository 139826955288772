<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you want to be respected and admired by others, it's important to develop self-confidence and assertiveness skills. This subliminal can help you achieve just that by targeting your subconscious mind."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can assist you:'
    },
    {
      type: 'list',
      items: [
        "Developing a strong sense of self-confidence, enabling you to present yourself assertively in various situations.",
        "Enhancing your communication skills, allowing you to convey your thoughts and ideas with clarity and conviction.",
        "Building resilience and self-esteem, so criticism or rejection doesn't affect your sense of self-worth.",
        "Improving your body language and non-verbal cues, projecting confidence and authority.",
        "Inspiring respect and admiration from others through your assertiveness and self-assuredness."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can rewire your subconscious mind to embrace self-confidence and assertiveness, which are essential qualities for commanding respect. Remember, developing these skills takes practice and effort, but with dedication and consistency, you can become the respected and admired individual you aspire to be."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Building respect and admiration from others can greatly enhance your personal and professional relationships. This subliminal is designed to help you develop self-confidence and assertiveness skills to command respect. Before you start listening, consider these strategies to maximize your results:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Clearly define the type of respect and admiration you desire from others.",
        "Visualize success: Imagine yourself as a confident and assertive individual, commanding respect in various relationships and settings.",
        "Affirmations: Create and repeat positive affirmations that align with your intention to boost your confidence and assertiveness.",
        "Dress for success: Use this opportunity to dress in a way that makes you feel confident and empowered.",
        "Hold your intention in mind: Keep focused on your desired outcome while listening to the subliminal affirmations."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal is a powerful tool to help you develop the qualities necessary to gain respect and admiration from others. As you listen regularly, you may notice an improvement in your self-confidence, assertiveness, and the way others perceive you. Embrace this opportunity to enhance your relationships and let the journey to gaining respect and admiration begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Make People Respect You subliminal session! By investing time and effort into developing self-confidence and assertiveness skills, you're taking steps towards commanding respect and admiration from others in all areas of life."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your progress: Take some time to journal about how you have grown and changed throughout this process.",
        "Set boundaries: Identify your limits and communicate them clearly to others, ensuring that your needs and values are respected.",
        "Practice self-empowerment: Engage in activities that build your self-esteem and confidence, such as setting and achieving personal goals.",
        "Improve communication skills: Hone your ability to express yourself assertively and effectively."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, earning respect is a continuous journey. As you continue to practice and strengthen your self-confidence and assertiveness, you will inspire admiration and respect from those around you. Take pride in your growth and keep striving for personal and professional success. You deserve it!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Make People Respect You subliminal help me gain respect from others?`, 
    answer: `The Make People Respect You subliminal is designed to help you develop self-confidence and assertiveness skills, which are key traits in commanding respect from others. By consistently listening to the subliminal messages, you can align your subconscious mind with positive beliefs about yourself, project confidence, and assert yourself in a respectful and assertive manner. With time, you may notice positive changes in how others perceive and respect you.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Make People Respect You subliminal?`, 
    answer: `Results can vary depending on the individual and their unique circumstances. Some people may experience positive changes in their relationships and interactions right away, while others may require more time and consistent listening to fully integrate the affirmations into their subconscious. We recommend listening to the subliminal daily and consistently for best results.`,
    open: false 
  },
  { 
    question: `Can the Make People Respect You subliminal guarantee that others will respect me?`, 
    answer: `While the Make People Respect You subliminal can help you develop self-confidence and assertiveness skills, it cannot control how others perceive or treat you. Respect is a subjective experience, and it depends on various factors, including individual perspectives and cultural norms. However, by working on your own mindset and behaviors, you can increase the likelihood of gaining respect from others.`,
    open: false 
  },
  { 
    question: `Can the Make People Respect You subliminal make me come across as arrogant or aggressive?`, 
    answer: `No, the Make People Respect You subliminal is designed to help you develop self-confidence and assertiveness in a respectful manner. It aims to empower you to assert your needs and boundaries while maintaining respect and consideration for others. It is important to use your increased confidence and assertiveness skills in a balanced and empathetic way when interacting with others.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Make People Respect You subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, it is important to use the self-confidence and assertiveness skills gained from listening to the subliminal in a responsible and respectful manner. If you have any concerns about your mental health or interactions with others, please consult with a mental health professional.`,
    open: false 
  },
  { 
    question: `Is it necessary to combine the Make People Respect You subliminal with other personal development techniques?`, 
    answer: `While the Make People Respect You subliminal can be effective on its own, combining it with other personal development techniques can enhance its effectiveness. Practical strategies such as setting and maintaining boundaries, improving communication skills, and developing emotional intelligence can work synergistically with the subliminal messages to help you gain respect from others.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>