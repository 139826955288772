<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Developing a charismatic personality can have a profound impact on your personal and professional life. This subliminal is designed to help you enhance your charisma and develop a magnetic personality that attracts and captivates others."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Increasing your confidence and self-assurance in social situations.",
        "Improving your communication skills, making it easier to connect with others.",
        "Enhancing your ability to captivatingly express yourself and engage others.",
        "Developing a positive and charismatic aura that naturally draws people in.",
        "Building better relationships, whether it's in your personal or professional life."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can rewire your subconscious mind and create lasting changes in your personality. As a result, you may notice that you become more magnetic, influential, and charming to those around you. Remember, developing charisma is a journey, and with consistent effort, you can unlock your full potential."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Becoming more charismatic and developing a magnetic personality can greatly impact your personal and professional life. This subliminal is designed to help you enhance your charisma and captivate others. Before you begin listening, consider these tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Clearly define what you want to achieve with your enhanced charisma. Focus on aspects like charm, confidence, and the ability to connect.",
        "Visualize your ideal self: Imagine yourself exuding charisma and captivating everyone around you. See yourself in different situations where your enhanced personality shines.",
        "Embrace positive self-talk: Replace self-doubt or negative thoughts with affirmations that reinforce your confidence and magnetism.",
        "Stay open and receptive: Let go of any resistance or limiting beliefs that may hinder your growth. Be open to new experiences and opportunities.",
        "Practice active listening: Cultivate the skill of attentively listening to others, showing genuine interest, and engaging in meaningful conversations."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you may notice an increase in your charisma, confidence, and the ability to connect with others. Embrace the journey towards enhancing your personality and magnetism, and let your true self shine through. Now, set your intention, press play, and enjoy the transformation."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Charismatic Personality Enhancement subliminal! By investing in enhancing your charisma and magnetic personality, you've taken a powerful step towards attracting and captivating others. Now, it's time to reflect on your journey and continue growing into the charismatic person you desire to be."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice self-awareness: Develop an understanding of your strengths and areas for improvement in building and enhancing your charisma.",
        "Observe influential individuals: Pay attention to charismatic individuals and learn from their behaviors, body language, and communication styles.",
        "Engage in active listening: Show genuine interest in others and develop better communication skills by actively listening and engaging in meaningful conversations.",
        "Embrace authenticity: Remember that charisma is not about putting on a false persona, but about being your genuine self and radiating confidence."
      ]
    },
    {
      type: 'paragraph',
      text: "Becoming more charismatic takes time and practice, but trust in your ability to develop and shine. Embrace your unique qualities, engage in meaningful connections, and let your magnetic personality draw others towards you. Remember, you have the power to captivate and influence those around you. Go out there and shine!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Charismatic Personality Enhancement subliminal help me?`, 
    answer: `The Charismatic Personality Enhancement subliminal is designed to help you develop a magnetic personality that attracts and captivates others. By listening to the subliminal messages, you can reprogram your subconscious mind to adopt charismatic traits and behaviors. This may include improved confidence, better communication skills, and an increased ability to connect with people on a deeper level.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Charismatic Personality Enhancement subliminal?`, 
    answer: `Results can vary depending on the individual and their unique circumstances. Some people may experience positive changes in their confidence and charisma right away, while others may require more time and consistent listening to fully develop these traits. We recommend listening to the subliminal daily and consistently for best results.`,
    open: false 
  },
  { 
    question: `Can the Charismatic Personality Enhancement subliminal replace personal development and social skills training?`, 
    answer: `No, the subliminal is designed to complement personal development efforts and social skills training. It can be used as a tool to enhance and reinforce the skills and qualities you are already working on. It is important to actively practice and develop your social skills through real-life interactions and experiences.`,
    open: false 
  },
  { 
    question: `Can the Charismatic Personality Enhancement subliminal make me someone I'm not?`, 
    answer: `No, the subliminal is not designed to change your core personality or make you into someone you're not. It is intended to help you develop and enhance charismatic traits and behaviors that feel authentic to you. By working with your existing qualities, the subliminal can help bring out your natural charisma and magnetism.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Charismatic Personality Enhancement subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about personal development or mental health, please consult with a qualified professional. It is important to note that the subliminal is intended to supplement personal growth efforts, not replace them.`,
    open: false 
  },
  { 
    question: `Will people notice that I'm using the Charismatic Personality Enhancement subliminal?`, 
    answer: `No, the subliminal messages are embedded within the audio, making them subconsciously perceivable but not consciously detectable. People around you will not be aware that you are listening to the subliminal unless you choose to inform them.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>