<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Ready to take control of your personal growth and success? With the Self-Life Coach subliminal, you can learn how to become your own life coach and unlock your full potential."
    },
    {
      type: 'heading',
      text: 'Here are a few ways this subliminal can support you:'
    },
    {
      type: 'list',
      items: [
        "Developing a strong sense of self-awareness and understanding your strengths and weaknesses.",
        "Setting and achieving meaningful goals that align with your values and aspirations.",
        "Building resilience and self-motivation to overcome obstacles and setbacks.",
        "Improving your problem-solving and decision-making skills.",
        "Creating a positive and empowering mindset to enhance your confidence and self-belief.",
        "Enhancing your personal relationships and communication skills.",
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to the Self-Life Coach subliminal, you can reprogram your subconscious mind to support your personal growth journey. With consistent practice and dedication, you can become your own life coach and unlock the tools and strategies needed to create the life you desire. Remember, self-coaching is a lifelong process, and this subliminal is here to assist you every step of the way."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Becoming your own life coach is a powerful way to take charge of your personal growth and ultimately, your success. This subliminal is designed to support and guide you on your journey. Before you start, consider these tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear intentions: Reflect on what specific areas of your life you want to improve and define your goals.",
        "Commit time for self-reflection: Dedicate regular moments for self-exploration, introspection, and evaluation of your progress.",
        "Practice mindfulness: Cultivate present-moment awareness to better understand your thoughts, emotions, and actions.",
        "Embrace self-compassion: Be kind to yourself throughout the coaching journey, acknowledging that growth takes time and effort."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to empower you and provide subconscious support as you embark on this transformative journey. By actively engaging in the process and incorporating these preparation tips, you can tap into your inner wisdom and become your own life coach. Trust in yourself, and let's begin this exciting chapter of personal growth and success together."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Self-Life Coach subliminal! By taking the time to invest in yourself and learn how to become your own life coach, you have embarked on a powerful journey of personal growth and success. Now, it's time to reflect on your experience and continue your path of self-discovery."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Define your short-term and long-term goals, both personally and professionally.",
        "Create action plans: Break down your goals into actionable steps and create a plan to achieve them.",
        "Practice self-reflection: Spend time regularly reflecting on your thoughts, actions, and progress.",
        "Celebrate successes: Acknowledge and celebrate your achievements, no matter how small they may seem."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, you have the power to shape your own life. By becoming your own life coach, you can take control of your personal growth and success. Embrace the journey, believe in yourself, and keep pushing forward. You are capable of achieving greatness!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What is a self-life coach?`, 
    answer: `A self-life coach is an individual who takes on the role of guiding and coaching themselves towards personal growth and success. They apply coaching techniques and tools to set goals, overcome obstacles, and make positive changes in various aspects of their lives, such as career, relationships, and personal development. By becoming their own life coach, individuals can take control of their journey and create the life they desire.`,
    open: false
  },
  { 
    question: `Why should I become my own life coach?`, 
    answer: `Becoming your own life coach empowers you to take control of your personal growth and success. It allows you to identify and pursue your goals with clarity, make decisions aligned with your values, and overcome obstacles that may arise along the way. By developing self-awareness, setting actionable plans, and holding yourself accountable, you can create a fulfilling and purposeful life on your terms.`,
    open: false 
  },
  { 
    question: `How can I become my own life coach?`, 
    answer: `To become your own life coach, start by clarifying your values, strengths, and areas for growth. Set specific and achievable goals, and create actionable plans to reach them. Practice self-reflection and mindfulness to gain clarity and insight into your thoughts, emotions, and behaviors. Develop habits of self-discipline, self-motivation, and self-compassion to stay committed to your personal growth journey. Continuously learn, adapt, and seek support when needed. Remember, the journey of becoming your own life coach is a lifelong process of learning and growth.`,
    open: false 
  },
  { 
    question: `Is it necessary to hire a professional life coach if I want to become my own life coach?`, 
    answer: `While hiring a professional life coach can be beneficial in certain situations, it is not necessary to become your own life coach. By utilizing self-coaching techniques and resources, such as books, online courses, and personal development tools, you can effectively guide yourself towards personal growth and success. However, if you feel the need for personalized guidance or support, working with a professional life coach can provide additional insights and accountability.`,
    open: false 
  },
  { 
    question: `Where can I find resources to help me become my own life coach?`, 
    answer: `There are numerous resources available to help you become your own life coach. Books, online courses, and workshops on personal development and coaching can provide valuable insights and techniques. Additionally, self-reflection exercises, journaling, and goal-setting tools can assist you in gaining clarity and taking actionable steps towards your goals. It can be beneficial to explore different resources and find those that resonate with you and your personal growth journey.`,
    open: false 
  },
  { 
    question: `What are the benefits of becoming my own life coach?`, 
    answer: `Becoming your own life coach brings several benefits, including increased self-awareness, enhanced decision-making skills, improved goal-setting abilities, and greater self-motivation and accountability. It empowers you to take control of your personal growth and success, leading to a more fulfilling and purposeful life. By becoming your own life coach, you develop the necessary skills to navigate challenges, overcome obstacles, and create positive changes in various aspects of your life.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>