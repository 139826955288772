<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Achieving maximum athletic performance requires more than just physical training. This subliminal program combines the power of your mind and focused training to help you unlock your full potential."
    },
    {
      type: 'heading',
      text: 'Key features and benefits of this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Enhanced focus and concentration, allowing you to perform at your peak during training and competitions.",
        "Increased motivation and drive to push yourself beyond your limits and set new personal records.",
        "Improved mental resilience and the ability to overcome challenges and setbacks.",
        "Heightened self-confidence and belief in your abilities, leading to improved performance under pressure.",
        "Boosted energy and stamina levels to sustain high-intensity workouts and excel in your athletic pursuits."
      ]
    },
    {
      type: 'paragraph',
      text: "By consistently listening to this subliminal program, you can train your mind to optimize your athletic performance. The subtle suggestions embedded in the audios will help rewire your subconscious mind, ultimately leading to enhanced focus, motivation, and confidence. Remember, the real power lies within you, and with the right mindset, you can achieve extraordinary athletic results." 
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Achieving maximum athletic performance requires not only physical training and skill development, but also mental focus and mindset. This subliminal is designed to enhance your athletic abilities by harnessing the power of your mind. Before you start, consider the following tips to optimize your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Define what maximum athletic performance means to you and set specific, achievable targets.",
        "Visualize success: Imagine yourself performing at your peak level, with precision, speed, and confidence.",
        "Create a training plan: Develop a structured training regimen tailored to your sport and goals.",
        "Focus on your intention: Keep your goals in mind while listening to the subliminal messages, allowing them to align with your subconscious mind."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is a tool to support your athletic journey. By combining it with dedicated training, focus, and mindset, you can unlock your full potential and elevate your performance to new heights. Embrace the power of your mind, stay committed to your goals, and let's begin the journey towards maximum athletic performance."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Maximum Athletic Performance Enhancement subliminal session! By harnessing the power of your mind and focused training, you're on your way to unlocking your full potential and reaching new heights in your athletic performance. Take a moment to reflect on your experience and continue your journey towards excellence."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Set specific goals: Define clear targets for your athletic pursuits to keep yourself motivated and focused.",
        "Train smart: Create a training plan that includes a balance of intensity, rest, and recovery.",
        "Visualize success: Use visualization techniques to mentally rehearse and visualize yourself achieving your athletic goals.",
        "Celebrate small milestones: Acknowledge and reward yourself for the progress you make along the way."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, reaching maximum athletic performance is a journey that requires dedication and perseverance. Stay committed to your training, embrace challenges, and trust in your abilities. With the power of your mind and focused training, there's no limit to what you can achieve. Keep soaring higher!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Maximum Athletic Performance Enhancement subliminal help me?`, 
    answer: `The Maximum Athletic Performance Enhancement subliminal is designed to help athletes unlock their full potential and achieve maximum performance. By listening to the subliminal regularly, the positive affirmations and messages can help enhance focus, motivation, and resilience. The subliminal works by reprogramming the subconscious mind to align with peak performance, allowing athletes to tap into their skills and abilities more effectively.`,
    open: false
  },
  { 
    question: `How long does it take to see improvements in athletic performance with the subliminal?`, 
    answer: `The time it takes to see improvements in athletic performance can vary from person to person. Some athletes may notice positive changes right away, while others may require consistent listening over time for the subliminal messages to make a noticeable impact. Consistency, along with focused training and a healthy lifestyle, can help athletes optimize their performance and achieve their goals.`,
    open: false 
  },
  { 
    question: `Can the Maximum Athletic Performance Enhancement subliminal replace physical training?`, 
    answer: `No, the subliminal is not intended to replace physical training. It is designed to complement and enhance athletic performance by working in conjunction with focused training and a healthy lifestyle. By aligning the mind and body towards peak performance, the subliminal can help athletes reach their full potential.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the Maximum Athletic Performance Enhancement subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, it is important to note that the subliminal is intended to supplement focused training and a healthy lifestyle, not replace them. Athletes should consult with their coaches or healthcare providers for personalized guidance and advice.`,
    open: false 
  },
  { 
    question: `How often should I listen to the Maximum Athletic Performance Enhancement subliminal?`, 
    answer: `For effective results, we recommend listening to the Maximum Athletic Performance Enhancement subliminal daily. Consistency is key in helping reprogram the subconscious mind and align it with peak performance. Finding a dedicated time and space for listening, such as before training sessions or during mental preparation, can further enhance its impact.`,
    open: false 
  },
  { 
    question: `Can the subliminal be used for any specific sport or athletic activity?`, 
    answer: `Yes, the Maximum Athletic Performance Enhancement subliminal can be used for any sport or athletic activity. Its goal is to help athletes unlock their full potential and achieve maximum performance across a variety of disciplines. The subliminal can adapt and align the subconscious mind with the specific goals and demands of each sport or activity.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>