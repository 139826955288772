<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Turning your passion into a successful business is no easy feat, but with the help of this subliminal, you can manifest your dream business and create a fulfilling career. By targeting your subconscious mind, it supports you in aligning your thoughts, beliefs, and actions with your business goals."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can assist you:'
    },
    {
      type: 'list',
      items: [
        "Increase your motivation and drive to pursue your business dream.",
        "Boost your confidence and belief in your abilities as an entrepreneur.",
        "Attract opportunities, resources, and connections that will contribute to your business's success.",
        "Enhance your creativity and innovative thinking, helping you stand out in the market.",
        "Overcome self-doubts, fears, and limiting beliefs that may be holding you back.",
        "Develop a resilient mindset to navigate challenges and setbacks in your business journey."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you may notice a shift in your mindset, paving the way for greater success in your business endeavors. Remember to take inspired action and seize the opportunities that come your way. With dedication and persistence, your dream business can become a reality."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Starting your dream business and turning your passion into a successful career is an exciting journey. This subliminal is designed to help you manifest your dream business and achieve your goals. Before you start listening, here are some tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your vision: Clearly define the type of business you want to create and the impact you want to make.",
        "Set your goals: Establish specific, achievable goals that will guide your actions and decisions.",
        "Visualize success: Visualize yourself running a thriving business, feeling fulfilled, and enjoying the journey.",
        "Believe in yourself: Build self-confidence and trust in your abilities and the value you have to offer.",
        "Stay committed: Make a commitment to yourself and your dream, and embrace any challenges that may arise as part of your growth."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal is a powerful tool to align your subconscious mind with your ambitions and help you attract the opportunities and resources needed for your dream business. As you listen regularly, you may notice increased motivation, inspiration, and clarity in your entrepreneurial journey. Embrace the excitement and potential of creating your dream business, and let's get started!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Manifest your Dream Business subliminal session! By focusing on manifesting your dream business, you've taken a significant step towards turning your passion into a successful and fulfilling career. Take a moment to reflect on the journey you've embarked on and the possibilities ahead."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your vision: Clearly define your business goals, values, and the impact you want to make.",
        "Create an action plan: Break down your big goals into actionable steps and create a timeline.",
        "Seek inspiration: Surround yourself with like-minded individuals, mentors, and resources that can support your entrepreneurial journey.",
        "Stay adaptable and resilient: Expect setbacks and challenges, but remain focused on your long-term vision."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, building a dream business takes time, dedication, and perseverance. Embrace the learning process and be open to making adjustments along the way. Believe in yourself and your abilities, and never forget the passion that drove you to pursue your entrepreneurial dreams. Manifest your dream business and make your mark on the world!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Manifest your Dream Business subliminal help me?`, 
    answer: `The Manifest your Dream Business subliminal is designed to align your subconscious mind with your goals and aspirations for your dream business. By listening to this subliminal regularly, you can empower yourself with a positive mindset, motivation, and clarity to take the necessary actions to manifest your dream business. It can help remove limiting beliefs, increase confidence, and attract opportunities that are in alignment with your vision.`,
    open: false
  },
  { 
    question: `How long will it take to manifest my dream business with this subliminal?`, 
    answer: `The timeline for manifesting your dream business varies for each individual and is influenced by factors such as your level of commitment, action-taking, and the specific circumstances of your business. Consistent listening to the subliminal can help reinforce your positive mindset and align your subconscious with your goals. However, it is important to remember that manifestation is a process, and results may not happen overnight. Patience, perseverance, and taking inspired action are key.`,
    open: false 
  },
  { 
    question: `Can the Manifest your Dream Business subliminal replace traditional business strategies and planning?`, 
    answer: `No, the subliminal is not intended to replace the importance of traditional business strategies and planning. It is designed to complement your business knowledge and actions by aligning your subconscious mind with your goals and increasing your motivation and confidence. Utilizing both the subliminal and practical business strategies can help maximize your chances of success.`,
    open: false 
  },
  { 
    question: `What are some additional practices I can do to enhance the effectiveness of the Manifest your Dream Business subliminal?`, 
    answer: `In addition to listening to the subliminal, you can enhance its effectiveness by visualizing your goals, journaling about your dream business, and taking consistent action towards your vision. Setting specific and achievable goals, seeking mentorship or guidance from successful entrepreneurs, and continuously learning about your industry can also support your manifestation process.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Manifest your Dream Business subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, please note that the subliminal is designed to supplement your manifesting efforts and should not replace fundamental business knowledge and strategies.`,
    open: false 
  },
  { 
    question: `Can the Manifest your Dream Business subliminal guarantee success in my business endeavors?`, 
    answer: `While the Manifest your Dream Business subliminal can help align your mindset with your goals and aspirations, it cannot guarantee success in your business endeavors. Success is influenced by various factors, including your consistent actions, market conditions, and external factors beyond your control. However, listening to the subliminal can empower you with the motivation, confidence, and focus needed to take inspired actions towards your dream business.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>