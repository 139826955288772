<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you want to enhance your numerical intelligence and improve your mathematical skills, this subliminal is designed to help you achieve that. By combining targeted brain training exercises and techniques, it helps develop a sharper mind and boost your problem-solving abilities in various numerical tasks and challenges."
    },
    {
      type: 'heading',
      text: 'Here are some benefits of using this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Improved mental agility and quick thinking in numerical tasks.",
        "Enhanced memory and retention of mathematical concepts and formulas.",
        "Increased focus and concentration when working on numerical problems.",
        "Boosted confidence in approaching and solving mathematical challenges.",
        "Expanded problem-solving skills and the ability to think analytically."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your daily routine, you can effectively train your brain to become more proficient in numerical intelligence. Over time, you may notice improvements in your mathematical abilities and problem-solving skills, empowering you to tackle complex tasks with ease. Keep in mind that consistent practice and repetition are key to achieving lasting results."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Improving your numerical intelligence and mathematical skills is an achievable goal with the help of this subliminal. Before you start your brain training, consider these tips to get the most out of your listening experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a study plan: Set aside dedicated time for practicing and improving your numerical intelligence.",
        "Stay consistent: Regular practice is key, so commit to a schedule that works for you.",
        "Break it down: Focus on specific areas of improvement, whether it's problem-solving, mental calculations, or understanding mathematical concepts.",
        "Embrace challenges: Don't shy away from difficult numerical tasks or challenges. Embrace them as opportunities to learn and grow.",
        "Believe in yourself: Develop a positive mindset and believe in your ability to improve your numerical intelligence. Replace self-doubt with confidence.",
        "Keep an open mind: Be receptive to new information, techniques, and strategies that can enhance your mathematical skills."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to support your brain training journey and help you develop a sharper mind and problem-solving abilities in numerical tasks. As you listen regularly, you may notice improvements in your confidence, mental clarity, and numerical intelligence. Trust the process, embrace challenges, and let's embark on this journey towards enhancing your mathematical skills and problem-solving abilities."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Numerical Intelligence Improvement session! You've taken a proactive step towards enhancing your mathematical skills and problem-solving abilities. Now, it's time to reflect on your progress and continue growing your numerical intelligence."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice regularly: Engage in math-related activities and exercises to reinforce your numerical skills.",
        "Challenge yourself: Solve complex mathematical problems and puzzles to push the boundaries of your numerical intelligence.",
        "Seek guidance: Consider working with a tutor or joining a study group to receive additional support and feedback.",
        "Apply math to real-world situations: Look for opportunities to use math in your daily life to strengthen your problem-solving skills."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, improving your numerical intelligence is a continuous process that requires dedication and practice. Embrace challenges and keep pushing yourself to become better every day. With consistent effort and determination, you can enhance your mathematical abilities and excel in various numerical tasks and challenges. Good luck!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Numerical Intelligence Improvement program enhance my mathematical skills?`, 
    answer: `The Numerical Intelligence Improvement program is designed to provide targeted brain training exercises and techniques that can enhance your mathematical skills. By regularly engaging with the program, you can develop a sharper mind and improve your problem-solving abilities in various numerical tasks and challenges. The program focuses on strengthening your numerical intelligence, enabling you to approach mathematical problems with greater confidence and efficiency.`,
    open: false
  },
  { 
    question: `How long does it take to see improvements in my numerical intelligence?`, 
    answer: `The time it takes to see improvements in your numerical intelligence may vary depending on factors such as your current skill level and the frequency of your practice. Consistency is key, and we recommend engaging with the program regularly for optimal results. With dedicated practice and effort, you can expect to see gradual improvements in your mathematical skills over time.`,
    open: false 
  },
  { 
    question: `What specific techniques are included in the Numerical Intelligence Improvement program?`, 
    answer: `The Numerical Intelligence Improvement program incorporates a variety of techniques to enhance your mathematical skills. These may include mental math exercises, numerical puzzles, problem-solving strategies, and visualization techniques. The program aims to provide a comprehensive approach to numerical intelligence improvement, equipping you with a range of tools and methods to tackle mathematical challenges effectively.`,
    open: false 
  },
  { 
    question: `Can the Numerical Intelligence Improvement program benefit individuals with little mathematical background?`, 
    answer: `Yes, the Numerical Intelligence Improvement program is designed to benefit individuals at different mathematical skill levels, including those with little background in mathematics. The program starts with foundational concepts and gradually progresses to more advanced topics. By following the program and engaging with the provided exercises, individuals with little mathematical background can develop their numerical intelligence and improve their mathematical abilities.`,
    open: false 
  },
  { 
    question: `Is the Numerical Intelligence Improvement program suitable for all age groups?`, 
    answer: `Yes, the Numerical Intelligence Improvement program is suitable for individuals of all age groups. The techniques and exercises provided can be tailored to meet the specific needs and skill levels of different age groups. Whether you are a student looking to improve your mathematical abilities or an adult seeking to sharpen your numerical intelligence, the program can be beneficial for your mathematical growth.`,
    open: false 
  },
  { 
    question: `Can the Numerical Intelligence Improvement program replace traditional mathematics education?`, 
    answer: `No, the Numerical Intelligence Improvement program is not intended to replace traditional mathematics education provided in schools or other educational settings. It is designed to complement and enhance your mathematical skills by providing targeted brain training exercises and techniques. The program can be used as a supplemental resource to support your overall mathematical growth and development.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>