<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Are you interested in becoming a successful insurance broker? This subliminal can help you acquire the skills and strategies necessary to excel in the insurance industry and achieve financial success."
    },
    {
      type: 'heading',
      text: 'Key features and benefits of this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Developing a strong understanding of insurance products, policies, and regulations.",
        "Enhancing your communication and negotiation skills to effectively interact with clients.",
        "Building confidence and self-assurance to approach potential clients and close deals.",
        "Acquiring marketing and networking techniques to expand your client base.",
        "Learning time management and organizational skills to efficiently handle paperwork and administrative tasks.",
        "Developing resilience and persistence to overcome challenges in the insurance industry.",
        "Gaining financial literacy to ensure profitable decision-making and long-term success."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can tap into your subconscious mind's potential and develop the mindset of a successful insurance broker. With consistent effort and application of the skills and strategies learned, you can pave the way for a rewarding career in the insurance industry. Remember, success takes dedication and perseverance, but with the right mindset, you can achieve your goals."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a career as an insurance broker can be both exciting and challenging. This subliminal is designed to help you develop the skills and mindset necessary for success in the insurance industry. Before you start listening, consider these preparation tips to enhance your journey:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Define what success means to you as an insurance broker. Are you aiming for financial success, building strong client relationships, or becoming an expert in a specific insurance field?",
        "Educate yourself: Stay updated on industry trends, products, and regulations. Continuous learning is crucial for your growth as a broker.",
        "Build a network: Connect with experienced professionals and fellow brokers. Networking can provide valuable insights and opportunities.",
        "Embrace challenges: Recognize that setbacks and obstacles are part of the journey. Approach them with a growth mindset and view them as opportunities for learning and improvement."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your daily routine, you can program your mind to cultivate the attributes necessary for success as an insurance broker. As you listen regularly, you may feel more confident, motivated, and aligned with your goals. Remember to stay committed, be open to learning, and embrace the journey towards becoming a successful insurance broker."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Become a Successful Insurance Broker subliminal session! By investing in your growth and learning the skills and strategies to excel in the insurance industry, you've taken an important step towards achieving financial success as an insurance broker."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Continual learning: Stay updated on industry trends, regulations, and new insurance products.",
        "Networking and building relationships: Attend industry events and connect with other professionals to expand your network.",
        "Develop strong communication skills: Effective communication is crucial in building trust and rapport with clients.",
        "Focus on providing value: Understand your clients' needs and tailor insurance solutions to meet their specific requirements."
      ]
    },
    {
      type: 'paragraph',
      text: "Becoming a successful insurance broker is a journey that requires dedication, perseverance, and ongoing learning. Embrace the challenges and continue to refine your skills and knowledge. Believe in your potential, and remember that your commitment to excellence will set you apart from the competition. Here's to your future as a thriving insurance broker!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What skills are necessary to become a successful insurance broker?`, 
    answer: `To become a successful insurance broker, it is important to have strong communication skills, negotiation skills, and the ability to build relationships with clients. Being knowledgeable about insurance products and regulations is also crucial. Additionally, having good analytical and problem-solving skills can help you assess the needs of your clients and provide them with the best insurance solutions.`,
    open: false
  },
  { 
    question: `How can I gain the necessary knowledge and skills to become a successful insurance broker?`, 
    answer: `You can gain the necessary knowledge and skills to become a successful insurance broker through education and training programs. Many insurance companies offer training programs that provide comprehensive information about insurance products, regulations, and sales techniques. Additionally, pursuing a degree in insurance or a related field can give you a solid foundation of knowledge and credibility in the industry.`,
    open: false 
  },
  { 
    question: `What are some strategies to attract clients as an insurance broker?`, 
    answer: `There are several strategies you can use to attract clients as an insurance broker. Building a strong network and establishing relationships with individuals and businesses in your target market can help generate leads. Offering valuable content and resources, such as informative blogs or seminars, can also help establish your expertise and attract potential clients. Utilizing digital marketing strategies, such as social media advertising and search engine optimization, can also increase your visibility and reach.`,
    open: false 
  },
  { 
    question: `Is it important to specialize in a specific type of insurance as an insurance broker?`, 
    answer: `Specializing in a specific type of insurance can be beneficial as an insurance broker. It allows you to become an expert in that particular field and better understand the unique needs of clients seeking those types of insurance. However, it is not necessary to specialize if you prefer to offer a wide range of insurance products. It ultimately depends on your personal goals and the needs of your target market.`,
    open: false 
  },
  { 
    question: `What are the key traits of a successful insurance broker?`, 
    answer: `Some key traits of a successful insurance broker include being self-motivated, proactive, and persistent. Building trust and maintaining strong relationships with clients is also important. Additionally, being adaptable to industry changes and having a strong work ethic can contribute to long-term success in the insurance industry.`,
    open: false 
  },
  { 
    question: `Is it necessary to have a large client base to be a successful insurance broker?`, 
    answer: `Having a large client base can contribute to the success of an insurance broker, but it is not the only indicator of success. Quality over quantity is important, as strong relationships and satisfied clients are key to long-term success. Focusing on providing excellent service and building trust with your existing clients can lead to referrals and growth in your client base over time.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>