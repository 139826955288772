<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Having an optimistic mindset can greatly enhance your overall well-being and outlook on life. This subliminal is designed to help you develop a positive mindset and approach life with resilience and positivity."
    },
    {
      type: 'heading',
      text: 'Key features and benefits of this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Shifting your mindset towards optimism, reframing challenges as opportunities for growth.",
        "Increasing your resilience and ability to bounce back from setbacks.",
        "Boosting your self-confidence and belief in your own abilities.",
        "Promoting a positive and optimistic attitude towards life's ups and downs.",
        "Encouraging a grateful mindset and appreciation for the present moment."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can gradually rewire your subconscious mind and develop a more positive and resilient mindset. As you embrace optimism, you may find yourself approaching challenges with a new sense of confidence and turning setbacks into stepping stones towards personal growth. Remember, consistency is key to experiencing lasting changes in your mindset."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embracing an optimistic mindset can have a profound impact on how you navigate through life's challenges and experiences. This subliminal is designed to support your journey towards developing a positive and resilient outlook. Before you start listening, consider these strategies to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your intentions: Take a moment to think about the areas in your life where you want to cultivate more optimism and resilience.",
        "Affirmation practice: Write down positive affirmations that resonate with you and read them aloud before listening to the subliminal affirmations.",
        "Visualize your desired mindset: Imagine yourself approaching challenges with a positive attitude and bouncing back from setbacks.",
        "Stay present and open: As you listen, stay present in the moment and let the subliminal messages work their magic, allowing your subconscious mind to absorb positive suggestions."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your daily routine, you can train your mind to embrace an optimistic mindset. Over time, you may find yourself naturally thinking more positively and approaching life with greater resilience. Take a moment to set your intentions, open your mind, and let the journey towards a more optimistic outlook begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Optimistic Mindset subliminal! By actively working on developing a positive and resilient mindset, you're taking important steps towards embracing a more optimistic approach to life. Now it's time to reflect on your experience and integrate the lessons learned into your day-to-day interactions."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Savor positive moments: Practice gratitude by consciously acknowledging and appreciating the positive aspects of your life.",
        "Challenge negative thoughts: Replace negative self-talk with more positive and empowering statements.",
        "Surround yourself with positivity: Seek out supportive and positive individuals who uplift and inspire you.",
        "Embrace resilience: View obstacles as opportunities for growth and learn to bounce back from setbacks."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, developing an optimistic mindset is an ongoing process. Cultivating positivity requires consistent effort and practice. Embrace the power of positive thinking and resilience as you navigate life's challenges and pursue your goals. Keep up the great work, and continue to approach life with optimism and a can-do attitude!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Optimistic Mindset subliminal help me?`, 
    answer: `The Optimistic Mindset subliminal is designed to influence your subconscious mind, helping you adopt a more positive and resilient approach to life. By listening to the subliminal regularly, you may notice shifts in your thought patterns, mood, and overall outlook. The affirmations embedded in the audio are designed to promote optimism, gratitude, and a belief in your ability to overcome challenges and manifest positive outcomes.`,
    open: false
  },
  { 
    question: `How long do I need to listen to the Optimistic Mindset subliminal to see results?`, 
    answer: `Results may vary depending on the individual and their existing mindset. Some people may experience positive changes quickly, while others may require more time and consistent listening to reprogram their thought patterns. We recommend listening to the subliminal daily for at least a few weeks to allow the affirmations to deeply influence your subconscious mind.`,
    open: false 
  },
  { 
    question: `Can the Optimistic Mindset subliminal help me overcome negative thinking and self-doubt?`, 
    answer: `Yes, the Optimistic Mindset subliminal is designed to counter negative thinking and self-doubt by promoting positive and empowering beliefs. The subliminal messages aim to rewire your subconscious mind, helping you replace negative thought patterns with more optimistic and self-affirming ones. Consistent listening can support you in developing a more resilient and positive mindset over time.`,
    open: false 
  },
  { 
    question: `How can I make the Optimistic Mindset subliminal more effective?`, 
    answer: `To enhance the effectiveness of the Optimistic Mindset subliminal, it can be helpful to create a supportive environment and engage in activities that reinforce positive thinking. Surrounding yourself with positive people, practicing gratitude, journaling, and engaging in self-care activities can all contribute to cultivating a more optimistic mindset. Additionally, consistent and daily listening to the subliminal can reinforce the positive affirmations and help solidify the desired mindset.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Optimistic Mindset subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Optimistic Mindset subliminal. However, if you have any concerns about your mental health, it is always best to consult with a qualified healthcare professional. The subliminal is designed to complement your journey towards a more positive mindset and does not replace professional help if needed.`,
    open: false 
  },
  { 
    question: `Can the Optimistic Mindset subliminal guarantee that I will always be positive?`, 
    answer: `While the Optimistic Mindset subliminal is designed to promote a positive mindset, it is important to remember that everyone has ups and downs in life. The subliminal can help you develop a more resilient and optimistic approach, but it does not guarantee that you will never experience negative emotions or challenges. It serves as a tool to support you on your journey towards positivity and resilience.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>