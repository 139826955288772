<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Are you tired of hitting the snooze button and feeling rushed every morning? This subliminal can help you become an early riser and transform your mornings into a peaceful and productive time."
    },
    {
      type: 'heading',
      text: 'Here are some benefits of waking up early:'
    },
    {
      type: 'list',
      items: [
        "Having extra time in the morning to focus on self-care activities like exercise, meditation, and journaling.",
        "Starting your day with a sense of calm and clarity, setting a positive tone for the rest of the day.",
        "Feeling more energized and alert throughout the day, without relying on caffeine.",
        "Being able to complete important tasks or work on personal projects before the distractions of the day begin.",
        "Having the opportunity to enjoy a leisurely breakfast and plan your day ahead."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal consistently, you can rewire your brain to naturally wake up earlier and embrace the benefits of being an early riser. Remember, establishing a new habit takes time and effort, but with dedication and persistence, you can become an early riser and transform your mornings into a time of productivity and success."
    }
  ]
},
            {
  id: 'before-listening',
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Becoming an early riser can have a significant impact on your energy, productivity, and overall well-being. This subliminal is designed to help you develop the habit of waking up early and starting your day with enthusiasm. Before you start listening, consider the following suggestions to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set a consistent wake-up time: Establish a regular wake-up time that aligns with your goals and allows for an adequate amount of rest.",
        "Create a nighttime routine: Establish a calming evening routine that promotes relaxation and prepares you for a restful sleep.",
        "Plan your morning: Determine meaningful and rewarding activities to engage in during your early morning hours to increase your motivation for waking up early.",
        "Visualize your ideal morning routine: Imagine yourself waking up refreshed and accomplishing tasks that bring you joy and fulfillment.",
        "Maintain a positive mindset: Adopt a positive attitude towards waking up early, reminding yourself of the benefits and the positive impact it will have on your day."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly and implementing these strategies, you can develop the habit of waking up early and experience increased energy, productivity, and overall well-being. Embrace the opportunity to transform your mornings and create a positive start to each day. Press play with enthusiasm and let the journey to becoming an early riser begin!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Become an Early Riser subliminal! By focusing on waking up early and starting your day with energy and productivity, you've taken a significant step towards transforming your daily routine and achieving your goals. Take a moment to reflect on your experience and the positive impact it can have on your life."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your morning routine: Evaluate how waking up early has affected your productivity and overall well-being.",
        "Establish a consistent sleep schedule: Set a regular bedtime and aim for seven to nine hours of quality sleep each night.",
        "Create a morning routine: Incorporate activities like exercise, meditation, or goal setting to start your day on a positive note.",
        "Minimize distractions: Identify and eliminate potential distractions that may hinder your ability to wake up early and be productive."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, becoming an early riser is a gradual process that requires patience and commitment. Embrace the changes and adjustments needed to support this new habit. As you continue to prioritize waking up early, you'll unlock more time and productivity, setting yourself up for success. Rise and shine!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Become an Early Riser subliminal help me wake up early?`, 
    answer: `The Become an Early Riser subliminal is designed to reprogram your subconscious mind and cultivate the habit of waking up early. By consistently listening to the subliminal messages, you can train your mind to embrace the benefits of early rising, such as increased productivity, improved focus, and a sense of calm in the morning. The subliminal works by aligning your thoughts and beliefs with the desired behavior, making it easier for you to wake up early and start your day with energy.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Become an Early Riser subliminal?`, 
    answer: `Results can vary from person to person, but with consistent listening, you may start noticing positive changes in your wake-up routine within a few weeks. It is important to be patient and give the subliminal messages time to work on your subconscious mind and solidify the habit of waking up early. Consistency and a positive mindset are key to achieving long-term success in becoming an early riser.`,
    open: false 
  },
  { 
    question: `Can I use the Become an Early Riser subliminal if I'm a night owl?`, 
    answer: `Yes, the Become an Early Riser subliminal can be beneficial for individuals who identify as night owls. The subliminal aims to shift your mindset and help you develop a new sleep schedule that aligns with waking up early. By consistently listening to the subliminal messages, you can gradually adjust your sleep-wake pattern and develop new habits that support waking up early.`,
    open: false 
  },
  { 
    question: `Are there any risks in using the Become an Early Riser subliminal?`, 
    answer: `No, there are no known risks associated with using the Become an Early Riser subliminal. However, if you have any concerns about your sleep or health, it is recommended to consult with a healthcare professional. The subliminal is designed to support healthy habits and empower you to wake up early, but it should not replace any medical advice or treatment you may need.`,
    open: false 
  },
  { 
    question: `Can listening to the Become an Early Riser subliminal affect my sleep quality?`, 
    answer: `Listening to the Become an Early Riser subliminal should not negatively impact your sleep quality. The subliminal messages are designed to empower and motivate you to wake up early, but they should not disrupt your ability to fall asleep or get adequate rest. It is important to prioritize a healthy sleep routine and ensure you are getting enough sleep while adjusting to your new waking schedule.`,
    open: false 
  },
  { 
    question: `Can I use the Become an Early Riser subliminal if I have trouble falling asleep?`, 
    answer: `If you have trouble falling asleep, it is recommended to address any underlying sleep issues with the help of a healthcare professional. The Become an Early Riser subliminal is designed to support waking up early, but it may not directly address difficulties with falling asleep. It is important to prioritize good sleep hygiene and seek professional advice for any sleep-related concerns.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>