import request from "@/utils/request";

export const getMoods = (data) => {
  return request.get("/api/mood").then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const getMoodFilters = (data) => {
  return request.post("/api/mood/filter", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const addMoods = (data) => {
  return request.post("/api/mood", data).then((res) => {
    return res.data;
  });
};

export const updateMoods = (data) => {
  return request.put(`/api/mood/${data.id}`, data).then((res) => {
    return res.data;
  });
};

export const updateToggle = (data) => {
  return request.post("/api/mood/toggle", data).then((res) => {
    return res.data;
  });
};

export const deleteMoods = (data) => {
  return request.delete(`/api/mood/${data.id}`).then((res) => {
    return res.data;
  });
};

export const updateUserMood = (data) => {
  return request.post("/api/user/mood/update/admin", data).then((res) => {
    return res.data;
  });
};

export const getCurrentMood = (data) => {
  return request.post("/api/user/mood/admin", data).then((res) => {
    return res.data;
  });
};

export const getUserCalendarMoods = (data) => {
  return request.post("/api/user/mood/calendar", data).then((res) => {
    return res.data;
  });
};

export const getUserMoodAudioCalendar = (data) => {
  return request.post("/api/user/mood/audio/calendar", data).then((res) => {
    return res.data;
  });
};
