<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Finding acceptance and confidence in your bald appearance can be a transformative journey, and this subliminal is here to support you along the way. By reprogramming your subconscious mind, it helps you embrace and appreciate your baldness."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Developing a positive mindset and attitude towards your baldness.",
        "Boosting your self-confidence and self-esteem, regardless of your hair loss.",
        "Letting go of societal beauty standards and embracing your unique appearance.",
        "Finding inner peace and acceptance with your baldness.",
        "Gaining the courage to confidently rock your bald look in any situation."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you may begin to notice a shift in how you perceive your baldness. It can help you develop a healthier mindset, leading to increased confidence and acceptance of your unique appearance. Remember, everyone is different, and embracing your baldness is a personal journey - this subliminal can be a helpful tool along the way."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Accepting and embracing your baldness is an important step towards building confidence and self-acceptance. This subliminal is designed to support and empower you on this journey. Before you start listening, consider the following tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your feelings: Reflect on your thoughts and emotions related to your baldness. Acceptance begins with self-awareness.",
        "Challenge societal beauty standards: Remind yourself that beauty comes in all forms, and that your bald appearance is unique and attractive.",
        "Practice self-love: Affirm your worth and value, focusing on qualities that extend beyond physical appearance.",
        "Visualize confidence: Imagine yourself confident and comfortable in your bald appearance, radiating self-assurance."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to help you shift your mindset towards acceptance and confidence. As you listen regularly, you may find yourself embracing your baldness with a newfound appreciation and love for your unique appearance. Take a moment to center yourself, embrace your journey, and let's start building your self-confidence and acceptance!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Baldness Acceptance and Confidence subliminal! By choosing to embrace your baldness with acceptance and confidence, you've taken a significant step towards self-love and appreciation. Now, take a moment to reflect on your experience and continue your journey towards a positive mindset and self-assurance."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Challenge negative thoughts: Whenever negative thoughts arise about your bald appearance, practice reframing them into positive affirmations.",
        "Focus on self-care: Dedicate time to self-care activities that make you feel good about yourself, such as grooming, skincare, or pampering routines.",
        "Surround yourself with support: Seek support from friends, family, or online communities that promote self-acceptance and body positivity.",
        "Celebrate your uniqueness: Embrace your bald appearance as part of what makes you unique and special."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, accepting and loving yourself just as you are is a journey. Celebrate your baldness as a part of your identity and let it fuel your confidence. You are beautiful and deserving of love and acceptance, baldness and all!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Baldness Acceptance and Confidence subliminal help me?`, 
    answer: `The Baldness Acceptance and Confidence subliminal is designed to help you cultivate acceptance and confidence in your bald appearance. By listening to the subliminal regularly, it can positively influence your subconscious mind, promoting self-acceptance, self-love, and a positive mindset towards your baldness. This can lead to increased confidence and a greater sense of self-esteem.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Baldness Acceptance and Confidence subliminal?`, 
    answer: `Results can vary from person to person. Some individuals may experience positive changes in their mindset and confidence right away, while others may require more time and consistent listening to fully embrace their baldness. We recommend listening to the subliminal daily and consistently for best results.`,
    open: false 
  },
  { 
    question: `Can the Baldness Acceptance and Confidence subliminal regrow hair or stop hair loss?`, 
    answer: `No, the subliminal is not intended to regrow hair or stop hair loss. It is designed to help individuals embrace and accept their baldness with confidence. If you have concerns about hair loss, we recommend consulting with a healthcare professional for appropriate advice and treatment options.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Baldness Acceptance and Confidence subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your baldness or self-esteem, it is advisable to seek support and guidance from a qualified professional.`,
    open: false 
  },
  { 
    question: `Will the Baldness Acceptance and Confidence subliminal work for me if I am not bald?`, 
    answer: `The Baldness Acceptance and Confidence subliminal is specifically designed to help individuals embrace and accept their bald appearance. If you are not bald, the subliminal may not be as relevant or effective for you. However, you may still find value in exploring self-acceptance and confidence subliminals that align with your personal goals and circumstances.`,
    open: false 
  },
  { 
    question: `Can the Baldness Acceptance and Confidence subliminal replace therapy or professional help?`, 
    answer: `No, the Baldness Acceptance and Confidence subliminal is not a substitute for therapy or professional help. While it can complement personal growth and self-acceptance, it is important to seek appropriate support if you are experiencing significant distress or emotional challenges related to your baldness or self-esteem.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>