import { render, staticRenderFns } from "./319-stop-anxiety-based-panic-attacks.vue?vue&type=template&id=49e6d32e&scoped=true&"
import script from "./319-stop-anxiety-based-panic-attacks.vue?vue&type=script&lang=js&"
export * from "./319-stop-anxiety-based-panic-attacks.vue?vue&type=script&lang=js&"
import style0 from "./319-stop-anxiety-based-panic-attacks.vue?vue&type=style&index=0&id=49e6d32e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49e6d32e",
  null
  
)

export default component.exports