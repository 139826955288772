<template>
  <div>
    <Guidelines />
  </div>
</template>

<script>
import Guidelines from '@/views/Main/Guidelines'
export default {
  components: {
    Guidelines
  }
}
</script>