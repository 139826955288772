<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "In life, we are inevitably faced with challenges and unfavorable circumstances that can test our mental well-being. This subliminal is designed to help you develop a weatherproof mindset, allowing you to remain strong and resilient regardless of external conditions."
    },
    {
      type: 'heading',
      text: 'Key benefits of cultivating a weatherproof mindset include:'
    },
    {
      type: 'list',
      items: [
        "Increased resilience to bounce back from setbacks and persevere through difficult times.",
        "Greater adaptability to handle unexpected changes and navigate uncertain situations.",
        "Improved emotional well-being and reduced vulnerability to stress and anxiety.",
        "Enhanced ability to maintain focus and positivity, even in challenging or negative environments.",
        "Heightened self-confidence and belief in your own abilities to overcome obstacles."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to embrace a weatherproof mindset. Over time, you may find yourself naturally responding to challenges with strength, resilience, and a positive mindset. Remember, building a weatherproof mindset is a journey, so be patient with yourself and celebrate your progress along the way."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Maintaining a strong and resilient mindset in the face of challenges can be a powerful tool for protecting your mental well-being. This subliminal is designed to help you develop a weatherproof mindset that remains strong and resilient regardless of external circumstances. Before you begin, consider these preparatory tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your goals: Identify the specific areas of your life where you want to develop a weatherproof mindset.",
        "Reflect on past resilience: Recall times when you have overcome challenges and draw inspiration from those experiences.",
        "Engage in positive affirmations: Affirm and reinforce your mental strength by repeating empowering statements before listening.",
        "Stay present: Remain mindful and focused on developing a weatherproof mindset throughout your listening experience."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can begin to cultivate a mindset that remains strong and resilient in the face of any adversity or unfavorable circumstances. Embrace this opportunity to strengthen your mental well-being and navigate life's challenges with confidence and perseverance."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Weatherproof Mindset subliminal! By cultivating a strong and resilient mindset, you're taking proactive steps towards protecting your mental well-being in any situation. It's important to reflect on your experience and continue to strengthen your mindset as you move forward."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your experience: Take time to journal and reflect on the thoughts and emotions that arise after listening.",
        "Practice gratitude: Cultivate a mindset of gratitude by regularly acknowledging the positive aspects of your life.",
        "Challenge negative thoughts: Whenever negative thoughts arise, consciously replace them with positive or realistic ones.",
        "Build a support system: Surround yourself with positive and supportive individuals who uplift and inspire you."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, developing a weatherproof mindset is an ongoing process. Practice self-compassion as you navigate challenges and unfavorable circumstances. With dedication, persistence, and a strong mindset, you're capable of overcoming anything that comes your way. Keep striving for mental well-being and personal growth!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Weatherproof Mindset subliminal help me?`, 
    answer: `The Weatherproof Mindset subliminal is designed to help you develop a mindset that remains strong and resilient in the face of challenges or unfavorable circumstances. By listening to the subliminal regularly, you can reprogram your subconscious mind to adopt a positive and adaptive attitude towards adversity. This can help protect your mental well-being and enable you to navigate through difficult situations with greater ease and resilience.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Weatherproof Mindset subliminal?`, 
    answer: `Results can vary depending on the individual and their unique circumstances. Some people may experience positive changes in their mindset and resilience right away, while others may require more time and consistent listening to fully integrate the subliminal messages. We recommend listening to the subliminal daily and consistently for best results.`,
    open: false 
  },
  { 
    question: `Can the Weatherproof Mindset subliminal eliminate all stress and challenges from my life?`, 
    answer: `No, the subliminal is not intended to eliminate all stress and challenges from your life. Its purpose is to help you develop a mindset that remains strong and resilient in the face of adversity. While it can empower you with the mental tools and perspective to navigate challenges more effectively, it is important to accept that some level of stress and challenges are a normal part of life.`,
    open: false 
  },
  { 
    question: `How can I make the Weatherproof Mindset subliminal more effective?`, 
    answer: `You can enhance the effectiveness of the subliminal by practicing mindfulness, self-reflection, and adopting positive coping strategies. Building a strong support network and engaging in activities that promote mental and emotional well-being can also complement the subliminal messages. Consistency in listening to the subliminal and actively applying its teachings in your daily life will contribute to its effectiveness.`,
    open: false 
  },
  { 
    question: `Can the Weatherproof Mindset subliminal replace therapy or professional help?`, 
    answer: `No, the Weatherproof Mindset subliminal is not a substitute for therapy or professional help. While it can be a helpful tool in developing a resilient mindset, it is important to seek appropriate professional support if you are facing severe mental health issues or struggling with persistent challenges. The subliminal is best used as a complement to professional help.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Weatherproof Mindset subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your mental well-being, it is important to seek professional advice or support. The subliminal is intended to supplement self-development practices and not replace professional help when needed.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>