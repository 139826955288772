<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Embracing your age and boosting your self-confidence can have a profound impact on your overall well-being. This subliminal is specifically designed to help you accept and celebrate who you are at any age."
    },
    {
      type: 'heading',
      text: 'Here are some benefits you can expect:'
    },
    {
      type: 'list',
      items: [
        "Feeling more confident and comfortable in your own skin.",
        "Letting go of societal pressures and expectations surrounding aging.",
        "Cultivating a positive mindset and outlook on aging.",
        "Appreciating the wisdom and experiences that come with age.",
        "Embracing your unique beauty and individuality."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can rewire your subconscious mind to embrace your age and boost your self-confidence. As a result, you may find yourself feeling more empowered, accepting, and optimistic about the journey of aging. Remember, self-acceptance and confidence are ongoing processes, so be patient and kind to yourself as you embark on this transformative journey."
    }
  ]
},
            {
  id: 'before-listening',
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Accepting and embracing your age while building self-confidence can be a transformative journey, and this subliminal is designed to support you along the way. Before you start listening, consider these tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on self-acceptance: Take a moment to appreciate and celebrate who you are at your current age. Recognize the unique qualities and experiences that have shaped you.",
        "Practice self-compassion: Treat yourself with kindness and understanding, especially when it comes to accepting aspects of yourself that you may perceive as flaws or imperfections.",
        "Challenge unrealistic standards: Reject societal pressures to look or act a certain way at a particular age. Embrace the beauty of aging and the wisdom that comes with it.",
        "Prioritize self-care: Make time for activities that align with your values and bring you joy, whether it's engaging in hobbies, practicing mindfulness, or connecting with loved ones."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal, allow the affirmations to support your journey towards age acceptance and self-confidence. With regular practice and a commitment to self-love, you can cultivate a positive mindset and embrace your age with grace and confidence. Remember, you are unique, valuable, and deserving of self-acceptance at any age. Let's begin this empowering journey together."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Age Acceptance and Confidence subliminal! By embracing your age and boosting your self-confidence, you're taking a powerful step towards self-love and acceptance. It's important to reflect on your experience and continue journeying towards greater self-acceptance."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice self-compassion: Be kind to yourself and celebrate your unique qualities.",
        "Challenge negative thoughts: Replace self-doubt with positive affirmations and empowering beliefs.",
        "Set realistic goals: Focus on personal growth and things you can control, rather than comparing yourself to others.",
        "Surround yourself with positivity: Connect with supportive friends and engage in activities that uplift and inspire you."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, accepting and celebrating your age is a journey that involves embracing who you are and nurturing a positive self-image. Be patient with yourself and remember that you are worthy of love and acceptance at any age. Embrace your uniqueness and let your confidence shine!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Age Acceptance and Confidence subliminal help me?`, 
    answer: `The Age Acceptance and Confidence subliminal is designed to help you embrace your age and boost your self-confidence. By listening to the subliminal messages, you can reprogram your subconscious mind to accept and celebrate who you are at any age. It can help you let go of societal pressures and expectations, and cultivate a deep sense of self-acceptance and self-worth.`,
    open: false
  },
  { 
    question: `How long does it take to see improvements in age acceptance and self-confidence?`, 
    answer: `The time it takes to see improvements in age acceptance and self-confidence varies from person to person. Some individuals may notice positive changes soon after starting to listen to the subliminal, while others may require more time to fully internalize the affirmations. Consistent and regular listening is recommended for best results.`,
    open: false 
  },
  { 
    question: `Can the Age Acceptance and Confidence subliminal help with body image issues related to aging?`, 
    answer: `Yes, the Age Acceptance and Confidence subliminal can help with body image issues related to aging. The subliminal messages are designed to promote self-acceptance and self-love, regardless of age or appearance. By reshaping your subconscious beliefs about aging, it can help you develop a more positive and empowering relationship with your body.`,
    open: false 
  },
  { 
    question: `Is the Age Acceptance and Confidence subliminal suitable for all ages?`, 
    answer: `Yes, the Age Acceptance and Confidence subliminal is suitable for individuals of all ages. It is never too early or too late to cultivate self-acceptance and boost your confidence. The affirmations are designed to connect with your subconscious mind and help you embrace your age at any stage of life.`,
    open: false 
  },
  { 
    question: `Can the Age Acceptance and Confidence subliminal replace therapy or counseling?`, 
    answer: `No, the Age Acceptance and Confidence subliminal is not a substitute for therapy or counseling. It is a tool that can complement your personal growth journey, but it is not intended to replace professional help. If you are experiencing significant emotional distress or mental health concerns, it is important to seek the support of a qualified therapist or counselor.`,
    open: false 
  },
  { 
    question: `How often should I listen to the Age Acceptance and Confidence subliminal?`, 
    answer: `To achieve the best results, it is recommended to listen to the Age Acceptance and Confidence subliminal consistently. We suggest incorporating it into your daily routine, such as listening to it in the morning or before bed. The more you expose yourself to the positive affirmations, the more they can influence your subconscious mind and help in building age acceptance and self-confidence.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>