<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Whether you're a casual player or a serious competitor, this subliminal is designed to help you enhance your skills and become a master at various board games. Through guided training and practice sessions, it works to sharpen your strategic thinking, decision-making, and overall gameplay."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help you:"
    },
    {
      type: 'list',
      items: [
        "Improving your ability to strategize and think several moves ahead.",
        "Enhancing your focus, concentration, and attention to detail during gameplay.",
        "Boosting your memory and recall abilities, allowing you to remember important game patterns and strategies.",
        "Increasing your confidence in making game-changing moves and taking calculated risks.",
        "Developing your adaptability and flexibility in responding to unexpected moves or changes in the game.",
        "Refining your analysis and problem-solving skills to overcome challenges and opponents.",
        "Heightening your enjoyment and satisfaction from playing board games as you see your progress and mastery."
      ]
    },
    {
      type: 'paragraph',
      text: "To make the most of this subliminal, listen regularly while engaging in board game training and practice. As you do so, you'll notice a gradual improvement in your gameplay skills and overall mastery. Remember, practice and persistence are key to becoming a board game master, and this subliminal can be a valuable tool to support your journey."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Are you ready to level up your board game skills and become a master player? This subliminal is designed to support your training and mastery of various board games. Before you start playing, here are some tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Choose your focus: Decide which board game or games you want to master and set your intention to improve your skills in those specific games.",
        "Learn the rules: Familiarize yourself with the official rules of the games you will be playing. Understanding the rules is essential for strategic decision-making.",
        "Practice regularly: Dedicate consistent time to practice and play the games. The more you play, the more you'll develop your strategies and improve your overall gameplay.",
        "Visualize success: Use visualization techniques to imagine yourself making strategic moves, winning games, and becoming a master player.",
        "Stay focused: While listening to the subliminal affirmations, keep your attention on your goals, envisioning yourself as a skilled and successful board game player."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is a tool to enhance your board game skills by tapping into your subconscious mind. By embracing focused practice and regularly listening to the subliminal, you can accelerate your progress and step up your gameplay. So get ready to level up, strategize, and embrace the excitement of mastering board games!"
    }
  ]
},
            {
  id: 'after-listening',
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Board Games Training and Mastery session! By dedicating time and effort to improve your skills in various board games, you're on your way to becoming a true master. Reflecting on your progress and strategies can help you further enhance your abilities."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Review your games: Take some time to analyze your past games and identify areas for improvement.",
        "Practice regularly: The more you practice, the more refined your skills will become.",
        "Learn from experts: Watch videos, read books, or join online communities to gain insights from experienced players.",
        "Experiment with strategies: Don't be afraid to try new tactics and approaches in your games."
      ]
    },
    {
      type: 'paragraph',
      text: "Becoming a master at board games takes dedication and a passion for learning. Embrace every opportunity to play, practice, and discover new techniques. Remember, every game is a chance to grow and refine your skills. Get ready to impress your opponents and dominate the board!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can I benefit from Board Games Training and Mastery?`, 
    answer: `Board Games Training and Mastery is designed to enhance your skills and knowledge of various board games. By participating in guided training and practice sessions, you can learn advanced strategies, improve your decision-making abilities, and gain a deeper understanding of game mechanics. This program can help you become a more confident and skilled player, making your board game experiences more enjoyable and competitive.`,
    open: false
  },
  { 
    question: `What types of board games are covered in the training?`, 
    answer: `The Board Games Training and Mastery program covers a wide range of board games, including classic games like chess, checkers, and backgammon, as well as modern strategy games like Settlers of Catan, Ticket to Ride, and Carcassonne. The program aims to provide comprehensive training that can be applied to various board games, helping you become a well-rounded and adaptable player.`,
    open: false 
  },
  { 
    question: `Is this program suitable for beginners or experienced players?`, 
    answer: `The Board Games Training and Mastery program is suitable for players of all levels, from beginners to experienced players. The training sessions are tailored to meet the needs and skill levels of each individual, providing a supportive environment for learning and improvement. Whether you are just starting or looking to take your skills to the next level, this program can help you achieve your goals.`,
    open: false 
  },
  { 
    question: `How long does it take to see improvement in my board game skills?`, 
    answer: `The time it takes to see improvement in your board game skills can vary depending on various factors, including your current level of experience and the frequency of your training. Consistent practice and active engagement in the training sessions can expedite your progress. However, it's important to remember that board game mastery is a continuous journey, and the more you invest in training, the greater your improvement will be over time.`,
    open: false 
  },
  { 
    question: `Can I participate in the training sessions remotely?`, 
    answer: `Yes, the Board Games Training and Mastery program offers remote training sessions that can be accessed from the comfort of your own home. Through online platforms and video conferences, you can engage with experienced trainers and fellow players, receiving guidance and feedback to improve your skills. Remote participation opens up opportunities for individuals from different locations to benefit from the program.`,
    open: false 
  },
  { 
    question: `What if I have specific board games I want to focus on during the training?`, 
    answer: `If you have specific board games you want to focus on during the training, you can discuss your preferences with the trainers. They can tailor the training sessions to address specific games and strategies based on your needs and interests. The program aims to be flexible and accommodating, ensuring that you receive the training and guidance that aligns with your goals.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>