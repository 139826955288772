import request from "@/utils/request";

export const addTrackInfos = (data) => {
  return request.post("/api/tracks/info", data).then((res) => {
    return res.data;
  });
};

export const deleteTrackInfos = (data) => {
  return request.delete("/api/tracks/info", data).then((res) => {
    return res.data;
  });
};

export const deleteVersionToTracks = (data) => {
  return request.post("/api/track/info/delete", data).then((res) => {
    return res.data;
  });
};
