<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're feeling weighed down by toxic relationships or negative influences in your life, know that you have the power to create a more positive and uplifting environment. This subliminal is designed to help you disconnect from people who drag you down and cultivate healthier relationships."
    },
    {
      type: 'heading',
      text: 'Benefits of using this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Identifying toxic relationships and negative influences in your life.",
        "Gaining the courage and confidence to set boundaries and cut off toxic people.",
        "Building resilience and self-worth to protect yourself from negative influences.",
        "Creating space for positive and supportive relationships to thrive.",
        "Enhancing your overall well-being and happiness by surrounding yourself with uplifting individuals."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal and absorbing the positive affirmations, you can reprogram your subconscious mind to prioritize healthy relationships and eliminate toxic ones. Remember, it's important to take care of yourself and surround yourself with people who uplift and support you."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Cutting off toxic relationships and removing negative influences from your life is a powerful step towards creating a positive and uplifting environment. As you prepare to listen to this subliminal, consider the following tips to empower yourself:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Evaluate your relationships: Reflect on the people in your life and identify those who consistently bring you down or drain your energy.",
        "Setting boundaries: Establish clear boundaries that protect your well-being and prioritize your own needs.",
        "Identify your values: Understand your own values and surround yourself with people who align with and support those values.",
        "Self-reflection: Take time to reflect on the patterns and dynamics in your relationships, recognizing the effects they have on your emotional and mental health."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal is designed to help you let go of toxic relationships and create a more positive and uplifting environment. As you listen regularly, you may find yourself feeling empowered, confident, and surrounded by people who uplift and support you. Embrace this moment as you take control of your own happiness and well-being. Let's begin the journey towards a healthier and more fulfilling social life."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Disconnect from People Who Drag You Down subliminal! You've taken a significant step towards prioritizing your well-being and creating a more positive and uplifting environment. Now, it's time to reflect on your experience and begin making positive changes in your relationships."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Evaluate your relationships: Take some time to assess the people in your life and how they make you feel.",
        "Set boundaries: Determine healthy boundaries to protect your mental and emotional well-being.",
        "Communicate your needs: Openly express your feelings and expectations in relationships.",
        "Surround yourself with positivity: Seek out supportive, uplifting individuals who encourage and inspire you."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, letting go of toxic relationships and negative influences can be challenging, but it is essential for your personal growth and happiness. Surround yourself with positive energy and people who uplift and support you. You deserve to be surrounded by individuals who bring out the best in you. You've got this!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How do I know if someone is toxic and dragging me down?`, 
    answer: `Toxic individuals can exhibit various behaviors, such as constantly criticizing or belittling you, manipulating or gaslighting you, being excessively negative or draining your energy, and not respecting your boundaries. If you consistently feel drained, upset, or unappreciated after interacting with someone, it may be a sign that they are toxic and dragging you down.`,
    open: false
  },
  { 
    question: `How can the Disconnect from People Who Drag You Down subliminal help me in cutting off toxic relationships?`, 
    answer: `The Disconnect from People Who Drag You Down subliminal helps you to create a more positive and uplifted environment by working on your subconscious mind. It can help you develop the mental strength and clarity to recognize toxic relationships and make the necessary changes. By listening to the subliminal messages regularly, you may find yourself becoming more aware of negative influences, feeling empowered to set boundaries, and attracting healthier connections.`,
    open: false 
  },
  { 
    question: `How long does it take to see results and feel the effects of the Disconnect from People Who Drag You Down subliminal?`, 
    answer: `The time it takes to see results and feel the effects of the subliminal can vary from person to person. Some may experience changes in their mindset and behavior towards toxic relationships relatively quickly, while others may require more time and consistent listening to fully detach from negative influences. For best results, it is recommended to listen to the subliminal consistently and give yourself time to process and implement the positive changes.`,
    open: false 
  },
  { 
    question: `Is it necessary to completely cut off all toxic relationships in my life?`, 
    answer: `In some cases, completely cutting off toxic relationships may be necessary for your well-being and personal growth. However, every situation is unique, and it is important to assess the level of toxicity and the potential for change in each relationship. Setting boundaries, having open and honest conversations, and seeking professional guidance can also be helpful in navigating challenging relationships.`,
    open: false 
  },
  { 
    question: `How do I handle the guilt or fear associated with cutting off toxic relationships?`, 
    answer: `Cutting off toxic relationships can be difficult and may bring up feelings of guilt or fear. It can be helpful to remind yourself of the negative impact these relationships have on your well-being and the potential for personal growth in creating a more positive and uplifting environment. Surrounding yourself with a supportive network and practicing self-care can also aid in managing these emotions.`,
    open: false 
  },
  { 
    question: `Can the Disconnect from People Who Drag You Down subliminal help me attract positive and uplifting relationships?`, 
    answer: `Yes, the Disconnect from People Who Drag You Down subliminal can help you attract positive and uplifting relationships by working on your subconscious mind. As you develop a more empowered and positive mindset towards relationships, you may find yourself naturally attracting and fostering healthier connections with people who uplift and support you.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>