<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Are you a fan of puzzle games? Do you want to improve your skills and become a master in solving puzzles? This subliminal is here to help you achieve that!"
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Enhancing your problem-solving abilities and sharpening your critical thinking skills.",
        "Increasing your focus, concentration, and attention to detail while solving puzzles.",
        "Boosting your memory and cognitive abilities, allowing you to remember patterns and strategies more effectively.",
        "Developing a strategic mindset and the ability to think several steps ahead in puzzle games.",
        "Building your confidence and perseverance to tackle even the most challenging puzzles."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can train and develop your puzzle game skills, expanding your capabilities and reaching new levels of mastery. Whether you enjoy crosswords, Sudoku, jigsaw puzzles, or any other type of puzzle game, this subliminal can help you become a true puzzle-solving guru. Get ready to impress your friends and have fun while challenging your brain!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Are you ready to level up your puzzle game skills? This subliminal is designed to help you enhance your problem-solving abilities and become a master in puzzle games. Before you start, consider these tips to maximize your training and development:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Clarify your goals and what you want to achieve in your puzzle game skills.",
        "Create a learning mindset: Approach puzzles with curiosity and a willingness to learn from challenges.",
        "Stay focused: Concentrate on the game and the puzzle at hand, eliminating distractions.",
        "Practice regularly: Consistency is key! Set aside dedicated time to practice and hone your skills.",
        "Visualize success: Imagine yourself mastering puzzle games and achieving your goals."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your puzzle game training, you can tap into your subconscious mind and accelerate your skill development. As you listen regularly, you may notice improvements in your problem-solving abilities and a greater sense of confidence in tackling puzzles. Embrace the process, stay dedicated, and get ready to elevate your puzzle game skills to the next level!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Puzzle Games Skills Training and Development audio! By dedicating time to enhancing your puzzle game skills, you are on your way to becoming a master in problem-solving and strategic thinking. As you reflect on your experience, keep in mind the incredible improvements you have made and the progress you are achieving."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Keep practicing: Continue engaging in puzzle games to refine your skills and challenge yourself.",
        "Explore different puzzles: Try out various puzzle types to broaden your problem-solving abilities.",
        "Analyze game strategies: Reflect on successful strategies and techniques that have worked for you.",
        "Join puzzle communities: Connect with fellow puzzle enthusiasts to learn from each other and exchange tips."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, improving puzzle game skills is a continuous process. Embrace new challenges and celebrate the progress you make along the way. With dedication and persistence, you are well on your way to becoming a master in puzzle games. Keep up the great work and enjoy the thrill of solving every puzzle that comes your way!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Puzzle Games Skills Training and Development program help improve my puzzle game skills?`, 
    answer: `The Puzzle Games Skills Training and Development program is designed to enhance your problem-solving abilities and improve your performance in puzzle games. Through targeted exercises, strategies, and techniques, you will learn how to approach puzzles more effectively, think critically, and analyze patterns and solutions. The program aims to provide you with the necessary skills and knowledge to become a master in puzzle games.`,
    open: false
  },
  { 
    question: `How long does it take to see improvements in my puzzle game skills?`, 
    answer: `The time it takes to see improvements in puzzle game skills can vary depending on factors such as your current skill level, the amount of time and effort you dedicate to training, and your ability to apply the techniques learned. Consistent practice and regular engagement with the program are key to achieving noticeable progress.`,
    open: false 
  },
  { 
    question: `Do I need any prior experience in puzzle games to benefit from the Puzzle Games Skills Training and Development program?`, 
    answer: `No, the program is designed to cater to individuals with different levels of experience in puzzle games. Whether you are a beginner or an experienced player looking to further enhance your skills, the program provides valuable insights, strategies, and techniques that can benefit players of all levels.`,
    open: false 
  },
  { 
    question: `What types of puzzle games does the program cover?`, 
    answer: `The Puzzle Games Skills Training and Development program covers a wide range of puzzle games, including but not limited to jigsaw puzzles, crossword puzzles, Sudoku, Rubik's Cube, and logic puzzles. The techniques and strategies taught in the program can be applied to various types of puzzles, helping you develop a versatile skill set.`,
    open: false 
  },
  { 
    question: `Can the Puzzle Games Skills Training and Development program be used for professional puzzle competitions?`, 
    answer: `Yes, the skills and techniques you learn from the Puzzle Games Skills Training and Development program can be applied in professional puzzle competitions. However, success in competitions also depends on factors such as practice, experience, and competition-specific strategies. The program provides a solid foundation to build upon and can be a valuable resource in your preparation.`,
    open: false 
  },
  { 
    question: `Is the Puzzle Games Skills Training and Development program suitable for children?`, 
    answer: `Yes, the Puzzle Games Skills Training and Development program can be suitable for children depending on their age, interest, and level of understanding. Parents or guardians may need to tailor the program materials and guidance to suit the child's individual needs and provide appropriate supervision and support.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>