<template>
  <div>
    <Dialog :dialog="dialog" :width="800" :title="`Edit Category`" @closeDialog="closeDialog">
      <div>
        <p class="mb-1">Mobile Version</p>
        <v-text-field solo dense hide-details class="mb-3" placeholder="Mobile Version" v-model="version"></v-text-field>
      </div>

      <template v-slot:action>
        <v-row class="ma-0">
          <v-col class="pl-0 pr-2">
            <v-btn block @click="submitDialog()" :disabled="disabled">Update</v-btn>
          </v-col>
          <v-col class="pl-2 pr-0">
            <v-btn block @click="closeDialog()">Cancel</v-btn>
          </v-col>
        </v-row>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/Dialog.vue'
import { getMessage } from '@/utils'

import { updateMobileVersions } from '@/api/mobileversion.js'

export default {
  props: {
    dialog: {
      type: Boolean
    },
    data: {
      type: Object
    }
  },
  data: () => {
    return {
      updateMobileVersions,
      getMessage,

      id: null,
      version: null
    }
  },
  computed: {
    disabled() {
      return this.version == '' || this.version == null
    }
  },
  components: {
    Dialog
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.id = this.data.id
      this.version = this.data.version
    },
    closeDialog() {
      this.$emit('closeDialog', false)
    },
    submitDialog() {
      const data = {
        id: this.id,
        version: this.version
      }

      this.updateMobileVersions(data).then((data) => {
        this.getMessage(this, data)
        this.closeDialog()
      })
    }
  }
}
</script>
