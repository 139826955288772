<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Breaking free from the cycle of binge eating and developing a healthier relationship with food is possible with the help of this subliminal. It targets your subconscious mind to rewire your thoughts and behaviors around food."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support you:'
    },
    {
      type: 'list',
      items: [
        "Identifying and addressing the root causes and triggers of your binge eating habits.",
        "Reprogramming negative thought patterns and beliefs about food and self-worth.",
        "Cultivating healthier coping mechanisms and strategies for dealing with emotions.",
        "Building a mindful and intuitive approach to eating, reconnecting with your body's cues of hunger and fullness.",
        "Developing a positive and balanced relationship with food, free from guilt or restriction."
      ]
    },
    {
      type: 'paragraph',
      text: "By consistently listening to this subliminal, you can gradually break free from the cycle of binge eating and develop healthier habits around food. Over time, you may find yourself feeling more in control, empowered, and at peace with your relationship with food. Remember, progress takes time, so be patient and kind to yourself throughout this transformative journey!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Breaking free from the cycle of binge eating and developing a healthier relationship with food is a journey that requires dedication and perseverance. This subliminal is designed to support you on that journey. Before you start listening, consider these tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your triggers: Be aware of the emotions, situations, or thoughts that tend to lead to binge eating episodes.",
        "Create a support system: Seek out friends, family, or professionals who can offer understanding, guidance, and encouragement.",
        "Practice mindful eating: Focus on the sensations, tastes, and textures of your meals, allowing yourself to truly savor the experience.",
        "Set realistic goals: Aim for progress rather than perfection, celebrating small victories along the way."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal, allow the affirmations to penetrate your subconscious and help reshape your thoughts and behaviors around food. Remember, change takes time and effort, so be patient and kind to yourself throughout this process. With dedication and the right mindset, you can break free from the cycle of binge eating and develop a healthier relationship with food. Take a deep breath, focus on your goals, and let's embark on this transformative journey together."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Binge Eating Habit Removal subliminal! You've taken a significant step towards breaking free from the cycle of binge eating and developing a healthier relationship with food. Now, it's essential to reflect on your experience and continue your journey towards healing."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice mindful eating: Pay attention to your body's hunger and fullness cues. Eat slowly and savor each bite.",
        "Identify triggers: Keep a journal to track emotional, environmental, or situational triggers that contribute to binge eating.",
        "Seek support: Consider joining a support group or seeking professional help to aid in your recovery.",
        "Focus on self-care: Engage in activities that nourish your mind, body, and soul, such as exercising, meditating, or engaging in hobbies.",
        "Challenge negative thoughts: Replace self-critical thoughts with positive affirmations and remind yourself of your progress."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, breaking free from binge eating is a journey that requires patience, self-compassion, and support. Celebrate each small victory and stay committed to developing a healthier relationship with food. You have the strength and resilience to overcome this challenge. Keep going!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How do I know if the Binge Eating Habit Removal subliminal is working for me?`, 
    answer: `The effects of the subliminal on breaking the cycle of binge eating may vary from person to person. Pay attention to any changes in your behavior and mindset towards food. You may notice a decrease in the frequency or intensity of binge episodes, as well as an increase in self-control and awareness of your eating habits. Keep listening to the subliminal and observe any positive changes that occur in your relationship with food.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Binge Eating Habit Removal subliminal?`, 
    answer: `Results can vary depending on the individual and their commitment to overcoming binge eating habits. Some people may notice positive changes after a few weeks of consistent listening, while others may require more time. It is important to be patient and dedicated to the process, using the subliminal as a tool to support your efforts.`,
    open: false 
  },
  { 
    question: `Are there any additional steps I should take to overcome binge eating while listening to the subliminal?`, 
    answer: `Listening to the Binge Eating Habit Removal subliminal can be a valuable tool in breaking free from binge eating, but it should be combined with other practical strategies. Consider seeking support from a therapist or support group specializing in eating disorders. Creating a meal plan, practicing mindful eating, and addressing any underlying emotional issues can also contribute to your progress. Remember, overcoming binge eating is a journey that requires self-compassion and perseverance.`,
    open: false 
  },
  { 
    question: `Can the Binge Eating Habit Removal subliminal replace professional help for overcoming an eating disorder?`, 
    answer: `No, the subliminal is not a substitute for professional help in the treatment of eating disorders. It can be used as a complementary tool to support your recovery, but it is important to seek guidance from a qualified healthcare provider or therapist who specializes in eating disorders for a comprehensive treatment plan.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Binge Eating Habit Removal subliminal?`, 
    answer: `There are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your eating habits or mental health, it is important to consult with a healthcare provider or therapist who can provide appropriate guidance and support.`,
    open: false 
  },
  { 
    question: `Will the Binge Eating Habit Removal subliminal take away my enjoyment of food?`, 
    answer: `No, the aim of the Binge Eating Habit Removal subliminal is to help you develop a healthier relationship with food, not to take away your enjoyment. It focuses on breaking the harmful cycle of binge eating and promoting self-control and balance. You should still be able to enjoy food while making healthier choices and developing a more positive mindset.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>