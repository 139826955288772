<template>
  <div>
    <Register />
  </div>
</template>

<script>
import { checkUrl } from '@/utils'
import Register from '@/views/Main/Register/index.vue'
export default {
  components: {
    Register
  },
  mounted() {
    const params = {
      route: this.$route,
      router: this.$router,
      tempname: 'subscription_temp'
    }
    checkUrl(params)
  },
  methods: {}
}
</script>
