<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Canceling bankruptcy and regaining financial stability can be a challenging process, but it is possible. This subliminal is designed to provide you with the necessary guidance and resources to navigate through this difficult time and work towards a brighter financial future."
    },
    {
      type: 'heading',
      text: 'Here are some ways in which this subliminal can assist you:'
    },
    {
      type: 'list',
      items: [
        "Providing positive affirmations and mindset shifts to help you overcome feelings of hopelessness and regain confidence in your financial abilities.",
        "Offering practical strategies and advice for rebuilding your credit and managing your finances effectively.",
        "Supporting you in creating a realistic and achievable financial plan to help you pay off debts and improve your financial wellbeing.",
        "Guiding you through the legal process of canceling bankruptcy by providing information and resources to connect you with appropriate professionals.",
        "Offering emotional support and encouragement during this challenging time, reminding you that you are not alone in your journey towards financial stability."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, canceling bankruptcy and regaining financial stability takes time, effort, and commitment. By listening to this subliminal regularly and implementing the strategies and advice provided, you can take significant steps towards improving your financial situation. Stay focused, stay determined, and know that a brighter future awaits you."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Canceling bankruptcy and regaining financial stability is a challenging process, but this subliminal can provide you with the guidance and resources you need to achieve your goal. Before you start listening, consider the following strategies to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Educate yourself: Gather information about the bankruptcy cancellation process, including laws, regulations, and available resources.",
        "Set realistic goals: Define what financial stability means to you and identify the steps you need to take to reach it.",
        "Create a detailed plan: Break down your goals into manageable action steps and create a timeline for achieving them.",
        "Stay positive and motivated: Visualize yourself achieving financial stability and maintaining healthy financial habits throughout your journey."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to support your efforts in canceling bankruptcy and regaining control of your finances. As you listen regularly, you may start to feel more empowered, motivated, and equipped to overcome challenges. Take a moment to focus on your goals, believe in your ability to achieve them, and let's begin the journey towards financial stability."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Cancel Bankruptcy session! You have taken a significant step towards regaining control of your financial situation and achieving stability. Now, it's time to reflect on your experience and take practical steps towards canceling bankruptcy and rebuilding your financial future."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Assess your financial situation: Take stock of your debts, assets, and income. Identify areas where you can make changes or seek professional help.",
        "Create a budget: Develop a realistic budget that helps you allocate funds towards debt repayment while covering your essential expenses.",
        "Connect with a financial advisor: Seek guidance from a professional who can provide personalized advice and assistance in canceling bankruptcy.",
        "Educate yourself: Learn about money management, debt consolidation, and other strategies to regain financial stability."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, canceling bankruptcy and rebuilding your financial life is a process that requires patience and perseverance. Stay committed to your goals, seek the necessary resources and advice, and believe in your ability to overcome financial challenges. With determination and the right actions, you can regain financial independence. Best of luck!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Can bankruptcy be cancelled?`, 
    answer: `Yes, bankruptcy can be cancelled under certain circumstances. If you have filed for bankruptcy and wish to cancel it, you will need to go through a legal process called bankruptcy dismissal. This involves filing a motion with the bankruptcy court and providing valid reasons for the dismissal. It is recommended to consult with a bankruptcy attorney for guidance on how to proceed with cancelling bankruptcy.`,
    open: false
  },
  { 
    question: `What are some reasons for cancelling bankruptcy?`, 
    answer: `There can be various reasons for cancelling bankruptcy, such as a change in financial circumstances, a successful negotiation with creditors, or the discovery of errors or fraud in the bankruptcy process. It is important to have valid and convincing reasons to support your request for cancellation.`,
    open: false 
  },
  { 
    question: `Can I cancel bankruptcy on my own or do I need an attorney?`, 
    answer: `While it is possible to file a motion for bankruptcy dismissal on your own, it is highly recommended to seek the guidance of a bankruptcy attorney. An attorney can help you navigate the legal process, ensure that all necessary documents are filled out correctly, and present a strong case for cancellation. They can also advise you on the potential implications and alternatives to bankruptcy.`,
    open: false 
  },
  { 
    question: `What are the potential consequences of cancelling bankruptcy?`, 
    answer: `The potential consequences of cancelling bankruptcy can vary depending on your specific situation and the stage of the bankruptcy process. You may be liable for any debts that were previously discharged, and creditors may resume collection efforts. Additionally, cancelling bankruptcy does not erase the negative impact of the bankruptcy filing on your credit history, which may affect your ability to obtain credit in the future.`,
    open: false 
  },
  { 
    question: `How long does it take to cancel bankruptcy?`, 
    answer: `The time it takes to cancel bankruptcy can vary depending on several factors, including the complexity of your case, the court's schedule, and the responsiveness of involved parties. It is recommended to consult with a bankruptcy attorney who can provide an estimate based on your specific circumstances.`,
    open: false 
  },
  { 
    question: `What are some alternatives to cancelling bankruptcy?`, 
    answer: `If you are considering cancelling bankruptcy, it may be beneficial to explore alternatives first. Some alternatives to bankruptcy may include debt consolidation, negotiating payment plans with creditors, or seeking credit counseling services. These alternatives can help you regain financial stability without the long-term consequences of bankruptcy. Consulting with a financial advisor or bankruptcy attorney can provide valuable insights into the most suitable options for your situation.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>