<template>
  <section>
    <div class="container">
      <div>
        <h1>
          Why Magus for Business?
        </h1>
        <h5 class="mb-2">
          Unlock Success with Simple, Reliable Solutions
        </h5>
        <h5>
          As the most innovative health and self-development company. Magus can help you <br>
          address the mental health and wellness needs of your business. We offer flexibility <br>
          and convenience to your company with innovative and relevant solutions.
        </h5>
      </div>
      <div class="col-10 col-lg-8 mx-auto">
        <div class="row my-4 ">
          <template v-for="item, index in menus">
            <div class="product-box-left" v-if="item.icon % 2 == 0">
              <div>
                <h6>{{ item.title }}</h6>
                <p v-html="item.details"></p>
              </div>
              <div>
                <img :src="require(`@/assets/images/main/business/2/${item.icon}.svg`)" alt="">
              </div>
            </div>
            <div v-else class="product-box-right">
              <div>
                <img :src="require(`@/assets/images/main/business/2/${item.icon}.svg`)" alt="">
              </div>
              <div>
                <h6>{{ item.title }}</h6>
                <p v-html="item.details"></p>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div>
        <button class="button btn btn-primary btn-lg" @click="$router.go(-1)">
        {{ showInterfaceButton }}</button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data: () => {
    return {
      show_interface: true,
      menus: [
        { icon: '1',
          details: "Enjoy the freedom to use our services whenever you want and wherever you are. We're available 24/7, making it easy for you to get what you need at any time of the day or night. Whether you're at home, in the office, or on the go, we're always just a click away. Experience ultimate convenience with our anytime, anywhere accessibility.",
          title: 'Use anytime and anywhere' 
         },
        { icon: '2', 
          details: "Our Custom Content Department is dedicated to crafting personalized subliminal audio tracks that aim to improve your mental well-being. We understand that each individual's journey to mental health is unique. That's why our team of experts works closely with you to understand your specific needs and challenges.<br><br>Using this insight, we create custom audio experiences designed to help you achieve your mental health goals. From reducing stress and anxiety to boosting confidence and focus, our tailored soundtracks serve as a supportive tool in your self-improvement journey. With our specialized content, you get more than just a listening experience; you get a personalized pathway to a healthier mind.",
          title: 'Custom Content Development' 
        },
        { icon: '3', 
          details: "Boost Your Team's Well-being and Productivity with Our Curated Mental Health Audio Tracks! Designed to improve focus, relieve stress, and build a healthier mindset, our subliminal audio service is easily accessible through our app and website. Ideal for teams who want to excel, while also caring for their mental health.",
          title: 'Curated Content for Work and Teams' 
        },
        { icon: '4', 
          details: "Discover the power of Magus App's easy-to-use dashboards and reports. Our simple tools help you track your journey to a better you. The dashboards show how you're doing, your favorite tracks, and what you're working on. Our reports dig deeper, showing how you feel, stress levels, and your mindset. It's like having a friend guiding you to a better life.",
          title: 'Comprehensive Dashboards and Reports' 
        }
      ]
    }
  },
  computed: {
    showInterfaceButton() {
      return this.show_interface ? "Back" : "Learn More";
    }
  },
}
</script>

<style scoped>
  h1, p,
  h5 {
    line-height: 1.35em;
    color: var(--ma-black-olive);
  }

  h5:nth-of-type(1) {
    color: var(--ma-tufts-blue);
  }

  h5:nth-of-type(2), p {
    font-family: "Montserrat-Regular";
  }

  h6 {
     color: var(--ma-tufts-blue);
  }

  .product-box-left>div>img, .product-box-right>div>img  {
    width: clamp(4.19rem, calc(1.64rem + 8.72vw), 10.75rem);
    margin-block: 1.5em;
  }
  .product-box-left >div, .product-box-right >div {
      float: left;
  }
  .product-box-left>div:nth-of-type(2) {
    width: 22%;
  }
  .product-box-left>div:nth-of-type(1) {
    width: 70%;
    margin-right: 20px;
    margin-bottom: 30px;
    
  }
  .product-box-left {
    padding: 0em;
    justify-content: center;
    text-align: left;
  }
  .product-box-right>div:nth-of-type(2) {
    width: 70%;
    margin-left: 20px;
    margin-bottom: 30px;
  }
  .product-box-right>div:nth-of-type(1) {
    width: 22%;
  }
  .product-box-right {
    padding: 0em;
    justify-content: center;
    text-align: left;
  }

  @media screen and (min-width: 40rem) {
    section {
      text-align: center;
      overflow-y: scroll;
    }

  }
</style>