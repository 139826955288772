<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Living with Irritable Bowel Syndrome (IBS) can be challenging, but there are effective treatments and strategies that can help you find relief and manage your symptoms. This subliminal is designed to support you on your journey to a more comfortable and fulfilling life."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can assist you in managing your IBS:'
    },
    {
      type: 'list',
      items: [
        "Reducing the frequency and severity of IBS symptoms, such as abdominal pain, bloating, and changes in bowel movements.",
        "Promoting relaxation and stress reduction, as stress can often trigger or worsen IBS symptoms.",
        "Increasing your ability to cope with the challenges of living with a chronic condition, improving your mental well-being.",
        "Encouraging healthy lifestyle habits, such as maintaining a balanced diet, regular exercise, and good sleep hygiene.",
        "Providing support and motivation to follow your treatment plan and make necessary lifestyle changes."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, managing IBS is a journey, and everyone's experience with the condition is unique. This subliminal is meant to be used in conjunction with medical advice and treatment. By incorporating this subliminal into your routine, you may find greater relief from your symptoms and experience an improved quality of life."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Managing the symptoms of Irritable Bowel Syndrome (IBS) can be challenging, but this subliminal is designed to provide you with effective treatments and strategies to find relief. Before you start listening, here are some tips to prepare yourself:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Educate yourself: Learn about IBS and its symptoms to gain a better understanding of your condition.",
        "Consult a healthcare professional: Seek professional advice to ensure accurate diagnosis and receive personalized treatment recommendations.",
        "Identify triggers: Pay attention to foods, stressors, or lifestyle factors that may worsen your symptoms and make a note of them.",
        "Embrace self-care: Prioritize self-care activities such as stress management, regular exercise, and sufficient sleep to promote overall well-being.",
        "Set realistic expectations: Understand that managing IBS is a journey, and improvements may take time. Be patient and kind to yourself."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal, keep in mind that it is designed to complement your existing treatment plan and help you manage your symptoms more effectively. By incorporating these strategies into your daily routine and maintaining a positive mindset, you can improve your quality of life and live more comfortably with IBS. Let's embark on this journey together and start finding relief!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Irritable Bowel Syndrome (IBS) Treatment session! By working on managing your symptoms and finding effective treatments, you're taking control of your health and improving your quality of life. Now, it's time to reflect on your experience and continue your journey towards living comfortably with IBS."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Educate yourself: Research and learn about IBS and potential triggers to better understand your condition.",
        "Keep a food diary: Monitor your meals and note down any correlations between specific foods and your symptoms.",
        "Explore treatment options: Consult with medical professionals to find medications or therapies that work for you.",
        "Manage stress: Practice stress-reducing techniques like deep breathing, meditation, or engaging in hobbies."
      ]
    },
    {
      type: 'paragraph',
      text: "Living with IBS can be challenging, but with the right strategies and support, you can minimize symptoms and live comfortably. Remember to be patient with yourself, as finding the right treatment and management plan may take time. Focus on self-care, reduce stress, and make informed decisions to maintain a good quality of life. Stay positive and empowered!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What are some common treatments for Irritable Bowel Syndrome (IBS)?`, 
    answer: `Common treatments for IBS typically include dietary changes, stress management techniques, medication, and lifestyle modifications. Some individuals find relief through the low FODMAP diet, which eliminates certain types of carbohydrates that can trigger symptoms. Others may benefit from medications that help regulate bowel movements or manage specific symptoms like pain or diarrhea. Working with a healthcare professional can help determine the best treatment plan for your specific needs.`,
    open: false
  },
  { 
    question: `Can natural remedies or alternative therapies help with IBS symptoms?`, 
    answer: `While there is limited scientific evidence to support the effectiveness of natural remedies and alternative therapies for IBS, some people find these approaches helpful in managing their symptoms. Examples of natural remedies and alternative therapies include probiotics, herbal supplements, acupuncture, and mind-body techniques like relaxation exercises or hypnotherapy. It is important to consult with a healthcare provider before trying any new treatment or therapy.`,
    open: false 
  },
  { 
    question: `How can stress management techniques help with IBS?`, 
    answer: `Stress can worsen IBS symptoms, so managing stress levels can have a positive impact on symptom severity. Techniques like deep breathing exercises, meditation, yoga, and regular physical activity can help reduce stress and promote overall well-being. Finding healthy coping mechanisms for stress can be beneficial in managing IBS.`,
    open: false 
  },
  { 
    question: `Are there any dietary changes that can help alleviate IBS symptoms?`, 
    answer: `Some dietary changes that may help alleviate IBS symptoms include following a low FODMAP diet, avoiding trigger foods such as dairy, caffeine, spicy foods, and high-fat foods, and eating smaller, more frequent meals. It may also be helpful to keep a food diary to identify which foods aggravate your symptoms. Working with a registered dietitian who specializes in digestive health can provide personalized guidance on dietary changes for IBS.`,
    open: false 
  },
  { 
    question: `Is there a cure for Irritable Bowel Syndrome (IBS)?`, 
    answer: `There is currently no known cure for IBS, but with proper management and lifestyle modifications, many individuals with IBS are able to effectively manage their symptoms and improve their quality of life. Treatment focuses on controlling and reducing symptoms so that individuals can live comfortably with the condition.`,
    open: false 
  },
  { 
    question: `Can lifestyle modifications help reduce IBS symptoms?`, 
    answer: `Yes, lifestyle modifications can play a significant role in reducing IBS symptoms. This includes maintaining a regular sleep schedule, staying physically active, managing stress levels, and practicing good self-care. It may also be beneficial to avoid smoking and limit alcohol consumption. Making sustainable lifestyle changes can contribute to symptom relief and overall well-being.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>