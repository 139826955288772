<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Dealing with nerve pain can be incredibly challenging, but this subliminal is here to offer you relief and healing. By using soothing and effective techniques, it aims to reduce your pain and discomfort, improving your overall quality of life."
    },
    {
      type: 'heading',
      text: 'Here are some key aspects of this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Targeting your subconscious mind to help alleviate nerve pain.",
        "Providing soothing and calming messages to help you relax and find relief.",
        "Promoting natural healing processes within your body.",
        "Assisting in reducing inflammation and swelling associated with nerve pain.",
        "Encouraging a positive mindset and overall well-being."
      ]
    },
    {
      type: 'paragraph',
      text: "With consistent use of this subliminal, you can tap into the power of your mind and body to promote healing and reduce nerve pain. While everyone's journey is unique, many people have found relief and significant improvements in their quality of life through the use of subliminal techniques. Remember, healing takes time and patience, and this subliminal can be a helpful tool along the way."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Living with nerve pain can be challenging, but this subliminal aims to provide you with soothing and effective techniques to alleviate your discomfort. Before you start listening, consider these suggestions to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a calm environment: Find a quiet and peaceful space where you can listen to the subliminal without interruptions.",
        "Visualize relief: Imagine yourself free from pain and visualize your body healing and regaining vitality.",
        "Focus on healing: Maintain a positive mindset and keep your intention of finding relief and healing at the forefront of your mind.",
        "Listen with openness: Be open to receiving the subliminal affirmations and allow them to work on a deep subconscious level."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal is designed to support your journey towards finding relief from nerve pain and improving your quality of life. By regularly listening and incorporating these techniques into your life, you can experience a reduction in pain and discomfort, allowing you to embrace a healthier, more vibrant life. Take a moment to prepare yourself, find a comfortable position, and let the healing begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Nerve Pain Healing subliminal! By focusing on finding relief from nerve pain and healing your body, you've taken a significant step towards improving your quality of life and reducing discomfort. Now, it's essential to reflect on your experience and continue your journey to healing and well-being."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Seek medical advice: If you haven't already, consult with a healthcare professional about your nerve pain.",
        "Try relaxation techniques: Explore various methods like deep breathing, meditation, or gentle yoga to reduce stress and promote healing.",
        "Consider physical therapy: Speak with a physical therapist for exercises and techniques to strengthen and support your body.",
        "Take care of your overall health: Engage in activities that contribute to your overall well-being, such as eating a balanced diet and getting regular exercise."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, healing from nerve pain is a journey that requires both physical and mental effort. Take the necessary steps to support your body's healing process, and be patient with yourself along the way. With time and consistent care, you can find relief and improve your quality of life. Wishing you a pain-free and vibrant future!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Can the Nerve Pain Healing techniques provide immediate relief?`, 
    answer: `The Nerve Pain Healing techniques may provide some immediate relief, but the effectiveness and duration of relief may vary from person to person. Consistent practice of these techniques can lead to long-term improvement in managing and reducing nerve pain. It is important to consult with a healthcare professional to develop a comprehensive plan for managing your specific condition.`,
    open: false
  },
  { 
    question: `Are the Nerve Pain Healing techniques safe to use alongside other treatments?`, 
    answer: `Yes, the Nerve Pain Healing techniques can be safely used alongside other treatments for nerve pain. However, it is important to consult with your healthcare provider to ensure that the techniques are appropriate for your specific condition. They can help you create an integrated approach to managing your nerve pain.`,
    open: false 
  },
  { 
    question: `How long does it take to see results from the Nerve Pain Healing techniques?`, 
    answer: `The time it takes to see results from the Nerve Pain Healing techniques may vary depending on the individual and the severity of the nerve pain. It is important to practice the techniques consistently and be patient with yourself. Over time, you may experience a reduction in pain and discomfort as your body heals.`,
    open: false 
  },
  { 
    question: `Can the Nerve Pain Healing techniques be used for all types of nerve pain?`, 
    answer: `While the Nerve Pain Healing techniques may provide relief for various types of nerve pain, it is essential to consult with a healthcare professional to determine the underlying cause of your specific nerve pain. Different types of nerve pain may require different treatment approaches, so it is important to receive a proper diagnosis and guidance from a medical expert.`,
    open: false 
  },
  { 
    question: `Can the Nerve Pain Healing techniques replace medical treatment?`, 
    answer: `The Nerve Pain Healing techniques are not intended to replace medical treatment for nerve pain. They can be used as a complementary approach to support your overall treatment plan. It is crucial to consult with a healthcare professional to develop an integrated and personalized plan for managing your specific condition.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with the Nerve Pain Healing techniques?`, 
    answer: `When practiced correctly, the Nerve Pain Healing techniques are generally safe and have minimal risks or side effects. However, it is important to approach the techniques mindfully and listen to your body. If you experience any discomfort or worsening of symptoms, it is advisable to consult with a healthcare professional.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>