<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "This subliminal is designed to help you unlock the secrets of the golden ratio and how it can contribute to the development of a symmetrical and attractive face. By targeting your subconscious mind, it aims to make lasting changes to your facial features and enhance your overall appearance."
    },
    {
      type: 'heading',
      text: 'What you can expect from this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Understanding the concept of the golden ratio and its significance in facial aesthetics.",
        "Learning techniques and exercises to enhance your facial symmetry and balance.",
        "Developing a greater appreciation for your unique facial features and how they contribute to your overall attractiveness.",
        "Gaining confidence and self-assurance in your appearance.",
        "Improving your facial muscle tone and overall facial harmony."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal and applying the techniques and exercises provided, you can gradually achieve a more balanced and harmonious facial appearance. It's important to remember that everyone's journey is unique, and results may vary. With consistency and patience, however, you can make significant progress towards achieving your desired facial aesthetics."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Unlocking the potential of your facial features and achieving a symmetrical and attractive face is an exciting journey. This subliminal is designed to guide you towards embracing the principles of the golden ratio and enhancing your facial harmony. Before you begin, here are some tips to maximize your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Understand the golden ratio: Learn about the mathematical concept and how it relates to facial aesthetics.",
        "Review your facial features: Identify areas that you would like to enhance or balance based on the principles of symmetry and the golden ratio.",
        "Practice facial exercises: Explore techniques and exercises designed to target specific areas and improve muscle tone and overall appearance.",
        "Maintain consistency: Consistently practice the techniques and exercises while listening to the subliminal, focusing your attention on your desire for a balanced and harmonious face."
      ]
    },
    {
      type: 'paragraph',
      text: "By committing to this subliminal and incorporating the preparation tips into your routine, you can take significant steps towards enhancing your facial features. As you continue your journey, remember to be patient, embrace your unique traits, and have faith in your ability to achieve a more balanced and harmonious appearance. Let's begin this transformative process together!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Golden Ratio and Symmetrical Face Development subliminal session! By exploring the secrets of the golden ratio and learning techniques to enhance your facial features, you've taken a step towards achieving a more balanced and harmonious appearance."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Embrace your unique features: Remember that beauty comes in all forms, and each face has its own distinct charm.",
        "Practice facial exercises: Engage in specific exercises and massages to improve muscle strength and tone in your face.",
        "Maintain a healthy skincare routine: Take care of your skin by cleansing, moisturizing, and protecting it from sun damage.",
        "Experiment with makeup and hair: Enhance your features using makeup techniques that accentuate your best attributes.",
        "Radiate confidence: Remember that true beauty comes from within, so embrace your uniqueness and let your confidence shine."
      ]
    },
    {
      type: 'paragraph',
      text: "Developing a symmetrical and attractive face is a journey that involves self-love, self-care, and embracing your individuality. Enjoy exploring the techniques and exercises you've learned, and remember that true beauty is not solely defined by any ratio or standard. Embrace your own unique beauty and shine bright!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What is the golden ratio and how does it relate to facial symmetry?`, 
    answer: `The golden ratio is a mathematical concept that is often used in art and design. It refers to the ratio of 1:1.618 and is believed to represent aesthetically pleasing proportions. When it comes to facial symmetry, the golden ratio suggests that certain facial features, such as the width of the nose in relation to the distance between the eyes, should ideally follow this ratio to create a balanced and attractive appearance. However, it's important to note that beauty is subjective and can vary across different cultures and individuals.`,
    open: false
  },
  { 
    question: `Can I enhance my facial symmetry and appearance using exercises and techniques?`, 
    answer: `Yes, there are exercises and techniques that can help enhance your facial symmetry and appearance. Facial exercises, such as facial yoga or muscle-toning exercises, can help improve muscle tone and circulation, which may contribute to a more youthful and balanced appearance. Additionally, techniques like contouring with makeup or using dermal fillers can help create the illusion of symmetry and enhance specific facial features. Consulting with a professional, such as a dermatologist or plastic surgeon, can provide personalized guidance for achieving your desired results.`,
    open: false 
  },
  { 
    question: `How long does it take to see results from facial exercises and techniques?`, 
    answer: `Results from facial exercises and techniques can vary depending on various factors, including your commitment to the exercises, your individual body composition, and genetics. Some people may start noticing improvements in their facial symmetry and appearance within a few weeks of consistent practice, while others may require more time. It's important to remember that these exercises and techniques should be performed in conjunction with a healthy lifestyle and overall self-care routine for optimal results.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects to consider when using facial exercises and techniques?`, 
    answer: `When performed correctly and with caution, facial exercises are generally safe. However, it's important to avoid exercises that put excessive strain on your facial muscles or cause discomfort. If you choose to use techniques such as contouring or dermal fillers, it's essential to consult with a professional to ensure safe and appropriate application. As with any cosmetic procedure or practice, there can be risks and side effects, so it's crucial to educate yourself, seek professional advice, and make informed decisions.`,
    open: false 
  },
  { 
    question: `Can the golden ratio and facial symmetry be achieved without invasive procedures?`, 
    answer: `Yes, achieving the golden ratio and facial symmetry can be pursued through non-invasive methods. Facial exercises, skincare routines, makeup techniques, and other non-surgical approaches can help enhance facial features and create the illusion of symmetry. It's important to remember that everyone's face is unique, and enhancing your appearance should be approached with realistic expectations and an emphasis on self-acceptance.`,
    open: false 
  },
  { 
    question: `Is the golden ratio the only measure of beauty and attractiveness?`, 
    answer: `No, the golden ratio is just one theory of beauty and attractiveness. Beauty standards vary across cultures and change over time. While the golden ratio and facial symmetry have historically been associated with attractiveness, it's essential to recognize that beauty is subjective and highly individualized. Embracing diversity and focusing on self-acceptance can play a significant role in developing a positive body image and overall well-being.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>