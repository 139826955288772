<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're struggling with constipation, you're not alone. It can be uncomfortable and disruptive to daily life. But there are proven techniques and remedies that can help relieve constipation and promote healthy digestion - and that's where this subliminal comes in."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support you:'
    },
    {
      type: 'list',
      items: [
        "Encouraging regular bowel movements and relieving constipation.",
        "Promoting a healthier digestive system and improving overall gut health.",
        "Reducing discomfort and bloating associated with constipation.",
        "Supporting the natural rhythm of your digestive system.",
        "Helping you establish healthier lifestyle habits that aid digestion."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal consistently, you can reprogram your subconscious mind to support healthy digestion and relieve constipation. Over time, you may find that you experience fewer episodes of constipation and feel more comfortable and at ease in your body. Remember, everyone's journey to better digestion is unique, so be patient and kind to yourself as you explore what works best for you."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "If you're experiencing constipation and looking for relief, this subliminal can help you promote healthy digestion and find comfort. Before you start listening, here are some strategies to consider:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Stay hydrated: Drink plenty of water throughout the day to maintain a healthy level of hydration.",
        "Eat a high-fiber diet: Include foods rich in fiber, such as fruits, vegetables, and whole grains, to help regulate your bowel movements.",
        "Exercise regularly: Engage in physical activity to stimulate your digestive system and promote regularity.",
        "Relaxation techniques: Practice relaxation techniques like deep breathing or meditation to reduce stress, which can contribute to constipation.",
        "Follow a consistent routine: Establish a regular time for bathroom visits to train your body's natural digestive patterns."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to support and amplify your efforts towards relieving constipation. By incorporating these preparation tips into your lifestyle and regularly listening to the subliminal, you can create a holistic approach to promote healthy digestion. So take a moment to focus on your well-being, set your intention, and let's begin the journey towards relief and improved digestive health."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the subliminal session on relieving constipation! By focusing on your digestion and finding relief from this uncomfortable condition, you've taken an important step towards improving your overall well-being. Take a moment to reflect on your experience and consider your next steps in promoting healthy digestion."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Evaluate your diet: Assess your dietary choices and identify any foods that may contribute to constipation.",
        "Increase fiber intake: Incorporate fiber-rich foods like fruits, vegetables, and whole grains into your meals.",
        "Stay hydrated: Ensure you're drinking enough water throughout the day to support regular bowel movements.",
        "Consider exercise: Engage in physical activity regularly to help stimulate digestion and promote regularity."
      ]
    },
    {
      type: 'paragraph',
      text: "Relieving constipation is a process that requires lifestyle adjustments and patience. Embrace the changes needed to support a healthy digestive system. Remember, every step you take towards promoting better digestion is a step towards feeling more comfortable and improving your overall quality of life. Good luck!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Relieve Constipation subliminal help me?`, 
    answer: `The Relieve Constipation subliminal is designed to help you promote healthy digestion and find relief from constipation. By listening to the subliminal audio, your subconscious mind can be influenced to support a more efficient digestive system. It can help you relax, reduce stress, and align your thoughts with healthy habits that promote regular bowel movements.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Relieve Constipation subliminal?`, 
    answer: `The time it takes to see results may vary from person to person. Some individuals may experience relief from constipation after a few listens, while others may require more time and consistency. We recommend listening to the subliminal daily and consistently for best results. Additionally, implementing a balanced and fiber-rich diet, staying hydrated, and exercising regularly can further support a healthy digestive system.`,
    open: false 
  },
  { 
    question: `Can the Relieve Constipation subliminal replace medical treatment for constipation?`, 
    answer: `No, the subliminal audio is not intended to replace medical treatment for constipation. It is designed to complement healthy lifestyle practices and promote a positive mindset towards digestion. If you have chronic or severe constipation, it is important to consult with a healthcare professional for appropriate diagnosis and treatment.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Relieve Constipation subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Relieve Constipation subliminal. However, if you have any concerns about your digestion or health, please consult with your healthcare provider. It is important to note that the subliminal is intended to supplement healthy lifestyle changes, not replace them.`,
    open: false 
  },
  { 
    question: `How can I improve the effectiveness of the Relieve Constipation subliminal?`, 
    answer: `To enhance the effectiveness of the Relieve Constipation subliminal, you can incorporate other healthy habits into your lifestyle. This includes maintaining a balanced and fiber-rich diet, staying hydrated, exercising regularly, and managing stress. Additionally, practicing relaxation techniques such as deep breathing or meditation can also help support healthy digestion and bowel movements.`,
    open: false 
  },
  { 
    question: `Will I need to listen to the Relieve Constipation subliminal forever?`, 
    answer: `The goal of the Relieve Constipation subliminal is to help you develop healthy habits and promote regular bowel movements. Over time, with consistency and the implementation of other healthy lifestyle practices, you may find that your digestion improves and constipation becomes less of a problem. As you continue to develop positive habits, you may not need to rely on the subliminal audio indefinitely.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>