<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Developing a sustainable financial future requires careful planning and effective management of your finances. This subliminal can help you achieve this by focusing on creating a solid financial plan and implementing strategies for long-term success."
    },
    {
      type: 'heading',
      text: 'Key aspects covered in this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Setting clear financial goals and creating a roadmap to achieve them.",
        "Learning effective budgeting techniques and methods for tracking expenses.",
        "Developing healthy spending habits and avoiding unnecessary debt.",
        "Understanding the importance of saving and investing for the future.",
        "Building financial resilience and preparing for unexpected expenses.",
        "Gaining confidence in managing your finances and making informed financial decisions."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your mindset to adopt sustainable financial practices and develop the discipline and motivation to stick to your financial plan. Remember, building a strong financial foundation takes time and effort, but with consistent effort, you can create a sustainable financial future."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Creating a sustainable financial future requires careful planning and effective strategies for managing your finances. This subliminal is designed to support you in developing a solid financial plan. Before you begin listening, consider the following tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Define your financial goals: Understand what you want to achieve financially, whether it's saving for retirement, paying off debt, or building an emergency fund.",
        "Assess your current situation: Take stock of your income, expenses, assets, and liabilities to gain a clear understanding of where you stand.",
        "Create a budget: Develop a realistic spending plan that aligns with your financial goals and helps you track your income and expenses.",
        "Educate yourself: Learn about different financial strategies and tools that can help you manage and grow your money.",
        "Stay committed: Maintain a positive mindset and stay focused on your financial goals while listening to the subliminal affirmations."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you will find yourself becoming more empowered and confident in your ability to take control of your financial future. Remember, creating a sustainable financial plan is a journey, and with dedication and consistency, you can build a solid foundation for a prosperous future. So take a moment to set your intentions and let's begin this transformative experience together."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Sustainable Financials and Financial Plan subliminal! By focusing on your financial future and developing effective strategies for managing your finances, you've taken a significant step towards creating a sustainable financial life. It's important to reflect on your experience and continue your journey towards financial well-being."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Review your financial goals: Take time to evaluate and refine your short-term and long-term financial goals.",
        "Create a budget: Develop a comprehensive budget that aligns with your goals and helps you track your expenses.",
        "Diversify your income: Consider exploring multiple streams of income to increase your financial stability.",
        "Educate yourself on personal finance: Continuously learn about financial management strategies and investment options available to you."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, building a sustainable financial future requires ongoing effort and a commitment to making sound financial decisions. Embrace the opportunities to grow your financial knowledge and take practical steps towards achieving your desired financial outcomes. Stay focused, stay motivated, and enjoy the journey to financial empowerment!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What is a financial plan and why is it important?`, 
    answer: `A financial plan is a comprehensive document that outlines your financial goals and the strategies you will use to achieve them. It includes budgeting, saving, investing, and debt management. Having a financial plan is important because it provides a roadmap for your financial future, helps you stay focused on your goals, and enables you to make informed decisions about your money.`,
    open: false
  },
  { 
    question: `How do I create a financial plan?`, 
    answer: `Creating a financial plan involves several steps. Start by setting clear financial goals, both short-term and long-term. Next, assess your current financial situation, including your income, expenses, debts, and assets. Create a budget that outlines your income and expenses, and allocate funds towards your goals. Develop a strategy for saving and investing, and consider factors such as risk tolerance and time horizon. Regularly review and adjust your financial plan as needed.`,
    open: false 
  },
  { 
    question: `How can I manage my finances more effectively?`, 
    answer: `Managing your finances effectively involves several key strategies. Start by creating a budget to track your income and expenses. This will help you identify areas where you can reduce expenses and save more. Establish an emergency fund to cover unexpected expenses and build a cushion for financial stability. Pay off high-interest debt as quickly as possible to save on interest payments. Automate your saving and bill payments to ensure consistency. Finally, educate yourself about personal finance and seek professional advice if needed.`,
    open: false 
  },
  { 
    question: `What are some common financial mistakes to avoid?`, 
    answer: `There are several common financial mistakes to avoid. Avoid living beyond your means by spending more than you earn. Do not neglect saving for retirement, as time is a valuable asset for growing your wealth. Avoid excessive debt and high-interest credit cards. Do not overlook the importance of an emergency fund to protect yourself from unexpected expenses. Finally, avoid making impulsive investment decisions without proper research and consultation.`,
    open: false 
  },
  { 
    question: `How can I improve my financial literacy?`, 
    answer: `Improving financial literacy involves educating yourself about personal finance concepts and strategies. Start by reading books and online resources about personal finance. Take courses or attend workshops on topics such as budgeting, investing, and retirement planning. Follow reputable financial experts and blogs for ongoing insights and tips. Finally, practice good financial habits and learn from your own experiences.`,
    open: false 
  },
  { 
    question: `Is it necessary to consult a financial advisor?`, 
    answer: `While it is not necessary to consult a financial advisor, it can be beneficial, especially if you have complex financial needs or goals. A financial advisor can provide expertise and guidance tailored to your specific situation. They can help you create a personalized financial plan, make informed investment decisions, and navigate any financial challenges you may encounter. However, it is essential to choose a qualified and trustworthy advisor.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>