<template>
  <div class="my-5">
    <h2 class="mx-8 my-2">Subscriptions</h2>
    <v-row class="mx-4">
      <v-col>
        <v-chip-group v-model="subscriptionid" column>
          <v-chip
            small
            filter
            outlined
            class="mx-1 my-1"
            v-for="(subscription, index) in subscriptions"
            :key="index"
          >
            {{ subscription.name }}
          </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      subscriptionid: 0,
    };
  },
  computed: {
    isRouteTrack() {
      return this.$route.path == "/user/track";
    },
    subscriptions() {
      return this.$store.state.subscription.data;
    },
  },
  watch: {
    subscriptionid(val) {
      this.$store.dispatch("subscription/getId", this.subscriptions[val].id);
    },
  },
};
</script>
