import { render, staticRenderFns } from "./357-housework-motivation.vue?vue&type=template&id=dde2ab1c&scoped=true&"
import script from "./357-housework-motivation.vue?vue&type=script&lang=js&"
export * from "./357-housework-motivation.vue?vue&type=script&lang=js&"
import style0 from "./357-housework-motivation.vue?vue&type=style&index=0&id=dde2ab1c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dde2ab1c",
  null
  
)

export default component.exports