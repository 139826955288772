<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on the journey of swordsmanship requires dedication, practice, and a strong mindset. This subliminal is designed to enhance your training and help you master the art of swordsmanship."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support your training:'
    },
    {
      type: 'list',
      items: [
        "Enhancing focus, concentration, and mental clarity for precise sword techniques.",
        "Developing agility, speed, and coordination to execute swift and accurate movements.",
        "Boosting confidence in your swordsmanship skills and abilities.",
        "Improving discipline and persistence to overcome challenges and plateaus in your training.",
        "Promoting a state of calmness and resilience during combat or duels.",
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you may notice improvements in your swordsmanship skills and overall mastery. Your mind will become more aligned with the principles of swordsmanship, allowing you to execute techniques with greater precision and finesse. Remember, becoming a skilled swordsman takes time, practice, and dedication, but with this subliminal, you can enhance your training journey."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on the journey to become a skilled swordsman requires dedication, focus, and a strong mindset. This subliminal is designed to support your training and help you master the techniques of swordsmanship. Before you begin, consider these preparation tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Clarify your goals and objectives in your swordsmanship training. Define what you want to achieve and visualize yourself successfully mastering the techniques.",
        "Create a training schedule: Establish a consistent practice routine to ensure regular improvement. Outline specific training sessions and allocate time for physical training, mental focus exercises, and studying the art of swordsmanship.",
        "Prepare your training environment: Set up a safe and dedicated space for your practice. Ensure you have the necessary equipment, such as a practice sword and protective gear, if applicable.",
        "Stay committed and disciplined: Keep your motivation high and commit to practicing regularly. Embrace the challenges and remain persistent in your pursuit of mastery."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to enhance your swordsmanship training by working on your mindset and subconscious beliefs. Through consistent listening, you may experience increased focus, mental clarity, and a greater connection with the art of swordsmanship. Embrace the journey, stay dedicated, and let's begin our quest to become a skilled swordsman."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Swordsmanship Training and Mastery program! You have taken the first step towards becoming a skilled swordsman and mastering the art of swordsmanship. As you reflect on your journey so far, remember that mastery comes through consistent practice and dedication."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice regularly: Set aside time for daily or weekly swordsmanship training sessions to hone your skills.",
        "Review your techniques: Continuously evaluate your form and technique, seeking ways to improve and refine your movements.",
        "Seek guidance: Consider finding a mentor or joining a swordsmanship group to learn from experienced practitioners.",
        "Stay disciplined: Cultivate discipline and dedication in your training, pushing yourself to achieve new levels of mastery."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember that becoming a skilled swordsman takes time and effort. Embrace the journey, stay committed to your training, and enjoy the progress you make along the way. Your dedication and passion for swordsmanship will lead you to the mastery you seek. Best of luck in your training!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How long does it take to become a skilled swordsman?`, 
    answer: `The time it takes to become a skilled swordsman can vary depending on various factors, including your dedication, discipline, and natural abilities. It is a journey that requires consistent practice, training, and refinement of your techniques. While some may show progress sooner than others, continuous learning and experience are key to mastering swordsmanship. Success in mastering swordsmanship ultimately depends on your commitment and the quality of your training.`,
    open: false
  },
  { 
    question: `Do I need any prior experience to start learning swordsmanship?`, 
    answer: `No prior experience is necessary to start learning swordsmanship. Beginner programs are designed to introduce you to the foundations of swordsmanship, including basic techniques, footwork, and principles. With consistent practice and the guidance of experienced instructors, you can progress from a beginner to more advanced levels over time.`,
    open: false 
  },
  { 
    question: `What equipment or gear do I need for swordsmanship training?`, 
    answer: `The specific equipment or gear required for swordsmanship training can vary depending on the style or discipline you choose to study. However, some common items may include a practice sword (e.g., wooden or synthetic), protective gear (e.g., mask, gloves, and chest protector), and appropriate training attire. It is best to consult with your instructor or mentor to determine the specific gear required for your training.`,
    open: false 
  },
  { 
    question: `Is swordsmanship training physically demanding?`, 
    answer: `Yes, swordsmanship training can be physically demanding. It requires strength, agility, coordination, and endurance. Regular physical training and conditioning outside of class can contribute to your overall performance. However, it is important to approach training at your own pace and gradually build your physical abilities to prevent injuries and improve your skills over time.`,
    open: false 
  },
  { 
    question: `Can I learn swordsmanship online?`, 
    answer: `Yes, it is possible to learn swordsmanship online. Many reputable instructors and schools offer online courses or tutorials that provide instruction, demonstrations, and guidance for learning swordsmanship remotely. However, it is essential to ensure that you choose a reliable and experienced instructor or program to maximize the effectiveness and safety of your training.`,
    open: false 
  },
  { 
    question: `Are there any risks or dangers involved in swordsmanship training?`, 
    answer: `Like any physical activity, swordsmanship training carries some inherent risks. It involves working with potentially dangerous weapons and techniques. Therefore, it is crucial to receive proper training from reputable instructors, follow safety protocols, and use appropriate protective gear. Listening to your body, practicing control, and gradually progressing in difficulty can help minimize the risk of injuries during training.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>