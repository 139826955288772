<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Are you looking to enhance your brain's performance and unlock your full cognitive potential? This subliminal can help you on your journey towards improved productivity, focus, and mental clarity."
    },
    {
      type: 'heading',
      text: 'Key features of this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Boosting your brain's ability to process information quickly and efficiently.",
        "Enhancing memory retention and recall for improved learning and knowledge retention.",
        "Increasing mental alertness and concentration, enabling better focus and productivity.",
        "Supporting brain health and neuroplasticity to promote long-term cognitive improvements.",
        "Boosting motivation and drive for achieving your goals and taking on intellectual challenges."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can rewire your brain to operate at a higher level, making it easier to absorb, process, and recall information. It's important to note that while this subliminal can be a powerful tool, it should be used in conjunction with a healthy lifestyle and practices that promote overall brain health. With consistent use, you can unlock your brain's potential and experience significant improvements in your cognitive abilities."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Are you ready to unlock your brain's full potential and enhance your cognitive abilities? This subliminal is designed to help you improve your brain performance, boosting productivity, focus, and overall mental function. Before you start listening, here are some tips to maximize your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a conducive environment: Find a quiet and comfortable space where you can focus without distractions.",
        "Set clear goals: Identify what areas of brain performance you want to enhance, such as memory, concentration, creativity, or problem-solving.",
        "Believe in your potential: Embrace a growth mindset and believe that you have the ability to improve and enhance your cognitive abilities.",
        "Maintain consistency: Make listening to the subliminal affirmations a regular part of your routine to maximize the benefits.",
        "Stay hydrated and nourished: Proper hydration and nutrition are essential for optimal brain function, so ensure you're taking care of your body's needs."
      ]
    },
    {
      type: 'paragraph',
      text: "By committing to regular listening and incorporating these preparation tips, you can tap into your brain's incredible capabilities and experience enhanced performance in various areas of your life. Get ready to unlock your full potential and embark on a journey of cognitive growth and improvement!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Brain Performance Enhancement session! By investing in your brain's performance and cognitive abilities, you're on your way to unlocking new levels of productivity and focus. Now, it's time to reflect on your experience and implement strategies to continue your growth journey."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your progress: Take some time to assess any positive changes you've noticed in your cognitive abilities.",
        "Practice brain exercises: Engage in activities like puzzles, reading, or learning new skills to continue challenging and stimulating your brain.",
        "Establish a focused work environment: Minimize distractions and create a workspace that promotes concentration and productivity.",
        "Prioritize rest and recovery: Take regular breaks, practice relaxation techniques, and ensure you're getting adequate sleep to support your brain's optimal performance."
      ]
    },
    {
      type: 'paragraph',
      text: "Enhancing brain performance is an ongoing process that requires consistent effort and dedication. Embrace your newfound abilities and continue to prioritize activities that support brain health. Remember, every small step you take towards boosting your cognitive abilities is a step towards unlocking your full potential. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  {
    question: `How does the Brain Performance Enhancement subliminal work?`,
    answer: `The Brain Performance Enhancement subliminal uses carefully crafted subliminal messages to target your subconscious mind and promote enhanced brain function. By listening to the subliminal regularly, you can reprogram your thought patterns and optimize your cognitive abilities, leading to improved productivity, focus, and mental performance.`,
    open: false
  },
  {
    question: `How long does it take to see results from the Brain Performance Enhancement subliminal?`,
    answer: `Results can vary depending on the individual and their unique circumstances. Some people may notice improvements in their cognitive abilities soon after starting to listen to the subliminal, while others may require more time and consistent listening. We recommend listening daily and consistently to maximize the benefits.`,
    open: false
  },
  {
    question: `Can the Brain Performance Enhancement subliminal increase my IQ?`,
    answer: `While the Brain Performance Enhancement subliminal can optimize your cognitive abilities and improve mental performance, it is important to understand that intelligence is a complex trait influenced by various factors. The subliminal can enhance your brain's potential and help you unlock hidden abilities, but it may not directly increase your IQ.`,
    open: false
  },
  {
    question: `Are there any side effects of listening to the Brain Performance Enhancement subliminal?`,
    answer: `No, there are no known side effects associated with listening to the Brain Performance Enhancement subliminal. However, if you have any concerns about your brain health or any underlying conditions, it is recommended to consult with a healthcare professional.`,
    open: false
  },
  {
    question: `Can the Brain Performance Enhancement subliminal be used by anyone?`,
    answer: `The Brain Performance Enhancement subliminal is designed for adult use. It may not be suitable for individuals under the age of 18 or those with certain medical conditions. If you have any concerns or specific health conditions, please consult with a healthcare professional before listening to the subliminal.`,
    open: false
  },
  {
    question: `How can I maximize the effectiveness of the Brain Performance Enhancement subliminal?`,
    answer: `To maximize the effectiveness of the Brain Performance Enhancement subliminal, it is important to listen to it consistently and in a distraction-free environment. Setting specific goals and incorporating other brain-boosting activities, such as regular exercise, healthy nutrition, and mental exercises like puzzles or meditation, can also enhance the subliminal's effects.`,
    open: false
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>