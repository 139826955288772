import Vue from "vue";
import App from "./App.vue";
import store from "./store/index";
import router from "./router/index";
import vuetify from "./plugins/vuetify";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/en";

import VueSplide from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/splide.min.css";

import '@/assets/css/style.css'
import '@/assets/css/bootstrap.min.css'

import '@/assets/js/script.js'
import '@/assets/js/bootstrap.bundle.min.js'

Vue.use(ElementUI, { locale });
Vue.use(VueSplide);

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
