export const audioDefaultImage = "default_audio.png";
export const userDefaultImage = "default_account.png";
export const defaultImage = "default_photo.jpg";
export const url = "https://prod.v2.magusaudio.com";
//export const url = "https://dev.v2.magusaudio.com";

export const filterNameById = (data) => {
  return data.map((item) => item.name).join(", ");
};

export const getAltImage = (event) => {
  event.target.src = userDefaultImage;
};

export const convertToTime = (seconds) => {
  var date = new Date(0);
  date.setSeconds(seconds); // specify value for SECONDS here
  return date.toISOString().substring(11, 19);
};

export const convertToShortTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor(seconds / 60);

  let second = "";
  if (seconds % 60 > 60) {
    second = " " + (seconds % 60) + " sec";
  }

  if (hours > 0) {
    return hours + " hr " + minutes + " min" + second;
  } else {
    if (minutes == 1) {
      return minutes + " minute" + second;
    } else {
      return minutes + " minutes" + second;
    }
  }
};

export const parseDate = (data) => {
  const fulldate = new Date(data);

  const year = fulldate.getFullYear();
  const date = fulldate.getDate();
  const month = fulldate.getMonth() + 1;

  return year + "-" + month + "-" + date + " 23:59:59";
};

export function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

export function formatDate(date) {
  return [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join("-");
}

export function formatMonth(date) {
  return [date.getFullYear(), padTo2Digits(date.getMonth() + 1)].join("-");
}

export const getMessage = (element, data) => {
  const { success, message } = data;
  if (success) {
    element.$message.success(message);
  } else {
    element.$message.error(message);
  }
};

export const getDuration = (file, duration) => {
  var content = file.raw;

  var url = URL.createObjectURL(content);
  var audioElement = new Audio(url);

  audioElement.addEventListener("loadedmetadata", (_event) => {
    duration.push(parseInt(audioElement.duration));
  });
};

export const checkUrl = (data) => {
  const { route, router, tempname } = data;
  const query = Object.keys(route.query);
  if (localStorage.getItem(tempname) != null) {
    try {
      const temp = atob(localStorage.getItem(tempname));
      const json = JSON.parse(temp);

      const date1 = new Date();
      const date2 = new Date(json.time_created);
      const time_diff = date1.getTime() - date2.getTime();

      var minutes = Math.floor(time_diff / 60000);

      if (minutes >= 5) {
        alert("Session Expired.");
        localStorage.removeItem(tempname);
      }
    } catch (e) {
      if (query.length != 0) {
        router.replace({ query: null });
      }
    }
  }
};

// check query params type in login page if user or admin
export const loginQueryParams = function (data) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get("type");
};

export const codeQueryParams = function (data) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get("code");
}; 

export const periodQueryParams = function (data) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get("period");
};

export const amountQueryParams = function (data) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get("amount");
};

export const promoQueryParams = function (data) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get("promo");
};

export const generateToken = function (length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};
