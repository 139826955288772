<template>
  <v-dialog id="dialog" persistent v-model="dialog" :max-width="width" style="overflow-x: hidden !important">
    <v-card id="card-dialog">
      <v-card-title class="d-flex align-self-center px-4">
        <v-icon @click="closeDialog">mdi-chevron-left</v-icon>
        <v-spacer></v-spacer>
        <p v-if="button == true" class="mb-0" @click="save" style="color: #427AB3; cursor: pointer;">Save</p>
      </v-card-title>

      <v-divider class="mb-5"></v-divider>

      <v-card-text style="padding: 0 15px">
        <slot></slot>
      </v-card-text>

      <v-card-actions>
        <slot name="action"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean
    },
    button: {
      type: Boolean
    },
    title: {
      type: String
    },
    width: {
      type: Number
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog', false)
    },
    save() {
      this.$emit('save')
    }
  }
}
</script>
