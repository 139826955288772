<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "The Law of Attraction Maximizer subliminal is designed to help you tap into the universal energy of attraction and manifest your desired outcomes. By aligning your thoughts, emotions, and beliefs with what you want to attract, you can create a powerful magnetic force that draws abundance and success into your life."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help you:"
    },
    {
      type: 'list',
      items: [
        "By rewiring your subconscious mind, it helps you release any limiting beliefs or negative thought patterns that may be blocking your manifestation process.",
        "It aligns your thoughts and emotions with what you want to attract, amplifying your focus and intention.",
        "It enhances your ability to visualize and feel as if your desires have already been manifested, increasing the vibrational frequency necessary for manifestation.",
        "It aids in developing a mindset of gratitude, appreciation, and abundance, attracting more of what you desire into your life.",
        "It supports you in taking inspired action towards your goals, guiding you towards opportunities and synchronicities that align with your manifestations."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can activate and strengthen your manifestation abilities, allowing you to create the life you desire. Remember, the Law of Attraction works in conjunction with your actions, so inspired and aligned action is key to manifesting your goals."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Unlocking the full potential of the Law of Attraction to manifest abundance and success in your life requires a mindset shift. This subliminal is designed to support your journey towards maximizing the Law of Attraction. Before you start listening, consider following these tips for a more effective experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your desires: Take some time to reflect on the specific outcomes you want to manifest and focus on them during your listening sessions.",
        "Believe in yourself: Cultivate a deep-rooted belief in your innate power to create and attract what you desire.",
        "Practice gratitude: Shift your focus to gratitude for what you already have, as this positive energy can attract more abundance into your life.",
        "Visualize your desired reality: Utilize your imagination during the subliminal session to vividly envision yourself living the life you desire, feeling the emotions associated with it."
      ]
    },
    {
      type: 'paragraph',
      text: "By preparing your mind and aligning your intentions, you can enhance the effectiveness of this subliminal in helping you manifest your desires. Embrace the journey of discovering your true potential and let the Law of Attraction work its magic in bringing abundance and success into your life."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Law of Attraction Maximizer subliminal! By focusing on harnessing the power of the Law of Attraction, you have taken a significant step towards increasing abundance, success, and manifestation in your life. It's important to reflect on your experience and continue your personal growth and transformation."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Visualize your goals: Spend time each day visualizing your desired outcomes and feeling the emotions of already having them.",
        "Practice gratitude: Cultivate gratitude for the abundance already present in your life to attract more positivity.",
        "Take inspired action: Listen to your intuition and take practical steps towards your goals, aligned with the messages you received during the session.",
        "Maintain a positive mindset: Continuously choose positive thoughts and affirmations to reinforce your belief in the Law of Attraction."
      ]
    },
    {
      type: 'paragraph',
      text: "Harnessing the power of the Law of Attraction is a continuous process that requires commitment and consistency. Trust in the universe and the power of your thoughts, and know that you have the ability to manifest the life you desire. Embrace the journey, stay aligned with your goals, and watch the abundance flow into your life. You've got this!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How long does it take to see results with the Law of Attraction Maximizer subliminal?`, 
    answer: `Results can vary from person to person, as it depends on factors such as belief systems, mindset, and consistency. Some individuals may notice positive changes almost immediately, while others may require more time and consistent listening to see noticeable results. We recommend listening to the subliminal daily and consistently for at least 21 days to allow the affirmations to reprogram your subconscious mind.`,
    open: false
  },
  { 
    question: `Can the Law of Attraction Maximizer subliminal help me attract specific things or situations?`, 
    answer: `Yes, the Law of Attraction Maximizer subliminal is designed to help you align your thoughts, beliefs, and energy with your desired manifestations. By focusing on your goals, visualizing success, and maintaining a positive mindset, you can increase your chances of attracting specific things or situations. However, it is important to remember that the Law of Attraction works in tandem with your actions and efforts, so taking inspired action is also essential.`,
    open: false 
  },
  { 
    question: `How can I amplify the effectiveness of the Law of Attraction Maximizer subliminal?`, 
    answer: `You can enhance the effectiveness of the subliminal by practicing gratitude, visualization, and positive affirmations on a daily basis. Setting clear and specific goals, maintaining a positive mindset, and taking inspired action towards your desired manifestations can also amplify the Law of Attraction in your life. It is also helpful to believe in the process and trust that the universe will deliver what is in your highest good.`,
    open: false 
  },
  { 
    question: `Is it necessary to listen to the Law of Attraction Maximizer subliminal for a specific amount of time each day?`, 
    answer: `While there is no set amount of time required to listen to the subliminal, consistency is key. We recommend setting aside dedicated time each day to listen to the subliminal, whether it's in the morning, during meditation, or before bed. Consistent exposure to the positive affirmations will help reinforce and reprogram your subconscious mind, increasing the effectiveness of the Law of Attraction in your life.`,
    open: false 
  },
  { 
    question: `Can the Law of Attraction Maximizer subliminal work for everyone?`, 
    answer: `Yes, the Law of Attraction Maximizer subliminal is designed to be effective for individuals who are open to the principles of the Law of Attraction and are willing to take inspired action towards their goals. However, the level of success and manifestations can vary depending on factors such as belief systems, mindset, and consistency.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects from listening to the Law of Attraction Maximizer subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Law of Attraction Maximizer subliminal. However, if you have any concerns about the Law of Attraction or its principles, we recommend seeking guidance from a qualified practitioner or expert in the field.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>