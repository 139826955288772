<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you struggle with getting deep and restful sleep, this subliminal can be a valuable tool in your sleep routine. By using guided training techniques, it helps induce a state of deep relaxation, allowing you to achieve a more restorative sleep."
    },
    {
      type: 'heading',
      text: 'Key features of this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Guided relaxation techniques that promote a sense of calm and tranquility before sleep.",
        "Soft, soothing background music to create a peaceful and sleep-inducing atmosphere.",
        "Subliminal affirmations targeting the subconscious mind to encourage deep and restful sleep.",
        "Gradual transition into a state of deep sleep, allowing your body and mind to rejuvenate.",
        "Gentle waking cues that help you transition back to wakefulness feeling refreshed and energized."
      ]
    },
    {
      type: 'paragraph',
      text: "With regular use of this subliminal, you can train your mind and body to enter a deep sleep state more easily. As a result, you may experience improved sleep quality, reduced sleep disturbances, and an overall sense of rejuvenation upon waking. Remember to use this subliminal consistently and create a peaceful sleep environment to maximize its benefits."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Achieving deep and restful sleep is crucial for your overall well-being, but it can be challenging to achieve consistently. This guided training is designed to help you enter a state of deep sleep, allowing your body and mind to recharge for the day ahead. Before you begin, consider implementing these tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a bedtime routine: Establish a consistent routine that signals to your body it’s time to wind down and prepare for sleep.",
        "Create a comfortable sleep environment: Ensure your bedroom is free from distractions, with a comfortable mattress, pillows, and appropriate room temperature.",
        "Limit screen time: Minimize exposure to electronic devices before sleep, as the blue light can disrupt your sleep patterns.",
        "Set an intention: Mentally prepare yourself to enter a deep and restful sleep state while listening to the guided training."
      ]
    },
    {
      type: 'paragraph',
      text: "As you embark on this deep sleep training, know that it is an investment in your well-being. By consistently practicing these techniques, you can cultivate healthier sleep patterns and wake up feeling refreshed and rejuvenated. Take a moment to set your intention, find a quiet and comfortable space, and let the journey towards deep, restful sleep begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Deep Sleep Training session! By dedicating time to improve the quality of your sleep, you've taken an important step towards feeling more refreshed and rejuvenated in your daily life. Now, it's time to reflect on your experience and continue your journey towards better sleep habits."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your sleep patterns: Consider keeping a sleep journal to track your progress and note any changes you experience.",
        "Create a bedtime routine: Establish a relaxing routine before bed, such as reading or practicing gentle stretches.",
        "Optimize your sleep environment: Ensure your bedroom is cool, dark, and quiet to create an ideal sleep environment.",
        "Practice relaxation techniques: Try deep breathing exercises or mindfulness meditation to ease your mind before sleep."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, improving the quality of your sleep is a continuous journey. Be patient with yourself as you make adjustments and embrace the practices that promote deep and restful sleep. With every night of rejuvenating rest, you'll awaken feeling energized and ready to face the day ahead. Sleep well!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  {
    question: `How can the Deep Sleep Training audio help me achieve deep and restful sleep?`,
    answer: `The Deep Sleep Training audio utilizes guided training techniques to help you relax your mind and body, leading to a state of deep and restful sleep. By following the instructions and allowing yourself to fully engage with the audio, you can experience improved sleep quality, reduced anxiety, and a more refreshed awakening.`,
    open: false
  },
  {
    question: `How long should I listen to the Deep Sleep Training audio to see results?`,
    answer: `Results may vary from person to person, but many users report experiencing positive effects after just a few listens. For best results, we recommend incorporating the Deep Sleep Training audio into your nightly routine and listening to it consistently for at least a few weeks to allow your mind and body to adjust to the relaxation techniques.`,
    open: false
  },
  {
    question: `Can the Deep Sleep Training audio be used by individuals with insomnia?`,
    answer: `Yes, the Deep Sleep Training audio can be beneficial for individuals with insomnia. The guided training techniques and relaxation prompts can help calm racing thoughts and create a peaceful environment for sleep. However, if you have severe or chronic insomnia, we recommend consulting with a healthcare professional for a comprehensive treatment plan.`,
    open: false
  },
  {
    question: `Is it safe to use the Deep Sleep Training audio every night?`,
    answer: `Yes, it is safe to use the Deep Sleep Training audio every night. The audio is designed to promote relaxation and induce deep sleep without any known risks or side effects. Incorporating it into your nightly routine can help create a consistent and restful sleep environment.`,
    open: false
  },
  {
    question: `Can the Deep Sleep Training audio replace sleep medication?`,
    answer: `The Deep Sleep Training audio is not intended to replace sleep medication prescribed by a healthcare professional. It is designed to complement good sleep hygiene practices and promote relaxation. If you are taking sleep medication, we recommend consulting with your healthcare provider before making any changes to your treatment plan.`,
    open: false
  },
  {
    question: `Will I become dependent on the Deep Sleep Training audio to fall asleep?`,
    answer: `No, the goal of the Deep Sleep Training audio is to help you develop healthier sleep patterns and provide relaxation techniques to induce deep sleep. It is not intended to create dependency. Over time, you may find that you are able to fall asleep more easily without relying on the audio.`,
    open: false
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>