<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Welcome to the world of Yaw-Yan Fervilleon, a dynamic and versatile martial arts system that will transform you into a skilled and confident practitioner. This training program is designed to guide you through the journey of learning and mastering the art of Yaw-Yan Fervilleon."
    },
    {
      type: 'heading',
      text: 'Key Features of this training program:'
    },
    {
      type: 'list',
      items: [
        "Comprehensive instruction on strikes, kicks, grappling, and weapons techniques.",
        "Progressive training modules that cater to all skill levels, from beginners to advanced practitioners.",
        "Development of strength, agility, and flexibility through targeted exercises and drills.",
        "Focus on self-defense skills and practical applications of Yaw-Yan Fervilleon techniques.",
        "Access to experienced instructors and a supportive community to enhance your learning journey."
      ]
    },
    {
      type: 'paragraph',
      text: "Through this training program, you will not only acquire a deep understanding of Yaw-Yan Fervilleon but also develop physical and mental discipline. Whether you are a beginner eager to explore the world of martial arts or an experienced practitioner looking to refine your skills, this program will empower you to reach your full potential. Take the first step towards mastery today!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a journey to learn and master Yaw-Yan Fervilleon requires dedication and commitment. This subliminal is designed to support your training and help you achieve your goals in this Filipino martial arts system. Before you begin, consider these preparatory tips to enhance your training experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your training goals: Clearly define what you want to achieve, such as improving your strikes, kicks, grappling skills, or weapon techniques.",
        "Create a training schedule: Establish a consistent routine that allows for regular practice and progress.",
        "Stay focused and disciplined: Develop mental resilience, focusing on perseverance and dedication to push through challenges.",
        "Create a supportive environment: Surround yourself with fellow practitioners, mentors, or training partners who can provide encouragement and motivation."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal is designed to enhance your training by tapping into your subconscious mind. By consistently listening and following through on your preparation tips, you can accelerate your progress and achieve mastery in Yaw-Yan Fervilleon. Get ready to embrace the power within you and embark on an empowering journey of self-improvement through martial arts."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Yaw-Yan Fervilleon Martial Arts Training and Mastery program! By dedicating yourself to learning this powerful Filipino martial arts system, you are embarking on a journey towards developing strength, agility, and self-defense skills. Now, it's time to reflect on your experience and continue your training to enhance your mastery of Yaw-Yan Fervilleon."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your progress: Keep a training journal to document your journey, note your improvements, and set future goals.",
        "Practice regularly: Dedicate time each day to practice the strikes, kicks, grappling, and weapons techniques you have learned.",
        "Train with a partner: Find a training partner to practice techniques and drills, improving your coordination and timing.",
        "Stay committed to self-improvement: Continuously challenge yourself to learn and grow in the art of Yaw-Yan Fervilleon."
      ]
    },
    {
      type: 'paragraph',
      text: "Mastery of Yaw-Yan Fervilleon requires dedication, discipline, and perseverance. Embrace the challenges and continue your training with passion. With each training session, you are strengthening not only your body but also your mind. Keep pushing your limits, and soon you will master the art of Yaw-Yan Fervilleon. Train hard and enjoy your journey!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What is Yaw-Yan Fervilleon?`, 
    answer: `Yaw-Yan Fervilleon is a Filipino martial arts system that incorporates various striking, kicking, grappling, and weapons techniques. It was developed by Grandmaster Napoleon Fernandez and is known for its speed, precision, and dynamic movements.`,
    open: false
  },
  { 
    question: `What are the benefits of training in Yaw-Yan Fervilleon?`, 
    answer: `Training in Yaw-Yan Fervilleon offers numerous benefits, including improved physical fitness, increased strength and agility, enhanced coordination and balance, and heightened self-defense skills. Additionally, it promotes discipline, focus, and mental fortitude.`,
    open: false 
  },
  { 
    question: `Do I need any prior martial arts experience to start training in Yaw-Yan Fervilleon?`, 
    answer: `No prior martial arts experience is necessary to start training in Yaw-Yan Fervilleon. The system is designed to be accessible to individuals of all skill levels, from beginners to advanced practitioners. Our instructors will guide you through the training process and help you progress at your own pace.`,
    open: false 
  },
  { 
    question: `Is Yaw-Yan Fervilleon suitable for self-defense?`, 
    answer: `Yes, Yaw-Yan Fervilleon is highly effective for self-defense. The system emphasizes practical techniques that can be used in real-life situations, teaching students how to defend themselves against various attacks. Through training, students develop the necessary skills and mindset to protect themselves and others if needed.`,
    open: false 
  },
  { 
    question: `What can I expect from Yaw-Yan Fervilleon training sessions?`, 
    answer: `Yaw-Yan Fervilleon training sessions are intense and challenging. They typically involve a combination of conditioning exercises, technique drills, partner drills, and sparring. The training environment is disciplined, but also supportive and collaborative. Students can expect to learn and refine techniques, improve their fitness levels, and develop a deeper understanding of the martial art.`,
    open: false 
  },
  { 
    question: `Is Yaw-Yan Fervilleon suitable for children?`, 
    answer: `Yaw-Yan Fervilleon training can be suitable for children, depending on their age and maturity level. We offer age-appropriate classes and prioritize safety and proper technique. Our instructors ensure that children can learn and train in a controlled and supervised environment. However, it is recommended to consult with our instructors to determine if Yaw-Yan Fervilleon is suitable for your child.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>