import request from "@/utils/request";

export const getMobileVersions = (data) => {
  return request.get("/api/mobile/version").then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const getMobileVersionFilters = (data) => {
  return request.post("/api/mobile/version/filter", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const addMobileVersions = (data) => {
  return request.post("/api/mobile/version", data).then((res) => {
    return res.data;
  });
};

export const updateMobileVersions = (data) => {
  return request.put(`/api/mobile/version/${data.id}`, data).then((res) => {
    return res.data;
  });
};

export const updateToggle = (data) => {
  return request.post("/api/mobile/version/toggle", data).then((res) => {
    return res.data;
  });
};

export const deleteMobileVersions = (data) => {
  return request.delete(`/api/mobile/version/${data.id}`).then((res) => {
    return res.data;
  });
};
