<template>
  <Dialog :dialog="dialog" :width="500" title="Forgot Password" @closeDialog="closeDialog">
    <div class="text-center">
        <div class="wraps">
          <div class="inputs">
            <h5 v-show="email!=''" class="h51">
              <span>Email</span>
              <span class="input-invalid">{{email_valid}}</span>
            </h5>
            <v-text-field flat solo placeholder="E-mail Address"
              v-model="email" class="shrink"></v-text-field>
          </div>
        </div>
      <div style="width: max-content; margin: auto;" class="justify-center align-center mt-5">
        <p v-show="message!=''" medium class="message">{{message}}</p>
      </div>
      <button class="button btn btn-primary btn-lg mt-0" :disabled="!isReady" @click="checkEmail()">RESET PASSWORD</button>
    </div>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialog.vue'
import { userCheckEmail, userResetPasswordEmail } from '@/api/user'
import { getMessage } from '@/utils'

export default {
  props: {
    dialog: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  components: {
    Dialog,
  },
  data: () => {
    return {
      userCheckEmail,
      userResetPasswordEmail,
      getMessage,

      email: '',
      message: '',
      email_valid: '',
      isEmailExist: false
    }
  },
  computed: {
    isReady() {
      return this.checkForm()
    },
  },
  watch: {
    email(value){
      if(value != ''){
        this.message=''
        this.validateEmail(value);
      }
    },
  },
  methods: {
    checkForm() {
      return (
        this.email != ''
      )
    },
    validateEmail(email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        this.email_valid = ''
      } else {
        this.email_valid = "Email is not valid"
      }
    },
    closeDialog(data) {
      this.$emit('closeDialog', false)
    },
    checkEmail() {
      const data = { email: this.email }
      this.userCheckEmail(data).then((res) => {
        const { success, message } = res
        if(success == true){
          const data = {
            email: this.email,
          }
          this.userResetPasswordEmail(data).then(res => {
            this.getMessage(this, res)
            this.email = ''
            this.closeDialog()
          })
        }else{
          this.message = message
          this.email = ''
        }

        setTimeout(() => {
          this.message = ''
        }, 3000)
      })
    },
  }
}
</script>

<style scoped>
div.wraps {
  border: solid 0.5px #CECECE;
  border-radius: 10px;
  flex-direction: column;
  padding-top: 1em;
}
.input-invalid {
  color: red;
  margin-left: 15px;
  font-size: 15px;
  font-weight: 600;
}
.message{
  background: #FFACAC !important; 
  color: black; 
  padding: 10px;
  font-family: 'Montserrat-Regular';
  text-align: center;
  border-radius: 10px;
  margin-left: -2px;
}
.h51 {
  text-align: left;
  margin-left: 10px;
}
</style>
