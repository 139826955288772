import request from "@/utils/request";

export const getMagus = (data) => {
  return request.post("/api/magus/search", data).then((res) => {
    return res.data;
  });
};
export const getMagusSub = (data) => {
  return request.post("/api/magus/info/search", data).then((res) => {
    return res.data;
  });
};

export const getMagusInfos = (data) => {
  return request.get("/api/magus/details").then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const getMagusInfosFilters = (data) => {
  return request.post("/api/magus/details/filter", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};
export const addMagusInfos = (data) => {
  return request.post("/api/magus/details", data).then((res) => {
    return res.data;
  });
};
export const addMagusDetailsInfos = (data) => {
  return request.post("/api/magus/info", data).then((res) => {
    return res.data;
  });
};

export const updateMagusInfos = (data) => {
  return request.put(`/api/magus/details/${data.id}`, data).then((res) => {
    return res.data;
  });
};

export const updateMagusDetailsInfos = (data) => {
  return request.put(`/api/magus/info/${data.id}`, data).then((res) => {
    return res.data;
  });
};

export const updateToggle = (data) => {
  return request.post("/api/magus/details/toggle", data).then((res) => {
    return res.data;
  });
};

export const deleteMagusInfos = (data) => {
  return request.delete(`/api/magus/details/${data.id}`).then((res) => {
    return res.data;
  });
};

export const deleteMagusDetailsInfos = (data) => {
  return request.delete(`/api/magus/info/${data.id}`).then((res) => {
    return res.data;
  });
};
