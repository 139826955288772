<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're looking to attract more money and financial prosperity into your life, this subliminal can help you shift your mindset and start manifesting abundance."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can assist you:'
    },
    {
      type: 'list',
      items: [
        "It helps reprogram your subconscious mind to believe in and attract wealth.",
        "It enhances your ability to recognize opportunities for financial growth.",
        "It boosts your confidence in managing and growing your finances.",
        "It aligns your mindset with abundance and fosters a positive relationship with money.",
        "It encourages a prosperity mindset that attracts wealth and financial success."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can gradually transform your thoughts, beliefs, and actions surrounding money. The subconscious reprogramming can guide you towards making better financial choices and attracting opportunities for wealth. Remember, attracting money requires aligning your mindset, taking inspired action, and being open to receiving abundance."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "If you're ready to shift your mindset and attract financial prosperity, this subliminal is designed to support you in manifesting abundance and wealth. Before you start listening, here are some tips to help you prepare:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your financial goals: Take a moment to reflect on your financial aspirations and identify what you want to attract into your life.",
        "Visualize abundance: Imagine yourself already experiencing financial prosperity and feeling the emotions associated with wealth.",
        "Release limiting beliefs: Let go of any negative thoughts or beliefs about money that may be holding you back.",
        "Maintain focus: Keep your intention of attracting abundance in mind while listening to the subliminal affirmations."
      ]
    },
    {
      type: 'paragraph',
      text: "By utilizing this subliminal regularly, you can tap into the power of your subconscious mind to attract greater wealth and financial freedom. Embrace this opportunity to align your thoughts and beliefs with abundance, and watch as new opportunities and financial prosperity flow into your life. Get ready to welcome abundance and let's begin the journey together!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Attract Money subliminal session! By focusing on shifting your mindset and inviting abundance and financial prosperity into your life, you have taken a significant step towards achieving a new level of wealth and financial freedom. As you reflect on your experience, it's important to remain open to the abundance that is coming your way."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Visualize your goals: Take some time to imagine yourself living in financial abundance, and feel the excitement and joy of achieving your dreams.",
        "Take inspired action: Identify practical steps you can take towards your financial goals, whether it's saving, investing, or starting a new venture.",
        "Practice gratitude: Cultivate a mindset of gratitude for the money and abundance that already exists in your life. This will attract more positivity and abundance.",
        "Surround yourself with positive influences: Engage with individuals who have a healthy relationship with money and who inspire you to achieve your financial dreams."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, attracting money and transforming your financial reality is a journey. Embrace the changes and opportunities that come your way. Stay committed to your goals, take inspired action, and trust that you are on a path to financial abundance. The universe is aligning in your favor. Embrace your new level of wealth and enjoy the journey!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Attract Money subliminal help me attract abundance and financial prosperity?`, 
    answer: `The Attract Money subliminal is designed to reprogram your subconscious mind with positive affirmations that align with abundance and financial prosperity. By consistently listening to the subliminal, you can shift your mindset to a state of abundance, attract opportunities for wealth, and develop a more positive relationship with money. It is important to combine the subliminal with practical actions, such as setting financial goals and taking steps towards them, to maximize its effectiveness.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Attract Money subliminal?`, 
    answer: `Results from using the Attract Money subliminal can vary from person to person. Some individuals may notice positive shifts in their mindset and financial situation relatively quickly, while others may require consistent listening over a longer period of time. We recommend listening to the subliminal daily and consistently to allow the affirmations to take root in your subconscious mind and manifest in your external reality.`,
    open: false 
  },
  { 
    question: `Can the Attract Money subliminal guarantee financial success?`, 
    answer: `While the Attract Money subliminal can help you shift your mindset and attract abundance, it cannot guarantee financial success on its own. It is important to combine the subliminal with practical actions, such as sound financial planning, budgeting, and taking steps towards your goals. The subliminal can be a powerful tool to complement your efforts and enhance your mindset towards wealth and prosperity.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Attract Money subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Attract Money subliminal. However, it is important to note that the subliminal is intended to supplement your efforts towards financial prosperity, not replace them. It is always advisable to exercise responsible financial management and seek professional advice when making financial decisions.`,
    open: false 
  },
  { 
    question: `Can the Attract Money subliminal help me if I have debt or financial difficulties?`, 
    answer: `Yes, the Attract Money subliminal can help you develop a more positive and abundance-oriented mindset, which can assist you in overcoming financial difficulties. By shifting your thoughts and beliefs about money, you may be more likely to take proactive steps towards resolving debt and improving your financial situation. However, it is important to combine the subliminal with practical actions, such as seeking financial guidance and implementing strategies to manage and reduce debt.`,
    open: false 
  },
  { 
    question: `Is the Attract Money subliminal suitable for everyone?`, 
    answer: `Yes, the Attract Money subliminal is suitable for everyone who wants to shift their mindset towards abundance and attract financial prosperity. Whether you are just starting your financial journey or looking to enhance your current wealth, the subliminal can help reprogram your subconscious and align your thoughts with your desired financial outcomes.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>