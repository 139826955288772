<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you've been wanting to boost your memory and enhance your cognitive abilities, this subliminal program is designed to help you do just that. By targeting your subconscious mind, it aims to improve your memory retention, focus, and overall cognitive performance."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help you:"
    },
    {
      type: 'list',
      items: [
        "Enhancing your memory capacity and recall ability.",
        "Improving your concentration and focus, making it easier to retain information.",
        "Boosting your cognitive abilities and mental clarity.",
        "Supporting better learning and information processing skills.",
        "Helping you stay sharp and mentally alert, especially as you age."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your daily routine, you can train your brain to function at its peak potential when it comes to memory and cognitive functions. As you listen regularly, you may start to notice improvements in your memory, learning abilities, and overall mental performance. Remember, consistency is key to achieving long-lasting results."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Enhancing your memory and cognitive abilities is possible with the help of this subliminal program. But before you begin, here are some tips to maximize its effectiveness:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a conducive environment: Find a quiet and comfortable space where you can listen to the subliminal program without any distractions.",
        "Set clear intentions: Clearly define your memory goals and what aspects of your cognitive abilities you wish to enhance.",
        "Focus on positive affirmations: Keep your mind open to receiving positive affirmations and suggestions for memory enhancement.",
        "Take deep breaths: Prior to starting the program, take a few deep breaths to calm your mind and prepare yourself for the session."
      ]
    },
    {
      type: 'paragraph',
      text: "By following these preparation tips, you can create an optimal mindset to absorb the subliminal messages and improve your memory and cognitive abilities. Remember to listen regularly and be patient with the process. Get ready to unlock your brain's potential and embark on a journey of memory enhancement and cognitive growth."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Memory Enhancement and Booster program! By investing time and effort into improving your memory and cognitive abilities, you have taken a significant step towards personal growth and development."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice memory techniques: Explore different memory techniques such as visualization, association, and mnemonic devices to improve your retention and recall.",
        "Challenge your brain: Engage in activities that challenge your memory, such as puzzles, brain games, and learning new skills.",
        "Stay mentally active: Continuously exercise your brain through reading, learning, and pursuing mentally stimulating hobbies.",
        "Maintain a healthy lifestyle: Prioritize good nutrition, regular exercise, and quality sleep to support optimal cognitive function."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, memory improvement is a lifelong journey. Be patient and consistent in implementing these post-listening tips. With continued practice and dedication, you can enhance your memory and unlock your cognitive potential. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Memory Enhancement and Booster program work?`, 
    answer: `The Memory Enhancement and Booster program utilizes subliminal messages to positively influence your subconscious mind. These messages are designed to enhance memory and cognitive abilities by promoting neural connections, increasing focus and concentration, and improving information retention. By consistently listening to the audio, you can reprogram your mind to optimize memory and cognitive function.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Memory Enhancement and Booster program?`, 
    answer: `Results may vary from person to person, and it may take time to notice significant improvements in memory and cognitive function. Consistency is key, so we recommend listening to the Memory Enhancement and Booster program daily for several weeks to allow the subliminal messages to make a lasting impact on your mind.`,
    open: false 
  },
  { 
    question: `Can the Memory Enhancement and Booster program help with memory problems caused by aging?`, 
    answer: `While the Memory Enhancement and Booster program is designed to improve memory and cognitive abilities, it may not specifically target memory problems related to aging. However, it can still be beneficial in enhancing overall cognitive function by promoting focus, concentration, and information retention.`,
    open: false 
  },
  { 
    question: `Are there any side effects of listening to the Memory Enhancement and Booster program?`, 
    answer: `No, there are no known side effects associated with listening to the Memory Enhancement and Booster program. This subliminal audio is safe to use, but if you have any concerns about your memory or cognitive health, it is always advisable to consult with a healthcare professional.`,
    open: false 
  },
  { 
    question: `Can the Memory Enhancement and Booster program replace studying or learning new information?`, 
    answer: `The Memory Enhancement and Booster program is not intended to replace traditional studying methods or the process of learning new information. It is designed to complement your existing study routine by enhancing memory and cognitive abilities. Regular studying and actively engaging with the material remain important for acquiring and retaining new knowledge.`,
    open: false 
  },
  { 
    question: `How can I make the Memory Enhancement and Booster program more effective?`, 
    answer: `To maximize the effectiveness of the Memory Enhancement and Booster program, it is recommended to create a conducive learning environment, practice regular relaxation techniques, and maintain a healthy lifestyle that includes a balanced diet and regular exercise. Additionally, incorporating active learning strategies into your study routine, such as summarizing information or teaching others, can also enhance memory retention and reinforcement.`,
    open: false 
  }
]
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>