<template>
  <div>
    <div class="mx-6 mt-5">
      <Card>
        <!-- <v-row>
          <v-col cols="12" sm="6" lg="6">
            <p class="mb-1">Title</p>
            <v-autocomplete solo dense hide-details clearable v-model="title" :items="playlists" item-text="title"
              item-value="title" />
          </v-col>
          <v-col cols="12" sm="6" lg="6">
            <p class="mb-1">Subscriptions</p>
            <v-autocomplete solo dense hide-details clearable v-model="subscription" :items="subscriptions"
              item-text="name" item-value="id" />
          </v-col>
        </v-row> -->
        <v-row>
          <v-col cols="12" sm="6" lg="6" v-for="search in searchs">
            <Select :title="search.title" :type="search.type" :text="search.text" :value="search.value"
              @getSelectItem="getSelectItem"></Select>
          </v-col>
        </v-row>
      </Card>
    </div>

    <Table itemkey="playlist_id" :loading="loading" :items="items" :headers="headers" :changePage="changePage" :select="selected" :total="total"
      @action="getAction" @updateLimitPage="updateLimitPage" expandtype="playlist" :showexpand="true"
      :addVisible="true" @updateSearch="updateSearch" @updateSort="updateSort" @updateVisible="updateVisible" />

    <addItemDialog v-if="dialogType == 'additem'" :data="data" :dialog="dialog" @closeDialog="closeDialog" />

    <addDialog v-if="dialogType == 'add'" :data="data" :dialog="dialog" @closeDialog="closeDialog" />

    <editDialog v-if="dialogType == 'update'" :data="data" :dialog="dialog" @closeDialog="closeDialog" />

    <deleteDialog v-if="dialogType == 'delete' || dialogType == 'deleteall' || dialogType == 'expanddelete'" :data="data"
      :type="dialogType" :dialog="dialog" :selected="selected" @selected="getSelected" @closeDialog="closeDialog" />
  </div>
</template>

<script>
import { getPlaylistFilters, updateToggle } from '@/api/playlist'
import { getAudio, playAudio, pauseAudio } from '@/utils/audio'
import { getMessage } from '@/utils'

import Card from '@/components/Card.vue'
import Table from '@/components/Admin/Table.vue'
import Select from '@/components/Admin/Select.vue'

import addDialog from './components/addDialog.vue'
import editDialog from './components/editDialog.vue'
import deleteDialog from './components/deleteDialog.vue'
import addItemDialog from './components/addItemDialog.vue'

export default {
  components: {
    Card,
    Table,
    Select,
    addDialog,
    editDialog,
    deleteDialog,
    addItemDialog
  },
  data: () => {
    return {
      getAudio,
      getMessage,
      playAudio,
      pauseAudio,

      getPlaylistFilters,
      updateToggle,

      data: {},
      dialog: false,
      dialogType: null,

      items: [],
      selected: [],
      loading: true,

      headers: [
        { text: '', value: 'cover', sortable: false },
        { text: 'Title', value: 'title', sortable: false },
        { text: 'Description', value: 'description', sortable: false },
        { text: 'Category', value: 'category_id', sortable: false },
        { text: 'Moods', value: 'moods_id', sortable: false },
        { text: 'Subscription', value: 'subscription_id', sortable: false },
        { text: 'Action', value: 'add_update_delete', sortable: false, width: '200' },
      ],

      searchs: [
        { title: 'Title', type: 'playlist', text: 'title', value: 'title' },
        { title: 'Subscription', type: 'subscription', text: 'name', value: 'id' },
      ],

      visible: null,
      sort: null,
      title: null,
      search: null,
      subscription: null,

      page: 1,
      total: 0,
      limit: 10,
      changePage: false

    }
  },
  created() {
    this.getData()
  },
  computed: {
    audio() {
      return this.$store.state.audio.data
    },
    isAudioPlaying() {
      return this.$store.state.audio.status
    },
    durationTimes() {
      return this.$store.state.audio.duration.times
    },
  },
  watch: {
    sort() {
      this.getData()
    },
    visible() {
      this.getData()
    },
    search() {
      this.getData()
    },
    subscription() {
      this.getData()
    },
    title() {
      this.getData()
    },
    durationTimes(val) {
      if (val.length > 0 && val.length == this.audio.length) {
        const duration = Math.max(...val);
        const index = val.indexOf(duration);

        this.$store.dispatch("audio/getCurrentTime", 0)
        this.$store.dispatch("audio/getDurationTime", val[index])

        this.playAudio()
      }
    },
  },
  methods: {
    getSelected(data) {
      this.selected = data
    },
    closeDialog() {
      this.dialog = false
      this.dialogType = null
      this.getData()
    },
    getAction(params) {
      this.dialog = true
      this.data = params.data
      this.dialogType = params.action
      this.selected = params.selected

      if (params.action == 'play') {
        if (this.isAudioPlaying) {
          this.pauseAudio()
        }

        this.$store.dispatch("audio/getCurrentTime", 0)
        this.getAudio(params.data, params.index)
      }

      if (params.action == 'pause') {
        this.pauseAudio(false)
      }

      if (params.action == 'additem') {
        const playlistid = params.data.playlist_id
        this.$store.dispatch('audio/getPlaylistId', playlistid)
      }

      if (params.action == 'toggle') {
        if(this.data.toggle==true){
          this.isVisible=0;
        }else{
          this.isVisible=1;
        }
        const data = {
          is_visible: this.isVisible,
          playlist_id: this.data.playlist_id,
        }
        this.updateToggle(data).then((res) => {
          this.getMessage(this, res)
          this.getData()
        })
      }
    },
    getData() {
      this.loading = true
      const data = {
        sort: this.sort,
        page: this.page,
        title: this.title,
        limit: this.limit,
        visible: this.visible,
        search: this.search,
        subscription_id: this.subscription
      }

      this.getPlaylistFilters(data).then((res) => {
        const { playlists, total } = res

        this.total = total
        this.items = playlists

        this.loading = false
      })
    },
    getSelectItem(item) {
      this.changePage = !this.changePage
      switch (item.type) {
        case 'playlist':
          this.title = item.data
          break;
        case 'subscription':
          this.subscription = item.data
          break;
      }
    },
    updateLimitPage(data) {
      const { page, limit } = data
      this.page = page
      this.limit = limit

      this.getData()
    },
    updateSearch(data) {
      this.search = data
      this.getData()
    },
    updateSort(data) {
      this.sort = data
      this.getData()
    },
    updateVisible(data) {
      this.visible = data
      this.getData()
    }
  }
}
</script>