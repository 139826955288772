<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Becoming a successful YouTuber is a dream for many, and this subliminal can help you turn that dream into a reality. By targeting your subconscious mind, it helps you learn and internalize the strategies and techniques necessary to grow your channel."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support your journey:'
    },
    {
      type: 'list',
      items: [
        "Learning effective video editing techniques and graphic design skills.",
        "Increasing your creativity and generating unique content ideas.",
        "Improving your on-camera presence, public speaking, and communication skills.",
        "Understanding YouTube analytics and utilizing SEO strategies to optimize your videos.",
        "Building a loyal audience and engaging with your viewers through comments and collaborations."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal consistently, you can empower yourself with the confidence, knowledge, and skills needed to become a successful YouTuber. Remember, it takes time and dedication to grow a channel, but with the right mindset and persistence, you can achieve your goals."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Becoming a successful YouTuber requires dedication, creativity, and a strategic approach. This subliminal program is designed to help you develop the mindset and skills necessary to grow your channel and achieve your goals. Before you start listening, consider the following tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Define what success means to you as a YouTuber, whether it's reaching a certain number of subscribers, increasing engagement, or generating income.",
        "Study successful channels: Analyze and learn from popular YouTubers in your niche, observing their content, style, and strategies.",
        "Plan your content: Develop a content strategy that aligns with your target audience's interests, offering unique and valuable content.",
        "Practice your presentation skills: Work on your on-camera presence, voice modulation, and storytelling abilities to captivate your viewers.",
        "Stay consistent: Consistency is key on YouTube, so develop a realistic upload schedule and commit to it.",
        "Embrace a growth mindset: Accept that success on YouTube takes time and effort, and be open to learning and improving along the way."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating these suggestions into your journey, along with regular listening to this subliminal program, you can enhance your chances of becoming a successful YouTuber. Remember, success is a process, so be patient and persistent in your pursuit. Get ready to unleash your creativity, connect with your audience, and build the thriving YouTube channel you envision!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Become a Successful YouTuber subliminal session! By investing your time and energy into learning the strategies and techniques for growing your YouTube channel, you're taking crucial steps towards achieving your goals and becoming a successful content creator."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a content plan: Outline your video ideas and schedule to maintain consistency.",
        "Engage with your audience: Respond to comments, ask for feedback, and build a community.",
        "Collaborate with other YouTubers: Seek opportunities to collaborate and cross-promote your channels.",
        "Learn from others: Stay updated on industry trends and study successful YouTubers to refine your skills."
      ]
    },
    {
      type: 'paragraph',
      text: "Becoming a successful YouTuber requires dedication, perseverance, and continuous learning. Embrace the journey, create valuable content, and remember to enjoy the process. With consistent effort and a genuine passion for your niche, you have the potential to thrive and make a positive impact through your YouTube channel. Best of luck on your path to success!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the "Become a Successful Youtuber" subliminal help me?`, 
    answer: `The "Become a Successful Youtuber" subliminal is designed to help you develop the mindset, confidence, and skills necessary to become a successful YouTuber. By listening to the subliminal messages, you can reprogram your subconscious mind to overcome any self-doubt, improve your on-camera presence, enhance your editing and content creation abilities, and attract more viewers and subscribers to your channel. Consistently listening to the subliminal can provide you with the motivation and inspiration to pursue your dreams of becoming a successful YouTuber.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the "Become a Successful Youtuber" subliminal?`, 
    answer: `Results may vary depending on individual factors, such as dedication, consistency, and the amount of effort you put into improving your skills and content. While some people may notice positive changes in their mindset and channel growth quickly, others may require more time and consistent listening to fully internalize the subliminal messages. We recommend listening to the subliminal daily and actively applying the strategies and techniques you learn to see the best results.`,
    open: false 
  },
  { 
    question: `Can the "Become a Successful Youtuber" subliminal guarantee success on YouTube?`, 
    answer: `While the "Become a Successful Youtuber" subliminal can help you develop the mindset, skills, and techniques necessary to become successful on YouTube, it does not guarantee instant or guaranteed success. Success on YouTube requires consistent effort, high-quality content, effective marketing strategies, and audience engagement. The subliminal can provide you with a foundation for success, but it is ultimately up to you to apply what you learn and put in the work to achieve your goals.`,
    open: false 
  },
  { 
    question: `Is it necessary to have prior experience or knowledge about YouTube to benefit from the subliminal?`, 
    answer: `No, prior experience or knowledge about YouTube is not necessary to benefit from the "Become a Successful Youtuber" subliminal. The subliminal is designed to help anyone who aspires to become a successful YouTuber, regardless of their current level of experience or knowledge. It can provide you with the necessary mindset, confidence, and skills to start and grow your YouTube channel.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the "Become a Successful Youtuber" subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the "Become a Successful Youtuber" subliminal. However, if you have any concerns about your mental health or well-being, it is important to consult with a healthcare professional. It is also essential to remember that success on YouTube requires hard work, dedication, and a long-term commitment to learning and improvement.`,
    open: false 
  },
  { 
    question: `Can the "Become a Successful Youtuber" subliminal guarantee a certain number of subscribers or views?`, 
    answer: `No, the "Become a Successful Youtuber" subliminal does not guarantee a specific number of subscribers or views on YouTube. The success of a YouTube channel depends on many factors, including the quality of content, engagement with the audience, effective marketing strategies, and consistency. The subliminal can help you develop the necessary mindset, skills, and techniques to attract more viewers and subscribers, but it is ultimately up to you to create high-quality content that resonates with your target audience.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>