<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're looking to enhance your meditation practice or improve your ability to relax and focus, this subliminal can be a valuable tool. It combines guided meditations and soothing sounds to help you achieve a deeper state of meditation."
    },
    {
      type: 'heading',
      text: 'Key features of this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Guided meditations: Listen to calming and instructive guided meditations that can aid in finding inner peace and tranquility.",
        "Soothing sounds: Experience a variety of soothing sounds, such as nature sounds or ambient music, that can promote relaxation and create a peaceful meditation environment.",
        "Enhanced focus: Train your mind to improve concentration and focus during meditation sessions, allowing you to fully immerse yourself in the practice.",
        "Deeper relaxation: Achieve a greater sense of relaxation and release stress and tension by connecting with your breath and letting go of distractions.",
        "Mindfulness training: Develop mindfulness skills that can be applied not only during meditation but also in your everyday life, helping you stay present and centered."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your meditation routine, you can enhance your practice and reap the benefits of relaxation, stress reduction, and improved focus. Whether you're a beginner or an experienced meditator, this subliminal can support your journey toward inner calmness and mindfulness."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a meditation practice can bring numerous benefits to your mind, body, and overall well-being. This subliminal is designed to enhance your meditation experience by helping you relax and focus more deeply. Before you begin, here are some suggestions to prepare:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Choose a comfortable meditation posture: Find a seated position that allows you to relax your body while maintaining alertness.",
        "Create a dedicated meditation space: Find a quiet and peaceful area where you can meditate without distractions.",
        "Set a timer: Decide on the duration of your meditation session and set a timer to maintain a consistent practice.",
        "Practice deep breathing: Take a few moments to focus on your breath, allowing your body to naturally relax and prepare for meditation."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember that the purpose of this subliminal is to enhance your meditation practice and provide a soothing environment for deep relaxation and focus. As you continue to listen regularly, you may find it easier to enter a meditative state, experience heightened relaxation, and improve your ability to concentrate. Now, take a moment to prepare yourself and let's begin this transformative journey."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Meditation Aid and Improvement session! By dedicating time to enhance your meditation practice, you've taken a significant step towards finding peace, relaxation, and focus in your life. Reflecting on your experience and committing to further growth is essential as you continue on your meditation journey."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your meditation experience: Consider journaling about any insights, emotions, or changes you've noticed.",
        "Establish a regular practice: Set aside a consistent time each day to meditate and make it a priority.",
        "Explore different techniques: Try out various guided meditations and sounds to find what resonates with you.",
        "Create a peaceful environment: Find a quiet space where you can meditate without distractions or interruptions."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, meditation is a lifelong practice that evolves over time. Embrace the journey and allow yourself to explore and grow in the present moment. As you continue on this path of self-discovery, may you find inner peace, clarity, and an increased sense of well-being. Keep meditating and nourish your soul!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Meditation Aid and Improvement subliminal enhance my meditation practice?`, 
    answer: `The Meditation Aid and Improvement subliminal is designed to help you relax, focus, and deepen your meditation practice. By listening to the subliminal regularly, you can train your mind to enter a state of relaxation more easily and maintain focus during meditation. The soothing sounds and guided meditations can help create a conducive environment for a peaceful and transformative meditation experience.`,
    open: false
  },
  { 
    question: `How long should I listen to the Meditation Aid and Improvement subliminal for optimal results?`, 
    answer: `The duration for optimal results may vary for each individual. It is recommended to listen to the Meditation Aid and Improvement subliminal consistently to fully benefit from its effects. Incorporating it into your daily meditation routine can help reinforce positive meditation habits and deepen your practice over time.`,
    open: false 
  },
  { 
    question: `Can the Meditation Aid and Improvement subliminal help with anxiety and stress reduction?`, 
    answer: `Yes, the Meditation Aid and Improvement subliminal can be beneficial for reducing anxiety and stress. Regular meditation practice can help calm the mind, promote relaxation, and develop a more positive mindset. The subliminal messages, combined with meditation, can enhance these effects and provide a holistic approach to managing anxiety and stress.`,
    open: false 
  },
  { 
    question: `What are the benefits of the guided meditations included in the Meditation Aid and Improvement subliminal?`, 
    answer: `The guided meditations included in the Meditation Aid and Improvement subliminal offer a structured approach to meditation, helping you relax, focus, and gain insights. They can guide you through specific practices, including mindfulness, visualization, and body scans, to deepen your self-awareness and spiritual connection. The soothing sounds accompanying the guided meditations further promote a peaceful and immersive meditation experience.`,
    open: false 
  },
  { 
    question: `Can I use the Meditation Aid and Improvement subliminal as a standalone meditation tool?`, 
    answer: `Yes, the Meditation Aid and Improvement subliminal can be used as a standalone meditation tool. However, it can also be complemented by other meditation techniques, such as breathwork or mantra repetition. Explore different approaches to meditation and find what works best for you to create a well-rounded practice.`,
    open: false 
  },
  { 
    question: `Is it possible to fall asleep while listening to the Meditation Aid and Improvement subliminal?`, 
    answer: `Yes, it is possible to fall asleep while listening to the Meditation Aid and Improvement subliminal, especially if you are in a relaxed state. However, for optimal results, it is recommended to listen to the subliminal during your waking hours when you are fully attentive and able to engage in meditation practice.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>