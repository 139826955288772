<template>
  <Dialog :dialog="dialog" :width="500" @closeDialog="closeDialog">
    <div v-if="isReady == true" class="mx-auto text-center">
      <input class="search" v-model="searchSub" placeholder="Search" />
      <div
        v-if="!isItemsEmpty"
        class="pl-5 pr-5"
        style="max-height: 350px; min-height: 100px; overflow: auto"
      >
        <template v-for="(item, index) in items">
          <div class="wrapper d-flex align-center" :key="index">
            <img class="cover" :src="item.cover" />
            <div class="text-left mt-4">
              <h6>{{ item.title }}</h6>
              <p class="text-limit-2">
                {{ item.description }}
              </p>
            </div>
            <img
              v-if="item.is_added != 1"
              @click="addSubliminal(item.subliminal_id)"
              class="icon"
              src="@/assets/images/user/playlist/add2.png"
            />
            <v-icon @click="addSubliminal(item.subliminal_id)" v-else>mdi-check</v-icon>
          </div>
        </template>
      </div>
      <div v-else class="mx-auto text-center">
        <h5 class="mt-5">No result found!</h5>
        <span
          >We couldn’t find what you searched for.<br />Try using a different
          key word and try again<br
        /></span>
        <img
          class="empty-search mt-2"
          src="@/assets/images/user/playlist/empty.png"
          alt=""
        />
      </div>
    </div>
    <template v-slot:action>
      <div class="content">
        <div v-if="message != ''" class="message">
          <span>{{ message }}</span>
        </div>
        <div v-if="error != ''" class="errors">
          <span>{{ error }}</span>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { getOwnPlaylist, addOwnPlaylist } from "@/api/ownplaylist";
import { getAllUserPlaylist } from "@/api/playlist";
import { addSubliminalToOwnPlaylists } from "@/api/playlistinfo";
import { getSearchPlaylist } from "@/api/search";
import { getMessage } from "@/utils";

import Dialog from "@/components/Dialog.vue";
import DataLoading from "@/components/DataLoading.vue";
import CardSingle from "@/components/Card/CardSingle.vue";

export default {
  props: {
    dialog: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data: () => {
    return {
      addOwnPlaylist,
      getOwnPlaylist,
      getAllUserPlaylist,
      getSearchPlaylist,
      getMessage,
      addSubliminalToOwnPlaylists,

      ownPlaylists: [],

      error: "",
      message: "",
      searchSub: null,
      items: [],
      radio: {},
      isReady: false,
    };
  },
  mounted() {
    let self = this;
    setTimeout(() => {
      self.getData();
      self.isReady = true;
    }, 0);
  },
  components: {
    Dialog,
    CardSingle,
    DataLoading,
  },
  computed: {
    user() {
      return JSON.parse(this.$store.state.user.info);
    },
    isItemsEmpty() {
      if (this.items != undefined) {
        return this.items.length == 0;
      }
      return false;
    },
  },
  watch: {
    data(val) {
      let self = this;
      setTimeout(() => {
        self.getData();
      }, 0);
    },
    searchSub(val) {
      this.searchSub = val;
      let self = this;
      setTimeout(() => {
        self.getData();
      }, 0);
    },
  },
  methods: {
    addSubliminal(id) {
      const data = {
        user_id: this.user.user_id,
        subscription_id: this.user.info.subscription_id,
        subliminal_id: id,
        playlist_id: this.data.playlist_id,
      };
      this.addSubliminalToOwnPlaylists(data).then((res) => {
        if (res.success == true) {
          this.getMessage(this, res)
        } else {
          this.getMessage(this, res)
        }
        this.getData();
        localStorage.setItem("user_track", JSON.stringify(res.data[0]));
        this.$store.dispatch("user/getTrack", res.data[0]);

        this.$store.dispatch("playlist/getOwnUpdate", res.data);
        this.$store.dispatch("playlist/getOwnLoading", false);

        this.getAllUserPlaylist(data).then((res) => {
          this.$store.dispatch("playlist/playlistInfoLists", res);
        })
        
        setTimeout(() => {
          this.message = "";
          this.error = "";
        }, 1500);
      });
    },
    exist() {
      this.error = "Subliminal has already been added";
      setTimeout(() => {
        this.error = "";
      }, 1500);
    },
    closeDialog() {
      this.error = "";
      this.message = "";
      this.$emit("closeDialog", false);
    },
    getData() {
      let self = this;
      let data = self.data;

      const datas = {
        user_id: self.user.user_id,
        subscription_id: self.user.info.subscription_id,
        search: self.searchSub,
        playlist_id: self.data.playlist_id,
      };
      this.getSearchPlaylist(datas).then((res) => {
        this.items = res;
      });
    },
  },
};
</script>

<style>
.content {
  height: 40px;
  margin: auto;
  margin-top: -15px;
}
.message {
  background: #eafbf6;
}
.message,
.errors {
  border-radius: 5px;
  margin: auto;
  height: 40px;
  padding: 10px 15px;
  font-family: "Montserrat-Regular";
  text-align: center;
}
.text-limit-2 {
  font-size: 10px;
  padding-right: 20px;
  font-family: "Montserrat-Regular";
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.errors {
  background: #fdedee;
}
.wrapper {
  margin-top: 10px;
}
.icon {
  height: 20px;
}
.empty-search {
  height: 80px;
}
.check {
  height: 17px;
}
.cover {
  height: 70px;
  width: 80px;
  border-radius: 5px;
  margin-right: 10px;
}
.search {
  padding-left: 10px;
  width: 100%;
  margin: auto;
  margin-top: -10px;
  margin-bottom: 10px;
  border-radius: 10px;
  border: solid 0.5px black;
  height: 40px;
  background: #f4eeee;
  font-family: "Montserrat-Regular";
}
</style>
