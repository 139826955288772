<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Stretch marks can be a source of insecurity for many people, but the good news is that they can be healed and their appearance reduced. This subliminal is designed to help you achieve smoother and more even-toned skin by targeting your subconscious mind."
    },
    {
      type: 'heading',
      text: 'Here is how this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Promoting the healing process of stretch marks, helping them fade over time.",
        "Reducing the visibility and appearance of stretch marks on your body.",
        "Nourishing and moisturizing your skin, improving its elasticity and texture.",
        "Boosting your confidence and self-esteem by helping you feel more comfortable in your own skin.",
        "Providing supportive affirmations and messages to help you embrace and accept your body."
      ]
    },
    {
      type: 'paragraph',
      text: "It's important to note that results may vary from person to person, and consistency is key when using this subliminal. By incorporating it into your daily routine and listening regularly, you can support your body's natural healing processes and work towards achieving smoother, more even-toned skin. Remember, self-love and acceptance are essential throughout this process."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a journey towards healing and removing stretch marks is a powerful step towards achieving smoother and more even-toned skin. This subliminal is designed to support your efforts in this process. Before you start listening, consider the following strategies to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Embrace self-acceptance: Recognize that stretch marks are a natural part of life and don't define your worth, but it's okay to want to heal and improve your skin.",
        "Nourish your skin: Prioritize a healthy skincare routine, such as moisturizing regularly, staying hydrated, and using products that can support skin elasticity.",
        "Practice positive self-talk: Replace negative thoughts or judgments about your body with affirmations that promote self-love and acceptance.",
        "Visualize your desired outcome: Imagine yourself with smooth, even-toned skin and feel the confidence it brings.",
        "Be patient and persistent: Healing and removing stretch marks takes time. Stay committed to your goals and trust in the process."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your routine along with the prescribed strategies, you can enhance the healing process and work towards achieving the skin you desire. Each listen brings you closer to your goal, so take a moment to prepare yourself mentally and emotionally, and let your journey to skin transformation begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Stretch Mark Healing and Removal subliminal! You have taken an important step towards achieving smoother and more even-toned skin. Now, it's time to reflect on your experience and continue your journey towards healing and removing stretch marks."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Nourish your skin: Use moisturizers and oils that promote skin elasticity and hydration.",
        "Stay hydrated: Drink plenty of water to keep your skin hydrated from within.",
        "Protect your skin: Apply sunscreen daily to prevent further damage and protect your skin.",
        "Practice self-care: Engage in activities that promote self-love and acceptance."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, healing and removing stretch marks takes time and patience. Embrace your body's natural healing process, and be kind to yourself. With consistent care and self-love, you can achieve smoother, more even-toned skin. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Stretch Mark Healing and Removal subliminal work?`, 
    answer: `The Stretch Mark Healing and Removal subliminal works by providing positive affirmations and suggestions to your subconscious mind, helping to reprogram your beliefs and thoughts about your skin's appearance. Through consistent listening, it can help to reduce the visibility of stretch marks and promote healing in the affected areas, resulting in smoother and more even-toned skin.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Stretch Mark Healing and Removal subliminal?`, 
    answer: `The time it takes to see results can vary depending on factors such as the severity of your stretch marks and your body's individual response. Consistent listening is key, and it is recommended to listen to the subliminal daily for at least a few weeks to allow the positive affirmations to work on reprogramming your subconscious mind.`,
    open: false 
  },
  { 
    question: `Are the results permanent?`, 
    answer: `The results achieved from the Stretch Mark Healing and Removal subliminal can vary from person to person. While the subliminal can help to reduce the visibility of stretch marks and promote healing, it is important to note that every individual is unique, and results may not be permanent. Maintaining a healthy lifestyle, such as staying hydrated, moisturizing the skin, and practicing self-care, can be beneficial in supporting long-term improvements.`,
    open: false 
  },
  { 
    question: `Can the Stretch Mark Healing and Removal subliminal be used on all skin types?`, 
    answer: `Yes, the Stretch Mark Healing and Removal subliminal can be used on all skin types. It is designed to promote healing and skin rejuvenation, regardless of skin tone or texture. However, if you have any specific concerns about your skin or are undergoing any medical treatments, it is always recommended to consult with a healthcare professional before making any changes to your skincare routine.`,
    open: false 
  },
  { 
    question: `Can I listen to the Stretch Mark Healing and Removal subliminal while using other skincare products?`, 
    answer: `Yes, you can listen to the Stretch Mark Healing and Removal subliminal while using other skincare products. The subliminal works on the subconscious level and does not interfere with topical skincare applications. In fact, combining the subliminal with a consistent skincare routine and practices that promote skin health can help enhance the overall results.`,
    open: false 
  },
  { 
    question: `Are there any known risks or side effects of listening to the Stretch Mark Healing and Removal subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Stretch Mark Healing and Removal subliminal. However, if you have any concerns about your skin or are undergoing any medical treatments, it is always recommended to consult with a healthcare professional before making any changes to your skincare routine.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>