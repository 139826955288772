<template>
  <div class="d-flex align-items-center justify-content-between px-2 pt-5 pb-3">
    <h3 v-if="greet == true">{{ greetings }}</h3>
    <h3 v-else></h3>
    <el-dropdown
      trigger="click"
      :hide-on-click="false"
      @command="handleCommand"
    >
      <v-avatar class="ml-2" size="40" color="grey lighten-2">
        <img :src="avatar" alt="Profile" />
      </v-avatar>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          class="d-flex py-2"
          v-if="isUserInfluencer"
          style="width: 180px"
          command="dashboard"
        >
          <v-icon class="mr-4">mdi-view-dashboard</v-icon>
          <span>Dashboard</span>
        </el-dropdown-item>
        <el-dropdown-item
          class="d-flex py-2"
          style="width: 180px"
          command="profile"
        >
          <v-icon class="mr-4">mdi-account</v-icon>
          <span>Profile</span>
        </el-dropdown-item>
        <el-dropdown-item
          class="d-flex py-2"
          style="width: 180px"
          command="logout"
        >
          <v-icon class="mr-4">mdi-logout</v-icon>
          <span>Logout</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  props: {
    greet: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },
  data: () => {
    return {
      greetings: null,
      avatar: null,
      is_visible: true,
    };
  },
  mounted() {
    let self = this;
    setTimeout(() => {
      self.getGreeting();
    }, 0);
  },
  computed: {
    user() {
      return this.$store.state.user.info;
    },
    users() {
      return JSON.parse(this.$store.state.user.info);
    },
    layout() {
      // Main, User, Admin
      return this.$route.matched[0].meta.layout;
    },
    isUserInfluencer() {
      return this.users != null
        ? this.users.info.is_influencer == "Yes"
        : false;
    },
    cover() {
      return this.$store.state.user.cover;
    },
  },
  methods: {
    handleCommand(command) {
      if (command == "logout") {
        this.logout();
      }

      if (command == "dashboard") {
        this.goToRoute("dashboard");
      }

      if (command == "profile") {
        this.goToRoute("settings");
      }
    },
    goToRoute(route) {
      this.$router.push(route);
    },
    getGreeting() {
      let greetings = null;

      let name = null;
      let user = JSON.parse(this.user);

      const date = new Date();
      let hours = date.getHours();

      if (hours >= 0 && hours <= 12) {
        greetings = "Good Morning,";
      } else if (hours >= 12 && hours <= 16) {
        greetings = "Good Afternoon,";
      } else if (hours >= 16 && hours <= 21) {
        greetings = "Good Evening,";
      } else if (hours >= 21 && hours <= 24) {
        greetings = "Good Night,";
      }

      if (name == null && user.hasOwnProperty("name")) {
        name = user.name;
      }

      this.greetings = greetings + " " + name;
      if (this.cover == null) {
        this.avatar = user.info.cover;
      } else {
        this.avatar = this.cover;
      }
    },
    logout() {
      this.$store.dispatch("screen/getLoading", true);

      setTimeout(() => {
        let type = null;
        if (this.layout == "User" || this.layout == "Main") {
          this.$store.dispatch("user/getInfo", {});
          this.$store.dispatch("user/getCover", null);
          this.$store.dispatch("user/getToken", null);
          localStorage.removeItem("user");
          localStorage.removeItem("user_token");
          localStorage.removeItem("user_track");

          this.$store.dispatch("audio/getPlaylistTrack", []);
          this.$store.dispatch("audio/getPlaylistType", "");

          localStorage.removeItem("playlistType");
          localStorage.removeItem("playlistTrack");
          localStorage.removeItem("playlistLoop");
          type = "user";
        }

        if (this.layout == "Admin") {
          this.$store.dispatch("admin/getInfo", {});
          this.$store.dispatch("admin/getToken", null);
          localStorage.removeItem("admin");
          localStorage.removeItem("admin_token");
          type = "admin";
        }

        this.$router.push(`/login?type=${type}`);
        this.$store.dispatch("screen/getLoading", false);
        this.$store.dispatch("audio/getData", []);
        this.$store.dispatch("audio/getDatas", []);
      }, 5000);
    },
  },
};
</script>

<style scoped>
.list {
  width: 180px;
}
span {
  font-family: "Montserrat-Regular";
}
</style>
