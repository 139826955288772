import { render, staticRenderFns } from "./243-musical-instruments-talent-and-skills-enhancement.vue?vue&type=template&id=db5aa722&scoped=true&"
import script from "./243-musical-instruments-talent-and-skills-enhancement.vue?vue&type=script&lang=js&"
export * from "./243-musical-instruments-talent-and-skills-enhancement.vue?vue&type=script&lang=js&"
import style0 from "./243-musical-instruments-talent-and-skills-enhancement.vue?vue&type=style&index=0&id=db5aa722&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db5aa722",
  null
  
)

export default component.exports