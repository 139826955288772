<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're struggling with an addiction to soft drinks and want to break free, this subliminal can help you on your journey to restore your health and well-being."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support you:'
    },
    {
      type: 'list',
      items: [
        "Reducing cravings for soft drinks and sugary beverages.",
        "Increasing your motivation and willpower to resist the temptation of soft drinks.",
        "Helping you build healthier habits and replace soft drinks with nourishing alternatives.",
        "Empowering you to make conscious choices about what you consume and prioritize your well-being.",
        "Supporting your body and mind in recovering from the negative effects of excessive soft drink consumption."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can rewire your subconscious mind and break the hold that soft drinks have on you. Over time, you may feel a renewed sense of energy, improved health, and a greater appreciation for nourishing your body. Remember, change takes time and effort, but you have the power to make positive choices for your well-being."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Overcoming an addiction to soft drinks can be challenging, but with the help of this subliminal, you can take steps toward breaking free and restoring your health and well-being. Before you start listening, consider implementing these strategies:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Educate yourself: Learn about the negative effects of soft drinks on your health and why you want to eliminate this habit.",
        "Set your intentions: Clarify your goals and motivations for quitting soft drinks, whether it's to improve your overall health, reduce sugar intake, or increase your energy levels.",
        "Create positive affirmations: Write down positive statements about your ability to overcome this addiction and restore your well-being.",
        "Seek support: Share your journey with a trusted friend or family member who can provide encouragement and hold you accountable.",
        "Keep hydrated: Replace soft drinks with healthier alternatives like water, herbal tea, or fruit-infused beverages to satisfy your cravings and maintain hydration."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal and absorb the positive affirmations, remember that you have the power to transform your habits and reclaim your health. With determination, support, and a commitment to making healthier choices, you can break free from the addiction to soft drinks and create a happier, healthier life."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Soft Drinks Addiction Removal subliminal! By addressing your addiction to soft drinks, you've taken a significant step towards reclaiming your health and well-being. Now it's time to reflect on your progress and continue making positive changes in your life."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your journey: Take a moment to consider how reducing or eliminating soft drinks has impacted your physical and mental well-being.",
        "Create healthy drink alternatives: Experiment with fruit-infused water, herbal teas, or homemade smoothies as a refreshing and nourishing substitute.",
        "Identify triggers: Be aware of the situations or emotions that may lead to cravings for soft drinks, and find healthier ways to cope with them.",
        "Seek support: Reach out to a trusted friend, family member, or support group to share your progress and get encouragement during challenging moments."
      ]
    },
    {
      type: 'paragraph',
      text: "Breaking free from a soft drink addiction is a gradual process that requires commitment and perseverance. Stay focused on your goal of restoring your health and well-being, and remember that every small step counts. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Soft Drinks Addiction Removal subliminal help me break free from my addiction?`, 
    answer: `The Soft Drinks Addiction Removal subliminal is designed to target the subconscious mind, helping you overcome cravings and break free from the addiction to soft drinks. By listening to this subliminal regularly, you can reprogram your mindset and develop healthier habits around beverage choices. Consistency with listening is key to allowing the subliminal messages to make a positive impact on your thoughts and behaviors.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Soft Drinks Addiction Removal subliminal?`, 
    answer: `The time it takes to see results can vary depending on the individual and their unique circumstances. Some people may experience positive changes in their cravings and habits right away, while others may require more time and consistent listening to fully overcome their addiction. We recommend listening to the subliminal daily and consistently while making conscious efforts to make healthier beverage choices.`,
    open: false 
  },
  { 
    question: `Are there any tips for making the Soft Drinks Addiction Removal subliminal more effective?`, 
    answer: `To enhance the effectiveness of the subliminal, it can be helpful to replace soft drinks with healthier beverage alternatives, such as infused water or herbal tea. Creating a supportive environment by removing soft drinks from your home and surrounding yourself with healthier options can also aid in breaking the addiction. Additionally, practicing mindfulness and self-awareness can help you identify triggers or situations that may lead to cravings, allowing you to develop healthier coping strategies.`,
    open: false 
  },
  { 
    question: `Can the Soft Drinks Addiction Removal subliminal be used alongside other addiction recovery programs?`, 
    answer: `Yes, the Soft Drinks Addiction Removal subliminal can be used alongside other addiction recovery programs or personal strategies. It is important to consult with a healthcare professional or addiction specialist to develop a comprehensive plan that addresses your specific needs and circumstances.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the Soft Drinks Addiction Removal subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your health or addiction, it is recommended to consult with a healthcare professional or addiction specialist for personalized guidance and support.`,
    open: false 
  },
  { 
    question: `Will the Soft Drinks Addiction Removal subliminal completely eliminate my desire for soft drinks?`, 
    answer: `While the Soft Drinks Addiction Removal subliminal is designed to help you overcome your addiction and develop healthier habits, individual results may vary. It is important to combine listening to the subliminal with practical efforts to make healthier choices and address any underlying reasons for the addiction. Over time, with consistent use and commitment to change, you can reduce your desire for soft drinks and improve your overall health and well-being.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>