import { render, staticRenderFns } from "./RecentSearch.vue?vue&type=template&id=cf9175e4&scoped=true&"
import script from "./RecentSearch.vue?vue&type=script&lang=js&"
export * from "./RecentSearch.vue?vue&type=script&lang=js&"
import style0 from "./RecentSearch.vue?vue&type=style&index=0&id=cf9175e4&prod&lang=css&"
import style1 from "./RecentSearch.vue?vue&type=style&index=1&id=cf9175e4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf9175e4",
  null
  
)

export default component.exports