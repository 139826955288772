<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Going through a divorce can be incredibly difficult and emotionally challenging. This subliminal is designed to help you heal and recover from the pain of divorce, allowing you to let go, move on, and create a new, fulfilling life for yourself."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can support you:"
    },
    {
      type: 'list',
      items: [
        "Assisting you in processing and releasing painful emotions associated with the divorce.",
        "Helping you let go of resentment, anger, and bitterness towards your ex-partner.",
        "Supporting you in rebuilding your self-esteem and confidence after the end of your marriage.",
        "Providing tools and affirmations to cultivate self-love and self-care during this transformational period.",
        "Encouraging you to embrace the present moment and focus on creating a new, fulfilling life for yourself."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind to let go of the past and embrace a brighter future. It takes time to heal from a divorce, but with patience and self-compassion, you can create a life that brings you joy, fulfillment, and new opportunities for love and happiness."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Recovering from a divorce can be an emotionally challenging process, but this subliminal can help you heal and move forward towards creating a new and fulfilling life. Before you start listening, here are some strategies to prepare yourself for the journey:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your emotions: Allow yourself to feel the range of emotions that come with divorce, and give yourself permission to grieve.",
        "Set boundaries: Establish clear boundaries with your ex-spouse to create a sense of separation and promote your personal growth.",
        "Practice self-care: Prioritize your well-being by engaging in activities that nourish your mind, body, and soul.",
        "Find support: Seek out a support system of friends, family, or a therapist who can provide guidance and understanding during this time."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to help you release the pain of divorce, let go of the past, and embrace a new chapter in your life. As you listen regularly, you may find yourself becoming stronger, more resilient, and ready to create a future filled with happiness and fulfillment. Take a deep breath, acknowledge your emotions, and let's embark on this transformative journey together."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Get over and Move on from Divorce subliminal! By choosing to heal and recover from the pain of divorce, you have taken a significant step towards creating a new, fulfilling life for yourself. It's important to reflect on your experience and continue your journey of healing and growth."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Allow yourself to feel: Give yourself permission to experience and process your emotions in a healthy way.",
        "Seek support: Reach out to friends, family, or a therapist who can provide guidance and a listening ear.",
        "Practice self-care: Prioritize self-care activities that nurture your mind, body, and soul.",
        "Set new goals: Begin envisioning and pursuing a future that brings happiness and fulfillment."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, healing from a divorce takes time and effort, but it is possible to find happiness and create a new chapter in your life. Be patient with yourself, be kind to yourself, and trust that brighter days are ahead. You are strong and capable of moving on to a more fulfilling and joyful future."
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the "Get over and Move on from Divorce" subliminal help me?`, 
    answer: `The "Get over and Move on from Divorce" subliminal is designed to support your healing and recovery process after a divorce. By listening to the subliminal regularly, you can reprogram your subconscious mind to let go of the pain, release negative emotions, and create a positive mindset towards moving on and creating a fulfilling life for yourself. It can help you gain clarity, rebuild your confidence, and embrace new opportunities for personal growth and happiness.`,
    open: false
  },
  { 
    question: `How long does it take to get over a divorce with the help of the subliminal?`, 
    answer: `The healing process after a divorce is unique to each individual, and the time it takes to fully recover can vary. The "Get over and Move on from Divorce" subliminal can be an effective tool to support your healing journey, but it is important to be patient with yourself and allow yourself the necessary time and space to heal. The subliminal can begin to create positive changes in your mindset and emotions over time, but healing is a gradual process.`,
    open: false 
  },
  { 
    question: `Can the subliminal help me find love again after my divorce?`, 
    answer: `Yes, the subliminal can support you in finding love again after divorce. As you heal and develop a positive mindset, you become more open to new relationships and opportunities for love. The subliminal can help you release any lingering pain or resentment from your previous marriage, allowing you to approach future relationships with a fresh perspective and a greater sense of self-love and confidence.`,
    open: false 
  },
  { 
    question: `Can the subliminal help me with co-parenting and maintaining a healthy relationship with my ex-spouse?`, 
    answer: `While the subliminal can help you heal and move on from the pain of divorce, it is not specifically designed to address co-parenting or maintaining a healthy relationship with your ex-spouse. However, as you develop a positive mindset and let go of negative emotions, you may find it easier to navigate these aspects of post-divorce life. Communication, setting boundaries, and seeking professional guidance when necessary can also be helpful for co-parenting and maintaining a healthy relationship with your ex-spouse.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the "Get over and Move on from Divorce" subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you are experiencing severe emotional distress or mental health issues related to your divorce, it is important to seek professional help from a therapist or counselor. The subliminal is a tool that can supplement your healing process, but it is not a substitute for professional support when needed.`,
    open: false 
  },
  { 
    question: `Will the subliminal make me forget or erase the memories of my marriage?`, 
    answer: `No, the subliminal is not intended to make you forget or erase the memories of your marriage. Healing from a divorce involves acknowledging and processing the emotions and experiences associated with it. The subliminal aims to support you in moving forward and creating a fulfilling life for yourself, while allowing the memories and lessons from your past to shape and inform your future.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>