import { render, staticRenderFns } from "./93-stop-over-gesturing.vue?vue&type=template&id=f53a693e&scoped=true&"
import script from "./93-stop-over-gesturing.vue?vue&type=script&lang=js&"
export * from "./93-stop-over-gesturing.vue?vue&type=script&lang=js&"
import style0 from "./93-stop-over-gesturing.vue?vue&type=style&index=0&id=f53a693e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f53a693e",
  null
  
)

export default component.exports