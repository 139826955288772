<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you find yourself frequently stomping your feet as a way to express frustration or anger, this subliminal can help you break free from this habit and find healthier alternatives for emotional release."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help you:"
    },
    {
      type: 'list',
      items: [
        "By targeting your subconscious mind, it can help you become aware of the habit of foot stomping and the emotions that trigger it.",
        "It can guide you towards finding alternative ways to express your emotions in a healthy and constructive manner.",
        "The subliminal messages can support a shift in your mindset, encouraging calmness, patience, and assertiveness.",
        "Listening to this subliminal regularly can help you develop new coping mechanisms and strategies for dealing with intense emotions.",
        "With time and consistent use, you can break the habit of foot stomping and learn to respond to challenging situations in a more positive and productive way."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming any habit takes time and effort. By incorporating this subliminal into your daily routine, you can gradually replace foot stomping with healthier ways of expressing yourself. Embrace the journey towards emotional growth and finding inner peace."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Breaking the habit of foot stomping and finding healthier ways to express your emotions can be a challenging journey. This subliminal is designed to help you navigate that journey and develop new coping mechanisms. Before you begin, consider these tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Self-reflection: Take a moment to understand the underlying emotions that trigger your foot stomping habit.",
        "Identify triggers: Recognize the situations or events that often lead to foot stomping, and be mindful of them when they occur.",
        "Find alternatives: Explore and brainstorm healthier ways to express your emotions, such as deep breathing, journaling, or engaging in physical activities.",
        "Empower yourself: Cultivate a positive mindset and remind yourself that you have the ability to choose healthier responses to your emotions."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is meant to reprogram your subconscious mind and help you break free from the foot stomping habit. Through consistent listening, you can develop new habits and find more constructive ways to express your emotions. Embrace this opportunity for personal growth and embrace the journey ahead. Let's get started!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Stop Foot Stomping subliminal! By taking this step towards breaking the habit of foot stomping and finding healthier ways to express your emotions, you've embarked on a journey of personal growth and self-improvement."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on triggers: Take time to identify what triggers your foot stomping habit and the emotions it represents.",
        "Explore alternatives: Find alternative ways to express your emotions, such as talking, writing, or engaging in physical activities.",
        "Practice self-awareness: Pay attention to your body and emotions, and learn to recognize when foot stomping tendencies arise.",
        "Seek support: Consider talking to a therapist or joining a support group to explore healthier coping strategies."
      ]
    },
    {
      type: 'paragraph',
      text: "Breaking the habit of foot stomping and finding healthier ways to express yourself is a process that takes time and patience. Be kind to yourself and celebrate each step you take towards positive change. Remember, you have the power to transform your emotional expression and live a more fulfilling life. Keep moving forward!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Stop Foot Stomping subliminal help me break the habit?`, 
    answer: `The Stop Foot Stomping subliminal is designed to reprogram your subconscious mind and help you find alternative ways to express your emotions. By listening to the subliminal regularly, you can become more aware of your triggers and learn healthier ways to cope with frustration or anger. The subliminal messages aim to promote self-control, patience, and mindfulness, allowing you to break the habit of foot stomping and find more constructive outlets for your emotions.`,
    open: false
  },
  { 
    question: `How long will it take to see results from the Stop Foot Stomping subliminal?`, 
    answer: `The time it takes to see results may vary from person to person. Some individuals may experience noticeable changes in behavior and emotional regulation after listening to the subliminal for a few weeks, while others may need more time. Consistency is key, and practicing the techniques and strategies suggested in the subliminal alongside regular listening can accelerate the process of breaking the habit of foot stomping.`,
    open: false 
  },
  { 
    question: `Can the subliminal be used for other types of impulsive behaviors?`, 
    answer: `The Stop Foot Stomping subliminal is designed specifically for addressing the habit of foot stomping. While it may indirectly help with other impulsive behaviors by promoting self-control and emotional regulation, it is recommended to use subliminals targeted towards those specific behaviors for more effective results. If you have concerns about a particular habit, it is always best to work with a qualified professional.`,
    open: false 
  },
  { 
    question: `Are there any side effects of listening to the Stop Foot Stomping subliminal?`, 
    answer: `No, there are no known side effects associated with listening to the Stop Foot Stomping subliminal. However, it is important to note that the subliminal is intended to complement healthy coping strategies and emotional regulation techniques. If you have any concerns about your emotional well-being or have a history of mental health conditions, it is recommended to seek support from a qualified professional.`,
    open: false 
  },
  { 
    question: `Can I listen to the subliminal while doing other activities?`, 
    answer: `It is generally recommended to listen to the Stop Foot Stomping subliminal in a relaxed and focused state, such as during a period of meditation or before going to sleep. This allows the subliminal messages to effectively reach your subconscious mind. However, if you find it more convenient to listen while engaging in other activities, such as while working or doing household chores, you can still benefit from the positive affirmations. Just ensure that you are able to give at least partial attention to the subliminal audio to maximize its impact.`,
    open: false 
  },
  { 
    question: `Will the subliminal completely eliminate my foot stomping habit?`, 
    answer: `The goal of the Stop Foot Stomping subliminal is to help you break the habit and find healthier ways to express your emotions. While the subliminal can be a powerful tool for creating positive change, individual results may vary. It is important to approach the process with patience, consistency, and a willingness to explore and implement new coping strategies alongside listening to the subliminal to achieve lasting results.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>