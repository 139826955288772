<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Emotional maturity is a valuable trait that can greatly enhance your ability to navigate through life's challenges. This subliminal is designed to help you develop emotional intelligence and cultivate emotional maturity."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help you:"
    },
    {
      type: 'list',
      items: [
        "Enhancing your self-awareness and understanding of your own emotions.",
        "Improving your ability to manage and regulate your emotions in various situations.",
        "Building empathy and improving your understanding of others' emotions.",
        "Developing resilience and the ability to bounce back from setbacks.",
        "Strengthening your communication skills, especially in handling difficult conversations.",
        "Promoting healthier relationships and reducing conflicts in your personal and professional life."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to adopt healthier emotional patterns and responses. Over time, you may notice an increase in your emotional intelligence and the ability to navigate challenging situations with grace and resilience. Remember, building emotional maturity is a gradual process, but the benefits are worth it."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Building emotional maturity and enhancing your emotional intelligence can greatly benefit your personal and professional life. This subliminal is designed to support your journey towards developing emotional maturity. Before listening, here are some suggestions to help you get the most out of this experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Clarify your desired emotional state and the areas of your life where you want to exhibit greater emotional maturity.",
        "Reflect on your emotions: Take a moment to identify and acknowledge your current emotional patterns and triggers.",
        "Cultivate self-awareness: Practice observing your thoughts and feelings without judgment, allowing yourself to gain insight into your emotional landscape.",
        "Create a supportive environment: Find a quiet and comfortable space where you can focus on the subliminal and engage with your emotions.",
        "Embrace the process: Approach this subliminal with an open mind and a willingness to learn and grow."
      ]
    },
    {
      type: 'paragraph',
      text: "Using this subliminal regularly, along with consistent reflection and self-awareness, can help you develop emotional maturity and navigate through life's challenges with grace and resilience. Embrace this opportunity to enhance your emotional intelligence and step into a more empowered version of yourself. Let's begin the journey towards emotional growth and maturity."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the session for Developing Emotional Maturity! By embarking on this journey to enhance your emotional intelligence and maturity, you have taken an important step towards navigating life's challenges with grace and resilience. Now, let's reflect on your experience and continue to cultivate emotional growth."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Embrace self-reflection: Take time to reflect on your emotions, triggers, and reactions in various situations.",
        "Practice empathy: Cultivate understanding and empathy towards others, considering their perspectives and experiences.",
        "Develop emotional literacy: Strengthen your vocabulary to accurately express and communicate your emotions.",
        "Build resilience: Develop strategies to bounce back from setbacks, cultivating a positive and resilient mindset."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, developing emotional maturity is an ongoing process that requires conscious effort and practice. Embrace the journey and be patient with yourself as you grow emotionally. By enhancing your emotional intelligence, you'll be better equipped to navigate through life's challenges with grace and resilience. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What is emotional maturity?`, 
    answer: `Emotional maturity refers to the ability to understand, manage, and express emotions in a healthy and constructive way. It involves being aware of one's own emotions, recognizing and empathizing with the emotions of others, and effectively managing conflicts and challenges. Emotional maturity allows individuals to navigate through life's ups and downs with grace, resilience, and a sense of personal responsibility.`,
    open: false
  },
  { 
    question: `What are the benefits of developing emotional maturity?`, 
    answer: `Developing emotional maturity can have numerous benefits in various areas of life. It can improve relationships, communication skills, and conflict resolution abilities. It helps individuals handle stress better and make sound decisions. Emotional maturity also promotes self-awareness, empathy, and a positive mindset, leading to greater personal and professional success.`,
    open: false 
  },
  { 
    question: `How can I develop emotional maturity?`, 
    answer: `Developing emotional maturity involves self-reflection, self-awareness, and practice. You can start by exploring your emotions, understanding their triggers, and examining your patterns of behavior and reactions. Practicing mindfulness and emotional regulation techniques can help you manage intense emotions and develop healthier coping mechanisms. Seeking therapy or counseling, reading books on emotional intelligence, and engaging in personal growth activities can also support your journey towards emotional maturity.`,
    open: false 
  },
  { 
    question: `Can subliminals help in developing emotional maturity?`, 
    answer: `Subliminals can be a helpful tool in developing emotional maturity by influencing the subconscious mind and reinforcing positive beliefs and behaviors. However, it is important to note that subliminals are not a magic solution and should be used in conjunction with other personal growth practices, such as self-reflection and therapy. Consistent listening to subliminal audios focused on emotional intelligence can complement your efforts in developing emotional maturity.`,
    open: false 
  },
  { 
    question: `How long does it take to develop emotional maturity?`, 
    answer: `The time it takes to develop emotional maturity varies from person to person. It is a lifelong journey that requires ongoing self-reflection, practice, and growth. Small changes and improvements in emotional maturity can be noticed relatively quickly, but it takes time and consistent effort to develop emotional intelligence to a greater extent.`,
    open: false 
  },
  { 
    question: `Can developing emotional maturity eliminate all challenges in life?`, 
    answer: `Developing emotional maturity doesn't eliminate challenges in life, but it equips individuals with the skills and mindset to navigate through them more effectively. Emotional maturity helps individuals respond to challenges with resilience, empathy, and a problem-solving mindset. It allows for personal growth and the ability to view challenges as opportunities for learning and development.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>