<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Building self-confidence is a journey, and this subliminal is designed to be your helpful companion along the way. By targeting your subconscious mind, it can help you boost your self-confidence and belief in yourself, leading to positive changes in all areas of your life."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Increasing your self-esteem and self-worth, helping you feel more deserving of success.",
        "Reducing self-doubt and negative self-talk, enabling you to overcome insecurities.",
        "Building resilience and the ability to bounce back from setbacks.",
        "Enhancing your assertiveness and ability to voice your opinions and needs.",
        "Improving your body language and nonverbal communication, allowing you to appear more confident to others."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can rewire your subconscious beliefs and thoughts, creating a strong foundation of self-confidence. Over time, you may notice yourself becoming more empowered, courageous, and willing to take on new challenges. Remember, self-confidence is a skill that can be developed, and this subliminal is here to support your growth."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Building self-confidence is a powerful journey that can positively impact all aspects of your life. This subliminal is designed to help boost your self-confidence and belief in yourself. Before you begin, here are some tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Clearly define the areas of your life where you want to feel more confident and believe in yourself.",
        "Practice self-care: Prioritize activities that boost your self-esteem and make you feel good about yourself.",
        "Challenge negative thoughts: Replace self-doubt and self-criticism with positive affirmations and self-encouragement.",
        "Visualize success: Imagine yourself confidently navigating different situations in your life and achieving your goals.",
        "Maintain focus: While listening, keep your attention on your desire for increased self-confidence, allowing the subliminal messages to sink into your subconscious mind."
      ]
    },
    {
      type: 'paragraph',
      text: "Embrace this opportunity to empower yourself and strengthen your self-confidence. Regular listening to this subliminal can help you develop a more positive mindset and belief in your abilities. Trust in the process, believe in yourself, and let your self-confidence bloom in all areas of your life."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on finishing the Self-Confidence Booster subliminal! By investing in your self-confidence, you've already taken a major step towards unlocking your full potential in all aspects of life. Now it's time to reflect on your experience and continue building your self-belief and confidence."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your journey: Take some time to journal about any changes or breakthroughs you've noticed in your self-confidence.",
        "Practice positive self-talk: Replace self-doubt with positive affirmations and empowering thoughts.",
        "Step outside your comfort zone: Challenge yourself to do something that scares you a little, and embrace the growth that comes with it.",
        "Surround yourself with positivity: Seek out positive relationships and environments that uplift and support you."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, building self-confidence is an ongoing process. Be patient with yourself and embrace the journey of growth. Believe in yourself and your abilities, because you are capable of achieving great things. Keep shining!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  {
    question: `How can the Self-Confidence Booster subliminal help me?`,
    answer: `The Self-Confidence Booster subliminal is designed to help you develop and enhance your self-confidence in all areas of your life. By listening to the subliminal regularly, the positive affirmations and messages will work to reprogram your subconscious mind, allowing you to believe in yourself, overcome self-doubt, and project a confident and empowered image to others.`,
    open: false
  },
  {
    question: `How long will it take to see results from the Self-Confidence Booster subliminal?`,
    answer: `The time it takes to see results can vary from person to person. Some individuals may experience a boost in self-confidence almost immediately after listening to the subliminal, while others may require more time and consistent listening to notice significant changes. We recommend listening to the subliminal daily and consistently for optimal results.`,
    open: false
  },
  {
    question: `Can the Self-Confidence Booster subliminal help with social anxiety?`,
    answer: `While the Self-Confidence Booster subliminal can help improve overall self-confidence, it is important to note that it is not a substitute for professional help for social anxiety or any other mental health conditions. The subliminal is designed to complement existing therapeutic strategies and self-care practices. If you are experiencing severe social anxiety, we recommend seeking guidance from a qualified healthcare professional.`,
    open: false
  },
  {
    question: `How can I make the Self-Confidence Booster subliminal more effective?`,
    answer: `To enhance the effectiveness of the Self-Confidence Booster subliminal, you can reinforce the positive affirmations by visualizing yourself as a confident and empowered individual. It can be helpful to set achievable goals and celebrate your successes along the way. Surrounding yourself with supportive and positive influences can also contribute to the effectiveness of the subliminal.`,
    open: false
  },
  {
    question: `Is it safe to listen to the Self-Confidence Booster subliminal?`,
    answer: `Yes, it is safe to listen to the Self-Confidence Booster subliminal. The subliminal messages are designed to positively influence your mindset without any known risks or side effects. However, if you have any concerns about your mental health or well-being, we recommend consulting with a healthcare professional.`,
    open: false
  },
  {
    question: `Can the Self-Confidence Booster subliminal help me in my professional life?`,
    answer: `Yes, the Self-Confidence Booster subliminal can positively impact your professional life by instilling a sense of self-assurance, providing motivation, and helping you overcome self-limiting beliefs. With increased self-confidence, you may find it easier to take on new challenges, communicate effectively, and achieve your goals in the workplace.`,
    open: false
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>