<template>
  <div class="my-4">
    <template v-if="loading">
      <h3 :class="`${isCardSlider}`">{{ name }}</h3>
      <div class="text-center my-2">
        <v-progress-circular indeterminate></v-progress-circular>
      </div>
    </template>
    <template v-else>
      <template v-if="isDataReady">
        <h3 :class="`${isCardSlider}`">{{ name }}</h3>
        <slot></slot>
      </template>
      <template v-else>
        <h3 :class="`${isCardSlider}`">{{ name }}</h3>
        <div class="text-center">
          <h4>No Data Found</h4>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    slider: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    name: {
      type: String,
      default: () => {
        return null;
      },
    },
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data: () => {
    return {
      counter: 5,
      loading: true,
    };
  },
  created() {
    var self = this;
    let timer = setInterval(function () {
      self.counter -= 1;

      if (self.isDataReady || self.counter == 0) {
        clearInterval(timer);
        self.loading = false;
      }
    }, 1000);
  },
  computed: {
    isDataReady() {
      return this.data.length > 0;
    },
    isCardSlider() {
      return this.slider ? "slider" : "";
    },
  },
};
</script>

<style scoped>
h3 {
  text-transform: capitalize;
  margin-bottom: 0px;
}

h3.slider {
  margin-inline: 45px;
}
</style>
