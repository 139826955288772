import { render, staticRenderFns } from "./84-stop-swearing.vue?vue&type=template&id=e728e848&scoped=true&"
import script from "./84-stop-swearing.vue?vue&type=script&lang=js&"
export * from "./84-stop-swearing.vue?vue&type=script&lang=js&"
import style0 from "./84-stop-swearing.vue?vue&type=style&index=0&id=e728e848&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e728e848",
  null
  
)

export default component.exports