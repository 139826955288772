<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're looking to achieve success in the real estate market and maximize your sales, this subliminal can help you get there. By targeting your subconscious mind, it can assist in shaping the strategies and mindset necessary for successful real estate selling."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can benefit you:"
    },
    {
      type: 'list',
      items: [
        "Providing motivation and determination to overcome challenges and setbacks.",
        "Enhancing your communication skills to build trust and rapport with potential clients.",
        "Boosting your confidence to negotiate effectively and close deals.",
        "Developing a positive and resilient mindset to navigate the ups and downs of the real estate market.",
        "Increasing your knowledge and expertise in the industry, allowing you to provide exceptional service to your clients."
      ]
    },
    {
      type: 'paragraph',
      text: "By consistently listening to this subliminal, you can reprogram your subconscious mind to align with the success-driven mindset and strategies of top real estate professionals. This can help you maximize your sales, achieve success, and stand out in the competitive real estate market. Remember, success in real estate selling takes a combination of hard work, knowledge, and the right mindset."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Preparing yourself mentally and emotionally is crucial when it comes to achieving success in the real estate market. This subliminal is designed to help you develop the strategies and mindset necessary for selling real estate effectively. Before you start listening, consider these tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Define what success looks like for you in the real estate market and establish achievable targets.",
        "Educate yourself: Continuously learn and improve your knowledge and skills in the real estate industry.",
        "Visualize success: Use visualization techniques to imagine yourself confidently closing deals and achieving your sales goals.",
        "Stay motivated and focused: Keep your goals in mind and maintain a positive attitude throughout your real estate selling journey."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind to support your real estate selling efforts. The affirmations embedded in the audio will assist you in building the confidence, skills, and mindset needed for success. Embrace this opportunity to prepare yourself mentally and emotionally for a rewarding and prosperous real estate career, and let's begin the journey towards real estate selling success."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Real Estate Selling Success subliminal session! By dedicating yourself to learning the strategies and cultivating the right mindset for successful real estate selling, you've taken a significant step towards maximizing your sales and achieving success in the real estate market."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Continued learning: Stay up-to-date with market trends, sales strategies, and negotiation techniques.",
        "Establish a strong network: Build relationships with industry professionals and potential clients to expand your reach.",
        "Sharpen your communication skills: Practice active listening and effective communication to better understand and cater to clients' needs.",
        "Set achievable goals: Create a realistic plan, set targets, and track your progress to measure your success."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, success in the real estate market requires continuous effort and dedication. Use the knowledge and motivation gained from this session to propel yourself towards your goals. With the right strategies and a determined mindset, you can achieve remarkable success in real estate selling. Best of luck!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What can I expect from the Real Estate Selling Success subliminal?`, 
    answer: `The Real Estate Selling Success subliminal is designed to help you develop the mindset, strategies, and skills necessary for successful real estate selling. By listening to the subliminal, you may experience increased confidence, motivation, and a positive mindset towards selling properties. It can also assist in improving your communication skills, negotiation abilities, and overall sales performance.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Real Estate Selling Success subliminal?`, 
    answer: `Results can vary depending on the individual and their unique circumstances. Some people may experience positive changes in their real estate selling abilities right away, while others may require more time and consistent listening to fully internalize the subliminal messages. We recommend listening to the subliminal regularly and practicing the strategies and techniques taught in the program for best results.`,
    open: false 
  },
  { 
    question: `Can the Real Estate Selling Success subliminal guarantee success in the real estate market?`, 
    answer: `The Real Estate Selling Success subliminal is designed to enhance your mindset, strategies, and skills for real estate selling. While it can provide valuable insights and techniques, success in the real estate market depends on various factors, including market conditions, competition, and individual effort. The subliminal is meant to be a tool to support your journey towards success, but it does not guarantee specific outcomes.`,
    open: false 
  },
  { 
    question: `Can the Real Estate Selling Success subliminal replace real estate training or education?`, 
    answer: `No, the subliminal is not a substitute for proper real estate training or education. It is designed to complement your existing knowledge and skills in the real estate industry. Continuous learning, staying informed about market trends, and seeking professional guidance are essential elements for long-term success in real estate.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Real Estate Selling Success subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Real Estate Selling Success subliminal. However, it is important to use common sense and ethical practices when applying the strategies and techniques taught in the program. The subliminal is intended to enhance your skills and mindset, not promote unethical behavior or manipulative sales tactics.`,
    open: false 
  },
  { 
    question: `Will the Real Estate Selling Success subliminal work if I have no prior experience in real estate?`, 
    answer: `The Real Estate Selling Success subliminal is designed to benefit individuals at various stages of their real estate journey. While prior experience can be helpful, the subliminal can still assist newcomers by building a strong foundation of mindset, strategies, and skills necessary for successful real estate selling. It is never too late to start learning and working towards your goals.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>