<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're feeling tired, drained, or lacking energy, this subliminal can help you revitalize your vitality and live a more vibrant life. By targeting your subconscious mind, it primes your mindset for increased energy levels and overall vitality."
    },
    {
      type: 'heading',
      text: 'You may experience the following benefits:'
    },
    {
      type: 'list',
      items: [
        "A noticeable increase in energy levels throughout the day.",
        "Feeling more alert, focused, and productive.",
        "Improved physical stamina and endurance.",
        "Reduced feelings of fatigue and lethargy.",
        "A renewed sense of motivation and zest for life."
      ]
    },
    {
      type: 'paragraph',
      text: "With regular use, this subliminal can help you break free from the cycle of fatigue and embrace a more energized and lively version of yourself. Imagine the possibilities when you have the energy and vitality to pursue your passions, achieve your goals, and enjoy life to the fullest. Start your journey to enhanced vitality today!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Increasing your energy levels and vitality is an essential step towards living a vibrant and fulfilling life. This subliminal is specifically designed to help you say goodbye to fatigue and embrace a more energized and lively version of yourself. Before you start listening, here are some tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Clearly define your desire to boost your energy levels and live a more lively life.",
        "Create a supportive routine: Incorporate healthy habits such as regular exercise, balanced nutrition, and sufficient rest to optimize your vitality.",
        "Visualize vibrant energy: Imagine yourself filled with vitality and enthusiasm, ready to embrace each day with renewed vigor.",
        "Stay present: During your listening session, focus your attention on the positive affirmations and embrace them with an open mind and heart."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can tap into the power of your subconscious mind to enhance your energy levels and live a more vibrant life. Embrace this opportunity to prioritize your well-being, set your intention, and get ready to experience enhanced vitality like never before."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Enhanced Vitality subliminal session! By focusing on boosting your energy levels and vitality, you've taken an important step towards living a more vibrant and fulfilling life. Reflect on your experience and embrace the positive changes that will follow."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Listen to your body: Pay attention to what energizes you and what drains your energy. Adjust your activities accordingly.",
        "Stay hydrated: Drink plenty of water throughout the day to maintain your energy levels and overall well-being.",
        "Move your body: Engage in regular physical activity to boost circulation and increase your energy levels.",
        "Nourish your body: Focus on a balanced diet with nutrient-rich foods that provide sustained energy.",
        "Practice self-care: Prioritize rest, relaxation, and activities that bring you joy and revitalization.",
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, cultivating enhanced vitality is an ongoing process. Be patient with yourself and celebrate each step forward. Embrace the vibrant and energized version of yourself that you are becoming. You have the power to live a life filled with vitality and purpose!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Enhanced Vitality subliminal help boost my energy levels?`, 
    answer: `The Enhanced Vitality subliminal is designed to positively influence your subconscious mind, helping to rewire your thoughts and beliefs about energy levels. By regularly listening to the subliminal, you can create a more energetic and vibrant mindset, allowing you to embrace a more revitalized version of yourself. The subliminal messages work on a subconscious level to promote feelings of vitality and alertness.`,
    open: false
  },
  { 
    question: `How long does it take to feel the effects of the Enhanced Vitality subliminal?`, 
    answer: `The time it takes to feel the effects of the Enhanced Vitality subliminal can vary from person to person. Some individuals may notice an increase in energy levels shortly after starting to listen, while others may need more time to reprogram their mindset. Consistent listening is key, and it is recommended to use the subliminal daily for at least a few weeks to experience significant improvements.`,
    open: false 
  },
  { 
    question: `Can the Enhanced Vitality subliminal replace healthy lifestyle habits?`, 
    answer: `No, the Enhanced Vitality subliminal is designed to complement and enhance healthy lifestyle habits, not replace them. While the subliminal can help you develop a more energized mindset, it is important to engage in regular exercise, maintain a balanced diet, stay hydrated, and get enough sleep to support your overall vitality.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Enhanced Vitality subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Enhanced Vitality subliminal. However, if you have any concerns about your energy levels or overall health, it is recommended to consult with your healthcare provider. The subliminal is intended to complement healthy lifestyle changes, not replace them.`,
    open: false 
  },
  { 
    question: `Can I listen to the Enhanced Vitality subliminal while performing tasks that require focus?`, 
    answer: `Yes, you can listen to the Enhanced Vitality subliminal while performing tasks that require focus. The subliminal messages are designed to work on a subconscious level, meaning they will not interfere with your conscious thoughts or distract you from your tasks. However, everyone is different, so if you find it affects your focus, it may be best to listen to it during relaxation periods or before bed.`,
    open: false 
  },
  { 
    question: `Can I listen to the Enhanced Vitality subliminal at any time of the day?`, 
    answer: `Yes, you can listen to the Enhanced Vitality subliminal at any time of the day that works best for you. However, it is recommended to listen to it in a relaxing environment where you can fully focus on the subliminal messages. Many individuals find it beneficial to listen in the morning or before engaging in activities that require high energy levels.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>