<template>
  <footer class="p-2">
    <div class="d-grid gap-2">
      <div class="mobile card" v-if="isPlayerAvailable">
        <!-- <div class="mobile card" v-show="false"> -->
        <div class="d-flex align-items-center justify-content-between p-2">
          <div class="information d-flex align-items-center gap-2">
            <div class="cover" :style="`background: url(${cover});`"></div>
            <div class="details mx-2">
              <h5 class="mb-0 text-limit-1">{{ title }}</h5>
              <span class="mb-0 text-limit-1">
                {{ categoryName }} &middot;
                {{ convertToShortTime(durationTime) }}
              </span>
            </div>
          </div>
          <div
            id="mobile-button"
            class="d-flex align-items-center justify-content-end gap-2 p-2"
          >
            <img
              v-if="isAudioPlaying"
              src="@/assets/images/user/webplayer/light/pause.png"
              @click="pauseAudios"
            />
            <img
              v-else
              src="@/assets/images/user/webplayer/light/play.png"
              @click="playAudios"
            />
            <Favorite :data="recentData" :text="getText(recentSubliminalId)" />
          </div>
        </div>
      </div>
      <div class="web card" v-show="isPlayerAvailable">
        <div class="d-flex flex-column px-2" style="width: 100%">
          <div
            class="d-flex align-items-center justify-content-between px-0"
            style="width: 100%"
          >
            <div
              class="information d-flex align-items-center gap-1"
              style="width: 100%"
            >
              <div class="cover" :style="`background: url(${cover});`"></div>
              <div class="details mx-2">
                <h5 class="mb-0 text-limit-1">{{ title }}</h5>
                <span class="mb-0">
                  {{ categoryName }} &middot;
                  {{ convertToShortTime(durationTime) }}
                </span>
              </div>
            </div>
            <div
              id="web-button"
              class="d-flex align-items-center justify-content-center gap-3 p-2"
              style="width: 100%"
            >
              <v-menu top left ref="menu" v-model="advancedVolume">
                <template v-slot:activator="{ on, attrs }">
                  <img
                    :src="
                      require(`@/assets/images/user/webplayer/${
                        advancedVolume ? 'dark' : 'light'
                      }/volume.png`)
                    "
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-list
                  style="min-width: 50px; min-height: 200px; padding: 20px"
                >
                  <div class="d-flex gap-3">
                    <template v-for="(volume, index) in volumes">
                      <div class="d-grid" :key="index">
                        <v-slider
                          vertical
                          :disabled="isUserFree"
                          min="0"
                          max="100"
                          v-model="volumes[index]"
                        ></v-slider>
                        <span>{{ audio_types[index] }}</span>
                      </div>
                    </template>

                    <v-divider
                      inset
                      style="border-color: light-gray; opacity: 1"
                      vertical
                      v-if="volumes.length > 1"
                    />

                    <div class="d-grid" v-if="volumes.length > 1">
                      <v-slider
                        vertical
                        :disabled="isUserFree"
                        min="0"
                        max="100"
                        v-model="volume"
                      ></v-slider>
                      <span>All Volumes</span>
                    </div>
                  </div>
                </v-list>
              </v-menu>

              <img
                :class="`${previousDisabled ? 'disabled' : ''}`"
                src="@/assets/images/user/webplayer/light/prev.png"
                @click="previousAudios"
              />

              <img
                v-if="isAudioPlaying"
                src="@/assets/images/user/webplayer/light/pause.png"
                @click="pauseAudios"
              />
              <img
                v-else
                src="@/assets/images/user/webplayer/light/play.png"
                @click="playAudios"
              />
              <img
                :class="`${nextDisabled ? 'disabled' : ''}`"
                src="@/assets/images/user/webplayer/light/next.png"
                @click="nextAudios"
              />
              <Favorite
                :data="recentData"
                :text="getText(recentSubliminalId)"
              />
            </div>
            <div
              id="other-button"
              class="d-flex align-items-center justify-content-end gap-4 p-2"
              style="width: 100%"
            >
              <img
                style="height: 25px"
                src="@/assets/images/user/webplayer/light/repeat.png"
                @click="repeatAudio"
              />
              <UserEllipsis :data="recentData" :height="20" />
            </div>
          </div>
          <div>
            <v-progress-linear
              v-model="progress"
              color="#1C8EDF"
              height="6"
              class="progress-line"
            ></v-progress-linear>
          </div>
        </div>
      </div>
      <div class="mobile card">
        <div class="d-flex align-items-center justify-content-center">
          <div class="menu d-flex align-items-center p-2">
            <img
              src="@/assets/images/user/footer/home.png"
              @click="goToRoute('overview')"
            />
            <img
              src="@/assets/images/user/footer/search.png"
              @click="goToRoute('search')"
            />
            <img
              src="@/assets/images/user/footer/sound-wave.png"
              @click="goToRoute('own/playlist')"
            />
            <img
              v-if="users.info.subscription_id == 1"
              @click="goToRoute2('/price')"
              src="@/assets/images/user/footer/notif.png"
            />
            <img
              src="@/assets/images/user/footer/user.png"
              @click="goToRoute('settings')"
            />
          </div>
        </div>
      </div>
    </div>

    <Dialog :dialog="dialog" :width="400" title="" @closeDialog="closeDialog">
      <v-list class="pa-3 pt-0">
        <h4>All Volume</h4>
        <p class="mt-2 mb-0">{{ title }}</p>
        <v-slider
          :disabled="isUserFree"
          class="mb-0"
          prepend-icon="mdi-volume-high"
          min="0"
          max="100"
          v-model="volume"
        ></v-slider>

        <h4 class="mb-2">Volume per track</h4>
        <template v-for="(volume, index) in volumes">
          <div :key="index">
            <!-- <p class="mt-2 mb-0">{{ titles[index] }}</p> -->
            <p class="mt-2 mb-0">{{ audio_types[index] }}</p>
            <v-slider
              :disabled="isUserFree"
              prepend-icon="mdi-volume-high"
              min="0"
              max="100"
              v-model="volumes[index]"
            ></v-slider>
          </div>
        </template>

        <v-btn block @click="updateCurrentVolumes" :disabled="isUserFree">
          Apply
        </v-btn>
      </v-list>
    </Dialog>
  </footer>
</template>

<script>
import { getAudioTypeById } from "@/api/audiotype";
import UserEllipsis from "@/components/User/UserEllipsis.vue";

import {
  playAudio,
  pauseAudio,
  nextAudio,
  previousAudio,
  getAudio,
  loopAudio,
  repeatAudio,
  addHistory,
} from "@/utils/audio";

import { convertToTime, convertToShortTime } from "@/utils";

import Dialog from "@/components/Dialog.vue";
import Favorite from "@/components/User/Favorite.vue";
import { getText, toggleData } from "@/utils/favorite.js";

import { getLikePlaylist, getLikeSubliminal } from "@/api/favorite";
import { getSearch, getSearchResult } from "@/api/search";
export default {
  data: () => {
    return {
      getText,
      toggleData,
      getAudioTypeById,

      getLikePlaylist,
      getLikeSubliminal,

      getAudio,
      playAudio,
      pauseAudio,
      nextAudio,
      previousAudio,
      repeatAudio,
      addHistory,
      convertToTime,
      convertToShortTime,
      getSearchResult,
      getSearch,

      volume: 0,
      volumes: [],
      old_volumes: [],
      audio_types: [],

      dialog: false,
      initial_history: true,
      volume_changed: true,

      forceUpdate: false,

      advancedVolume: false,

      favoriteData: [],

      initial_status: true,
      searchData: [],
    };
  },
  components: {
    Dialog,
    Favorite,
    UserEllipsis,
  },
  created() {
    window.addEventListener("beforeunload", () => {
      this.pauseAudios();
    });
  },
  mounted() {
    let self = this;

    setTimeout(() => {
      if (self.data.length > 0 && self.recentplayed.length > 0) {
        let index = self.data.findIndex((item) => {
          return item.subliminal_id == self.recentplayed[0].subliminal_id;
        });
        self.getAudio(self.data, index);
      } else {
        if (self.recentplayed.length > 0) {
          self.getAudio(self.recentplayed[0]);
        }
      }
    }, 0);
  },
  computed: {
    volumeData() {
      return this.$store.state.audio.volumes;
    },
    data() {
      return this.$store.state.audio.playlist.tracks;
    },
    recommendation() {
      return this.$store.state.recommendation.data;
    },
    featured() {
      return this.$store.state.featured.data;
    },
    favorite() {
      return this.$store.state.favorite.data;
    },
    favorites() {
      return this.$store.state.favorite.id;
    },
    cover() {
      return this.$store.state.audio.cover;
    },
    title() {
      return this.$store.state.audio.title;
    },
    titles() {
      return this.$store.state.audio.titles;
    },
    audio_type() {
      return this.$store.state.audio.audio_types;
    },
    audio() {
      return this.$store.state.audio.data;
    },
    audios() {
      return this.$store.state.audio.datas;
    },
    categoryName() {
      return Array.isArray(this.audios) &&
        this.audios.length &&
        this.title != null
        ? this.audios.filter((item) => item.title == this.title)[0]
            .category_name
        : this.audios.category_name;
    },
    recent() {
      return this.$store.state.recent.played.data;
    },
    recentplayed() {
      return this.$store.state.recent.played.data;
    },

    currentTime() {
      return this.$store.state.audio.current.time;
    },
    durationTime() {
      return this.$store.state.audio.duration.time;
    },
    durationTimes() {
      return this.$store.state.audio.duration.times;
    },
    isAudioPlaying() {
      return this.$store.state.audio.status;
    },
    repeat() {
      return this.$store.state.audio.repeat;
    },
    user() {
      return this.$store.state.user.info;
    },
    users() {
      return JSON.parse(this.$store.state.user.info);
    },
    isFavoriteUpdated() {
      return this.$store.state.audio.favorite.update;
    },
    favoriteId() {
      return this.$store.state.audio.favorite.id;
    },
    isPlayerAvailable() {
      return this.audios.length > 0 || Object.keys(this.audios).length > 0;
    },
    recent() {
      return this.$store.state.recent.played.data;
    },
    recents() {
      return JSON.stringify(this.recent);
    },
    recentData() {
      return this.data.length > 0
        ? this.data.filter(
            (item) => item.subliminal_id == this.subliminal_id
          )[0]
        : {};
    },
    recentSubliminalId() {
      return this.data.length > 0 ? this.subliminal_id : "";
    },
    loop() {
      return this.$store.state.audio.loop;
    },
    track() {
      return this.$store.state.user.track;
    },

    audioIndex() {
      return this.$store.state.audio.index;
    },
    audioCount() {
      return this.$store.state.audio.count;
    },

    repeatDisabled() {
      return this.audioCount == 0;
    },
    playDisabled() {
      return this.audioCount == 0;
    },
    previousDisabled() {
      return (this.audioIndex == 0 && !this.loop) || this.audioCount == 0;
    },
    nextDisabled() {
      return (
        (this.audioIndex + 1 == this.audioCount && !this.loop) ||
        this.audioCount == 0
      );
    },
    isUserFree() {
      return (
        JSON.parse(localStorage.getItem("user")).info.subscription_id == null ||
        JSON.parse(localStorage.getItem("user")).info.subscription_id == 1
      );
    },
    playlistType() {
      return this.$store.state.audio.playlist.type;
    },
    progress: {
      set(val) {
        this.updateCurrentTime(val);
      },
      get() {
        return Math.floor((this.currentTime / this.durationTime) * 100);
      },
    },
    subliminal_id() {
      return this.$store.state.audio.subliminal.id;
    },
    playlist_id() {
      return this.$store.state.audio.playlist.id;
    },
    audioData() {
      return this.$store.state.audio.data;
    },
    recentsearch() {
      return this.$store.state.recent.search.data;
    },
  },
  watch: {
    volumeData(val) {
      this.getVolumeData();
      this.getAudioTypesById();
    },
    recentplayed(val) {
      let self = this;
      if (self.initial_status) {
        setTimeout(() => {
          if (self.data.length > 0 && val.length > 0) {
            const index = self.data.findIndex((item) => {
              return item.subliminal_id == val[0].subliminal_id;
            });
            self.getAudio(self.data, index);
          } else {
            self.getAudio(val[0]);
          }
        }, 0);
        self.initial_status = false;
      }
    },
    dialog(val) {
      var self = this;
      this.getAudioTypesById();

      setTimeout(() => {
        self.volumeOpen(val);
      }, 1000);
    },
    volume(val) {
      if (val != undefined) {
        if (this.volume_changed) {
          for (let index = 0; index < this.volumes.length; index++) {
            this.volumes[index] = val;
          }
        }
        this.volume_changed = true;
        this.updateCurrentVolumes();
      }
    },
    volumes(val) {
      this.updateCurrentVolumes();
    },
    currentTime(val) {
      if (val == this.durationTime) {
        this.nextAudios();
      }
    },
    durationTimes(val) {
      // let self = this;
      // if (this.data.length == 0 && this.initial_history) {
      //   this.initial_history = false;
      //   // this.$store.dispatch("screen/getLoading", false);
      // }

      if (val.length > 0) {
        if (!this.initial_history) {
          this.playAudios();
        }

        if (this.initial_history) {
          let currentime = 0;
          if (this.data.length > 0 && this.recentplayed.length > 0) {
            currentime = this.recentplayed[0].current_time;
          }
          this.$store.dispatch("audio/getCurrentTime", parseInt(currentime));
          // this.$store.dispatch("screen/getLoading", false);
          this.initial_history = false;
        }
      }
    },
    isFavoriteUpdated(val) {
      let self = this;
      setTimeout(() => {
        if (val) {
          self.getAudioData();
        }
      }, 0);
    },
  },
  methods: {
    goToRoute(route) {
      this.$router.push("/user/" + route);
    },

    goToRoute2(route) {
      this.$router.push(route).catch((err) => {});
      window.scrollTo(0, 0);
    },
    closeDialog(data) {
      this.dialog = data;
    },
    playAudios() {
      this.playAudio();
    },
    pauseAudios() {
      this.pauseAudio();
    },
    nextAudios() {
      this.$store.commit("audio/getInitialStatus", true);
      this.nextAudio();
    },
    previousAudios() {
      this.$store.commit("audio/getInitialStatus", true);
      this.previousAudio();
    },
    updateCurrentVolumes() {
      this.$store.dispatch("audio/getVolumes", this.volumes);

      this.all_volume = false;
      this.apply_volume = true;
      this.mobile_volume = false;
      this.volume_per_track = false;

      let volumes = this.volumes.every((element) => {
        if (element === this.volumes[0]) {
          return true;
        }
      });

      if (volumes) {
        this.volume = this.volumes[0];
      }

      this.addHistory();
    },
    getVolumeData() {
      this.volumes = this.$store.state.audio.volumes;
      if (this.volumes.every((volume) => volume === this.volumes[0])) {
        this.volume = this.volumes[0];
      } else {
        this.volume = 100;
      }
      this.volume_changed = false;
    },
    volumeOpen(isVolumeOpen) {
      console.log("volumeOpen");
      if (isVolumeOpen) {
        this.old_volumes = [...this.volumes];
      } else {
        if (!this.apply_volume) {
          this.$store.dispatch("audio/getVolumes", this.old_volumes);
        }
      }
    },
    getAudioData() {
      let data;
      let track = { ...this.track };
      let recent = [...this.data];
      if (Object.keys(track).length > 0) {
        if (track.playlist_id == this.favoriteId) {
          const index = track.subliminals.findIndex((item) => {
            return item.subliminal_id == recent[0].subliminal_id;
          });
          if (index > -1) {
            recent[0].is_liked = recent[0].is_liked == 1 ? 0 : 1;
          }

          data = recent;
        } else {
          data = recent.map((item) => {
            if (item.subliminal_id == this.favoriteId) {
              return { ...item, is_liked: item.is_liked == 1 ? 0 : 1 };
            }

            if (item.playlist_id == this.favoriteId) {
              return { ...item, is_liked: item.is_liked == 1 ? 0 : 1 };
            }

            return item;
          });
        }
        this.$store.dispatch("recent/getPlayed", data);
      }
    },
    updateCurrentTime(val) {
      if (typeof parseInt(val) == "number") {
        const current_time = (parseInt(val) * this.durationTime) / 100;
        this.$store.dispatch("audio/getCurrentTime", parseInt(current_time));
      }
    },
    getAudioTypesById() {
      this.audio_types = [];
      for (let index = 0; index < this.audio_type.length; index++) {
        const element = this.audio_type[index];
        this.getAudioTypeById(element).then((res) => {
          this.audio_types.push(res[0].name);
        });
      }
    },
    getFavoriteSubliminal() {
      let user = JSON.parse(this.user);

      const data = {
        user_id: user.user_id,
        subscription_id: user.info.subscription_id,
      };

      this.getLikeSubliminal(data).then((res) => {
        res.forEach((items) => {
          this.favoriteData.push(items);
        });
      });
    },
    getFavoritePlaylist() {
      let user = JSON.parse(this.user);

      const data = {
        user_id: user.user_id,
        subscription_id: user.info.subscription_id,
      };

      this.getLikePlaylist(data).then((res) => {
        res.forEach((items) => {
          this.favoriteData.push(items);
        });
      });
    },
    getSearchTrack() {
      const user_id = JSON.parse(this.user).user_id;
      const subscription_id = JSON.parse(this.user).info.subscription_id;

      let data = {
        user_id: user_id,
        subscription_id: subscription_id,
      };

      this.getSearch(data).then((res) => {
        data.search = res[0];

        this.getSearchResult(data).then((res) => {
          for (let index = 0; index < res.playlist.length; index++) {
            const element = res.playlist[index];
            this.searchData.push(element);
          }

          for (let index = 0; index < res.subliminal.length; index++) {
            const element = res.subliminal[index];
            this.searchData.push(element);
          }
        });
      });
    },
  },
};
</script>

<style>
#web-button > div > img,
#mobile-button > div > img {
  height: 30px !important;
}
</style>

<style scoped>
footer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
}

.card {
  min-height: 60px;
}

.card > div {
  height: 100%;
}

.progress-line {
  border-radius: 3px;
  width: calc(100% - 224px);
  margin: 0 112px;
  margin-top: -6px;
}

.cover {
  min-width: 90px;
  height: 66px;

  border-radius: 5px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

span {
  font-size: 13px;
  font-family: "Montserrat-Medium";
}

#mobile-button > img:nth-child(1) {
  height: 35px;
}

#mobile-button > img:nth-child(2) {
  height: 30px;
}

/* #web-button > img:nth-child(3) {
  height: 60px;
}

#web-button > img:nth-child(1) {
  height: 30px !important;
}

#web-button > img:nth-child(2),
#web-button > img:nth-child(4) {
  height: 40px;
} */

/* #web-button > img:nth-child(3) {
  width: 48px;
  height: 48px;
  margin: 8px 0;
}

#web-button > img:nth-child(1) {
  width: 31px;
  height: 24px;
}
#web-button > img:nth-child(5) {
  width: 26px;
  height: 24px;
}

#web-button > img:nth-child(2),
#web-button > img:nth-child(4) {
  width: 40px;
  height: 39px;
} */

#web-button > img:nth-of-type(3) {
  width: 48px;
  height: 48px;
  margin: 8px 0;
}

#web-button > img:nth-of-type(1) {
  width: 31px;
  height: 24px;
}
#web-button > img:nth-of-type(5) {
  width: 26px;
  height: 24px;
}

#web-button > img:nth-of-type(2),
#web-button > img:nth-of-type(4) {
  width: 40px !important;
  height: 39px !important;
}

.information,
#other-button,
#mobile-button {
  min-width: 200px;
  /* max-width: 200px; */
}

img {
  height: 20px;
}

.web {
  display: block;
  -moz-box-shadow: 2px 6px 8px #b7b7b7;
  -webkit-box-shadow: 2px 6px 8px #b7b7b7;
  border-width: 0.1px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
  border-color: #eeeeee;
}

.mobile {
  display: none;
}

.text-limit-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.disabled {
  opacity: 0.5;
  filter: alpha(opacity=50); /* msie */
}

@media screen and (max-width: 990px) {
  .web {
    display: none;
  }

  .mobile {
    display: block;
  }
}

.mobile .menu {
  gap: 3rem;
}

@media screen and (max-width: 500px) {
  .mobile .menu {
    gap: 2.2rem;
  }
}

@media screen and (max-width: 420px) {
  .information {
    min-width: 100px;
  }
}
</style>
