<template>
  <div>
    <Dialog
      :dialog="dialog"
      :width="800"
      :title="`Add Track Version`"
      @closeDialog="closeDialog"
    >
      <div>
        <el-upload
          ref="files"
          multiple
          class="upload-demo"
          :action="`${url}/api/local/audio`"
          :limit="3"
          :file-list="filelist"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :on-change="handleChange"
          :on-exceed="handleExceed"
        >
          <v-btn block>Upload Version</v-btn>
        </el-upload>
      </div>

      <template v-slot:action>
        <v-row class="ma-0">
          <v-col class="pl-0 pr-2">
            <v-btn block @click="submitDialog()" :disabled="!addEnabled"
              >Add</v-btn
            >
          </v-col>
          <v-col class="pl-2 pr-0">
            <v-btn block @click="closeDialog()">Cancel</v-btn>
          </v-col>
        </v-row>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "@/components/Dialog.vue";

import { addTrackInfos } from "@/api/trackinfo.js";
import { deleteLocalAudio, deleteAllLocalFiles } from "@/api/file.js";

import { url, getDuration, getMessage } from "@/utils";

export default {
  props: {
    dialog: {
      type: Boolean,
    },
  },
  components: {
    Dialog,
  },
  data: () => {
    return {
      url,
      getMessage,

      getDuration,
      deleteLocalAudio,
      deleteAllLocalFiles,
      addTrackInfos,
      index: 0,

      filelist: [],
      tablefiles: [],

      durations: [],
    };
  },
  computed: {
    addEnabled() {
      return this.tablefiles.length > 0;
    },
    uploadList() {
      return this.$store.state.audio.upload.data;
    },
    trackid() {
      return this.$store.state.audio.track.id;
    },
  },
  methods: {
    beforeRemove(file, fileList) {
      const index = this.getFileIndex(file.name);
      this.tablefiles.splice(index, 1);
      this.durations.splice(index, 1);
    },
    handleRemove(file, fileList) {
      const index = fileList.findIndex((element) => element.name === file.name);
      this.deleteLocalAudio(file.name);
      fileList.splice(index, 1);
      this.tablefiles.splice(index, 1);
      this.durations.splice(index, 1);
    },
    handleAllRemove() {
      this.deleteAllLocalFiles();
    },
    handleChange(files, fileList) {
      if (this.tablefiles.indexOf(files) == -1) {
        this.tablefiles.push(files);
        this.getDuration(files, this.durations);
      }

      this.$store.dispatch("audio/getUploadList", fileList);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `The limit is 1, you selected 
        ${files.length} files this time,
        add up to ${files.length + fileList.length} 
        totally`
      );
    },
    closeDialog() {
      this.$emit("closeDialog", false);
      this.resetDialog();
      this.handleAllRemove();
    },
    closeAlert() {
      this.alert = false;
    },
    resetDialog() {
      this.filelist = [];
      this.tablefiles = [];
    },
    getFileIndex(filename) {
      return this.tablefiles.findIndex((item) => {
        return item.name === filename;
      });
    },
    submitDialog() {
      const files = this.tablefiles;
      for (let index = 0; index < files.length; index++) {
        const element = files[index];
        const duration = this.durations[index];

        const data = {
          track_id: this.trackid,
          file: element.name,
          duration: duration,
          newversion: true,
        };

        this.addTrackInfos(data).then((res) => {
          // const data = res.data;

          this.getMessage(this, res);
          this.closeDialog();
        });
      }
    },
  },
};
</script>

<style>
.upload-demo > div {
  width: 100%;
}

.upload-demo > ul {
  padding: 0px;
}
</style>
