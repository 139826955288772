<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you aspire to become a successful mechanical engineer, this subliminal can help you reach your goals by enhancing the necessary skills and knowledge. By targeting your subconscious mind, it encourages growth and expertise in various areas of mechanical engineering."
    },
    {
      type: 'heading',
      text: 'Key areas where this subliminal can benefit you include:'
    },
    {
      type: 'list',
      items: [
        "Developing a deep understanding of mechanical engineering principles and concepts.",
        "Gaining expertise in design software, analysis tools, and other technical skills.",
        "Improving problem-solving abilities and critical thinking skills.",
        "Enhancing project management and organizational skills.",
        "Building confidence in your abilities to tackle complex engineering tasks.",
        "Cultivating effective communication and teamwork skills for collaborating with colleagues and stakeholders.",
        "Staying motivated and disciplined throughout your journey to becoming a successful mechanical engineer."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can harness the power of your subconscious mind to accelerate your progress in the field of mechanical engineering. As you gain expertise, confidence, and motivation, you'll be better equipped to tackle engineering challenges and achieve your dream of becoming a successful mechanical engineer."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a journey to become a successful mechanical engineer requires dedication, perseverance, and a growth mindset. This subliminal is designed to support your aspirations and help you develop the necessary skills and knowledge to excel in the field. Before you begin, consider the following tips to enhance your listening experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your goals: Clearly define what you want to achieve as a mechanical engineer, whether it's mastering specific technical skills, improving problem-solving abilities, or honing your project management expertise.",
        "Visualize your success: Take a moment to imagine yourself thriving in your desired role as a successful mechanical engineer. Picture yourself confidently tackling complex challenges and delivering innovative solutions.",
        "Embrace a growth mindset: Cultivate a belief in your ability to learn and grow as a mechanical engineer. Embrace challenges as opportunities for growth and remain open to continuous learning and improvement.",
        "Set a study routine: Create a structured study plan to ensure consistent learning and practice. Allocate dedicated time to deepen your knowledge in areas such as design, analysis, and project management.",
        "Stay focused and motivated: Use the subliminal affirmations as a tool to energize your determination and maintain a positive mindset throughout your journey towards becoming a successful mechanical engineer."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your routine and implementing these preparation tips, you can increase your chances of achieving your dream of becoming a successful mechanical engineer. Trust in your abilities, stay committed to your goals, and let the transformation begin!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the 'Become a Successful Mechanical Engineer' subliminal session! By investing in your personal and professional growth, you've taken a major step towards achieving your dream of becoming a successful mechanical engineer."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Define the specific areas of mechanical engineering you want to specialize in and the skills you want to develop.",
        "Create a study plan: Design a structured plan to acquire the necessary knowledge and expertise through courses, books, or online resources.",
        "Take on practical projects: Engage in real-world projects, internships, or hands-on experiences to apply and enhance your skills.",
        "Network with professionals: Attend industry events, connect with experienced mechanical engineers, and seek mentorship opportunities to gain valuable insights and guidance."
      ]
    },
    {
      type: 'paragraph',
      text: "Becoming a successful mechanical engineer requires dedication, continuous learning, and a growth mindset. Embrace challenges, persevere through setbacks, and stay motivated on your journey. With the right mindset and consistent effort, you can turn your dream into reality. Best of luck in your future endeavors as a mechanical engineer!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What are the key skills required to become a successful mechanical engineer?`, 
    answer: `To become a successful mechanical engineer, it is important to have a strong foundation in mathematics, physics, and computer-aided design (CAD). Other key skills include problem-solving, critical thinking, communication, and teamwork. Additionally, staying updated with the latest advancements in the field and being adaptable are crucial for success. Continuous learning and professional development can help you refine and expand your skills.`,
    open: false
  },
  { 
    question: `How can I gain practical experience in the field of mechanical engineering?`, 
    answer: `Gaining practical experience in mechanical engineering can be achieved through internships, co-op programs, and entry-level positions. These opportunities allow you to apply your theoretical knowledge to real-world projects and develop practical skills. Additionally, getting involved in engineering projects, participating in professional organizations, and conducting research can also provide valuable experience.`,
    open: false 
  },
  { 
    question: `What are the opportunities for specialization within the field of mechanical engineering?`, 
    answer: `Mechanical engineering offers a wide range of specializations. Some common areas of specialization include automotive engineering, aerospace engineering, robotics, renewable energy, HVAC (heating, ventilation, and air conditioning), and manufacturing. It is important to explore your interests and consider the industry's current and future demands to choose a specialization that aligns with your goals and interests.`,
    open: false 
  },
  { 
    question: `What are the typical career paths for mechanical engineers?`, 
    answer: `Mechanical engineers have a variety of career paths available to them. They can work in industries such as automotive, aerospace, energy, manufacturing, and consulting. Career opportunities include design engineer, project engineer, research engineer, production engineer, and management roles. Some mechanical engineers also pursue advanced degrees and research positions in academia.`,
    open: false 
  },
  { 
    question: `What types of projects do mechanical engineers typically work on?`, 
    answer: `Mechanical engineers work on a wide range of projects, including designing and developing machines, tools, and mechanical systems. They may work on projects related to product design, manufacturing process optimization, energy efficiency, structural analysis, and automation. Their work can involve anything from creating detailed 3D models and simulations to overseeing the construction and testing of prototypes.`,
    open: false 
  },
  { 
    question: `Is continuing education important for mechanical engineers?`, 
    answer: `Yes, continuing education is vital for mechanical engineers to stay updated with the latest technological advancements, industry standards, and engineering practices. It allows engineers to expand their knowledge, enhance their skills, and remain competitive in the job market. Pursuing certifications, attending conferences, and participating in workshops are some ways to engage in lifelong learning as a mechanical engineer.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>