export const faqs = [
  {
    id: "1",
    name: "Safety Question",
  },
  {
    id: "2",
    name: "Questions About Results",
  },
  {
    id: "3",
    name: "Usage Scenario Questions",
  },
  {
    id: "4",
    name: "Listening Activity Questions",
  },
  {
    id: "5",
    name: "Technical Questions",
  },
  {
    id: "6",
    name: "Audio/Product Related Questions",
  },
];

export const faqs1 = [
  {
    question: "Are Magus Subliminals safe?",
    answer:
      "Yes, because all Magus Subliminals do not contain any negative or harmful statements to the listener. " +
      "All Subliminals and statements are carefully planned and designed to manifest effective, healthy, and safe results.",
  },
  {
    question: "Are Magus Subliminals safe for children?",
    answer:
      "Unless there are indicated age requirements on the product description or instructions of the subliminal, " +
      "all Magus Subliminal audios are safe for children ages seven (7) and above. However, for children of ages six (6) " +
      "and below, avoid exposing them to the subliminal audios because the information density of the messages encoded in the " +
      "audio might be overwhelming for them. For subliminals that change the body, the recommended age is 14 years old and up.",
  },
  {
    question: "Are Magus Subliminals safe for pregnant women?",
    answer:
      "Magus Subliminals have higher information density compared to all the subliminal audios in the market. Magus Subliminals have different " +
      "technologies that allow you to shift your focus and state of mind to make your mind function in its most optimal state to receive and " +
      "process the subliminal messages. Magus Subliminals have higher energy consumption and might make you drowsy or sleepy in the process. " +
      "Therefore, you should never listen to subliminals while driving, operating heavy machinery, or doing important work that requires focus on the task." +
      "We also have subliminal titles and modules for driving skills improvement and safe driving behaviors that can aid you in your driving." +
      "Recommended rest/break before driving" +
      "<ul class='mt-2'>" +
      "<li>M-Type – At least 30 mins-1 hour.</li>" +
      "<li>U-Type – At least 1-2 hours.</li>" +
      "<li>G-Type – At least 2 hours</li>" +
      "</ul>",
  },
  {
    question: "Are subliminals safe to listen while driving?",
    answer:
      "Yes, because all Magus Subliminals do not contain any negative or " +
      "harmful statements to the listener. All Subliminals and statements are " +
      "carefully planned and designed to manifest effective, healthy, and safe results.",
  },
  {
    question: "Is there any form of advertising in your subliminal?",
    answer:
      "All Magus Subliminal audios do not contain any content or messages that " +
      "advertise any product, company, party, institution, or individual in any way.",
  },
  {
    question:
      "Will the subliminals clash against my religious or spiritual beliefs?",
    answer:
      "All the statements in the subliminal do not include any form of content " +
      "to alter or change your religious or spiritual beliefs at all nor influence " +
      "you to alter or change your religious or spiritual beliefs. All statements are " +
      "designed to empower you, no matter your religious or spiritual beliefs",
  },
  {
    question: "Do we have gender considerations in the product?",
    answer:
      "Unless indicated in the title or description, all the subliminal audios offered " +
      "are gender-neutral and work regardless of the sexual orientation of the user without " +
      "having any adverse or unintended effects on the sexuality and sexual orientation of the user." +
      "For custom personal projects, we can apply gender-specific wordings in the script to improve " +
      "the user's experience and results. For family usage custom subliminals, all statements are gender neutral.",
  },
  {
    question:
      "Does your lifespan decrease while listening to Subliminal Audios?",
    answer:
      "It is natural as time moves forward that our bodies age and deteriorates thus, " +
      "our lifespan decreases as well. Therefore, whatever we do naturally decreases our " +
      "lifespan including listening to subliminals. But if you are using specialized subliminals, " +
      "like health subliminals or rejuvenating subliminals. Basically, your body is healing and " +
      "becoming healthier therefore it is possible to increase your lifespan if you listen to Subliminals. " +
      "By using Ultiliminals and Godliminals, this can be achievable on a certain degree. The Chief Creator’s " +
      "personal story and example is a living proof that you can achieve “Fountain of Youth” effects while using subliminals.",
  },
];

export const faqs2 = [
  {
    question: "How long will it take to see results?",
    answer:
      "With how the Magus Subliminals have progressed in terms of power and technology, " +
      "noticeable results in terms of state and awareness shifting are manifested almost" +
      "instantly or within a day given the right conditions. Permanent results and physical " +
      "changes are expected to manifest within 3 months of listening according to the instructions. " +
      "For more details and estimations, you can refer with your subliminal audio’s product manual. <br>" +
      "Differences of results speed" +
      "<ul>" +
      "<li>M type – minutes - hours</li>" +
      "<li>U type – seconds - minutes</li>" +
      "<li>U type – seconds - minutes</li>" +
      "</ul>",
  },
  {
    question: "How can I tell if subliminal messages work for me?",
    answer:
      "All Magus Subliminal Audios provides noticeable results and changes for you even " +
      "if they were happening smoothly and naturally. Your thought patterns are consistently " +
      "being upgraded and renewed in ways that truly empower you to naturally achieve the " +
      "changes you desire. However, you also need to be patience and dedicated in the process " +
      "to achieve permanent and lasting results.",
  },
  {
    question:
      "Will the subliminal messages work if English is not my native language?",
    answer:
      "All Magus Subliminal Audios works as intended regardless of your language due to our " +
      "proprietary scripts and technologies. However, having a strong English language fluency " +
      "will make it easier for the listener to process the messages." +
      "Linguistics training and guide – All magus subliminal audios for U and G type all have" +
      " a built-in linguistics training module that can help you comprehend all the scripts easily.",
  },
  {
    question: "Are the results permanent?",
    answer:
      "All Magus Subliminal Audios provide permanent results due to how we make them. " +
      "For permanent changes to happen, first, you must internalize the change at the core of your " +
      "being, resolve all the problems and blockages associated with the change at the root cause level. " +
      "Second, replace all negative associations with new positive paradigms and associations. " +
      "Third, provide stability at all levels and realizations to further cement your changes while making it " +
      "perfectly smooth, natural, and healthy for you. All processes and changes are done automatically through " +
      "the help of Magus Subliminals, and this is only the tip of the iceberg of what Magus Subliminals can do.",
  },
  {
    question: "What can I do to have faster results?",
    answer:
      "Magus Subliminals works to manifest results as intended in a fast and self-adapting manner. " +
      "That is why it works regardless of your current situation. But to achieve faster results, " +
      "first, you need to make sure that you use the subliminal audio as instructed. " +
      "Next, you got to have the right belief and motivation to help yourself achieve what you wish " +
      "to achieve with the help of the subliminal, no matter what it takes. Lastly, do the things " +
      "that advance you and avoid doing the things that delay you, block you, or move you away from reaching your desired goal.",
  },
  {
    question:
      "What are the best ways to listen to subliminal audios for faster results?",
    answer:
      "The following are some of the tips or actionable steps of listening to the subliminal for faster results:" +
      "Choose the right equipment – make sure that the speakers or phones that you are using can deliver the sound in an uncompromised way. " +
      "You can also refer to the Recommended Devices list. Scheduling your listening time – scheduling your listening time makes your mind " +
      "adapt to the habit of self-development that you set for yourself. Goal-setting and prioritization – how do you want the subliminal " +
      "audio to help you achieve your goal? Tie your general goals and priorities as Visualization or focusing while listening –" +
      "refer to your manual if you are suggested or advised to visualize or meditate while listening. Detailed instructions are also " +
      "presented in your designated manual.",
  },
  {
    question: "Do the results vary from user to user?",
    answer:
      "The intended results and goals of the subliminal audio are sure to manifest in due time; " +
      "however, each person is unique and may have different circumstances and experiences. " +
      "Therefore, there are nuances and variations in how the effects of the subliminal are triggered" +
      " as well as how the manifestation happens. Other factors include but not limited to user temperament " +
      "and personality, resources available to the listener, total exposure time to the subliminal audio, " +
      "and other variables that will be considered.",
  },
  {
    question: "Do we have gender considerations in the product?",
    answer:
      "Unless indicated in the title or description, all the subliminal audios offered are gender-neutral " +
      "and work regardless of the sexual orientation of the user without having any adverse or unintended effects " +
      "on the sexuality and sexual orientation of the user. For custom personal projects, we can apply gender-specific " +
      "wordings in the script to improve the user's experience and results." +
      "For family usage custom subliminals, all statements are gender neutral.",
  },
  {
    question:
      "Once I have achieved my goal, do I still need to listen to the subliminal audio?",
    answer:
      "It is optional to listen to the audio once you feel that you have gained the results you want. " +
      "However, it is strongly suggested to listen continuously to the audio for stronger and " +
      "lasting changes. Listening to the audio again gives you other benefits that are considered as a " +
      "bonus due to the supporting modules of the program.",
  },
];

export const faqs3 = [
  {
    question: "Is it okay to expose family members without them knowing?",
    answer:
      "Manipulating the free will of others without consent is unethical." +
      "First, you must inform your family members if you want to expose them to the audio " +
      "for them to benefit from the effects. Second, if you are using Custom Subliminals, " +
      "Ultiliminals, and Godliminals, their name must be embedded in the audio for them to " +
      "benefit and not cause violations to anti-piracy and unauthorized usage.",
  },
];

export const faqs4 = [
  {
    question: "How should I listen?",
    answer: "TEST",
  },
  {
    question: "Can I listen during sleep?",
    answer: "TEST",
  },
  {
    question: "Can I listen while doing other activities?",
    answer: "TEST",
  },
  {
    question: "Is it okay to meditate while listening to subliminals?",
    answer: "TEST",
  },
  {
    question: "Is it okay to use affirmations while listening to subliminals?",
    answer:
      "It is okay to use affirmations, provided that these affirmations " +
      "that you recite must be worded properly and aligned to your goal.",
  },
  {
    question: "How many audios can I listen at the same time?",
    answer:
      "Due to how Magus Subliminals are built, it is required for you to " +
      "listen to a single audio for only one at a time and NEVER at the same time. " +
      "However, you can simply create a playlist and listen to different topics of " +
      "subliminals of your choosing at your own pace.",
  },
  {
    question: "How many hours is the recommended listening time?",
    answer:
      "The recommended listening time depends on the instructions and recommendations " +
      "from your product manual or guide. As a rule of thumb, you can listen more than " +
      "the recommended time, but it is not lesser than the recommended time to have " +
      "consistent usage and faster results.",
  },
  {
    question: "Do you have any playlist or topic recommendations?",
    answer:
      "Depending on the goal that you are trying to achieve, we list down different " +
      "playlists and sequences that can help you achieve your desired results. " +
      "You can find them in the recommendations section of our website. " +
      "You can also ask for recommendations from your subliminal coach.",
  },
];

export const faqs5 = [
  {
    question: "Do you have suggestions for a sound system setup?",
    answer:
      "As a requirement for Ultrasonic/ Silent Subliminals, the speaker must be able to " +
      "deliver sounds up to 20,000 Hz. However, for the optimal listening experience, " +
      "the recommended speakers are stereo or surround type set-up. Avoid playing Magus Subliminals " +
      "to a wireless speaker due to potential audio quality loss when playing over a wireless connection.",
  },
  {
    question: "Is it possible to play subliminal audios on a mobile phone?",
    answer:
      "Yes, you can perfectly play Magus Subliminals on your phone. For Wave Audio (.wav) formats, " +
      "it is recommended that you download an app that can play .wav audio files. Soon, we will be " +
      "releasing the Magus streaming mobile app that is available on Android and iOS devices.",
  },
  {
    question: "What are your recommended apps to play subliminals?",
    answer:
      "Apps that are compatible to play different kinds of audio formats such as FLAC or WAV format " +
      "and can deliver audios with high sound quality above 120 kbps are strongly recommended. " +
      "My personal recommendation is VLC Media Player.",
  },
  {
    question: "What are the requirements for ultrasonic subliminals?",
    answer:
      "As a requirement for Ultrasonic/ Silent Subliminals, the speaker must be able to deliver sounds up to 20,000 Hz. " +
      "Check the specifications of your listening gear if it fits the criteria.",
  },
  {
    question: "Are headphones required to listen to subliminals?",
    answer:
      "Listening to headset, headphones, or earbuds are optional and up to your preferences. " +
      "However, you should avoid listening through wireless connection headsets due to potential audio " +
      "quality loss. To avoid hearing problems or damages, always listen to a low and comfortable volume.",
  },
  {
    question: "Do you recommend bone conduction earphones?",
    answer:
      "Tests and customer feedback show that Magus Subliminals is compatible with most wired bone " +
      "conduction earphones. However, results are not guaranteed if you are using wireless bone " +
      "conduction earphones or wireless headsets due to potential quality loss.",
  },
  {
    question: "Can I burn the subliminal audios to CD?",
    answer:
      "Magus Subliminals will lose its quality due to a different encoding formats and quality loss " +
      "when being burned on the CD. Due to this reason, the file is considered corrupted and the audio " +
      "might not work or deliver results as intended. Therefore, DO NOT burn the subliminal to any CD, DVD, " +
      "or any recording media.",
  },
  {
    question: "How many audios can I listen at the same time?",
    answer:
      "Due to how Magus Subliminals are built, it is required for you to listen to a single audio " +
      "for only one at a time and NEVER at the same time. However, you can simply create a playlist " +
      "and listen to different topics of subliminals of your choosing at your own pace.",
  },
  {
    question:
      "Can I upload the subliminal audios to a streaming site (YouTube, Spotify, Soundcloud, etc.)?",
    answer:
      "No, and it will automatically be classified as piracy and unauthorized and illegal distribution " +
      "that automatically triggers the anti-piracy script.",
  },
  {
    question: "What are the subliminal build formats you offer?",
    answer:
      "You can upload but never grant access to unauthorized people outside the allowable people " +
      "on the Master List. In case of custom personal and name embedded subliminal, other people " +
      "can only have the access to the subliminal file except for the creator, magus customer support " +
      "and affiliates, and you.",
  },
  {
    question:
      "Can I reupload the subliminal audios to another online storage device for backup?",
    answer:
      "Build formats are the formatting used to encode the messages in subliminal and " +
      "how they are presented to the listener. Currently, the subliminal formats that we offer are " +
      "Pure Track, Masked Track, Silent Track, and Hybrid Track which is the combination of Masked " +
      "and Silent Track. For custom orders, the subliminal messages can be encoded on the music of your choice. " +
      "Inside the streaming app, Hybrid and Masked formats are not uploaded since you can listen to Pure " +
      "and Silent tracks and adjust the respective volumes of each.",
  },
];

export const faqs6 = [
  {
    question: "What are the audio formats of your subliminal Audios?",
    answer:
      "Audio formats are the file type used when exporting the subliminal audio content. " +
      "We offer audio formats such as .mp3, FLAC, and .wav audio in some cases." +
      "In our subliminal streaming app, subliminal audios are uploaded in .wav format.",
  },
  {
    question: "How long is the subliminal audio?",
    answer:
      "The length varies depending on the product. Please refer to the respective manual " +
      "or product information of the subliminal you are using. The audio length and " +
      "the recommended listening are different and it is specified in your manual." +
      "The usual length of the audio is 30 minutes of listening time and the audio has " +
      "already covered the whole script multiple times.",
  },
  {
    question: "How are subliminal audios delivered?",
    answer:
      "The products are delivered digitally and are only available for download via the online store. " +
      "For the app, everything is streamed directly to your device. Whenever you make a purchase using " +
      "your account, the audio is automatically added to your online library. Custom Audios are delivered through a " +
      "specific download link. It will be sent to you by the dedicated staff after uploading. It will also be " +
      "available on your account via Magus online streaming Web and Mobile apps.",
  },
  {
    question: "Do you offer subliminal audios on CD, DVD, or USB?",
    answer:
      "We do not offer Magus Subliminals on any form of physical media. It is only available for download " +
      "at our online store and only available for listening on our App.",
  },
  {
    question: "Do you post the script or affirmation contents?",
    answer:
      "We do not post affirmations or specific statements due to various reasons. " +
      "One of them is that it contains highly classified trade secrets of the company. " +
      "We do, however, list down the detailed features of a given product in its respective " +
      "product page and instruction manual. We also give out recommended affirmations for you to recite.",
  },
  {
    question: "Do you post the script or affirmation contents?",
    answer:
      "Yes. You can find general affirmations for manifestation in the Subliminal Guide and Coaching Handbook. " +
      "The book also teaches you how to create affirmations for yourself. We will post other specific affirmations " +
      "of your desired topics soon. You can also ask your subliminal coach for affirmations based on your circumstances and situation.",
  },
];

export const faqsAll = [
  {
    question: "Are Magus Subliminals safe?",
    answer:
      "Yes, because all Magus Subliminals do not contain any negative or harmful statements to the listener. " +
      "All Subliminals and statements are carefully planned and designed to manifest effective, healthy, and safe results.",
  },
  {
    question: "Are Magus Subliminals safe for children?",
    answer:
      "Unless there are indicated age requirements on the product description or instructions of the subliminal, " +
      "all Magus Subliminal audios are safe for children ages seven (7) and above. However, for children of ages six (6) " +
      "and below, avoid exposing them to the subliminal audios because the information density of the messages encoded in the " +
      "audio might be overwhelming for them. For subliminals that change the body, the recommended age is 14 years old and up.",
  },
  {
    question: "Are Magus Subliminals safe for pregnant women?",
    answer:
      "Magus Subliminals have higher information density compared to all the subliminal audios in the market. Magus Subliminals have different " +
      "technologies that allow you to shift your focus and state of mind to make your mind function in its most optimal state to receive and " +
      "process the subliminal messages. Magus Subliminals have higher energy consumption and might make you drowsy or sleepy in the process. " +
      "Therefore, you should never listen to subliminals while driving, operating heavy machinery, or doing important work that requires focus on the task." +
      "We also have subliminal titles and modules for driving skills improvement and safe driving behaviors that can aid you in your driving." +
      "Recommended rest/break before driving" +
      "<ul class='mt-2'>" +
      "<li>M-Type – At least 30 mins-1 hour.</li>" +
      "<li>U-Type – At least 1-2 hours.</li>" +
      "<li>G-Type – At least 2 hours</li>" +
      "</ul>",
  },
  {
    question: "Are subliminals safe to listen while driving?",
    answer:
      "Yes, because all Magus Subliminals do not contain any negative or " +
      "harmful statements to the listener. All Subliminals and statements are " +
      "carefully planned and designed to manifest effective, healthy, and safe results.",
  },
  {
    question: "Is there any form of advertising in your subliminal?",
    answer:
      "All Magus Subliminal audios do not contain any content or messages that " +
      "advertise any product, company, party, institution, or individual in any way.",
  },
  {
    question:
      "Will the subliminals clash against my religious or spiritual beliefs?",
    answer:
      "All the statements in the subliminal do not include any form of content " +
      "to alter or change your religious or spiritual beliefs at all nor influence " +
      "you to alter or change your religious or spiritual beliefs. All statements are " +
      "designed to empower you, no matter your religious or spiritual beliefs",
  },
  {
    question: "Do we have gender considerations in the product?",
    answer:
      "Unless indicated in the title or description, all the subliminal audios offered " +
      "are gender-neutral and work regardless of the sexual orientation of the user without " +
      "having any adverse or unintended effects on the sexuality and sexual orientation of the user." +
      "For custom personal projects, we can apply gender-specific wordings in the script to improve " +
      "the user's experience and results. For family usage custom subliminals, all statements are gender neutral.",
  },
  {
    question:
      "Does your lifespan decrease while listening to Subliminal Audios?",
    answer:
      "It is natural as time moves forward that our bodies age and deteriorates thus, " +
      "our lifespan decreases as well. Therefore, whatever we do naturally decreases our " +
      "lifespan including listening to subliminals. But if you are using specialized subliminals, " +
      "like health subliminals or rejuvenating subliminals. Basically, your body is healing and " +
      "becoming healthier therefore it is possible to increase your lifespan if you listen to Subliminals. " +
      "By using Ultiliminals and Godliminals, this can be achievable on a certain degree. The Chief Creator’s " +
      "personal story and example is a living proof that you can achieve “Fountain of Youth” effects while using subliminals.",
  },
  {
    question: "How long will it take to see results?",
    answer:
      "With how the Magus Subliminals have progressed in terms of power and technology, " +
      "noticeable results in terms of state and awareness shifting are manifested almost" +
      "instantly or within a day given the right conditions. Permanent results and physical " +
      "changes are expected to manifest within 3 months of listening according to the instructions. " +
      "For more details and estimations, you can refer with your subliminal audio’s product manual. <br>" +
      "Differences of results speed" +
      "<ul>" +
      "<li>M type – minutes - hours</li>" +
      "<li>U type – seconds - minutes</li>" +
      "<li>U type – seconds - minutes</li>" +
      "</ul>",
  },
  {
    question: "How can I tell if subliminal messages work for me?",
    answer:
      "All Magus Subliminal Audios provides noticeable results and changes for you even " +
      "if they were happening smoothly and naturally. Your thought patterns are consistently " +
      "being upgraded and renewed in ways that truly empower you to naturally achieve the " +
      "changes you desire. However, you also need to be patience and dedicated in the process " +
      "to achieve permanent and lasting results.",
  },
  {
    question:
      "Will the subliminal messages work if English is not my native language?",
    answer:
      "All Magus Subliminal Audios works as intended regardless of your language due to our " +
      "proprietary scripts and technologies. However, having a strong English language fluency " +
      "will make it easier for the listener to process the messages." +
      "Linguistics training and guide – All magus subliminal audios for U and G type all have" +
      " a built-in linguistics training module that can help you comprehend all the scripts easily.",
  },
  {
    question: "Are the results permanent?",
    answer:
      "All Magus Subliminal Audios provide permanent results due to how we make them. " +
      "For permanent changes to happen, first, you must internalize the change at the core of your " +
      "being, resolve all the problems and blockages associated with the change at the root cause level. " +
      "Second, replace all negative associations with new positive paradigms and associations. " +
      "Third, provide stability at all levels and realizations to further cement your changes while making it " +
      "perfectly smooth, natural, and healthy for you. All processes and changes are done automatically through " +
      "the help of Magus Subliminals, and this is only the tip of the iceberg of what Magus Subliminals can do.",
  },
  {
    question: "What can I do to have faster results?",
    answer:
      "Magus Subliminals works to manifest results as intended in a fast and self-adapting manner. " +
      "That is why it works regardless of your current situation. But to achieve faster results, " +
      "first, you need to make sure that you use the subliminal audio as instructed. " +
      "Next, you got to have the right belief and motivation to help yourself achieve what you wish " +
      "to achieve with the help of the subliminal, no matter what it takes. Lastly, do the things " +
      "that advance you and avoid doing the things that delay you, block you, or move you away from reaching your desired goal.",
  },
  {
    question:
      "What are the best ways to listen to subliminal audios for faster results?",
    answer:
      "The following are some of the tips or actionable steps of listening to the subliminal for faster results:" +
      "Choose the right equipment – make sure that the speakers or phones that you are using can deliver the sound in an uncompromised way. " +
      "You can also refer to the Recommended Devices list. Scheduling your listening time – scheduling your listening time makes your mind " +
      "adapt to the habit of self-development that you set for yourself. Goal-setting and prioritization – how do you want the subliminal " +
      "audio to help you achieve your goal? Tie your general goals and priorities as Visualization or focusing while listening –" +
      "refer to your manual if you are suggested or advised to visualize or meditate while listening. Detailed instructions are also " +
      "presented in your designated manual.",
  },
  {
    question: "Do the results vary from user to user?",
    answer:
      "The intended results and goals of the subliminal audio are sure to manifest in due time; " +
      "however, each person is unique and may have different circumstances and experiences. " +
      "Therefore, there are nuances and variations in how the effects of the subliminal are triggered" +
      " as well as how the manifestation happens. Other factors include but not limited to user temperament " +
      "and personality, resources available to the listener, total exposure time to the subliminal audio, " +
      "and other variables that will be considered.",
  },
  {
    question: "Do we have gender considerations in the product?",
    answer:
      "Unless indicated in the title or description, all the subliminal audios offered are gender-neutral " +
      "and work regardless of the sexual orientation of the user without having any adverse or unintended effects " +
      "on the sexuality and sexual orientation of the user. For custom personal projects, we can apply gender-specific " +
      "wordings in the script to improve the user's experience and results." +
      "For family usage custom subliminals, all statements are gender neutral.",
  },
  {
    question:
      "Once I have achieved my goal, do I still need to listen to the subliminal audio?",
    answer:
      "It is optional to listen to the audio once you feel that you have gained the results you want. " +
      "However, it is strongly suggested to listen continuously to the audio for stronger and " +
      "lasting changes. Listening to the audio again gives you other benefits that are considered as a " +
      "bonus due to the supporting modules of the program.",
  },
  {
    question: "Is it okay to expose family members without them knowing?",
    answer:
      "Manipulating the free will of others without consent is unethical." +
      "First, you must inform your family members if you want to expose them to the audio " +
      "for them to benefit from the effects. Second, if you are using Custom Subliminals, " +
      "Ultiliminals, and Godliminals, their name must be embedded in the audio for them to " +
      "benefit and not cause violations to anti-piracy and unauthorized usage.",
  },
  {
    question: "How should I listen?",
    answer: "TEST",
  },
  {
    question: "Can I listen during sleep?",
    answer: "TEST",
  },
  {
    question: "Can I listen while doing other activities?",
    answer: "TEST",
  },
  {
    question: "Is it okay to meditate while listening to subliminals?",
    answer: "TEST",
  },
  {
    question: "Is it okay to use affirmations while listening to subliminals?",
    answer:
      "It is okay to use affirmations, provided that these affirmations " +
      "that you recite must be worded properly and aligned to your goal.",
  },
  {
    question: "How many audios can I listen at the same time?",
    answer:
      "Due to how Magus Subliminals are built, it is required for you to " +
      "listen to a single audio for only one at a time and NEVER at the same time. " +
      "However, you can simply create a playlist and listen to different topics of " +
      "subliminals of your choosing at your own pace.",
  },
  {
    question: "How many hours is the recommended listening time?",
    answer:
      "The recommended listening time depends on the instructions and recommendations " +
      "from your product manual or guide. As a rule of thumb, you can listen more than " +
      "the recommended time, but it is not lesser than the recommended time to have " +
      "consistent usage and faster results.",
  },
  {
    question: "Do you have any playlist or topic recommendations?",
    answer:
      "Depending on the goal that you are trying to achieve, we list down different " +
      "playlists and sequences that can help you achieve your desired results. " +
      "You can find them in the recommendations section of our website. " +
      "You can also ask for recommendations from your subliminal coach.",
  },
  {
    question: "Do you have suggestions for a sound system setup?",
    answer:
      "As a requirement for Ultrasonic/ Silent Subliminals, the speaker must be able to " +
      "deliver sounds up to 20,000 Hz. However, for the optimal listening experience, " +
      "the recommended speakers are stereo or surround type set-up. Avoid playing Magus Subliminals " +
      "to a wireless speaker due to potential audio quality loss when playing over a wireless connection.",
  },
  {
    question: "Is it possible to play subliminal audios on a mobile phone?",
    answer:
      "Yes, you can perfectly play Magus Subliminals on your phone. For Wave Audio (.wav) formats, " +
      "it is recommended that you download an app that can play .wav audio files. Soon, we will be " +
      "releasing the Magus streaming mobile app that is available on Android and iOS devices.",
  },
  {
    question: "What are your recommended apps to play subliminals?",
    answer:
      "Apps that are compatible to play different kinds of audio formats such as FLAC or WAV format " +
      "and can deliver audios with high sound quality above 120 kbps are strongly recommended. " +
      "My personal recommendation is VLC Media Player.",
  },
  {
    question: "What are the requirements for ultrasonic subliminals?",
    answer:
      "As a requirement for Ultrasonic/ Silent Subliminals, the speaker must be able to deliver sounds up to 20,000 Hz. " +
      "Check the specifications of your listening gear if it fits the criteria.",
  },
  {
    question: "Are headphones required to listen to subliminals?",
    answer:
      "Listening to headset, headphones, or earbuds are optional and up to your preferences. " +
      "However, you should avoid listening through wireless connection headsets due to potential audio " +
      "quality loss. To avoid hearing problems or damages, always listen to a low and comfortable volume.",
  },
  {
    question: "Do you recommend bone conduction earphones?",
    answer:
      "Tests and customer feedback show that Magus Subliminals is compatible with most wired bone " +
      "conduction earphones. However, results are not guaranteed if you are using wireless bone " +
      "conduction earphones or wireless headsets due to potential quality loss.",
  },
  {
    question: "Can I burn the subliminal audios to CD?",
    answer:
      "Magus Subliminals will lose its quality due to a different encoding formats and quality loss " +
      "when being burned on the CD. Due to this reason, the file is considered corrupted and the audio " +
      "might not work or deliver results as intended. Therefore, DO NOT burn the subliminal to any CD, DVD, " +
      "or any recording media.",
  },
  {
    question: "How many audios can I listen at the same time?",
    answer:
      "Due to how Magus Subliminals are built, it is required for you to listen to a single audio " +
      "for only one at a time and NEVER at the same time. However, you can simply create a playlist " +
      "and listen to different topics of subliminals of your choosing at your own pace.",
  },
  {
    question:
      "Can I upload the subliminal audios to a streaming site (YouTube, Spotify, Soundcloud, etc.)?",
    answer:
      "No, and it will automatically be classified as piracy and unauthorized and illegal distribution " +
      "that automatically triggers the anti-piracy script.",
  },
  {
    question: "What are the subliminal build formats you offer?",
    answer:
      "You can upload but never grant access to unauthorized people outside the allowable people " +
      "on the Master List. In case of custom personal and name embedded subliminal, other people " +
      "can only have the access to the subliminal file except for the creator, magus customer support " +
      "and affiliates, and you.",
  },
  {
    question:
      "Can I reupload the subliminal audios to another online storage device for backup?",
    answer:
      "Build formats are the formatting used to encode the messages in subliminal and " +
      "how they are presented to the listener. Currently, the subliminal formats that we offer are " +
      "Pure Track, Masked Track, Silent Track, and Hybrid Track which is the combination of Masked " +
      "and Silent Track. For custom orders, the subliminal messages can be encoded on the music of your choice. " +
      "Inside the streaming app, Hybrid and Masked formats are not uploaded since you can listen to Pure " +
      "and Silent tracks and adjust the respective volumes of each.",
  },
  {
    question: "What are the audio formats of your subliminal Audios?",
    answer:
      "Audio formats are the file type used when exporting the subliminal audio content. " +
      "We offer audio formats such as .mp3, FLAC, and .wav audio in some cases." +
      "In our subliminal streaming app, subliminal audios are uploaded in .wav format.",
  },
  {
    question: "How long is the subliminal audio?",
    answer:
      "The length varies depending on the product. Please refer to the respective manual " +
      "or product information of the subliminal you are using. The audio length and " +
      "the recommended listening are different and it is specified in your manual." +
      "The usual length of the audio is 30 minutes of listening time and the audio has " +
      "already covered the whole script multiple times.",
  },
  {
    question: "How are subliminal audios delivered?",
    answer:
      "The products are delivered digitally and are only available for download via the online store. " +
      "For the app, everything is streamed directly to your device. Whenever you make a purchase using " +
      "your account, the audio is automatically added to your online library. Custom Audios are delivered through a " +
      "specific download link. It will be sent to you by the dedicated staff after uploading. It will also be " +
      "available on your account via Magus online streaming Web and Mobile apps.",
  },
  {
    question: "Do you offer subliminal audios on CD, DVD, or USB?",
    answer:
      "We do not offer Magus Subliminals on any form of physical media. It is only available for download " +
      "at our online store and only available for listening on our App.",
  },
  {
    question: "Do you post the script or affirmation contents?",
    answer:
      "We do not post affirmations or specific statements due to various reasons. " +
      "One of them is that it contains highly classified trade secrets of the company. " +
      "We do, however, list down the detailed features of a given product in its respective " +
      "product page and instruction manual. We also give out recommended affirmations for you to recite.",
  },
  {
    question: "Do you post the script or affirmation contents?",
    answer:
      "Yes. You can find general affirmations for manifestation in the Subliminal Guide and Coaching Handbook. " +
      "The book also teaches you how to create affirmations for yourself. We will post other specific affirmations " +
      "of your desired topics soon. You can also ask your subliminal coach for affirmations based on your circumstances and situation.",
  },
];
export const sidebar = {
  user: [
    {
      text: "Home",
      icon: "mdi-home",
      to: "/user/overview",
    },
    {
      text: "Search",
      icon: "mdi-magnify",
      to: "/user/search",
    },
    {
      text: "Recommendation",
      icon: "mdi-chart-bar-stacked",
      to: "/user/recommend",
    },
    {
      text: "MyPlaylist",
      icon: "mdi-playlist-play",
      to: "/user/own/playlist",
    },
    {
      text: "Settings",
      icon: "mdi-cog",
      to: "/user/settings",
    },
  ],
  main: [
    {
      text: "Home",
      icon: "mdi-home",
      to: "/home",
    },
    {
      text: "Science",
      icon: "mdi-atom",
      to: "/science",
    },
    {
      text: "Business",
      icon: "mdi-domain",
      to: "/business",
    },
    {
      text: "Price",
      icon: "mdi-cash-multiple",
      to: "/price",
    },
  ],
  admin: [
    {
      text: "Overview",
      icon: "mdi-signal-cellular-3",
      to: "/admin/dashboard",
    },
    {
      text: "Management",
      icon: "mdi-view-dashboard",
      submenu: [
        {
          text: "Audio",
          icon: "mdi-view-dashboard",
          to: "/admin/management/audio",
        },
        {
          text: "Audio Type",
          icon: "mdi-view-dashboard",
          to: "/admin/management/audio/type",
        },
        {
          text: "Category",
          icon: "mdi-view-dashboard",
          to: "/admin/management/category",
        },
        {
          text: "Subscription",
          icon: "mdi-view-dashboard",
          to: "/admin/management/subscription",
        },
      ],
    },
    {
      text: "Users",
      icon: "mdi-account-group",
      to: "/admin/account",
    },
    {
      text: "Moods",
      icon: "mdi-emoticon-outline",
      to: "/admin/mood",
    },
    {
      text: "Mobile Version",
      icon: "mdi-cellphone",
      to: "/admin/mobile/version",
    },
    {
      text: "Promo Code",
      icon: "mdi-ticket-percent-outline",
      to: "/admin/promo/code",
    },
    {
      text: "Featured Contents",
      icon: "mdi-folder-star",
      to: "/admin/featured/contents",
    },
    {
      text: "How Magus Works",
      icon: "mdi-information-outline",
      to: "/admin/magus/information",
    },
  ],
};

export const sidebar2 = {
  main: [
    {
      to: "/home",
      label: "Home",
      icon: "mdi-home",
      active: false,
      button: false,
      submenu: [],
    },
    {
      to: "/science",
      label: "Science",
      icon: "mdi-atom",
      active: false,
      button: false,
      submenu: [],
    },
    {
      to: "/business",
      label: "Business",
      icon: "mdi-domain",
      active: false,
      button: false,
      submenu: [],
    },
    {
      to: "/price",
      label: "Price",
      icon: "mdi-cash-multiple",
      active: false,
      button: false,
      submenu: [],
    },
  ],
  user: [
    {
      to: "/user/overview",
      label: "Home",
      icon: "mdi-home",
      active: false,
      button: false,
      submenu: [],
    },
    {
      to: "/user/search",
      label: "Search",
      icon: "mdi-magnify",
      active: false,
      button: false,
      submenu: [],
    },
    {
      to: "/user/recommend",
      label: "Recommend",
      icon: "mdi-chart-bar-stacked",
      active: false,
      button: false,
      submenu: [],
    },
    {
      to: "/user/own/playlist",
      label: "My Playlist",
      icon: "mdi-playlist-play",
      active: false,
      button: false,
      submenu: [],
    },
    {
      to: "/user/settings",
      label: "Settings",
      icon: "mdi-cog",
      active: false,
      button: false,
      submenu: [],
    },
    {
      to: "",
      label: "My Favorites",
      icon: "mdi-star-outline",
      active: false,
      submenu: [
        {
          to: "/user/favorite/playlist",
          label: "My Favorites Playlist",
          icon: "",
        },
        {
          to: "/user/favorite/subliminal",
          label: "My Favorites Subliminal",
          icon: "",
        },
      ],
    },
  ],
  admin: [
    {
      to: "/admin/dashboard",
      label: "Overview",
      icon: "mdi-signal-cellular-3",
      active: false,
      button: false,
      submenu: [],
    },
    {
      to: "",
      label: "Management",
      icon: "mdi-view-dashboard",
      active: false,
      submenu: [
        {
          to: "/admin/management/audio",
          label: "Audio",
          icon: "",
        },
        {
          to: "/admin/management/category",
          label: "Category",
          icon: "",
        },
        {
          to: "/admin/management/audio/type",
          label: "Audio Type",
          icon: "",
        },
        {
          to: "/admin/management/subscription",
          label: "Subscription",
          icon: "",
        },
      ],
    },
    {
      to: "/admin/account",
      label: "Users",
      icon: "mdi-account-group",
      active: false,
      button: false,
      submenu: [],
    },
    {
      to: "/admin/mood",
      label: "Moods",
      icon: "mdi-emoticon-outline",
      active: false,
      button: false,
      submenu: [],
    },
    {
      to: "/admin/mobile/version",
      label: "Mobile Version",
      icon: "mdi-cellphone",
      active: false,
      button: false,
      submenu: [],
    },
    {
      to: "/admin/promo/code",
      label: "Promo Code",
      icon: "mdi-ticket-percent-outline",
      active: false,
      button: false,
      submenu: [],
    },
    {
      to: "/admin/featured/contents",
      label: "Featured Contents",
      icon: "mdi-folder-star",
      active: false,
      button: false,
      submenu: [],
    },
    {
      to: "/admin/magus/information",
      label: "How Magus Works",
      icon: "mdi-information-outline",
      active: false,
      button: false,
      submenu: [],
    },
  ],
};

export const sorts = [
  {
    text: "Ascending",
    value: "asc",
  },
  {
    text: "Descending",
    value: "desc",
  },
  {
    text: "Recent",
    value: "recent",
  },
];

export const visibles = [
  {
    text: "Visible",
    value: "1",
  },
  {
    text: "Not Visible",
    value: "0",
  },
];

export const is_influencer = [
  {
    text: "No",
    value: "No",
  },
  {
    text: "Yes",
    value: "Yes",
  },
];
export const statusMood = [
  {
    text: "Positive",
    value: "Positive",
  },
  {
    text: "Negative",
    value: "Negative",
  },
];

export const statuses = [
  {
    text: "Active",
    value: "active",
  },
  {
    text: "Inactive",
    value: "inactive",
  },
];

export const influencers = [
  {
    text: "No",
    value: "No",
  },
  {
    text: "Yes",
    value: "Yes",
  },
];

export const states = {
  users: [
    {
      text: "Active",
      value: "active",
    },
    {
      text: "Inactive",
      value: "inactive",
    },
  ],
  moods: [
    {
      text: "Positive",
      value: "Positive",
    },
    {
      text: "Negative",
      value: "Negative",
    },
  ],
};

export const pageLimits = [
  {
    text: 1,
    value: 1,
  },
  {
    text: 5,
    value: 5,
  },
  {
    text: 10,
    value: 10,
  },
  {
    text: 20,
    value: 20,
  },
  {
    text: 30,
    value: 30,
  },
];
