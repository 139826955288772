import Vue from "vue";
import VueRouter from "vue-router";
//
// Layouts
import Layout from "../layouts/Layout.vue";
import Admin from "../layouts/Admin.vue";
import Main from "../layouts/Main.vue";
import User from "../layouts/User.vue";

// Admin Pages
import Mood from "../pages/Admin/Mood.vue";
import Audio from "../pages/Admin/Audio.vue";
import Account from "../pages/Admin/Account.vue";
import Category from "../pages/Admin/Category.vue";
import AudioType from "../pages/Admin/AudioType.vue";
import Dashboard from "../pages/Admin/Dashboard.vue";
import PromoCode from "../pages/Admin/PromoCode.vue";
import MagusDetails from "../pages/Admin/MagusDetails.vue";
import Subscription from "../pages/Admin/Subscription.vue";
import MobileVersion from "../pages/Admin/MobileVersion.vue";
import FeaturedContents from "../pages/Admin/FeaturedContents.vue";

// Main Pages
import Home from "../pages/Main/Home.vue";
import Business from "../pages/Main/Business.vue";
import Science from "../pages/Main/Science.vue";
import Price from "../pages/Main/Price.vue";
import PriceMobile from "../pages/Main/PriceMobile.vue";
import Login from "../pages/Main/Login.vue";
import Register from "../pages/Main/Register.vue";
import Contact from "../pages/Main/Contact.vue";
import FAQs from "../pages/Main/FAQs.vue";
import TermsConditions from "../pages/Main/TermsConditions.vue";
import Guidelines from "../pages/Main/Guidelines.vue";
import AboutMagus from "../pages/Main/AboutMagus.vue";
import PrivacyPolicy from "../pages/Main/PrivacyPolicy.vue";

//Subliminal Guide Pages Here...
import InsomniaCure from "../pages/Guide/1-insomnia-cure.vue";
import HangoverCure from "../pages/Guide/2-hangover-cure.vue";
import EpilepsyHealing from "../pages/Guide/3-epilepsy-healing.vue";
import HealthySleepRecoveryandHealing from "../pages/Guide/4-healthy-sleep-recovery-and-healing.vue";
import BacterialInfectionHealing from "../pages/Guide/5-bacterial-infection-healing.vue";
import GeneralPainHealing from "../pages/Guide/6-general-pain-healing.vue";
import BodyPainReliefandHealing from "../pages/Guide/7-body-pain-relief-and-healing.vue";
import BonePainReliefandHealing from "../pages/Guide/8-bone-pain-relief-and-healing.vue";
import MusclePainHealing from "../pages/Guide/9-muscle-pain-healing.vue";
import NervePainHealing from "../pages/Guide/10-nerve-pain-healing.vue";
import MentallyInducedPainHealing from "../pages/Guide/11-mentally-induced-pain-healing.vue";
import AntiAging from "../pages/Guide/12-anti-aging.vue";
import AntioxidantTreatment from "../pages/Guide/13-antioxidant-treatment.vue";
import ErectileDysfunctionHealing from "../pages/Guide/14-erectile-dysfunction-healing.vue";
import StopPrematureEjaculation from "../pages/Guide/15-stop-premature-ejaculation.vue";
import MaleGenitalWartsRemovalandHealing from "../pages/Guide/16-male-genital-warts-removal-and-healing.vue";
import MenstrualCycleHealing from "../pages/Guide/17-menstrual-cycle-healing.vue";
import PolycysticovarysyndromePCOSHealing from "../pages/Guide/18-polycystic-ovary-syndrome-(pcos)-healing.vue";
import FemaleGenitalWartsHealing from "../pages/Guide/19-female-genital-warts-healing.vue";
import GeneralWeightLoss from "../pages/Guide/20-general-weight-loss.vue";
import HealthyEatingHabits from "../pages/Guide/21-healthy-eating-habits.vue";
import DietMotivation from "../pages/Guide/22-diet-motivation.vue";
import StopEatingSweets from "../pages/Guide/23-stop-eating-sweets.vue";
import StopEatingJunkFood from "../pages/Guide/24-stop-eating-junk-food.vue";
import EyesightImprovement from "../pages/Guide/25-eyesight-improvement.vue";
import MetabolismEnhancement from "../pages/Guide/26-metabolism-enhancement.vue";
import IrritableBowelSyndromeIBSTreatment from "../pages/Guide/27-irritable-bowel-syndrome-(ibs)-treatment.vue";
import MigrainePainReliefandHealing from "../pages/Guide/30-migraine-pain-relief-and-healing.vue";
import AvoidBurnout from "../pages/Guide/31-avoid-burnout.vue";
import ImmuneSystemBoost from "../pages/Guide/32-immune-system-boost.vue";
import GeneralStressReliefandHealing from "../pages/Guide/34-general-stress-relief-and-healing.vue";
import ExerciseMotivation from "../pages/Guide/35-exercise-motivation.vue";
import MuscleRecoveryAid from "../pages/Guide/36-muscle-recovery-aid.vue";
import MuscleGrowthAid from "../pages/Guide/37-muscle-growth-aid.vue";
import RelaxationAid from "../pages/Guide/38-relaxation-aid.vue";
import FallAsleepFast from "../pages/Guide/39-fall-asleep-fast.vue";
import RelieveConstipation from "../pages/Guide/41-relieve-constipation.vue";
import GrayHairReversalandNaturalColorRestoration from "../pages/Guide/42-gray-hair-reversal-and-natural-color-restoration.vue";
import RockHardAbs from "../pages/Guide/43-rock-hard-abs.vue";
import LookYounger from "../pages/Guide/44-look-younger.vue";
import FacialToningandDevelopment from "../pages/Guide/45-facial-toning-and-development.vue";
import YouthfulFace from "../pages/Guide/46-youthful-face.vue";
import FacialAcneRemovalandTreatment from "../pages/Guide/47-facial-acne-removal-and-treatment.vue";
import GoldenRatioFacialAlignment from "../pages/Guide/48-golden-ratio-facial-alignment.vue";
import DoubleChinRemoval from "../pages/Guide/49-double-chin-removal.vue";
import FacialSkinRegeneration from "../pages/Guide/50-facial-skin-regeneration.vue";
import StretchMarkHealingandRemoval from "../pages/Guide/51-stretch-mark-healing-and-removal.vue";
import DigestionEnhancement from "../pages/Guide/52-digestion-enhancement.vue";
import PostureAlignmentandTraining from "../pages/Guide/53-posture-alignment-and-training.vue";
import BodyAcceptanceandConfidence from "../pages/Guide/54-body-acceptance-and-confidence.vue";
import BaldnessAcceptanceandConfidence from "../pages/Guide/55-baldness-acceptance-and-confidence.vue";
import DisabilitiesAcceptanceandConfidence from "../pages/Guide/56-disabilities-acceptance-and-confidence.vue";
import AgeAcceptanceandConfidence from "../pages/Guide/57-age-acceptance-and-confidence.vue";
import HeightAcceptanceandConfidence from "../pages/Guide/58-height-acceptance-and-confidence.vue";
import FacialSlimming from "../pages/Guide/59-facial-slimming.vue";
import GoldenRatioandSymmetricalFaceDevelopment from "../pages/Guide/60-golden-ratio-and-symmetrical-face-development.vue";
import HealthyHairGrowth from "../pages/Guide/61-healthy-hair-growth.vue";
import SmokingAddictionRemoval from "../pages/Guide/62-smoking-addiction-removal.vue";
import StopSmokingCompletely from "../pages/Guide/63-stop-smoking-completely.vue";
import StopRecreationalDrugAbuse from "../pages/Guide/64-stop-recreational-drug-abuse.vue";
import StopUsingIllegalDrugsCompletely from "../pages/Guide/65-stop-using-illegal-drugs-completely.vue";
import AlcoholAddictionRemoval from "../pages/Guide/66-alcohol-addiction-removal.vue";
import CannabisAddictionRemoval from "../pages/Guide/67-cannabis-addiction-removal.vue";
import FreedomfromDrugAddictionandAbuse from "../pages/Guide/68-freedom-from-drug-addiction-and-abuse.vue";
import GamblingAddictionRemoval from "../pages/Guide/69-gambling-addiction-removal.vue";
import PornographyAddictionRemoval from "../pages/Guide/70-pornography-addiction-removal.vue";
import MasturbationAddictionRemoval from "../pages/Guide/71-masturbation-addiction-removal.vue";
import AlcoholModeration from "../pages/Guide/72-alcohol-moderation.vue";
import SmokingModeration from "../pages/Guide/73-smoking-moderation.vue";
import SocialMediaAddictionRemoval from "../pages/Guide/74-social-media-addiction-removal.vue";
import MobilePhoneAddictionRemoval from "../pages/Guide/75-mobile-phone-addiction-removal.vue";
import SoftDrinksAddictionRemoval from "../pages/Guide/76-soft-drinks-addiction-removal.vue";
import BingeEatingHabitRemoval from "../pages/Guide/77-binge-eating-habit-removal.vue";
import BingeDrinkingHabitRemoval from "../pages/Guide/78-binge-drinking-habit-removal.vue";
import BingeWatchingHabitRemoval from "../pages/Guide/79-binge-watching-habit-removal.vue";
import ChocolateAddictionRemoval from "../pages/Guide/80-chocolate-addiction-removal.vue";
import SweetsAddictionRemoval from "../pages/Guide/81-sweets-addiction-removal.vue";
import SelfieAddictionRemoval from "../pages/Guide/82-selfie-addiction-removal.vue";
import StopNailBiting from "../pages/Guide/83-stop-nail-biting.vue";
import StopSwearing from "../pages/Guide/84-stop-swearing.vue";
import StopMumbling from "../pages/Guide/85-stop-mumbling.vue";
import StopStuttering from "../pages/Guide/86-stop-stuttering.vue";
import StopTalkingtoofast from "../pages/Guide/87-stop-talking-too-fast.vue";
import StopNoisyBreathing from "../pages/Guide/88-stop-noisy-breathing.vue";
import StopTeethGrinding from "../pages/Guide/89-stop-teeth-grinding.vue";
import StopSweatingExcessively from "../pages/Guide/90-stop-sweating-excessively.vue";
import StopCrackingFingersandKnuckles from "../pages/Guide/91-stop-cracking-fingers-and-knuckles.vue";
import StopFootStomping from "../pages/Guide/92-stop-foot-stomping.vue";
import Stopovergesturing from "../pages/Guide/93-stop-over-gesturing.vue";
import FearofClosedSpacesandClaustrophobiaRemoval from "../pages/Guide/94-fear-of-closed-spaces-and-claustrophobia-removal.vue";
import FearofOpenSpacesandAgoraphobiaRemoval from "../pages/Guide/95-fear-of-open-spaces-and-agoraphobia-removal.vue";
import FearofFlyingandAerophobiaRemoval from "../pages/Guide/96-fear-of-flying-and-aerophobia-removal.vue";
import FearofClusteredHolesandTrypophobiaRemoval from "../pages/Guide/97-fear-of-clustered-holes-and-trypophobia-removal.vue";
import FearofSchoolsandSchoolPhobiaRemoval from "../pages/Guide/98-fear-of-schools-and-school-phobia-removal.vue";
import FearofCommitmentandCommitmentPhobiaRemoval from "../pages/Guide/99-fear-of-commitment-and-commitment-phobia-removal.vue";
import FearofDeathRemoval from "../pages/Guide/100-fear-of-death-removal.vue";
import FearofAgingandGerascophobiaRemoval from "../pages/Guide/101-fear-of-aging-and-gerascophobia-removal.vue";
import FearofAbandonmentRemoval from "../pages/Guide/102-fear-of-abandonment-removal.vue";
import FearofBeingAloneRemoval from "../pages/Guide/103-fear-of-being-alone-removal.vue";
import FearofSicknessandDiseasesRemoval from "../pages/Guide/104-fear-of-sickness-and-diseases-removal.vue";
import FearofCockroachesandCockroachPhobiaRemoval from "../pages/Guide/105-fear-of-cockroaches-and-cockroach-phobia-removal.vue";
import FearofSnakesandSnakePhobiaRemoval from "../pages/Guide/106-fear-of-snakes-and-snake-phobia-removal.vue";
import FearofSpidersandArachnophobiaRemoval from "../pages/Guide/107-fear-of-spiders-and-arachnophobia-removal.vue";
import FearofDoctorsandDoctorPhobiaRemoval from "../pages/Guide/108-fear-of-doctors-and-doctor-phobia-removal.vue";
import FearofDogsandCynophobiaRemoval from "../pages/Guide/109-fear-of-dogs-and-cynophobia-removal.vue";
import FearofBloodandHemophobiaRemoval from "../pages/Guide/110-fear-of-blood-and-hemophobia-removal.vue";
import FearofInjectionRemoval from "../pages/Guide/111-fear-of-injection-removal.vue";
import FearofCancerRemoval from "../pages/Guide/112-fear-of-cancer-removal.vue";
import FearofChildbirthRemovalforWomen from "../pages/Guide/113-fear-of-childbirth-removal-for-women.vue";
import FearofClownsandClownPhobiaRemoval from "../pages/Guide/114-fear-of-clowns-and-clown-phobia-removal.vue";
import FearofConflictRemoval from "../pages/Guide/115-fear-of-conflict-removal.vue";
import FearofConfrontationRemoval from "../pages/Guide/116-fear-of-confrontation-removal.vue";
import FearofCriticismRemoval from "../pages/Guide/117-fear-of-criticism-removal.vue";
import FearofBeingVulnerableRemoval from "../pages/Guide/118-fear-of-being-vulnerable-removal.vue";
import FearofHeightsandAcrophobiaRemoval from "../pages/Guide/119-fear-of-heights-and-acrophobia-removal.vue";
import FearofGhostsRemoval from "../pages/Guide/120-fear-of-ghosts-removal.vue";
import FearofInadequacyRemoval from "../pages/Guide/121-fear-of-inadequacy-removal.vue";
import FearofPublicSpeakingRemoval from "../pages/Guide/122-fear-of-public-speaking-removal.vue";
import FearofMissingOutFOMORemoval from "../pages/Guide/123-fear-of-missing-out-fomo-removal.vue";
import FearofIntimacyRemoval from "../pages/Guide/124-fear-of-intimacy-removal.vue";
import FearlessLivingStopLivinginFear from "../pages/Guide/125-fearless-living---stop-living-in-fear.vue";
import OvercomeAnalysisParalysis from "../pages/Guide/126-overcome-analysis-paralysis.vue";
import RemoveandHealFearBasedAnxiety from "../pages/Guide/127-remove-and-heal-fear-based-anxiety.vue";
import StopSuicidalThoughtsandSuicidePrevention from "../pages/Guide/128-stop-suicidal-thoughts-and-suicide-prevention.vue";
import StopFeelingsofWorthlessness from "../pages/Guide/129-stop-feelings-of-worthlessness.vue";
import AttractMoney from "../pages/Guide/130-attract-money.vue";
import AttractLuck from "../pages/Guide/131-attract-luck.vue";
import BecomeDebtFree from "../pages/Guide/132-become-debt-free.vue";
import AcquireLeadershipQualities from "../pages/Guide/133-acquire-leadership-qualities.vue";
import SalesSuccess from "../pages/Guide/134-sales-success.vue";
import StockMarketTradingSuccess from "../pages/Guide/135-stock-market-trading-success.vue";
import ForexandCurrencyTradingSuccess from "../pages/Guide/136-forex-and-currency-trading-success.vue";
import CryptocurrencyTradingSuccess from "../pages/Guide/137-cryptocurrency-trading-success.vue";
import SuccessfulEntrepreneurProgramming from "../pages/Guide/138-successful-entrepreneur-programming.vue";
import TimeManagementandProductivity from "../pages/Guide/139-time-management-and-productivity.vue";
import ManifestRelationshipwithaWealthyLover from "../pages/Guide/140-manifest-relationship-with-a-wealthy-lover.vue";
import ManifestaSugarMommy from "../pages/Guide/141-manifest-a-sugar-mommy.vue";
import ManifestaSugarDaddy from "../pages/Guide/142-manifest-a-sugar-daddy.vue";
import ManifestyourDreamJobandCareer from "../pages/Guide/143-manifest-your-dream-job-and-career.vue";
import ManifestyourDreamBusiness from "../pages/Guide/144-manifest-your-dream-business.vue";
import DisconnectfromPeopleWhoDragsYouDown from "../pages/Guide/145-disconnect-from-people-who-drags-you-down.vue";
import DisconnectFromToxicCoworkers from "../pages/Guide/146-disconnect-from-toxic-coworkers.vue";
import CurseCancellationandProtectionShield from "../pages/Guide/147-curse-cancellation-and-protection-shield.vue";
import CancelBankruptcy from "../pages/Guide/148-cancel-bankruptcy.vue";
import AngerManagementIssuesRemoval from "../pages/Guide/149-anger-management-issues-removal.vue";
import EmotionalRelease from "../pages/Guide/150-emotional-release.vue";
import AngerRelease from "../pages/Guide/151-anger-release.vue";
import AnxietyHealing from "../pages/Guide/152-anxiety-healing.vue";
import GeneralEmotionalHealing from "../pages/Guide/153-general-emotional-healing.vue";
import GetOverandMoveonfromYourExandPastRelationships from "../pages/Guide/154-get-over-and-move-on-from-your-ex-and-past-relationships.vue";
import BreakupEmotionalHealingandRecovery from "../pages/Guide/155-break-up-emotional-healing-and-recovery.vue";
import GetoverandMoveonfromDivorce from "../pages/Guide/156-get-over-and-move-on-from-divorce.vue";
import DivorceEmotionalPainHealing from "../pages/Guide/157-divorce-emotional-pain-healing.vue";
import BrokenFamilyEmotionalHealing from "../pages/Guide/158-broken-family-emotional-healing.vue";
import EmotionalPainManagement from "../pages/Guide/159-emotional-pain-management.vue";
import ReleaseForgiveness from "../pages/Guide/161-release-forgiveness.vue";
import GeneralTraumaHealing from "../pages/Guide/162-general-trauma-healing.vue";
import DepressionHealingandRemoval from "../pages/Guide/163-depression-healing-and-removal.vue";
import GeneralAbuseHealing from "../pages/Guide/164-general-abuse-healing.vue";
import MasteryourEmotionsandResponses from "../pages/Guide/165-master-your-emotions-and-responses.vue";
import OvercomeCyberBullying from "../pages/Guide/166-overcome-cyber-bullying.vue";
import HealPostTraumaticStressDisorderPTSD from "../pages/Guide/167-heal-post-traumatic-stress-disorder-(ptsd).vue";
import SafeHavenFeelsafeandSecureAlways from "../pages/Guide/168-safe-haven---feel-safe-and-secure-always.vue";
import CalmandPeacefulFeelings from "../pages/Guide/169-calm-and-peaceful-feelings.vue";
import ToxicShameHealing from "../pages/Guide/170-toxic-shame-healing.vue";
import ChildbirthTraumaHealing from "../pages/Guide/171-childbirth-trauma-healing.vue";
import StopSelfHarming from "../pages/Guide/172-stop-self-harming.vue";
import SelfForgiveness from "../pages/Guide/173-self-forgiveness.vue";
import ForgiveOthersandMoveOn from "../pages/Guide/174-forgive-others-and-move-on.vue";
import AssertivenessTraining from "../pages/Guide/175-assertiveness-training.vue";
import DevelopPositiveHumor from "../pages/Guide/176-develop-positive-humor.vue";
import HappierLifeNow from "../pages/Guide/177-happier-life-now.vue";
import BecomeanEarlyRiser from "../pages/Guide/178-become-an-early-riser.vue";
import BecomeaNightOwl from "../pages/Guide/179-become-a-night-owl.vue";
import SocializationandSocialSkillsEnhancement from "../pages/Guide/180-socialization-and-social-skills-enhancement.vue";
import LetgoofthePastandMoveon from "../pages/Guide/181-let-go-of-the-past-and-move-on.vue";
import PositiveThinkingandMindset from "../pages/Guide/182-positive-thinking-and-mindset.vue";
import PositiveAttitudeTrainingandDevelopment from "../pages/Guide/183-positive-attitude-training-and-development.vue";
import ToxicandNegativeAttitudeRemoval from "../pages/Guide/184-toxic-and-negative-attitude-removal.vue";
import BehavioralDevelopment from "../pages/Guide/185-behavioral-development.vue";
import PublicSpeakingSkillsImprovementandTraining from "../pages/Guide/186-public-speaking-skills-improvement-and-training.vue";
import ResolveInnerConflict from "../pages/Guide/187-resolve-inner-conflict.vue";
import OvercomeandHealShame from "../pages/Guide/188-overcome-and-heal-shame.vue";
import ResolveandHealGuilt from "../pages/Guide/189-resolve-and-heal-guilt.vue";
import SelfCare from "../pages/Guide/190-self-care.vue";
import SelfResponsibility from "../pages/Guide/191-self--responsibility.vue";
import SelfBelief from "../pages/Guide/192-self-belief.vue";
import SelfDiscipline from "../pages/Guide/193-self-discipline.vue";
import MeditationAidandImprovement from "../pages/Guide/194-meditation-aid-and-improvement.vue";
import CharismaDevelopment from "../pages/Guide/195-charisma-development.vue";
import SelfLove from "../pages/Guide/196-self-love.vue";
import SelfAcceptance from "../pages/Guide/197-self-acceptance.vue";
import SelfConsideration from "../pages/Guide/198-self-consideration.vue";
import InsecurityRemoval from "../pages/Guide/199-insecurity-removal.vue";
import StopBeingaPeoplePleaser from "../pages/Guide/200-stop-being-a-people-pleaser.vue";
import LearntosayNO from "../pages/Guide/201-learn-to-say-no.vue";
import AcceptingNewChallengesandExpandingComfortZone from "../pages/Guide/202-accepting-new-challenges-and-expanding-comfort-zone.vue";
import StopTalkingTooMuch from "../pages/Guide/203-stop-talking-too-much.vue";
import FailureMentalandEmotionalStabilityandRecovery from "../pages/Guide/204-failure-mental-and-emotional-stability-and-recovery.vue";
import BecomeMoreResilient from "../pages/Guide/205-become-more-resilient.vue";
import CourageouslyFearless from "../pages/Guide/206-courageously-fearless.vue";
import OvercomingShyness from "../pages/Guide/207-overcoming-shyness.vue";
import StopHairLoss from "../pages/Guide/208-stop-hair-loss.vue";
import DevelopEmotionalMaturity from "../pages/Guide/209-develop-emotional-maturity.vue";
import PositiveRelationshipwithParents from "../pages/Guide/210-positive-relationship-with-parents.vue";
import PositiveRelationshipwithCoworkers from "../pages/Guide/211-positive-relationship-with-coworkers.vue";
import PositiveRelationshipwithBoss from "../pages/Guide/212-positive-relationship-with-boss.vue";
import PositiveRelationshipwithSiblings from "../pages/Guide/213-positive-relationship-with-siblings.vue";
import PositiveRelationshipwithLover from "../pages/Guide/214-positive-relationship-with-lover.vue";
import GetoverJealousy from "../pages/Guide/215-get-over-jealousy.vue";
import HealRelationshipAnxiety from "../pages/Guide/216-heal-relationship-anxiety.vue";
import MaximumAthleticPerformanceEnhancement from "../pages/Guide/217-maximum-athletic-performance-enhancement.vue";
import BasketballSkillsTrainingandMastery from "../pages/Guide/218-basketball-skills-training-and-mastery.vue";
import BaseballSkillsTrainingandMastery from "../pages/Guide/219-baseball-skills-training-and-mastery.vue";
import AmericanFootballSkillsandTrainingMastery from "../pages/Guide/220-american-football-skills-and-training-mastery.vue";
import RugbySkillsandTrainingMastery from "../pages/Guide/221-rugby-skills-and-training-mastery.vue";
import ArcherySkillsandTrainingMastery from "../pages/Guide/222-archery-skills-and-training-mastery.vue";
import DartsSkillsandTrainingMastery from "../pages/Guide/223-darts-skills-and-training-mastery.vue";
import BoxingTrainingandMastery from "../pages/Guide/224-boxing-training-and-mastery.vue";
import FencingTrainingandMastery from "../pages/Guide/225-fencing-training-and-mastery.vue";
import KendoTrainingandMastery from "../pages/Guide/226-kendo-training-and-mastery.vue";
import WrestlingTrainingandMastery from "../pages/Guide/227-wrestling-training-and-mastery.vue";
import MuayThaiTrainingandMastery from "../pages/Guide/228-muay-thai-training-and-mastery.vue";
import KaliArnisTrainingandMastery from "../pages/Guide/229-kali-arnis-training-and-mastery.vue";
import YawYanFervilleonMartialArtsTrainingandMastery from "../pages/Guide/230-yaw-yan-fervilleon-martial-arts-training-and-mastery.vue";
import SwordsmanshipTrainingandMastery from "../pages/Guide/231-swordsmanship-training-and-mastery.vue";
import JudoTrainingandMastery from "../pages/Guide/232-judo-training-and-mastery.vue";
import AikidoTrainingandMastery from "../pages/Guide/233-aikido-training-and-mastery.vue";
import JeetKuneDoTrainingandMastery from "../pages/Guide/234-jeet-kune-do-training-and-mastery.vue";
import WingChunKungFuTrainingandMastery from "../pages/Guide/235-wing-chun-kung-fu-training-and-mastery.vue";
import JujitsuTrainingandMastery from "../pages/Guide/236-jujitsu-training-and-mastery.vue";
import KarateTrainingandMastery from "../pages/Guide/237-karate-training-and-mastery.vue";
import TaekwondoTrainingandMastery from "../pages/Guide/238-taekwondo-training-and-mastery.vue";
import TaiChiTrainingandMastery from "../pages/Guide/239-tai-chi-training-and-mastery.vue";
import BajiquanTrainingandMastery from "../pages/Guide/240-bajiquan-training-and-mastery.vue";
import SingingTalentandSkillsEnhancement from "../pages/Guide/241-singing-talent-and-skills-enhancement.vue";
import DancingTalentandSkillsEnhancement from "../pages/Guide/242-dancing-talent-and-skills-enhancement.vue";
import MusicalInstrumentsTalentandSkillsEnhancement from "../pages/Guide/243-musical-instruments-talent-and-skills-enhancement.vue";
import DrawingTalentandSkillsEnhancement from "../pages/Guide/244-drawing-talent-and-skills-enhancement.vue";
import PaintingTalentandSkillsEnhancement from "../pages/Guide/245-painting-talent-and-skills-enhancement.vue";
import BecomeaSuccessfulTeacher from "../pages/Guide/246-become-a-successful-teacher.vue";
import BecomeaSuccessfulInsuranceBroker from "../pages/Guide/247-become-a-successful-insurance-broker.vue";
import BecomeaSuccessfulVlogger from "../pages/Guide/248-become-a-successful-vlogger.vue";
import BecomeaSuccessfulYoutuber from "../pages/Guide/249-become-a-successful-youtuber.vue";
import BecomeaSuccessfulCallCenterAgent from "../pages/Guide/250-become-a-successful-call-center-agent.vue";
import BecomeaSuccessfulProgrammer from "../pages/Guide/251-become-a-successful-programmer.vue";
import BecomeaSuccessfulSocialMediaMarketer from "../pages/Guide/252-become-a-successful-social-media-marketer.vue";
import BecomeaSuccessfulArchitect from "../pages/Guide/253-become-a-successful-architect.vue";
import BecomeaSuccessfulMechanicalEngineer from "../pages/Guide/254-become-a-successful-mechanical-engineer.vue";
import BecomeaSuccessfulCivilEngineer from "../pages/Guide/255-become-a-successful-civil-engineer.vue";
import BecomeaSuccessfulProjectManager from "../pages/Guide/256-become-a-successful-project-manager.vue";
import BecomeaSuccessfulLawyer from "../pages/Guide/257-become-a-successful-lawyer.vue";
import BrainHealingandRegeneration from "../pages/Guide/258-brain-healing-and-regeneration.vue";
import StrategicThinkingSkillsTrainingandDevelopment from "../pages/Guide/259-strategic-thinking-skills-training-and-development.vue";
import MemoryEnhancementandBooster from "../pages/Guide/260-memory-enhancement-and-booster.vue";
import ChessTrainingandMastery from "../pages/Guide/261-chess-training-and-mastery.vue";
import BoardGamesTrainingandMastery from "../pages/Guide/262-board-games-training-and-mastery.vue";
import CreativeThinking from "../pages/Guide/263-creative-thinking.vue";
import BrainPerformanceEnhancement from "../pages/Guide/264-brain-performance-enhancement.vue";
import NumericalIntelligenceImprovement from "../pages/Guide/265-numerical-intelligence-improvement.vue";
import SpeedReadingSuccess from "../pages/Guide/266-speed-reading-success.vue";
import PhotoReadingSuccess from "../pages/Guide/267-photoreading-success.vue";
import Stopthehabitoftranslatinginthemindbeforeyouspeak from "../pages/Guide/268-stop-the-habit-of-translating-in-the-mind-before-you-speak.vue";
import GeneralLanguageLearningAid from "../pages/Guide/269-general-language-learning-aid.vue";
import ProperAccentSpeechTraining from "../pages/Guide/270-proper-accent-speech-training.vue";
import EnglishLanguageLearningAid from "../pages/Guide/271-english-language-learning-aid.vue";
import KoreanLanguageLearningAid from "../pages/Guide/272-korean-language-learning-aid.vue";
import NihongoJapaneseLanguageLearningAid from "../pages/Guide/273-nihongo-japanese-language-learning-aid.vue";
import MandarinChineseLanguageLearningAid from "../pages/Guide/274-mandarin-chinese-language-learning-aid.vue";
import RussianLanguageLearningAid from "../pages/Guide/275-russian-language-learning-aid.vue";
import SpanishLanguageLearningAid from "../pages/Guide/276-spanish-language-learning-aid.vue";
import IndianLanguageLearningAid from "../pages/Guide/277-indian-language-learning-aid.vue";
import ConfidenceforSingleDad from "../pages/Guide/278-confidence-for-single-dad.vue";
import ConfidenceforSingleMom from "../pages/Guide/279-confidence-for-single-mom.vue";
import ParentingConfidence from "../pages/Guide/280-parenting-confidence.vue";
import Confidencewiththeoppositesex from "../pages/Guide/281-confidence-with-the-opposite-sex.vue";
import FlirtingConfidenceforMen from "../pages/Guide/282-flirting-confidence-for-men.vue";
import FlirtingConfidenceforWomen from "../pages/Guide/283-flirting-confidence-for-women.vue";
import LucidDreamingAssistance from "../pages/Guide/284-lucid-dreaming-assistance.vue";
import AstralProjectionandTravel from "../pages/Guide/285-astral-projection-and-travel.vue";
import DreamMemoryRecall from "../pages/Guide/286-dream-memory-recall.vue";
import MakePeopleRespectyou from "../pages/Guide/287-make-people-respect-you.vue";
import EndNightmares from "../pages/Guide/288-end-nightmares.vue";
import StopDrunkDriving from "../pages/Guide/289-stop-drunk-driving.vue";
import StopSecondHandSmoking from "../pages/Guide/290-stop-second-hand-smoking.vue";
import MagneticPersonality from "../pages/Guide/291-magnetic-personality.vue";
import ImprovePersonalMagnetism from "../pages/Guide/292-improve-personal-magnetism.vue";
import SocialGatheringConfidence from "../pages/Guide/293-social-gathering-confidence.vue";
import SelfConfidenceBooster from "../pages/Guide/294-self-confidence-booster.vue";
import HealthyBodyDevelopment from "../pages/Guide/295-healthy-body-development.vue";
import PersonalPowerDevelopment from "../pages/Guide/296-personal-power-development.vue";
import SuccessSecrets from "../pages/Guide/297-success-secrets.vue";
import GeneralLifeSuccess from "../pages/Guide/298-general-life-success.vue";
import HouseworkProductivityandEnjoyment from "../pages/Guide/299-housework-productivity-and-enjoyment.vue";
import ExamSuccess from "../pages/Guide/300-exam-success.vue";
import FocusandConcentrationTrainingandDevelopment from "../pages/Guide/301-focus-and-concentration-training-and-development.vue";
import JobInterviewSuccess from "../pages/Guide/302-job-interview-success.vue";
import InvestmentPresentationSuccess from "../pages/Guide/303-investment-presentation-success.vue";
import LawofAttractionMaximizer from "../pages/Guide/304-law-of-attraction-maximizer.vue";
import QuantumJumpingSuccess from "../pages/Guide/305-quantum-jumping-success.vue";
import MotivationImprovement from "../pages/Guide/306-motivation-improvement.vue";
import OvercomeandStopProcrastination from "../pages/Guide/307-overcome-and-stop-procrastination.vue";
import AchieveGoals from "../pages/Guide/308-achieve-goals.vue";
import AnorexiaHealing from "../pages/Guide/309-anorexia-healing.vue";
import AttractHealth from "../pages/Guide/310-attract-health.vue";
import HaveaHealthyLifestyle from "../pages/Guide/311-have-a-healthy-lifestyle.vue";
import BecomeMoreSociableandOutgoing from "../pages/Guide/312-become-more-sociable-and-outgoing.vue";
import BecomeMorePersistent from "../pages/Guide/313-become-more-persistent.vue";
import BecomeMoreProductive from "../pages/Guide/314-become-more-productive.vue";
import StopBedWetting from "../pages/Guide/315-stop-bed-wetting.vue";
import CharismaticPersonalityEnhancement from "../pages/Guide/316-charismatic-personality-enhancement.vue";
import SexAddictionHealingandRemoval from "../pages/Guide/317-sex-addiction-healing-and-removal.vue";
import FreedomfromWorry from "../pages/Guide/318-freedom-from-worry.vue";
import StopAnxietyBasedPanicAttacks from "../pages/Guide/319-stop-anxiety-based-panic-attacks.vue";
import DevelopTelepathy from "../pages/Guide/320-develop-telepathy.vue";
import ThinklikeaWealthyPerson from "../pages/Guide/321-think-like-a-wealthy-person.vue";
import GettingThingsDoneHabitDevelopment from "../pages/Guide/322-getting-things-done-habit-development.vue";
import HaveanExtrovertedPersonality from "../pages/Guide/323-have-an-extroverted-personality.vue";
import ThinkingOutsidetheBox from "../pages/Guide/324-thinking-outside-the-box.vue";
import ConfidenceforTallMan from "../pages/Guide/325-confidence-for-tall-man.vue";
import ConfidenceforShortMan from "../pages/Guide/326-confidence-for-short-man.vue";
import ConfidenceforShortWomen from "../pages/Guide/327-confidence-for-short-women.vue";
import ConfidenceforPersonwithDisabilities from "../pages/Guide/328-confidence-for-person-with-disabilities.vue";
import ReinventingYourself from "../pages/Guide/329-reinventing-yourself.vue";
import RestlessLegSyndrome from "../pages/Guide/330-restless-leg-syndrome.vue";
import OrganizationSkillsEnhancement from "../pages/Guide/331-organization-skills-enhancement.vue";
import ManifestationWillpowerEnhancement from "../pages/Guide/332-manifestation-willpower-enhancement.vue";
import TapintoyourPersonalPower from "../pages/Guide/333-tap-into-your-personal-power.vue";
import ThinkBigandMakeitBig from "../pages/Guide/334-think-big-and-make-it-big.vue";
import PushPlansandGoalstotheLimit from "../pages/Guide/335-push-plans-and-goals-to-the-limit.vue";
import AttitudeofGratitude from "../pages/Guide/336-attitude-of-gratitude.vue";
import AttractAbundantMoney from "../pages/Guide/337-attract-abundant-money.vue";
import AttractSuccess from "../pages/Guide/338-attract-success.vue";
import AttractProsperity from "../pages/Guide/339-attract-prosperity.vue";
import LawofAttractionSuccess from "../pages/Guide/340-law-of-attraction-success.vue";
import FinanciallyOrganized from "../pages/Guide/341-financially-organized.vue";
import Planthemovementofmoneycarefully from "../pages/Guide/342-plan-the-movement-of-money-carefully.vue";
import SustainableFinancialsandFinancialPlan from "../pages/Guide/343-sustainable-financials-and-financial-plan.vue";
import Haveenoughmoneytopayofffinancialobligations from "../pages/Guide/344-have-enough-money-to-pay-off-financial-obligations.vue";
import MillionaireMoneyMindset from "../pages/Guide/345-millionaire-money-mindset.vue";
import SolidDecisionBeliefandConviction from "../pages/Guide/346-solid-decision-belief-and-conviction.vue";
import EmotionManagement from "../pages/Guide/347-emotion-management.vue";
import PurposeDrivenLifeKnowYourpurposeinLife from "../pages/Guide/348-purpose-driven-life-know-your-purpose-in-life.vue";
import LoveLanguagesMasteryandExpression from "../pages/Guide/349-love-languages-mastery-and-expression.vue";
import MindfulnessTraining from "../pages/Guide/350-mindfulness-training.vue";
import EnhancedVitality from "../pages/Guide/351-enhanced-vitality.vue";
import MotionSicknessCure from "../pages/Guide/352-motion-sickness-cure.vue";
import PositiveSelfTalk from "../pages/Guide/353-positive-self-talk.vue";
import ClarityofMind from "../pages/Guide/354-clarity-of-mind.vue";
import GettingUnstuck from "../pages/Guide/355-getting-unstuck.vue";
import OptimisticMindset from "../pages/Guide/356-optimistic-mindset.vue";
import HouseworkMotivation from "../pages/Guide/357-housework-motivation.vue";
import RealEstateSellingSuccess from "../pages/Guide/358-real-estate-selling-success.vue";
import WeatherproofMindset from "../pages/Guide/359-weatherproof-mindset.vue";
import OpportunityObstaclesandBlockagesRemoval from "../pages/Guide/360-opportunity-obstacles-and-blockages-removal.vue";
import MinimalistLiving from "../pages/Guide/361-minimalist-living.vue";
import SelfJudgementHealing from "../pages/Guide/362-self-judgement-healing.vue";
import IntentionalLivingMakingEveryMomentMatters from "../pages/Guide/363-intentional-living---making-every-moment-matters.vue";
import InnerBeliefandConvictionManifestation from "../pages/Guide/364-inner-belief-and-conviction-manifestation.vue";
import SelfLifeCoachBecomeYourOwnLifeCoach from "../pages/Guide/365-self-life-coach---become-your-own-life-coach.vue";
import AkashicRecordsAccessing from "../pages/Guide/366-akashic-records-accessing.vue";
import ToothachePainRelief from "../pages/Guide/367-toothache-pain-relief.vue";
import DeepSleepTraining from "../pages/Guide/368-deep-sleep-training.vue";
import HealthyHormonalBalancing from "../pages/Guide/369-healthy-hormonal-balancing.vue";
import PuzzleGamesSkillsTrainingandDevelopment from "../pages/Guide/370-puzzle-games-skills-training-and-development.vue";
import RubiksCubeSkillsTrainingandDevelopment from "../pages/Guide/371-rubiks-cube-skills-training-and-development.vue";
import SenseofSmellandTasteHealingandRecovery from "../pages/Guide/372-sense-of-smell-and-taste-healing-and-recovery.vue";
import BecomeOnlineandVirtualExtrovert from "../pages/Guide/373-become-online-and-virtual-extrovert.vue";
import ExerciseandWorkoutResultsEnhancer from "../pages/Guide/374-exercise-and-workout-results-enhancer.vue";
import PatriotismandEndColonialMentality from "../pages/Guide/375-patriotism-and-end-colonial-mentality.vue";
import IdeaCreationMastery from "../pages/Guide/376-idea-creation-mastery.vue";
import StopSnoring from "../pages/Guide/377-stop-snoring.vue";
import IntentionDiscernment from "../pages/Guide/378-intention-discernment.vue";

import Html from "@/components/HtmlFile.vue";

// from Home
import WideProductSelection from "../pages/Main/WideProductSelections.vue";
import WhyMagusforBusiness from "../pages/Main/WhyMagusforBusiness.vue";
import HowDoesMagus from "../pages/Main/HowDoesMagus.vue";

import ForgotPassword from "../pages/Main/ForgotPassword.vue";
import ChangePassword from "../pages/Main/ChangePassword.vue";
import SupportPage from "../pages/Main/SupportPage.vue";
import AppCrashing from "../pages/Main/Support/AppCrashing.vue";
import ResetPassword from "../pages/Main/Support/ResetPassword.vue";
import DeleteAccount from "../pages/Main/Support/DeleteAccount.vue";
import SoundTrouble from "../pages/Main/Support/SoundTrouble.vue";
import AuthCheckLoading from "../pages/Main/AuthCheckLoading.vue";

// User Pages
import Overview from "../pages/User/Overview.vue";
import Search from "../pages/User/Search.vue";
import Recommend from "../pages/User/Recommend.vue";
import Discover from "../pages/User/Discover.vue";
import Featured from "../pages/User/Featured.vue";
import OwnPlaylist from "../pages/User/OwnPlaylist.vue";
import UserProfile from "../pages/User/UserProfile.vue";
import Track from "../pages/User/Track.vue";
import UserTrack from "../pages/User/UserTrack.vue";
import RecentPlayed from "../pages/User/RecentPlayed.vue";
import NewSubliminalForYou from "../pages/User/NewSubliminalForYou.vue";
import MoodSelector from "../components/User/MoodSelector.vue";
import InfluencerDashboard from "../pages/User/Dashboard.vue";
import UserResetPassword from "../components/Home/ResetPassword.vue";

import FavoritePlaylist from "../views/User/FavoritePlaylist.vue";
import FavoriteSubliminal from "../views/User/FavoriteSubliminal.vue";

import Test from "../views/Test.vue";
import GooglePayment from "../views/Main/GooglePayment/index.vue";

// import Layout2 from "../views/Test/Layout.vue";
// import Main2 from "../views/Test/Main.vue";
// import User2 from "../views/Test/User.vue";
// import Admin2 from "../views/Test/Admin.vue";
// import Login2 from "../views/Test/Login.vue";
// import Register2 from "../views/Test/Register.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/test",
    name: "/test",
    meta: {
      layout: "Main",
    },
    component: Test,
  },
  {
    path: "/google/payment",
    name: "google_payment",
    meta: {
      layout: "Main",
    },
    component: GooglePayment,
  },

  // {
  //   path: "/test",
  //   name: "/test",
  //   meta: {
  //     layout: "Main",
  //   },
  //   component: Layout2,
  //   children: [
  //     {
  //       path: "main",
  //       name: "main",
  //       component: Main2,
  //     },
  //     {
  //       path: "login",
  //       name: "login",
  //       component: Login2,
  //       meta: {
  //         hasQueryParamType: true,
  //       },
  //     },
  //     {
  //       path: "register",
  //       name: "register",
  //       component: Register2,
  //     },
  //   ],
  // },
  // {
  //   path: "/test/user",
  //   name: "/test/user",
  //   meta: {
  //     layout: "User",
  //   },
  //   component: Layout2,
  //   children: [
  //     {
  //       path: "",
  //       name: "",
  //       component: User2,
  //     },
  //   ],
  // },
  // {
  //   path: "/test/admin",
  //   name: "/test/admin",
  //   meta: {
  //     layout: "Admin",
  //   },
  //   component: Layout2,
  //   children: [
  //     {
  //       path: "",
  //       name: "",
  //       component: Admin2,
  //     },
  //   ],
  // },
  // {
  //   path: "/test",
  //   name: "/test",
  //   component: Test,
  // },
  {
    path: "",
    redirect: "/home",
    meta: {
      layout: "Main",
    },
    component: Layout,
    children: [
      {
        path: "home",
        name: "home",
        component: Home,
      },
      {
        path: "wideproductselection",
        name: "wideproductselection",
        component: WideProductSelection,
      },
      {
        path: "business",
        name: "business",
        component: Business,
      },
      {
        path: "whyMagusforBusiness",
        name: "whyMagusforBusiness",
        component: WhyMagusforBusiness,
      },
      {
        path: "howDoesMagus",
        name: "howDoesMagus",
        component: HowDoesMagus,
      },
      {
        path: "science",
        name: "science",
        component: Science,
      },
      {
        path: "price",
        name: "price",
        component: Price,
      },
      {
        path: "price/mobile",
        name: "price/mobile",
        component: PriceMobile
      },
      {
        path: "login",
        name: "login",
        component: Login,
        meta: {
          hasQueryParamType: true,
        },
      },
      {
        path: "register",
        name: "register",
        component: Register,
      },
      {
        path: "forgotPassword",
        name: "forgotPassword",
        component: ForgotPassword,
      },
      {
        path: "changePassword",
        name: "changePassword",
        component: ChangePassword,
      },
      {
        path: "support",
        name: "support",
        component: SupportPage,
      },
      {
        path: "termsconditions",
        name: "termsconditions",
        component: TermsConditions,
      },
      {
        path: "privacypolicy",
        name: "privacypolicy",
        component: PrivacyPolicy,
      },
      {
        path: "guide",
        name: "guide",
        component: Guidelines,
      },
      {
        path: "contact",
        name: "contact",
        component: Contact,
      },
      {
        path: "about/magus",
        name: "about/magus",
        component: AboutMagus,
      },
      {
        path: "faqs",
        name: "faqs",
        component: FAQs,
      },
      {
        path: "support/app/crash",
        name: "support/app/crash",
        component: AppCrashing,
      },
      {
        path: "support/reset/password",
        name: "support/reset/password",
        component: ResetPassword,
      },
      {
        path: "support/sound/troubleshoot",
        name: "support/sound/troubleshoot",
        component: SoundTrouble,
      },
      {
        path: "support/delete/account",
        name: "support/delete/account",
        component: DeleteAccount,
      },
      {
        path: "auth/check",
        name: "auth/check",
        component: AuthCheckLoading,
      },
      {
        path: "reset/password",
        name: "reset/password",
        component: UserResetPassword,
      },
      {
        path: "html",
        name: "html",
        component: Html,
      },
      {
        path: "pages/subliminal-guide/insomnia-cure",
        name: "insomnia-cure",
        component: InsomniaCure,
      },
      {
        path: "pages/subliminal-guide/insomnia-cure",
        name: "insomnia-cure",
        component: InsomniaCure,
      },
      {
        path: "pages/subliminal-guide/hangover-cure",
        name: "hangover-cure",
        component: HangoverCure,
      },
      {
        path: "pages/subliminal-guide/epilepsy-healing",
        name: "epilepsy-healing",
        component: EpilepsyHealing,
      },
      {
        path: "pages/subliminal-guide/healthy-sleep-recovery-and-healing",
        name: "healthy-sleep-recovery-and-healing",
        component: HealthySleepRecoveryandHealing,
      },
      {
        path: "pages/subliminal-guide/bacterial-infection-healing",
        name: "bacterial-infection-healing",
        component: BacterialInfectionHealing,
      },
      {
        path: "pages/subliminal-guide/general-pain-healing",
        name: "general-pain-healing",
        component: GeneralPainHealing,
      },
      {
        path: "pages/subliminal-guide/body-pain-relief-and-healing",
        name: "body-pain-relief-and-healing",
        component: BodyPainReliefandHealing,
      },
      {
        path: "pages/subliminal-guide/bone-pain-relief-and-healing",
        name: "bone-pain-relief-and-healing",
        component: BonePainReliefandHealing,
      },
      {
        path: "pages/subliminal-guide/muscle-pain-healing",
        name: "muscle-pain-healing",
        component: MusclePainHealing,
      },
      {
        path: "pages/subliminal-guide/nerve-pain-healing",
        name: "nerve-pain-healing",
        component: NervePainHealing,
      },
      {
        path: "pages/subliminal-guide/mentally-induced-pain-healing",
        name: "mentally-induced-pain-healing",
        component: MentallyInducedPainHealing,
      },
      {
        path: "pages/subliminal-guide/anti-aging",
        name: "anti-aging",
        component: AntiAging,
      },
      {
        path: "pages/subliminal-guide/antioxidant-treatment",
        name: "antioxidant-treatment",
        component: AntioxidantTreatment,
      },
      {
        path: "pages/subliminal-guide/erectile-dysfunction-healing",
        name: "erectile-dysfunction-healing",
        component: ErectileDysfunctionHealing,
      },
      {
        path: "pages/subliminal-guide/stop-premature-ejaculation",
        name: "stop-premature-ejaculation",
        component: StopPrematureEjaculation,
      },
      {
        path: "pages/subliminal-guide/male-genital-warts-removal-and-healing",
        name: "male-genital-warts-removal-and-healing",
        component: MaleGenitalWartsRemovalandHealing,
      },
      {
        path: "pages/subliminal-guide/menstrual-cycle-healing",
        name: "menstrual-cycle-healing",
        component: MenstrualCycleHealing,
      },
      {
        path: "pages/subliminal-guide/polycystic-ovary-syndrome-(pcos)-healing",
        name: "polycystic-ovary-syndrome-(pcos)-healing",
        component: PolycysticovarysyndromePCOSHealing,
      },
      {
        path: "pages/subliminal-guide/female-genital-warts-healing",
        name: "female-genital-warts-healing",
        component: FemaleGenitalWartsHealing,
      },
      {
        path: "pages/subliminal-guide/general-weight-loss",
        name: "general-weight-loss",
        component: GeneralWeightLoss,
      },
      {
        path: "pages/subliminal-guide/healthy-eating-habits",
        name: "healthy-eating-habits",
        component: HealthyEatingHabits,
      },
      {
        path: "pages/subliminal-guide/diet-motivation",
        name: "diet-motivation",
        component: DietMotivation,
      },
      {
        path: "pages/subliminal-guide/stop-eating-sweets",
        name: "stop-eating-sweets",
        component: StopEatingSweets,
      },
      {
        path: "pages/subliminal-guide/stop-eating-junk-food",
        name: "stop-eating-junk-food",
        component: StopEatingJunkFood,
      },
      {
        path: "pages/subliminal-guide/eyesight-improvement",
        name: "eyesight-improvement",
        component: EyesightImprovement,
      },
      {
        path: "pages/subliminal-guide/metabolism-enhancement",
        name: "metabolism-enhancement",
        component: MetabolismEnhancement,
      },
      {
        path: "pages/subliminal-guide/irritable-bowel-syndrome-(ibs)-treatment",
        name: "irritable-bowel-syndrome-(ibs)-treatment",
        component: IrritableBowelSyndromeIBSTreatment,
      },
      {
        path: "pages/subliminal-guide/irritable-bowel-syndrome-(ibs)-treatment",
        name: "irritable-bowel-syndrome-(ibs)-treatment",
        component: IrritableBowelSyndromeIBSTreatment,
      },
      {
        path: "pages/subliminal-guide/irritable-bowel-syndrome-(ibs)-treatment",
        name: "irritable-bowel-syndrome-(ibs)-treatment",
        component: IrritableBowelSyndromeIBSTreatment,
      },
      {
        path: "pages/subliminal-guide/migraine-pain-relief-and-healing",
        name: "migraine-pain-relief-and-healing",
        component: MigrainePainReliefandHealing,
      },
      {
        path: "pages/subliminal-guide/avoid-burnout",
        name: "avoid-burnout",
        component: AvoidBurnout,
      },
      {
        path: "pages/subliminal-guide/immune-system-boost",
        name: "immune-system-boost",
        component: ImmuneSystemBoost,
      },
      {
        path: "pages/subliminal-guide/immune-system-boost",
        name: "immune-system-boost",
        component: ImmuneSystemBoost,
      },
      {
        path: "pages/subliminal-guide/general-stress-relief-and-healing",
        name: "general-stress-relief-and-healing",
        component: GeneralStressReliefandHealing,
      },
      {
        path: "pages/subliminal-guide/exercise-motivation",
        name: "exercise-motivation",
        component: ExerciseMotivation,
      },
      {
        path: "pages/subliminal-guide/muscle-recovery-aid",
        name: "muscle-recovery-aid",
        component: MuscleRecoveryAid,
      },
      {
        path: "pages/subliminal-guide/muscle-growth-aid",
        name: "muscle-growth-aid",
        component: MuscleGrowthAid,
      },
      {
        path: "pages/subliminal-guide/relaxation-aid",
        name: "relaxation-aid",
        component: RelaxationAid,
      },
      {
        path: "pages/subliminal-guide/fall-asleep-fast",
        name: "fall-asleep-fast",
        component: FallAsleepFast,
      },
      {
        path: "pages/subliminal-guide/fall-asleep-fast",
        name: "fall-asleep-fast",
        component: FallAsleepFast,
      },
      {
        path: "pages/subliminal-guide/relieve-constipation",
        name: "relieve-constipation",
        component: RelieveConstipation,
      },
      {
        path: "pages/subliminal-guide/gray-hair-reversal-and-natural-color-restoration",
        name: "gray-hair-reversal-and-natural-color-restoration",
        component: GrayHairReversalandNaturalColorRestoration,
      },
      {
        path: "pages/subliminal-guide/rock-hard-abs",
        name: "rock-hard-abs",
        component: RockHardAbs,
      },
      {
        path: "pages/subliminal-guide/look-younger",
        name: "look-younger",
        component: LookYounger,
      },
      {
        path: "pages/subliminal-guide/facial-toning-and-development",
        name: "facial-toning-and-development",
        component: FacialToningandDevelopment,
      },
      {
        path: "pages/subliminal-guide/youthful-face",
        name: "youthful-face",
        component: YouthfulFace,
      },
      {
        path: "pages/subliminal-guide/facial-acne-removal-and-treatment",
        name: "facial-acne-removal-and-treatment",
        component: FacialAcneRemovalandTreatment,
      },
      {
        path: "pages/subliminal-guide/golden-ratio-facial-alignment",
        name: "golden-ratio-facial-alignment",
        component: GoldenRatioFacialAlignment,
      },
      {
        path: "pages/subliminal-guide/double-chin-removal",
        name: "double-chin-removal",
        component: DoubleChinRemoval,
      },
      {
        path: "pages/subliminal-guide/facial-skin-regeneration",
        name: "facial-skin-regeneration",
        component: FacialSkinRegeneration,
      },
      {
        path: "pages/subliminal-guide/stretch-mark-healing-and-removal",
        name: "stretch-mark-healing-and-removal",
        component: StretchMarkHealingandRemoval,
      },
      {
        path: "pages/subliminal-guide/digestion-enhancement",
        name: "digestion-enhancement",
        component: DigestionEnhancement,
      },
      {
        path: "pages/subliminal-guide/posture-alignment-and-training",
        name: "posture-alignment-and-training",
        component: PostureAlignmentandTraining,
      },
      {
        path: "pages/subliminal-guide/body-acceptance-and-confidence",
        name: "body-acceptance-and-confidence",
        component: BodyAcceptanceandConfidence,
      },
      {
        path: "pages/subliminal-guide/baldness-acceptance-and-confidence",
        name: "baldness-acceptance-and-confidence",
        component: BaldnessAcceptanceandConfidence,
      },
      {
        path: "pages/subliminal-guide/disabilities-acceptance-and-confidence",
        name: "disabilities-acceptance-and-confidence",
        component: DisabilitiesAcceptanceandConfidence,
      },
      {
        path: "pages/subliminal-guide/age-acceptance-and-confidence",
        name: "age-acceptance-and-confidence",
        component: AgeAcceptanceandConfidence,
      },
      {
        path: "pages/subliminal-guide/height-acceptance-and-confidence",
        name: "height-acceptance-and-confidence",
        component: HeightAcceptanceandConfidence,
      },
      {
        path: "pages/subliminal-guide/facial-slimming",
        name: "facial-slimming",
        component: FacialSlimming,
      },
      {
        path: "pages/subliminal-guide/golden-ratio-and-symmetrical-face-development",
        name: "golden-ratio-and-symmetrical-face-development",
        component: GoldenRatioandSymmetricalFaceDevelopment,
      },
      {
        path: "pages/subliminal-guide/healthy-hair-growth",
        name: "healthy-hair-growth",
        component: HealthyHairGrowth,
      },
      {
        path: "pages/subliminal-guide/smoking-addiction-removal",
        name: "smoking-addiction-removal",
        component: SmokingAddictionRemoval,
      },
      {
        path: "pages/subliminal-guide/stop-smoking-completely",
        name: "stop-smoking-completely",
        component: StopSmokingCompletely,
      },
      {
        path: "pages/subliminal-guide/stop-recreational-drug-abuse",
        name: "stop-recreational-drug-abuse",
        component: StopRecreationalDrugAbuse,
      },
      {
        path: "pages/subliminal-guide/stop-using-illegal-drugs-completely",
        name: "stop-using-illegal-drugs-completely",
        component: StopUsingIllegalDrugsCompletely,
      },
      {
        path: "pages/subliminal-guide/alcohol-addiction-removal",
        name: "alcohol-addiction-removal",
        component: AlcoholAddictionRemoval,
      },
      {
        path: "pages/subliminal-guide/cannabis-addiction-removal",
        name: "cannabis-addiction-removal",
        component: CannabisAddictionRemoval,
      },
      {
        path: "pages/subliminal-guide/freedom-from-drug-addiction-and-abuse",
        name: "freedom-from-drug-addiction-and-abuse",
        component: FreedomfromDrugAddictionandAbuse,
      },
      {
        path: "pages/subliminal-guide/gambling-addiction-removal",
        name: "gambling-addiction-removal",
        component: GamblingAddictionRemoval,
      },
      {
        path: "pages/subliminal-guide/pornography-addiction-removal",
        name: "pornography-addiction-removal",
        component: PornographyAddictionRemoval,
      },
      {
        path: "pages/subliminal-guide/masturbation-addiction-removal",
        name: "masturbation-addiction-removal",
        component: MasturbationAddictionRemoval,
      },
      {
        path: "pages/subliminal-guide/alcohol-moderation",
        name: "alcohol-moderation",
        component: AlcoholModeration,
      },
      {
        path: "pages/subliminal-guide/smoking-moderation",
        name: "smoking-moderation",
        component: SmokingModeration,
      },
      {
        path: "pages/subliminal-guide/social-media-addiction-removal",
        name: "social-media-addiction-removal",
        component: SocialMediaAddictionRemoval,
      },
      {
        path: "pages/subliminal-guide/mobile-phone-addiction-removal",
        name: "mobile-phone-addiction-removal",
        component: MobilePhoneAddictionRemoval,
      },
      {
        path: "pages/subliminal-guide/soft-drinks-addiction-removal",
        name: "soft-drinks-addiction-removal",
        component: SoftDrinksAddictionRemoval,
      },
      {
        path: "pages/subliminal-guide/binge-eating-habit-removal",
        name: "binge-eating-habit-removal",
        component: BingeEatingHabitRemoval,
      },
      {
        path: "pages/subliminal-guide/binge-drinking-habit-removal",
        name: "binge-drinking-habit-removal",
        component: BingeDrinkingHabitRemoval,
      },
      {
        path: "pages/subliminal-guide/binge-watching-habit-removal",
        name: "binge-watching-habit-removal",
        component: BingeWatchingHabitRemoval,
      },
      {
        path: "pages/subliminal-guide/chocolate-addiction-removal",
        name: "chocolate-addiction-removal",
        component: ChocolateAddictionRemoval,
      },
      {
        path: "pages/subliminal-guide/sweets-addiction-removal",
        name: "sweets-addiction-removal",
        component: SweetsAddictionRemoval,
      },
      {
        path: "pages/subliminal-guide/selfie-addiction-removal",
        name: "selfie-addiction-removal",
        component: SelfieAddictionRemoval,
      },
      {
        path: "pages/subliminal-guide/stop-nail-biting",
        name: "stop-nail-biting",
        component: StopNailBiting,
      },
      {
        path: "pages/subliminal-guide/stop-swearing",
        name: "stop-swearing",
        component: StopSwearing,
      },
      {
        path: "pages/subliminal-guide/stop-mumbling",
        name: "stop-mumbling",
        component: StopMumbling,
      },
      {
        path: "pages/subliminal-guide/stop-stuttering",
        name: "stop-stuttering",
        component: StopStuttering,
      },
      {
        path: "pages/subliminal-guide/stop-talking-too-fast",
        name: "stop-talking-too-fast",
        component: StopTalkingtoofast,
      },
      {
        path: "pages/subliminal-guide/stop-noisy-breathing",
        name: "stop-noisy-breathing",
        component: StopNoisyBreathing,
      },
      {
        path: "pages/subliminal-guide/stop-teeth-grinding",
        name: "stop-teeth-grinding",
        component: StopTeethGrinding,
      },
      {
        path: "pages/subliminal-guide/stop-sweating-excessively",
        name: "stop-sweating-excessively",
        component: StopSweatingExcessively,
      },
      {
        path: "pages/subliminal-guide/stop-cracking-fingers-and-knuckles",
        name: "stop-cracking-fingers-and-knuckles",
        component: StopCrackingFingersandKnuckles,
      },
      {
        path: "pages/subliminal-guide/stop-foot-stomping",
        name: "stop-foot-stomping",
        component: StopFootStomping,
      },
      {
        path: "pages/subliminal-guide/stop-over-gesturing",
        name: "stop-over-gesturing",
        component: Stopovergesturing,
      },
      {
        path: "pages/subliminal-guide/fear-of-closed-spaces-and-claustrophobia-removal",
        name: "fear-of-closed-spaces-and-claustrophobia-removal",
        component: FearofClosedSpacesandClaustrophobiaRemoval,
      },
      {
        path: "pages/subliminal-guide/fear-of-open-spaces-and-agoraphobia-removal",
        name: "fear-of-open-spaces-and-agoraphobia-removal",
        component: FearofOpenSpacesandAgoraphobiaRemoval,
      },
      {
        path: "pages/subliminal-guide/fear-of-flying-and-aerophobia-removal",
        name: "fear-of-flying-and-aerophobia-removal",
        component: FearofFlyingandAerophobiaRemoval,
      },
      {
        path: "pages/subliminal-guide/fear-of-clustered-holes-and-trypophobia-removal",
        name: "fear-of-clustered-holes-and-trypophobia-removal",
        component: FearofClusteredHolesandTrypophobiaRemoval,
      },
      {
        path: "pages/subliminal-guide/fear-of-schools-and-school-phobia-removal",
        name: "fear-of-schools-and-school-phobia-removal",
        component: FearofSchoolsandSchoolPhobiaRemoval,
      },
      {
        path: "pages/subliminal-guide/fear-of-commitment-and-commitment-phobia-removal",
        name: "fear-of-commitment-and-commitment-phobia-removal",
        component: FearofCommitmentandCommitmentPhobiaRemoval,
      },
      {
        path: "pages/subliminal-guide/fear-of-death-removal",
        name: "fear-of-death-removal",
        component: FearofDeathRemoval,
      },
      {
        path: "pages/subliminal-guide/fear-of-aging-and-gerascophobia-removal",
        name: "fear-of-aging-and-gerascophobia-removal",
        component: FearofAgingandGerascophobiaRemoval,
      },
      {
        path: "pages/subliminal-guide/fear-of-abandonment-removal",
        name: "fear-of-abandonment-removal",
        component: FearofAbandonmentRemoval,
      },
      {
        path: "pages/subliminal-guide/fear-of-being-alone-removal",
        name: "fear-of-being-alone-removal",
        component: FearofBeingAloneRemoval,
      },
      {
        path: "pages/subliminal-guide/fear-of-sickness-and-diseases-removal",
        name: "fear-of-sickness-and-diseases-removal",
        component: FearofSicknessandDiseasesRemoval,
      },
      {
        path: "pages/subliminal-guide/fear-of-cockroaches-and-cockroach-phobia-removal",
        name: "fear-of-cockroaches-and-cockroach-phobia-removal",
        component: FearofCockroachesandCockroachPhobiaRemoval,
      },
      {
        path: "pages/subliminal-guide/fear-of-snakes-and-snake-phobia-removal",
        name: "fear-of-snakes-and-snake-phobia-removal",
        component: FearofSnakesandSnakePhobiaRemoval,
      },
      {
        path: "pages/subliminal-guide/fear-of-spiders-and-arachnophobia-removal",
        name: "fear-of-spiders-and-arachnophobia-removal",
        component: FearofSpidersandArachnophobiaRemoval,
      },
      {
        path: "pages/subliminal-guide/fear-of-doctors-and-doctor-phobia-removal",
        name: "fear-of-doctors-and-doctor-phobia-removal",
        component: FearofDoctorsandDoctorPhobiaRemoval,
      },
      {
        path: "pages/subliminal-guide/fear-of-dogs-and-cynophobia-removal",
        name: "fear-of-dogs-and-cynophobia-removal",
        component: FearofDogsandCynophobiaRemoval,
      },
      {
        path: "pages/subliminal-guide/fear-of-blood-and-hemophobia-removal",
        name: "fear-of-blood-and-hemophobia-removal",
        component: FearofBloodandHemophobiaRemoval,
      },
      {
        path: "pages/subliminal-guide/fear-of-injection-removal",
        name: "fear-of-injection-removal",
        component: FearofInjectionRemoval,
      },
      {
        path: "pages/subliminal-guide/fear-of-cancer-removal",
        name: "fear-of-cancer-removal",
        component: FearofCancerRemoval,
      },
      {
        path: "pages/subliminal-guide/fear-of-childbirth-removal-for-women",
        name: "fear-of-childbirth-removal-for-women",
        component: FearofChildbirthRemovalforWomen,
      },
      {
        path: "pages/subliminal-guide/fear-of-clowns-and-clown-phobia-removal",
        name: "fear-of-clowns-and-clown-phobia-removal",
        component: FearofClownsandClownPhobiaRemoval,
      },
      {
        path: "pages/subliminal-guide/fear-of-conflict-removal",
        name: "fear-of-conflict-removal",
        component: FearofConflictRemoval,
      },
      {
        path: "pages/subliminal-guide/fear-of-confrontation-removal",
        name: "fear-of-confrontation-removal",
        component: FearofConfrontationRemoval,
      },
      {
        path: "pages/subliminal-guide/fear-of-criticism-removal",
        name: "fear-of-criticism-removal",
        component: FearofCriticismRemoval,
      },
      {
        path: "pages/subliminal-guide/fear-of-being-vulnerable-removal",
        name: "fear-of-being-vulnerable-removal",
        component: FearofBeingVulnerableRemoval,
      },
      {
        path: "pages/subliminal-guide/fear-of-heights-and-acrophobia-removal",
        name: "fear-of-heights-and-acrophobia-removal",
        component: FearofHeightsandAcrophobiaRemoval,
      },
      {
        path: "pages/subliminal-guide/fear-of-ghosts-removal",
        name: "fear-of-ghosts-removal",
        component: FearofGhostsRemoval,
      },
      {
        path: "pages/subliminal-guide/fear-of-inadequacy-removal",
        name: "fear-of-inadequacy-removal",
        component: FearofInadequacyRemoval,
      },
      {
        path: "pages/subliminal-guide/fear-of-public-speaking-removal",
        name: "fear-of-public-speaking-removal",
        component: FearofPublicSpeakingRemoval,
      },
      {
        path: "pages/subliminal-guide/fear-of-missing-out-fomo-removal",
        name: "fear-of-missing-out-fomo-removal",
        component: FearofMissingOutFOMORemoval,
      },
      {
        path: "pages/subliminal-guide/fear-of-intimacy-removal",
        name: "fear-of-intimacy-removal",
        component: FearofIntimacyRemoval,
      },
      {
        path: "pages/subliminal-guide/fearless-living---stop-living-in-fear",
        name: "fearless-living---stop-living-in-fear",
        component: FearlessLivingStopLivinginFear,
      },
      {
        path: "pages/subliminal-guide/overcome-analysis-paralysis",
        name: "overcome-analysis-paralysis",
        component: OvercomeAnalysisParalysis,
      },
      {
        path: "pages/subliminal-guide/remove-and-heal-fear-based-anxiety",
        name: "remove-and-heal-fear-based-anxiety",
        component: RemoveandHealFearBasedAnxiety,
      },
      {
        path: "pages/subliminal-guide/stop-suicidal-thoughts-and-suicide-prevention",
        name: "stop-suicidal-thoughts-and-suicide-prevention",
        component: StopSuicidalThoughtsandSuicidePrevention,
      },
      {
        path: "pages/subliminal-guide/stop-feelings-of-worthlessness",
        name: "stop-feelings-of-worthlessness",
        component: StopFeelingsofWorthlessness,
      },
      {
        path: "pages/subliminal-guide/attract-money",
        name: "attract-money",
        component: AttractMoney,
      },
      {
        path: "pages/subliminal-guide/attract-luck",
        name: "attract-luck",
        component: AttractLuck,
      },
      {
        path: "pages/subliminal-guide/become-debt-free",
        name: "become-debt-free",
        component: BecomeDebtFree,
      },
      {
        path: "pages/subliminal-guide/acquire-leadership-qualities",
        name: "acquire-leadership-qualities",
        component: AcquireLeadershipQualities,
      },
      {
        path: "pages/subliminal-guide/sales-success",
        name: "sales-success",
        component: SalesSuccess,
      },
      {
        path: "pages/subliminal-guide/stock-market-trading-success",
        name: "stock-market-trading-success",
        component: StockMarketTradingSuccess,
      },
      {
        path: "pages/subliminal-guide/forex-and-currency-trading-success",
        name: "forex-and-currency-trading-success",
        component: ForexandCurrencyTradingSuccess,
      },
      {
        path: "pages/subliminal-guide/cryptocurrency-trading-success",
        name: "cryptocurrency-trading-success",
        component: CryptocurrencyTradingSuccess,
      },
      {
        path: "pages/subliminal-guide/successful-entrepreneur-programming",
        name: "successful-entrepreneur-programming",
        component: SuccessfulEntrepreneurProgramming,
      },
      {
        path: "pages/subliminal-guide/time-management-and-productivity",
        name: "time-management-and-productivity",
        component: TimeManagementandProductivity,
      },
      {
        path: "pages/subliminal-guide/manifest-relationship-with-a-wealthy-lover",
        name: "manifest-relationship-with-a-wealthy-lover",
        component: ManifestRelationshipwithaWealthyLover,
      },
      {
        path: "pages/subliminal-guide/manifest-a-sugar-mommy",
        name: "manifest-a-sugar-mommy",
        component: ManifestaSugarMommy,
      },
      {
        path: "pages/subliminal-guide/manifest-a-sugar-daddy",
        name: "manifest-a-sugar-daddy",
        component: ManifestaSugarDaddy,
      },
      {
        path: "pages/subliminal-guide/manifest-your-dream-job-and-career",
        name: "manifest-your-dream-job-and-career",
        component: ManifestyourDreamJobandCareer,
      },
      {
        path: "pages/subliminal-guide/manifest-your-dream-business",
        name: "manifest-your-dream-business",
        component: ManifestyourDreamBusiness,
      },
      {
        path: "pages/subliminal-guide/disconnect-from-people-who-drags-you-down",
        name: "disconnect-from-people-who-drags-you-down",
        component: DisconnectfromPeopleWhoDragsYouDown,
      },
      {
        path: "pages/subliminal-guide/disconnect-from-toxic-coworkers",
        name: "disconnect-from-toxic-coworkers",
        component: DisconnectFromToxicCoworkers,
      },
      {
        path: "pages/subliminal-guide/curse-cancellation-and-protection-shield",
        name: "curse-cancellation-and-protection-shield",
        component: CurseCancellationandProtectionShield,
      },
      {
        path: "pages/subliminal-guide/cancel-bankruptcy",
        name: "cancel-bankruptcy",
        component: CancelBankruptcy,
      },
      {
        path: "pages/subliminal-guide/anger-management-issues-removal",
        name: "anger-management-issues-removal",
        component: AngerManagementIssuesRemoval,
      },
      {
        path: "pages/subliminal-guide/emotional-release",
        name: "emotional-release",
        component: EmotionalRelease,
      },
      {
        path: "pages/subliminal-guide/anger-release",
        name: "anger-release",
        component: AngerRelease,
      },
      {
        path: "pages/subliminal-guide/anxiety-healing",
        name: "anxiety-healing",
        component: AnxietyHealing,
      },
      {
        path: "pages/subliminal-guide/general-emotional-healing",
        name: "general-emotional-healing",
        component: GeneralEmotionalHealing,
      },
      {
        path: "pages/subliminal-guide/get-over-and-move-on-from-your-ex-and-past-relationships",
        name: "get-over-and-move-on-from-your-ex-and-past-relationships",
        component: GetOverandMoveonfromYourExandPastRelationships,
      },
      {
        path: "pages/subliminal-guide/break-up-emotional-healing-and-recovery",
        name: "break-up-emotional-healing-and-recovery",
        component: BreakupEmotionalHealingandRecovery,
      },
      {
        path: "pages/subliminal-guide/get-over-and-move-on-from-divorce",
        name: "get-over-and-move-on-from-divorce",
        component: GetoverandMoveonfromDivorce,
      },
      {
        path: "pages/subliminal-guide/divorce-emotional-pain-healing",
        name: "divorce-emotional-pain-healing",
        component: DivorceEmotionalPainHealing,
      },
      {
        path: "pages/subliminal-guide/broken-family-emotional-healing",
        name: "broken-family-emotional-healing",
        component: BrokenFamilyEmotionalHealing,
      },
      {
        path: "pages/subliminal-guide/emotional-pain-management",
        name: "emotional-pain-management",
        component: EmotionalPainManagement,
      },
      {
        path: "pages/subliminal-guide/emotional-pain-management",
        name: "emotional-pain-management",
        component: EmotionalPainManagement,
      },
      {
        path: "pages/subliminal-guide/release-forgiveness",
        name: "release-forgiveness",
        component: ReleaseForgiveness,
      },
      {
        path: "pages/subliminal-guide/general-trauma-healing",
        name: "general-trauma-healing",
        component: GeneralTraumaHealing,
      },
      {
        path: "pages/subliminal-guide/depression-healing-and-removal",
        name: "depression-healing-and-removal",
        component: DepressionHealingandRemoval,
      },
      {
        path: "pages/subliminal-guide/general-abuse-healing",
        name: "general-abuse-healing",
        component: GeneralAbuseHealing,
      },
      {
        path: "pages/subliminal-guide/master-your-emotions-and-responses",
        name: "master-your-emotions-and-responses",
        component: MasteryourEmotionsandResponses,
      },
      {
        path: "pages/subliminal-guide/overcome-cyber-bullying",
        name: "overcome-cyber-bullying",
        component: OvercomeCyberBullying,
      },
      {
        path: "pages/subliminal-guide/heal-post-traumatic-stress-disorder-(ptsd)",
        name: "heal-post-traumatic-stress-disorder-(ptsd)",
        component: HealPostTraumaticStressDisorderPTSD,
      },
      {
        path: "pages/subliminal-guide/safe-haven---feel-safe-and-secure-always",
        name: "safe-haven---feel-safe-and-secure-always",
        component: SafeHavenFeelsafeandSecureAlways,
      },
      {
        path: "pages/subliminal-guide/calm-and-peaceful-feelings",
        name: "calm-and-peaceful-feelings",
        component: CalmandPeacefulFeelings,
      },
      {
        path: "pages/subliminal-guide/toxic-shame-healing",
        name: "toxic-shame-healing",
        component: ToxicShameHealing,
      },
      {
        path: "pages/subliminal-guide/childbirth-trauma-healing",
        name: "childbirth-trauma-healing",
        component: ChildbirthTraumaHealing,
      },
      {
        path: "pages/subliminal-guide/stop-self-harming",
        name: "stop-self-harming",
        component: StopSelfHarming,
      },
      {
        path: "pages/subliminal-guide/self-forgiveness",
        name: "self-forgiveness",
        component: SelfForgiveness,
      },
      {
        path: "pages/subliminal-guide/forgive-others-and-move-on",
        name: "forgive-others-and-move-on",
        component: ForgiveOthersandMoveOn,
      },
      {
        path: "pages/subliminal-guide/assertiveness-training",
        name: "assertiveness-training",
        component: AssertivenessTraining,
      },
      {
        path: "pages/subliminal-guide/develop-positive-humor",
        name: "develop-positive-humor",
        component: DevelopPositiveHumor,
      },
      {
        path: "pages/subliminal-guide/happier-life-now",
        name: "happier-life-now",
        component: HappierLifeNow,
      },
      {
        path: "pages/subliminal-guide/become-an-early-riser",
        name: "become-an-early-riser",
        component: BecomeanEarlyRiser,
      },
      {
        path: "pages/subliminal-guide/become-a-night-owl",
        name: "become-a-night-owl",
        component: BecomeaNightOwl,
      },
      {
        path: "pages/subliminal-guide/socialization-and-social-skills-enhancement",
        name: "socialization-and-social-skills-enhancement",
        component: SocializationandSocialSkillsEnhancement,
      },
      {
        path: "pages/subliminal-guide/let-go-of-the-past-and-move-on",
        name: "let-go-of-the-past-and-move-on",
        component: LetgoofthePastandMoveon,
      },
      {
        path: "pages/subliminal-guide/positive-thinking-and-mindset",
        name: "positive-thinking-and-mindset",
        component: PositiveThinkingandMindset,
      },
      {
        path: "pages/subliminal-guide/positive-attitude-training-and-development",
        name: "positive-attitude-training-and-development",
        component: PositiveAttitudeTrainingandDevelopment,
      },
      {
        path: "pages/subliminal-guide/toxic-and-negative-attitude-removal",
        name: "toxic-and-negative-attitude-removal",
        component: ToxicandNegativeAttitudeRemoval,
      },
      {
        path: "pages/subliminal-guide/behavioral-development",
        name: "behavioral-development",
        component: BehavioralDevelopment,
      },
      {
        path: "pages/subliminal-guide/public-speaking-skills-improvement-and-training",
        name: "public-speaking-skills-improvement-and-training",
        component: PublicSpeakingSkillsImprovementandTraining,
      },
      {
        path: "pages/subliminal-guide/resolve-inner-conflict",
        name: "resolve-inner-conflict",
        component: ResolveInnerConflict,
      },
      {
        path: "pages/subliminal-guide/overcome-and-heal-shame",
        name: "overcome-and-heal-shame",
        component: OvercomeandHealShame,
      },
      {
        path: "pages/subliminal-guide/resolve-and-heal-guilt",
        name: "resolve-and-heal-guilt",
        component: ResolveandHealGuilt,
      },
      {
        path: "pages/subliminal-guide/self-care",
        name: "self-care",
        component: SelfCare,
      },
      {
        path: "pages/subliminal-guide/self--responsibility",
        name: "self--responsibility",
        component: SelfResponsibility,
      },
      {
        path: "pages/subliminal-guide/self-belief",
        name: "self-belief",
        component: SelfBelief,
      },
      {
        path: "pages/subliminal-guide/self-discipline",
        name: "self-discipline",
        component: SelfDiscipline,
      },
      {
        path: "pages/subliminal-guide/meditation-aid-and-improvement",
        name: "meditation-aid-and-improvement",
        component: MeditationAidandImprovement,
      },
      {
        path: "pages/subliminal-guide/charisma-development",
        name: "charisma-development",
        component: CharismaDevelopment,
      },
      {
        path: "pages/subliminal-guide/self-love",
        name: "self-love",
        component: SelfLove,
      },
      {
        path: "pages/subliminal-guide/self-acceptance",
        name: "self-acceptance",
        component: SelfAcceptance,
      },
      {
        path: "pages/subliminal-guide/self-consideration",
        name: "self-consideration",
        component: SelfConsideration,
      },
      {
        path: "pages/subliminal-guide/insecurity-removal",
        name: "insecurity-removal",
        component: InsecurityRemoval,
      },
      {
        path: "pages/subliminal-guide/stop-being-a-people-pleaser",
        name: "stop-being-a-people-pleaser",
        component: StopBeingaPeoplePleaser,
      },
      {
        path: "pages/subliminal-guide/learn-to-say-no",
        name: "learn-to-say-no",
        component: LearntosayNO,
      },
      {
        path: "pages/subliminal-guide/accepting-new-challenges-and-expanding-comfort-zone",
        name: "accepting-new-challenges-and-expanding-comfort-zone",
        component: AcceptingNewChallengesandExpandingComfortZone,
      },
      {
        path: "pages/subliminal-guide/stop-talking-too-much",
        name: "stop-talking-too-much",
        component: StopTalkingTooMuch,
      },
      {
        path: "pages/subliminal-guide/failure-mental-and-emotional-stability-and-recovery",
        name: "failure-mental-and-emotional-stability-and-recovery",
        component: FailureMentalandEmotionalStabilityandRecovery,
      },
      {
        path: "pages/subliminal-guide/become-more-resilient",
        name: "become-more-resilient",
        component: BecomeMoreResilient,
      },
      {
        path: "pages/subliminal-guide/courageously-fearless",
        name: "courageously-fearless",
        component: CourageouslyFearless,
      },
      {
        path: "pages/subliminal-guide/overcoming-shyness",
        name: "overcoming-shyness",
        component: OvercomingShyness,
      },
      {
        path: "pages/subliminal-guide/stop-hair-loss",
        name: "stop-hair-loss",
        component: StopHairLoss,
      },
      {
        path: "pages/subliminal-guide/develop-emotional-maturity",
        name: "develop-emotional-maturity",
        component: DevelopEmotionalMaturity,
      },
      {
        path: "pages/subliminal-guide/positive-relationship-with-parents",
        name: "positive-relationship-with-parents",
        component: PositiveRelationshipwithParents,
      },
      {
        path: "pages/subliminal-guide/positive-relationship-with-coworkers",
        name: "positive-relationship-with-coworkers",
        component: PositiveRelationshipwithCoworkers,
      },
      {
        path: "pages/subliminal-guide/positive-relationship-with-boss",
        name: "positive-relationship-with-boss",
        component: PositiveRelationshipwithBoss,
      },
      {
        path: "pages/subliminal-guide/positive-relationship-with-siblings",
        name: "positive-relationship-with-siblings",
        component: PositiveRelationshipwithSiblings,
      },
      {
        path: "pages/subliminal-guide/positive-relationship-with-lover",
        name: "positive-relationship-with-lover",
        component: PositiveRelationshipwithLover,
      },
      {
        path: "pages/subliminal-guide/get-over-jealousy",
        name: "get-over-jealousy",
        component: GetoverJealousy,
      },
      {
        path: "pages/subliminal-guide/heal-relationship-anxiety",
        name: "heal-relationship-anxiety",
        component: HealRelationshipAnxiety,
      },
      {
        path: "pages/subliminal-guide/maximum-athletic-performance-enhancement",
        name: "maximum-athletic-performance-enhancement",
        component: MaximumAthleticPerformanceEnhancement,
      },
      {
        path: "pages/subliminal-guide/basketball-skills-training-and-mastery",
        name: "basketball-skills-training-and-mastery",
        component: BasketballSkillsTrainingandMastery,
      },
      {
        path: "pages/subliminal-guide/baseball-skills-training-and-mastery",
        name: "baseball-skills-training-and-mastery",
        component: BaseballSkillsTrainingandMastery,
      },
      {
        path: "pages/subliminal-guide/american-football-skills-and-training-mastery",
        name: "american-football-skills-and-training-mastery",
        component: AmericanFootballSkillsandTrainingMastery,
      },
      {
        path: "pages/subliminal-guide/rugby-skills-and-training-mastery",
        name: "rugby-skills-and-training-mastery",
        component: RugbySkillsandTrainingMastery,
      },
      {
        path: "pages/subliminal-guide/archery-skills-and-training-mastery",
        name: "archery-skills-and-training-mastery",
        component: ArcherySkillsandTrainingMastery,
      },
      {
        path: "pages/subliminal-guide/darts-skills-and-training-mastery",
        name: "darts-skills-and-training-mastery",
        component: DartsSkillsandTrainingMastery,
      },
      {
        path: "pages/subliminal-guide/boxing-training-and-mastery",
        name: "boxing-training-and-mastery",
        component: BoxingTrainingandMastery,
      },
      {
        path: "pages/subliminal-guide/fencing-training-and-mastery",
        name: "fencing-training-and-mastery",
        component: FencingTrainingandMastery,
      },
      {
        path: "pages/subliminal-guide/kendo-training-and-mastery",
        name: "kendo-training-and-mastery",
        component: KendoTrainingandMastery,
      },
      {
        path: "pages/subliminal-guide/wrestling-training-and-mastery",
        name: "wrestling-training-and-mastery",
        component: WrestlingTrainingandMastery,
      },
      {
        path: "pages/subliminal-guide/muay-thai-training-and-mastery",
        name: "muay-thai-training-and-mastery",
        component: MuayThaiTrainingandMastery,
      },
      {
        path: "pages/subliminal-guide/kali-arnis-training-and-mastery",
        name: "kali-arnis-training-and-mastery",
        component: KaliArnisTrainingandMastery,
      },
      {
        path: "pages/subliminal-guide/yaw-yan-fervilleon-martial-arts-training-and-mastery",
        name: "yaw-yan-fervilleon-martial-arts-training-and-mastery",
        component: YawYanFervilleonMartialArtsTrainingandMastery,
      },
      {
        path: "pages/subliminal-guide/swordsmanship-training-and-mastery",
        name: "swordsmanship-training-and-mastery",
        component: SwordsmanshipTrainingandMastery,
      },
      {
        path: "pages/subliminal-guide/judo-training-and-mastery",
        name: "judo-training-and-mastery",
        component: JudoTrainingandMastery,
      },
      {
        path: "pages/subliminal-guide/aikido-training-and-mastery",
        name: "aikido-training-and-mastery",
        component: AikidoTrainingandMastery,
      },
      {
        path: "pages/subliminal-guide/jeet-kune-do-training-and-mastery",
        name: "jeet-kune-do-training-and-mastery",
        component: JeetKuneDoTrainingandMastery,
      },
      {
        path: "pages/subliminal-guide/wing-chun-kung-fu-training-and-mastery",
        name: "wing-chun-kung-fu-training-and-mastery",
        component: WingChunKungFuTrainingandMastery,
      },
      {
        path: "pages/subliminal-guide/jujitsu-training-and-mastery",
        name: "jujitsu-training-and-mastery",
        component: JujitsuTrainingandMastery,
      },
      {
        path: "pages/subliminal-guide/karate-training-and-mastery",
        name: "karate-training-and-mastery",
        component: KarateTrainingandMastery,
      },
      {
        path: "pages/subliminal-guide/taekwondo-training-and-mastery",
        name: "taekwondo-training-and-mastery",
        component: TaekwondoTrainingandMastery,
      },
      {
        path: "pages/subliminal-guide/tai-chi-training-and-mastery",
        name: "tai-chi-training-and-mastery",
        component: TaiChiTrainingandMastery,
      },
      {
        path: "pages/subliminal-guide/bajiquan-training-and-mastery",
        name: "bajiquan-training-and-mastery",
        component: BajiquanTrainingandMastery,
      },
      {
        path: "pages/subliminal-guide/singing-talent-and-skills-enhancement",
        name: "singing-talent-and-skills-enhancement",
        component: SingingTalentandSkillsEnhancement,
      },
      {
        path: "pages/subliminal-guide/dancing-talent-and-skills-enhancement",
        name: "dancing-talent-and-skills-enhancement",
        component: DancingTalentandSkillsEnhancement,
      },
      {
        path: "pages/subliminal-guide/musical-instruments-talent-and-skills-enhancement",
        name: "musical-instruments-talent-and-skills-enhancement",
        component: MusicalInstrumentsTalentandSkillsEnhancement,
      },
      {
        path: "pages/subliminal-guide/drawing-talent-and-skills-enhancement",
        name: "drawing-talent-and-skills-enhancement",
        component: DrawingTalentandSkillsEnhancement,
      },
      {
        path: "pages/subliminal-guide/painting-talent-and-skills-enhancement",
        name: "painting-talent-and-skills-enhancement",
        component: PaintingTalentandSkillsEnhancement,
      },
      {
        path: "pages/subliminal-guide/become-a-successful-teacher",
        name: "become-a-successful-teacher",
        component: BecomeaSuccessfulTeacher,
      },
      {
        path: "pages/subliminal-guide/become-a-successful-insurance-broker",
        name: "become-a-successful-insurance-broker",
        component: BecomeaSuccessfulInsuranceBroker,
      },
      {
        path: "pages/subliminal-guide/become-a-successful-vlogger",
        name: "become-a-successful-vlogger",
        component: BecomeaSuccessfulVlogger,
      },
      {
        path: "pages/subliminal-guide/become-a-successful-youtuber",
        name: "become-a-successful-youtuber",
        component: BecomeaSuccessfulYoutuber,
      },
      {
        path: "pages/subliminal-guide/become-a-successful-call-center-agent",
        name: "become-a-successful-call-center-agent",
        component: BecomeaSuccessfulCallCenterAgent,
      },
      {
        path: "pages/subliminal-guide/become-a-successful-programmer",
        name: "become-a-successful-programmer",
        component: BecomeaSuccessfulProgrammer,
      },
      {
        path: "pages/subliminal-guide/become-a-successful-social-media-marketer",
        name: "become-a-successful-social-media-marketer",
        component: BecomeaSuccessfulSocialMediaMarketer,
      },
      {
        path: "pages/subliminal-guide/become-a-successful-architect",
        name: "become-a-successful-architect",
        component: BecomeaSuccessfulArchitect,
      },
      {
        path: "pages/subliminal-guide/become-a-successful-mechanical-engineer",
        name: "become-a-successful-mechanical-engineer",
        component: BecomeaSuccessfulMechanicalEngineer,
      },
      {
        path: "pages/subliminal-guide/become-a-successful-civil-engineer",
        name: "become-a-successful-civil-engineer",
        component: BecomeaSuccessfulCivilEngineer,
      },
      {
        path: "pages/subliminal-guide/become-a-successful-project-manager",
        name: "become-a-successful-project-manager",
        component: BecomeaSuccessfulProjectManager,
      },
      {
        path: "pages/subliminal-guide/become-a-successful-lawyer",
        name: "become-a-successful-lawyer",
        component: BecomeaSuccessfulLawyer,
      },
      {
        path: "pages/subliminal-guide/brain-healing-and-regeneration",
        name: "brain-healing-and-regeneration",
        component: BrainHealingandRegeneration,
      },
      {
        path: "pages/subliminal-guide/strategic-thinking-skills-training-and-development",
        name: "strategic-thinking-skills-training-and-development",
        component: StrategicThinkingSkillsTrainingandDevelopment,
      },
      {
        path: "pages/subliminal-guide/memory-enhancement-and-booster",
        name: "memory-enhancement-and-booster",
        component: MemoryEnhancementandBooster,
      },
      {
        path: "pages/subliminal-guide/chess-training-and-mastery",
        name: "chess-training-and-mastery",
        component: ChessTrainingandMastery,
      },
      {
        path: "pages/subliminal-guide/board-games-training-and-mastery",
        name: "board-games-training-and-mastery",
        component: BoardGamesTrainingandMastery,
      },
      {
        path: "pages/subliminal-guide/creative-thinking",
        name: "creative-thinking",
        component: CreativeThinking,
      },
      {
        path: "pages/subliminal-guide/brain-performance-enhancement",
        name: "brain-performance-enhancement",
        component: BrainPerformanceEnhancement,
      },
      {
        path: "pages/subliminal-guide/numerical-intelligence-improvement",
        name: "numerical-intelligence-improvement",
        component: NumericalIntelligenceImprovement,
      },
      {
        path: "pages/subliminal-guide/speed-reading-success",
        name: "speed-reading-success",
        component: SpeedReadingSuccess,
      },
      {
        path: "pages/subliminal-guide/photoreading-success",
        name: "photoreading-success",
        component: PhotoReadingSuccess,
      },
      {
        path: "pages/subliminal-guide/stop-the-habit-of-translating-in-the-mind-before-you-speak",
        name: "stop-the-habit-of-translating-in-the-mind-before-you-speak",
        component: Stopthehabitoftranslatinginthemindbeforeyouspeak,
      },
      {
        path: "pages/subliminal-guide/general-language-learning-aid",
        name: "general-language-learning-aid",
        component: GeneralLanguageLearningAid,
      },
      {
        path: "pages/subliminal-guide/proper-accent-speech-training",
        name: "proper-accent-speech-training",
        component: ProperAccentSpeechTraining,
      },
      {
        path: "pages/subliminal-guide/english-language-learning-aid",
        name: "english-language-learning-aid",
        component: EnglishLanguageLearningAid,
      },
      {
        path: "pages/subliminal-guide/korean-language-learning-aid",
        name: "korean-language-learning-aid",
        component: KoreanLanguageLearningAid,
      },
      {
        path: "pages/subliminal-guide/nihongo-japanese-language-learning-aid",
        name: "nihongo-japanese-language-learning-aid",
        component: NihongoJapaneseLanguageLearningAid,
      },
      {
        path: "pages/subliminal-guide/mandarin-chinese-language-learning-aid",
        name: "mandarin-chinese-language-learning-aid",
        component: MandarinChineseLanguageLearningAid,
      },
      {
        path: "pages/subliminal-guide/russian-language-learning-aid",
        name: "russian-language-learning-aid",
        component: RussianLanguageLearningAid,
      },
      {
        path: "pages/subliminal-guide/spanish-language-learning-aid",
        name: "spanish-language-learning-aid",
        component: SpanishLanguageLearningAid,
      },
      {
        path: "pages/subliminal-guide/indian-language-learning-aid",
        name: "indian-language-learning-aid",
        component: IndianLanguageLearningAid,
      },
      {
        path: "pages/subliminal-guide/confidence-for-single-dad",
        name: "confidence-for-single-dad",
        component: ConfidenceforSingleDad,
      },
      {
        path: "pages/subliminal-guide/confidence-for-single-mom",
        name: "confidence-for-single-mom",
        component: ConfidenceforSingleMom,
      },
      {
        path: "pages/subliminal-guide/parenting-confidence",
        name: "parenting-confidence",
        component: ParentingConfidence,
      },
      {
        path: "pages/subliminal-guide/confidence-with-the-opposite-sex",
        name: "confidence-with-the-opposite-sex",
        component: Confidencewiththeoppositesex,
      },
      {
        path: "pages/subliminal-guide/flirting-confidence-for-men",
        name: "flirting-confidence-for-men",
        component: FlirtingConfidenceforMen,
      },
      {
        path: "pages/subliminal-guide/flirting-confidence-for-women",
        name: "flirting-confidence-for-women",
        component: FlirtingConfidenceforWomen,
      },
      {
        path: "pages/subliminal-guide/lucid-dreaming-assistance",
        name: "lucid-dreaming-assistance",
        component: LucidDreamingAssistance,
      },
      {
        path: "pages/subliminal-guide/astral-projection-and-travel",
        name: "astral-projection-and-travel",
        component: AstralProjectionandTravel,
      },
      {
        path: "pages/subliminal-guide/dream-memory-recall",
        name: "dream-memory-recall",
        component: DreamMemoryRecall,
      },
      {
        path: "pages/subliminal-guide/make-people-respect-you",
        name: "make-people-respect-you",
        component: MakePeopleRespectyou,
      },
      {
        path: "pages/subliminal-guide/end-nightmares",
        name: "end-nightmares",
        component: EndNightmares,
      },
      {
        path: "pages/subliminal-guide/stop-drunk-driving",
        name: "stop-drunk-driving",
        component: StopDrunkDriving,
      },
      {
        path: "pages/subliminal-guide/stop-second-hand-smoking",
        name: "stop-second-hand-smoking",
        component: StopSecondHandSmoking,
      },
      {
        path: "pages/subliminal-guide/magnetic-personality",
        name: "magnetic-personality",
        component: MagneticPersonality,
      },
      {
        path: "pages/subliminal-guide/improve-personal-magnetism",
        name: "improve-personal-magnetism",
        component: ImprovePersonalMagnetism,
      },
      {
        path: "pages/subliminal-guide/social-gathering-confidence",
        name: "social-gathering-confidence",
        component: SocialGatheringConfidence,
      },
      {
        path: "pages/subliminal-guide/self-confidence-booster",
        name: "self-confidence-booster",
        component: SelfConfidenceBooster,
      },
      {
        path: "pages/subliminal-guide/healthy-body-development",
        name: "healthy-body-development",
        component: HealthyBodyDevelopment,
      },
      {
        path: "pages/subliminal-guide/personal-power-development",
        name: "personal-power-development",
        component: PersonalPowerDevelopment,
      },
      {
        path: "pages/subliminal-guide/success-secrets",
        name: "success-secrets",
        component: SuccessSecrets,
      },
      {
        path: "pages/subliminal-guide/general-life-success",
        name: "general-life-success",
        component: GeneralLifeSuccess,
      },
      {
        path: "pages/subliminal-guide/housework-productivity-and-enjoyment",
        name: "housework-productivity-and-enjoyment",
        component: HouseworkProductivityandEnjoyment,
      },
      {
        path: "pages/subliminal-guide/exam-success",
        name: "exam-success",
        component: ExamSuccess,
      },
      {
        path: "pages/subliminal-guide/focus-and-concentration-training-and-development",
        name: "focus-and-concentration-training-and-development",
        component: FocusandConcentrationTrainingandDevelopment,
      },
      {
        path: "pages/subliminal-guide/job-interview-success",
        name: "job-interview-success",
        component: JobInterviewSuccess,
      },
      {
        path: "pages/subliminal-guide/investment-presentation-success",
        name: "investment-presentation-success",
        component: InvestmentPresentationSuccess,
      },
      {
        path: "pages/subliminal-guide/law-of-attraction-maximizer",
        name: "law-of-attraction-maximizer",
        component: LawofAttractionMaximizer,
      },
      {
        path: "pages/subliminal-guide/quantum-jumping-success",
        name: "quantum-jumping-success",
        component: QuantumJumpingSuccess,
      },
      {
        path: "pages/subliminal-guide/motivation-improvement",
        name: "motivation-improvement",
        component: MotivationImprovement,
      },
      {
        path: "pages/subliminal-guide/overcome-and-stop-procrastination",
        name: "overcome-and-stop-procrastination",
        component: OvercomeandStopProcrastination,
      },
      {
        path: "pages/subliminal-guide/achieve-goals",
        name: "achieve-goals",
        component: AchieveGoals,
      },
      {
        path: "pages/subliminal-guide/anorexia-healing",
        name: "anorexia-healing",
        component: AnorexiaHealing,
      },
      {
        path: "pages/subliminal-guide/attract-health",
        name: "attract-health",
        component: AttractHealth,
      },
      {
        path: "pages/subliminal-guide/have-a-healthy-lifestyle",
        name: "have-a-healthy-lifestyle",
        component: HaveaHealthyLifestyle,
      },
      {
        path: "pages/subliminal-guide/become-more-sociable-and-outgoing",
        name: "become-more-sociable-and-outgoing",
        component: BecomeMoreSociableandOutgoing,
      },
      {
        path: "pages/subliminal-guide/become-more-persistent",
        name: "become-more-persistent",
        component: BecomeMorePersistent,
      },
      {
        path: "pages/subliminal-guide/become-more-productive",
        name: "become-more-productive",
        component: BecomeMoreProductive,
      },
      {
        path: "pages/subliminal-guide/stop-bed-wetting",
        name: "stop-bed-wetting",
        component: StopBedWetting,
      },
      {
        path: "pages/subliminal-guide/charismatic-personality-enhancement",
        name: "charismatic-personality-enhancement",
        component: CharismaticPersonalityEnhancement,
      },
      {
        path: "pages/subliminal-guide/sex-addiction-healing-and-removal",
        name: "sex-addiction-healing-and-removal",
        component: SexAddictionHealingandRemoval,
      },
      {
        path: "pages/subliminal-guide/freedom-from-worry",
        name: "freedom-from-worry",
        component: FreedomfromWorry,
      },
      {
        path: "pages/subliminal-guide/stop-anxiety-based-panic-attacks",
        name: "stop-anxiety-based-panic-attacks",
        component: StopAnxietyBasedPanicAttacks,
      },
      {
        path: "pages/subliminal-guide/develop-telepathy",
        name: "develop-telepathy",
        component: DevelopTelepathy,
      },
      {
        path: "pages/subliminal-guide/think-like-a-wealthy-person",
        name: "think-like-a-wealthy-person",
        component: ThinklikeaWealthyPerson,
      },
      {
        path: "pages/subliminal-guide/getting-things-done-habit-development",
        name: "getting-things-done-habit-development",
        component: GettingThingsDoneHabitDevelopment,
      },
      {
        path: "pages/subliminal-guide/have-an-extroverted-personality",
        name: "have-an-extroverted-personality",
        component: HaveanExtrovertedPersonality,
      },
      {
        path: "pages/subliminal-guide/thinking-outside-the-box",
        name: "thinking-outside-the-box",
        component: ThinkingOutsidetheBox,
      },
      {
        path: "pages/subliminal-guide/confidence-for-tall-man",
        name: "confidence-for-tall-man",
        component: ConfidenceforTallMan,
      },
      {
        path: "pages/subliminal-guide/confidence-for-short-man",
        name: "confidence-for-short-man",
        component: ConfidenceforShortMan,
      },
      {
        path: "pages/subliminal-guide/confidence-for-short-women",
        name: "confidence-for-short-women",
        component: ConfidenceforShortWomen,
      },
      {
        path: "pages/subliminal-guide/confidence-for-person-with-disabilities",
        name: "confidence-for-person-with-disabilities",
        component: ConfidenceforPersonwithDisabilities,
      },
      {
        path: "pages/subliminal-guide/reinventing-yourself",
        name: "reinventing-yourself",
        component: ReinventingYourself,
      },
      {
        path: "pages/subliminal-guide/restless-leg-syndrome",
        name: "restless-leg-syndrome",
        component: RestlessLegSyndrome,
      },
      {
        path: "pages/subliminal-guide/organization-skills-enhancement",
        name: "organization-skills-enhancement",
        component: OrganizationSkillsEnhancement,
      },
      {
        path: "pages/subliminal-guide/manifestation-willpower-enhancement",
        name: "manifestation-willpower-enhancement",
        component: ManifestationWillpowerEnhancement,
      },
      {
        path: "pages/subliminal-guide/tap-into-your-personal-power",
        name: "tap-into-your-personal-power",
        component: TapintoyourPersonalPower,
      },
      {
        path: "pages/subliminal-guide/think-big-and-make-it-big",
        name: "think-big-and-make-it-big",
        component: ThinkBigandMakeitBig,
      },
      {
        path: "pages/subliminal-guide/push-plans-and-goals-to-the-limit",
        name: "push-plans-and-goals-to-the-limit",
        component: PushPlansandGoalstotheLimit,
      },
      {
        path: "pages/subliminal-guide/attitude-of-gratitude",
        name: "attitude-of-gratitude",
        component: AttitudeofGratitude,
      },
      {
        path: "pages/subliminal-guide/attract-abundant-money",
        name: "attract-abundant-money",
        component: AttractAbundantMoney,
      },
      {
        path: "pages/subliminal-guide/attract-success",
        name: "attract-success",
        component: AttractSuccess,
      },
      {
        path: "pages/subliminal-guide/attract-prosperity",
        name: "attract-prosperity",
        component: AttractProsperity,
      },
      {
        path: "pages/subliminal-guide/law-of-attraction-success",
        name: "law-of-attraction-success",
        component: LawofAttractionSuccess,
      },
      {
        path: "pages/subliminal-guide/financially-organized",
        name: "financially-organized",
        component: FinanciallyOrganized,
      },
      {
        path: "pages/subliminal-guide/plan-the-movement-of-money-carefully",
        name: "plan-the-movement-of-money-carefully",
        component: Planthemovementofmoneycarefully,
      },
      {
        path: "pages/subliminal-guide/sustainable-financials-and-financial-plan",
        name: "sustainable-financials-and-financial-plan",
        component: SustainableFinancialsandFinancialPlan,
      },
      {
        path: "pages/subliminal-guide/have-enough-money-to-pay-off-financial-obligations",
        name: "have-enough-money-to-pay-off-financial-obligations",
        component: Haveenoughmoneytopayofffinancialobligations,
      },
      {
        path: "pages/subliminal-guide/millionaire-money-mindset",
        name: "millionaire-money-mindset",
        component: MillionaireMoneyMindset,
      },
      {
        path: "pages/subliminal-guide/solid-decision-belief-and-conviction",
        name: "solid-decision-belief-and-conviction",
        component: SolidDecisionBeliefandConviction,
      },
      {
        path: "pages/subliminal-guide/emotion-management",
        name: "emotion-management",
        component: EmotionManagement,
      },
      {
        path: "pages/subliminal-guide/purpose-driven-life-know-your-purpose-in-life",
        name: "purpose-driven-life-know-your-purpose-in-life",
        component: PurposeDrivenLifeKnowYourpurposeinLife,
      },
      {
        path: "pages/subliminal-guide/love-languages-mastery-and-expression",
        name: "love-languages-mastery-and-expression",
        component: LoveLanguagesMasteryandExpression,
      },
      {
        path: "pages/subliminal-guide/mindfulness-training",
        name: "mindfulness-training",
        component: MindfulnessTraining,
      },
      {
        path: "pages/subliminal-guide/enhanced-vitality",
        name: "enhanced-vitality",
        component: EnhancedVitality,
      },
      {
        path: "pages/subliminal-guide/motion-sickness-cure",
        name: "motion-sickness-cure",
        component: MotionSicknessCure,
      },
      {
        path: "pages/subliminal-guide/positive-self-talk",
        name: "positive-self-talk",
        component: PositiveSelfTalk,
      },
      {
        path: "pages/subliminal-guide/clarity-of-mind",
        name: "clarity-of-mind",
        component: ClarityofMind,
      },
      {
        path: "pages/subliminal-guide/getting-unstuck",
        name: "getting-unstuck",
        component: GettingUnstuck,
      },
      {
        path: "pages/subliminal-guide/optimistic-mindset",
        name: "optimistic-mindset",
        component: OptimisticMindset,
      },
      {
        path: "pages/subliminal-guide/housework-motivation",
        name: "housework-motivation",
        component: HouseworkMotivation,
      },
      {
        path: "pages/subliminal-guide/real-estate-selling-success",
        name: "real-estate-selling-success",
        component: RealEstateSellingSuccess,
      },
      {
        path: "pages/subliminal-guide/weatherproof-mindset",
        name: "weatherproof-mindset",
        component: WeatherproofMindset,
      },
      {
        path: "pages/subliminal-guide/opportunity-obstacles-and-blockages-removal",
        name: "opportunity-obstacles-and-blockages-removal",
        component: OpportunityObstaclesandBlockagesRemoval,
      },
      {
        path: "pages/subliminal-guide/minimalist-living",
        name: "minimalist-living",
        component: MinimalistLiving,
      },
      {
        path: "pages/subliminal-guide/self-judgement-healing",
        name: "self-judgement-healing",
        component: SelfJudgementHealing,
      },
      {
        path: "pages/subliminal-guide/intentional-living---making-every-moment-matters",
        name: "intentional-living---making-every-moment-matters",
        component: IntentionalLivingMakingEveryMomentMatters,
      },
      {
        path: "pages/subliminal-guide/inner-belief-and-conviction-manifestation",
        name: "inner-belief-and-conviction-manifestation",
        component: InnerBeliefandConvictionManifestation,
      },
      {
        path: "pages/subliminal-guide/self-life-coach---become-your-own-life-coach",
        name: "self-life-coach---become-your-own-life-coach",
        component: SelfLifeCoachBecomeYourOwnLifeCoach,
      },
      {
        path: "pages/subliminal-guide/akashic-records-accessing",
        name: "akashic-records-accessing",
        component: AkashicRecordsAccessing,
      },
      {
        path: "pages/subliminal-guide/toothache-pain-relief",
        name: "toothache-pain-relief",
        component: ToothachePainRelief,
      },
      {
        path: "pages/subliminal-guide/deep-sleep-training",
        name: "deep-sleep-training",
        component: DeepSleepTraining,
      },
      {
        path: "pages/subliminal-guide/healthy-hormonal-balancing",
        name: "healthy-hormonal-balancing",
        component: HealthyHormonalBalancing,
      },
      {
        path: "pages/subliminal-guide/puzzle-games-skills-training-and-development",
        name: "puzzle-games-skills-training-and-development",
        component: PuzzleGamesSkillsTrainingandDevelopment,
      },
      {
        path: "pages/subliminal-guide/rubiks-cube-skills-training-and-development",
        name: "rubik's-cube-skills-training-and-development",
        component: RubiksCubeSkillsTrainingandDevelopment,
      },
      {
        path: "pages/subliminal-guide/sense-of-smell-and-taste-healing-and-recovery",
        name: "sense-of-smell-and-taste-healing-and-recovery",
        component: SenseofSmellandTasteHealingandRecovery,
      },
      {
        path: "pages/subliminal-guide/become-online-and-virtual-extrovert",
        name: "become-online-and-virtual-extrovert",
        component: BecomeOnlineandVirtualExtrovert,
      },
      {
        path: "pages/subliminal-guide/exercise-and-workout-results-enhancer",
        name: "exercise-and-workout-results-enhancer",
        component: ExerciseandWorkoutResultsEnhancer,
      },
      {
        path: "pages/subliminal-guide/patriotism-and-end-colonial-mentality",
        name: "patriotism-and-end-colonial-mentality",
        component: PatriotismandEndColonialMentality,
      },
      {
        path: "pages/subliminal-guide/idea-creation-mastery",
        name: "idea-creation-mastery",
        component: IdeaCreationMastery,
      },
      {
        path: "pages/subliminal-guide/stop-snoring",
        name: "stop-snoring",
        component: StopSnoring,
      },
      {
        path: "pages/subliminal-guide/intention-discernment",
        name: "intention-discernment",
        component: IntentionDiscernment,
      },
    ],
  },
  {
    path: "/user",
    redirect: "/user/overview",
    meta: {
      layout: "User",
    },
    component: Layout,
    children: [
      {
        path: "overview",
        name: "overview",
        component: Overview,
      },
      {
        path: "recommend",
        name: "recommend",
        component: Recommend,
      },
      {
        path: "discover",
        name: "discover",
        component: Discover,
      },
      {
        path: "featured",
        name: "featured",
        component: Featured,
      },
      {
        path: "search",
        name: "search",
        component: Search,
      },
      {
        path: "settings",
        name: "settings",
        component: UserProfile,
      },
      {
        path: "own/playlist",
        name: "own/playlist",
        component: OwnPlaylist,
      },
      {
        path: "recent/played",
        name: "recent/played",
        component: RecentPlayed,
      },
      {
        path: "new/subliminal",
        name: "new/subliminal",
        component: NewSubliminalForYou,
      },
      {
        path: "track",
        name: "track",
        // component: Track,
        component: UserTrack,
      },
      {
        path: "mood",
        name: "user/mood",
        component: MoodSelector,
      },
      {
        path: "dashboard",
        name: "user/dashboard",
        component: InfluencerDashboard,
      },
      {
        path: "favorite/playlist",
        name: "favorite/playlist",
        component: FavoritePlaylist,
      },
      {
        path: "favorite/subliminal",
        name: "favorite/subliminal",
        component: FavoriteSubliminal,
      },
    ],
  },
  {
    path: "/admin",
    redirect: "/admin/dashboard",
    meta: {
      layout: "Admin",
    },
    component: Layout,
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard,
      },
      {
        path: "management/audio",
        name: "management/audio",
        component: Audio,
      },
      {
        path: "management/subscription",
        name: "management/subscription",
        component: Subscription,
      },
      {
        path: "management/category",
        name: "management/category",
        component: Category,
      },
      {
        path: "management/audio/type",
        name: "management/audio/type",
        component: AudioType,
      },
      {
        path: "account",
        name: "account",
        component: Account,
      },

      {
        path: "mood",
        name: "mood",
        component: Mood,
      },
      {
        path: "promo/code",
        name: "promo/code",
        component: PromoCode,
      },
      {
        path: "mobile/version",
        name: "mobile/version",
        component: MobileVersion,
      },
      {
        path: "featured/contents",
        name: "featured/contents",
        component: FeaturedContents,
      },
      {
        path: "magus/information",
        name: "magus/information",
        component: MagusDetails,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  history: true,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.hasQueryParamType)) {
    let updateNext = null;

    if (!Object.prototype.hasOwnProperty.call(to.query, "type")) {
      updateNext = {
        ...to,
        query: {
          ...to.query,
          type: "user",
        },
      };
    }

    if (updateNext) {
      next(updateNext);
      return;
    } else {
      next();
    }
  }
  next();
});

export default router;
