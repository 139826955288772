import request from "@/utils/request";

export const getDiscover = (data) => {
  return request.post("/api/discover", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};
export const getFeatured = (data) => {
  return request.post("/api/featured", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};
export const getRecommendation = (data) => {
  return request.post("/api/recommendation", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};
export const getAudioHistory = (data) => {
  return request.post(`/api/audio/history`, data).then((res) => {
    const { data } = res.data;
    return data;
  });
};
export const addAudioHistory = (data) => {
  return request.post(`/api/audio/history/add`, data).then((res) => {
    const { data } = res.data;
    return data;
  });
};
