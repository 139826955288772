<template>
  <div class="container">
    <DataLoading name="new subliminal for you" :data="recommendation" :slider="true">
      <div class="new-subliminal-container">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-6 col-lg-4" v-for="item in recommendation">
            <CardSingle name="new subliminal for you" :item="item" />
          </div>
        </div>
      </div>  
    </DataLoading>
  </div>
</template>

<script>
import CardSingle from '@/components/Card/CardSingle.vue'
import DataLoading from '@/components/DataLoading.vue'

export default {
  props: {
    limit: {
      type: Number,
      default: () => {
        return 0
      }
    },
    to: {
      type: String
    }
  },
  components: {
    CardSingle,
    DataLoading
  },
  computed: {
    recommendation() {
      return this.$store.state.recommendation.data
    }
  },
  methods: {
    // async getData() {
    //   if (this.subscriptionId != 0) {
    //     await this.getRecommendation(this.subscriptionId).then(res => {
    //       const playlist = res.playlist
    //       const subliminal = res.subliminal
    //       let data = playlist.concat(subliminal)

    //       let data_sort = data.sort((a, b) => {
    //         return new Date(b.created_at) - new Date(a.created_at);
    //       })

    //       this.$store.dispatch('getNewSubliminal', data_sort)
    //       this.loading = false
    //     })
    //   }
    // },
    getAllData() {
      this.$router.push(this.to)
    }
  }
}
</script>

<style>
.new-subliminal-container {
  margin: 10px 45px !important; 
}
</style>