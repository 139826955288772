<template>
  <div class="container">
    <CardBanner :item="data" />
    <div class="row">
      <template v-for="(track, index) in tracks">
        <div class="col-12 col-sm-12 col-md-6 col-lg-4" :key="index">
          <CardSingle :item="track" :index="index"></CardSingle>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import CardSingle from "@/components/Card/CardSingle.vue";
import CardBanner from "@/components/Card/CardBanner.vue";

export default {
  components: {
    CardSingle,
    CardBanner,
  },
  computed: {
    data() {
      return this.$store.state.user.track;
    },
    tracks() {
      return this.isTrackPlaylist ? this.data.subliminals : [this.data];
    },
    isTrackPlaylist() {
      return this.data.hasOwnProperty("subliminals");
    },
  },
};
</script>
