<template>
  <section>
    <div class="container">
      <div>
        <h1>Why Magus for Business?</h1>
        <h5 class="mb-2">Unlock Success with Simple, Reliable Solutions</h5>
        <h5>
          As the most innovative health and self-development company. Magus can
          help you address the mental health and wellness needs of your
          business. We offer flexibility and convenience to your company with
          innovative and relevant solutions.
        </h5>
      </div>
      <div class="col-10 col-lg-8 mx-auto">
        <div class="row my-4">
          <template v-for="(item, index) in menus">
            <div
              class="product-card col-6 col-sm-6 col-md-3 col-lg-3 pa-2"
              :key="index"
            >
              <img
                :src="
                  require(`@/assets/images/main/business/2/${item.icon}.svg`)
                "
                alt=""
              />
              <div>
                <h6>{{ item.title }}</h6>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div>
        <button
          class="button btn btn-primary btn-lg"
          @click="goToRoute('/whyMagusforBusiness')"
        >
          {{ showInterfaceButton }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data: () => {
    return {
      show_interface: false,
      menus: [
        {
          icon: "1",
          details:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in.",
          title: "Use anytime and anywhere",
        },
        {
          icon: "2",
          details:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in.",
          title: "Custom Content Development",
        },
        {
          icon: "3",
          details:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in.",
          title: "Curated Content for Work and Teams",
        },
        {
          icon: "4",
          details:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in.",
          title: "Comprehensive Dashboards and Reports",
        },
      ],
    };
  },
  computed: {
    showInterfaceButton() {
      return this.show_interface ? "Back" : "Learn More";
    },
  },
  methods: {
    goToRoute(route) {
      if (this.currentRoute != route) {
        this.$router.push(route).catch((err) => {});
        window.scrollTo(0, 0);
      }
    },
  },
};
</script>

<style scoped>
h1,
p,
h5 {
  line-height: 1.35em;
  color: var(--ma-black-olive);
}
h1 {
  padding-top: 1.5em;
}
section > .container > div:nth-of-type(1) > h5:nth-of-type(1) {
  color: var(--ma-tufts-blue);
}
section > .container > div:nth-of-type(1) > h5:nth-of-type(2) {
  font-family: "Montserrat-Regular";
  padding: 0 1.5em;
}
.product-card > img {
  width: clamp(2.19rem, calc(1.64rem + 8.72vw), 8.75rem);
  margin-block: 1.5em;
  margin-right: auto;
  margin-left: auto;
}
.product-card {
  padding: 0em;
  justify-content: center;
  text-align: center;
}

@media screen and (min-width: 40rem) {
  section {
    text-align: center;
    overflow-y: scroll;
  }

  section:nth-of-type(3) {
    text-align: center;

    & p:nth-of-type(2) {
      font-family: var(--montserrat);
    }
  }

  @media screen and (min-width: 1400px) {
    height: 95vh;
  }

  @media screen and (max-width: 1400px) {
    height: auto;
  }
}
</style>
