<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "This subliminal is designed to guide you on your journey to mastering Jeet Kune Do, the martial art created by the legendary Bruce Lee. By targeting your subconscious mind, it helps you absorb the principles and techniques of this martial art more effectively."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can enhance your training:'
    },
    {
      type: 'list',
      items: [
        "Improving your focus and concentration, allowing you to absorb and understand the techniques better.",
        "Boosting your mental and physical agility, enhancing your ability to react and adapt in combat situations.",
        "Increasing your stamina and endurance, enabling you to train harder and longer.",
        "Enhancing your body awareness and control, helping you execute techniques with precision and efficiency.",
        "Instilling a mindset of discipline and perseverance, essential for continuous growth and improvement in martial arts."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can align your subconscious mind with the teachings of Jeet Kune Do, accelerating your progress and deepening your understanding of this art form. Remember, true mastery takes time and dedication, but with consistent practice and the right mindset, you can achieve it."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on the journey of learning Jeet Kune Do can be both exciting and challenging. This subliminal is designed to enhance your training and help you master the art of self-defense and combat. Before you begin, here are some tips to maximize your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Identify what you wish to achieve in your Jeet Kune Do training, be it improving technique, increasing speed and power, or mastering specific moves.",
        "Warm-up and stretch: Prior to beginning your training, ensure you warm up your body and perform stretching exercises to prevent injuries and improve flexibility.",
        "Create a focused environment: Find a space where you can practice without distractions, ensuring you have enough room to move and perform techniques safely.",
        "Visualize success: Take a moment to visualize yourself executing techniques with precision, speed, and confidence, reinforcing your training goals."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, it can help you develop a deep understanding of Jeet Kune Do principles and techniques, as well as strengthen your mental focus and discipline. Embrace this transformative journey and let the teachings of Bruce Lee guide you towards mastery. Now, let's get ready to unleash the unlimited potential within you!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Jeet Kune Do Training and Mastery session! By diving into the principles and techniques of this martial art, you've taken a significant step towards mastering the art of self-defense and combat. It's time to reflect on your experience and continue your journey towards becoming a skilled practitioner."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice regularly: Consistency is key to improving your Jeet Kune Do skills. Set aside dedicated time for practice.",
        "Review techniques: Refine your technique by revisiting the lessons and practicing them step by step.",
        "Sparring and partner training: Find a training partner or join a class to apply your skills in realistic scenarios.",
        "Study and learn: Deepen your understanding of Jeet Kune Do through books, videos, and additional resources."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, mastery takes time and dedication. Embrace the journey, be patient with yourself, and celebrate your progress along the way. With consistent practice and a thirst for knowledge, you have the potential to become a skilled martial artist. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Is Jeet Kune Do suitable for beginners?`, 
    answer: `Yes, Jeet Kune Do can be practiced by individuals of all skill levels, including beginners. The martial art emphasizes adaptability and personal expression, allowing practitioners to develop their skills at their own pace. With proper guidance and training, beginners can learn and progress in Jeet Kune Do.`,
    open: false
  },
  { 
    question: `What are the core principles of Jeet Kune Do?`, 
    answer: `Jeet Kune Do is centered around several core principles, including simplicity, directness, economy of motion, and interception. These principles guide practitioners to be efficient, adaptable, and effective in combat. By focusing on these principles, practitioners can develop a versatile and practical martial art style.`,
    open: false 
  },
  { 
    question: `How long does it take to master Jeet Kune Do?`, 
    answer: `The time it takes to master Jeet Kune Do varies depending on factors such as dedication, practice frequency, and prior martial arts experience. Mastery is a lifelong journey, and progress is individual to each practitioner. It is important to approach Jeet Kune Do with patience, consistency, and a willingness to continuously learn and refine your skills.`,
    open: false 
  },
  { 
    question: `Do I need to be physically fit to practice Jeet Kune Do?`, 
    answer: `While physical fitness can be beneficial for practicing any martial art, Jeet Kune Do can be adapted to suit various fitness levels. Practitioners can start with foundational techniques and gradually build strength, flexibility, and stamina over time. The key is to train regularly and progressively challenge yourself to improve your physical capabilities.`,
    open: false 
  },
  { 
    question: `Are there any age restrictions for practicing Jeet Kune Do?`, 
    answer: `There are no strict age restrictions for practicing Jeet Kune Do. However, it's important to consider your physical abilities and any pre-existing health conditions. It is recommended to consult with a qualified instructor or healthcare professional to determine if practicing Jeet Kune Do is suitable for you.`,
    open: false 
  },
  { 
    question: `Can Jeet Kune Do be used for self-defense?`, 
    answer: `Yes, Jeet Kune Do is an effective martial art for self-defense. It teaches practitioners to be adaptable and apply techniques that are practical in real-life situations. By training in Jeet Kune Do, you can develop the necessary skills and mindset to protect yourself and others if needed.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>