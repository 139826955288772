<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're dealing with Polycystic Ovary Syndrome (PCOS), this subliminal can be a valuable tool on your healing journey. By targeting your subconscious mind, it aims to support you in managing and alleviating the symptoms of PCOS for a healthier and more balanced life."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help:'
    },
    {
      type: 'list',
      items: [
        "Regulating hormonal imbalances that are often associated with PCOS.",
        "Supporting weight management and healthy metabolism.",
        "Balancing insulin levels and improving insulin sensitivity.",
        "Alleviating symptoms such as irregular periods, acne, and excessive hair growth.",
        "Promoting emotional well-being and reducing stress levels.",
        "Enhancing fertility and supporting reproductive health.",
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can start to reprogram your subconscious mind and create positive changes in your body. It's important to remember that healing is a journey, and results may vary. However, with patience and consistency, you can take steps towards managing your PCOS and living a more balanced and fulfilling life."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "If you are looking to heal and manage the symptoms of Polycystic Ovary Syndrome (PCOS) in a natural and holistic way, this subliminal can be a valuable tool in your journey. Before you begin, here are some tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Educate yourself: Research and gather information about PCOS to better understand the condition and its effects on your body.",
        "Set intentions: Clarify your goals and intentions for using this subliminal, whether it's to alleviate specific symptoms or achieve a more balanced life.",
        "Create a self-care routine: Incorporate healthy habits into your daily life, such as exercise, proper nutrition, stress management, and mindfulness practices.",
        "Create a supportive environment: Surround yourself with positive influences, be it supportive friends, a knowledgeable healthcare provider, or online communities of individuals managing PCOS."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal is designed to help reprogram your subconscious mind, allowing you to adopt healthier habits and manage the symptoms of PCOS more effectively. As you listen regularly, be open to the possibilities of self-healing and positive change. Embrace this opportunity to prioritize your well-being and take control of your journey towards a healthier and more balanced life."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Polycystic ovary syndrome (PCOS) Healing subliminal! By taking this step towards managing and healing your PCOS symptoms, you are prioritizing your health and well-being. Now, it's time to reflect on your experience and continue your journey towards a healthier and more balanced life."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Educate yourself: Learn more about PCOS, its symptoms, and ways to manage them.",
        "Consult a healthcare professional: Seek guidance from your doctor or a specialist to develop an individualized plan.",
        "Adopt a balanced lifestyle: Focus on incorporating a healthy diet, regular exercise, stress management, and sufficient sleep.",
        "Join a support group: Connect with others who are also managing PCOS to share experiences and gain support."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, healing and managing PCOS is a unique journey for every individual. Take the time to learn about your body, consult professionals, and make sustainable lifestyle changes. By prioritizing your health and well-being, you're taking an important step towards living a happier and more balanced life. You've got this!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Can the Polycystic Ovary Syndrome (PCOS) Healing subliminal cure PCOS?`, 
    answer: `The Polycystic Ovary Syndrome (PCOS) Healing subliminal is not a cure for PCOS. It is designed to help manage and alleviate the symptoms of PCOS by promoting hormonal balance, reducing inflammation, and supporting overall health and well-being. It is important to work with a healthcare provider to develop a comprehensive treatment plan that includes lifestyle changes, medical treatments, and any necessary medications.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the PCOS Healing subliminal?`, 
    answer: `Results can vary from person to person, and it may take time to see noticeable improvements in PCOS symptoms. Consistent listening and combining the subliminal with other recommended treatments, such as a healthy diet, exercise, and medical interventions, can enhance the effectiveness and speed up results.`,
    open: false 
  },
  { 
    question: `Can the PCOS Healing subliminal replace medical treatments?`, 
    answer: `No, the PCOS Healing subliminal is not intended to replace medical treatments or professional advice. It is designed to complement other treatments and lifestyle changes recommended by your healthcare provider. It can help support your overall well-being and assist in managing PCOS symptoms, but it is important to work with a healthcare professional to create a comprehensive treatment plan.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the PCOS Healing subliminal?`, 
    answer: `There are no known risks or side effects associated with listening to the PCOS Healing subliminal. However, if you have any concerns about your PCOS or health, please consult with your healthcare provider. The subliminal is designed to supplement other recommended treatments and lifestyle changes, not replace them.`,
    open: false 
  },
  { 
    question: `Can the PCOS Healing subliminal help regulate menstrual cycles?`, 
    answer: `The PCOS Healing subliminal aims to promote hormonal balance and overall health, which may help regulate menstrual cycles for some individuals with PCOS. However, it is important to note that results can vary, and it is recommended to work with a healthcare provider to address the specific needs and challenges of your PCOS.`,
    open: false 
  },
  { 
    question: `How often should I listen to the PCOS Healing subliminal?`, 
    answer: `For best results, it is recommended to listen to the PCOS Healing subliminal daily. Consistent and regular listening can help reinforce positive affirmations and promote long-term changes in mindset and overall well-being.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>