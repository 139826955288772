<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're struggling with feelings of shame, know that you're not alone. This subliminal is designed to help you overcome and heal shame, allowing you to move forward in your life with confidence and self-acceptance."
    },
    {
      type: 'heading',
      text: 'Benefits of using this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Gaining a deeper understanding of the root causes of your shame.",
        "Releasing and letting go of harmful feelings of shame.",
        "Cultivating self-compassion and self-acceptance.",
        "Building resilience and embracing your worthiness.",
        "Moving forward with confidence and letting go of self-judgment."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can start to rewire your subconscious mind and create a healthier relationship with yourself. Over time, you may find that the burden of shame lifts, allowing you to live a more authentic and fulfilling life. Remember, healing takes time, so be patient and gentle with yourself throughout this journey."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Dealing with feelings of shame can be challenging, but this subliminal is designed to help you overcome and heal those emotions, allowing you to embrace self-acceptance and move forward with confidence. Before you start listening, consider these helpful strategies:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your feelings: Recognize and accept the presence of shame in your life. This step is crucial for initiating healing and growth.",
        "Practice self-compassion: Be kind and gentle with yourself, offering understanding and forgiveness for past mistakes.",
        "Challenge negative self-talk: Replace self-critical thoughts with positive affirmations and compassionate language.",
        "Visualize healing: Imagine yourself free from shame and living a life filled with self-acceptance and confidence.",
        "Open your mind and heart: Approach the subliminal with an open mindset, allowing the positive affirmations to penetrate your subconscious and facilitate healing."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you may start to experience a shift in your perception of shame, allowing for deeper healing and personal growth. Embrace this opportunity to release the weight of shame and embrace a life filled with self-acceptance and a renewed sense of confidence."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congrats on completing the Overcome and Heal Shame subliminal! By embarking on this journey of healing and self-acceptance, you have taken an important step towards freeing yourself from the burdens of shame. Now, it's time to reflect on your progress and continue your path to emotional well-being."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice self-compassion: Be kind and understanding towards yourself, especially when shame arises.",
        "Challenge negative self-talk: Replace self-judgment with positive and empowering affirmations.",
        "Seek support: Reach out to a trusted friend, family member, or therapist to discuss your feelings and experiences.",
        "Engage in self-care activities: Prioritize activities that bring you joy, relaxation, and inner peace.",
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, healing from shame takes time and patience. Be gentle with yourself throughout the process and celebrate small victories along the way. You have the strength and resilience to heal and move forward with confidence and self-acceptance. Keep shining!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What is shame and why is it important to overcome and heal it?`, 
    answer: `Shame is a deep-seated feeling of guilt, unworthiness, and embarrassment about oneself. It can be detrimental to one's mental and emotional well-being, leading to low self-esteem, self-isolation, and fear of judgment and rejection. Overcoming and healing shame is important as it allows individuals to release negative self-perceptions, develop self-acceptance, and move forward with confidence and a healthier sense of self.`,
    open: false
  },
  { 
    question: `How can the Overcome and Heal Shame subliminal help me?`, 
    answer: `The Overcome and Heal Shame subliminal audio is designed to reprogram your subconscious mind with positive affirmations and messages that support self-acceptance, self-love, and letting go of shame. By listening to the subliminal regularly, it can help you release deep-rooted shame, heal emotional wounds, and cultivate a healthier and more compassionate relationship with yourself.`,
    open: false 
  },
  { 
    question: `How long does it take to overcome and heal shame with the subliminal?`, 
    answer: `The time it takes to overcome and heal shame can vary from person to person. It may depend on factors such as the depth of the shame, individual experiences, and the consistency of listening to the subliminal. We recommend listening to the subliminal daily, consistently, and over an extended period to see significant results.`,
    open: false 
  },
  { 
    question: `Can the subliminal help with shame related to specific experiences or traumas?`, 
    answer: `Yes, the Overcome and Heal Shame subliminal audio can be beneficial in addressing shame related to specific experiences or traumas. It aims to promote healing, self-acceptance, and personal growth. However, it is important to note that the subliminal is not a substitute for professional therapeutic support. If you are dealing with trauma or significant emotional distress, seeking the guidance of a qualified therapist is recommended.`,
    open: false 
  },
  { 
    question: `Will the subliminal eliminate all feelings of shame?`, 
    answer: `The Overcome and Heal Shame subliminal aims to assist individuals in releasing and healing shame. While it can be highly effective, it is important to have realistic expectations. The subliminal can empower you with the tools and mindset to work through feelings of shame, but individual experiences and personal growth journeys may vary.`,
    open: false 
  },
  { 
    question: `Are there any risks associated with listening to the subliminal for healing shame?`, 
    answer: `There are no known risks associated with listening to the Overcome and Heal Shame subliminal. However, it is important to be gentle with yourself and practice self-care during the healing process. If you have underlying mental health conditions or concerns, it is advisable to seek professional guidance alongside using the subliminal audio.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>