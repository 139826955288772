<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Self-acceptance is an important aspect of overall well-being and happiness. This subliminal is designed to help you let go of self-judgment and cultivate self-love and self-compassion."
    },
    {
      type: 'heading',
      text: 'Benefits of using this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Embracing your true self and accepting all aspects of who you are.",
        "Letting go of self-judgment and being more compassionate towards yourself.",
        "Increasing self-confidence and self-esteem.",
        "Developing a positive and nurturing relationship with yourself.",
        "Feeling more at peace with your strengths and weaknesses."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, it can help rewire your thoughts and beliefs, allowing you to embrace yourself fully and experience greater self-acceptance. Remember, self-acceptance is a lifelong journey, and with consistency and practice, you can cultivate a deep sense of self-love and self-compassion."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a journey of self-acceptance is a powerful step towards cultivating a positive self-image and inner peace. This subliminal is here to support and guide you along the way. Before you start listening, consider the following suggestions to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Embrace vulnerability: Allow yourself to be open and vulnerable to the process of self-acceptance.",
        "Practice self-compassion: Be kind and gentle with yourself, replacing self-judgment with self-love.",
        "Release negative self-talk: Pay attention to any negative self-talk and consciously replace it with positive affirmations.",
        "Visualize your ideal self: Imagine yourself fully accepting and loving who you are, embracing all your strengths and weaknesses."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to help you transform your relationship with yourself and cultivate a deep sense of self-acceptance. As you listen regularly, you may start to experience a shift in your self-perception, embracing your uniqueness and inner beauty. So take a moment to set your intention, breathe deeply, and let the journey of self-acceptance unfold."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Self-Acceptance subliminal! You have taken an important step towards embracing and accepting yourself fully. Now it's time to reflect on your experience and continue nurturing self-love and self-compassion."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice self-reflection: Take time to journal and reflect on your thoughts and emotions.",
        "Challenge self-judgment: Notice when you are being self-critical and replace it with positive affirmations.",
        "Surround yourself with positivity: Seek out supportive individuals who uplift and encourage you.",
        "Engage in self-care: Prioritize activities that nourish your mind, body, and soul."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, self-acceptance is an ongoing journey. Be patient with yourself as you cultivate self-love and self-compassion. Celebrate your unique qualities and embrace all aspects of who you are. You are worthy, and you deserve to love yourself unconditionally. Keep shining!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Self-Acceptance subliminal help me embrace and accept myself?`, 
    answer: `The Self-Acceptance subliminal is designed to influence your subconscious mind with positive affirmations and beliefs that promote self-love, self-compassion, and self-acceptance. By consistently listening to the subliminal, you can reprogram your mindset and let go of self-judgment, embracing and accepting yourself fully as you are.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Self-Acceptance subliminal?`, 
    answer: `Results may vary from person to person, and the time it takes to see results can depend on various factors, including individual circumstances and the level of self-acceptance you are starting from. Consistent listening to the subliminal over a prolonged period can help reinforce positive beliefs and facilitate self-acceptance. It is important to be patient and kind to yourself throughout the process.`,
    open: false 
  },
  { 
    question: `Can the Self-Acceptance subliminal help with overcoming self-doubt and insecurities?`, 
    answer: `Yes, the Self-Acceptance subliminal can be beneficial for overcoming self-doubt and insecurities. It aims to shift your mindset towards self-love, self-compassion, and acceptance, which can help you let go of self-judgment and embrace your true self. It works on a subconscious level and can assist in reprogramming negative thought patterns that contribute to self-doubt and insecurities.`,
    open: false 
  },
  { 
    question: `Can the Self-Acceptance subliminal change my personality?`, 
    answer: `The Self-Acceptance subliminal is not intended to change your core personality. Its purpose is to help you cultivate a more positive mindset, embrace your true self, and let go of self-judgment. It aims to enhance your self-love, self-compassion, and self-acceptance, allowing you to fully embrace and express your authentic personality.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Self-Acceptance subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Self-Acceptance subliminal. However, if you have any concerns about your mental health or well-being, it is recommended to consult with a qualified healthcare professional.`,
    open: false 
  },
  { 
    question: `Do I need to listen to the Self-Acceptance subliminal forever to maintain self-acceptance?`, 
    answer: `While consistent listening to the Self-Acceptance subliminal can help reinforce positive beliefs and cultivate self-acceptance, the ultimate goal is for the changes to become ingrained in your mindset and for self-acceptance to become a natural part of your life. You may find that periodic listening or revisiting the subliminal can be helpful to maintain and further enhance your self-acceptance journey.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>