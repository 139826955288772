<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Being a single dad can present unique challenges, but with the right mindset, you can navigate them with confidence and self-assurance. This subliminal is designed to help boost your confidence in your parenting abilities and address any doubts or anxieties you may have."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Increasing your self-confidence as a single dad and in your parenting skills.",
        "Helping you overcome any doubts or anxieties you may have about your ability to parent.",
        "Providing you with a sense of calm and clarity in your parenting decisions.",
        "Boosting your resilience and ability to handle the challenges that come with being a single dad.",
        "Supporting you in cultivating a healthy work-life balance and prioritizing self-care."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can train your mind to embrace a confident and positive mindset as a single dad. You may find yourself feeling more empowered, capable, and equipped to handle the responsibilities of parenting. Remember, building confidence takes time and practice, but you have what it takes to thrive as a single dad."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Being a single dad comes with its own unique set of challenges, but with the right mindset, you can navigate this journey with confidence and self-assurance. This subliminal is designed to support you in boosting your confidence as a single dad. Before listening, here are some tips to help you get the most out of this subliminal:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your strengths: Recognize the positive aspects of your parenting journey and the strengths you bring to the table as a single dad.",
        "Set an intention: Decide what areas of confidence you would like to focus on, whether it's being more patient, assertive, or handling challenges with ease.",
        "Visualize success: Imagine yourself embodying confidence in various parenting situations, feeling calm and self-assured.",
        "Keep an open mind: Be receptive to the subliminal messages and allow them to sink into your subconscious mind, creating positive shifts in your confidence."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can cultivate a strong sense of confidence that will empower you in your role as a single dad. Embrace this opportunity for growth and remember that you are capable, resilient, and deserving of confidence. Press play and let your journey towards increased confidence and ease in parenting begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Confidence for Single Dad subliminal session! By focusing on boosting your confidence as a single dad, you're taking important steps towards navigating the challenges of parenting with ease and self-assurance. Reflecting on your experience and commitment to personal growth is crucial as you continue on this journey."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Embrace self-care: Prioritize self-care activities that recharge and rejuvenate you.",
        "Connect with other single dads: Seek support from other single dads who understand the unique challenges you face.",
        "Focus on your strengths: Identify and celebrate the positive qualities and skills you bring to parenting.",
        "Set realistic goals: Break down your parenting goals into manageable steps to build confidence and track progress."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, being a confident single dad is a journey, and it's okay to ask for help along the way. Embrace self-care, connect with a support network, and believe in your abilities. You have what it takes to be an amazing parent and role model to your child. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Confidence for Single Dad subliminal help me?`, 
    answer: `The Confidence for Single Dad subliminal is designed to help boost your self-confidence and provide you with the mental tools to navigate the challenges of single parenting. By listening to the subliminal regularly, you can reprogram your subconscious mind to believe in your abilities as a father, feel more self-assured in your decision-making, and approach parenting with ease.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Confidence for Single Dad subliminal?`, 
    answer: `Results may vary from person to person, but with consistent listening, you may start experiencing positive changes in your confidence levels within a few weeks. Over time, as the subliminal messages influence your subconscious mind, you may notice a greater sense of self-assurance and ease in your role as a single dad.`,
    open: false 
  },
  { 
    question: `Can the Confidence for Single Dad subliminal help with specific parenting challenges?`, 
    answer: `Yes, the Confidence for Single Dad subliminal is designed to help you navigate various parenting challenges with more confidence and ease. By reprogramming your subconscious mind, it can provide you with the mental resilience and self-assurance to handle different situations, such as disciplining, bonding with your child, or balancing work and parenting responsibilities.`,
    open: false 
  },
  { 
    question: `Is it necessary to listen to the Confidence for Single Dad subliminal every day?`, 
    answer: `Consistency is key when it comes to utilizing subliminal programming. Listening to the Confidence for Single Dad subliminal daily can help reinforce the positive affirmations and continue to shape your mindset in a confident and self-assured direction. Set aside dedicated time each day to listen to the subliminal, and you may see more significant and lasting results.`,
    open: false 
  },
  { 
    question: `Can the Confidence for Single Dad subliminal replace therapy or counseling?`, 
    answer: `The Confidence for Single Dad subliminal is not a substitute for therapy or counseling. While it can help boost your confidence and provide you with positive mindset reinforcement, it does not replace the guidance and support that a trained professional can offer. If you are experiencing difficulties or need additional support, consider seeking professional help alongside listening to the subliminal.`,
    open: false 
  },
  { 
    question: `Can the Confidence for Single Dad subliminal benefit single moms as well?`, 
    answer: `Yes, the Confidence for Single Dad subliminal can be beneficial for single moms as well. While it is specifically tailored towards single dads, many of the underlying confidence-building and self-assurance principles can apply to single moms too. The subliminal can help any single parent cultivate a stronger sense of confidence and navigate the challenges of parenting with ease.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>