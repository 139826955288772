<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Hair loss can be a distressing experience, but there are effective techniques and treatments available to help prevent and even reverse it. This subliminal is specifically designed to address the underlying causes of hair loss and promote a healthy scalp and hair growth."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help you:"
    },
    {
      type: 'list',
      items: [
        "Stimulating hair follicles and promoting new hair growth.",
        "Improving blood circulation to the scalp to strengthen hair follicles.",
        "Nourishing the scalp with essential nutrients for healthy hair growth.",
        "Reducing inflammation and preventing damage to hair follicles.",
        "Promoting a healthy scalp environment to prevent hair loss.",
        "Boosting confidence and self-esteem by regaining a fuller head of hair."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can improve the health of your scalp, stimulate hair growth, and prevent further hair loss. It's important to note that results may vary depending on the individual and any underlying conditions. Consistency is key, so incorporate this subliminal into your daily routine for the best chances of regrowing and maintaining a healthy head of hair."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Experiencing hair loss can be a frustrating and distressing experience. This subliminal is designed to provide you with the tools and techniques to prevent hair loss and promote healthy hair growth. Before you begin listening, consider these tips to enhance your journey:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Educate yourself: Learn about the causes of hair loss and explore effective treatments and techniques that can help in preventing further hair loss and promoting hair growth.",
        "Take care of your scalp: Maintain good scalp hygiene and use gentle products that nourish and protect your scalp.",
        "Embrace a healthy lifestyle: Eat a balanced diet, exercise regularly, and manage stress levels to support overall hair health.",
        "Visualize healthy hair: Envision yourself with a head full of healthy, voluminous hair, and hold this image in your mind while listening to the subliminal affirmations."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal is designed to reprogram your subconscious mind and support you on your journey to prevent hair loss and achieve a healthy scalp. With consistent listening and incorporating healthy habits into your lifestyle, you may witness positive changes in your hair growth and overall hair health. Embrace this opportunity to take control of your hair and regain your confidence."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Stop Hair Loss subliminal session! By investing your time and energy into preventing hair loss and promoting a healthy scalp, you've taken an important step towards regaining and maintaining your hair's natural vitality. Now, let's reflect on your journey and explore further steps you can take to support your hair health."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Educate yourself: Continue researching and learning about effective techniques and treatments for hair loss prevention and regrowth.",
        "Evaluate your hair care routine: Assess your current hair care practices and make necessary adjustments to promote a healthier scalp and hair growth.",
        "Consider dietary changes: Explore foods and supplements that may nourish your hair, such as those rich in vitamins, minerals, and antioxidants.",
        "Nurture your scalp: Incorporate regular scalp massages, proper hydration, and protective hairstyles to support a healthy environment for hair growth."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, addressing hair loss is a unique and individual journey. Stay committed, patient, and open to exploring various strategies to support your hair health. With consistent effort and the right techniques, you can take significant strides towards regrowing and maintaining your hair. Embrace this process, and may you enjoy healthy, beautiful hair!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Can the Stop Hair Loss subliminal regrow hair?`, 
    answer: `While the Stop Hair Loss subliminal can help in promoting a healthy scalp and preventing further hair loss, regrowing hair depends on various factors including genetics, overall health, and individual circumstances. The subliminal is designed to support and nourish the hair and scalp, but results may vary from person to person.`,
    open: false
  },
  { 
    question: `How often should I listen to the Stop Hair Loss subliminal?`, 
    answer: `For optimal results, it is recommended to listen to the Stop Hair Loss subliminal daily. Consistency is key in reprogramming the subconscious mind and allowing the positive affirmations to take effect. Additionally, integrating a healthy hair care routine and lifestyle habits can further support hair growth and health.`,
    open: false 
  },
  { 
    question: `Are there any side effects or risks associated with the Stop Hair Loss subliminal?`, 
    answer: `There are no known side effects associated with listening to the Stop Hair Loss subliminal. However, if you have any concerns about hair loss or scalp health, it is recommended to consult with a dermatologist or healthcare professional who can provide personalized advice and guidance.`,
    open: false 
  },
  { 
    question: `Can the Stop Hair Loss subliminal replace professional hair loss treatments?`, 
    answer: `The Stop Hair Loss subliminal is not intended to replace professional hair loss treatments or medical advice. It is designed to complement and support existing treatments and remedies. If you are undergoing any specific hair loss treatment, it is best to consult with your healthcare professional before making any changes to your routine.`,
    open: false 
  },
  { 
    question: `How long does it take to see results from the Stop Hair Loss subliminal?`, 
    answer: `Results may vary from person to person and depend on factors such as individual circumstances and consistency in listening. It is recommended to listen to the subliminal consistently over a period of time, along with adopting a healthy hair care routine and lifestyle habits, to potentially see improvements in hair health and prevention of further hair loss.`,
    open: false 
  },
  { 
    question: `Can the Stop Hair Loss subliminal work for everyone?`, 
    answer: `The effectiveness of the Stop Hair Loss subliminal can vary from person to person. Factors such as genetics, overall health, and individual circumstances can influence results. The subliminal is designed to support and promote a healthy scalp and prevent further hair loss. While it may work for many individuals, individual results may vary.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>