<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you have a passion for karate and want to take your skills to the next level, this subliminal can help you on your journey to mastery. By targeting your subconscious mind, it can enhance your training, discipline, and overall performance."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support your karate journey:'
    },
    {
      type: 'list',
      items: [
        'Improving focus and concentration during training sessions and competitions.',
        'Enhancing physical strength, speed, and agility for better technique execution.',
        'Building mental toughness and resilience to overcome challenges and setbacks.',
        'Promoting discipline and consistency in your training routine.',
        'Boosting confidence and belief in your abilities as a martial artist.',
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can unlock your full potential as a karate practitioner. It can help you overcome limits, push through plateaus, and achieve greater mastery in this martial art form. Remember, mastery takes time and dedication, but with this subliminal as a powerful tool, you're on the right path to becoming a skilled karateka."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on the path of karate training requires dedication, discipline, and a strong mindset. This subliminal is designed to support you in your journey towards mastering karate. Before you begin, here are some tips to help you prepare:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Clarify your goals in mastering karate, whether it's improving technique, building physical strength, or achieving mental focus.",
        "Create a training schedule: Establish a consistent training routine that allows you to dedicate regular time and effort to your karate practice.",
        "Prepare your body: Engage in warm-up exercises and stretches to ensure your body is ready for the physical demands of karate training.",
        "Cultivate a focused mindset: Before listening to the subliminal, clear your mind and create a mental space for learning, growth, and dedication."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, it can help reinforce your motivation, discipline, and physical abilities in karate. Embrace the journey, stay committed to your training, and remember that mastery takes time and practice. Take a deep breath, let go of any distractions, and let your karate training begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Karate Training and Mastery session! By investing your time and effort into training and practicing karate, you have taken a significant step towards achieving mastery in this martial art form. Reflecting on your experience and committing to continuous improvement will further enhance your physical strength, discipline, and overall growth."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Set personal goals: Identify specific areas of karate you want to improve upon and set goals to work towards.",
        "Prioritize consistency: Regularly practice karate to refine your techniques and build muscle memory.",
        "Engage in cross-training: Supplement your karate practice with exercises that enhance strength, flexibility, and endurance.",
        "Seek mentorship: Find an experienced karate practitioner or instructor who can guide you on your journey."
      ]
    },
    {
      type: 'paragraph',
      text: "Becoming a master of karate requires dedication, patience, and a growth mindset. Embrace the challenges, push your limits, and stay committed to constant improvement. Remember, every step you take towards honing your skills and deepening your understanding of karate brings you closer to realizing your full potential. Keep up the fantastic work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How long does it take to master karate?`, 
    answer: `The time it takes to master karate can vary depending on various factors, including individual dedication, frequency of practice, and natural aptitude. Mastery in any martial art form is a lifelong journey that requires consistent training, discipline, and continuous learning. It is important to focus on the process and not solely on the end goal of mastery, as every training session provides an opportunity for growth and improvement.`,
    open: false
  },
  { 
    question: `What are some tips for improving karate techniques?`, 
    answer: `Improving karate techniques involves consistent practice, focus, and attention to detail. Here are a few tips that may help:

1. Practice regularly: Dedicate regular time for training to improve muscle memory and technique.
2. Focus on the basics: Mastering the fundamental techniques will set a strong foundation for more advanced moves.
3. Seek feedback: Work with a qualified instructor who can provide guidance and correct any errors in technique.
4. Visualize success: Mental rehearsal and visualization can enhance performance and improve technique.
5. Engage in physical conditioning: Strengthening the body through conditioning exercises can improve overall performance.

Remember, progress takes time, so be patient and persistent in your training.`,
    open: false 
  },
  { 
    question: `Is it necessary to be physically fit before starting karate training?`, 
    answer: `Karate training can be adapted to various fitness levels, and you do not need to be physically fit before starting. Regular training will improve your fitness over time as you build strength, flexibility, and endurance. However, it is always a good idea to consult with a healthcare professional before starting any new physical activity, especially if you have any pre-existing health conditions.`,
    open: false 
  },
  { 
    question: `Is karate training suitable for children?`, 
    answer: `Yes, karate training can be suitable for children. Karate provides a structured environment for children to develop discipline, focus, self-control, and physical fitness. It is essential to find a reputable and experienced instructor who specializes in teaching karate to children. The instructor should prioritize safety, age-appropriate training, and a positive learning environment.`,
    open: false 
  },
  { 
    question: `Can I compete in karate tournaments after training?`, 
    answer: `Yes, many karate practitioners choose to compete in tournaments as a way to test their skills, gain experience, and challenge themselves. Competing in tournaments allows practitioners to showcase their techniques and compare their abilities with fellow karateka. However, tournament participation is not mandatory, and many individuals practice karate solely for self-improvement, self-defense, or personal development.`,
    open: false 
  },
  { 
    question: `Can karate training help with self-defense?`, 
    answer: `Yes, karate training can help develop self-defense skills. Karate techniques focus on strikes, kicks, blocks, and other defensive moves that can be applied in a real-life self-defense situation. However, it is important to remember that self-defense is not solely about physical techniques. Karate training also emphasizes situational awareness, conflict de-escalation, and self-confidence to make effective decisions and avoid or escape dangerous situations.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>