<template>
  <Dialog :dialog="dialog" :width="1000" :title="`Add Subliminal To Playlist`" @closeDialog="closeDialog">
    <Table itemkey="subliminal_id" 
      :add="false" 
      :delete="false" 
      :addItem="true" 
      :loading="loading" 
      :items="items"
      :headers="headers" 
      :select="selected" 
      :addVisible="true"
      :total="total" 
      @action="getAction"
      @updateLimitPage="updateLimitPage"
      @updateSearch="updateSearch"
      @updateVisible="updateVisible"
      @updateSort="updateSort" 
    />
  </Dialog>
</template>


<script>
import { getSubliminalFilters } from '@/api/subliminal'
import { updateSubliminalToPlaylists, deleteSubliminalToPlaylists } from '@/api/playlistinfo'

import Dialog from '@/components/Dialog.vue'
import Table from '@/components/Admin/Table.vue'

export default {
  props: {
    data: {},
    dialog: {
      type: Boolean
    }
  },
  components: {
    Table,
    Dialog
  },
  data: () => {
    return {
      deleteSubliminalToPlaylists,
      updateSubliminalToPlaylists,
      getSubliminalFilters,

      loading: true,

      items: [],
      selected: [],
      old_selected: [],

      headers: [
        { text: 'Title', value: 'title', sortable: false },
        { text: 'Category', value: 'category_id', sortable: false },
        { text: 'Subscription', value: 'subscription_id', sortable: false },
      ],
      
      sort: null,
      search: null,
      visible: null,
      limit: 10,
      page: 1,
      total: 0,
      
    }
  },
  created() {
    this.getData()
  },
  watch: {
    sort() {
      this.getData();
    },
    visible() {
      this.getData();
    },
    search() {
      this.getData();
    },
  },
  computed: {
    audiotypes() {
      return this.$store.state.audio.types;
    },
    playlistid() {
      return this.$store.state.audio.playlist.id;
    },
  },
  methods: {
    getData() {
      const data = {
        limit: this.limit,
        page: this.page,
        visible: this.visible,
        search: this.search,
        sort: this.sort,
      }

      this.getSubliminalFilters(data).then((res) => {
        const { subliminals, total } = res

        this.total = total
        this.items = subliminals

        this.selected = this.data.subliminals
        this.old_selected = this.data.subliminals

        for (let index = 0; index < this.items.length; index++) {
          const element = this.items[index];
          const item = this.data.subliminals.filter(item => {
            return element.subliminal_id == item.subliminal_id
          })
          if (item.length > 0) {
            element.category = item[0].category_id
          }
        }
        this.loading = false
      })
    },
    getAction(params) {
      this.selected = params.selected

      if (this.old_selected.length > this.selected.length) {
        let data = this.old_selected.filter(object1 => {
          return !this.selected.some(object2 => {
            return object1.subliminal_id === object2.subliminal_id;
          });
        });


        for (let index = 0; index < data.length; index++) {
          let element = data[index];

          element.playlist_id = this.playlistid
          element.subliminal_id = element.subliminal_id
          element.category_id = element.category_id
          element.subscription_id = Array.isArray(element.subscription_id) ? element.subscription_id.join(",") : element.subscription_id

          this.deleteSubliminalToPlaylists(element)
        }
      }

      for (let index = 0; index < this.selected.length; index++) {
        let element = this.selected[index];

        element.playlist_id = this.playlistid
        element.subliminal_id = element.subliminal_id
        element.category_id = element.category_id
        element.subscription_id = Array.isArray(element.subscription_id) ? element.subscription_id.join(",") : element.subscription_id

        this.updateSubliminalToPlaylists(element).then(res => {
          this.closeDialog()
        })
      }
    },
    closeDialog() {
      this.$emit('closeDialog', false)
    },
    updateSearch(data) {
      this.search = data
      this.page = 1
      this.getData()
    },
    updateVisible(data) {
      this.visible = data;
      this.page = 1
      this.getData();
    },
    updateSort(data) {
      this.sort = data
      this.getData()
    },
    updateLimitPage(data) {
      //this.loading = true
      const { page, limit } = data
      this.page = page
      this.limit = limit

      this.getData()
    }
  }
}
</script>