<template>
  <Dialog :dialog="dialog" :width="500" @closeDialog="closeDialog">
    <div v-if="isReady == true">
      <div
        v-if="!isItemsEmpty || searching == true"
        class="mx-auto text-center"
      >
        <input class="search" v-model="search" placeholder="Search" />
        <div
          class="pl-5 pr-5"
          style="max-height: 350px; min-heigh: 100px; overflow: auto"
        >
          <template v-for="(item, index) in items">
            <div class="wrapper d-flex align-center" :key="index">
              <img class="cover" :src="item.cover" />
              <div class="titles">
                <h6>{{ item.title }}</h6>
              </div>
              <img
                v-if="item.is_added != 1"
                @click="addSubliminal(item.playlist_id)"
                class="icon"
                src="@/assets/images/user/playlist/add.png"
              />
              <v-icon @click="addSubliminal(item.playlist_id)" v-else
                >mdi-check</v-icon
              >
            </div>
          </template>
        </div>
        <div v-if="isItemsEmpty" class="mx-auto text-center">
          <h6 class="mt-5">No result found!</h6>
          <span
            >We couldn’t find what you searched for.<br />Try using a different
            key word and try again<br
          /></span>
          <img
            class="empty mt-2"
            src="@/assets/images/user/playlist/empty.png"
            alt=""
          />
        </div>
      </div>
      <div v-else class="mx-auto text-center">
        <img
          class="empty mb-2"
          src="@/assets/images/user/playlist/empty_playlist.png"
          alt=""
        />
        <p>Let’s start building your playlist</p>
      </div>
    </div>
    <div class="text-center" v-else>
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
    <template v-slot:action>
      <div class="text-center" style="margin: auto; margin-top: -15px">
        <button
          @click="opendAddtoNewPlaylist()"
          class="button btn btn-primary btn-lg"
        >
          Create new playlist
        </button>
      </div>
    </template>
    <AddOwnPlaylistTitleDialog
      :data="data"
      :dialog="thisdialog"
      @closeDialog="thiscloseDialog"
    />
  </Dialog>
</template>

<script>
import {
  searchOwnPlaylist,
  getOwnPlaylist,
  addOwnPlaylist,
} from "@/api/ownplaylist";
import { addSubliminalToOwnPlaylists } from "@/api/playlistinfo";
import { getMessage } from "@/utils";

import Dialog from "@/components/Dialog.vue";
import DataLoading from "@/components/DataLoading.vue";
import AddOwnPlaylistTitleDialog from "@/components/User/AddOwnPlaylistTitleDialog.vue";
import CardSingle from "@/components/Card/CardSingle.vue";

export default {
  props: {
    dialog: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data: () => {
    return {
      addOwnPlaylist,
      getOwnPlaylist,
      getMessage,
      searchOwnPlaylist,
      addSubliminalToOwnPlaylists,

      ownPlaylists: [],
      items: [],

      isReady: false,
      thisdialog: false,
      searching: false,
      search: null,
      radio: {},
    };
  },
  components: {
    Dialog,
    AddOwnPlaylistTitleDialog,
    CardSingle,
    DataLoading,
  },
  computed: {
    user() {
      return JSON.parse(this.$store.state.user.info);
    },
    subscriptionId() {
      return this.$store.state.subscription.id;
    },
    enabledCreateButton() {
      return (
        (this.search != "" && this.search != null) ||
        Object.keys(this.radio).length > 0
      );
    },
    isItemsEmpty() {
      if (this.items != undefined) {
        return this.items.length == 0;
      }
      return false;
    },
  },
  mounted() {
    let self = this;
    setTimeout(() => {
      self.isReady = true;
    }, 2000);
  },
  watch: {
    dialog(val) {
      if (val) {
        this.getData();
      } else {
        this.radio = {};
        this.search = null;
      }
    },
    search(val) {
      this.searching = true;
      this.getData();
    },
  },
  // mounted() {
  //   var self = this;
  //   setTimeout(() => {
  //     self.getData();
  //   }, 0);
  // },
  methods: {
    addSubliminal(id) {
      const data = {
        user_id: this.user.user_id,
        subscription_id: this.user.info.subscription_id,
        playlist_id: id,
        subliminal_id: this.data.subliminal_id,
      };
      this.addSubliminalToOwnPlaylists(data).then((res) => {
        this.getData();
        this.$store.dispatch("playlist/getOwnUpdate", res.data);
        this.$store.dispatch("playlist/getOwnLoading", false);
        this.getMessage(this, res);
      });
    },
    opendAddtoNewPlaylist() {
      this.dialog = false;
      this.thisdialog = true;
    },
    getData() {
      console.log("addownplaylistdialog");
      const data = {
        user_id: this.user.user_id,
        subscription_id: this.user.info.subscription_id,
        search: this.search,
        subliminal_id: this.data.subliminal_id,
      };
      this.searchOwnPlaylist(data).then((res) => {
        this.items = res.data;
      });
    },
    closeDialog() {
      this.isReady = false;
      this.$emit("closeDialog", false);
    },
    thiscloseDialog() {
      this.isReady = false;
      this.thisdialog = false;
      this.$emit("closeDialog", false);
    },
    getOwnPlaylists() {
      const user_id = this.user.user_id;

      const data = {
        subscription_id: this.subscriptionId,
        user_id: user_id,
      };

      this.getOwnPlaylist(data).then((res) => {
        this.ownPlaylists = res;
        if (this.search != "" && this.search != null) {
          this.ownPlaylists = this.ownPlaylists.filter((ownPlaylist) => {
            return (
              ownPlaylist.title
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) > -1
            );
          });
        }

        const result = res.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });

        this.$store.dispatch("playlist/getOwnData", result);
      });
    },
    addToPlaylist() {
      let isNewPlaylist = false;

      let info = {};
      let data = { ...this.data };
      data.user_id = this.user.user_id;

      // getting subliminal
      if (data.hasOwnProperty("subliminal_id")) {
        info = [data];
      }

      if (data.hasOwnProperty("playlist_id")) {
        info = data.subliminals;
      }

      // getting title
      if (this.search != "" && this.search != null) {
        isNewPlaylist = true;
        data.title = this.search;
      }

      if (Object.keys(this.radio).length > 0) {
        isNewPlaylist = false;
        data.title = this.radio.title;
        data.playlist_id = this.radio.playlist_id;
      }

      // createing playlist
      if (isNewPlaylist) {
        this.addOwnPlaylist(data).then((res) => {
          for (let index = 0; index < info.length; index++) {
            let element = info[index];
            element.playlist_id = res[0].playlist_id;

            this.addSubliminalToOwnPlaylists(element);
          }
        });
      } else {
        for (let index = 0; index < info.length; index++) {
          let element = info[index];

          this.addSubliminalToOwnPlaylists(element);
        }
      }

      this.getOwnPlaylists();
      this.$store.dispatch("playlist/getOwnUpdate", true);
      this.closeDialog();
    },
    getBackground(params) {
      if (params.hasOwnProperty("subliminals")) {
        const count = params.subliminals.length;
        if (count > 0) {
          let index = Math.floor(Math.random() * count);
          return params.subliminals[index].cover;
        }
      }

      return params.cover;
    },
  },
};
</script>

<style>
.empty {
  height: 80px;
}
p {
  font-family: "Montserrat-Regular";
  font-size: 12px;
  margin-top: 10px;
}
.search {
  padding-left: 10px;
  width: 100%;
  margin: auto;
  margin-top: -10px;
  margin-bottom: 10px;
  border-radius: 10px;
  border: solid 0.5px black;
  height: 40px;
  background: #f4eeee;
  font-family: "Montserrat-Regular";
}
span {
  font-family: "Montserrat-Regular";
}
.wrapper {
  margin-top: 10px;
}
.icon {
  height: 20px;
}
.check {
  height: 17px;
}
.cover {
  height: 70px;
  width: 80px;
  border-radius: 5px;
  margin-right: 10px;
}
.titles {
  width: 80%;
  text-align: left;
}
</style>
