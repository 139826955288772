<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Holding onto resentment can weigh us down and prevent us from living fully. This subliminal is designed to help you let go of grudges, release the burden of resentment, and find inner peace through forgiveness."
    },
    {
      type: 'heading',
      text: 'Here are some ways in which this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Helping you let go of negative emotions and find closure with past hurts.",
        "Promoting compassion and empathy, enabling you to see situations from a different perspective.",
        "Fostering a sense of forgiveness towards others and yourself, allowing for personal growth and healing.",
        "Alleviating stress and anxiety associated with holding onto grudges and resentment.",
        "Empowering you to move forward with a lighter heart and a renewed sense of peace and freedom."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can rewire your subconscious mind and cultivate a forgiving mindset. It may take time, but as you embrace forgiveness, you'll find yourself letting go of the past and experiencing greater emotional well-being. Remember, forgiveness is a gift you give yourself."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Choosing to forgive others can be a powerful step towards personal growth and healing. This subliminal is designed to support you in releasing resentment and finding inner peace. Before you start listening, consider these suggestions:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your feelings: Recognize the emotions associated with the past hurts and allow yourself to feel them fully.",
        "Let go of the need for justice: Understand that forgiving does not mean forgetting or condoning the behavior but rather freeing yourself from the burden of anger and resentment.",
        "Practice self-compassion: Remember, forgiveness is a process, and it is okay to have mixed feelings or moments of struggle along the way.",
        "Visualize the desired outcome: Imagine yourself letting go of the negative emotions, and envision a future where you have moved on and found peace."
      ]
    },
    {
      type: 'paragraph',
      text: "As you embark on this journey towards forgiveness, know that it is a personal and empowering choice. By listening to this subliminal regularly, you can gradually release the weight of resentment and create space for healing and growth. Take a deep breath, open your heart, and let the power of forgiveness transform your life."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Forgiving Others and Moving On subliminal! By embarking on this journey of forgiveness, you have taken a significant step towards freeing yourself from the burden of resentment and finding inner peace. It's time to reflect on your experience and continue your personal growth and transformation."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your feelings: Take the time to journal or meditate on your emotions and thoughts surrounding forgiveness.",
        "Practice empathy and understanding: Try to place yourself in the other person's shoes to gain a different perspective.",
        "Let go of negative thoughts: Release any lingering resentments or grudges you may be holding onto.",
        "Focus on self-care and healing: Engage in activities that promote self-love and emotional well-being."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, forgiveness is a powerful tool for personal growth and freeing yourself from past pain. Be patient with yourself throughout this process, and embrace the journey towards a lighter heart and a renewed sense of peace. You deserve to move forward and thrive!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Forgive Others and Move On subliminal help me?`, 
    answer: `The Forgive Others and Move On subliminal is designed to help you release feelings of resentment and anger towards others, allowing you to experience emotional healing and move forward with a sense of peace. The subliminal messages work on your subconscious mind, planting positive affirmations and promoting forgiveness, compassion, and understanding. By consistently listening to the subliminal, you may notice a shift in your mindset and a greater ability to let go of past grievances.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Forgive Others and Move On subliminal?`, 
    answer: `The time it takes to see results may vary from person to person. Some individuals may experience changes in their mindset and emotional well-being after a few weeks of consistent listening, while others may require more time and repetition. It is important to be patient with yourself and trust in the process of emotional healing and forgiveness.`,
    open: false 
  },
  { 
    question: `Can the Forgive Others and Move On subliminal help with deep-seated trauma or unresolved issues?`, 
    answer: `While the Forgive Others and Move On subliminal can support you in releasing resentment and forgiving others, it is not a substitute for therapy or professional help. If you have deep-seated trauma or unresolved issues, it is important to seek the guidance of a mental health professional to address and process those emotions in a safe and supportive environment.`,
    open: false 
  },
  { 
    question: `How can I enhance the effectiveness of the Forgive Others and Move On subliminal?`, 
    answer: `You can enhance the effectiveness of the subliminal by incorporating other self-care practices into your routine. This may include journaling, meditation, therapy, or engaging in activities that help release emotional stress. Surrounding yourself with positive and supportive relationships can also contribute to your ability to forgive and move forward.`,
    open: false 
  },
  { 
    question: `Is it normal to feel resistance or discomfort while listening to the Forgive Others and Move On subliminal?`, 
    answer: `It is not uncommon to experience resistance or discomfort while listening to the subliminal, as it may bring up emotions that need to be acknowledged and addressed. This is a normal part of the healing process. If you find the emotions overwhelming, it may be helpful to seek support from a therapist or counselor.`,
    open: false 
  },
  { 
    question: `Can the Forgive Others and Move On subliminal help me forgive myself?`, 
    answer: `Yes, the Forgive Others and Move On subliminal can also help you cultivate self-forgiveness. The affirmations and messages are designed to promote self-compassion and acceptance, allowing you to release self-blame and guilt. By listening consistently and practicing self-reflection, you can work towards forgiving yourself and moving forward with a renewed sense of inner peace.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>