<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you want to excel in sales and increase your sales revenue, this subliminal can help you develop the mindset and skills necessary for success. By targeting your subconscious mind, it can support you in becoming a more confident, persuasive, and effective salesperson."
    },
    {
      type: 'heading',
      text: 'Here are some ways in which this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Boosting your confidence and belief in your ability to close sales.",
        "Enhancing your communication and interpersonal skills to build better connections with potential clients.",
        "Increasing your motivation, drive, and persistence in pursuing sales opportunities.",
        "Improving your ability to handle objections and overcome challenges in the sales process.",
        "Heightening your focus and concentration to stay on top of your sales targets and goals."
      ]
    },
    {
      type: 'paragraph',
      text: "With regular listening, this subliminal can help you transform into a highly effective salesperson. By aligning your subconscious mind with the qualities and skills needed for success, you can unlock your full sales potential. Remember, success in sales requires continuous learning and improvement, and this subliminal can be a valuable tool on your journey towards sales excellence."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a journey towards sales success requires more than just knowledge and skills. It also demands the right mindset and motivation. This subliminal aims to help you develop the mindset and skills necessary to achieve sales success and increase your sales revenue. Before you start listening, consider the following strategies:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your goals: Clearly define what success in sales means to you and identify specific targets you want to achieve.",
        "Adopt a growth mindset: Embrace the belief that your abilities and skills can be developed and improved over time.",
        "Immerse yourself in learning: Seek out resources, books, and podcasts to continuously develop your sales skills and knowledge.",
        "Stay focused: Maintain a strong sense of purpose and commitment to your goals, especially during challenging times or rejection."
      ]
    },
    {
      type: 'paragraph',
      text: "By integrating this subliminal into your daily routine and applying the preparation tips, you can reinforce positive beliefs, enhance your sales mindset, and unleash your full potential in sales. Let this be the starting point of your journey towards sales success, and get ready to achieve remarkable results!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Sales Success subliminal session! By investing your time and energy into developing the mindset and skills necessary for success in sales, you're paving the way to achieving your goals and increasing sales revenue. It's important to reflect on your experience and continue your growth in the field of sales."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Assess your progress: Reflect on your sales strategies and outcomes to identify areas for improvement.",
        "Continuously learn: Stay up to date with industry trends, sales techniques, and customer behavior.",
        "Practice active listening: Pay attention to your customers' needs and concerns to provide personalized solutions.",
        "Seek feedback: Request feedback from mentors, colleagues, and customers to gain insights and improve your performance."
      ]
    },
    {
      type: 'paragraph',
      text: "Success in sales is a continuous journey that requires constant learning, practice, and adaptation. Embrace the challenges and setbacks as learning opportunities, and celebrate your wins along the way. With dedication and persistence, you have the potential to achieve remarkable sales success. Keep striving for excellence!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Sales Success subliminal help me improve my sales performance?`, 
    answer: `The Sales Success subliminal is designed to influence your subconscious mind, helping you develop a mindset that is aligned with sales success. By listening to the subliminal regularly, you may experience increased confidence, enhanced communication skills, and improved motivation. It can also help you overcome any limiting beliefs or negative thought patterns that may be hindering your sales performance.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Sales Success subliminal?`, 
    answer: `Results can vary from person to person, and may also depend on factors such as individual effort and consistency. Some individuals may notice improvements in their sales performance relatively quickly, while others may require more time and consistent listening to fully integrate the positive affirmations into their mindset. We recommend listening to the subliminal daily and consistently for best results.`,
    open: false 
  },
  { 
    question: `Can the Sales Success subliminal replace sales training and skill development?`, 
    answer: `No, the Sales Success subliminal is not intended to replace sales training and skill development. It serves as a tool to complement and enhance your existing knowledge and skills. It can help you develop the right mindset and motivation to excel in sales, but practical training and skill development are essential for long-term success.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Sales Success subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Sales Success subliminal. However, if you have any concerns about your sales performance or professional development, it may be beneficial to seek guidance from a sales coach or mentor.`,
    open: false 
  },
  { 
    question: `How can I maximize the effectiveness of the Sales Success subliminal?`, 
    answer: `To maximize the effectiveness of the Sales Success subliminal, we recommend creating a conducive environment for learning and growth. This can include setting clear goals, practicing active listening, and regularly self-reflecting on your sales performance. Additionally, incorporating practical strategies and techniques from sales training programs can complement the positive affirmations embedded in the subliminal.`,
    open: false 
  },
  { 
    question: `Can the Sales Success subliminal help me overcome sales-related anxiety?`, 
    answer: `The Sales Success subliminal can be helpful in reducing sales-related anxiety by promoting positive mindset and confidence. However, if you are experiencing significant anxiety that is impacting your sales performance, it is advisable to seek support from a mental health professional or counselor who specializes in anxiety disorders.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>