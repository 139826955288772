<template>
  <MagusDetails />
</template>

<script>
import MagusDetails from '@/views/Admin/MagusDetails/index.vue'
export default {
  components: {
    MagusDetails
  },
  data: () => {
    return {}
  }
}
</script>
