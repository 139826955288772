<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Becoming a successful architect requires not only technical skills and knowledge but also the right mindset and motivation. This subliminal is designed to empower you with the necessary qualities to thrive in the world of architecture and design."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support your journey:'
    },
    {
      type: 'list',
      items: [
        "Developing a growth mindset and embracing challenges as opportunities for growth.",
        "Building confidence in your creative abilities and design skills.",
        "Enhancing your communication and collaboration skills to work effectively with clients and teams.",
        "Strengthening your problem-solving and critical thinking abilities to overcome architectural challenges.",
        "Cultivating a strong work ethic and discipline to meet project deadlines and deliver high-quality work."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to align with the mindset of a successful architect. With consistent use, you may start noticing improvements in your architectural skills, career progression, and overall satisfaction in your work. Remember, success in architecture is a journey, and this subliminal is here to support you every step of the way."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a career in architecture requires dedication, skill, and a growth mindset. This subliminal is designed to support you in unlocking your potential and building the necessary skills for success in the field. Before you start listening, consider these tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your goals: Identify your aspirations within the architectural field, whether it's in design, project management, or entrepreneurship.",
        "Research and study: Immerse yourself in architectural knowledge, study the works of renowned architects, and stay up-to-date with industry trends.",
        "Embrace challenges: Cultivate a mindset that views challenges as opportunities for growth and learning.",
        "Visualize success: Imagine yourself as a successful architect, fulfilling your goals and making a positive impact in the field.",
        "Stay focused: Maintain your motivation and drive by reminding yourself of your passion for architecture and the impact you want to make."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, the subliminal affirmations in this session are designed to help you develop the mindset, skills, and drive necessary for a successful career in architecture. As you listen regularly, you may begin to feel more confident, creative, and capable of achieving your goals in the architectural world. So take a moment, visualize your future success, and let's embark on this empowering journey together."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Become a Successful Architect subliminal session! By investing in yourself and developing the skills and mindset needed for success in the field of architecture, you've taken a significant step towards building a thriving career. Take a moment to reflect on how this session has empowered you and how it can shape your future as an architect."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Define what success means to you in the field of architecture and create a roadmap to achieve your aspirations.",
        "Expand your knowledge: Continue learning and staying updated with the latest trends, techniques, and innovations in architecture and design.",
        "Build a strong network: Connect with other architects, professionals, and mentors who can support and inspire your journey.",
        "Immerse yourself in the field: Seek opportunities to gain hands-on experience, whether through internships, competitions, or personal projects."
      ]
    },
    {
      type: 'paragraph',
      text: "Becoming a successful architect is a lifelong pursuit. Embrace the challenges and opportunities that come your way, and remain dedicated to your craft. With the skills, mindset, and determination you've cultivated, you have the potential to make a lasting impact in the world of architecture. Keep dreaming, designing, and creating!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Can the Become a Successful Architect subliminal help me become an architect without any prior training or education?`, 
    answer: `No, the Become a Successful Architect subliminal is designed to help individuals who already have a foundation in architecture or are in the process of acquiring the necessary education and training. It aims to enhance your skills, mindset, and motivation to succeed in the field of architecture. It is important to pursue the required education and training to become a qualified architect.`,
    open: false
  },
  { 
    question: `How can the Become a Successful Architect subliminal support my career in architecture?`, 
    answer: `The Become a Successful Architect subliminal can support your career in architecture by influencing your mindset and enhancing your skills. It can help boost your motivation, creativity, focus, and confidence, allowing you to perform at your best and succeed in various aspects of your architectural career. It is a tool to supplement your existing knowledge and efforts.`,
    open: false 
  },
  { 
    question: `How long does it take to see results from the Become a Successful Architect subliminal?`, 
    answer: `Results can vary from person to person and depend on factors such as individual receptiveness and consistency in listening to the subliminal. Some people may start noticing positive changes in their mindset and approach to their architectural career within a few weeks, while others may require longer exposure to the subliminal messages. Consistent and regular listening is recommended for optimal results.`,
    open: false 
  },
  { 
    question: `Can the Become a Successful Architect subliminal replace the need for hard work and dedication in the field of architecture?`, 
    answer: `No, the Become a Successful Architect subliminal is not a substitute for hard work, dedication, and the necessary education and training in the field of architecture. It is designed as a tool to enhance your mindset, motivation, and skills to maximize your potential and increase your chances of success. Actively applying what you learn and consistently putting in effort are essential for a successful career.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Become a Successful Architect subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, it is important to note that the subliminal is intended to supplement your efforts and should not be relied upon as the sole source of skill development and career success. It is always beneficial to consult with mentors, professionals, and continue seeking knowledge and experience in the architectural field.`,
    open: false 
  },
  { 
    question: `Can the Become a Successful Architect subliminal help with specific aspects of my architectural career, such as client communication or project management?`, 
    answer: `Yes, the Become a Successful Architect subliminal is designed to enhance various aspects of your architectural career, including but not limited to client communication, project management, creativity, problem-solving, and professionalism. The subliminal messages can have a positive influence on your mindset and behavior, enabling you to develop and excel in these areas.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>