<template>
  <div>
    <v-footer v-if="isUser" app fixed height="120">
      <div
        v-if="isMobile && !playDisabled"
        class="grey lighten-4 mobile-bottom-button text-center"
      >
        <v-icon size="15" @click="mobile_button = !mobile_button"
          >mdi-chevron-double-up</v-icon
        >
      </div>
      <div class="main-container d-flex">
        <div
          v-if="!isMobile"
          class="media-container d-flex justify-start align-center"
        >
          <v-avatar tile color="grey lighten-2">
            <img :src="cover" />
          </v-avatar>
          <div class="mx-2">
            <h6>{{ title }}</h6>
          </div>
        </div>
        <div v-else></div>

        <div class="media-container d-flex justify-center align-center">
          <div>
            <div class="d-flex gap-3 align-items-center">
              <img
                :class="`media-button ${loopDisabled ? 'disabled' : ''}`"
                :src="
                  require(`@/assets/images/user/webplayer/${isDark}/repeat.png`)
                "
                alt=""
                @click="loopAudio"
              />
              <img
                :class="`media-button ${previousDisabled ? 'disabled' : ''}`"
                :src="
                  require(`@/assets/images/user/webplayer/${isDark}/prev.png`)
                "
                alt=""
                @click="previousAudio"
              />
              <img
                v-if="isAudioPlaying"
                :class="`media-button ${playDisabled ? 'disabled' : ''}`"
                :src="
                  require(`@/assets/images/user/webplayer/${isDark}/pause.png`)
                "
                alt=""
                @click="pauseAudios"
              />
              <img
                v-else
                :class="`media-button ${playDisabled ? 'disabled' : ''}`"
                :src="
                  require(`@/assets/images/user/webplayer/${isDark}/play.png`)
                "
                alt=""
                @click="playAudios"
              />
              <img
                :class="`media-button ${nextDisabled ? 'disabled' : ''}`"
                :src="
                  require(`@/assets/images/user/webplayer/${isDark}/next.png`)
                "
                alt=""
                @click="nextAudios"
              />
              <img
                :class="`media-button ${repeatAudio ? 'disabled' : ''}`"
                :src="
                  require(`@/assets/images/user/webplayer/${isDark}/repeat-one.png`)
                "
                alt=""
                @click="repeatAudio"
              />
            </div>
            <div class="d-flex justify-center align-center mt-1">
              <span class="time">{{ convertToTime(currentTime) }}</span>
              <v-progress-linear
                v-model="progress"
                color="deep-purple accent-4"
                class="mx-2"
                height="8"
              ></v-progress-linear>
              <span class="time">{{ convertToTime(durationTime) }}</span>
            </div>
          </div>
        </div>

        <div
          v-if="!isMobile"
          class="media-container-2 d-flex justify-end align-center gap-3"
        >
          <img
            :class="`${playDisabled ? 'disabled' : ''}`"
            src="@/assets/images/user/webplayer/PLAYLIST.png"
            alt=""
            @click="goToTrack"
          />
          <v-menu
            v-model="all_volume"
            :close-on-content-click="false"
            offset-x
            :disabled="playDisabled"
          >
            <template v-slot:activator="{ on, attrs }">
              <img
                :class="`media-button ${playDisabled ? 'disabled' : ''}`"
                src="@/assets/images/user/webplayer/VOLUME.png"
                alt=""
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-card class="pa-4" style="min-width: 300px">
              <h3>All Volume</h3>
              <p class="mt-2 mb-0">{{ title }}</p>
              <v-slider
                :disabled="isUserFree"
                prepend-icon="mdi-volume-high"
                min="0"
                max="100"
                v-model="volume"
              ></v-slider>
              <v-btn block @click="updateCurrentVolumes" :disabled="isUserFree">
                Apply
              </v-btn>
            </v-card>
          </v-menu>
          <v-menu
            v-model="volume_per_track"
            :close-on-content-click="false"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <img
                :class="`media-button ${playDisabled ? 'disabled' : ''}`"
                :src="
                  require(`@/assets/images/user/webplayer/${isDark}/advance-volume.png`)
                "
                alt=""
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-card class="pa-4" style="min-width: 300px">
              <h3>Volume per track</h3>
              <template v-for="(volume, index) in volumes">
                <div :key="index">
                  <p class="mt-2 mb-0">{{ titles[index] }}</p>
                  <v-slider
                    :disabled="isUserFree"
                    prepend-icon="mdi-volume-high"
                    min="0"
                    max="100"
                    v-model="volumes[index]"
                  ></v-slider>
                </div>
              </template>
              <v-btn block @click="updateCurrentVolumes" :disabled="isUserFree">
                Apply
              </v-btn>
            </v-card>
          </v-menu>
        </div>
        <div v-else></div>
      </div>
    </v-footer>

    <v-bottom-sheet v-model="mobile_button" class="pa-5">
      <v-list class="pa-3">
        <h4>All Volume</h4>
        <p class="mt-2 mb-0">{{ title }}</p>
        <v-slider
          :disabled="isUserFree"
          class="mb-0"
          prepend-icon="mdi-volume-high"
          min="0"
          max="100"
          v-model="volume"
        ></v-slider>

        <h4 class="mb-2">Volume per track</h4>
        <template v-for="(volume, index) in volumes">
          <div :key="index">
            <p class="mt-2 mb-0">{{ titles[index] }}</p>
            <v-slider
              :disabled="isUserFree"
              prepend-icon="mdi-volume-high"
              min="0"
              max="100"
              v-model="volumes[index]"
            ></v-slider>
          </div>
        </template>

        <v-btn block @click="updateCurrentVolumes" :disabled="isUserFree">
          Apply
        </v-btn>
      </v-list>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { getAudioHistory } from "@/api/home";
import { getSubscriptionById } from "@/api/subscription";

import { convertToTime } from "@/utils";
import {
  playAudio,
  pauseAudio,
  nextAudio,
  previousAudio,
  getAudio,
  loopAudio,
  repeatAudio,
  addHistory,
} from "@/utils/audio";

export default {
  data: () => {
    return {
      getAudioHistory,

      getAudio,
      nextAudio,
      loopAudio,
      playAudio,
      addHistory,
      pauseAudio,
      repeatAudio,
      previousAudio,
      convertToTime,
      convertToTime,
      getSubscriptionById,

      volume: 0,
      volumes: [],
      old_volumes: [],
      recent_data: [],

      all_volume: false,
      apply_volume: false,
      mobile_button: false,
      volume_changed: true,
      initial_history: true,
      volume_per_track: false,
    };
  },
  computed: {
    width() {
      return this.$store.state.screen.width;
    },
    layout() {
      return this.$route.matched[0].meta.layout;
    },

    loop() {
      return this.$store.state.audio.loop;
    },
    audio() {
      return this.$store.state.audio.data;
    },
    audios() {
      return this.$store.state.audio.datas;
    },
    audioIndex() {
      return this.$store.state.audio.index;
    },
    audioCount() {
      return this.$store.state.audio.count;
    },
    cover() {
      return this.$store.state.audio.cover;
    },
    title() {
      return this.$store.state.audio.title;
    },
    titles() {
      return this.$store.state.audio.titles;
    },
    repeat() {
      return this.$store.state.audio.repeat;
    },
    currentTime() {
      return this.$store.state.audio.current.time;
    },
    durationTime() {
      return this.$store.state.audio.duration.time;
    },
    durationTimes() {
      return this.$store.state.audio.duration.times;
    },
    recentPlayed() {
      return this.$store.state.recent.played.data;
    },
    user() {
      return this.$store.state.user.info;
    },
    progress: {
      set(val) {
        this.updateCurrentTime(val);
      },
      get() {
        return Math.floor((this.currentTime / this.durationTime) * 100);
      },
    },

    loopDisabled() {
      return this.audioCount == 0;
    },
    repeatDisabled() {
      return this.audioCount == 0;
    },
    playDisabled() {
      return this.audioCount == 0;
    },
    previousDisabled() {
      return (this.audioIndex == 0 && !this.loop) || this.audioCount == 0;
    },
    nextDisabled() {
      return (
        (this.audioIndex + 1 == this.audioCount && !this.loop) ||
        this.audioCount == 0
      );
    },

    isUser() {
      return this.layout === "User";
    },
    isMobile() {
      return this.width < 1000;
    },
    isMobileSlim() {
      return this.width < 480;
    },
    isMobileSlimmer() {
      return this.width < 330;
    },
    isAudioPlaying() {
      return this.$store.state.audio.status;
    },
    isPlaylist() {
      return this.playlistId != this.subliminalId;
    },
    isSubliminal() {
      return this.playlistId == this.subliminalId;
    },
    isUserFree() {
      return (
        JSON.parse(localStorage.getItem("user")).info.subscription_id == null ||
        JSON.parse(localStorage.getItem("user")).info.subscription_id == 1
      );
    },
    isDark() {
      return this.$vuetify.theme.isDark ? "dark" : "light";
    },
  },
  watch: {
    currentTime(val) {
      if (val == this.durationTime) {
        this.nextAudio();
      }
    },
    durationTimes(val) {
      if (this.recentPlayed.length == 0 && this.initial_history) {
        this.initial_history = false;
        this.$store.dispatch("screen/getLoading", false);
      }

      if (val.length > 0 && val.length == this.audio.length) {
        const duration = Math.max(...val);
        const index = val.indexOf(duration);

        this.$store.dispatch("audio/getDurationTime", val[index]);
        if (!this.initial_history) {
          this.playAudio();
        }

        if (this.initial_history) {
          let currentime = 0;
          if (this.recentPlayed.length > 0) {
            currentime = this.recentPlayed[0].current_time;
          }

          this.$store.dispatch("audio/getCurrentTime", parseInt(currentime));
          this.$store.dispatch("screen/getLoading", false);
          this.initial_history = false;
        }
      }
    },
    isMobile() {
      this.all_volume = false;
      this.mobile_volume = false;
      this.volume_per_track = false;
    },
    volume(val) {
      if (val != undefined) {
        if (this.volume_changed) {
          for (let index = 0; index < this.volumes.length; index++) {
            this.volumes[index] = val;
          }
        }
        this.volume_changed = true;
      }
    },
    all_volume(val) {
      this.volumeOpen(val);
    },
    volume_per_track(val) {
      this.volumeOpen(val);
    },
    mobile_volume(val) {
      this.volumeOpen(val);
    },
    recentPlayed(val) {
      if (this.initial_history) {
        this.getAudio(val[0]);
        this.$store.dispatch("screen/getLoading", val.length > 0);
      }
    },
  },
  methods: {
    async playAudios() {
      if (this.currentTime == this.durationTime) {
        this.$store.dispatch("getCurrentTime", 0);
      }
      await this.playAudio();
    },
    async pauseAudios() {
      await this.pauseAudio();
    },
    nextAudios() {
      this.nextAudio();
    },
    previousAudios() {
      this.previousAudio();
    },
    updateCurrentVolumes() {
      this.$store.dispatch("audio/getVolumes", this.volumes);

      this.all_volume = false;
      this.apply_volume = true;
      this.mobile_volume = false;
      this.volume_per_track = false;

      this.addHistory();
    },
    updateCurrentTime(val) {
      if (typeof parseInt(val) == "number") {
        const current_time = (parseInt(val) * this.durationTime) / 100;
        this.$store.dispatch("audio/getCurrentTime", parseInt(current_time));
      }
    },
    updateCurrentTitleCover() {
      let title = "";
      let cover = "";
      const data = this.currentRecent;

      if (data.hasOwnProperty("playlist_id")) {
        title = data.info[this.currentIndex].title;
        cover = data.info[this.currentIndex].cover;
      } else {
        title = data.title;
        cover = data.cover;
      }

      this.$store.dispatch("getCurrentSubliminalTitle", title);
      this.$store.dispatch("getCurrentCover", cover);
    },
    goToTrack() {
      this.$store.dispatch("user/getTrack", this.recentPlayed[0]);
      if (this.$route.path != "/user/track") {
        this.$router.push("/user/track");
      }
    },
    getVolumeData() {
      this.volumes = this.$store.state.audio.volumes;
      if (this.volumes.every((volume) => volume === this.volumes[0])) {
        this.volume = this.volumes[0];
      } else {
        this.volume = 100;
      }
      this.volume_changed = false;
    },
    volumeOpen(isVolumeOpen) {
      if (isVolumeOpen) {
        this.getVolumeData();
        this.old_volumes = [...this.volumes];
      } else {
        if (!this.apply_volume) {
          this.$store.dispatch("audio/getVolumes", this.old_volumes);
        }
      }
    },
  },
};
</script>

<style>
/* .media-button {
  margin-inline: 5px;
} */

.main-container {
  width: 100%;
  justify-content: space-between;
}

/* .media-container {
  min-width: 300px;
} */

.mobile-bottom-button {
  width: 50px;
  margin: 0 auto;

  z-index: 10000;
  position: absolute;

  left: 0;
  right: 0;
  bottom: 118px;

  border-top-left-radius: 25%;
  border-top-right-radius: 25%;
}

.title {
  width: 200px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media screen and (max-width: 330px) {
  .main-container {
    width: 100%;
    justify-content: center;
  }

  /* .media-button {
    margin-inline: 3px;
  } */
}

.media-container .media-button:nth-of-type(1),
.media-container .media-button:nth-of-type(5) {
  width: 30px;
  height: 30px;
}

.media-container .media-button:nth-of-type(3) {
  width: 50px;
  height: 50px;
}

.media-container .media-button:nth-of-type(2),
.media-container .media-button:nth-of-type(4) {
  width: 40px;
  height: 40px;
}

.media-container-2 .media-button {
  width: 40px;
  height: 40px;
}

.media-button.disabled {
  filter: grayscale(100);
}
</style>
