<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Do you have a fear of snakes or suffer from snake phobia? You're not alone. Many people experience fear or anxiety when encountering snakes, and it can be a paralyzing experience. Fortunately, this subliminal is designed to help you overcome your fear and eliminate snake phobia."
    },
    {
      type: 'heading',
      text: 'Here is how this subliminal can help:'
    },
    {
      type: 'list',
      items: [
        "Reduce anxiety and fear when seeing or thinking about snakes.",
        "Reprogram your mind to associate snakes with feelings of calmness and peace.",
        "Build confidence in your ability to confront and handle encounters with snakes.",
        "Develop a deeper understanding and appreciation for these fascinating creatures.",
        "Regain a sense of control and freedom by overcoming your fear of snakes."
      ]
    },
    {
      type: 'paragraph',
      text: "Using this subliminal regularly can help you rewire your subconscious mind, allowing you to approach snakes with ease and confidence. As you listen, you may notice a gradual reduction in fear and anxiety, and an increased sense of calm. Remember, overcoming a phobia takes time and patience, but with this subliminal, you can take the first steps toward reclaiming your peace of mind."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Dealing with a fear of snakes or snake phobia can be challenging, but this subliminal is here to help you overcome those fears and regain a sense of calm and peace. Before you start listening, consider these preparatory tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Face your fear: Acknowledge and understand that your fear is an irrational response. Realize that you have the power to change your perception.",
        "Educate yourself: Learn more about snakes, their behavior, and their role in the ecosystem. This knowledge can help demystify them and reduce anxiety.",
        "Visualize positive outcomes: Imagine yourself feeling calm and confident around snakes, focus on positive experiences, and visualize yourself overcoming your fear.",
        "Stay open-minded: Remain receptive to the subliminal messages and affirmations, allowing them to reprogram your subconscious mind towards a healthier perspective on snakes."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal is designed to help diminish your fear of snakes and eliminate your snake phobia. With regular listening, you may find yourself feeling more at ease and appreciating the beauty of these creatures. Take a moment to prepare yourself mentally, let go of any doubts or apprehensions, and embrace the opportunity to overcome your fear of snakes. Let's begin this transformative journey together!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Fear of Snakes and Snake Phobia Removal subliminal! You've taken an important step towards overcoming your fear and rediscovering peace and calm around these fascinating creatures. Reflecting on your experience and continuing your journey towards conquering your fear is essential."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Educate yourself about snakes: Gain knowledge about different snake species, their behaviors, and habitats.",
        "Practice exposure therapy: Gradually expose yourself to photos, videos, or controlled environments with snakes to desensitize your fear.",
        "Seek professional help if needed: Consider consulting with a therapist specializing in anxiety and phobias for additional support.",
        "Engage in relaxation techniques: Practice deep breathing, meditation, or mindfulness exercises to reduce anxiety and promote relaxation."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming fears takes time and effort, but with perseverance, you can find peace and calm around snakes. Celebrate your progress and continue taking small steps towards conquering your fear. You've got this!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Fear of Snakes and Snake Phobia Removal subliminal work?`, 
    answer: `The Fear of Snakes and Snake Phobia Removal subliminal works by targeting your subconscious mind with positive affirmations and suggestions that help reframe your perception of snakes and reduce fear and anxiety. By listening to the subliminal regularly, you can gradually change your automatic responses to snakes, allowing you to feel more calm and confident in their presence.`,
    open: false
  },
  { 
    question: `How long does it take to overcome a fear of snakes using the subliminal?`, 
    answer: `The time it takes to overcome a fear of snakes using the subliminal can vary from person to person. It depends on the severity of the fear and the individual's responsiveness to the subliminal messages. Consistent and regular listening is encouraged for best results. Some individuals may start noticing positive changes within a few weeks, while others may require more time.`,
    open: false 
  },
  { 
    question: `Can the Fear of Snakes and Snake Phobia Removal subliminal work for severe snake phobia?`, 
    answer: `The Fear of Snakes and Snake Phobia Removal subliminal can be beneficial for individuals with mild to moderate snake phobia. However, for severe phobias, we recommend seeking professional help from a qualified therapist or counselor who specializes in treating phobias.`,
    open: false 
  },
  { 
    question: `Is it necessary to face real snakes to overcome the fear using the subliminal?`, 
    answer: `No, it is not necessary to face real snakes to overcome the fear using the subliminal. The subliminal messages are designed to help reframe your perception and reduce fear and anxiety around snakes. However, if you feel comfortable and confident, gradual exposure to real or simulated snakes under the guidance of a professional may enhance the effectiveness of the subliminal.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with using the Fear of Snakes and Snake Phobia Removal subliminal?`, 
    answer: `No, there are no known risks or side effects associated with using the Fear of Snakes and Snake Phobia Removal subliminal audio. However, if you have any concerns or pre-existing conditions, it is always recommended to consult with a healthcare professional before using any subliminal program.`,
    open: false 
  },
  { 
    question: `Does the Fear of Snakes and Snake Phobia Removal subliminal work for everyone?`, 
    answer: `The Fear of Snakes and Snake Phobia Removal subliminal is designed to be effective for most individuals. However, everyone is unique, and individual results may vary. It is important to approach the subliminal with an open mind and a genuine desire to overcome your fear of snakes. Consistent and regular listening can increase the likelihood of positive outcomes.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>