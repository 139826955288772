<template>
  <div class="my-5">
    <Contact />
  </div>
</template>

<script>
import Contact from '@/views/Main/Contact'
export default {
  components: {
    Contact
  }
}
</script>