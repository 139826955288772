<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Going through a divorce can be an incredibly challenging and painful experience. This subliminal is designed to help you heal and overcome the emotional pain associated with divorce, allowing you to find peace and move forward with your life."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help you:"
    },
    {
      type: 'list',
      items: [
        "Assisting in processing and releasing the emotions and grief that come with divorce.",
        "Promoting self-love, self-compassion, and rebuilding your self-esteem.",
        "Providing comfort and strength during times of sadness, anger, or other difficult emotions.",
        "Encouraging forgiveness and letting go of resentment towards your ex-partner.",
        "Supporting the development of a positive and resilient mindset for the future."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can begin to heal and release the emotional pain associated with divorce. It may help you find strength, inner peace, and a renewed sense of purpose as you navigate the next chapter of your life. Remember, healing is a journey, and it's important to be patient and kind to yourself throughout the process."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Going through a divorce can be an emotionally challenging experience, but healing and finding peace is possible. This subliminal is designed to help you overcome the emotional pain of your divorce and move forward with your life. Before you begin listening, here are a few suggestions to prepare yourself:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your emotions: Allow yourself to feel and process the pain and emotions associated with the divorce.",
        "Practice self-care: Prioritize self-care activities that nourish your mind, body, and spirit.",
        "Set intentions for healing: Reflect on what you hope to achieve through this subliminal, and set clear intentions for your healing journey.",
        "Create a supportive environment: Surround yourself with positive, understanding people who can provide emotional support during this time."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you may begin to experience a sense of healing, peace, and empowerment. Allow yourself to be patient and compassionate with yourself throughout this process. You have the strength within you to heal and move forward. Take a deep breath, trust in your ability to overcome, and let the healing begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Divorce Emotional Pain Healing session! By focusing on healing and overcoming the emotional pain from your divorce, you've taken a crucial step towards finding peace and moving forward with your life."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Give yourself time to heal: Recognize that healing takes time and be patient with yourself.",
        "Seek support: Reach out to friends, family, or therapists who can provide understanding and guidance.",
        "Practice self-care: Engage in activities that bring you joy and nurture your well-being.",
        "Embrace forgiveness: Work towards forgiving yourself and others involved in the divorce.",
        "Focus on personal growth: Use this experience as an opportunity for self-reflection and growth."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, healing from the emotional pain of divorce is a process. Be gentle with yourself as you navigate this journey. By practicing self-care and seeking support, you can find peace and move forward with your life. You deserve happiness and a bright future ahead!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Divorce Emotional Pain Healing subliminal help me?`, 
    answer: `The Divorce Emotional Pain Healing subliminal is designed to support your emotional healing process after a divorce. By listening to the subliminal, you can access positive affirmations and messages that promote healing, self-empowerment, and resilience. It can help you release negative emotions, regain your sense of self-worth, and find the inner strength to move forward with your life.`,
    open: false
  },
  { 
    question: `How long does it take to heal from emotional pain after a divorce using the subliminal?`, 
    answer: `The healing process is unique to each individual and can vary in duration. The Divorce Emotional Pain Healing subliminal can play a supportive role, but it is important to allow yourself enough time and self-care to process your emotions fully. Consistent and regular listening to the subliminal can contribute to your healing journey, but it is not a substitute for professional guidance or therapy if needed.`,
    open: false 
  },
  { 
    question: `Can the Divorce Emotional Pain Healing subliminal replace therapy or counseling?`, 
    answer: `The Divorce Emotional Pain Healing subliminal is designed to complement therapy or counseling for emotional healing after a divorce. While the subliminal can be a valuable tool in supporting your healing journey, it is not a substitute for professional guidance or treatment. If you feel the need for additional support, it is recommended to seek the assistance of a qualified therapist or counselor.`,
    open: false 
  },
  { 
    question: `How can I enhance the effectiveness of the Divorce Emotional Pain Healing subliminal?`, 
    answer: `To enhance the effectiveness of the subliminal, create a supportive environment by surroundin
g yourself with positive influences and engaging in self-care activities. This can include practicing mindfulness or meditation, journaling, engaging in physical exercise, and seeking out a strong support system of friends and family. Taking proactive steps to address your emotions and seeking professional help if necessary can also play a vital role in your healing process.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Divorce Emotional Pain Healing subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Divorce Emotional Pain Healing subliminal. However, if you have any concerns about your emotional well-being or mental health, it is recommended to seek professional assistance.`,
    open: false 
  },
  { 
    question: `Will the Divorce Emotional Pain Healing subliminal make me forget about my ex-spouse?`, 
    answer: `No, the goal of the Divorce Emotional Pain Healing subliminal is not to make you forget about your ex-spouse, but rather to help you heal and find peace within yourself. It aims to support your emotional well-being, allowing you to move forward with your life in a positive and empowered way.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>