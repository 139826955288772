<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Do you find yourself translating in your mind before speaking in another language? This subliminal can help you break that habit and speak more fluently and confidently in any language."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "You'll be able to speak more naturally and effortlessly without the need for internal translation.",
        "Your speech will become more fluent and flow smoothly, making conversations easier.",
        "You'll gain confidence in your ability to communicate effectively in any language.",
        "Your vocabulary and language skills will improve as you become more comfortable expressing yourself directly.",
        "You'll develop a more authentic and authentic speaking style, enhancing your overall language fluency."
      ]
    },
    {
      type: 'paragraph',
      text: "With consistent use of this subliminal, you can rewire your mind to think and speak in your target language without the barrier of translation. Over time, you'll notice a significant improvement in your language skills and the confidence to express yourself naturally. Remember, practice is key, and this subliminal can be a valuable tool in your language learning journey."
    }
  ]
},
            {
  id: 'before-listening',
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Breaking the habit of translating in your mind before speaking can be a challenge, but with the help of this subliminal, you can develop fluent and natural communication skills. Before you start listening, consider these tips to enhance your progress:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Focus on your goal of speaking fluently without the need to translate in your mind.",
        "Practice visualization: Imagine yourself speaking confidently and fluidly in various situations.",
        "Boost your vocabulary: Engage in activities that expand your vocabulary and improve your language proficiency.",
        "Stay present: Keep your attention on the present moment and the words you want to express, rather than relying on translation."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal is designed to help rewire your subconscious mind and break the habit of translating before speaking. As you listen regularly, you may notice increased confidence in your ability to communicate fluently and effortlessly. Embrace this journey of self-improvement, stay focused on your goal, and let's begin!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the session to stop the habit of translating in your mind before you speak! By focusing on improving your speaking and communication skills, you've taken an important step towards fluency. Reflecting on this achievement and planning for continued growth is crucial as you move forward."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice speaking without translating: Engage in conversations or role-playing exercises to hone your ability to communicate directly.",
        "Immerse yourself in the language: Surround yourself with authentic language materials like books, movies, or podcasts.",
        "Build vocabulary and phrases: Use flashcards or language learning apps to expand your word bank and practice using common phrases.",
        "Embrace mistakes and learn from them: Don't be afraid to make errors while speaking. Learn from them and use them as opportunities for growth."
      ]
    },
    {
      type: 'paragraph',
      text: "Becoming fluent in a language takes time and effort, but with dedication and consistent practice, you can achieve your goal. Embrace the challenges, keep practicing, and soon you'll find yourself speaking confidently and fluently. You're on your way to becoming a skilled communicator. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the subliminal help me stop translating in my mind before speaking?`, 
    answer: `The subliminal is designed to reprogram your subconscious mind and help you develop the habit of speaking fluently without the need to translate in your mind first. By consistently listening to the subliminal, you can align your thoughts and actions with confident and fluent communication. Over time, you may notice a reduction in the need to translate as you become more comfortable and fluent in speaking.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the subliminal?`, 
    answer: `Results may vary from person to person. Some people may experience noticeable improvements in their ability to speak fluently without translation after listening to the subliminal for a few weeks, while others may require more time. Consistent listening and practice are key to achieving desired outcomes.`,
    open: false 
  },
  { 
    question: `Can the subliminal replace language learning or speaking practice?`, 
    answer: `No, the subliminal is a tool to support your language learning and communication skills. It helps retrain your subconscious mind to develop the habit of speaking fluently without translating, but it is still important to engage in language learning activities and practice speaking regularly to reinforce and apply what you have learned.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the subliminal?`, 
    answer: `No known risks or side effects are associated with listening to the subliminal audio. However, if you have any concerns about your language learning or communication abilities, it is always recommended to consult with a language instructor or professional for personalized guidance.`,
    open: false 
  },
  { 
    question: `Can the subliminal be used for learning any language?`, 
    answer: `Yes, the subliminal can be beneficial for individuals learning any language. It focuses on developing the habit of speaking fluently without translating, which can be applied to any language learning process. Whether you are learning English, Spanish, Mandarin, or any other language, the subliminal can help you improve your communication skills.`,
    open: false 
  },
  { 
    question: `How often should I listen to the subliminal for best results?`, 
    answer: `For best results, it is recommended to listen to the subliminal daily and consistently. Consistency and repetition are important in reprogramming the subconscious mind and reinforcing new habits. Find a time and place where you can listen to the subliminal without distractions and make it a regular part of your language learning routine.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>