<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Do you have big dreams and aspirations but find it challenging to turn them into reality? This subliminal is here to help you unlock your full potential and make your dreams come true."
    },
    {
      type: 'heading',
      text: 'Here are some key ways this subliminal can support you:'
    },
    {
      type: 'list',
      items: [
        "By reprogramming your subconscious mind, it encourages a positive and abundant mindset.",
        "It helps you break free from self-limiting beliefs and self-doubt that may be holding you back.",
        "It boosts your confidence, resilience, and motivation, empowering you to take bold actions towards your goals.",
        "It enhances your visualization and manifestation skills, allowing you to align your thoughts and actions with your desired outcomes.",
        "It supports you in overcoming obstacles, setbacks, and fear of failure, helping you stay focused and determined on your path to success."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can shift your mindset, align your energy, and tap into your limitless potential. Remember, turning your dreams into reality requires consistent effort, resilience, and belief in yourself. With the help of this subliminal, you can think big and make it big!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Preparing your mind for success is an essential step in achieving your biggest goals and aspirations. This subliminal is designed to help unleash your full potential and help you think big. Before you start listening, here are some tips to consider:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your goals: Take some time to reflect on what you truly desire and set clear and specific goals for yourself.",
        "Believe in yourself: Cultivate a strong belief in your abilities, and trust that you have what it takes to achieve your dreams.",
        "Visualize success: Imagine yourself already achieving your goals and experiencing the joy and satisfaction that comes with it.",
        "Create an action plan: Break down your goals into smaller, actionable steps, and create a plan to work towards them.",
        "Embrace positivity: Surround yourself with positive influences and affirmations that reinforce your potential for success."
      ]
    },
    {
      type: 'paragraph',
      text: "As you embark on this journey of self-discovery and personal growth, remember that you have all the potential within you to make your dreams a reality. Allow the subliminal messages to penetrate your subconscious mind and guide you towards success. Trust in yourself, stay persistent, and let's begin this empowering transformation together!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Think Big and Make it Big subliminal! By focusing on unlocking your full potential and pursuing your biggest goals and aspirations, you've taken a significant step towards personal growth and success. Now, it's time to reflect on your experience and continue on your journey towards achieving greatness."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Write down your big goals and break them down into smaller, actionable steps.",
        "Visualize success: Regularly imagine yourself achieving your goals and visualize the life you want.",
        "Surround yourself with positivity: Connect with supportive and like-minded individuals who inspire and motivate you.",
        "Stay committed and persistent: Embrace challenges as opportunities for growth and keep pushing forward."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, achieving your greatest aspirations requires dedication and perseverance. Trust in your abilities, believe in yourself, and keep taking steps towards your dreams. You have the power to think big and make it big!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the "Think Big and Make it Big!" subliminal help me achieve my goals?`, 
    answer: `The "Think Big and Make it Big!" subliminal is designed to reprogram your subconscious mind with positive affirmations and empowering beliefs that support your ability to think big and achieve your goals. By consistently listening to the subliminal, you can cultivate a mindset of confidence, determination, and focus, allowing you to overcome obstacles and take action towards your dreams.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the "Think Big and Make it Big!" subliminal?`, 
    answer: `Results may vary depending on the individual and their specific circumstances. Some people may experience positive changes in their mindset and actions right away, while others may require more time and consistent listening. We recommend listening to the subliminal daily for optimal results and allowing the affirmations to work on a subconscious level over time.`,
    open: false 
  },
  { 
    question: `Can the "Think Big and Make it Big!" subliminal replace practical action towards achieving my goals?`, 
    answer: `No, the subliminal is intended to support and enhance your mindset and motivation towards achieving your goals, but it does not replace the need for practical action. It's important to combine the subliminal with strategic planning, goal setting, and consistent effort in order to maximize your chances of success.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the "Think Big and Make it Big!" subliminal?`, 
    answer: `There are no known risks or side effects associated with listening to this subliminal audio. However, it's always important to use your own judgment and listen to your body. If you have any concerns or underlying health conditions, please consult with your healthcare provider before using this subliminal.`,
    open: false 
  },
  { 
    question: `How can I make the "Think Big and Make it Big!" subliminal more effective?`, 
    answer: `To enhance the effectiveness of the subliminal, it can be helpful to visualize your goals, maintain a positive mindset, and surround yourself with a supportive environment. Setting specific and achievable goals, breaking them down into manageable steps, and taking consistent action can also contribute to the effectiveness of the subliminal in achieving your desired outcomes.`,
    open: false 
  },
  { 
    question: `Can the "Think Big and Make it Big!" subliminal help with overcoming self-doubt and limiting beliefs?`, 
    answer: `Yes, one of the aims of the "Think Big and Make it Big!" subliminal is to help you overcome self-doubt and limiting beliefs that may be holding you back from achieving your goals. By continuously exposing your subconscious mind to positive affirmations and empowering beliefs, you can gradually reframe your perspective and develop a more confident and resilient mindset.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>