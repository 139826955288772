<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're tired of living with a toxic and negative attitude, this subliminal can help you make a positive transformation. By targeting your subconscious mind, it works to eliminate negative thought patterns and cultivate a more positive and fulfilling life."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Removing toxic attitudes and beliefs that hold you back from living your best life.",
        "Developing a more optimistic and positive outlook on life.",
        "Increasing your self-esteem and confidence levels.",
        "Enhancing your relationships by fostering a more positive and supportive attitude.",
        "Empowering you to overcome obstacles and face challenges with resilience and positivity."
      ]
    },
    {
      type: 'paragraph',
      text: "With regular listening, this subliminal can gradually reshape your mindset and help you let go of toxic and negative attitudes. As you embrace a more positive outlook, you may find yourself enjoying greater happiness, success, and fulfillment in all areas of your life. Remember, change takes time and effort, but the results are well worth it."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Shifting your mindset and letting go of toxic and negative attitudes is a powerful step towards living a more positive and fulfilling life. This subliminal is designed to support you in this transformation. Before you begin listening, consider the following suggestions to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Self-reflection: Take a moment to identify the negative attitudes you want to eliminate and the positive mindset you want to cultivate.",
        "Affirmations: Write down positive affirmations that contradict your negative attitudes, and aim to integrate them into your daily routine.",
        "Visualization: Visualize yourself living a life free from toxic and negative attitudes, and imagine the positive impact it will have on your overall well-being.",
        "Openness: Approach the subconscious reprogramming process with an open mind, allowing the subliminal messages to penetrate deeply and create lasting change."
      ]
    },
    {
      type: 'paragraph',
      text: "As you embark on this journey of transformation, remember that change takes time and effort. Regular listening, combined with self-reflection and the integration of positive practices, can help you create a more positive and fulfilling life. Embrace this opportunity to let go of toxic attitudes and make room for happiness and growth. Let's begin!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Toxic and Negative Attitude Removal subliminal session! By embarking on this journey to transform your mindset, you've taken an important step towards living a more positive and fulfilling life. Now, it's time to reflect on your progress and continue your personal growth."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Self-reflection: Take time to reflect on your negative attitudes and behaviors and identify areas that need improvement.",
        "Practice gratitude: Cultivate a habit of finding things to be grateful for each day.",
        "Surround yourself with positivity: Seek out positive environments and surround yourself with supportive and uplifting people.",
        "Choose positive self-talk: Replace negative self-talk with positive affirmations and encouraging thoughts."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, transforming your mindset takes time and effort, but with consistency and determination, you can make positive changes. Embrace the journey of self-discovery and let go of toxic attitudes. Each step you take towards a more positive mindset brings you closer to a life filled with joy, fulfillment, and success. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Toxic and Negative Attitude Removal subliminal help me?`, 
    answer: `The Toxic and Negative Attitude Removal subliminal is designed to reprogram your subconscious mind and eliminate toxic and negative attitudes. By listening to the subliminal regularly, you can begin to change your thought patterns and replace negative attitudes with positive and empowering ones. Over time, you may notice a shift in your mindset, leading to a more positive and fulfilling life.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Toxic and Negative Attitude Removal subliminal?`, 
    answer: `Results can vary from person to person, and the time it takes to see results may depend on several factors, including the intensity of your negative attitudes and your level of commitment to listening to the subliminal. Consistent and regular listening is key to achieving the desired results. While it may take some time, you may notice gradual changes in your thought patterns and overall attitude over time.`,
    open: false 
  },
  { 
    question: `Can the Toxic and Negative Attitude Removal subliminal completely eliminate all negative attitudes?`, 
    answer: `The Toxic and Negative Attitude Removal subliminal is designed to help you reprogram your subconscious mind and eliminate toxic and negative attitudes. However, it is important to remember that changing deeply ingrained thought patterns takes time and effort. While the subliminal can be a powerful tool in this process, it may not completely eliminate all negative attitudes on its own. It is recommended to complement the subliminal with self-awareness, personal growth practices, and positive habits to maximize its effectiveness.`,
    open: false 
  },
  { 
    question: `How often should I listen to the Toxic and Negative Attitude Removal subliminal?`, 
    answer: `To achieve the best results, it is recommended to listen to the Toxic and Negative Attitude Removal subliminal daily. Consistent and regular listening can help reinforce positive messages and gradually reprogram your subconscious mind. It can be beneficial to incorporate the subliminal into your daily routine, such as listening in the morning or before bed, to establish a consistent practice.`,
    open: false 
  },
  { 
    question: `Can the Toxic and Negative Attitude Removal subliminal replace therapy or professional help?`, 
    answer: `The Toxic and Negative Attitude Removal subliminal is not a substitute for professional therapy or help. While it can be a helpful tool in transforming negative attitudes, it is important to seek professional guidance if you are struggling with severe or persistent negative attitudes. A qualified therapist or counselor can provide personalized support and strategies to address deep-rooted issues.`,
    open: false 
  },
  { 
    question: `Are there any side effects of listening to the Toxic and Negative Attitude Removal subliminal?`, 
    answer: `No, there are no known side effects associated with listening to the Toxic and Negative Attitude Removal subliminal. However, as with any self-improvement practice, it is essential to listen to your own feelings and emotions. If you experience discomfort or emotional distress while using the subliminal, it may be helpful to seek professional help or reassess your approach to personal growth.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>