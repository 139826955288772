import request from "@/utils/request";

export const getPromoCodes = (data) => {
  return request.get("/api/promo/code").then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const getPromoCodeFilters = (data) => {
  return request.post("/api/promo/code/filter").then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const getPromoCode = (data) => {
  return request.post("/api/promo/code/data", data).then((res) => {
    const { data } = res;
    return data;
  });
};

export const addPromoCodes = (data) => {
  return request.post("/api/promo/code", data).then((res) => {
    return res.data;
  });
};

export const updatePromoCodes = (data) => {
  return request.put(`/api/promo/code/${data.id}`, data).then((res) => {
    return res.data;
  });
};

export const updateToggle = (data) => {
  return request.post("/api/promo/code/toggle", data).then((res) => {
    return res.data;
  });
};
export const deletePromoCodes = (data) => {
  return request.delete(`/api/promo/code/${data.id}`).then((res) => {
    return res.data;
  });
};

export const addPromoCodeManual = (data) => {
  return request.post("/api/promo/code/manual", data).then((res) => {
    return res.data;
  });
};
