<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Constant worrying can take a toll on your mental and physical well-being, but you don't have to let it control your life. This subliminal can help you break free from the cycle of worry and embrace a more peaceful mindset."
    },
    {
      type: 'heading',
      text: 'Some ways this subliminal can benefit you in achieving freedom from worrying include:'
    },
    {
      type: 'list',
      items: [
        "Reducing anxiety and stress levels, allowing you to experience more calmness and tranquility.",
        "Shifting your thought patterns to focus on positive outcomes and possibilities instead of worrying about the worst-case scenarios.",
        "Promoting a sense of self-assurance and trust in your ability to handle any challenges that come your way.",
        "Enhancing your ability to let go of worries and live in the present moment.",
        "Improving overall well-being by freeing up mental space for more constructive and positive thoughts."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal audio, you can reprogram your subconscious mind to let go of worries and embrace a more peaceful and relaxed state of being. As you continue to practice these positive mental habits, you may find that your overall sense of well-being improves, allowing you to live a more stress-free life."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Freeing yourself from worry and living a stress-free life is an empowering goal, and this subliminal can assist you on your journey. To optimize your experience, consider implementing these strategies before starting:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your worries: Take a moment to identify and acknowledge the specific worries that plague your mind.",
        "Release control: Recognize that worrying does not solve problems and let go of the need to control every outcome.",
        "Positive affirmations: Prepare positive statements to counteract negative thoughts and replace worry with calmness and positivity.",
        "Practice mindfulness: Engage in mindfulness techniques such as deep breathing or meditation to cultivate a calm and peaceful mindset."
      ]
    },
    {
      type: 'paragraph',
      text: "By engaging with this subliminal regularly, you can reprogram your mind and embrace a more serene and worry-free existence. As you listen, focus your attention on the positive affirmations and allow them to permeate your subconscious mind. Take a deep breath, release your worries, and embark on the path to freedom from worry and a tranquil state of being."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Freedom from Worry subliminal session! By focusing on freeing your mind from worries, you've taken an important step towards achieving a more peaceful and stress-free life. Now it's time to reflect on your experience and continue your journey towards inner calm and tranquility."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your worries: Take some time to write down your worries and try to identify patterns or triggers.",
        "Practice mindfulness: Engage in activities like meditation or deep breathing exercises to cultivate a calm and present mindset.",
        "Establish a worry-free zone: Create a designated space or time where you consciously choose to let go of worries and focus on relaxation and positivity.",
        "Connect with support: Seek the support of trusted friends, family, or professionals who can provide guidance and encouragement on your journey towards a worry-free life."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, freedom from worry is a gradual process, and it requires consistent effort and self-compassion. Embrace each step of your journey and celebrate the progress you make along the way. With dedication and the tools you've gained, you can create a life filled with peace and inner harmony. Best wishes on your worry-free adventure!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Freedom from Worry subliminal help me?`, 
    answer: `The Freedom from Worry subliminal is designed to help reprogram your mind and let go of worries. By listening to the subliminal messages, you can begin to adopt a calm and peaceful mindset, letting go of negative thoughts and embracing a more positive outlook on life. With consistent listening, you may notice a reduction in anxiety and stress, and an increased ability to handle life's challenges with greater ease.`,
    open: false
  },
  { 
    question: `How long do I need to listen to the Freedom from Worry subliminal to see results?`, 
    answer: `The time it takes to see results can vary depending on the individual and their unique circumstances. Some people may notice positive changes right away, while others may require more time and consistent listening to fully reprogram their mindset. We recommend listening to the subliminal daily and consistently for best results.`,
    open: false 
  },
  { 
    question: `Can the Freedom from Worry subliminal help with anxiety disorders?`, 
    answer: `The Freedom from Worry subliminal aims to promote a more positive and calm mindset, which can be beneficial for individuals experiencing mild to moderate anxiety. However, it is not a substitute for professional medical advice or treatment for severe anxiety or anxiety disorders. If you are experiencing severe symptoms or have been diagnosed with an anxiety disorder, we recommend consulting with a healthcare professional.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Freedom from Worry subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your mental health, please consult with a healthcare professional. It is important to note that the subliminal is intended to supplement healthy coping mechanisms and strategies, not replace them.`,
    open: false 
  },
  { 
    question: `Can the Freedom from Worry subliminal make me stop caring about important things in my life?`, 
    answer: `No, the goal of the Freedom from Worry subliminal is not to make you stop caring about important things in your life, but rather to help you develop a healthier relationship with worry and stress. It aims to empower you with the ability to handle challenges and uncertainties with a more calm and rational mindset. You can still care and be proactive, but without excessive worry and anxiety.`,
    open: false 
  },
  { 
    question: `Can the Freedom from Worry subliminal replace therapy or professional help?`, 
    answer: `No, the Freedom from Worry subliminal is not intended to replace therapy or professional help. It is designed to complement healthy coping mechanisms and strategies, and can be used as a supplemental tool to support your journey towards a calmer and more peaceful mindset. If you are currently receiving therapy or professional help, we encourage you to continue with your treatment plan.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>