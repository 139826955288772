<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Recovering from the wounds of general abuse can be a challenging journey, but this subliminal is here to support you every step of the way. By targeting your subconscious mind, it helps you heal, rebuild your sense of self-worth, and find inner peace."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can assist you:'
    },
    {
      type: 'list',
      items: [
        "Releasing the pain and trauma associated with general abuse.",
        "Rebuilding your self-esteem and confidence.",
        "Developing healthier coping mechanisms and strategies.",
        "Reconnecting with your inner strength and resilience.",
        "Cultivating forgiveness and letting go of resentment.",
        "Creating a sense of safety and trust within yourself.",
        "Promoting inner peace and emotional healing."
      ]
    },
    {
      type: 'paragraph',
      text: "It's important to remember that healing takes time and patience, but with consistent use of this subliminal, you can make significant progress. As you listen regularly, you may start to feel a sense of relief, empowerment, and gradual healing. You deserve to reclaim your life and find peace within yourself."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Healing from general abuse is a courageous and transformative journey towards reclaiming your self-worth and inner peace. This subliminal is designed to support you in that process. Before you begin, consider these suggestions to prepare yourself:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a safe space: Find a quiet and comfortable environment where you can listen to the subliminal uninterrupted.",
        "Set clear intentions: Reflect on your desire to heal, rebuild self-esteem, and cultivate inner peace.",
        "Practice self-care: Prioritize self-care activities such as journaling, meditation, or engaging in hobbies that bring joy and relaxation.",
        "Be patient and gentle with yourself: Healing takes time, so allow yourself the space to process your emotions and experiences as you listen."
      ]
    },
    {
      type: 'paragraph',
      text: "As you embark on this healing journey with the support of this subliminal, remember that you deserve love, healing, and restoration. Allow the affirmations to penetrate your subconscious mind and guide you towards a place of healing and empowerment. Embrace this opportunity to take care of yourself and begin your path to healing from general abuse."
    }
  ]
},
            {
  id: 'after-listening',
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the General Abuse Healing subliminal! Taking steps towards healing from the wounds of abuse is a courageous and empowering choice. Now that you've completed the session, it's important to reflect, nurture yourself, and continue your journey towards inner healing and restoration of self-worth."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice self-compassion: Be kind to yourself as you heal. Practice self-care and allow yourself to feel and process your emotions.",
        "Seek support: Reach out to trusted friends, family, or professionals who can offer guidance, understanding, and support.",
        "Engage in healing activities: Explore therapeutic practices like journaling, art therapy, or meditation to aid in your healing journey.",
        "Set boundaries: Establish healthy boundaries in your relationships and prioritize your emotional well-being.",
        "Focus on personal growth: Invest time and energy into self-development and personal growth to rebuild your sense of self-worth.",
        "Celebrate progress: Acknowledge and celebrate your growth and achievements, no matter how small they may seem."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, healing from general abuse takes time and patience. Be gentle with yourself and trust that you are on the path to reclaiming your inner peace and sense of self-worth. You are strong, resilient, and deserving of love and happiness."
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the General Abuse Healing subliminal help me in my healing journey?`, 
    answer: `The General Abuse Healing subliminal is designed to support your healing process by reprogramming your subconscious mind with positive affirmations and empowering messages. It can help you regain your sense of self-worth, inner peace, and overcome the negative effects of abuse. By listening to the subliminal regularly, you can nurture a more positive mindset and work towards healing and rebuilding your life.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the General Abuse Healing subliminal?`, 
    answer: `The healing process is different for everyone, and the time it takes to see results may vary. Some individuals may notice positive changes in their thoughts, emotions, and overall well-being relatively quickly, while for others, it may take more time and consistent listening to fully experience the effects of the subliminal. Patience and self-compassion are important on the healing journey.`,
    open: false 
  },
  { 
    question: `Can the General Abuse Healing subliminal replace therapy or professional help?`, 
    answer: `No, the General Abuse Healing subliminal is not a substitute for therapy or professional help. It is intended to be a supportive tool that complements your healing journey. If you are dealing with the effects of abuse, we strongly recommend seeking help from a qualified therapist or counselor who can provide guidance and personalized support.`,
    open: false 
  },
  { 
    question: `How often should I listen to the General Abuse Healing subliminal?`, 
    answer: `For best results, we recommend listening to the General Abuse Healing subliminal daily or as frequently as possible. Consistency is key in reprogramming your subconscious mind and reinforcing positive affirmations. Find a time and place where you can relax and focus on the subliminal without distractions.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the General Abuse Healing subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you are experiencing any distressing emotions or symptoms, it is important to seek professional help from a qualified therapist or counselor who can provide appropriate support and guidance tailored to your individual needs and circumstances.`,
    open: false 
  },
  { 
    question: `Can the General Abuse Healing subliminal erase all the pain and negative effects of abuse?`, 
    answer: `While the General Abuse Healing subliminal can be a helpful tool in your healing journey, it cannot completely erase the pain or negative effects of abuse. Healing is a complex and individual process that may involve various modalities and support systems. The subliminal aims to support and empower you on your journey towards healing and regaining your sense of self-worth and inner peace.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>