<template>
  <div
    style="overflow-x: hidden"
    class="d-flex align-center justify-left float-container"
  >
    <div class="image-container">
      <img
        class="one-image"
        src="../../../assets/images/main/home/section_2/1.svg"
      />
    </div>
    <div class="text-center">
      <div class="main-text mb-5">
        <p style="color: #4797de">Welcome to Magus</p>
        <p style="color: #4797de">Sign in to start listening.</p>
      </div>
      <div class="input-container">
        <v-text-field
          flat
          solo
          placeholder="E-mail Address"
          v-model="email"
        ></v-text-field>
        <v-divider
          style="background: black; margin-left: 10px; margin-right: 10px"
        ></v-divider>
        <v-text-field
          flat
          solo
          :type="showPassword ? 'text' : 'password'"
          placeholder="Password"
          v-model="password"
          @click:append="showPassword = !showPassword"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        ></v-text-field>
      </div>
      <div>
        <p style="color: #4797de">Forgot Password?</p>
      </div>
      <div class="sub-text mt-5">
        <v-btn
          medium
          style="
            background: white;
            color: #427ab3;
            border-radius: 5px;
            width: 10vw;
          "
          class="button"
          >SIGN IN</v-btn
        >
      </div>
      <div class="separator">
        <div class="line"></div>
        <p class="or">Or login with</p>
        <div class="line"></div>
      </div>
      <div class="button-container d-flex align-center justify-center">
        <div class="sub-text mt-5 mr-2">
          <v-btn
            @click="goToRoute('/register')"
            medium
            style="background: #4797de; color: white; border-radius: 10px"
            class="button"
            >Learn More</v-btn
          >
        </div>
        <div class="sub-text mt-5 ml-2">
          <v-btn
            medium
            style="background: #4797de; color: white; border-radius: 10px"
            class="button"
            >Learn More</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";

import { url, loginQueryParams, getMessage } from "@/utils";
import { userSignIn } from "@/api/user";

export default {
  components: {
    Card,
  },
  data: () => {
    return {
      url,
      getMessage,
      userSignIn,
      loginQueryParams,

      email: "",
      password: "",

      dialog: false,

      rememberMe: false,
      isEmailExist: false,
      showPassword: false,
    };
  },
  computed: {
    isUser() {
      return this.userType == "user";
    },
    userType() {
      return this.loginQueryParams();
    },
  },
  methods: {
    goToRoute(route) {
      if (this.currentRoute != route) {
        this.$router.push(route).catch((err) => {});
      }
    },
    closeDialog() {
      this.dialog = false;
    },
    login() {
      this.$store.dispatch("screen/getLoading", true);

      const params = {
        email: this.email,
        password: this.password,
        admin: !this.isUser,
      };

      this.userSignIn(params).then((res) => {
        const { success, data } = res;
        if (success) {
          let type = null;
          let path = null;

          const token = data.token;
          const info = JSON.stringify(data.user);

          if (this.userType == "user") {
            type = "user";
            path = "/user";
          }

          if (this.userType == "admin") {
            type = "admin";
            path = "/admin";
          }

          this.$store.dispatch(`${type}/getInfo`, info);
          this.$store.dispatch(`${type}/getToken`, token);
          this.$store.dispatch(`${type}/getLocalInfo`, info);
          this.$store.dispatch(`${type}/getLocalToken`, token);

          this.$router.push(path);
        } else {
          this.email = "";
          this.password = "";
        }
        this.$store.dispatch("screen/getLoading", false);
        this.getMessage(this, res);
      });
    },
  },
};
</script>

<style scoped>
.float-container {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 6vh;
}
.input-container {
  border: solid 0.5px;
  border-radius: 10px;
  padding: 10px;
}
.separator {
  display: flex;
  align-items: center;
}
.separator .line {
  flex: 1;
  height: 0.5px;
  background-color: #272727;
}
.or {
  margin: 10px;
}
.image-container {
  margin-left: 10%;
  width: 50%;
}
.one-image {
  width: 100%;
}
.text-center {
  width: 40%;
}
.main-text > p {
  font-family: "Montserrat-Bold";
  font-size: 3vw;
}

@media screen and (max-width: 1300px) {
  .float-container {
    height: 50vh;
    padding: 5vh;
  }
}
@media screen and (max-width: 800px) {
  .float-container {
    height: 35vh;
    padding: 3vh;
  }
}
@media screen and (max-width: 500px) {
  .float-container {
    height: 25vh;
    padding: 1.5vh;
  }
}
@media screen and (max-width: 350px) {
  .float-container {
    height: 15vh;
    padding: 1vh;
  }
}
</style>
