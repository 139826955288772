export const test = {
  namespaced: true,
  state: {
    data: null,
    lists: [],
  },
  getters: {},
  actions: {
    getData({ commit }, data) {
      commit("getData", data);
    },
    getLists({ commit }, data) {
      commit("getLists", data);
    },
  },
  mutations: {
    getData(state, data) {
      state.data = data;
    },
    getLists(state, data) {
      state.lists = data;
    },
  },
};
