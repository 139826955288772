<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're seeking to attract prosperity, abundance, and wealth into your life, this subliminal can assist you in achieving that. By working on your subconscious mind, it aims to shift your mindset and thoughts towards a more positive and prosperous outlook."
    },
    {
      type: 'heading',
      text: 'Here is how this subliminal can help you:'
    },
    {
      type: 'list',
      items: [
        "By reprogramming your subconscious beliefs and thoughts about money and success.",
        "By removing any limiting beliefs or negative blocks that may be hindering your prosperity.",
        "By attracting opportunities and abundance through a shift in your energy and vibrations.",
        "By increasing your confidence and self-belief in your ability to achieve financial success.",
        "By aligning your thoughts and actions with the mindset of prosperity and wealth."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, attracting prosperity is a journey that requires effort and consistency. Listening to this subliminal regularly can help you stay focused and maintain a positive outlook as you work towards your financial goals. As your mindset gradually shifts, you may find yourself attracting more opportunities and experiencing greater abundance in your life."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Welcome to the subliminal experience that can help you attract prosperity, abundance, and wealth into your life. Before you begin, take a moment to prepare yourself for this transformative journey with the following suggestions:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your desires: Reflect on what prosperity means to you and what specific goals you would like to achieve.",
        "Create a positive environment: Surround yourself with uplifting and motivating elements that support your mindset shift towards abundance.",
        "Set daily intentions: Start each day by affirming your commitment to attracting prosperity and aligning your thoughts and actions accordingly.",
        "Visualize success: Take a moment to imagine yourself enjoying the abundance and wealth you desire, allowing your subconscious mind to embrace this new reality."
      ]
    },
    {
      type: 'paragraph',
      text: "As you embark on this journey, trust in the power of your subconscious mind to manifest your desires. By consistently listening to this subliminal and adopting a positive and abundance-focused mindset, you can invite prosperity into your life. Get ready to unlock the possibilities of a prosperous future, and let the transformation begin!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Attract Prosperity subliminal session! By focusing on shifting your mindset and thoughts, you've taken a powerful step towards inviting abundance, prosperity, and wealth into your life. Now, it's time to reflect on your experience and continue your journey towards attracting prosperity."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice gratitude: Cultivate an attitude of gratitude by acknowledging the blessings in your life.",
        "Visualize your goals: Use the power of visualization to see yourself already living a prosperous life.",
        "Take inspired action: Identify opportunities and take practical steps towards your financial goals.",
        "Surround yourself with positivity: Surround yourself with people who inspire and support your prosperity mindset."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, attracting prosperity is an ongoing process that requires dedication and belief in your own potential. Stay committed to your goals, embrace a mindset of abundance, and take inspired action towards your dreams. With the right mindset and actions, you have the power to create a life of prosperity and abundance. Wishing you the best on your journey!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Attract Prosperity subliminal work?`, 
    answer: `The Attract Prosperity subliminal works by sending positive affirmations directly to your subconscious mind, helping to shift your mindset and thoughts towards abundance, prosperity, and wealth. The subliminal messages bypass your conscious awareness and go straight to your subconscious, helping to reprogram your beliefs and attract positive opportunities into your life.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Attract Prosperity subliminal?`, 
    answer: `Results can vary depending on the individual and their unique circumstances. Some people may experience positive changes right away, while others may require more time and consistent listening to fully shift their mindset towards attracting prosperity. We recommend listening to the subliminal daily and consistently for best results.`,
    open: false 
  },
  { 
    question: `Can the Attract Prosperity subliminal guarantee financial success?`, 
    answer: `The Attract Prosperity subliminal is designed to help you shift your mindset and thoughts towards abundance and wealth. While it can be a powerful tool in attracting positive opportunities and creating a mindset conducive to success, it does not guarantee financial success on its own. It is important to combine the subliminal with practical actions and strategies to increase your chances of achieving your financial goals.`,
    open: false 
  },
  { 
    question: `Can the Attract Prosperity subliminal work for everyone?`, 
    answer: `The Attract Prosperity subliminal is designed to work for anyone who is open to the idea of shifting their mindset towards abundance and wealth. However, individual results may vary depending on factors such as mindset, belief system, and personal circumstances. It is important to approach the subliminal with an open mind and take inspired action towards your goals.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Attract Prosperity subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, it is important to note that the subliminal is intended to supplement your efforts in attracting wealth and prosperity, not replace them. It is also important to approach the subliminal with realistic expectations and understand that it is not a magic solution.`,
    open: false 
  },
  { 
    question: `Can I listen to the Attract Prosperity subliminal while doing other tasks?`, 
    answer: `It is recommended to listen to the Attract Prosperity subliminal when you can fully focus on the audio and its affirmations. While you can listen to it while doing other tasks, giving it your full attention during the listening session can help enhance its effectiveness. Find a quiet and comfortable space where you can relax and tune into the subliminal messages.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>