<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Holding onto resentment and grudges can weigh you down and prevent you from experiencing inner peace. This subliminal is designed to help you release forgiveness and find freedom and healing in your heart."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help:'
    },
    {
      type: 'list',
      items: [
        "Guiding you towards letting go of past hurts and resentments.",
        "Empowering you with a sense of compassion, understanding, and empathy.",
        "Helping you heal emotional wounds and finding closure.",
        "Supporting you in breaking free from the cycle of anger and bitterness.",
        "Encouraging inner peace and fostering a positive outlook on life."
      ]
    },
    {
      type: 'paragraph',
      text: "Through regular listening, this subliminal can assist you in rewiring your subconscious mind to let go of grudges and embrace forgiveness. As you release the burden of resentment, you may experience a newfound sense of freedom, peace, and emotional well-being. Remember, forgiveness is a process, and with each session, you are taking a significant step towards healing."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Forgiveness can be a powerful tool for personal growth and healing. This subliminal is designed to support you in releasing the burden of resentment and finding inner peace through forgiveness. Before you begin, consider the following tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your pain: Take a moment to reflect on the hurt and pain caused by the situation or person you are seeking forgiveness for.",
        "Understand forgiveness: Educate yourself about the benefits of forgiveness and how it can positively impact your life.",
        "Shift your perspective: Try to empathize with the person who hurt you and understand their own struggles and limitations.",
        "Write a forgiveness letter: Consider writing a letter expressing your grievances and your decision to forgive. You may choose to send it or keep it for yourself.",
        "Set your intention: Before listening, take a moment to set your intention to release the burden of resentment and find inner peace through forgiveness."
      ]
    },
    {
      type: 'paragraph',
      text: "As you embark on this journey towards forgiveness, remember that it is a process that takes time and self-compassion. Embrace the opportunity to find freedom, healing, and a renewed sense of peace within yourself. Take a deep breath, set your intention, and let the transformative power of forgiveness unfold."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Release Forgiveness subliminal session! By embarking on this journey of forgiveness, you have taken a significant step towards finding inner peace and freedom. Now, it's time to reflect on your experience and continue to cultivate forgiveness in your life."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your emotions: Take some time to journal or meditate on your feelings and thoughts after the session.",
        "Practice empathy and understanding: Seek to understand the perspectives of those you may need to forgive.",
        "Release negative thoughts: Whenever negative emotions arise, consciously choose to let them go and focus on positive aspects of your life.",
        "Set boundaries: As you work towards forgiveness, it's important to establish healthy boundaries to protect yourself from further harm."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, forgiveness is a process, and it may take time to fully let go of resentment. Be patient with yourself and practice self-compassion along the way. By releasing the burden of grudges, you open yourself up to a world of healing and inner peace. Keep moving forward on this transformative journey. You've got this!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Release Forgiveness subliminal help me?`, 
    answer: `The Release Forgiveness subliminal is designed to help you let go of resentment and find inner peace through the power of forgiveness. By listening to the subliminal messages, you can reshape your subconscious mind to release grudges, heal emotional wounds, and experience greater freedom and healing. It can support you in cultivating empathy, understanding, and compassion towards yourself and others, ultimately leading to a more peaceful and fulfilling life.`,
    open: false
  },
  { 
    question: `How long will it take for me to experience the benefits of the Release Forgiveness subliminal?`, 
    answer: `The time it takes to experience the benefits of the Release Forgiveness subliminal can vary from person to person. It may depend on various factors such as the depth of the emotional wounds or the level of willingness to forgive. We recommend listening to the subliminal regularly and consistently for an extended period to allow the subconscious mind to integrate the positive affirmations and foster a mindset of forgiveness.`,
    open: false 
  },
  { 
    question: `Can the Release Forgiveness subliminal help with forgiving oneself?`, 
    answer: `Yes, the Release Forgiveness subliminal is designed to help with both forgiving others and forgiving oneself. It can support you in letting go of self-blame, guilt, and shame, and nurturing self-compassion and self-forgiveness. By rewiring your subconscious mind, the subliminal messages can assist you in embracing your imperfections, accepting past mistakes, and moving forward with greater self-love and understanding.`,
    open: false 
  },
  { 
    question: `Is it necessary to confront the person I need to forgive while listening to the Release Forgiveness subliminal?`, 
    answer: `No, it is not necessary to confront the person you need to forgive while listening to the Release Forgiveness subliminal. Forgiveness is an internal process, and the subliminal is designed to assist you in releasing resentment, healing, and finding inner peace within yourself. While you may choose to have a conversation or communicate your forgiveness with the person if it feels appropriate and safe, it is not a requirement for the subliminal to be effective.`,
    open: false 
  },
  { 
    question: `Can the Release Forgiveness subliminal replace therapy or counseling?`, 
    answer: `No, the Release Forgiveness subliminal is not intended to replace professional therapy or counseling. It can be used as a supplementary tool to support your healing and personal growth journey. If you have deep-seated emotional wounds or unresolved trauma, it is recommended to seek the guidance of a qualified mental health professional.`,
    open: false 
  },
  { 
    question: `Will the Release Forgiveness subliminal erase memories of past events or make me forget what happened?`, 
    answer: `No, the Release Forgiveness subliminal will not erase memories of past events or make you forget what happened. It is designed to help you cultivate forgiveness towards yourself and others while promoting healing and emotional well-being. The subliminal aims to shift your mindset and emotional response to past events, allowing you to find peace and liberation, but it does not alter your memory or the factual record of what occurred.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>