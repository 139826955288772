<template>
  <div :class="`card playlist ${isPlayerAvailable ? 'with-player' : ''}`">
    <div class="section">
      <div class="title">
        <div class="d-flex align-items-center gap-2">
          <img src="@/assets/images/user/footer/sound-wave.png" />
          <h6 class="mb-0">My Subs</h6>
        </div>
        <div class="d-flex align-items-center gap-2">
          <img
            v-if="isCurrentTabPlaylist"
            src="@/assets/images/user/playlist/add2.png"
            @click="dialog = true"
          />
        </div>
      </div>
      <div class="tabs">
        <button
          id="favoritetabs"
          :class="`btn btn-sm d-flex ${isTabFavorite}`"
          @click="getActiveTab('favorite')"
        >
          <img class="mr-1"
            :src="
              require(`@/assets/images/user/sidebar/heart_${
                tabs != 'favorite' ? 'black' : 'white'
              }.png`)
            "
          />
          Favorites
        </button>
        <button
          id="playlisttabs"
          :class="`btn btn-sm d-flex ${isTabPlaylist}`"
          @click="getActiveTab('playlist')"
        >
          <img class="mr-1"
            :src="
              require(`@/assets/images/user/${
                tabs != 'playlist' ? 'playlist/add2' : 'sidebar/add_white'
              }.png`)
            "
          />
          My Playlists
        </button>
      </div>
      <div v-if="tabs == 'favorite'" class="tabs">
        <button
          id="subliminalssubtabs"
          :class="`${isSubTabSubliminals}`"
          @click="getActiveSubTab('subliminals')"
        >
          Subliminals
        </button>

        <button
          id="playlistssubtabs"
          :class="`${isSubTabPlaylists}`"
          @click="getActiveSubTab('playlists')"
        >
          Playlists
        </button>
      </div>
      <div v-else class="tabs">
        <button
          id="magussubtabs"
          :class="`${isSubTabMagus}`"
          @click="getActiveSubTab('magus')"
        >
          By Magus
        </button>

        <button
          id="yousubtabs"
          :class="`${isSubTabYou}`"
          @click="getActiveSubTab('you')"
        >
          By You
        </button>
      </div>
      <div class="items">
        <div>
          <div
            class="card card-items p-2"
            v-for="(item, index) in data"
            :key="index"
            @click="
              item.hasOwnProperty('playlist_id')
                ? goToTrack(item)
                : getAudios(data, index)
            "
          >
            <div
              class="cover"
              :style="`background: url(${getBackground(item)});`"
            ></div>

            <div class="details mx-2">
              <h6 class="mb-0 text-limit-1">{{ item.title }}</h6>
              <p class="mb-0">{{ getDuration(item) }}</p>
              <span class="mb-0 text-limit-2">
                {{ item.description }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddOwnPlaylistTitleDialog
      :data="null"
      :dialog="dialog"
      @closeDialog="closeDialog"
    />
  </div>
</template>

<script>
import { getLikePlaylist, getLikeSubliminal } from "@/api/favorite";
import { getOwnPlaylist, getMagusPlaylist } from "@/api/ownplaylist";
import { convertToTime, convertToShortTime } from "@/utils";

import { mapGetters } from "vuex";
import { audio } from "@/mixins/audio";

import AddOwnPlaylistTitleDialog from "@/components/User/AddOwnPlaylistTitleDialog.vue";
export default {
  mixins: [audio],
  components: {
    AddOwnPlaylistTitleDialog,
  },
  data: () => {
    return {
      convertToTime,

      getLikeSubliminal,
      getLikePlaylist,

      getMagusPlaylist,
      getOwnPlaylist,

      favoriteId: [],
      favorites: [],

      playlistId: [],
      playlists: [],

      tabs: null,
      subTabs: null,

      dialog: false,

      isFavoriteChange: false,
      isPlaylistChange: false,
    };
  },
  mounted() {
    this.tabs = "favorite";
    this.subTabs = "subliminals";

    let self = this;
    setTimeout(() => {
      self.getFavorites();
      self.getPlaylists();
    }, 0);
  },
  computed: {
    ...mapGetters({
      isDataReady: "newaudio/getDataStatus",
      favoriteIDs: "favorite/getFavoriteIDs",
      favoriteInfoLists: "favorite/getFavoriteInfoLists",
      playlistIDs: "playlist/getPlaylistIDs",
      playlistInfoLists: "playlist/getPlaylistInfoLists",
    }),
    data() {
      if (this.tabs == "favorite") {
        const favorites = [...this.favorites].filter((item) => {
          return this.subTabs == "subliminals"
            ? item.hasOwnProperty("subliminal_id")
            : item.hasOwnProperty("playlist_id");
        });

        return favorites;
      }

      const playlists = [...this.playlists].filter((item) => {
        return this.subTabs == "magus"
          ? item.is_own_playlist == 0
          : item.is_own_playlist == 1;
      });

      return playlists;
    },
    user() {
      return this.$store.state.user.info;
    },
    isPlayerAvailable() {
      return this.playlistTracks.length > 0
        ? this.playlistTracks.length > 0
        : this.recentplayed.length > 0;
    },
    isCurrentTabPlaylist() {
      return this.tabs == "playlist";
    },
    isTabPlaylist() {
      return this.tabs == "playlist" ? "btn-primary" : "btn-light";
    },
    isTabFavorite() {
      return this.tabs == "favorite" ? "btn-primary" : "btn-light";
    },
    isSubTabSubliminals() {
      return this.subTabs == "subliminals" ? "selected" : "unselected";
    },
    isSubTabPlaylists() {
      return this.subTabs == "playlists" ? "selected" : "unselected";
    },
    isSubTabMagus() {
      return this.subTabs == "magus" ? "selected" : "unselected";
    },
    isSubTabYou() {
      return this.subTabs == "you" ? "selected" : "unselected";
    },
    playlistType() {
      return this.$store.state.audio.playlist.type;
    },
    playlistTrack() {
      return this.$store.state.audio.playlist.tracks;
    },
    isRouteTrack() {
      return this.$route.path == "/user/track";
    },
  },
  watch: {
    favoriteInfoLists(val) {
      this.favorites = val;
    },
    playlistInfoLists(val) {
      this.playlists = val;
    },
  },
  methods: {
    getActiveSubTab(tab) {
      this.subTabs = tab;
    },
    getActiveTab(tab) {
      this.tabs = tab;
      if (tab == "playlist") {
        this.subTabs = "magus";
      } else {
        this.subTabs = "subliminals";
      }
    },
    getFavorites() {
      let user = JSON.parse(this.user);

      const data = {
        user_id: user.user_id,
        subscription_id: user.info.subscription_id,
      };

      this.getLikeSubliminal(data).then((res) => {
        res.forEach((items) => {
          if (!this.favoriteIDs.includes(items.subliminal_id)) {
            this.favoriteId.push(items.subliminal_id);
            this.favorites.push(items);
          }
        });
      });

      this.getLikePlaylist(data).then((res) => {
        res.forEach((items) => {
          if (
            items.is_own_playlist == 1 &&
            !this.favoriteIDs.includes(items.playlist_id)
          ) {
            this.favoriteId.push(items.playlist_id);
            this.favorites.push(items);
          }

          if (
            items.is_own_playlist == 0 &&
            items.subliminals.length > 0 &&
            !this.favoriteIDs.includes(items.playlist_id)
          ) {
            this.favoriteId.push(items.playlist_id);
            this.favorites.push(items);
          }
        });
      });

      if (!this.favoriteIDs.includes(this.favoriteIDs)) {
        this.$store.dispatch("favorite/favoriteIDs", this.favoriteId);
        this.$store.dispatch("favorite/favoriteInfoLists", this.favorites);
      }
    },
    getPlaylists() {
      let user = JSON.parse(this.user);

      const data = {
        user_id: user.user_id,
        subscription_id: user.info.subscription_id,
      };

      this.getMagusPlaylist(data).then((res) => {
        res.forEach((items) => {
          if (!this.playlistIDs.includes(items.playlist_id)) {
            this.playlistId.push(items.playlist_id);
            this.playlists.push(items);
          }
        });
      });

      this.getOwnPlaylist(data).then((res) => {
        res.forEach((items) => {
          if (!this.playlistIDs.includes(items.playlist_id)) {
            this.playlistId.push(items.playlist_id);
            this.playlists.push(items);
          }
        });
      });

      if (!this.playlistIDs.includes(this.playlistId)) {
        this.$store.dispatch("playlist/playlistIDs", this.playlistId);
        this.$store.dispatch("playlist/playlistInfoLists", this.playlists);
      }
    },
    getBackground(params) {
      if (params.hasOwnProperty("subliminals")) {
        const count = params.subliminals.length;
        if (count > 0) {
          let index = Math.floor(Math.random() * count);
          return params.subliminals[index].cover;
        }
      }

      return params.cover;
    },
    getDuration(params) {
      if(params.duration != undefined){
        return convertToTime(params.duration/1000)
      }
    },
    goToTrack(data) {
      let track = data;
      /*const localTrack = JSON.parse(localStorage.getItem("user_track"));
      if (localTrack != null) {
        if (data.playlist_id == localTrack.playlist_id) {
          track = localTrack;
        }
      }*/

      this.$store.dispatch("user/getTrack", track);
      localStorage.setItem("user_track", JSON.stringify(track));
      if (!this.isRouteTrack) {
        this.$router.push("/user/track");
      }
    },
    getAudios(data, index) {
      if (this.playlistType != "recent") {
        this.$store.dispatch("audio/getPlaylistType", "discover");
      }

      if (JSON.stringify(this.playlistTrack) != JSON.stringify(data)) {
        this.$store.dispatch("audio/getPlaylistTrack", data);
      }

      this.$store.dispatch("newaudio/currentTime", 0);
      this.$store.dispatch("newaudio/subliminalIndex", index);
      this.initializeData(data, index, true);

      this.$store.dispatch("newaudio/playedStatus", true);
    },
    closeDialog(val) {
      this.dialog = val;
    },
  },
};
</script>

<style scoped>
.playlist {
  padding: 10px 10px 0px 10px;
  height: calc(100vh - 170px);
  border-width: 0px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
}

.playlist.with-player {
  height: calc(100vh - 265px);
}

.section {
  height: calc(100% - 150px);
}

.title,
.tabs {
  height: 40px;
  width: 100%;
  margin-bottom: 10px;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.tabs {
  display: flex;
  align-items: center;
  gap: 10px;
}

.items {
  overflow-y: auto;
  width: 100%;
  max-height: 100%;
}

.items > div {
  overflow-y: auto;
  height: 100%;
  padding-inline: 0.5px;
}

.menu-item {
  display: flex;
  gap: 5px;
  align-items: center;

  height: 50px;
  width: 100%;
}

.tabs img,
.title img,
.menu-item > img {
  height: 18px;
}

.tabs button {
  width: 100%;
}

.menu-item.active > h6 {
  font-size: 1.25rem;
}

.menu-item.active > img {
  height: 28px;
}

.card-items {
  display: flex;
  flex-direction: row;
  align-content: center;

  height: 80px;
  width: 100%;
  margin-bottom: 10px;
}

.cover {
  min-width: 90px;
  height: 64px;

  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

p {
  font-size: 10px;
}

span {
  font-size: 8px;
  font-family: "Montserrat-Regular";
}

.text-limit-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.text-limit-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.selected {
  color: #1c8edf;
  border-bottom-color: #0275d8;
  border-bottom-width: 2px;
}
.selected,
.unselected {
  font-size: 12px;
  font-family: "Montserrat-Bold";
  height: 25px;
  border-bottom-style: solid;
  border-radius: 0px;
  margin-top: -10px;
}
.unselected {
  border-bottom-width: 1px;
  border-bottom-color: #d9d9d9;
  color: #404040;
}
</style>
