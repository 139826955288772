<template>
  <div>
    <Dialog :dialog="dialog" :width="800" :title="`Add Track`" @closeDialog="closeDialog">
      <div>
        <v-row class="ma-0" style="gap: 20px">
        <v-col class="pa-0">
        <p class="mb-1 mt-3">Select File</p>
        <el-upload ref="files" multiple class="upload-demo" :action="`${url}/api/local/audio`" :limit="3"
          :file-list="filelist" :on-remove="handleRemove" :before-remove="beforeRemove" :on-change="handleChange"
          :on-success="handleSuccess" :on-progress="handleProgress" :on-exceed="handleExceed">
          <v-btn block style="height: 3rem;">Upload</v-btn>
        </el-upload>
        </v-col>
        <v-col>

        <p class="mb-1">Subscription Name</p>
          <v-autocomplete v-model="subscription" :items="subscriptions" block solo multiple small-chips clearable
            color="blue-grey lighten-2" label="Subscriptions" hide-details class="mb-0" item-text="name"
            item-value="id"/>
        </v-col>
        </v-row>

        <v-divider class="mt-8 mb-0"></v-divider>
        <v-data-table class="table-component" :items="tablefiles" :headers="headers" hide-default-footer item-key="name"
          mobile-breakpoint="0">
          <template v-slot:item.name="{ item }">
            <div style="min-width: 100px">
              <p class="mb-0">{{ item.name }}</p>
            </div>
          </template>
          <template v-slot:item.audiotype="{ index }">
            <div style="min-width: 130px">
              <v-autocomplete v-model="audiotype[index]" :items="audiotypes" solo dense class="py-3"
                color="blue-grey lighten-2" placeholder="Audio Type" hide-details item-text="name" item-value="id" />
            </div>
          </template>
        </v-data-table>
      </div>

      <template v-slot:action>
        <v-row class="ma-0">
          <v-col class="pl-0 pr-2">
            <v-btn block @click="submitDialog()" :disabled="!addEnabled">Add</v-btn>
          </v-col>
          <v-col class="pl-2 pr-0">
            <v-btn block @click="closeDialog()">Cancel</v-btn>
          </v-col>
        </v-row>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/Dialog.vue'

import { addTracks, checkTrack } from '@/api/track.js'
import { getAudioTypes } from '@/api/audiotype.js'
import { deleteLocalAudio, deleteAllLocalFiles } from '@/api/file.js'

import { url, getDuration, getMessage } from '@/utils'

export default {
  props: {
    dialog: {
      type: Boolean
    }
  },
  components: {
    Dialog
  },
  data: () => {
    return {
      url,
      getMessage,

      addTracks,
      checkTrack,
      getDuration,
      getAudioTypes,
      deleteLocalAudio,
      deleteAllLocalFiles,
      index: 0,
      count: 1,
      success: false,

      filelist: [],
      tablefiles: [],
      subscription: [],

      audiotype: [],
      durations: [],

      headers: [
        {
          text: 'Title',
          value: 'name',
          sortable: false
        },
        {
          text: 'Audio Type',
          value: 'audiotype',
          sortable: false
        }
      ]
    }
  },
  computed: {
    addEnabled() {
      return (
        this.tablefiles.length == this.audiotype.length &&
        this.tablefiles.length > 0 &&
        this.audiotype.length > 0 && this.success == true
      )
    },
    subscriptions() {
      return this.$store.state.subscription.data;
    },
    audiotypes() {
      return this.$store.state.audio.types
    },
    uploadList() {
      return this.$store.state.audio.upload.data
    }
  },
  methods: {
    beforeRemove(file, fileList) {
      const index = this.getFileIndex(file.name)
      this.tablefiles.splice(index, 1)
      this.audiotype.splice(index, 1)
      this.durations.splice(index, 1)
    },
    handleRemove(file, fileList) {
      const index = fileList.findIndex(element => element.name === file.name)
      this.deleteLocalAudio(file.name)
      fileList.splice(index, 1)
      this.tablefiles.splice(index, 1)
      this.audiotype.splice(index, 1)
      this.durations.splice(index, 1)
    },
    handleSuccess(file, fileList) {
      this.success = true
      const title = fileList.name.split('.').slice(0, -1).join('.')
      const params = { title: title }

      this.checkTrack(params).then(res => {
        if (!res.success) {
          this.handleRemove(fileList, this.uploadList)
          this.getMessage(this, res)
        }
      })
    },
    handleAllRemove() {
      this.deleteAllLocalFiles()
    },
    handleChange(files, fileList) {
      if (this.tablefiles.indexOf(files) == -1) {
        this.tablefiles.push(files)
        this.getDuration(files, this.durations)
      }

      this.$store.dispatch('audio/getUploadList', fileList)
    },
    handleProgress(files, fileList) {
      this.success = false
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `The limit is 1, you selected 
        ${files.length} files this time,
        add up to ${files.length + fileList.length} 
        totally`
      )
    },
    closeDialog() {
      this.$emit('closeDialog', false)
      this.resetDialog()
      this.handleAllRemove()
    },
    closeAlert() {
      this.alert = false
    },
    resetDialog() {
      this.filelist = []
      this.subscription == [] 
      this.tablefiles = []
    },
    getFileIndex(filename) {
      return this.tablefiles.findIndex((item) => {
        return item.name === filename
      })
    },
    getAudioType() {
      this.getAudioTypes().then((data) => {
        this.audiotypes = data
      })
    },
    submitDialog() {
      if(this.count == 1){
        const files = this.tablefiles
        for (let index = 0; index < files.length; index++) {
          const element = files[index]
          const audiotype = this.audiotype[index]
          const duration = this.durations[index]

          const data = {
            file: element.name,
            description: '',
            audio_type_id: audiotype,
            duration: duration,
            subscription_id: this.subscription.join(',')

          }

          this.addTracks(data).then((res) => {
            const tracks = res.data

            this.getMessage(this, res)
            this.closeDialog()

            this.$store.dispatch("track/getData", tracks)
            this.count=0;
          })
        }
      }
    }
  }
}
</script>

<style>
.upload-demo>div {
  width: 100%;
}

.upload-demo>ul {
  padding: 0px;
}
</style>
