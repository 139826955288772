<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Dealing with a fear of clowns or clown phobia can be challenging, but it is possible to overcome these fears and regain control. This subliminal is specifically designed to help you do just that."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help you:"
    },
    {
      type: 'list',
      items: [
        "Gradually desensitizing your subconscious mind to the fear of clowns.",
        "Replacing negative associations and thoughts about clowns with positive ones.",
        "Reducing anxiety, panic, and discomfort related to clowns or clown-related imagery.",
        "Building confidence and creating a sense of safety when encountering clowns or clown-related situations.",
        "Providing a tool for long-lasting change and freedom from your fear of clowns."
      ]
    },
    {
      type: 'paragraph',
      text: "You can use this subliminal to reprogram your mind, allowing you to approach clowns or clown-related situations with a newfound sense of calm and control. Over time, you may find that your fear diminishes and that you can enjoy a clown-free life. Remember, consistent use and exposure to clowns are important in overcoming your fear."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Having a fear of clowns or a clown phobia can be a challenging experience, but this subliminal is here to help you overcome these fears and finally find relief. Before you start listening, here are some tips to keep in mind:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your fear: Recognize and accept that you have a fear of clowns or a clown phobia. It's a natural reaction, and you're not alone.",
        "Set positive intentions: Focus on wanting to release the fear and feeling confident in your ability to do so.",
        "Practice deep breathing: Take slow, deep breaths to calm your body and mind before starting the subliminal.",
        "Create a safe environment: Ensure you're in a comfortable and secure space where you can relax without distractions."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal is designed to address the root cause of your fear and help you overcome it. With regular listening, you may start to experience reduced anxiety and a newfound sense of calm around clowns. Take a moment to prepare yourself, set your intentions, and get ready to let go of your fear. You're on your way to reclaiming your peace of mind."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Fear of Clowns and Clown Phobia Removal session! By working towards overcoming your fear, you've taken a brave step towards reclaiming your peace of mind. Now that you've listened to the subliminal, it's important to reflect on your experience and continue building your confidence."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your progress: Take a moment to recognize the steps you've taken towards conquering your fear.",
        "Expose yourself gradually: Gradually confront situations or images related to clowns to desensitize yourself.",
        "Practice relaxation techniques: Deep breathing, meditation, and visualization can help you stay calm during anxiety-provoking situations.",
        "Seek professional help if needed: If your fear of clowns persists or significantly impacts your daily life, consider reaching out to a therapist or psychologist for guidance."
      ]
    },
    {
      type: 'paragraph',
      text: "Overcoming fear takes time and patience, but you have already shown great strength by taking this important step. Stay committed to your journey of conquering your fear of clowns, and remember that you have the power to overcome anything that stands in your way. You've got this!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Fear of Clowns and Clown Phobia Removal subliminal work?`, 
    answer: `The Fear of Clowns and Clown Phobia Removal subliminal uses carefully crafted subliminal messages to reprogram your subconscious mind and help you overcome your fear of clowns. By listening to the audio regularly, your subconscious mind will gradually adopt new beliefs, associations, and reactions towards clowns, allowing you to let go of your fear and phobia for good.`,
    open: false
  },
  { 
    question: `How long does it take to overcome a fear of clowns using the subliminal?`, 
    answer: `The time it takes to overcome a fear of clowns using the subliminal can vary from person to person. It depends on factors such as the intensity of your fear, your mindset, and how regularly you listen to the audio. Consistently listening to the subliminal over a period of time can help speed up the process and reinforce positive changes in your subconscious mind.`,
    open: false 
  },
  { 
    question: `Can the Fear of Clowns and Clown Phobia Removal subliminal be used for other phobias?`, 
    answer: `While the Fear of Clowns and Clown Phobia Removal subliminal is specifically designed to target clown phobia, it may help with similar phobias or fears as well. The subliminal messages are crafted to help you reprogram your subconscious mind's response to the trigger associated with clowns. However, if you have other specific phobias, it may be beneficial to explore a subliminal audio more tailored to that particular fear.`,
    open: false 
  },
  { 
    question: `Is it normal to feel uncomfortable or anxious when first listening to the subliminal?`, 
    answer: `Yes, it is normal to feel some discomfort or anxiety initially when listening to the Fear of Clowns and Clown Phobia Removal subliminal, especially if you have a strong phobia. This initial reaction can be a sign that the subliminal messages are starting to challenge and rewire your existing beliefs about clowns. However, if the discomfort becomes overwhelming or distressing, it may be best to discontinue use and seek professional guidance.`,
    open: false 
  },
  { 
    question: `Can I still be cautious around clowns even after using the subliminal?`, 
    answer: `Yes, it is natural to feel cautious around clowns even after using the Fear of Clowns and Clown Phobia Removal subliminal. Overcoming a fear or phobia does not mean completely eliminating all caution, as it is important to prioritize your comfort and safety. The subliminal aims to help you reduce fear and anxiety associated with clowns, but it is still advisable to respect your boundaries and take any necessary precautions.`,
    open: false 
  },
  { 
    question: `Are there any side effects of listening to the Fear of Clowns and Clown Phobia Removal subliminal?`, 
    answer: `No, there are no known side effects associated with listening to the Fear of Clowns and Clown Phobia Removal subliminal. However, if you experience any discomfort or distress, it is important to discontinue use and seek professional guidance. This subliminal audio is intended to be a tool to support individuals in overcoming their fear of clowns, but individual results may vary.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>