<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you've been struggling with fear-based anxiety and are ready to experience true healing and emotional stability, this subliminal can be a powerful tool on your journey."
    },
    {
      type: 'heading',
      text: 'Here are some key ways this subliminal can help you:'
    },
    {
      type: 'list',
      items: [
        "Removing fear-based anxiety and replacing it with a sense of calm and peace.",
        "Healing past traumas or triggers that have contributed to your anxiety.",
        "Enhancing your ability to manage stress and handle difficult situations with resilience.",
        "Building self-confidence and a belief in your own inner strength and abilities.",
        "Promoting a sense of emotional stability and balance in your life."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind to release fear-based anxiety and embrace a more positive and empowering mindset. Over time, you may find yourself feeling more grounded, resilient, and free from the grip of anxiety. Remember, healing is a journey, but with commitment and self-care, you can overcome your anxiety and live a more fulfilling life."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Dealing with fear-based anxiety can be incredibly challenging, but this subliminal is designed to support your journey towards healing and emotional stability. Before you start listening, consider these tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Find a quiet and comfortable space: Choose a place where you can relax and focus on the subliminal messages without distractions.",
        "Set your intention: Clearly define your desire to remove fear-based anxiety and experience true healing and emotional stability.",
        "Breathe deeply: Take a few moments to practice deep breathing and allow yourself to let go of tension and anxiety.",
        "Choose an open mindset: Be open to receiving the subliminal affirmations and allow them to work on a subconscious level."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you may start to notice a reduction in fear-based anxiety and an increase in emotional stability. Embrace this opportunity to heal and let go of the negative emotions that have been holding you back. Take a deep breath, set your intention, and let's begin the journey towards true healing and emotional stability."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Remove and Heal Fear Based Anxiety subliminal session! By dedicating time and effort to remove fear-based anxiety from your life, you've taken an important step towards true healing and emotional stability. It's now time to reflect on your experience and continue on your journey of personal growth."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Journal your thoughts and emotions: Writing down your feelings can help you gain valuable insights and release emotions.",
        "Practice self-care: Engage in activities that bring you joy and relaxation to nurture your emotional well-being.",
        "Seek support if needed: Consider reaching out to a therapist or counselor to help you navigate your healing process.",
        "Embrace self-compassion: Be kind and gentle with yourself during this healing journey. Celebrate your progress, no matter how small."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember that healing from fear-based anxiety is a process, and it may require time and patience. Trust in your ability to overcome and grow, and know that you are not alone. With continued effort and self-compassion, you can experience true healing and emotional stability. Keep moving forward and embrace the journey!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Remove and Heal Fear Based Anxiety subliminal work?`, 
    answer: `The Remove and Heal Fear Based Anxiety subliminal works by targeting your subconscious mind with positive affirmations and suggestions to reframe your thoughts, release fearful beliefs, and promote a sense of emotional stability and healing. By consistently listening to the subliminal, you can gradually reprogram your mind to let go of fear-based anxiety and experience a greater sense of calm and peace.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Remove and Heal Fear Based Anxiety subliminal?`, 
    answer: `The time it takes to see results may vary depending on the individual and the severity of their fear-based anxiety. Some people may notice positive changes in their mindset and emotional well-being quite quickly, while others may require more time. Consistent listening to the subliminal is important for best results.`,
    open: false 
  },
  { 
    question: `Can the Remove and Heal Fear Based Anxiety subliminal be used alongside therapy or counseling?`, 
    answer: `Yes, the Remove and Heal Fear Based Anxiety subliminal can be used alongside therapy or counseling. It can complement the work you are doing with a mental health professional by reinforcing positive affirmations and supporting your healing journey. However, it is important to consult with your therapist or counselor before incorporating any new tools or techniques into your treatment plan.`,
    open: false 
  },
  { 
    question: `Can the subliminal completely eliminate fear-based anxiety?`, 
    answer: `The subliminal is designed to help you release fear-based anxiety and experience healing and emotional stability. Results may vary, and it is important to be patient with yourself as you go through your healing process. Remember that self-care practices, therapy, and other supportive resources can also play a role in managing and overcoming fear-based anxiety.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the Remove and Heal Fear Based Anxiety subliminal?`, 
    answer: `There are no known risks or side effects associated with listening to the Remove and Heal Fear Based Anxiety subliminal. However, if you have any concerns about your mental health or anxiety, it is important to consult with a qualified health professional for appropriate guidance and support. The subliminal is intended to be a supplement to a holistic approach to mental well-being.`,
    open: false 
  },
  { 
    question: `What other techniques or practices can enhance the effectiveness of the Remove and Heal Fear Based Anxiety subliminal?`, 
    answer: `In addition to listening to the subliminal, you may find it beneficial to practice mindfulness, deep breathing exercises, journaling, or other stress-reducing techniques. Engaging in regular physical exercise, maintaining a balanced diet, and getting enough sleep can also contribute to overall emotional well-being. Exploring therapy or counseling options can provide additional tools and support for healing from fear-based anxiety.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>