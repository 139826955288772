<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you want to take your exercise and workout results to the next level, this subliminal can help you achieve your fitness goals. By targeting your subconscious mind, it amplifies and enhances the effects of your physical training."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit your fitness journey:'
    },
    {
      type: 'list',
      items: [
        "Increasing motivation and drive to push harder and challenge yourself during workouts.",
        "Boosting physical endurance and stamina, allowing you to engage in more intense training sessions.",
        "Enhancing muscle growth and strength development for a fitter and stronger body.",
        "Improving exercise performance and technique to maximize the effectiveness of your workouts.",
        "Accelerating post-workout recovery, reducing muscle soreness, and promoting faster muscle repair."
      ]
    },
    {
      type: 'paragraph',
      text: "By consistently listening to this subliminal alongside your exercise routine, you can supercharge your progress and experience greater results. Remember to stay committed, push your limits, and embrace the positive changes happening in your body. Your fitness journey is about to reach new heights!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Before starting your exercise and workout routine, take a moment to prepare your mind for maximum results. This subliminal is designed to help you amplify and enhance the effects of your physical efforts. Consider these tips before you begin:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Define what you want to achieve through your workouts, whether it’s building strength, increasing endurance, or improving overall fitness.",
        "Visualize success: Imagine yourself performing exercises with ease and achieving your desired results.",
        "Warm up properly: Engage in a dynamic warm-up routine to get your muscles ready and prevent injuries.",
        "Stay focused: Maintain a strong mental connection to your goals and intentions throughout your workout, allowing the subliminal affirmations to align with your efforts."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to support and enhance your workout results. As you listen regularly, you may find yourself more motivated, energized, and focused during your exercise routine. Embrace the power of your mind, push yourself, and let's make your fitness goals a reality!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Exercise and Workout Results Enhancer subliminal session! By focusing on enhancing the results of your exercise and workout routines, you've taken a significant step towards achieving a fitter and stronger body. Now, it's time to reflect on your experience and take the necessary steps to continue maximizing your fitness potential."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Track your progress: Keep a workout journal to record your exercises, sets, reps, and weights used.",
        "Set realistic goals: Establish achievable targets that align with your fitness aspirations.",
        "Mix up your routine: Incorporate variety in your workouts to challenge your body and prevent plateaus.",
        "Fuel your body: Ensure you're consuming a balanced diet with adequate protein and nutrients to support your fitness goals."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, enhancing workout results requires consistency, dedication, and a commitment to pushing yourself. Stay motivated, track your progress, and make adjustments to your routine when needed. With each workout, you're one step closer to achieving the fit and strong body you desire. Keep up the fantastic work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Exercise and Workout Results Enhancer subliminal enhance the results of my exercise routine?`, 
    answer: `The Exercise and Workout Results Enhancer subliminal is designed to target your subconscious mind, helping to increase your motivation, focus, and determination during workouts. By aligning your thoughts and beliefs with the goal of achieving optimal exercise results, the subliminal can enhance your overall performance and help you see better results from your exercise routine.`,
    open: false
  },
  { 
    question: `How long does it take to see enhanced results from the Exercise and Workout Results Enhancer subliminal?`, 
    answer: `The time it takes to see enhanced results will vary from person to person. Consistent listening to the subliminal audio, combined with a well-rounded exercise routine, is key to achieving optimal results. Some individuals may start noticing improvements sooner, while others may require more time to see significant changes. Patience and continued dedication to your workouts and listening to the subliminal are important for long-term success.`,
    open: false 
  },
  { 
    question: `Can the Exercise and Workout Results Enhancer subliminal replace exercise and physical activity?`, 
    answer: `No, the Exercise and Workout Results Enhancer subliminal is not intended to replace exercise or physical activity. It is designed to enhance the results of your workouts by aligning your subconscious mind with your fitness goals. Combining the subliminal with a consistent exercise routine and healthy lifestyle choices will yield the best results.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Exercise and Workout Results Enhancer subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Exercise and Workout Results Enhancer subliminal. However, it's important to listen to your body and exercise within your limits. If you have any concerns about your exercise routine or health, consult with a healthcare or fitness professional.`,
    open: false 
  },
  { 
    question: `Can the Exercise and Workout Results Enhancer subliminal help with weight loss?`, 
    answer: `The Exercise and Workout Results Enhancer subliminal can help enhance your exercise routine and improve your overall fitness levels. While weight loss may be a potential outcome of regular exercise, it is important to adopt a balanced and sustainable approach to your diet and lifestyle for effective and long-term weight management.`,
    open: false 
  },
  { 
    question: `How often should I listen to the Exercise and Workout Results Enhancer subliminal?`, 
    answer: `For optimal results, it is recommended to listen to the Exercise and Workout Results Enhancer subliminal regularly, preferably on a daily basis. Consistency is key to aligning your subconscious mind with your fitness goals and maximizing the benefits of the subliminal audio.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>