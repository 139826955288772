<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Height can be a sensitive topic for many people, and it's common to have insecurities or doubts about it. However, it's important to embrace and accept your height, as it is a part of who you are. This subliminal is designed to help you build confidence and learn to love and accept yourself, regardless of your height."
    },
    {
      type: 'heading',
      text: 'Here are some ways in which this subliminal can support you:'
    },
    {
      type: 'list',
      items: [
        "Boosting your self-esteem by reframing your mindset and thoughts about your height.",
        "Letting go of any insecurities or negative beliefs related to your height.",
        "Developing a positive body image and accepting yourself as you are.",
        "Gaining confidence in social situations and overcoming any height-related anxieties.",
        "Cultivating a sense of pride and appreciation for your unique height."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, confidence comes from within, and this subliminal can help you tap into your inner strength and acceptance. With regular use, you may start to feel more comfortable in your own skin and showcase your confidence to the world. Embrace your height and embrace yourself!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Building confidence and accepting your height is an empowering journey. This subliminal is designed to help you embrace your height, let go of any insecurities or doubts, and cultivate a deep sense of self-acceptance. Before you begin, here are some tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your self-perception: Take a moment to understand how your height affects your self-image and identify areas where you would like to build confidence.",
        "Practice self-compassion: Be gentle with yourself and challenge any negative self-talk or limiting beliefs surrounding your height.",
        "Celebrate your strengths: Shift your focus towards your positive qualities, talents, and achievements, recognizing that height does not define your worth.",
        "Embrace visualization techniques: Visualize yourself standing tall with confidence and radiating self-acceptance. See yourself embodying the qualities you desire."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to the subliminal affirmations, allow them to work on a subconscious level, gradually transforming your perception of yourself and boosting your self-confidence. Embrace this opportunity for growth, acceptance, and embracing your unique height. Let's embark on this journey together and start building unshakeable confidence."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Height Acceptance and Confidence subliminal session! By embarking on this journey of self-acceptance, you've taken a significant step towards building confidence and embracing your height. Now it's time to reflect on your experience and continue nurturing a positive relationship with your body and self-image."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice self-affirmations: Repeat positive statements about your height and appearance to reinforce self-acceptance.",
        "Challenge negative thoughts: Identify and challenge any negative beliefs or insecurities related to your height.",
        "Surround yourself with positivity: Seek out supportive communities or individuals who uplift and celebrate individuality.",
        "Focus on personal growth: Shift your focus towards developing skills and attributes that make you feel confident."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, height does not define your worth or your abilities. Embrace and celebrate your unique features, for they are what make you special. Build a strong foundation of self-acceptance and confidence, and let go of any insecurities or doubts. You are beautiful just as you are!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Height Acceptance and Confidence subliminal help me?`, 
    answer: `The Height Acceptance and Confidence subliminal is designed to help you embrace your height and build confidence in yourself. By listening to the subliminal messages, you can reprogram your subconscious mind to let go of any insecurities or doubts you may have about your height. It can also help you develop a positive self-image and learn to love and accept your height unconditionally.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Height Acceptance and Confidence subliminal?`, 
    answer: `Results may vary from person to person. Some individuals may notice positive changes in their confidence and self-acceptance right away, while others may require more time and consistent listening for the affirmations to take effect. We recommend listening to the subliminal daily and consistently to achieve the best results.`,
    open: false 
  },
  { 
    question: `Can the Height Acceptance and Confidence subliminal make me taller?`, 
    answer: `No, the Height Acceptance and Confidence subliminal is not intended to physically change your height. It is designed to help you develop confidence and acceptance of your existing height. It can empower you to embrace your height and let go of any insecurities or doubts you may have about it.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the Height Acceptance and Confidence subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your height or self-confidence, it is always recommended to seek support from a mental health professional or counselor who can provide personalized guidance.`,
    open: false 
  },
  { 
    question: `Can the Height Acceptance and Confidence subliminal replace therapy or counseling?`, 
    answer: `No, the subliminal is not a substitute for professional therapy or counseling. While it can be a helpful tool for building confidence and self-acceptance, it is important to address any deeper emotional issues or concerns with the guidance of a qualified mental health professional.`,
    open: false 
  },
  { 
    question: `How can I maximize the effectiveness of the Height Acceptance and Confidence subliminal?`, 
    answer: `To maximize the effectiveness of the subliminal, it is recommended to listen to it in a relaxed and focused state, such as before bed or during a meditation session. You can also supplement listening with other confidence-building practices, such as positive affirmations, self-reflection, and personal growth exercises. Surrounding yourself with a supportive and positive environment can also reinforce the affirmations and help you maintain a confident mindset.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>