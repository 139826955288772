<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Becoming a successful civil engineer requires a combination of technical skills, knowledge, and the right mindset. This subliminal is designed to help you develop these qualities and excel in your career."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can assist you:'
    },
    {
      type: 'list',
      items: [
        "Enhancing your knowledge in civil engineering principles, design concepts, and construction techniques.",
        "Improving your problem-solving and critical thinking skills to tackle complex engineering challenges.",
        "Developing effective project management and organizational abilities to handle large-scale construction projects.",
        "Building a mindset of continuous learning and professional growth in the field of civil engineering.",
        "Increasing your confidence and belief in your abilities as a successful civil engineer."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can rewire your subconscious mind to align with the qualities and skills needed for success in civil engineering. It can help you become more knowledgeable, skilled, and confident in your abilities. Remember, achieving success in your career requires dedication, hard work, and continuous learning, and this subliminal can be a valuable tool to support your journey."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a successful career in civil engineering requires dedication, skills, and a growth mindset. This subliminal is designed to support your journey towards becoming a successful civil engineer. Before you start listening, consider these preparation tips to enhance your learning experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your goals: Clarify your career objectives and visualize your desired outcomes in the field of civil engineering.",
        "Create a learning plan: Identify areas where you want to grow and develop specific skills in design, construction, project management, and more.",
        "Stay motivated: Remember the reasons why you want to become a civil engineer and envision the impact you can make in this field.",
        "Visualize success: Imagine yourself excelling in your career, leading projects, and making a positive difference in the industry."
      ]
    },
    {
      type: 'paragraph',
      text: "By utilizing this subliminal regularly, you can enhance your mindset, skillset, and overall career potential as a civil engineer. Visualize your success, stay dedicated to your goals, and embrace the opportunities to grow and learn in this dynamic field. Get ready to kickstart your journey towards becoming a successful civil engineer!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the session on becoming a successful civil engineer! By investing time in learning the skills and mindset required for your career, you're taking significant steps towards achieving your goals. Reflect on your experience and consider how you can continue to excel in your chosen path."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Define your short-term and long-term objectives as a civil engineer.",
        "Continuously improve your knowledge: Stay updated on the latest advancements and emerging technologies in the field.",
        "Seek mentorship: Connect with experienced professionals who can guide and inspire you on your journey.",
        "Gain practical experience: Look for opportunities to gain hands-on experience through internships or entry-level positions.",
        "Develop strong communication skills: Effective communication is crucial in a career in civil engineering.",
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, the path to becoming a successful civil engineer requires dedication, perseverance, and continuous learning. Embrace challenges and seize opportunities for growth. Your passion for this field will guide you towards a fulfilling and rewarding career. Best of luck!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Is listening to the Become a Successful Civil Engineer subliminal enough to become a successful civil engineer?`, 
    answer: `While the subliminal can help you develop the mindset and motivation needed to excel in your career as a civil engineer, it is important to note that it is not a substitute for education, knowledge, skills, and practical experience. The subliminal is designed to support your personal and professional growth, but it is still essential to pursue the necessary education and gain hands-on experience in the field to become a successful civil engineer.`,
    open: false
  },
  { 
    question: `What can I expect from listening to the Become a Successful Civil Engineer subliminal?`, 
    answer: `Listening to the subliminal can help you develop a positive and determined mindset, enhance your focus and motivation, and boost your self-confidence and resilience as a civil engineer. It aims to align your subconscious with success in the field and can provide a supportive and empowering environment for your personal and professional growth.`,
    open: false 
  },
  { 
    question: `How often should I listen to the Become a Successful Civil Engineer subliminal?`, 
    answer: `Consistency is key when it comes to listening to the subliminal. We recommend listening to it daily for at least a few weeks to allow the positive affirmations to reprogram your subconscious mind effectively. You can listen to it during your free time, while working, or during relaxation periods, whichever works best for you.`,
    open: false 
  },
  { 
    question: `Can the Become a Successful Civil Engineer subliminal help with specific aspects of my career, such as project management or design skills?`, 
    answer: `Yes, the subliminal aims to enhance and support your overall personal and professional growth as a civil engineer, including your skills in project management, design, and other areas of expertise. By aligning your subconscious mind with success, it can help you develop the mindset and motivation needed to excel in different aspects of your career.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Become a Successful Civil Engineer subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your career or mental well-being, we recommend consulting with a career counselor or mental health professional for personalized guidance.`,
    open: false 
  },
  { 
    question: `Can I listen to the Become a Successful Civil Engineer subliminal while studying or working?`, 
    answer: `Yes, you can listen to the subliminal while studying or working. It is designed to be listened to in the background, without requiring your full attention. However, if you find it distracting or prefer to focus solely on your tasks, you can also listen to it during your free time or during relaxation periods.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>