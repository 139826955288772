<template>
  <v-dialog id="dialog" persistent v-model="dialog" :max-width="width" style="overflow-x: hidden !important; height: 100px;">
    <v-card id="card-dialog" height="430px" style="overflow-y: hidden;">
      <v-card-title class="d-flex align-self-center px-4 wrapper">
        <p @click="closeDialog">Cancel</p>
        <v-spacer></v-spacer>
      </v-card-title>
      <h2 style="text-align: center">Premium</h2>

      <v-card-text style="padding: 0 15px">
        <slot></slot>
      </v-card-text>


      <v-card-actions>
        <slot name="action"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean
    },
    title: {
      type: String
    },
    width: {
      type: Number
    },
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog', false)
    }
  }
}
</script>

<style scoped>

.wrapper{
  background-image: url("~@/assets/images/components/payment_2.png");
  background-repeat: no-repeat;
  background-size: 30% 90%;
  background-position: center right;
}
h2 {
  color: var(--ma-tufts-blue);
  font-family: "Montserrat-Bold" !important;
  margin-top: -30px;
}
p {
  text-decoration: underline;
  color: #60636C;
  font-family: "Montserrat-Bold";
  cursor: pointer;
}
</style>