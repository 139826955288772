<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Dealing with facial acne can be frustrating and impact your self-confidence, but rest assured, there are effective treatment options and skincare routines that can help. This subliminal is designed to support and enhance your journey to clear, healthy skin."
    },
    {
      type: 'heading',
      text: 'Here are some key ways this subliminal can assist you:'
    },
    {
      type: 'list',
      items: [
        "By promoting a positive mindset and boosting your self-esteem about your natural beauty.",
        "By encouraging consistency and commitment to your skincare routine.",
        "By helping reduce inflammation and breakouts through targeted subconscious suggestions.",
        "By promoting healthy eating habits and proper hydration, which can contribute to clearer skin.",
        "By encouraging you to take proactive steps towards acne prevention and treatment.",
        "By helping you develop resilience and patience during your journey to clear skin."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, achieving clear, healthy skin takes time and a multifaceted approach. Alongside using this subliminal, it's essential to consult with a dermatologist and follow a personalized skincare routine. By embracing holistic care and consistently working towards your goal, you can make progress in your journey to achieving the clear, radiant skin you desire."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Dealing with facial acne can be frustrating, but this subliminal is here to support you on your journey towards achieving clear and healthy skin. Before you start listening, consider these tips to enhance your experience and optimize your results:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Educate yourself: Learn about effective acne treatment options and skincare routines that are suitable for your skin type.",
        "Develop a skincare routine: Create a consistent daily routine that includes cleansing, toning, and moisturizing with products designed for acne-prone skin.",
        "Practice lifestyle habits: Maintain a healthy diet, exercise regularly, manage stress, and get enough sleep to support overall skin health.",
        "Set clear intentions: Focus on your desire for clear, healthy skin while listening to the subliminal affirmations, allowing them to penetrate your subconscious mind."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember that this subliminal is designed to support you in your journey towards achieving clear skin, but it is also essential to take practical steps in your daily life to combat facial acne. By combining the power of the subliminal with a consistent skincare routine and healthy lifestyle habits, you can optimize your results and enjoy a clearer, healthier complexion. Get ready to say goodbye to facial acne and embrace the journey towards radiant skin!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Facial Acne Removal and Treatment audio! By investing time and effort into improving your skin, you've taken an important step towards achieving clear, healthy skin. Now that you've listened to the subliminal, it's time to take action and maintain your progress."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Follow a skincare routine: Establish a consistent routine that includes cleansing, exfoliating, and moisturizing.",
        "Consider professional treatments: Consult with a dermatologist about treatment options like chemical peels or laser therapy.",
        "Keep your skin clean: Avoid touching your face excessively and regularly change pillowcases and face towels.",
        "Support your skin from within: Eat a balanced diet, stay hydrated, and manage stress levels for overall skin health."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, achieving clear, healthy skin is a process that requires patience and persistence. By implementing these tips and caring for your skin both externally and internally, you're giving yourself the best chance for success. Embrace the journey towards clear, radiant skin, and maintain your dedication to a skincare routine. You've got this!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How long does it take to see results with the Facial Acne Removal and Treatment?`, 
    answer: `Results can vary depending on the individual and the severity of their acne. Some people may see improvements within a few weeks, while others may require a longer period of consistent treatment to achieve desired results. It is important to be patient and consistent with your skincare routine.`,
    open: false
  },
  { 
    question: `What are some effective treatment options for facial acne?`, 
    answer: `Some effective treatment options for facial acne include over-the-counter topical treatments with ingredients like benzoyl peroxide or salicylic acid, prescription medication, professional treatments like chemical peels or laser therapy, and adopting a consistent skincare routine with gentle cleansers and moisturizers suitable for acne-prone skin. It is recommended to consult with a dermatologist for personalized treatment advice.`,
    open: false 
  },
  { 
    question: `Are there any natural remedies or home treatments for facial acne?`, 
    answer: `While there are some natural remedies that may help with facial acne, such as tea tree oil, honey, or turmeric, their effectiveness can vary from person to person. It is important to approach natural remedies with caution and consult with a dermatologist before incorporating them into your skincare routine, especially if you have sensitive or reactive skin.`,
    open: false 
  },
  { 
    question: `Can diet and lifestyle choices affect facial acne?`, 
    answer: `Diet and lifestyle choices can have an impact on facial acne. Research suggests that a diet high in sugar, refined carbohydrates, and dairy products may contribute to acne. Additionally, stress, lack of sleep, and certain medications can also affect the condition of the skin. Maintaining a balanced diet, managing stress levels, getting enough sleep, and adopting a regular skincare routine can help improve the appearance of acne.`,
    open: false 
  },
  { 
    question: `Can the Facial Acne Removal and Treatment help with acne scars?`, 
    answer: `The Facial Acne Removal and Treatment may help improve the appearance of acne scars over time, depending on the individual and the severity of the scars. However, it is important to note that deeper or more severe acne scars may require professional treatments like dermal fillers, chemical peels, or laser therapy for significant improvement.`,
    open: false 
  },
  { 
    question: `Are there any side effects of the Facial Acne Removal and Treatment?`, 
    answer: `It is possible to experience side effects with certain acne treatments, especially prescription medications or professional treatments. Common side effects may include dryness, redness, peeling, or increased sensitivity to sunlight. It is important to follow the instructions provided with the specific treatment and consult with a dermatologist if you have any concerns or experience severe or persistent side effects.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>