import request from "@/utils/request";

export const getPlaylists = (data) => {
  return request.get("/api/playlist").then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const getPlaylistFilters = (data) => {
  return request.post("/api/playlist/filter", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const addPlaylists = (data) => {
  return request.post("/api/playlist", data).then((res) => {
    return res.data;
  });
};

export const updateToggle = (data) => {
  return request.post("/api/playlist/toggle", data).then((res) => {
    return res.data;
  });
};

export const updatePlaylists = (data) => {
  return request.put(`/api/playlist/${data.playlist_id}`, data).then((res) => {
    return res.data;
  });
};

export const deletePlaylists = (data) => {
  return request.delete(`/api/playlist/${data.playlist_id}`).then((res) => {
    return res.data;
  });
};

export const addOwnPlaylists = (data) => {
  return request.post("/api/own/playlist/add", data).then((res) => {
    return res.data;
  });
};

export const getAllUserPlaylist = (data) => {
  return request.post(`/api/playlist/user`, data).then((res) => {
    const { data } = res.data;
    return data;
  });
};
