<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Being confident in your romantic interactions is empowering and can greatly enhance your flirting skills as a woman. This subliminal is designed to help boost your confidence and make you feel more empowered when flirting."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Increasing your self-esteem and belief in your attractiveness.",
        "Boosting your ability to initiate and engage in flirtatious conversations.",
        "Enhancing your body language and nonverbal cues to signal interest.",
        "Helping you overcome shyness or fear of rejection when flirting.",
        "Building a positive and magnetic energy that attracts potential partners."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to support your flirting confidence and empower you in your romantic interactions. With increased confidence, you can approach flirting with a sense of ease, authenticity, and enjoyment. Embrace your power and have fun!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Developing confidence when flirting can be a powerful tool to feel more assertive and empowered in your romantic interactions. This subliminal is designed to help boost your confidence and enhance your flirting skills. Before you start listening, consider these tips to make the most of your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Embrace self-love: Prioritize self-care and build a positive relationship with yourself.",
        "Identify your strengths: Recognize what makes you unique and special, and remind yourself of your worth.",
        "Practice positive affirmations: Repeat empowering statements that boost your confidence and reinforce your self-belief.",
        "Visualize success: Imagine yourself confidently flirting and enjoying positive interactions with others.",
        "Maintain an open mindset: Be receptive to new ideas and approaches to flirting, and embrace the opportunity for personal growth."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly and incorporating these strategies into your life, you can cultivate a stronger sense of confidence and empowerment when flirting. Remember to be patient with yourself and celebrate your progress along the way. Get ready to radiate confidence and connect with others on a whole new level!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Flirting Confidence for Women subliminal session! By taking this step towards boosting your confidence in flirting and romantic interactions, you are empowering yourself in your personal and social life. It's important to reflect on your experience and maintain this newfound confidence moving forward."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Cultivate positive self-talk: Replace self-doubt with affirmations that reinforce your confidence.",
        "Step out of your comfort zone: Challenge yourself to initiate conversations and engage in playful banter.",
        "Practice active listening: Show genuine interest in the person you're flirting with by listening attentively.",
        "Embrace rejection: Remember that rejection is a normal part of the dating process, and it doesn't define your worth."
      ]
    },
    {
      type: 'paragraph',
      text: "Confidence in flirting is an ongoing journey. Embrace the process, celebrate your progress, and keep practicing the skills you've learned. Remember, you are deserving of love and connection, and your confidence will shine through in your romantic interactions. Flirt fearlessly and enjoy the journey!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Flirting Confidence for Women subliminal help boost my confidence?`, 
    answer: `The Flirting Confidence for Women subliminal is designed to influence your subconscious mind with positive affirmations that promote self-confidence and empowerment in romantic interactions. By regularly listening to the audio, you can develop a more positive self-image, embrace your femininity, and feel more at ease when flirting with others.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Flirting Confidence for Women subliminal?`, 
    answer: `Results may vary from person to person. Some individuals may start to notice an increase in confidence and empowerment in their romantic interactions after a few listens, while others may require more time. Consistently listening to the subliminal audio is key to experiencing the full benefits.`,
    open: false 
  },
  { 
    question: `Can the Flirting Confidence for Women subliminal help me overcome social anxiety in romantic situations?`, 
    answer: `While the Flirting Confidence for Women subliminal is designed to boost confidence and empowerment in romantic interactions, it may not specifically address social anxiety. However, by promoting a positive self-image and mindset, it can contribute to reducing anxiety and increasing comfort in social situations over time. If you have severe social anxiety, it is recommended to seek professional help in addition to using the subliminal.`,
    open: false 
  },
  { 
    question: `Is it necessary to actively flirt with others while listening to the subliminal to see results?`, 
    answer: `No, it is not necessary to actively flirt with others while listening to the subliminal to see results. The subliminal works on your subconscious mind to promote confidence and empowerment in romantic interactions, regardless of whether you are actively flirting or not. However, putting yourself in social situations where you can practice and apply your newfound confidence may help reinforce and solidify the positive changes brought about by the subliminal.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Flirting Confidence for Women subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, it is important to use the subliminal responsibly and in conjunction with healthy and respectful social behaviors. If you have any concerns about your mental health or well-being, consult with a healthcare professional.`,
    open: false 
  },
  { 
    question: `Can the Flirting Confidence for Women subliminal work for people of all sexual orientations?`, 
    answer: `Yes, the Flirting Confidence for Women subliminal can benefit individuals of all sexual orientations. The subliminal is designed to enhance self-confidence and empowerment in romantic interactions and is not limited to any particular sexual orientation.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>