<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you feel that you've been cursed or are surrounded by negative energies, this subliminal can help you break free and protect yourself. By focusing on canceling curses and creating a protective shield, it aims to bring you a sense of peace and security."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help you:"
    },
    {
      type: 'list',
      items: [
        "Removing negative energies and breaking free from any curses that may be affecting you.",
        "Creating a strong protective shield around you, preventing negative energies from entering your space.",
        "Promoting a sense of calmness, peace, and inner strength, helping you deal with any negative influences.",
        "Boosting your energy levels and overall well-being by eliminating the draining effects of curses and negative energies.",
        "Enhancing your intuition and spiritual protection, enabling you to detect and repel any harmful influences."
      ]
    },
    {
      type: 'paragraph',
      text: "Regularly listening to this subliminal can help you reclaim your personal power and create a positive environment around you. It is important to remember that the effects of curses and negative energies can vary, so be patient and allow the subliminal to work its magic. Trust in the process and believe in your ability to break free and protect yourself."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Dealing with curses and negative energies can be overwhelming and draining, but this subliminal is designed to help you remove curses and protect yourself from such negative influences. Before you start listening, consider the following tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Clearly define your desire to remove curses and protect yourself from negative energies.",
        "Create a sacred space: Find a quiet, comfortable place where you feel safe and secure.",
        "Mental preparation: Take a few moments to quiet your mind, focus on your intentions, and visualize a protective shield around you.",
        "Release negativity: Let go of any doubts or fears, trusting in the power of this subliminal to cancel curses and shield you from negativity."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, reaffirm your intention, and believe in the power of protection and the removal of curses. Embrace the opportunity to release negative energies from your life and create a shield of strength and positivity. Take a deep breath, prepare your mind, and get ready to embark on a journey of renewal and protection."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Curse Cancellation and Protection Shield subliminal! By engaging with this powerful audio, you have taken the necessary steps to remove curses and shield yourself from negative energies. As you continue on your journey of protection and personal growth, take a moment to reflect on your experience and consider the following post-listening tips:"
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Visualize a protective shield: Imagine a vibrant, impenetrable shield surrounding you, protecting you from any negativity.",
        "Surround yourself with positive energy: Engage in activities and spend time with people who uplift and inspire you.",
        "Cleansing rituals: Incorporate rituals like burning sage or utilizing crystals to clear negative energies from your space.",
        "Affirmations: Repeat positive affirmations daily to reinforce a positive mindset and a protective aura."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, protecting yourself from negative energies is an ongoing process. Stay mindful of your environment, thoughts, and energy exchanges. Trust in your own strength and the power of the protection shield you have created. You are strong, resilient, and deserving of a life filled with positivity and light. Keep shining!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Curse Cancellation and Protection Shield work?`, 
    answer: `The Curse Cancellation and Protection Shield is designed to remove curses and protect against negative energies. It works by using powerful subliminal messages and affirmations that target the subconscious mind, helping to break the hold of curses and create a shield of protection. It helps to shift your energy, mindset, and vibrations to a more positive and protected state. Consistent listening is key to allow the affirmations to work on a deeper level.`,
    open: false
  },
  { 
    question: `Can the Curse Cancellation and Protection Shield protect me from all negative energies?`, 
    answer: `While the Curse Cancellation and Protection Shield can help protect against negative energies, it is important to note that it may not be able to shield you from all types of negative energies or situations. It is a powerful tool that can provide an extra layer of protection, but it is also important to practice self-care, set boundaries, and surround yourself with positive influences.`,
    open: false 
  },
  { 
    question: `How long does it take to see results from using the Curse Cancellation and Protection Shield?`, 
    answer: `Results may vary depending on the individual and the complexity of the situation. Some people may experience immediate relief, while others may require more time and consistent listening to see significant changes. We recommend listening to the subliminal daily and consistently for best results. It is important to have patience and trust in the process.`,
    open: false 
  },
  { 
    question: `Can the Curse Cancellation and Protection Shield remove all types of curses?`, 
    answer: `The Curse Cancellation and Protection Shield is designed to break the hold of curses and protect against negative energies. However, the effectiveness may vary depending on the nature and strength of the curse. It is important to note that the subliminal audio is not a substitute for seeking professional help or guidance if you believe you are under a severe curse or experiencing harmful supernatural activities.`,
    open: false 
  },
  { 
    question: `Is there any risk or side effects associated with using the Curse Cancellation and Protection Shield?`, 
    answer: `No, there are no known risks or side effects associated with using this subliminal audio. However, if you have any concerns or underlying health conditions, it is always advised to consult with a healthcare professional. It is important to note that the subliminal audio is intended to provide support and should not replace seeking necessary help or guidance.`,
    open: false 
  },
  { 
    question: `Can the Curse Cancellation and Protection Shield guarantee complete protection?`, 
    answer: `While the Curse Cancellation and Protection Shield is a powerful tool, it cannot guarantee complete protection from all negative energies or situations. It can provide an extra layer of protection and support, but it is important to remember that individual experiences may vary. It is always advised to practice self-care, set healthy boundaries, and seek professional help or guidance if needed.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>