<template>
  <section>
    <div class="container">
      <div>
        <div>
          <h1>HOW DOES MAGUS HELP YOUR BUSINESS?</h1>
          <h5 class="h51">Employee Benefits + maximized ROI</h5>
          <h5>
            Your employees are your greatest assets. Offering your team mental
            health and self-development benefits makes them feel valued and
            rewarded.
          </h5>
          <h5>
            Getting Magus for your team allows your business and your employees
            to run at peak performance.
          </h5>
          <div>
            <button
              class="button btn btn-primary btn-lg"
              @click="goToRoute('/howDoesMagus')"
            >
              Learn More
            </button>
          </div>
        </div>
        <div class="col-12 col-lg-12 mx-auto">
          <div class="row">
            <template v-for="(item, index) in menus">
              <div
                class="product-card col-6 col-sm-6 col-md-6 col-lg-6 gap-2 g-2"
                :key="index"
              >
                <img :class="`icon${index}`" :src="item.icon" alt="" />
                <div>
                  <h6 v-html="item.title"></h6>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data: () => {
    return {
      menus: [
        {
          icon: require("../../../assets/images/main/business/3/1.png"),
          title: "Increased Productivity and Work Performance",
        },
        {
          icon: require("../../../assets/images/main/business/3/2.png"),
          title: "Improved Mental and Emotional Health",
        },
        {
          icon: require("../../../assets/images/main/business/3/3.png"),
          title: "Better Well-Being and Lower Stress Levels",
        },
        {
          icon: require("../../../assets/images/main/business/3/4.png"),
          title: "Better Employee Morale and Staff Retention",
        },
      ],
    };
  },
  methods: {
    goToRoute(route) {
      if (this.currentRoute != route) {
        this.$router.push(route).catch((err) => {});
        window.scrollTo(0, 0);
      }
    },
  },
};
</script>

<style scoped>
h1 {
  color: var(--ma-black-olive);
  line-height: 1.35em;
}
.h51 {
  color: var(--ma-tufts-blue);
  font-family: "Montserrat-Bold";
}
h5 {
  font-family: "Montserrat-Regular";
}
h6 {
  padding: 2px;
}

img {
  height: clamp(2.19rem, calc(1.64rem + 9.72vw), 13.75rem);
  margin-right: auto;
  margin-left: auto;
}

.product-card {
  align-items: center;
  justify-content: center;
  border: 1px solid #1c8edf;
  border-radius: 20px;
  height: fit-content;
  text-align: center;
}

@media screen and (min-width: 80rem) {
  section > .container {
    @media screen and (min-width: 1200px) {
      text-align: left;
      height: auto;
      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        & > div {
          width: 50%;
        }
      }
    }

    @media screen and (max-width: 1200px) {
      height: auto;
      text-align: center;
    }
  }
}
</style>
