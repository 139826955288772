<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Life is full of ups and downs, and building resilience is crucial for navigating through challenges and setbacks. This subliminal is designed to help you develop the strength and mental toughness to overcome adversity and bounce back stronger."
    },
    {
      type: 'heading',
      text: 'Key features of this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Building resilience to effectively cope with stress and difficult situations.",
        "Developing a positive mindset and the ability to see challenges as opportunities for growth.",
        "Enhancing your self-belief and confidence in your ability to overcome obstacles.",
        "Learning to manage emotions and cultivate inner strength in the face of adversity.",
        "Promoting mental toughness and a resilient attitude towards setbacks."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, it will gradually rewire your subconscious mind, helping you develop the resilience and inner strength needed to bounce back from life's challenges. Remember, resilience is a skill that can be learned and strengthened. With continued use, you can cultivate a resilient mindset and live a more resilient life."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Developing resilience is crucial for navigating life's challenges and setbacks. This subliminal is designed to help you build the mental toughness and strength to bounce back and overcome adversity. Before you start listening, consider these strategies to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your goals: Clarify what resilience means to you and the areas in your life where you'd like to become more resilient.",
        "Practice self-care: Prioritize activities that nurture your physical, mental, and emotional well-being.",
        "Cultivate a positive mindset: Focus on positive thinking, gratitude, and self-belief.",
        "Stay present: Practice mindfulness and embrace the present moment."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you may find yourself becoming more resilient in various aspects of your life. Embrace the opportunity to strengthen your mental fortitude, bounce back stronger from challenges, and cultivate a resilient mindset. Remember, resilience is a skill that can be developed, and with dedication and consistency, you can overcome any adversity that comes your way."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the subliminal session for Becoming More Resilient! By focusing on building resilience, you have taken an important step towards developing the strength and mental toughness to overcome challenges and setbacks in life. As you reflect on your experience, remember that resilience is a skill that can be nurtured and strengthened."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge setbacks as opportunities: Embrace challenges as chances to grow and learn.",
        "Practice self-care: Prioritize activities that promote your physical, mental, and emotional well-being.",
        "Cultivate a growth mindset: Embrace the belief that you can learn and improve from any situation.",
        "Seek support: Surround yourself with a supportive network of friends, family, or a mentor."
      ]
    },
    {
      type: 'paragraph',
      text: "Building resilience is a lifelong journey, and it's normal to face setbacks along the way. Remember to be patient with yourself and celebrate even the small victories. With determination and the right mindset, you have the power to bounce back stronger from any adversity. Keep going!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Become More Resilient subliminal help me?`, 
    answer: `The Become More Resilient subliminal is designed to help you develop a resilient mindset and bounce back from challenges and setbacks. By listening to the subliminal messages, you can strengthen your mental toughness, improve your problem-solving skills, and embrace a positive outlook even in difficult situations. The subliminal aims to reprogram your subconscious mind to view challenges as opportunities for growth and develop the resilience needed to overcome adversity.`,
    open: false
  },
  { 
    question: `How long do I need to listen to the Become More Resilient subliminal to see results?`, 
    answer: `Results can vary from person to person, and it may take time to see noticeable changes in your resilience levels. Consistency is key, and we recommend listening to the Become More Resilient subliminal daily for at least a few weeks to allow the positive affirmations to take effect. Over time, you may find yourself better equipped to handle challenges and setbacks with resilience and mental toughness.`,
    open: false 
  },
  { 
    question: `Can the Become More Resilient subliminal replace professional therapy or counseling?`, 
    answer: `No, the Become More Resilient subliminal is not a substitute for professional therapy or counseling. It is designed to complement other personal development practices and serve as a tool to enhance your personal resilience. If you are experiencing severe emotional distress or mental health issues, it is important to seek professional help.`,
    open: false 
  },
  { 
    question: `What are some practical steps I can take to build resilience in my daily life?`, 
    answer: `In addition to listening to the Become More Resilient subliminal, there are several practical steps you can take to build resilience in your daily life. These include practicing self-care, developing a strong support system, setting realistic goals, adopting a growth mindset, practicing mindfulness and gratitude, and seeking opportunities for personal growth and learning. Combining these practices with the subliminal can help you develop and strengthen your resilience over time.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Become More Resilient subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Become More Resilient subliminal audio. However, if you have any concerns about your mental health or well-being, please consult with a mental health professional. The subliminal is designed to supplement personal development practices, not replace them.`,
    open: false 
  },
  { 
    question: `Can the Become More Resilient subliminal help with specific challenges or traumas?`, 
    answer: `While the Become More Resilient subliminal can help develop resilience and mental toughness, it is not specifically tailored to address individual challenges or traumas. If you have experienced significant traumas or are struggling with specific challenges, it is important to seek professional help or therapy to address and process those experiences.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>