<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're looking to develop a healthy and fit body, this subliminal can be a powerful tool to support your journey. By targeting your subconscious mind, it helps you make positive changes to your nutrition, exercise routine, and lifestyle choices."
    },
    {
      type: 'heading',
      text: 'Here are some key areas where this subliminal can help:'
    },
    {
      type: 'list',
      items: [
        "Improving your motivation and commitment to sticking to a healthy diet and exercise plan.",
        "Enhancing your ability to make nutritious food choices and resist unhealthy cravings.",
        "Boosting your energy levels and overall strength, making it easier to engage in physical activity.",
        "Supporting a positive body image and cultivating self-acceptance.",
        "Promoting healthy habits and behaviors that contribute to a fit and energetic body."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can rewire your subconscious programming and align it with your goals for a healthier body. With time and consistency, you may experience increased vitality, improved physical performance, and a greater sense of well-being. Remember, transforming your body takes patience and dedication, but you have the power to achieve your desired results."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a journey to develop a healthier and fitter body requires commitment and dedication. This subliminal is designed to support you in making positive nutrition, exercise, and lifestyle choices that will contribute to your overall well-being. Before you begin, consider these tips to maximize your results:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set specific goals: Clearly define what you want to achieve in terms of your body and overall health.",
        "Create a sustainable plan: Develop a balanced nutrition plan and exercise routine that suits your needs and preferences.",
        "Prepare your environment: Eliminate any temptations or distractions that may hinder your progress, and surround yourself with supportive influences.",
        "Visualize your desired outcome: During the subliminal, visualize yourself already having achieved your goals, feeling strong and energized."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is a powerful tool to support and reinforce your efforts towards a healthy and fit body. With consistent listening and a commitment to making positive choices, you can experience significant improvements in your overall well-being. Embrace this opportunity to prioritize your health and enjoy the journey towards a stronger, more energetic body!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Healthy Body Development program! By focusing on proper nutrition, exercise, and lifestyle choices, you are well on your way to improving your overall well-being and achieving a healthier, more energetic body. Now, it's important to reflect on your experience and continue your journey towards personal growth and transformation."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your progress: Take the time to acknowledge how far you've come and celebrate your achievements.",
        "Maintain a balanced diet: Continue nourishing your body with nutritious foods that support your fitness goals.",
        "Keep up with exercise: Consistency is key; create a workout routine that you enjoy and can stick to.",
        "Prioritize rest and recovery: Allow your body time to recuperate and repair through proper rest and sleep.",
        "Stay motivated: Set new goals, track your progress, and surround yourself with positive influences."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, developing a healthy and fit body is a lifelong journey. Embrace the process, be patient with yourself, and enjoy the benefits of a stronger, more energetic physique. You're on the right path, and with dedication and commitment, you'll continue to thrive. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Healthy Body Development subliminal help me achieve my fitness goals?`, 
    answer: `The Healthy Body Development subliminal is designed to help you develop a healthy and fit body by influencing your subconscious mind. The subliminal messages aim to cultivate positive thoughts and beliefs about nutrition, exercise, and overall well-being. By consistently listening to the subliminal, you can enhance your motivation, discipline, and commitment towards making healthy choices and achieving your fitness goals.`,
    open: false
  },
  { 
    question: `How long does it take to see physical results from the Healthy Body Development subliminal?`, 
    answer: `The time it takes to see physical results may vary depending on various factors, such as individual metabolism, consistency in following healthy habits, and the specific goals you have set. For some individuals, noticeable changes may occur within a few weeks, while others may require more time. It is important to combine the subliminal with proper nutrition, regular exercise, and a balanced lifestyle for optimal results.`,
    open: false 
  },
  { 
    question: `Can the Healthy Body Development subliminal replace the need for exercise and a balanced diet?`, 
    answer: `No, the Healthy Body Development subliminal is not intended to replace exercise and a balanced diet. Its purpose is to complement and enhance your fitness journey by reinforcing positive beliefs and motivation towards healthy lifestyle choices. To achieve a healthy and fit body, it is important to combine the subliminal with regular exercise and a well-balanced diet.`,
    open: false 
  },
  { 
    question: `How can I make the Healthy Body Development subliminal more effective?`, 
    answer: `To make the Healthy Body Development subliminal more effective, it is recommended to set specific, measurable, achievable, relevant, and time-bound (SMART) goals. Additionally, create a well-structured exercise routine, prioritize nutrition, get sufficient rest, and practice mindful eating. Surround yourself with a supportive environment and seek guidance from fitness professionals or healthcare providers if needed.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with using the Healthy Body Development subliminal?`, 
    answer: `No, there are no known risks or side effects associated with using the Healthy Body Development subliminal. However, if you have any underlying health concerns or questions about your fitness regimen, it is recommended to consult with a healthcare professional. This subliminal is designed to support your overall well-being and healthy lifestyle choices.`,
    open: false 
  },
  { 
    question: `Can the Healthy Body Development subliminal help me build muscle or lose weight?`, 
    answer: `The Healthy Body Development subliminal aims to support your journey towards a healthy and fit body, which may include building muscle or losing weight depending on your goals. By positively influencing your mindset and motivation, the subliminal can enhance your commitment to regular exercise, balanced nutrition, and overall well-being, ultimately facilitating progress towards your desired body composition.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>