<template>
  <div class="d-flex justify-content-center">
    <div id="google-pay-button"></div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      amount: "0",
      paymentsClient: null,
    };
  },
  mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.has("amount")) {
      this.amount = urlParams.get("amount");
    }

    this.initGooglePay();
  },
  methods: {
    initGooglePay() {
      // Check if the browser supports Google Pay
      if (this.paymentsClient == null) {
        this.paymentsClient = new google.payments.api.PaymentsClient({
          environment: "TEST",
        });

        // Define payment data request
        const paymentDataRequest = {
          apiVersion: 1,
          apiVersionMinor: 0,
          allowedPaymentMethods: [
            {
              type: "CARD",
              parameters: {
                allowedAuthMethods: ["PAN_ONLY"],
                allowedCardNetworks: ["VISA", "MASTERCARD"],
              },
              tokenizationSpecification: {
                type: "PAYMENT_GATEWAY",
                parameters: {
                  gateway: "example",
                  gatewayMerchantId: "BCR2DN4T5GFLLUCS",
                },
              },
            },
          ],
          merchantInfo: {
            merchantName: "Magus Audio",
            merchantId: "BCR2DN4T5GFLLUCS",
          },
          transactionInfo: {
            totalPriceStatus: "FINAL",
            totalPrice: parseFloat(this.amount).toFixed(2).toString(),
            currencyCode: "PHP",
          },
        };

        // Create Google Pay button
        const googlePayButton = this.paymentsClient.createButton({
          onClick: () => {
            this.paymentsClient
              .loadPaymentData(paymentDataRequest)
              .then((paymentData) => {
                console.log("Payment successful:", paymentData);
                // Handle successful payment
              })
              .catch((error) => {
                console.error("Error:", error);
                // Handle error
              });
          },
        });

        // Add Google Pay button to the DOM
        document
          .getElementById("google-pay-button")
          .appendChild(googlePayButton);
      } else {
        console.error("Google Pay is not supported in this browser.");
      }
    },
  },
};
</script>
