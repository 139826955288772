<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Fear and anxiety surrounding cancer can have a significant impact on your mental and emotional well-being. This subliminal is designed to help you overcome those fears and develop a positive mindset towards your health and well-being."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help:'
    },
    {
      type: 'list',
      items: [
        "Reducing anxiety and fear related to cancer diagnosis, treatment, and prognosis.",
        "Shifting your mindset from fear to empowerment and positivity.",
        "Promoting inner peace and calmness in the face of cancer-related worries.",
        "Enhancing your ability to cope with the emotional challenges of cancer.",
        "Developing a greater sense of hope, resilience, and optimism towards your health."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can rewire your subconscious mind to let go of fear and embrace a more positive outlook on your health journey. Remember, overcoming fear takes time and effort, but with consistent use, you can cultivate a mindset that supports your well-being." 
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Overcoming the fear and anxiety associated with cancer can be a challenging journey, but this subliminal is here to support you in developing a positive mindset towards your health and well-being. Before you begin listening, take a moment to prepare yourself using these strategies:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your fears: Recognize and accept the fears and anxieties you have surrounding cancer, allowing yourself to confront them head-on.",
        "Educate yourself: Gain knowledge about cancer prevention, treatment, and survivor stories to empower yourself with accurate information and dispel misconceptions.",
        "Practice relaxation techniques: Incorporate deep breathing exercises, meditation, or other relaxation techniques into your routine to reduce stress and promote calmness.",
        "Visualize a healthy future: Imagine yourself as a healthy individual, free from cancer and enjoying a vibrant life, reinforcing positivity and hope.",
        "Hold onto hope: Embrace a positive mindset, believing in your ability to overcome challenges and maintain good health."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to help you reframe your thoughts and emotions surrounding cancer, enabling you to approach your health with a renewed sense of optimism and strength. As you listen regularly, you may find yourself feeling more resilient, empowered, and in control of your well-being. Take a moment to center yourself, set your intention, and let this subliminal guide you towards a more positive mindset and a healthier future."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Fear of Cancer Removal subliminal! By addressing and eliminating your fear and anxiety surrounding cancer, you have taken a significant step towards cultivating a positive mindset and improving your overall health and well-being."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice self-care: Look after your physical, emotional, and mental well-being through activities like exercise, relaxation techniques, and engaging in hobbies.",
        "Seek professional support: If your fear and anxiety persist, consider reaching out to a therapist or counselor who can provide guidance and support.",
        "Educate yourself: Gain knowledge about cancer prevention, early detection, and treatment options to empower yourself with accurate information.",
        "Surround yourself with positivity: Surround yourself with supportive and uplifting people who can help you maintain a positive mindset."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming fear and anxiety is a process that may take time. Be patient and kind to yourself as you navigate this journey towards a positive mindset and a healthier life. With each step you take, you are strengthening your ability to face challenges and embrace a brighter future. Keep believing in yourself!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Fear of Cancer Removal subliminal help me?`, 
    answer: `The Fear of Cancer Removal subliminal is designed to help you overcome your fear and anxiety surrounding cancer by reprogramming your subconscious mind with positive and empowering beliefs. By regularly listening to the subliminal, you may start to notice a reduction in fear, increased feelings of calmness, and a more positive mindset towards your health and well-being.`,
    open: false
  },
  { 
    question: `How long does it take to see results with the Fear of Cancer Removal subliminal?`, 
    answer: `Results can vary from person to person. Some individuals may notice a shift in their mindset and a reduction in fear and anxiety after a few listens, while others may require more time and consistent listening to fully reprogram their subconscious beliefs. We recommend listening to the subliminal daily and consistently for best results.`,
    open: false 
  },
  { 
    question: `Can the Fear of Cancer Removal subliminal replace medical treatment or therapy?`, 
    answer: `No, the Fear of Cancer Removal subliminal is not intended to replace medical treatment or therapy. It is designed to complement existing treatment plans by helping you develop a more positive mindset towards your health and well-being. If you have concerns about cancer or other health issues, we recommend consulting with a healthcare professional.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the Fear of Cancer Removal subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Fear of Cancer Removal subliminal. However, if you have any concerns about health or anxiety, please consult with your healthcare provider. The subliminal is intended to be a tool to support your mental and emotional well-being.`,
    open: false 
  },
  { 
    question: `How can I make the Fear of Cancer Removal subliminal more effective?`, 
    answer: `You can enhance the effectiveness of the Fear of Cancer Removal subliminal by creating a calm and relaxing listening environment, practicing deep breathing or meditation while listening, and using visualization techniques to imagine a healthy and positive future. Consistent and dedicated listening can also help reinforce positive beliefs and reduce fear and anxiety.`,
    open: false 
  },
  { 
    question: `Will the Fear of Cancer Removal subliminal make me completely fearless of cancer?`, 
    answer: `The Fear of Cancer Removal subliminal is designed to help you cultivate a more positive mindset towards cancer and reduce fear and anxiety. While it may help alleviate some of your fears, it is important to remember that fear is a natural and protective emotion. The subliminal aims to empower you and help you develop a healthier relationship with your health and well-being.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>