<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you struggle with mumbling or feeling like others don't understand you when you speak, this subliminal can help you break out of that habit. It's designed to target your subconscious mind and improve your speaking skills, clarity, and confidence."
    },
    {
      type: 'heading',
      text: 'Here are some benefits you may experience:'
    },
    {
      type: 'list',
      items: [
        "Speaking more clearly and articulately, making it easier for others to understand you.",
        "Breaking the habit of mumbling and developing better enunciation.",
        "Increasing your confidence when speaking, leading to more effective communication.",
        "Improving your overall speaking skills, including tone, volume, and pace.",
        "Enhancing your ability to express yourself clearly and assertively."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind to eliminate mumbling and improve your speaking abilities. Over time, you may notice a significant difference in how well others understand you, as well as an increase in your own confidence when communicating. Remember, consistency is key, so make sure to incorporate this subliminal into your daily routine for best results."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Breaking the habit of mumbling can greatly improve your speaking skills and clarity, allowing you to communicate confidently and be understood by others. This subliminal is designed to help you achieve just that. Before you begin listening, consider these tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Awareness: Pay attention to when you find yourself mumbling and make a conscious effort to correct it.",
        "Slow down: Practicing speaking at a slower pace can help you articulate your words more clearly.",
        "Enunciate: Focus on pronouncing each word with precision and clarity.",
        "Record yourself: Use a recording device to listen to yourself speak and identify areas where you can improve your clarity.",
        "Confidence: Believe in your ability to speak clearly and confidently."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is a tool to help reprogram your subconscious mind and break the habit of mumbling. With consistent listening and practice, you can transform your speaking skills and become more effective in your communication. Embrace this opportunity to grow and develop, and let the journey to clearer speech begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Stop Mumbling subliminal session! By focusing on improving your speaking skills and clarity, you've taken an important step towards enhancing your communication and being understood by others. Reflecting on your progress and maintaining your efforts is key as you continue on this journey of self-improvement."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice speaking clearly: Take the opportunity to speak aloud and enunciate your words.",
        "Record yourself speaking: Listen to your recordings and identify areas where you can improve your clarity.",
        "Engage in speech exercises: Research and try exercises designed to improve articulation and pronunciation.",
        "Seek feedback: Ask friends or family to provide feedback on your speech clarity and make necessary adjustments."
      ]
    },
    {
      type: 'paragraph',
      text: "Breaking the habit of mumbling and improving your speaking skills takes time and consistent practice. Embrace the journey and continue to challenge yourself to speak confidently and be understood by others. Remember, each step you take towards clearer speech is a step towards improved communication and greater confidence. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Stop Mumbling subliminal help improve my speaking skills?`, 
    answer: `The Stop Mumbling subliminal is designed to influence your subconscious mind and help you break the habit of mumbling. By consistently listening to the subliminal audio, you can become more aware of your speech patterns and practice speaking clearly and confidently. Over time, this can improve your speaking skills and make it easier for others to understand you.`,
    open: false
  },
  { 
    question: `How long does it take to see improvements in my speaking skills with the Stop Mumbling subliminal?`, 
    answer: `The time it takes to see improvements in your speaking skills may vary depending on factors such as your current habits, dedication to listening to the subliminal, and frequency of practice. Consistently listening to the subliminal and actively working on speaking clearly can accelerate the process. It is recommended to listen to the subliminal daily and implement additional speech exercises for best results.`,
    open: false 
  },
  { 
    question: `Are there any additional techniques or exercises I can use along with the Stop Mumbling subliminal?`, 
    answer: `Along with listening to the Stop Mumbling subliminal, there are other techniques and exercises you can incorporate to enhance your speaking skills. These include practicing articulation exercises, recording yourself speaking and analyzing the recordings for areas of improvement, and seeking feedback from others. By combining these methods with the subliminal, you can make significant progress in improving your clarity of speech.`,
    open: false 
  },
  { 
    question: `Can the Stop Mumbling subliminal help with confidence in speaking?`, 
    answer: `Yes, the Stop Mumbling subliminal can help improve your confidence in speaking by breaking the habit of mumbling and increasing your awareness of how you sound to others. By practicing speaking clearly and consistently with the subliminal, you can develop a more confident speaking style and feel more comfortable expressing yourself verbally.`,
    open: false 
  },
  { 
    question: `Will listening to the subliminal change my voice or accent?`, 
    answer: `No, the Stop Mumbling subliminal is not designed to change your voice or accent. Its purpose is to help you break the habit of mumbling and improve your clarity of speech. Your natural voice and accent will remain unchanged.`,
    open: false 
  },
  { 
    question: `Is it possible to completely eliminate mumbling with the Stop Mumbling subliminal?`, 
    answer: `While the Stop Mumbling subliminal is designed to help you break the habit of mumbling, it may not completely eliminate it, especially if it is deeply ingrained. However, through consistent practice and listening to the subliminal, you can significantly reduce the habit of mumbling and improve your overall clarity of speech.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>