<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Building a strong inner belief and conviction is crucial for manifesting your desires and achieving your goals. This subliminal is designed to help you develop the mindset and confidence needed to make your dreams a reality."
    },
    {
      type: 'heading',
      text: 'Here are some key elements of how this subliminal works:'
    },
    {
      type: 'list',
      items: [
        "It targets your subconscious mind to reprogram limiting beliefs that may be holding you back.",
        "It helps you cultivate a sense of unwavering belief and confidence in your abilities to manifest your desires.",
        "It encourages positive affirmations and visualization to reinforce your inner conviction.",
        "It supports you in developing a mindset of abundance, attracting opportunities, and taking inspired action.",
        "It assists in overcoming self-doubt and fear, allowing you to step into your power and go after what you want."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you may start to notice a shift in your belief system and mindset. You may begin to feel more empowered, motivated, and aligned with your goals. Remember, manifesting your desires is a journey that requires patience and dedication, but with the right mindset, anything is possible."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Building a strong inner belief and conviction is essential for manifesting your desires and achieving your goals. This subliminal is designed to support you in developing that belief, but there are certain steps you can take before you start listening to optimize the effectiveness of the program:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your desires: Clearly define what you want to manifest in your life, whether it's career success, financial abundance, or fulfilling relationships.",
        "Visualize your success: Imagine already having achieved your desired outcomes. Feel the emotions and embody the confidence associated with your accomplishments.",
        "Create affirmations: Write down positive affirmations that align with your desires and reflect your belief in your ability to manifest them.",
        "Stay open and receptive: Have an open mind and be willing to embrace new beliefs and perspectives that will support your manifestation journey."
      ]
    },
    {
      type: 'paragraph',
      text: "By preparing yourself mentally and emotionally, you set a strong foundation for the subliminal affirmations to work deeply within your subconscious mind. With consistent listening, you can cultivate a powerful inner belief and conviction that propels you towards your goals. Embrace this opportunity to tap into your true potential and let the manifestation begin!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Inner Belief and Conviction Manifestation subliminal! By focusing on developing a strong inner belief and conviction, you've taken a significant step towards manifesting your desires and achieving your goals. Reflect on your experience and embrace the power that lies within you to create the life you truly want."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Visualize your success: Envision yourself achieving your goals in vivid detail to strengthen your belief in your ability to manifest.",
        "Challenge limiting beliefs: Identify and replace any negative or doubtful thoughts with positive affirmations.",
        "Set clear intentions: Define your goals and desires clearly to give yourself a clear target to focus on.",
        "Take inspired action: Use your newfound belief and conviction to take practical steps towards your goals."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, your inner belief and conviction are powerful tools that can help you overcome obstacles and manifest the life you desire. Trust in yourself and your abilities, and continue taking inspired action towards your dreams. You have the power to create your own reality. Believe it!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Inner Belief and Conviction Manifestation subliminal work?`, 
    answer: `The Inner Belief and Conviction Manifestation subliminal uses positive affirmations designed to reprogram your subconscious mind and reinforce a strong inner belief and conviction. By consistently listening to the audio, you can align your thoughts with your desires and goals, helping to manifest them in your reality. This subliminal aims to create a mindset that is confident, determined, and unwavering in the pursuit of your dreams.`,
    open: false
  },
  { 
    question: `How long before I start seeing results with the Inner Belief and Conviction Manifestation subliminal?`, 
    answer: `Results may vary from person to person, and it typically takes time to see noticeable changes. Some individuals may experience positive shifts in their belief and conviction within a few weeks of consistent listening, while others may require more time. Remember to be patient and continue listening daily for best results.`,
    open: false 
  },
  { 
    question: `How can I enhance the effectiveness of the Inner Belief and Conviction Manifestation subliminal?`, 
    answer: `To enhance the effectiveness of the Inner Belief and Conviction Manifestation subliminal, it is important to visualize yourself already achieving your goals and living your desired reality. Create a daily visualization practice that complements your listening routine. Additionally, taking inspired action towards your goals, maintaining a positive mindset, and practicing gratitude can further support the manifestation process.`,
    open: false 
  },
  { 
    question: `Is it necessary to listen to the Inner Belief and Conviction Manifestation subliminal every day?`, 
    answer: `Consistency is key when using subliminal audio, so it is recommended to listen to the Inner Belief and Conviction Manifestation subliminal every day. The more frequently you listen, the more the positive affirmations can penetrate your subconscious mind and reinforce the desired belief and conviction. Daily listening helps build momentum and maintain focus on your goals.`,
    open: false 
  },
  { 
    question: `Can the Inner Belief and Conviction Manifestation subliminal manifest anything I desire?`, 
    answer: `The Inner Belief and Conviction Manifestation subliminal is designed to help you strengthen your inner belief and conviction towards your goals and desires, supporting the manifestation process. While it can be a powerful tool, it is important to remember that manifestation involves a combination of mindset, inspired action, and external factors. Results may vary, and the subliminal is not a guarantee of specific outcomes.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Inner Belief and Conviction Manifestation subliminal?`, 
    answer: `There are no known risks or side effects associated with listening to the Inner Belief and Conviction Manifestation subliminal. However, it is important to set realistic goals, trust the process, and listen to your intuition while working towards your desires. If you have any concerns, consult with your healthcare provider or trusted professional.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>