<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're looking to lose weight in a safe and sustainable way, this subliminal program can provide the support and guidance you need. It is designed to help you achieve your weight loss goals while improving your overall health and well-being."
    },
    {
      type: 'heading',
      text: 'Key features and benefits of this subliminal program:'
    },
    {
      type: 'list',
      items: [
        "Promotes healthy eating habits and portion control.",
        "Boosts motivation and discipline to stick to your weight loss plan.",
        "Encourages regular physical activity and exercise.",
        "Helps you develop a positive body image and self-confidence.",
        "Provides subconscious support to overcome emotional eating and food cravings.",
        "Fosters a supportive and empowering mindset towards your weight loss journey."
      ]
    },
    {
      type: 'paragraph',
      text: "By consistently listening to this subliminal program, you can reprogram your subconscious mind to support your weight loss efforts and adopt a healthier lifestyle. Remember, sustainable weight loss takes time and effort, but with the right mindset and support, you can achieve your goals and improve your overall health."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a weight loss journey can be a challenging endeavor, but with the right mindset and support, you can achieve your goals. This subliminal is designed to provide you with the motivation, mindset, and determination to make lasting changes. Before you start listening, here are some tips to help you prepare:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set realistic goals: Identify what you want to achieve and break it down into small, actionable steps.",
        "Create a support system: Surround yourself with people who will encourage and motivate you throughout your journey.",
        "Educate yourself: Learn about healthy eating habits, portion sizes, and nutrition to make informed choices.",
        "Practice self-compassion: Be kind to yourself and embrace the process, understanding that weight loss is a gradual journey."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to help you reprogram your subconscious mind and establish new habits and beliefs around weight loss. By staying committed, focused, and resilient, you can make significant progress towards your weight loss goals. So take a moment to set your intentions, visualize your success, and let's begin this transformative journey together."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the General Weight Loss program! By embarking on this journey, you've taken a significant step towards achieving your weight loss goals and improving your overall health. Now that you've finished listening to the subliminal session, it's important to reflect on your progress and continue making positive changes."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Monitor your progress: Track your weight, measurements, and body composition regularly to see how your body is changing.",
        "Stay committed: Stick to your weight loss plan and make healthy choices consistently.",
        "Find support: Surround yourself with people who encourage and support your weight loss journey.",
        "Stay active: Incorporate different forms of physical activity into your routine to boost your weight loss efforts."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, weight loss is a gradual process that requires dedication and persistence. Focus on making sustainable lifestyle changes rather than resorting to restrictive diets. Take pride in your progress and celebrate even the smallest victories along the way. You're on your way to achieving the healthy, vibrant life you desire!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the General Weight Loss program work?`, 
    answer: `The General Weight Loss program is designed to help you achieve your weight loss goals by incorporating a combination of healthy eating, regular exercise, and mindset shifts. The program provides guidance on creating a balanced and nutritious diet, developing an exercise routine, and cultivating a positive and motivated mindset towards weight loss. By following the program consistently, you can improve your overall health and achieve sustainable weight loss results.`,
    open: false 
  },
  { 
    question: `How long will it take to see results with the General Weight Loss program?`, 
    answer: `Weight loss results can vary from person to person depending on various factors such as starting weight, metabolism, and adherence to the program. It's important to approach weight loss as a gradual and sustainable process rather than expecting immediate results. With dedication and consistency, you can start noticing positive changes in your body and health within a few weeks or months.`,
    open: false 
  },
  { 
    question: `Can I customize the General Weight Loss program to fit my needs and preferences?`, 
    answer: `Yes, the General Weight Loss program can be customized to fit your needs and preferences. It provides a framework for healthy eating and regular exercise, but you can adapt it based on your dietary restrictions, preferences, and fitness level. It's important to listen to your body and make adjustments that work best for you while still staying aligned with the principles of the program.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with the General Weight Loss program?`, 
    answer: `The General Weight Loss program focuses on promoting healthy habits and sustainable weight loss, so there are no known risks or side effects associated with following the program. However, it's always a good idea to consult with your healthcare provider before starting any weight loss program, especially if you have any pre-existing medical conditions or concerns.`,
    open: false 
  },
  { 
    question: `Is the General Weight Loss program suitable for everyone?`, 
    answer: `The General Weight Loss program is designed to be suitable for most individuals aiming to lose weight in a healthy and sustainable way. However, it's important to consider your personal health and individual circumstances. If you have any underlying medical conditions or concerns, it's recommended to consult with your healthcare provider before starting the program.`,
    open: false 
  },
  { 
    question: `Can the General Weight Loss program be combined with other weight loss methods or programs?`, 
    answer: `The General Weight Loss program can be combined with other weight loss methods or programs as long as they are safe and aligned with your health goals. It's important to listen to your body and make sure the combined approaches are balanced and sustainable. If you have any concerns, it's best to consult with a healthcare professional or a certified nutritionist.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>