<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Charisma is a powerful trait that can greatly enhance your social and professional interactions. With this subliminal, you can tap into your inner charisma and develop the personal magnetism that attracts and engages others."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help you:"
    },
    {
      type: 'list',
      items: [
        "Enhancing your self-confidence and belief in your own abilities.",
        "Improving your communication skills and ability to connect with others.",
        "Increasing your presence and charisma, making you more captivating and influential.",
        "Boosting your social skills and making you more comfortable in any social setting.",
        "Developing your emotional intelligence, allowing you to understand and relate to others more effectively."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to naturally embody charisma and attract positive interactions. Over time, you may notice that people are drawn to your presence, and you feel more confident and at ease in social or professional situations. Remember, developing charisma is a journey, but with consistent practice, you can unlock your full charismatic potential."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Developing charisma and enhancing personal magnetism can have a significant impact on your social and professional interactions. This subliminal is designed to assist you in cultivating those qualities. Before you begin, consider the following preparation tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Clarify your goals for developing charisma and attracting others in social or professional settings.",
        "Embrace positive self-talk: Adopt empowering affirmations to reinforce your charisma and personal magnetism.",
        "Practice confident body language: Pay attention to your posture, eye contact, and nonverbal cues to project charisma and engagement.",
        "Visualize success: Imagine yourself effortlessly connecting with others and radiating positive energy."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can strengthen your magnetic presence, boost your confidence, and attract positive interactions with others. Embrace this opportunity to enhance your personal charisma, and let the transformation begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Charisma Development subliminal! By working on your charisma and personal magnetism, you've taken a significant step towards enhancing your social and professional interactions. Now, it's time to reflect on your experience and continue your journey towards being a more captivating and engaging individual."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice active listening: Show genuine interest in others and focus on building connections.",
        "Work on your body language: Be aware of your posture, gestures, and facial expressions to convey confidence and openness.",
        "Develop your communication skills: Work on expressing yourself clearly and effectively.",
        "Expand your social circle: Engage in activities and events that allow you to meet new people and broaden your network."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, developing charisma is an ongoing process. It's about continuously honing your skills and embracing authenticity. As you implement these post-listening tips, observe how your interactions and relationships evolve. Enjoy the journey of becoming a more charismatic and magnetic individual!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Charisma Development subliminal help me?`, 
    answer: `The Charisma Development subliminal is designed to help you enhance your personal magnetism, confidence, and presence. By listening to the subliminal regularly, your subconscious mind can absorb positive affirmations that will shape your thoughts, behavior, and energy in a way that attracts and engages others. You may notice an increase in your ability to captivate an audience, build rapport, and make a positive impression in social or professional settings.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Charisma Development subliminal?`, 
    answer: `Results can vary depending on the individual and their unique circumstances. Some people may notice positive changes in their charisma and personal magnetism right away, while others may require more time and consistent listening to fully integrate the affirmations into their subconscious mind. We recommend listening to the subliminal daily and consistently for best results.`,
    open: false 
  },
  { 
    question: `How can I maximize the effectiveness of the Charisma Development subliminal?`, 
    answer: `You can enhance the effectiveness of the subliminal by practicing and applying the skills and techniques you learn in real-life social interactions. Actively seeking out opportunities for personal growth and self-improvement, such as attending public speaking or communication workshops, can further amplify the impact of the subliminal. Additionally, being mindful of your body language, tone of voice, and listening skills can also help you cultivate your charisma beyond the subliminal's influence.`,
    open: false 
  },
  { 
    question: `Can the Charisma Development subliminal replace developing genuine social skills?`, 
    answer: `No, the Charisma Development subliminal is designed to enhance and amplify your existing social skills, not replace them. It provides subconscious support and helps align your mindset with charismatic behavior, but it is important to actively practice and develop genuine social skills through real-life experiences, self-reflection, and feedback from others.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Charisma Development subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your social or mental well-being, please consult with a mental health professional. It is important to note that the subliminal is intended to supplement your personal growth journey, not replace it.`,
    open: false 
  },
  { 
    question: `Will the Charisma Development subliminal make me appear fake or insincere?`, 
    answer: `No, the Charisma Development subliminal is not designed to make you appear fake or insincere. Its purpose is to help you develop and enhance your genuine charisma, authenticity, and personal magnetism. The subliminal aims to align your thoughts, behavior, and energy with charismatic traits, allowing you to make a positive and authentic connection with others.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>