<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Going through a breakup can be one of the most challenging and emotionally draining experiences in life. This subliminal is designed to support your healing journey and help you recover from the pain."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can assist you:'
    },
    {
      type: 'list',
      items: [
        "Letting go of the pain, resentment, and negative emotions associated with the breakup.",
        "Finding closure and acceptance, allowing yourself to move forward with your life.",
        "Building resilience and inner strength, so you can navigate through the healing process.",
        "Rebuilding self-confidence and self-esteem, rediscovering your worth and value.",
        "Attracting positivity and new opportunities for love and happiness into your life."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, healing from a breakup takes time and self-care. By regularly listening to this subliminal, you can rewire your subconscious mind and support your emotional healing journey. Allow yourself the space and time to heal, and trust that happiness and love will find their way back to you."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Recovering from a breakup and finding emotional healing can be a challenging process, but this subliminal is here to support you on your journey. Before you begin, consider implementing these strategies to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your emotions: Allow yourself to feel and process the pain, sadness, anger, or any other emotions associated with the breakup.",
        "Create a self-care routine: Focus on taking care of your physical, emotional, and mental well-being with activities that bring you joy and peace.",
        "Practice self-compassion: Be kind and understanding towards yourself as you navigate through the healing process.",
        "Set clear intentions: Formulate specific intentions for your emotional healing and focus on them while listening to the subliminal affirmations."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, it can help you release emotional pain, let go of the past, and move forward towards healing and happiness. Embrace the journey of healing and growth, and remember to be patient and gentle with yourself throughout the process. Together, we will navigate through this difficult phase and emerge stronger on the other side."
    }
  ]
},
            {
  id: 'after-listening',
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Break-up Emotional Healing and Recovery subliminal session! You've taken an important step towards healing from your breakup and finding happiness again. Now, it's time to reflect on your experience and continue moving forward on your journey of emotional healing."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your emotions: Allow yourself to feel and process the range of emotions that come with a breakup.",
        "Practice self-care: Take care of your physical, mental, and emotional well-being by engaging in activities that bring you joy and relaxation.",
        "Seek support: Reach out to friends, family, or a therapist who can provide guidance and a listening ear during this healing process.",
        "Focus on personal growth: Use this opportunity to rediscover yourself, set new goals, and explore new interests and hobbies.",
        "Let go of negative thoughts: Challenge negative self-talk and replace it with positive affirmations and self-compassion.",
        "Give yourself time: Healing takes time, so be patient with yourself and allow the healing process to unfold naturally."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, healing from a breakup is a journey, and it's normal to have ups and downs along the way. Trust in your ability to heal and find happiness again. Each day, you are growing stronger and closer to a brighter future. Keep moving forward, and know that you deserve love, happiness, and a fulfilling life. You've got this!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How long does it take to heal from a breakup using the Break-up Emotional Healing and Recovery subliminal?`, 
    answer: `The healing process after a breakup can vary from person to person and depend on the individual's circumstances. It is important to give yourself time and patience to heal at your own pace. The Break-up Emotional Healing and Recovery subliminal is designed to support and facilitate the healing process, but there is no definitive timeline for healing. Consistent listening to the subliminal can aid in reprogramming your subconscious mind and promoting emotional healing.`,
    open: false
  },
  { 
    question: `Can the Break-up Emotional Healing and Recovery subliminal completely eliminate the pain of a breakup?`, 
    answer: `The Break-up Emotional Healing and Recovery subliminal is designed to help facilitate healing and recovery from a breakup. While it can be a helpful tool in dealing with the emotional pain, it may not completely eliminate all the pain associated with the breakup. Healing is a process, and everyone's journey is unique. The subliminal can provide support and guidance as you work towards healing and finding happiness again.`,
    open: false 
  },
  { 
    question: `How often should I listen to the Break-up Emotional Healing and Recovery subliminal?`, 
    answer: `For best results, we recommend listening to the Break-up Emotional Healing and Recovery subliminal on a regular basis. Consistency is key in reprogramming your subconscious mind and facilitating emotional healing. Find a routine that works for you, whether it's listening daily, multiple times a week, or during moments when you need extra support.`,
    open: false 
  },
  { 
    question: `Can the Break-up Emotional Healing and Recovery subliminal help me find happiness again?`, 
    answer: `Yes, the Break-up Emotional Healing and Recovery subliminal is designed to help you heal from a breakup and move forward towards finding happiness again. It can aid in shifting your mindset, promoting self-love, and empowering you to embrace new beginnings. It is important to remember that healing is a process, and finding happiness may take time, but the subliminal can be a valuable tool in that journey.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Break-up Emotional Healing and Recovery subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Break-up Emotional Healing and Recovery subliminal. However, if you have any concerns about your emotional well-being or mental health, it is always recommended to seek professional help. The subliminal is intended to support and complement healing efforts, but it is not a substitute for professional assistance.`,
    open: false 
  },
  { 
    question: `Will listening to the Break-up Emotional Healing and Recovery subliminal make me forget my ex?`, 
    answer: `The Break-up Emotional Healing and Recovery subliminal is not intended to make you forget your ex. It is designed to facilitate healing, support emotional recovery, and help you move forward towards finding happiness. While you may still have memories, the subliminal can assist in shifting your focus towards personal growth, self-care, and embracing a brighter future.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>