<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "When it comes to building and maintaining muscle, proper recovery is essential. This subliminal is specifically designed to assist in speeding up the muscle recovery process and reducing muscle soreness."
    },
    {
      type: 'heading',
      text: 'Key benefits of using this muscle recovery aid include:'
    },
    {
      type: 'list',
      items: [
        "Enhanced muscle repair and regeneration, leading to faster recovery after workouts.",
        "Reduced muscle soreness and decreased post-exercise discomfort.",
        "Optimized muscle growth and improved muscle strength.",
        "Increased blood flow and improved circulation to support the delivery of nutrients to muscles.",
        "Enhanced relaxation and stress relief to aid in overall muscle recovery."
      ]
    },
    {
      type: 'paragraph',
      text: "Incorporating this subliminal into your recovery routine can help support your body's natural healing processes and maximize the benefits of your workouts. By listening regularly, you can experience reduced muscle soreness, faster recovery, and improved overall muscle health. Remember, consistency is key to achieving the best results."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Enhancing your muscle recovery process and reducing muscle soreness is crucial for achieving optimal performance and pushing your physical limits. This muscle recovery aid is designed to support your body's healing and regeneration. Here are a few tips to maximize the benefits:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Stay hydrated: Proper hydration is essential for muscle recovery. Make sure you drink enough water throughout the day and especially during your workouts.",
        "Fuel your body: Consume a balanced diet rich in protein, healthy fats, and carbohydrates to provide your muscles with the necessary nutrients for repair and growth.",
        "Rest and sleep: Prioritize rest days and quality sleep to allow your muscles time to recover.",
        "Stretching and foam rolling: Incorporate stretching and foam rolling exercises into your routine to improve flexibility, increase blood flow, and relieve muscle tension.",
        "Mental preparation: Before listening to the muscle recovery aid, visualize your muscles healing, becoming stronger, and feeling refreshed and energized."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember that consistency is key when it comes to optimizing muscle recovery. By incorporating this muscle recovery aid into your routine and adopting these preparation tips, you can accelerate your body's healing process and experience reduced muscle soreness. Get ready to unleash your full potential and achieve your fitness goals!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Muscle Recovery Aid subliminal session! By focusing on accelerating your muscle recovery process and reducing soreness, you've taken a significant step towards optimizing your fitness journey. It's important to reflect on your experience and continue supporting your body's recovery needs."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Listen to your body: Pay attention to any signals of fatigue or injury and adjust your fitness routine accordingly.",
        "Fuel your body: Consume a balanced diet with sufficient protein to support muscle repair and recovery.",
        "Incorporate active recovery: Engage in low-intensity activities like stretching, yoga, or swimming to promote blood flow and aid in muscle recovery.",
        "Prioritize rest and sleep: Allow adequate time for your body to rest and repair through quality sleep."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, muscle recovery is an essential component of any fitness routine. Take care of your body by listening to its needs, providing proper nutrition, and allowing ample time for rest and rejuvenation. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Muscle Recovery Aid work?`, 
    answer: `The Muscle Recovery Aid is designed to complement your body's natural healing processes and promote faster muscle recovery. By providing the body with essential nutrients and supporting cellular repair, the subliminal aids in reducing muscle soreness and promoting overall muscle healing. It contains subliminal messages that target the subconscious mind to enhance the body's natural ability to recover from physical exertion and exercise.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Muscle Recovery Aid?`, 
    answer: `Results can vary depending on the individual and the extent of muscle damage or soreness. Some may experience relief and improved recovery within a few days of listening to the subliminal, while others may require longer periods of consistent use. We recommend incorporating the Muscle Recovery Aid into your post-workout routine and listening to it regularly for optimal results.`,
    open: false 
  },
  { 
    question: `Can the Muscle Recovery Aid be used for any type of physical activity or workout?`, 
    answer: `Yes, the Muscle Recovery Aid can be used after various types of physical activity, including strength training, cardio, and endurance exercises. It is designed to support muscle recovery and reduce soreness regardless of the specific workout or activity.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with using the Muscle Recovery Aid?`, 
    answer: `No, there are no known risks or side effects associated with using the Muscle Recovery Aid. However, if you have any concerns about your health or experience any unusual symptoms, it is recommended to consult with a healthcare professional.`,
    open: false 
  },
  { 
    question: `Can the Muscle Recovery Aid replace medical treatment or advice for injuries?`, 
    answer: `No, the Muscle Recovery Aid is not intended to replace medical treatment or professional advice for injuries. It is designed to complement and support the body's natural healing processes but should not be considered a substitute for appropriate medical care. If you have any injuries or health concerns, please seek guidance from a qualified healthcare provider.`,
    open: false 
  },
  { 
    question: `Can the Muscle Recovery Aid be used by individuals with underlying health conditions?`, 
    answer: `The Muscle Recovery Aid is generally safe for use by individuals with underlying health conditions. However, if you have any specific health concerns or conditions, it is advisable to consult with a healthcare professional before incorporating it into your routine to ensure it is appropriate for your individual needs.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>