<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you've been struggling with a bacterial infection, this guided program is here to help. By combining powerful affirmations and relaxation techniques, it can support your body's natural healing processes and help you regain your health."
    },
    {
      type: 'heading',
      text: 'Here are some ways this guided program can assist you:'
    },
    {
      type: 'list',
      items: [
        "Boosting your immune system to fight off the bacterial infection more effectively.",
        "Reducing inflammation and promoting faster healing within your body.",
        "Providing moments of relaxation and stress relief, which can support your overall well-being.",
        "Helping you maintain a positive mindset and stay motivated throughout your healing journey."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, healing from a bacterial infection takes time and patience. By incorporating this guided program into your healing routine, you can work towards a healthier body and a stronger immune system. Stay consistent and trust in your body's ability to heal."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Overcoming bacterial infections can be a challenging process, but this guided program aims to support your healing journey. Before starting, here are some suggestions to help you prepare for the upcoming experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Educate yourself: Learn about the specific bacterial infection you're dealing with, its symptoms, and treatment options.",
        "Consult a medical professional: Seek guidance from a healthcare provider to ensure the program aligns with your unique needs and health background.",
        "Follow your prescribed treatment: If you've been prescribed antibiotics or other medications, be sure to follow your doctor's instructions.",
        "Engage in self-care: Nurture your body by getting enough rest, staying hydrated, and eating nutritious foods to support your immune system.",
        "Set your intention: Focus on your desire to heal, regain your health, and boost your immune system."
      ]
    },
    {
      type: 'paragraph',
      text: "This guided program is designed to work in harmony with your body's natural healing abilities. By listening to the program regularly and embracing a holistic approach to healing, you may find relief from symptoms and experience improvements in your overall well-being. Take a deep breath, trust the process, and let the healing begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Bacterial Infection Healing program! By investing your time and energy in this guided program, you've taken an important step towards healing your body and boosting your immune system. As you continue your journey towards regaining your health, it's important to reflect on your experience and implement post-listening tips to support your healing process."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Listen to your body: Pay attention to any changes or improvements in your overall well-being.",
        "Continue any prescribed treatments: Follow your doctor's advice and complete any prescribed medications or treatments.",
        "Boost your immune system: Focus on nourishing your body with nutrient-rich foods, staying hydrated, and maintaining a healthy lifestyle.",
        "Practice good hygiene: Follow proper hygiene practices to prevent the spread of bacteria and infections.",
        "Seek medical advice: If symptoms persist or worsen, consult a healthcare professional for further guidance."
      ]
    },
    {
      type: 'paragraph',
      text: "Healing from bacterial infections requires time, patience, and consistent effort. Trust in your body's ability to heal, and take proactive steps to support your immune system and overall well-being. Remember, you are strong and resilient, and with proper care and attention, your health will be restored. Wishing you a healthy and vibrant future!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Bacterial Infection Healing program work?`, 
    answer: `The Bacterial Infection Healing program utilizes a combination of guided visualization, positive affirmations, and relaxation techniques to promote healing and boost the immune system. By listening to the program regularly, you can align your mind and body to assist in the healing process and support your overall well-being.`,
    open: false
  },
  { 
    question: `Can the Bacterial Infection Healing program cure all types of bacterial infections?`, 
    answer: `The Bacterial Infection Healing program is designed to complement and support the body's natural healing process. While it may assist in boosting the immune system and promoting overall health, it is not a substitute for professional medical treatment. If you suspect you have a bacterial infection, it is important to seek medical advice and follow the prescribed treatment plan in addition to incorporating this program into your wellness routine.`,
    open: false 
  },
  { 
    question: `How often should I listen to the Bacterial Infection Healing program?`, 
    answer: `To maximize the benefits of the Bacterial Infection Healing program, we recommend listening to it regularly. This can vary depending on your individual circumstances, but consistency is key. You may choose to listen once or twice a day, or as often as you feel necessary to support your healing journey. Remember to also follow any medical advice given by your healthcare provider.`,
    open: false 
  },
  { 
    question: `Are there any side effects or risks associated with the Bacterial Infection Healing program?`, 
    answer: `No known side effects or risks are associated with listening to the Bacterial Infection Healing program. However, if you have any concerns about your health or the program's suitability for your specific condition, it is recommended to consult with your healthcare provider.`,
    open: false 
  },
  { 
    question: `Can the Bacterial Infection Healing program be used alongside antibiotics or other medications?`, 
    answer: `Yes, the Bacterial Infection Healing program can be used alongside antibiotics or other medications prescribed by your healthcare provider. It is designed to complement and support medical treatments. However, it is important to follow your healthcare provider's instructions and consult with them before making any changes to your treatment plan.`,
    open: false 
  },
  { 
    question: `Can the Bacterial Infection Healing program prevent future bacterial infections?`, 
    answer: `The Bacterial Infection Healing program aims to support the body's immune system and overall wellness, which may contribute to reducing the risk of future infections. However, it is not a guarantee or a substitute for practicing good hygiene, following appropriate medical advice, and taking necessary precautions to prevent bacterial infections.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>