import request from "@/utils/request";

export const createPaymentLink = (data) => {
  return request.post("/api/user/create/payment/link/web", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const paymentWebhookEnable = (data) => {
  return request.post("/api/user/payment/webhook/enable", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const createPayment = (data) => {
  return request.post("/api/user/payment/record", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};
