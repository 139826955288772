<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Telepathy, the ability to communicate with others through thought, is a skill that many believe to be within the realm of human potential. This subliminal is designed to help unlock and enhance your telepathic abilities, allowing you to connect with others on a deep and intuitive level."
    },
    {
      type: 'heading',
      text: 'Benefits of developing telepathy include:'
    },
    {
      type: 'list',
      items: [
        "Improved intuition and the ability to sense others' thoughts and emotions.",
        "Enhanced communication skills, both verbal and nonverbal.",
        "Greater empathy and understanding in your relationships.",
        "Increased connection and synergy with others.",
        "Heightened awareness and perception of energy fields."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal and practicing exercises to develop your telepathic skills, you can strengthen your ability to connect with others on a deeper level. It's important to approach this practice with an open mind and patience, as telepathy is a skill that requires time and practice to develop. With dedication and consistency, you may experience heightened abilities of communication and connection that can enhance various aspects of your life."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on the journey to develop telepathy is an exciting endeavor that can enhance your communication skills and deepen your connections with others. Before you start listening to this subliminal, here are some tips to help you prepare:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a quiet space: Find a peaceful environment where you can listen to the subliminal without distractions.",
        "Relax your mind and body: Prior to listening, take a few moments to calm your thoughts and release any tension or stress.",
        "Open your mind: Approach this session with an open and receptive mindset, allowing yourself to explore the possibilities of telepathic communication.",
        "Focus on your intention: Clearly define your desire to develop telepathy and communicate with others on a deep and intuitive level."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating these preparation tips into your routine, you can maximize the benefits of this subliminal and enhance your telepathic abilities. Allow yourself to embrace the power of your mind, and let the journey to enhanced communication begin!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Develop Telepathy subliminal session! By engaging in this practice, you have taken a significant step towards unlocking the potential of your mind and enhancing your ability to connect with others on a deep and intuitive level. Now, it's time to reflect on your experience and continue your journey of telepathic development."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Journal your experiences: Write down any telepathic experiences or intuitive insights you have.",
        "Practice visualization: Enhance your telepathic abilities by visualizing yourself successfully connecting with others.",
        "Meditate: Regular meditation can help calm your mind and improve your focus, which can enhance your telepathic abilities.",
        "Trust your intuition: Listen to your gut instincts and rely on your intuition when trying to connect with others telepathically."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, developing telepathy is a process that takes time and practice. Embrace each step of your journey and trust in the power of your mind. With dedication and belief in yourself, you can continue to unlock your telepathic potential and deepen your connections with others. Happy telepathic adventures!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Is telepathy real?`, 
    answer: `The existence of telepathy is a topic of debate among scientists and researchers. While there is anecdotal evidence and personal experiences that suggest telepathic communication may be possible, it has not been scientifically proven.`,
    open: false
  },
  { 
    question: `Can the Develop Telepathy subliminal help me develop telepathic abilities?`, 
    answer: `The Develop Telepathy subliminal is designed to help you enhance your intuitive and empathic abilities, which can potentially assist in developing stronger connections with others. However, it is important to note that telepathy, if it exists, may involve various factors beyond just listening to a subliminal audio.`,
    open: false 
  },
  { 
    question: `How long does it take to develop telepathic skills with the subliminal?`, 
    answer: `The time it takes to develop telepathic skills can vary depending on the individual and other factors. Developing telepathy, if possible, may require significant time and practice. The Develop Telepathy subliminal can serve as a tool to support your personal development journey, but it is important to manage your expectations and be patient with the process.`,
    open: false 
  },
  { 
    question: `Can everyone develop telepathic abilities?`, 
    answer: `It is unclear whether everyone has the potential to develop telepathic abilities as it has not been scientifically proven. It is possible that some individuals may naturally possess stronger intuitive or empathic abilities, while others may require more practice and training to develop these skills further.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Develop Telepathy subliminal?`, 
    answer: `There are no known risks or side effects associated with listening to the Develop Telepathy subliminal. It is important to approach the subliminal with an open mind and use it as a tool for personal growth and exploration. However, if you have any concerns or experience any unusual effects, it is advisable to discontinue use and consult a healthcare professional.`,
    open: false 
  },
  { 
    question: `Is telepathy a replacement for verbal communication?`, 
    answer: `Telepathy, if it exists, would not necessarily replace verbal communication but rather act as an additional means of connection and understanding. Verbal communication serves as a vital aspect of human interaction, while telepathy, if real, could potentially enhance and deepen our connections on an intuitive level.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>