<template>
  <div>
    <AboutMagus />
  </div>
</template>

<script>
import AboutMagus from '@/views/Main/AboutMagus'
export default {
  components: {
    AboutMagus
  }
}
</script>