<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you often find yourself struggling to express your needs and desires assertively, this subliminal can help you develop the necessary skills and mindset to communicate effectively while maintaining respectful relationships."
    },
    {
      type: 'heading',
      text: 'Here are some key aspects that this subliminal focuses on:'
    },
    {
      type: 'list',
      items: [
        "Increasing your self-confidence and self-esteem to feel more empowered in expressing yourself.",
        "Developing clear and effective communication skills to express your thoughts and opinions.",
        "Learning to set boundaries and assert your needs without being aggressive or passive.",
        "Building courage and resilience to handle challenging or uncomfortable situations assertively.",
        "Improving your empathy and listening skills to better understand others and foster healthy relationships."
      ]
    },
    {
      type: 'paragraph',
      text: "By consistently listening to this subliminal, you can rewire your subconscious mind to adopt assertiveness as a natural and effective communication style. Over time, you may notice positive changes in your ability to express yourself confidently, assertively communicate your needs, and maintain respectful relationships. Remember, practice and persistence are key to mastering assertiveness."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Becoming more assertive in your communication can greatly enhance your relationships and overall well-being. This subliminal is designed to help you develop assertiveness skills while maintaining respect for others. Before you begin, consider these tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Identify your motivations: Reflect on why you want to become more assertive and how it can benefit your life and relationships.",
        "Practice self-awareness: Observe your behavior and communication patterns, identifying areas where you can improve.",
        "Set clear boundaries: Determine what is acceptable and not acceptable to you, and communicate those boundaries to others.",
        "Focus on empathy: Remember that being assertive doesn't mean being aggressive; strive to understand others' perspectives and express your needs with compassion."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal, allow the messages to sink into your subconscious, helping you develop assertiveness skills that empower you to communicate your needs and desires effectively. Remember, assertiveness is a journey, and with regular practice, you will become more confident in expressing yourself while maintaining respectful relationships. Let's begin this transformative process together!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Assertiveness Training subliminal! By focusing on developing your assertiveness skills, you are taking a significant step towards improving your communication and relationships. Now, it's time to reflect on your experience and incorporate assertiveness into your daily life."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice self-reflection: Take time to reflect on your communication style and any changes you have noticed.",
        "Set clear boundaries: Learn to establish and communicate your boundaries effectively.",
        "Use 'I' statements: When expressing your needs or desires, use 'I' statements to communicate assertively without blaming or accusing others.",
        "Learn active listening: Develop the skill of truly listening and understanding others' perspectives before responding.",
        "Practice assertive body language: Pay attention to your posture, eye contact, and tone of voice, ensuring they reflect your assertiveness."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, assertiveness is about expressing yourself confidently and respectfully. It may take time to fully integrate these skills into your life, so be patient with yourself. Each step you take towards assertiveness is a step towards healthier and more fulfilling relationships. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What is assertiveness?`, 
    answer: `Assertiveness is the ability to express your thoughts, opinions, needs, and desires in a direct and respectful manner. It involves standing up for yourself while considering the rights and feelings of others. Assertive communication promotes open dialogue, builds self-confidence, and helps establish healthier boundaries in relationships.`,
    open: false
  },
  { 
    question: `How can the Assertiveness Training subliminal help me?`, 
    answer: `The Assertiveness Training subliminal is designed to influence your subconscious mind with positive affirmations that promote assertive behavior and communication. By listening to the subliminal regularly, you can strengthen your assertiveness skills, increase self-confidence, develop effective communication techniques, and enhance your ability to express your needs and desires while maintaining respectful relationships.`,
    open: false 
  },
  { 
    question: `How long does it take to see results from the Assertiveness Training subliminal?`, 
    answer: `Results can vary from person to person, but with consistent listening, you may start noticing positive changes in your assertiveness skills within a few weeks. Over time, the subliminal messages can help reprogram your subconscious mind and influence your behavior towards more assertive and confident communication.`,
    open: false 
  },
  { 
    question: `Can the Assertiveness Training subliminal make me aggressive or disrespectful towards others?`, 
    answer: `No, the Assertiveness Training subliminal is designed to help you develop healthy assertiveness skills, not to promote aggression or disrespectful behavior. It encourages effective communication and considerate expression of needs and desires while maintaining respect for others. It is important to approach assertiveness with empathy and understanding, fostering a positive and productive environment in your relationships.`,
    open: false 
  },
  { 
    question: `Is it necessary to practice assertiveness outside of listening to the subliminal?`, 
    answer: `While listening to the Assertiveness Training subliminal can help enhance your assertiveness skills, it is also beneficial to practice assertiveness techniques in your daily life. Engaging in role-playing exercises, seeking feedback, and consciously applying assertiveness skills in real-life scenarios can reinforce the positive changes brought about by the subliminal messages.`,
    open: false 
  },
  { 
    question: `Can the Assertiveness Training subliminal help with social anxiety?`, 
    answer: `The Assertiveness Training subliminal addresses the development of assertiveness skills and may indirectly assist with social anxiety by boosting self-confidence and reducing fear of expressing oneself. However, it is important to seek professional guidance for the treatment of social anxiety, as it may require comprehensive therapy and support.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>