<template>
  <div class="container">
    <TextEditor :text="text" @getText="getText" />
  </div>
</template>

<script>
import TextEditor from "@/components/Admin/TextEditor.vue";
export default {
  components: {
    TextEditor,
  },
  data: () => {
    return {
      text: null,

      // Sample Text with HTML Tag
      // text: "Hello World <b>!</b>",
    };
  },
  methods: {
    getText(val) {
      console.log(val);
      // this.text = val
    },
  },
};
</script>

<style scoped lang="postcss"></style>
