<template>
  <v-card
    class="item pa-4"
    @click="handleAction(item, index)"
    :style="`background: ${item.color}`"
  >
    <div class="d-flex gap-2">
      <v-avatar color="grey" tile size="55">
        <img :src="item.cover" alt="" />
      </v-avatar>
      <div class="d-flex flex-column">
        <div>
          <h6>{{ item.title }}</h6>
          <p>{{ item.description }}</p>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { getAudio } from "@/utils/audio";
export default {
  props: {
    index: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data: () => {
    return {
      getAudio,
    };
  },
  computed: {
    isRouteTrack() {
      return this.$route.path == "/user/track";
    },
    data() {
      return this.$store.state.user.track;
    },
    isTrackPlaylist() {
      return this.data.hasOwnProperty("subliminals");
    },
  },
  methods: {
    handleAction(data, index) {
      if (!this.isRouteTrack) {
        // Go to Track
        this.$store.dispatch("user/getTrack", data);
        this.$router.push("/user/track");
      } else {
        // play audio
        this.$store.dispatch("audio/getLoading", true);
        this.$store.dispatch("audio/getCurrentTime", 0);

        if (this.isTrackPlaylist) {
          this.getAudio(this.data, index);
        } else {
          this.getAudio(data);
        }
      }
    },
  },
};
</script>

<style scoped>
p {
  font-size: 10px !important;
  font-family: var(--montserrat) !important;

  display: -webkit-box;
  max-width: 300px;
  -webkit-box-orient: vertical;
  overflow: hidden;

  -webkit-line-clamp: 2;

  margin: 0px;
}

h6 {
  display: -webkit-box;
  max-width: 100%;
  -webkit-box-orient: vertical;
  overflow: hidden;

  -webkit-line-clamp: 1;
}

.item {
  min-width: calc((100% / 3) - 16px);
}

@media screen and (max-width: 1200px) {
  .item {
    min-width: calc((100% / 2) - 16px);
  }
}

@media screen and (max-width: 960px) {
  .item {
    min-width: calc((100% / 1));
  }
}
</style>
