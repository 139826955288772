<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're concerned about the harmful effects of second-hand smoke, this subliminal can be a powerful tool for you. By training your subconscious mind, it helps you eliminate second-hand smoke from your life and protect both yourself and your loved ones."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support you in this journey:'
    },
    {
      type: 'list',
      items: [
        "Increasing your awareness of the dangers of second-hand smoke and its impact on your health.",
        "Boosting your determination and willpower to create a smoke-free environment for yourself and your loved ones.",
        "Reducing cravings and temptations associated with second-hand smoke exposure.",
        "Strengthening your commitment to maintaining a healthy and smoke-free lifestyle.",
        "Enhancing your ability to communicate effectively with others about the importance of avoiding second-hand smoke."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, your decision to eliminate second-hand smoke from your life is a positive step towards better health and well-being. With the help of this subliminal, you can reinforce your commitment, overcome challenges, and create a smoke-free environment that promotes a healthier and safer lifestyle for everyone involved."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Taking steps to eliminate second-hand smoke from your life is a crucial decision for the health and well-being of yourself and your loved ones. By listening to this subliminal, you can reinforce your commitment to creating a smoke-free environment. Here are a few suggestions to consider before you start listening:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set a clear intention: Determine your motivation for eliminating second-hand smoke, whether it's to protect your health, the health of your loved ones, or create a smoke-free atmosphere.",
        "Establish boundaries: Communicate your decision to those around you and kindly but firmly ask them to respect your smoke-free environment.",
        "Create a support system: Reach out to friends, family, or support groups who can offer encouragement and understanding during this process.",
        "Focus on your goals: While listening, concentrate on your determination to remove second-hand smoke from your life and the positive impact it will have on your health and the health of those around you."
      ]
    },
    {
      type: 'paragraph',
      text: "Utilizing this subliminal can help reinforce your mindset and overcome any obstacles you may face on your journey to a smoke-free life. By cultivating a healthy and supportive environment, you are taking an essential step towards protecting yourself and your loved ones. So, take a moment to center yourself, establish your intention, and let's begin this transformative journey together!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Stop Second Hand Smoking subliminal session! By taking this important step towards protecting yourself and your loved ones from the harmful effects of second-hand smoke, you're making a significant impact on your health and well-being. Now, it's time to reflect on your experience and continue this journey towards a smoke-free environment."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a smoke-free zone: Establish areas in your home, workplace, and car where smoking is not allowed.",
        "Communicate with smokers: Encourage open and honest discussions about the health risks of second-hand smoke.",
        "Support smoking cessation: If someone you know is a smoker, provide resources and encouragement to help them quit.",
        "Educate others: Raise awareness about the dangers of second-hand smoke and promote a healthier environment for everyone."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, creating a smoke-free environment is an ongoing process. Stay committed to your goal, and continue advocating for a healthier lifestyle. By taking these steps, you're making a positive change and protecting the well-being of yourself and those around you. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Stop Second Hand Smoking subliminal help me?`, 
    answer: `The Stop Second Hand Smoking subliminal is designed to help you overcome the habit of being exposed to second-hand smoke. By listening to the audio regularly, it can support your subconscious mind in developing a stronger aversion to second-hand smoke and motivate you to create a smoke-free environment for yourself and your loved ones.`,
    open: false
  },
  { 
    question: `Will listening to the Stop Second Hand Smoking subliminal make me quit smoking?`, 
    answer: `The Stop Second Hand Smoking subliminal is specifically designed to help you eliminate exposure to second-hand smoke, rather than directly helping you quit smoking yourself. However, by cultivating a healthier mindset towards second-hand smoke, it may indirectly influence you to make healthier lifestyle choices, such as quitting smoking.`,
    open: false 
  },
  { 
    question: `Can the subliminal help me protect my loved ones from second-hand smoke?`, 
    answer: `Yes, the subliminal can help you become more conscious of the dangers of second-hand smoke and motivate you to take proactive steps in protecting your loved ones from its harmful effects. By focusing on building a smoke-free environment, you can create a healthier space for yourself and those around you.`,
    open: false 
  },
  { 
    question: `What are the benefits of eliminating second-hand smoke from my life?`, 
    answer: `Eliminating second-hand smoke from your life can have an array of health benefits. It reduces the risk of respiratory problems, cardiovascular diseases, and other smoking-related illnesses for both yourself and your loved ones. Additionally, creating a smoke-free environment can improve the overall air quality around you, leading to a healthier and more pleasant living space.`,
    open: false 
  },
  { 
    question: `How long will it take to see results from listening to the subliminal?`, 
    answer: `The time it takes to see results from listening to the Stop Second Hand Smoking subliminal may vary from person to person. It depends on various factors, such as individual receptiveness to subliminal messages and the consistency of listening. We recommend listening regularly and continuously for effective and lasting results.`,
    open: false 
  },
  { 
    question: `Is the Stop Second Hand Smoking subliminal safe to listen to?`, 
    answer: `Yes, the Stop Second Hand Smoking subliminal is safe to listen to. It uses positive affirmations and messages aimed at supporting a healthier mindset towards second-hand smoke. However, if you have any concerns or underlying health conditions, it is always advisable to consult with a healthcare professional.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>