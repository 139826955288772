<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Are you feeling overwhelmed by the constant stream of thoughts and distractions in your mind? This subliminal can help you find clarity of mind through guided visualization and mindfulness techniques."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can benefit you:"
    },
    {
      type: 'list',
      items: [
        "Reduce mental clutter and achieve a quieter, more focused mind.",
        "Enhance your ability to concentrate and stay present in the moment.",
        "Improve decision-making skills and problem-solving abilities.",
        "Experience a greater sense of calm and inner peace.",
        "Increase mental clarity and sharpen your focus.",
        "Promote overall cognitive function and mental well-being."
      ]
    },
    {
      type: 'paragraph',
      text: "With regular use of this subliminal, you can train your mind to filter out distractions and achieve a state of mental clarity. Whether you're studying, working, or simply trying to find peace amidst a busy day, this subliminal can support you in cultivating a clear and focused mind. Remember, like any skill, achieving mental clarity requires practice and consistency."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Achieving mental clarity can provide a sense of peace and focus in our often busy and hectic lives. This subliminal is designed to help you clear your mind from clutter and find that mental clarity you seek. Before starting your listening session, consider these suggestions to make the most out of it:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Find a quiet and comfortable space: Choose a location where you can relax and fully immerse yourself in the listening experience.",
        "Set an intention: Clarify in your mind what you hope to achieve with this subliminal. Whether it's to quiet your thoughts, find inner peace, or gain a fresh perspective, hold that intention in the forefront of your mind.",
        "Practice deep breathing: Take a few moments to engage in deep, slow breaths. This can help calm your mind and prepare you for the session ahead.",
        "Let go of expectations: Allow yourself to be open to experiencing whatever arises during the session. Avoid attaching expectations or judgments to the process.",
        "Focus on the present moment: As you listen, bring your attention back to the present moment whenever your mind starts to wander. Engage in the guided visualizations and mindfulness techniques offered in the subliminal."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is a tool to support your journey towards mental clarity. With regular listening and a willingness to let go of mental clutter, you can cultivate a clearer and more focused mind. Now, take a moment to set your intention, find your calm, and let the journey to mental clarity begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Clarity of Mind guided visualization and mindfulness subliminal! You've taken an essential step towards achieving mental clarity and freeing your mind from clutter. It's important to reflect on your experience and integrate what you've learned into your daily life."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice daily mindfulness: Dedicate a few minutes each day to engage in mindfulness techniques like deep breathing or body scan meditation.",
        "Declutter your physical environment: Create a clean and organized space to support mental clarity.",
        "Journaling: Write down your thoughts and feelings regularly to help clear your mind and gain perspective.",
        "Set boundaries: Learn to prioritize and say no to commitments that may contribute to mental clutter."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, achieving mental clarity is an ongoing process. Embrace the daily practices that support a clear mind, and be patient with yourself as you navigate through any challenges. By continuing to prioritize mental clarity, you're opening up space for focus, creativity, and a sense of peace. Keep up the excellent work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Clarity of Mind subliminal help me achieve mental clarity?`, 
    answer: `The Clarity of Mind subliminal uses guided visualization and mindfulness techniques to help clear your mind from clutter and promote mental clarity. By listening to the subliminal regularly, you can train your mind to focus and let go of distractions, allowing for greater clarity and improved productivity. The positive affirmations embedded in the audio can help reprogram your subconscious mind for heightened mental clarity.`,
    open: false
  },
  { 
    question: `How long should I listen to the Clarity of Mind subliminal to see results?`, 
    answer: `The time it takes to see results from the Clarity of Mind subliminal can vary from person to person. Some individuals may notice improvements in mental clarity after a few sessions, while others may require more consistent listening over a longer period. We recommend incorporating the subliminal into your daily routine for at least a few weeks to allow the affirmations to work their magic.`,
    open: false 
  },
  { 
    question: `Can the Clarity of Mind subliminal help with anxiety and stress?`, 
    answer: `While the Clarity of Mind subliminal is designed to promote mental clarity, it may indirectly help alleviate anxiety and stress by training your mind to focus and let go of distracting thoughts. However, it is important to note that the subliminal is not a substitute for professional help or treatment for anxiety and stress. If you are experiencing severe anxiety or stress, we recommend seeking guidance from a healthcare professional.`,
    open: false 
  },
  { 
    question: `Can I listen to the Clarity of Mind subliminal while doing other tasks?`, 
    answer: `Yes, you can listen to the Clarity of Mind subliminal while doing other tasks such as studying, working, or engaging in light activities. However, for optimal results, it is recommended to find a quiet and comfortable space where you can fully immerse yourself in the guided visualization and affirmations.`,
    open: false 
  },
  { 
    question: `Is there a specific time of day when I should listen to the Clarity of Mind subliminal?`, 
    answer: `There is no specific time of day when you should listen to the Clarity of Mind subliminal. You can choose a time that works best for you and your schedule. Some people prefer listening in the morning to start their day with mental clarity, while others find it beneficial before bed to clear their mind before sleep. Find a time that allows you to be present and focus on the subliminal without distractions.`,
    open: false 
  },
  { 
    question: `Can the Clarity of Mind subliminal help with decision-making?`, 
    answer: `Yes, the Clarity of Mind subliminal can help improve decision-making by training your mind to focus and let go of distractions. By promoting mental clarity, the subliminal can assist you in making clearer and more informed decisions. However, it is important to still consider all relevant factors and seek appropriate guidance when making important decisions.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>