<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Do you often find yourself giving up on your goals when faced with challenges? This subliminal can help you develop the mindset and habits of persistence, enabling you to overcome obstacles and achieve your dreams."
    },
    {
      type: 'heading',
      text: 'Here are a few ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Increasing your determination and drive to pursue your goals, even when faced with setbacks.",
        "Building resilience to overcome obstacles and bounce back stronger.",
        "Developing a positive attitude and self-belief to stay motivated and focused on your goals.",
        "Cultivating discipline and consistency in your actions, helping you stay on track.",
        "Enhancing your ability to adapt to changing circumstances and find creative solutions to challenges."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can rewire your subconscious mind, paving the way for a more persistent and resilient mindset. Over time, you may notice yourself becoming more determined, focused, and driven to achieve your goals. Remember, persistence is a skill that can be developed, and with practice, you can overcome any obstacle in your path."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Developing persistence is essential for achieving your goals and overcoming obstacles in life. This subliminal is designed to help you cultivate the mindset and habits of persistence. Before you start listening, consider these strategies to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Define your goals: Clarify what you want to achieve and why it's important to you.",
        "Break it down: Divide your goals into smaller, manageable steps to make them more attainable.",
        "Visualize success: Imagine yourself persevering through challenges and achieving your desired outcome.",
        "Embrace a growth mindset: Focus on learning and improvement rather than fearing failure or setbacks.",
        "Stay committed: Remind yourself of your goals regularly and hold yourself accountable.",
        "Use positive affirmations: Repeat affirmations related to persistence and resilience, such as 'I am determined and resilient in the face of challenges.'"
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your daily routine and embracing these preparation tips, you can strengthen your persistence muscle and overcome obstacles with resilience. Remember, persistence is a journey, and with consistent practice, you can develop the necessary mindset and habits to achieve your goals."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Great job on completing the Become More Persistent subliminal! By focusing on developing a mindset of persistence, you are priming yourself for success and personal growth. It's important to reflect on your experience and take proactive steps to cultivate persistence in your daily life."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Define what you want to achieve and break it down into actionable steps.",
        "Create a schedule: Map out your daily, weekly, or monthly tasks to stay organized and focused.",
        "Embrace failure as a learning opportunity: Instead of giving up, see setbacks as chances to grow and improve.",
        "Surround yourself with support: Seek out like-minded individuals who can encourage and motivate you on your journey."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, persistence is a mindset that can be developed over time. Stay committed to your goals, stay accountable to yourself, and never let obstacles deter you from achieving what you desire. With persistence, you have the power to conquer anything that comes your way. Keep going!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Become More Persistent subliminal help me achieve my goals?`, 
    answer: `The Become More Persistent subliminal is designed to influence your subconscious mind, helping you develop the mindset and habits of persistence. By listening to the subliminal regularly, you can reinforce positive affirmations and reprogram your thinking patterns, allowing you to stay focused, motivated, and determined in pursuit of your goals. It can help you overcome obstacles, stay committed, and push through challenges, ultimately increasing your chances of success.`,
    open: false
  },
  { 
    question: `How long do I need to listen to the Become More Persistent subliminal to see results?`, 
    answer: `Results from listening to the Become More Persistent subliminal may vary from person to person. Some individuals may experience positive changes in their persistence levels soon after starting, while others may require more time for the subliminal messages to take effect. We recommend consistently listening to the subliminal for at least a few weeks to allow the affirmations to penetrate your subconscious mind and produce noticeable results.`,
    open: false 
  },
  { 
    question: `Can the Become More Persistent subliminal help with specific areas of my life, such as work or personal goals?`, 
    answer: `Yes, the Become More Persistent subliminal can be applied to various areas of your life, including work, personal goals, and any other endeavors you wish to pursue. It is designed to help you develop persistence as a fundamental trait and apply it to different aspects of your life. By training your mind to stay focused, motivated, and resilient, you can increase your chances of success in various areas.`,
    open: false 
  },
  { 
    question: `Should I listen to the Become More Persistent subliminal for a specific amount of time each day?`, 
    answer: `There is no specific amount of time that is universally recommended for listening to the Become More Persistent subliminal. However, consistency is important. We suggest incorporating it into your daily routine and listening to it at a time when you can give it your full attention, such as during moments of relaxation or before bed. Consistently exposing your subconscious mind to the subliminal messages can enhance their impact and help reinforce persistence as a mindset and habit.`,
    open: false 
  },
  { 
    question: `Can the Become More Persistent subliminal replace hard work and effort?`, 
    answer: `No, the Become More Persistent subliminal is not a substitute for hard work and effort. It is designed to help you develop the mindset and habits of persistence, which can enhance your ability to stay focused and motivated in achieving your goals. However, it is important to combine persistence with consistent action and effort to maximize your chances of success.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the Become More Persistent subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Become More Persistent subliminal. However, if you have any concerns about persisting towards your goals, we recommend seeking guidance from a qualified coach, therapist, or mentor.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>