<template>
  <div class="my-5">
    <FAQs />
  </div>
</template>

<script>
import FAQs from '@/views/Main/FAQs'
export default {
  components: {
    FAQs
  }
}
</script>