import { render, staticRenderFns } from "./87-stop-talking-too-fast.vue?vue&type=template&id=6450d618&scoped=true&"
import script from "./87-stop-talking-too-fast.vue?vue&type=script&lang=js&"
export * from "./87-stop-talking-too-fast.vue?vue&type=script&lang=js&"
import style0 from "./87-stop-talking-too-fast.vue?vue&type=style&index=0&id=6450d618&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6450d618",
  null
  
)

export default component.exports