<template>
  <div class="container">
    <DataLoading name="favorite subliminal" :data="favorites" :slider="false">
      <div class="favorite-subliminal-container">
        <div class="row">
          <div
            class="col-12 col-sm-12 col-md-6 col-lg-4"
            v-for="(item, index) in favorites"
            :key="index"
          >
            <CardSingle name="favorite subliminal" :item="item" />
          </div>
        </div>
      </div>
    </DataLoading>
  </div>
</template>

<script>
import { getLikeSubliminal } from "@/api/favorite";

import CardSingle from "@/components/Card/CardSingle.vue";
import DataLoading from "@/components/DataLoading.vue";

export default {
  data: () => {
    return {
      getLikeSubliminal,

      favorites: [],
    };
  },
  components: {
    CardSingle,
    DataLoading,
  },
  mounted() {
    this.getFavoriteSubliminal();
  },
  computed: {
    user() {
      return this.$store.state.user.info;
    },
  },
  methods: {
    getFavoriteSubliminal() {
      let user = JSON.parse(this.user);

      const data = {
        user_id: user.user_id,
        subscription_id: user.info.subscription_id,
      };

      this.getLikeSubliminal(data).then((res) => {
        this.favorites = res;
      });
    },
  },
};
</script>
