<template>
  <div>
    <div class="mx-6 mt-3 mb-0">
      <h2>Dashboard</h2>
    </div>
    <v-row class="mx-3 my-0">
      <v-col cols="12" md="8" lg="8">
        <Card :height="300">
          <div class="d-flex align-center justify-space-between">
            <h3>Top Subliminal</h3>
            <el-date-picker v-model="date" type="date" style="width: 150px">
            </el-date-picker>
          </div>

          <div class="px-1 my-2" style="height: 230px; overflow-y: auto">
            <Card v-for="topTrackHistory, index in topTrackHistories" :key="index"
              class="d-flex justify-space-between my-4">
              <span>
                {{ topTrackHistory.title }}
              </span>
              <div class="d-flex justify-space-between" style="width: 100px">
                <span>
                  {{ topTrackHistory.numberplayed }}
                </span>
                <span>
                  {{ topTrackHistory.numberfavorite }}
                </span>
              </div>
            </Card>
          </div>
        </Card>
      </v-col>
      <v-col cols="12" md="4" lg="4">
        <Card :height="300">
          <div class="d-flex align-center justify-space-between">
            <h3>User</h3>
            <el-date-picker v-model="date" type="date" style="width: 150px">
            </el-date-picker>
          </div>
          <div class="px-1 my-2" style="height: 230px; overflow-y: auto">
            <Card v-for="userCountBySub, index in userCountBySubs" :key="index" class="d-flex justify-space-between my-4">
              <span>
                {{ userCountBySub.name }}
              </span>
              <span>
                {{ userCountBySub.count }}
              </span>
            </Card>
          </div>
        </Card>
      </v-col>
    </v-row>
    <v-row class="ma-3 mt-0">
      <v-col cols="12" md="7" lg="7">
        <Card :height="300" />
      </v-col>
      <v-col cols="12" md="5" lg="5">
        <Card :height="300" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getUserSubscriptionCount } from '@/api/subscription'
import { getTopTrackHistories } from '@/api/history'
import { formatDate } from '@/utils'
import Card from '@/components/Card.vue'
export default {
  components: {
    Card
  },
  data: () => {
    return {
      formatDate,
      getTopTrackHistories,
      getUserSubscriptionCount,

      date: new Date(),
      userCountBySubs: [],
      topTrackHistories: []
    }
  },
  watch: {
    date(val) {
      this.getUserCountBySubscription()
    }
  },
  mounted() {
    this.getTopTrackHistory()
    this.getUserCountBySubscription()
  },
  methods: {
    getTopTrackHistory() {
      this.getTopTrackHistories().then((res) => {
        this.topTrackHistories = res
      })
    },
    getUserCountBySubscription() {
      const data = {
        date: this.formatDate(this.date)
      }

      this.getUserSubscriptionCount(data).then((res) => {
        this.userCountBySubs = res
      })
    }
  }
}
</script>
