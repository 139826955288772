<template>
  <div>
    <Section1 />
    <Section2 />
    <Section3 />
    <Section4 />
  </div>
</template>

<script>
import Section1 from '@/views/Main/Science/Section1.vue'
import Section2 from '@/views/Main/Science/Section2.vue'
import Section3 from '@/views/Main/Science/Section3.vue'
import Section4 from '@/views/Main/Science/Section4.vue'
export default {
  components: {
    Section1,
    Section2,
    Section3,
    Section4
  }
}
</script>
