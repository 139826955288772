<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you struggle with anger issues and find it difficult to manage your emotions, this subliminal can be a powerful tool to help you release and let go of anger in a healthy and constructive way."
    },
    {
      type: 'heading',
      text: 'Here are some benefits of using this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Learning to identify anger triggers and respond to them in a calm and balanced manner.",
        "Developing healthier coping mechanisms and emotional regulation skills.",
        "Reducing the intensity and duration of angry outbursts.",
        "Promoting a sense of inner peace and emotional well-being.",
        "Enhancing communication and conflict resolution skills.",
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind and create new neural pathways that support healthy anger management. Over time, you may find that you are able to navigate challenging situations with greater ease, maintaining a sense of calm and peace in your life. Remember, it's a process, and practicing self-compassion is important as you work towards releasing and managing your anger."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Letting go of anger is an essential step towards finding peace and tranquility in your life. This subliminal is designed to support you in releasing and managing your anger in a healthy and constructive way. Before you begin your listening session, consider these strategies to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your anger: Recognize and accept that you are feeling anger. It is a normal emotion, and by acknowledging it, you can begin to work through it.",
        "Create a safe space: Find a quiet and comfortable place where you can listen to the subliminal affirmations without any distractions.",
        "Take deep breaths: Practice deep breathing exercises before and during the listening session to help you relax and let go of tension.",
        "Set your intention: Focus on your desire to release and let go of anger, allowing the subliminal messages to guide you towards a calmer state of mind."
      ]
    },
    {
      type: 'paragraph',
      text: "By utilizing this subliminal regularly and following these preparation tips, you can start to transform your relationship with anger. As you let go of anger and embrace a more peaceful and calm state, you will create space for positivity and harmony in your life. Take a moment to center yourself, set your intention, and let the healing process begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Anger Release subliminal session! By consciously working towards releasing and letting go of anger, you are taking a significant step towards bringing peace and calm into your life. Now, it's time to reflect on your experience and keep the momentum going."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice mindfulness: Engage in activities such as meditation or deep breathing exercises to help manage anger triggers.",
        "Express yourself creatively: Use art, writing, or other forms of self-expression to channel and release any remaining emotions.",
        "Develop healthy coping mechanisms: Find alternative ways to deal with anger, such as exercising, journaling, or talking to a trusted friend or therapist.",
        "Practice forgiveness: Consider forgiving yourself and others to lighten the emotional burden and create space for healing and growth."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, releasing and managing anger is a continuous process that requires dedication and self-awareness. By implementing these post-listening tips into your daily life, you can cultivate a sense of peace and transform your relationships and well-being. Embrace the journey and embrace the calm within!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Anger Release subliminal help me?`, 
    answer: `The Anger Release subliminal can help you release and let go of anger in a healthy and constructive way by influencing your subconscious mind. It is designed to promote self-awareness, emotional regulation, and positive coping mechanisms to deal with anger. By consistently listening to the subliminal, you may develop a calmer and more balanced approach to handling anger, allowing peace and calm to enter your life.`,
    open: false
  },
  { 
    question: `How long does it take to see results with the Anger Release subliminal?`, 
    answer: `Results may vary from person to person, as each individual's response to the subliminal is unique. Some people may notice improvements in anger management and emotional well-being after a few listens, while others may require more time and consistent listening to experience significant changes. We recommend listening to the subliminal daily and allowing at least a few weeks for the affirmations to take effect.`,
    open: false 
  },
  { 
    question: `Can the Anger Release subliminal replace therapy or professional help?`, 
    answer: `No, the Anger Release subliminal is not a substitute for therapy or professional help. It is designed to complement therapeutic techniques and promote emotional well-being. If you have persistent anger issues or any underlying mental health concerns, we recommend seeking professional assistance from a qualified therapist or counselor.`,
    open: false 
  },
  { 
    question: `Can I listen to the Anger Release subliminal while experiencing anger?`, 
    answer: `Yes, you can listen to the Anger Release subliminal while experiencing anger. The subliminal messages are designed to help you develop healthier coping mechanisms and emotional regulation skills. Listening to the subliminal during moments of anger can aid in creating a more positive and constructive mindset.`,
    open: false 
  },
  { 
    question: `Are there any side effects of listening to the Anger Release subliminal?`, 
    answer: `No, there are no known side effects associated with listening to the Anger Release subliminal. However, if you have any concerns about your anger management or mental health, it is recommended to consult with a healthcare professional or therapist.`,
    open: false 
  },
  { 
    question: `Can the Anger Release subliminal be used for individuals with chronic anger issues?`, 
    answer: `The Anger Release subliminal can be beneficial for individuals with chronic anger issues as it aims to promote emotional regulation and healthy coping mechanisms. However, it is important to note that severe anger issues may require additional professional help and therapeutic interventions. The subliminal can be a useful complement to therapy but should not be relied upon as the sole means of anger management.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>