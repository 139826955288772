<template>
  <div class="container">
    <div style="margin-block: 2rem">
      <div>
        <div class="text-center">
          <h1>Welcome User!</h1>
          <h4>How are you feeling right now?</h4>
          <div class="row my-5">
            <template v-for="(mood, index) in moods">
              <div
                :class="`moods col-6 col-sm-4 col-md-4 col-lg-3`"
                @click="updateMoods(mood)"
                @mouseenter="mood_id = mood.id"
                @mouseleave="mood_id = 0"
                :key="index"
                :style="`background-color: ${
                  mood_id == mood.id ? mood.description : ''
                };`"
              >
                <img :src="mood.image" alt="" />
                <h5>{{ mood.name }}</h5>
              </div>
            </template>
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-flex justify-content-end">
            <v-btn class="btn btn-outline small" @click="updateMoods()">I don't know yet</v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { updateUserMood } from "@/api/mood.js";
import { getUserCategories } from "@/api/category.js";
import { getRecommendation } from "@/api/home.js";

export default {
  data: () => {
    return {
      updateUserMood,
      getUserCategories,
      getRecommendation,

      mood_id: 0,
    };
  },
  computed: {
    user() {
      return JSON.parse(this.$store.state.user.info);
    },
    moods() {
      return this.$store.state.mood.data;
    },
  },
  created() {
    this.$store.dispatch("screen/getLoading", true);
  },
  mounted() {
    if (this.moods.length > 0) {
      this.$store.dispatch("screen/getLoading", false);
    }
  },
  watch: {
    moods(val) {
      if (val.length > 0) {
        this.$store.dispatch("screen/getLoading", false);
      }
    },
  },
  methods: {
    updateMoods(mood = "") {
      let mood_id = "";
      if (mood != "") {
        mood_id = mood.id;
      }

      const data = {
        user_id: this.user.user_id,
        mood_id: mood_id,
        subscription_id: this.user.info.subscription_id,
      };

      this.updateUserMood(data);

      this.user.info.moods_id = mood_id;

      this.$store.dispatch("user/getInfo", JSON.stringify(this.user));
      this.$store.dispatch("user/getLocalInfo", JSON.stringify(this.user));

      this.getUserCategories(data).then((res) => {
        this.$store.dispatch("category/getUserCategory", res.data);
      });

      this.getRecommendation(data).then((res) => {
        const playlist = res.playlist;
        const subliminal = res.subliminal;
        let result = playlist.concat(subliminal);

        const results = result.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        this.$store.dispatch("recommendation/getData", subliminal);
        this.$store.dispatch("recommendation/getLoading", false);
      });
      this.goToHome();
    },
    goToHome() {
      this.$router.push("/user");
    },
  },
};
</script>

<style scoped>
  img {
    width: 8rem;
    height: 8rem;
    margin-left: auto;
    margin-right: auto;
  }

  .moods:hover {
    color: white;
    &>h5 {
      font-family: "Montserrat-Bold";
    }
  }
  h5, h4 {
    font-family: "Montserrat-SemiBold";
  }
</style>
