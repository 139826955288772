<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If your binge drinking habits are causing negative effects on your life and well-being, it's time to make a change. This subliminal can help you break free from your binge drinking habit and develop a healthier relationship with alcohol."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support you:'
    },
    {
      type: 'list',
      items: [
        "Reducing cravings and urges to binge drink, helping you regain control over your choices.",
        "Increasing your motivation and determination to overcome the binge drinking habit.",
        "Supporting the development of healthier coping mechanisms for stress and emotional triggers.",
        "Reprogramming your subconscious mind to associate alcohol with negative effects and a healthier lifestyle.",
        "Boosting your self-esteem and confidence to make positive changes for your physical and mental well-being."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can gradually shift your mindset and behaviors around alcohol. It's important to remember that breaking a habit takes time and effort, but with dedication and support, you can overcome binge drinking and create a healthier and happier life for yourself."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Breaking free from a binge drinking habit can be challenging, but it is a positive step towards improving your overall well-being. By listening to this subliminal, you can reprogram your mind and develop a healthier relationship with alcohol. Before you begin, consider the following tips to maximize the effectiveness of this subliminal:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your why: Understand the reasons behind your desire to overcome your binge drinking habit and focus on the positive changes it will bring to your life.",
        "Create a support system: Surround yourself with people who will encourage and support your journey towards healthier habits.",
        "Set boundaries: Establish clear boundaries for yourself regarding alcohol consumption, such as limiting the number of drinks or avoiding certain situations that may trigger binge drinking.",
        "Imagine a healthier future: Visualize yourself living a life free from the negative effects of binge drinking, and use this mental image as motivation for change."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you may start to notice a shift in your mindset and behaviors around alcohol. Remember, change takes time, and be patient with yourself as you embark on this journey. By taking control of your life and making positive changes, you are prioritizing your physical and mental well-being. Let's begin together and create a healthier, happier future." 
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Binge Drinking Habit Removal subliminal session! By taking this step towards overcoming your binge drinking habit, you are prioritizing your physical and mental well-being. Reflecting on your journey and continuing to make positive changes is essential as you move forward."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Analyze triggers and patterns: Identify situations, emotions, or thoughts that may have contributed to your binge drinking habit.",
        "Build a support network: Seek out friends, family, or support groups who can provide encouragement and understanding throughout your journey.",
        "Seek professional help: Consider reaching out to a therapist or counselor who specialized in addiction to provide you with the necessary guidance and support.",
        "Find healthier coping mechanisms: Explore alternative activities, hobbies, or relaxation techniques that can replace the urge to binge drink."
      ]
    },
    {
      type: 'paragraph',
      text: "Overcoming a binge drinking habit is a courageous and transformative journey. Be proud of yourself for taking the necessary steps to improve your physical and mental well-being. Remember, change is possible, and with the right support and determination, you can create a healthier relationship with alcohol and live a fulfilling life." 
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Binge Drinking Habit Removal subliminal help me overcome my binge drinking habit?`, 
    answer: `The Binge Drinking Habit Removal subliminal is designed to target your subconscious mind and reprogram it with positive affirmations that promote a healthier relationship with alcohol. By listening to the subliminal regularly, you can gradually change your thoughts, beliefs, and behaviors surrounding binge drinking. It can help you develop a stronger sense of self-control, make healthier choices, and ultimately overcome your binge drinking habit.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Binge Drinking Habit Removal subliminal?`, 
    answer: `Results may vary from person to person, and the time it takes to see results can depend on various factors such as individual circumstances and the consistency of listening. Some individuals may experience positive changes in their attitudes and behaviors towards binge drinking in a relatively short amount of time, while others may require more time and consistent listening to fully overcome their habit. We recommend listening to the subliminal daily and consistently for best results.`,
    open: false 
  },
  { 
    question: `Can the Binge Drinking Habit Removal subliminal be used as a standalone solution to overcome binge drinking?`, 
    answer: `The Binge Drinking Habit Removal subliminal is designed to support individuals in their journey to overcome their binge drinking habit. However, it is important to note that everyone's situation is unique, and overcoming an addiction or unhealthy habit often requires a multi-dimensional approach. We recommend combining the subliminal with other supportive measures such as seeking professional help, joining support groups, or implementing healthier coping strategies.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the Binge Drinking Habit Removal subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Binge Drinking Habit Removal subliminal. However, if you have any concerns about your alcohol consumption or if you are experiencing severe addiction, we strongly recommend seeking professional help from a healthcare provider or addiction specialist.`,
    open: false 
  },
  { 
    question: `Can the Binge Drinking Habit Removal subliminal be used alongside therapy or counseling?`, 
    answer: `Yes, the Binge Drinking Habit Removal subliminal can be used alongside therapy or counseling. It can complement other treatment approaches and be a helpful tool in reinforcing positive changes and empowering individuals in their journey to overcome their binge drinking habit. We encourage you to consult with your therapist or counselor for personalized guidance on integrating the subliminal into your treatment plan.`,
    open: false 
  },
  { 
    question: `Will the Binge Drinking Habit Removal subliminal erase all cravings for alcohol?`, 
    answer: `The Binge Drinking Habit Removal subliminal is designed to help you develop a healthier relationship with alcohol and overcome your binge drinking habit. While it can help reduce cravings and promote self-control, it is important to note that individual experiences may vary. Overcoming addiction often requires a comprehensive approach, and it is advisable to seek professional help and support in dealing with cravings and maintaining sobriety.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>