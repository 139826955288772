<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Learning to say 'no' and setting healthy boundaries is an important skill that can greatly improve your personal and professional life. This subliminal is designed to help you develop the confidence and assertiveness to say 'no' when necessary."
    },
    {
      type: 'heading',
      text: 'Here are some situations where this subliminal can be particularly beneficial:'
    },
    {
      type: 'list',
      items: [
        "If you often find yourself saying 'yes' to too many obligations, leaving you overwhelmed and overworked.",
        "If you struggle with people-pleasing tendencies and have difficulty putting your own needs first.",
        "If you want to establish and maintain healthy boundaries in your personal and professional relationships.",
        "If you lack confidence in asserting your own preferences, opinions, and desires.",
        "If you're looking to improve your communication skills and be more effective in expressing your boundaries."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind to empower you with the ability to say 'no' confidently and assertively. You will learn how to prioritize your own needs and establish healthy boundaries, leading to a more balanced and fulfilling life. Remember, learning to say 'no' is a process, and with practice, it will become easier and more natural."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Learning to say 'no' can be challenging, but it is an essential skill for establishing healthy boundaries and prioritizing your own well-being. This subliminal is designed to help you develop the confidence and assertiveness to say 'no' when necessary. Before you begin, consider these preparation tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Identify your limits: Reflect on your personal and professional boundaries, understanding what you are comfortable with and what you are not.",
        "Practice assertiveness: Role-play saying 'no' in different scenarios, using firm yet polite language.",
        "Visualize success: Imagine yourself confidently saying 'no' and establishing healthy boundaries and assertiveness in your interactions.",
        "Hold your intention in mind: Stay focused on your desire to develop the skill and confidence to say 'no' while listening to the subliminal affirmations."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to support and reinforce your efforts to assertively say 'no' and establish healthy boundaries. Regular listening can help you develop the mindset and confidence needed to prioritize your well-being and set limits in your personal and professional life. Take a moment to center yourself, visualize your success, and let's begin the journey to assertive communication!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Learn to say NO subliminal session! By empowering yourself to assertively say 'no,' you're taking significant strides towards establishing healthy boundaries in both your personal and professional life. Now it's time to reflect on your experience and continue your journey of self-improvement."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your experiences: Consider writing down situations where saying 'no' would have been beneficial to you.",
        "Practice assertiveness: Engage in role-playing exercises to boost your confidence in saying 'no' assertively.",
        "Establish clear boundaries: Analyze your current boundaries and make adjustments where needed to protect your well-being.",
        "Prioritize self-care: Make self-care a priority to enhance your self-esteem and reinforce your ability to say 'no' when necessary."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember that saying 'no' is an important skill that can positively impact your personal and professional life. Building this skill takes time and practice, so be patient with yourself. Keep nurturing your ability to set healthy boundaries and assertively communicate your needs. You're on your way to leading a more empowered and fulfilling life!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Why is it important to learn to say no and establish healthy boundaries?`, 
    answer: `Learning to say no and establish healthy boundaries is important for maintaining your mental and emotional well-being. It allows you to prioritize your own needs, goals, and values, and prevents you from feeling overwhelmed or taken advantage of. Setting boundaries also promotes healthier relationships and communication with others, as it creates clarity and mutual respect.`,
    open: false
  },
  { 
    question: `How can the Learn to say NO subliminal help me develop the skill and confidence to say no?`, 
    answer: `The Learn to say NO subliminal works by influencing your subconscious mind with positive affirmations and beliefs that support assertiveness, confidence, and boundary-setting. By consistently listening to the audio, you can reprogram your mindset and develop the necessary skills and confidence to say no assertively and establish healthy boundaries in your personal and professional life.`,
    open: false 
  },
  { 
    question: `How long does it take to see results from the Learn to say NO subliminal?`, 
    answer: `Results vary from person to person, as everyone responds differently to subliminal messages. Some individuals may experience positive shifts in their assertiveness and boundary-setting abilities shortly after starting the audio. However, for lasting changes, it is recommended to listen to the subliminal consistently for several weeks or months to fully reprogram your subconscious mind.`,
    open: false 
  },
  { 
    question: `Can the Learn to say NO subliminal help with specific situations or relationships?`, 
    answer: `Yes, the Learn to say NO subliminal can help you develop the necessary skills and confidence to set boundaries and say no assertively in various situations and relationships. Whether it's saying no to excessive workload, setting boundaries with family or friends, or establishing professional boundaries, the subliminal can support you in developing assertiveness and communication skills applicable to different contexts.`,
    open: false 
  },
  { 
    question: `Is it possible to overuse the Learn to say NO subliminal?`, 
    answer: `No, there is no risk of overusing the Learn to say NO subliminal. It is designed to be listened to at your own discretion and comfort level. However, for best results, it is recommended to listen to the subliminal consistently and integrate the mindset and skills gained into your daily life.`,
    open: false 
  },
  { 
    question: `Can the Learn to say NO subliminal be used as a standalone solution for boundary-setting and assertiveness?`, 
    answer: `The Learn to say NO subliminal is a powerful tool that can help you develop the necessary mindset and confidence for boundary-setting and assertiveness. However, it is important to also practice and apply the skills learned from the subliminal in real-life situations. Combining the subliminal with practical application, self-reflection, and learning from experiences can lead to more effective and lasting changes in your ability to say no and establish healthy boundaries.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>