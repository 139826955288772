<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're interested in mastering the art of fencing, you've come to the right place. This subliminal is designed to help you develop the skills, techniques, and mindset necessary to become a skilled fencer."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help you:"
    },
    {
      type: 'list',
      items: [
        "Enhancing focus and concentration, allowing you to stay fully present during training and matches.",
        "Improving physical strength, agility, and coordination for better performance on the fencing strip.",
        "Boosting confidence and mental resilience to overcome challenges and take risks during bouts.",
        "Developing tactical thinking and strategic decision-making abilities for successful fencing strategies.",
        "Fostering discipline and dedication to training, ensuring consistent progress and improvement."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can align your subconscious mind with the skills and mindset required for fencing mastery. As you continue your training journey, you may find yourself becoming more skilled, confident, and successful in the sport. Remember, practice and perseverance are key to becoming a skilled fencer."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a journey to master the art of fencing requires dedication and proper training techniques. This subliminal is designed to support your progress in becoming a skilled fencer. Before you start your training session, consider these tips to optimize your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your goals: Clearly define what you want to achieve in fencing, such as improving your technique, increasing your speed, or enhancing your overall performance.",
        "Warm-up properly: Engage in a dynamic warm-up routine to prepare your body for the physical demands of fencing.",
        "Focus your mind: Visualize yourself performing with precision and grace, imagining the movements and techniques you aspire to master.",
        "Maintain focus during training: Keep your attention on your training objectives while listening to the subliminal affirmations, allowing them to reinforce your mental state."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal consistently, it can aid in sharpening your focus, boosting your confidence, and enhancing your overall skills as a fencer. Embrace the challenge, stay committed to your training, and let the path to mastery begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Fencing Training and Mastery session! By dedicating yourself to learning the art of fencing, you've taken a significant step towards becoming a skilled fencer. It's time to reflect on your progress and continue your journey towards mastery."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Schedule regular practice sessions: Consistency is key to improving your fencing skills.",
        "Seek further training: Consider finding a fencing coach or joining a fencing club to receive expert guidance.",
        "Analyze your performance: Review videos of your practice sessions and competitions to identify areas for improvement.",
        "Stay physically fit: Engage in exercises and conditioning routines that enhance your strength, agility, and endurance."
      ]
    },
    {
      type: 'paragraph',
      text: "Becoming a skilled fencer requires dedication, discipline, and continuous learning. Embrace the challenges, learn from your mistakes, and celebrate your successes. Remember, each practice session brings you closer to mastery. Keep up the great work, and may you achieve great success in your fencing journey!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How long does it take to become a skilled fencer with proper training?`, 
    answer: `The time it takes to become a skilled fencer can vary depending on various factors, including your commitment, practice schedule, and natural abilities. Consistent training, discipline, and dedication are crucial to developing and refining your fencing skills. With regular practice and guidance from experienced coaches, you can start to see progress in your fencing abilities within a few months, but mastery may take years of continuous training.`,
    open: false
  },
  { 
    question: `What is the best age to start fencing training?`, 
    answer: `Fencing can be enjoyed by people of all ages, from children to adults. Starting at a younger age can provide more time for skill development and competitive opportunities, but it is never too late to begin fencing training. The key is to find a supportive and experienced coach who can tailor the training to your needs and skill level.`,
    open: false 
  },
  { 
    question: `Do I need any prior experience or athletic ability to start fencing training?`, 
    answer: `No prior experience or specific athletic abilities are necessary to start fencing training. Fencing is a sport that can be learned and enjoyed by individuals of various fitness levels and body types. With proper training and practice, you can develop the necessary skills, agility, and coordination required for fencing.`,
    open: false 
  },
  { 
    question: `What equipment do I need for fencing training?`, 
    answer: `For fencing training, you will need specific equipment, including a fencing mask, jacket, plastron (underarm protector), gloves, fencing pants or knickers, and fencing shoes. Additionally, you will need a suitable weapon (foil, epee, or sabre) and the necessary electrical equipment for competition. It is important to consult with your coach or a reputable fencing equipment supplier to ensure you have the appropriate gear for your training needs.`,
    open: false 
  },
  { 
    question: `Can I participate in fencing competitions as a beginner?`, 
    answer: `Yes, beginners can participate in fencing competitions designed for their skill level, such as local or regional tournaments for novices or newcomers. Competitions provide valuable experience, allowing you to test your skills, learn from other fencers, and track your progress. Your coach can guide you on when it is appropriate to start competing based on your skill and readiness.`,
    open: false 
  },
  { 
    question: `How important is physical conditioning for fencing?`, 
    answer: `Physical conditioning plays a vital role in fencing, as it helps improve agility, speed, endurance, and overall performance. Regular conditioning exercises, such as cardiovascular workouts, strength training, and flexibility exercises, can enhance your fencing abilities and reduce the risk of injuries. Your coach can provide guidance on specific conditioning exercises tailored to the demands of fencing.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>