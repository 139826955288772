<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If anxiety-based panic attacks are affecting your daily life, know that you have the power to regain control. This subliminal is designed to help you overcome panic attacks and provide you with techniques to manage and prevent them."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support you:'
    },
    {
      type: 'list',
      items: [
        "Helping you understand the triggers and underlying causes of your panic attacks.",
        "Teaching you relaxation techniques and coping strategies to use during panic attacks.",
        "Encouraging a positive mindset and reducing anxiety levels on a subconscious level.",
        "Providing you with tools to manage stress and anxiety, preventing panic attacks from occurring in the first place.",
        "Empowering you to regain control of your thoughts and responses during panic attacks."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can rewire your subconscious mind and develop healthier ways of coping with anxiety and preventing panic attacks. Remember, overcoming anxiety-based panic attacks takes time and practice, but with dedication and the right tools, you can find relief and live a more empowered life."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Reclaiming control over your life and managing anxiety-based panic attacks is possible with the help of this subliminal. Before you start listening, here are some strategies to consider:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Educate yourself: Learn about anxiety and panic attacks, understand the symptoms, and familiarize yourself with effective coping techniques.",
        "Create a calm environment: Find a quiet, comfortable space where you can focus during your listening sessions.",
        "Set your intention: Focus on your desire to manage and prevent panic attacks, and visualize yourself feeling calm, confident, and in control.",
        "Practice deep breathing: Take a few moments to practice deep, slow breaths before starting the subliminal to help relax your body and mind."
      ]
    },
    {
      type: 'paragraph',
      text: "By committing to this subliminal and incorporating these strategies, you can begin to regain control of your life and manage anxiety-based panic attacks. Remember, healing is a journey, and each step counts. Take a moment to set your intention, embrace the possibilities, and let this subliminal guide you towards a life filled with peace, calmness, and strength."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Stop Anxiety-Based Panic Attacks subliminal! By dedicating time and effort to overcome anxiety-based panic attacks, you've taken a courageous step towards regaining control of your life. As you continue on your journey towards managing and preventing panic attacks, it's essential to reflect on your experience and incorporate healthy coping strategies into your daily life."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice deep breathing exercises: Deep breaths can help calm your body and mind during moments of anxiety or panic.",
        "Engage in regular physical activity: Exercise releases endorphins, which can help reduce anxiety and improve overall well-being.",
        "Establish a support system: Reach out to loved ones or consider joining a support group to connect with others who understand your experience.",
        "Practice self-care: Prioritize activities that promote relaxation and reduce stress, such as meditation, taking baths, or engaging in hobbies."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming anxiety-based panic attacks is a process that requires patience and self-compassion. Be kind to yourself as you navigate this journey and celebrate each small victory along the way. With dedication and the right tools, you can manage and prevent panic attacks, leading to a more fulfilling and anxiety-free life. Keep going!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What causes anxiety-based panic attacks?`, 
    answer: `Anxiety-based panic attacks can be triggered by various factors, including stress, trauma, genetics, or certain medical conditions. They occur when the body's natural response to stress becomes exaggerated, leading to intense feelings of fear and physical symptoms. Understanding the underlying causes of your anxiety can help you better manage and prevent panic attacks.`,
    open: false
  },
  { 
    question: `How can the Stop Anxiety-Based Panic Attacks subliminal help me?`, 
    answer: `The Stop Anxiety-Based Panic Attacks subliminal is designed to positively influence your subconscious mind and help you develop coping mechanisms to manage and prevent panic attacks. By regularly listening to the audio, you can reprogram your mindset and adopt a more calm and centered approach to anxiety. It is important to note that the subliminal is intended to supplement professional help and self-care practices, and it is not a substitute for medical advice or treatment.`,
    open: false 
  },
  { 
    question: `How long does it take to see results with the Stop Anxiety-Based Panic Attacks subliminal?`, 
    answer: `Results may vary depending on the individual and their unique circumstances. Some people may experience a reduction in frequency and intensity of panic attacks relatively quickly, while others may require more time and consistent listening to reprogram their mindset. We recommend listening to the subliminal daily and consistently for at least a few weeks to allow the positive affirmations to take effect.`,
    open: false 
  },
  { 
    question: `Can the Stop Anxiety-Based Panic Attacks subliminal replace therapy or medication?`, 
    answer: `No, the subliminal is not intended to replace professional therapy or medication. It is designed to complement and support your existing treatment plan by promoting a more positive and empowered mindset towards anxiety-based panic attacks. If you are currently receiving therapy or taking medication for anxiety, it is important to continue following the guidance of your healthcare provider.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Stop Anxiety-Based Panic Attacks subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your mental health or are currently under the care of a mental health professional, please consult with them before incorporating the subliminal into your routine.`,
    open: false 
  },
  { 
    question: `Can the Stop Anxiety-Based Panic Attacks subliminal be used in conjunction with other treatment methods?`, 
    answer: `Yes, the subliminal can be used in conjunction with other treatment methods, such as therapy or medication. It is designed to complement and support your existing treatment plan by promoting a more positive mindset towards anxiety-based panic attacks. It is important to communicate with your healthcare provider and incorporate the subliminal as part of a holistic approach to managing and preventing panic attacks.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>