<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Becoming a successful lawyer requires more than just legal knowledge. It also requires a strong mindset and a set of skills that can help you excel in your career and achieve professional fulfillment. That's where this subliminal comes in!"
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help:'
    },
    {
      type: 'list',
      items: [
        "Developing a confident and resilient mindset to overcome challenges in your legal career.",
        "Improving your communication skills to effectively advocate for your clients.",
        "Enhancing your critical thinking and problem-solving abilities to excel in legal analysis and strategy.",
        "Building strong time management and organizational skills to handle a demanding workload.",
        "Cultivating a positive and professional demeanor to build trust and rapport with clients and colleagues."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, it can help rewire your subconscious mind to align with the mindset and skills necessary for success in the legal field. Whether you're a law student aspiring to become a lawyer or a practicing attorney looking to advance your career, this subliminal can support your journey towards professional achievement and fulfillment. Remember, consistent use and continued growth are key to becoming a successful lawyer."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a career as a lawyer requires dedication, hard work, and a strong mindset. This subliminal is designed to help you develop the mindset and skills necessary to become a successful lawyer and achieve professional fulfillment. Before you start listening, consider these strategies to optimize your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Define your goals: Clarify what success looks like to you as a lawyer and set specific, achievable goals.",
        "Visualize your future: Imagine yourself as a successful lawyer, engaging in meaningful work and making a positive impact.",
        "Commit to continuous learning: Cultivate a mindset of growth and always seek opportunities to expand your knowledge and skills.",
        "Stay focused and disciplined: Develop habits and routines that support your professional development and help you stay on track.",
        "Embrace challenges: View setbacks and obstacles as opportunities for growth and learning.",
        "Believe in yourself: Cultivate self-confidence and trust in your abilities as a lawyer."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can strengthen your mindset, enhance your skills, and unlock your full potential as a lawyer. Remember, success is a journey that requires commitment and resilience. Stay focused on your goals, embrace the challenges, and let this subliminal empower you to become a successful lawyer."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Become a Successful Lawyer subliminal! By dedicating time to developing the mindset and skills needed for success in your legal career, you are setting yourself up for professional fulfillment. Reflect on your journey and use that momentum to continue growing and achieving your goals."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Define your long-term objectives and break them down into smaller, actionable steps.",
        "Expand your legal knowledge: Stay up-to-date with industry trends and continue learning through professional development opportunities.",
        "Network and build connections: Connect with other legal professionals, join industry associations, and attend events to expand your professional network.",
        "Improve your communication skills: Work on enhancing your verbal and written communication skills to effectively advocate for your clients."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, becoming a successful lawyer is a process that requires continuous growth and dedication. Tap into your inner drive and stay committed to honing your legal skills and mindset. With perseverance and determination, you have the potential to excel in your career and achieve the professional fulfillment you desire. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Become a Successful Lawyer subliminal help me in my legal career?`, 
    answer: `The Become a Successful Lawyer subliminal is designed to help you develop the mindset and skills necessary to excel in your legal career. By listening to the subliminal regularly, you can enhance your focus, motivation, and confidence, improve your critical thinking and problem-solving abilities, and cultivate a professional and ethical approach to your work. The subliminal messages work on a subconscious level to reinforce positive beliefs and behaviors that contribute to your success as a lawyer.`,
    open: false
  },
  { 
    question: `How long will it take to see results from the Become a Successful Lawyer subliminal?`, 
    answer: `Results may vary depending on the individual and their unique circumstances. Some people may notice positive changes in their mindset and professional performance right away, while others may require more time and consistent listening to fully internalize the subliminal messages. We recommend listening to the subliminal regularly and incorporating it into your daily routine for best results.`,
    open: false 
  },
  { 
    question: `Can the Become a Successful Lawyer subliminal replace legal education and experience?`, 
    answer: `No, the subliminal is intended to complement your legal education and experience, not replace them. While the subliminal can help you develop a positive mindset and acquire certain skills that contribute to your success as a lawyer, it is essential to acquire formal education and gain practical experience in the field to excel in your legal career.`,
    open: false 
  },
  { 
    question: `How can I maximize the benefits of listening to the Become a Successful Lawyer subliminal?`, 
    answer: `To maximize the benefits of the subliminal, it is important to listen to it on a regular basis and in a relaxed state of mind. Create a peaceful and distraction-free environment while listening to the audio, and visualize yourself achieving success in your legal career. Additionally, combining the subliminal with practical strategies such as setting career goals, seeking mentors, and continuously learning and improving your skills can further enhance your chances of achieving professional fulfillment.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Become a Successful Lawyer subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Become a Successful Lawyer subliminal. However, if you have any concerns about your legal career or professional development, it is recommended to seek guidance from mentors or career counselors in the field. The subliminal is designed to support your growth and development as a lawyer, but it is not a guarantee of success.`,
    open: false 
  },
  { 
    question: `Can the Become a Successful Lawyer subliminal be beneficial for law students?`, 
    answer: `Yes, law students can benefit from listening to the Become a Successful Lawyer subliminal. It can help build confidence, focus, and motivation, which are essential qualities for success in law school. The subliminal messages can also reinforce positive attitudes towards learning, critical thinking, and legal reasoning, enhancing the overall learning experience.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>