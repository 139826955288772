<template>
  <div>
    <Dialog :dialog="dialog" :width="800" :title="`Reset Password`" @closeDialog="closeDialog">
      <p class="mb-0">Are you sure you want to reset the password of {{ email }}</p>
      <template v-slot:action>
        <v-row class="ma-0">
          <v-col class="pl-0 pr-2">
            <v-btn block @click="submitDialog()">Reset</v-btn>
          </v-col>
          <v-col class="pl-2 pr-0">
            <v-btn block @click="closeDialog()">Cancel</v-btn>
          </v-col>
        </v-row>
      </template>
    </Dialog>

    <!-- <Alert :code="code" :alert="alert" :message="message" @closeAlert="closeAlert()"></Alert> -->
  </div>
</template>

<script>
import axios from 'axios'
import { userResetPasswordEmail } from '@/api/user'
import { getMessage } from '@/utils'

// import Alert from '@/components/Alert.vue'
import Dialog from '@/components/Dialog.vue'
export default {
  props: {
    dialog: {
      type: Boolean
    },
    data: {
      type: Object
    }
  },
  data: () => {
    return {
      code: 0,
      message: '',
      userResetPasswordEmail,
      getMessage,

      alert: false,
      email: null,

      user_id: null,
      password: null
    }
  },
  components: {
    // Alert,
    Dialog
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.user_id = this.user_id
      this.email = this.data.email
    },
    closeDialog() {
      this.resetDialog()
      this.$emit('closeDialog', false)
    },
    closeAlert() {
      this.alert = false
    },
    resetDialog() {
      this.user_id = null
      this.password = null
    },
    submitDialog() {

      const data = {
        email: this.email,
      }

      this.userResetPasswordEmail(data).then((data) => {
        this.getMessage(this, data)
        this.closeDialog()
      })
    }
  }
}
</script>
