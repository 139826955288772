<template>
  <div style="margin-top: 20px">
    <div>
      <div class="d-flex justify-content-between align-center">
        <h4 v-if="periodtype == 'per year'">Annually</h4>
        <h4 v-else>Monthly</h4>
        <h5>{{ amount }}.00 PHP</h5>
      </div>
      <v-divider class="mt-15" style="background: black"></v-divider>

      <div class="d-flex justify-content-between">
        <p>Subtotal:</p>
        <p>{{ amount }}.00 PHP</p>
      </div>
      <div v-if="discount == 0" class="promo">
        <h6 style="" @click="dialog = true">Got Promo Code?</h6>
      </div>
      <div v-else>
        <div class="promo d-flex" @click="dialog = true">
          <h6 style="">{{ promocode }}</h6>
          <h6 style="margin-right: 10px">-{{ discount }}.00 PHP</h6>
        </div>
        <div class="text-right" style="margin-bottom: -10px; margin-top: -5px">
          <span>Promo code applied</span>
        </div>
      </div>
      <v-divider class="mb-5" style="background: black"></v-divider>
      <div class="d-flex justify-content-between">
        <p>Total</p>
        <p>{{ totalamount }}.00 PHP</p>
      </div>
      <div class="text-center">
        <button
          class="button btn btn-lg"
          style="width: 120px; color: white; background-color: #1c8edf"
          @click="proceedPayment()"
        >
          Proceed
        </button>
      </div>
    </div>
    <DialogPrice
      :dialog="dialog"
      :width="350"
      :title="`Promo Code`"
      @closeDialog="closeDialog"
    >
      <input
        v-if="message != ''"
        class="w-icon mt-5"
        v-model="promocode"
        placeholder="Got Promo Code?"
      />
      <input
        v-else
        class="wo-icon mt-5"
        v-model="promocode"
        placeholder="Got Promo Code?"
      />
      <div
        v-show="message != ''"
        class="text-right"
        style="margin-bottom: -23px"
      >
        <span>{{ message }}</span>
      </div>
      <div class="text-center">
        <button
          @click="closeDialog"
          v-if="promocodeUpdate == true"
          class="button btn btn-lg"
          style="
            width: 120px;
            margin-top: 220px;
            color: white;
            background-color: #1c8edf;
          "
        >
          Submit
        </button>
        <button
          @click="closeDialog"
          v-else
          class="button btn btn-lg"
          style="
            width: 200px;
            margin-top: 220px;
            color: white;
            background-color: #1c8edf;
          "
        >
          Back to Summary
        </button>
      </div>
    </DialogPrice>
  </div>
</template>

<script>
import { getMessage, generateToken } from "@/utils";
import { checkUrl } from "@/utils";
import { userSignUp } from "@/api/user.js";
import { amountQueryParams, promoQueryParams } from "@/utils";
import {
  createPaymentLink,
  createPayment,
  paymentWebhookEnable,
} from "@/api/payment";
import { getPromoCode, addPromoCodeManual } from "@/api/promocode";
import { getSubscriptionByName } from "@/api/subscription";

import DialogPrice from "@/components/DialogPrice.vue";
export default {
  components: {
    DialogPrice,
  },
  props: {
    code: {
      type: String,
      default: () => {
        return "";
      },
    },
    name: {
      type: String,
      default: () => {
        return "Premium";
      },
    },
    amount: {
      type: String,
      default: () => {
        return "";
      },
    },
    period: {
      type: String,
      default: () => {
        return "";
      },
    },
    isLogin: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  mounted() {
    this.$store.dispatch("screen/getLoading", false);
    if(this.code != "") {
      this.mobilePayment()
    }
  },
  computed: {
    amountParams() {
      return this.amountQueryParams();
    },
    promoParams() {
      return this.promoQueryParams();
    },
    periodtype() {
      return this.period == "Yearly" ? "per year" : "per month";
    },
    totalamount() {
      return this.amount - this.discount;
    },
    user() {
      return this.$store.state.user.info;
    },
    users() {
      return JSON.parse(this.user);
    },
    userToken() {
      return this.$store.state.user.token;
    },
  },
  watch: {
    promocode(value) {
      if (value == "" || value == null) {
        this.message = "";
      } else {
        this.promocode = value.toUpperCase();
        this.getPromoCodeData();
      }
    },
    dialog(value) {
      if (value) {
        this.discount = 0;
        this.promocode = "";
        this.message = "";
        this.promocodeUpdate = false;
      }
    },
  },
  data: () => {
    return {
      promoQueryParams,
      amountQueryParams,  
      getMessage,
      userSignUp,
      createPayment,
      createPaymentLink,
      getPromoCode,
      generateToken,
      addPromoCodeManual,
      getSubscriptionByName,
      paymentWebhookEnable,

      dialog: false,
      promocode: "",
      message: "",
      discount: 0,
      promocodeUpdate: false,
      paymentdescription: "Enjoy more with Magus Premium.",
    };
  },
  methods: {
    skipPayment() {
      this.$store.dispatch("screen/getLoading", true);
      if (localStorage.getItem("register_temp") == null) {
        this.$router.push("register");

        const params = JSON.stringify({
          period: this.period,
          amount: 0,
          subscription: "Free",
          time_created: new Date(),
          promo_code: this.promocode.toUpperCase(),
        });

        localStorage.setItem("subscription_temp", btoa(params));
      } else {
        const params = localStorage.getItem("register_temp");
        const decrypted = atob(params);
        const data = JSON.parse(decrypted);

        this.userSignUp(data).then((res) => {
          const { success, data } = res;
          if (success) {
            localStorage.setItem("user_token", data.token);
            localStorage.setItem("user", JSON.stringify(data.user));
            localStorage.removeItem("register_temp");
            localStorage.removeItem("subscription_temp");
          }

          this.getMessage(this, res);
          // this.$router.push('/user')
          this.$router.push("/user/mood");
        });
      }
    },
    mobilePayment() {
      const paymentData = {
        amount: parseInt(this.amountParams + "00"),
        subscription: this.name,
        description: this.paymentdescription,
        user_id: this.users.user_id,
        promo_code: this.promoParams,
        period: this.period,
      };
      this.createPaymentLink(paymentData).then((res) => {
        const checkout_url = res.attributes.checkout_url;
        paymentData.payment_id = res.attributes.reference_number;
        paymentData.transaction_code = res.attributes.reference_number;
        paymentData.period = this.period;

        this.getSubscriptionByName(paymentData.subscription).then((res) => {
          paymentData.subscription_id = res[0].id;

          if (this.promocode != "") {
            this.addPromoCodeManual(paymentData);
          }
          this.createPayment(paymentData);
        });

        this.paymentWebhookEnable().then((res) => {
          window.location.href = checkout_url;
        });
      });
    },
    proceedPayment() {
      if (this.userToken == null) {
        this.$store.dispatch("screen/getLoading", true);
        const params = {
          route: this.$route,
          router: this.$router,
          tempname: "register_temp",
        };
        checkUrl(params);

        if (this.isLogin) {
          return;
        }

        if (localStorage.getItem("register_temp") == null) {
          this.$router.push("register");

          const params = JSON.stringify({
            period: this.period,
            amount: parseInt(this.totalamount + "00"),
            subscription: this.name,
            description: this.paymentdescription,
            time_created: new Date(),
            promo_code: this.promocode.toUpperCase(),
          });

          localStorage.setItem("subscription_temp", btoa(params));
        } else {
          const params = localStorage.getItem("register_temp");
          const decrypted = atob(params);
          const data = JSON.parse(decrypted);

          data.amount = parseInt(this.totalamount + "00");
          data.period = this.period;
          data.subscription = this.name;
          data.description = this.paymentdescription;

          let paymentData = { ...data };

          this.userSignUp(data).then((res) => {
            const { success, data } = res;
            if (success) {
              this.$store.dispatch("screen/getLoading", true);
              localStorage.setItem("user_token", data.token);
              localStorage.setItem("user", JSON.stringify(data.user));
              localStorage.removeItem("register_temp");
            }

            this.getMessage(this, res);

            paymentData.user_id = data.user.user_id;
            paymentData.promo_code = this.promocode.toUpperCase();
            paymentData.period = this.period;

            this.createPaymentLink(paymentData).then((res) => {
              const checkout_url = res.attributes.checkout_url;
              paymentData.payment_id = res.attributes.reference_number;
              paymentData.transaction_code = res.attributes.reference_number;

              this.getSubscriptionByName(paymentData.subscription).then(
                (res) => {
                  paymentData.subscription_id = res[0].id;

                  if (this.promocode != "") {
                    this.addPromoCodeManual(paymentData);
                  }
                  this.createPayment(paymentData);
                }
              );

              this.paymentWebhookEnable().then((res) => {
                window.location.href = checkout_url;
              });
            });
          });
        }
      } else {
        const paymentData = {
          amount: parseInt(this.totalamount + "00"),
          subscription: this.name,
          description: this.paymentdescription,
          user_id: this.users.user_id,
          promo_code: this.promocode.toUpperCase(),
          period: this.period,
        };
        this.createPaymentLink(paymentData).then((res) => {
          const checkout_url = res.attributes.checkout_url;
          paymentData.payment_id = res.attributes.reference_number;
          paymentData.transaction_code = res.attributes.reference_number;
          paymentData.period = this.period;

          this.getSubscriptionByName(paymentData.subscription).then((res) => {
            paymentData.subscription_id = res[0].id;
            if (this.promocode != "") {
              this.addPromoCodeManual(paymentData);
            }
            this.createPayment(paymentData);
          });

          this.paymentWebhookEnable().then((res) => {
            window.location.href = checkout_url;
          });
        });
      }
    },
    getPromoCodeData() {
      this.discount = 0;

      const data = {
        amount: this.amount,
        code: this.promocode.toUpperCase(),
      };

      this.getPromoCode(data).then((res) => {
        if (res.success == true) {
          const isPromoCodeExist = res.data != null;
          if (isPromoCodeExist) {
            const promocode = res.data;
            const discount = promocode.adjustment_amount;
            this.discount = discount;
            this.promocodeUpdate = true;
            this.message = "";
          } else {
            this.message = res.message;
          }
        } else {
          this.message = res.message;
        }
      });
    },
    closeDialog() {
      this.dialog = false;
    }
  },
};
</script>

<style scoped>
h1 {
  color: var(--ma-tufts-blue);
  font-family: "Montserrat-Bold" !important;
  margin-top: -20px;
}

h4,
p {
  color: #2f3033;
  font-family: "Montserrat-SemiBold" !important;
}
p {
  font-size: 16px;
}

h5:nth-of-type(1),
p:nth-of-type(2) {
  font-family: "Montserrat-Medium" !important;
}

h6 {
  font-family: "Montserrat-SemiBold" !important;
  color: rgba(47, 48, 51, 0.54);
}

.button {
  text-transform: none;
  font-family: "Montserrat-Bold" !important;
}

.button.header {
  color: black !important;
}
.subtotal {
  display: flex;
  justify-content: space-between;
}
.promo {
  text-transform: none;
  cursor: pointer;
  background: #e9e9e9;
  padding-top: 12px;
  padding-bottom: 1px;
  padding-left: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  justify-content: space-between;
}

.w-icon {
  background: url("~@/assets/images/main/home/5/1.png") #e9e9e9;
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: center right 10px;
}
.wo-icon,
.w-icon {
  padding: 10px;
  width: 100%;
  margin: auto;
  border-radius: 5px;
  color: rgba(47, 48, 51, 0.54);
  font-family: "Montserrat-SemiBold" !important;
  background-color: #e9e9e9;
  font-size: 16px;
}
span {
  font-family: "Montserrat-Medium" !important;
  color: #2f3033;
  font-size: 12px;
}
</style>
