<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Breaking free from the destructive cycle of using illegal drugs is a challenging but worthwhile journey towards living a healthy and drug-free life. This subliminal is designed to support you on this path by targeting your subconscious mind and helping you overcome cravings and dependency."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can assist you:'
    },
    {
      type: 'list',
      items: [
        "Reducing cravings and compulsive thoughts associated with illegal drug use.",
        "Building mental resilience and coping mechanisms to resist temptations.",
        "Instilling a strong desire for a drug-free lifestyle and a healthier future.",
        "Enhancing self-control and discipline to stay committed to your recovery.",
        "Boosting motivation and confidence in your ability to break free from addiction."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming drug addiction is a gradual process that requires dedication and support. By regularly listening to this subliminal, you can rewire your subconscious mind to align with your goal of living a drug-free life. As you progress, you will experience positive changes in your thoughts, behaviors, and overall well-being. Stay committed and believe in your ability to create a new, fulfilling chapter in your life."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on taking the first step towards a drug-free life. This subliminal is designed to support you in breaking the cycle of using illegal drugs and creating a healthier, more fulfilling life. Before you start listening, consider these tips for a successful journey:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Commit to change: Make a firm commitment to yourself that you are ready to break free from the destructive cycle of drug use and embrace a healthier lifestyle.",
        "Seek support: Reach out to trusted friends, family, or professionals who can provide the support and guidance you need on this journey.",
        "Create a drug-free environment: Rid your living space of any drugs or reminders of drug use, surrounding yourself with positivity and inspiration.",
        "Visualize a drug-free life: Take a moment to visualize yourself living a vibrant, fulfilling life without the influence of illegal drugs.",
        "Be patient and gentle with yourself: Breaking free from addiction is a process, and it's important to be kind to yourself along the way."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is a powerful tool to support you on your journey to a drug-free life, but it's important to combine it with your determination and commitment. As you listen regularly, you may find your desire for illegal drugs diminishing, as well as an increase in energy, clarity, and overall well-being. Embrace this opportunity for positive change, and let's begin your journey towards a brighter future."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Stop Using Illegal Drugs Completely subliminal session! By embarking on this journey to break free from the destructive cycle of drug use, you have taken a courageous step towards reclaiming your life and embracing a drug-free existence."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Seek support: Reach out to trusted friends, family, or support groups who can provide guidance and assistance on your journey.",
        "Create a safe environment: Remove any triggers or reminders of your past drug use from your surroundings.",
        "Find healthy coping mechanisms: Discover and engage in activities that bring you joy, relaxation, and fulfillment.",
        "Establish a strong support system: Surround yourself with positive influences and individuals who support your commitment to a drug-free life."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, breaking free from drug addiction requires perseverance and commitment. Celebrate every milestone and embrace the small victories along the way. With the right support, determination, and healthier choices, you can create a future filled with freedom, happiness, and fulfillment. Stay strong and believe in yourself!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Can the Stop Using Illegal Drugs Completely subliminal help me overcome drug addiction?`, 
    answer: `While the Stop Using Illegal Drugs Completely subliminal is designed to help individuals break free from the destructive cycle of drug use, it is important to note that overcoming addiction is a complex process that may require a comprehensive approach. We recommend seeking professional help and support from addiction specialists, therapists, and support groups in conjunction with using the subliminal.`,
    open: false
  },
  { 
    question: `How often should I listen to the Stop Using Illegal Drugs Completely subliminal?`, 
    answer: `For best results, we recommend listening to the Stop Using Illegal Drugs Completely subliminal regularly and consistently. You can incorporate it into your daily routine, such as listening in the morning or before bedtime. Consistency is key to allowing the subliminal messages to reinforce a positive and motivated mindset towards living a drug-free life.`,
    open: false 
  },
  { 
    question: `Will the Stop Using Illegal Drugs Completely subliminal have immediate effects on my drug use?`, 
    answer: `The effects of the subliminal messages on drug use can vary from person to person. Some individuals may notice a decrease in cravings or a shift in their mindset towards drug use shortly after starting to listen, while others may require more time and consistent listening to experience significant changes. It is important to remember that the subliminal is designed to be a tool in a comprehensive approach to overcoming drug addiction.`,
    open: false 
  },
  { 
    question: `Can I use the Stop Using Illegal Drugs Completely subliminal as my sole method of overcoming drug addiction?`, 
    answer: `No, the Stop Using Illegal Drugs Completely subliminal is intended to complement other forms of treatment and support for drug addiction. Overcoming addiction often requires a multi-faceted approach that may include therapy, support groups, lifestyle changes, and professional intervention. We recommend utilizing the subliminal as a tool alongside other evidence-based treatments for optimal results.`,
    open: false 
  },
  { 
    question: `Are there any known risks or side effects associated with listening to the Stop Using Illegal Drugs Completely subliminal?`, 
    answer: `There are no known risks or side effects associated with listening to the subliminal audio. However, if you have any concerns about your drug use or health, we strongly advise consulting with a healthcare professional or addiction specialist. The subliminal is designed to support a mindset shift towards a drug-free life but should not replace professional guidance and care.`,
    open: false 
  },
  { 
    question: `Will the Stop Using Illegal Drugs Completely subliminal erase all cravings for drugs?`, 
    answer: `While the Stop Using Illegal Drugs Completely subliminal is designed to help individuals break free from the destructive cycle of drug use, it is important to note that overcoming addiction involves a complex process. The subliminal aims to support a mindset shift towards a drug-free life, but individual experiences may vary. We recommend seeking professional help and utilizing other resources in conjunction with using the subliminal for the best chance of success.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>