<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're struggling to resist the temptation of sweets and find yourself caught in a cycle of sugar addiction, this subliminal can provide the support you need to break free."
    },
    {
      type: 'heading',
      text: 'Here are some benefits you may experience:'
    },
    {
      type: 'list',
      items: [
        "Reduced cravings for sweets and sugary foods.",
        "Increased willpower and self-control to resist sugar temptations.",
        "Heightened awareness of the negative effects of excessive sugar consumption.",
        "Improved ability to make healthier food choices.",
        "Enhanced satisfaction and enjoyment from nourishing foods."
      ]
    },
    {
      type: 'paragraph',
      text: "By aligning your subconscious mind with your conscious desire to overcome sugar addiction, you can gradually rewire your thought patterns and behavior around sweets. As you listen to this subliminal regularly, you may find it easier to make healthier choices and break free from the hold that sugar has on you. Remember, it's a journey, and progress takes time and effort."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "If you're struggling with a craving for sweets and want to break free from the cycle of sugar addiction, this subliminal can help you on your journey. Before you start listening, here are some suggestions to maximize the effectiveness of this subliminal:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Identify your triggers: Pay attention to situations, emotions, or habits that often lead to your cravings for sweets.",
        "Plan healthier options: Have nutritious snacks readily available to combat cravings.",
        "Stay hydrated: Sometimes thirst can be mistaken for hunger or sugar cravings.",
        "Shift your mindset: Remind yourself of the negative effects of excessive sugar consumption and the benefits of cutting back or eliminating it from your diet.",
        "Focus on the end goal: Envision a life without being controlled by sugar cravings and how it will positively impact your physical and mental well-being."
      ]
    },
    {
      type: 'paragraph',
      text: "With consistent listening to this subliminal and implementing healthy habits, you can overcome your cravings for sweets and break free from the cycle of sugar addiction. Stay committed, be patient with yourself, and embrace the journey towards a healthier relationship with food."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Stop Eating Sweets subliminal session! By taking this step towards overcoming your craving for sweets, you're on your way to breaking free from the cycle of sugar addiction. Reflecting on your experience and implementing healthy strategies will be crucial for your continued success."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Identify triggers: Pay attention to situations or emotions that lead to craving sweets.",
        "Replace sugary snacks: Find healthier alternatives like fresh fruits or flavored teas.",
        "Practice portion control: If indulging in sweets, be mindful of serving sizes.",
        "Stay hydrated: Make sure to drink enough water throughout the day to curb cravings."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming sugar addiction is a journey that requires commitment and self-control. Be kind to yourself during this process and celebrate small victories. By implementing these post-listening tips, you'll be able to create healthier habits and enjoy the benefits of a balanced diet. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Stop Eating Sweets subliminal help me overcome my craving for sweets?`, 
    answer: `The Stop Eating Sweets subliminal is designed to target your subconscious mind and reprogram your thoughts and behaviors related to sweet cravings. By listening to the subliminal messages regularly, you can gradually develop a healthier relationship with food and reduce your desire for sweets. The subliminal can help you build willpower, make healthier choices, and break free from the cycle of sugar addiction.`,
    open: false
  },
  { 
    question: `How long does it take to see results with the Stop Eating Sweets subliminal?`, 
    answer: `The time it takes to see results may vary from person to person. Some individuals may notice a reduction in their craving for sweets after a few listens, while others may require more consistent listening to reprogram their subconscious mind. We recommend listening to the subliminal daily and being patient with the process to achieve the best outcomes.`,
    open: false 
  },
  { 
    question: `Does the Stop Eating Sweets subliminal eliminate cravings for all types of sweets?`, 
    answer: `The Stop Eating Sweets subliminal is designed to help you overcome cravings for sweets in general. While it can be effective for reducing cravings for various types of sugary foods, it may not specifically target cravings for specific flavors or ingredients. The subliminal aims to help you develop a healthier mindset towards all sweet foods and make better choices overall.`,
    open: false 
  },
  { 
    question: `Can the Stop Eating Sweets subliminal replace willpower and self-control?`, 
    answer: `The Stop Eating Sweets subliminal is a tool that can support your efforts in overcoming sweet cravings and developing healthier eating habits. While it can help strengthen your willpower and self-control, it is important to combine it with your own commitment and conscious efforts. Practicing mindfulness, creating a supportive environment, and making conscious choices can all contribute to your success in breaking free from the cycle of sugar addiction.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the Stop Eating Sweets subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Stop Eating Sweets subliminal. However, if you have any concerns about your diet or health, please consult with your healthcare provider. It's important to note that the subliminal is intended to supplement healthy lifestyle changes, not replace them.`,
    open: false 
  },
  { 
    question: `Will the Stop Eating Sweets subliminal make me completely lose my desire for sweet foods?`, 
    answer: `The Stop Eating Sweets subliminal is designed to help you overcome your craving for sweets and develop a healthier mindset towards food. While it can reduce your desire for sweet foods, it is not intended to completely eliminate all enjoyment or desire. The subliminal aims to help you make more conscious and balanced choices that align with your goals for a healthier lifestyle.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>