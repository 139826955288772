<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Tai Chi is an ancient martial art that combines slow, flowing movements with deep breathing and meditation. It is known for its numerous physical, mental, and spiritual benefits. This subliminal is designed to help you master the art of Tai Chi and experience all its transformative effects."
    },
    {
      type: 'heading',
      text: 'Here are some key aspects of this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Learning and mastering the basic Tai Chi movements and forms.",
        "Developing balance, coordination, and flexibility through regular practice.",
        "Enhancing body awareness and mindfulness.",
        "Cultivating a sense of inner peace, calm, and harmonious energy flow.",
        "Improving overall physical fitness and strength.",
        "Fostering mental clarity, focus, and stress relief.",
        "Connecting with the philosophical and spiritual foundations of Tai Chi."
      ]
    },
    {
      type: 'paragraph',
      text: "With consistent use of this subliminal, you can deepen your practice and achieve profound levels of mastery in Tai Chi. As you listen regularly, you may notice improvements in your physical abilities, mental well-being, and spiritual connection. Remember, the journey towards Tai Chi mastery is a lifelong process, and this subliminal can be your supportive companion along the way."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on the journey of mastering the art of Tai Chi can be a transformative experience for your body, mind, and spirit. This subliminal is designed to support your progress in Tai Chi training and help you unlock the numerous benefits it has to offer. Here are some considerations to keep in mind before you begin:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a dedicated practice space: Find a quiet and peaceful area where you can perform your Tai Chi movements without distractions.",
        "Wear comfortable clothing: Choose loose-fitting attire that allows for ease of movement and flexibility.",
        "Focus on your breath: Practice mindful breathing techniques to cultivate a sense of inner calm and concentration.",
        "Set your intention: Clarify your goals and aspirations for your Tai Chi practice, whether it's improved balance, flexibility, or inner harmony.",
        "Embrace the learning process: Be patient with yourself and embrace the journey of learning and mastering Tai Chi, as progress comes with time and practice."
      ]
    },
    {
      type: 'paragraph',
      text: "By cultivating a regular Tai Chi practice and incorporating this subliminal into your training routine, you can awaken your body's potential, enhance your mental focus, and nurture inner harmony. Embrace this opportunity to delve into the ancient art of Tai Chi and unlock its profound physical, mental, and spiritual benefits."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Tai Chi Training and Mastery session! By embarking on this journey to master the ancient art of Tai Chi, you have opened yourself up to a world of physical, mental, and spiritual benefits. Now, it's time to reflect on your experience and continue your progress in the practice of Tai Chi."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice regularly: Dedicate time each day to practice Tai Chi and hone your skills.",
        "Continue learning: Seek out additional resources, classes, or workshops to deepen your understanding of Tai Chi.",
        "Embrace mindful movement: Focus on the connection between your body, breath, and energy as you move through the Tai Chi forms.",
        "Find a community: Join a Tai Chi group or find a practice partner to share experiences and support each other's growth."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, mastering Tai Chi is a lifelong journey, and each step you take brings you closer to improved balance, flexibility, and inner harmony. Embrace the process, stay consistent in your practice, and enjoy the countless rewards that Tai Chi offers for your mind, body, and spirit. Well done!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What is Tai Chi?`, 
    answer: `Tai Chi is an ancient Chinese martial art that focuses on slow, gentle movements, deep breathing, and mindfulness. It is often practiced as a form of moving meditation and is known for promoting physical, mental, and spiritual well-being.`,
    open: false
  },
  { 
    question: `What are the benefits of practicing Tai Chi?`, 
    answer: `Practicing Tai Chi can have numerous benefits, including improved balance, flexibility, and posture. It can also help reduce stress, increase relaxation, enhance focus, and cultivate a sense of inner harmony. Regular practice of Tai Chi has been associated with improved cardiovascular health, joint mobility, and overall physical fitness.`,
    open: false 
  },
  { 
    question: `How long does it take to master Tai Chi?`, 
    answer: `The time it takes to master Tai Chi can vary depending on factors such as individual dedication, practice frequency, and natural aptitude. It is a lifelong journey, and progress is made gradually through consistent practice. With regular and focused training, individuals can experience noticeable improvements in their Tai Chi skills and overall well-being over time.`,
    open: false 
  },
  { 
    question: `Can anyone learn Tai Chi, regardless of age or physical condition?`, 
    answer: `Yes, Tai Chi is suitable for people of all ages and physical conditions. It is a low-impact exercise that can be modified to accommodate various fitness levels and physical capabilities. However, it is always advisable to consult with a healthcare professional before starting any new exercise program, particularly if you have any underlying health concerns.`,
    open: false 
  },
  { 
    question: `Do I need any special equipment or clothing to practice Tai Chi?`, 
    answer: `No, you do not need any special equipment or clothing to practice Tai Chi. It can be practiced in comfortable, loose-fitting clothing and in any open space that allows for freedom of movement. Some people prefer to practice Tai Chi barefoot, but lightweight and flexible footwear can also be used if necessary.`,
    open: false 
  },
  { 
    question: `Should I learn Tai Chi from a qualified instructor?`, 
    answer: `While it is possible to learn Tai Chi through self-study using instructional videos or books, learning from a qualified instructor can provide valuable guidance, feedback, and correction. An experienced instructor can help ensure that you are practicing the movements correctly and safely, and they can offer additional insights into the principles and philosophy of Tai Chi.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>