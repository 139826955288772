<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're interested in mastering the art of Judo and becoming a skilled practitioner, this comprehensive training program is designed for you. Through dedicated practice and guidance, you can develop the necessary skills and techniques to excel in this martial art discipline."
    },
    {
      type: 'heading',
      text: "Here's what you can expect from this program:"
    },
    {
      type: 'list',
      items: [
        "Learn the fundamental principles and techniques of Judo, including throws, grappling, and ground techniques.",
        "Progress through structured training modules that cater to beginners, intermediate, and advanced levels.",
        "Develop physical strength, flexibility, and coordination that are essential for Judo mastery.",
        "Receive guidance from experienced instructors who will provide valuable feedback and corrections.",
        "Participate in sparring and competition simulations to enhance your practical skills.",
        "Immerse yourself in the philosophy and principles of Judo, fostering discipline and respect.",
        "Join a supportive community of fellow Judo enthusiasts to share knowledge and experiences."
      ]
    },
    {
      type: 'paragraph',
      text: "By committing to this training program and consistently practicing, you can progressively enhance your Judo skills and achieve mastery in this martial art. Whether you aspire to compete in tournaments or simply enjoy the physical and mental benefits of Judo, this program will guide you on your journey. Remember, dedication and perseverance are key to becoming a skilled Judo practitioner."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on your journey to master Judo requires dedication, discipline, and a strong mindset. This subliminal is designed to support your training and help you become a skilled practitioner of this martial art. Before you begin, here are some tips to help you make the most out of this subliminal experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Determine what you want to achieve in your Judo training, whether it's mastering specific techniques, improving your strength and agility, or advancing in belt levels.",
        "Create a training schedule: Establish a consistent training routine to ensure regular practice and progress.",
        "Visualize success: Imagine yourself performing Judo techniques with precision and skill, visualizing every movement and step of the way.",
        "Maintain focus: Concentrate on your intentions and goals while listening to the subliminal affirmations, allowing them to align with your subconscious mind and enhance your training."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is a tool that can support your Judo training and help you unlock your full potential. As you listen regularly, you may notice improvements in your technique, focus, and overall performance. Embrace the journey, stay committed to your practice, and let the power of the subliminal guide you on your path to Judo mastery."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Judo Training and Mastery program! By dedicating yourself to the comprehensive training provided, you have taken significant steps toward becoming a skilled practitioner in the art of Judo. Now it's time to reflect on your progress and continue your journey of growth and mastery."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice regularly: Set aside consistent time for training and practice the techniques you've learned.",
        "Review and analyze: Watch videos or seek feedback to enhance your understanding of Judo techniques and strategies.",
        "Set goals: Establish short-term and long-term goals to keep yourself motivated and track your progress.",
        "Stay injury-free: Prioritize proper warm-ups, cool-downs, and stretching to prevent injuries and maintain your overall well-being."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, mastery in Judo is a continuous journey that requires dedication, discipline, and resilience. Embrace the challenges and setbacks, as they are valuable opportunities for growth. Keep pushing yourself and never stop learning. With perseverance and practice, you are on your way to becoming a skilled and accomplished Judo practitioner!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How long does it take to become a skilled practitioner in Judo?`, 
    answer: `The time it takes to become a skilled practitioner in Judo varies from person to person. It depends on factors such as the individual's dedication, commitment, and consistent practice. With regular training and guidance from experienced instructors, it is possible to make progress and develop proficiency over time. Patience, hard work, and a growth mindset are key elements in the journey towards mastery in Judo.`,
    open: false
  },
  { 
    question: `Is Judo suitable for all ages and fitness levels?`, 
    answer: `Yes, Judo is a martial art discipline that can be practiced by individuals of all ages and fitness levels. It is adaptable and can be modified to accommodate different abilities and needs. From young children to older adults, anyone can benefit from the physical, mental, and self-defense aspects of Judo.`,
    open: false 
  },
  { 
    question: `What are the benefits of practicing Judo?`, 
    answer: `Practicing Judo offers numerous benefits. It improves physical fitness, strength, flexibility, and coordination. It also enhances mental focus, discipline, and self-confidence. Additionally, Judo provides practical self-defense skills and promotes respect, sportsmanship, and camaraderie through training and competition. It is a holistic martial art that offers lifelong learning and personal growth opportunities.`,
    open: false 
  },
  { 
    question: `Can I practice Judo as a form of exercise without competing?`, 
    answer: `Absolutely! Many people practice Judo solely for its physical and mental benefits without participating in competitions. Judo training provides an excellent full-body workout and helps develop various skills and qualities, even if one does not wish to compete. Whether your goal is fitness, self-defense, or personal growth, Judo can be a rewarding martial art to practice.`,
    open: false 
  },
  { 
    question: `Do I need any special equipment to start practicing Judo?`, 
    answer: `To start practicing Judo, you will need a judo gi (uniform) consisting of a jacket and pants. Additionally, a belt that represents your rank will be required as you progress. It is also recommended to have a mouthguard for added safety. Your instructor will guide you on the appropriate equipment and where to acquire it.`,
    open: false 
  },
  { 
    question: `Is Judo safe to practice?`, 
    answer: `Judo is generally considered a safe martial art when practiced with proper technique and under the guidance of a qualified instructor. The emphasis on respect, control, and partner safety minimizes the risk of injury. However, as with any physical activity, accidents can occur. It is essential to listen to your body, use caution, and follow proper training protocols to reduce the risk of injury.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>