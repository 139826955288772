import request from "@/utils/request";

export const deleteLocalImage = (data) => {
  return request.delete(`/api/local/image/${data}`).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const deleteLocalAudio = (data) => {
  return request.delete(`/api/local/audio/${data}`).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const deleteAllLocalFiles = (data) => {
  return request.delete("/api/local/audio/remove/all").then((res) => {
    const { data } = res.data;
    return data;
  });
};
