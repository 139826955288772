<template>
  <div class="mb-5 mt-10">
    <SupportCard :full="true">
      <SoundTrouble :full="true" />
    </SupportCard>
  </div>
</template>

<script>
import SoundTrouble from '@/views/Main/SupportPage/SoundTrouble.vue'
import SupportCard from '@/components/SupportCard.vue'
export default {
  components: {
    SoundTrouble,
    SupportCard
  }
}
</script>
