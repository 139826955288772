<template>
  <div v-show="isReady && search==''" style="height: 320px">
    <div>
      <div class="align-center banner my-2"
        :style="`background: url(${listenTo[currentNumber % listenTo.length].cover})`">
        <div @click="goToTrack(listenTo[currentNumber % listenTo.length])" class="listen-wrapper">
          <h4 class="mt-2">Listen to</h4>
          <h2>{{ listenTo[currentNumber % listenTo.length].title }}</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
  components: {
  },
  props: {
    search: {
      type: String,
      default: () => {
        return "";
      },
    },
    listenTo: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isReady: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  mounted() {
    this.startRotation();
  },
  computed: {
  },
  watch: {
  },
  data: () => {
    return {
      currentNumber: 0,
    };
  },
  methods: {
    startRotation() {
      this.timer = setInterval(this.next, 5000);
    },
    stopRotation() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    next() {
      this.currentNumber += 1
    },
    goToTrack(data) {
      this.$emit("goToTrack", data);
    },
  },
};
</script>

<style scoped>
  .banner {
    cursor: pointer;
    border-radius: 5px;
    height: 300px;

    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }
  .listen-wrapper {
    height: 220px; 
    padding: 20px;
  }
  h4 {
    color: white; 
    text-shadow: 1px 1px black;
    font-family: 'Montserrat-SemiBold';
  }
  h2 {
    color: white;
    font-family: 'Montserrat-Bold';
    text-shadow: 1px 1px black;
  }
</style>
