import { render, staticRenderFns } from "./68-freedom-from-drug-addiction-and-abuse.vue?vue&type=template&id=41726b1b&scoped=true&"
import script from "./68-freedom-from-drug-addiction-and-abuse.vue?vue&type=script&lang=js&"
export * from "./68-freedom-from-drug-addiction-and-abuse.vue?vue&type=script&lang=js&"
import style0 from "./68-freedom-from-drug-addiction-and-abuse.vue?vue&type=style&index=0&id=41726b1b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41726b1b",
  null
  
)

export default component.exports