export const screen = {
  namespaced: true,
  state: {
    width: 0,
    height: 0,
    layout: null,
    loading: true,
  },
  getters: {},
  actions: {
    getWidth({ commit }, data) {
      commit("getWidth", data);
    },
    getHeight({ commit }, data) {
      commit("getHeight", data);
    },
    getLayout({ commit }, data) {
      commit("getLayout", data);
    },
    getLoading({ commit }, data) {
      commit("getLoading", data);
    },
  },
  mutations: {
    getWidth(state, data) {
      state.width = data;
    },
    getHeight(state, data) {
      state.height = data;
    },
    getLayout(state, data) {
      state.layout = data;
    },
    getLoading(state, data) {
      state.loading = data;
    },
  },
};
