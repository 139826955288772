<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Are you interested in mastering the art of Aikido? This subliminal can assist you in your journey by helping you develop the necessary skills and mindset. Whether you're a beginner or an experienced practitioner, this subliminal can support your training and help you reach new levels of mastery."
    },
    {
      type: 'heading',
      text: 'Key aspects of this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Enhancing your physical abilities, including strength, flexibility, and coordination.",
        "Improving your technical skills and understanding of Aikido techniques.",
        "Promoting mental focus, concentration, and mindfulness during training.",
        "Cultivating a sense of inner peace and harmony, both on and off the mat.",
        "Boosting your self-confidence and belief in your abilities as an Aikido practitioner."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can immerse yourself in a positive mindset that aligns with the principles of Aikido. This can enhance your training experience, deepen your understanding of the art, and help you achieve mastery. Remember, consistent practice and dedication are essential on the path to becoming an accomplished Aikido practitioner."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on the journey to master the art of Aikido requires dedication, practice, and a mindset of continuous improvement. This subliminal is designed to support your training and help you achieve mastery in Aikido. Before you start listening, consider these tips to optimize your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your training goals: Define what you want to achieve in your Aikido practice, whether it's improving technique, increasing focus, or fostering inner peace.",
        "Create a training schedule: Establish regular training sessions to maintain consistency and progress.",
        "Maintain a positive mindset: Embrace challenges as opportunities for growth and practice self-compassion throughout your journey.",
        "Visualize success: Picture yourself executing Aikido techniques with precision and grace, embodying the principles of harmony and self-defense."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal, allow the affirmations to penetrate your subconscious mind, reinforcing your dedication to Aikido and your commitment to personal growth. With consistent training, an open mind, and a willingness to learn, you can embark on a transformative journey in which the practice of Aikido becomes not only a self-defense skill but also a path towards inner peace and harmony."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Aikido Training and Mastery subliminal! By devoting time and effort to master the art of Aikido, you're on a journey towards self-improvement and personal growth. Reflect on your experience and take steps to integrate the principles you've learned into your daily life."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice regularly: Dedicate time to refine your skills and techniques through consistent training.",
        "Seek guidance from a skilled instructor: Enhance your learning by working with an experienced Aikido practitioner.",
        "Embrace the principles of Aikido: Apply the philosophy of harmony, non-resistance, and blending in your interactions.",
        "Cultivate mindfulness and self-awareness: Develop a deeper understanding of your thoughts, emotions, and actions."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, mastering Aikido is a lifelong journey that extends beyond the dojo. Stay committed to your practice and integrate the principles of Aikido into all aspects of your life. Embrace the balance between self-defense and inner harmony, and continue to evolve both physically and spiritually. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How long does it take to master Aikido?`, 
    answer: `The time it takes to master Aikido varies from person to person and depends on various factors, such as prior experience, training frequency, and dedication. Becoming proficient in the techniques and principles of Aikido usually takes several years of consistent practice and learning from experienced instructors. It is a lifelong journey of continuous improvement and personal growth.`,
    open: false
  },
  { 
    question: `What are some benefits of practicing Aikido?`, 
    answer: `Practicing Aikido offers numerous benefits, including improved self-defense skills, increased physical fitness, enhanced balance and coordination, reduced stress, and increased mental focus. It also promotes a sense of inner peace, mindfulness, and harmony with oneself and others. Aikido training can be a transformative experience that positively impacts various aspects of life.`,
    open: false 
  },
  { 
    question: `Do I need prior martial arts experience to start learning Aikido?`, 
    answer: `No, you do not need any prior martial arts experience to start learning Aikido. Aikido is a martial art that can be practiced and enjoyed by people of all ages and fitness levels. Beginners are welcomed and encouraged to join classes to learn the fundamentals and gradually progress at their own pace.`,
    open: false 
  },
  { 
    question: `Is Aikido suitable for self-defense?`, 
    answer: `Yes, Aikido is suitable for self-defense as it emphasizes redirecting an attacker's energy instead of using brute force. Through Aikido training, you will learn effective techniques to neutralize and subdue an aggressor while simultaneously minimizing harm to yourself and the attacker. It teaches you how to use an opponent's energy and movements against them in a controlled and nonviolent manner.`,
    open: false 
  },
  { 
    question: `Can older adults or people with physical limitations practice Aikido?`, 
    answer: `Yes, Aikido can be practiced by older adults and individuals with physical limitations. Aikido techniques can be adapted to accommodate different body types, fitness levels, and mobility restrictions. It is important to communicate with your instructor about any concerns or limitations you may have so they can provide modifications and ensure your safe participation in training.`,
    open: false 
  },
  { 
    question: `Are there any spiritual aspects to Aikido?`, 
    answer: `Yes, Aikido has spiritual aspects that focus on blending with the energy of the universe and cultivating harmony within oneself and with others. It encourages practitioners to develop a mindful and compassionate attitude toward life, fostering personal growth, and self-discovery. However, these spiritual aspects are not tied to any specific religion and can be interpreted and experienced individually.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>