<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Positive self-talk is a powerful tool for boosting self-esteem and promoting a positive mindset. This subliminal is designed to help you harness the power of self-talk to foster positivity within yourself and improve your overall well-being."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Enhancing your self-esteem and self-worth by replacing negative self-talk with positive affirmations.",
        "Encouraging a more optimistic perspective on life and promoting a brighter outlook on daily challenges.",
        "Building resilience and inner strength to navigate through difficult times with a positive mindset.",
        "Improving your self-image and cultivating a greater sense of self-acceptance and self-love.",
        "Boosting your confidence levels and empowering you to pursue your goals and dreams."
      ]
    },
    {
      type: 'paragraph',
      text: "With regular listening, this subliminal can help rewire your subconscious mind to embrace positive self-talk as a natural part of your inner dialogue. As a result, you may experience increased self-esteem, improved relationships, and a more positive outlook on life. Remember, self-talk is a habit, and consistent practice is essential for long-lasting change."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embracing the power of positive self-talk can have a transformative impact on your self-esteem and well-being. This subliminal is designed to help you foster positivity within yourself. Before you begin your journey, consider the following tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Identify areas for improvement: Reflect on areas of your life where you would like to cultivate more positivity and self-belief.",
        "Create affirmations: Craft positive statements that align with your desired mindset and aspirations.",
        "Practice gratitude: Focus on the things you appreciate in your life to shift your perspective towards positivity.",
        "Set your intention: Prior to listening, set a clear intention to embrace positivity and utilize the subliminal messages to reinforce your self-talk."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, incorporating positive self-talk into your daily routine requires persistence and dedication. By regularly listening to this subliminal and actively engaging in positive self-talk, you can nurture a more confident and resilient mindset. So, take this opportunity to focus on fostering positivity within yourself, and let the journey towards improved self-esteem and overall well-being begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Positive Self-Talk subliminal! You've taken a significant step towards fostering positivity within yourself and improving your self-esteem and overall well-being. As you reflect on your experience, remember that practicing self-talk is an ongoing journey."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Challenge negative thoughts: When negative thoughts arise, challenge and replace them with positive, affirming statements.",
        "Create positive affirmations: Develop your own affirmations that reflect the positive qualities and values you want to embody.",
        "Practice gratitude: Each day, reflect on what you're grateful for and focus on the positive aspects of your life.",
        "Surround yourself with positivity: Surround yourself with positive people, uplifting content, and inspiring environments."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, positive self-talk is a powerful tool that can transform your mindset and outlook on life. Embrace the journey of fostering positivity within yourself, and watch as it positively impacts every aspect of your life. You are worthy, capable, and deserving of all the love and happiness in the world!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What is self-talk and how can it benefit me?`, 
    answer: `Self-talk refers to the internal dialogue that occurs within a person's mind. It can either be positive or negative, and has a significant impact on one's self-esteem and overall well-being. Engaging in positive self-talk can help you cultivate a more optimistic mindset, boost your confidence, reduce stress, and improve your overall mental health and resilience.`,
    open: false
  },
  { 
    question: `How can I start incorporating positive self-talk into my daily life?`, 
    answer: `Incorporating positive self-talk into your daily life involves being aware of your thoughts and consciously redirecting negative self-talk towards more positive and empowering statements. Start by identifying any negative or self-critical thoughts and replace them with positive affirmations and encouragement. Practicing gratitude, self-compassion, and mindfulness can also enhance the effectiveness of positive self-talk.`,
    open: false 
  },
  { 
    question: `Can positive self-talk help improve my self-esteem?`, 
    answer: `Yes, positive self-talk can significantly improve self-esteem. By consciously replacing negative self-talk with positive affirmations and empowering statements, you can shift your mindset towards self-acceptance, self-compassion, and self-love. Over time, this can lead to a more positive self-perception and increased self-esteem.`,
    open: false 
  },
  { 
    question: `Are there any techniques or strategies to make positive self-talk more effective?`, 
    answer: `To make positive self-talk more effective, it can be helpful to write down positive affirmations and repeat them daily. Visualization techniques, such as imagining yourself achieving your goals and overcoming challenges, can also enhance the power of positive self-talk. Surrounding yourself with a supportive and positive environment can also reinforce positive self-talk and further boost its effectiveness.`,
    open: false 
  },
  { 
    question: `Can positive self-talk replace therapy or professional help?`, 
    answer: `Positive self-talk can be a valuable tool for self-improvement and maintaining positive mental health. However, it is not a substitute for professional therapy or help. If you are struggling with significant issues related to self-esteem, mental health, or emotional well-being, it is recommended to seek the guidance of a qualified therapist or healthcare professional.`,
    open: false 
  },
  { 
    question: `Will positive self-talk work for everyone?`, 
    answer: `Positive self-talk can be beneficial for most individuals, but its effectiveness may vary from person to person. Some people may find it easier to adopt positive self-talk as a habit, while others may need more time and practice. It is important to be patient with yourself and keep practicing positive self-talk consistently to achieve lasting positive changes in your well-being.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>