import { render, staticRenderFns } from "./Featured.vue?vue&type=template&id=4ee897ca&scoped=true&"
import script from "./Featured.vue?vue&type=script&lang=js&"
export * from "./Featured.vue?vue&type=script&lang=js&"
import style0 from "./Featured.vue?vue&type=style&index=0&id=4ee897ca&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ee897ca",
  null
  
)

export default component.exports