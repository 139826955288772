<template>
  <section>
    <div>
      <div>
         <h1>
          <span>MAGUS</span>
          <span>   for</span>
        </h1>
      </div>
    </div>
    <div :class="`container fade-in-out background-${counter}`">
      <div>
        <h2 id="changeText" style="font-size: clamp(2.50rem, calc(1.41rem + 3.43vw), 5.63rem); margin-top: 1em;">CALM MIND</h2>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data: () => {
    return {
      counter: 1,
      element_text: null,

      text: ["CALM MIND", "EVERYONE"],
    }
  },
  mounted() {
    this.element_text = document.getElementById("changeText");
    this.animateBGText()
  },
  methods: {
    animateBGText() {
      setInterval(() => {
        if (this.counter >= this.text.length && this.text[this.counter] == undefined) {
          this.counter = 0;
        }

        this.element_text.innerHTML = this.text[this.counter];
        this.counter++;
      }, 10000)
    }
  }
}
</script>

<style scoped>
  section:nth-of-type(1) > .container1 {
    padding-block: 2em;
    position: relative;
  }
  h1{
    margin-top: 4%;
    text-align: center;
    position: absolute;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    @media screen and (min-width: 1200px) {
      margin-top: 10%;
    }
  }
  h1>span:nth-child(1) {
    font-size: clamp(2.50rem, calc(1.41rem + 1.43vw), 5.63rem);
    color: var(--ma-tufts-blue) !important;
  }
  h1>span:nth-child(2) {
    color: black;
  }
  h3 {
    margin-top: 2em;
    color: var(--ma-black-olive);
    line-height: 1.35em;
  }
  @media screen and (min-width: 1200px) {
        height: 90vh;
        .container.background-1 {
          background-image: url("~@/assets/images/main/science/4/1.png"), 
          url("~@/assets/images/main/science/4/2.png");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: bottom 0em left 0%, bottom 0em right 0%;
        }
        .container.background-2 {
          background-image: url("~@/assets/images/main/science/4/3.png"), 
          url("~@/assets/images/main/science/4/4.png");
          background-repeat: no-repeat;
          background-size:contain;
          background-position: bottom 0px left 0%, bottom 0px right 0%;
        }
      }
  .container.background-1 {
    @media screen and (min-width: 1200px) {
      background-image: url("~@/assets/images/main/science/4/1.png"), 
      url("~@/assets/images/main/science/4/2.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom 0em left 0%, bottom 0em right 0%;
    }
  }
  .container.background-2 {
    @media screen and (min-width: 1200px) {
      background-image: url("~@/assets/images/main/science/4/3.png"), 
      url("~@/assets/images/main/science/4/4.png");
      background-repeat: no-repeat;
      background-size:contain;
      background-position: bottom 0px left 0%, bottom 0px right 0%;
    }
  }
  @media screen and (min-width: 40rem) {
    section {
      text-align: center;
    }

    section>.container {
      padding-block: 10em;

      @media screen and (min-width: 1200px) {
        height: 90vh;
      }

      @media screen and (max-width: 1200px) {
        height: auto;
        text-align: center;
      }
    }
  }

</style>