<template>
  <v-app>
    <Appbar @openDrawer="openDrawer" />
    <Sidebar :drawer="drawer" @closeDrawer="closeDrawer" />
    <v-main>
      <router-view></router-view>
    </v-main>
    <Loading />
  </v-app>
</template>

<script>
import { getMoods } from "@/api/mood";
import { getTracks } from "@/api/track";
import { getPlaylists } from "@/api/playlist";
import { getCategories } from "@/api/category";
import { getAudioTypes } from "@/api/audiotype";
import { getSubliminals } from "@/api/subliminal";
import { getSubscriptions } from "@/api/subscription";
import { getInfluencers, getUsers } from "@/api/user";

import Appbar from "@/components/User/UserAppbar.vue";
import Sidebar from "@/components/Sidebar.vue";
import Guidebar from "@/components/User/Guidebar.vue";
import Loading from "@/components/Loading.vue";

export default {
  components: {
    Appbar,
    Sidebar,
    Guidebar,
    Loading,
  },
  data: () => {
    return {
      getUsers,
      getMoods,
      getTracks,
      getPlaylists,
      getCategories,
      getAudioTypes,
      getInfluencers,
      getSubliminals,
      getSubscriptions,

      drawer: false,
    };
  },
  created() {
    this.getUserData();
    this.getMoodData();
    this.getTrackData();
    this.getPlaylistData();
    this.getCategoryData();
    this.getAudioTypeData();
    this.getInfluencerData();
    this.getSubliminalData();
    this.getSubscriptionData();
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    openDrawer(data) {
      this.drawer = data;
    },
    closeDrawer(data) {
      this.drawer = data;
    },
    openGuide(data) {
      this.guide = data;
    },
    closeGuide(data) {
      this.guide = data;
    },
    getUserData() {
      this.getUsers().then((res) => {
        this.$store.dispatch("user/getData", res);
      });
    },
    getMoodData() {
      this.getMoods().then((res) => {
        this.$store.dispatch("mood/getData", res);
      });
    },
    getTrackData() {
      this.getTracks().then((res) => {
        this.$store.dispatch("track/getData", res);
      });
    },
    getAudioTypeData() {
      this.getAudioTypes().then((res) => {
        this.$store.dispatch("audio/getTypes", res);
      });
    },
    getCategoryData() {
      this.getCategories().then((res) => {
        this.$store.dispatch("category/getData", res);
      });
    },
    getPlaylistData() {
      this.getPlaylists().then((res) => {
        this.$store.dispatch("playlist/getData", res);
      });
    },
    getInfluencerData() {
      this.getInfluencers().then((res) => {
        this.$store.dispatch("user/getInfluencer", res);
      });
    },
    getSubliminalData() {
      this.getSubliminals().then((res) => {
        this.$store.dispatch("subliminal/getData", res);
      });
    },
    getSubscriptionData() {
      this.getSubscriptions().then((res) => {
        this.$store.dispatch("subscription/getData", res);
      });
    },
    handleResize() {
      this.$store.dispatch("screen/getWidth", window.innerWidth);
      this.$store.dispatch("screen/getHeight", window.innerHeight);
    },
  },
};
</script>

<style>
.pages {
  max-width: 1350px;
  margin-block: 20px;
}
</style>
