<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Intention discernment is the process of becoming aware of your own intentions and choosing to make conscious decisions that align with your values and goals. This subliminal is designed to help you strengthen this ability and live a more intentional life."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Increasing self-awareness and understanding of your true motivations behind your actions.",
        "Developing the ability to differentiate between your own authentic intentions and external influences.",
        "Becoming more mindful and present in your decision-making process.",
        "Gaining clarity and aligning your actions with your values and long-term goals.",
        "Enhancing your intuition and inner guidance to make better choices."
      ]
    },
    {
      type: 'paragraph',
      text: "As you regularly listen to this subliminal, you may notice yourself becoming more in tune with your own intentions and making decisions that truly resonate with who you are and what you want to achieve. Remember, intention discernment is a practice that can be cultivated and strengthened over time, and this subliminal is here to support you on your journey."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Intention discernment is a powerful skill that can help you make conscious decisions that align with your values and goals. This subliminal is designed to support you in developing this skill. Before you begin, consider the following suggestions to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a quiet and peaceful space: Find a comfortable and uninterrupted environment where you can fully focus on the subliminal messages.",
        "Set your intention: Reflect on what you hope to achieve through intention discernment and clarify your goals.",
        "Practice mindfulness: Before listening, take a few moments to relax your mind and be fully present in the current moment.",
        "Open your mind: Be receptive to the subliminal affirmations and allow them to penetrate your subconscious mind.",
        "Trust the process: Have faith in your ability to develop intention discernment and trust that the subliminal will support your growth."
      ]
    },
    {
      type: 'paragraph',
      text: "As you embark on this journey of intention discernment, remember that it is a process that takes time and practice. With regular listening and a commitment to self-reflection, you can develop a clearer understanding of your intentions and make conscious choices that align with your true self. Trust in your own inner wisdom and let the subliminal guide you towards a more intentional and fulfilling life."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Intention Discernment subliminal session! By dedicating time and energy to develop your ability to discern your own intentions, you've taken a significant step towards greater self-awareness and personal growth. Now it's time to reflect on your experience and continue your journey of conscious decision-making aligned with your values and goals."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your intentions: Take moments to pause and observe your thoughts, motivations, and desires.",
        "Practice mindfulness: Engage in activities that help you stay present and connected to your inner self.",
        "Set clear goals: Define what you want to achieve and align your intentions with those goals.",
        "Make conscious choices: Before making decisions, pause and consider if they align with your values and long-term aspirations."
      ]
    },
    {
      type: 'paragraph',
      text: "Developing the ability to discern your intentions takes time and effort, but it's a powerful tool for living a more purposeful and fulfilling life. Stay committed to your journey of self-discovery and aligning your actions with your true desires. As you practice conscious decision-making, you'll discover new depths of authenticity and possibilities. Trust in yourself and embrace the path ahead!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Intention Discernment subliminal help me?`, 
    answer: `The Intention Discernment subliminal is designed to help you develop the ability to discern your own intentions and make conscious decisions aligned with your values and goals. It works by influencing your subconscious mind with positive affirmations, allowing you to become more aware of your thoughts, emotions, and motivations. Through consistent listening, you can strengthen your intuition and empower yourself to make choices that serve your highest good.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Intention Discernment subliminal?`, 
    answer: `Results may vary depending on the individual and their unique circumstances. Some people may notice positive changes in their ability to discern intentions and make conscious decisions relatively quickly, while others may require more time and consistent listening to fully reprogram their mindset. We recommend listening to the subliminal daily and consistently for best results.`,
    open: false 
  },
  { 
    question: `Can the Intention Discernment subliminal increase my self-awareness?`, 
    answer: `Yes, the Intention Discernment subliminal can help increase self-awareness by influencing your subconscious mind and improving your ability to observe your thoughts, emotions, and motivations. As you become more self-aware, you can develop a deeper understanding of yourself and make conscious decisions that align with your values and goals.`,
    open: false 
  },
  { 
    question: `Will the Intention Discernment subliminal make decisions for me?`, 
    answer: `No, the Intention Discernment subliminal is not designed to make decisions for you. Its purpose is to enhance your ability to discern your own intentions and make conscious choices aligned with your values and goals. It empowers you to listen to your inner wisdom and make decisions that reflect your authentic self.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Intention Discernment subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your mental health or decision-making abilities, please consult with a healthcare professional or therapist. The subliminal is intended to supplement personal growth and self-development efforts, not replace professional guidance.`,
    open: false 
  },
  { 
    question: `Can the Intention Discernment subliminal help me align my actions with my goals?`, 
    answer: `Yes, the Intention Discernment subliminal can help you align your actions with your goals by increasing your ability to discern your intentions and make conscious decisions. As you become more aware of your thoughts, emotions, and motivations, you can make choices that are in harmony with your values and aspirations, leading to greater alignment and progress towards your goals.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>