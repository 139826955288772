<template>
  <v-card
    class="item pa-4"
    @click="goToTrack(item)"
    :style="`background: ${item.color}`"
  >
    <FavoriteLink :data="item" @updateLiked="updateLiked"></FavoriteLink>
    <div class="d-flex gap-2">
      <v-avatar class="profile" color="grey" tile>
        <img :src="item.cover" alt="" />
      </v-avatar>
      <div class="d-flex flex-column justify-space-between">
        <div>
          <h5>{{ item.title }}</h5>
          <p>{{ item.description }}</p>
          <div v-if="isRouteTrack">
            <v-checkbox label="Show Guide" v-model="guideStatus"></v-checkbox>
          </div>
        </div>
        <div class="d-flex gap-2">
          <v-btn x-small @click.stop="handleAction(item)">
            {{ isCurrentTrackPlaying ? "Pause" : "Listen" }}
          </v-btn>
          <v-btn x-small @click.stop="openDialog">Add to Playlist</v-btn>
        </div>
      </div>
    </div>
    <AddOwnPlaylistDialog
      :dialog="dialog"
      :data="item"
      @closeDialog="closeDialog"
    />
  </v-card>
</template>

<script>
import { getAudio } from "@/utils/audio";
import FavoriteLink from "@/components/User/FavoriteLink.vue";
import AddOwnPlaylistDialog from "@/components/User/AddOwnPlaylistDialog.vue";
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    FavoriteLink,
    AddOwnPlaylistDialog,
  },
  data: () => {
    return {
      getAudio,

      dialog: false,
    };
  },
  computed: {
    isRouteTrack() {
      return this.$route.path == "/user/track";
    },
    audioIndex() {
      return this.$store.state.audio.index;
    },
    isAudioPlaying() {
      return this.$store.state.audio.status;
    },
    playlistId() {
      return this.$store.state.audio.playlist.id;
    },
    subliminalId() {
      return this.$store.state.audio.subliminal.id;
    },
    isCurrentTrackPlaying() {
      if (this.item.hasOwnProperty("subliminal_id"))
        return (
          this.item.subliminal_id == this.playlistId &&
          this.item.subliminal_id == this.subliminalId &&
          this.isAudioPlaying
        );

      if (this.item.hasOwnProperty("playlist_id"))
        return (
          this.item.playlist_id == this.playlistId &&
          this.item.subliminals[this.audioIndex].subliminal_id ==
            this.subliminalId &&
          this.isAudioPlaying
        );
    },
    guideStatus: {
      get() {
        return this.$store.state.audio.guide.status;
      },
      set(val) {
        this.$store.dispatch("audio/getGuideStatus", val);
      },
    },
  },
  methods: {
    openDialog(data) {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    handleAction(data) {
      this.$store.dispatch("audio/getLoading", true);
      this.$store.dispatch("audio/getCurrentTime", 0);
      this.getAudio(data);
    },
    updateLiked(val) {
      this.item.is_liked = val ? 1 : 0;
    },
    goToTrack(data) {
      if (!this.isRouteTrack) {
        this.$store.dispatch("user/getTrack", data);
        this.$router.push("/user/track");
      }
    },
  },
};
</script>

<style scoped>
p {
  font-size: 10px !important;
  font-family: var(--montserrat) !important;

  display: -webkit-box;
  max-width: 300px;
  -webkit-box-orient: vertical;
  overflow: hidden;

  -webkit-line-clamp: 4;
}

.item {
  min-width: calc((100% / 1));
  height: 280px;
}

.profile {
  width: 250px !important;
  height: 250px !important;
  min-width: 250px !important;
}

@media screen and (max-width: 550px) {
  .item {
    min-width: calc((100% / 1));
    height: 160px;
  }

  .profile {
    width: 130px !important;
    height: 130px !important;
    min-width: 130px !important;
  }

  h5 {
    margin-bottom: 0px;
  }

  p {
    font-size: 9px !important;
    margin-bottom: 0px;
    -webkit-line-clamp: 2;
  }
}

@media screen and (max-width: 400px) {
  .item {
    min-width: calc((100% / 1));
    height: 110px;
  }

  .profile {
    width: 80px !important;
    height: 80px !important;
    min-width: 80px !important;
  }

  p {
    -webkit-line-clamp: 2;
  }
}
</style>
