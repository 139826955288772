<template>
  <div class="card mini-player">
    <div class="d-flex gap-2">
      <div class="card profile" :style="`background: url(${image})`">
        <v-icon
          size="40"
          @click="playingAudio"
          v-if="!isAudioPlaying"
          :disabled="!playButtonDisabled"
        >
          mdi-play
        </v-icon>
        <v-icon
          size="40"
          @click="pausingAudio"
          v-if="isAudioPlaying"
          :disabled="!playButtonDisabled"
        >
          mdi-pause
        </v-icon>
      </div>
      <div class="details mx-2">
        <h5 class="text-left">{{ title }}</h5>
        <p class="time text-right">
          {{ convertToTime(currentTime) }} / {{ convertToTime(durationTime) }}
        </p>
        <v-progress-linear
          v-model="progress"
          color="deep-purple accent-4"
          height="8"
        ></v-progress-linear>
        <div class="d-flex justify-content-end mt-2 gap-1">
          <v-icon
            size="15"
            :color="prevButtonDisabled ? 'gray' : 'black'"
            @click="prevAudio()"
          >
            mdi-chevron-left-circle
          </v-icon>
          <v-icon
            size="15"
            :color="nextButtonDisabled ? 'gray' : 'black'"
            @click="nextAudio()"
          >
            mdi-chevron-right-circle
          </v-icon>
        </div>
      </div>
    </div>
    <div class="description">
      <h5
        class="text-left ml-4 mt-5"
        style="color: var(--ma-tufts-blue); font-family: Montserrat-Regular"
      >
        Description
      </h5>
      <p class="text-left ml-4">
        {{ description }}
      </p>
    </div>
  </div>
</template>

<script>
import { convertToTime } from "@/utils";
import { getAudio, playAudio, pauseAudio } from "@/utils/audio";
import { getFeaturedContents } from "@/api/featured";
export default {
  data: () => {
    return {
      getAudio,
      playAudio,
      pauseAudio,
      getFeaturedContents,

      convertToTime,

      audios: [],
      index: 0,
      title: null,
      description: null,
      image: null,

      skipAudio: false,
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    durationTimes(val) {
      if (val.length > 0) {
        if (this.skipAudio) {
          this.playingAudio();
          this.skipAudio = false;
        }
      }
    },
  },
  computed: {
    audio() {
      return this.$store.state.audio.data;
    },
    cover() {
      return this.$store.state.audio.cover;
    },
    currentTime() {
      return this.$store.state.audio.current.time;
    },
    durationTime() {
      return this.$store.state.audio.duration.time;
    },
    durationTimes() {
      return this.$store.state.audio.duration.times;
    },

    isAudioPlaying() {
      return this.$store.state.audio.status;
    },
    playButtonDisabled() {
      return this.durationTime > 0;
    },
    nextButtonDisabled() {
      return this.index + 1 >= this.audios.length;
    },
    prevButtonDisabled() {
      return this.index <= 0;
    },
    progress: {
      set(val) {
        this.updateCurrentTime(val);
      },
      get() {
        return Math.floor((this.currentTime / this.durationTime) * 100);
      },
    },
  },
  methods: {
    getData() {
      this.getFeaturedContents().then((res) => {
        this.audios = res;
        this.index = 0;
        this.description = this.audios[this.index].description;
        this.image = this.audios[this.index].cover;
        this.title = this.audios[this.index].title;
        this.getAudio(this.audios[this.index]);
      });
    },
    playingAudio() {
      this.playAudio();
    },
    pausingAudio() {
      this.pauseAudio(false);
    },
    updateCurrentTime(val) {
      if (typeof parseInt(val) == "number") {
        const current_time = (parseInt(val) * this.durationTime) / 100;
        this.$store.dispatch("audio/getCurrentTime", parseInt(current_time));
      }
    },
    nextAudio() {
      if (this.index < this.audios.length - 1) {
        this.$store.commit("audio/getInitialStatus", true);
        this.pausingAudio();
        this.index += 1;
        this.description = this.audios[this.index].description;
        this.image = this.audios[this.index].cover;
        this.getAudio(this.audios[this.index]);
        this.skipAudio = true;
      }
    },
    prevAudio() {
      if (this.index > 0) {
        this.$store.commit("audio/getInitialStatus", true);
        this.pausingAudio();
        this.index -= 1;
        this.description = this.audios[this.index].description;
        this.image = this.audios[this.index].cover;
        this.getAudio(this.audios[this.index]);
        this.skipAudio = true;
      }
    },
  },
};
</script>

<style scoped>
.card {
  max-width: 100%;
}
.profile {
  margin-top: 10px;
  margin-left: 10px;
  width: 35%;
  height: 100px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}
.details {
  margin-top: 10px;
  margin-right: 10px;
  width: 70%;
  height: 100px;
}
h1 {
  color: var(--ma-black-olive);
  line-height: 1.35em;
}
h3 {
  color: var(--ma-tufts-blue);
}
p {
  font-family: "Montserrat-Regular";
}

@media screen and (min-width: 80rem) {
}
</style>
