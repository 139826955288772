<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Practicing gratitude can have a profound impact on your life and overall well-being. This subliminal is designed to help you cultivate an attitude of gratitude and appreciate the present moment and all the blessings in your life."
    },
    {
      type: 'heading',
      text: 'Benefits of cultivating an attitude of gratitude:'
    },
    {
      type: 'list',
      items: [
        "Increased happiness and positive emotions.",
        "Reduced stress and anxiety levels.",
        "Improved mental and emotional resilience.",
        "Enhanced relationships and social connections.",
        "Greater satisfaction with life in general.",
        "Heightened mindfulness and presence in the present moment.",
        "Improved overall well-being and quality of life."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can rewire your subconscious mind to focus on the positive aspects of life and develop a grateful mindset. This can bring about profound changes in your outlook, attitude, and overall happiness. Remember, practicing gratitude is a lifelong journey, and this subliminal can serve as a powerful tool to support your growth."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Developing an attitude of gratitude can be a transformative practice, bringing positivity and contentment into your everyday life. This subliminal is designed to support you in cultivating gratitude and embracing the present moment. Before you begin, consider these suggestions to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your blessings: Take a moment to think about the things you appreciate in your life, big and small. Focus on moments, experiences, relationships, or anything else you are grateful for.",
        "Keep a gratitude journal: Write down things you are thankful for each day, allowing yourself to fully acknowledge and appreciate them.",
        "Shift your perspective: Look for the silver lining in challenging situations or find gratitude in the lessons they bring.",
        "Stay present: Practice mindfulness and be fully present in the moment, noticing the beauty and blessings that surround you."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating the practice of gratitude and regular listening to this subliminal, you can cultivate positivity, increase happiness, and experience a deeper sense of contentment in your life. Embrace this opportunity to focus on gratitude, appreciating the present moment, and let the journey to an attitude of gratitude begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Attitude of Gratitude subliminal! By cultivating an attitude of gratitude, you are opening yourself up to experiencing the positive impact it can have on your life. Now, let's take a moment to reflect and continue embracing gratitude in your daily life."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Journal your blessings: Write down three things you are grateful for each day to cultivate gratitude.",
        "Practice mindfulness: Take a few moments each day to be fully present and appreciate the little things.",
        "Express gratitude: Let the people in your life know how much you appreciate them and their actions.",
        "Shift perspective: When faced with challenges, try to find the silver lining and focus on the positive aspects."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, cultivating gratitude is an ongoing journey that requires consistent effort and intention. By embracing an attitude of gratitude, you can experience enhanced happiness, contentment, and a deeper appreciation for life's blessings. Keep practicing, and may gratitude continue to enrich your life!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Attitude of Gratitude subliminal benefit me?`, 
    answer: `The Attitude of Gratitude subliminal is designed to help you cultivate an attitude of gratitude and appreciation in your life. This mindset shift can have numerous benefits, including increased happiness, improved relationships, reduced stress, and overall well-being. By regularly listening to the subliminal and allowing the positive affirmations to influence your subconscious mind, you can develop a more grateful outlook on life and experience the positive impact it has on your overall mindset and daily experiences.`,
    open: false
  },
  { 
    question: `How long does it take to see the effects of the Attitude of Gratitude subliminal?`, 
    answer: `The time it takes to see the effects of the Attitude of Gratitude subliminal can vary from person to person. Some individuals may notice a shift in their mindset and overall outlook after just a few listens, while others may require more time and consistent practice to fully integrate the attitude of gratitude into their lives. We recommend listening to the subliminal daily and incorporating gratitude practices, such as journaling or expressing gratitude to others, for best results.`,
    open: false 
  },
  { 
    question: `How can I make the Attitude of Gratitude subliminal more effective?`, 
    answer: `To make the Attitude of Gratitude subliminal more effective, it is beneficial to actively practice gratitude in your daily life. This can include keeping a gratitude journal, expressing gratitude to others, or simply taking moments throughout the day to reflect on and appreciate the blessings in your life. By combining these practical exercises with regular listening to the subliminal, you can strengthen and reinforce the attitude of gratitude in your subconscious mind.`,
    open: false 
  },
  { 
    question: `Can the Attitude of Gratitude subliminal improve relationships?`, 
    answer: `Yes, developing an attitude of gratitude can have a positive effect on relationships. Expressing gratitude towards others can deepen connections, foster appreciation, and create a more positive and harmonious environment. By listening to the Attitude of Gratitude subliminal and adopting a grateful mindset, you can enhance your relationships and contribute to a more fulfilling and meaningful social life.`,
    open: false 
  },
  { 
    question: `Is there any risk or side effect to listening to the Attitude of Gratitude subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Attitude of Gratitude subliminal. However, it is important to note that the subliminal is designed to complement your gratitude practice and mindset. It is not a substitute for professional medical advice or treatment for any underlying psychological conditions.`,
    open: false 
  },
  { 
    question: `Will listening to the Attitude of Gratitude subliminal make me complacent or reduce my drive for success?`, 
    answer: `No, developing an attitude of gratitude through the subliminal does not make you complacent or diminish your drive for success. Instead, it helps shift your mindset to appreciate and acknowledge the present moment and the blessings in your life while still maintaining motivation and ambition. Gratitude can actually fuel your drive for success by cultivating a positive and optimistic mindset.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>