<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you struggle with being confident and outgoing in online and virtual interactions, this subliminal can help you develop the necessary social skills and thrive in virtual environments."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can benefit you:"
    },
    {
      type: 'list',
      items: [
        "Boosting your confidence and self-esteem in virtual interactions.",
        "Improving your communication skills, including active listening and expressing yourself clearly online.",
        "Reducing social anxiety and nervousness when engaging with others virtually.",
        "Enhancing your ability to make connections and build relationships in online communities or virtual settings.",
        "Adapting to virtual communication tools and platforms with ease and comfort."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to embrace your inner extrovert in online environments. As a result, you may feel more confident, comfortable, and engaged in virtual interactions, allowing you to make the most of your online experiences. Remember, practice and patience are key to building your social skills and thriving in the digital world."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embracing online and virtual interactions can be challenging, but this subliminal is designed to help you become a confident and outgoing version of yourself in these environments. To prepare yourself before you listen, consider the following tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set a clear intention: Determine what specific social skills you want to develop or improve on in online and virtual interactions.",
        "Visualize success: Imagine yourself confidently engaging with others in virtual environments, expressing yourself with ease and establishing meaningful connections.",
        "Practice active listening: Develop the habit of listening attentively to others during virtual conversations, demonstrating empathy and understanding.",
        "Step out of your comfort zone: Challenge yourself to participate in virtual group activities or engage in conversations with new people to build your confidence and social skills."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is meant to empower you and help you thrive in online and virtual environments. As you listen regularly, you may notice positive shifts in your social skills and overall confidence. So take a deep breath, focus on your intention, and let's embark on this journey of self-improvement together."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Become Online and Virtual Extrovert subliminal! You've taken an important step towards becoming more confident and outgoing in virtual interactions, which can greatly enhance your social skills and overall well-being. Now, it's time to reflect on your experience and continue your growth in the online world."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Evaluate your progress: Take note of any positive changes or improvements in your online interactions and communication skills.",
        "Practice active listening: Engage fully in conversations, pay attention to others, and respond thoughtfully.",
        "Step out of your comfort zone: Join online communities or forums to connect with like-minded individuals and practice your new skills.",
        "Embrace mistakes: Don't be too hard on yourself if you face any challenges or setbacks. Learn from them and keep going."
      ]
    },
    {
      type: 'paragraph',
      text: "Becoming more confident and outgoing in virtual environments is a journey that requires perseverance and practice. Embrace new opportunities, connect with others, and continue to develop your social skills to thrive in the online world. Remember, your potential is limitless, and you have the power to create meaningful connections through virtual interactions."
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Become Online and Virtual Extrovert subliminal help me?`, 
    answer: `The Become Online and Virtual Extrovert subliminal is designed to help you develop confidence, social skills, and an outgoing mindset in online and virtual interactions. By listening to the subliminal regularly, you can reprogram your subconscious mind to feel more comfortable and thrive in virtual environments. It can help reduce social anxiety, boost self-assurance, and enhance your ability to connect with others through digital platforms.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Become Online and Virtual Extrovert subliminal?`, 
    answer: `Results can vary from person to person, and it may take some time for the subliminal messages to manifest in your behavior and mindset. Some individuals may notice improvements in their confidence and social skills relatively quickly, while others may require more consistent listening over an extended period. We recommend listening to the subliminal daily and giving it time to work its effects.`,
    open: false 
  },
  { 
    question: `Can the Become Online and Virtual Extrovert subliminal help with social anxiety in person as well?`, 
    answer: `While the primary focus of the subliminal is to help you thrive in online and virtual interactions, it can also have positive effects on social anxiety in person. By building your confidence and improving your social skills through the subliminal, you may find that it translates into real-world social situations as well.`,
    open: false 
  },
  { 
    question: `Will the Become Online and Virtual Extrovert subliminal make me extroverted all the time?`, 
    answer: `The Become Online and Virtual Extrovert subliminal is designed to help you develop confidence, social skills, and an outgoing mindset in online and virtual interactions. While it can support you in becoming more extroverted in these environments, it will not fundamentally change your personality traits or make you extroverted all the time. It aims to enhance your abilities to comfortably engage and connect with others in digital spaces.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Become Online and Virtual Extrovert subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your mental health or social anxiety, it is advisable to seek professional guidance. The subliminal is intended to supplement your personal growth efforts and support you in improving your online and virtual interactions.`,
    open: false 
  },
  { 
    question: `Can I use the Become Online and Virtual Extrovert subliminal for online presentations or public speaking?`, 
    answer: `Yes, the Become Online and Virtual Extrovert subliminal can be beneficial for improving your confidence and communication skills, which can apply to online presentations or public speaking. By reprogramming your subconscious mind, it can help you feel more comfortable and self-assured in delivering presentations and speaking in front of a virtual audience.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>