<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "In today's fast-paced world, finding calm and peacefulness can be a challenge. But with this subliminal, you can create a peaceful state of mind and cultivate inner tranquility."
    },
    {
      type: 'heading',
      text: 'Key features of this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Soothing sounds and melodies that help relax your mind and reduce stress.",
        "Guided meditations that provide step-by-step instructions for achieving a peaceful state of mind.",
        "Positive affirmations that reinforce feelings of calm and peace.",
        "Visualization exercises that allow you to imagine yourself in serene and tranquil environments.",
        "Breathing techniques that help you slow down and find inner peace in any situation."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your daily routine, you can create a lasting sense of calm and peacefulness. Whether you're dealing with anxiety, stress, or simply seeking more tranquility in your life, this subliminal can help you achieve the inner peace you desire. Remember to listen with an open mind and embrace the journey towards serenity."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Finding peace and tranquility in today's fast-paced world can be challenging, but this subliminal is designed to help you cultivate calm and peaceful feelings. Before you start listening, here are some suggestions to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a serene environment: Find a quiet and comfortable space where you can relax without distractions.",
        "Set your intention: Reflect on your desire to experience calm and peacefulness, and visualize yourself embracing tranquility.",
        "Focus on your breath: Take a few deep breaths to center yourself and bring your attention to the present moment.",
        "Open your heart and mind: Approaching this session with an open and receptive mindset can enhance the benefits."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember that this subliminal is designed to guide you towards a state of calm and peace. As you listen regularly, allow yourself to fully immerse in the soothing sounds and guided meditations, giving yourself permission to let go of stress and tension. Embrace this opportunity to cultivate inner tranquility and create a peaceful state of mind."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Calm and Peaceful Feelings session! By dedicating time to find inner tranquility and cultivate a peaceful state of mind, you've taken an important step towards creating a more serene and balanced life. Now, let's reflect on your experience and explore ways to continue nurturing calmness and peace in your daily routine."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice mindfulness: Incorporate mindfulness into your day by focusing on the present moment and observing your thoughts and emotions non-judgmentally.",
        "Create a peaceful space: Set up a dedicated area in your home where you can engage in calming activities like meditation, reading, or simply enjoying quietude.",
        "Establish a relaxation ritual: Incorporate relaxation techniques such as deep breathing exercises, progressive muscle relaxation, or guided meditations into your daily routine.",
        "Engage in soothing activities: Find hobbies or activities that bring you joy and tranquility, such as listening to calming music, taking nature walks, or practicing yoga."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, cultivating calmness and peace is a continuous journey. Embrace each moment of tranquility and allow it to permeate your life. By prioritizing your inner well-being, you are creating a space that welcomes serenity and balance. Keep nourishing your peaceful spirit!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can I achieve a sense of calm and peacefulness with the Calm and Peaceful Feelings audio?`, 
    answer: `To achieve a sense of calm and peacefulness with the Calm and Peaceful Feelings audio, find a quiet and comfortable space where you can relax and focus. Listen to the audio with headphones for a more immersive experience. Follow the guided meditations and allow yourself to fully immerse in the soothing sounds and visualizations. Practicing deep breathing and mindfulness techniques can also enhance the calming effects of the audio.`,
    open: false
  },
  { 
    question: `How long does it take to experience the calming effects of the Calm and Peaceful Feelings audio?`, 
    answer: `The time it takes to experience the calming effects of the Calm and Peaceful Feelings audio may vary from person to person. Some individuals may feel a sense of calm and peacefulness right away, while others may need more time and consistent listening to fully relax and embrace the benefits. We recommend creating a regular practice of listening to the audio to enhance its effects.`,
    open: false 
  },
  { 
    question: `Can the Calm and Peaceful Feelings audio be used during meditation practice?`, 
    answer: `Yes, the Calm and Peaceful Feelings audio can be used during meditation practice. The soothing sounds and guided meditations are designed to help you enter a calm and peaceful state of mind, making it an ideal accompaniment to meditation. Whether you are a beginner or an experienced meditator, the audio can enhance your practice by creating a serene and tranquil environment.`,
    open: false 
  },
  { 
    question: `Is it safe to listen to the Calm and Peaceful Feelings audio while driving or operating machinery?`, 
    answer: `No, it is not safe to listen to the Calm and Peaceful Feelings audio while driving or operating machinery. The audio is intended for relaxation and meditation purposes, and it is important to give it your full attention in a safe and controlled environment. Engaging in activities that require alertness or concentration while listening to the audio may pose a risk.`,
    open: false 
  },
  { 
    question: `Can the Calm and Peaceful Feelings audio help with anxiety or stress?`, 
    answer: `The Calm and Peaceful Feelings audio is designed to promote a sense of calm and peacefulness, which can be beneficial for individuals experiencing anxiety or stress. However, it is important to note that it is not a substitute for professional help or treatment for anxiety disorders or chronic stress. If you are experiencing severe anxiety or stress, it is recommended to seek the guidance of a healthcare professional.`,
    open: false 
  },
  { 
    question: `Is the Calm and Peaceful Feelings audio suitable for children?`, 
    answer: `Yes, the Calm and Peaceful Feelings audio can be suitable for children. However, it is recommended to supervise and guide children when using the audio to ensure a safe and appropriate experience. Parents or guardians should also consider the individual needs and sensitivities of their children before introducing them to the audio.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>