<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "A healthy lifestyle is within your reach, and this subliminal can help you get there. By reprogramming your subconscious mind, it can support you in building the habits and mindset necessary for a balanced and vibrant life."
    },
    {
      type: 'heading',
      text: 'Here are some key ways this subliminal can assist you:'
    },
    {
      type: 'list',
      items: [
        "Encouraging the adoption of healthy habits, such as regular exercise and proper nutrition.",
        "Increasing motivation and discipline to stay consistent with your healthy choices.",
        "Promoting self-care and prioritizing your well-being in all areas of your life.",
        "Boosting your energy levels and promoting vitality for an active and fulfilling lifestyle.",
        "Helping you maintain a positive mindset and overcome obstacles on your journey to wellness."
      ]
    },
    {
      type: 'paragraph',
      text: "With regular listening, this subliminal can aid in rewiring your subconscious mind to align with your aspirations for a healthy lifestyle. Remember, change takes time and effort, but with each small step you take, you'll be one step closer to living the vibrant and fulfilling life you desire."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a journey towards a healthy lifestyle is a powerful decision. This subliminal is designed to support you in creating positive changes in your daily routine. Before you start, consider these tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a clear vision: Identify and visualize what a healthy lifestyle means to you. Picture yourself living that vibrant, balanced life.",
        "Set achievable goals: Break down larger objectives into smaller, manageable steps to make them more attainable and sustainable.",
        "Establish a routine: Incorporate healthy habits, exercise, and a balanced diet into your daily schedule. Consistency is key.",
        "Maintain a positive mindset: Believe in your ability to make lasting changes and overcome any challenges that may arise."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can align your subconscious mind with your conscious desires for a healthier lifestyle. Embrace the journey and find joy in taking control of your well-being. Remember, small consistent steps lead to significant transformations. Let's begin this empowering experience and create the healthy lifestyle you deserve."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Have a Healthy Lifestyle subliminal session! You've made a commitment to prioritize your well-being and take control of your health. Reflect on the positive impact that a healthy lifestyle can have on your overall vitality and wellness."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Set achievable goals: Break down your larger health goals into smaller, actionable steps.",
        "Create a balanced routine: Incorporate regular exercise, nutritious meals, and self-care practices into your daily life.",
        "Stay motivated: Surround yourself with positive influences, whether it's supportive friends, inspiring quotes, or daily affirmations.",
        "Practice mindfulness: Engage in activities like meditation or journaling to promote self-awareness and mental well-being."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, living a healthy lifestyle is an ongoing journey. Embrace the process and be kind to yourself along the way. Each small step you take towards incorporating good habits will bring you closer to a life filled with vitality and wellness. You've got this!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can I develop good habits for a healthy lifestyle?`, 
    answer: `Developing good habits for a healthy lifestyle starts with setting specific and achievable goals. Start small and gradually incorporate new habits into your daily routine. Focus on one habit at a time and make sure to track your progress. Consistency is key, so be patient and give yourself time to adjust to the changes.`,
    open: false
  },
  { 
    question: `What are some examples of healthy habits I can incorporate into my daily routine?`, 
    answer: `Examples of healthy habits include regular exercise, eating a balanced diet, getting enough sleep, staying hydrated, managing stress, and practicing self-care. It's important to find activities that you enjoy and that work for your lifestyle, as this will increase your chances of sticking to them in the long run.`,
    open: false 
  },
  { 
    question: `How can I stay motivated to maintain a healthy lifestyle?`, 
    answer: `Staying motivated to maintain a healthy lifestyle can be challenging, but there are strategies that can help. Set realistic goals, celebrate your successes, and find a support system that can hold you accountable. It's also important to focus on the benefits of a healthy lifestyle, such as increased energy levels, improved mood, and reduced risk of certain diseases. Lastly, remember to be kind to yourself and allow for occasional indulgences without guilt.`,
    open: false 
  },
  { 
    question: `Do I need to follow a strict diet to have a healthy lifestyle?`, 
    answer: `A healthy lifestyle is not about following a strict diet, but rather about making balanced and nutritious food choices. It's important to listen to your body, eat a variety of foods, and focus on portion control. Moderation is key, and it's perfectly fine to enjoy occasional treats as part of a healthy lifestyle. Consulting with a registered dietitian can help you create a personalized and sustainable eating plan.`,
    open: false 
  },
  { 
    question: `Is exercise necessary for a healthy lifestyle?`, 
    answer: `Regular exercise is an important component of a healthy lifestyle. It helps improve cardiovascular health, build muscle strength, manage weight, and reduce the risk of chronic diseases. Aim for at least 150 minutes of moderate-intensity aerobic activity per week, along with strength training exercises on two or more days. Find activities that you enjoy and make them a regular part of your routine.`,
    open: false 
  },
  { 
    question: `How can I manage stress as part of a healthy lifestyle?`, 
    answer: `Managing stress is vital for a healthy lifestyle. There are several strategies you can try, including practicing mindfulness and relaxation techniques, exercising regularly, getting enough sleep, maintaining a support system, and engaging in activities that bring you joy. It's important to find what works best for you and make stress management a priority.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>