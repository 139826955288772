import { render, staticRenderFns } from "./52-digestion-enhancement.vue?vue&type=template&id=5435841a&scoped=true&"
import script from "./52-digestion-enhancement.vue?vue&type=script&lang=js&"
export * from "./52-digestion-enhancement.vue?vue&type=script&lang=js&"
import style0 from "./52-digestion-enhancement.vue?vue&type=style&index=0&id=5435841a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5435841a",
  null
  
)

export default component.exports