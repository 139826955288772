<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Living a lifestyle free from burnout is essential for your overall well-being and happiness. This subliminal is designed to help you train your mind to rest and slow down, preventing burnout and promoting a healthier lifestyle."
    },
    {
      type: 'heading',
      text: 'Benefits of using this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Learning to recognize the signs of burnout and taking proactive steps to prevent it.",
        "Building healthier boundaries and saying no when necessary to protect your time and energy.",
        "Developing effective stress management techniques to cope with daily pressures.",
        "Prioritizing self-care and making time for activities that recharge and rejuvenate you.",
        "Finding balance and harmony in your life, ensuring that work, relationships, and personal time are all given proper attention."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can rewire your subconscious mind to prioritize rest, self-care, and a balanced approach to life. You'll be able to recognize when you're reaching your limits and take steps to prevent burnout before it happens. Remember, it's important to practice self-compassion and make self-care a priority in order to maintain a lifestyle free from burnout."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Taking steps to avoid burnout is crucial for maintaining a healthy and balanced lifestyle. This subliminal is designed to support your journey in finding rest and slowing down. Before you begin, consider these strategies to help enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your limits: Recognize signs of stress and fatigue in your body and mind, and understand the importance of self-care.",
        "Create healthy boundaries: Set clear boundaries between work, personal life, and rest, ensuring you prioritize time for relaxation and rejuvenation.",
        "Practice mindfulness: Engage in activities that promote mindfulness and stress reduction, such as meditation, deep breathing, or yoga.",
        "Keep your intention in mind: While listening to the subliminal, stay focused on your intention to live a lifestyle free from burnout, allowing the affirmations to guide your subconscious mind."
      ]
    },
    {
      type: 'paragraph',
      text: "By utilizing this subliminal, you can train your mind to prioritize rest and avoid burnout. Regular listening, along with implementing healthy boundaries and self-care practices, can lead to a more balanced and fulfilling life. Take a moment to commit to your well-being, and let the journey to a burnout-free lifestyle begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Avoid Burnout subliminal session! By focusing on rest, slowing down, and avoiding burnout, you've taken an important step towards living a more balanced and fulfilling life. Now, it's time to reflect on your journey and continue prioritizing your well-being."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Take breaks: Incorporate short breaks throughout your day to rest and recharge.",
        "Set boundaries: Learn to say 'no' when your plate is already full and avoid taking on too much.",
        "Practice self-care: Engage in activities that bring you joy and relaxation.",
        "Manage stress: Explore stress management techniques like deep breathing, meditation, or exercise."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, avoiding burnout is an ongoing process that requires self-awareness and self-care. Be kind to yourself and remember that it's okay to prioritize your well-being. Embrace the journey towards a more balanced, fulfilling, and burnout-free lifestyle. You've got this!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What is burnout and how does it affect me?`, 
    answer: `Burnout refers to a state of emotional, mental, and physical exhaustion caused by prolonged periods of stress and overwork. It can negatively affect your overall well-being, productivity, and relationships. Common symptoms of burnout include fatigue, lack of motivation, decreased performance, and increased cynicism or detachment. Recognizing the signs of burnout is the first step towards preventing it and prioritizing self-care.`,
    open: false
  },
  { 
    question: `How can the Avoid Burnout subliminal help me?`, 
    answer: `The Avoid Burnout subliminal is designed to help you develop healthy habits and mindset shifts that promote relaxation, self-care, and stress management. By listening to the subliminal regularly, you can train your mind to rest, slow down, and prioritize your well-being. It aims to empower you with the mental tools to identify and address the causes of burnout, leading to a healthier and more balanced lifestyle.`,
    open: false 
  },
  { 
    question: `Is it normal to feel guilty when taking breaks or prioritizing self-care?`, 
    answer: `Feeling guilty when taking breaks or prioritizing self-care is a common experience, especially for individuals who tend to overwork or have perfectionistic tendencies. However, it is essential to recognize that rest and self-care are vital for maintaining productivity, well-being, and preventing burnout. The Avoid Burnout subliminal can help you reframe your mindset and overcome feelings of guilt, enabling you to prioritize self-care without judgment.`,
    open: false 
  },
  { 
    question: `How long does it take to see results from the Avoid Burnout subliminal?`, 
    answer: `Results may vary depending on the individual and their unique circumstances. It can take time to reprogram your mindset and develop new habits. Consistency is key, so we recommend listening to the subliminal regularly for at least a few weeks to allow the positive affirmations to take effect. Over time, you may notice a greater sense of balance, resilience, and improved well-being.`,
    open: false 
  },
  { 
    question: `Can the Avoid Burnout subliminal replace professional help or therapy?`, 
    answer: `No, the Avoid Burnout subliminal is not a substitute for professional help or therapy. While it can complement your self-care practices, it is important to seek professional guidance if you are experiencing severe burnout or mental health concerns. A therapist or healthcare professional can provide personalized support and guidance tailored to your specific needs.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Avoid Burnout subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your well-being or mental health, it is important to consult with a healthcare professional or therapist. The subliminal is intended to supplement your self-care practices, not replace them.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>