<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Your immune system plays a crucial role in protecting your body from illnesses and diseases. This subliminal is designed to help you strengthen your immune system and enhance its functioning."
    },
    {
      type: 'heading',
      text: 'Here are some key benefits of using this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Boosting your body's natural defense mechanisms and resistance to infections.",
        "Supporting the production of antibodies and enhancing immune response.",
        "Promoting overall health and well-being by fortifying your immune system.",
        "Increasing your body's ability to fight off and recover from illnesses.",
        "Reducing the risk of chronic diseases and maintaining optimal health."
      ]
    },
    {
      type: 'paragraph',
      text: "Regular listening to this subliminal can help reprogram your subconscious mind to support a stronger immune system. By incorporating this into your routine, you can empower your body to better protect itself from potential threats, maintain good health, and optimize your overall well-being. Remember, consistency is key in reaping the long-term benefits."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Taking steps to strengthen your immune system is vital for overall health and well-being. This subliminal is designed to support your immune system and help protect your body from illnesses and diseases. Before you start listening, consider these tips to optimize your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice healthy lifestyle habits: Eat a nutritious diet, exercise regularly, get sufficient sleep, and manage stress levels.",
        "Maintain proper hygiene: Wash your hands frequently, cover your mouth when coughing or sneezing, and disinfect frequently-touched surfaces.",
        "Stay hydrated: Drink plenty of water to support optimal immune function.",
        "Set a positive intention: Focus on activating and strengthening your immune system while listening to the subliminal affirmations."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your daily routine and adopting healthy habits, you can enhance your immune system's capabilities and fortify your body against illnesses and diseases. Take a moment to relax, set your intention, and let's begin the journey towards a stronger and healthier immune system."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Immune System Boost subliminal session! By dedicating time to strengthening your immune system, you've taken an important step towards safeguarding your health and well-being. As you reflect on the significance of a strong immune system, consider implementing these post-listening tips to continue your journey."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Focus on nutrition: Ensure you're consuming a balanced diet rich in vitamins, minerals, and antioxidants.",
        "Stay physically active: Engage in regular exercise to support overall immune function.",
        "Practice stress management: Chronic stress can weaken the immune system, so find healthy ways to reduce and manage stress levels.",
        "Prioritize sleep: Aim for consistent and adequate sleep to give your immune system the rest it needs to function optimally."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, strengthening your immune system is an ongoing process that requires consistent effort and adopting a healthy lifestyle. By implementing these tips, you're actively taking charge of your immune health. Continue to prioritize your well-being and enjoy a stronger, more resilient body!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Immune System Boost subliminal strengthen my immune system?`, 
    answer: `The Immune System Boost subliminal is designed to positively influence your subconscious mind, promoting the strengthening and optimization of your immune system. By listening to the subliminal regularly, you can align your thoughts and beliefs with a healthy and robust immune response. It works in conjunction with healthy lifestyle habits, such as a balanced diet, regular exercise, adequate sleep, and stress management, to support your immune system's overall function.`,
    open: false
  },
  { 
    question: `How long does it take to see the effects of the Immune System Boost subliminal?`, 
    answer: `The time it takes to see the effects of the Immune System Boost subliminal can vary from person to person. Some individuals may experience positive changes in their immune response relatively quickly, while others may require more time to fully benefit from the subliminal messages. Consistent listening is recommended to allow the affirmations to work on a subconscious level and create lasting change.`,
    open: false 
  },
  { 
    question: `Can the Immune System Boost subliminal prevent me from getting sick?`, 
    answer: `The Immune System Boost subliminal is designed to support and strengthen your immune system, but it is not a guarantee against illnesses or diseases. It works by aligning your thoughts and mindset with a healthy immune response, but it is important to remember that maintaining good hygiene practices, following recommended health guidelines, and seeking medical advice when necessary are also important in preventing and managing illnesses.`,
    open: false 
  },
  { 
    question: `Are there any side effects of listening to the Immune System Boost subliminal?`, 
    answer: `No, there are no known side effects associated with listening to the Immune System Boost subliminal. However, if you have any concerns about your immune health or specific medical conditions, it is recommended to consult with your healthcare provider before making any changes to your treatment plan.`,
    open: false 
  },
  { 
    question: `Can the Immune System Boost subliminal be used as a replacement for medical treatment?`, 
    answer: `No, the Immune System Boost subliminal is not intended to replace medical treatment or professional advice. It is designed to complement and support your overall health and immune system function. If you have any specific medical conditions or concerns, it is important to consult with your healthcare provider for appropriate diagnosis and treatment.`,
    open: false 
  },
  { 
    question: `Can I listen to the Immune System Boost subliminal while doing other activities?`, 
    answer: `While it is possible to listen to the Immune System Boost subliminal while doing other activities, it is recommended to allocate focused time for listening. This allows you to fully immerse yourself in the subliminal messages and enhance their effectiveness. Find a quiet and relaxing environment where you can dedicate your attention to the subliminal audio for optimal results.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>