<template>
  <footer class="p-2">
    <div
      class="card d-flex flex-column pa-1 pb-0"
      v-if="!isMobile && isPlayerAvailable"
    >
      <div class="web">
        <div class="justify-content-start pt-1 px-1">
          <div class="cover" :style="`background: url(${cover});`"></div>
          <div>
            <h5 class="mb-0 text-limit-1">{{ title }}</h5>
            <span class="text-limit-1">
              {{ category }} &middot;
              <template v-if="durationTime > 0">
                {{ convertToShortTime(durationTime) }}
              </template>
              <template v-else>
                <Buffering />
              </template>
            </span>
          </div>
        </div>

        <div class="justify-content-center pt-1 px-1">
          <div v-if="!isDataReady">
            <img
              :class="`${!isDataReady ? 'disabled' : ''}`"
              src="@/assets/images/user/webplayer/light/volume.png"
            />
          </div>

          <el-dropdown v-else trigger="click" :hide-on-click="false">
            <img src="@/assets/images/user/webplayer/light/volume.png" />
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="d-flex py-2">
                <template v-if="volumes.length > 1">
                  <div
                    class="d-flex flex-column align-items-center"
                    v-for="(volume, index) in volumes"
                    :key="index"
                  >
                    <el-slider
                      :disabled="isUserFree"
                      v-model="volumes[index]"
                      vertical
                      @change="changeVolume(index)"
                      height="200px"
                      placeholder="dasdasjld"
                    >
                    </el-slider>

                    <div
                      class="d-flex justify-content-center"
                      style="font-size: 8px"
                    >
                      {{ audio_types[index] }}
                    </div>
                  </div>
                  <v-divider
                    vertical
                    style="border: 1px solid black; margin-inline: 10px"
                  ></v-divider>
                </template>
                <div class="d-flex flex-column align-items-center">
                  <el-slider
                    vertical
                    :disabled="isUserFree"
                    height="200px"
                    v-model="allVolumes"
                    @change="changeAllVolume(allVolumes)"
                  >
                  </el-slider>
                  <div
                    class="d-flex justify-content-center"
                    style="font-size: 8px"
                  >
                    All
                  </div>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <img
            :class="`${isPrevDisabled ? 'disabled' : ''}`"
            src="@/assets/images/user/webplayer/light/prev.png"
            @click="skipAudio(false)"
          />
          <img
            v-if="isAudioPlaying"
            :class="`${!isDataReady ? 'disabled' : ''}`"
            :src="require(`@/assets/images/user/webplayer/light/pause.png`)"
            @click="toggleAudio()"
          />
          <img
            v-else
            :class="`${!isDataReady ? 'disabled' : ''}`"
            :src="require(`@/assets/images/user/webplayer/light/play.png`)"
            @click="toggleAudio()"
          />
          <img
            :class="`${isNextDisabled ? 'disabled' : ''}`"
            src="@/assets/images/user/webplayer/light/next.png"
            @click="skipAudio()"
          />
          <!-- <Favorite
            :data="recentData"
            :text="getText(recentSubliminalId)"
            :fromPlayer="true"
          /> -->
          <TestFavorite :data="recentData" />
        </div>
        <div class="justify-content-end pt-1 px-4">
          <img v-if="isAudioLoop == true"
            :class="`${!isDataReady ? 'disabled' : ''}`"
            src="@/assets/images/user/webplayer/light/repeat1.png"
            class="mx-2"
            @click="audioLoop()"
          />
          <img v-else
            :class="`${!isDataReady ? 'disabled' : ''}`"
            src="@/assets/images/user/webplayer/light/repeat.png"
            class="mx-2"
            @click="audioLoop()"
          />
          <UserEllipsis :data="recentData" :height="20" :fromPlayer="true" />
        </div>
      </div>
      <div class="d-flex justify-content-center progress-container">
        <template v-if="!isDataReady">
          <v-progress-linear
            color="#1C8EDF"
            height="6"
            class="disabled progress-line"
          ></v-progress-linear>
        </template>
        <template v-else>
          <v-progress-linear
            v-model="progress"
            color="#1C8EDF"
            height="6"
            class="progress-line"
          ></v-progress-linear>
        </template>
      </div>
    </div>
    <div
      class="card d-flex flex-column pa-1"
      v-if="isMobile && isPlayerAvailable"
    >
      <div class="mobile">
        <div class="justify-content-start pt-1 px-1">
          <div class="cover" :style="`background: url(${cover});`"></div>
          <div>
            <h5 class="mb-0 text-limit-1">{{ title }}</h5>
            <span class="text-limit-1">
              {{ category }} &middot;
              <template v-if="durationTime > 0">
                {{ convertToShortTime(durationTime) }}
              </template>
              <template v-else>
                <Buffering />
              </template>
            </span>
          </div>
        </div>
        <div class="justify-content-end pt-1 px-3">
          <img
            v-if="isAudioPlaying"
            :class="`${!isDataReady ? 'disabled' : ''}`"
            :src="require(`@/assets/images/user/webplayer/light/pause.png`)"
            @click="toggleAudio()"
          />
          <img
            v-else
            :class="`${!isDataReady ? 'disabled' : ''}`"
            :src="require(`@/assets/images/user/webplayer/light/play.png`)"
            @click="toggleAudio()"
          />
          <!-- <Favorite
            :data="recentData"
            :text="getText(recentSubliminalId)"
            :fromPlayer="true"
          /> -->
          <TestFavorite :data="recentData" :fromPlayer="true" />
        </div>
      </div>
    </div>
    <div class="card-sidebar d-flex flex-column pa-1 mt-2 mb-0" v-if="isMobile">
      <div class="pa-3">
        <div class="d-flex justify-content-center gap-4">
          <img
            class="mobile-sidebar-image"
            src="@/assets/images/user/footer/home.png"
            @click="goToRoute('overview')"
          />
          <img
            class="mobile-sidebar-image"
            src="@/assets/images/user/footer/search.png"
            @click="goToRoute('search')"
          />
          <img
            class="mobile-sidebar-image"
            src="@/assets/images/user/footer/sound-wave.png"
            @click="goToRoute('own/playlist')"
          />
          <img
            class="mobile-sidebar-image"
            src="@/assets/images/user/footer/notif.png"
            @click="goToRoute2('/price')"
          />
          <img
            class="mobile-sidebar-image"
            src="@/assets/images/user/footer/user.png"
            @click="goToRoute('settings')"
          />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { convertToShortTime } from "@/utils";
import { getText, toggleData } from "@/utils/favorite.js";

import Favorite from "@/components/User/Favorite.vue";
import Buffering from "@/components/Buffering.vue";
import UserEllipsis from "@/components/User/UserEllipsis.vue";
import TestFavorite from "@/components/User/UserFavorite.vue";

import { mapGetters } from "vuex";
import { audio } from "@/mixins/audio";
import { addAudioHistory } from "@/api/history";
import { getAudioTypeById } from "@/api/audiotype";

export default {
  mixins: [audio],
  data: () => {
    return {
      getText,
      toggleData,
      convertToShortTime,

      interval: 0,
      allVolumes: 0,

      isMobile: false,
      isInitial: true,

      audio_types: [],

      toggleText: "play",
    };
  },
  components: {
    Favorite,
    Buffering,
    UserEllipsis,
    TestFavorite,
  },
  mounted() {
    this.isMobile = this.width < 900;
    this.getData();
  },
  computed: {
    ...mapGetters({
      isDataReady: "newaudio/getDataStatus",
      isAudioPlaying: "newaudio/getPlayingStatus",
      currentTime: "newaudio/getCurrentTime",
      isAudioPlayed: "newaudio/getPlayedStatus",
      isAudioSkipped: "newaudio/getSkippedStatus",
      isAudioLoop: "newaudio/getLoopStatus",
    }),
    user() {
      return this.$store.state.user.info;
    },
    width() {
      return this.$store.state.screen.width;
    },
    recentplayed() {
      return this.$store.state.recent.played.data;
    },
    playlistType() {
      return this.$store.state.audio.playlist.type;
    },
    playlistTrack() {
      return this.$store.state.audio.playlist.tracks;
    },
    recentData() {
      return this.playlistTrack.length > 0
        ? this.playlistTrack[this.subliminalIndex]
        : this.recentplayed[this.subliminalIndex];
    },
    recentSubliminalId() {
      return this.playlistTrack.length > 0 ? this.subliminal_id : "";
    },
    subliminal_id() {
      return this.playlistTrack.length > 0
        ? this.playlistTrack[this.subliminalIndex].subliminal_id
        : "";
    },
    progress: {
      set(val) {
        this.changeCurrentTime(val);
      },
      get() {
        return (this.currentTime / this.durationTime) * 100;
      },
    },
    isFavoriteUpdated() {
      return this.$store.state.audio.favorite.update;
    },
    isPlayerAvailable() {
      return this.playlistTracks.length > 0
        ? this.playlistTracks.length > 0
        : this.recentplayed.length > 0;
    },
    isUserFree() {
      return this.user == null
        ? false
        : JSON.parse(this.user).info.subscription_id == null ||
            JSON.parse(this.user).info.subscription_id == 1;
    },
  },
  watch: {
    width(val) {
      this.isMobile = val < 900;
    },
    isDataReady(val) {
      if (val && !this.isInitial) {
        this.toggleAudio();
      }
      this.isInitial = false;
    },
    currentTime(val) {
      if (
        this.playlistTracks.length - 1 == this.subliminalIndex &&
        val == this.durationTime
      ) {
        this.$store.dispatch("newaudio/currentTime", 0);
        this.$store.dispatch("newaudio/playingStatus", false);
      }
    },
    durationTime(val) {
      if (val > 0) {
        this.$store.dispatch("newaudio/dataStatus", true);
      }
    },
    isAudioPlayed(val) {
      if (val) {
        this.isInitial = false;
      }
    },
    isAudioPlaying(val) {
      if (val) {
        let currentTime = this.currentTime;

        this.interval = setInterval(() => {
          if (currentTime < this.durationTime) {
            currentTime += 1;
            this.$store.dispatch("newaudio/currentTime", currentTime);
          } else {
            if (this.subliminalIndex < this.subliminals.length - 1) {
              if (!this.isAudioLoop) {
                this.skipAudio();
              } else {
                let self = this;
                self.toggleAudio();
                self.$store.dispatch("newaudio/currentTime", 0);
              }
              return;
            }
          }
        }, 1000);

        this.toggleText = "pause";
      } else {
        clearInterval(this.interval);
        if (!this.isAudioSkipped) {
          this.addHistory();
        }
        this.$store.dispatch("newaudio/skippedStatus", false);
        this.toggleText = "play";
      }
    },
    volumes(val) {
      this.allVolumes = val.every((item) => item == val[0]) ? val[0] : 100;
    },
    recentplayed(val) {
      if (val.length > 0) {
        this.$store.dispatch("newaudio/currentTime", val[0].current_time);
      }
    },
    audioTypes(val) {
      this.setAudioTypes(val);
    },
    isPlayerAvailable(val) {
      if (val) {
        this.getData();
      }
    },
  },
  methods: {
    getData() {
      if (
        this.subliminalIndex > 0 &&
        this.playlistTracks.length < this.subliminalIndex
      ) {
        this.$store.dispatch("newaudio/subliminalIndex", 0);
      }
      let playlistTracks = this.playlistTracks;
      if (playlistTracks.length == 0 && this.recentplayed.length > 0) {
        playlistTracks = [this.recentplayed[0]];
      }

      const isAudioLoop = this.isAudioLoop;
      this.initializeData(playlistTracks, this.subliminalIndex);
      let self = this;
      setTimeout(() => {
        self.$store.dispatch("newaudio/loopStatus", isAudioLoop);
      }, 0);
    },
    goToRoute(route) {
      this.$router.push("/user/" + route);
    },
    goToRoute2(route) {
      this.$router.push(route).catch((err) => {});
      window.scrollTo(0, 0);
    },
    setAudioTypes(audioTypes) {
      for (let index = 0; index < audioTypes.length; index++) {
        const element = audioTypes[index];
        getAudioTypeById(element).then((res) => {
          if (res != undefined) {
            if (res.length > 0) {
              this.audio_types.push(res[0].name);
            }
          }
        });
      }
    },
    audioLoop() {
      if (this.isDataReady) {
        let loop = !this.isAudioLoop;
        this.$store.dispatch("newaudio/loopStatus", loop);
      }
    },
    addHistory() {
      const user = JSON.parse(this.$store.state.user.info);
      const data = {
        user_id: user.user_id,
        volume: this.volumes.join(),
        playlist_id: this.playlistTracks[this.subliminalIndex].subliminal_id,
        current_time: this.currentTime,
        duration_time: this.durationTime,
        subliminal_id: this.playlistTracks[this.subliminalIndex].subliminal_id,
        subscription_id: user.info.subscription_id,
      };

      addAudioHistory(data).then((res) => {
        const playlist = res.playlist;
        const subliminal = res.subliminal;
        let result = playlist.concat(subliminal);

        let results = result.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });

        this.$store.dispatch("recent/getPlayed", results);

        if (this.playlistType == "recent") {
          this.$store.dispatch("audio/getPlaylistTrack", results);
          this.$store.dispatch("newaudio/subliminalIndex", 0);
        }
      });
    },
  },
};
</script>

<style scoped>
footer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
}

.web {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
}

.mobile {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
}

.card,
.card-sidebar {
  -moz-box-shadow: 2px 6px 8px #b7b7b7;
  -webkit-box-shadow: 2px 6px 8px #b7b7b7;
  border-width: 0.1px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
  border-color: #eeeeee;

  background: #ffffff;
}

.cover {
  min-width: 90px;
  height: 66px;

  border-radius: 5px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.web > div,
.mobile > div {
  display: flex;
  align-items: center;

  gap: 10px;
}

.web > div:nth-of-type(2) > div > img {
  width: 31px;
  height: 24px;
}

.web > div:nth-of-type(2) > img:nth-of-type(2) {
  width: 48px;
  height: 48px;
}

.web > div:nth-of-type(2) > img:nth-of-type(1),
.web > div:nth-of-type(2) > img:nth-of-type(3) {
  width: 40px;
  height: 39px;
}

.web > div:nth-of-type(2) > img:nth-of-type(4) {
  width: 26px;
  height: 24px;
}

.web > div:nth-of-type(3) > img:nth-of-type(1) {
  width: 30px;
  height: 30px;
}

.web > div:nth-of-type(3) > img:nth-of-type(2) {
  height: 25px;
}

.progress-container {
  padding-inline: 115px 65px;
}

.progress-line {
  border-radius: 3px;
}

.mobile > div:nth-of-type(2) > img:nth-of-type(1) {
  height: 35px;
}

.mobile > div:nth-of-type(2) > img:nth-of-type(2) {
  width: 30px;
  height: 25px;
}

.mobile-sidebar-image {
  width: 35px;
  height: 35px;
}

.disabled {
  opacity: 0.5;
  filter: alpha(opacity=50); /* msie */
}

.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.8);
}

.text-limit-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>
