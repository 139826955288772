<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Facial toning and development is a natural and effective way to improve the tone and appearance of your facial muscles. This subliminal is designed to help you achieve a more youthful and radiant look through targeted exercises and techniques."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Strengthening and toning facial muscles for improved definition and symmetry.",
        "Enhancing facial features such as cheekbones, jawline, and eyebrows.",
        "Increasing blood circulation to the face, leading to a healthier and more vibrant complexion.",
        "Reducing the appearance of fine lines, wrinkles, and sagging skin.",
        "Promoting relaxation and stress reduction for a more youthful and refreshed appearance."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating the exercises and techniques mentioned in this subliminal into your daily routine, you can start to see noticeable improvements in the tone and appearance of your facial muscles over time. Remember, consistency is key, so make sure to listen regularly and follow the recommended exercises to achieve the best results."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a journey to improve the tone and appearance of your facial muscles can be exciting, but it's important to remember that consistency is key. This subliminal is designed to support your efforts in achieving a more youthful and radiant look. Before you begin your session, consider these helpful tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Educate yourself: Familiarize yourself with facial exercises and techniques that target the specific areas you want to improve.",
        "Stay committed: Consistency is essential. Set realistic goals and dedicate regular time to perform facial exercises.",
        "Prepare your skin: Cleanse your face and apply any skincare products appropriate for your skin type before starting your session.",
        "Focus on your goals: Visualize your desired outcome and maintain a positive mindset throughout the listening session, allowing the subliminal messages to align with your subconscious mind."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, change takes time, and with dedication and patience, you can achieve the results you desire. Use this subliminal as a tool to enhance your facial features and embrace your natural beauty. Take a moment to breathe, set your intentions, and let's begin the journey towards a more youthful and radiant appearance."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Facial Toning and Development session! By dedicating time to improve the tone and appearance of your facial muscles, you are taking steps towards enhancing your facial features and achieving a more youthful and radiant look. Now, let's reflect on your experience and plan for ongoing transformation."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your experience: Take a moment to consider how facial toning exercises have made you feel and any changes you have noticed.",
        "Prioritize consistent practice: Make facial toning exercises a part of your daily routine to see continued improvement.",
        "Take care of your skin: Incorporate a skincare routine that suits your skin type and complements your facial toning efforts.",
        "Seek professional guidance: Consult with a dermatologist or aesthetician for personalized advice on facial toning and development techniques."
      ]
    },
    {
      type: 'paragraph',
      text: "Achieving noticeable results with facial toning takes time and dedication. Stay committed to your ongoing practice, maintain a positive mindset, and embrace the journey towards enhancing your facial appearance. You're on your way to a more confident and radiant self!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How often should I perform facial toning exercises?`, 
    answer: `To see noticeable results, it is recommended to perform facial toning exercises at least 3-5 times per week. Consistency is key when it comes to toning and strengthening your facial muscles. However, it is important to listen to your body and not overexert yourself. Start with a comfortable frequency and gradually increase the intensity or duration as your muscles become more accustomed to the exercises.`,
    open: false
  },
  { 
    question: `How long does it take to see results from facial toning exercises?`, 
    answer: `Individual results may vary, but with consistent practice, you may start to notice improvements in your facial tone and appearance within a few weeks to a couple of months. It's important to be patient and maintain a regular routine to allow your muscles to strengthen and for the benefits to become more visible.`,
    open: false 
  },
  { 
    question: `Are facial toning exercises suitable for everyone?`, 
    answer: `Facial toning exercises are generally safe and suitable for most individuals. However, if you have any underlying health conditions or concerns, it is advisable to consult with a healthcare professional before starting any new exercise regimen, including facial toning exercises. They can provide personalized advice based on your specific circumstances.`,
    open: false 
  },
  { 
    question: `Can facial toning exercises help reduce wrinkles and sagging skin?`, 
    answer: `Facial toning exercises can help improve the tone and elasticity of your facial muscles, which may indirectly contribute to reducing the appearance of wrinkles and sagging skin. By strengthening the muscles, they can provide a more lifted and youthful appearance. However, it is important to note that results may vary depending on factors such as genetics, age, and overall skincare routine.`,
    open: false 
  },
  { 
    question: `Is it necessary to use additional skincare products alongside facial toning exercises?`, 
    answer: `While facial toning exercises can contribute to improving the appearance of your facial muscles, it is beneficial to complement them with a comprehensive skincare routine. Using appropriate cleansers, moisturizers, and sunscreen can help maintain the health and condition of your skin. It is always recommended to choose products that are suitable for your skin type and address your specific concerns.`,
    open: false 
  },
  { 
    question: `Can I achieve a more defined jawline through facial toning exercises?`, 
    answer: `Yes, facial toning exercises can help strengthen and define your jawline muscles, contributing to a more sculpted appearance. Exercises that target the jawline and neck area can help tone and tighten the muscles in that region. However, it is important to note that individual results may vary, and consistency in practicing the exercises is key to seeing improvements.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>