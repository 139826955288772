<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you struggle with maintaining focus and concentration, this subliminal can help you enhance your abilities in these areas. Whether you need to stay focused on work, study, or any other task, this subliminal can support you in improving your productivity and performance."
    },
    {
      type: 'heading',
      text: 'Here are some ways in which this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Increase your ability to stay focused for longer periods of time.",
        "Improve your concentration skills, allowing you to delve deeper into tasks.",
        "Enhance your mental clarity and alertness, leading to better decision-making.",
        "Boost your productivity and efficiency in various activities.",
        "Train your mind to ignore distractions and maintain a single-pointed focus.",
        "Develop the ability to switch between tasks seamlessly, without losing concentration."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to support you in staying focused and concentrated on whatever you set out to accomplish. Remember, like any skill, focus and concentration can be developed and improved with consistent practice and dedication."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Developing your focus and concentration skills can have a significant impact on your productivity and performance in various tasks and activities. This subliminal is designed to help enhance your abilities in these areas. Before you start listening, here are some preparation tips to consider:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a distraction-free environment: Find a quiet and comfortable space where you can listen without interruptions.",
        "Set clear goals: Identify specific tasks or activities where you want to improve your focus and concentration.",
        "Eliminate external distractions: Minimize or remove distractions such as noise, notifications, and other sources of interruption.",
        "Practice mindfulness: Enhance your ability to be present and focused by incorporating mindfulness techniques into your daily routine.",
        "Have a positive mindset: Believe in your potential to improve and approach the training with an open and optimistic mindset."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal audio program aims to train and develop your focus and concentration abilities by targeting your subconscious mind. Regular listening and consistent practice can lead to noticeable improvements over time. Embrace this opportunity to invest in your personal and professional growth, and let the journey to enhanced focus and concentration begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Focus and Concentration Training and Development session! By investing time and effort in enhancing your focus and concentration abilities, you're taking steps towards improving your productivity and performance in various aspects of life. Now, it's important to reflect on your experience and continue your growth in this area."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice mindfulness: Engage in activities like meditation or deep breathing to improve your ability to stay present and focused.",
        "Eliminate distractions: Create a conducive environment free from distractions like noise, clutter, and unnecessary technology.",
        "Prioritize tasks: Utilize techniques like the Eisenhower Matrix or time blocking to manage your priorities effectively.",
        "Take regular breaks: Allow yourself short breaks to recharge your focus and prevent mental fatigue.",
        "Set achievable goals: Break down larger tasks into smaller, manageable goals to maintain your motivation and momentum.",
        "Implement focus exercises: Incorporate practices like the Pomodoro Technique or visualizations to enhance your focus and concentration skills."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, improving your focus and concentration abilities is an ongoing process. Embrace the journey and keep practicing these techniques consistently. With dedication and persistence, you'll experience noticeable improvements in your productivity and performance. Stay focused and keep thriving!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Focus and Concentration Training and Development program benefit me?`, 
    answer: `The Focus and Concentration Training and Development program can benefit you by improving your ability to concentrate, stay focused, and eliminate distractions. It can enhance your productivity and performance in various tasks or activities, such as studying, work, or creative projects. By training your mind to maintain a high level of focus and concentration, you can achieve better results and make the most of your time and energy.`,
    open: false
  },
  { 
    question: `How long does it take to see improvements in focus and concentration with the program?`, 
    answer: `The time it takes to see improvements in focus and concentration can vary from person to person. Some individuals may notice positive changes shortly after starting the program, while others may need more time and consistent practice. The key is to stay committed and practice the techniques and exercises regularly to build and strengthen your focus and concentration abilities.`,
    open: false 
  },
  { 
    question: `What techniques or exercises are included in the Focus and Concentration Training and Development program?`, 
    answer: `The Focus and Concentration Training and Development program may include a variety of techniques and exercises tailored to improve focus and concentration. These may include mindfulness meditation, visualization exercises, goal-setting strategies, time-management techniques, and cognitive training exercises. The specific techniques and exercises may vary depending on the program you are following, but they are all designed to enhance your ability to maintain focus and concentration.`,
    open: false 
  },
  { 
    question: `Can the program help with issues like ADHD or other attention-related disorders?`, 
    answer: `The Focus and Concentration Training and Development program may be helpful for individuals with attention-related challenges like ADHD, but it is not a substitute for professional medical advice or treatment. It is recommended to consult with a healthcare professional or specialist in attention-related disorders for a comprehensive evaluation and personalized guidance. The program may complement other therapeutic approaches or be used as a self-help tool in conjunction with professional treatment.`,
    open: false 
  },
  { 
    question: `Are there any side effects or risks associated with the Focus and Concentration Training and Development program?`, 
    answer: `No, there are no known side effects or risks associated with the Focus and Concentration Training and Development program. However, it is important to practice the techniques and exercises mindfully and within your personal capabilities. If you experience any discomfort or have concerns, consult with a healthcare professional or specialist.`,
    open: false 
  },
  { 
    question: `Can the program be used by anyone, regardless of age or occupation?`, 
    answer: `Yes, the Focus and Concentration Training and Development program can be used by individuals of various ages and occupations. The techniques and exercises can benefit students, professionals, athletes, artists, or anyone who wants to improve their focus and concentration abilities. The program is designed to be adaptable and customizable to different needs and goals.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>