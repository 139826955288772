<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're struggling with anxiety and searching for ways to find relief, this subliminal is here to guide you towards a calmer, more peaceful state of mind. By reprogramming your subconscious mind, it can help you overcome anxious thoughts and embrace a life free from the grip of anxiety."
    },
    {
      type: 'heading',
      text: 'Here are some techniques and strategies you can discover through this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Deep breathing exercises and relaxation techniques to help you calm your mind and body.",
        "Positive affirmations and empowering beliefs to replace negative and anxious thoughts.",
        "Visualization exercises to imagine yourself in serene, peaceful settings, promoting a sense of calmness.",
        "Coping mechanisms and strategies to manage and reduce anxiety symptoms.",
        "Mindfulness and meditation practices to ground yourself in the present moment and find inner peace."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating these techniques into your daily life and listening to this subliminal regularly, you can begin to break free from the cycle of anxiety and create a more harmonious, anxiety-free existence. Remember, healing takes time and effort, but with dedication and perseverance, you can find inner peace and calmness."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a journey to heal from anxiety and find inner peace is a courageous step towards a happier and healthier life. This subliminal is designed to support you on this journey by introducing positive affirmations into your subconscious mind. Before you begin listening, here are some suggestions to maximize your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a serene space: Find a quiet and comfortable environment in which you can listen to the subliminal without distractions.",
        "Set your intention: Clearly define your desire to overcome anxiety and find inner calmness. Visualize yourself living a life free from the grip of anxiety.",
        "Practice deep breathing: Take a few moments to engage in deep breathing exercises before starting the subliminal. This can help relax your mind and body, allowing you to be more receptive to the affirmations.",
        "Stay open and receptive: Approach this subliminal with an open mind and heart, fully embracing the potential for positive change and healing."
      ]
    },
    {
      type: 'paragraph',
      text: "As you continue to listen to this subliminal regularly, you may begin to notice a reduction in anxious thoughts and an increase in inner peace and calmness. Be patient with the process and kind to yourself along the way. Remember, healing takes time, but with dedication and perseverance, you can create a life free from the limitations of anxiety. Take a moment now to set your intention and let the transformative journey begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Anxiety Healing session! By investing in your mental well-being, you've taken a major step towards overcoming anxiety and finding inner peace. Now it's time to reflect on your experience and continue your journey towards a life free from the grip of anxiety."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice mindfulness: Incorporate mindfulness techniques, such as deep breathing and meditation, into your daily routine to reduce anxiety.",
        "Challenge negative thoughts: Implement cognitive restructuring, questioning negative thoughts, and replacing them with positive and rational ones.",
        "Engage in self-care: Prioritize activities that bring you joy and relaxation, such as hobbies, physical exercise, or spending time with loved ones.",
        "Seek support: Consider reaching out to a therapist or joining a support group to receive professional guidance and connect with others going through similar experiences."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, healing from anxiety is a process that varies for each individual. Be patient and kind to yourself as you navigate this journey. With the techniques and strategies you've learned, you're now equipped to find inner peace and live a life free from the grip of anxiety. Trust in your ability to overcome and thrive!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Anxiety Healing subliminal help me overcome anxiety?`, 
    answer: `The Anxiety Healing subliminal is designed to help you reprogram your subconscious mind, allowing you to let go of anxious thoughts and find inner peace and calmness. By listening to the subliminal regularly, you can train your mind to adopt new thought patterns and release the grip of anxiety. It is important to be consistent and patient, as the process of overcoming anxiety may vary from person to person.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Anxiety Healing subliminal?`, 
    answer: `Results may vary from individual to individual, and the time it takes to see results can depend on various factors such as the severity of anxiety and individual responsiveness. Some people may notice improvements in their anxiety levels shortly after starting to listen to the subliminal, while others may require more time and consistent listening. It is recommended to listen to the subliminal regularly for several weeks for best results.`,
    open: false 
  },
  { 
    question: `Can the Anxiety Healing subliminal be used as a substitute for therapy or professional help?`, 
    answer: `The Anxiety Healing subliminal can be a helpful tool in managing anxiety, but it is not a substitute for professional therapy or medical help. If you are experiencing severe anxiety or have a diagnosed anxiety disorder, it is best to seek professional assistance from a licensed therapist or healthcare provider. The subliminal can be used as a complementary tool to support your overall well-being.`,
    open: false 
  },
  { 
    question: `Are there any side effects of listening to the Anxiety Healing subliminal?`, 
    answer: `There are no known side effects associated with listening to the Anxiety Healing subliminal. However, if you have any concerns about your mental health or are currently under the care of a mental health professional, it is advisable to consult with them before using any subliminal audio recordings.`,
    open: false 
  },
  { 
    question: `Can the Anxiety Healing subliminal be listened to while sleeping?`, 
    answer: `Yes, you can listen to the Anxiety Healing subliminal while sleeping. Your subconscious mind remains active during sleep, making it an ideal time to reinforce positive affirmations and reprogram your thought patterns. You can play the subliminal at a low volume or use comfortable headphones designed for sleep.`,
    open: false 
  },
  { 
    question: `What can I do to enhance the effectiveness of the Anxiety Healing subliminal?`, 
    answer: `In addition to listening to the subliminal, you can complement its effects by practicing relaxation techniques, engaging in regular exercise, and maintaining a healthy lifestyle. Setting realistic goals, practicing mindfulness, and seeking support from loved ones or a therapist can also contribute to your overall anxiety healing journey.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>