<template>
  <div>
    <div class="mx-6 mt-5">
      <Card>
        <v-row>
          <v-col cols="12" sm="6" lg="6" v-for="search in searchs">
            <Select
              :title="search.title"
              :type="search.type"
              :text="search.text"
              :value="search.value"
              @getSelectItem="getSelectItem"
            ></Select>
          </v-col>
        </v-row>
      </Card>
    </div>

    <Table
      itemkey="title"
      :loading="loading"
      :items="items"
      :headers="headers"
      :select="selected"
      :changePage="changePage"
      :total="total"
      @action="getAction"
      @updateLimitPage="updateLimitPage"
      expandtype="track"
      :addVisible="true"
      :showexpand="true"
      @updateSearch="updateSearch"
      @updateSort="updateSort"
      @updateVisible="updateVisible"

    />

    <addItemDialog
      v-if="dialogType == 'additem'"
      :data="data"
      :dialog="dialog"
      @closeDialog="closeDialog"
    />

    <addDialog
      v-if="dialogType == 'add'"
      :data="data"
      :dialog="dialog"
      @closeDialog="closeDialog"
    />

    <editDialog
      v-if="dialogType == 'update'"
      :data="data"
      :dialog="dialog"
      @closeDialog="closeDialog"
    />

    <deleteDialog
      v-if="
        dialogType == 'delete' ||
        dialogType == 'deleteall' ||
        dialogType == 'expanddelete'
      "
      :data="data"
      :type="dialogType"
      :dialog="dialog"
      :selected="selected"
      @selected="getSelected"
      @closeDialog="closeDialog"
    />
  </div>
</template>

<script>
import { getTrackFilters, updateToggle } from "@/api/track";
import { getAudio, playAudio, pauseAudio } from "@/utils/audio";
import { getMessage } from "@/utils";

import Card from "@/components/Card.vue";
import Table from "@/components/Admin/Table.vue";
import Select from "@/components/Admin/Select.vue";

import addDialog from "./components/addDialog.vue";
import editDialog from "./components/editDialog.vue";
import deleteDialog from "./components/deleteDialog.vue";
import addItemDialog from "./components/addItemDialog.vue";

export default {
  components: {
    Card,
    Table,
    Select,
    addDialog,
    editDialog,
    deleteDialog,
    addItemDialog,
  },
  data: () => {
    return {
      getAudio,
      playAudio,
      pauseAudio,

      getTrackFilters,
      getMessage,
      updateToggle,

      data: {},
      dialog: false,
      dialogType: null,

      items: [],
      selected: [],
      loading: true,

      headers: [
        { text: "Title", value: "title", sortable: false },
        { text: "Description", value: "description", sortable: false },
        { text: "Audio Type", value: "name", sortable: false },
        { text: "Subscription", value: "subscription_id", sortable: false },
        {
          text: "Action",
          value: "add_update_delete",
          sortable: false,
          width: "230",
        },
      ],

      searchs: [
        { title: "Title", type: "track", text: "title", value: "title" },
        { title: "Audio Type", type: "audiotype", text: "name", value: "name" },
      ],

      sort: null,
      visible: null,
      title: null,
      search: null,
      audio_type: null,
      subscription: null,

      page: 1,
      total: 0,
      limit: 10,
      changePage: false,

    };
  },
  created() {
    this.getData();
  },
  computed: {
    audio() {
      return this.$store.state.audio.data;
    },
    version() {
      return this.$store.state.audio.version;
    },
    isAudioPlaying() {
      return this.$store.state.audio.status;
    },
    durationTimes() {
      return this.$store.state.audio.duration.times;
    },
  },
  watch: {
    subscription() {
      this.getData();
    },
    sort() {
      this.getData();
    },
    visible() {
      this.getData();
    },
    search() {
      this.getData();
    },
    audio_type() {
      this.getData();
    },
    title() {
      this.getData();
    },
    durationTimes(val) {
      if (val.length > 0) {
        const duration = Math.max(...val);
        const index = val.indexOf(duration);

        this.$store.dispatch("audio/getCurrentTime", 0);
        this.$store.dispatch("audio/getDurationTime", val[index]);

        this.playAudio();
      }
    },
  },
  methods: {
    getSelected(data) {
      this.selected = data;
    },
    closeDialog() {
      this.dialog = false;
      this.dialogType = "";
      this.getData();
    },
    getAction(params) {
      this.dialog = true;
      this.data = params.data;
      this.dialogType = params.action;
      this.selected = params.selected;
      const index = params.index;

      if (params.action == "additem") {
        const trackid = params.data.track_id;
        this.$store.dispatch("audio/getTrackId", trackid);
      }

      if (params.action == "expandplay") {
        this.$store.dispatch("audio/getDurationTime", null);
        if (this.isAudioPlaying) {
          this.pauseAudio(false);
        }

        const version = this.data.tracks[index].version;
        this.data.version = version;

        this.getAudio(this.data, index);
      }

      if (params.action == "expandpause") {
        this.pauseAudio(false);
      }

      if (params.action == "toggle") {
        if (this.data.toggle == true) {
          this.isVisible = 0;
        } else {
          this.isVisible = 1;
        }
        const data = {
          is_visible: this.isVisible,
          track_id: this.data.track_id,
        };
        this.updateToggle(data).then((res) => {
          this.getMessage(this, res);
          this.getData();
        });
      }
    },
    getData() {
      this.loading = true;
      const data = {
        page: this.page,
        sort: this.sort,
        visible: this.visible,
        title: this.title,
        limit: this.limit,
        search: this.search,
        subscription_id: this.subscription,
        audio_type: this.audio_type,
      };

      this.getTrackFilters(data).then((res) => {
        const { tracks, total } = res;

        this.total = total;
        this.items = tracks;

        this.loading = false;
      });
    },
    getSelectItem(item) {
      this.changePage = !this.changePage
      switch (item.type) {
        case "track":
          this.title = item.data;
          break;
        case "audiotype":
          this.audio_type = item.data;
          break;
        case "subscription":
          this.subscription = item.data;
          break;
      }
    },
    updateLimitPage(data) {
      const { page, limit } = data;
      this.page = page;
      this.limit = limit;

      this.getData();
    },
    updateSearch(data) {
      this.search = data;
      this.getData();
    },
    updateSort(data) {
      this.sort = data;
      this.getData();
    },
    updateVisible(data) {
      this.visible = data;
      this.getData();
    },
  },
};
</script>
