<template>
  <Dialog :dialog="dialog" :width="800" :title="`Edit Audio Type`" @closeDialog="closeDialog">
    <div>
      <p class="mb-1">Audio Type Name</p>
      <v-text-field solo dense hide-details class="mb-3" placeholder="Audio Type" v-model="name"></v-text-field>

      <p class="mb-1">Description</p>
      <v-textarea solo dense hide-details class="mb-3" placeholder="Description" v-model="description"></v-textarea>

      <p class="mb-0">Volume</p>
      <v-slider v-model="volume" :max="100" hide-details>
        <template v-slot:prepend>
          <v-text-field solo dense v-model="volume" type="number" style="width: 70px" hide-details
            disabled></v-text-field>
        </template>
      </v-slider>
    </div>

    <template v-slot:action>
      <v-row class="ma-0">
        <v-col class="pl-0 pr-2">
          <v-btn block @click="submitDialog()" :disabled="disabled">Update</v-btn>
        </v-col>
        <v-col class="pl-2 pr-0">
          <v-btn block @click="closeDialog()">Cancel</v-btn>
        </v-col>
      </v-row>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialog.vue'

import { getMessage } from '@/utils'
import { updateAudioTypes } from '@/api/audiotype.js'

export default {
  props: {
    dialog: {
      type: Boolean
    },
    data: {
      type: Object
    }
  },
  data: () => {
    return {
      getMessage,
      updateAudioTypes,

      id: null,
      name: null,
      volume: null,
      description: null
    }
  },
  components: {
    Dialog
  },
  computed: {
    disabled() {
      return this.name == null || this.name == ''
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.id = this.data.id
      this.name = this.data.name
      this.volume = this.data.volume
      this.description = this.data.description
    },
    resetDialog() {
      this.id = null
      this.name = null
      this.volume = null
      this.description = null
    },
    closeDialog() {
      this.$emit('closeDialog', false)
      this.resetDialog()
    },
    submitDialog() {
      const data = {
        id: this.id,
        name: this.name,
        volume: this.volume,
        description: this.description
      }

      this.updateAudioTypes(data).then((res) => {
        const audio_types = res.data

        this.getMessage(this, res)
        this.closeDialog()

        this.$store.dispatch("audio/getTypes", audio_types)
      })
    }
  }
}
</script>
