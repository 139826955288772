<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Making the decision to stop drunk driving is a crucial step towards creating a safer environment on the roads. This subliminal is designed to aid you in taking that pledge and staying committed to your choice."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help you:"
    },
    {
      type: 'list',
      items: [
        "Increasing your awareness of the dangers and consequences of drunk driving.",
        "Strengthening your resolve to make responsible choices about alcohol consumption and driving.",
        "Empowering you to resist peer pressure and say no to drinking and driving.",
        "Helping you develop alternative plans for transportation and finding healthier ways to have fun.",
        "Supporting you in reaching out for help and finding support networks if needed."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind, reinforcing your commitment to not drink and drive. Remember, your choice to stop drunk driving makes a significant impact on your own safety as well as the safety of others on the road. Together, we can create a world with fewer accidents and tragedies caused by drunk driving."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Taking the pledge to stop drunk driving is an important step towards creating safer roads for everyone. This subliminal is designed to support and reinforce your commitment to this crucial cause. Before you start listening, consider the following tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Understand the consequences: Educate yourself about the risks and potential consequences of drunk driving to strengthen your resolve.",
        "Set a personal goal: Determine how you want to contribute to the cause - whether it's by spreading awareness, being a responsible designated driver, or supporting organizations that combat drunk driving.",
        "Reflect on the impact: Consider the potential positive impact your commitment can have on your own life, the lives of others, and the community as a whole.",
        "Share your pledge: Express your commitment to stop drunk driving to friends, family, and loved ones, and encourage them to join you in making responsible choices."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal is a powerful tool to reinforce your dedication and strengthen your resolve to stop drunk driving. By listening regularly, you can align your subconscious mind with your conscious intentions, making it easier to make responsible choices and create a safer environment for everyone. So let's take the pledge together and make a difference on the roads!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on committing to stop drunk driving! By taking this pledge and choosing to prioritize the safety of yourself and others on the road, you've made a significant decision that can have a positive impact on countless lives."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Spread awareness: Share the importance of not driving under the influence with your friends, family, and community.",
        "Plan ahead: Designate a sober driver or make arrangements for alternative transportation before going out.",
        "Offer assistance: If you notice someone attempting to drive while intoxicated, intervene and find a safe alternative for them.",
        "Support local organizations: Get involved with local initiatives that promote responsible drinking and discourage drunk driving."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, the choice to not drive under the influence is a powerful one that contributes to a safer community. By being proactive and spreading awareness about the dangers of drunk driving, you are making a difference. Thank you for taking this important step towards preventing accidents and protecting lives."
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What can I do to help stop drunk driving?`, 
    answer: `There are several actions you can take to help stop drunk driving. Firstly, never drink and drive yourself and always have a designated driver or use alternative transportation options if you plan to consume alcohol. Encourage others to do the same and educate them about the dangers of drunk driving. If you see someone who appears intoxicated and about to drive, intervene and offer them a safe alternative or call for assistance. Lastly, support organizations and campaigns that advocate for stricter laws and penalties for drunk driving.`,
    open: false
  },
  { 
    question: `What are the consequences of drunk driving?`, 
    answer: `Drunk driving can have severe consequences both legally and in terms of personal safety. If caught, consequences may include fines, license suspension or revocation, mandatory alcohol education or treatment programs, and even imprisonment. On the personal safety front, drunk driving increases the risk of accidents, injuries, and fatalities for both the driver and others on the road. It is always best to avoid driving under the influence to prevent these potential life-altering consequences.`,
    open: false 
  },
  { 
    question: `How can I recognize signs of drunk driving?`, 
    answer: `Recognizing signs of potential drunk driving can help you take appropriate action to prevent accidents or report unsafe behaviors. Some common signs include erratic or weaving driving, sudden and frequent braking or accelerating, driving significantly below or above the speed limit, making wide turns, ignoring traffic signals or signs, and driving with headlights off at night. If you observe any of these signs, keep a safe distance and report the vehicle to the authorities if necessary.`,
    open: false 
  },
  { 
    question: `What should I do if I suspect someone is driving under the influence?`, 
    answer: `If you suspect someone is driving under the influence, do not try to confront or intervene directly. Instead, prioritize your safety and the safety of others on the road. Take note of the vehicle's description, license plate number, and any other identifying details. Contact the local authorities or the non-emergency police line and provide them with the information. They can take appropriate action to prevent potential accidents or harm caused by drunk driving.`,
    open: false 
  },
  { 
    question: `Where can I find resources and support to stop drunk driving?`, 
    answer: `There are various organizations and campaigns dedicated to stopping drunk driving that provide resources, information, and support. You can start by researching local initiatives or contacting national organizations such as Mothers Against Drunk Driving (MADD). These organizations offer educational materials, support groups, and opportunities to get involved in advocacy work to prevent drunk driving and support affected individuals and families.`,
    open: false 
  },
  { 
    question: `How can I spread awareness about the dangers of drunk driving?`, 
    answer: `You can spread awareness about the dangers of drunk driving by using various platforms and methods. Consider sharing information and statistics through social media, organizing or participating in awareness events or campaigns, and engaging in conversations with friends, family, and colleagues about the importance of responsible alcohol consumption and never driving under the influence. Encourage others to take the pledge to stop drunk driving and create a safer road environment for everyone.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>