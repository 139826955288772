<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Delivering a successful investment presentation requires more than just the right content - it also requires the ability to captivate your audience and attract potential investors. That's where this subliminal comes in!"
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help you:'
    },
    {
      type: 'list',
      items: [
        "Boosting your confidence and reducing nervousness or stage fright when presenting.",
        "Enhancing your public speaking skills and communication abilities.",
        "Improving your ability to articulate your ideas and present complex information clearly.",
        "Increasing your charisma and presence, making you more compelling to listen to.",
        "Attracting positive energy and opportunities during your investment presentations.",
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can train your mind to be more confident, articulate, and persuasive during your investment presentations. The affirmations embedded in the subliminal will help you overcome any self-doubt or anxiety, allowing you to deliver your pitch with poise and captivate your audience. Remember, practice is essential, but this subliminal can give you the mental edge you need to succeed."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Preparing for an investment presentation can be both exciting and nerve-wracking. This subliminal is designed to help you build confidence, enhance your communication skills, and deliver a successful presentation that captivates your audience. Before you start listening, here are some tips to set yourself up for success:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Outline your key points: Clearly define your main ideas and supporting evidence to create a structured and compelling presentation.",
        "Practice your delivery: Rehearse your presentation multiple times to build confidence, refine your speaking style, and ensure a smooth delivery.",
        "Visualize success: Imagine yourself delivering a powerful presentation, engaging your audience, and receiving positive feedback.",
        "Manage your nerves: Use relaxation techniques, deep breathing exercises, or visualization to stay calm and composed during your presentation."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to tap into your subconscious mind and help you unlock your full potential as a presenter. As you listen regularly, you may notice increased confidence, improved public speaking skills, and a greater ability to captivate your audience. Take a moment to focus on your goals, visualize your success, and get ready to deliver an impactful investment presentation."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Investment Presentation Success subliminal! By engaging with this audio, you've taken a significant step towards improving your presentation skills and captivating potential investors. Now it's time to reflect on your experience and embrace the opportunities that lie ahead."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Refine your content: Review and refine the content of your investment presentation to ensure it effectively communicates your message and value.",
        "Practice your delivery: Dedicate time to rehearse your presentation, paying attention to your tone, body language, and overall confidence.",
        "Seek feedback: Reach out to trusted individuals for feedback on your presentation skills and seek areas of improvement.",
        "Research your audience: Understand your potential investors and tailor your presentation to address their specific needs and concerns."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, delivering a successful investment presentation requires preparation, practice, and adaptability. Embrace the opportunity to showcase your ideas and vision, and let your passion shine through. With dedication and perseverance, you can captivate your audience and attract potential investors to support your ventures. Good luck!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Investment Presentation Success subliminal help improve my presentation skills?`, 
    answer: `The Investment Presentation Success subliminal is designed to enhance your confidence, public speaking skills, and ability to deliver a compelling investment presentation. By listening to the subliminal regularly, you may experience improvements in your communication skills, clarity of speech, and overall stage presence. It can help you tap into your inner potential and present your ideas in a confident and convincing manner.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Investment Presentation Success subliminal?`, 
    answer: `Results can vary depending on the individual and their unique circumstances. Some people may experience positive changes right away, while others may require more time and consistent listening to fully reap the benefits. We recommend listening to the subliminal daily and consistently for best results, and practicing your presentation skills regularly to reinforce the positive changes.`,
    open: false 
  },
  { 
    question: `Are there any tips to help me maximize the effectiveness of the Investment Presentation Success subliminal?`, 
    answer: `To maximize the effectiveness of the Investment Presentation Success subliminal, it's important to set clear goals for your presentation, develop a strong understanding of your topic, and practice your presentation skills regularly. Visualizing yourself delivering a successful presentation while listening to the subliminal can also help reinforce your subconscious mind's alignment with your goals. Additionally, seeking feedback from trusted individuals can provide valuable insights to further improve your presentation skills.`,
    open: false 
  },
  { 
    question: `Can the Investment Presentation Success subliminal guarantee that I will attract investors?`, 
    answer: `The Investment Presentation Success subliminal is designed to enhance your presentation skills and increase your chances of captivating your audience and attracting potential investors. It provides subconscious support and alignment with your goals, but ultimately, the success of attracting investors depends on various factors, including the quality of your business plan, market conditions, and individual investor preferences.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Investment Presentation Success subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Investment Presentation Success subliminal. However, it is important to note that the subliminal is intended to supplement your efforts in improving your presentation skills and should not replace thorough preparation and practice.`,
    open: false 
  },
  { 
    question: `Can I listen to the Investment Presentation Success subliminal while practicing my presentation?`, 
    answer: `Yes, you can listen to the Investment Presentation Success subliminal while practicing your presentation. The subliminal messages are designed to support and align your subconscious mind with your presentation goals. Listening to it during practice sessions can help enhance your confidence and delivery, reinforcing the positive changes you aim to achieve.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>