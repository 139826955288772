<template>
  <Dialog :dialog="dialog" :width="800" :title="`Edit Subscription`" @closeDialog="closeDialog">
    <div>
      <v-row class="ma-0 justify-space-between">
        <v-col cols="9" class="pa-0">
          <p class="mb-1">Subscription Name</p>
          <v-text-field solo dense hide-details class="mb-3" placeholder="Subscription" v-model="name"></v-text-field>
        </v-col>
        <v-col cols="2" class="pa-0">
          <p class="mb-1">Color Card</p>
          <el-color-picker class="colorcard" v-model="color"></el-color-picker>
        </v-col>
      </v-row>
      
      <p class="mb-1">Description</p>
      <v-textarea solo dense hide-details class="mb-3" placeholder="Description" v-model="description"></v-textarea>
      <p class="mb-1">Amount (Month)</p>
      <v-text-field type="number" solo dense hide-details class="mb-3" placeholder="Amount (Month)"
        v-model="amount_month"></v-text-field>
      <p class="mb-1">Amount (Year)</p>
      <v-text-field type="number" solo dense hide-details class="mb-3" placeholder="Amount (Year)"
        v-model="amount_year"></v-text-field>
    </div>

    <template v-slot:action>
      <v-row class="ma-0">
        <v-col class="pl-0 pr-2">
          <v-btn block @click="submitDialog()" :disabled="disabled">Update</v-btn>
        </v-col>
        <v-col class="pl-2 pr-0">
          <v-btn block @click="closeDialog()">Cancel</v-btn>
        </v-col>
      </v-row>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialog.vue'

import { getMessage } from '@/utils'
import { updateSubscriptions } from '@/api/subscription.js'
import { deleteLocalImage } from '@/api/file.js'

export default {
  props: {
    dialog: {
      type: Boolean
    },
    data: {
      type: Object
    }
  },
  data: () => {
    return {
      getMessage,
      updateSubscriptions,
      deleteLocalImage,

      id: null,
      name: null,
      description: null,
      amount_month: null,
      amount_year: null,
      color: null,
      status: 0,
    }
  },
  components: {
    Dialog
  },
  created() {
    this.getData()
  },
  computed: {
    disabled() {
      return (
        this.name == null ||
        this.name == ''
      )
    }
  },
  methods: {
    getData() {
      this.id = this.data.id
      this.name = this.data.name
      this.description = this.data.description
      this.amount_month = this.data.amount
      this.amount_year = this.data.amount_year
      this.status = this.data.is_visible
      this.color = this.data.color
    },
    resetDialog() {
      this.id = null
      this.name = null
      this.description = null
      this.amount_month = null
      this.amount_year = null
      this.color = null
    },
    closeDialog() {
      this.$emit('closeDialog', false)
      this.deleteLocalImage(this.image)
      this.resetDialog()
    },
    submitDialog() {
      const data = {
        id: this.id,
        name: this.name,
        description: this.description,
        is_visible: this.status,
        color: this.color,
        amount: parseInt(this.amount_month),
        amount_year: parseInt(this.amount_year)
      }

      this.updateSubscriptions(data).then((res) => {
        const moods = res.data

        this.getMessage(this, res)
        this.closeDialog()

        this.$store.dispatch("subscription/getData", moods)
      })
    }
  }
}
</script>
