<template>
  <section>
    <div class="container">
      <div>
        <h1 class="maintitle">
          Subliminal Guide
        </h1>
        <h5>
          All contents are under construction and will be available on the next
          version of the manual. The list of topics is not yet final, and more
          topics and information will be added soon.
        </h5>
      </div>
      <div class="col-10 col-lg-8 mx-auto">
        <div class="row my-4 ">
          <template v-for="item, index in items">
            <div class="product-box-left" v-if="item.id % 2 != 0">
              <div>
                <p v-html="item.description"></p>
              </div>
              <div>
                <img :src="item.cover" alt="">
              </div>
            </div>
            <div v-else class="product-box-right">
              <div>
                <img :src="item.cover" alt="">
              </div>
              <div>
                <p v-html="item.description"></p>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div>
        <button class="button btn btn-primary btn-lg"  @click="$router.go(-1)">
        Back</button>
      </div>
    </div>
  </section>
</template>

<script>
import { getMagus } from '@/api/magusdetails'

export default {
  data: () => {
    return {
      getMagus,

      show_interface: true,
      menus: [
        { icon: '1',
          details: "Enjoy the freedom to use our services whenever you want and wherever you are. We're available 24/7, making it easy for you to get what you need at any time of the day or night. Whether you're at home, in the office, or on the go, we're always just a click away. Experience ultimate convenience with our anytime, anywhere accessibility.",
          title: 'Use anytime and anywhere' 
         },
        { icon: '2', 
          details: "Our Custom Content Department is dedicated to crafting personalized subliminal audio tracks that aim to improve your mental well-being. We understand that each individual's journey to mental health is unique. That's why our team of experts works closely with you to understand your specific needs and challenges.<br><br>Using this insight, we create custom audio experiences designed to help you achieve your mental health goals. From reducing stress and anxiety to boosting confidence and focus, our tailored soundtracks serve as a supportive tool in your self-improvement journey. With our specialized content, you get more than just a listening experience; you get a personalized pathway to a healthier mind.",
          title: 'Custom Content Development' 
        },
        { icon: '3', 
          details: "Boost Your Team's Well-being and Productivity with Our Curated Mental Health Audio Tracks! Designed to improve focus, relieve stress, and build a healthier mindset, our subliminal audio service is easily accessible through our app and website. Ideal for teams who want to excel, while also caring for their mental health.",
          title: 'Curated Content for Work and Teams' 
        },
        { icon: '4', 
          details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in.',
          title: 'Comprehensive Dashboards and Reports' 
        }
      ],
      items: [],
    }
  },
  computed: {
    showInterfaceButton() {
      return this.show_interface ? "Back" : "Learn More";
    }
  },
  created() {
    this.getData()
  },
  methods: {
    goToRoute(route) {
      if (this.currentRoute != route) {
        this.$router.push(route).catch(err => { })
        window.scrollTo(0,0);
      }
    },
    getData() {
      const data = {
        title: 'Subliminal Guide',
      }
      this.getMagus(data).then((res) => {
        this.items=(res.data[0].info)
      })
    },
    
  }
}
</script>

<style scoped>
  section:nth-of-type(1)>.container {

    & h1, p,
    h5 {
      line-height: 1.6em;
    }
    & h1 {
      color: var(--ma-tufts-blue);
    }

    &>div:nth-of-type(1)>h5:nth-of-type(1) {
      color: var(--ma-black-olive);
      font-family: "Montserrat-Regular";
    }

  }
  .maintitle {
    font-size: 40px !important;
  }
  img {
    width: clamp(5.19rem, calc(1.64rem + 8.72vw), 10.75rem);
    margin-block: 1.5em;
  }
  .product-box-left > div {
    float: left;
  }
  .product-box-left > div:nth-of-type(2) {
    width: 50%;
    padding-left: 2%;
    @media screen {
      @media (min-width: 1000px) {
        padding-left: 10%;
      }
    }
  }
  .product-box-left > div:nth-of-type(1) {
    width: 50%;
  }
  .product-box-left > div:nth-of-type(2) {
    padding-left: 50px;
  }
  .product-box-left > h6 {
    color: var(--ma-tufts-blue);
  }
  .product-box-left > p, h5 {
    font-family: "Montserrat-Regular";
    color: var(--ma-black-olive);
  }
  .product-box-left {
    padding: 0em;
    justify-content: center;
    text-align: left;
  }
  .product-box-right > div {
    float: left;
  }
  .product-box-right > div:nth-of-type(2) {
    width: 50%;
    margin-bottom: 30px;
  }
  .product-box-right > div:nth-of-type(1) {
    width: 50%;
    padding-left: 2%;
    @media screen {
      @media (min-width: 1000px) {
        padding-left: 10%;
      }
    }
  }
  .product-box-right > h6 {
    color: var(--ma-tufts-blue);
  }
  .product-box-right > p {
    font-family: "Montserrat-Regular";
    color: var(--ma-black-olive);
  }
  .product-box-right {
    padding: 0em;
    justify-content: center;
    text-align: left;
  }
  .button {
    margin-top: -5em;
  }

  @media screen and (min-width: 40rem) {
    section {
      text-align: center;
      overflow-y: scroll;
    }
  }
</style>