import request from "@/utils/request";

export const getPlaylistInfoById = (data) => {
  return request.get(`/api/playlist/info/${data}`).then((res) => {
    return res.data;
  });
};

export const addSubliminalToOwnPlaylists = (data) => {
  return request.post("/api/own/playlist/info/add", data).then((res) => {
    return res.data;
  });
};
export const deleteSubliminalToOwnPlaylists = (data) => {
  return request.post("/api/own/playlist/info/delete", data).then((res) => {
    return res.data;
  });
};

export const updateSubliminalToPlaylists = (data) => {
  return request.post("/api/playlist/info/update", data).then((res) => {
    return res.data;
  });
};

export const deleteSubliminalToPlaylists = (data) => {
  return request.post("/api/playlist/info/delete", data).then((res) => {
    return res.data;
  });
};
