<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Strategic thinking skills are vital in navigating the complexities of life and work. This subliminal is designed to help you develop and enhance your strategic thinking abilities, enabling you to become a proficient problem solver and decision maker."
    },
    {
      type: 'heading',
      text: 'Benefits of improving your strategic thinking skills:'
    },
    {
      type: 'list',
      items: [
        "Enhanced ability to analyze complex situations and information.",
        "Increased skill in identifying opportunities and potential solutions.",
        "Improved critical thinking abilities to assess risks and benefits.",
        "Better foresight to anticipate challenges and obstacles.",
        "Developing effective strategies for success in various aspects of life and work."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can train your mind to think strategically, allowing you to approach problems with a clearer, more analytical mindset. As you cultivate these skills, you'll gain confidence in your decision-making abilities and become more adept at finding successful outcomes. Remember, consistent practice and application are essential to mastering strategic thinking."
    }
  ]
},
            {
  id: 'before-listening',
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Improving your strategic thinking skills is essential for becoming a proficient problem solver and decision maker. This subliminal is designed to help you develop and enhance these skills, enabling you to analyze complex situations, identify opportunities, and make informed choices. Before you begin, here are some strategies to maximize your training and development:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your objectives: Identify the specific areas of strategic thinking that you want to develop and focus on.",
        "Create a learning environment: Find a quiet and comfortable space where you can fully concentrate and absorb the subliminal messages.",
        "Set realistic goals: Break down your development journey into small achievable milestones to track your progress.",
        "Stay open-minded: Embrace new perspectives and approaches to problem-solving, and be willing to explore different possibilities.",
        "Apply your learning: Practice strategic thinking skills in real-life situations, allowing yourself to adapt and grow."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly and applying the strategies in your daily life, you can cultivate a sharper and more effective strategic thinking mindset. Embrace the opportunity to expand your problem-solving abilities and make better decisions. Let's begin your journey towards becoming a strategic thinker!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Strategic Thinking Skills Training and Development subliminal! You've taken a significant step towards becoming a more proficient problem solver and decision maker in all areas of your life. Now it's time to reflect on your experience and continue your growth and development."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your progress: Take a moment to think about how you've developed your strategic thinking skills.",
        "Apply your skills: Look for opportunities to apply your strategic thinking abilities in different situations.",
        "Seek feedback: Ask for feedback from colleagues, friends, or mentors to gain different perspectives.",
        "Continue learning: Keep exploring resources and learning strategies to enhance your strategic thinking skills."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, developing strategic thinking skills is an ongoing process. Embrace the challenges and opportunities that come your way, and continue to grow and refine your abilities. With practice and dedication, you'll become an even more effective problem solver and decision maker. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Who can benefit from strategic thinking skills training and development?`, 
    answer: `Strategic thinking skills training and development can benefit individuals in various aspects of life and work. Whether you are a business professional, an entrepreneur, a student, or someone looking to enhance their problem-solving and decision-making abilities, this training can help you think more strategically, analyze situations effectively, and make informed choices.`,
    open: false
  },
  { 
    question: `What are the key components of strategic thinking skills?`, 
    answer: `Strategic thinking skills involve several key components, including the ability to analyze complex situations, think critically and creatively, identify opportunities, anticipate and mitigate potential challenges, make informed decisions, develop long-term plans, and adapt to changing circumstances. These skills are essential for individuals who want to excel in leadership positions or navigate complex problem-solving scenarios.`,
    open: false 
  },
  { 
    question: `How can strategic thinking skills training help improve my problem-solving abilities?`, 
    answer: `Strategic thinking skills training can help improve problem-solving abilities by teaching individuals how to approach problems from a strategic perspective. This involves analyzing the problem, understanding its underlying causes, considering various potential solutions, evaluating the pros and cons of each option, and selecting the most effective strategy to address the problem. By developing strategic thinking skills, individuals can become more proficient problem solvers and make well-informed decisions.`,
    open: false 
  },
  { 
    question: `Can strategic thinking skills be learned and developed?`, 
    answer: `Yes, strategic thinking skills can be learned and developed through training and practice. While some individuals may naturally possess certain aspects of strategic thinking, others can acquire and strengthen these skills through focused training programs. By engaging in strategic thinking exercises, learning from case studies, and applying strategic thinking principles in real-life situations, individuals can enhance their ability to think strategically and become more effective decision makers.`,
    open: false 
  },
  { 
    question: `How can strategic thinking skills benefit my career?`, 
    answer: `Strategic thinking skills can benefit your career by enhancing your problem-solving abilities, decision-making skills, and overall effectiveness as a professional. Employers value individuals who can think critically, analyze complex situations, and develop effective strategies. By demonstrating strong strategic thinking skills, you can stand out in your field, contribute to the success of your organization, and open up opportunities for career advancement.`,
    open: false 
  },
  { 
    question: `Are there any prerequisites or qualifications required for strategic thinking skills training and development?`, 
    answer: `No specific prerequisites or qualifications are required for strategic thinking skills training and development. This training is beneficial for individuals at various stages of their personal and professional development. Whether you are just starting your career or have years of experience, strategic thinking skills training can help you enhance your abilities and achieve success in your chosen field.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>