<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're looking to enhance your dancing talent and skills, this subliminal can help you achieve your goals. By targeting your subconscious mind, it can assist in developing your dancing abilities and becoming a better dancer overall."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support your dancing journey:'
    },
    {
      type: 'list',
      items: [
        "Improving your technique and mastering dance moves with precision and grace.",
        "Increasing your flexibility, strength, and stamina for more dynamic performances.",
        "Boosting your confidence and stage presence, allowing you to shine on the dance floor.",
        "Enhancing your musicality and rhythm, enabling you to better connect with the music.",
        "Helping you overcome any self-doubt or fear of failure, so you can fully express yourself through dance."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, it can help reprogram your mind for success in dancing. You may notice improvements in your skills, creativity, and overall enjoyment of dance. Remember, practice, dedication, and consistent use of this subliminal are important in achieving your dancing goals."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Are you ready to take your dancing talent and skills to the next level? This subliminal is designed to enhance your dancing abilities and help you become a better dancer. Before pressing play, consider the following tips to make the most of your listening experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your dance goals: Think about the specific areas you want to improve, whether it's technique, rhythm, style, or performance.",
        "Create a practice routine: Dedicate regular time to practice and hone your skills, giving attention to both technical exercises and expressive movements.",
        "Visualize your success: Imagine yourself dancing with confidence, precision, and grace. Visualize the crowd cheering and feeling proud of your performance.",
        "Keep an open mind: Be receptive to the subliminal affirmations and allow them to work with your subconscious mind to enhance your dancing abilities."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you may notice improvements in your dancing technique, creativity, and overall performance. Embrace the journey, persevere through challenges, and trust in your ability to become the dancer you aspire to be. Are you ready to step onto the dance floor and let your talent shine? Let's get started!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Dancing Talent and Skills Enhancement subliminal! By investing time in refining your dancing talent and skills, you've taken a significant step towards becoming the best dancer you can be. Now that you've finished, it's time to reflect on your experience and continue your journey towards dancing excellence."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice regularly: Consistent practice is key to improving your dancing talent and skills.",
        "Set achievable goals: Identify specific areas you want to improve and set realistic targets.",
        "Seek feedback: Ask for constructive feedback from teachers, instructors, or fellow dancers.",
        "Explore different styles: Expand your dance repertoire by trying out different dance styles or techniques." 
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, becoming a skilled dancer takes time, dedication, and perseverance. Embrace the challenges along the way and view them as opportunities for growth. Your commitment to enhancing your dancing talent and skills will undoubtedly pay off. Get out there, dance with passion, and let your talent shine!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Dancing Talent and Skills Enhancement subliminal help me become a better dancer?`, 
    answer: `The Dancing Talent and Skills Enhancement subliminal is designed to work on your subconscious mind, helping you to develop and enhance your natural dancing talent and skills. By regularly listening to the subliminal messages, you can improve your coordination, rhythm, technique, and overall confidence as a dancer. The subliminal can also help ignite your passion for dance and enhance your creativity.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Dancing Talent and Skills Enhancement subliminal?`, 
    answer: `Results can vary from person to person, and it may take some time for the subliminal messages to create positive changes in your dancing abilities. Consistent listening and practice are key factors in achieving noticeable results. We recommend listening to the subliminal daily and combining it with regular dance training and practice sessions for optimal improvement.`,
    open: false 
  },
  { 
    question: `Can the Dancing Talent and Skills Enhancement subliminal replace dance lessons or training?`, 
    answer: `No, the subliminal is not intended to replace dance lessons or training. It is designed to enhance your natural abilities and mindset as a dancer. Incorporating the subliminal messages alongside regular dance lessons and practice can help you maximize your potential and accelerate your progress as a dancer.`,
    open: false 
  },
  { 
    question: `How can I make the most out of the Dancing Talent and Skills Enhancement subliminal?`, 
    answer: `To make the most out of the subliminal, it is recommended to listen to it in a relaxed and focused state. Create a dedicated time and space for listening, where you can fully immerse yourself in the positive affirmations. Additionally, practice dance regularly, set goals, and challenge yourself to grow as a dancer. Combining the subliminal with consistent practice and a growth mindset can lead to significant improvements in your dancing skills.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Dancing Talent and Skills Enhancement subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your physical health or dance technique, it is always advisable to consult with a qualified dance instructor or healthcare professional.`,
    open: false 
  },
  { 
    question: `Can the subliminal help me overcome stage fright or performance anxiety as a dancer?`, 
    answer: `While the Dancing Talent and Skills Enhancement subliminal can help boost confidence and improve overall mindset, overcoming stage fright or performance anxiety requires a multifaceted approach. It can be beneficial to seek additional guidance from a qualified dance instructor, coach, or therapist who specializes in performance anxiety. Together with the subliminal, they can provide strategies and tools to help you manage and overcome stage fright.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>