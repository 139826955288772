import request from "@/utils/request";

export const getUsers = (data) => {
  return request.get("/api/user").then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const getUserByUserId = (data) => {
  return request.get(`/api/user/${data.user_id}`).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const getInfluencers = (data) => {
  return request.get("/api/influencer").then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const getUserFilters = (data) => {
  return request.post("/api/user/filter", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const addUsers = (data) => {
  return request.post("/api/user", data).then((res) => {
    return res.data;
  });
};

export const updateUsers = (data) => {
  return request.put(`/api/user/${data.user_id}`, data).then((res) => {
    return res.data;
  });
};

export const updateToggle = (data) => {
  return request.post("/api/user/toggle", data).then((res) => {
    return res.data;
  });
};

export const deleteUsers = (data) => {
  return request.delete(`/api/user/${data.user_id}`).then((res) => {
    return res.data;
  });
};

export const deleteUserEmail = (data) => {
  return request.post("/api/delete/email", data).then((res) => {
    return res.data;
  });
};

export const deleteUserEmailProvider = (data) => {
  return request.post("/api/delete/email2/provider", data).then((res) => {
    return res.data;
  });
};

export const userSignIn = (data) => {
  return request.post("/api/login", data).then((res) => {
    return res.data;
  });
};

export const userSignUp = (data) => {
  return request.post("/api/register", data).then((res) => {
    return res.data;
  });
};

export const userCheckEmail = (data) => {
  return request.post("/api/user/check/email", data).then((res) => {
    return res.data;
  });
};

export const userResetPassword = (data) => {
  return request.post("/api/user/reset/password", data).then((res) => {
    return res.data;
  });
};

export const userChangePassword = (data) => {
  return request.post("/api/user/update/password", data).then((res) => {
    return res.data;
  });
};
export const userUpdatePassword = (data) => {
  return request.post("/api/user/change/password", data).then((res) => {
    return res.data;
  });
};

export const userResetPasswordEmail = (data) => {
  return request.post("/api/user/reset/password/email", data).then((res) => {
    return res.data;
  });
};

export const getUserResetPassword = (data) => {
  return request.post("/api/user/reset/password", data).then((res) => {
    return res.data;
  });
};

export const checkAuthentication = (data) => {
  return request.post("/api/auth/check", data).then((res) => {
    return res.data;
  });
};

export const userLogOut = (data) => {
  return request.post("/api/logout", data).then((res) => {
    return res.data;
  });
};

export const updateUserPassword = (data) => {
  return request.post("/api/user/update/password", data).then((res) => {
    return res.data;
  });
};

export const getDashboard = (data) => {
  return request.post("/api/user/influencer", data).then((res) => {
    return res.data;
  });
};

export const checkMobileLogin = (data) => {
  return request.post("/api/check/mobile/payment/login", data).then((res) => {
    return res.data;
  });
};
// export const updateUserMoods = (data) => {
//   return request.post("/api/user/update/mood", data).then((res) => {
//     return res.data;
//   });
// };

// export const updateUserInfo = (data) => {
//   return request.post("/api/user/update/password", data).then((res) => {
//     return res.data;
//   });
// };
