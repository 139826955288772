<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Many people go through life feeling lost or unfulfilled, unsure of their purpose or what truly matters to them. If you're seeking clarity and direction, this subliminal can help you uncover your purpose and live a purpose-driven life."
    },
    {
      type: 'heading',
      text: 'How this subliminal can help:'
    },
    {
      type: 'list',
      items: [
        "It guides you to explore and understand your passions, values, and interests.",
        "It helps you gain insights into your strengths, talents, and unique abilities.",
        "It encourages self-reflection and introspection to uncover your true purpose.",
        "It supports you in aligning your actions and choices with your purpose.",
        "It empowers you to live a more fulfilling and meaningful existence."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can tap into your subconscious mind and access the deep wisdom within you. As you gain clarity about your purpose, you'll be able to make decisions and take actions that align with your passions and values, leading to a more purposeful and satisfying life. Remember, discovering your purpose is a journey, and this subliminal is here to guide you along the way."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a journey to discover your true purpose in life can be both exciting and challenging. This subliminal is designed to help you align with your passions and values, guiding you towards a fulfilling and meaningful existence. Before you start listening, consider these suggestions to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your interests: Take time to explore your passions, hobbies, and what brings you joy.",
        "Identify your values: Consider what is most important to you and what you stand for.",
        "Visualize your ideal life: Picture yourself living a purpose-driven existence, doing what you love and feeling fulfilled.",
        "Hold onto your aspirations: Maintain a positive and open mindset while listening, allowing the subliminal messages to resonate deeply within you."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can tap into your subconscious mind and unlock the clarity and motivation needed to live a purpose-driven life. Embrace this moment of self-discovery, trust the process, and allow your passions and values to guide you towards a fulfilling and meaningful existence. Your purpose is waiting to be discovered, so let's begin this transformative journey together."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Purpose-Driven Life subliminal! By exploring your true purpose in life, you've embarked on a journey of self-discovery and personal growth. Now that you've taken this important step, it's time to reflect on your experience and continue building a fulfilling and meaningful existence driven by your passions and values."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your values and passions: Take the time to deeply consider what truly matters to you and what brings you joy.",
        "Set meaningful goals: Create a roadmap for yourself that aligns with your purpose and helps you live a purpose-driven life.",
        "Take inspired action: Start taking steps towards your purpose by pursuing activities, hobbies, or careers that ignite your passion.",
        "Practice gratitude: Cultivate a mindset of gratitude to appreciate the journey and the opportunities that come your way."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, living a purpose-driven life is an ongoing process. Embrace the self-discovery and growth that comes with it, and be open to evolving and refining your purpose along the way. Stay true to yourself, follow your heart, and live a life that is authentically yours. Your purpose awaits!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Purpose-Driven Life subliminal help me discover my purpose?`, 
    answer: `The Purpose-Driven Life subliminal is designed to align your subconscious mind with your passions, values, and authentic self. By listening to the subliminal, you can enhance self-reflection, gain clarity about your desires and aspirations, and become more aware of opportunities that align with your purpose. It can support you in uncovering your true calling and living a more fulfilling and meaningful life.`,
    open: false
  },
  { 
    question: `How long does it take to discover my purpose using the Purpose-Driven Life subliminal?`, 
    answer: `Discovering your purpose is a personal journey that unfolds at its own pace. The time it takes to gain clarity on your purpose can vary from person to person. Consistently listening to the Purpose-Driven Life subliminal can amplify self-awareness and help you identify patterns, interests, and values that may lead you to your true calling. It is recommended to listen to the subliminal daily and be open to introspection and exploration.`,
    open: false 
  },
  { 
    question: `Can the Purpose-Driven Life subliminal help me align my career with my purpose?`, 
    answer: `Yes, the Purpose-Driven Life subliminal can support you in aligning your career path with your purpose. By influencing your subconscious mind, it can enhance your clarity and intuition, helping you make decisions that are in alignment with your values and passions. However, it is important to consider your unique circumstances and seek additional guidance or support if needed when making career-related choices.`,
    open: false 
  },
  { 
    question: `Will the Purpose-Driven Life subliminal provide all the answers about my purpose?`, 
    answer: `While the Purpose-Driven Life subliminal can help you gain clarity and insight into your purpose, it is not a magical solution that provides all the answers. Discovering your purpose is a personal and ongoing journey of self-discovery. The subliminal can serve as a tool to amplify your self-awareness and guide you towards meaningful paths, but it is important to explore, reflect, and seek support as needed to fully uncover your purpose.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Purpose-Driven Life subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Purpose-Driven Life subliminal. However, the subliminal is intended to be used as a tool for self-exploration and personal growth, and not a replacement for professional guidance or therapy. If you have concerns about your direction in life or mental well-being, it is advisable to seek support from a qualified professional.`,
    open: false 
  },
  { 
    question: `Can the Purpose-Driven Life subliminal change my purpose over time?`, 
    answer: `As you continue your journey of self-discovery and personal growth, your understanding of your purpose may evolve. The Purpose-Driven Life subliminal can help you gain clarity on your purpose in the present moment, but it is normal for your purpose to shift or expand as you gain new experiences and insights. Embrace the growth and be open to discovering new aspects of your purpose along the way.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>