import { render, staticRenderFns } from "./304-law-of-attraction-maximizer.vue?vue&type=template&id=b8fb3822&scoped=true&"
import script from "./304-law-of-attraction-maximizer.vue?vue&type=script&lang=js&"
export * from "./304-law-of-attraction-maximizer.vue?vue&type=script&lang=js&"
import style0 from "./304-law-of-attraction-maximizer.vue?vue&type=style&index=0&id=b8fb3822&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8fb3822",
  null
  
)

export default component.exports