<template>
  <main class="mx-auto">
    <section class="d-flex">
      <div>
        <img src="@/assets/images/main/login/1.png" alt="" />
      </div>
      <header>
        <h1>Forgot your Password?</h1>
        <p>No worries! We’ll help you reset it in a breeze</p>
        <div class="container">
          <div class="inputs">
            <p v-show="email!=''" >
              <span>Email</span>
              <span class="input-invalid">{{email_valid}}</span>
            </p>
            <v-text-field flat solo placeholder="E-mail Address"
              v-model="email" class="shrink"></v-text-field>
          </div>
        </div>
        <div style="width: max-content; margin: auto;" class="justify-center align-center mt-0">
          <p v-show="message!=''" medium class="message">{{ message }}</p>
        </div>
        <v-flex justify-center class="mt-5">
          <v-btn v-if="message == ''" @click="login()" medium class="sign-in-able">SUBMIT</v-btn>
          <v-btn v-else :disabled="isDisabled" medium class="sign-in-disable">SUBMIT</v-btn>
        </v-flex>
        </div>
        <h3 class="text-center">
          <span>Don’t have an account? </span>
          <span @click="goToRoute('/register')" class="sign-up">Sign up</span>
        </h3>
      </header>
    </section>
  </main>
</template>

<script>
import Card from '@/components/Card.vue'

import { url, loginQueryParams, getMessage } from '@/utils'
import { userSignIn } from '@/api/user'

export default {
  components: {
    Card,
  },
  data: () => {
    return {
      url,
      getMessage,
      userSignIn,
      loginQueryParams,

      email: '',
      message: '',
      email_valid: '',
      password_valid: '',

      dialog: false,

      rememberMe: false,
      isEmailExist: false,
      showPassword: false,
      
    }
  },
  watch: {
    email(value){
      if(value != ''){
        this.message=''
        this.validateEmail(value);
      }
    },
    password(value){
      if(value != ''){
        this.message=''
        this.validatePassword(value);
      }
    }
  },
  computed: {
    isUser() {
      return this.userType == 'user'
    },
    userType() {
      return this.loginQueryParams()
    },
  },
  methods: {
    validateEmail(email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        this.email_valid = ''
      } else {
        this.email_valid = "Email is not valid"
      }
    },
    validatePassword(password) {
      if (password.length < 8){
        this.password_valid = 'Min 8 characters'
      }else{
        if(/[0-9]/.test(password)){
          if (/[a-z]/.test(password)){
            if (/[A-Z]/.test(password)){
              this.password_valid = ""
            }else{
              this.password_valid = 'At least one uppercase letter'
            }
          }else{
            this.password_valid = 'At least one lowercase letter'
          }
        }else{
          this.password_valid = 'At least one number'
        }
      }
    },
    goToRoute(route) {
      if (this.currentRoute != route) {
        this.$router.push(route).catch(err => { })
      }
    },
    closeDialog() {
      this.dialog = false
    },
    login() {
      this.$store.dispatch("screen/getLoading", true)

      const params = {
        email: this.email,
        password: this.password,
        admin: !this.isUser
      }

      this.userSignIn(params).then((res) => {
        const { success, data } = res
        if (success) {
          let type = null
          let path = null

          const token = data.token
          const info = JSON.stringify(data.user)

          if (this.userType == 'user') {
            type = "user"
            path = "/user"
          }

          if (this.userType == 'admin') {
            type = "admin"
            path = '/admin'
          }

          this.$store.dispatch(`${type}/getInfo`, info)
          this.$store.dispatch(`${type}/getToken`, token)
          this.$store.dispatch(`${type}/getLocalInfo`, info)
          this.$store.dispatch(`${type}/getLocalToken`, token)

          this.$router.push(path)
        } else {
          this.email = ''
          this.password = ''
        }
        this.message=res.message
        this.$store.dispatch('screen/getLoading', false)
      })
    }
  }
}
</script>

<style scoped>
main {
  color: #404040;
  padding-block: 40px;
}

section {
  align-items: center;
  margin-left: 0px;
  margin-right: auto;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    text-align: center;

    & div {
      order: 1;
    }
  }
}

header {
  font-family: "Montserrat";
  margin-right: 150px;
  max-width: 65ch;
  @media screen {
      @media (max-width: 1000px) {
        margin-right: 0px;
      }
    }
  &>h1 {
    font-size: 43px;
    font-weight: 700;
    color: #4797de;

    @media screen {
      @media (max-width: 700px) {
        font-size: 40px;
      }

      @media (max-width: 400px) {
        font-size: 30px;
      }
    }
  }

  &>p {
    font-size: 20px;
    font-weight: 400;
    line-height: normal;


    @media screen {
      @media (max-width: 700px) {
        margin-inline: 20px;
        font-size: 16px;
      }

      @media (max-width: 400px) {
        font-size: 12px;
      }
    }
  }
  &>h3 {
    font-size: 15px;
    font-weight: 500;
    margin-top: 20px;
    .sign-up {
      text-decoration: underline;
      color: #427AB3;   
      cursor: pointer;
      font-weight: 700;
    }
  }
}
div.container {
  border: solid 0.5px #CECECE;
  border-radius: 10px;
  flex-direction: column;
  width: 45vw;
  height: 100px;
  @media screen {
      @media (max-width: 1000px) {
          width: 85vw;
      }
    }
}
div.inputs{
  font-family: "Montserrat";
  text-align: left;

  &>p {
    font-size: 12px;
    font-weight: 400;
    color: #696969;
    .input-invalid {
      color: red;
      margin-left: 15px;
      font-size: 11px;
      font-weight: 600;
    }
  }
}
.sign-in-able {
  background: #427AB3 !important; 
  font-family: 'Montserrat-Bold';
  color: white; 
}
.sign-in-able, .sign-in-disable, .message {
  border-radius: 5px; 
  width: 20vw;
  font-size: 1vw;
  @media screen {
      @media (max-width: 1000px) {
          width: 85vw;
          font-size: 12px;
      }
    }
}
.sign-in-disable {
  color: #427AB3 !important; 
  font-family: 'Montserrat-Bold';
  background: white; 
  cursor: not-allowed;
}
.message{
  background: #FFACAC !important; 
  color: black; 
  padding: 10px;
  font-family: 'Montserrat-Regular';
  text-align: center;
  margin-top: 20px;
  margin-bottom: -10px;
}

img {
  width: 100%;

  @media screen and (max-width: 1000px) {
    width: calc(100% - 100px);
  }
}
</style>