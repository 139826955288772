import { render, staticRenderFns } from "./86-stop-stuttering.vue?vue&type=template&id=d01a03f4&scoped=true&"
import script from "./86-stop-stuttering.vue?vue&type=script&lang=js&"
export * from "./86-stop-stuttering.vue?vue&type=script&lang=js&"
import style0 from "./86-stop-stuttering.vue?vue&type=style&index=0&id=d01a03f4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d01a03f4",
  null
  
)

export default component.exports