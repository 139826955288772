<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Achieving mastery in Kendo requires dedicated training and discipline. This subliminal is designed to support you in your journey to become skilled in the traditional Japanese martial art of swordsmanship."
    },
    {
      type: 'heading',
      text: 'Here are the key features of this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Enhancing your focus and concentration during practice and competitions.",
        "Boosting your physical strength, agility, and speed for better performance.",
        "Developing precise and powerful techniques in the art of swordsmanship.",
        "Improving your mental resilience, discipline, and perseverance in training.",
        "Cultivating a strong mindset and confidence in your abilities as a Kendo practitioner."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can tap into the power of your subconscious mind to reinforce positive qualities needed for Kendo mastery. Your training will be enhanced, and you will develop the skills necessary to excel in this martial art. Remember, progress in Kendo takes time and dedication, but with consistent practice and the support of this subliminal, you can achieve greatness."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on the journey of Kendo training and mastery requires discipline and dedication. This subliminal is designed to support you in honing your skills and becoming proficient in the traditional Japanese martial art of swordsmanship. Before you begin your training, consider the following preparation tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Clarify your goals and aspirations in pursuing Kendo, whether it’s attaining mastery in technique, improving focus and discipline, or gaining a deeper understanding of the art.",
        "Create a training schedule: Establish a consistent routine that allows for regular practice and progression.",
        "Prepare your mindset: Cultivate a mindset of dedication, perseverance, and respect for the art of Kendo.",
        "Focus on your intention: Maintain a clear focus on your intention while listening to the subliminal affirmations, allowing your subconscious mind to align with your goals."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal aims to harness the power of your subconscious mind, empowering you to achieve new heights in your Kendo training. As you embrace the physical and mental challenges ahead, remember that mastery is a journey that requires patience and persistence. With focused training and a passionate spirit, you can unlock your full potential in the art of Kendo. Let us embark on this remarkable path of growth together."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Kendo Training and Mastery subliminal! By embarking on this journey, you have taken the first step towards mastering the traditional Japanese martial art of swordsmanship. It's now time to reflect on your experience and continue your pursuit of Kendo excellence."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice regularly: Dedicate time to consistent Kendo training to improve your technique and build muscle memory.",
        "Study the philosophy of Kendo: Dive deeper into the principles and values underlying this martial art.",
        "Seek guidance from a master: Consider finding a qualified Kendo instructor who can provide personalized guidance and support.",
        "Attend workshops and competitions: Engage with the Kendo community and participate in events to challenge yourself and learn from others."
      ]
    },
    {
      type: 'paragraph',
      text: "Becoming a skilled Kendo practitioner requires discipline, perseverance, and a commitment to lifelong learning. Stay focused on your training, embrace the challenges, and celebrate your progress along the way. Remember, each step you take in your Kendo journey brings you closer to mastery. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Is Kendo suitable for beginners?`, 
    answer: `Yes, Kendo is suitable for beginners. It is a martial art that can be practiced by people of all ages and fitness levels. Beginners will start with the basic techniques and gradually progress as they gain experience and proficiency. It is important to find a qualified Kendo instructor or dojo to guide you in your training.`,
    open: false
  },
  { 
    question: `Do I need to have prior experience in martial arts to learn Kendo?`, 
    answer: `No, you do not need to have prior experience in martial arts to learn Kendo. Kendo can be learned and practiced by anyone with dedication and the willingness to learn. Previous martial arts experience may provide some advantages, but it is not a requirement.`,
    open: false 
  },
  { 
    question: `What equipment do I need for Kendo training?`, 
    answer: `For Kendo training, you will need a few essential pieces of equipment. This includes a bamboo sword called a shinai, a set of protective armor known as bogu, and a Kendo uniform called kendogi and hakama. It is important to invest in high-quality equipment for safety and optimal performance. Your instructor or dojo can guide you in choosing the appropriate gear.`,
    open: false 
  },
  { 
    question: `Is Kendo physically demanding?`, 
    answer: `Yes, Kendo can be physically demanding. It requires strength, agility, and endurance. Regular training and conditioning are necessary to improve your physical fitness and stamina for Kendo. However, the intensity of training can be adjusted based on individual capabilities and goals. It is always advisable to consult with your healthcare provider before starting any new physical activity.`,
    open: false 
  },
  { 
    question: `Can I compete in Kendo tournaments?`, 
    answer: `Yes, Kendo practitioners have the opportunity to compete in various tournaments at the local, national, and international levels. Competing in tournaments can help you test your skills, gain valuable experience, and meet other Kendo enthusiasts. Your instructor or Kendo federation can provide you with information on upcoming tournaments and how to participate.`,
    open: false 
  },
  { 
    question: `Is Kendo only about sword fighting?`, 
    answer: `While Kendo is primarily focused on swordsmanship, it is also a holistic martial art that emphasizes discipline, respect, and self-improvement. Kendo training encompasses not only physical techniques but also mental and spiritual development. It teaches important values such as sportsmanship, perseverance, and mindfulness.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>