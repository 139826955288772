<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Self-consideration is an important aspect of personal growth and well-being. This subliminal is designed to help you prioritize your own needs, set boundaries, and cultivate a healthier relationship with yourself."
    },
    {
      type: 'heading',
      text: 'By using this subliminal, you may experience the following benefits:'
    },
    {
      type: 'list',
      items: [
        "Increased self-awareness and understanding of your own needs and desires.",
        "Improved self-confidence and self-esteem, allowing you to advocate for yourself.",
        "Enhanced emotional well-being and better management of stress and anxiety.",
        "Greater sense of self-compassion and self-acceptance.",
        "Improved relationships with others as you establish healthier boundaries."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, practicing self-consideration is a lifelong journey, and this subliminal is just one tool to support you along the way. By listening regularly and integrating the affirmations into your daily life, you can gradually create a happier and more fulfilling life for yourself. Prioritizing your own well-being is not selfish; it's essential for your overall happiness and the quality of your relationships."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Prioritizing your own needs and well-being is essential for living a happier and more fulfilling life. This subliminal is designed to support you in practicing self-consideration. Before you begin listening, take a moment to reflect on these tips for getting the most out of this experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Before starting, remind yourself of the importance of self-consideration and how it can positively impact your life.",
        "Create dedicated time and space: Find a quiet and comfortable environment where you can listen to the subliminal without distractions.",
        "Relax your mind and body: Take a few deep breaths, let go of any tension, and allow yourself to fully embrace the experience.",
        "Embrace self-reflection: As you listen, pay attention to any thoughts or feelings that arise, and let them guide you towards greater self-awareness and understanding."
      ]
    },
    {
      type: 'paragraph',
      text: "By actively incorporating self-consideration into your daily life and regularly listening to this subliminal, you can cultivate a stronger sense of self-worth and nurture your own well-being. Embrace this opportunity to prioritize yourself and create a happier, more fulfilling life."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Self-Consideration subliminal! By prioritizing your own needs and well-being, you have taken an important step towards living a happier and more fulfilling life. It's time to reflect on your experience and continue nurturing your personal growth."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice self-reflection: Set aside time to reflect on your thoughts, emotions, and needs. Consider journaling as a helpful tool.",
        "Set boundaries: Learn to say 'no' when necessary and establish boundaries that protect your mental and emotional well-being.",
        "Engage in self-care activities: Prioritize activities that nourish your mind, body, and soul, such as meditation, exercise, or hobbies.",
        "Cultivate self-compassion: Be kind to yourself, celebrate your achievements, and offer self-compassion during challenging times."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, self-consideration is an ongoing practice that requires patience and self-awareness. Prioritizing your own needs and well-being is essential for a happier and more fulfilling life. Embrace this journey and continue sowing seeds of self-love and compassion. You are deserving of happiness and fulfillment!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What does it mean to practice self-consideration?`, 
    answer: `Practicing self-consideration means prioritizing your own needs, emotions, and well-being. It involves being mindful of your own boundaries and taking the time to care for yourself physically, emotionally, and mentally. This can involve setting healthy boundaries, practicing self-care activities, and cultivating self-compassion and self-acceptance.`,
    open: false
  },
  { 
    question: `Why is self-consideration important?`, 
    answer: `Self-consideration is important because it allows you to take care of yourself and your own well-being. It helps prevent burnout, enhances self-esteem and self-confidence, and contributes to a more balanced and fulfilling life. By prioritizing your own needs, you can better manage stress, build healthier relationships, and have more energy and presence in your daily life.`,
    open: false 
  },
  { 
    question: `How can I practice self-consideration in my daily life?`, 
    answer: `There are various ways to practice self-consideration in your daily life. This can include setting healthy boundaries, carving out time for self-care activities, expressing your needs and desires, seeking support when needed, and cultivating self-compassion and self-acceptance. It is also important to listen to your intuition and prioritize activities and relationships that bring you joy and fulfillment.`,
    open: false 
  },
  { 
    question: `Is self-consideration selfish?`, 
    answer: `No, self-consideration is not selfish. It is important to prioritize your own needs and well-being in order to have the capacity to care for others and live a fulfilling life. Practicing self-consideration is about finding a balance between caring for yourself and being considerate towards others.`,
    open: false 
  },
  { 
    question: `How can I overcome guilt or resistance when practicing self-consideration?`, 
    answer: `Overcoming guilt or resistance when practicing self-consideration can be challenging but important. It can be helpful to remind yourself of the benefits and importance of self-care, set realistic expectations for yourself, and challenge any negative beliefs or self-judgment. Seeking support from a therapist or trusted friend can also provide guidance and encouragement as you navigate these feelings.`,
    open: false 
  },
  { 
    question: `Can practicing self-consideration improve my relationships with others?`, 
    answer: `Yes, practicing self-consideration can improve your relationships with others. By prioritizing your own well-being, you can show up more authentically, set healthier boundaries, and communicate your needs more effectively. This allows for greater emotional connection, mutual respect, and healthier dynamics within relationships.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>