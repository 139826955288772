<template>
  <div
    id="appcrashing"
    :class="`content ${isfull} px-5`"
    :style="`height: ${contentheight}px`"
  >
    <p :class="`title ${isfull}`">
      The app is not working or crashing, how to troubleshoot?
    </p>
    <p>
      If the app is not working properly, crashing, or lagging, here are some
      recommendations for troubleshooting:
    </p>

    <ol class="my-5">
      <li>Double check your internet connection.</li>
      <li>Make sure you have updated your app to the latest version.</li>
      <li>Uninstall and reinstall the app.</li>
      <li>Restart your device.</li>
      <li>
        Disable any active VPNs. Unfortunately, sometimes VPNs do not have
        reliable network connectivity.
      </li>
      <li>Make sure to set the correct date on your device.</li>
    </ol>

    <p id="crashlimit">
      If you are still having trouble after trying these steps, you can reach
      out to our support team. You may email us at info@magusaudio.com.
    </p>
    <p>
      Please include a description of the issue you’re experiencing and any
      relevant screenshots, if applicable.
    </p>

    <div class="d-flex justify-space-between">
      <p class="link" @click="getAction('delete')">Delete Account</p>
      <p class="link" @click="getAction('sound')">Sound Troubleshoot</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    full: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data: () => {
    return {
      contentheight: 0,
    };
  },
  created() {
    window.addEventListener("resize", this.changeContentHeight);
  },
  mounted() {
    this.changeContentHeight();
  },
  computed: {
    isfull() {
      return this.full ? "full" : "";
    },
  },
  methods: {
    changeContentHeight() {
      if (!this.full) {
        const contentheight = document.getElementById("crashlimit").offsetTop;
        const appcrashing = document.getElementById("appcrashing").offsetTop;

        this.contentheight = contentheight - appcrashing;
      }
    },
    getAction(data) {
      let route = "";
      switch (data) {
        case "delete":
          route = "/support/delete/account";
          break;
        case "sound":
          route = "/support/sound/troubleshoot";
          break;
      }

      this.$router.push(route);
    },
  },
};
</script>

<style scoped>
.content {
  overflow: hidden;
}

.content.full {
  height: 100% !important;
  overflow: initial;
}

.title.full {
  text-align: center;
}

p {
  font-size: 12px;
  text-align: justify;
}

li {
  font-size: 12px;
  padding: 5px 0px;
  text-align: justify;
}

.link {
  font-size: 12px;
  cursor: pointer;
}

.link:hover {
  color: blue;
  text-decoration: underline;
}
</style>
