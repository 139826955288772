import request from "@/utils/request";

export const getTracks = (data) => {
  return request.get("/api/track").then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const getTrackFilters = (data) => {
  return request.post("/api/filter/track", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const addTracks = (data) => {
  return request.post("/api/track", data).then((res) => {
    return res.data;
  });
};

export const updateTracks = (data) => {
  return request.put(`/api/track/${data.track_id}`, data).then((res) => {
    return res.data;
  });
};

export const updateToggle = (data) => {
  return request.post("/api/toggle/track", data).then((res) => {
    return res.data;
  });
};

export const deleteTracks = (data) => {
  return request.delete(`/api/track/${data.track_id}`).then((res) => {
    return res.data;
  });
};

export const getTrackById = (data) => {
  return request.get(`/api/track/${data}`).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const checkTrack = (data) => {
  return request.post("/api/check/track", data).then((res) => {
    return res.data;
  });
};

export const checkTrackVersion = (data) => {
  return request.post("/api/check/track/version", data).then((res) => {
    return res.data;
  });
};
