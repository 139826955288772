<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Becoming an effective and successful leader requires the development of specific qualities and skills. This subliminal is designed to help you acquire the leadership qualities needed to excel in various aspects of life."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Developing strong communication and interpersonal skills to inspire and influence others.",
        "Enhancing your decision-making and problem-solving abilities to navigate challenges effectively.",
        "Fostering self-confidence and assertiveness to take charge and lead with conviction.",
        "Improving your ability to motivate and empower those around you.",
        "Building resilience and adaptability to handle dynamic and demanding situations.",
        "Cultivating effective teamwork and collaboration skills for successful group endeavors."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to embody the qualities and skills of a successful leader. As you internalize these traits, you will find yourself growing into a more confident and influential leader in various areas of your life. Remember, leadership development is a continuous process that requires dedication and practice."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Building leadership qualities and skills is an essential step towards achieving success in different areas of life. This subliminal is designed to help you acquire the traits and mindset needed to become an effective leader. Before starting the session, consider the following strategies to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your goals: Identify the specific leadership qualities and skills you want to develop and manifest in your life.",
        "Visualize your success: Imagine yourself demonstrating these leadership qualities and succeeding in your chosen endeavors.",
        "Set affirmations: Create powerful affirmations that align with your desired leadership attributes and repeat them regularly.",
        "Stay focused: During the session, maintain a clear focus on your goals and intentions, allowing the subliminal messages to support your path to becoming a successful leader."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal and incorporating these strategies, you can begin to cultivate the mindset and qualities necessary for effective leadership. Embrace this opportunity to grow and develop as a leader in all aspects of your life, and let your journey towards becoming a successful leader begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Acquire Leadership Qualities subliminal! By focusing on developing your leadership qualities, you've taken a significant step toward becoming a more effective and successful leader in various aspects of your life. It's now time to reflect on your experience and continue your personal growth journey."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your experience: Take a moment to journal your thoughts and experiences throughout the subliminal session.",
        "Apply the skills: Look for opportunities to apply the leadership qualities you've acquired in your daily life and various situations.",
        "Seek professional development: Consider further developing your leadership skills through workshops, courses, or mentoring programs.",
        "Practice self-reflection: Regularly assess your growth as a leader and identify areas for improvement or further development."
      ]
    },
    {
      type: 'paragraph',
      text: "Becoming a successful leader takes continual effort and practice. Embrace the qualities you've developed, apply them in your life, and never stop learning. You are on the path to becoming an influential and inspiring leader. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Acquire Leadership Qualities subliminal help me become a better leader?`, 
    answer: `The Acquire Leadership Qualities subliminal is designed to tap into your subconscious mind and instill positive beliefs, attitudes, and behaviors associated with effective leadership. By consistently listening to the subliminal, you can develop qualities such as confidence, decision-making skills, communication abilities, and the ability to inspire and motivate others.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Acquire Leadership Qualities subliminal?`, 
    answer: `Results may vary depending on individual factors and the time and effort put into personal development. Some people may notice changes in their leadership abilities relatively quickly, while others may require more time to internalize and implement the subliminal messages. Consistent listening over an extended period is recommended for best results.`,
    open: false 
  },
  { 
    question: `Can the Acquire Leadership Qualities subliminal be used for specific leadership roles or situations?`, 
    answer: `Yes, the subliminal can be used to develop leadership qualities for various roles and situations, including professional, personal, and community leadership. It can help individuals develop the skills and mindset necessary to lead teams, manage projects, make strategic decisions, and inspire others towards a common goal.`,
    open: false 
  },
  { 
    question: `In addition to listening to the subliminal, what other steps can I take to enhance my leadership abilities?`, 
    answer: `Listening to the subliminal is just one part of the equation. To enhance your leadership abilities, consider seeking opportunities for growth and learning, such as attending workshops, seminars, or leadership development programs. Engaging in self-reflection, seeking feedback from trusted individuals, and practicing empathy and active listening can also contribute to your growth as a leader.`,
    open: false 
  },
  { 
    question: `Is the Acquire Leadership Qualities subliminal suitable for everyone?`, 
    answer: `The subliminal is designed to be beneficial for individuals seeking to develop or enhance their leadership abilities. However, it may not be suitable for individuals who do not have a genuine interest in leadership or are not open to personal growth and development. It is important to have a willingness to learn and apply the ideas presented in the subliminal for it to be effective.`,
    open: false 
  },
  { 
    question: `Can the Acquire Leadership Qualities subliminal guarantee success in leadership roles?`, 
    answer: `While the subliminal can help develop qualities and skills associated with effective leadership, success in leadership roles depends on a combination of factors, including individual effort, experience, and the specific context of leadership. The subliminal can be a valuable tool in your personal development journey, but it does not guarantee success on its own.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>