export const track = {
  namespaced: true,
  state: {
    id: "",
    title: "",

    data: [],
  },
  getters: {},
  actions: {
    getId({ commit }, data) {
      commit("getId", data);
    },
    getTitle({ commit }, data) {
      commit("getTitle", data);
    },
    getData({ commit }, data) {
      commit("getData", data);
    },
  },
  mutations: {
    getId(state, data) {
      state.id = data;
    },
    getTitle(state, data) {
      state.title = data;
    },
    getData(state, data) {
      state.data = data;
    },
  },
};
