<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Having a positive sense of humor can greatly enhance your daily life by bringing laughter, joy, and a lighthearted perspective. This subliminal is designed to help you develop and cultivate a positive sense of humor."
    },
    {
      type: 'heading',
      text: 'Here are some benefits of developing a positive sense of humor:'
    },
    {
      type: 'list',
      items: [
        "Increased ability to find humor in everyday situations and enjoy life's lighter moments.",
        "Enhanced social connections and relationships through shared laughter and positive interactions.",
        "Improved resilience and ability to cope with stress and difficult situations.",
        "Boosted creativity and ability to think outside the box.",
        "A more positive outlook on life, leading to greater overall wellbeing and happiness."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, it works on your subconscious mind to shift your perspective and develop a positive sense of humor. You may start to notice yourself finding the funny side of things more easily and effortlessly, bringing more laughter and joy into your daily life. Remember, developing a positive sense of humor is a process, and with consistent practice, it can become a natural part of who you are."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Cultivating a positive sense of humor can bring joy and lightness to your everyday life. This subliminal is designed to help you develop a more positive and lighthearted approach to humor. Before you start listening, consider these suggestions to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Open your mind: Be open to new perspectives and different forms of humor, allowing yourself to explore and appreciate various types of jokes and comedy.",
        "Surround yourself with laughter: Seek out funny movies, comedy shows, or humorous books to immerse yourself in a positive and light-hearted environment.",
        "Practice gratitude: Cultivating gratitude can help you find humor in everyday situations and appreciate the lighter side of life.",
        "Embrace positivity: Maintain a positive attitude and outlook as you listen to subliminal affirmations, allowing them to align with your subconscious mind."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your daily routine and adopting a positive mindset, you can develop a stronger sense of humor and experience the joy and laughter it brings. So relax, open your mind to the possibilities of humor, and let the journey to a more lighthearted and joyous life begin!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Develop Positive Humor subliminal session! You've taken a step towards cultivating a positive sense of humor and bringing more laughter and joy into your daily life. Now, it's time to reflect on your experience and continue nurturing your newfound sense of humor."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Find humor in everyday life: Look for opportunities to see the lighter side of situations.",
        "Surround yourself with positive people: Spend time with friends who uplift your spirits and share your sense of humor.",
        "Practice gratitude: Cultivate an attitude of gratitude as it can enhance your sense of humor.",
        "Engage in activities that make you laugh: Watch comedies, read funny books, or listen to humorous podcasts."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, developing a positive sense of humor is a lifelong journey. Embrace the power of laughter and continue to find joy in the simple moments. Laugh often, spread positivity, and let your humor brighten the world around you. Keep smiling!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Develop Positive Humor subliminal help me?`, 
    answer: `The Develop Positive Humor subliminal is designed to help you develop a positive sense of humor and bring more laughter and joy into your daily life. By listening to the subliminal messages, you can reprogram your subconscious mind to find humor in everyday situations and see the lighter side of life. This can improve your mood, reduce stress, and enhance your overall well-being.`,
    open: false
  },
  { 
    question: `How long does it take to develop a positive sense of humor with the subliminal?`, 
    answer: `Results may vary from person to person and depend on several factors, such as the individual's mindset and consistency in listening to the subliminal. Some people may notice positive changes in their sense of humor relatively quickly, while others may require longer periods of consistent listening. We recommend listening to the Develop Positive Humor subliminal daily and consistently for best results.`,
    open: false 
  },
  { 
    question: `Can the Develop Positive Humor subliminal make me laugh all the time?`, 
    answer: `The Develop Positive Humor subliminal is not intended to make you constantly laugh. Rather, it helps you develop a positive sense of humor and see the lighter side of life. You may find yourself more inclined to find humor and joy in everyday situations, leading to more frequent laughter and a generally lighter mood.`,
    open: false 
  },
  { 
    question: `Does the Develop Positive Humor subliminal work for everyone?`, 
    answer: `The effectiveness of the Develop Positive Humor subliminal may vary from person to person. Individuals with a more open mindset and willingness to embrace humor and laughter may be more likely to experience positive results. However, everyone can benefit from cultivating a positive sense of humor and finding joy in their daily lives.`,
    open: false 
  },
  { 
    question: `Is it possible to develop a positive sense of humor without the subliminal?`, 
    answer: `While it is possible to develop a positive sense of humor without using subliminals, the Develop Positive Humor subliminal serves as a tool to expedite the process and reprogram your subconscious mind more effectively. The subliminal messages are specifically designed to influence your thoughts and beliefs, making it easier to develop and maintain a positive sense of humor.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the Develop Positive Humor subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. It is safe and non-invasive. However, if you have any concerns about your mental health or emotional well-being, it is recommended to consult with a healthcare professional.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>