<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you struggle with a fear of heights or acrophobia, you're not alone. Many people experience intense anxiety and panic in elevated situations. This subliminal is designed to help you overcome your fear and develop the confidence to face heights with ease."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help you:"
    },
    {
      type: 'list',
      items: [
        "By targeting your subconscious mind, it aims to reprogram deeply ingrained fears and anxieties related to heights.",
        "It guides you through therapeutic exercises and visualizations to help desensitize your mind to heights.",
        "It promotes a sense of calm and relaxation in elevated situations, making it easier for you to face your fears.",
        "It instills a belief in your own abilities and empowers you to confidently navigate height-related challenges.",
        "It encourages positive self-talk and mindset shifts to help you overcome negative thought patterns associated with heights."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming a fear of heights takes time and patience. Consistently listening to this subliminal can gradually rewire your subconscious mind and help you build a healthier perception of elevated situations. With practice and persistence, you can regain control and confidently face heights without fear or anxiety."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Conquering your fear of heights and acrophobia is an important step towards living a life free from limitations. This subliminal therapy is designed to guide you through the process of overcoming your fears and reprogramming your mind for confidence in elevated situations. Before you begin, consider these preparatory suggestions:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your fear: Recognize and accept your fear of heights as a valid emotion.",
        "Set your intention: Clearly define your goal of overcoming your fear and regaining control in elevated situations.",
        "Practice relaxation techniques: Learn and utilize techniques such as deep breathing or mindfulness meditation to help calm your mind and body.",
        "Visualize success: Create a mental image of yourself confidently facing and overcoming your fear of heights.",
        "Stay committed: Maintain a dedication to listening to the subliminal therapy consistently and incorporating its affirmations into your daily routine."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal therapy aims to reprogram your subconscious mind, empowering you to fearlessly navigate elevated situations. As you progress on this journey, you may find your confidence growing and your fear subsiding. Embrace the transformative power of your mind and trust in the process. Let's start your path to conquering your fear of heights and acrophobia!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Fear of Heights and Acrophobia Removal session! You've taken a courageous step towards conquering your fear. Now that you've listened to the session, it's important to reflect on your experience and continue your journey towards confidently facing elevated situations."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your progress: Take a moment to appreciate your bravery in confronting your fear.",
        "Expose yourself to heights gradually: Start with low-risk situations and gradually increase exposure to elevated environments.",
        "Practice relaxation techniques: Utilize deep breathing exercises or mindfulness to calm any anxiety or stress that may arise.",
        "Seek professional help if needed: Consider consulting with a therapist experienced in treating phobias for additional support and guidance."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming fear takes time and effort, but you have already shown incredible strength. Celebrate each step you take towards conquering your fear and believe in your ability to confidently face elevated situations. Keep pushing forward and embrace your newfound freedom!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Fear of Heights and Acrophobia Removal program help me?`, 
    answer: `The Fear of Heights and Acrophobia Removal program is designed to help individuals overcome their fear of heights and acrophobia through guided therapy and mental reprogramming. It can help you understand the underlying causes and triggers of your fear, challenge negative thought patterns, and gradually desensitize you to elevated situations. By building confidence and providing tools to manage anxiety, the program aims to empower you to confidently face heights without fear or distress.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Fear of Heights and Acrophobia Removal program?`, 
    answer: `The time it takes to see results from the Fear of Heights and Acrophobia Removal program can vary depending on the individual and the severity of their fear. Some individuals may experience noticeable improvements after just a few sessions, while others may require more time and consistent practice. It is important to be patient and committed to the program for optimal results.`,
    open: false 
  },
  { 
    question: `Can I use the Fear of Heights and Acrophobia Removal program on my own?`, 
    answer: `The Fear of Heights and Acrophobia Removal program is designed to be used under the guidance of a qualified therapist or mental health professional. They can provide you with the necessary support, guidance, and personalized strategies to help you overcome your fear of heights and acrophobia in a safe and effective manner.`,
    open: false 
  },
  { 
    question: `Are there any risks involved in participating in the Fear of Heights and Acrophobia Removal program?`, 
    answer: `The Fear of Heights and Acrophobia Removal program is generally safe when conducted under the guidance of a qualified therapist or mental health professional. However, it is important to discuss any specific concerns or risks with your provider before starting the program. They can assess your individual needs and ensure that the program is appropriate for you.`,
    open: false 
  },
  { 
    question: `Can the Fear of Heights and Acrophobia Removal program guarantee a complete elimination of fear?`, 
    answer: `While the Fear of Heights and Acrophobia Removal program can help individuals overcome their fear of heights and acrophobia, it is important to note that results may vary from person to person. The program provides effective tools and techniques to manage and reduce fear, but complete elimination of fear cannot be guaranteed. The goal is to help you build resilience, confidence, and strategies to face elevated situations with reduced anxiety and distress.`,
    open: false 
  },
  { 
    question: `Is the Fear of Heights and Acrophobia Removal program suitable for everyone?`, 
    answer: `The Fear of Heights and Acrophobia Removal program may benefit individuals who experience fear of heights and acrophobia. However, it is important to consult with a qualified therapist or mental health professional to determine if the program is suitable for your specific needs and circumstances. They can assess your individual situation and create a personalized plan to address your fear.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>