export const favorite = {
  namespaced: true,
  state: {
    id: [],
    favoriteIDs: [],
    favoriteInfoLists: [],
  },
  getters: {
    getFavoriteIDs(state) {
      return state.favoriteIDs;
    },
    getFavoriteInfoLists(state) {
      return state.favoriteInfoLists;
    },
  },
  actions: {
    getFavorite({ commit }, data) {
      commit("getFavorite", data);
    },
    favoriteIDs({ commit }, data) {
      commit("setFavoriteIDs", data);
    },
    favoriteInfoLists({ commit }, data) {
      commit("setFavoriteInfoLists", data);
    },
  },
  mutations: {
    getFavorite(state, data) {
      state.id = data;
    },
    setFavoriteIDs(state, data) {
      state.favoriteIDs = data;
    },
    setFavoriteInfoLists(state, data) {
      state.favoriteInfoLists = data;
    },
  },
};
