<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Breaking free from pornography addiction is a challenging journey, but you don't have to face it alone. This subliminal is designed to help you regain control over your life and overcome destructive habits so you can live a healthier, more fulfilling life."
    },
    {
      type: 'heading',
      text: 'Here are some key features of this subliminal:'
    },
    {
      type: 'list',
      items: [
        "It helps rewire your brain and diminish the craving and dependency on pornography.",
        "It assists in strengthening your willpower and self-discipline to resist urges and temptations.",
        "It encourages healthier coping mechanisms and replaces unhealthy habits with positive ones.",
        "It supports mindset shifts towards a healthier sexuality and a more balanced perspective on intimacy.",
        "It provides motivation and inspiration to pursue fulfilling activities and relationships outside of pornography."
      ]
    },
    {
      type: 'paragraph',
      text: "Using this subliminal regularly can aid in breaking the cycle of pornography addiction and establishing a healthier lifestyle. It takes time and effort, but with commitment and dedication, you can regain control over your life. Remember, you are capable of creating positive change and living a fulfilling life free from the grip of addiction."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Recovering from pornography addiction takes commitment and dedication, but this subliminal is designed to support you on your journey. Before you start listening, here are some suggestions to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your addiction: Recognize the impact pornography has had on your life and affirm your decision to break free from it.",
        "Create a support system: Reach out to trusted friends or family members who can offer understanding, encouragement, and accountability.",
        "Develop healthier coping mechanisms: Find alternative activities, hobbies, or self-care practices that can replace the urge to turn to pornography.",
        "Set goals: Establish realistic goals for yourself, both short-term and long-term, to track your progress and celebrate milestones.",
        "Stay committed: Remind yourself of your desire for a healthier, more fulfilling life whenever you listen to the subliminal affirmations."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal aims to help you break free from pornography addiction and regain control over your life. As you listen regularly, you may notice positive changes in your mindset, behavior, and overall well-being. Embrace this opportunity for personal growth and transformation, and let's begin this journey towards a healthier, more fulfilling life."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Pornography Addiction Removal session! By taking this important step towards overcoming addiction and reclaiming control over your life, you are on the path to a healthier, more fulfilling future. It's essential to reflect on your journey so far and continue working towards your goals."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a support system: Reach out to trusted friends or family members who can provide emotional support and hold you accountable.",
        "Set boundaries: Limit your exposure to triggers and create a safe environment that discourages engaging in addictive behaviors.",
        "Develop healthy coping mechanisms: Find alternative activities or hobbies that bring you joy and fulfillment.",
        "Practice self-care: Prioritize taking care of your mental, emotional, and physical well-being.",
        "Seek professional help: Consider consulting with a therapist or addiction counselor who specializes in pornography addiction."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, breaking free from addiction is a challenging journey, but with persistence and dedication, you can regain control over your life. Embrace the support available to you and be kind to yourself throughout this process. You are capable of living a healthier, more fulfilling life, free from destructive habits. Keep moving forward!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Pornography Addiction Removal subliminal help me overcome my addiction?`, 
    answer: `The Pornography Addiction Removal subliminal is designed to help you break free from destructive habits and regain control over your life. By listening to the subliminal messages, you can reprogram your subconscious mind to resist the urge to engage in pornography and develop healthier coping mechanisms. Regular listening, along with seeking support from professionals or joining support groups, can increase the effectiveness of the subliminal in helping you overcome addiction.`,
    open: false
  },
  { 
    question: `How long will it take to see results from the Pornography Addiction Removal subliminal?`, 
    answer: `Results may vary from person to person, and the time it takes to see significant results can depend on various factors, such as the severity of the addiction and individual circumstances. It is recommended to listen to the subliminal consistently and allow enough time for the positive affirmations to rewire your subconscious mind. Pairing the subliminal with therapy or counseling can also help speed up the recovery process.`,
    open: false 
  },
  { 
    question: `Can the Pornography Addiction Removal subliminal be used as the sole treatment for addiction?`, 
    answer: `The Pornography Addiction Removal subliminal is designed to complement therapeutic approaches and support systems in overcoming addiction. While it can be a helpful tool in your recovery journey, it is important to seek professional help, such as therapy or counseling, to address the underlying causes of addiction and develop a comprehensive treatment plan tailored to your needs.`,
    open: false 
  },
  { 
    question: `Are there any potential risks or side effects of listening to the Pornography Addiction Removal subliminal?`, 
    answer: `There are no known risks or side effects associated with listening to the Pornography Addiction Removal subliminal. However, it is important to remember that addiction recovery is a complex process, and using the subliminal as a standalone solution may not be sufficient. It is crucial to seek support from professionals and participate in a comprehensive addiction recovery program.`,
    open: false 
  },
  { 
    question: `Can the Pornography Addiction Removal subliminal eliminate all cravings and temptations related to addiction?`, 
    answer: `The Pornography Addiction Removal subliminal can help rewire your subconscious mind to resist cravings and develop healthier coping mechanisms. However, it is important to note that everyone's journey is unique, and complete elimination of cravings and temptations may require additional support, such as therapy or counseling, along with lifestyle changes and a strong support system.`,
    open: false 
  },
  { 
    question: `Will the Pornography Addiction Removal subliminal cause me to forget my past actions or experiences related to addiction?`, 
    answer: `No, the Pornography Addiction Removal subliminal does not aim to erase your past actions or experiences related to addiction. It is designed to help you break free from destructive habits and develop healthier coping mechanisms. Acknowledging and learning from your past experiences can be an important part of your recovery journey.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>