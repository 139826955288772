<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're looking to take your rugby skills to the next level, this subliminal can be a valuable tool in your training arsenal. Designed to target your subconscious mind, it can help you enhance your abilities and master the sport through dedicated training and practice."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit your rugby skills:'
    },
    {
      type: 'list',
      items: [
        "Improving your agility, speed, and overall physical performance on the field.",
        "Enhancing your focus, concentration, and mental stamina during games and training sessions.",
        "Boosting your confidence and belief in your abilities as a rugby player.",
        "Supporting your motivation and drive to consistently train and improve your skills.",
        "Helping you develop strategic thinking and decision-making abilities on the field.",
        "Promoting teamwork, communication, and cooperation with your teammates."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can unlock your full potential as a rugby player and achieve mastery in the sport. It is important to combine the use of this subliminal with dedicated training, practice, and a disciplined mindset. Remember, becoming a master at rugby requires consistent effort and dedication, but with the right mindset and tools, you can achieve your goals." 
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Becoming a master of rugby requires dedication, discipline, and consistent training. This subliminal is designed to enhance your rugby skills and help you achieve mastery in the sport. Before you start your training session, consider these tips to optimize your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Identify specific areas of your rugby game that you want to improve, such as passing, tackling, or agility.",
        "Create a training plan: Develop a structured training routine that includes drills, exercises, and skill-specific practice.",
        "Visualize success: Imagine yourself performing at your best during a game, executing flawless skills and making winning plays.",
        "Stay focused: While listening to the subliminal, maintain your concentration on your training goals and visualize yourself improving in your desired areas."
      ]
    },
    {
      type: 'paragraph',
      text: "As you make this subliminal a regular part of your training routine, you may notice improvements in your rugby skills, mental focus, and overall performance on the field. Embrace the opportunity to refine your abilities, stay committed to your training plan, and let your dedication lead you to mastery in the sport of rugby."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Rugby Skills and Training Mastery session! By investing your time and energy into improving your rugby skills, you're on your way to becoming a master of the sport. Reflection and continued effort are vital as you progress towards your rugby goals."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Analyze your performance: Review your training sessions and matches to identify areas for improvement.",
        "Set specific goals: Establish targets for your skill development and track your progress.",
        "Practice regularly: Consistency is key to mastering any skill. Dedicate time to train and hone your rugby abilities.",
        "Seek guidance: Consider working with a coach or joining a team to receive valuable feedback and guidance."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, becoming a master at rugby requires dedication and perseverance. Embrace the challenges and enjoy the journey of continual growth. With consistent practice and a positive mindset, you'll take your rugby skills to new heights. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Rugby Skills and Training Mastery program help improve my rugby skills?`, 
    answer: `The Rugby Skills and Training Mastery program is designed to enhance your rugby skills through a combination of comprehensive training techniques and mental conditioning. It includes drills, exercises, and strategies that focus on various aspects of the game, such as passing, tackling, kicking, and decision-making. The program also incorporates visualization and positive affirmations to help develop a winning mindset and improve overall performance on the field.`,
    open: false
  },
  { 
    question: `How long does it take to see improvements in my rugby skills with the Rugby Skills and Training Mastery program?`, 
    answer: `Results may vary depending on individual dedication and practice. With consistent training and implementation of the techniques and strategies taught in the program, you can start to see improvements in your rugby skills in a matter of weeks. However, it is important to note that progress is a continuous journey, and continued practice and commitment are key to long-term skill development.`,
    open: false 
  },
  { 
    question: `Can beginners benefit from the Rugby Skills and Training Mastery program?`, 
    answer: `Yes, the Rugby Skills and Training Mastery program is designed to cater to all skill levels, including beginners. The program provides a step-by-step approach to skill development, starting with the basics and progressing towards more advanced techniques. Whether you are new to the sport or looking to refine your skills, the program offers valuable guidance and training resources to help you improve.`,
    open: false 
  },
  { 
    question: `Do I need any special equipment or access to a rugby field to benefit from the program?`, 
    answer: `While having access to a rugby field and basic equipment can enhance your training experience, it is not a requirement to benefit from the program. Many of the drills and exercises can be adapted to smaller spaces or modified using alternative equipment. The program focuses on skill development and mindset training, which can be practiced in various settings, including at home or in a gym.`,
    open: false 
  },
  { 
    question: `Is it necessary to have prior rugby experience to participate in the Rugby Skills and Training Mastery program?`, 
    answer: `No, prior rugby experience is not necessary to participate in the Rugby Skills and Training Mastery program. The program is designed to cater to individuals of all skill levels, including beginners. It provides comprehensive guidance and training resources to help you develop your rugby skills and knowledge of the game, regardless of prior experience.`,
    open: false 
  },
  { 
    question: `Will the Rugby Skills and Training Mastery program make me a professional rugby player?`, 
    answer: `The Rugby Skills and Training Mastery program is designed to enhance your rugby skills and knowledge of the game through dedicated practice and training. While it can significantly improve your performance on the field, becoming a professional rugby player requires a combination of talent, dedication, and opportunities. The program provides valuable resources to help you progress in your rugby journey, but individual commitment and engagement are essential for achieving professional-level success.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>