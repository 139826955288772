<template>
  <div style="max-width: 1350px; margin-block: 20px">
    <Track />
  </div>
</template>

<script>
import Track from "@/views/User/Track.vue";

export default {
  components: {
    Track,
  },
  created() {
    if (localStorage.getItem("user_track") != null) {
      this.$store.dispatch(
        "user/getTrack",
        JSON.parse(localStorage.getItem("user_track"))
      );
      localStorage.removeItem("user_track");
    }
  },
  mounted() {
    window.addEventListener("beforeunload", () => {
      if (Object.keys(this.userTrack).length > 0) {
        this.$store.dispatch(
          "user/getLocalTrack",
          JSON.stringify(this.userTrack)
        );
      }
    });
  },
  computed: {
    userTrack() {
      return this.$store.state.user.track;
    },
  },
};
</script>
