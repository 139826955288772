import { render, staticRenderFns } from "./51-stretch-mark-healing-and-removal.vue?vue&type=template&id=67b0f66b&scoped=true&"
import script from "./51-stretch-mark-healing-and-removal.vue?vue&type=script&lang=js&"
export * from "./51-stretch-mark-healing-and-removal.vue?vue&type=script&lang=js&"
import style0 from "./51-stretch-mark-healing-and-removal.vue?vue&type=style&index=0&id=67b0f66b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67b0f66b",
  null
  
)

export default component.exports