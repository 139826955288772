<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're ready to change your financial situation and achieve greater wealth and abundance, this subliminal can help you make that shift. By targeting your subconscious mind, it aims to cultivate the mindset and habits of a wealthy person."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support you:'
    },
    {
      type: 'list',
      items: [
        "Aligning your thoughts and beliefs with those of successful, wealthy individuals.",
        "Helping you develop a positive money mindset and overcome any limiting beliefs around wealth.",
        "Motivating you to take action towards your financial goals and make smart financial decisions.",
        "Increasing your confidence in your ability to create and attract wealth.",
        "Encouraging you to develop consistent habits that contribute to financial success."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can start rewiring your subconscious mind to think and act like a wealthy person. As a result, you may find yourself making better financial choices, attracting opportunities for wealth, and experiencing a greater sense of abundance in all aspects of your life. Remember, real wealth goes beyond just monetary value and includes a fulfilling and purposeful life."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Developing the mindset and habits of a wealthy person is a powerful way to attract financial success and abundance into your life. This subliminal is designed to help you align your subconscious mind with the thoughts and behaviors of wealthy individuals. Before you start listening, consider these tips for maximizing the impact of the subliminal program:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your financial goals: Define what wealth means to you and set clear, specific goals that align with your vision.",
        "Immerse yourself in wealth: Surround yourself with resources that motivate and inspire you, such as books, podcasts, and success stories.",
        "Visualize your desired outcomes: Create vivid mental images of the financial success and abundance you wish to manifest.",
        "Maintain a positive mindset: Remain open to opportunities, adopt an abundance mindset, and cultivate gratitude for what you already have.",
        "Take inspired action: Incorporate wealth-building habits into your daily routine and take steps towards your financial goals."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, your subconscious mind will gradually adopt the beliefs and behaviors that lead to financial success and abundance. Embrace this transformational journey, stay committed to your goals, and get ready to welcome wealth into your life."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Think like a Wealthy Person subliminal! By investing time and effort into aligning your mindset and habits with those of wealthy individuals, you're setting yourself up for financial success and abundance. Now that you've finished the session, it's time to reflect on your experience and take action towards achieving your goals."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Assess your mindset: Take a moment to evaluate your current beliefs about money and wealth. Identify any limiting beliefs and write down affirmations to counteract them.",
        "Set clear financial goals: Create specific, measurable, achievable, relevant, and time-bound (SMART) goals that align with your vision of wealth and abundance.",
        "Create a budget: Establish a budget that reflects your financial goals and allows for both saving and investing.",
        "Educate yourself: Continuously learn about personal finance, investing, and wealth creation through books, podcasts, and courses.",
        "Take inspired action: Act on opportunities that align with your vision of financial success and consistently work towards your goals."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, becoming wealthy is a journey that requires persistence and dedication. Embrace the mindset and habits of wealthy individuals, and take practical steps towards your financial goals. Believe in your ability to achieve abundance and success, and continue growing towards a prosperous future!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Think like a Wealthy Person subliminal help me achieve financial success?`, 
    answer: `The Think like a Wealthy Person subliminal is designed to influence your subconscious mind with positive affirmations and beliefs related to wealth and abundance. By consistently listening to the subliminal, you can reprogram your mindset and develop habits and thought patterns that align with financial success. This can help you make better financial decisions, attract opportunities, and take actions that lead to achieving your financial goals.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Think like a Wealthy Person subliminal?`, 
    answer: `Results may vary depending on the individual and their unique circumstances. Some people may start noticing positive changes in their mindset and financial habits after a few weeks of consistent listening, while others may require more time. It is important to continue listening to the subliminal regularly and maintain a proactive approach towards financial growth and success.`,
    open: false 
  },
  { 
    question: `Can the Think like a Wealthy Person subliminal guarantee financial success?`, 
    answer: `While the subliminal can help you develop the mindset and habits of a wealthy person, it is important to note that financial success is also influenced by various external factors and individual circumstances. The subliminal is a tool to support your journey towards financial abundance, but it does not guarantee specific outcomes.`,
    open: false 
  },
  { 
    question: `How can I maximize the effectiveness of the Think like a Wealthy Person subliminal?`, 
    answer: `To maximize the effectiveness of the subliminal, it is recommended to listen to it daily and consistently. Additionally, practicing gratitude, setting clear financial goals, and taking action towards achieving them can further enhance the impact of the subliminal. Surrounding yourself with positive and like-minded individuals who support your financial goals can also contribute to your success.`,
    open: false 
  },
  { 
    question: `Is the Think like a Wealthy Person subliminal a replacement for financial education and planning?`, 
    answer: `No, the subliminal is not a replacement for financial education or appropriate financial planning. It is intended to complement your financial knowledge and inspire a positive mindset towards wealth and abundance. It is recommended to continue learning about personal finance and seek professional advice to make informed financial decisions.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Think like a Wealthy Person subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Think like a Wealthy Person subliminal. However, it is important to note that the subliminal is meant to be used as a tool for personal development and should not replace professional financial advice or any necessary financial planning.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>