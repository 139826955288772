<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "When it comes to managing your finances, planning the movement of your money is crucial. This subliminal is designed to provide you with effective strategies and techniques to ensure that your money moves in a deliberate and purposeful manner, helping you maximize your financial success."
    },
    {
      type: 'heading',
      text: 'Here are some key aspects this subliminal focuses on:'
    },
    {
      type: 'list',
      items: [
        "Creating a budget that aligns with your financial goals and priorities.",
        "Developing a strategic plan to pay off debt and manage expenses effectively.",
        "Learning how to save and invest wisely for long-term financial stability.",
        "Understanding the importance of tracking your income and expenses.",
        "Developing strong money management habits and avoiding impulsive spending.",
        "Building a mindset of abundance and financial responsibility."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to adopt healthy financial habits and make conscious decisions about your money. Over time, you may find yourself more confident and empowered in managing your finances, achieving your financial goals, and creating a solid foundation for your financial future."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Planning and managing your finances wisely is crucial for achieving financial success. This subliminal is designed to help you develop effective strategies and techniques for carefully moving your money. Before you start listening, consider the following tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear financial goals: Define what you want to achieve with your finances and establish specific targets.",
        "Assess your current financial situation: Understand your income, expenses, savings, and debts to create a realistic plan.",
        "Create a budget: Track your expenses and allocate funds to different categories to ensure your money is used purposefully.",
        "Educate yourself: Learn about various investment options, savings strategies, and financial management techniques to make informed decisions.",
        "Visualize financial success: Imagine yourself achieving your financial goals, feeling confident and in control of your money."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating these strategies into your financial journey while listening to this subliminal, you can develop a healthy and purposeful relationship with money. Remember, financial success is a gradual process, and consistent commitment to your financial goals is key. Now, take a moment to focus on your financial well-being and get ready to shape a brighter financial future."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the 'Plan the Movement of Money Carefully' session! You've taken an important step towards gaining control over your financial future. Now, it's time to reflect on the strategies and techniques you've learned and put them into action."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a budget: Use your newfound knowledge to set up a budget that aligns with your financial goals.",
        "Track your spending: Monitor your expenses to identify areas where you can cut back or make improvements.",
        "Save and invest: Determine a savings plan and explore investment opportunities to grow your wealth.",
        "Educate yourself: Continue learning about personal finance to further enhance your financial intelligence and decision-making."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, managing your finances is an ongoing process that requires diligence and commitment. Take the necessary steps to ensure your money moves purposefully and aligns with your goals. With careful planning and consistent effort, you can achieve financial success and security. Best of luck on your journey!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Why is it important to plan the movement of money?`, 
    answer: `Planning the movement of money is crucial for effective financial management because it allows you to be intentional with how you allocate and use your funds. By having a plan in place, you can prioritize your financial goals, avoid unnecessary expenses, and make informed decisions about saving, investing, and spending. It helps you take control of your finances and work towards achieving long-term financial success.`,
    open: false
  },
  { 
    question: `What are some strategies for planning the movement of money?`, 
    answer: `There are various strategies you can employ to plan the movement of money effectively. Some key strategies include creating a budget, tracking your expenses, setting financial goals, saving consistently, minimizing debt, diversifying your income sources, and investing wisely. It's important to find strategies that align with your financial situation and goals and adjust them as needed.`,
    open: false 
  },
  { 
    question: `How can I track my expenses to plan the movement of money better?`, 
    answer: `Tracking your expenses is essential for understanding your spending patterns and making informed decisions about your finances. You can track your expenses manually using spreadsheets or financial apps, or utilize automated budgeting tools that categorize your transactions. Regularly reviewing and analyzing your expenses can help you identify areas where you can cut back, save more, or redirect funds towards your financial goals.`,
    open: false 
  },
  { 
    question: `Is it necessary to have a financial advisor for planning the movement of money?`, 
    answer: `Having a financial advisor can provide valuable expertise and guidance when it comes to planning the movement of money. They can help you assess your financial situation, set realistic goals, and develop a personalized plan to achieve them. While a financial advisor is not necessary for everyone, seeking professional advice can be beneficial, especially for complex financial matters or if you feel overwhelmed by the planning process.`,
    open: false 
  },
  { 
    question: `What are some common mistakes to avoid when planning the movement of money?`, 
    answer: `When planning the movement of money, it's important to be aware of common mistakes that can hinder your financial success. Some mistakes to avoid include overspending, not saving for emergencies, neglecting to review and adjust your financial plan regularly, taking on too much debt, and making impulsive investment decisions. By being mindful of these pitfalls, you can make more informed choices and stay on track towards your financial goals.`,
    open: false 
  },
  { 
    question: `How can I start planning the movement of money if I have limited financial knowledge?`, 
    answer: `If you have limited financial knowledge, it's never too late to start learning and improving your understanding of personal finance. Begin by studying basic financial concepts, such as budgeting, saving, and investing, through books, articles, or online resources. Consider enrolling in financial literacy courses or consulting with a financial advisor to gain personalized guidance. Taking small steps and gradually implementing good financial habits can significantly impact your financial well-being over time.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>