<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Living with migraines can be debilitating and frustrating, but this subliminal aims to provide you with relief and support in your healing journey. By targeting your subconscious mind, it can help alleviate migraine pain and promote overall well-being."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can assist you:'
    },
    {
      type: 'list',
      items: [
        "Diminishing the intensity and duration of migraines.",
        "Reducing the frequency of migraines, providing longer periods of relief between episodes.",
        "Helping you manage and cope with migraine triggers, empowering you to avoid or minimize their effects.",
        "Promoting relaxation and stress reduction, as tension and anxiety can often contribute to migraines.",
        "Supporting the healing process of your mind and body, aiding in faster recovery from migraines."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your routine, you can retrain your subconscious mind to find relief from migraine pain and promote healing. It is important to note that individual experiences may vary, and it may take time and consistent use to see significant improvements. Remember to consult with a healthcare professional for comprehensive migraine management."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Dealing with migraine pain can be debilitating and affect various aspects of your life. This subliminal aims to provide relief from migraine pain and support the healing of your mind and body. Before you start listening, consider these suggestions to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a calm environment: Find a quiet space where you can relax and be free from distractions during your listening session.",
        "Find a comfortable position: Sit or lie down in a comfortable position to allow your body to fully relax and receive the healing messages.",
        "Set your intention: Focus your mind on finding relief from migraine pain and visualizing a calm, pain-free state of being.",
        "Keep an open mind: Approach the subliminal with an open and receptive mindset, trusting in the power of your subconscious mind to aid in your healing process."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your routine, you can create positive changes in your perception of migraine pain and bring about healing for your mind and body. Embrace this opportunity to prioritize your well-being and say goodbye to migraine pain for good. Let's begin this journey towards a pain-free life."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Migraine Pain Relief and Healing subliminal session! You've taken an important step towards finding relief from migraine pain and healing your mind and body. As you continue on your journey, take some time to reflect on your experience and implement the following post-listening tips for further progress."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Take note of triggers: Keep a record of potential triggers, such as certain foods, stress, or lack of sleep.",
        "Practice relaxation techniques: Explore meditation, deep breathing exercises, or progressive muscle relaxation to manage stress and promote healing.",
        "Seek professional help: Consider consulting with a healthcare provider or specialist for additional guidance and support.",
        "Maintain a healthy lifestyle: Prioritize regular exercise, balanced nutrition, and adequate rest to support your overall well-being and migraine management."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, finding relief from migraine pain and healing is a process that may require a multi-faceted approach. Applying these post-listening tips, along with consistency and patience, can contribute to your journey towards saying goodbye to migraine pain for good. Wishing you a pain-free and vibrant life!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Migraine Pain Relief and Healing subliminal help with my migraines?`, 
    answer: `The Migraine Pain Relief and Healing subliminal uses positive affirmations to influence your subconscious mind and promote relaxation, reduce stress, and alleviate migraine pain. By listening to the subliminal regularly, you may experience a reduction in the frequency and intensity of migraine attacks. It is important to note that this subliminal is not a substitute for medical advice or treatment, so please consult with your healthcare provider for a comprehensive approach to managing your migraines.`,
    open: false
  },
  { 
    question: `How long does it take to experience relief from migraines with the subliminal?`, 
    answer: `Results may vary from person to person, and it may take time for the subliminal messages to effect subconscious changes. We recommend listening to the Migraine Pain Relief and Healing subliminal consistently for at least a few weeks to give it a chance to make a difference in your migraine symptoms. It is important to remember that this subliminal is intended to be used as a complementary approach to your migraine management plan, not as a standalone solution.`,
    open: false 
  },
  { 
    question: `Can the Migraine Pain Relief and Healing subliminal completely eliminate my migraines?`, 
    answer: `The Migraine Pain Relief and Healing subliminal is designed to help alleviate migraine symptoms by promoting relaxation and a positive mindset. While it may reduce the frequency and intensity of migraine attacks for some individuals, it is not guaranteed to completely eliminate migraines. It is important to work with your healthcare provider to develop a comprehensive treatment plan that addresses your specific needs.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Migraine Pain Relief and Healing subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your migraines or health, it is important to consult with your healthcare provider for proper diagnosis and advice. The subliminal is intended to supplement your existing migraine management plan and is not meant to replace medical treatment.`,
    open: false 
  },
  { 
    question: `Can I listen to the Migraine Pain Relief and Healing subliminal during a migraine attack?`, 
    answer: `It is generally recommended to find a calm and quiet environment during a migraine attack to rest and manage your symptoms. The subliminal may be more effective when listened to during non-migraine periods to promote relaxation and overall well-being. You can incorporate the subliminal into your daily routine to create a positive mindset and potentially reduce the frequency and intensity of migraine attacks over time.`,
    open: false 
  },
  { 
    question: `Can the Migraine Pain Relief and Healing subliminal be used in conjunction with other migraine treatments?`, 
    answer: `Yes, the Migraine Pain Relief and Healing subliminal can be used as a complementary approach to your existing migraine treatment plan. It is important to work with your healthcare provider to develop a comprehensive strategy that addresses your specific needs and includes a combination of medical treatments, lifestyle changes, and alternative therapies if applicable.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>