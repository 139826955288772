<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're experiencing irregular or painful periods, managing the symptoms of PMS, or simply seeking to bring balance and harmony to your menstrual cycle, this subliminal can be beneficial for you. By working with your subconscious mind, it aims to support the healing and regulation of your menstrual cycle."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help:'
    },
    {
      type: 'list',
      items: [
        "Promoting regular and predictable menstrual cycles.",
        "Reducing the intensity of menstrual cramps and pain.",
        "Alleviating symptoms of PMS, such as mood swings and bloating.",
        "Enhancing overall hormonal balance and function.",
        "Improving your emotional well-being during your menstrual cycle.",
        "Supporting a deeper connection to your body and its natural rhythms."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your routine, you can encourage positive changes and address imbalances within your menstrual cycle. Remember that individual results may vary, and it's important to consult with a healthcare professional for any specific concerns. With regular use and patience, you can work towards achieving a healthier and more harmonious menstrual cycle."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Finding balance and harmony in your menstrual cycle is important for your overall well-being. This subliminal is designed to help you heal and regulate your cycle, bringing you closer to a state of balance. Before you start listening, consider these tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Educate yourself: Research and understand the factors that can affect your menstrual cycle, such as lifestyle choices, nutrition, stress, and hormone levels.",
        "Practice self-care: Prioritize self-care activities that promote relaxation, reduce stress, and support your body's natural healing process.",
        "Create a peaceful environment: Find a quiet and comfortable space where you can relax and listen to the subliminal without distractions.",
        "Set your intention: Focus on healing and regulating your menstrual cycle, visualizing a balanced and harmonious state for your body and mind."
      ]
    },
    {
      type: 'paragraph',
      text: "As you embark on this journey of healing and balance, remember that consistency is key. Regularly listening to this subliminal can create positive shifts in your menstrual cycle and bring greater harmony to your body and mind. Take a moment to center yourself, set your intention, and let the healing begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Menstrual Cycle Healing subliminal! By focusing on your menstrual cycle and working towards balance and harmony in your body and mind, you've taken an important step towards improving your overall well-being. Now, it's time to reflect on your experience and continue your journey towards a healthier menstrual cycle."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Normalize self-care: Prioritize self-care activities during your menstrual cycle to provide comfort and support.",
        "Listen to your body: Pay attention to the signals your body gives you during your cycle and adjust your activities and routines accordingly.",
        "Maintain a healthy lifestyle: Eat nutritious foods, engage in regular exercise, and get enough rest to support your menstrual health.",
        "Consider seeking professional help: If you experience persistent irregularities or discomfort, it may be beneficial to consult a healthcare provider."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, healing and regulating your menstrual cycle is a personal journey. Be patient with yourself and give yourself the care and attention you deserve. By taking these post-listening tips to heart, you're supporting your body and mind in achieving balance and harmony. Keep going and embrace the renewed sense of well-being on your menstrual cycle healing journey!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Menstrual Cycle Healing subliminal help regulate my menstrual cycle?`, 
    answer: `The Menstrual Cycle Healing subliminal is designed to influence your subconscious mind and promote balance and harmony in your menstrual cycle. By listening to the subliminal messages regularly, you may experience reduced menstrual pain, more regular cycles, and improved overall reproductive health. Consistency is key, so it is recommended to listen to the subliminal daily for best results.`,
    open: false
  },
  { 
    question: `How long does it take to see improvements in menstrual cycle regularity?`, 
    answer: `The time it takes to see improvements in menstrual cycle regularity may vary from person to person. Some individuals may notice positive changes after a few weeks of consistent listening, while others may require more time. It is important to remember that everyone's body is different, so results may vary.`,
    open: false 
  },
  { 
    question: `Can the Menstrual Cycle Healing subliminal help with menstrual pain?`, 
    answer: `Yes, the Menstrual Cycle Healing subliminal is designed to help reduce menstrual pain and discomfort. The subliminal messages work to influence your subconscious mind and promote relaxation and balance in your reproductive system. While it may not completely eliminate all pain, it can help to alleviate symptoms and promote a more comfortable menstrual experience.`,
    open: false 
  },
  { 
    question: `Is it safe to listen to the Menstrual Cycle Healing subliminal during my period?`, 
    answer: `Yes, it is safe to listen to the Menstrual Cycle Healing subliminal during your period. The subliminal messages are designed to promote balance and harmony in your menstrual cycle. Listening to the subliminal during your period can help provide additional support and promote relaxation and comfort.`,
    open: false 
  },
  { 
    question: `Should I stop using other menstrual products or treatments when using the Menstrual Cycle Healing subliminal?`, 
    answer: `No, the Menstrual Cycle Healing subliminal is not intended to replace any necessary medical or menstrual products or treatments. It is designed to be used as a complementary tool to support your overall health and well-being. If you have any concerns or questions about specific products or treatments, it is recommended to consult with your healthcare provider.`,
    open: false 
  },
  { 
    question: `Can the Menstrual Cycle Healing subliminal help with hormonal imbalances?`, 
    answer: `The Menstrual Cycle Healing subliminal is not a substitute for medical advice and should not be used as the sole treatment for hormonal imbalances. However, it can help promote balance and harmony in your menstrual cycle, which may indirectly support healthier hormonal function. If you suspect you have a hormonal imbalance, it is important to consult with a healthcare professional for a proper diagnosis and treatment plan.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>