<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you struggle with excessive sweating and it's affecting your daily life, this subliminal can help you find relief and regain your confidence. By targeting your subconscious mind, it can assist in reducing excessive sweating and promoting a drier and more comfortable life."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Reducing the amount of sweat your body produces, especially in situations where you tend to sweat excessively.",
        "Helping you feel more comfortable and confident in social situations, free from the worry of excessive sweating.",
        "Reducing the odor associated with excessive sweating, leading to improved personal hygiene.",
        "Promoting a dry and comfortable life, allowing you to enjoy daily activities without the discomfort of excessive sweat.",
        "Boosting your self-esteem and overall confidence, helping you feel more at ease in various situations."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to regulate your body's sweat production and reduce excessive sweating. Remember, results may vary, but with consistent use, you can improve your quality of life and feel more confident in your own skin."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Excessive sweating can be a frustrating and embarrassing condition to deal with, but there are strategies you can implement to help manage it. This subliminal is designed to assist you in reducing and controlling your excessive sweating. Before you start listening, here are some tips to prepare yourself for a successful session:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Stay positive and open-minded: Approach this subliminal with a positive attitude, believing in the potential for change.",
        "Set your intention: Clearly define your goal, whether it's to reduce overall sweating or specific problem areas.",
        "Create a supportive environment: Find a quiet and comfortable space where you can relax and focus on the subliminal messages.",
        "Visualize your desired outcome: Imagine yourself living a dry and comfortable life, free from excessive sweating."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to the subliminal affirmations, allow them to sink into your subconscious mind. Embrace the possibility of change and trust in the process. Remember, lasting results may require regular listening and consistency. Take a moment to set your intention, let go of any doubts, and prepare yourself for the journey towards a drier and more confident life."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Stop Sweating Excessively subliminal! You've taken a significant step towards overcoming excessive sweating and enjoying a more comfortable and confident life. As you reflect on your experience, remember that you have the power to make positive changes."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Investigate triggers: Pay attention to activities or situations that may trigger excessive sweating and find ways to manage or avoid them.",
        "Choose breathable fabrics: Opt for natural fabrics like cotton to allow your skin to breathe and minimize sweating.",
        "Practice stress management: Engage in relaxation techniques like deep breathing or meditation to reduce stress levels, as stress can contribute to excessive sweating.",
        "Consult a healthcare professional: If excessive sweating persists, consider seeking advice from a healthcare professional who can provide further guidance and treatment options."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, everyone's journey towards overcoming excessive sweating is unique. Stay patient and determined, and don't hesitate to reach out for support when needed. You deserve to live a dry and comfortable life!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Stop Sweating Excessively subliminal work?`, 
    answer: `The Stop Sweating Excessively subliminal uses positive affirmations and subliminal messages to target the subconscious mind and help reprogram it for reduced sweating. By listening to the audio regularly, you can begin to change your mindset and minimize excessive sweating. Consistency is key, and it is important to note that individual results may vary.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Stop Sweating Excessively subliminal?`, 
    answer: `The time it takes to see results from the Stop Sweating Excessively subliminal can vary from person to person. Some individuals may notice improvements within a few weeks of consistent listening, while others may require more time. It is recommended to listen to the subliminal daily and give it sufficient time to work on reprogramming your subconscious mind.`,
    open: false 
  },
  { 
    question: `Can the Stop Sweating Excessively subliminal be used alongside other treatments?`, 
    answer: `Yes, the Stop Sweating Excessively subliminal can be used alongside other treatments. It is important to consult with a healthcare professional to determine the best approach for managing excessive sweating. The subliminal can complement other treatments by focusing on the mindset and subconscious reprogramming aspect of reducing sweating.`,
    open: false 
  },
  { 
    question: `Is the Stop Sweating Excessively subliminal safe to use?`, 
    answer: `Yes, the Stop Sweating Excessively subliminal is safe to use. The audio contains positive affirmations and subliminal messages that are designed to target the subconscious mind and promote a reduction in excessive sweating. However, if you have any concerns or underlying health conditions, it is always recommended to consult with a healthcare professional.`,
    open: false 
  },
  { 
    question: `Will the Stop Sweating Excessively subliminal completely stop all sweating?`, 
    answer: `The Stop Sweating Excessively subliminal is designed to help reduce excessive sweating by targeting the subconscious mind and changing the mindset related to sweating. While individual results may vary, the aim is to minimize excessive sweating and allow you to enjoy a drier and more comfortable life. It is important to note that complete cessation of sweating is not guaranteed.`,
    open: false 
  },
  { 
    question: `Can the Stop Sweating Excessively subliminal be used by everyone?`, 
    answer: `Yes, the Stop Sweating Excessively subliminal can be used by most individuals. However, it is important to note that individual results may vary. If you have any concerns or underlying health conditions, it is recommended to consult with a healthcare professional before using the subliminal.`,
    open: false 
  }
]
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>