<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're struggling with a gambling addiction, you're not alone. This subliminal is designed to help you break free from the destructive cycle of gambling and regain control of your life."
    },
    {
      type: 'heading',
      text: 'Here are some areas where this subliminal can assist you:'
    },
    {
      type: 'list',
      items: [
        "Overcoming cravings and urges to gamble.",
        "Developing a healthier relationship with money and finances.",
        "Building self-discipline and impulse control to resist the temptation to gamble.",
        "Finding healthier ways to cope with stress, boredom, or other triggers that lead to gambling.",
        "Rebuilding relationships and trust that may have been damaged by your addiction.",
        "Creating a solid plan for financial stability and managing your money wisely."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal works by influencing your subconscious mind, helping you change your thoughts, habits, and behaviors surrounding gambling. It can provide you with the support and motivation you need to break free from your addiction and create a healthier, happier life. Remember, recovery is a journey, but with dedication and perseverance, you can overcome your gambling addiction and find the freedom and financial stability you deserve."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Breaking free from a gambling addiction can be challenging, but with the help of this subliminal, you can regain control of your life. Before you start your journey towards freedom and financial stability, consider these preparation tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your addiction: Recognize that you have a gambling addiction and that seeking support is a courageous step forward.",
        "Set clear goals: Define what you want to achieve by overcoming your addiction, such as regaining financial stability, rebuilding relationships, or improving your overall well-being.",
        "Create a support system: Reach out to family, friends, or professionals who can provide emotional support and guidance throughout your recovery process.",
        "Visualize a healthier future: Picture yourself living a fulfilling life free from the destructive cycle of gambling, and hold onto that vision throughout your listening sessions."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind to resist temptations, find healthier outlets, and overcome your gambling addiction. Embrace the courage within you, set your goals, and take the first step towards a brighter, addiction-free future."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Gambling Addiction Removal subliminal session! By taking the initiative to address your gambling addiction, you have taken a significant step towards regaining control of your life and finding freedom from its destructive cycle."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Seek professional support: Consult with a therapist or counselor to help you navigate your journey towards recovery.",
        "Join support groups: Connect with others who have experienced or are going through similar challenges for additional guidance and encouragement.",
        "Create a new routine: Fill your time with productive and fulfilling activities to replace the time spent gambling.",
        "Establish financial safeguards: Set up barriers that restrict your access to gambling funds and consider seeking advice from a financial advisor."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming a gambling addiction is a difficult but achievable process. Surround yourself with a strong support system and be patient with yourself as you navigate through this journey to regain control of your life. Each day of abstinence is a step towards a brighter future. Stay strong!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Gambling Addiction Removal subliminal help me overcome my gambling addiction?`, 
    answer: `The Gambling Addiction Removal subliminal is designed to work on a subconscious level, helping to reprogram your thoughts, beliefs, and behaviors related to gambling. By listening to the subliminal regularly, you can begin to break free from the destructive cycle of gambling and develop healthier habits. The subliminal messages promote self-control, motivation to quit, and a positive mindset towards financial stability and personal fulfillment.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Gambling Addiction Removal subliminal?`, 
    answer: `Results may vary depending on the individual and the severity of the gambling addiction. It is important to remember that overcoming addiction is a journey, and it may take time to fully rewire your subconscious mind. Consistently listening to the subliminal messages and combining it with other forms of support, such as counseling or support groups, can optimize your chances of successful recovery.`,
    open: false 
  },
  { 
    question: `Can the Gambling Addiction Removal subliminal cure my gambling addiction completely?`, 
    answer: `The Gambling Addiction Removal subliminal is a tool to help you overcome your addiction and develop healthier habits and mindset towards gambling. However, addiction is a complex issue, and it is important to seek additional support and professional help. The subliminal can complement other forms of treatment and support, such as therapy, counseling, or support groups, to increase your chances of successful recovery.`,
    open: false 
  },
  { 
    question: `Will I still have cravings for gambling after using the Gambling Addiction Removal subliminal?`, 
    answer: `While the Gambling Addiction Removal subliminal is designed to reprogram your thoughts, beliefs, and behaviors related to gambling, it cannot guarantee the complete elimination of cravings. The subliminal aims to provide you with the tools and mindset to better manage and overcome cravings. Combining the subliminal with therapy, counseling, or support groups can further support you in dealing with cravings and maintaining a gambling-free lifestyle.`,
    open: false 
  },
  { 
    question: `Is it safe to stop professional counseling or therapy while using the Gambling Addiction Removal subliminal?`, 
    answer: `The Gambling Addiction Removal subliminal can be a helpful tool to complement other forms of support, such as counseling or therapy. However, it is important to consult with your therapist or counselor before making any changes to your treatment plan. They can provide guidance on how the subliminal can be integrated into your overall recovery process.`,
    open: false 
  },
  { 
    question: `Can the Gambling Addiction Removal subliminal help with other addictive behaviors?`, 
    answer: `The Gambling Addiction Removal subliminal is specifically designed to address gambling addiction. While some subliminal messages may be applicable to other addictive behaviors, it is recommended to seek specific subliminals or professional support tailored to the particular addiction you wish to address.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>