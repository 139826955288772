<template>
  <Dialog :dialog="dialog" :width="500" @closeDialog="closeDialog">
    <div class="text-center">
      <div class="deer-container">
        <img class="deer" src="@/assets/images/user/playlist/deer.png" />
      </div>
      <input
        class="p-title"
        v-model="title"
        placeholder="Type playlist title"
      />
    </div>
    <template v-slot:action>
      <div class="add-btn">
        <button @click="addPlaylist()">Add</button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { getOwnPlaylist, addOwnPlaylist } from "@/api/ownplaylist";
import { addSubliminalToOwnPlaylists } from "@/api/playlistinfo";
import { getMessage } from "@/utils";
import { getAllUserPlaylist } from "@/api/playlist.js";

import Dialog from "@/components/Dialog.vue";
import DataLoading from "@/components/DataLoading.vue";
import CardSingle from "@/components/Card/CardSingle.vue";

import { mapGetters } from "vuex";

export default {
  props: {
    dialog: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data: () => {
    return {
      getMessage,
      addOwnPlaylist,
      getOwnPlaylist,
      addSubliminalToOwnPlaylists,
      getAllUserPlaylist,

      ownPlaylists: [],

      search: null,
      radio: {},
      title: null,
    };
  },
  components: {
    Dialog,
    CardSingle,
    DataLoading,
  },
  computed: {
    ...mapGetters({
      isDataReady: "newaudio/getDataStatus",
      favoriteIDs: "favorite/getFavoriteIDs",
      favoriteInfoLists: "favorite/getFavoriteInfoLists",
      playlistIDs: "playlist/getPlaylistIDs",
      playlistInfoLists: "playlist/getPlaylistInfoLists",
    }),
    user() {
      return JSON.parse(this.$store.state.user.info);
    },
    subscriptionId() {
      return this.$store.state.subscription.id;
    },
  },
  watch: {
    dialog(val) {
      if (val) {
      } else {
        this.radio = {};
        this.search = null;
      }
    },
  },
  methods: {
    addPlaylist() {
      let playlistId = [...this.playlistIDs];
      let playlists = [...this.playlistInfoLists];

      if (this.data != null) {
        const data = {
          title: this.title,
          user_id: this.user.user_id,
          subscription_id: this.user.info.subscription_id,
        };
        this.addOwnPlaylist(data).then((res) => {
          if (res.success == true) {
            const data2 = {
              user_id: this.user.user_id,
              subscription_id: this.user.info.subscription_id,
              playlist_id: res.data[0].playlist_id,
              subliminal_id: this.data.subliminal_id,
            };
            this.addSubliminalToOwnPlaylists(data2).then((res) => {
              this.closeDialog();
              this.getMessage(this, res);
              res.data.forEach((items) => {
                if (!this.playlistIDs.includes(items.playlist_id)) {
                  playlistId.push(items.playlist_id);
                  playlists.push(items);
                }
              });

              if (!this.playlistIDs.includes(playlistId)) {
                this.$store.dispatch("playlist/playlistInfoLists", playlists);
              }
              this.$store.dispatch("playlist/getOwnLoading", false);
            });
          } else {
            this.title = null;
            this.getMessage(this, res);
          }
        });
      } else {
        const data = {
          title: this.title,
          user_id: this.user.user_id,
          subscription_id: this.user.info.subscription_id,
        };
        this.addOwnPlaylist(data).then((res) => {
          this.getMessage(this, res);
          if (res.success == true) {
            this.closeDialog();
            localStorage.setItem("user_track", JSON.stringify(res.data[0]));
            this.$store.dispatch("user/getTrack", res.data[0]);
            this.$store.dispatch("playlist/getOwnLoading", false);

            this.getAllUserPlaylist(data).then((res) => {
              this.$store.dispatch("playlist/playlistInfoLists", res);
            })

          }
          this.title = null;
        });
      }
    },
    closeDialog() {
      this.$emit("closeDialog", false);
    },
  },
};
</script>

<style>
.add-btn {
  margin: auto;
  margin-top: -10px;
  margin-bottom: 10px;
  width: 150px;
  padding: 10px;
  background: #1c8edf;
  border-radius: 10px;
  font-family: "Montserrat-Bold" !important;
  color: white;
  font-size: 14px;
  text-align: center;
}
.deer-container {
  background-color: #427ab3;
  width: 150px;
  margin: auto;
  border-radius: 5px;
  padding: 10px;
}
.deer {
  height: 120px;
  width: 130px;
}
.p-title {
  padding: 10px;
  width: 60%;
  margin: auto;
  margin-top: 20px;
  border-radius: 5px;
  color: rgba(47, 48, 51, 0.54);
  font-family: "Montserrat-Medium" !important;
  background-color: white;
  font-size: 14px;
  border-width: 0px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
}

.own-playlist-container {
  margin: 0px 15px !important;
}

.cover {
  min-width: 90px;
  height: 64px;

  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.card {
  min-height: 80px;
  width: 100%;
}

.card,
.details {
  cursor: pointer;
}

.details > p:nth-of-type(1) {
  font-size: 10px;
}

.details > p:nth-of-type(2) {
  font-size: 8px;
}
</style>
