<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're looking to achieve a more youthful face and boost your confidence in your appearance, this subliminal is designed to help you do just that. By incorporating natural skincare routines and techniques, it aims to restore and maintain a youthful and radiant complexion."
    },
    {
      type: 'heading',
      text: 'Benefits of using this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Reducing the appearance of fine lines, wrinkles, and age spots.",
        "Reversing the signs of aging and promoting a more youthful complexion.",
        "Boosting collagen production and improving skin elasticity.",
        "Enhancing overall skin texture and tone for a smoother, healthier-looking face.",
        "Increasing your confidence and self-esteem by improving your appearance."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your daily skincare routine, you can tap into the power of your subconscious mind to support your skincare goals. With consistency and regular use, you may notice significant improvements in your skin's appearance and overall radiance. Remember, natural skincare takes time and patience, but your efforts will be rewarded with a more youthful and vibrant face."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Regaining a youthful face and boosting your confidence in your appearance is a goal that many aspire to achieve. This subliminal is designed to assist you in your journey towards a more youthful-looking face through natural skincare routines and techniques. Before you start listening, here are some suggestions to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Educate yourself: Learn about different skincare routines, ingredients, and techniques that promote a youthful complexion.",
        "Establish a skincare routine: Develop a consistent daily skincare routine tailored to your specific needs and goals.",
        "Nourish your body: Consume a balanced diet rich in nutrients that promote healthy skin, such as antioxidants, collagen, vitamins, and minerals.",
        "Maintain a positive mindset: Believe in the power of natural skincare and the ability of your body to rejuvenate and restore your appearance."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal, you'll be exposed to affirmations that can help rewire your subconscious mind to support a more youthful face. By combining the power of your mind with informed skincare practices, you can feel more confident in your appearance and embrace your journey towards a rejuvenated, youthful face. Get ready to unlock your true radiance!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Youthful Face subliminal! You've taken a positive step towards enhancing your skincare routine and boosting your confidence in your appearance. Now it's time to reflect on your experience and continue your journey towards maintaining a youthful face."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Take care of your skin: Follow a regular skincare routine with products suited for your skin type.",
        "Protect from sun damage: Apply sunscreen daily and limit sun exposure to prevent premature aging.",
        "Stay hydrated: Hydrate your skin by drinking enough water and using moisturizers.",
        "Nourish from within: Incorporate a balanced diet rich in antioxidants for healthy, youthful skin."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, maintaining a youthful face requires consistent effort and a holistic approach to skincare. Embrace the self-care journey and enjoy the confidence and vibrancy that comes with taking care of yourself. You deserve to look and feel your best!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Youthful Face subliminal help in restoring and maintaining a youthful appearance?`, 
    answer: `The Youthful Face subliminal is designed to positively influence your subconscious mind, encouraging the adoption of natural skincare routines and techniques that promote a youthful face. By consistently listening to the subliminal, you can enhance your skincare routine, improve skin health, and foster a confident and youthful appearance.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Youthful Face subliminal?`, 
    answer: `Results may vary depending on individual factors, such as skin type, consistency in skincare practices, and overall health. It is recommended to listen to the subliminal regularly and engage in a comprehensive skincare routine for optimal results. In general, significant improvements can be noticed within a few weeks to a few months of consistent practice.`,
    open: false 
  },
  { 
    question: `Can the Youthful Face subliminal replace skincare products and treatments?`, 
    answer: `The Youthful Face subliminal is not intended to replace skincare products and treatments. Instead, it complements these practices by working on your mindset and promoting a positive relationship with your skin. It can help you adopt healthier skincare habits and routines that enhance the effectiveness of skincare products and treatments.`,
    open: false 
  },
  { 
    question: `Is it safe to use the Youthful Face subliminal in conjunction with other skincare products?`, 
    answer: `Yes, it is safe to use the Youthful Face subliminal in conjunction with other skincare products. The subliminal messages focus on promoting a positive mindset towards skincare and enhancing natural ways to maintain a youthful face. It is important to follow proper skincare practices and consult with a dermatologist or skincare professional as needed.`,
    open: false 
  },
  { 
    question: `Will the Youthful Face subliminal work for everyone?`, 
    answer: `The Youthful Face subliminal is designed to be effective for most individuals seeking to restore and maintain a more youthful appearance. However, individual results may vary based on factors such as genetics, lifestyle, and overall health. Consistent practice, along with healthy lifestyle choices and suitable skincare products, can improve the effectiveness of the subliminal.`,
    open: false 
  },
  { 
    question: `Can the Youthful Face subliminal be used by people of all ages?`, 
    answer: `Yes, the Youthful Face subliminal can be used by people of all ages who are interested in maintaining a more youthful appearance naturally. It is never too early or too late to adopt healthy skincare practices and a positive mindset towards your skin.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>