<template>
  <div>
    <Subscription />
  </div>
</template>

<script>
import Subscription from '@/views/Admin/Subscription/index.vue'
export default {
  components: {
    Subscription
  },
  data: () => {
    return {}
  }
}
</script>
