import { render, staticRenderFns } from "./196-self-love.vue?vue&type=template&id=749de673&scoped=true&"
import script from "./196-self-love.vue?vue&type=script&lang=js&"
export * from "./196-self-love.vue?vue&type=script&lang=js&"
import style0 from "./196-self-love.vue?vue&type=style&index=0&id=749de673&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "749de673",
  null
  
)

export default component.exports