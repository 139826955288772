<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're struggling with masturbation addiction and want to break free from its harmful effects, this subliminal can be a helpful tool on your journey to recovery. By targeting your subconscious mind, it can assist in reprogramming unhealthy patterns and behaviors."
    },
    {
      type: 'heading',
      text: 'Here are some ways in which this subliminal can support you:'
    },
    {
      type: 'list',
      items: [
        "Reducing the frequency and intensity of masturbation urges.",
        "Increasing willpower and self-discipline to resist temptations.",
        "Promoting healthier coping mechanisms and alternative ways of finding pleasure.",
        "Building self-esteem, self-confidence, and a positive body image.",
        "Reestablishing a healthy and balanced approach to sexuality and intimacy."
      ]
    },
    {
      type: 'paragraph',
      text: "By using this subliminal regularly, you can begin to regain control of your life and move towards a healthier mindset and lifestyle. It's important to remember that overcoming addiction takes time, effort, and patience. Be kind to yourself throughout this process and celebrate each step towards recovery."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Addiction to masturbation can have negative effects on your physical, mental, and emotional well-being. This subliminal is designed to help you break free from this habit and regain control of your life. Before starting, here are some suggestions to prepare yourself for the subliminal session:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your desire for change: Recognize that you want to stop and regain control over your actions.",
        "Set your intention: Focus on your goal to overcome the addiction and create a healthy relationship with your body and sexuality.",
        "Create a support system: Reach out to trusted friends or professionals who can provide guidance and encouragement throughout your journey.",
        "Eliminate triggers: Identify and remove any triggers that may lead to urges or temptations.",
        "Stay positive: Believe in your ability to overcome this addiction and regain control of your life.",
        "Be patient and forgiving: Understand that breaking free from addiction takes time and effort, and be kind to yourself throughout the process."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening regularly to this subliminal, you can reprogram your subconscious mind and establish healthier habits and behaviors. Remember, you are taking an important step towards self-improvement and personal growth. Embrace this moment of change, stay committed, and let the journey to freedom begin!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Masturbation Addiction Removal subliminal session! By choosing to take control of this aspect of your life, you've embarked on a journey towards breaking free from harmful habits and regaining control over your actions. As you reflect on your experience, remember the importance of self-care and self-compassion throughout this process."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Identify triggers: Take note of the situations, emotions, or thoughts that trigger your masturbation addiction.",
        "Replace harmful habits: Explore healthier coping mechanisms such as physical activity, creative outlets, or engaging in new hobbies.",
        "Seek support: Reach out to trusted friends, family, or professionals who can provide guidance and encouragement on this journey.",
        "Practice self-care: Prioritize self-care activities that foster relaxation, stress reduction, and overall well-being."
      ]
    },
    {
      type: 'paragraph',
      text: "Breaking free from addiction is a challenging process, but with commitment, determination, and the right support, you can overcome it. Remember to be patient and kind to yourself during setbacks or moments of temptation. Each day is an opportunity for growth and positive change. You're on the path to reclaiming control of your life. Keep going!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Can the Masturbation Addiction Removal subliminal help me overcome my addiction?`, 
    answer: `Yes, the Masturbation Addiction Removal subliminal is designed to help individuals overcome and break free from the harmful effects of masturbation addiction. By listening to the subliminal messages regularly, it can assist in reprogramming your subconscious mind towards healthier habits and behaviors. However, individual results may vary and it is important to combine the subliminal with other appropriate support systems and strategies for a comprehensive approach to recovery.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Masturbation Addiction Removal subliminal?`, 
    answer: `The time it takes to see results from the Masturbation Addiction Removal subliminal can vary depending on the individual and the severity of the addiction. Consistent and dedicated listening is key to reprogramming the subconscious mind and creating lasting change. It may take several weeks or even months to see significant improvements, and it is important to be patient and persistent in your efforts.`,
    open: false 
  },
  { 
    question: `Are there any strategies I can use alongside the Masturbation Addiction Removal subliminal to aid in my recovery?`, 
    answer: `Yes, combining the Masturbation Addiction Removal subliminal with other strategies can enhance your recovery journey. Consider seeking professional help or joining a support group to gain additional support and guidance. Developing a healthy routine, finding alternative outlets for stress or emotions, practicing self-care, and avoiding triggers or situations that may lead to relapse can also be helpful in overcoming addiction.`,
    open: false 
  },
  { 
    question: `Is it safe to listen to the Masturbation Addiction Removal subliminal while undergoing therapy?`, 
    answer: `Yes, it is generally safe to listen to the Masturbation Addiction Removal subliminal while undergoing therapy. However, it is important to consult with your therapist or healthcare provider to ensure that it aligns with your treatment plan and goals. They can provide personalized guidance and support based on your specific needs and circumstances.`,
    open: false 
  },
  { 
    question: `Can the Masturbation Addiction Removal subliminal be a standalone treatment for addiction?`, 
    answer: `No, the Masturbation Addiction Removal subliminal is not intended to be a standalone treatment for addiction. It is designed to supplement and support your overall recovery efforts. In cases of addiction, it is important to seek professional help and guidance from qualified therapists or addiction specialists. They can provide a comprehensive treatment plan tailored to your unique needs.`,
    open: false 
  },
  { 
    question: `Will the Masturbation Addiction Removal subliminal eliminate sexual desire altogether?`, 
    answer: `No, the Masturbation Addiction Removal subliminal is not intended to eliminate sexual desire altogether. It aims to help individuals overcome addiction and regain control of their lives. It can assist in transforming unhealthy compulsions into healthier behaviors, but it is not designed to suppress or eliminate normal and healthy sexual desire.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>