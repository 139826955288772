<template>

</template>

<script>
import { checkUrl } from "@/utils";
import { getMagus } from "@/api/magusdetails";
import { userSignUp, checkMobileLogin } from "@/api/user.js";
import {
  createPaymentLink,
  createPayment,
  paymentWebhookEnable,
} from "@/api/payment";
import { getPromoCode, addPromoCodeManual } from "@/api/promocode";
import { getSubscriptionByName } from "@/api/subscription";

import { codeQueryParams, loginQueryParams, periodQueryParams, amountQueryParams, promoQueryParams } from "@/utils";
export default {
  data: () => {
    return {
      getMagus,
      userSignUp,
      checkMobileLogin,
      codeQueryParams,
      periodQueryParams,
      amountQueryParams, 
      promoQueryParams,

      getSubscriptionByName,
      getPromoCode,
      addPromoCodeManual,
      createPaymentLink,
      createPayment,
      paymentWebhookEnable,

      dialog: false,

      subscription_name: "",
      subscription_amount: 0,
      subscription_period: "",

      period: "Monthly",
      isLogin: false,
      items: [],
      start: 0,
      end: 3,
      nextButton: true,
      prevButton: true,

      checkout_url: "",
      paymentdescription: "Enjoy more with Magus Premium.",
    };
  },
  created() {
    this.$store.dispatch("screen/getLoading", true);
    const params = {
      route: this.$route,
      router: this.$router,
      tempname: "register_temp",
    };
    checkUrl(params);
    this.getData();
    this.checkMobileLogins()
  },
  computed: {
    periodParams() {
      return this.periodQueryParams();
    },
    codeParams() {
      return this.codeQueryParams();
    },
    amountParams() {
      return this.amountQueryParams();
    },
    promoParams() {
      return this.promoQueryParams();
    },
    isMobile() {
      return this.$store.state.screen.width < 1200;
    },
    subscription() {
      return this.$store.state.subscription.data;
    },
    data() {
      return this.subscription.filter((items) => items.name == "Premium");
    },
    user() {
      return this.$store.state.user.info;
    },
    users() {
      return JSON.parse(this.user);
    },
    userToken() {
      return this.$store.state.user.token;
    },
  },
  watch: {
    period(val) {
      this.subscription_period = val;
    },
  },
  methods: {
    checkMobileLogins() {
      if(this.codeParams != null){
        const data = {
          code: this.codeParams,
        }
        this.checkMobileLogin(data).then((res) => {
          if(res.success == true) {
            const token = res.data.token;
            const info = JSON.stringify(res.data.user);
            const cover = res.data.user.info.cover;

            this.$store.dispatch(`user/getCover`, cover);
            this.$store.dispatch(`user/getInfo`, info);
            this.$store.dispatch(`user/getToken`, token);
            this.$store.dispatch(`user/getLocalInfo`, info);
            this.$store.dispatch(`user/getLocalToken`, token);
            if(this.periodParams != null) {
              if(this.periodParams == 1){
                this.getPeriod('Monthly')
                this.mobilePayment()
              }else if (this.periodParams == 2){
                this.getPeriod('Yearly')
                this.mobilePayment()
              }
            }
          }
        })
      }
    },
    mobilePayment() {
      const paymentData = {
        amount: parseInt(this.amountParams + "00"),
        subscription: "Premium",
        description: this.paymentdescription,
        user_id: this.users.user_id,
        promo_code: this.promoParams,
        period: this.period,
      };
      this.createPaymentLink(paymentData).then((res) => {
        const checkout_url = res.attributes.checkout_url;
        paymentData.payment_id = res.attributes.reference_number;
        paymentData.transaction_code = res.attributes.reference_number;
        paymentData.period = this.period;

        this.getSubscriptionByName(paymentData.subscription).then((res) => {
          paymentData.subscription_id = res[0].id;
          if (this.promoParams != "") {
            this.addPromoCodeManual(paymentData);
          }
        });

        this.createPayment(paymentData).then((res) => {
          this.paymentWebhookEnable().then((res) => {
            window.location.href = checkout_url;
          });
        });
      });
    },
    getData() {
      const data = {
        title: "Premium",
      };
      this.getMagus(data).then((res) => {
        this.items = res.data[0].info;
      });
    },
    openDialog() {
      if (this.period == "Yearly")
        this.subscription_amount = this.data[0].amount_year;
      else this.subscription_amount = this.data[0].amount;
    },
    getPeriod(data) {
      this.period = data;
    },
    getSubscriptionName(subscription) {
      this.subscription_name = subscription.name;
      return subscription.name.toUpperCase();
    },
    getAmount(subscription, period) {
      let price = subscription.amount;
      if (period == "Yearly") price = subscription.amount_year;

      this.subscription_amount = price;
      return price.toLocaleString("en-US");
    },
    closeDialog(data) {
      this.dialog = false;
    },
    next() {
      if (this.end != this.items.length && this.end < this.items.length) {
        this.start = this.end;
        this.end = this.start + 3;
      } else {
        this.start = 0;
        this.end = this.start + 3;
      }
    },
    prev() {
      if (this.start != 0) {
        this.end = this.start;
        this.start = this.end - 3;
      } else {
        this.end = this.items.length;
        this.start = this.end - 3;
      }
    },
  },
};
</script>

<style scoped>
section:nth-of-type(1) > .container1 {
  padding-block: 2em;
}
h1 {
  color: var(--ma-tufts-blue);
  font-family: "Montserrat-Black";
}
h3 {
  margin-top: 2em;
  color: var(--ma-black-olive);
  line-height: 1.35em;
}
h5:nth-of-type(2) {
  font-family: "Montserrat-Regular";
}
.products {
  width: 80%;
  margin: auto;
}
.skip {
  font-family: "Montserrat-Bold";
  color: #427ab3;
  margin-top: 2em;
}
img {
  height: clamp(4.19rem, calc(1.64rem + 5.72vw), 2.75rem);
}
.wrapperss {
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: #c6d7e8;
  background-image: url("~@/assets/images/main/price/1.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: right;
}
.wrappers3 {
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: #c6d7e8;
  background-image: url("~@/assets/images/main/price/2.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: right;
}
.wrappers2,
.wrappers3,
.wrapperss {
  cursor: pointer;
  margin: auto;
  width: 80%;
  padding: 10px;
  @media screen and (min-width: 1000px) {
    width: 35%;
  }
}

@media screen and (min-width: 40rem) {
  section {
    text-align: center;
  }

  section:nth-of-type(1) > .container1 {
    padding-block: 4em;

    @media screen and (min-width: 1000px) {
      background-image: url("~@/assets/images/main/price/4.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: top;
    }
  }
}
</style>
