<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Living with a fear of ghosts can be a constant source of anxiety and fear, but it doesn't have to control your life. This subliminal is designed to help you overcome your fear and live with more peace and freedom."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help you:'
    },
    {
      type: 'list',
      items: [
        "Reducing the intensity and frequency of your fear reactions to ghosts or supernatural entities.",
        "Helping you develop a sense of calm and control in situations that trigger your fear.",
        "Encouraging positive and rational thoughts about ghosts, challenging irrational beliefs.",
        "Supporting your ability to face and confront your fear gradually, at your own pace.",
        "Building resilience and confidence in dealing with your fear, enhancing your overall sense of well-being."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming a fear takes time and effort, but with consistent use of this subliminal, you can gradually shift your mindset and reduce the impact of your fear of ghosts. You deserve to live without constant anxiety and fear, so take the first step towards reclaiming your peace of mind today."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Conquering your fear of ghosts is a brave and empowering journey, and this subliminal is here to support and guide you along the way. Before starting your listening session, consider these helpful tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your fear: Recognize that your fear is valid, but also understand that it can be overcome.",
        "Educate yourself: Learn more about the nature of ghosts and paranormal phenomena to gain a deeper understanding.",
        "Speak your intentions: Verbally state your desire to let go of your fear and invite a sense of peace and calmness into your life.",
        "Practice grounding techniques: Develop grounding practices that help you feel present and safe in the moment.",
        "Open your mind: Approach the subliminal session with an open mind and a willingness to embrace positive change."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to help you release the anxiety and fear surrounding ghosts, allowing you to live a life free from constant worry. Embrace this opportunity for growth, and trust that you have the inner strength to face your fears and create a sense of peace within yourself. Let's embark on this transformative journey together."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Fear of Ghosts Removal subliminal session! By confronting and working on your fear, you've taken an important step towards living a life free from anxiety and constant fear. Now it's time to reflect on your experience and continue your journey towards emotional well-being."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your progress: Take a moment to identify any changes or shifts in your feelings and thoughts regarding ghosts.",
        "Challenge your beliefs: Engage in rational thinking and question the validity of your fear, recognizing that it might not be based on reality.",
        "Practice relaxation techniques: Incorporate deep breathing exercises, meditation, or yoga to manage anxiety.",
        "Seek professional help if needed: Consider speaking with a therapist or counselor who specializes in anxiety or phobias."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming a fear takes time and effort, but you have already shown great courage by facing it head-on. Stay positive, be patient with yourself, and continue working towards a life free from the grip of fear. You have the power to create a future filled with peace and contentment."
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Fear of Ghosts Removal subliminal work?`, 
    answer: `The Fear of Ghosts Removal subliminal works by targeting the subconscious mind to help reframe and release the fear of ghosts. It uses positive affirmations and soothing background sounds to promote a sense of safety and calmness. Through regular listening, the subliminal aims to gradually shift your perception and mindset towards ghosts, helping you overcome your fear and live without constant anxiety.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Fear of Ghosts Removal subliminal?`, 
    answer: `The time it takes to see results can vary from person to person. Some individuals may notice positive changes in their fear response after only a few listens, while others may require more time and consistent listening to fully reprogram their subconscious mind. We recommend listening to the subliminal daily and consistently for best results.`,
    open: false 
  },
  { 
    question: `Can the Fear of Ghosts Removal subliminal completely eliminate my fear?`, 
    answer: `The Fear of Ghosts Removal subliminal is designed to help you overcome your fear of ghosts and live without constant anxiety. However, individual results may vary, and the subliminal may not completely eliminate your fear. It aims to shift your mindset and reduce the intensity of your fear response, empowering you to face and manage your fear more effectively.`,
    open: false 
  },
  { 
    question: `Can the Fear of Ghosts Removal subliminal be used alongside therapy or other treatments?`, 
    answer: `Yes, the Fear of Ghosts Removal subliminal can be used alongside therapy or other treatments. It can serve as a supplement to existing therapy or self-help practices, helping to reinforce positive changes and support your overall progress. If you are currently receiving therapy or other treatments, we recommend discussing the use of the subliminal with your healthcare provider for personalized guidance.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Fear of Ghosts Removal subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your fear or mental health, please consult with a qualified healthcare professional. The subliminal is intended to supplement other therapeutic interventions, not replace them.`,
    open: false 
  },
  { 
    question: `Will the Fear of Ghosts Removal subliminal make me fearless in all situations?`, 
    answer: `The Fear of Ghosts Removal subliminal aims to help you overcome your fear of ghosts, but it may not eliminate fear entirely in all situations. Fear is a natural response that can serve a protective purpose. The subliminal is designed to help you manage your fear more effectively, enabling you to live without constant anxiety and fear specifically related to ghosts.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>