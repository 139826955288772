<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "The fear of inadequacy can hold us back from reaching our true potential. This subliminal is designed to help you overcome this fear and embrace your worth, boosting your confidence and belief in your abilities."
    },
    {
      type: 'heading',
      text: 'Key benefits of using this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Letting go of self-doubt and negative beliefs about your capabilities.",
        "Embracing a positive self-image and recognizing your strengths and talents.",
        "Developing a resilient mindset that can handle challenges and setbacks.",
        "Increasing self-confidence and trusting in your abilities to achieve success.",
        "Stepping out of your comfort zone and pursuing your goals with conviction."
      ]
    },
    {
      type: 'paragraph',
      text: "With consistent use, this subliminal can help rewire your subconscious mind, allowing you to let go of feelings of inadequacy and step into your true potential. Over time, you may find yourself feeling more confident, capable, and empowered to pursue your dreams. Remember, change takes time and effort, but you have the power to overcome your fears and embrace your worth."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Facing and overcoming the fear of inadequacy can be a transformative experience, empowering you to embrace your true potential. This subliminal is designed to support you on this journey by reprogramming your subconscious mind. Here are some suggestions to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your fear: Recognize that fear of inadequacy is a common emotion and it does not define your worth.",
        "Set your intentions: Focus on specific areas where you want to build confidence and overcome self-doubt.",
        "Affirm your worth: Write down positive affirmations about your abilities, talents, and worthiness.",
        "Visualize success: Imagine yourself confidently embracing challenges and achieving your goals without the fear of inadequacy holding you back."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal, allow the affirmations to sink deeply into your subconscious mind, replacing self-doubt with self-assurance. Embrace the potential for growth, step out of your comfort zone, and trust in your abilities. The journey towards overcoming fear of inadequacy and embracing your true potential starts now."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Fear of Inadequacy Removal subliminal! By taking the brave step to confront and overcome your fear, you are on your way to embracing your true potential and feeling confident in your abilities and worth. It's time to reflect on your journey and continue your transformation."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Challenge negative thoughts: Practice replacing self-doubt with positive affirmations and empowering beliefs.",
        "Celebrate your accomplishments: Recognize and appreciate your achievements, no matter how small.",
        "Embrace self-care: Prioritize activities that nurture your physical, mental, and emotional well-being.",
        "Set realistic goals: Break down your aspirations into manageable steps and celebrate each milestone."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming the fear of inadequacy is an ongoing journey. Be kind to yourself and embrace your progress. Believe in your abilities and worth, and know that you have the power to achieve greatness. Keep going and shine bright!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Fear of Inadequacy Removal subliminal help me?`, 
    answer: `The Fear of Inadequacy Removal subliminal is designed to help you overcome the fear of inadequacy and boost your self-confidence. By listening to the subliminal messages consistently, it can help reprogram your subconscious mind to release limiting beliefs and replace them with empowering thoughts and beliefs about your abilities and worth. Over time, you may notice an increase in self-esteem, assertiveness, and a greater acceptance of yourself as you embrace your true potential.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Fear of Inadequacy Removal subliminal?`, 
    answer: `Results can vary from person to person, as everyone's journey is unique. Some individuals may start to notice positive changes in their mindset and confidence levels within a few weeks of consistent listening, while others may require more time. It is important to be patient and continue listening to the subliminal to allow the affirmations to work on a deeper level and create lasting changes.`,
    open: false 
  },
  { 
    question: `Can I listen to the Fear of Inadequacy Removal subliminal while doing other activities?`, 
    answer: `Ideally, it is recommended to listen to the Fear of Inadequacy Removal subliminal when you can give it your full attention and create a focused and relaxed environment. However, if you are unable to do so, you can still listen to the subliminal while engaging in light activities that do not require your complete concentration, such as reading, doing household chores, or going for a walk.`,
    open: false 
  },
  { 
    question: `Is the Fear of Inadequacy Removal subliminal suitable for everyone?`, 
    answer: `The Fear of Inadequacy Removal subliminal is designed for individuals who struggle with feelings of inadequacy and low self-esteem. It can be beneficial for anyone looking to boost their self-confidence and embrace their true potential. However, if you have any pre-existing mental health conditions or concerns, it is always advisable to consult with a healthcare professional before using subliminal audios or making any significant changes to your mental health routine.`,
    open: false 
  },
  { 
    question: `Can the Fear of Inadequacy Removal subliminal replace therapy or counseling?`, 
    answer: `No, the Fear of Inadequacy Removal subliminal is not intended to replace therapy or counseling. While it can be a helpful tool to complement your personal growth journey, it is not a substitute for professional help. If you are experiencing significant emotional distress or mental health issues, it is important to seek guidance from a qualified therapist or counselor.`,
    open: false 
  },
  { 
    question: `Are there any side effects of listening to the Fear of Inadequacy Removal subliminal?`, 
    answer: `No, there are no known side effects associated with listening to the Fear of Inadequacy Removal subliminal. However, if you experience any discomfort or negative emotions while listening, it is recommended to discontinue use and consult with a mental health professional. Remember to prioritize your emotional well-being and make any necessary adjustments to your self-improvement routine as needed.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>