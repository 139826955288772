<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Intentional living is all about taking charge of your life and making each moment meaningful. This subliminal is designed to help you embrace intentional living and create a fulfilling life with purpose."
    },
    {
      type: 'heading',
      text: 'Why should you consider this subliminal?'
    },
    {
      type: 'list',
      items: [
        "If you feel like you're going through the motions and want to infuse your life with purpose.",
        "If you want to make the most of each day and create meaningful experiences.",
        "If you're looking to align your actions and decisions with your values and goals.",
        "If you desire to live a more mindful, present, and fulfilling life.",
        "If you're ready to take control of your life and design the future you desire."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to adopt intentional living habits. You may find yourself making choices that align with your values, setting meaningful goals, and experiencing a deeper sense of fulfillment in your daily life. Remember, intentional living is a journey, and with consistent practice, you can create the life you've always wanted."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Living an intentional life allows you to prioritize what truly matters and make the most out of every moment. This subliminal is designed to guide you in cultivating a purpose-driven life. Before you start listening, here are some tips to help you set the stage for your intentional living journey:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your values and passions: Identify what brings you joy and fulfillment, and align your actions with these core aspects of your being.",
        "Set clear goals: Define what you want to achieve and break it down into actionable steps.",
        "Eliminate distractions: Create an environment that minimizes distractions and allows you to focus on the present moment.",
        "Practice mindfulness: Cultivate a state of awareness and presence by engaging in mindful activities such as meditation or deep breathing exercises.",
        "Visualize your ideal life: Imagine yourself living with intention and purpose, experiencing the fulfillment that comes with making every moment count."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, it will help align your subconscious mind with your intentions, making intentional living a natural and effortless part of your daily life. Embrace this opportunity to create a fulfilling life and make every moment matter. Let's embark on this transformative journey together."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Intentional Living - Making Every Moment Matters subliminal session! By prioritizing intentional living, you've taken an important step towards creating a fulfilling and purposeful life. Now, it's time to reflect on your experience and continue your journey towards living intentionally."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your values: Consider what truly matters to you and align your choices with your values.",
        "Practice mindfulness: Stay present in the moment and savor the experiences you encounter each day.",
        "Set meaningful goals: Identify what you want to achieve and take steps to make those goals a reality.",
        "Embrace gratitude: Cultivate a mindset of gratitude, appreciating the small joys and blessings in your life."
      ]
    },
    {
      type: 'paragraph',
      text: "Living intentionally is an ongoing practice that requires conscious effort. Embrace each day as an opportunity to make every moment count and create a life aligned with your deepest desires. Remember, you have the power to shape your own journey and live a meaningful and intentional life."
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What does it mean to live intentionally?`, 
    answer: `Living intentionally means being mindful and purposeful in how you spend your time and make choices. It involves aligning your actions and decisions with your values, goals, and priorities, and being fully present in the moment. Intentional living allows you to create a life that is meaningful, fulfilling, and aligned with your true desires.`,
    open: false
  },
  { 
    question: `How can I start living intentionally?`, 
    answer: `To start living intentionally, take time to reflect on your values, goals, and priorities. Identify what truly matters to you and what brings you joy and fulfillment. Set clear intentions and create a plan of action to align your daily activities with your values. Practice mindfulness and being fully present in each moment. Regularly evaluate and adjust your actions to ensure they align with your intentions and goals.`,
    open: false 
  },
  { 
    question: `What are the benefits of intentional living?`, 
    answer: `Intentional living offers numerous benefits, including a greater sense of purpose and fulfillment, increased self-awareness, improved decision-making, reduced stress and overwhelm, stronger relationships, and a more positive mindset. Living intentionally allows you to make the most of every moment, focus on what truly matters, and create a life that brings you joy and satisfaction.`,
    open: false 
  },
  { 
    question: `How can intentional living improve my overall well-being?`, 
    answer: `Intentional living can improve your overall well-being by helping you prioritize self-care, set boundaries, and make choices that align with your physical, emotional, and mental health. When you live intentionally, you are more likely to engage in activities that bring you joy, fulfill your needs, and support your well-being. It allows you to cultivate a healthier and more balanced lifestyle.`,
    open: false 
  },
  { 
    question: `Can intentional living help with goal-setting and achieving success?`, 
    answer: `Yes, intentional living is closely tied to goal-setting and achieving success. By living intentionally, you gain clarity on your goals and priorities, and you make choices and take actions that align with them. Intentional living helps you stay focused, motivated, and disciplined, which increases your chances of achieving success in various areas of your life.`,
    open: false 
  },
  { 
    question: `What steps can I take to practice intentional living every day?`, 
    answer: `To practice intentional living every day, start by setting clear intentions for the day and aligning your activities with your priorities. Take regular breaks for self-reflection and mindfulness to ensure you are staying true to your intentions. Practice gratitude and seek out opportunities to engage fully in each moment. Regularly review and adjust your actions to ensure they are in line with your long-term goals and aspirations.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>