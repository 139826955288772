<template>
  <div :class="`container ${overlay ? 'overlay' : ''}`">
    <div>
      <div class="d-flex justify-content-center align-center">
        <div>
          <v-icon size="30" @click="prev()">mdi-chevron-left</v-icon>
        </div>
        <div id="scroll-container">
          <div id="scroll" :class="`d-flex gap-4 pa-4 scroll-${scrollName}`">
            <slot></slot>
          </div>
        </div>
        <div>
          <v-icon size="30" @click="next()">mdi-chevron-right</v-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: () => {
        return null
      }
    },
    overlay: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data: () => {
    return {
      item: null,
      scroll: null
    }
  },
  mounted() {
    this.item = document.getElementsByClassName('item');
    this.scroll = document.querySelector(`#scroll.scroll-${this.scrollName}`);
  },
  computed: {
    scrollName() {
      return this.name.replace(" ", "-").replace("'", "")
    }
  },
  methods: {
    next() {
      this.scroll.scrollLeft += (this.item[0].offsetWidth + 24)
    },
    prev() {
      this.scroll.scrollLeft -= (this.item[0].offsetWidth + 24)
    }
  }
}
</script>

<style scoped>
.container>div {
  margin-inline: 20px;
}

.container.overlay>div {
  margin-inline: -10px;

  & h3 {
    margin-inline: 45px;
  }
}

#scroll-container {
  max-width: 100%;
}

#scroll {
  overflow: hidden;
  scroll-behavior: smooth;
}


h3 {
  text-transform: capitalize;
  margin-bottom: 0px;
  margin-inline: 15px;
}

@media screen and (max-width: 765px) {
  .container.overlay>div {
    margin-inline: 20px;

    & h3 {
      margin-inline: 15px;
    }
  }
}
</style>