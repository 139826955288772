<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Healing from past traumas is a complex and personal journey, but with the help of this guided healing program, you can take important steps towards emotional and mental recovery."
    },
    {
      type: 'heading',
      text: 'Key features of this program include:'
    },
    {
      type: 'list',
      items: [
        "A safe and supportive space to explore and process your emotions and experiences.",
        "Practical tools and techniques to help you cope with triggers and manage symptoms.",
        "Expert guidance and insights to help you navigate through the healing process.",
        "Exercises and activities designed to promote self-reflection and personal growth.",
        "Strategies for cultivating resilience and building a stronger sense of self."
      ]
    },
    {
      type: 'paragraph',
      text: "As you engage with this program, you may gradually experience a sense of release, healing, and empowerment. It's important to remember that healing is not linear, and each person's journey is unique. By committing to this program and giving yourself the time and space to heal, you are taking a powerful step towards reclaiming your emotional well-being."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a journey of trauma healing requires courage and a commitment to self-care. This subliminal is designed to support you as you work towards emotional and mental recovery. Here are some suggestions to help prepare yourself before listening:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a safe space: Find a quiet, comfortable environment where you can listen without distractions.",
        "Set your intentions: Determine what you hope to achieve through this healing process and focus on those goals.",
        "Practice self-compassion: Be gentle with yourself and acknowledge that healing takes time.",
        "Have support systems in place: Consider reaching out to a therapist or support group for additional guidance and support on your healing journey."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal aims to help you reframe and release negative experiences, allowing space for healing and growth. By regularly listening to these affirmations, you may begin to notice shifts in your emotional well-being and an increased ability to cope with past traumas. Remember, you are deserving of healing, and this subliminal is here to support you along the way."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the General Trauma Healing program! This guided healing journey has empowered you to embark on a path of emotional and mental recovery from past traumas. Take a moment to acknowledge your progress and celebrate this important milestone."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice self-care: Engage in activities that promote self-compassion and self-nurturing.",
        "Seek support: Consider reaching out to a therapist or support group to continue your healing journey.",
        "Create healthy boundaries: Identify and set boundaries to protect your emotional well-being.",
        "Embrace forgiveness: Explore forgiveness as a powerful tool for releasing emotional burdens."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, healing from trauma is a unique and personal journey. Be patient with yourself as you navigate the ups and downs. Trust the process, and know that with time and effort, you can experience true emotional and mental recovery. You are strong and resilient!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How long does it take to see results from the General Trauma Healing program?`, 
    answer: `The healing process can vary from person to person and depends on the nature and severity of the trauma. It may take time for you to notice significant changes, and individual results will vary. It is important to be patient and consistent with the healing techniques and exercises provided in the program.`,
    open: false
  },
  { 
    question: `Is the General Trauma Healing program suitable for everyone?`, 
    answer: `The General Trauma Healing program is designed to help individuals heal from past traumas and experience emotional and mental recovery. However, it is essential to consider your individual circumstances and consult with a mental health professional if you have concerns or specific needs relating to trauma healing.`,
    open: false 
  },
  { 
    question: `Can the General Trauma Healing program replace therapy or counseling?`, 
    answer: `The General Trauma Healing program is not a substitute for therapy or counseling. It can be used as a complementary tool to support your healing process, but it is not intended to replace professional help. If you are experiencing significant distress or struggling to cope with trauma, we recommend seeking guidance from a qualified mental health professional.`,
    open: false 
  },
  { 
    question: `What should I do if I find the General Trauma Healing exercises triggering or overwhelming?`, 
    answer: `If you find any of the exercises provided in the General Trauma Healing program triggering or overwhelming, it is important to prioritize your safety and well-being. Take a break from the exercises, practice self-care, and reach out to a mental health professional for support. Trauma healing can be challenging, and having professional guidance can be beneficial.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with the General Trauma Healing program?`, 
    answer: `The General Trauma Healing program is designed to support and facilitate recovery from past traumas. However, it is possible that certain exercises or techniques may bring up difficult emotions or memories. If you find these experiences distressing or overwhelming, it is advisable to seek guidance from a mental health professional who can provide personalized support.`,
    open: false 
  },
  { 
    question: `Can I use the General Trauma Healing program along with other healing modalities?`, 
    answer: `Yes, the General Trauma Healing program can be used alongside other healing modalities if you feel comfortable and it aligns with your healing journey. It is essential to ensure that the different approaches and techniques you use are compatible and supportive of one another. It may be beneficial to consult with mental health professionals or practitioners in the respective modalities to create a comprehensive and personalized healing plan.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>