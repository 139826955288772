<template>
  <div class="sidebar">
    <div class="logo">
      <img
        @click="goToRoute2('/home')"
        class="images"
        src="@/assets/images/logo/1.png"
      />
    </div>
    <div class="card menu">
      <div
        :class="`menu-item d-flex align-items-center gap-2 
        ${isHome ? 'active' : ''}`"
      >
        <img src="@/assets/images/user/footer/home.png" />
        <h6 class="mb-0" @click="goToRoute('overview')">Home</h6>
      </div>
      <div
        :class="`menu-item d-flex align-items-center gap-2 
        ${isSearch ? 'active' : ''}`"
      >
        <img src="@/assets/images/user/footer/search.png" />
        <h6 class="mb-0" @click="goToRoute('search')">Search</h6>
      </div>
    </div>
    <UserPlaylist />
  </div>
</template>

<script>
import UserPlaylist from "@/components/User/UserPlaylist.vue";
export default {
  components: {
    UserPlaylist,
  },
  data: () => {
    return {
      route: null,
    };
  },
  mounted() {
    this.route = this.$route.fullPath;
  },
  computed: {
    isHome() {
      return this.route == "/user/overview";
    },
    isSearch() {
      return this.route == "/user/search";
    },
  },
  watch: {
    "$route.fullPath"(val) {
      this.route = val;
    },
  },
  methods: {
    goToRoute(route) {
      this.route = "/user/" + route;
      this.$router.push("/user/" + route);
    },
    goToRoute2(route) {
      if (this.currentRoute != route) {
        this.$router.push(route).catch((err) => {});
      }
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped>
.sidebar {
  width: 300px;
}

.menu {
  padding: 10px;
  height: 95px;

  margin-bottom: 10px;
  border-width: 0px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
}

.logo {
  margin-block: 10px;
}

.menu-item {
  display: flex;
  gap: 5px;
  align-items: center;

  height: 50px;
  width: 100%;
}

.menu-item > img {
  height: 20px;
}

.menu-item.active > h6 {
  font-size: 1.25rem;
}

.menu-item.active > img {
  height: 28px;
}

.images {
  width: 0%;
  margin-top: -20px;
  margin-bottom: -30px;
  margin-left: -10px;
  @media screen {
    @media (min-width: 990px) {
      width: 120px;
    }
  }
}

@media screen and (max-width: 900px) {
  .sidebar {
    display: none;
  }
}
</style>
