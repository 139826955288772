import request from "@/utils/request";

export const getAudioTypes = (data) => {
  return request.get("/api/audio/type").then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const getAudioTypeById = (data) => {
  return request.get(`/api/audio/type/${data}`).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const getAudioTypeFilters = (data) => {
  return request.post("/api/audio/type/filter", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const addAudioTypes = (data) => {
  return request.post("/api/audio/type", data).then((res) => {
    return res.data;
  });
};

export const updateAudioTypes = (data) => {
  return request.put(`/api/audio/type/${data.id}`, data).then((res) => {
    return res.data;
  });
};

export const updateToggle = (data) => {
  return request.post("/api/audio/type/toggle", data).then((res) => {
    return res.data;
  });
};

export const deleteAudioTypes = (data) => {
  return request.delete(`/api/audio/type/${data.id}`).then((res) => {
    return res.data;
  });
};
