<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Feeling stuck and stagnant in life can be frustrating and demotivating. However, with the help of this subliminal, you can break free from those feelings and create a path towards progress and fulfillment."
    },
    {
      type: 'heading',
      text: 'This subliminal can be particularly beneficial in the following situations:'
    },
    {
      type: 'list',
      items: [
        "If you're struggling to find your purpose or passion in life.",
        "If you feel trapped in a monotonous routine and crave excitement and growth.",
        "If you're facing obstacles or challenges that are hindering your progress.",
        "If you want to develop a positive mindset and believe in your ability to create change.",
        "If you're ready to embark on a new chapter in life and need support in taking the first steps."
      ]
    },
    {
      type: 'paragraph',
      text: "No matter where you currently are in life, this subliminal can help you shift your perspective and overcome the feelings of being stuck. By reprogramming your subconscious mind, you can cultivate a mindset that is open to new possibilities and ready to take action. Remember, progress and fulfillment are within your reach."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Feeling stuck and stagnant in life can be frustrating and discouraging, but this subliminal is designed to help you break free and create positive changes. Before you start listening, here are some strategies to consider:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your goals: Take some time to clarify what areas of your life you feel stuck in and what you hope to achieve.",
        "Identify barriers: Recognize any limiting beliefs or patterns that may be holding you back.",
        "Visualize progress: Imagine yourself moving forward, overcoming obstacles, and achieving your goals.",
        "Embrace a growth mindset: Cultivate a belief that you have the capacity to learn, change, and grow."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal can be a powerful tool for shifting your mindset and breaking free from stagnation. As you listen regularly, you may start to experience a renewed sense of motivation, clarity, and progress in your life. So take a moment to center yourself, set your intentions, and let's embark on a journey of growth and fulfillment."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Getting Unstuck subliminal! You've taken an important step towards breaking free from feelings of stagnation and creating a path towards progress and fulfillment. Now, it's time to reflect on your experience and continue your journey of personal growth."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your current situation: Take some time to think about what areas of your life you feel stuck in and what aspects you would like to change.",
        "Set achievable goals: Break down your larger goals into smaller, manageable steps that you can take to start moving forward.",
        "Seek support and guidance: Consider reaching out to a mentor, therapist, or trusted friend who can provide guidance and help you navigate your way.",
        "Take action: Start by taking one small step towards your goal. Progress is achieved through consistent action."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, getting unstuck is a process that may take time and effort. Be patient with yourself and celebrate every small victory along the way. You have the power to create the life you desire. Keep moving forward and embrace the journey of growth and fulfillment!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can I use the Getting Unstuck subliminal to overcome feelings of being stuck?`, 
    answer: `To overcome feelings of being stuck, listen to the Getting Unstuck subliminal regularly. The subliminal messages are designed to influence your subconscious mind and help you break free from negative thought patterns and limiting beliefs. As you continue to listen, you may notice shifts in your mindset, increased motivation, and new opportunities for growth and progress.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Getting Unstuck subliminal?`, 
    answer: `The time it takes to see results may vary depending on the individual and their unique circumstances. Some people may experience positive changes relatively quickly, while others may require more time and consistent listening. We recommend listening to the subliminal daily and being open to recognizing and seizing opportunities for personal growth that may arise.`,
    open: false 
  },
  { 
    question: `Can the Getting Unstuck subliminal help with specific areas of my life where I feel stuck?`, 
    answer: `Yes, the Getting Unstuck subliminal can help with specific areas of your life where you feel stuck. The subliminal messages are designed to target underlying beliefs and thought patterns that may be holding you back. By rewiring your subconscious mind, the subliminal can help you gain clarity, motivation, and a sense of direction to create progress and fulfillment in all areas of your life.`,
    open: false 
  },
  { 
    question: `Can the Getting Unstuck subliminal replace therapy or professional help?`, 
    answer: `The Getting Unstuck subliminal is designed to complement therapy or professional help, not replace them. It can be used as a supplementary tool to support your personal growth journey. If you are dealing with specific mental health issues or complex challenges, we recommend seeking help from a qualified therapist or professional.`,
    open: false 
  },
  { 
    question: `Is it normal to feel uncomfortable or emotional while listening to the Getting Unstuck subliminal?`, 
    answer: `It is not uncommon to experience a range of emotions while listening to the Getting Unstuck subliminal. Breaking free from old patterns and beliefs can bring up uncomfortable feelings as you work through and release them. Take the time to process your emotions and be gentle with yourself as you navigate this transformative process.`,
    open: false 
  },
  { 
    question: `Can I listen to the Getting Unstuck subliminal while doing other activities?`, 
    answer: `While you can listen to the Getting Unstuck subliminal while doing other activities, it is recommended to find a quiet and comfortable space where you can focus on the affirmations without distractions. This allows your subconscious mind to absorb the positive messages more effectively.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>