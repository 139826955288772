<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're looking to reduce and moderate your smoking habit, this subliminal can be a valuable tool on your journey to better health and overall well-being. By targeting your subconscious mind, it helps shift your mindset and behaviors related to smoking."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can assist you:'
    },
    {
      type: 'list',
      items: [
        "Reducing cravings for cigarettes and the desire to smoke.",
        "Increasing your willpower and self-control to resist the urge to smoke.",
        "Helping you develop healthier coping mechanisms for stress and emotions.",
        "Encouraging a more positive and supportive mindset towards quitting or cutting back smoking.",
        "Promoting a healthier lifestyle by gradually reducing the frequency and quantity of cigarettes."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, quitting or moderating smoking is a journey that requires commitment and effort. This subliminal can assist you in reprogramming your subconscious mind to support your goals, but it's important to also seek additional resources and support. With determination and this subliminal, you can make significant strides towards a healthier, smoke-free life."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Taking steps towards reducing and moderating your smoking habit can greatly benefit your health and overall well-being. This subliminal is designed to help you on this journey, providing support and reinforcement. Before you start listening, consider these strategies to optimize your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Define what reducing and moderating your smoking habit looks like for you. Be specific and realistic.",
        "Find motivation: Understand your personal reasons for wanting to reduce and moderate your smoking habit. Keep them in mind as you listen to the subliminal.",
        "Replace smoking triggers: Identify situations or triggers that usually prompt you to smoke, and find healthier alternatives or coping mechanisms to replace them.",
        "Practice relaxation techniques: Develop techniques that help you manage cravings and reduce stress during the process.",
        "Remember your intention: Keep in mind the intention to reduce and moderate your smoking habit throughout the entire listening session. Let the subliminal affirmations align with your desire for positive change."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal and staying committed to your goals, you can gradually reduce and moderate your smoking habit. It may take time and effort, but remember that change is possible. Embrace this opportunity to prioritize your health and well-being, and let's begin the journey towards a healthier lifestyle."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Smoking Moderation subliminal! Taking this step towards reducing and moderating your smoking habit is a powerful commitment to improving your health and overall well-being. It's important to reflect on your journey and continue your progress towards a smoke-free lifestyle."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Identify triggers: Take note of situations, emotions, or habits that make you want to smoke.",
        "Create a plan: Develop strategies to overcome cravings and substitute smoking with healthier alternatives.",
        "Seek support: Consider joining a support group or reaching out to friends and family for encouragement.",
        "Celebrate milestones: Acknowledge and reward yourself for every achievement, no matter how small."
      ]
    },
    {
      type: 'paragraph',
      text: "Reducing and moderating your smoking habit is a journey that requires determination and resilience. Remember that setbacks may happen, but what matters is your commitment to change. Stay focused on your goals, seek support when needed, and celebrate your progress. You have the power to improve your health and well-being. Keep going!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Smoking Moderation subliminal work?`, 
    answer: `The Smoking Moderation subliminal works by sending positive affirmations directly to your subconscious mind, helping to reprogram your thoughts and behaviors related to smoking. The subliminal messages are designed to promote awareness, self-control, and a healthier mindset towards smoking. By consistently listening to the subliminal, you can gradually reduce and moderate your smoking habit.`,
    open: false
  },
  { 
    question: `How long does it take to see results with the Smoking Moderation subliminal?`, 
    answer: `The time to see results can vary from person to person. Some individuals may notice positive changes in their smoking habits and mindset after a few weeks of consistent listening, while others may require more time. It's important to remember that everyone's journey is different, and it's essential to stay committed and patient with your progress.`,
    open: false 
  },
  { 
    question: `Can the Smoking Moderation subliminal help me quit smoking completely?`, 
    answer: `While the Smoking Moderation subliminal is designed to reduce and moderate your smoking habit, it may not be sufficient for individuals who wish to quit smoking altogether. If your goal is to quit smoking completely, it is recommended to seek additional support, such as counseling, nicotine replacement therapy, or other smoking cessation methods.`,
    open: false 
  },
  { 
    question: `Are there any side effects of listening to the Smoking Moderation subliminal?`, 
    answer: `No, there are no known side effects associated with listening to the Smoking Moderation subliminal. However, it's important to note that everyone's experience may vary. If you have any concerns about your health or smoking habit, it's advisable to consult with a healthcare professional.`,
    open: false 
  },
  { 
    question: `Can the Smoking Moderation subliminal be used alongside other smoking cessation methods?`, 
    answer: `Yes, the Smoking Moderation subliminal can be used alongside other smoking cessation methods. It can complement your efforts to quit smoking by reprogramming your mindset and promoting healthier habits. However, it's recommended to consult with a healthcare professional or smoking cessation specialist for personalized advice and guidance.`,
    open: false 
  },
  { 
    question: `Is the Smoking Moderation subliminal suitable for everyone?`, 
    answer: `The Smoking Moderation subliminal is generally suitable for most individuals who want to reduce and moderate their smoking habit. However, it's important to consider any individual sensitivities or specific health conditions. If you have any concerns, it's best to consult with a healthcare professional before integrating the subliminal into your routine.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>