<template>
  <section style="background: #1c8edf">
    <div class="container">
      <div>
        <div>
          <div class="d-flex social-media">
            <img
              class="magus-image"
              src="@/assets/images/main/magus.svg"
              alt=""
            />
          </div>
          <div class="d-flex social-media mb-5">
            <v-icon style="color: white" size="35">mdi-facebook</v-icon>
            <v-icon style="color: white" size="35">mdi-instagram</v-icon>
            <v-icon style="color: white" size="35">mdi-twitter</v-icon>
          </div>
        </div>
        <div>
          <v-row class="mb-0">
            <v-col
              cols="6"
              sm="6"
              md="6"
              lg="6"
              style="color: white"
              class="text-left"
              v-for="(item, index) in items"
              :key="index"
            >
              <span v-for="element in item">
                <p
                  v-if="element.type != 'none'"
                  @click="getAction(element)"
                  class="hove"
                >
                  {{ element.text }}
                </p>
                <p v-else class="unhover">{{ element.text }}</p>
              </span>
            </v-col>
          </v-row>
          <v-divider style="background: white"></v-divider>
          <v-row class="mb-0">
            <v-col
              cols="6"
              sm="4"
              md="4"
              lg="4"
              style="color: white"
              class="text-left"
              v-for="(item, index) in items2"
              :key="index"
            >
              <div>
                <div
                  v-for="(element, index) in item"
                  :key="index"
                  @click="getAction(element)"
                >
                  <span>
                    <p
                      v-if="element.type != 'none'"
                      @click="getAction(element)"
                      class="hoves"
                    >
                      {{ element.text }}
                    </p>
                    <p v-else class="unhover">
                      {{ new Date().getFullYear() }}. Copyright | All Right
                      Reserved
                    </p>
                  </span>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Dialog from "@/components/Dialog";
import Guidelines from "@/views/Main/Guidelines";
export default {
  components: {
    Dialog,
    Guidelines,
  },
  data: () => ({
    title: "",
    type: "",
    value: "",
    dialog: false,
    items: [
      [
        { text: "Platform", type: "none", value: "platform" },
        { text: "About Magus", type: "page", value: "about" },
      ],
      [
        { text: "Support", type: "page", value: "contact" },
        { text: "Frequently Asked Questions", type: "page", value: "faq" },
        { text: "Subliminal General Guide", type: "page", value: "guide" },
      ],
    ],
    items2: [
      [{ text: "Platform", type: "none", value: "platform" }],
      [{ text: "Terms and Conditions", type: "page", value: "termscondition" }],
      [{ text: "Privacy and Policy", type: "page", value: "policy" }],
    ],
    items3: [
      [{ text: "Terms and Conditions", type: "page", value: "termscondition" }],
    ],
  }),
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    getAction(element) {
      if (element.type == "dialog") {
        this.title = element.title;
        this.type = element.type;
        this.value = element.value;
        this.dialog = true;
      }

      if (element.type == "page") {
        let route = "";
        switch (element.value) {
          case "about":
            route = "/about/magus";
            break;
          case "contact":
            route = "/support";
            break;
          case "faq":
            route = "/faqs";
            break;
          case "termscondition":
            route = "/termsconditions";
            break;
          case "guide":
            route = "/guide";
            break;
          case "policy":
            route = "/privacypolicy";
            break;
        }

        window.scrollTo(0, 0);
        this.$router.push(route);
      }
    },
  },
};
</script>

<style scoped>
section > .container {
  padding-block: 2em;
  & img {
    width: clamp(8.75rem, calc(0rem + 43.75vw), 17.5rem);
    margin-block: 0.5em;
  }
  & h1 {
    color: var(--ma-black-olive);
    line-height: 1.35em;
  }
  & h3 {
    color: var(--ma-tufts-blue);
  }
}
.magus-image {
  width: 30%;
}
.social-media {
  width: 100%;
  align-items: center;
  justify-content: center;
}

.hove:hover,
.hoves:hover {
  color: black;
  text-decoration: underline;
  cursor: pointer;
}

.unhover {
  color: white;
  cursor: auto;
}

@media screen and (min-width: 80rem) {
  section {
    text-align: left;
  }

  section > .container {
    & img {
      width: clamp(17.5rem, calc(5rem + 36.75vw), 55rem);
    }

    @media screen and (min-width: 1200px) {
      height: auto;
      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 100px;
      }
      & > div > div:nth-of-type(1) {
        width: 20%;
        align-items: center;
        justify-content: center;
      }
      & > div > div:nth-of-type(2) {
        width: 80%;
      }
      .hoves {
        margin-left: 5em;
      }
    }

    @media screen and (max-width: 1200px) {
      height: auto;
      text-align: center;
      .hoves {
        margin-left: 0em;
      }
    }
  }
}
</style>
