<template>
<section>
  <div class="container">
    <div>
      <h1>
        Redefined Listening Experience
      </h1>
      <h5 class="mb-2">Easy-to-Use Interface. Robust and Powerful Features.</h5>
      <h5 class="black-text">
        Immersive listening experience that will help you elevate your life.
      </h5>
    </div>
    <div class="col-10 col-lg-8 mx-auto" v-if="show_interface">
      <div class="row my-4 justify-content-center">
        <template v-for="item, index in items">
          <div class="feature-card col-6 col-sm-6 col-md-4 col-lg-4" :key="index">
            <div class="d-flex flex-column align-items-center">
              <div>
                <img class="feature-image" :src="require(`@/assets/images/main/home/4/${item.icon}.png`)" alt="">
                <h6>{{ item.title }}</h6>
                <p class="description" v-html="item.description"></p>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="mx-auto" v-else>
      <img class="redefined-image" :src="require(`@/assets/images/main/home/4/9.png`)" alt="">
    </div>
    <div>
      <button class="button btn btn-primary btn-sm" @click="show_interface = !show_interface">
        {{ showInterfaceButton }}
      </button>
    </div>
  </div>
</section>    
</template>

<script>
export default {
  data: () => {
    return {
      show_interface: false,
      items: [
        {
          title: 'Anytime. Anywhere.', 
          description: 'MAGUS offers ad free forever <br> '+
          'feature so that you can improve <br>'+
          'your life without distractions.', 
          icon: '1'
        },
        {
          title: 'Ad-Free. Forever', 
          description: 'MAGUS offers ad free forever <br> '+
          'feature so that you can improve <br>'+
          'your life without distractions.', 
          icon: '2'
        },
        {
          title: 'Diverse Topics.', 
          description: 'MAGUS offers ad free forever <br> '+
          'feature so that you can improve <br>'+
          'your life without distractions.', 
          icon: '3'
        },
        {
          title: 'Multiple Controls', 
          description: 'MAGUS offers ad free forever <br> '+
          'feature so that you can improve <br>'+
          'your life without distractions.', 
          icon: '4'
        },
        {
          title: 'Specialized or Personal', 
          description: 'MAGUS offers ad free forever <br> '+
          'feature so that you can improve <br>'+
          'your life without distractions.', 
          icon: '5'
        },
        {
          title: 'Subliminal Masterclass.', 
          description: 'MAGUS offers ad free forever <br> '+
          'feature so that you can improve <br>'+
          'your life without distractions.', 
          icon: '6'
        },
        {
          title: 'Detailed Instructions.', 
          description: 'MAGUS offers ad free forever <br> '+
          'feature so that you can improve <br>'+
          'your life without distractions.', 
          icon: '7'
        },
        {
          title: 'Detailed Instructions.', 
          description: 'MAGUS offers ad free forever <br> '+
          'feature so that you can improve <br>'+
          'your life without distractions.', 
          icon: '8'
        },
      ] 
    }
  },
  computed: {
    showInterfaceButton() {
      return this.show_interface ? "Back to Interface" : "View All Features";
    }
  }
}
</script>

<style scoped>
  h1, p, .black-text {
    line-height: 1.35em;
    color: var(--ma-black-olive);
  }
  h5 {
    line-height: 1.35em;
    color: var(--ma-tufts-blue);
  }
  .black-text {
    padding: 0 2em;
    font-family: "Montserrat-Regular";
  }

  .redefined-image {
    width: 69%;
    margin-left: auto;
    margin-right: auto; 
  }
  .feature-image {
    width: 1.7em;
  }

  .description {
    font-family: "Montserrat-Regular";
  }

  .regular-text {
    line-height: 1.35em;
    padding: 0 2em;
  }

  .feature-card {
    padding: 0 10px;
    text-align: left;
  }

  @media screen and (min-width: 40rem) {
    @media screen and (min-width: 1400px) {
      height: 95vh;
    }

    @media screen and (max-width: 1399px) {
      height: auto;
    }

    section:nth-of-type(4)>.container {
      text-align: center;
      & p:nth-of-type(2) {
        font-family: "Montserrat-Regular";
      }
    }
  }
</style>
