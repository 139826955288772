<template>
  <div>
    <!-- <p class="mb-1">{{ title }}</p> -->
    <!-- <v-autocomplete solo dense hide-details clearable :multiple="isMultiple" v-model="item" :items="items"
      :item-text="text" :item-value="value" @change="getSelectItem" :label="title" />-->

    <v-autocomplete v-model="item" :items="items" :multiple="isMultiple" clearable solo dense color="blue-grey lighten-2"
        :placeholder="title" hide-details class="mb-3" :item-text="text" :item-value="value" @change="getSelectItem"/>
  </div>
</template>

<script>
import { orders, statuses, influencers, statusMoods, states } from '@/utils/data'

export default {
  props: {
    data: {},
    type: {
      type: String
    },
    text: {
      type: String
    },
    value: {
      type: String
    },
    title: {
      type: String,
      default: () => {
        return null
      }
    },
    index: {
      type: Number,
      default: () => {
        return 0
      }
    },
    custom: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data: () => {
    return {
      orders,
      states,
      statuses,
      statusMoods,
      influencers,

      item: null
    }
  },
  computed: {
    items() {
      if (this.type == 'user') return this.users
      if (this.type == 'mood') return this.moods
      if (this.type == 'order') return this.orders
      if (this.type == 'track') return this.tracks
      if (this.type == 'status') return this.statuses
      if (this.type == 'playlist') return this.playlists
      if (this.type == 'category') return this.categories
      if (this.type == 'audiotype') return this.audiotypes
      if (this.type == 'statemood') return this.states.moods
      if (this.type == 'stateuser') return this.states.users
      if (this.type == 'influencer') return this.influencers
      if (this.type == 'subliminal') return this.subliminals
      if (this.type == 'subscription') return this.subscriptions
      return this.custom.length > 0 ? this.custom : []
    },
    users() {
      return this.$store.state.user.data;
    },
    moods() {
      return this.$store.state.mood.data;
    },
    tracks() {
      return this.$store.state.track.data;
    },
    audiotypes() {
      return this.$store.state.audio.types;
    },
    categories() {
      return this.$store.state.category.data;
    },

    subliminals() {
      return this.$store.state.subliminal.data;
    },
    subscriptions() {
      return this.$store.state.subscription.data;
    },
    playlists() {
      return this.$store.state.playlist.playlist.data;
    },
    isMultiple() {
      return Array.isArray(this.data)
    },
  },
  created() {
    this.item = this.data
  },
  methods: {
    getSelectItem() {
      const data = {
        type: this.type,
        data: this.item,
        index: this.index
      }

      this.$emit("getSelectItem", data)
    }
  }
}
</script>