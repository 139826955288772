<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you struggle with productivity and achieving your goals, this subliminal can help you develop the habit of getting things done efficiently and effectively. By targeting your subconscious mind, it can support you in increasing your productivity and achieving your goals with ease."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Increasing your motivation and drive to tackle tasks and projects.",
        "Enhancing your focus and concentration, allowing you to work more effectively.",
        "Boosting your time management skills, helping you prioritize and utilize your time efficiently.",
        "Promoting a proactive mindset, encouraging you to take action and make progress.",
        "Reducing procrastination tendencies, allowing you to overcome obstacles and stay on track."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can rewire your subconscious mind to support your productivity and goal achievement. As you develop the habit of getting things done, you may notice increased success, improved efficiency, and a greater sense of accomplishment. Remember, consistent practice and commitment are key to making lasting changes."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Developing the habit of getting things done efficiently and effectively is essential for increasing productivity and achieving your goals. This subliminal is designed to help you cultivate this habit and make it a natural part of your life. Before you start listening, here are some tips to set yourself up for success:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your goals: Clearly define what you want to accomplish and break it down into actionable steps.",
        "Create a schedule: Set aside specific times for tasks and prioritize them according to their importance.",
        "Eliminate distractions: Minimize distractions that can derail your focus and productivity.",
        "Visualize success: Imagine yourself completing tasks effortlessly and feeling a sense of accomplishment.",
        "Set clear intentions: Have a clear mindset and purpose as you listen to the subliminal affirmations."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can rewire your mind to develop the habit of getting things done efficiently and effectively. Embrace the power of your subconscious mind and envision yourself effortlessly achieving your goals. Take a moment to set your intentions, tune in, and let's start transforming your productivity and success!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Getting Things Done Habit Development subliminal! By investing in developing the habit of getting things done efficiently and effectively, you're taking a proactive step towards increasing your productivity and achieving your goals with ease. It's important to reflect on your experience after listening and continue to nurture this new habit."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your progress: Take time to journal about the changes you have noticed in your productivity and goal achievement.",
        "Set daily goals: Start each day by setting specific and achievable goals to keep yourself focused and motivated.",
        "Break tasks into smaller steps: Divide larger tasks into smaller, more manageable steps to make them less overwhelming.",
        "Eliminate distractions: Identify and minimize distractions that hinder your productivity, such as notifications or unnecessary clutter."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, developing a habit takes time, consistency, and effort. Each day presents an opportunity to sharpen your productivity skills and achieve your goals with ease. Stay committed, stay focused, and embrace the power of getting things done. You've got this!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Getting Things Done Habit Development subliminal help me become more productive?`, 
    answer: `The Getting Things Done Habit Development subliminal is designed to help reprogram your subconscious mind to adopt efficient and effective habits for getting things done. By consistently listening to the subliminal, you can enhance your focus, motivation, and discipline, allowing you to increase your productivity and achieve your goals with greater ease.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Getting Things Done Habit Development subliminal?`, 
    answer: `Results may vary from person to person, but with consistent and dedicated listening, you may start noticing positive changes in your productivity and habits within a few weeks. It is important to remain patient and allow the subliminal messages to work on a subconscious level, gradually influencing your behavior and mindset towards getting things done efficiently.`,
    open: false 
  },
  { 
    question: `Can the Getting Things Done Habit Development subliminal help with procrastination?`, 
    answer: `Yes, the subliminal is designed to help you overcome procrastination by rewiring your subconscious mind and cultivating a proactive mindset. With consistent listening, you can begin to develop healthy habits and behaviors that counteract procrastination, allowing you to take action and complete tasks efficiently.`,
    open: false 
  },
  { 
    question: `How can I maximize the effectiveness of the Getting Things Done Habit Development subliminal?`, 
    answer: `To maximize the effectiveness of the subliminal, it is recommended to listen to it daily, preferably in a relaxed and focused state. Setting clear goals and creating a to-do list can also help you stay organized and prioritize tasks. Combining the subliminal with practical time management strategies and adopting a growth mindset can further support your journey towards becoming more productive and achieving your goals.`,
    open: false 
  },
  { 
    question: `Can the Getting Things Done Habit Development subliminal replace effective time management techniques?`, 
    answer: `The subliminal is designed to complement and enhance effective time management techniques, not replace them. While it can help reprogram your subconscious mind towards more productive habits, it is important to combine it with practical strategies such as prioritizing tasks, setting deadlines, and managing your time effectively.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Getting Things Done Habit Development subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your productivity or mental well-being, please consult with a healthcare professional or mental health provider.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>