<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Premature ejaculation can be a distressing and frustrating experience for many men, but it doesn't have to define your sex life. This subliminal is designed to help you overcome and manage premature ejaculation, so you can feel more confident and satisfied in your sexual experiences."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help you:"
    },
    {
      type: 'list',
      items: [
        "Reprogramming your subconscious mind to reduce anxiety and performance pressure.",
        "Increasing your mental and physical stamina to prolong sexual endurance.",
        "Enhancing your ability to relax and stay present during sexual activities.",
        "Improving your overall sexual confidence and self-esteem.",
        "Helping you develop healthy and satisfying sexual relationships."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, it can help shift your mindset and build the necessary skills and confidence to overcome premature ejaculation. Remember, everyone's journey is unique, and it may take time to see significant improvements. Stick with it and trust the process - you deserve a fulfilling and satisfying sex life."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Dealing with premature ejaculation can be challenging and may affect your sexual confidence and satisfaction. This subliminal aims to help you overcome and manage premature ejaculation. Before starting, consider the following tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Educate yourself: Learn about the causes and potential techniques to manage premature ejaculation.",
        "Identify triggers: Understand the factors that contribute to premature ejaculation in your case.",
        "Focus on relaxation: Practice relaxation techniques, such as deep breathing or meditation, to reduce anxiety and stress that may contribute to premature ejaculation.",
        "Visualize success: Spend a few moments visualizing yourself engaging in satisfying and lasting sexual experiences.",
        "Hold positive thoughts: Keep positive affirmations in mind while listening to the subliminal messages."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal is designed to help you reframe your mindset and develop strategies for overcoming premature ejaculation. By listening regularly, you may experience increased control, confidence, and satisfaction in your sexual encounters. Take a moment to prepare yourself mentally and emotionally, and let's embark on this journey towards sexual well-being and fulfillment."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Stop Premature Ejaculation subliminal session! By taking this step to overcome and manage premature ejaculation, you've made a positive impact on your sexual confidence and satisfaction. Now, it's important to reflect on your progress and continue your journey towards a more satisfying sex life."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice mindfulness: Pay attention to your body and sensations during intimate moments.",
        "Explore techniques: Learn about and experiment with techniques that can help delay ejaculation.",
        "Communicate with your partner: Openly discuss your concerns and desires to foster a supportive and understanding environment.",
        "Professional help: Consider seeking guidance from healthcare professionals or therapists specializing in sexual health."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, managing premature ejaculation is a process that requires patience and continuous effort. Stay committed to your goals, explore different strategies, and seek support if needed. With time and practice, you can enhance your sexual confidence and experience a more satisfying intimate connection."
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What is premature ejaculation?`, 
    answer: `Premature ejaculation is a sexual dysfunction characterized by the inability to control or delay ejaculation during sexual activity. It can lead to feelings of frustration, embarrassment, and dissatisfaction for both partners involved.`,
    open: false
  },
  { 
    question: `How can the Stop Premature Ejaculation subliminal help?`, 
    answer: `The Stop Premature Ejaculation subliminal is designed to help reprogram your subconscious mind, promoting confidence, control, and relaxation during sexual encounters. By aligning your thoughts and beliefs with more empowered and positive sexual experiences, the subliminal can assist in overcoming premature ejaculation and improving sexual satisfaction.`,
    open: false 
  },
  { 
    question: `How long does it take to see results from the Stop Premature Ejaculation subliminal?`, 
    answer: `Results may vary depending on the individual and their unique circumstances. Some people may experience improvements in their sexual performance and confidence sooner, while others may require more time and consistent listening to fully reprogram their mindset. We recommend listening to the subliminal daily, in addition to practicing other techniques and strategies for managing premature ejaculation, to maximize results.`,
    open: false 
  },
  { 
    question: `Can the Stop Premature Ejaculation subliminal replace therapy or professional help?`, 
    answer: `The Stop Premature Ejaculation subliminal is not a substitute for therapy or professional help for underlying psychological or physical factors contributing to premature ejaculation. It is designed to complement other approaches and provide additional support in managing the condition. If premature ejaculation is causing significant distress or impacting your relationship, we recommend consulting with a healthcare professional or sex therapist for personalized guidance and treatment options.`,
    open: false 
  },
  { 
    question: `Are there any side effects of listening to the Stop Premature Ejaculation subliminal?`, 
    answer: `No, there are no known side effects associated with listening to the Stop Premature Ejaculation subliminal. However, individual experiences may vary, and if you have any concerns, it is always recommended to consult with a healthcare professional.`,
    open: false 
  },
  { 
    question: `Can the Stop Premature Ejaculation subliminal be used by anyone?`, 
    answer: `The Stop Premature Ejaculation subliminal can be used by individuals who are seeking to overcome and manage premature ejaculation. It is important to note that subliminals work by influencing the subconscious mind and may not be effective for everyone. If you have any underlying health conditions or concerns, please consult with a healthcare professional before using the subliminal.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>