<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "This subliminal is designed to help you unlock and enhance your drawing talent and skills. Whether you're a beginner or someone looking to take their artistic abilities to the next level, this subliminal can support you on your artistic journey."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help you:'
    },
    {
      type: 'list',
      items: [
        "Tap into your creativity and expand your imagination.",
        "Improve your drawing techniques, such as shading, perspective, and proportions.",
        "Boost your confidence as an artist and overcome self-doubt.",
        "Enhance your focus and concentration while sketching or drawing.",
        "Develop a consistent art practice and discipline.",
        "Gain inspiration and motivation to create beautiful and captivating artwork."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your mind to overcome artistic blocks, learn new skills, and develop your unique artistic style. Practice, patience, and dedication are essential, but this subliminal can provide the extra support you need along the way. Start unlocking your drawing talent and creating breathtaking artwork today!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Unlocking your drawing talent and enhancing your skills requires dedication and a focused mindset. This subliminal is designed to help you tap into your artistic potential and create stunning artwork. Before you start listening, consider these tips to optimize your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your artistic goals: Define what you want to achieve with your drawing talent, whether it's improving your technique, exploring different styles, or expressing your creativity.",
        "Create a dedicated workspace: Set up an area where you can focus on your art, free from distractions.",
        "Establish a regular practice routine: Consistency is key. Set aside time each day to work on your drawing skills and make it a priority in your schedule.",
        "Visualize your artistic success: Imagine yourself creating beautiful and captivating artwork, feeling inspired and confident in your abilities.",
        "Stay open-minded and curious: Embrace the opportunity to learn and grow as an artist. Be open to trying new techniques and exploring different mediums."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your daily routine and following these preparation tips, you can unlock your drawing talent and enhance your skills. Remember, every stroke on the canvas is an opportunity for growth and self-expression. So get ready to embark on an artistic journey filled with creativity, inspiration, and personal fulfillment."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Drawing Talent and Skills Enhancement subliminal! By investing time and effort into developing your drawing talent and skills, you're embarking on a creative journey full of possibilities. Reflect on your progress and continue nurturing your artistic abilities."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice regularly: Set aside dedicated time to draw and experiment with different techniques.",
        "Seek feedback: Share your artwork with others and be open to constructive criticism to improve.",
        "Explore different styles: Don't be afraid to step out of your comfort zone and try new approaches to drawing.",
        "Study and learn: Continuously expand your knowledge of drawing and art through books, courses, and tutorials."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, becoming a skilled artist takes time and perseverance. Embrace the challenges and enjoy the process of honing your craft. With dedication and practice, your drawing talent will continue to bloom, allowing you to create stunning and captivating artwork. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `I have no prior drawing experience. Can the Drawing Talent and Skills Enhancement subliminal help me?`, 
    answer: `Yes, the Drawing Talent and Skills Enhancement subliminal can help individuals of all skill levels, including those with no prior drawing experience. The subliminal messages are designed to enhance your natural ability and improve your drawing skills. With consistent listening and practice, you can develop and refine your talent over time.`,
    open: false
  },
  { 
    question: `How often should I listen to the Drawing Talent and Skills Enhancement subliminal?`, 
    answer: `For best results, we recommend listening to the Drawing Talent and Skills Enhancement subliminal daily. Consistency is key in reprogramming your mindset and enhancing your drawing skills. The more you listen, the more your subconscious mind can absorb the positive affirmations and bring about noticeable improvements in your abilities.`,
    open: false 
  },
  { 
    question: `Can the Drawing Talent and Skills Enhancement subliminal help me with specific drawing techniques?`, 
    answer: `Yes, the Drawing Talent and Skills Enhancement subliminal can help you improve in specific drawing techniques. The subliminal messages are designed to enhance your artistic abilities as a whole, which includes developing proficiency in various drawing techniques. Consistent listening and practice will help you better understand and execute different techniques with time and dedication.`,
    open: false 
  },
  { 
    question: `I already have some drawing skills. Can the subliminal still benefit me?`, 
    answer: `Absolutely! Even if you already have some drawing skills, the Drawing Talent and Skills Enhancement subliminal can help you take your abilities to the next level. It can assist you in honing your skills, refining your technique, and expanding your artistic horizons. Consistent listening and practice will help you continue to grow and improve as an artist.`,
    open: false 
  },
  { 
    question: `Are there any side effects of listening to the Drawing Talent and Skills Enhancement subliminal?`, 
    answer: `No, there are no known side effects associated with listening to the Drawing Talent and Skills Enhancement subliminal. It is a safe and natural method to enhance your artistic abilities. However, it is important to note that the subliminal is a tool for personal growth and improvement, and progress will depend on your dedication and practice.`,
    open: false 
  },
  { 
    question: `Can the subliminal make me an overnight drawing prodigy?`, 
    answer: `While the Drawing Talent and Skills Enhancement subliminal can significantly improve your drawing talent and skills, it is not a guarantee of overnight transformation into a drawing prodigy. Developing artistic abilities requires practice, dedication, and consistent effort over time. The subliminal can assist you in this process by enhancing your mindset and motivation towards improvement.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>