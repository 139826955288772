<template>
  <div style="overflow-x: hidden; height: 100vh;" >
    <div class="content mb-5">
      <p class="maintitle">Terms & Conditions</p>
        <div class="terms-class">
          <v-expansion-panels focusable v-model="expansion">
            <v-expansion-panel v-for="(faq, i) in items" :key="i">
              <v-expansion-panel-header @click="click(i)" 
              :style="`background-color: ${ selection == i ? '#1C8EDF' : '' }; color: ${ selection == i ? 'white' : ''};`"
              class="subtitle">{{ faq.title.toUpperCase() }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <p class="mt-3 mb-0 text-left answer" v-html="faq.description">
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
    </div>
  </div>
</template>

<script>
import { getMagus } from '@/api/magusdetails'
import Card from '@/components/Card.vue'

export default {
  components: {
    Card
  },
  data: () => {
    return {
      getMagus,
      title: '',
      description: '',
      expansion: '',
      terms: '',
      items: [],
      selection: null
    }
  },
  created() {
    this.getData()
  },
  methods: {
    click(i) {
      if(this.selection != i){
        this.selection = i;
      }else{
        this.selection = null;
      }
    },
    stringToArray(description) {
      return description.split("~")
    },
    stringToArray2(description) {
      return description.split("**")
    },
    stringToArrays(description) {
      return description.split("##")
    },
    getData() {
      const data = {
        title: 'Terms and Condition',
      }
      this.getMagus(data).then((res) => {
        this.items=(res.data[0].info)
      })
    },
  }
}
</script>
<style scoped>
.content {
  margin-top: 50px;
  margin-bottom: 50px;
  padding-bottom: 50px;
  padding-inline: 30px;
   @media screen {
    @media (min-width: 1000px) {
      margin-bottom: 100px;
      padding-inline: 100px;
    }
  }
}
.terms-class {
  padding-bottom: 50px;
}

p{
  line-height: 1.6em;
}

p.maintitle {
  text-align: left !important;
  color: #4797DE !important;
  font-family: 'Montserrat-Bold' !important;
  
  font-size: 40px !important;
  margin-top: 30px !important;
}
p.title {
  width: 100%;
  text-align: left !important;
  font-family: 'Montserrat-SemiBold' !important;

  font-size: 20px !important;
  padding-top: 20px !important;
}

span.subtitle,
p.subtitle {
  font-family: 'Montserrat-SemiBold' !important;
}

.subtitle {
  font-size: .9em !important;
  font-family: 'Montserrat-SemiBold' !important;
}
</style>
