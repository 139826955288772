<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're holding onto pent-up emotions and struggling to find inner peace, this subliminal can provide the support you need. It is designed to help you release and let go of negative emotions, allowing for emotional freedom."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Assisting in the release of past traumas, hurts, or disappointments.",
        "Allowing you to experience emotional release and catharsis.",
        "Promoting a greater sense of inner peace and emotional well-being.",
        "Encouraging forgiveness and letting go of grudges or resentments.",
        "Helping you cultivate a healthier relationship with your emotions."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to let go of pent-up emotions and find emotional freedom. Remember, the journey to emotional healing takes time and self-compassion, but you are capable of experiencing inner peace and emotional well-being."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a journey of emotional release and inner peace can be transformative, but it's important to prepare yourself mentally and emotionally before listening to this subliminal. Here are some suggestions to help you make the most of this experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a safe space: Find a quiet and comfortable place where you won't be disturbed during your listening session.",
        "Set an intention: Clarify your intention for emotional release and invite inner peace into your life.",
        "Allow yourself to feel: Be open to experiencing and acknowledging your emotions without judgment or resistance.",
        "Journaling: Consider keeping a journal to jot down any thoughts, feelings, or insights that arise during or after your listening session."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember that emotional release is a personal journey, and it may take time to fully experience the benefits. Be patient and compassionate towards yourself as you embrace this process of letting go and finding inner peace. By incorporating this subliminal into your routine, you are taking a powerful step towards emotional freedom and well-being. Now, take a deep breath, set your intention, and let the healing begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Emotional Release subliminal! By choosing to let go of pent-up emotions, you have taken a significant step towards experiencing emotional freedom and finding inner peace. Take a moment to reflect on your journey and continue your path towards emotional well-being."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Journal your emotions: Write down your thoughts and feelings to gain better clarity and understanding.",
        "Practice self-care: Engage in activities that help you relax, such as taking a bath or going for a walk in nature.",
        "Seek support: Reach out to a trusted friend, family member, or therapist to talk about your emotions.",
        "Practice mindfulness: Incorporate mindfulness techniques, such as deep breathing or meditation, into your daily routine."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, emotional healing is a process that takes time and patience. Allow yourself to feel and acknowledge your emotions without judgment. Embrace the opportunity to find inner peace and experience emotional freedom. You are on the right path, and your well-being is worth it!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Emotional Release subliminal work?`, 
    answer: `The Emotional Release subliminal works by sending positive affirmations and messages directly to your subconscious mind. These affirmations are designed to help you let go of pent-up emotions and negative energy, allowing you to experience emotional freedom and find inner peace. By listening to the subliminal regularly, you can reprogram your mindset and release emotional blockages that may be holding you back.`,
    open: false
  },
  { 
    question: `How long does it take to experience the benefits of the Emotional Release subliminal?`, 
    answer: `The time it takes to experience the benefits of the Emotional Release subliminal may vary from person to person. Some individuals may notice positive changes in their emotional well-being early on, while others may require more time and consistent listening. It is recommended to listen to the subliminal audio daily and be patient as you allow the affirmations to work on a subconscious level.`,
    open: false 
  },
  { 
    question: `Can the Emotional Release subliminal help with trauma healing?`, 
    answer: `While the Emotional Release subliminal can be a helpful tool in processing and releasing emotions, it is not a substitute for professional therapy or treatment for trauma. If you have experienced trauma, it is important to seek the guidance of a licensed therapist or counselor who can provide personalized support and guidance.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Emotional Release subliminal?`, 
    answer: `Listening to the Emotional Release subliminal is generally considered safe and does not have any known risks or side effects. However, it is important to be aware that emotions may surface as part of the release process. It is recommended to create a supportive environment and seek professional help if needed.`,
    open: false 
  },
  { 
    question: `How can I maximize the effectiveness of the Emotional Release subliminal?`, 
    answer: `To maximize the effectiveness of the Emotional Release subliminal, create a peaceful and comfortable space for listening. Allow yourself to be open to the messages and affirmations, and try to let go of any judgment or resistance. Journaling or engaging in other self-reflective practices may also be helpful in processing and integrating your emotions.`,
    open: false 
  },
  { 
    question: `Is it normal to feel more emotional after listening to the Emotional Release subliminal?`, 
    answer: `Yes, it is normal to feel more emotional after listening to the Emotional Release subliminal. This is a sign that the subliminal is working and helping you release pent-up emotions. Give yourself permission to feel and allow the emotions to flow naturally, being gentle and compassionate with yourself as you navigate the healing process.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>