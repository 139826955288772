<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you constantly find yourself feeling anxious or dissatisfied because you're afraid of missing out on something, this subliminal can help you break free from the grip of FOMO. By targeting your subconscious mind, it encourages a shift in perspective and helps you embrace the present moment with contentment and satisfaction."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can be beneficial:'
    },
    {
      type: 'list',
      items: [
        "Reducing feelings of anxiety and discontentment caused by FOMO.",
        "Helping you cultivate a sense of gratitude and appreciation for the present moment.",
        "Supporting the development of healthy boundaries and the ability to prioritize your own needs and desires.",
        "Promoting a sense of contentment and satisfaction with your own life, regardless of what others are doing or experiencing.",
        "Encouraging mindfulness and the practice of being fully present in each moment."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you may notice a gradual shift in your mindset and a greater ability to find joy and fulfillment in the present moment. Remember, breaking free from FOMO takes time and conscious effort, but with the help of this subliminal, you can overcome it and embrace a more satisfying and contented life."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Overcoming the fear of missing out (FOMO) can bring a sense of peace and contentment to your life. This subliminal is designed to help you let go of FOMO and embrace the present moment fully. Before you start listening, consider these suggestions to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your FOMO: Recognize and accept that FOMO is a natural human response, but also understand that it can hinder your ability to be fully present in the moment.",
        "Set an intention: Prioritize being present and finding contentment in every situation.",
        "Reflect on missed opportunities: Remind yourself that every choice has trade-offs and focus on the positive aspects of the choices you have made.",
        "Practice gratitude: Cultivate a mindset of gratitude for the present moment and the experiences it brings.",
      ]
    },
    {
      type: 'paragraph',
      text: "With each listening session, you can train your mind to let go of FOMO and bring more contentment and satisfaction into your life. Remember, the journey towards embracing the present moment begins with recognizing and accepting your fear of missing out, but it doesn't end there. So take a deep breath, set your intention, and let's start embracing the present together."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Fear of Missing Out (FOMO) Removal subliminal! By addressing and overcoming this fear, you've taken a significant step towards embracing the present moment with contentment and satisfaction. As you reflect on your experience, remember to continue nurturing a mindset of fulfillment and gratitude."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice mindfulness: Engage in mindfulness exercises to stay grounded and fully present in the moment.",
        "Limit social media use: Set boundaries and reduce excessive scrolling to minimize FOMO triggers.",
        "Focus on quality connections: Prioritize meaningful interactions with loved ones and cultivate deeper relationships.",
        "Celebrate your accomplishments: Recognize and appreciate your own achievements, rather than constantly seeking external validation."
      ]
    },
    {
      type: 'paragraph',
      text: "Overcoming the fear of missing out is a continuous process that requires self-awareness and intentional choices. By practicing gratitude, embracing the present, and nurturing meaningful connections, you can find a sense of fulfillment and joy in every moment. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Fear of Missing Out FOMO Removal subliminal help me overcome FOMO?`, 
    answer: `The Fear of Missing Out FOMO Removal subliminal is designed to help reprogram your subconscious mind and change your perspective towards the fear of missing out. By listening to the subliminal regularly, you can develop a sense of contentment and satisfaction in the present moment, allowing you to let go of FOMO and embrace a more fulfilling and mindful life.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Fear of Missing Out FOMO Removal subliminal?`, 
    answer: `The time it takes to see results from the Fear of Missing Out FOMO Removal subliminal can vary depending on the individual. Some people may notice positive changes in their mindset and behavior right away, while others may require more time and consistent listening to fully overcome FOMO. We recommend listening to the subliminal daily and consistently for best results.`,
    open: false 
  },
  { 
    question: `Can the Fear of Missing Out FOMO Removal subliminal completely eliminate FOMO?`, 
    answer: `While the Fear of Missing Out FOMO Removal subliminal can be a powerful tool in helping you overcome FOMO, the complete elimination of FOMO may vary from person to person. It is important to remember that FOMO is a complex emotion that can stem from various underlying factors. The subliminal aims to shift your mindset and reduce the impact of FOMO in your life, but it may require additional self-reflection, mindfulness, and personal growth to fully overcome it.`,
    open: false 
  },
  { 
    question: `Can the Fear of Missing Out FOMO Removal subliminal be used alongside therapy or other treatments?`, 
    answer: `Yes, the Fear of Missing Out FOMO Removal subliminal can be used alongside therapy or other treatments. However, it is advisable to consult with your therapist or healthcare provider before incorporating the subliminal into your treatment plan to ensure it aligns with your specific needs and goals.`,
    open: false 
  },
  { 
    question: `Is there any scientific evidence supporting the effectiveness of subliminals?`, 
    answer: `The effectiveness of subliminals is still a topic of debate within the scientific community. While there is limited scientific research on subliminal messaging specifically, studies have shown that subliminal stimuli can influence behavior and attitudes to some extent. It is important to approach subliminals with an open mind and understand that individual experiences may vary.`,
    open: false 
  },
  { 
    question: `Can I listen to the Fear of Missing Out FOMO Removal subliminal while doing other activities?`, 
    answer: `Yes, you can listen to the Fear of Missing Out FOMO Removal subliminal while doing other activities. The subliminal messages are designed to work on your subconscious mind, so you don't need to consciously focus on them. However, it's important to find a comfortable listening environment where you can relax and let the subliminal play in the background without distractions.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>