<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Understanding and effectively expressing love languages is a powerful tool for enhancing and deepening your relationships. This subliminal is designed to help you master and express love languages, allowing you to create stronger connections with the people in your life."
    },
    {
      type: 'heading',
      text: 'Here are some key benefits of using this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Gain a deeper understanding of the five love languages and how they can enhance your relationships.",
        "Discover your own love language preferences and understand how they impact your interactions with others.",
        "Develop the ability to effectively express love and appreciation in the love languages that resonate with your loved ones.",
        "Improve communication and connection by speaking your loved ones' love languages, fostering stronger relationships.",
        "Enhance trust, intimacy, and emotional connection in your relationships by actively practicing the love languages."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind to adopt the mindset and behaviors associated with each love language. This will allow you to more naturally express love and appreciation in ways that resonate with your loved ones, creating deeper and more fulfilling connections. Remember, consistent practice is key to mastering and integrating love languages into your relationships."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Strengthening your ability to communicate love effectively is an essential skill for building and maintaining meaningful relationships. This subliminal is designed to help you master and express love languages, enriching your connections with others. Here are some suggestions to prepare for your listening session:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your relationships: Consider the people you want to deepen your connection with and identify their love language preferences.",
        "Educate yourself on the love languages: Familiarize yourself with the five love languages (Words of Affirmation, Acts of Service, Receiving Gifts, Quality Time, and Physical Touch) and how they can be expressed.",
        "Visualize utilizing love languages: Imagine yourself effectively expressing each love language and the positive impact it will have on your relationships.",
        "Set your intention: Before you begin listening, decide on your goal for enhancing your communication of love languages."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal, trust in your ability to master and express love languages. Embrace the growth and transformative power this audio can have on your relationships. Remember, effective communication of love is a skill that can be nurtured and developed. Let your journey to mastering love languages and deepening connections with others begin now."
    }
  ]
},
            {
  id: 'after-listening',
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Love Languages Mastery and Expression subliminal session! By investing in understanding and effectively expressing love languages, you've taken a significant step towards enhancing your relationships and deepening your connections with others. Now, it's important to reflect on your experience and continue taking actionable steps towards growth and transformation."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your love languages: Think about which love languages resonate with you the most, and consider how they impact your relationships.",
        "Communicate with loved ones: Share your newfound knowledge about love languages with your loved ones and encourage open conversations.",
        "Practice active listening: Pay attention to the words, emotions, and needs of others, and make an effort to understand and respond accordingly.",
        "Express love in different ways: Experiment with expressing love in various love languages to see how it positively impacts your relationships."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, mastering and expressing love languages is an ongoing process that requires patience, empathy, and consistent effort. Embrace this journey and enjoy the deeper connections and fulfillment it brings to your relationships. Keep nourishing the love in your life and watch it flourish!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What are love languages?`, 
    answer: `Love languages refer to the different ways individuals give and receive love. The concept, popularized by Gary Chapman, includes five love languages: words of affirmation, acts of service, receiving gifts, quality time, and physical touch. Each person may have a primary love language, which is the way they most strongly feel loved and appreciated.`,
    open: false
  },
  { 
    question: `How can Love Languages Mastery and Expression help improve my relationships?`, 
    answer: `Love Languages Mastery and Expression can help improve your relationships by providing insights into your own love language and understanding the love languages of those around you. This knowledge allows you to communicate love and appreciation more effectively, leading to deeper connections and stronger relationships.`,
    open: false 
  },
  { 
    question: `How long does it take to master and express love languages?`, 
    answer: `Mastering and effectively expressing love languages is a continuous learning process that may vary from person to person. It requires self-awareness, practice, and open communication with your loved ones. With consistent effort and a genuine desire to understand and connect with others, you can make significant progress in mastering and expressing love languages.`,
    open: false 
  },
  { 
    question: `Can Love Languages Mastery and Expression be used in all types of relationships?`, 
    answer: `Yes, Love Languages Mastery and Expression can be applied to all types of relationships, including romantic partnerships, friendships, family bonds, and professional connections. Understanding and effectively communicating using the love languages can enhance any relationship by fostering emotional intimacy and mutual understanding.`,
    open: false 
  },
  { 
    question: `What if my partner and I have different love languages?`, 
    answer: `Having different love languages is not uncommon and can actually enrich a relationship. It provides an opportunity for both partners to learn and grow in understanding each other's needs. Open and honest communication, along with a willingness to adapt and meet each other halfway, can help bridge any gaps and create a stronger connection.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of using Love Languages Mastery and Expression?`, 
    answer: `No, there are no known risks or side effects associated with using Love Languages Mastery and Expression. It is a tool designed to enhance and deepen relationships by fostering understanding, connection, and effective communication.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>