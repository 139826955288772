<template>
  <div>
    <UserAppbar />
    <div style="overflow: auto">
      <div class="card-container p-2">
        <Dashboard />
      </div>
    </div>
  </div>
</template>

<script>
import UserAppbar from "@/components/User/UserAppbar.vue";
import Dashboard from "@/views/User/Dashboard.vue";
export default {
  components: {
    UserAppbar,
    Dashboard,
  },
};
</script>

<style scoped>
.card-container {
  width: calc(100vw - 375px);
  height: calc(100vh - 100px);
}

@media screen and (max-width: 990px) {
  .card-container {
    width: calc(100vw - 48px);
  }
}

@media screen and (max-width: 500px) {
  .card-container {
    width: 100vw;
  }
}
</style>
