<template>
  <div>
    <HowDoesMagus />
  </div>
</template>

<script>
import HowDoesMagus from '@/views/Main/HowDoesMagus'
export default {
  components: {
    HowDoesMagus
  }
}
</script>