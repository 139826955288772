<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Attracting a generous and supportive sugar daddy into your life is possible with the power of manifestation. This subliminal is designed to help shift your mindset and energy, aligning you with the abundance and opportunities to manifest your desires."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can assist you:'
    },
    {
      type: 'list',
      items: [
        "Enhancing your self-confidence and self-worth, making you more magnetic to potential sugar daddies.",
        "Attracting opportunities and connections that can lead to meeting a generous and supportive sugar daddy.",
        "Helping you release any limiting beliefs or blocks around receiving financial and emotional support.",
        "Empowering you to set clear boundaries and communicate your desires effectively.",
        "Enhancing your manifestation abilities, helping you visualize and manifest your ideal sugar daddy."
      ]
    },
    {
      type: 'paragraph',
      text: "By consistently listening to this subliminal and embracing the belief that you deserve abundance and support, you can manifest a generous and supportive sugar daddy into your life. Remember, manifestation requires a combination of intention, belief, and action, so be open to the opportunities that come your way and take inspired action towards your desires."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Manifesting a sugar daddy into your life can be an exciting and transformative experience. Before you start listening to this subliminal, it's important to set your mind and intentions on the desired outcome. Here are some tips to help you prepare:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your desires: Clearly define what you are looking for in a sugar daddy, including their personality traits, financial stability, and the type of relationship you desire.",
        "Believe in yourself: Cultivate a strong belief that you deserve to attract a generous and supportive sugar daddy.",
        "Visualize your ideal relationship: Imagine yourself in a fulfilling and harmonious relationship with your sugar daddy, experiencing love, support, and financial abundance.",
        "Embrace gratitude: Practice gratitude for the opportunities and experiences that may come from manifesting a sugar daddy in your life."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to work with your subconscious mind, aligning your thoughts and beliefs with the manifestation of a sugar daddy. By setting clear intentions, visualizing your desires, and cultivating a positive mindset, you can enhance the power of this subliminal. Trust the process, stay open to possibilities, and let the universe bring your ideal sugar daddy into your life."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Manifest a Sugar Daddy subliminal session! By opening your mind to the power of manifestation, you've taken a step towards attracting a generous and supportive sugar daddy into your life. It's important to reflect on your journey and continue taking action to manifest your desires."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Visualize your ideal sugar daddy: Take time to create a clear mental image of the qualities and characteristics you seek.",
        "Cultivate a positive mindset: Maintain a belief in your ability to manifest and attract the life you desire.",
        "Take inspired action: Act on opportunities that align with your goals and values, increasing your chances of meeting the right person.",
        "Focus on self-improvement: Develop your own interests, skills, and self-confidence to attract a mutually beneficial relationship."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, manifestation is a process that requires patience and effort. Stay committed to your goals and take inspired action to manifest your desires. Trust in the universe and yourself, knowing that you have the power to attract the life you dream of. Best of luck on your journey!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Can the Manifest a Sugar Daddy subliminal guarantee that I will find a sugar daddy?`, 
    answer: `The Manifest a Sugar Daddy subliminal is designed to help you align your mindset and intentions towards attracting a generous and supportive sugar daddy into your life. However, results may vary depending on various factors, including individual circumstances and the effort put into taking practical steps towards meeting potential sugar daddies. It is important to approach any relationship, including sugar arrangements, with caution and prioritize personal safety.`,
    open: false
  },
  { 
    question: `Is it ethical to use the Manifest a Sugar Daddy subliminal?`, 
    answer: `The ethicality of using the Manifest a Sugar Daddy subliminal is subjective and depends on personal values and beliefs. It is important to approach sugar arrangements with transparency, open communication, and mutual consent. Make sure to establish clear boundaries and expectations in any potential relationship. It is also recommended to conduct thorough research and be aware of the legal regulations surrounding sugar arrangements in your country or region.`,
    open: false 
  },
  { 
    question: `How long before I see results from listening to the Manifest a Sugar Daddy subliminal?`, 
    answer: `Results can vary from person to person and depend on various factors such as mindset, intention, and the effort put into meeting potential sugar daddies. Some individuals may experience positive changes and attract opportunities relatively quickly, while for others, it may take more time and consistent listening to the subliminal to see results. We recommend listening to it regularly and taking practical steps towards meeting potential sugar daddies to increase your chances of attracting a compatible partner.`,
    open: false 
  },
  { 
    question: `Can the Manifest a Sugar Daddy subliminal help in finding a long-term partner?`, 
    answer: `The Manifest a Sugar Daddy subliminal is specifically designed to help manifest a sugar daddy, which typically refers to a more financially supportive and mutually beneficial relationship. If you are seeking a long-term romantic partner, it may be more beneficial to focus on subliminals or practices designed specifically for attracting a compatible long-term partner.`,
    open: false 
  },
  { 
    question: `Is it necessary to take practical steps alongside listening to the Manifest a Sugar Daddy subliminal?`, 
    answer: `While listening to the Manifest a Sugar Daddy subliminal can help align your mindset and intentions, taking practical steps is also crucial in attracting a sugar daddy. This may include actively seeking potential sugar daddies through legitimate online platforms, attending social events, actively engaging in conversations, and establishing clear communication and boundaries with potential partners. Combining the subliminal with practical action can increase your chances of finding a compatible sugar daddy.`,
    open: false 
  },
  { 
    question: `Do I need to disclose to potential sugar daddies that I have been using the Manifest a Sugar Daddy subliminal?`, 
    answer: `Whether or not to disclose your use of the Manifest a Sugar Daddy subliminal is a personal decision. It is not necessary to disclose this information, as it is an individual practice aimed at aligning your mindset. However, it is important to prioritize transparency, open communication, and establishing clear expectations in any potential relationship.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>