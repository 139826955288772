<template>
  <div class="pb-3">
    <div class="search-wrapper">
      <input class="search-class mt-2" v-model="search" placeholder="Search" @keyup="addInputSearch(search)"/>
      <div class="button-class" @click="clear">
        <img src="@/assets/images/user/icon/clear.png" class="img2" />
      </div>
    </div>
    <div class="mt-3">
      <v-chip class="ma-1" label v-for="(item, index) in recentsearch" :key="index" @click="searchingResult(item)">
        {{ item }}
      </v-chip>
      <v-chip v-if="recentsearch.length != 0" closable class="mx-1" label @click="clearSearch()" color="black" text-color="white">
        clear all
      </v-chip>
    </div>
    
    <ListenTo :search="search" :isReady="isReady" @goToTrack="goToTrack" :listenTo="searchUser_playlist"/>
    
    <div v-if="isReady == true">
      <div v-if="searchPlaylists.length == 0 && searchSubliminals.length == 0">
        <Empty/>
      </div>
      <div v-else>
        <Subliminal :isReady="isReady" @getAudios="getAudios" :subliminal="searchSubliminals"/>
        <Playlist :isReady="isReady" @goToTrack="goToTrack" :playlist="searchPlaylists"/>
      </div>
    </div>
    <div v-else class="text-center">
      <div class="loader"></div>
    </div>

    <div style="height: 100px;">
    </div>

  </div>
</template>

<script>
import ListenTo from "@/views/User/Search/ListenTo.vue";
import Subliminal from "@/views/User/Search/Subliminal.vue";
import Playlist from "@/views/User/Search/Playlist.vue";
import Empty from "@/views/User/Search/Empty.vue";

import { addSearch, getSearchResult, 
getClearSearch, getSearchSubAndPlaylistResult, 
getSearchListenToPlaylist } from "@/api/search";

import { getPlaylists } from "@/api/ownplaylist";
import { convertToTime } from "@/utils";

import { audio } from "@/mixins/audio";
import { mapGetters } from "vuex";

export default {
  components: {
    ListenTo,
    Subliminal,
    Playlist,
    Empty
  },
  mixins: [audio],
  data: () => {
    return {
      addSearch,
      getSearchResult,
      getSearchListenToPlaylist,
      getSearchSubAndPlaylistResult,
      getClearSearch,
      getPlaylists,
      convertToTime,

      isReady: false,
      search: null,
      searchSubliminals: [],
      searchPlaylists: [],
      searchData: [],
      searchUser_playlist: [],
      index: 0,
      limit: 50,
    };
  },
  computed: {
    recentsearch() {
      return this.$store.state.recent.search.data;
    },
    user() {
      return this.$store.state.user.info;
    },
    playlistTrack() {
      return this.$store.state.audio.playlist.tracks;
    },
    playlistType() {
      return this.$store.state.audio.playlist.type;
    },
    ...mapGetters({
      isDataReady: "newaudio/getDataStatus",
      isAudioPlaying: "newaudio/getPlayingStatus",
    }),
  },
  created() {
    this.getSearch("");
  },
  methods: {
    clear() {
      this.search = "";
      this.getSearch("");
    },
    next(i) {
      this.index = i;
    },
    addInputSearch(search) {
      clearTimeout(this.timeout);    
        var self = this;
        this.timeout = setTimeout(function () {
          const user_id = JSON.parse(self.user).user_id;
          const subscription_id = JSON.parse(self.user).info.subscription_id;

          const data = {
            user_id: user_id,
            search: self.search,
            subscription_id: subscription_id,
          };

          self.addSearch(data).then((res) => {
            self.$store.dispatch("recent/getSearch", res);
          });

          self.getSearch(search);
        }, 1000);
    },
    clearSearch() {
      const user_id = JSON.parse(this.user).user_id;
      const subscription_id = JSON.parse(this.user).info.subscription_id;
      const data = {
        user_id: user_id,
      };

      this.getClearSearch(data).then((res) => {
        this.$store.dispatch("recent/getSearch", res);
        this.$store.dispatch("recent/getSearchLoading", false);
      });
    },
    getSearch(search) {
      this.search = search

      const user_id = JSON.parse(this.user).user_id;
      const subscription_id = JSON.parse(this.user).info.subscription_id;

      const data = {
        user_id: user_id,
        search: search,
        page: 1,
        limit: this.limit,
        subscription_id: subscription_id,
      };

      this.data = [];
      this.getSearchListenToPlaylist(data).then((res) => {
        this.searchUser_playlist = res;
      });
      this.searchingResult(search);
    },
    searchingResult(search) {
      this.isReady = false;
      this.searchSubliminals = [];
      this.searchPlaylists = [];
      this.search = search

      const user_id = JSON.parse(this.user).user_id;
      const subscription_id = JSON.parse(this.user).info.subscription_id;

      const datas = {
        user_id: user_id,
        search: search,
        page: 1,
        limit: this.limit,
        subscription_id: subscription_id,
      };
      this.getSearchSubAndPlaylistResult(datas).then((res) => {
        this.searchSubliminals = this.searchSubliminals.concat(res.subliminal)
        this.searchPlaylists = this.searchPlaylists.concat(res.playlist)
        this.isReady = true;

        let i=2;
        while (i <= res.pages) {
          const datas = {
            user_id: user_id,
            search: search,
            page: i,
            limit: this.limit,
            subscription_id: subscription_id,
          };
          this.getSearchSubAndPlaylistResult(datas).then((res) => {
            this.searchSubliminals = this.searchSubliminals.concat(res.subliminal)
            this.searchPlaylists = this.searchPlaylists.concat(res.playlist)
            console.log(this.searchSubliminals.length)
          });
          i++;
        }
      });
    },
    getAudios(data, index) {
      if (this.playlistType != "recent") {
        this.$store.dispatch("audio/getPlaylistType", "recentsearch");
      }

      if (JSON.stringify(this.playlistTrack) != JSON.stringify(data)) {
        this.$store.dispatch("audio/getPlaylistTrack", data);
      }

      this.$store.dispatch("newaudio/currentTime", 0);
      this.$store.dispatch("newaudio/subliminalIndex", index);
      this.initializeData(data, index, true);

      this.$store.dispatch("newaudio/playedStatus", true);
    },
    goToTrack(data) {
      localStorage.setItem("user_track", JSON.stringify(data));
      this.$store.dispatch("user/getTrack", data);
      if (!this.isRouteTrack) {
        this.$router.push("/user/track");
      }
    },
  },
};
</script>

<style scoped>
  .search-wrapper {
    width: 100%;
    position: relative;
  }
  .search-class {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid white;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
    padding: 5px 10px;
  }
  .button-class {
    height: 50px;
    position: absolute;
    top: 15px;
    right: 0;
    padding: 5px 10px;
  }
  .loader {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #3498db;
    width: 50px;
    height: 50px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10rem;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
</style>
