<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Living with bone pain can be debilitating and affect your daily life. This subliminal aims to alleviate and heal bone pain, promoting a healthier and pain-free body."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help:'
    },
    {
      type: 'list',
      items: [
        "Reduce the intensity and frequency of bone pain, providing relief and comfort.",
        "Accelerate the healing process, aiding in the recovery of bone injuries and fractures.",
        "Promote a healthier skeletal system, strengthening bones and reducing the risk of future pain.",
        "Release tension and stress in the body, which can contribute to bone pain.",
        "Support a positive mindset and mental resilience in dealing with bone pain."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind to promote healing and alleviate bone pain. As your body responds to the positive suggestions, you may experience improved comfort, mobility, and overall well-being. Remember, this is not a substitute for medical advice or treatment, and it's essential to consult with a healthcare professional for any underlying conditions."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Finding relief from bone pain and promoting healing can be a challenging journey, but this subliminal is designed to support you in your quest for a healthier, pain-free body. Here are some tips to enhance your experience before you start listening:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your healing intention: Focus on your desire to alleviate bone pain and promote healing within your body.",
        "Find a comfortable space: Create a calm and relaxing environment where you can easily listen to the subliminal affirmations.",
        "Relax your body and mind: Take a few deep breaths, allowing yourself to release tension and enter a state of calmness.",
        "Imagine relief and healing: Picture yourself free from bone pain, engaging in your favorite activities with ease and comfort.",
        "Keep an open mind: Be receptive to the subliminal messages and trust in the healing power of your subconscious mind."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening regularly to this subliminal, you can tap into your body's natural healing abilities and alleviate bone pain. With dedication and a positive mindset, you can move closer to a healthier and pain-free life. Take a moment to center yourself, set your intention, and let the healing journey begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Bone Pain Relief and Healing subliminal session! You've taken an important step toward alleviating your bone pain and promoting a healthier, pain-free body. Reflecting on your experience and taking post-listening actions will enhance your healing process."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Prioritize self-care: Be gentle with your body and give it the rest and care it needs to heal.",
        "Follow your healthcare provider's guidance: Ensure you're following any recommended treatments or medications.",
        "Incorporate gentle exercises: Work with a physical therapist to include exercises that promote bone health and flexibility.",
        "Consider dietary changes: Consult a nutritionist to ensure you're getting adequate nutrients to support bone healing."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, healing takes time, and everyone's journey is unique. Be patient and kind to yourself as you navigate the process. With consistent self-care and the right support, you can promote healing and restore balance to your body. Wishing you a speedy recovery and a pain-free future!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Bone Pain Relief and Healing subliminal work?`, 
    answer: `The Bone Pain Relief and Healing subliminal uses positive affirmations and soothing background sounds to influence your subconscious mind and promote healing of bone pain. The subliminal messages aim to reduce inflammation, ease discomfort, and support the body's natural healing processes. By consistently listening to the subliminal, you can cultivate a more positive mindset towards bone health and experience pain relief over time.`,
    open: false
  },
  { 
    question: `How long does it take to experience relief from bone pain?`, 
    answer: `The time it takes to experience relief from bone pain can vary depending on the individual and the severity of the condition. It is important to listen to the Bone Pain Relief and Healing subliminal consistently and be patient with the healing process. While some people may notice improvements in a short period of time, others may require more time for the subliminal messages to take effect.`,
    open: false 
  },
  { 
    question: `Can the Bone Pain Relief and Healing subliminal be used alongside other forms of treatment?`, 
    answer: `Yes, the Bone Pain Relief and Healing subliminal can be used in conjunction with other forms of treatment, such as medication or physical therapy. It is important to consult with your healthcare provider before making any changes to your treatment plan. The subliminal is designed to support and enhance the body's healing processes and can be a valuable tool in your overall pain management strategy.`,
    open: false 
  },
  { 
    question: `Are there any side effects of listening to the Bone Pain Relief and Healing subliminal?`, 
    answer: `No, there are no known side effects of listening to the Bone Pain Relief and Healing subliminal. However, if you have any concerns about your bone health or are experiencing severe pain, it is important to consult with your healthcare provider. The subliminal is intended to complement and support your existing pain management and healing practices.`,
    open: false 
  },
  { 
    question: `Can the Bone Pain Relief and Healing subliminal heal all types of bone pain?`, 
    answer: `The Bone Pain Relief and Healing subliminal aims to promote healing and pain relief in general. While it can be beneficial for various types of bone pain, it may not address or cure specific underlying medical conditions. It is important to consult with your healthcare provider for a proper diagnosis and personalized treatment plan.`,
    open: false 
  },
  { 
    question: `Is the Bone Pain Relief and Healing subliminal a substitute for medical advice?`, 
    answer: `No, the Bone Pain Relief and Healing subliminal is not a substitute for medical advice or treatment. It is designed to complement and support your existing pain management and healing practices. If you have any concerns about your bone health or are experiencing severe pain, it is important to consult with your healthcare provider for proper diagnosis and guidance.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>