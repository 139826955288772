import { render, staticRenderFns } from "./60-golden-ratio-and-symmetrical-face-development.vue?vue&type=template&id=62794b84&scoped=true&"
import script from "./60-golden-ratio-and-symmetrical-face-development.vue?vue&type=script&lang=js&"
export * from "./60-golden-ratio-and-symmetrical-face-development.vue?vue&type=script&lang=js&"
import style0 from "./60-golden-ratio-and-symmetrical-face-development.vue?vue&type=style&index=0&id=62794b84&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62794b84",
  null
  
)

export default component.exports