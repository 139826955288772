<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Quitting smoking can be challenging, but with the right mindset and support, it is possible to break free from the nicotine addiction and live a smoke-free life. This subliminal is designed to help you in your journey to stop smoking completely."
    },
    {
      type: 'heading',
      text: 'Here are the ways this subliminal can assist you:'
    },
    {
      type: 'list',
      items: [
        "Reduce cravings and the desire to smoke.",
        "Overcome withdrawal symptoms and manage nicotine cravings.",
        "Create a new, healthier perception of yourself as a non-smoker.",
        "Build willpower, determination, and discipline to stay smoke-free.",
        "Replace smoking with healthier habits and coping mechanisms.",
        "Increase your motivation and commitment to quit smoking for good."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind and strengthen your resolve to quit smoking. Remember, quitting smoking is a process, and it may take time and effort. However, with persistence and the right support, you can succeed in living a smoke-free life."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on taking this step towards a smoke-free life! Quitting smoking can be challenging, but with the help of this subliminal, you can break free from the nicotine addiction. Before you start listening, here are some tips to prepare yourself:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Clarify why you want to quit smoking and visualize the benefits of a smoke-free life.",
        "Create a support system: Inform your loved ones about your decision and ask for their support and encouragement.",
        "Clean your environment: Get rid of all cigarettes, lighters, and ashtrays to remove any triggers from your surroundings.",
        "Find healthy coping mechanisms: Identify alternative activities to help you manage stress or cravings, such as exercise or deep breathing techniques."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to reprogram your subconscious mind and help you overcome the challenges of quitting smoking. As you listen regularly, you may notice a decrease in cravings, increased willpower, and a renewed sense of control over your life. Embrace this opportunity to make a positive change and start afresh. You have the power to live a smoke-free life!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Stop Smoking Completely subliminal! By taking this step towards quitting smoking, you're on your way to a healthier and smoke-free life. Reflecting on your journey and implementing habits that support your decision is crucial as you continue on this path."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Stay committed: Remind yourself of the reasons why you want to quit smoking, and keep them in mind whenever you face cravings.",
        "Seek support: Reach out to friends, family, or support groups who can provide encouragement and help you stay on track.",
        "Replace smoking with healthier habits: Find alternative activities like exercising, reading, or practicing mindfulness to help distract from cravings.",
        "Create a smoke-free environment: Get rid of any smoking paraphernalia and avoid situations or triggers that may tempt you to smoke."
      ]
    },
    {
      type: 'paragraph',
      text: "Quitting smoking is a challenging process, but every step forward is a victory. Stay determined, embrace the positive changes, and focus on the benefits of your smoke-free life. Remember, you have the power to break free from nicotine addiction and live a healthier, happier life. You can do it!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Stop Smoking Completely subliminal help me quit smoking?`, 
    answer: `The Stop Smoking Completely subliminal is designed to target the subconscious mind and help reprogram your thoughts and behaviors related to smoking. By listening to the subliminal regularly, you can gradually break free from the nicotine addiction and develop a smoke-free lifestyle. It can assist in increasing your willpower, reducing cravings, and changing your mindset about smoking.`,
    open: false
  },
  { 
    question: `How long will it take for me to quit smoking using the subliminal?`, 
    answer: `The time it takes to quit smoking using the Stop Smoking Completely subliminal can vary from person to person. It depends on factors such as the level of nicotine addiction, individual motivation, and consistency in listening to the subliminal. We recommend listening to the subliminal daily and consistently for best results. Some may experience positive changes within a few weeks, while others may require a longer period of time to fully overcome the addiction.`,
    open: false 
  },
  { 
    question: `Can the subliminal help if I have tried quitting smoking multiple times without success?`, 
    answer: `Yes, the Stop Smoking Completely subliminal can still be effective even if you have tried quitting smoking multiple times before without success. It helps target the underlying patterns and beliefs that contribute to the addiction, providing support and reinforcement for your quitting efforts. It is important to approach the process with determination and a willingness to make positive changes in your life.`,
    open: false 
  },
  { 
    question: `Is it necessary to combine the subliminal with other smoking cessation methods?`, 
    answer: `The Stop Smoking Completely subliminal can be used as a standalone tool to aid in quitting smoking. However, combining it with other smoking cessation methods, such as counseling, support groups, or nicotine replacement therapy, can enhance your chances of success. Each individual is unique, so finding a combination of strategies that works best for you is important.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your health or quitting smoking, it is advisable to consult with a healthcare professional. The subliminal is intended to support your quitting efforts and should not replace any medical advice or treatment plans prescribed by a healthcare provider.`,
    open: false 
  },
  { 
    question: `Will the subliminal help me handle cravings and withdrawal symptoms during the quitting process?`, 
    answer: `The Stop Smoking Completely subliminal can help reduce cravings and provide support during the quitting process. However, it is important to remember that quitting smoking may still involve some withdrawal symptoms and challenges. It is beneficial to seek additional support from healthcare professionals or smoking cessation programs to address any physical or psychological symptoms that may arise.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>