<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you find yourself constantly seeking validation through social media and feeling the need to post selfies all the time, this subliminal can help you break free from that addiction and regain control of your life."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can be beneficial:'
    },
    {
      type: 'list',
      items: [
        "Reducing the obsession with taking and posting selfies.",
        "Developing a healthier relationship with social media and self-image.",
        "Increased self-confidence and self-worth that isn't reliant on external validation.",
        "Finding fulfillment and validation from within yourself, rather than seeking it from others.",
        "Developing a more balanced and authentic presence on social media."
      ]
    },
    {
      type: 'paragraph',
      text: "Through regular use of this subliminal, you can reprogram your subconscious mind to break free from selfie addiction. You'll begin to prioritize your own happiness and well-being over the need for constant validation from others. Remember, change takes time, so be patient and persistent in your listening."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Breaking free from selfie addiction is an important step towards reclaiming control over your life and self-worth. This subliminal has been designed to support you in your journey towards overcoming this addiction and finding validation from within. Before you start listening, here are some tips to help you prepare:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on the impact: Consider the ways in which selfie addiction may be affecting your life, relationships, and self-esteem.",
        "Create healthier coping mechanisms: Find alternative ways to boost your self-confidence and seek validation beyond social media.",
        "Limit social media use: Reduce the time spent on social media platforms that contribute to your selfie addiction.",
        "Focus on your inner worth: Shift your focus inward and recognize your value beyond validation from others."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, it can help reshape your mindset and reduce the need for external validation. Embrace this opportunity to reconnect with yourself, strengthen your self-esteem, and regain control of your life. Remember, true happiness and confidence come from within. Now, let's begin your journey towards freedom from selfie addiction."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Selfie Addiction Removal subliminal! By taking this step towards freeing yourself from the constant need for validation through social media, you've shown great dedication and commitment to personal growth. Now, it's important to reflect on your experience and continue your journey towards regaining control of your life."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your mindset: Consider journaling your thoughts and emotions surrounding selfie addiction.",
        "Limit social media use: Establish boundaries and schedule periods of social media detox to reduce exposure and temptation.",
        "Focus on meaningful connections: Invest time and effort in nurturing real-life relationships and face-to-face interactions.",
        "Practice self-acceptance: Embrace and love yourself for who you are, independent of likes and comments on social media."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, breaking free from selfie addiction is a process that requires patience and self-compassion. Embrace and celebrate the progress you have made, and continue to prioritize your mental well-being and genuine connections with others. You are on the path to reclaiming your life and living it authentically!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Selfie Addiction Removal subliminal help me overcome my addiction?`, 
    answer: `The Selfie Addiction Removal subliminal is designed to target the subconscious mind and reprogram it with new, healthier thought patterns. By consistently listening to the subliminal messages, you can begin to break free from the constant need for validation through social media and regain control of your life. The subliminal will help you develop a healthier relationship with technology and social media, allowing you to focus on your own self-worth and happiness instead of seeking external validation.`,
    open: false
  },
  { 
    question: `How long does it take to overcome selfie addiction using the Selfie Addiction Removal subliminal?`, 
    answer: `The time it takes to overcome selfie addiction can vary depending on the individual and their level of addiction. Consistent and daily listening to the Selfie Addiction Removal subliminal can help speed up the process, but it may still take time and dedication to fully break the habit. We recommend using the subliminal as a tool alongside other coping mechanisms and seeking support from loved ones or professionals if needed.`,
    open: false 
  },
  { 
    question: `Can the Selfie Addiction Removal subliminal make me stop using social media altogether?`, 
    answer: `The Selfie Addiction Removal subliminal is designed to help you regain control and develop a healthier relationship with social media, not to make you stop using it altogether. It aims to reduce the constant need for validation and help you prioritize your own self-worth and happiness over the opinions of others. It is up to you to determine the extent to which you want to use social media in your life.`,
    open: false 
  },
  { 
    question: `Are there any side effects of listening to the Selfie Addiction Removal subliminal?`, 
    answer: `No, there are no known side effects associated with listening to the Selfie Addiction Removal subliminal. However, if you have any concerns about your mental health or addictive behaviors, please consult with a healthcare professional. The subliminal is intended to be used as a tool alongside other supportive measures.`,
    open: false 
  },
  { 
    question: `Will the Selfie Addiction Removal subliminal completely remove my desire for validation?`, 
    answer: `The Selfie Addiction Removal subliminal aims to help you develop a healthier mindset and reduce your reliance on external validation. However, it is important to note that seeking validation is a natural human need to some extent. The subliminal will assist you in finding a healthier balance and directing your focus towards your own self-worth and happiness rather than solely relying on external validation.`,
    open: false 
  },
  { 
    question: `Can I use the Selfie Addiction Removal subliminal alongside other self-help techniques?`, 
    answer: `Yes, the Selfie Addiction Removal subliminal can be used alongside other self-help techniques. It can complement other coping strategies such as therapy, counseling, or support groups. It is important to find a comprehensive approach that works best for you and seek professional help if needed.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>