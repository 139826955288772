<template>
  <div>
    <Mobile_Section />
  </div>
</template>

<script>
import Mobile_Section from '@/views/Main/Price/Mobile_Section.vue'
export default {
  components: {
    Mobile_Section
  }
}
</script>