<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're ready to unlock your personal power and create the life you desire, this subliminal can be a powerful tool in your journey towards personal development."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help you:"
    },
    {
      type: 'list',
      items: [
        "Develop a strong belief in your abilities and increase self-confidence.",
        "Enhance your motivation and determination to achieve your goals.",
        "Overcome limiting beliefs and self-doubt that hold you back from success.",
        "Cultivate a positive mindset and attract abundance and opportunities.",
        "Improve your communication skills and build better relationships.",
        "Boost your creativity and problem-solving abilities.",
        "Increase your resilience and ability to adapt to challenges.",
        "Tap into your inner strength and unlock your full potential."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind to align with your goals and aspirations. As you develop your personal power, you'll gain the confidence and skills needed to overcome obstacles and create a life that reflects your true potential. Remember, personal development is a lifelong journey, and continued use of this subliminal can support your ongoing growth and transformation."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a personal power development journey can be the key to unlocking your full potential and creating the life you desire. This subliminal is designed to support your growth and help you develop the skills and mindset needed for success. Here are some suggestions to prepare for your listening session:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your goals: Take a moment to define what you want to achieve and what areas of your life you want to improve.",
        "Visualize your success: Imagine yourself already achieving your goals and experiencing the life you desire.",
        "Embrace a growth mindset: Adopt the belief that your abilities and intelligence can be developed through dedication and hard work.",
        "Focus on the affirmations: Keep your attention on the subliminal messages, allowing them to seep into your subconscious mind and create positive change."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you may notice a shift in your confidence, self-belief, and motivation. Embrace this transformative journey and trust in your ability to create the life you desire. Set your intentions, immerse yourself in the subliminal experience, and let your personal power unfold."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Personal Power Development subliminal session! By dedicating time to unlock your personal power, you've taken an important step towards achieving your goals and creating the life you desire. As you reflect on your experience, remember that your potential is limitless, and you have the ability to transform your life."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Set meaningful goals: Define what you want to achieve and create a plan to work towards them.",
        "Practice self-reflection: Take time to understand your strengths, weaknesses, values, and beliefs.",
        "Challenge limiting beliefs: Identify any negative thoughts or beliefs that may be holding you back and reframe them into positive affirmations.",
        "Take inspired action: Break your goals into smaller, actionable steps and consistently work towards them."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, personal power is about unleashing your potential and embracing your authentic self. Trust in your abilities, stay motivated, and take consistent action towards your goals. You have the power to create the life you desire. Keep shining!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Personal Power Development subliminal help me achieve my goals?`, 
    answer: `The Personal Power Development subliminal is designed to influence your subconscious mind, helping you develop the necessary mindset, motivation, and confidence to achieve your goals. By regularly listening to the subliminal messages, you can align your thoughts and beliefs with success, increase your self-discipline, and overcome any self-limiting beliefs that may be holding you back.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Personal Power Development subliminal?`, 
    answer: `Results can vary from person to person. Some individuals may experience positive changes almost immediately, while others may require more time and consistent listening to fully reprogram their mindset and see significant improvements in their personal power. We recommend listening to the subliminal daily and consistently for best results.`,
    open: false 
  },
  { 
    question: `Can the Personal Power Development subliminal help with confidence and self-esteem?`, 
    answer: `Yes, the Personal Power Development subliminal can help boost your confidence and self-esteem. Through positive affirmations and empowering messages, it aims to rewire your subconscious mind, allowing you to recognize your worth, embrace your strengths, and believe in your abilities.`,
    open: false 
  },
  { 
    question: `How can I make the Personal Power Development subliminal more effective?`, 
    answer: `To enhance the effectiveness of the Personal Power Development subliminal, you can supplement it with other personal growth practices such as journaling, visualization, and goal-setting. Setting clear goals, creating action plans, and consistently working towards them can also help maximize the impact of the subliminal on your personal power development.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Personal Power Development subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Personal Power Development subliminal audio. However, if you have any concerns about your personal development journey, please consult with a qualified professional. It is important to note that the subliminal is intended to supplement personal growth efforts and not replace professional guidance if needed.`,
    open: false 
  },
  { 
    question: `Can the Personal Power Development subliminal help me overcome procrastination?`, 
    answer: `Yes, the Personal Power Development subliminal can help you overcome procrastination by influencing your subconscious mind to prioritize action, focus, and productivity. However, it is important to combine the subliminal with practical strategies and techniques to effectively manage and overcome procrastination.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>