<template>
  <section>
    <div class="container">
      <div>
        <div>
          <h1>Find out how Magus for Business can transform your Organization.</h1>
          <h5>As the most innovative mental health and self-development company, Magus can help you address the mental health and wellness needs of your business. We offer flexibility and convenience to your company with innovative and relevant solutions.</h5>
          <div class="mt-5">
            <button @click="dialog = true" class="button btn btn-primary btn-lg">LET’S GET TO KNOW EACH OTHER</button>
          </div>
          <p class="mt-5">By proceeding, you consent to allow Magus to store and process the personal information submitted above to provide you the content requested.</p>
          
        </div>
        <div>
          <img class="big-img" src="@/assets/images/main/business/6/1.png" alt="" />
        </div>
      </div>
        <Dialog :dialog="dialog" :width="800" title="" @closeDialog="closeDialog">
          <ContactUs @closeDialog="closeDialog" />
        </Dialog>
    </div>
  </section>  
</template>

<script>

import Dialog from '@/components/Dialog.vue'
import ContactUs from '@/components/Home/ContactUs.vue'

export default {
  components: {
    Dialog,
    ContactUs
  },
  data: () => {
    return {
      dialog: false,
    }
  },
  methods: {
    goToRoute(route) {
      if (this.currentRoute != route) {
        this.$router.push(route).catch(err => { })
      }
    },
    closeDialog(data) {
      this.dialog = data
    },
  }
}
</script>

<style scoped>
  h1, h5, p {
    color: var(--ma-black-olive);
    line-height: 1.35em;
  }

  h5, p {
    font-family: "Montserrat-Regular";
  }
  .big-img {
    width: clamp(12.50rem, calc(5.00rem + 32.75vw), 26.00rem);
  }

  @media screen and (min-width: 80rem) {
    section>.container {

      @media screen and (min-width: 1200px) {
        text-align: left;
        height: 80vh;
        overflow-y: hidden;
        
        &>div {
          display: flex;

          &>div {
            width: 50%;
          }
          &>div:nth-of-type(2){
            padding-left: 10%;
          }
        }
      }

      @media screen and (max-width: 1200px) {
        height: auto;
        text-align: center;
      }
    }
  }
</style>