<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Rock-hard abs are not only aesthetically pleasing but also a sign of a strong and healthy core. This subliminal is designed to assist you in achieving your desired abdominal muscles by combining effective exercises and targeted workouts."
    },
    {
      type: 'heading',
      text: 'Key Features of this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Engaging in exercises that specifically target the abdominal muscles for maximum impact.",
        "Strengthening your core, which can improve your overall balance, stability, and posture.",
        "Utilizing a variety of exercises to target different areas of the abdominal muscles for a more complete workout.",
        "Following a structured workout plan that gradually increases in intensity to help you progress towards rock-hard abs.",
        "Providing guidance on proper form and technique to maximize the effectiveness of each exercise."
      ]
    },
    {
      type: 'paragraph',
      text: "With consistent use of this subliminal and dedication to your workouts, you can enhance the appearance of your abs and achieve a toned, defined, and rock-hard midsection. Remember, results may vary depending on your individual body composition and fitness level, but with commitment and perseverance, you can reach your goals."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Building rock-hard abs requires dedication and consistency in both your workouts and lifestyle. This subliminal is designed to help you stay motivated and focused on your journey towards achieving your desired abdominal muscles. Before you begin, consider these tips for optimal results:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set realistic goals: Define what having rock-hard abs means to you and create a specific plan to achieve it.",
        "Commit to a workout routine: Consistency is key in strengthening your core. Set aside dedicated time for targeted ab exercises.",
        "Maintain a balanced diet: Combine your workouts with a healthy eating plan to reduce body fat and reveal your ab muscles.",
        "Stay motivated: Visualize your desired results, use positive affirmations, and remember why you started this journey."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember that building rock-hard abs takes time and effort. This subliminal can help you stay motivated and focused on your goals, but it's important to pair it with a consistent exercise routine and healthy lifestyle. Embrace the challenge, stay committed, and start working towards achieving those sculpted abs!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Rock-Hard Abs subliminal session! By dedicating yourself to effective exercises and targeted workouts, you're well on your way to achieving toned and defined abdominal muscles. Now, it's time to reflect on your experience and continue progressing towards your fitness goals."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Celebrate your progress: Take pride in the efforts you've put in and acknowledge how far you've come.",
        "Stay consistent: Maintain a regular fitness routine to continue strengthening your core and achieving rock-hard abs.",
        "Mix up your exercises: Incorporate a variety of abdominal workouts to challenge your muscles and prevent plateaus.",
        "Fuel your body: Eat a balanced diet that supports your fitness goals, providing the necessary nutrients for muscle growth and recovery."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, achieving rock-hard abs takes time, commitment, and perseverance. Embrace the journey and stay focused on your goals. By staying consistent with your workouts and nourishing your body, you'll be well on your way to a stronger, more sculpted core. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How often should I do the Rock-Hard Abs workouts?`, 
    answer: `To achieve rock-hard abs, consistency is key. We recommend incorporating the Rock-Hard Abs workouts into your fitness routine at least three to four times a week. It is important to allow your muscles time to recover between workouts, so be sure to listen to your body and make adjustments as needed.`,
    open: false
  },
  { 
    question: `What exercises are included in the Rock-Hard Abs program?`, 
    answer: `The Rock-Hard Abs program includes a variety of exercises that target the abdominal muscles, such as crunches, planks, leg raises, and bicycle crunches. These exercises engage your core muscles and help strengthen and tone your abs for a more defined appearance. The program also provides modifications and progression options to accommodate different fitness levels.`,
    open: false 
  },
  { 
    question: `Can I do the Rock-Hard Abs workouts at home without any equipment?`, 
    answer: `Yes, you can easily do the Rock-Hard Abs workouts at home without any equipment. Many of the exercises in the program are bodyweight exercises that require no additional equipment. However, if you have access to equipment like resistance bands or stability balls, you can incorporate them to add variety and intensity to your workouts.`,
    open: false 
  },
  { 
    question: `How long does it take to see results with the Rock-Hard Abs program?`, 
    answer: `The time it takes to see results with the Rock-Hard Abs program can vary depending on individual factors such as fitness level, diet, and consistency. With dedication and consistency, you can start noticing improvements in your abdominal muscles within a few weeks. Remember to combine the workouts with a balanced diet for optimal results.`,
    open: false 
  },
  { 
    question: `Will the Rock-Hard Abs program help me lose belly fat?`, 
    answer: `While the Rock-Hard Abs program focuses on strengthening and toning the abdominal muscles, it is important to note that spot reduction of fat is not possible through exercise alone. To reduce belly fat and reveal your abs, it is necessary to combine the workouts with a balanced diet and regular cardio exercise. This will help create a calorie deficit and promote overall fat loss, leading to a leaner midsection.`,
    open: false 
  },
  { 
    question: `Is the Rock-Hard Abs program suitable for beginners?`, 
    answer: `Yes, the Rock-Hard Abs program is suitable for beginners. The program includes modifications and progressions for each exercise, allowing individuals of all fitness levels to participate. Whether you're just starting your fitness journey or already have some experience, you can customize the program to match your current abilities and gradually increase the intensity as you progress.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>