<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you often find housework to be tedious, overwhelming, or lacking motivation, this subliminal can help shift your mindset and make the tasks more enjoyable and productive."
    },
    {
      type: 'heading',
      text: 'Here are some ways in which this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Increase your motivation and enthusiasm for doing household chores.",
        "Boost your productivity and efficiency to complete tasks more quickly.",
        "Find satisfaction in completing each task and maintaining an organized living space.",
        "Create a positive mindset and attitude towards housework.",
        "Feel more energized and focused while performing your household duties."
      ]
    },
    {
      type: 'paragraph',
      text: "By consistently listening to this subliminal, you can rewire your subconscious mind to associate housework with productivity and enjoyment. This can result in a more organized, clean living space and a greater sense of accomplishment. Remember, building new habits takes time and effort, but with the support of this subliminal, you can transform your attitude towards housework and create a more pleasant environment for yourself."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Finding motivation and enjoyment in household chores can enhance your productivity and help create a more organized living space. This subliminal is designed to support you in achieving that goal. Before beginning your listening session, consider these strategies to maximize your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Focus on your desire to be productive and find satisfaction in completing household tasks.",
        "Create a routine: Establish a regular cleaning schedule to maintain a tidy living space and avoid feeling overwhelmed.",
        "Break tasks down: Divide larger chores into smaller, more manageable tasks to make them feel less daunting.",
        "Create a positive environment: Play soothing music or listen to an uplifting podcast while performing your chores to boost your mood and make the experience more enjoyable."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you may start to notice a shift in your attitude towards housework. You may find yourself feeling more motivated, productive, and satisfied with a clean and organized living space. Embrace this opportunity to enhance your productivity and enjoyment while doing household chores, and let's begin this empowering journey together."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Housework Productivity and Enjoyment subliminal! By focusing on increasing your productivity and enjoyment while doing household chores, you've taken a positive step towards creating a more organized living space and finding satisfaction in completing tasks. Let's reflect on your experience and maintain this progress."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Set realistic goals: Break down your housework tasks into manageable chunks and set achievable goals.",
        "Create a cleaning schedule: Establish a routine for cleaning specific areas or tasks to maintain a tidy living space.",
        "Discover your preferences: Experiment with different cleaning methods or organizing techniques to find what works best for you.",
        "Make it enjoyable: Play your favorite music, audiobooks, or podcasts while doing housework to make the time more enjoyable."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, maintaining a clean and organized living space takes consistent effort. Embrace the changes and enjoy the sense of accomplishment that comes from completing tasks. With a positive mindset and practical strategies, you can find satisfaction and productivity in your housework routine. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Housework Productivity and Enjoyment subliminal help me?`, 
    answer: `The Housework Productivity and Enjoyment subliminal is designed to positively influence your mindset and attitudes towards household chores. By listening to the subliminal regularly, you can cultivate a sense of satisfaction, increase motivation, and find enjoyment in completing tasks. It can also help you create and maintain an organized living space to enhance your overall well-being.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Housework Productivity and Enjoyment subliminal?`, 
    answer: `The time it takes to see results can vary from person to person. Some people may notice positive changes in their mindset and behavior towards housework relatively quickly, while others may require more time and consistent listening to fully reprogram their thoughts and attitudes. We recommend listening to the subliminal daily and consistently for best results.`,
    open: false 
  },
  { 
    question: `Can the Housework Productivity and Enjoyment subliminal help me with procrastination?`, 
    answer: `Yes, the Housework Productivity and Enjoyment subliminal can help you overcome procrastination by influencing your subconscious mind to adopt a more motivated and positive mindset towards household chores. Listening to the subliminal regularly can help you develop effective strategies to overcome procrastination habits and find satisfaction in completing tasks.`,
    open: false 
  },
  { 
    question: `How can I make the Housework Productivity and Enjoyment subliminal more effective?`, 
    answer: `To make the Housework Productivity and Enjoyment subliminal more effective, create a routine of listening to it consistently. You can also enhance its effectiveness by breaking down tasks into smaller, manageable steps, setting achievable goals, and rewarding yourself for completing tasks. Developing effective organization and time management skills can also complement the subliminal's effects, helping you become more productive and enjoy your housework.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Housework Productivity and Enjoyment subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. It is designed to promote positive mindset and attitudes towards housework. However, if you have any concerns or specific health conditions, please consult with your healthcare provider. It is important to note that the subliminal is intended to supplement and support your efforts in improving your productivity and enjoyment while doing household chores, not replace them.`,
    open: false 
  },
  { 
    question: `Can the Housework Productivity and Enjoyment subliminal replace proper cleaning practices?`, 
    answer: `No, the Housework Productivity and Enjoyment subliminal is not intended to replace proper cleaning practices. It is designed to help you cultivate a positive mindset and attitudes towards household chores. However, it is still important to follow proper cleaning techniques and maintain a clean and organized living space for hygiene and well-being.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>