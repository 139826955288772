<template>
  <div class="mr-1">
    <el-upload ref="upload" :class="`${uploads ? ' upload' : ''}`" :action="`${url}/api/local/image`"
      :file-list="fileList" list-type="picture-card" :limit="1" :multiple="false" :on-change="handleChange"
      :on-remove="handleRemove">
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{ file }">
        <img class="el-upload-list__item-thumbnail" :src="file.url" />
        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-preview" @click="handlePreview(file)">
            <i class="el-icon-zoom-in"></i>
          </span>
          <span v-if="!deleted" class="el-upload-list__item-delete" @click="handleRemove(file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialog" append-to-body :close-on-click-modal="false">
      <img width="100%" :src="image" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import { url } from '@/utils'
import { deleteLocalImage } from '@/api/file.js'
export default {
  props: {
    upload: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    files: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      url,

      deleteLocalImage,
      file: '',
      fileList: [],

      image: '',
      dialog: false,
      deleted: false,
      uploads: false
    }
  },
  mounted() {
    this.uploads = this.upload
    this.fileList = this.files
  },
  methods: {
    handleChange(file) {
      console.log(file)
      this.uploads = true
      this.file = file.name
      this.$emit('getImageName', this.file)
    },
    handleRemove(file) {
      this.deleteLocalImage(file.name)

      this.$refs.upload.clearFiles()
      this.uploads = false
      this.fileList = []
      this.$emit('getImageName', '')
    },
    handlePreview(file) {
      this.image = file.url
      this.dialog = true
    }
  }
}
</script>

<style>
.upload>div {
  margin: 0px;
  padding: 0px;
}

.upload .el-upload {
  display: none;
}
</style>
