<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Toxic shame can be incredibly damaging to your self-esteem and overall well-being. This subliminal is designed to help you release and heal from toxic shame, allowing you to live a life free from self-blame and guilt."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support your healing process:'
    },
    {
      type: 'list',
      items: [
        "Encouraging self-compassion and self-acceptance, helping you let go of negative self-judgment.",
        "Promoting forgiveness and understanding towards yourself, allowing you to move past past mistakes and regrets.",
        "Boosting your self-worth and self-esteem, helping you recognize your inherent value and worthiness.",
        "Supporting the development of healthier coping mechanisms and strategies for dealing with shame triggers.",
        "Empowering you to embrace self-love and create a positive, nurturing relationship with yourself."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can rewire your subconscious mind and release the grip of toxic shame. As you heal and let go of self-blame and guilt, you can experience greater self-acceptance, happiness, and inner peace. Remember, healing from toxic shame is a journey, but you have the power to overcome it and live a life filled with self-love and compassion."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Healing from toxic shame is a transformative journey towards self-acceptance and emotional freedom. This subliminal is designed to support you in releasing and healing from the burden of toxic shame. Before you start your listening session, consider the following preparation tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Clarify your intention to release the grip of toxic shame and cultivate self-compassion and self-acceptance.",
        "Create a safe space: Find a quiet, comfortable place where you can relax and focus on your healing journey without distractions.",
        "Practice self-care: Prioritize self-nurturing activities that can support your emotional well-being, such as journaling, meditation, or talking to a trusted friend.",
        "Visualize your healing: Imagine yourself free from the weight of toxic shame and visualize the positive changes and emotions you wish to experience."
      ]
    },
    {
      type: 'paragraph',
      text: "As you embark on your path to healing, this subliminal will gently guide your subconscious mind towards releasing toxic shame, fostering self-forgiveness, and embracing self-love. Trust in your innate capacity for healing, and allow these subliminal affirmations to support you on your journey."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Toxic Shame Healing subliminal session! By taking this step towards releasing and healing from toxic shame, you are on your way to living a life free from self-blame and guilt. Now, let's reflect on your experience and continue your journey towards self-acceptance and healing."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice self-compassion: Be kind and forgiving towards yourself, acknowledging that you are deserving of love and acceptance.",
        "Seek support: Reach out to a therapist or support group to help you further process and heal from toxic shame.",
        "Challenge negative thoughts: Replace self-blaming thoughts with more positive and realistic ones.",
        "Mindfulness and grounding techniques: Practice mindfulness and grounding exercises to help you stay present and connected to yourself in moments of shame."
      ]
    },
    {
      type: 'paragraph',
      text: "Healing from toxic shame is a courageous journey that requires patience and self-compassion. Remember, you are not defined by your past experiences or mistakes. Embrace the process of healing, and know that you deserve to live a life filled with self-love, acceptance, and freedom from shame. Keep moving forward!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Toxic Shame Healing subliminal help me?`, 
    answer: `The Toxic Shame Healing subliminal is designed to help you release and heal from toxic shame, a deep-rooted feeling of worthlessness and self-blame. By listening to the subliminal messages, you can reprogram your subconscious mind to let go of harmful beliefs and emotions associated with shame. Over time, it can lead to increased self-acceptance, self-love, and a healthier sense of self-worth.`,
    open: false
  },
  { 
    question: `How long does it take to heal from toxic shame with the subliminal?`, 
    answer: `The healing process from toxic shame is unique to each individual and can vary in duration. It depends on various factors such as the severity of the shame, personal resilience, and consistency in listening to the subliminal. It is recommended to listen to the subliminal regularly and give yourself time and patience as you work through the healing process.`,
    open: false 
  },
  { 
    question: `Can the Toxic Shame Healing subliminal replace therapy or professional help?`, 
    answer: `The Toxic Shame Healing subliminal can be a valuable tool in your journey towards healing, but it is not a substitute for professional therapy or help. It complements therapeutic interventions by providing additional support and reinforcement. If you are struggling with toxic shame, it is recommended to seek professional help from a therapist or counselor who specializes in trauma and shame healing.`,
    open: false 
  },
  { 
    question: `What are some other ways to support the healing process alongside the subliminal?`, 
    answer: `Alongside listening to the Toxic Shame Healing subliminal, there are other ways to support your healing process. Engaging in therapy or counseling, practicing self-care, and building healthy support systems are important in healing from toxic shame. Additionally, journaling, meditation, and self-reflection can help uncover and process underlying emotions and beliefs associated with shame.`,
    open: false 
  },
  { 
    question: `Can the Toxic Shame Healing subliminal trigger difficult emotions?`, 
    answer: `The Toxic Shame Healing subliminal may bring up difficult emotions as part of the healing process. It is important to listen to the subliminal with self-compassion and seek additional support if needed. If you find the emotions overwhelming or distressing, consider reaching out to a mental health professional who can help guide you through the healing journey.`,
    open: false 
  },
  { 
    question: `Is it normal to experience setbacks during the healing process with the subliminal?`, 
    answer: `Yes, setbacks can be a normal part of the healing process when working through toxic shame. Healing is not linear, and it is natural to experience ups and downs along the way. It is important to practice self-kindness, patience, and perseverance as you navigate the journey towards healing and self-acceptance.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>