<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you struggle with stuttering, it can have a significant impact on your confidence and daily interactions. This subliminal is designed to help you overcome stuttering and speak confidently and fluently."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Reducing stuttering and improving your ability to communicate effectively.",
        "Eliminating the fear, anxiety, and self-consciousness associated with stuttering.",
        "Boosting your confidence in social situations and public speaking.",
        "Helping you develop a more relaxed and natural speaking rhythm.",
        "Improving your overall communication skills and enhancing your relationships."
      ]
    },
    {
      type: 'paragraph',
      text: "Through subconscious programming, this subliminal can help rewire your brain to reduce stuttering and increase your confidence in speaking. As you listen regularly, you may notice significant improvements in your speech fluency and a reduction in the negative emotions associated with stuttering. Remember, progress takes time and practice, but with dedication, you can achieve lasting results."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Overcoming stuttering and speaking confidently and fluently is within your reach, and this subliminal can help you achieve it. Before you start listening, here are some tips to maximize the effectiveness of this program:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Clearly define your goal of speaking confidently and fluently, free from the limitations of stuttering.",
        "Practice positive self-talk: Replace negative thoughts or beliefs with positive affirmations about your speech and communication abilities.",
        "Visualize success: Imagine yourself speaking fluently and confidently in various situations, creating a vivid mental image of your success.",
        "Focus on your intention: Keep your goal and desire to overcome stuttering in mind while listening to the subliminal affirmations."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to rewire your subconscious mind and help you eliminate the fear and anxiety associated with stuttering. By listening regularly, you can develop more fluent speech patterns and boost your confidence in communicating with others. Embrace this opportunity for growth, set your intention, and let the journey to confident, fluent speech begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Stop Stuttering subliminal session! By committing to improving your speech fluency and confidence, you've taken an important step towards overcoming stuttering and living a more fulfilling life. Now, it's time to reflect on your journey and continue your progress."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice speaking: Engage in regular speaking exercises to build confidence and fluency.",
        "Seek support: Consider joining a support group or seeking professional help to assist you on your journey.",
        "Control anxiety: Develop techniques to manage anxiety or stress that can exacerbate stuttering.",
        "Celebrate progress: Recognize and celebrate the improvements you've made in your speech."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming stuttering is a process that takes time and effort. Be patient with yourself and celebrate every milestone along the way. Never forget that you have the power to speak confidently and fluently. Keep pushing forward, and embrace the journey ahead!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Stop Stuttering subliminal work?`, 
    answer: `The Stop Stuttering subliminal works by delivering positive affirmations directly to your subconscious mind. These affirmations help rewire your thought patterns and beliefs about speaking, allowing you to overcome stuttering and speak confidently and fluently. By consistently listening to the subliminal, you can gradually reduce fear and anxiety associated with stuttering and develop a more relaxed and natural speaking style.`,
    open: false
  },
  { 
    question: `How long will it take for the Stop Stuttering subliminal to be effective?`, 
    answer: `The time it takes to see results with the Stop Stuttering subliminal may vary from person to person. Some individuals may experience positive changes almost immediately, while others may require more time and consistent listening to reprogram their mindset towards fluent speech. Consistency is key, and we recommend listening to the subliminal regularly to optimize its effectiveness.`,
    open: false 
  },
  { 
    question: `Is the Stop Stuttering subliminal a substitute for speech therapy or professional intervention?`, 
    answer: `No, the Stop Stuttering subliminal is not a substitute for speech therapy or professional intervention. It is designed to complement existing therapy or self-help practices by addressing the mental and emotional aspects of stuttering. If you have a severe stutter or underlying speech disorders, it is important to consult with a speech therapist or healthcare professional for comprehensive guidance.`,
    open: false 
  },
  { 
    question: `Can the Stop Stuttering subliminal be used by children or teenagers?`, 
    answer: `Yes, the Stop Stuttering subliminal can be used by children and teenagers. However, it is recommended to consult with a parent or guardian before incorporating the subliminal into their routine. For children or teenagers with serious speech difficulties, it is important to involve a speech therapist or healthcare professional in their treatment plan.`,
    open: false 
  },
  { 
    question: `Are there any side effects of listening to the Stop Stuttering subliminal?`, 
    answer: `No, there are no known side effects associated with listening to the Stop Stuttering subliminal. It is a safe and non-invasive tool for reprogramming your mindset towards fluent speech. If you have any concerns about your specific situation, it is recommended to consult with a healthcare professional.`,
    open: false 
  },
  { 
    question: `Can the Stop Stuttering subliminal be used alongside other speech therapy techniques?`, 
    answer: `Yes, the Stop Stuttering subliminal can be used alongside other speech therapy techniques. It can help reinforce the positive changes and progress made during therapy sessions. Combining the subliminal with professional intervention can provide a comprehensive approach to overcoming stuttering and achieving fluent speech.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>