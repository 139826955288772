<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Unlock your limitless imagination and enhance your creative thinking skills with this subliminal. By targeting your subconscious mind, it can help you tap into your creative potential and foster innovation."
    },
    {
      type: 'heading',
      text: 'Benefits of using this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Boosting your ability to think outside the box and generate unique ideas.",
        "Enhancing problem-solving skills and developing innovative approaches.",
        "Increasing confidence and self-belief in your creative abilities.",
        "Stimulating inspiration and fostering a proactive mindset.",
        "Breaking through creative blocks and overcoming fear of failure."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your daily routine, you can rewire your subconscious mind to embrace and enhance your creative thinking skills. As you continue to listen, you may find yourself becoming more open-minded, innovative, and inventive. Remember, creativity is a skill that can be developed, and this subliminal can be a valuable tool in your creative journey."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Unleashing your creative thinking skills can open doors to new possibilities and transformations. This subliminal is designed to help you enhance your creativity, imagination, and innovation. Before you begin the session, consider these strategies to maximize your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a creative space: Find a comfortable and inspiring environment where your mind can freely explore and generate ideas.",
        "Clear your mind: Engage in a short mindfulness or meditation practice to quiet any mental clutter and create a focused state of mind.",
        "Set clear goals: Determine what specific aspect of your creative thinking you want to enhance, such as brainstorming, problem-solving, or finding unique solutions.",
        "Embrace curiosity: Approach this subliminal session with an open and curious mindset, ready to explore new ideas and think outside the box."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you may notice your ability to think creatively expanding and your imagination flourishing. Embrace the process, trust in your innate creativity, and allow this subliminal to unlock your limitless potential. Now, find a tranquil space, set your creative goals, and let your imagination soar."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Creative Thinking session! By focusing on enhancing your creative thinking skills, you've taken a step towards unlocking your limitless imagination and innovation. Reflecting on this experience and considering how it can positively impact various aspects of your life is key as you move forward."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Embrace new perspectives: Challenge yourself to see situations from different angles, fostering creativity.",
        "Engage in creative exercises: Practice brainstorming, mind mapping, and other techniques to encourage divergent thinking.",
        "Seek inspiration: Surround yourself with art, music, literature, and nature to stimulate your imagination.",
        "Apply creativity in everyday life: Find innovative solutions to problems, and try new hobbies or activities."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, creativity is a skill that can be improved through practice and exploration. Embrace the opportunities to expand your imagination and approach challenges with fresh perspectives. Let your creativity flow in all areas of your life, and watch as you unlock new possibilities and infinite potential!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Creative Thinking subliminal help me enhance my creativity?`, 
    answer: `The Creative Thinking subliminal is designed to stimulate and activate your subconscious mind, allowing you to tap into your limitless creativity. By listening to the subliminal regularly, you can train your brain to think more creatively, expand your imagination, and unlock innovative ideas. It can help you break through mental blocks and approach problem-solving in new and unique ways.`,
    open: false
  },
  { 
    question: `How long does it take to see improvements in creative thinking?`, 
    answer: `The time it takes to see improvements in creative thinking can vary from person to person. For some, the effects may be immediate, while for others, it may require consistent listening over a period of time. We recommend listening to the Creative Thinking subliminal daily and consistently to maximize its potential for enhancing your creative abilities.`,
    open: false 
  },
  { 
    question: `Can the Creative Thinking subliminal be used for specific creative endeavors?`, 
    answer: `Yes, the Creative Thinking subliminal can be used to enhance your creative thinking skills in any specific area or creative endeavor. The subliminal messages can help you overcome creative blocks, generate new ideas, and approach your chosen field of creativity with a fresh perspective. Whether you are an artist, writer, musician, or entrepreneur, the subliminal can assist in unlocking your creative potential.`,
    open: false 
  },
  { 
    question: `Should I listen to the Creative Thinking subliminal while working on creative tasks?`, 
    answer: `While it is not necessary to listen to the Creative Thinking subliminal while actively working on creative tasks, you may find it beneficial to incorporate it into your daily routine. Listening to the subliminal during quiet periods or before engaging in creative activities can help prime your mind for enhanced creative thinking and inspiration.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Creative Thinking subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Creative Thinking subliminal. However, it is important to note that the subliminal is designed to supplement your creative endeavors, not replace them. It is recommended to combine the subliminal with regular practice, exploration, and learning in your chosen creative field to fully unlock your creative potential.`,
    open: false 
  },
  { 
    question: `Can the Creative Thinking subliminal help with overcoming creative blocks?`, 
    answer: `Yes, the Creative Thinking subliminal is specifically designed to help you overcome creative blocks. By influencing your subconscious mind, it can break through barriers and limitations that may be hindering your creative thinking. Consistent listening to the subliminal can help you tap into your unlimited creative potential and find new solutions to challenges.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>