<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're looking to improve your teaching skills and make a bigger impact in the lives of your students, this subliminal can help you achieve that goal. By targeting your subconscious mind, it can enhance your abilities as an educator."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Increasing your confidence and self-belief as a teacher.",
        "Improving your classroom management skills and creating a positive learning environment.",
        "Enhancing your communication and presentation skills to effectively convey knowledge to your students.",
        "Boosting your creativity and innovation in lesson planning and teaching methods.",
        "Helping you connect with students on a deeper level and inspire them to reach their full potential."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can rewire your subconscious mind to align with your goals of becoming a successful and impactful educator. As a result, you may notice improvements in your teaching abilities, student engagement, and overall satisfaction in your role as a teacher. Remember, continuous growth and learning are keys to becoming the best teacher you can be."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Becoming a successful teacher requires dedication, growth, and a passion for education. This subliminal is designed to help you enhance your teaching skills and make a positive impact in the lives of your students. Before you start listening, here are a few tips to maximize your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Identify specific areas you want to improve as a teacher, such as classroom management, lesson planning, or student engagement.",
        "Reflect on your teaching style: Consider your strengths and areas for growth, and be open to adopting new strategies and techniques.",
        "Stay motivated: Remind yourself of the importance of your role as an educator and the positive impact you can have on students' lives.",
        "Be present in the moment: While listening to the subliminal affirmations, focus your attention on embracing new ideas and concepts to enhance your teaching skills."
      ]
    },
    {
      type: 'paragraph',
      text: "As you embark on this journey to become a successful teacher, remember that growth takes time and effort. Regular listening to this subliminal can help you develop the mindset and skills necessary for impactful teaching. Embrace the opportunity to enrich your career and make a lasting difference in the lives of your students. Let's begin!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Become a Successful Teacher subliminal session! By focusing on enhancing your teaching skills and becoming a successful educator, you've taken a significant step towards making a positive impact on the lives of your students. Reflecting on your experience and continuing your personal growth is essential as you progress on your teaching journey."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Self-reflection: Take time to reflect on your teaching experiences, both successes and challenges, and identify areas for improvement.",
        "Continuing education: Stay updated on the latest teaching techniques and methods through professional development opportunities, workshops, and courses.",
        "Observe other teachers: Attend classes or observe experienced teachers to gain insights and inspiration for your own teaching style.",
        "Connect with other educators: Join professional networks, attend conferences, or participate in online teacher communities to share ideas, resources, and support."
      ]
    },
    {
      type: 'paragraph',
      text: "Becoming a successful teacher is a lifelong journey of learning and growth. Embrace your passion for education, stay dedicated to your students, and continue refining your skills. Remember, you have the power to positively impact the lives of your students and shape future generations. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  {
    question: `How can the "Become a Successful Teacher" subliminal help me enhance my teaching skills?`,
    answer: `The "Become a Successful Teacher" subliminal is designed to reinforce positive beliefs and attitudes about teaching, boost confidence in your abilities, and improve communication and classroom management skills. By consistently listening to the subliminal audio, you can align your subconscious mind with qualities and behaviors that contribute to successful teaching, such as patience, creativity, and effective lesson planning. It can also help improve your ability to connect with students and create a positive learning environment.`,
    open: false
  },
  {
    question: `How long does it take to see results from listening to the "Become a Successful Teacher" subliminal?`,
    answer: `Results may vary, but with consistent listening, some individuals may start noticing positive changes in their teaching skills within a few weeks. However, it's important to remember that individual responses may differ, and it may take longer for certain changes to manifest. The subliminal is designed to gradually reprogram your mindset, so continued practice is important for long-lasting results.`,
    open: false
  },
  {
    question: `Can the "Become a Successful Teacher" subliminal replace professional development or training for teachers?`,
    answer: `No, the "Become a Successful Teacher" subliminal is not intended to replace professional development or training programs for teachers. It is designed to complement and enhance your existing skills and attributes as an educator. Continuing professional development and training are essential for staying updated on best practices and acquiring new knowledge in the field of education.`,
    open: false
  },
  {
    question: `Is it necessary to listen to the subliminal audio every day for it to be effective?`,
    answer: `Consistency is key for the "Become a Successful Teacher" subliminal to be most effective. Regular daily listening, preferably at a time when you can relax and focus solely on the audio, can help reprogram your subconscious mind and reinforce positive beliefs and behaviors related to teaching. The more you listen, the more likely you are to see positive changes in your teaching skills and overall effectiveness as an educator.`,
    open: false
  },
  {
    question: `Are there any risks or side effects of listening to the "Become a Successful Teacher" subliminal?`,
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your teaching career or mental well-being, it is recommended to consult with a professional. The subliminal is intended to supplement your personal growth as a teacher and should not replace any necessary professional support or guidance.`,
    open: false
  },
  {
    question: `Can the "Become a Successful Teacher" subliminal help with classroom management and student behavior?`,
    answer: `Yes, the "Become a Successful Teacher" subliminal can help improve classroom management skills and positively influence student behavior. By aligning your subconscious mind with qualities like patience, assertiveness, and effective communication, you may find it easier to establish a positive learning environment and manage student behavior more effectively. However, it's important to note that classroom management techniques and strategies should also be complemented by ongoing professional development and practical experience.`,
    open: false
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>