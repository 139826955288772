<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Whether you're a beginner or an experienced player, this subliminal is designed to help you enhance your baseball skills and reach your fullest potential on the field. By targeting your subconscious mind, it can ignite your motivation, focus, and determination to become a master player."
    },
    {
      type: 'heading',
      text: 'Key aspects covered in this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Improving your batting technique, accuracy, and power.",
        "Enhancing your fielding skills, including agility, speed, and precision.",
        "Boosting your throwing and pitching abilities.",
        "Increasing your overall baseball IQ and strategic thinking on the field.",
        "Building mental resilience, confidence, and focus during games and practices."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to align with your goals as a baseball player. As a result, you may notice improvements in your performance, agility, and overall understanding of the game. Remember, consistent practice and dedication are also crucial in refining your skills and becoming a master player."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Are you ready to take your baseball skills to the next level? This subliminal is designed to help you improve your skills and become a master player. Before you start your training with this subliminal, here are some tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set specific goals: Identify the areas of your baseball skills that you want to improve, such as hitting, fielding, or pitching.",
        "Create a training plan: Develop a structured training routine that includes drills and exercises targeting your specific goals.",
        "Visualize success: Imagine yourself confidently executing the skills you want to improve. Visualize yourself playing at a high level and achieving your goals.",
        "Stay focused: While listening to the subliminal, maintain a clear focus on your training goals and visualize yourself improving and mastering the skills."
      ]
    },
    {
      type: 'paragraph',
      text: "By utilizing this subliminal, you can tap into your subconscious mind and enhance your ability to learn and master baseball skills. With consistent training and dedication, you can achieve your goals and become a standout player on the field. So get ready to give it your all, set your goals, and let the journey to baseball mastery begin!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Baseball Skills Training and Mastery subliminal! By investing time and effort in improving your baseball skills, you've taken a significant step towards becoming a master player. Now, it's time to reflect on your progress and determine the next steps on your journey to excellence."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Analyze your performance: Assess your strengths and areas for improvement to develop a targeted practice plan.",
        "Set goals: Define specific and measurable goals to strive for in your baseball training.",
        "Practice consistently: Dedicate regular time to practice drills, techniques, and game scenarios to hone your skills.",
        "Seek feedback: Collaborate with coaches, teammates, or mentors for valuable insights and guidance."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, mastering baseball skills is a process that requires dedication and perseverance. Embrace the challenges as opportunities for growth, and stay committed to your training routine. By continually pushing yourself and seeking ways to improve, you'll inch closer to becoming the master player you aspire to be. Play ball!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Baseball Skills Training and Mastery program help me improve my baseball skills?`, 
    answer: `The Baseball Skills Training and Mastery program is designed to enhance your baseball skills by providing effective training techniques and strategies. The program includes drills, exercises, and mental focus techniques that can help you improve your batting, pitching, fielding, and overall performance on the field. By consistently practicing the techniques taught in the program, you can develop the necessary skills to become a master baseball player.`,
    open: false
  },
  { 
    question: `How long does it take to see improvements in my baseball skills using this program?`, 
    answer: `The time it takes to see improvements in your baseball skills using the Baseball Skills Training and Mastery program may vary depending on several factors, including your current skill level, dedication to practice, and consistency in following the program. While some players may see immediate improvements, others may require more time and practice to see significant progress. The key is to remain committed to the training techniques and consistently work on developing your skills.`,
    open: false 
  },
  { 
    question: `Do I need any special equipment or facilities to practice the techniques in this program?`, 
    answer: `The Baseball Skills Training and Mastery program can be practiced in various settings, including baseball fields, batting cages, or even in your backyard. While having access to basic baseball equipment such as bats, gloves, and baseballs is beneficial, many of the techniques can be practiced using common household items or improvised equipment. The program focuses on developing fundamental skills and can be adapted to suit different resources and facilities.`,
    open: false 
  },
  { 
    question: `Can this program help me improve my performance in specific baseball positions?`, 
    answer: `Yes, the Baseball Skills Training and Mastery program is designed to help you improve your performance in various baseball positions, including batting, pitching, fielding, and base running. The program provides position-specific drills and exercises that target the skills required for each position. By consistently practicing these position-specific techniques, you can enhance your performance and proficiency in your desired baseball positions.`,
    open: false 
  },
  { 
    question: `Is this program suitable for beginners who are new to baseball?`, 
    answer: `Yes, the Baseball Skills Training and Mastery program is suitable for beginners who are new to baseball. The program covers the fundamentals of the game and provides step-by-step guidance on developing essential baseball skills. Whether you are a beginner or an experienced player looking to improve your skills, this program can help you enhance your performance and become a better baseball player.`,
    open: false 
  },
  { 
    question: `Are there any risks of injury associated with the training techniques in this program?`, 
    answer: `While the Baseball Skills Training and Mastery program focuses on safe and effective training techniques, there is always a risk of injury when participating in physical activities, including baseball. It is important to follow proper form, warm-up before training, and listen to your body to prevent injuries. If you have any pre-existing medical conditions or concerns, it is advisable to consult with a healthcare professional before starting any training program.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>