import { mapGetters } from "vuex";
export const audio = {
  computed: {
    ...mapGetters({
      subliminalIndex: "newaudio/getSubliminalIndex",
      trackURLs: "newaudio/getTrackURLs",
      volumes: "newaudio/getVolumes",
      audioTypes: "newaudio/getAudioTypes",
      durationTimes: "newaudio/getDurationTimes",
      isAudioPlaying: "newaudio/getPlayingStatus",
      isAudioLoop: "newaudio/getLoopStatus",
      isAudioSkip: "newaudio/getSkipStatus",
      sources: "newaudio/getSources",
      context: "newaudio/getContext",
      buffers: "newaudio/getBuffers",
      gains: "newaudio/getGains",
    }),
    playlistTracks() {
      return this.$store.state.audio.playlist.tracks;
    },
    recentplayed() {
      return this.$store.state.recent.played.data;
    },
    subliminals() {
      if (this.playlistTracks != undefined && this.playlistTracks != null) {
        if (Array.isArray(this.playlistTracks)) {
          return this.playlistTracks.length > 0 ? this.playlistTracks : [];
        } else {
          return Object.keys(this.playlistTracks).length > 0
            ? this.playlistTracks.subliminals
            : [];
        }
      }

      return [];
    },
    isPrevDisabled() {
      return this.isDataReady ? this.subliminalIndex == 0 : true;
    },
    isNextDisabled() {
      return this.isDataReady
        ? this.subliminalIndex == this.subliminals.length - 1
        : true;
    },
    durationTime() {
      return this.durationTimes.length == this.trackURLs.length &&
        this.durationTimes.length != 0 &&
        this.trackURLs.length != 0
        ? Math.max(...this.durationTimes)
        : 0;
    },
    title() {
      return this.subliminals.length > 0
        ? this.subliminals[this.subliminalIndex].title
        : this.recentplayed[0].title;
    },
    category() {
      return this.subliminals.length > 0
        ? this.subliminals[this.subliminalIndex].category_name
        : this.recentplayed[0].category_name;
    },
    cover() {
      return this.subliminals.length > 0
        ? this.subliminals[this.subliminalIndex].cover
        : this.recentplayed[0].cover;
    },
  },
  methods: {
    initializeData(data, index = 0) {
      const isAudioSkipped = this.isAudioSkipped;

      if (this.isAudioPlaying) {
        this.$store.dispatch("newaudio/currentTime", 0);
        this.pauseAudio();
      }

      let trackURLs = [];

      this.$store.dispatch("newaudio/reset");
      this.$store.dispatch("newaudio/subliminalIndex", index);

      if (isAudioSkipped) {
        this.$store.dispatch("newaudio/skippedStatus", true);
      }

      if (Array.isArray(data) && data.length > 0) {
        if (data[index].hasOwnProperty("subliminal_id")) {
          const subliminals = data[index];
          const volumes = subliminals.volume.split(",").map(function (item) {
            return parseInt(item);
          });

          for (let index = 0; index < volumes.length; index++) {
            const volume = volumes[index];
            const audiotype = subliminals.tracks[index].audio_type_id;
            this.$store.dispatch("newaudio/audioTypes", audiotype);
            this.$store.dispatch("newaudio/volumes", volume);
          }

          for (let i = 0; i < subliminals.tracks.length; i++) {
            const element = subliminals.tracks[i];
            trackURLs.push(element.link);
          }
        }
      } else {
        if (data.hasOwnProperty("playlist_id")) {
          const subliminals = data.subliminals[index];

          const volumes = subliminals.volume.split(",").map(function (item) {
            return parseInt(item);
          });

          for (let index = 0; index < volumes.length; index++) {
            const volume = volumes[index];
            const audiotype = subliminals.tracks[index].audio_type_id;
            this.$store.dispatch("newaudio/audioTypes", audiotype);
            this.$store.dispatch("newaudio/volumes", volume);
          }

          for (let i = 0; i < subliminals.tracks.length; i++) {
            const element = subliminals.tracks[i];
            trackURLs.push(element.link);
          }
        }
      }

      this.$store.dispatch("newaudio/trackURLs", trackURLs);
      this.setUpAudioContext();
    },
    setUpAudioContext() {
      const context = new (window.AudioContext || window.webkitAudioContext)();
      this.$store.dispatch("newaudio/context", context);
      const trackURLs = this.trackURLs;

      for (let index = 0; index < trackURLs.length; index++) {
        const url = trackURLs[index];
        this.sendRequest(url);
      }
    },
    sendRequest(url) {
      let self = this;
      const request = new XMLHttpRequest();
      request.open("GET", url, true);
      request.responseType = "arraybuffer";
      request.onload = function () {
        self.context.decodeAudioData(
          request.response,
          self.onBufferLoad,
          self.onBufferError
        );
      };
      request.send();
    },
    onBufferLoad(buffer) {
      this.$store.dispatch("newaudio/buffers", buffer);
      this.$store.dispatch("newaudio/durationTimes", parseInt(buffer.duration));

      this.createBufferSource(buffer);
    },
    onBufferError(e) {
      console.log("onBufferError", e);
    },
    toggleAudio() {
      if (!this.isDataReady) {
        return;
      }

      if (this.isAudioPlaying) {
        this.pauseAudio();
        this.$store.dispatch("newaudio/playingStatus", false);
      } else {
        this.playAudio();
        this.$store.dispatch("newaudio/playingStatus", true);
      }
    },
    createBufferSource(buffer) {
      let sources = this.context.createBufferSource();
      sources.buffer = buffer;

      let gains = this.context.createGain();

      sources.connect(gains);
      gains.connect(this.context.destination);

      this.$store.dispatch("newaudio/sources", sources);
      this.$store.dispatch("newaudio/gains", gains);
    },
    playAudio() {
      this.$store.dispatch("newaudio/resetsources", []);
      this.$store.dispatch("newaudio/resetgains", []);
      for (let index = 0; index < this.trackURLs.length; index++) {
        this.createBufferSource(this.buffers[index]);
        if (this.currentTime > 0) {
          this.sources[index].start(0, this.currentTime);
        } else {
          this.sources[index].start(0);
        }

        this.gains[index].gain.setValueAtTime(this.volumes[index] / 100, 0);
      }
    },
    pauseAudio() {
      for (let index = 0; index < this.trackURLs.length; index++) {
        this.sources[index].stop(0);
      }
    },
    skipAudio(isNext = true) {
      if (!this.isDataReady) {
        return;
      }

      if (isNext && this.isNextDisabled) {
        return;
      }

      if (!isNext && this.isPrevDisabled) {
        return;
      }
      console.log("skipAudio");
      this.$store.dispatch("newaudio/loopStatus", false);
      this.$store.dispatch("newaudio/skippedStatus", true);
      if (this.isAudioPlaying) {
        this.pauseAudio();
      }

      if (isNext) {
        this.nextAudio();
      } else {
        this.previousAudio();
      }

      this.initializeData(this.playlistTracks, this.subliminalIndex);
    },
    nextAudio() {
      let subliminalIndex = this.subliminalIndex;
      if (subliminalIndex < this.subliminals.length) {
        this.$store.dispatch(
          "newaudio/subliminalIndex",
          parseInt((subliminalIndex += 1))
        );
      }
    },
    previousAudio() {
      let subliminalIndex = this.subliminalIndex;
      if (subliminalIndex > 0) {
        this.$store.dispatch(
          "newaudio/subliminalIndex",
          parseInt((subliminalIndex -= 1))
        );
      }
    },
    changeVolume(index) {
      const volume = this.volumes[index] / 100;
      this.gains[index].gain.linearRampToValueAtTime(volume, 0);
    },
    changeAllVolume(volumes) {
      this.$store.dispatch("newaudio/resetvolumes", []);
      const volume = volumes / 100;

      for (let index = 0; index < this.gains.length; index++) {
        this.$store.dispatch("newaudio/volumes", volumes);
        this.gains[index].gain.linearRampToValueAtTime(volume, 0);
      }
    },
    changeCurrentTime(val) {
      if (typeof parseInt(val) == "number") {
        const current_time = (parseInt(val) * this.durationTime) / 100;
        this.$store.dispatch("newaudio/currentTime", parseInt(current_time));
      }

      if (this.isAudioPlaying) {
        this.toggleAudio();
        setTimeout(() => {
          this.toggleAudio();
        }, 100);
      }
    },
  },
};
