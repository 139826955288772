<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Embark on a journey to master the ancient Chinese martial art of Bajiquan and unlock your full combat potential. This subliminal is designed to support your training and help you develop powerful strikes and enhance your combat skills through practice and dedication."
    },
    {
      type: 'heading',
      text: 'Key aspects of this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Developing a strong foundation of Bajiquan techniques and principles.",
        "Improving your striking power, speed, and accuracy.",
        "Enhancing your footwork, balance, and agility for effective maneuvering.",
        "Building mental focus, discipline, and perseverance required for mastery.",
        "Expanding your combat knowledge and understanding of Bajiquan strategies.",
        "Gaining confidence in your martial arts skills and abilities."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal and practicing Bajiquan, you can accelerate your progress and unlock your true potential in this martial art. Remember, mastery takes time and dedication, so embrace the journey and commit to consistent training. With each session, you move closer to becoming a skilled and formidable practitioner of Bajiquan."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on the journey to master the ancient Chinese martial art of Bajiquan requires dedication, discipline, and a clear focus. This subliminal is designed to support your training and help you enhance your combat skills. Before you begin, consider these preparation tips to maximize your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your goals: Define what you want to achieve through your Bajiquan training, whether it's mastering specific strikes, improving your agility, or becoming a well-rounded martial artist.",
        "Establish a training routine: Consistency is key. Develop a regular practice schedule and commit to it.",
        "Visualize success: Picture yourself executing powerful, precise strikes and embodying the spirit of Bajiquan.",
        "Focus your mind: Clear your thoughts and maintain a mindset of perseverance and determination while listening to the subliminal affirmations."
      ]
    },
    {
      type: 'paragraph',
      text: "By immersing yourself in this subliminal, you can tap into your untapped potential and unlock new heights in your Bajiquan journey. Train hard, stay focused, and let this audio support your quest for mastery in this ancient martial art. The path to becoming a skilled Bajiquan practitioner starts now." 
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Bajiquan Training and Mastery subliminal session! By dedicating yourself to the practice of this ancient Chinese martial art form, you have taken a significant step towards enhancing your combat skills and developing powerful strikes. Reflecting on your training experience and continuing your martial arts journey is essential for your growth and improvement."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Continue consistent training: Stay committed to regular practice to further refine your techniques and enhance your combat skills.",
        "Set personal goals: Define specific targets for your Bajiquan training to keep yourself motivated and focused.",
        "Seek guidance: Consider working with a qualified instructor who can provide valuable feedback and help you progress.",
        "Practice mindfulness: Develop mental resilience and focus, as it plays a crucial role in mastering Bajiquan."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, mastering Bajiquan is a journey that requires dedication, discipline, and continuous effort. Embrace the challenges, stay motivated, and enjoy the process. With consistent training and a focused mindset, you will unlock your full potential in this ancient martial art. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What is Bajiquan?`, 
    answer: `Bajiquan is an ancient Chinese martial art known for its powerful and explosive strikes. It emphasizes efficiency, directness, and practicality in combat. Bajiquan is characterized by its unique stance, body mechanics, and close-range techniques. It is a highly effective martial art for self-defense and combat situations.`,
    open: false
  },
  { 
    question: `What are the benefits of training in Bajiquan?`, 
    answer: `Training in Bajiquan can provide various benefits, including improved physical fitness, enhanced coordination and balance, increased self-confidence, stress relief, and self-defense skills. It also helps develop discipline, mental focus, and perseverance through rigorous training and practice.`,
    open: false 
  },
  { 
    question: `How long does it take to master Bajiquan?`, 
    answer: `The time it takes to master Bajiquan varies based on individual dedication, training frequency, and natural ability. Mastery of Bajiquan, like any martial art, is a lifelong journey. It requires years of consistent and dedicated practice under the guidance of a knowledgeable instructor. However, with regular training and proper instruction, one can acquire a solid foundation and steadily progress in their skills.`,
    open: false 
  },
  { 
    question: `Is Bajiquan suitable for beginners?`, 
    answer: `Yes, Bajiquan can be learned by beginners. It is important to start with a qualified instructor who can teach you the fundamentals and guide you through proper techniques and training. With regular practice and patience, beginners can develop a strong foundation in Bajiquan and work towards more advanced techniques.`,
    open: false 
  },
  { 
    question: `Is Bajiquan only for self-defense, or can it be used in competitions?`, 
    answer: `While Bajiquan is highly effective for self-defense, it can also be utilized in various combat sports and competitions. Bajiquan techniques such as strikes, throws, joint locks, and sweeps can be adapted for use in sparring, sanda (Chinese kickboxing), and other martial arts competitions. Many practitioners participate in tournaments to test their skills and showcase their abilities.`,
    open: false 
  },
  { 
    question: `Are there any age restrictions for learning Bajiquan?`, 
    answer: `Bajiquan can be learned by people of various ages. However, it is recommended to consult with a qualified instructor to determine the appropriate age for starting training, as it can depend on the individual's physical capabilities and maturity. There are classes available for children, adults, and seniors, each tailored to suit the different needs and abilities of the respective age groups.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>