<template>
  <section>
    <div class="container">
      <div>
        <div>
          <h1>
            We work with the best partners.
          </h1>
          <h5>
            As the most innovative mental health 
            and self-development company, 
            Magus can help you address the 
            mental health and wellness needs of 
            your business. We offer flexibility and 
            convenience to your company with 
            innovative and relevant solutions.
          </h5>
        </div>
        <div>
          <div class="products justify-content-center d-flex">
            <div class="col-4 col-sm-4 col-md-4 col-lg-4" v-for="menu, index in items.slice(start,end)">
              <div @mouseover="mouseover(menu.description, index)" v-if="selected == index" class="text-center">
                <img class="big-img" :src="menu.cover" alt="">
                <h6 class="mt-3" style="color: var(--ma-tufts-blue)">{{ menu.title }}</h6>
              </div>
              <div @mouseover="mouseover(menu.description, index)" v-else class="text-center">
                <img class="small-img" :src="menu.cover" alt="">
                <h6 class="mt-3">{{ menu.title }}</h6>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div>
              <v-icon size="50" @click="prev()">mdi-chevron-left</v-icon>
            </div>
            <p v-html="description" style="text-align: center; padding: 20px;"></p>
            <div>
              <v-icon size="50" @click="next()">mdi-chevron-right</v-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>  
</template>

<script>
import { getMagus } from '@/api/magusdetails'
import SliderForBusiness from '@/components/Slider/SliderForBusiness.vue'

export default {
  components: {
    SliderForBusiness,
  },
  data: () => {
    return {
      getMagus,
      nextButton: true,
      prevButton: false,
      description: '',

      items: [],
      selected: 1,
      start: 0,
      end: 3,
    }
  },
  created() {
    this.getData()
  },
  methods: {
    mouseover(menu, index){
      this.selected = index;
      this.description = menu;
    },
    next(){
      if(this.end != this.items.length && this.end < this.items.length){
        this.start = this.end ;
        this.end = this.start + 3;
        this.prevButton = true;
        this.description=(this.items[this.start+this.selected].description)
      }else{
        this.start = 0 ;
        this.end = this.start + 3;
        this.description=(this.items[this.start+this.selected].description)
      }
    },
    prev(){
      if(this.start != 0){
        this.end = this.start;
        this.start = this.end - 3;
        this.nextButton = true;
        this.description=(this.items[this.start+this.selected].description)
      }else{
        this.end = this.items.length
        this.start = this.end -3 
        this.description=(this.items[this.start+this.selected].description)
      }
    },
    getData() {
      const data = {
        title: 'Partners',
      }
      this.getMagus(data).then((res) => {
        this.items = (res.data[0].info)
        this.description = this.items[this.selected].description;
      })
    },
  }
}
</script>

<style scoped>
  section>.container {
    padding-block: 2em;
    & h1 {
      color: var(--ma-black-olive);
      line-height: 1.35em;
    }
  }
  h1, h5, h4 {
    color: var(--ma-black-olive);
    line-height: 1.35em;
  }
  h5 {
    font-family: "Montserrat-Regular";
  }
  h6 {
    font-family: "Montserrat-SemiBold";
  }
  h4 {
    color: var(--ma-tufts-blue);
  }
  .small-img {
    width: 100px;
    opacity: 50%;
    margin-top: 60px;
    margin-right: auto;
    margin-left: auto;
  }
  .big-img {
    margin-top: 60px;
    width: 100px;
    margin-right: auto;
    margin-left: auto;
  }
  .products {
    height: 15em;
  }


  @media screen and (min-width: 80rem) {

    section>.container {
      @media screen and (min-width: 1200px) {
        text-align: left;
        height: 90vh;

        &>div {
          display: flex;
          align-items: center;
          justify-content: center;
          & > div {
            width: 50%;
          }
        }
        & > div>div {
          height: 70vh;
          margin-top: 30px;
          padding-top: 100px;
        }
      }

      @media screen and (max-width: 1200px) {
        height: auto;
        text-align: center;
        
      }
    }
  }
</style>