<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you struggle with shyness, know that you're not alone. It can be challenging to break out of your shell and feel confident in social situations. This subliminal is designed to help you overcome shyness and build social confidence, allowing you to live a more fulfilling and connected life."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support you:'
    },
    {
      type: 'list',
      items: [
        "Reducing feelings of social anxiety and self-consciousness.",
        "Boosting self-esteem and self-worth, allowing you to feel more comfortable in your skin.",
        "Helping you develop effective communication skills and become a better conversationalist.",
        "Encouraging a positive mindset and belief in your own abilities to socialize and connect with others.",
        "Providing support in stepping out of your comfort zone and trying new social experiences."
      ]
    },
    {
      type: 'paragraph',
      text: "With consistent use, this subliminal can help rewire your subconscious mind, allowing you to let go of shyness and embrace social confidence. As you continue to work on building your social skills, you'll find yourself more at ease in various social settings and enjoying deeper connections with others. Remember, progress takes time, so be patient and kind to yourself throughout the journey."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Overcoming shyness can be challenging, but this subliminal is designed to help you build confidence and live a more connected life. Before you start listening, here are some tips to prepare yourself:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your desire for change: Recognize that you want to overcome shyness and embrace new social experiences.",
        "Set small goals: Start with simple social interactions and gradually challenge yourself to step out of your comfort zone.",
        "Practice positive self-talk: Replace negative thoughts with empowering affirmations that boost your confidence.",
        "Visualize success: Imagine yourself confidently engaging in social situations and forming meaningful connections."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to help rewire your subconscious mind and empower you to overcome shyness. With consistent listening and an open mindset, you can develop the social confidence needed to lead a more fulfilling life. Take a moment to set your intentions, relax, and let the transformation begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Overcoming Shyness subliminal session! You've taken a powerful step towards breaking free from the limitations of shyness and embracing a more fulfilling and connected life. Now, it's time to reflect on your progress and continue cultivating your social confidence."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Challenge yourself: Push beyond your comfort zone and engage in social situations that make you slightly uncomfortable.",
        "Practice active listening: Make an effort to truly listen and engage in conversations, showing interest in others.",
        "Build positive self-talk: Replace negative self-beliefs with empowering thoughts and affirmations.",
        "Seek support: Find a mentor, therapist, or join supportive groups to continue your personal growth journey."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming shyness is a process, and it may take time to fully develop your social confidence. Be patient with yourself and celebrate even the smallest victories. With each step you take towards embracing your true self, you're creating a life filled with authentic connections and meaningful experiences. Keep shining!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Overcoming Shyness subliminal help me?`, 
    answer: `The Overcoming Shyness subliminal is designed to reprogram your subconscious mind and help you overcome shyness by building social confidence. By listening to the subliminal consistently, you can gradually change your mindset and beliefs about yourself, fostering a more positive and outgoing attitude in social situations. Over time, you may find yourself feeling more comfortable and confident when interacting with others.`,
    open: false
  },
  { 
    question: `How long does it take to overcome shyness with the subliminal?`, 
    answer: `The time it takes to overcome shyness with the subliminal can vary from person to person. It depends on several factors, including the level of shyness, personal circumstances, and consistency of listening. For some individuals, positive changes can be noticed within a few weeks of regular use. However, it is important to continue listening to the subliminal to reinforce and maintain the progress made.`,
    open: false 
  },
  { 
    question: `What can I do to support the effectiveness of the Overcoming Shyness subliminal?`, 
    answer: `In addition to listening to the subliminal, you can also practice self-reflection and self-acceptance. Engaging in social activities and gradually exposing yourself to social situations can be helpful in overcoming shyness. It is also beneficial to surround yourself with supportive and understanding individuals who can encourage and uplift you. Remember to be patient with yourself and celebrate even the smallest steps forward.`,
    open: false 
  },
  { 
    question: `Can the subliminal completely get rid of my shyness?`, 
    answer: `While the subliminal can effectively help you overcome shyness and build social confidence, the process is unique to each individual. It may not completely eliminate shyness, as it is a natural aspect of personality for some people. However, with consistent use, the subliminal can empower you to manage and work through shyness, allowing you to live a more fulfilling and connected life.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the Overcoming Shyness subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Overcoming Shyness subliminal. However, if you have any concerns about your mental health or specific circumstances, it is always recommended to consult with a mental health professional.`,
    open: false 
  },
  { 
    question: `Can the subliminal make me change my personality?`, 
    answer: `No, the subliminal is not intended to change your core personality. Its purpose is to help you overcome shyness and develop social confidence. You will still remain true to your authentic self, but with increased self-assurance and willingness to engage in social situations.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>