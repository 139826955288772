<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Achieving hormonal balance is crucial for your overall health and well-being, and this subliminal can help you on that journey. By targeting your subconscious mind, it aims to promote balanced hormone levels and improve your body's functionality."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Regulating menstrual cycles and reducing symptoms of PMS.",
        "Managing and reducing hormonal acne breakouts.",
        "Supporting weight management and reducing stubborn belly fat.",
        "Enhancing fertility and reproductive health.",
        "Improving mood stability and reducing mood swings.",
        "Boosting energy levels and reducing fatigue.",
        "Supporting better sleep quality and regulating sleep patterns.",
        "Promoting overall hormonal balance and improving well-being."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can tap into the power of your subconscious mind to encourage balance in your hormones and enhance your overall health. Remember, everyone's journey may be different, so be patient and consistent with your subliminal practice to achieve the best results."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Achieving hormonal balance is essential for your overall health and well-being. This subliminal is designed to help you restore and maintain harmony in your hormones. Before you begin listening, consider following these tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Educate yourself: Learn about the symptoms of hormonal imbalance and identify areas you would like to improve.",
        "Create a self-care routine: Incorporate activities that reduce stress, such as exercise, meditation, and getting enough sleep.",
        "Nourish your body: Focus on a balanced diet that includes foods known to support hormonal health, such as fruits, vegetables, and healthy fats.",
        "Stay consistent: Commit to listening to the subliminal regularly, as it takes time to reprogram your subconscious mind and create lasting change."
      ]
    },
    {
      type: 'paragraph',
      text: "By dedicating yourself to this subliminal and integrating these preparation tips into your routine, you can take significant steps towards achieving hormonal balance. Remember to be patient and kind to yourself throughout this process. Together, let's embark on a journey to improve your health and well-being."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Healthy Hormonal Balancing subliminal! By prioritizing hormone balance, you have taken a significant step towards improving your overall health and well-being. Take a moment to reflect on the transformations you are experiencing and embrace the journey ahead."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Educate yourself: Learn about hormones and how they impact your body and mind.",
        "Focus on a nutrient-rich diet: Include foods that support hormonal balance such as whole grains, fruits, and vegetables.",
        "Practice stress management: Engage in activities like meditation and deep breathing to reduce stress levels.",
        "Engage in regular exercise: Physical activity helps balance hormones and supports overall well-being."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember that achieving hormonal balance is an ongoing process. Be patient with yourself and celebrate even the smallest improvements. Take care of your body and mind, and trust that you are moving towards a healthier, happier version of yourself. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Healthy Hormonal Balancing subliminal help improve hormonal health?`, 
    answer: `The Healthy Hormonal Balancing subliminal uses positive affirmations to influence your subconscious mind and promote balance in your hormones. By consistently listening to the subliminal, you can reprogram your mindset and encourage your body to regulate hormone production and function more effectively. It may also support overall health and well-being.`,
    open: false
  },
  { 
    question: `Will the Healthy Hormonal Balancing subliminal have immediate results?`, 
    answer: `The time it takes to see results from the Healthy Hormonal Balancing subliminal may vary depending on individual factors and circumstances. It may take several weeks or longer for the positive affirmations to have a noticeable impact on your hormonal health. Consistent listening is important for optimal results.`,
    open: false 
  },
  { 
    question: `Can the Healthy Hormonal Balancing subliminal replace medical treatment for hormonal imbalances?`, 
    answer: `No, the Healthy Hormonal Balancing subliminal is not a substitute for medical treatment or professional advice. It is designed to complement and support existing treatments and healthy lifestyle choices. If you have concerns about your hormonal health, it is important to consult with a healthcare professional.`,
    open: false 
  },
  { 
    question: `Are there any side effects of listening to the Healthy Hormonal Balancing subliminal?`, 
    answer: `The Healthy Hormonal Balancing subliminal is designed to be safe for most individuals with no known side effects. However, everyone is unique, and if you experience any unusual symptoms or discomfort while listening to the subliminal, it is best to discontinue use and consult with a healthcare professional.`,
    open: false 
  },
  { 
    question: `How often should I listen to the Healthy Hormonal Balancing subliminal?`, 
    answer: `For best results, it is recommended to listen to the Healthy Hormonal Balancing subliminal daily. Consistency is important to allow the positive affirmations to influence your subconscious mind over time. You can choose to listen to it at a time and frequency that works best for you.`,
    open: false 
  },
  { 
    question: `Can the Healthy Hormonal Balancing subliminal help with specific hormonal conditions?`, 
    answer: `While the Healthy Hormonal Balancing subliminal is designed to support overall hormonal health and well-being, it may not specifically target or treat individual hormonal conditions. If you have a specific hormonal condition, it is important to consult with a healthcare professional to determine the most appropriate course of action.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>