<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Healing from depression and finding inner peace and happiness is a journey, and this subliminal is designed to support you along the way. By targeting your subconscious mind, it aims to remove negative thoughts and emotions, and promote positive mental well-being."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help:'
    },
    {
      type: 'list',
      items: [
        "Assisting in the release of negative energy and emotions that contribute to depression.",
        "Promoting a more positive and optimistic outlook on life.",
        "Encouraging self-compassion and self-love, fostering a healthier relationship with yourself.",
        "Supporting the development of coping mechanisms and resilience to deal with challenges.",
        "Guiding you towards finding inner peace, happiness, and a sense of purpose."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can start to rewire your subconscious mind and create a positive internal environment. It may take time and patience, but with consistent use, you can experience healing, emotional balance, and a renewed zest for life. Remember, you are not alone in this journey, and seeking additional support from professionals is always encouraged."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Finding the strength to overcome depression can be challenging, but this subliminal is designed to support your journey towards healing and finding inner peace. Before you begin, consider these strategies to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a calming environment: Find a quiet and comfortable space where you can relax and focus on your healing journey.",
        "Set your intention: Clarify your desire to overcome depression and cultivate inner peace and happiness.",
        "Practice self-care: Prioritize activities that promote physical, mental, and emotional well-being.",
        "Visualize your healing: Imagine yourself finding joy, peace, and happiness while letting go of negative thoughts and emotions."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to help you connect with your inner strength and promote positive change. Regular listening, combined with self-care and a positive mindset, can support your journey towards healing. Embrace this moment of self-care and transformation, and let the healing process begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Depression Healing and Removal subliminal session! By taking this important step towards overcoming depression, you've shown incredible strength and determination. It's now time to reflect on your experience and continue your journey towards inner peace and happiness."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice self-care: Prioritize activities that bring you joy, peace, and relaxation.",
        "Engage in positive self-talk: Replace negative thoughts with affirmations and encouraging words.",
        "Reach out for support: Consider talking to a therapist or confiding in a trusted friend or family member.",
        "Create a gratitude practice: Take time each day to appreciate the positive aspects of your life.",
        "Explore coping strategies: Find healthy ways to manage your emotions, such as journaling or engaging in creative outlets."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, healing from depression is a process that takes time and effort. Be patient and kind to yourself as you navigate this journey. You have the strength within you to find inner peace and happiness. Keep moving forward, and know that brighter days are ahead."
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Depression Healing and Removal subliminal help me?`, 
    answer: `The Depression Healing and Removal subliminal is designed to reprogram your subconscious mind, helping you overcome depression and remove negative thoughts and emotions. By listening to the subliminal regularly, you can begin to shift your mindset towards a more positive and joyful outlook. It can provide support and empowerment on your journey to finding inner peace and happiness.`,
    open: false
  },
  { 
    question: `How long does it take to notice improvements in my mood and mental well-being?`, 
    answer: `Results may vary from person to person, but listening to the Depression Healing and Removal subliminal consistently can help expedite the process. Some individuals may experience positive changes in mood and mental well-being right away, while others may need more time for the affirmations to create lasting effects. We recommend listening daily and being patient with yourself as you progress on your healing journey.`,
    open: false 
  },
  { 
    question: `Can the Depression Healing and Removal subliminal replace therapy or medication?`, 
    answer: `The Depression Healing and Removal subliminal is not a substitute for professional therapy or medication. It can serve as a complementary tool to support your mental health and well-being, but if you are experiencing severe depression or mental health challenges, it is important to seek guidance from a qualified healthcare professional.`,
    open: false 
  },
  { 
    question: `What can I do to enhance the effectiveness of the Depression Healing and Removal subliminal?`, 
    answer: `In addition to listening to the subliminal, it can be beneficial to incorporate other positive practices into your life. This may include maintaining a healthy lifestyle, engaging in activities that bring you joy, seeking support from loved ones, and practicing self-care. Additionally, combining the subliminal with therapy or counseling can provide a comprehensive approach to healing from depression.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Depression Healing and Removal subliminal?`, 
    answer: `There are no known risks or side effects associated with listening to the Depression Healing and Removal subliminal. However, if you have any concerns about your mental health or are currently under the care of a mental health professional, we recommend consulting with them before incorporating any new practices into your routine.`,
    open: false 
  },
  { 
    question: `Can the Depression Healing and Removal subliminal be used in combination with other subliminals?`, 
    answer: `Yes, you can use the Depression Healing and Removal subliminal in combination with other subliminals, as long as they are focused on different aspects of personal growth and do not conflict with each other. However, it is important to listen to each subliminal separately in order to fully benefit from the affirmations provided.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>