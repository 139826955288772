<template>
  <div v-show="isReady" class="my-2">
    <div v-if="subliminal.length != 0">
      <h5>Subliminals</h5>
      <div class="pb-3" style="height: 180px">
        <v-slide-group multiple show-arrows>
          <v-slide-item v-for="(sub, index) in subliminal"
            :key="index"
          >
            <div class="card mx-2"
              :style="`background: url(${sub.cover})`"
              @click="getAudios(subliminal, index)"
            >
              <p class="mb-0">{{ sub.title }}</p>
            </div>
          </v-slide-item>
        </v-slide-group>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
  components: {
  },
  props: {
    subliminal: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isReady: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  mounted() {
  },
  computed: {
  },
  watch: {
  },
  data: () => {
    return {
    };
  },
  methods: {
    getAudios(sub, index) {
      this.$emit("getAudios", sub, index);
    },
  },
};
</script>

<style scoped>
  .card {
    width: 250px;
    height: 150px;
    padding: 10px;
    color: white;
    text-shadow: 1px 1px black;

    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }
  .card:hover,
  .card1:hover {
    color: black;
    width: 270px;
    height: 170px;
    text-shadow: 0px 0px;
  }
  .card:nth-child(1) {
    margin-left: 0px !important;
  }
</style>
