const getDefaultState = () => {
  return {
    // Data
    subliminal: {
      index: 0,
    }, // subliminal index
    track: {
      urls: [],
    }, // track URLs

    // Audio property
    volume: {
      data: [],
    },

    audio: {
      type: [],
    },

    time: {
      current: 0,
      duration: [],
    },

    // Audio status
    status: {
      loop: false,
      skip: false,
      data: false,
      playing: false,
      played: false,
      skipped: false,
    },

    // Web audio API
    buffer: {
      index: 0,
      data: [],
    },
    context: null,
    sources: [],
    gains: [],
  };
};

export const newaudio = {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getSubliminalIndex(state) {
      return state.subliminal.index;
    },
    getTrackURLs(state) {
      return state.track.urls;
    },
    getVolumes(state) {
      return state.volume.data;
    },
    getAudioTypes(state) {
      return state.audio.type;
    },
    getCurrentTime(state) {
      return state.time.current;
    },
    getDurationTimes(state) {
      return state.time.duration;
    },
    getPlayingStatus(state) {
      return state.status.playing;
    },
    getPlayedStatus(state) {
      return state.status.played;
    },
    getLoopStatus(state) {
      return state.status.loop;
    },
    getSkipStatus(state) {
      return state.status.skip;
    },
    getSkippedStatus(state) {
      return state.status.skipped;
    },
    getDataStatus(state) {
      return state.status.data;
    },
    getBufferIndex(state) {
      return state.buffer.index;
    },
    getSources(state) {
      return state.sources;
    },
    getContext(state) {
      return state.context;
    },
    getBuffers(state) {
      return state.buffer.data;
    },
    getGains(state) {
      return state.gains;
    },
  },
  actions: {
    subliminalIndex({ commit }, data) {
      commit("setSubliminalIndex", data);
    },
    trackURLs({ commit }, data) {
      commit("setTrackURLs", data);
    },
    volumes({ commit }, data) {
      commit("setVolumes", data);
    },
    audioTypes({ commit }, data) {
      commit("setAudioTypes", data);
    },
    currentTime({ commit }, data) {
      commit("setCurrentTime", data);
    },
    durationTimes({ commit }, data) {
      commit("setDurationTimes", data);
    },
    playingStatus({ commit }, data) {
      commit("setPlayingStatus", data);
    },
    playedStatus({ commit }, data) {
      commit("setPlayedStatus", data);
    },
    loopStatus({ commit }, data) {
      commit("setLoopStatus", data);
    },
    skipStatus({ commit }, data) {
      commit("setSkipStatus", data);
    },
    skippedStatus({ commit }, data) {
      commit("setSkippedStatus", data);
    },
    dataStatus({ commit }, data) {
      commit("setDataStatus", data);
    },
    bufferIndex({ commit }, data) {
      commit("setBufferIndex", data);
    },
    sources({ commit }, data) {
      commit("setSources", data);
    },
    context({ commit }, data) {
      commit("setContext", data);
    },
    buffers({ commit }, data) {
      commit("setBuffers", data);
    },
    gains({ commit }, data) {
      commit("setGains", data);
    },
    reset({ commit }) {
      commit("reset");
    },
    resetvolumes({ commit }, data) {
      commit("resetVolumes", data);
    },
    resetsources({ commit }, data) {
      commit("resetSources", data);
    },
    resetgains({ commit }, data) {
      commit("resetGains", data);
    },
  },
  mutations: {
    setPlaylistLoop(state, data) {
      state.subliminal.loop = data;
    },
    setSubliminalIndex(state, data) {
      state.subliminal.index = data;
    },
    setTrackURLs(state, data) {
      state.track.urls = data;
    },
    setVolumes(state, data) {
      state.volume.data.push(data);
    },
    setAudioTypes(state, data) {
      state.audio.type.push(data);
    },
    setCurrentTime(state, data) {
      state.time.current = data;
    },
    setDurationTimes(state, data) {
      state.time.duration.push(data);
    },
    setPlayingStatus(state, data) {
      state.status.playing = data;
    },
    setPlayedStatus(state, data) {
      state.status.played = data;
    },
    setLoopStatus(state, data) {
      state.status.loop = data;
    },
    setSkipStatus(state, data) {
      state.status.skip = data;
    },
    setSkippedStatus(state, data) {
      state.status.skipped = data;
    },
    setDataStatus(state, data) {
      state.status.data = data;
    },
    setBufferIndex(state, data) {
      state.buffer.index = data;
    },
    setSources(state, data) {
      state.sources.push(data);
    },
    setContext(state, data) {
      state.context = data;
    },
    setBuffers(state, data) {
      state.buffer.data.push(data);
    },
    setGains(state, data) {
      state.gains.push(data);
    },
    reset(state) {
      Object.assign(state, getDefaultState());
    },
    resetAudioTypes(state, data) {
      state.audio.type = data;
    },
    resetVolumes(state, data) {
      state.volume.data = data;
    },
    resetSources(state, data) {
      state.sources = data;
    },
    resetGains(state, data) {
      state.gains = data;
    },
  },
};
