import { render, staticRenderFns } from "./76-soft-drinks-addiction-removal.vue?vue&type=template&id=65f096bf&scoped=true&"
import script from "./76-soft-drinks-addiction-removal.vue?vue&type=script&lang=js&"
export * from "./76-soft-drinks-addiction-removal.vue?vue&type=script&lang=js&"
import style0 from "./76-soft-drinks-addiction-removal.vue?vue&type=style&index=0&id=65f096bf&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65f096bf",
  null
  
)

export default component.exports