<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Training and mastering Wing Chun Kung Fu requires dedication, practice, and a comprehensive understanding of the art. This subliminal is designed to support your journey and help you become a skilled practitioner."
    },
    {
      type: 'heading',
      text: 'Here are the key elements of this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Building a strong foundation: Enhance your understanding of the fundamental principles, techniques, and theories of Wing Chun Kung Fu.",
        "Improving physical abilities: Develop strength, speed, flexibility, and coordination to execute techniques with precision and efficiency.",
        "Mental focus and discipline: Train your mind to stay focused, disciplined, and present during training sessions and sparring sessions.",
        "Refining techniques: Fine-tune your stance, footwork, strikes, blocks, and forms to enhance your overall performance and martial arts skills.",
        "Applying Wing Chun in real-world scenarios: Practice applying your skills and techniques in practical self-defense situations.",
        "Becoming a skilled practitioner: Gradually master the art of Wing Chun Kung Fu and develop your personal style and expression."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reinforce your commitment to training, absorb knowledge at a subconscious level, and enhance your overall progression. Remember, mastering Wing Chun Kung Fu is a lifelong journey, and consistent practice and dedication are key to reaching your goals."
    }
  ]
},
            {
  id: 'before-listening',
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on the journey to master Wing Chun Kung Fu requires dedication, discipline, and a willingness to learn. With this subliminal, you can enhance your training and accelerate your progress. Before you start, consider these tips to optimize your preparation:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Define what you want to achieve through your Wing Chun practice, whether that's mastering specific techniques, improving physical fitness, or attaining a black belt.",
        "Create a training schedule: Consistency is key, so establish a regular practice routine that works for you.",
        "Focus on mindset: Prepare your mind by cultivating a positive attitude, mental resilience, and a growth mindset.",
        "Create a conducive training space: Ensure you have enough room to move and practice without distractions.",
        "Stay committed: Remember that progress takes time, so stay motivated and dedicated to your training journey."
      ]
    },
    {
      type: 'paragraph',
      text: "As you immerse yourself in the world of Wing Chun Kung Fu, use this subliminal to reinforce positive beliefs, enhance your muscle memory, and deepen your understanding of the art. Trust the process, stay consistent, and let the power of subliminal messaging support your path to becoming a skilled Wing Chun practitioner."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Wing Chun Kung Fu Training and Mastery session! By dedicating yourself to the comprehensive training and practice of Wing Chun Kung Fu, you've embarked on a remarkable journey towards becoming a skilled practitioner. Reflecting upon your progress and maintaining consistency is essential as you continue your path of growth and mastery."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Review your training sessions: Take time to analyze your technique, form, and progress.",
        "Practice regularly: Consistency is key in mastering Wing Chun Kung Fu. Set aside dedicated time for practice.",
        "Engage in sparring sessions: Challenge yourself by applying your skills in controlled sparring sessions.",
        "Seek guidance and feedback: If possible, consult with a qualified instructor to receive guidance and feedback on your training."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, becoming a skilled practitioner of Wing Chun Kung Fu requires discipline, perseverance, and continuous effort. Embrace the journey and remain committed to honing your skills. With dedication and practice, you will progress and achieve mastery. The path ahead may be challenging, but it is also incredibly rewarding. Keep practicing and never give up!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What is Wing Chun Kung Fu?`, 
    answer: `Wing Chun Kung Fu is a traditional Chinese martial art known for its efficiency, practicality, and close-range combat techniques. Developed by a woman named Ng Mui, Wing Chun emphasizes speed, accuracy, and simultaneous attack and defense.`,
    open: false
  },
  { 
    question: `Who can learn Wing Chun Kung Fu?`, 
    answer: `Wing Chun Kung Fu can be learned and practiced by people of all ages and fitness levels. The techniques are designed to be effective for individuals with different body types and strengths. Anyone with a passion for martial arts and a willingness to learn can start their journey in Wing Chun.`,
    open: false 
  },
  { 
    question: `Is Wing Chun Kung Fu difficult to learn?`, 
    answer: `Like any martial art, Wing Chun Kung Fu requires consistent practice and dedication to master. The techniques and principles may seem challenging at first, but with the guidance of a skilled instructor and regular training, steady progress can be made. With time and perseverance, individuals can develop proficiency in Wing Chun Kung Fu.`,
    open: false 
  },
  { 
    question: `Do I need any prior martial arts experience to learn Wing Chun Kung Fu?`, 
    answer: `No, prior martial arts experience is not necessary to begin learning Wing Chun Kung Fu. The systematic training approach in Wing Chun allows beginners to start from the basics and gradually progress to more advanced techniques. Whether you are a beginner or have previous martial arts experience, Wing Chun can offer you a unique journey of self-discovery and personal growth.`,
    open: false 
  },
  { 
    question: `How long does it take to become proficient in Wing Chun Kung Fu?`, 
    answer: `The time it takes to become proficient in Wing Chun Kung Fu can vary depending on several factors, such as the frequency and intensity of training, individual dedication, and natural aptitude. Consistent practice over several years is often required to achieve a high level of proficiency. Remember, mastering any martial art is a lifelong journey that rewards perseverance and dedication.`,
    open: false 
  },
  { 
    question: `Can Wing Chun Kung Fu be used for self-defense?`, 
    answer: `Yes, Wing Chun Kung Fu is an effective martial art for self-defense. Its techniques are designed to be practical and efficient in real-life situations, involving close-range combat and simultaneous attack and defense. By learning Wing Chun, individuals can develop the confidence and skills necessary to protect themselves if the need arises.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>