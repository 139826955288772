var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`card playlist ${_vm.isPlayerAvailable ? 'with-player' : ''}`},[_c('div',{staticClass:"section"},[_c('div',{staticClass:"title"},[_vm._m(0),_c('div',{staticClass:"d-flex align-items-center gap-2"},[(_vm.isCurrentTabPlaylist)?_c('img',{attrs:{"src":require("@/assets/images/user/playlist/add2.png")},on:{"click":function($event){_vm.dialog = true}}}):_vm._e()])]),_c('div',{staticClass:"tabs"},[_c('button',{class:`btn btn-sm d-flex ${_vm.isTabFavorite}`,attrs:{"id":"favoritetabs"},on:{"click":function($event){return _vm.getActiveTab('favorite')}}},[_c('img',{staticClass:"mr-1",attrs:{"src":require(`@/assets/images/user/sidebar/heart_${
              _vm.tabs != 'favorite' ? 'black' : 'white'
            }.png`)}}),_vm._v(" Favorites ")]),_c('button',{class:`btn btn-sm d-flex ${_vm.isTabPlaylist}`,attrs:{"id":"playlisttabs"},on:{"click":function($event){return _vm.getActiveTab('playlist')}}},[_c('img',{staticClass:"mr-1",attrs:{"src":require(`@/assets/images/user/${
              _vm.tabs != 'playlist' ? 'playlist/add2' : 'sidebar/add_white'
            }.png`)}}),_vm._v(" My Playlists ")])]),(_vm.tabs == 'favorite')?_c('div',{staticClass:"tabs"},[_c('button',{class:`${_vm.isSubTabSubliminals}`,attrs:{"id":"subliminalssubtabs"},on:{"click":function($event){return _vm.getActiveSubTab('subliminals')}}},[_vm._v(" Subliminals ")]),_c('button',{class:`${_vm.isSubTabPlaylists}`,attrs:{"id":"playlistssubtabs"},on:{"click":function($event){return _vm.getActiveSubTab('playlists')}}},[_vm._v(" Playlists ")])]):_c('div',{staticClass:"tabs"},[_c('button',{class:`${_vm.isSubTabMagus}`,attrs:{"id":"magussubtabs"},on:{"click":function($event){return _vm.getActiveSubTab('magus')}}},[_vm._v(" By Magus ")]),_c('button',{class:`${_vm.isSubTabYou}`,attrs:{"id":"yousubtabs"},on:{"click":function($event){return _vm.getActiveSubTab('you')}}},[_vm._v(" By You ")])]),_c('div',{staticClass:"items"},[_c('div',_vm._l((_vm.data),function(item,index){return _c('div',{key:index,staticClass:"card card-items p-2",on:{"click":function($event){item.hasOwnProperty('playlist_id')
              ? _vm.goToTrack(item)
              : _vm.getAudios(_vm.data, index)}}},[_c('div',{staticClass:"cover",style:(`background: url(${_vm.getBackground(item)});`)}),_c('div',{staticClass:"details mx-2"},[_c('h6',{staticClass:"mb-0 text-limit-1"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.getDuration(item)))]),_c('span',{staticClass:"mb-0 text-limit-2"},[_vm._v(" "+_vm._s(item.description)+" ")])])])}),0)])]),_c('AddOwnPlaylistTitleDialog',{attrs:{"data":null,"dialog":_vm.dialog},on:{"closeDialog":_vm.closeDialog}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center gap-2"},[_c('img',{attrs:{"src":require("@/assets/images/user/footer/sound-wave.png")}}),_c('h6',{staticClass:"mb-0"},[_vm._v("My Subs")])])
}]

export { render, staticRenderFns }