<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "When it comes to recovery and healing, quality sleep is crucial. This subliminal is specifically designed to support your body's healing process by promoting healthy sleep habits and optimizing your rest."
    },
    {
      type: 'heading',
      text: 'Here are some key benefits you may experience:'
    },
    {
      type: 'list',
      items: [
        "Enhanced recovery from physical activity or injuries due to the regenerative power of deep sleep.",
        "Improved immune function by allowing your body to reset and strengthen its defenses.",
        "Reduced inflammation and pain, as quality sleep supports the body's natural healing processes.",
        "Increased mental clarity and cognitive function, making it easier to focus and problem-solve.",
        "A heightened sense of overall well-being and rejuvenation, as your body repairs and replenishes itself during sleep."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your bedtime routine, you can create an optimal environment for healing and recovery. Over time, you may notice improvements in your physical health, mental well-being, and overall quality of life. Remember to prioritize consistent use to maximize the benefits."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Prioritizing quality sleep is essential for your body's recovery and healing process. This subliminal is designed to support your journey towards achieving restorative sleep and experiencing the rejuvenating effects of a good night's rest. Before you start listening, consider these strategies to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your sleep intentions: Clearly define what you want to achieve through healthy sleep habits, such as enhanced physical recovery, mental rejuvenation, or overall healing.",
        "Establish a soothing pre-sleep routine: Engage in relaxing activities before bedtime, such as reading, taking a warm bath, or practicing mindfulness, to prepare your mind and body for rest.",
        "Create an optimal sleep environment: Ensure your bedroom promotes restful sleep by setting a comfortable temperature, reducing noise and light, and arranging cozy and supportive bedding.",
        "Focus on your healing journey: While listening to the subliminal, maintain a strong intention to embrace and support your body's healing process through restful sleep."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your routine and following these preparation tips, you can nourish your body and mind with the healing power of sleep. Embrace this opportunity to prioritize your well-being and begin your journey to enhanced recovery and rejuvenation."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Healthy Sleep Recovery and Healing subliminal! By prioritizing healthy sleep habits, you've taken a significant step towards restoring and healing your body. Reflecting on the transformative power of quality sleep is crucial to your ongoing journey of recovery and well-being."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Nurture your body: Provide yourself with a nutrient-rich diet to support healing and recovery.",
        "Create a sleep sanctuary: Make your bedroom a peaceful, comfortable space that promotes rest and relaxation.",
        "Practice self-care: Engage in activities that promote relaxation, such as meditation or taking soothing baths.",
        "Prioritize quality rest: Establish a consistent sleep schedule and aim for a sufficient duration of sleep each night."
      ]
    },
    {
      type: 'paragraph',
      text: "Healing and recovery take time, but by prioritizing healthy sleep, you are giving your body the restorative rest it needs. Embrace the journey and be gentle with yourself as you continue to make progress. Remember, each night of quality sleep brings you closer to a stronger, healthier, and more rejuvenated version of yourself. Wishing you peaceful nights and a brighter tomorrow!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Healthy Sleep Recovery and Healing subliminal benefit my body's recovery and healing processes?`, 
    answer: `The Healthy Sleep Recovery and Healing subliminal is designed to promote quality sleep, which is essential for the body's recovery and healing processes. During sleep, the body repairs itself, strengthens the immune system, and restores energy levels. By listening to the subliminal regularly, you can align your subconscious mind with healthy sleep patterns, allowing for more effective recovery and healing.`,
    open: false
  },
  { 
    question: `How long do I need to listen to the Healthy Sleep Recovery and Healing subliminal to experience its benefits?`, 
    answer: `The time it takes to experience the benefits of the Healthy Sleep Recovery and Healing subliminal may vary from person to person. It is recommended to listen to the subliminal consistently for at least a few weeks to allow the positive affirmations to have a lasting impact on your subconscious mind. The more consistent you are with listening, the more you can enhance your body's natural recovery and healing processes.`,
    open: false 
  },
  { 
    question: `Can the Healthy Sleep Recovery and Healing subliminal replace medical treatment or therapy?`, 
    answer: `No, the Healthy Sleep Recovery and Healing subliminal is not intended to replace medical treatment or therapy. It is designed to complement a healthy sleep routine, which can support the body's natural recovery and healing processes. If you have any specific medical concerns or conditions, it is important to consult with your healthcare provider for proper guidance and treatment.`,
    open: false 
  },
  { 
    question: `What are some additional practices I can incorporate along with listening to the Healthy Sleep Recovery and Healing subliminal?`, 
    answer: `In addition to listening to the Healthy Sleep Recovery and Healing subliminal, you can establish a consistent sleep schedule, create a relaxing bedtime routine, ensure your sleep environment is comfortable and free from distractions, avoid excessive screen time before bed, and practice stress-reducing techniques such as meditation or deep breathing. These practices can further enhance your body's recovery and healing processes.`,
    open: false 
  },
  { 
    question: `Is it safe to listen to the Healthy Sleep Recovery and Healing subliminal every night?`, 
    answer: `Yes, it is safe to listen to the Healthy Sleep Recovery and Healing subliminal every night. The subliminal messages are designed to promote healthy sleep patterns and support the body's natural recovery and healing processes. Incorporating it into your nightly routine can help reinforce positive sleep habits and enhance the rejuvenating effects of sleep.`,
    open: false 
  },
  { 
    question: `Will the Healthy Sleep Recovery and Healing subliminal cure all my health issues?`, 
    answer: `The Healthy Sleep Recovery and Healing subliminal is not a cure for specific health issues. It is designed to support the body's natural recovery and healing processes by promoting quality sleep. If you have any specific health concerns or conditions, it is important to consult with your healthcare provider for proper diagnosis and treatment.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>