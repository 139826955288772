<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "This subliminal is designed to help you overcome financial challenges and empower you to have enough money to pay off your financial obligations. By targeting your subconscious mind, it can help you discover strategies and mindset shifts to increase your income and financial resources."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can assist you:'
    },
    {
      type: 'list',
      items: [
        "Identifying opportunities for income growth and financial abundance.",
        "Attracting wealth and abundance into your life through positive affirmations.",
        "Developing a money mindset that is aligned with prosperity and financial success.",
        "Boosting your confidence in managing your finances and making smart financial decisions.",
        "Overcoming limiting beliefs and subconscious blocks that may be hindering your financial progress."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can rewire your subconscious mind to embrace a mindset of abundance and attract financial prosperity. It can provide you with the motivation and inspiration to take action towards increasing your income and overcoming financial obstacles. Remember, implementing the strategies and mindset shifts in your everyday life is essential for achieving the desired results."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Achieving financial freedom and meeting your obligations can be a challenge, but this subliminal is here to support you on your journey. Before you begin, consider following these strategies and mindset shifts to empower your financial well-being:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear financial goals: Define what you want to achieve financially, whether it's paying off debt, increasing income, or saving for the future.",
        "Create a budget: Understand your current financial situation and make a plan to allocate your resources effectively.",
        "Embrace a positive mindset: Believe in your ability to improve your financial situation and attract abundance into your life.",
        "Educate yourself: Seek knowledge and information about personal finance, investments, and different income-generating opportunities.",
        "Take inspired action: Implement the strategies you learn and adapt accordingly to overcome financial challenges."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to help reprogram your subconscious mind and align your thoughts and actions with financial success. By listening regularly and applying the strategies that resonate with you, you can cultivate a prosperous mindset and take the necessary steps to achieve financial stability. Trust in the process, stay committed, and let the journey towards financial freedom begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the session on having enough money to pay off financial obligations! You've taken an important step towards improving your financial situation and achieving greater financial security. As you reflect on the strategies and mindset shifts you've learned, remember that you have the power to create positive changes in your financial life."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Assess your financial situation: Review your income, expenses, and debt obligations to gain a clear understanding of your current financial status.",
        "Set realistic goals: Identify specific financial goals that will help you meet your obligations and create a plan to achieve them.",
        "Develop a budget: Create a budget that aligns with your financial goals and allows for saving and paying off debt.",
        "Explore new income opportunities: Consider ways to increase your income, such as freelancing or starting a side business.",
        "Shift your mindset: Adopt a positive and abundance mindset when it comes to money, focusing on opportunities rather than limitations."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, improving your financial situation takes time and effort. Stay committed to your goals, implement the strategies you've learned, and remain open to opportunities. With a proactive mindset and the right actions, you can create a solid foundation for financial freedom. Best of luck on your financial journey!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the strategies and mindset shifts in the "Have enough money to pay off financial obligations" subliminal help me?`, 
    answer: `The subliminal audio is designed to influence your subconscious mind, changing your beliefs and attitudes towards money and financial resources. By adopting positive money mindsets and implementing effective strategies, you can increase your income and financial resources, allowing you to comfortably meet and pay off your financial obligations. Consistent listening to the subliminal can help reinforce these new beliefs and behaviors.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the "Have enough money to pay off financial obligations" subliminal?`, 
    answer: `The time it takes to see results can vary from person to person. It depends on individual circumstances, willingness to implement new strategies, and consistency in listening to the subliminal. Some people may start noticing positive changes in their mindset and financial situation within a few weeks, while others may require more time and consistency.`,
    open: false 
  },
  { 
    question: `Can the "Have enough money to pay off financial obligations" subliminal replace financial planning and budgeting?`, 
    answer: `No, the subliminal is not a substitute for proper financial planning and budgeting. It is designed to complement these strategies by reshaping your mindset and beliefs about money. By combining the subliminal with practical financial planning and budgeting techniques, you can create a solid foundation for achieving your financial goals.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the "Have enough money to pay off financial obligations" subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, please note that the subliminal is not a substitute for professional financial advice. It is essential to seek guidance from a qualified financial advisor to address your specific financial needs and goals.`,
    open: false 
  },
  { 
    question: `How can I maximize the effectiveness of the "Have enough money to pay off financial obligations" subliminal?`, 
    answer: `To maximize the effectiveness of the subliminal audio, create clear and specific financial goals, implement sound financial planning practices, and take consistent action towards improving your financial situation. Additionally, practicing gratitude, visualization, and affirmations can support your subconscious mind in manifesting positive financial changes. Remember, the subliminal is a tool to help shift your mindset, but you must also take responsibility and action to improve your financial situation.`,
    open: false 
  },
  { 
    question: `Is the "Have enough money to pay off financial obligations" subliminal suitable for everyone?`, 
    answer: `The "Have enough money to pay off financial obligations" subliminal can benefit individuals looking to change their mindset and improve their financial situation. However, results may vary depending on individual circumstances and commitment to implementing positive changes. It is always advisable to seek professional financial advice for personalized guidance.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>