<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're tired of waking up in the middle of the night from nightmares, this subliminal can help bring you the peace and restful sleep you deserve. By targeting your subconscious mind, it aims to eliminate nightmares and promote peaceful nights."
    },
    {
      type: 'heading',
      text: 'Benefits of using this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Reducing the frequency and intensity of nightmares, allowing for more peaceful sleep.",
        "Promoting restful sleep, so you can wake up feeling refreshed and rejuvenated.",
        "Helping you feel safer and more comfortable during sleep, improving overall sleep quality.",
        "Reducing anxiety and stress levels associated with nightmares, leading to a more relaxed state of mind."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly before bedtime, you can reprogram your subconscious mind to let go of negative dream experiences and create a peaceful sleeping environment. As a result, you can enjoy nights free from nightmares and wake up refreshed and ready to take on the day. Remember, consistent use is key to experiencing lasting changes."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Having nightmares can disrupt your sleep and leave you feeling exhausted during the day. This subliminal is designed to help you overcome nightmares and achieve more peaceful and restful nights. Before you start listening, try these strategies to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a calming bedtime routine: Engage in relaxing activities before bed to promote relaxation and prepare your mind for a peaceful night's sleep.",
        "Create a safe sleep environment: Make your bedroom a comfortable and secure space, free from distractions and sources of anxiety.",
        "Practice positive visualization: Before sleep, imagine yourself having a peaceful and restful night, free from nightmares.",
        "Clear your mind: Release any worries or troubling thoughts before bed, allowing your mind to be calm and open to positive change.",
        "Focus on your intention: While listening to the subliminal, keep your intention of achieving peaceful and nightmare-free nights at the forefront of your mind."
      ]
    },
    {
      type: 'paragraph',
      text: "By using this subliminal consistently and following these preparation tips, you can train your subconscious mind to overcome nightmares and enjoy restful nights of sleep. Embrace this opportunity to take control of your sleep and wake up refreshed and rejuvenated."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the End Nightmares subliminal session! By focusing on overcoming your nightmares and improving the quality of your sleep, you've taken an important step towards having more peaceful and restful nights. Now, let's reflect on your experience and explore how you can continue to have better, more rejuvenating sleep."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your dreams: Keep a dream journal to jot down any patterns, emotions, or recurring themes in your dreams.",
        "Create a calming bedtime routine: Establish a relaxing routine before bed to signal your body and mind that it's time to unwind.",
        "Practice relaxation techniques: Explore mindfulness, deep breathing, or meditation exercises to promote a sense of calm and relaxation before sleep.",
        "Ensure a sleep-friendly environment: Make your bedroom comfortable, quiet, and dark to enhance your chances of having deep, uninterrupted sleep."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming nightmares and improving your sleep is a process that takes time and consistent effort. Embrace the journey and make a commitment to prioritize your well-being. With persistence and these post-listening tips, you can create a sleep environment that promotes tranquility and wake up feeling refreshed and ready to take on each day. Sweet dreams!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the End Nightmares subliminal help me?`, 
    answer: `The End Nightmares subliminal is designed to help you overcome and eliminate nightmares, allowing you to have peaceful and restful nights. By listening to the subliminal messages regularly, they can work on your subconscious mind to reduce the occurrence of nightmares and promote more positive dream experiences. The subliminal aims to create a soothing and calming mental state before sleep, helping you wake up refreshed and ready for the day.`,
    open: false
  },
  { 
    question: `How long does it take to see results with the End Nightmares subliminal?`, 
    answer: `The time it takes to see results may vary from person to person. Some individuals may notice a decrease in nightmares after a few days of consistent listening, while others may require more time. It is recommended to listen to the subliminal regularly and consistently for the best outcome. Gradually, you may begin to experience more peaceful and restful nights with fewer nightmares.`,
    open: false 
  },
  { 
    question: `Can the End Nightmares subliminal be used for children?`, 
    answer: `Yes, the End Nightmares subliminal can be used for children. However, it is important to consider their age and individual needs. It may be helpful to consult with a healthcare professional or listen to the subliminal together with your child to ensure its suitability and effectiveness for their specific situation.`,
    open: false 
  },
  { 
    question: `Are there any side effects or risks associated with using the End Nightmares subliminal?`, 
    answer: `No, there are no known side effects or risks associated with using the End Nightmares subliminal audio. However, if you have any concerns about nightmares or your sleep quality, it is recommended to consult with a healthcare professional. The subliminal is designed to complement healthy sleep practices and promote more positive dream experiences.`,
    open: false 
  },
  { 
    question: `Can the End Nightmares subliminal replace professional help for nightmares?`, 
    answer: `No, the End Nightmares subliminal is not intended to replace professional help for severe or recurring nightmares. If you are experiencing persistent nightmares or have underlying psychological or medical conditions, it is important to seek guidance from a healthcare professional or mental health practitioner.`,
    open: false 
  },
  { 
    question: `Can the End Nightmares subliminal be used along with other sleep aids or techniques?`, 
    answer: `Yes, the End Nightmares subliminal can be used along with other sleep aids or techniques, such as relaxation exercises or a bedtime routine. It is important to find a comprehensive approach that works best for you and supports healthy sleep habits. If you have specific concerns or questions about combining the subliminal with other sleep aids, consult with a healthcare professional.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>