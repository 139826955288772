<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Feeling safe and secure is essential for your overall well-being, and this subliminal can help you create a safe haven within yourself and your surroundings. By targeting your subconscious mind, it fosters feelings of safety and security in all aspects of your life."
    },
    {
      type: 'heading',
      text: 'Benefits of using this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Feeling a sense of safety and security within yourself, promoting self-confidence and self-assurance.",
        "Creating a safe and secure environment around you, both physically and emotionally.",
        "Reducing anxiety and fear, allowing you to navigate life with greater peace of mind.",
        "Building trust in relationships and attracting positive and supportive people into your life.",
        "Enhancing your ability to cope with stress and adversity, knowing that you have a safe and secure foundation."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can train your subconscious mind to embrace feelings of safety and security. As you continue to reinforce these positive affirmations, you may notice a shift in your mindset and the way you perceive the world around you. Remember, creating a safe haven takes time and practice, but you have the power to cultivate it within yourself."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Building a sense of safety and security in your life is essential for your well-being and peace of mind. This subliminal is designed to help you create a safe haven within yourself and in your surroundings. Before you start listening, consider these preparation tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your safety needs: Identify areas in your life where you desire more safety and security.",
        "Visualize a safe haven: Imagine what your ideal safe environment looks like and feels like. Create a clear mental image.",
        "Declutter and organize: Create physical and mental space by decluttering your surroundings and organizing your belongings.",
        "Affirmations: Repeat positive affirmations related to safety and security before or during your listening session."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal, it will help align your subconscious mind with feelings of safety and security. The more you listen and reinforce these positive messages, the more you can create a safe and secure environment for yourself. Embrace this opportunity to prioritize your well-being and step confidently into a life filled with safety and security."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Safe Haven subliminal! By focusing on creating a safe and secure environment for yourself, you're taking an important step towards fostering feelings of safety and security in all aspects of your life. Take a moment to acknowledge your commitment to personal growth and reflect on the positive changes you've experienced."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a physical safe haven: Designate a special space in your home that brings you comfort and peace.",
        "Practice relaxation techniques: Explore mindfulness, deep breathing, or meditation to center yourself and reduce stress.",
        "Surround yourself with supportive people: Foster relationships with those who make you feel safe, valued, and understood.",
        "Set healthy boundaries: Establish boundaries to protect your emotional and mental well-being."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, creating a safe and secure environment is an ongoing process. Be patient with yourself and continue to prioritize self-care and personal growth. As you cultivate feelings of safety and security, you'll notice positive changes in all areas of your life. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Safe Haven subliminal work to create feelings of safety and security?`, 
    answer: `The Safe Haven subliminal works by sending positive affirmations directly to your subconscious mind, helping to reprogram your thoughts and beliefs about safety and security. By consistently listening to the subliminal, you can start to cultivate a sense of safety and security in all areas of your life. Over time, this can lead to increased confidence, peace of mind, and a greater ability to handle life's challenges.`,
    open: false
  },
  { 
    question: `When will I start noticing the effects of the Safe Haven subliminal?`, 
    answer: `The time it takes to notice the effects of the Safe Haven subliminal can vary for each individual. Some people may experience positive changes in their feelings of safety and security shortly after starting to listen, while others may require more time to fully embrace the affirmations. We recommend listening to the subliminal consistently and being open to the positive shifts that may occur in your mindset and perception of safety.`,
    open: false 
  },
  { 
    question: `Can the Safe Haven subliminal alone create a safe and secure environment?`, 
    answer: `The Safe Haven subliminal is designed to help you foster feelings of safety and security, but it is important to take practical steps in creating a safe and secure environment as well. This may include implementing security measures, establishing healthy boundaries, and surrounding yourself with positive and supportive relationships. The subliminal can complement your efforts by shifting your mindset and enhancing your sense of safety and security.`,
    open: false 
  },
  { 
    question: `Is it possible to become too dependent on the Safe Haven subliminal for feelings of safety and security?`, 
    answer: `No, the aim of the Safe Haven subliminal is to empower you to cultivate feelings of safety and security within yourself. It is not intended to create dependence or replace real-world measures to ensure safety. The subliminal messages work to enhance your mindset and mindset, allowing you to approach life with greater confidence and peace, even in challenging situations.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the Safe Haven subliminal?`, 
    answer: `There are no known risks or side effects associated with listening to the Safe Haven subliminal. However, if you have any concerns or underlying mental health conditions, it is recommended to consult with a healthcare professional before using subliminal audios. It is important to use the subliminal as a supplement to healthy coping strategies and self-care practices.`,
    open: false 
  },
  { 
    question: `Can the effects of the Safe Haven subliminal be permanent?`, 
    answer: `The effects of the Safe Haven subliminal can have a lasting impact on your mindset and perception of safety and security. However, like any personal development practice, it requires consistency and ongoing reinforcement to maintain the positive changes. Continuing to listen to the subliminal and actively engaging in practices that promote feelings of safety and security can help solidify the effects over time.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>