<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you struggle with noisy breathing, you know how disruptive it can be to your daily life. Fortunately, there are techniques and methods available to help you address this issue and improve your respiratory health."
    },
    {
      type: 'heading',
      text: 'Here are some techniques to stop noisy breathing:'
    },
    {
      type: 'list',
      items: [
        "Practice diaphragmatic breathing: This technique focuses on deep breathing from the diaphragm, allowing for more efficient airflow and reduced noise.",
        "Use nasal strips or dilators: These devices can help open your nasal passages, allowing for easier and quieter breathing.",
        "Try nasal irrigation: Clearing your nasal passages with a saline solution can help reduce congestion and improve breathing.",
        "Maintain a healthy weight: Excess weight can contribute to breathing difficulties, so managing your weight can help alleviate noisy breathing.",
        "Avoid triggers: Identify and avoid triggers that worsen your breathing, such as allergies, smoking, or certain foods.",
        "Consult a healthcare professional: If your noisy breathing persists or is accompanied by other symptoms, it's important to seek medical advice for a proper diagnosis and treatment plan."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating these techniques into your daily routine, you can improve your respiratory health and breathe more easily and quietly. Remember, it may take time and practice to fully overcome noisy breathing, but with persistence, you can achieve better airflow and enjoy the benefits of improved breathing." 
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Constantly dealing with noisy breathing can be frustrating and impact your respiratory health. This subliminal is designed to provide you with techniques to stop noisy breathing and improve your overall well-being. Before you start listening, consider these strategies:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Awareness: Pay attention to your breathing patterns and identify triggers that may contribute to noisy breathing.",
        "Relaxation techniques: Practice deep breathing exercises, meditation, or yoga to reduce stress and promote smoother breathing.",
        "Posture and positioning: Maintain good posture and try sleeping with your head elevated to improve airway clearance.",
        "Stay hydrated: Drink plenty of water to keep your airways moist and reduce congestion.",
        "Stay consistent: Make a commitment to regularly listen to this subliminal to reinforce positive changes in your breathing habits."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating these strategies into your daily routine along with consistent listening to the subliminal, you can learn to breathe more easily and quietly. Embrace this opportunity to improve your respiratory health and regain control over your breathing. Let's begin the journey to quiet and effortless breathing!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Stop Noisy Breathing subliminal session! You've taken an important step towards improving your respiratory health and achieving quiet and easy breathing. Now, it's time to reflect on your experience and implement helpful techniques in your daily life."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice deep breathing: Learn and practice deep breathing techniques that can help relax your airways and reduce noise.",
        "Maintain good posture: Sit or stand up straight to keep your airways open and facilitate smoother breathing.",
        "Stay hydrated: Drink enough water to keep your respiratory system lubricated and minimize throat dryness.",
        "Avoid triggers: Identify and avoid triggers that can worsen your breathing, such as allergens or irritants.",
        "Consider nasal strips or devices: Nasal strips or devices can help improve nasal airflow and reduce snoring or noisy breathing."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, improving your respiratory health is a gradual process. Be patient and consistent with implementing these techniques. With practice and persistence, you can achieve quieter and easier breathing, leading to better overall well-being. Breathe easy!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What causes noisy breathing?`, 
    answer: `Noisy breathing can have various causes, including nasal congestion, throat relaxation during sleep, allergies, asthma, chronic obstructive pulmonary disease (COPD), and other respiratory conditions. It is important to identify the underlying cause of your noisy breathing in order to determine the most appropriate treatment and management strategies.`,
    open: false
  },
  { 
    question: `Can the Stop Noisy Breathing techniques be used for temporary relief or long-term management?`, 
    answer: `The techniques provided for stopping noisy breathing can be used for both temporary relief and long-term management, depending on the cause and severity of the issue. For example, techniques such as nasal decongestion or breathing exercises can provide temporary relief, while lifestyle changes and management of underlying conditions can support long-term improvement in respiratory health.`,
    open: false 
  },
  { 
    question: `What are some lifestyle changes that can help with noisy breathing?`, 
    answer: `There are several lifestyle changes that can help improve respiratory health and reduce noisy breathing. These include maintaining a healthy weight, avoiding triggers such as tobacco smoke and air pollution, practicing good posture, staying hydrated, managing stress, and adopting good sleep hygiene practices.`,
    open: false 
  },
  { 
    question: `Are there any natural remedies for reducing noisy breathing?`, 
    answer: `Certain natural remedies may help reduce noisy breathing, but their effectiveness can vary from person to person. Some potential remedies include using saline nasal rinses, using a humidifier to add moisture to the air, practicing nasal irrigation with a neti pot, and incorporating herbal remedies like eucalyptus or peppermint into your self-care routine. It is important to consult with a healthcare professional before trying any new remedies, especially if you have underlying respiratory conditions.`,
    open: false 
  },
  { 
    question: `When should I seek medical help for my noisy breathing?`, 
    answer: `You should seek medical help for your noisy breathing if it is persistent, worsening, or accompanied by other concerning symptoms such as chest pain, difficulty breathing, wheezing, or coughing up blood. A healthcare professional can evaluate your symptoms, conduct a thorough examination, and recommend appropriate diagnostic tests or treatments based on your individual situation.`,
    open: false 
  },
  { 
    question: `Can the Stop Noisy Breathing techniques be used by individuals with respiratory conditions?`, 
    answer: `The Stop Noisy Breathing techniques can be used by individuals with respiratory conditions, but it is important to consult with a healthcare professional for personalized advice and guidance. Depending on the specific condition and its severity, certain techniques may be more appropriate or require modification to ensure safety and effectiveness.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>