<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Dealing with female genital warts can be uncomfortable and challenging, but this subliminal is here to help you find relief and promote healing. By targeting your subconscious mind, it supports your body's natural ability to heal and recover."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help you:"
    },
    {
      type: 'list',
      items: [
        "Relieving discomfort associated with genital warts and supporting the healing process.",
        "Boosting your immune system to fight off the virus that causes genital warts.",
        "Promoting overall vaginal health and restoring balance in the affected area.",
        "Reducing inflammation and soothing any irritation or itching caused by the warts.",
        "Encouraging a positive mindset and emotional well-being as you navigate this condition."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind to support your body's healing process and promote overall vaginal health. It's important to remember that seeking medical advice and treatment is always recommended for genital warts. This subliminal can complement your existing efforts and provide additional support on your journey to healing."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Dealing with the discomfort and health issues associated with female genital warts can be challenging. This subliminal is designed to aid in the healing process and promote overall vaginal health. Before you begin listening, consider the following strategies to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Educate yourself: Learn about female genital warts, their causes, and treatment options. Knowledge empowers you to make informed decisions about your health.",
        "Self-care routine: Create a self-care routine that focuses on gentle cleansing and moisturizing to promote vaginal health.",
        "Emotional well-being: Prioritize your mental and emotional well-being. Engage in stress-reducing activities or seek support from loved ones or professionals.",
        "Focus on healing: While listening to the subliminal, focus your attention on the intention to heal and promote the health of your body."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is here to support you on your healing journey. By combining the power of your subconscious mind with self-care practices and a positive mindset, you can find relief and promote overall vaginal health. Embrace this opportunity to prioritize your well-being, and let the healing process begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Female Genital Warts Healing subliminal! By focusing on healing and treating genital warts, you have taken a significant step towards relieving discomfort and promoting overall vaginal health. Now, it is important to reflect on your experience and continue taking care of your well-being."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Visit your healthcare provider: If you haven't done so already, it's important to consult with a healthcare professional for proper diagnosis and treatment options.",
        "Practice good hygiene: Maintain regular and gentle cleansing of the affected area to prevent secondary infections.",
        "Follow treatment recommendations: Adhere to any prescribed treatment plans and medications for genital wart removal and healing.",
        "Monitor your body: Pay attention to any changes or symptoms and address them promptly with your healthcare provider."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, the healing process may take time, and it's important to be patient and consistent with your self-care routine. By seeking professional help and following recommended treatment plans, you are taking the necessary steps towards relieving discomfort and promoting overall vaginal health. Stay positive and committed to your well-being!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How long does it take for female genital warts to heal?`, 
    answer: `The healing time for female genital warts can vary depending on various factors such as the individual's immune system, the size and number of warts, and the treatment methods used. In some cases, warts may go away on their own within a few months, while others may require medical intervention. It is important to consult with a healthcare professional for an accurate diagnosis and appropriate treatment plan.`,
    open: false
  },
  { 
    question: `What are the treatment options for female genital warts?`, 
    answer: `Treatment options for female genital warts may include topical medications, such as creams or ointments, that help to remove or reduce the warts. Other methods such as cryotherapy (freezing the warts), laser therapy, or surgical removal may also be recommended by a healthcare professional. The chosen treatment option will depend on the size, location, and number of warts, as well as the individual's overall health and preferences.`,
    open: false 
  },
  { 
    question: `Can the Female Genital Warts Healing subliminal treat genital warts without medical intervention?`, 
    answer: `The Female Genital Warts Healing subliminal is designed to promote overall vaginal health and well-being, but it is not a substitute for medical treatment. It can be used as a complementary tool to support the healing process and relieve discomfort, but it is important to consult with a healthcare professional for proper diagnosis and treatment of genital warts.`,
    open: false 
  },
  { 
    question: `Will the Female Genital Warts Healing subliminal prevent the recurrence of genital warts?`, 
    answer: `The Female Genital Warts Healing subliminal is not guaranteed to prevent the recurrence of genital warts. It is designed to promote healing and relieve discomfort, but it is important to address the underlying cause of the warts and follow the recommended treatment plan provided by a healthcare professional to minimize the risk of recurrence.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with the Female Genital Warts Healing subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Female Genital Warts Healing subliminal. However, if you have any concerns or questions about your condition or treatment, it is important to consult with a healthcare professional for individualized advice and guidance.`,
    open: false 
  },
  { 
    question: `Can the Female Genital Warts Healing subliminal be used during pregnancy?`, 
    answer: `Pregnant individuals should consult with their healthcare provider before using any type of subliminal or alternative treatment. It is important to prioritize the safety and well-being of both the mother and the baby. A healthcare professional can provide personalized advice based on the individual's specific circumstances.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>