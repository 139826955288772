export const playlistMixins = {
  created() {
    window.addEventListener("beforeunload", () => {
      if (this.user != null) {
        this.setPlaylistType();
        this.setPlaylistTrack();
        this.setPlaylistLoop();
        this.setPlaylistSubliminalIndex();
      }
    });
  },
  beforeMount() {
    let type = localStorage.getItem("playlistType");
    let track = localStorage.getItem("playlistTrack");
    let playlistSubliminalIndex = localStorage.getItem(
      "playlistSubliminalIndex"
    );
    let loop = localStorage.getItem("playlistLoop");
    if (type != undefined) {
      this.$store.dispatch("audio/getPlaylistType", type);

      if (track != undefined) {
        this.$store.dispatch("audio/getPlaylistTrack", JSON.parse(track));
      }

      if (playlistSubliminalIndex != undefined) {
        this.$store.dispatch(
          "newaudio/subliminalIndex",
          parseInt(playlistSubliminalIndex)
        );
      }

      if (loop != undefined) {
        this.$store.dispatch("newaudio/loopStatus", loop);
      }
      this.removePlaylistType();
      this.removePlaylistTrack();
      this.removePlaylistLoop();
      this.removePlaylistSubliminalIndex();
    }
  },
  computed: {
    playlistType() {
      return this.$store.state.audio.playlist.type;
    },
    playlistTrack() {
      return this.$store.state.audio.playlist.tracks;
    },
    playlistSubliminalIndex() {
      return this.$store.state.newaudio.subliminal.index;
    },
    playlistLoop() {
      return this.$store.state.newaudio.status.loop;
    },
    user() {
      return localStorage.getItem("user");
    },
  },
  watch: {
    playlistType(val) {
      window.addEventListener("beforeunload", () => {
        this.setPlaylistType();
        this.setPlaylistTrack();
        this.setPlaylistLoop();
        this.setPlaylistSubliminalIndex();
      });
    },
  },
  methods: {
    setPlaylistType() {
      if (this.playlistType != null && this.playlistType != "") {
        localStorage.setItem("playlistType", this.playlistType);
      }
    },
    removePlaylistType() {
      localStorage.removeItem("playlistType");
    },
    setPlaylistTrack() {
      if (this.playlistTrack.length > 0) {
        localStorage.setItem(
          "playlistTrack",
          JSON.stringify(this.playlistTrack)
        );
      }
    },
    removePlaylistTrack() {
      localStorage.removeItem("playlistTrack");
    },
    setPlaylistSubliminalIndex() {
      if (this.playlistSubliminalIndex != null) {
        localStorage.setItem(
          "playlistSubliminalIndex",
          this.playlistSubliminalIndex
        );
      }
    },
    removePlaylistSubliminalIndex() {
      localStorage.removeItem("playlistSubliminalIndex");
    },
    setPlaylistLoop() {
      if (this.playlistLoop != null) {
        localStorage.setItem("playlistLoop", this.playlistLoop);
      }
    },
    removePlaylistLoop() {
      localStorage.removeItem("playlistLoop");
    },
  },
};
