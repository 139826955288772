<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Childbirth is a transformative experience, but for some, it can also be traumatic. If you're struggling with the emotional and physical effects of a traumatic childbirth, this subliminal can help you find healing and resolution."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support your healing:'
    },
    {
      type: 'list',
      items: [
        "Promoting emotional healing and helping you process any feelings of fear, sadness, or anger.",
        "Addressing physical discomfort or pain associated with childbirth and supporting your body's natural healing processes.",
        "Providing a safe and nurturing space for you to release any trauma or negative emotions related to childbirth.",
        "Supporting your overall well-being and helping you regain a sense of empowerment and self-worth.",
        "Encouraging a positive mindset and helping you develop resilience and strength during your healing journey."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can guide your subconscious mind towards healing and transformation. It's important to remember that healing takes time and that every individual's journey is unique. Be patient with yourself and allow this subliminal to support you as you navigate your healing process."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Healing from childbirth trauma is an important step towards emotional and physical well-being. This subliminal is specifically designed to support your journey of healing. Before you start listening, here are some suggestions to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a safe space: Find a quiet and comfortable environment where you can focus on your healing journey.",
        "Set your intention: Reflect on your desire to heal and let go of any emotional or physical pain associated with childbirth.",
        "Practice self-care: Engage in activities that promote relaxation and self-nurturing, such as gentle exercise, deep breathing, or taking a warm bath.",
        "Maintain openness: Approach the subliminal affirmations with an open mind and heart, allowing them to resonate and support your healing process."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you may find yourself experiencing emotional healing, increased self-compassion, and a sense of empowerment. Remember to be gentle with yourself and allow the healing journey to unfold at its own pace. Take a moment to ground yourself, set your intention for healing, and let this transformative process begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Childbirth Trauma Healing subliminal session! By embarking on this journey of healing, you have taken a significant step towards experiencing emotional and physical well-being. It's important to reflect on the progress you've made and continue nurturing yourself throughout this healing process."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Journal your emotions: Express your feelings and experiences in a safe space, allowing yourself to process and heal.",
        "Seek support: Consider reaching out to a therapist, counselor, or support group who specializes in trauma healing.",
        "Practice self-care: Prioritize self-care activities that nourish your mind, body, and soul.",
        "Engage in self-compassion: Be gentle with yourself and acknowledge that healing takes time."
      ]
    },
    {
      type: 'paragraph',
      text: "Healing from childbirth trauma is a personal journey, and it's essential to be patient and kind to yourself along the way. Remember that you are not alone, and seeking support is a sign of strength. Allow yourself to heal, grow, and rediscover your inner strength. Embrace the transformative power of healing and create a path of emotional and physical well-being."
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Is it common to experience trauma after childbirth?`, 
    answer: `Childbirth trauma is not uncommon and can affect individuals differently. Factors such as a difficult labor, medical complications, or feelings of loss of control during the birth process can contribute to the development of traumatic feelings. It is important to address and seek support for any emotional or physical trauma experienced during childbirth.`,
    open: false
  },
  { 
    question: `How can the Childbirth Trauma Healing subliminal help with healing?`, 
    answer: `The Childbirth Trauma Healing subliminal is designed to support emotional and physical healing after childbirth. By listening to the subliminal messages, you can promote a positive mindset, release negative emotions, and cultivate self-compassion. It is a tool that can complement other therapeutic techniques or support systems in your healing journey.`,
    open: false 
  },
  { 
    question: `How long does it take to heal from childbirth trauma?`, 
    answer: `The healing process from childbirth trauma varies for each individual, and there is no set timeline. It may depend on factors such as the severity of the trauma, the support system available, and the individual's resilience. It is important to be patient and kind to yourself as you navigate your healing journey. Seeking professional support or joining a support group can also be beneficial.`,
    open: false 
  },
  { 
    question: `Can the Childbirth Trauma Healing subliminal replace therapy or professional support?`, 
    answer: `No, the Childbirth Trauma Healing subliminal is not a substitute for therapy or professional support. It is designed to complement other forms of therapy or support systems by promoting a positive mindset and self-healing. If you are struggling with the effects of childbirth trauma, it is recommended to seek professional guidance for a comprehensive approach to your healing process.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Childbirth Trauma Healing subliminal?`, 
    answer: `There are no known risks or side effects associated with listening to the Childbirth Trauma Healing subliminal. However, it is important to prioritize your mental and emotional well-being and seek professional support if needed. While the subliminal can be a useful tool, it is not a replacement for professional guidance.`,
    open: false 
  },
  { 
    question: `Can the Childbirth Trauma Healing subliminal be used during pregnancy?`, 
    answer: `The Childbirth Trauma Healing subliminal is designed to support healing after childbirth. It is not specifically intended for use during pregnancy. It is recommended to consult with a healthcare professional regarding any emotional or physical concerns during pregnancy.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>