<template>
  <section>
    <div class="container1">
      <div style>
        <h1 class="mt-5">Create your new Password!</h1>
        <h5 class="mb-7">Always remember your password from now on.</h5>
        <div class="wraps">
          <div class="inputs">
            <h5 v-show="password != ''" class="h51">
              <span>New Password</span>
              <span class="input-invalid">{{ password_valid }}</span>
            </h5>
            <v-text-field
              flat
              solo
              class="shrink"
              :type="showPassword ? 'text' : 'password'"
              placeholder="New Password"
              v-model="password"
              @click:append="showPassword = !showPassword"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            ></v-text-field>
          </div>
          <div class="inputs">
            <v-divider class="divider"></v-divider>
            <h5 v-show="passwordConfirm != ''" class="h51">
              <span>Confirm New Password</span>
              <span class="input-invalid">{{ passwordConfirm_valid }}</span>
            </h5>
            <v-text-field
              flat
              solo
              class="shrink"
              :type="showPasswordConfirm ? 'text' : 'password'"
              placeholder="Confirm New Password"
              v-model="passwordConfirm"
              @click:append="showPasswordConfirm = !showPasswordConfirm"
              :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
            ></v-text-field>
          </div>
        </div>
        <div
          style="width: max-content; margin: auto"
          class="justify-center align-center mt-0"
        >
          <p v-show="message != ''" medium class="message">{{ message }}</p>
        </div>
        <button
          class="button btn btn-primary btn-lg mt-5"
          :disabled="!isChangeReady"
          @click="changePassword()"
        >
          CHANGE PASSWORD
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { getUserResetPassword, userChangePassword } from "@/api/user";
import { getMessage } from "@/utils";

export default {
  data: () => {
    return {
      getMessage,
      getUserResetPassword,
      userChangePassword,

      password: "",
      passwordConfirm: "",
      message: "",
      password_valid: "",
      passwordConfirm_valid: "",

      showPassword: false,
      showPasswordConfirm: false,
      time_allow: 100,
    };
  },
  created() {
    this.getResetPassword();
  },
  mounted() {
    this.$store.dispatch("screen/getLoading", false);
  },
  watch: {
    password(value) {
      if (value != "") {
        this.message = "";
        this.validatePassword(value);
      }
    },
    passwordConfirm(value) {
      this.passwordMatch();
    },
  },
  computed: {
    isUser() {
      return this.userType == "user";
    },
    userType() {
      return this.loginQueryParams();
    },
    isChangeReady() {
      return this.checkForm();
    },
  },
  methods: {
    validatePassword(password) {
      if (password.length < 8) {
        this.password_valid = "Min 8 characters";
      } else {
        if (/[0-9]/.test(password)) {
          if (/[a-z]/.test(password)) {
            if (/[A-Z]/.test(password)) {
              this.password_valid = "";
            } else {
              this.password_valid = "At least one uppercase letter";
            }
          } else {
            this.password_valid = "At least one lowercase letter";
          }
        } else {
          this.password_valid = "At least one number";
        }
      }
    },
    closeDialog() {
      this.dialog = false;
    },
    resetForm() {
      this.password = "";
      this.passwordConfirm = "";
      this.$store.dispatch("screen/getLoading", false);
    },
    checkForm() {
      return (
        this.password != "" &&
        this.passwordConfirm != "" &&
        this.password == this.passwordConfirm
      );
    },
    passwordMatch() {
      if (this.password != this.passwordConfirm) {
        this.passwordConfirm_valid = "Password does not match";
      } else {
        this.passwordConfirm_valid = "";
      }
    },
    getTimeDiffInMinutes(dt2, dt1) {
      var diff = (dt2.getTime() - dt1.getTime()) / 1000 / 60;
      return Math.abs(Math.round(diff));
    },
    subtractHours(date, hours) {
      date.setHours(date.getHours() - hours);

      return date;
    },
    getResetPassword() {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");
      const data = {
        token: token,
      };

      this.getUserResetPassword(data).then((res) => {
        const { data } = res;

        if (data.length > 0) {
          this.user_id = data[0].user_id;

          const created_at = new Date(data[0].created_at)
            .toUTCString()
            .toString();

          const dt1 = new Date(created_at);
          const dt2 = new Date();

          const result = this.getTimeDiffInMinutes(dt2, dt1);
          if (result > this.time_allow) {
            this.$router.push("/");
          }
        } else {
          this.$router.push("/");
        }

        this.$store.dispatch("screen/getLoading", false);
      });
    },
    changePassword() {
      const data = {
        user_id: this.user_id,
        // current_password: this.current_password,
        password: this.password,
        //new_password_confirmation: this.new_password_confirmation,
      };

      //this.userChangePassword(data);
      console.log(data);
      this.userChangePassword(data).then((data) => {
        this.getMessage(this, data);
        this.resetForm();
      });
    },
  },
};
</script>

<style scoped>
h1 {
  color: var(--ma-tufts-blue);
}
p,
.h51 {
  text-align: left;
  margin-left: 10px;
}
.divider {
  background: black;
  margin-left: 10px;
  margin-top: -10px;
  margin-right: 10px;
}
div.wraps {
  border: solid 0.5px #cecece;
  border-radius: 10px;
  flex-direction: column;
  width: 92%;
  margin-left: 4%;
  padding-top: 1em;
}
div.wraps2 {
  width: 92%;
  margin-left: 4%;
}
.forgot {
  cursor: pointer;
  color: var(--ma-tufts-blue);
  text-decoration: underline;
}
.inputs {
  margin-bottom: -10px;
}
.input-invalid {
  color: red;
  margin-left: 15px;
  font-size: 15px;
  font-weight: 600;
}
.message {
  background: #ffacac !important;
  color: black;
  padding: 10px;
  font-family: "Montserrat-Regular";
  text-align: center;
  border-radius: 10px;
  margin-left: -2px;
}
.separator {
  display: flex;
  text-align: center;
  align-items: center;
}
.separator .line {
  height: 0.5px;
  flex: 1;
  background-color: #272727;
}
.or {
  margin: 10px;
  text-align: center;
  font-family: "Montserrat-Regular";
}
h6 {
  margin: 20px;
}
.sign-up {
  text-decoration: underline;
  color: #427ab3;
  cursor: pointer;
  font-weight: 700;
}
.content {
  padding-inline: 20px;
  margin-bottom: 10px;
  @media screen {
    @media (min-width: 1000px) {
      margin-bottom: 10px;
      padding-inline: 50px;
    }
  }
}
.maintitle {
  font-family: "Montserrat-Bold" !important;
  color: var(--ma-tufts-blue);
  margin-top: 10px !important;
}

@media screen and (min-width: 80rem) {
  section:nth-of-type(1) > .container1 {
    @media screen and (min-width: 1400px) {
      background-image: url("~@/assets/images/main/login/1.png");
      background-repeat: no-repeat;
      background-size: 50%;
      background-position: left;
    }

    @media screen and (min-width: 1400px) {
      height: 100vh;
      padding: 2em;

      background-position: left;
      & > div {
        width: 50%;
        margin-right: 0;
        margin-left: auto;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 20vh;
      }
    }
  }
}
</style>
