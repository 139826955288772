<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're experiencing general pain or discomfort, this subliminal can help provide relief and improve your quality of life. By incorporating soothing sounds and healing techniques, it aims to reduce both physical and emotional pain."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can assist you:'
    },
    {
      type: 'list',
      items: [
        "Relieving muscle aches, tension, and soreness in the body.",
        "Alleviating headaches or migraines and promoting relaxation.",
        "Reducing stress and anxiety that may contribute to physical discomfort.",
        "Promoting a sense of emotional well-being and inner peace.",
        "Improving sleep quality, allowing for better rest and recovery."
      ]
    },
    {
      type: 'paragraph',
      text: "Whether you're dealing with chronic pain or seeking relief from temporary discomfort, this subliminal can support your healing journey. Regular listening can help reframe your mindset and promote physical and emotional healing. Remember, it's important to consult with healthcare professionals for any severe or ongoing pain, but this subliminal can be a valuable complement to your overall pain management strategies."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Living with pain, whether physical or emotional, can greatly impact your daily life and overall well-being. This subliminal is designed to provide you with relief, healing, and relaxation. Before you start listening, here are some suggestions to help enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a comfortable space: Find a quiet and peaceful environment where you can fully relax.",
        "Set your intention: Focus on your desire for relief from pain and discomfort, and envision yourself experiencing that relief.",
        "Practice deep breathing: Take slow, deep breaths to help calm your mind and body before starting.",
        "Be open to healing: Have an open mindset and trust in the power of the subliminal messages to facilitate healing."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, allow yourself to fully immerse in the soothing sounds and healing techniques. Embrace the potential for positive change and improvement in your quality of life. Remember, healing takes time, so be patient and compassionate with yourself. Let's begin your journey towards finding relief from pain and discomfort."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the General Pain Healing session! By dedicating time to soothe your mind and body, you're taking important steps towards finding relief from any physical or emotional pain you may be experiencing. It's now time to reflect on your experience and continue your healing journey."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your emotions: Take time to identify and understand your feelings surrounding the pain you've been experiencing.",
        "Practice self-care: Engage in activities that nurture and restore your mind and body.",
        "Seek professional help if needed: If your pain persists or becomes more severe, consider consulting with a healthcare professional.",
        "Focus on relaxation techniques: Explore relaxation exercises like deep breathing, mindfulness, or meditation to further alleviate pain."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember that healing is a process, and it's important to be patient and kind to yourself along the way. Take the time to prioritize your well-being and continue seeking ways to reduce any pain or discomfort you may be facing. With each step you take, you're moving closer to a higher quality of life and greater peace within. Keep going!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the General Pain Healing audio help with my pain?`, 
    answer: `The General Pain Healing audio is designed to provide relief from general pain and discomfort through soothing sounds and healing techniques. By listening to the audio, you can experience a reduction in both physical and emotional pain, improving your overall quality of life. The combination of relaxing sounds and healing affirmations promotes a sense of calm, relaxation, and healing.`,
    open: false
  },
  { 
    question: `How often should I listen to the General Pain Healing audio?`, 
    answer: `The frequency of listening to the General Pain Healing audio can vary depending on your needs. It can be beneficial to incorporate it into your daily routine or whenever you're experiencing pain or discomfort. Consistent listening can help reinforce the healing affirmations and promote a sense of well-being.`,
    open: false 
  },
  { 
    question: `Can the General Pain Healing audio replace medical treatment for chronic pain conditions?`, 
    answer: `No, the General Pain Healing audio is not intended to replace medical treatment for chronic pain conditions. It is designed to complement other treatment methods and promote relaxation and comfort. If you have a chronic pain condition, it is important to consult with your healthcare provider for a comprehensive treatment plan.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the General Pain Healing audio?`, 
    answer: `No, there are no known risks or side effects associated with listening to the General Pain Healing audio. However, if you have any concerns about your pain or health, please consult with your healthcare provider. It is important to note that the audio is intended to supplement other pain management techniques and not replace them.`,
    open: false 
  },
  { 
    question: `What if the General Pain Healing audio doesn't work for me?`, 
    answer: `While the General Pain Healing audio is designed to provide relief from general pain and discomfort, its effectiveness can vary from person to person. If you do not experience the desired results, you may consider exploring other pain management techniques or consulting with a healthcare professional for personalized advice. It's important to find the approach that works best for you.`,
    open: false 
  },
  { 
    question: `Can the General Pain Healing audio be used for emotional pain as well?`, 
    answer: `Yes, the General Pain Healing audio can be beneficial for both physical and emotional pain. The soothing sounds and healing techniques can provide comfort and relaxation, promoting a sense of emotional well-being. However, it is important to address underlying emotional issues with appropriate support or therapy if needed.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>