<template>
  <main class="mx-auto">
    <section v-if="selected == 1" class="d-flex">
      <card>
        <h1>Tell us a bit about yourself</h1>
        <div>
          <p>Name</p>
          <input v-model="message" />
        </div>
        <div>
          <p>Contact Number</p>
          <input v-model="message" />
        </div>
        <div>
          <p>Email Address</p>
          <input v-model="message" />
        </div>
        <button @click="goToRoute('/business')" class="button-2 btn">
          BACK
        </button>
        <button @click="next(selected)" class="button_2 btn">PROCEED</button>
      </card>
    </section>
    <section v-else-if="selected == 2" class="d-flex">
      <card>
        <h1>What are you working on?</h1>
        <div>
          <p>Name</p>
          <input v-model="message" />
        </div>
        <div>
          <p>Company Size</p>
          <input v-model="message" />
        </div>
        <div>
          <p>Country</p>
          <input v-model="message" />
        </div>
        <div>
          <p>Industry</p>
          <input v-model="message" />
        </div>
        <button @click="prev(selected)" class="button-2 btn">BACK</button>
        <button @click="next(selected)" class="button_2 btn">PROCEED</button>
      </card>
    </section>
    <section v-else class="d-flex">
      <card>
        <h1>How can we help you?</h1>
        <div>
          <p>Message</p>
          <textarea v-model="message" />
        </div>
        <button @click="prev(selected)" class="button-2 btn">BACK</button>
        <button @click="next(selected)" class="button_2 btn">PROCEED</button>
      </card>
    </section>
  </main>
</template>
<script>
export default {
  data: () => {
    return {
      selected: 1,
      message: "",
      name: "",
    };
  },
  methods: {
    goToRoute(route) {
      if (this.currentRoute != route) {
        this.$router.push(route).catch((err) => {});
      }
    },
    next(selected) {
      this.selected = selected + 1;
    },
    prev(selected) {
      this.selected = selected - 1;
    },
  },
};
</script>
<style scoped>
main {
  overflow-x: hidden;
  overflow-y: hidden;
  height: 80vh;
  color: #404040;
  padding-block: 40px;
}

section {
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    text-align: center;

    & div {
      order: 1;
    }
  }
}

header {
  font-family: "Montserrat";
  max-width: 70ch;
  margin: 50px;

  & > h1 {
    font-size: 50px;
    font-weight: 700;

    @media screen {
      @media (max-width: 700px) {
        font-size: 40px;
      }

      @media (max-width: 400px) {
        font-size: 30px;
      }
    }
  }
  & > h5 {
    font-size: 20px;
    font-weight: 400;
    line-height: normal;

    @media screen {
      @media (max-width: 700px) {
        margin-inline: 20px;
        font-size: 16px;
      }

      @media (max-width: 400px) {
        font-size: 12px;
      }
    }
  }
  & > p {
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
    margin-top: 20px;

    @media screen {
      @media (max-width: 700px) {
        margin-inline: 20px;
        font-size: 12px;
      }

      @media (max-width: 400px) {
        font-size: 9px;
      }
    }
  }
}

card {
  font-family: "Montserrat";
  width: 90vw;
  text-align: center;
  margin: 50px;
  & > div {
    width: 400px;
    margin: auto;
    text-align: left;
    & p {
      margin-bottom: 0px;
      margin-top: 20px;

      color: #1c8edf;

      font-size: 20px;
      font-weight: 700;
    }
    & input {
      width: 400px;
      height: 40px;

      margin: auto;

      border-radius: 10px;
      border: solid 1px black;
    }
    & textarea {
      width: 400px;
      height: 200px;

      margin: auto;

      border-radius: 10px;
      border: solid 1px black;
    }
  }
  & > h1 {
    font-size: 50px;
    font-weight: 700;

    @media screen {
      @media (max-width: 700px) {
        font-size: 40px;
      }

      @media (max-width: 400px) {
        font-size: 30px;
      }
    }
  }
  & > h5 {
    font-size: 20px;
    font-weight: 400;
    line-height: normal;

    @media screen {
      @media (max-width: 700px) {
        margin-inline: 20px;
        font-size: 16px;
      }

      @media (max-width: 400px) {
        font-size: 12px;
      }
    }
  }
  & > p {
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
    margin-top: 20px;

    @media screen {
      @media (max-width: 700px) {
        margin-inline: 20px;
        font-size: 12px;
      }

      @media (max-width: 400px) {
        font-size: 9px;
      }
    }
  }
}

img {
  width: 100%;
  margin: auto 0px;

  @media screen and (max-width: 1000px) {
    width: calc(100% - 70%);
  }
}
.button {
  width: 300px;
}
.button_2,
.button-2 {
  width: 150px;
  margin: 10px;
}
.button,
.button_2 {
  color: #ffffff;
  background: #1c8edf;
}
.button-2 {
  border: #1c8edf 1px solid;
  color: #1c8edf;
}
.button,
.button_2,
.button-2,
.button:hover {
  margin-top: 20px;
  font-family: "Raleway";
  font-weight: 700;
  font-size: 15px;
  border-radius: 10px;
}
</style>
