<template>
  <div>
    <Dialog
      :dialog="dialog"
      :width="350"
      :title="`Delete Admin`"
      @closeDialog="closeDialog"
    >
      <p class="mb-0">You are about to delete Admin : {{ name }}</p>

      <template v-slot:action>
        <v-row class="ma-0">
          <v-col class="pl-0 pr-2">
            <v-btn block @click="submitDialog()" :disabled="disabledbutton"
              >Delete</v-btn
            >
          </v-col>
          <v-col class="pl-2 pr-0">
            <v-btn block @click="closeDialog()">Cancel</v-btn>
          </v-col>
        </v-row>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/Dialog.vue'

import { getMessage } from '@/utils'
import { deleteUsers } from '@/api/user.js'

export default {
  props: {
    dialog: {
      type: Boolean
    },
    data: {
      type: Array
    },
    selected: {
      type: Array
    }
  },
  data: () => {
    return {
      getMessage,
      deleteUsers,

      name: null,

      disabledbutton: false,

      select: []
    }
  },
  components: {
    Dialog
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      if (this.data.length > 1) {
        this.name = `${this.data.length} items`
      } else {
        this.name = `${this.data[0].name}`
      }
    },
    closeDialog() {
      this.$emit('closeDialog', false)
    },
    submitDialog() {
      let data = this.data
      this.select = this.selected
      this.disabledbutton = true

      for (let index = 0; index < data.length; index++) {
        const element = data[index]
        this.select = this.select.filter(function (obj) {
          return obj.id !== element.id
        })

        this.deleteUsers(element).then((data) => {
          this.$emit('selected', this.select)
          this.getMessage(this, data)
          this.closeDialog()
        })
      }
    }
  }
}
</script>
