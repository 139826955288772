<template>
  <div>
    <template v-for="(detail, index) in moods_details">
      <div
        v-if="isMoodExist && index == 0"
        class="d-flex align-center mb-5"
        :key="index"
      >
        <img
          :src="detail.image"
          class="mood p-2"
          @click="goToRoute('/user/mood')"
          @mouseenter="mood_id = detail.id"
          @mouseleave="mood_id = 0"
          :style="`background-color: ${
            mood_id == detail.id ? detail.description : ''
          };`"
        />
        <div v-if="detail.status == 'Positive'" class="ml-10">
          <h6>Glad that you're feeling {{ detail.name }} today.</h6>
          <h6>Here are recommended subliminal for you to boost your mood.</h6>
        </div>
        <div v-else class="ml-10">
          <h6>We're here to cheer you up!</h6>
          <h6>Here are recommended subliminal for you to boost your mood.</h6>
        </div>
      </div>
    </template>
    <div v-if="categories.length > 0">
      <h5>Category</h5>
      <div class="pb-3" style="height: 170px">
        <v-slide-group multiple show-arrows>
          <v-slide-item v-if="categories.length != 1">
            <div class="card1 mx-2" 
              :style="`border: ${
                selected_category == '' ? `5px #1c8edf solid` : ''
              };`"
              @click="getCategory('')">
              <p class="mb-0">All</p>
            </div>
          </v-slide-item>
          <v-slide-item v-for="(category, index) in categories" :key="index">
            <div
              class="card mx-2"
              :style="`background: url(${category.image}); border: ${
                  selected_category == category.id ? `5px #1c8edf solid` : ''
                };`"
              @click="getCategory(category.id)"
            >
              <p class="mb-0">{{ category.name }}</p>
            </div>
          </v-slide-item>
        </v-slide-group>
      </div>
    </div>
  </div>
</template>

<script>
import { getRecommendation } from "@/api/home.js";

export default {
  data: () => {
    return {
      getRecommendation,
      selected_category: '',
      mood_id: 0,
    };
  },
  computed: {
    categories() {
      return this.$store.state.category.user.data;
    },
    user() {
      return JSON.parse(this.$store.state.user.info);
    },
    moods() {
      return this.$store.state.mood.data;
    },
    moods_details() {
      return this.moods.filter((items) => items.id == this.user.info.moods_id);
    },
    isMoodExist() {
      return this.user != null
        ? this.user.info.moods_id != "" && this.user.info.moods_id != null
        : false;
    },
  },
  methods: {
    getCategory(id) {
      this.selected_category = id;

      let mood = "";
      if (this.moods_details[0] != undefined) {
        mood = this.moods_details[0].id;
      }
      const data = {
        user_id: this.user.user_id,
        mood_id: mood,
        subscription_id: this.user.info.subscription_id,
        category_id: id,
      };
      this.getRecommendation(data).then((res) => {
        const playlist = res.playlist;
        const subliminal = res.subliminal;
        let result = playlist.concat(subliminal);

        const results = result.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        this.$store.dispatch("recommendation/getData", subliminal);
        this.$store.dispatch("recommendation/getLoading", false);
      });
    },
    goToRoute(route) {
      if (this.currentRoute != route) {
        this.$router.push(route).catch((err) => {});
        window.scrollTo(0, 0);
      }
    },
  },
};
</script>

<style>
.v-slide-group__next,
.v-slide-group__prev {
  width: 15px !important;
  min-width: 15px !important;
  flex: 0 1 15px !important;
}
</style>

<style scoped>
.card {
  width: 250px;
  height: 150px;
  padding: 10px;
  color: white;
  text-shadow: 1px 1px black;

  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}
.card:hover,
.card1:hover {
  color: black;
  width: 270px;
  height: 170px;
  text-shadow: 0px 0px;
}
.card1 {
  background: url("~@/assets/images/user/dashboard/all.png") blue;
  width: 250px;
  height: 150px;
  padding: 10px;
  color: white;
  text-shadow: 1px 1px black;

  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  border-radius: 5px;
}

.card:nth-child(1) {
  margin-left: 0px !important;
}

p {
  font-size: 16px;
}
h6:nth-of-type(2) {
  font-family: "Montserrat-Regular";
}
img {
  width: clamp(6.31rem, calc(0.25rem + 5.63vw), 5.9rem);
  cursor: pointer;
}
img:hover {
  /* background: var(--ma-tufts-blue); */
}
</style>
