<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're dissatisfied with the appearance of your double chin and longing for a more defined and sculpted jawline, this subliminal can help you achieve your goals. It combines targeted exercises and techniques to specifically address the issue of a double chin."
    },
    {
      type: 'heading',
      text: 'Here are some key features of this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Includes exercises and techniques designed to tone and strengthen the muscles in the jaw and neck area.",
        "Targets the accumulation of excess fat and skin under the chin to help reduce the appearance of a double chin.",
        "Encourages healthy lifestyle habits, such as regular exercise and a balanced diet, to support overall weight management.",
        "Promotes a positive mindset and self-image, fostering confidence and motivation in your journey towards a more defined jawline.",
        "Offers guidance and support to help you stay consistent and committed to your double chin removal goals."
      ]
    },
    {
      type: 'paragraph',
      text: "It's important to note that individual results may vary, and it may take time to see noticeable changes. Consistency and dedication to the exercises and techniques provided are key to achieving the desired results. Remember, embracing a healthy lifestyle and maintaining a positive mindset are essential for long-lasting success in your double chin removal journey."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Eliminating a double chin and achieving a more defined jawline requires commitment and consistency. This subliminal is designed to support your efforts by harnessing the power of your mind. Before you begin, consider these suggestions to optimize your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Learn the exercises: Familiarize yourself with targeted exercises and techniques specifically designed to reduce double chin and sculpt the jawline.",
        "Create a routine: Develop a consistent routine to perform these exercises, ideally incorporating them into your daily schedule.",
        "Visualize your goal: As you engage in the exercises, visualize your double chin diminishing and your jawline becoming more defined.",
        "Maintain focus: While listening to the subliminal affirmations, stay focused on your intention of achieving a more sculpted jawline."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal can help you tap into the power of your mind to enhance the effectiveness of your exercises. With regular listening and consistent effort, you may notice gradual improvements in the appearance of your chin and jawline. Embrace this opportunity to take control of your self-image and create the results you desire."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Double Chin Removal session! You've taken a step towards achieving a more defined and sculpted jawline. Now that you have learned targeted exercises and techniques, it's time to reflect on your experience and continue working towards your goal."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Consistency is key: Make sure to practice the targeted exercises regularly for the best results.",
        "Evaluate your progress: Take pictures or measurements of your double chin area to track changes.",
        "Maintain a healthy lifestyle: In addition to the exercises, focus on eating a balanced diet and staying hydrated.",
        "Stay positive: Remember that progress takes time, so be patient and celebrate small victories along the way."
      ]
    },
    {
      type: 'paragraph',
      text: "Achieving a more defined and sculpted jawline requires dedication and effort. By staying consistent with the exercises and maintaining a healthy lifestyle, you are on the right path. Embrace the journey and keep working towards your goal. You've got this!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Can the Double Chin Removal techniques really help reduce my double chin?`, 
    answer: `Yes, the Double Chin Removal techniques can be effective in reducing the appearance of a double chin. These techniques involve targeted exercises and techniques that help strengthen and tone the muscles in the chin and neck area. Consistently practicing these exercises can help improve muscle definition and reduce excess fat under the chin.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Double Chin Removal techniques?`, 
    answer: `Results can vary depending on individual factors such as genetics, lifestyle, and consistency of practicing the techniques. Some people may start noticing improvements within a few weeks, while others may require more time. It's important to be patient and consistent with the exercises to achieve desired results.`,
    open: false 
  },
  { 
    question: `Are the Double Chin Removal techniques safe?`, 
    answer: `Yes, the Double Chin Removal techniques are generally safe when practiced correctly. However, it is important to listen to your body and avoid overexertion or strain. If you have any underlying medical conditions or concerns, it's recommended to consult with a healthcare professional before starting any new exercise regimen.`,
    open: false 
  },
  { 
    question: `Do I need any special equipment to perform the Double Chin Removal exercises?`, 
    answer: `No, you do not need any special equipment to perform the Double Chin Removal exercises. Most of the exercises can be done using just your body weight and can be easily incorporated into your daily routine.`,
    open: false 
  },
  { 
    question: `Can the Double Chin Removal techniques help me lose weight overall?`, 
    answer: `The Double Chin Removal techniques primarily focus on toning and strengthening the muscles in the chin and neck area. While regular exercise can contribute to an overall healthy lifestyle, these techniques are not specifically designed for weight loss. If your goal is to lose weight, it is recommended to incorporate a balanced diet and regular exercise routine that targets your entire body.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with the Double Chin Removal techniques?`, 
    answer: `When performed correctly, the Double Chin Removal techniques are generally safe and do not pose any significant risks or side effects. However, as with any exercise regimen, it's important to listen to your body and avoid overexertion. If you experience any discomfort or pain, it's recommended to modify or discontinue the exercises and consult with a healthcare professional.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>