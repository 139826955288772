<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're looking to achieve strong, healthy, and beautiful hair, this subliminal can help you on your journey. By tapping into the power of your subconscious mind, it promotes natural methods to promote hair growth and improve the overall health of your hair."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Stimulating hair follicles to promote faster and healthier hair growth.",
        "Increasing blood circulation to the scalp, providing essential nutrients to the hair follicles.",
        "Boosting the production of collagen and keratin, key proteins for strong and vibrant hair.",
        "Reducing hair breakage and strengthening the hair shaft.",
        "Improving the overall health and appearance of your hair, making it shinier, smoother, and more manageable."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to support healthy hair growth and maintenance. Remember, patience and consistency are key when it comes to achieving long-lasting results. Start your journey to beautiful and vibrant hair today!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "If you're looking to enhance the health and growth of your hair, this subliminal can provide the support and motivation you need. Before starting your listening session, consider these tips to optimize your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your hair goals: Define what you want to achieve, such as stronger, thicker, or longer hair.",
        "Establish a consistent hair care routine: Devote time to nurturing your hair, including regular washing, conditioning, and deep treatments.",
        "Nourish your body: Support hair growth by consuming a balanced diet, rich in vitamins and essential nutrients.",
        "Practice self-care: Manage stress levels and promote overall well-being, as stress can impact hair health.",
        "Hold positive intentions: Center your focus on your desired hair goals while listening to the subliminal affirmations."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to work in harmony with your efforts to promote healthy hair growth. By integrating these tips into your routine and consistently listening to the subliminal, you can cultivate stronger, healthier, and more beautiful hair. Embrace this opportunity to care for yourself and your hair, and get ready to shine!"
    }
  ]
},
            {
  id: 'after-listening',
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Healthy Hair Growth subliminal! You've taken a step towards achieving strong, healthy, and beautiful hair naturally. Now, it's time to reflect on your experience and continue nurturing your hair for optimal growth and vitality."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Evaluate your hair care routine: Take note of any changes you've made and their effects on your hair.",
        "Maintain a balanced diet: Ensure you're consuming nutrients that promote hair health, such as vitamins A, C, and E, and biotin.",
        "Avoid heat and chemical damage: Limit the use of heat styling tools and harsh chemical treatments that can damage your hair.",
        "Practice stress management: Stress can negatively impact hair growth, so incorporate stress-relieving activities into your routine.",
        "Keep your scalp healthy: Maintain a clean scalp by using gentle, sulfate-free shampoos and scalp treatments.",
        "Protect your hair: Use hats or protective styles to shield your hair from sun exposure, harsh weather, and friction."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, healthy hair growth is a process that requires consistency and patience. Embrace the natural methods and lifestyle changes you've implemented, and be kind to your hair. With time, dedication, and the right care, you'll achieve the strong, healthy, and beautiful hair you desire. Keep shining!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How long does it take to see results with the Healthy Hair Growth subliminal?`, 
    answer: `Results may vary depending on the individual and their unique circumstances. Some people may notice improvements in hair health and growth within a few weeks, while others may require more time and consistent listening to the subliminal. It is important to be patient and consistent with listening to the subliminal to give your hair the best chance to grow stronger and healthier.`,
    open: false
  },
  { 
    question: `Can the Healthy Hair Growth subliminal help with hair loss?`, 
    answer: `The Healthy Hair Growth subliminal is designed to promote overall hair health and encourage natural hair growth. While it may help some individuals experiencing mild to moderate hair loss or thinning, it is not a substitute for professional medical advice or treatment for severe hair loss. Consult with a healthcare professional or dermatologist for personalized advice and treatment options for hair loss.`,
    open: false 
  },
  { 
    question: `How often should I listen to the Healthy Hair Growth subliminal?`, 
    answer: `To reap the full benefits of the Healthy Hair Growth subliminal, it is recommended to listen to it daily or on a regular basis. Consistency is key to enhancing its effectiveness. You may choose to listen to it at a time that is convenient for you, such as during your morning or evening routine.`,
    open: false 
  },
  { 
    question: `Can the Healthy Hair Growth subliminal be used with hair products or treatments?`, 
    answer: `Yes, the Healthy Hair Growth subliminal can be used in conjunction with hair products or treatments. It is designed to complement and support healthy hair habits and natural hair growth. However, it is important to choose hair products and treatments that are suitable for your hair type and consult with a professional stylist or trichologist for personalized advice.`,
    open: false 
  },
  { 
    question: `Are there any side effects of listening to the Healthy Hair Growth subliminal?`, 
    answer: `No known side effects are associated with listening to the Healthy Hair Growth subliminal. The subliminal messages are designed to positively influence your mindset and support healthy hair habits. However, if you have any concerns or experience any unusual reactions, it is advisable to discontinue use and consult with a healthcare professional.`,
    open: false 
  },
  { 
    question: `Can the Healthy Hair Growth subliminal work for all hair types?`, 
    answer: `Yes, the Healthy Hair Growth subliminal is designed to work for all hair types. It is based on promoting overall hair health and natural hair growth, which can be beneficial for all individuals looking to achieve strong, healthy, and beautiful hair. Regardless of hair type, regular care and maintenance are essential for optimal results.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>