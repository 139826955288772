<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're looking to become more extroverted, develop stronger social skills, and connect more easily with others, this subliminal can assist you on your journey."
    },
    {
      type: 'heading',
      text: 'Here are some benefits of using this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Increasing your confidence when interacting with others.",
        "Enhancing your communication skills, both verbally and non-verbally.",
        "Growing your social network and building meaningful connections.",
        "Feeling more comfortable in group settings and social situations.",
        "Finding it easier to initiate conversations and express yourself."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to adopt more extroverted traits, allowing you to thrive in social settings and form deeper connections with others. Remember, personal growth is a continuous journey, and with dedication and practice, you can develop a more extroverted personality."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "If you're looking to enhance your social interactions and become more extroverted, this subliminal can help you achieve your desired personality traits. Before you begin listening, consider the following preparation tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear intentions: Reflect upon the specific extroverted qualities you wish to develop, such as being more outgoing, confident, or engaging.",
        "Visualize your ideal interactions: Picture yourself in social situations, effortlessly initiating conversations, and connecting with others.",
        "Step out of your comfort zone: Embrace opportunities to socialize, even if it feels unfamiliar or challenging at first.",
        "Maintain focus: While listening to the subliminal affirmations, keep your attention on your goals and envision yourself embodying an extroverted personality."
      ]
    },
    {
      type: 'paragraph',
      text: "With consistent listening, this subliminal can help reshape your thoughts, beliefs, and behaviors, allowing you to develop an extroverted personality and thrive in social settings. Embrace this moment of self-improvement and let your journey to becoming more social and connected begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Have an Extroverted Personality subliminal session! By working towards developing and cultivating an extroverted personality, you have taken a significant step towards becoming more social and easily connecting with others. It's time to reflect on your experience and continue your personal growth."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your progress: Take a moment to think about how your social interactions have changed since starting this subliminal.",
        "Step out of your comfort zone: Challenge yourself to engage in social activities or initiate conversations with new people.",
        "Practice active listening: Show genuine interest in others by focusing on what they have to say.",
        "Find common interests: Look for shared hobbies or topics to connect with others more easily."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, developing an extroverted personality is a lifelong journey. Embrace the opportunities to step out of your comfort zone and embrace social connections. With time and practice, you will become more comfortable and confident in social settings. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Can the Have an Extroverted Personality subliminal completely change my personality?`, 
    answer: `The Have an Extroverted Personality subliminal is designed to help you develop and cultivate traits associated with extroversion. While it can positively influence your behavior and mindset towards socializing, it may not completely change your core personality traits. It is important to remember that everyone has a unique personality, and embracing your true self is equally valuable.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Have an Extroverted Personality subliminal?`, 
    answer: `The results may vary from person to person and depend on various factors, including individual mindset, listening consistency, and openness to change. It is recommended to listen to the subliminal consistently over a period of time, typically a few weeks or more, to allow the positive affirmations to reprogram your subconscious and gradually influence your social behavior.`,
    open: false 
  },
  { 
    question: `What can I do to enhance the effectiveness of the Have an Extroverted Personality subliminal?`, 
    answer: `To enhance the effectiveness of the subliminal, it is recommended to actively engage in social situations and practice different interpersonal skills. Pushing yourself out of your comfort zone and challenging any social anxieties can help accelerate the development of an extroverted personality. It is also important to have realistic expectations and be patient with the progress you make.`,
    open: false 
  },
  { 
    question: `Can the Have an Extroverted Personality subliminal help with social anxiety?`, 
    answer: `While the Have an Extroverted Personality subliminal can help you develop traits associated with extroversion, it may not specifically target or cure social anxiety. It can, however, assist in building confidence, reducing self-consciousness, and facilitating more ease in social interactions. If you are experiencing significant social anxiety, it is recommended to seek professional help from a mental health practitioner.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Have an Extroverted Personality subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Have an Extroverted Personality subliminal. However, if you have any concerns or pre-existing mental health conditions, it is advisable to consult with a healthcare provider before using subliminal audios or making any significant changes to your personal development journey.`,
    open: false 
  },
  { 
    question: `Will the Have an Extroverted Personality subliminal make me uncomfortable in social situations?`, 
    answer: `No, the Have an Extroverted Personality subliminal is not intended to make you uncomfortable. Its purpose is to help you develop an extroverted mindset, feel more confident, and enhance your social skills. It should work to build your comfort level in social situations and facilitate natural connections with others.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>