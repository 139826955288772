<template>
  <div>
    <TermsConditions />
  </div>
</template>

<script>
import TermsConditions from '@/views/Main/TermsConditions'
export default {
  components: {
    TermsConditions
  }
}
</script>