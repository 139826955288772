<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're looking to improve your darts skills and become a master of the game, this subliminal can help you on your journey. By targeting your subconscious mind, it can enhance your focus, coordination, and overall performance in darts."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Improving your aim and accuracy by fine-tuning your hand-eye coordination.",
        "Enhancing your concentration and focus, helping you stay in the zone during games.",
        "Boosting your confidence and mental resilience, enabling you to perform under pressure.",
        "Promoting a positive mindset and reducing self-doubt, allowing you to embrace your potential.",
        "Supporting your training efforts by making practice sessions more productive and effective."
      ]
    },
    {
      type: 'paragraph',
      text: "Whether you're a beginner looking to develop a solid foundation or an experienced player aiming to take your skills to the next level, this subliminal can assist you in achieving your goals. Remember to combine it with regular practice and dedication to maximize your progress."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Achieving mastery in the game of darts requires dedication, practice, and a strong mindset. This subliminal is designed to support your journey towards becoming a skilled darts player. Before you start listening, consider these tips to enhance your training experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Define what you want to achieve in your darts training, whether it's improving your accuracy, consistency, or strategy.",
        "Create a training routine: Develop a structured training plan that includes practice drills, exercise, and mental preparation.",
        "Stay focused: Minimize distractions during your training sessions, and fully engage your mind and body in the practice.",
        "Visualize success: Imagine yourself hitting the bullseye consistently and executing your throws with precision and accuracy."
      ]
    },
    {
      type: 'paragraph',
      text: "As you embark on your journey to improve your darts skills, remember that regular practice, determination, and a positive mindset are key. This subliminal can help reinforce your commitment to training and unlock your full potential as a darts player. Embrace the opportunities to challenge yourself, stay motivated, and enjoy the process of mastering the art of darts."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Darts Skills and Training Mastery subliminal! By dedicating time and effort to improve your darts skills, you are on your way to becoming a skilled player in the game. Now, it's important to take a moment to reflect on your experience and integrate what you've learned into your practice routine."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice regularly: Set aside specific practice sessions to hone your darts skills and improve your accuracy.",
        "Analyze your technique: Observe your throw, grip, stance, and follow-through to identify areas for improvement.",
        "Focus on consistency: Work on repeating the same motions and strategies to develop muscle memory and enhance your performance.",
        "Mental preparation: Practice visualization techniques to imagine successful throws and build mental resilience.",
        "Play with others: Engage in friendly matches or join a local darts club to challenge yourself and learn from experienced players."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, mastering darts is a continuous journey that requires dedication and practice. Embrace the challenges and setbacks as opportunities for growth and improvement. With consistent effort and a positive mindset, you will steadily progress and reach new levels of skill and mastery. Keep aiming high!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Darts Skills and Training Mastery subliminal help me improve my darts skills?`, 
    answer: `The Darts Skills and Training Mastery subliminal is designed to enhance your focus, concentration, and muscle memory related to darts. By listening to the subliminal while practicing or visualizing your dart throws, it can help reinforce positive affirmations and subliminal messages that support your skill development. Consistent listening can help you train your mind and body to perform at your best.`,
    open: false
  },
  { 
    question: `How often should I listen to the Darts Skills and Training Mastery subliminal?`, 
    answer: `For optimal results, we recommend listening to the Darts Skills and Training Mastery subliminal daily, ideally before or during practice sessions. Consistency is key in reprogramming your subconscious mind and reinforcing positive affirmations that support your darts training and skill development.`,
    open: false 
  },
  { 
    question: `What are some additional techniques I can use alongside the Darts Skills and Training Mastery subliminal to improve my darts skills?`, 
    answer: `In addition to listening to the subliminal, you can incorporate other techniques to further enhance your darts skills. This may include practicing regularly, seeking guidance from experienced players or coaches, analyzing your technique and making adjustments, and participating in competitions or friendly games to apply your skills under pressure. Consistent dedication and a growth mindset are essential to becoming a skilled player.`,
    open: false 
  },
  { 
    question: `Can the Darts Skills and Training Mastery subliminal make me a professional dart player?`, 
    answer: `The Darts Skills and Training Mastery subliminal is designed to enhance your focus, concentration, and muscle memory related to darts. While it can support your skill development and performance, becoming a professional dart player requires consistent hard work, practice, and dedication. The subliminal is a tool to help you in your journey, but success ultimately depends on your own efforts and commitment.`,
    open: false 
  },
  { 
    question: `Is there a risk of becoming too focused on darts by listening to the Darts Skills and Training Mastery subliminal?`, 
    answer: `No, there is no known risk of becoming too focused on darts by listening to the Darts Skills and Training Mastery subliminal. However, it is important to maintain a balanced lifestyle and allocate time for other areas of your life as well. Remember to prioritize your overall well-being while pursuing your darts goals.`,
    open: false 
  },
  { 
    question: `Is there a money-back guarantee for the Darts Skills and Training Mastery subliminal?`, 
    answer: `Our money-back guarantee policy varies depending on where you purchased the Darts Skills and Training Mastery subliminal. If you have any concerns about your purchase, please reach out to our customer support team for assistance.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>