<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you struggle with a fear of confrontation, you're not alone. Many people find it difficult to speak up in difficult conversations or assert themselves in challenging situations. With this subliminal, you can start to overcome your fear of confrontation and develop the confidence to handle these moments."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help:'
    },
    {
      type: 'list',
      items: [
        "Reducing anxiety and fear around confrontation.",
        "Building self-confidence and assertiveness.",
        "Enhancing communication skills and expressing your thoughts and feelings effectively.",
        "Learning techniques to navigate difficult conversations with ease.",
        "Developing a positive mindset towards confrontation, viewing it as an opportunity for growth and resolution."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can rewire your subconscious mind to release fear and embrace confidence when facing confrontations. Over time, you may notice that you feel more secure in expressing yourself and standing up for your needs. Remember, practice and patience are key to overcoming your fear of confrontation."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Fear of confrontation can hold us back from expressing ourselves and standing up for what we believe in. This subliminal is designed to help you overcome this fear and develop the confidence to assert yourself in difficult conversations and situations. Before you start listening, consider these tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your fear: Recognize that fear of confrontation is natural, but it doesn't have to control you.",
        "Identify triggers: Understand the situations or types of conversations that make you feel uncomfortable or anxious.",
        "Visualize success: Imagine yourself confidently and assertively expressing your thoughts and feelings in confrontational situations.",
        "Remember your goals: Keep in mind that this subliminal is designed to help you build confidence and overcome your fear of confrontation."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal, you can begin to reframe your thoughts and beliefs about confrontation, allowing you to approach difficult conversations with more confidence and assertiveness. Embrace this opportunity for personal growth and transformation. You have the power to change your relationship with confrontation and develop the skills to navigate these situations with ease. Now, let's get started!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Fear of Confrontation Removal subliminal session! By actively working on overcoming your fear of confrontation, you've taken a powerful step towards personal growth and developing assertiveness. Now it's time to reflect on your progress and continue to build on your newfound confidence."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice self-reflection: Take time to understand your triggers and explore the roots of your fear of confrontation.",
        "Utilize role-playing: Engage in imagined conversations or practice assertive communication techniques with a trusted friend or mentor.",
        "Set realistic goals: Start by asserting yourself in smaller, less intimidating situations and gradually work towards more challenging ones.",
        "Embrace discomfort: Recognize that growth often comes from stepping outside your comfort zone and facing your fears."
      ]
    },
    {
      type: 'paragraph',
      text: "Conquering the fear of confrontation is a journey, and it may not happen overnight. Be patient with yourself and celebrate every small victory along the way. Remember, you have the ability to assert yourself confidently and create healthier, more respectful relationships. Keep practicing, and watch your self-confidence soar!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Fear of Confrontation Removal subliminal help me?`, 
    answer: `The Fear of Confrontation Removal subliminal is designed to help you overcome your fear of confrontation and develop the confidence to assert yourself in difficult conversations and situations. By listening to the subliminal regularly, the positive affirmations and messages will work on a subconscious level to change your mindset and reduce anxiety related to confrontations. Over time, you may notice an increased ability to communicate assertively and handle challenging interactions with more ease.`,
    open: false
  },
  { 
    question: `How long does it usually take to see results from the Fear of Confrontation Removal subliminal?`, 
    answer: `Results may vary from person to person. Some individuals may experience positive changes in their mindset and behavior after a few listens, while others may require more time and consistent listening to fully overcome their fear of confrontation. We recommend listening to the subliminal daily and for an extended period to achieve the best results.`,
    open: false 
  },
  { 
    question: `Can the Fear of Confrontation Removal subliminal make me too aggressive or confrontational?`, 
    answer: `No, the Fear of Confrontation Removal subliminal is aimed at helping you develop assertiveness and confidence in expressing your feelings and opinions clearly and respectfully. It is not designed to make you excessively aggressive or confrontational. The subliminal promotes balance and encourages healthy communication by empowering you to communicate your needs and boundaries effectively.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Fear of Confrontation Removal subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your mental health or specific fears related to confrontation, it is advisable to consult with a mental health professional. The subliminal is meant to assist in your personal growth, but it is not a substitute for professional therapy or guidance.`,
    open: false 
  },
  { 
    question: `Can the Fear of Confrontation Removal subliminal be used in combination with therapy or counseling?`, 
    answer: `Yes, the Fear of Confrontation Removal subliminal can be used in conjunction with therapy or counseling. It can complement the work you are doing with a mental health professional by reinforcing positive changes and empowering you to apply the tools and techniques you learn in therapy. If you are currently receiving therapy, it is recommended to discuss using subliminals with your therapist to ensure it aligns with your treatment plan.`,
    open: false 
  },
  { 
    question: `Can the Fear of Confrontation Removal subliminal help with social anxiety?`, 
    answer: `While the Fear of Confrontation Removal subliminal specifically targets the fear of confrontation, it may indirectly help with social anxiety as well. Building confidence in handling difficult conversations and asserting yourself can contribute to a reduction in overall social anxiety symptoms. However, if you specifically struggle with social anxiety, it is recommended to seek professional help for a comprehensive treatment plan.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>