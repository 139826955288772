<template>
  <div>
    <Tabs :tabs="tabs" @getTabItem="getTabItem" />
    <User v-if="tabItem == 0" />
    <Admin v-if="tabItem == 1" />
  </div>
</template>

<script>
import Tabs from '@/components/Tabs.vue'
import User from '@/views/Admin/User/index.vue'
import Admin from '@/views/Admin/Admin/index.vue'

export default {
  components: {
    Tabs,
    User,
    Admin
  },
  data: () => {
    return {
      tabs: ['User', 'Admin'],
      tabItem: 0
    }
  },
  methods: {
    getTabItem(data) {
      this.tabItem = data
    }
  }
}
</script>

<style>
.el-tabs__item {
  padding-inline: 10px !important;
}
</style>
