import request from "@/utils/request";

export const getTopTrackHistories = (data) => {
  return request.get("api/track/history/hits/5").then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const addAudioHistory = (data) => {
  return request.post("api/audio/history/add", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};
