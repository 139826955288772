<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "The law of attraction is a powerful principle that states that like attracts like, and by harnessing this universal law, you can manifest success in all areas of your life. This subliminal is designed to align your subconscious mind with the law of attraction, making it easier for you to attract and manifest your desired outcomes."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help you:'
    },
    {
      type: 'list',
      items: [
        "Increasing your belief in the power of the law of attraction to manifest success.",
        "Aligning your thoughts, emotions, and actions with your desires and goals.",
        "Boosting your confidence and self-belief, making it easier to take inspired action.",
        "Removing any subconscious blocks or limiting beliefs that may be holding you back.",
        "Enhancing your ability to visualize and focus on your desired outcomes.",
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind to work in harmony with the law of attraction. As you align your thoughts, emotions, and actions with your goals, you will start to see synchronicities and opportunities that guide you towards success. Remember, success is within your reach, and with the power of the law of attraction, you can manifest it into your reality."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Unlock the potential of the law of attraction and manifest success in your life with this powerful subliminal. Before you begin, here are some tips to help you make the most of this experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Define your goals: Clarify what success means to you in different areas of your life, whether it's career, relationships, health, or personal growth.",
        "Keep a positive mindset: Approach this subliminal with an open mind and an optimistic attitude.",
        "Visualize your desired outcomes: Visualize yourself already achieving your goals and experiencing the success you desire.",
        "Stay present and focused: Maintain your intention and stay present during the subliminal session, allowing the affirmations to penetrate your subconscious mind."
      ]
    },
    {
      type: 'paragraph',
      text: "As you embark on this journey of harnessing the law of attraction, remember that consistency and belief in the process are key. Through regular listening and aligning your thoughts with your desired outcomes, you can attract success, abundance, and positivity. Let's get started and welcome a life filled with limitless possibilities!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Law of Attraction Success subliminal session! By focusing on harnessing the power of the law of attraction, you've taken a significant step towards manifesting success in every aspect of your life. Take a moment to reflect on your journey and the possibilities that lie ahead."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your goals: Take the time to define what success means to you in different areas of your life.",
        "Practice gratitude: Cultivate an attitude of gratitude by acknowledging and appreciating the blessings in your life.",
        "Visualize your success: Use the power of your imagination to vividly picture yourself achieving your goals.",
        "Take inspired action: Translate your dreams into actions by identifying practical steps you can take towards your goals."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, the law of attraction is a powerful tool, but it requires your dedication and belief. Keep your focus on your goals, maintain positive thoughts and feelings, and take inspired actions towards your dreams. Trust in the process, and watch as the universe aligns to manifest your desires. You have the power to create the life you truly desire!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Law of Attraction Success subliminal work?`, 
    answer: `The Law of Attraction Success subliminal works by aligning your subconscious mind with positive affirmations and beliefs related to success and abundance. By consistently listening to the subliminal, you can reprogram your thoughts and attract more opportunities for success into your life. It helps you cultivate a mindset that is focused on positivity, gratitude, and taking inspired action towards your goals.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Law of Attraction Success subliminal?`, 
    answer: `Results may vary from person to person, as everyone's individual circumstances and beliefs play a role. Some people may start noticing positive changes in their thinking and manifesting abilities within a few weeks, while others may take longer. Consistent listening and an open mindset can enhance the effectiveness of the subliminal.`,
    open: false 
  },
  { 
    question: `Can the Law of Attraction Success subliminal help with specific goals or areas of life?`, 
    answer: `Yes, the Law of Attraction Success subliminal can be applied to specific goals or areas of life. By focusing on the specific aspects you wish to manifest success in, you can tailor your affirmations and intentions accordingly. Whether it's career, relationships, health, or any other area, the subliminal can help you align your mindset and actions towards success in those areas.`,
    open: false 
  },
  { 
    question: `What can I do to maximize the effectiveness of the Law of Attraction Success subliminal?`, 
    answer: `To maximize the effectiveness of the Law of Attraction Success subliminal, it is essential to create a supportive environment and practice gratitude daily. Visualizing and feeling the emotions associated with your desired outcomes can also amplify the manifestation process. Additionally, taking inspired action, setting clear goals, and staying focused on your intentions can help accelerate the manifestation of success in your life.`,
    open: false 
  },
  { 
    question: `Is there any scientific evidence to support the effectiveness of subliminal affirmations?`, 
    answer: `While there is limited scientific research on subliminal affirmations, anecdotal evidence suggests that subliminal messaging can be a powerful tool for personal development and mindset transformation. Many individuals have reported positive results and shifts in their thinking after consistent listening to subliminal audio. It is important to approach subliminal affirmations with an open and receptive mindset and understand that individual experiences may vary.`,
    open: false 
  },
  { 
    question: `Can the Law of Attraction Success subliminal guarantee success in life?`, 
    answer: `The Law of Attraction Success subliminal is a tool designed to help you align your thoughts and beliefs with success. While it can significantly impact your mindset and bring forth opportunities, it is important to remember that success is a result of various factors, including effort, perseverance, and external circumstances. The subliminal can enhance your manifestation abilities, but it does not guarantee automatic success without taking appropriate action.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>