<template>
  <Dialog :dialog="dialog" :width="800" :title="`Add Category`" @closeDialog="closeDialog">
    <div>
      <div class="d-flex justify-center ma-5">
        <Upload v-if="dialog" :upload="false" :files="files" @getImageName="getImageName" />
      </div>

      <p class="mb-1">Category Name</p>
      <v-text-field solo dense hide-details class="mb-3" placeholder="Category" v-model="name"></v-text-field>

      <p class="mb-1">Description</p>
      <v-textarea solo dense hide-details class="mb-3" placeholder="Description" v-model="description"></v-textarea>
    </div>

    <template v-slot:action>
      <v-row class="ma-0">
        <v-col class="pl-0 pr-2">
          <v-btn block @click="submitDialog()" :disabled="disabled">Add</v-btn>
        </v-col>
        <v-col class="pl-2 pr-0">
          <v-btn block @click="closeDialog()">Cancel</v-btn>
        </v-col>
      </v-row>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialog.vue'
import Upload from '@/components/Upload.vue'

import { getMessage } from '@/utils'
import { deleteLocalImage } from '@/api/file.js'
import { addCategories } from '@/api/category.js'

export default {
  props: {
    dialog: {
      type: Boolean
    }
  },
  data: () => {
    return {
      getMessage,
      addCategories,
      deleteLocalImage,

      files: [],

      image: null,
      name: null,
      description: null
    }
  },
  components: {
    Upload,
    Dialog
  },
  computed: {
    disabled() {
      return (this.image == '' ||
        this.name == null ||
        this.name == '' ||
        this.image == null)
    }
  },
  methods: {
    getImageName(value) {
      this.image = value
    },
    resetDialog() {
      this.image = null
      this.name = null
      this.description = null
    },
    closeDialog() {
      this.$emit('closeDialog', false)
      this.deleteLocalImage(this.image)
      this.resetDialog()
    },
    submitDialog() {
      const data = {
        file: this.image,
        name: this.name,
        description: this.description
      }

      this.addCategories(data).then((res) => {
        const categories = res.data

        this.getMessage(this, res)
        this.closeDialog()

        this.$store.dispatch("category/getData", categories)
      })
    }
  }
}
</script>
