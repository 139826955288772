<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're looking to boost your metabolism and enhance your body's ability to burn calories, this subliminal is here to help. By targeting your subconscious mind, it aims to create positive changes in your metabolism and support your weight management goals."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can help you:"
    },
    {
      type: 'list',
      items: [
        "Increasing your metabolic rate, allowing you to burn calories more efficiently.",
        "Enhancing your body's fat-burning capabilities by promoting thermogenesis.",
        "Supporting a healthy weight management by reducing cravings and promoting a feeling of fullness.",
        "Boosting your energy levels and promoting an active lifestyle.",
        "Encouraging a positive mindset and self-image in relation to your body and weight."
      ]
    },
    {
      type: 'paragraph',
      text: "It's important to note that this subliminal is not a magic solution for weight loss. It is designed to enhance your body's natural processes and support your efforts towards a healthy weight. With consistent use and combined with a balanced diet and regular exercise, it can help you achieve and maintain your desired weight. Remember, everyone's metabolism is unique, so individual results may vary."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Improving your metabolism can have a significant impact on your weight and overall health. This subliminal is designed to help enhance your metabolism and support your weight management goals. Before you begin listening, consider these tips to prepare:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your weight management goals: Determine what you want to achieve and visualize yourself reaching your desired weight.",
        "Focus on positive affirmations: Replace negative self-talk with positive statements about your body and metabolism.",
        "Educate yourself: Learn about the factors that influence metabolism and how you can optimize them through diet and exercise.",
        "Create a supportive environment: Surround yourself with people who support your goals and remove any potential distractions from your environment."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your daily routine, you can reprogram your mind to support a healthy metabolism and weight management. Remember to stay committed to your goals and embrace the power of positive affirmations. Now, let's start this journey towards an enhanced metabolism and a healthier, more vibrant you!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Metabolism Enhancement subliminal! By focusing on boosting your metabolism, you've taken an important step towards achieving and maintaining a healthy weight. Reflecting on the changes you can make to support your metabolism is crucial for your continued progress and success."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Nourish your body: Focus on consuming nutrient-dense foods that support metabolism, such as lean proteins, whole grains, and fruits and vegetables.",
        "Stay active: Incorporate regular physical activity into your daily routine to further enhance your metabolism.",
        "Drink plenty of water: Staying hydrated is essential for optimal metabolism function.",
        "Get adequate rest: Prioritize quality sleep to support your body's metabolic processes."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, boosting your metabolism is a journey that involves sustainable lifestyle changes. By nourishing your body, staying active, hydrating, and prioritizing rest, you are setting yourself up for success. Believe in yourself and your ability to create positive, lasting changes. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Metabolism Enhancement subliminal work?`, 
    answer: `The Metabolism Enhancement subliminal uses positive affirmations and subliminal messages to influence your subconscious mind, encouraging your body to increase its metabolic rate. These messages are designed to help your body efficiently utilize calories and maintain a healthy weight. By consistently listening to the subliminal, you can reprogram your mindset to support a more effective metabolism.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Metabolism Enhancement subliminal?`, 
    answer: `Results from the Metabolism Enhancement subliminal can vary based on individual factors and circumstances. Some people may notice improvements in their metabolism and weight management relatively quickly, while others may require more time and consistent listening to experience significant changes. We recommend listening to the subliminal regularly and combining it with a balanced diet and exercise routine for optimal results.`,
    open: false 
  },
  { 
    question: `Can the Metabolism Enhancement subliminal replace a healthy diet and exercise?`, 
    answer: `No, the subliminal is designed to complement a healthy diet and exercise routine, not replace them. While it can support your weight management goals by enhancing your metabolism, it is important to engage in regular physical activity and consume a balanced diet to maintain overall health and well-being.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with the Metabolism Enhancement subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Metabolism Enhancement subliminal. However, if you have any concerns about your metabolism or health, it is always recommended to consult with a healthcare professional.`,
    open: false 
  },
  { 
    question: `How can I make the Metabolism Enhancement subliminal more effective?`, 
    answer: `To enhance the effectiveness of the Metabolism Enhancement subliminal, it is recommended to complement it with a balanced and nutritious diet. Regular exercise, including both cardiovascular activities and strength training, can also help boost your metabolism and support weight management. Additionally, adopting healthy lifestyle habits, such as getting enough sleep, managing stress, and staying hydrated, can contribute to overall metabolic health.`,
    open: false 
  },
  { 
    question: `Is the Metabolism Enhancement subliminal suitable for everyone?`, 
    answer: `The Metabolism Enhancement subliminal is generally suitable for most individuals. However, if you have any underlying medical conditions or concerns about your metabolism, it is advisable to consult with a healthcare professional before using the subliminal.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>