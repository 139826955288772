import { render, staticRenderFns } from "./156-get-over-and-move-on-from-divorce.vue?vue&type=template&id=3197bbd1&scoped=true&"
import script from "./156-get-over-and-move-on-from-divorce.vue?vue&type=script&lang=js&"
export * from "./156-get-over-and-move-on-from-divorce.vue?vue&type=script&lang=js&"
import style0 from "./156-get-over-and-move-on-from-divorce.vue?vue&type=style&index=0&id=3197bbd1&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3197bbd1",
  null
  
)

export default component.exports