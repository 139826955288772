<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you struggle with fear or anxiety surrounding intimacy, know that you're not alone. Many people face challenges in developing healthy, fulfilling relationships due to past experiences or underlying fears. This subliminal is designed to help you overcome these obstacles and cultivate deeper connections."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support you:'
    },
    {
      type: 'list',
      items: [
        "Addressing the root causes of your fear of intimacy and helping you understand and heal from past experiences.",
        "Reducing anxiety and building confidence in yourself and your ability to connect with others.",
        "Reframing negative beliefs or thought patterns that hinder your ability to form healthy relationships.",
        "Encouraging vulnerability and opening up to emotional intimacy.",
        "Supporting you in setting boundaries and creating a balance between self-care and connection with others."
      ]
    },
    {
      type: 'paragraph',
      text: "With regular use, this subliminal can help you let go of fear and anxiety, and embrace intimacy on your own terms. As you listen, you may find yourself more open to genuine connections, experiencing deeper emotional bonds, and enjoying the benefits of healthy, fulfilling relationships. Remember, healing and growth are a journey, but with persistence, positive change is possible."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Dealing with fear and anxiety surrounding intimacy can be challenging, but this subliminal is designed to support you in overcoming these obstacles and building healthy, fulfilling relationships. Here are some tips to consider before you start listening:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your fear: Recognize and accept the existence of your fear of intimacy, as this is the first step towards overcoming it.",
        "Self-reflection: Take some time to reflect on the reasons behind your fear and anxiety. Understand the root causes and patterns that may be influencing your behavior.",
        "Set realistic expectations: Remember that personal growth and change take time. Be patient with yourself as you work towards developing healthier relationship dynamics.",
        "Affirm your intention: Remind yourself of your desire to overcome your fear of intimacy and cultivate meaningful connections as you listen to the subliminal."
      ]
    },
    {
      type: 'paragraph',
      text: "As you embark on this journey, trust in your ability to transform and grow. This subliminal serves as a powerful tool to reprogram your subconscious mind, allowing you to release fear and embrace vulnerability in your relationships. So take a moment to center yourself, embrace your intentions, and let the transformation begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Fear of Intimacy Removal subliminal session! By addressing your fears and anxieties surrounding intimacy, you have taken an important step towards fostering healthy and fulfilling relationships. Now it's time to reflect on your experience and continue your personal growth and transformation."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Self-reflection: Take time to journal and reflect on your fears and anxieties regarding intimacy.",
        "Seek support: Reach out to a trusted friend or therapist to explore your emotions and experiences.",
        "Practice vulnerability: Gradually start to open up and share your thoughts and feelings with others.",
        "Take small steps: Slowly challenge your fears by engaging in intimate activities with trusted individuals."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming the fear of intimacy is a process that requires patience and self-compassion. Celebrate every small milestone and be gentle with yourself as you navigate through this journey. Embrace vulnerability, build deep connections, and nurture the healthy, fulfilling relationships you deserve!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What is the Fear of Intimacy Removal subliminal?`, 
    answer: `The Fear of Intimacy Removal subliminal is an audio program designed to help individuals overcome their fear and anxiety surrounding intimacy and develop healthy, fulfilling relationships. It uses positive subliminal messages to reprogram the subconscious mind and create new, empowering beliefs and attitudes towards intimacy.`,
    open: false
  },
  { 
    question: `How does the Fear of Intimacy Removal subliminal work?`, 
    answer: `The Fear of Intimacy Removal subliminal works by delivering positive affirmations directly to the subconscious mind. These affirmations help to replace negative beliefs and fears surrounding intimacy with positive and empowering beliefs that support healthy, fulfilling relationships. The subliminal messages bypass the conscious mind's resistance, allowing the affirmations to take root and create lasting changes in thoughts, behaviors, and emotions.`,
    open: false 
  },
  { 
    question: `How long does it take to see results from the Fear of Intimacy Removal subliminal?`, 
    answer: `Results can vary from person to person, and the time it takes to see results may depend on factors such as the individual's readiness for change and the depth of their fear of intimacy. Some may notice improvements in their attitudes and behaviors towards intimacy relatively quickly, while others may need more time and consistent listening to fully overcome their fears. We recommend regular and consistent use of the subliminal for best results.`,
    open: false 
  },
  { 
    question: `Can the Fear of Intimacy Removal subliminal help with trauma-related fear of intimacy?`, 
    answer: `While the Fear of Intimacy Removal subliminal can be a supportive tool in overcoming fears and anxieties surrounding intimacy, it is important to note that it is not a substitute for professional therapy or counseling, especially in cases of trauma-related fear of intimacy. If you have experienced trauma, we recommend seeking the guidance of a qualified mental health professional.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Fear of Intimacy Removal subliminal?`, 
    answer: `There are no known risks or side effects associated with listening to the Fear of Intimacy Removal subliminal. However, as with any self-improvement program, it is important to listen to your body and emotions and make sure the subliminal feels supportive and beneficial for you personally. If you have concerns or experience any discomfort, we recommend discontinuing use and consulting with a mental health professional.`,
    open: false 
  },
  { 
    question: `Can the Fear of Intimacy Removal subliminal guarantee success in overcoming fear of intimacy?`, 
    answer: `While the Fear of Intimacy Removal subliminal can be a powerful tool in overcoming fear of intimacy, individual results may vary. Success in overcoming fear of intimacy involves a combination of internal work, self-reflection, and possibly professional guidance. The subliminal can provide support and create positive changes in your mindset, but it is ultimately up to you to apply the principles and insights gained from the program to your daily life.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>