import request from "@/utils/request";

export const getFeaturedContents = (data) => {
  return request.get("/api/featured/contents").then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const getFeaturedContentsFilters = (data) => {
  return request.post("/api/featured/contents/filter", data).then((res) => {
    const { data } = res.data;
    return data;
  });
};

export const addFeaturedContents = (data) => {
  return request.post("/api/featured/contents", data).then((res) => {
    return res.data;
  });
};

export const updateFeaturedContents = (data) => {
  return request.put(`/api/featured/contents/${data.id}`, data).then((res) => {
    return res.data;
  });
};

export const updateToggle = (data) => {
  return request.post("/api/featured/contents/toggle", data).then((res) => {
    return res.data;
  });
};

export const deleteFeaturedContents = (data) => {
  return request.delete(`/api/featured/contents/${data.id}`).then((res) => {
    return res.data;
  });
};
