<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you or your partner struggles with snoring, you know how disruptive it can be to your sleep quality and overall well-being. This subliminal is designed to help you put an end to snoring and improve your sleep for a better, more restful night's sleep."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help:'
    },
    {
      type: 'list',
      items: [
        "Targeting the underlying causes of snoring to reduce or eliminate it completely.",
        "Strengthening the muscles in your airways to promote better airflow and reduce snoring.",
        "Helping you adopt a healthier sleep position that minimizes snoring.",
        "Reducing congestion and inflammation in the airways for clearer breathing.",
        "Promoting relaxation and stress reduction, which can contribute to better sleep quality."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your nighttime routine, you can reprogram your subconscious mind to address the root causes of snoring. Over time, you may notice a significant reduction in snoring episodes, allowing you and your partner to enjoy more peaceful and restorative sleep. Remember, consistency is key, so make sure to listen to this subliminal regularly for optimal results."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Snoring can disrupt your sleep and the sleep of those around you, but with this subliminal, you can take steps to stop snoring and improve your sleep quality. Before you start listening, here are some tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Understand the causes: Learn about the common causes of snoring, such as nasal congestion, sleep position, or excess weight, to better address them.",
        "Seek medical advice if necessary: If your snoring is persistent or severe, consult a healthcare professional for a proper diagnosis and potential treatment options.",
        "Establish a sleep routine: Develop a consistent sleep schedule and create a relaxing pre-sleep routine to promote better sleep hygiene.",
        "Focus on your intention: While listening to the subliminal, concentrate on your desire to stop snoring and improve your sleep quality."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal is designed to help reprogram your subconscious mind and address underlying factors contributing to snoring. Through regular listening and attention to your sleep habits, you can work towards reducing snoring and achieving a more peaceful night's sleep. Embrace this opportunity to prioritize your well-being and put an end to snoring once and for all!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Stop Snoring subliminal session! By addressing your snoring, you have taken an important step towards improving your sleep quality, not just for yourself but also for your partner. Reflect on the positive changes you can make in your life as you move forward."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Explore the causes: Research potential reasons for your snoring, such as sleep position, allergies, or weight.",
        "Consider lifestyle changes: Incorporate exercise and maintain a healthy weight to reduce snoring.",
        "Try snoring aids: Experiment with nasal strips, throat sprays, or specialized pillows to alleviate snoring.",
        "Create a sleep-friendly environment: Ensure your bedroom is dark, quiet, and cool for optimal sleep."
      ]
    },
    {
      type: 'paragraph',
      text: "Addressing snoring is an ongoing process, but with determination and the right steps, you can make a positive impact on your sleep quality and overall well-being. Embrace the changes and improvements that come along the way, and enjoy a more peaceful and restorative sleep. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Stop Snoring subliminal help me?`, 
    answer: `The Stop Snoring subliminal is designed to help you reduce or eliminate snoring by positively influencing your subconscious mind. By listening to the subliminal messages regularly, you can train your mind to adopt healthier breathing patterns and promote improved airflow during sleep. The subliminal can also help you develop a more relaxed and restful sleep, benefiting both you and your partner.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Stop Snoring subliminal?`, 
    answer: `Results may vary from person to person. Some individuals may experience improvements in snoring right away, while others may require more time and consistency in listening to the subliminal messages. It is recommended to listen to the subliminal daily and consistently for at least a few weeks to allow the positive changes to take effect.`,
    open: false 
  },
  { 
    question: `Is the Stop Snoring subliminal suitable for everyone?`, 
    answer: `The Stop Snoring subliminal is designed for individuals who snore and wish to reduce or eliminate their snoring habit. However, it is always important to consult with a healthcare professional if you have underlying health conditions or concerns about your snoring. They can provide personalized advice and determine the best course of action to address your specific needs.`,
    open: false 
  },
  { 
    question: `Can listening to the Stop Snoring subliminal replace medical treatment for snoring?`, 
    answer: `No, the Stop Snoring subliminal is not intended to replace medical treatment for snoring or other related sleep disorders. It is designed to complement existing treatments and lifestyle changes by positively influencing your mindset towards healthier sleep patterns. If you have concerns about your snoring, consult with a healthcare professional for proper evaluation and guidance.`,
    open: false 
  },
  { 
    question: `Are there any side effects of listening to the Stop Snoring subliminal?`, 
    answer: `No known side effects are associated with listening to the Stop Snoring subliminal. However, if you experience any discomfort or worsening of symptoms while listening, discontinue use and consult with a healthcare professional. It is important to ensure that your snoring is not a symptom of a more serious underlying condition.`,
    open: false 
  },
  { 
    question: `Can the Stop Snoring subliminal benefit both me and my partner?`, 
    answer: `Yes, the Stop Snoring subliminal can benefit both you and your partner. By reducing or eliminating snoring, you can improve your own sleep quality and promote a more peaceful and restful sleep environment for your partner. This can lead to better overall well-being and a healthier relationship.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>