<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Facial symmetry and balance play a significant role in our perception of attractiveness. The golden ratio method of facial alignment aims to enhance the natural beauty and harmony of your features. This subliminal uses the power of your subconscious mind to help achieve this."
    },
    {
      type: 'heading',
      text: 'Benefits of using this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Enhancing facial symmetry and proportions, creating a more balanced appearance.",
        "Improving the alignment of facial features including the eyes, nose, and lips.",
        "Boosting self-confidence and self-esteem by achieving a more attractive and harmonious look.",
        "Creating a youthful and vibrant appearance through facial balance and proportion.",
        "Helping to overcome insecurities related to facial asymmetry or disproportions."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can influence your subconscious mind to work towards aligning your facial features according to the golden ratio. As you continue to use it, you may notice subtle but impactful changes in your facial symmetry and appearance. Remember, patience and consistent use are key to achieving your desired results."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a journey towards achieving facial harmony and balance is an empowering choice, and this subliminal is designed to support you on that path. Before you start listening, consider these suggestions to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Understand the golden ratio: Familiarize yourself with the concept of the golden ratio and how it relates to facial alignment.",
        "Reflect on your goals: Consider which aspects of your facial features you would like to enhance or bring into balance.",
        "Visualize your desired outcome: Picture yourself with your ideal facial alignment, feeling confident and satisfied with your appearance.",
        "Maintain a positive mindset: Approach this journey with an optimistic attitude, knowing that change is possible and that you have the power to transform your self-perception."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, it can assist you in reprogramming your subconscious mind and aligning your thoughts and beliefs with your desired facial features. Embrace this opportunity to embark on a transformative journey towards a more attractive and balanced appearance. Visualize your goals, stay positive, and get ready to unlock your true potential."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Golden Ratio Facial Alignment subliminal! By focusing on achieving facial harmony and balance through the golden ratio method, you've taken a step towards enhancing your appearance and boosting your confidence. Now, it's time to reflect on your experience and take further steps towards achieving your desired results."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Study the golden ratio: Continue learning about the golden ratio and how it applies to facial features.",
        "Evaluate your facial features: Take time to examine your own face and identify areas that you would like to improve or enhance.",
        "Identify tailored actions: Research and consult with professionals to determine the best course of action to achieve your desired facial alignment.",
        "Practice self-acceptance: Remember that beauty comes in all forms, and true beauty shines from within."
      ]
    },
    {
      type: 'paragraph',
      text: "Achieving facial harmony and balance is a personal journey, and it's important to approach it with patience and self-compassion. Embrace the changes you make and remember that your unique features contribute to your individuality and beauty. Keep focusing on your goals, and you'll continue to progress towards a more attractive and balanced appearance. You've got this!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What is the Golden Ratio Facial Alignment?`, 
    answer: `The Golden Ratio Facial Alignment is a method used to determine and enhance facial harmony and balance based on the principles of the golden ratio. The golden ratio is a mathematical ratio found in nature that is believed to represent aesthetic perfection. This method helps to identify and align specific facial features to achieve a more attractive and balanced appearance.`,
    open: false
  },
  { 
    question: `How does the Golden Ratio Facial Alignment work?`, 
    answer: `The Golden Ratio Facial Alignment uses proportions and measurements derived from the golden ratio to analyze your specific facial features. By identifying areas that may be deviating from the golden ratio, the method provides personalized recommendations and techniques to help align and balance these features for a more aesthetically pleasing appearance.`,
    open: false 
  },
  { 
    question: `Can the Golden Ratio Facial Alignment be used on any face shape?`, 
    answer: `Yes, the Golden Ratio Facial Alignment can be used on any face shape. The method takes into account the unique proportions of your facial features to create a customized plan that enhances balance and harmony. Whether you have an oval, round, square, heart, or any other face shape, the Golden Ratio Facial Alignment can help you achieve a more attractive and balanced appearance.`,
    open: false 
  },
  { 
    question: `Is the Golden Ratio Facial Alignment permanent?`, 
    answer: `The effects of the Golden Ratio Facial Alignment can be long-lasting, as the method aims to align your facial features in a way that enhances your natural beauty. However, it is important to note that individual results may vary, and maintenance practices may be recommended to sustain the desired appearance over time.`,
    open: false 
  },
  { 
    question: `Is the Golden Ratio Facial Alignment a substitute for cosmetic procedures?`, 
    answer: `The Golden Ratio Facial Alignment is not a substitute for cosmetic procedures. It is a non-invasive method that focuses on analyzing and adjusting the natural proportions of your facial features. While it can help you achieve a more attractive and balanced appearance, it does not involve surgical or invasive techniques. If you are considering cosmetic procedures, it is best to consult with a qualified professional.`,
    open: false 
  },
  { 
    question: `Does the Golden Ratio Facial Alignment require professional assistance?`, 
    answer: `While you can learn about the principles of the Golden Ratio Facial Alignment and apply them yourself, seeking professional assistance can provide more accurate measurements and personalized recommendations. A professional who specializes in facial aesthetics can help you understand how the golden ratio applies to your specific facial features and guide you in achieving the desired results.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>