<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you struggle with self-judgment and insecurities, this subliminal is here to help you heal and find self-acceptance. By targeting your subconscious mind, it can assist you in releasing negative self-talk and embracing a more positive and compassionate attitude towards yourself."
    },
    {
      type: 'heading',
      text: 'This subliminal can support you in:'
    },
    {
      type: 'list',
      items: [
        "Letting go of self-judgment and self-criticism.",
        "Increasing self-compassion and acceptance.",
        "Releasing insecurities and embracing your authentic self.",
        "Transforming negative thought patterns into positive ones.",
        "Building confidence and self-esteem.",
        "Cultivating a more loving and nurturing relationship with yourself."
      ]
    },
    {
      type: 'paragraph',
      text: "With regular use of this subliminal, you can gradually shift your mindset and let go of self-judgment. As you practice self-acceptance and compassion, you may experience a newfound sense of worthiness and inner peace. Remember, healing takes time, so be patient and gentle with yourself throughout this journey."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Healing self-judgment and embracing self-acceptance is a powerful journey, and this subliminal is here to support you on that path. Before you start listening, here are some suggestions to enhance your experience and make the most of this healing process:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a safe space: Find a quiet and comfortable environment where you can fully immerse yourself in the healing process.",
        "Set your intention: Clearly visualize your desire to heal and release self-judgment, making a commitment to yourself to embrace self-acceptance.",
        "Practice self-care: Prioritize self-care activities that make you feel nurtured and supported. This can help create a foundation of self-love and compassion.",
        "Let go of expectations: Allow yourself to be open and receptive to the transformative messages of the subliminal, without placing any pressure or judgment on yourself."
      ]
    },
    {
      type: 'paragraph',
      text: "As you embark on this journey of self-healing and self-acceptance, remember to be kind to yourself. You are deserving of love, compassion, and acceptance. With each listening session, allow the powerful affirmations to penetrate your subconscious mind, creating lasting change. Take a deep breath, embrace this moment of transformation, and let your healing journey begin." 
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Self-Judgement Healing subliminal session! By working on releasing self-judgment and accepting yourself fully, you've taken a powerful step towards inner healing and personal growth. It's important to reflect on your experience and continue nurturing self-compassion and self-acceptance."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice self-reflection: Take moments to reflect on your thoughts, feelings, and behaviors without judgment.",
        "Challenge negative self-talk: Replace self-critical thoughts with kind, compassionate affirmations.",
        "Embrace self-care: Make self-care a priority by engaging in activities that nourish your mind, body, and soul.",
        "Surround yourself with positivity: Surround yourself with supportive and uplifting people who appreciate and accept you for who you are."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, healing self-judgment is a process that takes time and patience. Be gentle with yourself and celebrate every step forward you take in embracing self-acceptance and self-love. You are enough, just as you are. Keep shining!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Self-Judgment Healing subliminal work?`, 
    answer: `The Self-Judgment Healing subliminal works by delivering positive affirmations directly to your subconscious mind. These affirmations are designed to challenge and replace negative self-judgments with self-acceptance and self-love. By consistently listening to the subliminal, you can gradually rewire your thoughts and beliefs, allowing yourself to let go of insecurities and embrace your true self.`,
    open: false
  },
  { 
    question: `How long do I need to listen to the Self-Judgment Healing subliminal to see results?`, 
    answer: `Results may vary depending on individual circumstances and the extent of self-judgment and insecurities. You may start noticing positive changes in your self-perception and self-acceptance after consistent listening over a period of several weeks. It is important to be patient and trust the process, as healing and self-transformation take time.`,
    open: false 
  },
  { 
    question: `Can the Self-Judgment Healing subliminal replace therapy or professional help?`, 
    answer: `The Self-Judgment Healing subliminal is designed to support and enhance any healing or personal growth journey you may be on. While it can be a valuable tool, it is not a substitute for therapy or professional help. If you are experiencing severe self-judgment or are in need of mental health support, it is recommended to seek guidance from a licensed therapist or healthcare professional.`,
    open: false 
  },
  { 
    question: `How can I make the Self-Judgment Healing subliminal more effective?`, 
    answer: `To enhance the effectiveness of the Self-Judgment Healing subliminal, it can be helpful to create a supportive environment, engage in self-care practices, and practice mindfulness and self-reflection. Surrounding yourself with positive influences, such as supportive friends or mentors, can also contribute to your healing process. Additionally, incorporating self-compassion and self-acceptance techniques into your daily life can reinforce the positive affirmations delivered by the subliminal.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the Self-Judgment Healing subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Self-Judgment Healing subliminal. However, if you have any concerns about your mental health or well-being, it is recommended to consult with a licensed therapist or healthcare professional.`,
    open: false 
  },
  { 
    question: `Can the Self-Judgment Healing subliminal help with specific insecurities?`, 
    answer: `The Self-Judgment Healing subliminal aims to address and release self-judgment and overall insecurities. While the specific insecurities may vary from person to person, the affirmations are designed to promote self-acceptance and self-love in various areas of life. As you listen to the subliminal, pay attention to any shifts in your perception and mindset, as well as any opportunities for growth and healing.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>