<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're struggling with fear of schools or school phobia, know that you're not alone. This subliminal can help you overcome your fear, develop a positive perspective on education, and create a comfortable learning environment for yourself."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can assist you:'
    },
    {
      type: 'list',
      items: [
        "Reducing anxiety and fear associated with school and academic settings.",
        "Building confidence and self-esteem in your ability to handle school-related challenges.",
        "Promoting a sense of safety, belonging, and comfort in the school environment.",
        "Encouraging a positive and open mindset towards learning and education.",
        "Helping you develop effective coping mechanisms and relaxation techniques when facing school-related stressors."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind to let go of fear and create a positive association with schools and education. With time, you may find yourself feeling more at ease and even excited about learning. Remember, everyone's journey is unique, so be patient and kind to yourself as you work through your fears."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Facing and overcoming the fear of schools and school phobia can be challenging, but with the help of this subliminal, you can develop a positive perspective on education and create a comfortable learning environment for yourself. Before starting your listening session, take a moment to consider these preparation tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your fear: Recognize and accept your fear of schools as a valid emotion.",
        "Set realistic goals: Establish achievable goals for yourself regarding your perspective on education and your experience with schools.",
        "Focus on positive affirmations: During your listening session, keep positive affirmations in mind to shift your mindset and create a more positive association with schools.",
        "Visualize a comfortable learning environment: Imagine yourself in a safe and supportive learning environment, where you feel calm, confident, and ready to learn."
      ]
    },
    {
      type: 'paragraph',
      text: "By actively working on shifting your mindset and utilizing the power of your subconscious mind, this subliminal can help you overcome your fear of schools and school phobia. Embrace this opportunity to create a positive relationship with education and embrace the joy of learning. Get ready to face your fears and embark on a transformative journey towards a comfortable and fulfilling learning experience."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Fear of Schools and School Phobia Removal subliminal session! By addressing your fear and phobia, you've taken a brave step towards creating a more positive perspective on education and learning. As you reflect on your journey, remember to be kind to yourself and acknowledge the progress you've made."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Identify triggers: Reflect on specific situations or thoughts that trigger your fear of schools or school phobia.",
        "Gradual exposure: Gradually expose yourself to school-related environments or activities, starting with small steps.",
        "Seek support: Talk to a trusted friend, family member, or counselor about your fears and seek their support.",
        "Practice relaxation techniques: Incorporate relaxation exercises, such as deep breathing or meditation, to help manage anxiety."
      ]
    },
    {
      type: 'paragraph',
      text: "Overcoming fear and phobia is a process that takes time and persistence. Remember to celebrate your small victories along the way and be patient with yourself. With determination and support, you can create a comfortable learning environment for yourself and cultivate a positive perspective on education. Keep shining!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Fear of Schools and School Phobia Removal subliminal help me overcome my fear?`, 
    answer: `The Fear of Schools and School Phobia Removal subliminal is designed to reprogram your subconscious mind, helping you develop a positive perspective on education and create a comfortable learning environment for yourself. By regularly listening to the subliminal, you may notice a decrease in anxiety related to schools and an increase in confidence and motivation to engage in educational activities.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Fear of Schools and School Phobia Removal subliminal?`, 
    answer: `Results may vary depending on the individual and their unique circumstances. Some people may experience positive changes quickly, while others may require more time and consistent listening to fully overcome their fear of schools and school phobia. We recommend listening to the subliminal daily and regularly to reinforce positive affirmations and transform your mindset towards education.`,
    open: false 
  },
  { 
    question: `Can the Fear of Schools and School Phobia Removal subliminal replace therapy or professional help?`, 
    answer: `No, the subliminal is not intended to replace therapy or professional help. It is designed to complement and enhance any existing therapeutic interventions or treatments you may be undergoing. If you are experiencing severe school phobia or related mental health difficulties, it is important to seek professional assistance from a qualified therapist or counselor.`,
    open: false 
  },
  { 
    question: `How can I make the Fear of Schools and School Phobia Removal subliminal more effective?`, 
    answer: `In addition to listening to the subliminal, you can enhance its effectiveness by practicing techniques to manage anxiety, such as deep breathing exercises and mindfulness. Gradual exposure to school-related settings and activities can also be helpful in overcoming fear. Surrounding yourself with supportive people and seeking professional help if needed can contribute to a holistic approach in addressing your fear of schools and school phobia.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Fear of Schools and School Phobia Removal subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have concerns about your fears or mental health, it is recommended to consult with a mental health professional. The subliminal is intended to supplement therapeutic interventions and personal development efforts, not replace them.`,
    open: false 
  },
  { 
    question: `Will listening to the Fear of Schools and School Phobia Removal subliminal erase my fear completely?`, 
    answer: `The Fear of Schools and School Phobia Removal subliminal is designed to assist you in overcoming your fear and developing a positive perspective on education. While it may significantly reduce or even eliminate your fear, the extent of the results can vary from person to person. Consistently listening to the subliminal and combining it with other strategies and support can increase your chances of achieving lasting positive changes.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>