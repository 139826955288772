<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Building a positive and loving relationship with your parents is fundamental for a harmonious family dynamic. This subliminal is designed to help you strengthen the bond with your parents, fostering trust, understanding, and respect."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Enhancing communication skills and promoting open dialogue with your parents.",
        "Developing empathy and understanding towards your parents' point of view.",
        "Building trust and mutual respect in your relationship.",
        "Overcoming conflicts and improving problem-solving skills within the family.",
        "Creating a positive and loving atmosphere at home.",
        "Strengthening your bond with your parents and fostering a healthy family dynamic."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to cultivate a positive relationship with your parents. With increased communication and understanding, you can create a harmonious family environment based on love, trust, and respect. Remember, building strong relationships takes time and effort, but the rewards are worth it."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Developing a positive and loving relationship with your parents is essential for a harmonious family dynamic. This subliminal is designed to help you build trust, understanding, and respect with your parents. Before you begin listening, consider these tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your intentions: Think about the kind of relationship you want with your parents and what changes you would like to see.",
        "Practice empathy: Put yourself in your parents' shoes and try to understand their perspective and motivations.",
        "Communicate openly: Have honest and respectful conversations with your parents, expressing your feelings and thoughts.",
        "Be patient and forgiving: Building a positive relationship takes time, so be patient with yourself and your parents, and forgive any past misunderstandings or conflicts."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating these tips alongside the subliminal affirmations, you can nurture a positive and loving bond with your parents. Through consistent listening and conscious efforts, you can create a harmonious and fulfilling family relationship that will benefit both you and your parents. Embrace this opportunity for growth and begin your journey towards a positive relationship with your parents."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Positive Relationship with Parents subliminal session! By actively working towards building a positive and loving relationship with your parents, you have taken a significant step towards creating a harmonious family dynamic. Reflecting on this experience and continuing your journey of growth and connection is vital as you move forward."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice active listening: Show genuine interest and empathy when communicating with your parents.",
        "Express gratitude: Take time to thank your parents for their love, support, and the things they do for you.",
        "Seek to understand: Try to see situations from their perspective and practice empathy and understanding.",
        "Set healthy boundaries: Establish clear boundaries to promote healthy communication and maintain your individuality."
      ]
    },
    {
      type: 'paragraph',
      text: "Building a positive relationship with your parents takes time and effort, but it can greatly enhance the quality of your family life. Cherish the moments you have together, nurture the bond you share, and continue to prioritize open and respectful communication. Remember, by investing in your relationship with your parents, you contribute to a loving and harmonious family dynamic."
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Positive Relationship with Parents subliminal help me build a better relationship with my parents?`, 
    answer: `The Positive Relationship with Parents subliminal is designed to positively influence your subconscious mind and help you cultivate a loving and harmonious relationship with your parents. By listening to the subliminal regularly, you can develop a mindset that is more open, understanding, and respectful towards your parents, allowing for healthier communication and deeper connections.`,
    open: false
  },
  { 
    question: `How long does it take to see improvements in my relationship with my parents?`, 
    answer: `The time it takes to see improvements in your relationship with your parents may vary depending on your individual circumstances. Consistent listening to the Positive Relationship with Parents subliminal can help reprogram your subconscious mind and foster positive changes in your thoughts, emotions, and behavior towards your parents. It is important to be patient and allow time for these changes to take root and reflect in your relationship.`,
    open: false 
  },
  { 
    question: `Can the Positive Relationship with Parents subliminal fix a strained relationship with my parents?`, 
    answer: `The Positive Relationship with Parents subliminal is designed to promote positive changes in thoughts, emotions, and behavior towards your parents. While it can help create a more loving and harmonious connection, it may not be a solution for deeply strained relationships. In such cases, it is recommended to seek additional support from a therapist or counselor for guidance on repairing and rebuilding trust in the parent-child relationship.`,
    open: false 
  },
  { 
    question: `Can the Positive Relationship with Parents subliminal help with unresolved conflicts?`, 
    answer: `The Positive Relationship with Parents subliminal can promote a mindset of understanding, empathy, and forgiveness, which can contribute to resolving conflicts in a healthy and constructive manner. However, it is important to actively engage in open and honest communication with your parents to address and work through any unresolved conflicts that may exist.`,
    open: false 
  },
  { 
    question: `Is it necessary for my parents to listen to the subliminal as well for it to be effective?`, 
    answer: `No, it is not necessary for your parents to listen to the subliminal for it to be effective. The subliminal is designed to positively influence your own mindset and behavior, which can, in turn, contribute to building a more positive relationship with your parents. However, open and honest communication with your parents is essential for nurturing a healthy and supportive bond.`,
    open: false 
  },
  { 
    question: `Can the Positive Relationship with Parents subliminal help me become a better parent myself in the future?`, 
    answer: `Yes, the Positive Relationship with Parents subliminal can help you develop a more understanding and compassionate mindset towards your parents, which can positively influence your own approach to parenting in the future. By fostering a loving and harmonious relationship with your parents, you can gain valuable insights and experiences that can inform your own parenting style.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>