<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Are you looking to take your plans and goals to the next level? This subliminal can help you push them to their limits and achieve new heights."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support you:'
    },
    {
      type: 'list',
      items: [
        "It can provide you with the motivation and drive to push through challenges and obstacles.",
        "It can help you cultivate a mindset of excellence and exceed your own expectations.",
        "It can increase your confidence and belief in your own abilities.",
        "It can help you overcome self-doubt and fear of failure.",
        "It can support you in setting ambitious goals and taking consistent action towards them."
      ]
    },
    {
      type: 'paragraph',
      text: "With regular listening, this subliminal can reprogram your subconscious mind, empowering you to push your plans and goals to the limit. By embracing the mindset of pushing beyond your limits, you can create incredible results and achieve success beyond what you ever thought possible. Remember, the power to achieve greatness is within you."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Are you ready to push your plans and goals to the limit? This subliminal is designed to help you achieve new heights and exceed your own expectations. Here are some tips to prepare yourself for this empowering journey:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Get clear on your objectives: Define your plans and goals with clarity, specificity, and ambition.",
        "Break it down: Divide your goals into smaller, actionable tasks to make them more manageable and achievable.",
        "Believe in yourself: Cultivate a strong belief in your capabilities and the power of pushing yourself.",
        "Stay focused: Maintain a clear mental focus on your goals while listening to the subliminal affirmations."
      ]
    },
    {
      type: 'paragraph',
      text: "With this subliminal, you will tap into your inner strength, determination, and unwavering commitment to push beyond your limits. Trust in yourself, embrace the challenges, and let this subliminal be your tool for achieving extraordinary success. Together, let's take your plans and goals to the next level!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the 'Push Plans and Goals to the Limit' session! By taking the initiative to push your plans and goals to their limits, you have already shown incredible dedication and determination. Now, it's time to reflect on your experience and harness that energy to achieve new heights."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Challenge yourself: Set new targets and goals that stretch your abilities and push you out of your comfort zone.",
        "Break it down: Divide your big goals into smaller, manageable tasks to make them more achievable.",
        "Find support: Surround yourself with people who inspire and motivate you to keep pushing forward.",
        "Embrace failure: View setbacks as learning opportunities and use them to grow stronger and more resilient."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, you have what it takes to exceed your own expectations. Embrace the challenges, push yourself, and keep working towards your dreams. Success comes to those who are willing to go above and beyond. Now, go out there and make incredible things happen!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Push Plans and Goals to the Limit subliminal help me?`, 
    answer: `The Push Plans and Goals to the Limit subliminal is designed to help you develop a mindset of pushing your plans and goals to their maximum potential. By listening to the subliminal messages, you can rewire your subconscious mind, increase your motivation, and tap into your full potential. This can result in a greater drive to achieve your goals, push past limitations, and exceed your own expectations.`,
    open: false
  },
  { 
    question: `How long should I listen to the Push Plans and Goals to the Limit subliminal?`, 
    answer: `The duration for seeing results can vary from person to person. It is recommended to listen to the subliminal consistently for at least a few weeks, if not longer, to allow the messages to take root in your subconscious mind. Consistency is key in achieving lasting changes in mindset and behavior.`,
    open: false 
  },
  { 
    question: `Can the Push Plans and Goals to the Limit subliminal help with procrastination?`, 
    answer: `Yes, the Push Plans and Goals to the Limit subliminal can help you overcome procrastination by instilling a sense of motivation, determination, and focus. It aims to reprogram your subconscious mind to prioritize your goals and take consistent action towards achieving them. However, it is important to combine the subliminal with practical strategies to combat procrastination, such as setting SMART goals and breaking tasks down into manageable steps.`,
    open: false 
  },
  { 
    question: `Will the Push Plans and Goals to the Limit subliminal guarantee success and achievement of all my goals?`, 
    answer: `While the Push Plans and Goals to the Limit subliminal can help you develop a mindset of pushing your plans and goals to their maximum potential, success and achievement ultimately depend on various factors such as effort, resources, and external circumstances. The subliminal is a tool to empower and motivate you, but it is important to combine it with practical strategies and actions to increase your chances of success.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the Push Plans and Goals to the Limit subliminal?`, 
    answer: `There are no known risks or side effects associated with listening to the Push Plans and Goals to the Limit subliminal. However, it is important to note that the subliminal is designed to support and enhance your mindset and motivation. It should not replace practical planning, strategic thinking, and hard work required to achieve your goals.`,
    open: false 
  },
  { 
    question: `Can the Push Plans and Goals to the Limit subliminal be used for any type of goal?`, 
    answer: `Yes, the Push Plans and Goals to the Limit subliminal can be used to enhance motivation and mindset for any type of goal. Whether it's personal, professional, fitness, or any other area of life, the subliminal aims to push your plans and goals to their maximum potential and help you achieve new heights and exceed your own expectations.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>