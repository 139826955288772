<template>
  <div>
    <img
      v-if="isFromPlayer"
      :class="`${!isDataReady ? 'disabled' : ''}`"
      src="@/assets/images/user/webplayer/light/ellipsis.png"
      :style="`height: ${height}px;`"
    />
    <el-dropdown v-else trigger="click" @command="handleCommand">
      <img
        src="@/assets/images/user/webplayer/light/ellipsis.png"
        :style="`height: ${height}px;`"
      />
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          command="guide"
          class="d-flex py-2"
          style="width: 230px"
        >
          <v-icon class="mr-4">mdi-note</v-icon>
          <span>Show Guide</span>
        </el-dropdown-item>
        <el-dropdown-item
          v-if="is_own == 1"
          command="remove"
          class="d-flex py-2"
          style="width: 230px"
        >
          <v-icon class="mr-4">mdi-minus-circle</v-icon>
          <span>Remove from Playlist</span>
        </el-dropdown-item>
        <el-dropdown-item
          v-else
          command="add"
          class="d-flex py-2"
          style="width: 230px"
        >
          <v-icon class="mr-4">mdi-playlist-plus</v-icon>
          <span>Add from Playlist</span>
        </el-dropdown-item>
      </el-dropdown-menu>
      <Dialog :dialog="dialog" :width="600" title="" @closeDialog="closeDialog">
        <div class="px-5">
          <div class="information d-flex align-items-center gap-2">
            <div class="cover" :style="`background: url(${data.cover});`"></div>
            <div class="details mx-2">
              <h5 class="mb-0 text-limit-1">{{ data.title }}</h5>
              <span class="mb-0 text-limit-1">
                {{ data.category_name }}
              </span>
            </div>
          </div>

          <h5 class="guide-text">Guide</h5>
          <div
            class="pr-4"
            style="height: 50vh; overflow: auto"
            v-html="data.guide"
          ></div>
        </div>
      </Dialog>
      <AddToOwnPlaylist
        :dialog="owndialog"
        :data="data"
        @closeDialog="closeDialog"
      />
    </el-dropdown>
  </div>
</template>

<script>
import Dialog from "@/components/Dialog.vue";
import AddToOwnPlaylist from "@/components/User/AddToOwnPlaylist.vue";
import { getMessage, convertToShortTime } from "@/utils";

import { toggleData } from "@/utils/favorite.js";
import { deleteSubliminalToOwnPlaylists } from "@/api/playlistinfo.js";
import { getAllUserPlaylist } from "@/api/playlist.js";
import { mapGetters } from "vuex";
export default {
  props: {
    height: {
      type: Number,
      default: () => {
        return 16;
      },
    },
    is_own: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    fromPlayer: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  components: {
    Dialog,
    AddToOwnPlaylist,
  },
  data: () => {
    return {
      getMessage,
      getAllUserPlaylist,
      convertToShortTime,
      dialog: false,
      owndialog: false,
      closeOnContentClick: true,

      toggleData,
      deleteSubliminalToOwnPlaylists,
    };
  },
  mounted() {},
  computed: {
    ...mapGetters({
      isDataReady: "newaudio/getDataStatus",
    }),
    isLiked() {
      return this.liked ? "dark" : "light";
    },
    liked() {
      return this.subliminals.is_liked == 1;
    },
    subliminals() {
      return Object.keys(this.data).length > 0
        ? this.data.subliminals[this.index]
        : {};
    },
    user() {
      return JSON.parse(this.$store.state.user.info);
    },
    isFromPlayer() {
      return !this.isDataReady && this.fromPlayer;
    },
  },
  methods: {
    handleCommand(command) {
      //if (this.isDataReady) {
        if (command == "guide") {
          this.openGuide();
        }

        if (command == "add") {
          this.openAddPlaylist();
        }

        if (command == "remove") {
          this.deleteSubliminalToPlaylist();
        }
      //}
    },
    openGuide() {
      this.dialog = true;
    },
    openAddPlaylist() {
      this.owndialog = true;
    },
    closeDialog(data) {
      this.dialog = data;
      this.owndialog = data;
    },
    deleteSubliminalToPlaylist() {
      const localTrack = JSON.parse(localStorage.getItem("user_track"));
      const data = {
        user_id: this.user.user_id,
        playlist_id: localTrack.playlist_id,
        subscription_id: this.user.info.subscription_id,
        subliminal_id: this.data.subliminal_id,
      };
      this.deleteSubliminalToOwnPlaylists(data).then((res) => {
        localStorage.setItem("user_track", JSON.stringify(res.data[0]));
        this.$store.dispatch("user/getTrack", res.data[0]);

        this.$store.dispatch("playlist/getOwnUpdate", res.data);
        this.$store.dispatch("playlist/getOwnLoading", false);

        this.getMessage(this, res);

        this.getAllUserPlaylist(data).then((res) => {
          this.$store.dispatch("playlist/playlistInfoLists", res);
        })
      });
    },
  },
};
</script>

<style scoped>
.list {
  width: 180px;
}

.menu-img {
  width: 20px;
  margin-right: 10px;
}

.guide-text {
  margin-top: 20px;
  font-family: "Montserrat-SemiBold";
}

.cover {
  min-width: 120px;
  height: 80px;

  border-radius: 5px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.disabled {
  opacity: 0.5;
  filter: alpha(opacity=50); /* msie */
}
</style>
