import { render, staticRenderFns } from "./119-fear-of-heights-and-acrophobia-removal.vue?vue&type=template&id=0d450734&scoped=true&"
import script from "./119-fear-of-heights-and-acrophobia-removal.vue?vue&type=script&lang=js&"
export * from "./119-fear-of-heights-and-acrophobia-removal.vue?vue&type=script&lang=js&"
import style0 from "./119-fear-of-heights-and-acrophobia-removal.vue?vue&type=style&index=0&id=0d450734&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d450734",
  null
  
)

export default component.exports