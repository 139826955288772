<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Whether you're a beginner or an advanced learner, this subliminal is designed to enhance your language learning journey. By targeting your subconscious mind, it aims to boost your vocabulary, grammar, pronunciation, and overall language fluency."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help:'
    },
    {
      type: 'list',
      items: [
        "Improving your ability to retain new vocabulary words and phrases.",
        "Enhancing your comprehension skills and ability to understand spoken and written language.",
        "Boosting your confidence in speaking the language and reducing self-doubt.",
        "Supporting the development of accurate pronunciation and intonation.",
        "Increasing your motivation and persistence in language learning."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your language learning routine, you can accelerate your progress and make the language learning process more enjoyable. Remember, practice and consistency are key to mastering any language, and this subliminal can be a valuable tool in your language learning toolkit."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a language learning journey can be both exciting and challenging. This subliminal is designed to support your language learning process by enhancing your skills and boosting your overall fluency. Before you start listening, here are some strategies to maximize your learning experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Define your learning objectives and identify what specific language skills you want to improve, such as vocabulary, grammar, pronunciation, or fluency.",
        "Create a study plan: Establish a consistent and organized study routine that allows you to allocate dedicated time for language learning.",
        "Immerse yourself in the language: Surround yourself with authentic resources, such as books, movies, music, or language exchange programs, to expose yourself to the language and culture.",
        "Stay motivated: Continuously remind yourself of the benefits and reasons why you want to learn the language.",
        "Embrace mistakes: View mistakes as learning opportunities and an essential part of the language acquisition process."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember that this subliminal is a powerful tool to supplement your language learning efforts, but consistent practice and dedication are essential for making progress. By incorporating these preparation tips into your learning routine and listening regularly to the subliminal, you can enhance your language skills and achieve fluency more efficiently. Enjoy the language learning journey and watch yourself become a more confident and proficient language learner!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the General Language Learning Aid subliminal! By investing time and effort into improving your language skills, you are actively expanding your opportunities for personal and professional growth. It's essential to reflect on your language learning journey and continue to nurture your linguistic abilities."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice regularly: Set aside dedicated time each day to engage with the language you're learning.",
        "Expand your vocabulary: Utilize vocabulary-building techniques such as flashcards or language learning apps.",
        "Immerse yourself: Surround yourself with the language by watching movies, reading books, or listening to podcasts in the target language.",
        "Find a language partner: Seek out opportunities to practice speaking with native speakers or language learners."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, language learning is a journey that requires consistent effort and dedication. Embrace the challenges and celebrate your progress along the way. By continuing to practice and expose yourself to the language, you will improve your language skills and unlock a world of new opportunities. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `What is the General Language Learning Aid subliminal?`, 
    answer: `The General Language Learning Aid subliminal is an audio program designed to help you improve your language skills and enhance your ability to learn new languages. It uses subliminal messages to positively influence your subconscious mind, boosting vocabulary, grammar, pronunciation, and overall language fluency. By listening to this subliminal regularly, you can improve your language learning abilities and make the learning process more efficient and effective.`,
    open: false
  },
  { 
    question: `How does the General Language Learning Aid subliminal work?`, 
    answer: `The General Language Learning Aid subliminal works by using positive affirmations that are embedded in the audio to target your subconscious mind. These affirmations help to reprogram your thoughts and beliefs about language learning, making it easier for you to retain new vocabulary, grasp grammar concepts, improve pronunciation, and overall enhance your language fluency. By consistently listening to the subliminal, you can train your mind to become more receptive to language learning and accelerate your progress.`,
    open: false 
  },
  { 
    question: `How often should I listen to the General Language Learning Aid subliminal?`, 
    answer: `For best results, we recommend listening to the General Language Learning Aid subliminal on a daily basis. Consistency is key when using subliminals, as it allows the positive affirmations to reinforce your language learning abilities over time. By incorporating the subliminal into your daily routine, you can create a habit that maximizes its effectiveness and accelerates your language learning journey.`,
    open: false 
  },
  { 
    question: `Can the General Language Learning Aid subliminal be used for any language?`, 
    answer: `Yes, the General Language Learning Aid subliminal can be used to aid in learning any language. The positive affirmations are designed to enhance overall language learning skills, including vocabulary, grammar, pronunciation, and fluency. Whether you are learning English, Spanish, French, or any other language, the subliminal can support your learning journey by reprogramming your subconscious mind to be more receptive to language acquisition.`,
    open: false 
  },
  { 
    question: `Can the General Language Learning Aid subliminal be used by beginners?`, 
    answer: `Yes, the General Language Learning Aid subliminal can be used by beginners in language learning. It is designed to support learners of all levels, from beginners to more advanced learners. The affirmations target core language learning skills and can help beginners build a strong foundation while instilling a positive and confident mindset towards language learning.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the General Language Learning Aid subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the General Language Learning Aid subliminal. However, if you have any concerns about your language learning journey or any specific language-related challenges, it is recommended to consult with a language instructor or professional for personalized guidance and support.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>