<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you suffer from a fear of flying or aerophobia, you're not alone. This subliminal is specifically designed to help you overcome your fear and start traveling with confidence and ease."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help:'
    },
    {
      type: 'list',
      items: [
        "Reducing anxiety and fear related to flying.",
        "Replacing negative thoughts and beliefs with positive and empowering ones.",
        "Building confidence in your ability to handle any discomfort or turbulence during flights.",
        "Developing relaxation techniques and coping mechanisms to manage fear while flying.",
        "Creating a sense of calmness and peace during air travel."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can rewire your subconscious mind to let go of fear and embrace a more positive and confident attitude towards flying. Imagine the freedom and opportunities that will open up once you conquer your fear of flying. It's time to let go of limitations and experience the joy of travel!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "If you have a fear of flying or aerophobia, this subliminal can help you overcome your fear and start traveling confidently and fearlessly. Before you begin listening, here are some tips to help you prepare:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Educate yourself: Learn about the safety features of airplanes and the statistics that show flying is one of the safest modes of transportation.",
        "Challenge your thoughts: Recognize and challenge any negative thoughts or beliefs you have about flying. Replace them with positive and rational ones.",
        "Relaxation techniques: Practice deep breathing exercises, meditation, or other relaxation techniques to help calm your mind and body before and during flights.",
        "Visualization: Visualize yourself boarding the plane calmly, feeling safe and relaxed throughout the journey.",
        "Support system: Seek support from family, friends, or professionals who can offer encouragement and understanding as you work to overcome your fear."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is specifically designed to address and alleviate your fear of flying and aerophobia. With regular listening and a positive mindset, you can gradually build confidence and enjoy flying without fear. Embrace this opportunity to conquer your fear and open yourself up to new travel experiences. Safe travels!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Fear of Flying and Aerophobia Removal subliminal session! By addressing your fear of flying, you've taken a significant step towards traveling confidently and fearlessly. It's important to take some time to reflect on your progress and continue building your confidence as you move forward."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your achievements: Take note of the progress you've made in overcoming your fear of flying.",
        "Learn relaxation techniques: Explore different relaxation exercises and breathing techniques to practice during flights.",
        "Expose yourself to flying experiences: Gradually expose yourself to flying situations to build confidence and desensitize anxiety.",
        "Seek professional guidance: Consider consulting with a therapist or counselor specializing in anxiety and phobias."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming the fear of flying is a process that requires consistent effort and practice. Be patient with yourself, celebrate your achievements, and continue taking steps towards flying confidently. With time and dedication, you will be able to travel the world fearlessly. Bon voyage!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Fear of Flying and Aerophobia Removal subliminal help me?`, 
    answer: `The Fear of Flying and Aerophobia Removal subliminal is designed to help you overcome your fear of flying and aerophobia by targeting the subconscious mind. The subliminal messages work to reprogram negative beliefs and associations related to flying, promoting a calm and relaxed state of mind during air travel. By consistently listening to the subliminal, you can gradually build confidence and resilience, allowing you to travel confidently and fearlessly.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Fear of Flying and Aerophobia Removal subliminal?`, 
    answer: `Results can vary from person to person, and the time it takes to notice improvements may also depend on the severity of your fear. Some individuals may experience positive changes after just a few listenings, while others may require more time to reprogram their subconscious beliefs. Consistent and regular listening is recommended for best results.`,
    open: false 
  },
  { 
    question: `Can the Fear of Flying and Aerophobia Removal subliminal be used along with other fear-reducing techniques?`, 
    answer: `Yes, the Fear of Flying and Aerophobia Removal subliminal can be used in conjunction with other fear-reducing techniques, such as exposure therapy or relaxation techniques. It is important to find a combination of strategies that works best for you and complements your personal journey towards overcoming your fear of flying. As always, it is recommended to consult with a professional if you have any concerns or specific needs.`,
    open: false 
  },
  { 
    question: `Is the Fear of Flying and Aerophobia Removal subliminal safe to listen to during a flight?`, 
    answer: `Yes, it is safe to listen to the Fear of Flying and Aerophobia Removal subliminal during a flight. However, it is important to follow all safety instructions provided by the airline and remain aware of your surroundings. Listening to the subliminal may help promote relaxation and reduce anxiety, but it should not replace important safety measures or distract you from the necessary attention during air travel.`,
    open: false 
  },
  { 
    question: `Can the Fear of Flying and Aerophobia Removal subliminal guarantee complete elimination of fear?`, 
    answer: `While the Fear of Flying and Aerophobia Removal subliminal is designed to help you overcome your fear of flying and aerophobia, individual results may vary. The subliminal can aid in reprogramming negative beliefs and associations, empowering you to develop a more positive and confident mindset towards air travel. However, the extent to which your fear is eliminated will also depend on your individual circumstances and commitment to personal growth.`,
    open: false 
  },
  { 
    question: `Should I stop using the Fear of Flying and Aerophobia Removal subliminal once my fear is gone?`, 
    answer: `Once you have overcome your fear of flying and aerophobia, you may choose to continue using the Fear of Flying and Aerophobia Removal subliminal as a reinforcement tool or transition to other subliminals that align with your personal goals. It is ultimately up to you to decide how the subliminal can continue to support your personal growth and development.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>