<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Breaking up is never easy, but this subliminal is here to help you heal and move on from your past relationship. By targeting your subconscious mind, it can assist you in letting go of the pain, resentment, and negative emotions associated with your ex and past relationships."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support your healing process:'
    },
    {
      type: 'list',
      items: [
        "Helping you release emotional baggage and let go of the past.",
        "Encouraging self-love and boosting your self-esteem.",
        "Promoting forgiveness, both for yourself and your ex.",
        "Assisting in rebuilding trust and opening yourself up to new relationships.",
        "Empowering you to embrace new opportunities for love and happiness."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, healing takes time, and everyone's journey is unique. By consistently listening to this subliminal, you can gradually let go of the pain and move forward with a renewed sense of self and the possibility of finding true love again. Embrace this opportunity for healing and growth."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Letting go of a past relationship and finding the strength to move forward can be challenging, but this subliminal is here to support you on your journey to healing. Before you start listening, consider these strategies to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your emotions: Allow yourself to feel the pain and sadness of the breakup, but also recognize that you have the power to heal and create a bright future.",
        "Self-reflection: Take time to analyze the lessons learned from past relationships and understand what you truly desire in a healthy and loving partnership.",
        "Practice self-care: Prioritize your well-being by engaging in activities that bring you joy, such as exercise, spending time with loved ones, and pursuing your interests.",
        "Visualize your future: Envision a life filled with love, happiness, and new opportunities. Focus on the positive aspects of leaving the past behind.",
        "Stay open to new experiences: Allow yourself to be receptive to new relationships, trusting that the right person will come into your life when the time is right."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal, it will help you shift your mindset, release emotional baggage, and embrace new beginnings. Remember that healing takes time, and it is okay to have ups and downs along the way. Let go of the past, embrace self-love, and get ready to welcome the love and happiness that you deserve."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Get Over and Move on from Your Ex and Past Relationships subliminal! You've taken an important step towards healing and moving forward from the pain of a breakup and past relationships. It's time to focus on your personal growth and open yourself up to new opportunities for love and happiness."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Allow yourself to grieve: It's normal to feel some sadness and pain after a breakup. Give yourself permission to acknowledge and process these emotions.",
        "Practice self-care: Engage in activities that bring you joy, care for your physical and mental well-being, and surround yourself with positive influences.",
        "Forgive and let go: Release anger and resentment towards your ex or past relationships. Focus on your own healing, growth, and future happiness.",
        "Embrace new opportunities: Open yourself up to new experiences and possibilities. Don't be afraid to take chances and meet new people."
      ]
    },
    {
      type: 'paragraph',
      text: "Healing from a breakup and moving on from past relationships takes time and patience. Be kind to yourself throughout this process and remember that you deserve love and happiness. By allowing yourself to heal and embracing new opportunities, you can create a brighter and more fulfilling future. Best of luck on your journey!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Get Over and Move on from Your Ex and Past Relationships subliminal help me heal from a breakup?`, 
    answer: `The Get Over and Move on from Your Ex and Past Relationships subliminal works by targeting your subconscious mind to promote healing and letting go of past relationship pain. Through positive affirmations and powerful subliminal messages, it can help you reframe your thoughts and emotions surrounding the breakup, allowing you to move forward and open yourself up to new opportunities for love and happiness.`,
    open: false
  },
  { 
    question: `How long does it take to recover from a breakup using the subliminal?`, 
    answer: `The time it takes to recover from a breakup varies from person to person and depends on various factors such as the length of the relationship and individual coping mechanisms. While the subliminal can help accelerate the healing process, it is important to allow yourself the time and space to heal naturally. Consistently listening to the subliminal and practicing self-care can support your journey towards moving on from your past relationship.`,
    open: false 
  },
  { 
    question: `Can the subliminal help me let go of past relationship trauma?`, 
    answer: `Yes, the Get Over and Move on from Your Ex and Past Relationships subliminal is designed to help you let go of past relationship trauma and emotional baggage. It aims to rewire your subconscious mind to release negative patterns and emotions associated with the past, allowing you to embrace a healthier and more positive outlook on love and relationships.`,
    open: false 
  },
  { 
    question: `Will the subliminal make me forget about my ex completely?`, 
    answer: `The subliminal is not meant to erase memories of your ex completely. Instead, it aims to help you detach from the pain and negative emotions associated with the past relationship, allowing you to move forward and create a better future for yourself. You will still have the memories, but they will no longer hold you back or cause unnecessary suffering.`,
    open: false 
  },
  { 
    question: `Is it normal to feel sadness or grief despite using the Get Over and Move on from Your Ex and Past Relationships subliminal?`, 
    answer: `Yes, it is normal to experience moments of sadness or grief even while using the subliminal. Healing takes time, and it is important to acknowledge and honor your emotions. The subliminal can support your healing journey and provide you with the tools to navigate these emotions in a healthy way.`,
    open: false 
  },
  { 
    question: `Can the subliminal help me find love again?`, 
    answer: `Yes, the Get Over and Move on from Your Ex and Past Relationships subliminal can help you open yourself up to new opportunities for love and happiness. By releasing past relationship baggage and fostering a positive mindset, it can create space for new love to enter your life. However, it is important to be patient and allow love to unfold naturally in its own time.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>