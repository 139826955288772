<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're experiencing a diminished sense of smell and taste, this subliminal can help you heal and recover. By using targeted exercises and techniques, it aims to enhance your sensory perception and bring back the ability to fully experience and enjoy the flavors and scents of life."
    },
    {
      type: 'heading',
      text: "Here's how this subliminal can benefit you:"
    },
    {
      type: 'list',
      items: [
        "By stimulating neural pathways and sensory receptors associated with smell and taste.",
        "By promoting neuroplasticity and encouraging the brain to create new connections.",
        "By helping you establish a regular routine of specific exercises and techniques.",
        "By enhancing your focus and mindfulness, allowing you to fully engage with your senses.",
        "By providing support and motivation to stay consistent in your practice."
      ]
    },
    {
      type: 'paragraph',
      text: "Through regular use, this subliminal can help you regain and enhance your sense of smell and taste. As your brain rewires and your sensory perception improves, you may notice a broader range of flavors and aromas, leading to a richer and more fulfilling experience with food, drinks, and scents. Remember, healing and recovery take time, so be patient and committed to the process."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "If you're looking to enhance your sense of smell and taste, this subliminal can be a powerful tool to support your healing and recovery journey. Before you start, consider the following preparation tips to optimize your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Commit to practice: Dedicate yourself to regular practice of the exercises and techniques provided in the subliminal.",
        "Create a sensory-rich environment: Surround yourself with different scents and flavors to stimulate your senses during and after listening.",
        "Stay open-minded: Approach the subliminal with an open and receptive mindset, trusting that it can help reawaken your senses.",
        "Visualize success: Imagine fully experiencing and savoring the flavors and scents of different foods and fragrances."
      ]
    },
    {
      type: 'paragraph',
      text: "With consistent practice and a positive mindset, this subliminal can help you regain the joy and richness of smelling and tasting. Embrace this opportunity for healing and recovery, and allow yourself to fully immerse in the world of scents and flavors. Get ready to embark on a journey of rediscovery and enjoy the full sensory experience of life once again."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Sense of Smell and Taste Healing and Recovery subliminal! By focusing on healing and enhancing your senses, you've taken an important step towards fully experiencing the flavors and scents of life. Reflecting on your journey and next steps will help you continue on the path of recovery and enjoyment."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Explore different scents and tastes: Expand your palate by trying new foods and engaging in aroma therapy.",
        "Practice mindful eating: Slow down and savor each bite, paying attention to the flavors and textures.",
        "Try scent training exercises: Utilize techniques like smelling essential oils or focusing on specific scents.",
        "Stay consistent: Make a habit of engaging in activities that promote the healing and recovery of your senses."
      ]
    },
    {
      type: 'paragraph',
      text: "Recovering your sense of smell and taste is a personal journey that requires patience and dedication. Embrace the opportunities to explore new flavors and scents, and savor the experiences that come with it. Remember, every step you take towards healing is a step towards a more vibrant and enjoyable life. Enjoy the beautiful world of senses!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How long does it take to see improvements in my sense of smell and taste with the Sense of Smell and Taste Healing and Recovery subliminal?`, 
    answer: `The time it takes to see improvements in your sense of smell and taste can vary depending on individual circumstances and the extent of any underlying issues. Consistent listening to the subliminal, combined with targeted exercises and techniques, can help speed up the recovery process. It is recommended to continue listening to the subliminal regularly and be patient as gradual improvements occur.`,
    open: false
  },
  { 
    question: `Can the Sense of Smell and Taste Healing and Recovery subliminal help with anosmia or other specific conditions?`, 
    answer: `The Sense of Smell and Taste Healing and Recovery subliminal is designed to promote the healing and recovery of the sense of smell and taste. While it can be beneficial for general improvement, it is not a substitute for professional medical advice or treatment for specific medical conditions. If you have concerns about your sense of smell or taste, it is recommended to consult with a healthcare professional.`,
    open: false 
  },
  { 
    question: `What exercises or techniques can I implement alongside listening to the subliminal to enhance my sense of smell and taste?`, 
    answer: `Alongside listening to the Sense of Smell and Taste Healing and Recovery subliminal, there are several exercises and techniques you can try to enhance your sense of smell and taste. These include aroma therapy, sensory training exercises, mindful eating, and avoiding smoking or exposure to strong chemicals. Experimenting with different smells and flavors, as well as practicing gratitude for the ability to experience them, can also be beneficial.`,
    open: false 
  },
  { 
    question: `Is it safe to listen to the Sense of Smell and Taste Healing and Recovery subliminal while pregnant or breastfeeding?`, 
    answer: `It is generally safe to listen to the Sense of Smell and Taste Healing and Recovery subliminal while pregnant or breastfeeding. However, if you have any concerns, it is recommended to consult with your healthcare provider before incorporating any new practices into your routine.`,
    open: false 
  },
  { 
    question: `Can children and teenagers listen to the Sense of Smell and Taste Healing and Recovery subliminal?`, 
    answer: `Yes, children and teenagers can listen to the Sense of Smell and Taste Healing and Recovery subliminal. However, it is important for parents or guardians to supervise and ensure that the subliminal is appropriate for their child's age and development. If you have any concerns, it is recommended to consult with a healthcare professional.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the Sense of Smell and Taste Healing and Recovery subliminal?`, 
    answer: `There are no known risks or side effects associated with listening to the Sense of Smell and Taste Healing and Recovery subliminal. However, if you have any concerns about your sense of smell or taste, or if you experience any adverse effects while listening to the subliminal, it is recommended to consult with a healthcare professional.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>