<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you've been dreaming of becoming a successful vlogger and growing your online presence, this subliminal can help turn that dream into a reality. By targeting your subconscious mind, it empowers you with the strategies and techniques needed to thrive in the world of vlogging."
    },
    {
      type: 'heading',
      text: 'Here are some key benefits of using this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Gaining confidence in front of the camera and connecting authentically with your audience.",
        "Mastering the art of storytelling and creating engaging content that keeps viewers hooked.",
        "Learning how to effectively edit and produce high-quality videos that stand out.",
        "Developing a consistent content schedule to attract and retain a loyal audience.",
        "Understanding the ins and outs of social media promotion and leveraging platforms to grow your online presence.",
        "Overcoming challenges and setbacks with resilience, persistence, and a positive mindset."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind to adopt the mindset and skills necessary for vlogging success. Remember, building a successful vlogging career takes time and dedication, but with this subliminal as your guide, you'll be well on your way to achieving your goals."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Becoming a successful vlogger takes dedication, creativity, and strategic planning. This subliminal is designed to support you on your journey to online success. Prior to listening, consider these tips to enhance your vlogging experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Define your niche: Determine the specific topics or themes you want to focus on in your vlogs and establish your unique style.",
        "Research successful vloggers: Learn from those who have already achieved success in the vlogging world. Study their strategies, content, and engagement with their audience.",
        "Plan your content: Create a content calendar or outline to ensure your vlogs are well-planned and aligned with your audience's interests.",
        "Practice and improve your skills: Enhance your videography, editing, and public speaking skills to deliver high-quality content.",
        "Set your intentions: Visualize your success as a vlogger and believe in your ability to achieve your goals.",
        "Stay consistent: Commit to regular vlogging and engage with your audience consistently to build a loyal following."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating these strategies, along with the subliminal messages in this session, you can boost your confidence, increase your creativity, and attract a larger audience. Take a deep breath, focus on your intentions, and let's embark on your journey to becoming a successful vlogger!" 
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Become a Successful Vlogger subliminal session! By focusing on developing the skills and strategies needed to excel in the world of vlogging, you've taken an important step towards growing your online presence and achieving success. Now, it's time to reflect on your experience and continue your journey to becoming a successful vlogger."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Evaluate your progress: Analyze your existing vlogs to identify areas for improvement.",
        "Research your target audience: Understand their preferences and interests to create content that resonates with them.",
        "Develop your unique style: Experiment with different techniques and find your voice as a vlogger.",
        "Build a consistent posting schedule: Regularly upload content to keep your audience engaged and coming back for more."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, becoming a successful vlogger takes time and dedication. Embrace the learning process, be open to feedback, and make continuous improvements. your online presence will grow, and you'll be well on your way to achieving your vlogging goals. Good luck and happy vlogging!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Become a Successful Vlogger subliminal help me?`, 
    answer: `The Become a Successful Vlogger subliminal is designed to help you develop the mindset, confidence, and motivation needed to become a successful vlogger and grow your online presence. By listening to the subliminal regularly, you can align your subconscious beliefs and thoughts with the goals and strategies necessary for vlogging success. This can include improving your communication skills, building a supportive audience, and maximizing your creativity and productivity.`,
    open: false
  },
  { 
    question: `How long before I start seeing results from the Become a Successful Vlogger subliminal?`, 
    answer: `The time it takes to see results can vary from person to person. Some may experience positive changes right away, while others may need more time and consistent listening to fully integrate the subliminal messages. It is important to be patient and persistent, as building a successful vlogger career takes time and effort. We recommend listening to the subliminal daily and consistently for best results.`,
    open: false 
  },
  { 
    question: `What can I do to maximize the effectiveness of the Become a Successful Vlogger subliminal?`, 
    answer: `To maximize the effectiveness of the subliminal, it is important to take action in conjunction with listening. This may include honing your vlogging skills, engaging with your audience, and staying up-to-date with the latest trends and strategies in the vlogging world. Setting specific goals, creating a content schedule, and collaborating with other vloggers can also help you accelerate your vlogging success.`,
    open: false 
  },
  { 
    question: `Can the Become a Successful Vlogger subliminal guarantee my success as a vlogger?`, 
    answer: `While the Become a Successful Vlogger subliminal can help you develop the mindset and strategies necessary for success, it cannot guarantee success on its own. Your success as a vlogger depends on various factors, including your dedication, hard work, and the quality of your content. The subliminal is a tool to support and enhance your efforts, but it is ultimately up to you to put in the necessary work to achieve your goals.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Become a Successful Vlogger subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, it is important to note that the subliminal is intended to supplement your efforts and strategies as a vlogger, not replace them. It is important to take a balanced approach and make informed decisions when it comes to your vlogging career.`,
    open: false 
  },
  { 
    question: `Will the Become a Successful Vlogger subliminal provide me with specific vlogging techniques or strategies?`, 
    answer: `The Become a Successful Vlogger subliminal focuses on developing the mindset, confidence, and motivation needed for vlogging success. It does not provide specific vlogging techniques or strategies. However, it can help you cultivate the mindset necessary to learn and implement these techniques and strategies effectively. It is recommended to complement the subliminal with further learning and research in the field of vlogging.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>