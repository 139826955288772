<template>
  <div>
    <Login />
  </div>
</template>

<script>
import Login from '../../views/Main/Login/index.vue'
export default {
  components: {
    Login
  },
  computed: {
    isLogin() {
      return this.$store.state.loginStatus == 'login'
    }
  },
  methods: {}
}
</script>
<!-- 
<style scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
}
</style> -->
