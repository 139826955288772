<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Fear of commitment and commitment phobia can greatly impact your relationships and personal fulfillment. This subliminal program is designed to help you overcome these fears and embrace healthy and fulfilling connections with others."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Helping you understand the root causes of your fear of commitment and commitment phobia.",
        "Releasing negative beliefs and patterns that contribute to your fear and avoidance of commitment.",
        "Building your self-esteem and confidence in your ability to maintain healthy relationships.",
        "Promoting trust and emotional vulnerability, allowing you to fully open up to love and commitment.",
        "Assisting you in embracing a positive mindset and perspective on commitment and relationships."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal program, you can retrain your subconscious mind to let go of fear and embrace commitment. With time and practice, you can cultivate healthier relationship patterns and experience the joy and fulfillment that come with deep connections. Remember, change takes time, so be patient and compassionate with yourself throughout this transformative process."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Dealing with fear of commitment or commitment phobia can be challenging, but this subliminal is designed to help you overcome those barriers and embrace healthier and more fulfilling relationships. Before starting your listening session, consider these tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Self-reflection: Take a moment to understand the reasons for your fear of commitment. Identifying the root causes can help you address them more effectively.",
        "Positive affirmation: Repeat positive statements that reinforce your desire and ability to form positive and lasting relationships.",
        "Visualize your ideal relationship: Imagine yourself in a healthy and fulfilling partnership, feeling secure and content.",
        "Stay open-minded: Approach this subliminal with curiosity and willingness to embrace positive change."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal and internalizing its messages, you can shift your mindset and overcome your fear of commitment or commitment phobia. Remember to be patient with yourself, as personal growth takes time. Embrace this opportunity to create positive changes in your life and enjoy the journey towards healthier relationships."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Fear of Commitment and Commitment Phobia Removal session! You've taken an important step towards breaking free from your fear and embracing healthy, fulfilling relationships. Now, it's time to reflect on your journey and continue your growth."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Self-reflection: Take some time to think about your past experiences and how they might have influenced your fear of commitment. Write down any insights or patterns you discover.",
        "Open communication: Communicate your feelings and fears with your partner or trusted friends. Sharing your concerns can help build understanding and support.",
        "Take small steps: Start by committing to small tasks or activities, gradually building up to bigger commitments. This will help you gain confidence along the way.",
        "Seek professional help: Consider seeking therapy or counseling to receive guidance and support in overcoming your fear of commitment.",
        "Practice self-care: Nurture your emotional well-being by engaging in activities that bring you joy and relaxation. Self-care can help build self-confidence and promote a positive mindset."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming the fear of commitment is a process that takes time and effort. Be patient with yourself, celebrate each step forward, and embrace the journey towards healthier relationships and personal growth. You have the power to create the fulfilling and loving connections you deserve!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Fear of Commitment and Commitment Phobia Removal subliminal help me overcome my fear of commitment?`, 
    answer: `The Fear of Commitment and Commitment Phobia Removal subliminal is designed to positively influence your subconscious mind and change your perception and behavior towards commitment. By listening to the subliminal messages regularly, you can reprogram your mindset, release any fears or anxieties associated with commitment, and develop a more positive and open attitude towards healthy and fulfilling relationships.`,
    open: false
  },
  { 
    question: `How long will it take for me to see results with the Fear of Commitment and Commitment Phobia Removal subliminal?`, 
    answer: `Results may vary from person to person, and how quickly you see results with the subliminal can depend on various factors, such as your level of commitment phobia and your consistency in listening to the subliminal. It is recommended to listen to the subliminal consistently for at least a few weeks to allow the positive affirmations to work on your subconscious mind.`,
    open: false 
  },
  { 
    question: `Can the Fear of Commitment and Commitment Phobia Removal subliminal replace therapy or professional help?`, 
    answer: `The subliminal can be a helpful tool in overcoming commitment fears, but it is not intended to replace therapy or professional help. If you are struggling with severe commitment phobia or deep-rooted emotional issues, it is advisable to seek guidance from a qualified therapist or counselor who can provide tailored support and strategies for your specific situation.`,
    open: false 
  },
  { 
    question: `Will the Fear of Commitment and Commitment Phobia Removal subliminal make me want to rush into relationships?`, 
    answer: `No, the Fear of Commitment and Commitment Phobia Removal subliminal is not designed to make you rush into relationships or disregard your own boundaries. It aims to help you overcome your fear of commitment and develop healthier attitudes towards relationships. The subliminal works by promoting a positive and balanced mindset that allows you to form connections with others without being hindered by fear or avoidance.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Fear of Commitment and Commitment Phobia Removal subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Fear of Commitment and Commitment Phobia Removal subliminal. However, if you have any concerns or specific psychological conditions, it is advisable to consult with a mental health professional before using the subliminal.`,
    open: false 
  },
  { 
    question: `Can the Fear of Commitment and Commitment Phobia Removal subliminal be used for other fears or anxieties?`, 
    answer: `While the subliminal is specifically designed to address commitment fears and commitment phobia, some individuals may find it helpful in dealing with other related fears or anxieties. The subliminal messages focus on cultivating a positive and open mindset, which can be beneficial in various areas of life. However, for specific fears or anxieties unrelated to commitment, it may be more effective to explore subliminals targeted for those particular concerns.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>