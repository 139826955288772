<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're looking to reverse gray hair and restore your natural hair color, this subliminal can help you achieve that using natural methods and remedies. By targeting your subconscious mind, it encourages the restoration of your hair's natural color."
    },
    {
      type: 'heading',
      text: 'Benefits of using this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Stimulating the production of melanin in your hair follicles, which contributes to natural hair color.",
        "Promoting blood circulation to the scalp, supporting healthy hair growth and color.",
        "Providing essential nutrients and vitamins to nourish and strengthen your hair follicles.",
        "Reducing stress and oxidative damage, which can contribute to premature graying.",
        "Supporting overall hair health and vitality."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can harness the power of your subconscious mind to reverse gray hair and restore your natural hair color. It's important to remember that results may vary for each individual, and it may take time to see noticeable changes. Consistency and patience are key in achieving your desired outcome."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Are you ready to reverse gray hair and restore your natural hair color? This subliminal is designed to support you on your journey to achieving your desired hair color using natural methods and remedies. Before you start listening, here are some things to consider:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Research natural methods: Familiarize yourself with natural methods and remedies that are believed to help reverse gray hair and restore natural color.",
        "Set realistic expectations: Understand that natural methods may take time and consistency to show results.",
        "Create a self-care routine: Develop a routine that includes lifestyle changes, like a healthy diet, regular exercise, stress management, and proper hair care.",
        "Visualize your desired hair color: Imagine your hair returning to its natural color and feeling confident and happy with the transformation."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal and implementing the strategies mentioned above, you can support the restoration of your natural hair color. Remember to stay patient and consistent throughout this journey. Embrace this opportunity to reconnect with your hair and enhance your self-confidence through natural means. Let's begin!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Gray Hair Reversal and Natural Color Restoration subliminal session! By investing in your hair's natural color and reversing gray hair, you've taken a significant step towards embracing your authentic self. Now, it's time to reflect on your experience and continue your journey towards vibrant, healthy hair."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Embrace a healthy diet: Nourish your hair from within by consuming nutrient-rich foods that support hair health.",
        "Consider natural remedies: Research and experiment with natural options such as herbal rinses or essential oils known for hair color restoration.",
        "Protect your hair: Minimize heat styling, chemical treatments, and exposure to damaging factors like sun and pollution.",
        "Stay patient and consistent: Hair color restoration takes time, so be patient and maintain a consistent routine to see gradual results."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, your hair is unique, and each person's experience with hair color restoration may vary. Embrace your beautiful journey towards vibrant, natural hair color and celebrate the progress you've made so far. With dedication and care, you can continue to nurture your hair's health and restore its natural radiance. Shine on!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Can the Gray Hair Reversal and Natural Color Restoration subliminal really reverse gray hair?`, 
    answer: `The Gray Hair Reversal and Natural Color Restoration subliminal is designed to help you cultivate a positive mindset and healthier habits that may contribute to the restoration of your natural hair color. While it is not possible to guarantee complete reversal of gray hair for everyone, the subliminal aims to promote overall hair health and encourage the body's natural processes for hair pigmentation.`,
    open: false
  },
  { 
    question: `How long does it take to see results with the Gray Hair Reversal and Natural Color Restoration subliminal?`, 
    answer: `Results from the subliminal can vary depending on factors such as age, genetics, and overall health. It may take several weeks or even months of consistent listening to the subliminal for some individuals to notice changes in their hair color. Patience, dedication, and a healthy lifestyle can all contribute to the effectiveness of the subliminal.`,
    open: false 
  },
  { 
    question: `Do I need to follow any additional steps besides listening to the Gray Hair Reversal and Natural Color Restoration subliminal?`, 
    answer: `While listening to the subliminal is an important part of the process, it is also beneficial to adopt a healthy lifestyle that supports overall hair health. This includes a balanced diet, regular exercise, stress management, and proper hair care. Taking care of your hair and scalp, avoiding excessive heat and chemical treatments, and protecting your hair from environmental damage can also contribute to the effectiveness of the subliminal.`,
    open: false 
  },
  { 
    question: `Are there any known side effects of listening to the Gray Hair Reversal and Natural Color Restoration subliminal?`, 
    answer: `No, there are no known side effects associated with listening to this subliminal audio. However, if you have any concerns about your hair or health, please consult with your healthcare provider or a qualified professional. While the subliminal aims to promote natural color restoration, it does not replace expert advice or medical treatment.`,
    open: false 
  },
  { 
    question: `Can the Gray Hair Reversal and Natural Color Restoration subliminal be used on colored or dyed hair?`, 
    answer: `Yes, the subliminal can be used on colored or dyed hair. It is designed to promote overall hair health and encourage the body's natural processes for hair pigmentation. However, please note that individual results may vary depending on the condition and type of hair.`,
    open: false 
  },
  { 
    question: `Is the Gray Hair Reversal and Natural Color Restoration subliminal suitable for everyone?`, 
    answer: `The Gray Hair Reversal and Natural Color Restoration subliminal is suitable for most individuals who desire to promote natural hair color restoration. However, individual results may vary depending on factors such as age, genetics, and overall health. It is recommended to listen to the subliminal consistently and consult with a healthcare provider or a qualified professional for personalized advice.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>