<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're struggling with a fear of abandonment, it can negatively impact your relationships and overall wellbeing. This subliminal is designed to help you overcome that fear and develop healthier and more secure relationships."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Reducing anxiety and insecurity related to abandonment.",
        "Promoting self-love and self-worth, boosting your confidence in relationships.",
        "Helping you develop trust and open communication in your relationships.",
        "Supporting the healing process from past abandonment experiences.",
        "Empowering you to set healthy boundaries and cultivate fulfilling relationships."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to let go of fear and embrace a more positive and secure mindset. As you develop healthier beliefs and behaviors, you'll be able to form deeper connections with others and enjoy more fulfilling relationships. Remember, personal growth takes time and dedication, but you have the ability to overcome your fear of abandonment and create the relationships you deserve."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "If you struggle with a fear of abandonment, this subliminal can help you overcome that fear and build healthier and more secure relationships. Before you begin, here are some tips to prepare yourself for the subliminal experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your fear: Recognize and accept that you have a fear of abandonment. This is the first step towards overcoming it.",
        "Self-reflection: Take some time to understand the root causes of your fear. Reflect on past experiences and any patterns you may have noticed.",
        "Set positive intentions: Focus on developing healthy and secure relationships. Visualize yourself feeling safe, loved, and valued.",
        "Open yourself to change: Be willing to let go of limiting beliefs and negative thought patterns that contribute to your fear of abandonment."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to reprogram your subconscious mind and help you overcome your fear of abandonment. By listening regularly, you can develop new beliefs, increase your self-worth, and build healthier relationships. Embrace the opportunity to heal and grow, and get ready to transform your mindset and experience of love and connection."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Fear of Abandonment Removal subliminal! By taking this step to overcome your fear of abandonment, you are paving the way for healthier and more secure relationships. Now that you've listened to the session, it's important to reflect and continue your personal growth and transformation."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your feelings: Take some time to journal your thoughts and emotions after the session.",
        "Practice self-compassion: Be kind and patient with yourself as you work through your fear of abandonment.",
        "Communicate with loved ones: Share your experience and feelings with trusted friends or family members.",
        "Seek professional guidance: If needed, consider reaching out to a therapist or counselor who can provide support and guidance in overcoming your fear."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember that healing from the fear of abandonment takes time and effort, but it is possible. Be gentle with yourself as you navigate this journey, and know that you are deserving of healthy, loving, and secure connections. Keep pushing forward, and don't hesitate to seek help along the way. You've got this!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Fear of Abandonment Removal subliminal help me overcome my fear?`, 
    answer: `The Fear of Abandonment Removal subliminal is designed to target the subconscious mind and reprogram it to let go of the fear of abandonment. By listening to the subliminal regularly, the positive affirmations can help you build self-confidence, heal emotional wounds, and develop healthier attachment styles. Over time, you may notice a reduction in anxiety and an increased ability to form secure and fulfilling relationships.`,
    open: false
  },
  { 
    question: `Is it normal to take time to see results with the Fear of Abandonment Removal subliminal?`, 
    answer: `Yes, it is normal for individuals to experience different timelines when overcoming the fear of abandonment. Some may notice changes in their mindset and behavior relatively quickly, while others may require more time and consistent listening to fully reprogram their subconscious beliefs and patterns. Patience and persistence are key when working towards overcoming deeply ingrained fears.`,
    open: false 
  },
  { 
    question: `Can the Fear of Abandonment Removal subliminal replace therapy or professional help?`, 
    answer: `The Fear of Abandonment Removal subliminal is not a substitute for professional therapy or help. While it can be a helpful tool in conjunction with therapy, it is important to seek guidance from a qualified mental health professional to address the root causes of your fear and receive personalized support in your healing journey.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the Fear of Abandonment Removal subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Fear of Abandonment Removal subliminal. However, if you have any concerns about your mental health, it is important to consult with a qualified mental health professional for appropriate guidance and support.`,
    open: false 
  },
  { 
    question: `How can I maximize the effectiveness of the Fear of Abandonment Removal subliminal?`, 
    answer: `To maximize the effectiveness of the Fear of Abandonment Removal subliminal, it is recommended to listen to it consistently and in a calm and relaxed environment. Pairing it with other therapeutic practices such as journaling, self-reflection, and seeking support from trusted individuals can also enhance the healing process. Remember to be patient and kind to yourself throughout your journey of overcoming the fear of abandonment.`,
    open: false 
  },
  { 
    question: `Can the Fear of Abandonment Removal subliminal help with all types of abandonment fears?`, 
    answer: `The Fear of Abandonment Removal subliminal is designed to address a broad range of abandonment fears. However, for individuals with complex trauma or specific phobias related to abandonment, it is important to seek professional help to ensure comprehensive support and healing.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>