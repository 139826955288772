<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Inner conflicts can arise when we have conflicting thoughts, emotions, or desires within ourselves. These conflicts can cause feelings of unrest, confusion, and dissatisfaction. However, by addressing and resolving these conflicts, we can find peace and alignment within ourselves."
    },
    {
      type: 'heading',
      text: 'Here are some key aspects of this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Identifying and acknowledging the conflicting thoughts and emotions that you may be experiencing.",
        "Understanding the underlying reasons and beliefs associated with these conflicting thoughts.",
        "Gaining clarity and perspective on the different aspects of the conflict.",
        "Finding strategies to effectively manage and resolve the conflict in a healthy and constructive way.",
        "Developing self-compassion and acceptance throughout the process of resolving inner conflicts."
      ]
    },
    {
      type: 'paragraph',
      text: "With regular listening, this subliminal can help you navigate and resolve your inner conflicts, allowing for a greater sense of inner peace, harmony, and personal growth. Remember, addressing inner conflicts is a journey, and it may take time and patience. Trust in your ability to find resolution and embrace the inner peace that awaits you."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Resolving inner conflicts and finding peace within yourself is a journey that requires self-reflection and emotional healing. This subliminal is designed to guide you through that process. Before you begin, here are some suggestions to prepare yourself:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a quiet space: Find a comfortable and quiet place where you can listen to the subliminal without distractions.",
        "Set your intention: Reflect on the specific inner conflicts you want to address and resolve.",
        "Open yourself to healing: Be open to the process and allow yourself to experience and release conflicting emotions.",
        "Practice self-compassion: Be gentle with yourself throughout this journey and acknowledge that healing takes time.",
        "Trust the process: Understand that resolving inner conflicts may not happen overnight, but with regular listening, you can make progress."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal, allow the affirmations to penetrate your subconscious mind and help you find harmony within yourself. Embrace this opportunity for growth and self-discovery, knowing that by resolving inner conflicts, you can create a greater sense of peace and well-being in your life."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Resolve Inner Conflict subliminal session! By acknowledging and addressing the conflicting thoughts and emotions within yourself, you've taken an important step towards finding peace and clarity. As you reflect on your experience, remember that resolving inner conflicts is a continuous process that requires patience and self-compassion."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Self-reflection: Take the time to identify and understand the root causes of your inner conflicts.",
        "Writing exercise: Consider journaling or writing down your conflicting thoughts and emotions to gain clarity.",
        "Seek support: Reach out to trusted friends, family, or professionals who can provide guidance and help.",
        "Practice self-compassion: Be kind to yourself as you navigate through the process of resolving your inner conflicts."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember that finding peace within yourself is a journey unique to you. Embrace the insights and lessons gained from this experience, and continue to invest in your personal growth and well-being. You have the capacity to overcome inner conflicts and create a harmonious inner world. Trust yourself and the process."
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Resolve Inner Conflict subliminal help me?`, 
    answer: `The Resolve Inner Conflict subliminal can help you address and resolve conflicting thoughts and emotions by influencing your subconscious mind. By listening to the subliminal regularly, you can reprogram your mindset and develop a greater sense of self-awareness and emotional balance. It can provide support as you navigate through inner conflicts and help you find peace within yourself.`,
    open: false
  },
  { 
    question: `How long does it take to resolve inner conflicts with the subliminal?`, 
    answer: `The time it takes to resolve inner conflicts can vary from person to person. Some may notice positive changes in their mindset and emotions relatively quickly, while others may require more time and consistent listening. We recommend listening to the Resolve Inner Conflict subliminal daily and allowing the positive affirmations to gradually influence your subconscious and help you find resolution.`,
    open: false 
  },
  { 
    question: `Can the Resolve Inner Conflict subliminal replace therapy or professional help?`, 
    answer: `No, the Resolve Inner Conflict subliminal is not a substitute for therapy or professional help. It is designed to complement therapeutic interventions and provide support as you work through your inner conflicts. If you are experiencing significant emotional distress or struggling with unresolved trauma, it is important to seek the guidance of a trained mental health professional.`,
    open: false 
  },
  { 
    question: `What can I do to enhance the effectiveness of the Resolve Inner Conflict subliminal?`, 
    answer: `You can enhance the effectiveness of the Resolve Inner Conflict subliminal by incorporating self-reflection practices into your daily routine. Journaling, meditation, and mindfulness exercises can help you deepen your self-awareness and uncover the root causes of your inner conflicts. In addition, seeking support from a therapist or counselor can provide valuable guidance and tools for resolving inner conflicts.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Resolve Inner Conflict subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Resolve Inner Conflict subliminal. However, if you have any concerns about your mental health or emotional well-being, it is important to seek professional help. The subliminal is intended to supplement therapeutic interventions or personal growth work, not replace them.`,
    open: false 
  },
  { 
    question: `Is it normal to experience discomfort or resistance when listening to the Resolve Inner Conflict subliminal?`, 
    answer: `Yes, it is not uncommon to experience some discomfort or resistance when addressing and resolving inner conflicts. Inner growth and healing can be challenging, and it is natural to encounter resistance along the way. If you find the process difficult, consider seeking the support of a therapist or counselor who can provide guidance and help you navigate through your journey of resolving inner conflicts.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>