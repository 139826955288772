<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "In today's fast-paced world, finding relaxation and inner peace can sometimes feel difficult. This is where this subliminal comes in - to help you reprogram your mind to be able to relax anytime and anywhere."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can be beneficial for you:'
    },
    {
      type: 'list',
      items: [
        "If you often feel stressed, anxious, or overwhelmed and struggle to find moments of relaxation.",
        "If you want to cultivate a deeper sense of peace and tranquility in your daily life.",
        "If you find it difficult to unwind and switch off from work or other responsibilities.",
        "If you want to be able to relax in any environment, even in situations that may typically cause stress.",
        "If you want to improve your overall well-being and mental health by incorporating regular relaxation practices.",
      ]
    },
    {
      type: 'paragraph',
      text: "With regular use, this subliminal can help you rewire your mind to respond to relaxation cues and triggers more easily. You may find yourself becoming more naturally calm, centered, and able to find peace in any situation. Remember, relaxation is a skill that can be developed and strengthened with practice."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Finding relaxation in today's fast-paced world can be challenging, but this subliminal is designed to help reprogram your mind to relax whenever and wherever you need it. Before you start, here are a few tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a peaceful environment: Find a quiet and comfortable space where you can relax without distractions.",
        "Set your intention: Focus on your desire to be able to relax easily and effortlessly.",
        "Take deep breaths: Take a few deep breaths before starting the subliminal to help calm your mind and prepare for relaxation.",
        "Open your mind: Approach the subliminal with an open and receptive mindset, allowing the positive suggestions to penetrate your subconscious."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can train your mind to find relaxation more easily and effortlessly. It may take time, but with dedication and practice, you can create a positive shift in your ability to relax. So find a quiet space, take a deep breath, and let's begin the journey to a more relaxed state of mind."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Relaxation Aid subliminal! By investing time in reprogramming your mind to relax, you're taking an important step towards improving your overall well-being and finding calm in any situation. Now, let's reflect on your experience and embrace the practice of relaxation in your daily life."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice mindfulness: Incorporate mindfulness techniques into your daily routine to cultivate a sense of peace and presence.",
        "Try different relaxation techniques: Experiment with various methods such as deep breathing exercises, meditation, or progressive muscle relaxation.",
        "Create a relaxing environment: Designate a space at home where you can unwind and destress.",
        "Prioritize self-care: Engage in activities that bring you joy and allow yourself time for rest and relaxation."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, relaxation is a skill that improves with practice. Embrace the journey of finding peace and tranquility in your everyday life. As you continue to prioritize relaxation and self-care, you'll discover the numerous benefits it has on your mental, emotional, and physical well-being. Enjoy the moments of serenity!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Relaxation Aid subliminal work?`, 
    answer: `The Relaxation Aid subliminal works by sending positive affirmations directly to your subconscious mind, helping you reprogram your mindset and adopt a state of relaxation. By consistently listening to the subliminal, you can train your mind to automatically relax during stressful situations and promote a sense of inner calm and peace.`,
    open: false
  },
  { 
    question: `Can I listen to the Relaxation Aid subliminal while doing other activities?`, 
    answer: `Yes, you can listen to the Relaxation Aid subliminal while doing other activities. The subliminal messages are designed to work in the background, so you can listen to them while working, studying, doing chores, or even during exercise. However, for maximum effectiveness, it is recommended to find a quiet and comfortable space where you can fully focus on relaxation and allow the subconscious mind to absorb the affirmations.`,
    open: false 
  },
  { 
    question: `How long should I listen to the Relaxation Aid subliminal?`, 
    answer: `The recommended listening duration for the Relaxation Aid subliminal is at least 20 minutes per session. However, you can listen to it for longer periods if you prefer. Consistency is key, so it is recommended to incorporate regular listening sessions into your routine to fully benefit from the positive affirmations and reprogramming of your mind.`,
    open: false 
  },
  { 
    question: `Will the Relaxation Aid subliminal work for everyone?`, 
    answer: `The effectiveness of the Relaxation Aid subliminal may vary from person to person. While many individuals experience positive results, some may require more time and consistent listening sessions to fully reprogram their mind and achieve a state of relaxation. It is important to approach the subliminal with an open mind and be patient with the process.`,
    open: false 
  },
  { 
    question: `Are there any side effects of using the Relaxation Aid subliminal?`, 
    answer: `No, there are no known side effects associated with using the Relaxation Aid subliminal audio. However, if you have any concerns or health conditions, it is advisable to consult with a healthcare professional before using the subliminal. The subliminal is intended to supplement relaxation techniques and practices, not replace them.`,
    open: false 
  },
  { 
    question: `Can the Relaxation Aid subliminal be used as a substitute for professional therapy?`, 
    answer: `No, the Relaxation Aid subliminal is not a substitute for professional therapy. It is designed to help you relax and reprogram your mind towards a state of calm, but it should not replace any necessary therapeutic interventions or treatments. If you have any mental health concerns, it is important to seek guidance from a qualified professional.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>