export const recent = {
  namespaced: true,
  state: {
    played: {
      data: [],
      loading: true,
    },
    search: {
      data: [],
      loading: true,
    },
  },
  getters: {},
  actions: {
    getPlayed({ commit }, data) {
      commit("getPlayed", data);
    },
    getSearch({ commit }, data) {
      commit("getSearch", data);
    },
    getPlayedLoading({ commit }, data) {
      commit("getPlayedLoading", data);
    },
    getSearchLoading({ commit }, data) {
      commit("getSearchLoading", data);
    },
  },
  mutations: {
    getPlayed(state, data) {
      state.played.data = data;
    },
    getSearch(state, data) {
      state.search.data = data;
    },
    getPlayedLoading(state, data) {
      state.played.loading = data;
    },
    getSearchLoading(state, data) {
      state.search.loading = data;
    },
  },
};
