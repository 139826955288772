<template>
  <FeaturedContents />
</template>

<script>
import FeaturedContents from '@/views/Admin/FeaturedContents/index.vue'
export default {
  components: {
    FeaturedContents
  },
  data: () => {
    return {}
  }
}
</script>
