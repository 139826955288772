<template>
  <div>
    <v-overlay
      :value="overlay"
      color="white"
      opacity="1"
      style="z-index: 1000000"
    >
      <v-img src="/logo.png" class="blink"></v-img>
    </v-overlay>
  </div>
</template>

<script>
import { checkAuthentication } from "@/api/user";
import { getMessage } from "@/utils";
export default {
  data: () => {
    return {
      getMessage,
      checkAuthentication,

      overlay: true,
    };
  },
  mounted() {
    const query = window.location.search;
    const params = new URLSearchParams(query);
    const token = params.get("token");

    if (token != "") {
      localStorage.setItem("user_token", token);
    }

    this.checkOAuth();
  },
  methods: {
    checkOAuth() {
      this.checkAuthentication().then((res) => {
        const { success, data } = res;
        if (success) {
          localStorage.setItem("user_token", data.token);
          localStorage.setItem("user", JSON.stringify(data.user));
          // this.$router.push('/user')
          this.$router.push("/user/mood");
        } else {
          this.$router.push("/login");
          if (res.code == 401) {
            res.message = "User Already Exist";
          }
          this.getMessage(this, res);
        }
      });
    },
  },
};
</script>

<style>
@keyframes fade {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fade {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.blink {
  animation: fade 3000ms infinite;
  -webkit-animation: fade 3000ms infinite;

  width: 80px;
  height: 80px;
}
</style>
