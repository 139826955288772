<template>
  <section class="mb-6">
    <div class="container">
      <div>
        <h1>
          Ready to go Premium?
        </h1>
        <h5 class="mb-5">
          Embark on a journey to a happier, healthier you with Magus App Premium. Gain access to the ultimate subliminal audio streaming experience that can transform your mental health and well-being. Try it today!
        </h5>
      </div>
      <div class="row gap-2 justify-content-center">
        <template v-for="subscription, index in subscriptions">
          <div class="subscription-card col-5" :key="index">
            <div>
              <h3>{{ subscription.name }}</h3>

              <template v-if="subscription.name != 'Free'">
                <h4>{{ getAmount(subscription) }}</h4>
                <div class="d-flex justify-content-center">
                  <div :class="`period-card ${isPeriodMonthly}`" @click="getPeriod('Monthly')">
                    <p class="mb-0">Monthly</p>
                  </div>
                  <div :class="`period-card ${isPeriodYearly}`" @click="getPeriod('Yearly')">
                    <p class="mb-0">Yearly</p>
                  </div>
                </div>
              
              </template>
              
              <ul>
                <li v-for="description in stringToArray(subscription.description)">
                  <v-icon size="12" :color="subscription.name == 'Free' ? 'white' : ''">
                    mdi-check-circle-outline
                  </v-icon> &nbsp;
                  {{ description }}
                </li>
              </ul>
            </div>
            <div>
              <button class="button btn btn-sm" v-if="subscription.name == 'Free'" @click="skipPayment()">
                sign up
              </button>
              <button class="button btn btn-sm" v-else @click="dialog = true">
                go {{ subscription.name }}
              </button>
            </div>
          </div>
        </template>
      </div>
      <DialogPrice :dialog="dialog" :width="350" title="" @closeDialog="closeDialog">
        <Payment name="Premium" :amount="subscription_amount.toString()" :period="period" :isLogin="isLogin"
          @closeDialog="closeDialog" />
      </DialogPrice>
    </div>
  </section>
</template>

<script>
import DialogPrice from '@/components/DialogPrice.vue'
import Payment from '@/components/Home/Payment.vue'
export default {
  components: {
    DialogPrice,
    Payment
  },
  data: () => {
    return {
      dialog: false,

      subscription_display: ['Free', 'Premium'],

      subscription_name: '',
      subscription_amount: 0,
      subscription_period: '',

      period: "Monthly",
      isLogin: false
    }
  },
  computed: {
    user() {
      return this.$store.state.user.info;
    },
    users() {
      return JSON.parse(this.user);
    },
    userToken() {
      return this.$store.state.user.token;
    },
    subscription() {
      return this.$store.state.subscription.data
    },
    subscriptions() {
      return this.subscription.filter(subscription => this.subscription_display.includes(subscription.name))
    },
    isPeriodMonthly() {
      if (this.period == 'Monthly') return "active"
    },
    isPeriodYearly() {
      if (this.period == 'Yearly') return "active"
    },
  },
  watch: {
    period(val) {
      this.subscription_period = val
    }
  },
  methods: {
    stringToArray(description) {
      return description.split(";")
    },
    getPeriod(data) {
      this.period = data
    },
    getAmount(subscription) {
      let price = subscription.amount
      if (this.period == 'Yearly') price = subscription.amount_year

      this.subscription_amount = price
      return price.toLocaleString("en-US")
    },
    skipPayment() {
      if(this.userToken == null){
        this.$router.push('register')

        const params = JSON.stringify({
          period: this.period,
          amount: 0,
          subscription: 'Free',
          time_created: new Date()
        })

        localStorage.setItem('subscription_temp', btoa(params))
      }else{
        this.$router.push('user')
      }
    },
    closeDialog(data) {
      this.dialog = data
    },
  }
}
</script>

<style scoped>
  h5 {
    line-height: 1.35em;
    font-family: "Montserrat-Regular";
  }
  .container > div:nth-of-type(1) > p:nth-of-type(1) {
    margin-block: 1em;
  }
  .subscription-card > div > ul {
    padding-inline: 1rem !important;
    max-height: clamp(3.75rem, calc(3.32rem + 7.57vw), 10.00rem);

    overflow-y: auto;
  }
  .subscription-card > div > ul > li {
    list-style-type: none;
      /* font-size: clamp(0.44rem, calc(0.37rem + 0.33vw), 0.63rem); */
    font-size: clamp(0.31rem, calc(0.25rem + 0.63vw), 0.90rem);
    font-family: var(--montserrat);
    padding: .5em 0;

    text-align: left;
    text-wrap: balance;
  }
  .subscription-card > div > div > div > p {
    font-size: clamp(0.31rem, calc(0.25rem + 0.73vw), 1rem);
    font-family: var(--montserrat);
    text-wrap: balance;
  }
  .subscription-card > div > h3 {
    font-size: clamp(0.94rem, calc(0.61rem + 1.63vw), 1.88rem);
    font-family: var(--montserrat-bold);
    margin: 0em;
  }
  .subscription-card > div >h4:before {
    content: "PHP ";
    font-size: clamp(0.50rem, calc(0.37rem + 0.65vw), 0.88rem);
  }
  .subscription-card > div > h4 {
    font-size: clamp(0.81rem, calc(0.57rem + 1.60vw), 1.90rem);
    font-family: var(--montserrat-bold);
    margin: 0.1em;
  }
  .period-card {
    padding: .35em;
    margin: 1em 0;
    min-width: 65px;

    color: var(--ma-black-olive);
    background: var(--ma-white);
    cursor: pointer;
  }
  .period-card.active {
    background: var(--ma-bleu-de-france);
  }
  .period-card.active > p {
    font-family: var(--montserrat-bold);
    color: var(--ma-white);
  }
  .subscription-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: clamp(7.50rem, calc(5.54rem + 14.78vw), 25.13rem);
    height: clamp(13.75rem, calc(12.01rem + 12.70vw), 25.75rem);

    border-radius: 8px;

    padding: .5em;
  }

  h3,
  button {
    text-transform: uppercase;
  }
  .subscription-card:nth-of-type(1) > div > button {
    color: var(--ma-bleu-de-france);
    background: var(--ma-white);
  }
  .subscription-card:nth-of-type(1) {
    background: var(--ma-bleu-de-france);
    color: var(--ma-white);
  }

  .subscription-card:nth-of-type(2) > div > h3{
    color: var(--ma-royal-orange);
  }
  .subscription-card:nth-of-type(2) > div > h4 {
    color: var(--ma-bleu-de-france);
  }
  .subscription-card:nth-of-type(2) > div >button {
    background: var(--ma-royal-orange);
    color: var(--ma-white);
    }
  .subscription-card:nth-of-type(2) {
    background: var(--ma-bright-gray);
  }

  @media screen and (max-width: 30.93rem) {
    section > p {
      font-size: 0.47em;
    }
  }

  @media screen and (min-width: 40rem) {
    .container {
      padding-block: 2rem;
    }
    p {
      font-family: var(--montserrat);
    }
    section {
      text-align: center;
    }
  }
</style>