<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Dealing with failure can be mentally and emotionally challenging, but this subliminal is designed to help you overcome those struggles and build a stable and resilient mindset. By targeting your subconscious mind, it can help you embrace failure as a catalyst for personal growth and transformation."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can help:'
    },
    {
      type: 'list',
      items: [
        "Supporting your mental and emotional stability in the face of failure.",
        "Creating a mindset that views failure as an opportunity for growth and learning.",
        "Encouraging resilience and the ability to bounce back from setbacks.",
        "Helping you develop self-compassion and kindness towards yourself during challenging times.",
        "Fostering a positive mindset and belief in your abilities and potential, regardless of past failures."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to approach failure in a healthier and more empowering way. Over time, you may find yourself better equipped to navigate setbacks, embrace growth opportunities, and cultivate a mindset of resilience and self-belief. Remember, healing and growth take time, so be patient with yourself throughout the process."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Dealing with failure can be emotionally challenging, but this subliminal is designed to help you navigate those struggles and develop mental and emotional stability and resilience. Before you start listening, consider the following tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your emotions: Allow yourself to feel and process the emotions associated with failure, understanding that it's a natural part of life.",
        "Shift your perspective: Embrace failure as an opportunity for growth and learning, rather than a setback.",
        "Practice self-compassion: Be kind and understanding towards yourself during this challenging time, recognizing that failure does not define your worth.",
        "Set realistic expectations: Understand that recovery takes time and effort. Be patient with yourself as you work towards stability and resilience.",
        "Keep an open mind: Listen to the subliminal with a receptive and positive attitude, allowing the affirmations to make a lasting impact on your subconscious mind."
      ]
    },
    {
      type: 'paragraph',
      text: "As you engage in this subliminal, remember that failure is a natural part of life's journey. By embracing the lessons it offers, you can grow stronger and develop the mental and emotional stability needed to bounce back. Take a moment to prepare yourself mentally and emotionally, and let the transformational process begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Failure Mental and Emotional Stability and Recovery subliminal session! By choosing to face and overcome the challenges that come with failure, you're showing great strength and resilience. It's important to reflect on your experience and continue your journey towards mental and emotional stability and growth."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice self-compassion: Remind yourself that failure is a natural part of life and an opportunity for growth.",
        "Embrace a growth mindset: See failure as a stepping stone towards success and learn from your mistakes.",
        "Seek support: Talk to trusted friends, family, or a therapist to help process your emotions and gain perspective.",
        "Set realistic goals: Break down your goals into manageable steps and celebrate small victories along the way."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, failure is not a reflection of your worth or abilities. It's an opportunity for learning, growth, and resilience. Embrace the process, stay positive, and keep moving forward. You have what it takes to overcome any setback and thrive. Believe in yourself!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Failure Mental and Emotional Stability and Recovery subliminal help me?`, 
    answer: `The Failure Mental and Emotional Stability and Recovery subliminal is designed to support you in overcoming the mental and emotional struggles associated with failure. It aims to help you develop resilience, stability, and a positive mindset towards failure. By listening to the subliminal consistently, you can reprogram your subconscious mind to embrace failure as a catalyst for growth and learn from it.`,
    open: false
  },
  { 
    question: `How long does it take to see the effects of the Failure Mental and Emotional Stability and Recovery subliminal?`, 
    answer: `The time it takes to see the effects of the subliminal can vary from person to person. Some individuals may notice positive changes in their mindset and emotional well-being after a few listens, while others may require more time for the affirmations to take root. Consistent listening is key to reprogramming the subconscious mind and experiencing lasting changes.`,
    open: false 
  },
  { 
    question: `Can the Failure Mental and Emotional Stability and Recovery subliminal help with specific types of failure, such as relationship failures or career setbacks?`, 
    answer: `Yes, the Failure Mental and Emotional Stability and Recovery subliminal is designed to help you navigate various types of failure, including but not limited to relationship failures and career setbacks. It aims to provide you with the tools and mindset to bounce back from failure, learn from the experience, and grow stronger.`,
    open: false 
  },
  { 
    question: `Can the subliminal completely remove the negative emotions associated with failure?`, 
    answer: `While the Failure Mental and Emotional Stability and Recovery subliminal can help you develop a positive mindset towards failure and cope with negative emotions, it is important to understand that experiencing some degree of negative emotions after failure is normal and part of the growth process. The subliminal aims to support you in managing and processing your emotions in a healthy way.`,
    open: false 
  },
  { 
    question: `Is it necessary to combine the Failure Mental and Emotional Stability and Recovery subliminal with other personal development practices?`, 
    answer: `While the Failure Mental and Emotional Stability and Recovery subliminal can be effective on its own, combining it with other personal development practices can enhance its effectiveness. Practices such as journaling, therapy, self-reflection, and seeking support from loved ones can provide additional tools and resources in your journey towards mental and emotional stability and recovery.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Failure Mental and Emotional Stability and Recovery subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your mental or emotional well-being, it is recommended to seek professional guidance and support. The subliminal is intended to be a supplement to your personal development journey, not a substitute for professional help.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>