import store from "@/store";
import { addAudioHistory } from "@/api/history";
import {
  getAudioTrack,
  getAudioSubliminal,
  getAudioPlaylist,
} from "@/api/audio";

import { getTrackById } from "@/api/track";
import { getPlaylistInfoById } from "@/api/playlistinfo";
import { playlist } from "@/store/modules/playlist";

export const getAudio = (data, index = 0) => {
  let user = JSON.parse(store.state.user.info);
  let count = 1;

  let title = null;
  let cover = null;
  let version = null;
  let duration = null;
  let track_id = null;
  let playlist_id = null;
  let subliminal_id = null;

  let titles = [];
  let volumes = [];
  let durations = [];
  let audio_types = [];

  let tracks = [];
  let subliminals = [];

  let track_links = [];

  store.dispatch("audio/getDatas", data);
  store.dispatch("audio/getIndex", index);

  if (data.hasOwnProperty("playlist_id")) {
    count = data.subliminals.length;
    subliminals = data.subliminals[index];

    playlist_id = data.playlist_id;
    subliminal_id = subliminals.subliminal_id;

    title = data.title;
    cover = data.cover;
    volumes = subliminals.volume.split(",");

    tracks = subliminals.tracks;
  } else if (data.hasOwnProperty("track_id")) {
    /*console.log(data.tracks[index])
    const filename = data.tracks[index].file_name;
    const extension = data.tracks[index].extension;

    const audio_file = filename + "." + extension;*/

    const link = data.tracks[index].link;
    const title = data.title;
    const volume = data.volume;
    const duration = data.tracks[index].duration;
    version = data.tracks[index].version;
    track_id = data.tracks[index].track_id;

    let track = {};

    track.link = link;
    track.title = title;
    track.volume = volume;
    track.duration = duration;

    tracks[0] = track;
  } else {
    if (Array.isArray(data)) {
      let playlist_track_id = store.state.audio.playlist.track.id;
      if (playlist_track_id == null) {
        playlist_track_id = data[index].subliminal_id;
      }

      playlist_id = playlist_track_id;
      subliminal_id = data[index].subliminal_id;

      title = data[index].title;
      cover = data[index].cover;

      if (data[index].hasOwnProperty("tracks")) {
        tracks = data[index].tracks;
      }

      count = data.length;
    } else {
      playlist_id = data.subliminal_id;
      subliminal_id = data.subliminal_id;

      title = data.title;
      cover = data.cover;

      if (data.hasOwnProperty("tracks")) {
        tracks = data.tracks;
      }
    }
  }

  for (let index = 0; index < tracks.length; index++) {
    const element = tracks[index];

    titles.push(element.title);
    track_links.push(element.link);
    volumes.push(element.volume);
    durations.push(element.duration / 1000);
    audio_types.push(element.audio_type_id);
  }

  duration = data.duration_time;
  if (duration == undefined) {
    duration = Math.max(...durations);
  }

  store.dispatch("audio/getCount", count);
  store.dispatch("audio/getData", track_links);
  store.dispatch("audio/getCover", cover);
  store.dispatch("audio/getTitle", title);
  store.dispatch("audio/getTitles", titles);
  store.dispatch("audio/getVolumes", volumes);
  store.dispatch("audio/getAudioTypes", audio_types);
  store.dispatch("audio/getDurationTime", duration);
  store.dispatch("audio/getDurationTimes", durations);

  store.dispatch("audio/getVersion", version);
  store.dispatch("audio/getTrackId", track_id);
  store.dispatch("audio/getPlaylistId", playlist_id);
  store.dispatch("audio/getSubliminalId", subliminal_id);
};

export const playAudio = () => {
  store.dispatch("audio/getPlay");
};

export const pauseAudio = (history = true) => {
  store.dispatch("audio/getPause");

  if (history) {
    addHistory();
  }
};

export const nextAudio = () => {
  const loop = store.state.audio.loop;
  const index = store.state.audio.index;
  const count = store.state.audio.count;
  const repeat = store.state.audio.repeat;

  const next = index + 1;
  if (!repeat) {
    if (next < count) {
      skipAudio(next);
    }
  }

  if (repeat) {
    skipAudio(index);
  }

  if (next == count) {
    pauseAudio();
  }

  if (loop && next == count) {
    skipAudio(0);
  }
};

export const previousAudio = () => {
  const loop = store.state.audio.loop;
  const index = store.state.audio.index;
  const count = store.state.audio.count;
  const repeat = store.state.audio.repeat;

  const previous = index - 1;
  if (!loop && !repeat) {
    if (previous > -1) {
      skipAudio(index - 1);
    }
  }

  if (repeat) {
    skipAudio(index);
  }

  if (previous == -1) {
    pauseAudio();
  }

  if (loop && previous == -1) {
    skipAudio(count - 1);
  }
};

export const skipAudio = (index) => {
  store.dispatch("audio/getLoading", true);

  const data = store.state.audio.datas;
  const isAudioPlaying = store.state.audio.status;

  if (isAudioPlaying) {
    pauseAudio();
  }

  store.dispatch("audio/getCurrentTime", 0);
  getAudio(data, index);
};

export const repeatAudio = () => {
  const loop = store.state.audio.loop;

  if (!loop) {
    const repeat = store.state.audio.repeat;
    store.dispatch("audio/getRepeat", !repeat);
  }
};

export const loopAudio = () => {
  const loop = store.state.audio.loop;

  if (!loop) {
    store.dispatch("audio/getRepeat", false);
  }

  store.dispatch("audio/getLoop", !loop);
};

export const addHistory = () => {
  const user = JSON.parse(store.state.user.info);
  const data = {
    user_id: user.user_id,
    volume: store.state.audio.volumes.join(),
    playlist_id: store.state.audio.playlist.id,
    current_time: store.state.audio.current.time,
    duration_time: store.state.audio.duration.time,
    subliminal_id: store.state.audio.subliminal.id,
    subscription_id: user.info.subscription_id,
  };

  addAudioHistory(data).then((res) => {
    const playlist = res.playlist;
    const subliminal = res.subliminal;
    let result = playlist.concat(subliminal);

    let results = result.sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    });

    store.dispatch("recent/getPlayed", results);
  });
};
