<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Becoming a successful social media marketer requires a combination of skills, knowledge, and strategies. This subliminal is designed to help you develop these qualities and achieve your goals in the realm of social media marketing."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can assist you:'
    },
    {
      type: 'list',
      items: [
        "Learning effective social media marketing techniques and tactics.",
        "Building a solid understanding of different social media platforms and their algorithms.",
        "Developing your creative skills to create engaging and shareable content.",
        "Gaining confidence in promoting your brand or business on social media.",
        "Improving your networking and relationship-building skills in the online marketing space."
      ]
    },
    {
      type: 'paragraph',
      text: "By consistently listening to this subliminal, you can harness the power of your subconscious mind to absorb and implement the knowledge and skills necessary for success in social media marketing. With practice and dedication, you can grow your online presence, attract a larger audience, and achieve your business goals. Remember, results may vary, but your commitment to learning and applying the strategies will greatly increase your chances of success."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Embarking on a journey to become a successful social media marketer requires dedication and continuous learning. This subliminal is designed to help you develop the mindset and skills necessary to thrive in this industry. Before you start listening, consider implementing the following strategies to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear goals: Define what success means to you in the realm of social media marketing and establish specific and measurable objectives.",
        "Engage in continuous learning: Stay up-to-date with the latest trends and strategies in social media marketing through courses, podcasts, and industry publications.",
        "Build a network: Connect with other social media marketers, influencers, and industry professionals to foster collaboration, exchange knowledge, and gain valuable insights.",
        "Stay organized: Develop a system to manage your social media campaigns, content creation, and analytics effectively.",
        "Maintain a growth mindset: Embrace challenges, seek feedback, and cultivate a willingness to adapt and evolve in the ever-changing landscape of social media marketing."
      ]
    },
    {
      type: 'paragraph',
      text: "With this subliminal as a powerful tool in your arsenal, combined with your passion and determination, you can become a successful social media marketer. By setting clear goals, continuously learning, building connections, staying organized, and maintaining a growth mindset, you are on your way to achieving remarkable results. Embrace this opportunity to unlock your full potential and make a significant impact in the world of social media marketing."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Become a Successful Social Media Marketer subliminal! By investing in your skills and learning the strategies to thrive in the world of social media marketing, you've taken a significant step towards growing your online presence and business. As you embark on this exciting journey, make sure to reflect on your experience and continue your progress."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Evaluate your current online presence: Take a closer look at your social media profiles and identify areas for improvement.",
        "Set specific goals: Define what you want to achieve as a social media marketer and create a roadmap to track your progress.",
        "Stay updated on industry trends: Keep yourself informed about the latest social media marketing strategies and tools.",
        "Connect with like-minded individuals: Join online communities or attend networking events to expand your professional network."
      ]
    },
    {
      type: 'paragraph',
      text: "Becoming a successful social media marketer requires continuous learning and adaptation. Embrace the challenges and opportunities that come your way, and stay dedicated to honing your skills. Remember, every effort you put into growing your online presence will contribute to your success as a social media marketer. Best of luck!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Do I need prior experience in social media marketing to benefit from this course?`, 
    answer: `No, this course is suitable for beginners as well as those with some experience in social media marketing. It covers the fundamental principles and strategies of social media marketing, making it accessible to learners of all levels.`,
    open: false
  },
  { 
    question: `What will I learn from this course?`, 
    answer: `This course will teach you the essential skills and strategies to become a successful social media marketer. You will learn how to create and optimize social media profiles, develop engaging content, build a strong online presence, grow your audience, and implement effective social media advertising campaigns. The course also covers analytics and tracking to measure your success and make data-driven decisions.`,
    open: false 
  },
  { 
    question: `How long does it take to complete the course?`, 
    answer: `The time it takes to complete the course depends on your learning pace and schedule. It is designed to be flexible, allowing you to learn at your own convenience. On average, most learners complete the course within a few weeks by dedicating a few hours each week.`,
    open: false 
  },
  { 
    question: `Is there a certificate upon completion of the course?`, 
    answer: `Yes, upon successful completion of the course, you will receive a certificate of completion. This certificate verifies your achievement and can be a valuable addition to your professional portfolio or resume.`,
    open: false 
  },
  { 
    question: `Can I access the course material after completing it?`, 
    answer: `Yes, you will have lifetime access to the course material. This means you can revisit the content anytime, allowing you to refresh your knowledge or reference specific topics as needed.`,
    open: false 
  },
  { 
    question: `What if I have questions or need support during the course?`, 
    answer: `You will have access to a community or forum where you can ask questions and receive support from both instructors and fellow learners. Additionally, the course may provide resources such as live Q&A sessions or email support to ensure you have the assistance you need throughout your learning journey.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>