<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're currently experiencing toothache pain and discomfort, this subliminal can help provide relief and improve your overall dental health. By utilizing soothing sounds and guided meditation, it aims to alleviate your toothache and create a more comfortable dental experience."
    },
    {
      type: 'heading',
      text: 'Benefits of using this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Relieving toothache pain and discomfort, allowing you to feel more at ease.",
        "Promoting relaxation and reducing feelings of anxiety related to dental visits.",
        "Improving your overall dental health by encouraging positive oral hygiene habits.",
        "Reducing stress and tension in the mouth, jaw, and surrounding areas.",
        "Enhancing your ability to cope with dental procedures and treatments."
      ]
    },
    {
      type: 'paragraph',
      text: "By incorporating this subliminal into your dental care routine, you can find relief from toothache pain and discomfort. It can also help you develop a healthier mindset towards dental health and manage any anxieties related to dental visits. Remember, consistent use in conjunction with professional dental care is important for maintaining optimal dental health."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Toothache pain can be incredibly uncomfortable and disruptive to your daily life. This subliminal is designed to help you find relief from toothache pain and improve your overall dental health. Before you start listening, it's important to prepare yourself for the session with these steps:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Find a comfortable position: Ensure that you are in a comfortable position, either sitting or lying down, so that you can fully relax during the session.",
        "Take a moment to breathe: Before beginning, take a few deep breaths to calm your mind and prepare yourself for the experience.",
        "Focus on the intention: Keep in mind that the purpose of this subliminal is to provide relief from toothache pain and promote dental health. Maintain a clear focus on your desire for pain relief and improved dental well-being.",
        "Use headphones if possible: For the best listening experience, consider using headphones to fully immerse yourself in the soothing sounds and guided meditation."
      ]
    },
    {
      type: 'paragraph',
      text: "By following these preparation tips, you can optimize your experience with this subliminal and increase its effectiveness in providing relief from toothache pain. Remember to listen regularly and give yourself the time and space to fully relax and benefit from the soothing sounds and guided meditation. Close your eyes, take a deep breath, and let the journey to toothache pain relief begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Toothache Pain Relief session! By taking the time to address your toothache pain and discomfort, you've taken an important step towards improving your dental health and overall well-being. As you reflect on your experience, remember that relief and healing are within reach."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Continue practicing good oral hygiene: Brush and floss regularly to maintain a healthy mouth.",
        "Seek professional dental care: If your toothache persists, it's essential to consult a dentist for a proper diagnosis and treatment.",
        "Use natural remedies: Explore herbal remedies like clove oil or rinsing with saltwater for temporary relief.",
        "Take care of yourself: Maintain a healthy lifestyle with balanced nutrition, adequate sleep, and stress management, as they can contribute to overall dental health."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, seeking relief from toothache pain is a journey that requires a combination of self-care and professional assistance. Be proactive in taking care of your dental health to prevent future problems. Your journey to a pain-free smile starts now!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Toothache Pain Relief audio help with toothache pain?`, 
    answer: `The Toothache Pain Relief audio utilizes soothing sounds and guided meditation to help alleviate toothache pain and discomfort. By promoting relaxation and reducing stress, the audio can provide temporary relief from dental pain. It is important to consult with a dentist for proper diagnosis and treatment of the underlying cause of toothache pain.`,
    open: false
  },
  { 
    question: `Is the Toothache Pain Relief audio a substitute for dental treatment?`, 
    answer: `No, the Toothache Pain Relief audio is not a substitute for dental treatment. It is intended to provide temporary relief from toothache pain and discomfort while awaiting professional dental care. It is important to consult with a dentist for proper diagnosis and treatment of toothache issues.`,
    open: false 
  },
  { 
    question: `How often should I listen to the Toothache Pain Relief audio?`, 
    answer: `You can listen to the Toothache Pain Relief audio as often as needed to help manage toothache pain. However, it is important to seek dental treatment to address the underlying cause of the toothache. The audio can provide temporary relief in the meantime.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of using the Toothache Pain Relief audio?`, 
    answer: `No, there are no known risks or side effects associated with using the Toothache Pain Relief audio. However, if toothache pain persists or worsens, it is important to seek professional dental care.`,
    open: false 
  },
  { 
    question: `Can the Toothache Pain Relief audio be used for other types of pain?`, 
    answer: `While the Toothache Pain Relief audio is specifically designed for toothache pain relief, it may provide some relief from other types of minor pain or discomfort. However, it is not intended to replace appropriate medical treatment for other conditions. If you have concerns about other types of pain, consult with a healthcare professional.`,
    open: false 
  },
  { 
    question: `Can the Toothache Pain Relief audio be used for children?`, 
    answer: `The Toothache Pain Relief audio can be used for children, but it is important to assess the individual child's ability to follow the guided meditation and listen to the audio comfortably. Parental supervision is recommended. It is also crucial to consult with a pediatric dentist for proper diagnosis and treatment of any dental issues in children.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>