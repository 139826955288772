<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works',
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Dealing with feelings of worthlessness can be incredibly challenging, but with the help of this subliminal, you can start to overcome them. By targeting your subconscious mind, this subliminal aims to build a strong sense of self-worth and value."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Developing a positive mindset that recognizes your worth and abilities.",
        "Building confidence and self-esteem, empowering you to pursue your goals.",
        "Reducing self-doubt and negative self-talk, replacing them with positive affirmations.",
        "Creating a foundation of self-acceptance and self-love, allowing you to embrace your uniqueness.",
        "Helping you let go of external validation and find validation from within."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember that overcoming feelings of worthlessness takes time and effort, but with consistent listening to this subliminal, you can begin to build a positive mindset that recognizes your worth. Embrace your unique qualities and abilities, and remember that you are deserving of love, happiness, and success."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Struggling with feelings of worthlessness can be challenging, but this subliminal can help you develop a more positive mindset. Before you start listening, consider these strategies to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your worth: Recognize that everyone has unique abilities and value, including yourself.",
        "Challenge negative thoughts: Be aware of negative self-talk and actively replace it with positive affirmations.",
        "Practice self-compassion: Treat yourself with kindness, understanding, and forgiveness.",
        "Visualize self-worth: Imagine yourself confident, successful, and deserving of love and respect.",
        "Keep an open mind: Embrace the powerful messages embedded in this subliminal as you listen."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to rewire your subconscious mind and help you build a strong sense of self-worth. With regular listening, you may notice a shift in your thoughts and feelings, allowing you to embrace your worth and recognize your abilities. Take a moment to set your intention and let's embark on this journey to a positive mindset."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Stop Feelings of Worthlessness subliminal! By taking this step towards overcoming feelings of worthlessness, you have shown incredible strength and determination. Remember, your worth and value are inherent, and this journey will help you recognize and embrace them."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice self-compassion: Be kind to yourself and treat yourself with love and understanding.",
        "Challenge negative self-talk: Replace self-critical thoughts with positive affirmations and empowering statements.",
        "Engage in self-reflection: Explore your strengths, talents, and accomplishments to develop a stronger sense of self-worth.",
        "Surround yourself with positivity: Seek supportive relationships and environments that uplift and encourage you."
      ]
    },
    {
      type: 'paragraph',
      text: "Overcoming feelings of worthlessness is a process that takes time and effort. Embrace each step of your journey and trust in your ability to cultivate a positive mindset. You are worthy, deserving, and capable of achieving anything you set your mind to. Believe in yourself, and remember your inherent value!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Stop Feelings of Worthlessness subliminal help me?`, 
    answer: `The Stop Feelings of Worthlessness subliminal is designed to help you overcome negative self-perceptions and develop a strong sense of self-worth and value. By listening to the subliminal messages, you can reprogram your subconscious mind to cultivate a positive mindset and recognize your unique worth and abilities. Over time, this can lead to increased confidence, self-acceptance, and a more fulfilling life.`,
    open: false
  },
  { 
    question: `How long does it take to see results from listening to the Stop Feelings of Worthlessness subliminal?`, 
    answer: `Results can vary from person to person, and it may take time to fully reprogram your subconscious beliefs about self-worth. Consistent and regular listening to the subliminal can help accelerate the process. It is important to be patient and kind to yourself throughout the journey of building a positive mindset and overcoming feelings of worthlessness.`,
    open: false 
  },
  { 
    question: `Can the Stop Feelings of Worthlessness subliminal replace therapy or professional help?`, 
    answer: `No, the Stop Feelings of Worthlessness subliminal is not a substitute for therapy or professional help. While it can assist in building a positive mindset, it is important to seek professional guidance if you are experiencing severe feelings of worthlessness or mental health concerns. A combination of the subliminal and professional support can potentially be beneficial in your journey towards self-acceptance and healing.`,
    open: false 
  },
  { 
    question: `How can I enhance the effectiveness of the Stop Feelings of Worthlessness subliminal?`, 
    answer: `To enhance the effectiveness of the subliminal, it is helpful to create a supportive and positive environment. Surround yourself with uplifting and encouraging people, practice self-care and self-compassion, and engage in activities that promote self-growth and self-discovery. Additionally, setting realistic goals and affirming your worth through positive self-talk can further enhance the impact of the subliminal messages.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Stop Feelings of Worthlessness subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Stop Feelings of Worthlessness subliminal. However, if you have any concerns about your mental health, it is important to seek professional help. The subliminal is intended to support your journey towards building a positive mindset, but it should not replace any necessary professional intervention.`,
    open: false 
  },
  { 
    question: `Will the Stop Feelings of Worthlessness subliminal make me overly confident or arrogant?`, 
    answer: `No, the goal of the Stop Feelings of Worthlessness subliminal is to help you develop a healthy self-worth and a positive mindset, not to promote arrogance or excessive confidence. It aims to support your journey towards recognizing your true value and abilities in a balanced and self-compassionate way.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>