<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're having trouble staying motivated with your diet or healthy eating habits, don't worry - you're not alone. That's where this subliminal comes in!"
    },
    {
      type: 'heading',
      text: 'Here are some situations where it can be especially helpful:'
    },
    {
      type: 'list',
      items: [
        "If you find it hard to resist unhealthy food cravings or emotional eating.",
        "If you struggle to stay motivated to stick to your diet plan or healthy eating habits.",
        "If you want to cultivate a healthier relationship with food and your body.",
        "If you're starting a new diet plan or healthy lifestyle and need support to stay motivated.",
        "If you want to feel more confident and empowered in your ability to make healthy choices."
      ]
    },
    {
      type: 'paragraph',
      text: "No matter what your situation is, this subliminal can help you shift your mindset and overcome your struggles with diet and healthy eating habits. As you listen regularly, you may start to feel more motivated, inspired, and in control of your relationship with food. Remember, building healthy eating habits takes time and effort, but you can do it!"
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Getting motivated to stick to a diet plan or make healthier food choices can be tough, but this subliminal can help you overcome those barriers and achieve your goals. Here are some tips to try before pressing play:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set your intention: Focus on your goals, whether it's to resist cravings, make healthier choices, or stay committed to your diet plan.",
        "Write it down: Document your intention in a journal using specific, positive language.",
        "Visualize success: Imagine achieving your goal and feeling confident in your ability to make healthy choices.",
        "Hold your intention in mind: Keep focused on your goals while listening to the subliminal affirmations."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, this subliminal is designed to help you shift your mindset and overcome your struggles with diet and healthy eating habits. As you listen regularly, you may start to feel more motivated, inspired, and in control of your relationship with food. So take a deep breath, set your intention, and let's get started!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on finishing the Diet Motivation subliminal! Now that you've taken this step towards improving your diet and healthy eating habits, it's important to reflect on your experience and continue your personal growth and transformation."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect on your experience: Consider writing down your thoughts and experiences in a journal.",
        "Track your progress: Use mood trackers to observe trends related to your diet and habits.",
        "Plan healthy meals: Create a meal plan with nutritious foods to fuel your body and goals.",
        "Take practical steps: Focus on actions like portion control, adequate sleep, and staying hydrated."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, building healthy eating habits takes time and effort, but you can do it! Stay focused on your goals, and take practical steps to improve your diet and overall well-being. You got this!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can I tell if the Diet Motivation subliminal is working for me?`, 
    answer: `It might take some time to see the effects of the subliminal on your motivation towards dieting, but you may notice changes in your behavior and attitude towards food. As you keep listening, pay attention to any positive changes or opportunities for growth that may arise in your life. You may also experience a greater sense of inner motivation, focus, and determination as your mindset becomes more aligned with healthy eating habits.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Diet Motivation subliminal?`, 
    answer: `Results can vary depending on the individual and their unique circumstances. Some people may experience positive changes right away, while others may require more time and consistent listening to fully reprogram their mindset towards healthy eating. We recommend listening to the subliminal daily and consistently for best results.`,
    open: false 
  },
  { 
    question: `How can I make the Diet Motivation subliminal more effective?`, 
    answer: `You can enhance the effectiveness of the subliminal by setting specific and achievable goals, creating a support system, and surrounding yourself with healthy food options. Practicing mindfulness and self-reflection can also help you identify triggers or patterns that may be hindering your motivation towards healthy eating. Combining the subliminal with practical and sustainable lifestyle changes, such as a balanced and nutritious diet and regular exercise, can also help you achieve your goals.`,
    open: false 
  },
  { 
    question: `Can the Diet Motivation subliminal replace a healthy diet and exercise?`, 
    answer: `No, the subliminal is intended to help you cultivate a positive and motivated mindset towards healthy eating habits. It is important to combine the subliminal with practical and sustainable lifestyle changes, such as a balanced and nutritious diet and regular exercise, to achieve long-term results.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Diet Motivation subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to this subliminal audio. However, if you have any concerns about your diet or health, please consult with your healthcare provider. It is important to note that the subliminal is intended to supplement healthy lifestyle changes, not replace them.`,
    open: false 
  },
  { 
    question: `Will listening to the Diet Motivation subliminal make me lose my appetite for food or my sense of enjoyment in eating?`, 
    answer: `No, the Diet Motivation subliminal is not intended to decrease your appetite or make you lose your sense of enjoyment in eating. It is designed to help you cultivate a positive and motivated mindset towards healthy eating habits. You should still be able to enjoy your food while making healthy choices that align with your goals.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>