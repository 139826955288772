import { render, staticRenderFns } from "./42-gray-hair-reversal-and-natural-color-restoration.vue?vue&type=template&id=fcc3693a&scoped=true&"
import script from "./42-gray-hair-reversal-and-natural-color-restoration.vue?vue&type=script&lang=js&"
export * from "./42-gray-hair-reversal-and-natural-color-restoration.vue?vue&type=script&lang=js&"
import style0 from "./42-gray-hair-reversal-and-natural-color-restoration.vue?vue&type=style&index=0&id=fcc3693a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fcc3693a",
  null
  
)

export default component.exports