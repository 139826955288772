<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Are you feeling overwhelmed or stressed about your finances? Don't worry - this subliminal is here to help you become financially organized and take control of your future."
    },
    {
      type: 'heading',
      text: 'Here are a few ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "By instilling positive money management habits and mindset.",
        "By reducing financial stress and providing a feeling of control over your money.",
        "By improving your financial decision-making skills and helping you make smarter choices.",
        "By promoting discipline and consistency in tracking your expenses and creating budgets.",
        "By encouraging long-term financial planning and setting achievable goals."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you may begin to notice positive shifts in your financial habits and mindset. It can help you create a solid foundation for your financial future and reduce the stress associated with money. Remember, achieving financial organization takes time and effort, but with persistence, you can achieve your goals and create a stress-free financial future."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Taking control of your finances and becoming financially organized is an important step towards a stress-free financial future. This subliminal is designed to support you in this journey. Before you start, consider these tips to enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Set clear financial goals: Determine what you want to achieve financially, whether it's saving for a specific goal, paying off debt, or creating an emergency fund.",
        "Create a budget: Establish a budget that aligns with your goals and helps you track your income and expenses.",
        "Simplify and organize your financial documents: Gather and organize all your financial documents in a way that makes it easy to access and monitor your financial situation.",
        "Visualize financial success: Visualize yourself achieving your financial goals and feeling a sense of peace and security."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly and implementing these preparation tips, you can cultivate a positive mindset towards money and take steps towards a financially organized life. Embrace this opportunity to improve your financial well-being and begin your journey towards a stress-free financial future."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Financially Organized subliminal! By investing time and energy into improving your financial organization, you're taking a significant step towards a stress-free and secure financial future. Now, it's important to reflect on your experience and take further action to enhance your financial management skills."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Assess your current financial situation: Take stock of your income, expenses, and any outstanding debts.",
        "Create a budget: Develop a detailed plan to allocate your income and set spending limits for different categories.",
        "Track your expenses: Use expense-tracking apps or spreadsheets to monitor where your money goes.",
        "Set financial goals: Define short-term and long-term goals to provide direction and motivation for your financial journey."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, financial organization is an ongoing process. By implementing these post-listening tips, you're well on your way to achieving financial stability and abundance. Stay committed to organizing your finances, and you'll create a solid foundation for a stress-free and prosperous financial future. Keep up the fantastic work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Financially Organized subliminal help me?`, 
    answer: `The Financially Organized subliminal is designed to reprogram your subconscious mind and promote positive habits and attitudes towards financial organization. By listening to the subliminal regularly, you can develop organization skills, improve financial decision-making, and reduce stress related to managing your finances. It can help you create a solid foundation for a stress-free financial future.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Financially Organized subliminal?`, 
    answer: `The time it takes to see results from the Financially Organized subliminal may vary from person to person. For some individuals, positive changes in financial organization habits and attitudes may occur relatively quickly. However, it is important to listen to the subliminal consistently and practice the principles of financial organization to maximize its impact. Over time, you may notice increased clarity, better financial planning, and a sense of control over your finances.`,
    open: false 
  },
  { 
    question: `How can I make the Financially Organized subliminal more effective?`, 
    answer: `To enhance the effectiveness of the Financially Organized subliminal, it is recommended to create a supportive environment for financial organization. This may include setting specific financial goals, creating a budget, and establishing a routine for managing your finances. Additionally, practicing mindfulness, self-reflection, and implementing practical organization strategies can further support your financial journey. Combining the subliminal with practical actions can increase its effectiveness.`,
    open: false 
  },
  { 
    question: `Can the Financially Organized subliminal replace financial planning and assistance from professionals?`, 
    answer: `No, the Financially Organized subliminal is a tool to help you develop a positive mindset and habits related to financial organization. It is not intended to replace the guidance and expertise of financial professionals. While the subliminal can assist you in creating a foundation for financial organization, it is recommended to seek professional advice for complex financial planning or investment decisions.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Financially Organized subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Financially Organized subliminal. However, it is important to note that the subliminal is not a substitute for sound financial planning or professional advice. It is designed to complement your efforts in becoming financially organized.`,
    open: false 
  },
  { 
    question: `Can the Financially Organized subliminal make me rich or guarantee financial success?`, 
    answer: `The Financially Organized subliminal is designed to help you develop positive habits, attitudes, and organization skills related to finances. While it can help create a solid foundation for financial success, it cannot guarantee wealth or specific financial outcomes. Financial success depends on various factors, including personal circumstances, financial knowledge, and individual actions.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>