import { render, staticRenderFns } from "./154-get-over-and-move-on-from-your-ex-and-past-relationships.vue?vue&type=template&id=d6cf4d6c&scoped=true&"
import script from "./154-get-over-and-move-on-from-your-ex-and-past-relationships.vue?vue&type=script&lang=js&"
export * from "./154-get-over-and-move-on-from-your-ex-and-past-relationships.vue?vue&type=script&lang=js&"
import style0 from "./154-get-over-and-move-on-from-your-ex-and-past-relationships.vue?vue&type=style&index=0&id=d6cf4d6c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6cf4d6c",
  null
  
)

export default component.exports