<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you desire a relationship with a wealthy lover who can support and enhance your life, this subliminal is designed to help you manifest that reality. By targeting your subconscious mind, it can attract and align you with the right partner."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can assist you:'
    },
    {
      type: 'list',
      items: [
        "Enhancing your self-worth and confidence to attract a wealthy partner.",
        "Opening yourself up to receiving love and abundance from a compatible, prosperous partner.",
        "Aligning your energy with the frequency of wealth and prosperity.",
        "Removing limiting beliefs and blocks around money and relationships.",
        "Helping you visualize and believe in the possibility of a fulfilling relationship with a wealthy lover."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember that manifestation requires not only the willingness to align with your desires but also taking inspired action in alignment with your intentions. By listening to this subliminal regularly, you can shift your mindset, beliefs, and energy to manifest a relationship with a wealthy lover who brings abundance and fulfillment into your life."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Attracting a fulfilling and abundant relationship with a wealthy partner requires aligning your thoughts, beliefs, and desires with your intention. This subliminal is designed to help you manifest the relationship you desire. Before you begin listening, take a moment to consider the following preparation tips:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your desires: Visualize the qualities and attributes you wish to experience in your ideal partner.",
        "Believe in abundance: Embrace the mindset of abundance and believe that there is an incredible wealth of love and support available to you.",
        "Release limiting beliefs: Identify and let go of any beliefs or fears that may be blocking the manifestation of a wealthy and fulfilling relationship.",
        "Stay open and receptive: Cultivate a mindset of openness, allowing the universe to bring you the partner who aligns with your desires.",
        "Hold your intention in mind: During the subliminal, focus your thoughts on your intention and maintain a positive and receptive attitude."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal regularly, you may notice shifts in your thoughts, feelings, and energy that align with attracting a wealthy partner. Embrace these changes and trust the process. Remember, your thoughts and beliefs have the power to shape your reality, so stay focused, maintain a positive mindset, and manifest the abundant and fulfilling relationship you desire."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Manifest Relationship with a Wealthy Lover subliminal session! You have taken a significant step towards attracting a fulfilling and abundant relationship, with a partner who can support and enhance your life. As you reflect on this experience, remember to stay open and receptive to the opportunities that may come your way."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your desires: Write down your ideal qualities and attributes in a partner.",
        "Work on self-improvement: Focus on personal growth and becoming the best version of yourself.",
        "Practice gratitude: Cultivate an attitude of appreciation for the opportunities and experiences in your life.",
        "Stay open to possibilities: Keep an open mind and be receptive to meeting new people and forming connections."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, attracting a wealthy and fulfilling relationship requires aligning your mindset and actions with your desires. Stay positive, believe in the possibility of finding your ideal partner, and remain open to the journey ahead. Love and abundance are on their way to you!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Manifest Relationship with a Wealthy Lover subliminal work?`, 
    answer: `The Manifest Relationship with a Wealthy Lover subliminal works by targeting your subconscious mind with positive affirmations and visualizations that align with your desire for a fulfilling and abundant relationship with a wealthy partner. These subliminal messages help to reprogram your mindset, thoughts, and beliefs about relationships and wealth, attracting the right partner into your life.`,
    open: false
  },
  { 
    question: `How long does it take to manifest a relationship with a wealthy lover?`, 
    answer: `The time it takes to manifest a relationship with a wealthy lover can vary from person to person. It depends on various factors, including your beliefs, energy, and alignment with your desire. It is important to maintain a positive mindset, trust the process, and continue listening to the subliminal consistently. Some people may experience results sooner, while others may require more time to see changes in their love life.`,
    open: false 
  },
  { 
    question: `Can the Manifest Relationship with a Wealthy Lover subliminal attract someone who truly loves me, rather than just their wealth?`, 
    answer: `Yes, the subliminal is designed to help you manifest a fulfilling and abundant relationship with a wealthy lover who genuinely loves and supports you. It focuses on attracting a partner who aligns with your desires, values, and can enhance your life on multiple levels, including emotional connection and compatibility.`,
    open: false 
  },
  { 
    question: `Do I need to take any action to manifest a relationship with a wealthy lover?`, 
    answer: `While the subliminal can help reprogram your mindset and attract a wealthy lover into your life, it is important to take inspired action as well. This means being open to opportunities, meeting new people, and actively engaging in activities and environments where you are likely to meet potential partners. Combining the subliminal with a proactive approach can optimize the manifestation process.`,
    open: false 
  },
  { 
    question: `Is it possible to manifest a relationship with a wealthy lover if I am not wealthy myself?`, 
    answer: `Yes, it is still possible to manifest a relationship with a wealthy lover even if you are not wealthy yourself. The subliminal focuses on attracting a partner who is financially abundant and can enhance your life in that aspect. However, it is essential to have a genuine connection, shared values, and open communication in any relationship, regardless of financial status.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects associated with listening to the Manifest Relationship with a Wealthy Lover subliminal?`, 
    answer: `No, there are no known risks or side effects associated with listening to the Manifest Relationship with a Wealthy Lover subliminal. However, it is important to approach your desire for a relationship with mindfulness, sincerity, and respect for yourself and others.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>