<template>
  <section>
    <div class="container1">
      <div class="ma-6">
        <h3 class="maintitle">How can we help you?</h3>
        <input @click="mouseover" class="search-class mt-2" v-model="question" placeholder="Let us know what your concern is ..." />
      </div>
      <div v-show="hover == true" class="ma-6">
        <div v-for="(item, index) in items" :key="index">
          <h5 @click="select(item)" class="questions">{{ item.title }}</h5>
        </div>
        <h5 v-show="items.length == 0" @click="dialog = true" class="questions">
          Please contact support for other concern.
        </h5>
      </div>
      <div class="ma-6">
        <h5 class="mt-2" style="text-align: left">{{ title }}</h5>
        <p v-html="description" style="text-align: left"></p>
      </div>
      <div class="col-10 col-lg-10 mx-auto" style="margin-top: -70px;">
        <div class="row my-4">
          <template v-for="(item, index) in menus">
            <div
              class="product-card col-12 col-sm-12 col-md-12 col-lg-4 pa-2"
              :key="index"
            >
              <div @click="goToRoute(item.route)" class="wrapper">
                <img :src="`${item.icon}`" />
                <h6>{{ item.title }}</h6>
                <p v-html="item.details"></p>
              </div>
            </div>
          </template>
        </div>
      </div>
      <Dialog :dialog="dialog" :width="800" title="" @closeDialog="closeDialog">
        <ContactUs @closeDialog="closeDialog" />
      </Dialog>
    </div>
  </section>
</template>

<script>
import { getMagus, getMagusSub } from "@/api/magusdetails";
import Dialog from "@/components/Dialog.vue";
import ContactUs from "@/components/Home/ContactUs.vue";

export default {
  components: {
    Dialog,
    ContactUs,
  },
  data: () => {
    return {
      getMagus,
      getMagusSub,
      dialog: false,

      hover: false,
      title: "",
      description: "",
      question: "",
      items: [],
      menus: [
        {
          icon: require("../../../assets/images/main/footer/support/1.png"),
          route: "/termsconditions",
          title: "Terms and Conditions",
          details:
            "Understand your rights and responsibilities by reading our comprehensive terms and conditions, ensuring a safe and secure platform experience.",
        },
        {
          icon: require("../../../assets/images/main/footer/support/2.png"),
          route: "/faqs",
          title: "Frequently Asked Questions",
          details:
            "Find fast and clear solutions to your questions in our FAQs, aimed to make your journey with us as smooth as possible.",
        },
        {
          icon: require("../../../assets/images/main/footer/support/3.png"),
          route: "/guide",
          title: "Subliminal General Guide",
          details:
            "Explore how subliminal messages can positively influence your life with our easy-to-understand guide, designed to unlock the power of your subconscious mind.",
        },
      ],
    };
  },
  watch: {
    question(value) {
      if (value != "") {
        this.hover = true;
        this.getSearch();
      } else {
        this.hover = false;
        this.getData();
      }

      if (this.items.length == 0) {
        this.title = "";
        this.description = "";
      }
    },
  },
  created() {
    this.getData();
  },
  methods: {
    closeDialog(data) {
      this.dialog = data;
    },
    select(data) {
      this.description = data.description;
      this.title = data.title;
      this.hover = false;
    },
    mouseover: function () {
      this.hover = true;
    },
    mouseleave: function () {
      this.hover = false;
    },
    goToRoute(route) {
      if (this.currentRoute != route) {
        this.$router.push(route).catch((err) => {});
        window.scrollTo(0, 0);
      }
    },
    getSearch() {
      const data = {
        title: "Contact Us",
        search: this.question,
      };
      this.getMagusSub(data).then((res) => {
        this.items = res.data.sub_info;
      });
    },
    getData() {
      const data = {
        title: "Contact Us",
      };
      this.getMagus(data).then((res) => {
        this.items = res.data[0].info;
      });
    },
  },
};
</script>

<style scoped>
  section:nth-of-type(1) > .container1 {
    padding-block: 2em;
  }
  h1,
  h5,
  h6:nth-of-type(2) {
    color: var(--ma-tufts-blue);
  }
  h3 {
    color: var(--ma-tufts-blue);
    line-height: 1.35em;
  }
  p {
    font-family: "Montserrat-Regular";
    padding: 20px;
  }
  .questions {
    color: var(--ma-black-olive);
    line-height: 1.35em;
    font-family: "Montserrat-Regular" !important;
    border-bottom: 0.5px solid var(--ma-black-olive);
    padding-bottom: 0.2em;
    margin-bottom: 1em;
    cursor: pointer;
    text-align: left;
  }
  .wrapper {
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding: 2em;
    height: 20em;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .maintitle {
    font-size: 40px !important;
  }
  img {
    width: 7em;
    margin-bottom: 0.4em;
    margin-left: auto;
    margin-right: auto; 
  }
  .search-class {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid white;
    border-radius: 5px;
    background-color: white;
    background-image: url("~@/assets/images/logo/search.png");
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    padding: 5px 40px;
    background-repeat: no-repeat;
    background-size: 1.2em;
    background-position: 10px;
  }

@media screen and (min-width: 40rem) {
  section:nth-of-type(1) > .container1 {
    @media screen and (min-width: 1400px) {
      padding: 8em;
      padding-top: 3em;
    }

    @media screen and (min-width: 1200px) and (max-width: 1400px) {
      padding: 5em;
    }
  }
}
</style>
