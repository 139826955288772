<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you feel like you could use a little more luck in your life, this subliminal is here to help. By working with your subconscious mind, it can help you shift your mindset and energy to attract more luck and opportunities."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can benefit you:'
    },
    {
      type: 'list',
      items: [
        "Increasing your positive mindset and attracting more positive experiences.",
        "Boosting your confidence and belief in your own capabilities, making you more likely to seize opportunities.",
        "Enhancing your intuition and ability to recognize and act on lucky breaks.",
        "Helping you cultivate an abundance mindset, attracting wealth and prosperity.",
        "Allowing you to let go of limiting beliefs and patterns that may be blocking luck from flowing into your life."
      ]
    },
    {
      type: 'paragraph',
      text: "By regularly listening to this subliminal, you can reprogram your subconscious mind to align with the vibrations of luck and abundance. As a result, you may begin to notice more synchronicities, positive experiences, and opportunities coming your way. Remember, the key is to stay open-minded and take inspired action when opportunities present themselves."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Are you ready to manifest more luck and opportunities into your life? This subliminal is designed to help shift your mindset and energy to attract positive experiences. Before you listen, here are a few tips to enhance your manifestation journey:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Clarify your intentions: Take a moment to clearly define what luck and opportunities mean to you. What specific areas of your life do you want to attract more luck in?",
        "Cultivate gratitude: Start by appreciating and acknowledging the blessings and opportunities you already have in your life. This positive mindset can help attract even more luck.",
        "Practice visualization: See yourself already living the lucky life you desire. Visualize yourself encountering fortunate situations and embrace the happiness and fulfillment they bring.",
        "Stay open and receptive: Be open to receiving luck and opportunities in unexpected ways. Trust that the universe will guide you towards the right path."
      ]
    },
    {
      type: 'paragraph',
      text: "By embracing a positive mindset and aligning your energy with the frequencies of luck and abundance, you are priming yourself for positive experiences. Remember, manifestation works best when coupled with action—be proactive, remain open to opportunities, and embrace the journey ahead. Let's harness the power of attraction together!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Attract Luck subliminal! By working on shifting your mindset and energy, you've set the stage to attract more luck and opportunities into your life. It's important to reflect on your experiences and continue to cultivate a positive and open mindset."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice gratitude: Take time each day to express appreciation for the positive aspects of your life.",
        "Visualize success: Imagine yourself achieving your goals and visualize the positive outcomes you desire.",
        "Embrace positive affirmations: Repeat empowering statements that reinforce abundance and luck in your life.",
        "Be open to opportunities: Stay aware and seize the chances that come your way, even if they seem unexpected."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, attracting luck is a process that requires an open and positive mindset. Keep practicing gratitude, visualizing success, and embracing growth. Stay open to opportunities and remain aligned with the energy of abundance. The universe is ready to bring more luck into your life. Embrace it!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How does the Attract Luck subliminal work?`, 
    answer: `The Attract Luck subliminal works by sending positive affirmations directly to your subconscious mind, helping to shift your mindset and energy towards attracting luck and opportunities. By consistently listening to the subliminal, you can reprogram your subconscious beliefs and align your thoughts with positivity, abundance, and opportunities for luck.`,
    open: false
  },
  { 
    question: `How long should I listen to the Attract Luck subliminal to see results?`, 
    answer: `The time it takes to see results from the Attract Luck subliminal can vary from person to person. Some individuals may notice changes or opportunities for luck shortly after starting to listen, while others may require more time and consistent listening. We recommend listening to the subliminal daily for at least a few weeks to allow the positive affirmations to take effect.`,
    open: false 
  },
  { 
    question: `Can the Attract Luck subliminal guarantee specific outcomes or winning in games of chance?`, 
    answer: `No, the Attract Luck subliminal is not a guarantee of specific outcomes or winning in games of chance. It is designed to help shift your mindset and energy towards attracting luck and opportunities, but individual results may vary. How luck manifests in your life can be influenced by various factors, including your actions, choices, and external circumstances.`,
    open: false 
  },
  { 
    question: `How can I enhance the effectiveness of the Attract Luck subliminal?`, 
    answer: `You can enhance the effectiveness of the Attract Luck subliminal by combining it with positive visualization, gratitude practices, and taking inspired action towards your goals. Maintaining a positive mindset and open attitude towards opportunities can also be beneficial in attracting luck. Remember that luck is a combination of preparation and opportunity, so be proactive and open to new experiences.`,
    open: false 
  },
  { 
    question: `Is there any risk associated with listening to the Attract Luck subliminal?`, 
    answer: `No, there are no known risks associated with listening to the Attract Luck subliminal. However, if you have any concerns or pre-existing mental health conditions, please consult with a healthcare professional before incorporating it into your routine. It is important to note that the subliminal is intended to supplement your mindset and energy towards attracting luck, not replace effort and action in pursuing your goals.`,
    open: false 
  },
  { 
    question: `Can the Attract Luck subliminal work for everyone?`, 
    answer: `The Attract Luck subliminal is designed to be effective for most individuals, as it targets the subconscious mind to create positive changes in mindset and energy. However, individual results may vary based on factors such as mindset, beliefs, and external circumstances. It is important to approach the subliminal with an open mind and take inspired action towards your goals to optimize its effectiveness.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>