<template>
  <div>
    <ForgotPassword />
  </div>
</template>

<script>
import { checkUrl } from '@/utils'
import ForgotPassword from '@/views/Main/ForgotPassword'
export default {
  components: {
    ForgotPassword
  },
}
</script>
