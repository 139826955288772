<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you find yourself unable to resist the allure of binge-watching, don't worry - you're not alone. This subliminal is designed to help you break free from the habit and regain control over your screen time and productivity."
    },
    {
      type: 'heading',
      text: 'Here is how this subliminal can help:'
    },
    {
      type: 'list',
      items: [
        "It can reduce the cravings and urges to binge-watch, allowing you to focus on other activities.",
        "It can help you develop healthier screen time habits and establish boundaries with your entertainment consumption.",
        "It can increase your motivation and discipline to resist the temptation of binge-watching.",
        "It can support you in finding alternative activities that are more productive and fulfilling.",
        "It can boost your self-control and willpower to make intentional choices regarding your screen time."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can rewire your subconscious mind to prioritize productivity and healthy habits over excessive screen time. Over time, you may notice a decrease in your desire to binge-watch and an increase in your ability to manage your time effectively. Remember, breaking habits takes time and effort, but with consistent use of this subliminal, you can regain control and create a more balanced lifestyle."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Breaking free from the habit of binge-watching requires motivation and determination. This subliminal is designed to help you regain control over your screen time and increase your productivity. Before you start listening, consider these tips to prepare yourself:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge the habit: Recognize that binge-watching has become a habit that you want to change.",
        "Set your intention: Determine why you want to reduce binge-watching and how it will positively impact your life.",
        "Create alternative activities: Plan other activities to fill your time, such as reading, exercising, or spending time with loved ones.",
        "Remove distractions: Minimize access to streaming platforms or set limits on screen time to avoid falling back into the habit."
      ]
    },
    {
      type: 'paragraph',
      text: "As you listen to this subliminal, remember that change takes time and effort. With consistent and focused listening, you can rewire your brain and develop healthier habits around screen time. Embrace this opportunity to regain control over your life and make more mindful choices. Let's start this journey together!"
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Binge Watching Habit Removal subliminal session! You've taken an important step towards regaining control over your screen time and boosting your productivity. Now that you've finished listening, it's time to reflect on your experience and implement positive changes in your habits and routine."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Assess your viewing habits: Reflect on how binge-watching affects your daily life and goals.",
        "Set screen time limits: Determine specific time slots for leisure screen time and stick to them.",
        "Find alternative activities: Discover new hobbies or engage in activities that bring you joy and fulfillment.",
        "Create a schedule: Plan your day with designated time for work, leisure, and self-care to help maintain balance."
      ]
    },
    {
      type: 'paragraph',
      text: "Breaking the binge-watching habit requires commitment and perseverance, but you have the power to regain control over your screen time. Embrace the positive changes ahead and stay focused on your goals. Remember, each step you take towards limiting binge-watching brings you closer to a more balanced and fulfilling life. Keep up the great work!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Binge Watching Habit Removal subliminal help me?`, 
    answer: `The Binge Watching Habit Removal subliminal is designed to reprogram your subconscious mind and help you break free from the habit of binge-watching. It uses positive affirmations to promote self-discipline, reduce cravings for excessive screen time, and increase your motivation to engage in other activities. By listening to the subliminal regularly, you can gradually regain control over your screen time and become more productive.`,
    open: false
  },
  { 
    question: `Will the Binge Watching Habit Removal subliminal completely eliminate my desire to binge-watch?`, 
    answer: `While the Binge Watching Habit Removal subliminal can help reduce cravings for excessive screen time, it cannot guarantee that your desire to binge-watch will be completely eliminated. The subliminal aims to rewire your subconscious mind and promote healthier habits, but individual results may vary. Consistent listening and a conscious effort to engage in other activities can contribute to minimizing the urge to binge-watch.`,
    open: false 
  },
  { 
    question: `How long does it take to see results with the Binge Watching Habit Removal subliminal?`, 
    answer: `The time it takes to see results with the Binge Watching Habit Removal subliminal can vary from person to person. Some individuals may notice positive changes in their screen time habits within a few weeks of consistent listening, while others may require more time. To maximize the effectiveness of the subliminal, it is recommended to listen daily and stay committed to reducing your binge-watching habit.`,
    open: false 
  },
  { 
    question: `Can I listen to the Binge Watching Habit Removal subliminal while watching TV?`, 
    answer: `It is generally recommended to listen to the Binge Watching Habit Removal subliminal when you are not actively engaged in watching TV or other screens. This allows your subconscious mind to absorb the positive affirmations without distractions. However, if you find it more convenient to listen while watching TV, you can still benefit from the subliminal's messages, although the effectiveness may vary.`,
    open: false 
  },
  { 
    question: `Are there any side effects of listening to the Binge Watching Habit Removal subliminal?`, 
    answer: `There are no known side effects associated with listening to the Binge Watching Habit Removal subliminal. However, if you have any concerns about your screen time habits or mental health, it is recommended to consult with a healthcare professional. The subliminal is designed to support your efforts in breaking free from binge-watching, but it should not replace professional advice or treatment if needed.`,
    open: false 
  },
  { 
    question: `Can the Binge Watching Habit Removal subliminal be used for other addictive behaviors?`, 
    answer: `The Binge Watching Habit Removal subliminal is specifically designed to address the habit of binge-watching. While the affirmations may be beneficial in promoting self-discipline and reducing cravings, it is not intended as a universal solution for all addictive behaviors. For other addictive habits, it is recommended to explore targeted subliminals or seek professional help if necessary.`,
    open: false 
  }
]
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>