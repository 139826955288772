<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you're struggling with anger management issues and find it challenging to control your emotions, this subliminal can help you regain control and create a more peaceful and harmonious life."
    },
    {
      type: 'heading',
      text: 'Here are the key benefits of using this subliminal:'
    },
    {
      type: 'list',
      items: [
        "Reducing feelings of anger and frustration in your daily life.",
        "Developing healthier coping mechanisms for dealing with anger triggers.",
        "Becoming more self-aware and mindful of your emotions.",
        "Improving communication skills to express your feelings in a healthier way.",
        "Building resilience and patience to handle challenging situations.",
        "Creating a more positive and harmonious environment in your relationships."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can reprogram your subconscious mind to respond differently to anger triggers and develop more effective strategies for managing your emotions. Remember, change takes time and effort, but with commitment and consistency, you can overcome your anger management issues and live a more peaceful life."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Addressing anger management issues is an important step towards a more peaceful and harmonious life. This subliminal is designed to support you in overcoming these challenges and regaining control over your emotions. Before you start listening, here are some suggestions to consider:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your triggers: Reflect on situations or circumstances that tend to provoke anger in you. Awareness is the first step towards change.",
        "Identify healthy coping mechanisms: Explore alternative ways to manage and express your emotions, such as deep breathing, exercise, or talking to a trusted friend.",
        "Create a calm environment: Find a quiet and comfortable space where you can listen to the subliminal undisturbed.",
        "Focus on your intention: As you listen, keep your intention to overcome anger management issues at the forefront of your mind, allowing the subliminal affirmations to work on a subconscious level."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal is a tool to support your journey towards emotional balance and a more peaceful existence. With regular listening and a commitment to exploring healthy coping strategies, you can gradually gain control over your anger and cultivate a harmonious life. Embrace this opportunity for personal growth, set your intention, and let's begin the process of change together."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Anger Management Issues Removal subliminal! By actively working to overcome your anger management issues, you've taken a significant step towards regaining control over your emotions and creating a more peaceful and harmonious life. Now, it's time to reflect on your experience and continue your personal growth and transformation."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Reflect and identify triggers: Take time to identify the situations or behaviors that trigger your anger.",
        "Practice deep breathing and relaxation techniques: These techniques can help you calm down and regain control in moments of anger.",
        "Communicate effectively: Work on improving your communication skills to express your emotions in a healthy and constructive way.",
        "Seek support if needed: Consider reaching out to a therapist or support group to help you further in managing your anger.",
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming anger management issues is a journey that requires dedication and self-reflection. Be patient with yourself as you work towards creating a more peaceful and harmonious life. You have the power to control your emotions and respond to situations in a healthier way. Keep moving forward!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Anger Management Issues Removal subliminal help me?`, 
    answer: `The Anger Management Issues Removal subliminal is designed to address deep-seated anger issues and assist in the process of healing and emotional regulation. By listening to the audio regularly, you can reprogram your subconscious mind to respond more calmly and rationally to triggers, allowing you to regain control over your emotions and experience a more peaceful and harmonious life.`,
    open: false
  },
  { 
    question: `How long will it take for the subliminal to help me manage my anger better?`, 
    answer: `The time it takes to see improvements in anger management can vary from person to person. Consistent listening to the Anger Management Issues Removal subliminal can help rewire your subconscious mind over time. It is important to be patient and practice self-awareness to fully benefit from the positive affirmations and suggestions embedded in the audio.`,
    open: false 
  },
  { 
    question: `Can this subliminal completely remove my anger issues?`, 
    answer: `While the Anger Management Issues Removal subliminal aims to assist in managing and regulating anger, the complete removal of anger issues may require additional support such as therapy or counseling. The subliminal can be a helpful tool in conjunction with professional help to aid in the healing and transformation process.`,
    open: false 
  },
  { 
    question: `Is it necessary to continue listening to the subliminal even after my anger issues improve?`, 
    answer: `Once you notice improvements in managing your anger, it is up to you to decide whether to continue listening to the subliminal. Some individuals find it beneficial to continue using the subliminal as a form of maintenance and reinforcement, while others may choose to reduce or stop listening once their anger issues are better managed. Trust your intuition and assess what is most helpful for your personal growth and ongoing well-being.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of using the Anger Management Issues Removal subliminal?`, 
    answer: `No, there are no known risks or side effects associated with using the Anger Management Issues Removal subliminal. However, if you have any concerns about your anger issues or mental health, it is always recommended to seek professional guidance from a therapist or counselor.`,
    open: false 
  },
  { 
    question: `Can I listen to the subliminal while doing other activities?`, 
    answer: `To fully benefit from the Anger Management Issues Removal subliminal, it is recommended to listen to the audio in a relaxed and focused state, free from distractions. Find a quiet and comfortable space where you can dedicate your attention to the subliminal. However, if you find it more convenient to listen while engaging in other activities, you can still experience some positive effects.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>