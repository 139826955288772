<template>
  <div style="overflow: hidden">
    <v-overlay :value="overlay" style="z-index: 1000000">
      <v-img
        src="/logo copy.png"
        class="blink"
        style="width: 100px; height: 120px"
      ></v-img>
    </v-overlay>
  </div>
</template>

<script>
export default {
  computed: {
    overlay() {
      return this.$store.state.audio.loading;
    },
  },
};
</script>
