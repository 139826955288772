<template>
  <div>
    <Table title="Categories" itemkey="name" :loading="loading" :items="items" :headers="headers" :select="selected"
      :total="total" @action="getAction" @updateLimitPage="updateLimitPage" @updateSearch="updateSearch"
      @updateSort="updateSort" />

    <addDialog v-if="dialogType == 'add'" :data="data" :dialog="dialog" @closeDialog="closeDialog" />

    <editDialog v-if="dialogType == 'update'" :data="data" :dialog="dialog" @closeDialog="closeDialog" />

    <deleteDialog v-if="dialogType == 'delete' || dialogType == 'deleteall'" :data="data" :dialog="dialog"
      :selected="selected" @selected="getSelected" @closeDialog="closeDialog" />
  </div>
</template>

<script>
import { getCategoryFilters, updateToggle } from '@/api/category'
import { getMessage } from '@/utils'

import Card from '@/components/Card.vue'
import Table from '@/components/Admin/Table.vue'

import addDialog from './components/addDialog.vue'
import editDialog from './components/editDialog.vue'
import deleteDialog from './components/deleteDialog.vue'

export default {
  components: {
    Card,
    Table,
    addDialog,
    editDialog,
    deleteDialog
  },
  data: () => {
    return {
      getCategoryFilters,
      getMessage, 
      updateToggle,

      data: {},
      dialog: false,
      dialogType: null,

      items: [],
      selected: [],
      loading: true,

      headers: [
        { text: 'Image', value: 'image', sortable: false },
        { text: 'Name', value: 'name', sortable: false },
        { text: 'Description', value: 'description', sortable: false },
        { text: 'Action', value: 'update_delete', sortable: false, width: '180' },
      ],

      sort: null,
      search: null,

      page: 1,
      total: 0,
      limit: 10,
    }
  },
  created() {
    this.getData()
  },
  watch: {
    sort() {
      this.getData()
    },
    search() {
      this.getData()
    }
  },
  methods: {
    getSelected(data) {
      this.selected = data
    },
    closeDialog() {
      this.dialog = false
      this.dialogType = null
      this.getData()
    },
    getAction(params) {
      this.dialog = true
      this.data = params.data
      this.dialogType = params.action
      this.selected = params.selected

      if (params.action == 'toggle') {
        if(this.data.toggle==true){
          this.isVisible=0;
        }else{
          this.isVisible=1;
        }
        const data = {
          is_visible: this.isVisible,
          id: this.data.id,
        }
        this.updateToggle(data).then((res) => {
          this.getMessage(this, res)
          this.getData()
        })
      }
    },
    getData() {
      this.loading = true
      const data = {
        sort: this.sort,
        page: this.page,
        limit: this.limit,
        search: this.search,
      }

      this.getCategoryFilters(data).then((res) => {
        const { category, total_pages } = res

        this.total = total_pages
        this.items = category

        this.loading = false
      })
    },
    updateLimitPage(data) {
      const { page, limit } = data
      this.page = page
      this.limit = limit

      this.getData()
    },
    updateSearch(data) {
      this.search = data
      this.getData()
    },
    updateSort(data) {
      this.sort = data
      this.getData()
    }
  }
}
</script>
