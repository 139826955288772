<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Dealing with a fear of closed spaces or claustrophobia can be extremely challenging, but it doesn't have to control your life. This subliminal is designed to help you overcome your fears and regain a sense of control and freedom."
    },
    {
      type: 'heading',
      text: 'By using this subliminal, you can:'
    },
    {
      type: 'list',
      items: [
        "Gradually reduce feelings of anxiety and panic when faced with closed spaces.",
        "Replace fear with a sense of calmness and relaxation in confined environments.",
        "Reprogram your subconscious mind to associate closed spaces with safety and comfort.",
        "Build confidence in your ability to handle and navigate closed spaces.",
        "Regain a sense of freedom and control in your life by conquering your fear."
      ]
    },
    {
      type: 'paragraph',
      text: "Listening to this subliminal regularly can help rewire your thought patterns and beliefs surrounding closed spaces, allowing you to approach them with a newfound sense of calmness and ease. Remember, overcoming fears takes time and practice, but with this subliminal as a tool, you can make progress towards living a life free from the limitations of claustrophobia."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Overcoming a fear of closed spaces can be a transformative journey towards regaining control and freedom in your life. This subliminal is designed to support you in facing and conquering your claustrophobia. Before you begin, here are some suggestions to help you prepare:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your fear: Recognize and accept your fear of closed spaces as a valid feeling. This is the first step towards overcoming it.",
        "Start small: Begin by gradually exposing yourself to slightly confined spaces, such as small rooms or even closed doors, to build your tolerance.",
        "Practice relaxation techniques: Explore deep breathing, progressive muscle relaxation, or meditation to manage anxiety and promote calmness.",
        "Visualize success: Imagine feeling calm, confident, and in control while in closed spaces. Visualize yourself overcoming your fear.",
        "Remind yourself of your intentions: Keep your desire to overcome claustrophobia in mind while listening to the subliminal affirmations."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal is a tool to assist you on your journey to conquering your fear of closed spaces and claustrophobia. As you listen regularly and work on gradually exposing yourself to confined environments, you may notice a steady decrease in anxiety and an increase in your ability to remain calm and confident. Take a deep breath, embrace your courage, and let's embark on this empowering journey together."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Fear of Closed Spaces and Claustrophobia Removal subliminal! By taking this brave step to overcome your fear, you're on your way to regaining control of your life and enjoying the freedom that comes with it. As you reflect on your experience, remember that you have the power to conquer your fears and live life to the fullest."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Take small steps: Start by gradually exposing yourself to closed spaces, taking it one step at a time.",
        "Practice breathing exercises: Deep, slow breaths can help calm anxiety in the face of triggering situations.",
        "Seek support: Consider reaching out to a therapist or joining a support group to connect with others who share similar challenges.",
        "Challenge negative thoughts: Replace negative thoughts with positive affirmations and visualizations of successfully overcoming claustrophobic situations."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, you are capable of overcoming your fear of closed spaces and claustrophobia. With time, patience, and persistence, you can reclaim your freedom and live a life free from the constraints of fear. Embrace each step forward, and trust yourself on this journey to relief and liberation!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Fear of Closed Spaces and Claustrophobia Removal subliminal help me?`, 
    answer: `The Fear of Closed Spaces and Claustrophobia Removal subliminal is designed to help you overcome your fear of closed spaces and claustrophobia by reprogramming your subconscious mind. The subliminal messages work to replace negative thoughts and emotions associated with closed spaces with positive and empowering beliefs. Over time, listening to the subliminal can help you feel more calm, relaxed, and in control when faced with enclosed environments.`,
    open: false
  },
  { 
    question: `How long does it take to see results from the Fear of Closed Spaces and Claustrophobia Removal subliminal?`, 
    answer: `Results may vary depending on the individual and the severity of their fear or claustrophobia. Some people may notice improvements in their mindset and ability to cope with closed spaces after a few listens, while others may require more time and consistency. We recommend listening to the subliminal regularly and consistently for best results.`,
    open: false 
  },
  { 
    question: `Can I use the Fear of Closed Spaces and Claustrophobia Removal subliminal along with therapy or other treatments?`, 
    answer: `Yes, the Fear of Closed Spaces and Claustrophobia Removal subliminal can be used in conjunction with therapy or other treatments for fear or claustrophobia. The subliminal messages can support and enhance the effectiveness of traditional therapeutic approaches. However, if you are currently undergoing therapy or treatment, we recommend consulting with your healthcare professional to ensure that incorporating the subliminal aligns with your individual needs and goals.`,
    open: false 
  },
  { 
    question: `Is it safe to listen to the Fear of Closed Spaces and Claustrophobia Removal subliminal?`, 
    answer: `Yes, it is safe to listen to the Fear of Closed Spaces and Claustrophobia Removal subliminal. The audio is designed to deliver positive affirmations and suggestions to your subconscious mind, helping you create new, empowering beliefs and attitudes towards closed spaces. However, if you have any concerns or pre-existing medical conditions, it is always a good idea to consult with your healthcare provider before starting any new therapeutic practices.`,
    open: false 
  },
  { 
    question: `Can the Fear of Closed Spaces and Claustrophobia Removal subliminal work for everyone?`, 
    answer: `The Fear of Closed Spaces and Claustrophobia Removal subliminal is designed to help individuals overcome their fear of closed spaces, but results may vary depending on the individual and their unique circumstances. While many people have experienced positive changes and reduced anxiety through listening to the subliminal, it may not work the same way for everyone. It is important to approach the subliminal with an open mind and a willingness to participate actively in your healing journey.`,
    open: false 
  },
  { 
    question: `Will listening to the Fear of Closed Spaces and Claustrophobia Removal subliminal make me immediately comfortable in closed spaces?`, 
    answer: `The Fear of Closed Spaces and Claustrophobia Removal subliminal is designed to help you overcome your fear of closed spaces over time, but it is not a magical solution. The subliminal works by gradually shifting your subconscious beliefs and thought patterns towards empowerment and relaxation when facing enclosed environments. It may take some time and consistent listening to experience noticeable improvements. Remember to be patient with yourself as you work towards your healing goals.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>