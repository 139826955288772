import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { user } from "./modules/user";
import { test } from "./modules/test";
import { mood } from "./modules/mood";
import { admin } from "./modules/admin";
import { audio } from "./modules/audio";
import { track } from "./modules/track";
import { screen } from "./modules/screen";
import { recent } from "./modules/recent";
import { featured } from "./modules/featured";
import { discover } from "./modules/discover";
import { category } from "./modules/category";
import { playlist } from "./modules/playlist";
import { subliminal } from "./modules/subliminal";
import { subscription } from "./modules/subscription";
import { recommendation } from "./modules/recommendation";
import { information } from "./modules/information";
import { favorite } from "./modules/favorite";

import { newaudio } from "./modules/newaudio";

const store = new Vuex.Store({
  modules: {
    user,
    test,
    mood,
    admin,
    audio,
    track,
    screen,
    recent,
    featured,
    discover,
    category,
    playlist,
    subliminal,
    subscription,
    recommendation,
    information,
    favorite,
    newaudio,
  },
});

export default store;
