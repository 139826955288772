<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Overcoming sex addiction and healing from its harmful effects is a challenging process, but with the help of this subliminal, you can make significant progress towards a healthier and more fulfilling life."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support you:'
    },
    {
      type: 'list',
      items: [
        "Assisting in breaking free from addictive behaviors and patterns related to sex addiction.",
        "Reducing cravings and urges to engage in harmful sexual behaviors.",
        "Promoting self-control, discipline, and healthy coping mechanisms.",
        "Boosting self-esteem and self-worth, allowing you to cultivate a positive self-image.",
        "Supporting emotional healing and addressing any underlying trauma or issues contributing to sex addiction."
      ]
    },
    {
      type: 'paragraph',
      text: "By listening to this subliminal regularly, you can rewire your subconscious mind and create new thought patterns that align with your desire to lead a healthier and more fulfilling life. It's important to remember that healing takes time, and setbacks may occur along the way. Stay committed to your journey and trust in your ability to overcome sex addiction."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Healing from sex addiction and reclaiming your life is a courageous journey. This subliminal is designed to support you in overcoming addiction and cultivating a healthier and more fulfilling life. Before you begin, here are some strategies to help enhance your experience:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your desire for change: Recognize and embrace your motivation and commitment to healing and leaving the harmful effects of sex addiction behind.",
        "Seek support: Consider reaching out to a therapist, support group, or trusted individual who can provide guidance and encouragement throughout your journey.",
        "Create a safe space: Take steps to create an environment that promotes healing, free from triggers and unhealthy influences.",
        "Focus on positive affirmations: While listening to the subliminal affirmations, maintain a mindset focused on your worthiness, self-love, and the possibilities of a life free from addiction."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal is a tool to support your healing process. As you engage with the subliminal regularly, alongside therapy or other forms of support, you may experience a shift in mindset, increased self-awareness, and a path towards healthier choices. Embrace this opportunity for transformation and reclaim your life from the grips of addiction."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Sex Addiction Healing and Removal subliminal session! By taking this step towards overcoming your sex addiction, you are on the path to reclaiming your life and experiencing true fulfillment. Now, it's important to reflect on your journey and continue your healing process."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Seek professional help: Consider reaching out to a therapist or counselor who specializes in addiction to provide you with guidance and support.",
        "Build a support system: Surround yourself with understanding and nonjudgmental individuals who can offer you encouragement and accountability.",
        "Practice self-care: Focus on activities that promote self-care and self-love, such as exercise, meditation, and spending time in nature.",
        "Implement healthy coping mechanisms: Develop healthy ways to manage stress and emotions, such as journaling, deep breathing, or engaging in hobbies you enjoy.",
        "Avoid triggers: Identify and minimize triggers that may lead to relapses, whether it be certain people, places, or behaviors.",
        "Celebrate accomplishments: Acknowledge and celebrate your progress along the way, no matter how small or big."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, overcoming sex addiction is a journey that requires patience, self-compassion, and support. The road may have its ups and downs, but know that you have the strength and resilience to turn your life around. Keep moving forward, and embrace the healthier, more fulfilling life that awaits you."
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Sex Addiction Healing and Removal subliminal help me?`, 
    answer: `The Sex Addiction Healing and Removal subliminal is designed to assist individuals in overcoming sex addiction and removing its harmful effects from their lives. By listening to the subliminal regularly, the positive affirmations can help reprogram your subconscious mind, promoting healthier thoughts, behaviors, and attitudes towards sex. It aims to empower you to let go of addictive patterns and establish a healthier and more fulfilling life.`,
    open: false
  },
  { 
    question: `How long does it take to recover from sex addiction using the subliminal?`, 
    answer: `Recovery time from sex addiction can vary from person to person. The Sex Addiction Healing and Removal subliminal can be a useful tool in the recovery process, but it is important to remember that it is not a quick fix. Results may take time and require consistent listening, along with professional help and support, to achieve lasting change and healing.`,
    open: false 
  },
  { 
    question: `Can the Sex Addiction Healing and Removal subliminal replace therapy or professional help?`, 
    answer: `The subliminal is designed to complement therapy and professional help, not replace them. It can be used as a supportive tool to reinforce positive changes, but it is important to seek professional guidance if you are struggling with sex addiction. A comprehensive approach, combining therapy, support groups, and the subliminal, can be most effective in overcoming sex addiction.`,
    open: false 
  },
  { 
    question: `Are there any risks or side effects of listening to the Sex Addiction Healing and Removal subliminal?`, 
    answer: `There are no known risks or side effects associated with listening to this subliminal audio. However, if you have concerns about your mental health or addiction, it is important to consult with a qualified healthcare professional who specializes in addiction or mental health.`,
    open: false 
  },
  { 
    question: `Will the subliminal completely remove all my sexual desires?`, 
    answer: `No, the goal of the Sex Addiction Healing and Removal subliminal is not to remove healthy and consensual sexual desires. It aims to help individuals overcome unhealthy and addictive patterns associated with sex addiction and establish healthier thoughts and behaviors towards sex. It is about achieving a balanced and fulfilling approach to sexuality.`,
    open: false 
  },
  { 
    question: `How can I make the subliminal more effective in my recovery from sex addiction?`, 
    answer: `To enhance the effectiveness of the subliminal and support your recovery from sex addiction, it is important to seek professional help and join support groups specialized in sex addiction. Developing a comprehensive plan that includes therapy, self-reflection, healthy coping mechanisms, and a support network can greatly contribute to your healing journey. Consistent listening to the subliminal can reinforce positive changes over time.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>