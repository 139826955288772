<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "Healing and recovering from the effects of anorexia is a journey that requires support, understanding, and a commitment to self-care. This subliminal is designed to assist you in cultivating a healthy relationship with food and your body, helping you regain balance and well-being."
    },
    {
      type: 'heading',
      text: 'Benefits of using this subliminal include:'
    },
    {
      type: 'list',
      items: [
        "Rebuilding a positive and healthy relationship with food.",
        "Developing self-compassion and self-love towards your body.",
        "Letting go of harmful thoughts and beliefs related to food, weight, and body image.",
        "Increasing feelings of self-worth and body acceptance.",
        "Creating a support system and implementing healthy coping mechanisms.",
        "Breaking free from the cycle of restrictive and unhealthy eating habits."
      ]
    },
    {
      type: 'paragraph',
      text: "This subliminal can help rewire your subconscious mind, enabling you to let go of destructive thought patterns and embrace a healthier perspective. With regular listening, you can begin to heal and recover, rebuilding a positive relationship with food and your body. Remember, healing takes time, but with dedication and support, you can achieve a balanced and fulfilling life."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Recovering from the effects of anorexia can be a challenging and deeply personal journey, but this subliminal is here to support you every step of the way. Before you start, here are some suggestions to help you get the most out of your healing process:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Create a safe space: Find a quiet, comfortable spot where you can relax and fully devote your attention to the subliminal messages.",
        "Focus on self-care: Prioritize self-care in your daily routine, including nourishing your body with balanced meals and engaging in activities that bring you joy and peace.",
        "Set positive intentions: Set clear and positive intentions for your healing journey, focusing on restoring a healthy relationship with food and your body.",
        "Practice self-compassion: Be gentle with yourself and practice self-compassion as you navigate the healing process."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, healing from anorexia is a unique and personal experience. This subliminal aims to provide support and encouragement as you embark on your journey towards a healthier relationship with food and your body. Embrace this opportunity for growth, believe in your ability to heal, and let the reconnection with your true self begin."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Anorexia Healing subliminal session! By taking this step towards healing and recovery, you've shown immense strength and determination. It's important to reflect on your experience and continue nurturing a healthy relationship with food and your body."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Practice self-care: Engage in activities that promote relaxation, self-love, and positive body image.",
        "Seek support: Reach out to a therapist, support group, or loved ones who can provide understanding and guidance.",
        "Challenge negative thoughts: Identify and challenge any negative or distorted thoughts about your body or food.",
        "Develop a healthy eating plan: Work with a nutritionist to create a balanced and nourishing meal plan.",
        "Celebrate progress: Recognize and celebrate small victories along your journey to recovery."
      ]
    },
    {
      type: 'paragraph',
      text: "Remember, recovering from the effects of anorexia is a challenging process, but you're not alone. Surround yourself with a strong support system, be patient with yourself, and celebrate the progress you make along the way. You deserve a healthy, happy life!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `Can the Anorexia Healing subliminal cure anorexia?`, 
    answer: `The Anorexia Healing subliminal is designed to help individuals in their healing and recovery journey from the effects of anorexia. While it can provide support and positive reinforcement, it is important to understand that anorexia is a complex mental health disorder that often requires professional intervention and treatment. If you or someone you know is struggling with anorexia, it is crucial to seek help from a qualified healthcare provider.`,
    open: false
  },
  { 
    question: `How does the Anorexia Healing subliminal work?`, 
    answer: `The Anorexia Healing subliminal works by delivering positive affirmations and suggestions directly to the subconscious mind, aiming to redefine your relationship with food and your body. By repeatedly listening to the subliminal, you may begin to shift your mindset, improve self-esteem, foster a healthier body image, and cultivate healthier eating habits. It is important to use this subliminal as a complement to professional treatment and therapy if you are struggling with anorexia.`,
    open: false 
  },
  { 
    question: `Is it safe to listen to the Anorexia Healing subliminal while receiving treatment for anorexia?`, 
    answer: `It is generally safe to listen to the Anorexia Healing subliminal while receiving treatment for anorexia. However, it is important to consult with your healthcare provider or therapist to ensure it aligns with your personalized treatment plan. They can provide guidance on how to incorporate the subliminal into your overall recovery journey.`,
    open: false 
  },
  { 
    question: `Will the Anorexia Healing subliminal work overnight?`, 
    answer: `The effects of the Anorexia Healing subliminal may vary from person to person. It is important to approach it with realistic expectations and understand that healing from anorexia is a gradual process. Consistent listening over time, along with professional treatment and therapy, can support your recovery and help shift your mindset towards a healthier relationship with food and your body.`,
    open: false 
  },
  { 
    question: `Can the Anorexia Healing subliminal be used by individuals who have recovered from anorexia?`, 
    answer: `Yes, individuals who have recovered from anorexia can still benefit from the Anorexia Healing subliminal. It can help reinforce positive mindset shifts, maintain healthy eating habits, and support a continued healthy relationship with food and the body. However, it is important to consult with your healthcare provider to ensure it aligns with your specific circumstances and current state of recovery.`,
    open: false 
  },
  { 
    question: `Are there any side effects of listening to the Anorexia Healing subliminal?`, 
    answer: `There are no known side effects associated with listening to the Anorexia Healing subliminal. However, if you have any concerns or questions regarding its use, it is recommended to consult with a healthcare provider or therapist who can provide personalized guidance based on your individual needs.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>