import { render, staticRenderFns } from "./254-become-a-successful-mechanical-engineer.vue?vue&type=template&id=66c61fbc&scoped=true&"
import script from "./254-become-a-successful-mechanical-engineer.vue?vue&type=script&lang=js&"
export * from "./254-become-a-successful-mechanical-engineer.vue?vue&type=script&lang=js&"
import style0 from "./254-become-a-successful-mechanical-engineer.vue?vue&type=style&index=0&id=66c61fbc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66c61fbc",
  null
  
)

export default component.exports