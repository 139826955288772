<template>
  <GuideTemplate :tabs="tabs" :faqItems="faqItems" @toggleAccordion="toggleAccordion"/>
</template>

<script>
import GuideTemplate from './Template/GuideTemplate.vue'

export default {
    components: {
      GuideTemplate
    },
    methods: {
        toggleAccordion(selectedIndex) {
            this.faqItems.forEach((item, index) => {
                item.open = index === selectedIndex ? !item.open : false;
            });
        }
    },
    data: () => {
        return {
        selectedTab: 'how-it-works',
        tabs: [
            {
  id: 'how-it-works', 
  name: 'How it Works',
  sections: [
    {
      type: 'paragraph',
      text: "If you find yourself constantly craving chocolate and struggling to resist the temptation, this subliminal can help you break free from your addiction and regain control over your eating habits."
    },
    {
      type: 'heading',
      text: 'Here are some ways this subliminal can support you:'
    },
    {
      type: 'list',
      items: [
        "Reducing cravings for chocolate and unhealthy food.",
        "Strengthening your willpower and self-discipline to resist temptation.",
        "Promoting healthier alternatives and habits to replace your chocolate cravings.",
        "Helping you develop a healthier relationship with food and overcome emotional eating triggers.",
        "Boosting your confidence and motivation to make healthier choices in your diet."
      ]
    },
    {
      type: 'paragraph',
      text: "Breaking free from a chocolate addiction takes time and effort, but with consistent use of this subliminal, you can rewire your subconscious mind and gradually shift your cravings and habits. Remember, be patient with yourself and celebrate small victories along the way. You have the power to overcome your addiction and embrace a healthier lifestyle."
    }
  ]
},
            {
  id: 'before-listening', 
  name: 'Before Listening',
  sections: [
    {
      type: 'paragraph',
      text: "If you find yourself struggling with a chocolate addiction and want to regain control over your cravings and eating habits, this subliminal can be a valuable tool on your journey to recovery. Before you start listening, here are some suggestions to help you prepare:"
    },
    {
      type: 'heading',
      text: 'Preparation Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your addiction: Recognize and accept that you have an addiction to chocolate and that overcoming it is crucial for your well-being.",
        "Identify triggers: Reflect on the situations, emotions, or thoughts that typically lead to your chocolate cravings, so you can proactively address them.",
        "Seek support: Share your goals and struggles with trusted friends or family members who can provide encouragement and help keep you accountable.",
        "Create alternative coping mechanisms: Explore healthy alternatives and enjoyable activities that can satisfy your emotional needs and replace the habit of reaching for chocolate.",
        "Commit to self-care: Prioritize taking care of your physical and emotional well-being, as this can contribute to your overall success in breaking the addiction."
      ]
    },
    {
      type: 'paragraph',
      text: "By utilizing this subliminal regularly and implementing these preparation tips, you can gradually regain control over your cravings and form healthier eating habits. Remember, breaking an addiction takes time and effort, so be patient and kind to yourself throughout the process. You deserve to live a healthy and fulfilling life, free from the constraints of chocolate addiction. Let's begin this transformative journey together."
    }
  ]
},
            {
  id: 'after-listening', 
  name: 'After Listening',
  sections: [
    {
      type: 'paragraph',
      text: "Congratulations on completing the Chocolate Addiction Removal subliminal audio! By taking this step to overcome your addiction to chocolate, you've set yourself on a path towards regaining control over your cravings and unhealthy eating habits. It's time to reflect on your experience and continue your journey towards a healthier relationship with food."
    },
    {
      type: 'heading',
      text: 'Post-Listening Tips:'
    },
    {
      type: 'list',
      items: [
        "Acknowledge your progress: Give yourself credit for the steps you've taken towards breaking free from your chocolate addiction.",
        "Find healthier alternatives: Discover and try new snacks or treats that can satisfy your cravings without derailing your progress.",
        "Address emotional eating: Identify triggers and find healthy coping mechanisms to deal with emotions instead of turning to chocolate.",
        "Seek support: Consider joining a support group or confiding in a trusted friend or family member for encouragement and accountability."
      ]
    },
    {
      type: 'paragraph',
      text: "Overcoming addiction takes time and effort, but with every choice you make to prioritize your well-being, you are one step closer to freedom. Stay strong, stay focused, and remember that you have the power to break free from the control of chocolate cravings. You've got this!"
    }
  ]
},
            { 
            id: 'faq', 
            name: 'FAQ',
            sections: []
            }
        ],
 faqItems: [
  { 
    question: `How can the Chocolate Addiction Removal subliminal help me overcome my addiction?`, 
    answer: `The Chocolate Addiction Removal subliminal is designed to target and reprogram the subconscious mind, helping you overcome your addiction to chocolate. By listening to the subliminal consistently, you can begin to recognize and transform the underlying factors contributing to your cravings and unhealthy eating habits. Over time, you may experience a reduction in cravings, increased self-control, and a healthier relationship with food.`,
    open: false
  },
  { 
    question: `How long does it take to overcome a chocolate addiction with the subliminal?`, 
    answer: `The time it takes to overcome a chocolate addiction with the subliminal can vary depending on the individual. It may take some time for the affirmations to take effect and for your mindset to shift towards healthier eating habits. Consistent listening and practicing healthy coping mechanisms can help speed up the process. It is important to be patient with yourself and celebrate small victories along the way.`,
    open: false 
  },
  { 
    question: `Are there any additional steps I can take to complement the Chocolate Addiction Removal subliminal?`, 
    answer: `While listening to the subliminal is an effective tool in overcoming chocolate addiction, there are additional steps you can take to support your journey. Creating a support system, identifying triggers and finding alternative coping strategies, and replacing unhealthy snacks with healthier options are all helpful steps. Seeking professional guidance or joining a support group can also provide additional tools and accountability.`,
    open: false 
  },
  { 
    question: `Can the Chocolate Addiction Removal subliminal be used for other food addictions?`, 
    answer: `While the Chocolate Addiction Removal subliminal is specifically designed to address addiction to chocolate, some individuals may find it helpful for overcoming other food addictions as well. The underlying principles of reprogramming the subconscious mind and building healthier habits can apply to various forms of food addiction. However, for the best results, it is recommended to use subliminals specifically tailored to the target addiction.`,
    open: false 
  },
  { 
    question: `Is it possible to take breaks from listening to the subliminal without losing progress?`, 
    answer: `Yes, it is possible to take breaks from listening to the subliminal without losing progress. Consistency is important, but occasional breaks will not reverse the progress you have made. When you resume listening, it may take a few sessions to reestablish the momentum, but the positive effects you have already experienced can still be built upon.`,
    open: false 
  },
  { 
    question: `Is the Chocolate Addiction Removal subliminal safe for everyone to use?`, 
    answer: `Yes, the Chocolate Addiction Removal subliminal is generally safe, as it consists of positive affirmations that aim to reprogram the subconscious mind. However, if you have any underlying health conditions or concerns, it is advisable to consult with a healthcare professional before using the subliminal. Additionally, it is important to use the subliminal as a supplement to healthy lifestyle changes and seek professional help if needed.`,
    open: false 
  }
] 
        };
    },
};
</script>

<style scoped>
    body {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }
    .active-class {
        border-bottom-color: #4a5568;
        color: #4a5568; /* Optional: Change text color for active tab */
        font-weight: 600;
    }
    button:focus {
        outline: none;
    }
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
        color: rgb(45, 73, 254);
    }
    li.flex.items-center {
        align-items: center;
        gap: 0.5rem;
    }
</style>